'use strict';

define("MeterControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class MeterControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            this.actualFormat = lxSplitFormat(this.control.details.actualFormat);
            this.totalFormat = lxSplitFormat(this.control.details.totalFormat);
        }

        prepareStates(newVals) {
            this.states.actualValue = newVals[this.control.states.actual];
            this.states.totalValue = newVals[this.control.states.total];
            this.states.isConsuming = this.states.actualValue > 0;
            var splitTexts = lxUnitConverter.convertSplitAndApply(this.control.details.actualFormat, this.states.actualValue, true);
            this.states.actualSuccText = splitTexts.succTxt;
            this.states.actualValueText = splitTexts.valueTxt;
            this.states.actualText = lxUnitConverter.convertAndApply(this.control.details.actualFormat, this.states.actualValue);
            splitTexts = lxUnitConverter.convertSplitAndApply(this.control.details.totalFormat, this.states.totalValue, true);
            this.states.totalSuccText = splitTexts.succTxt;
            this.states.totalValueText = splitTexts.valueTxt;
            this.states.totalText = lxUnitConverter.convertAndApply(this.control.details.totalFormat, this.states.totalValue);

            if (this.control.isBidirectional()) {
                this.states.totalNegValue = newVals[this.control.states.totalNeg];
                splitTexts = lxUnitConverter.convertSplitAndApply(this.control.details.totalFormat, this.states.totalNegValue, true);
                this.states.totalNegSuccText = splitTexts.succTxt;
                this.states.totalNegValueText = splitTexts.valueTxt;
                this.states.totalNegText = lxUnitConverter.convertAndApply(this.control.details.totalFormat, this.states.totalNegValue);
            }
            if (this.control.hasStorage()) {
                this.states.storageValue = newVals[this.control.states.storage];
                splitTexts = lxUnitConverter.convertSplitAndApply(this.control.details.storageFormat, this.states.storageValue, true);
                this.states.storageSuccText = splitTexts.succTxt;
                this.states.storageValueText = splitTexts.valueTxt;
                this.states.storageText = lxUnitConverter.convertAndApply(this.control.details.storageFormat, this.states.storageValue);
            }
        }

        getStateText() {
            return this.states.actualText + SEPARATOR_SYMBOL + this.states.totalText;
        }

        getStateTextForContent() {
            return null;
        }

        getStateIcon() {
            if (this.states.isConsuming) {
                return Icon.ConsumptionMeter.ON;
            }

            return Icon.ConsumptionMeter.OFF;
        }

        getStateColor() {
            if (this.states.isConsuming) {
                return window.Styles.colors.stateActive;
            }
        }

    };
});
