'use strict';

ActiveMSComp.factory('MessageCenterExt', function () {
    let weakThis,
        currentMSSerialNo,
        currentUserName,
        MESSAGE_CENTER_FILENAME = "_MessageCenter.json",
        // We will prepend the current serialNumber later
        comp,
        lastSeverityChange,
        specificEntriesMap = {}; // key = sourceUuid; value = Array of entries

    function MessageCenterExt(activeMsComp) {
        this.name = "MessageCenterExt";
        weakThis = this
        comp = activeMsComp;
        CompChannel.on(CCEvent.StructureReady, registerForStates.bind(weakThis)); // Use ConnClose in favour of StopMSSession,
        // StopMSSession won't be emitted when the socket closes due to bad connection

        CompChannel.on(CCEvent.ConnClosed, unregisterStates.bind(weakThis));
    }

    /**
     * Wrap currentMessageCenterStructure into an object to be able to use Getter and Setter
     * @type {{_currentMessageCenterStructure: null, currentMessageCenterStructure}}
     * @private
     */


    MessageCenterExt.prototype._private = {
        _currentMessageCenterStructure: null,

        get currentMessageCenterStructure() {
            // It is important to clone the current structure the prevent the currentStructure from being modified
            return cloneObject(this._currentMessageCenterStructure);
        },

        set currentMessageCenterStructure(structure) {
            if (structure && structure.entries) {
                structure.entries = structure.entries.sort(MessageCenterHelper.sortEntries);
            }

            structure = sanitizeEntries(structure);
            this._currentMessageCenterStructure = structure;
            MessageCenterHelper.currentStructure = structure;
            saveMessageCenterStructure(structure);
            notifyListener(structure);
        }

    };
    /**
     * Lets anyone register for severity change
     * @param listener function called when new structure is received
     */

    MessageCenterExt.prototype.registerForMessageCenterUpdate = function registerForMessageCenterUpdate(listener) {
        if (weakThis._private.currentMessageCenterStructure) {
            Debug.SystemState && console.log(`SystemState: registerForMCUpdate(), structure is already here => ${weakThis._private.currentMessageCenterStructure?.entries?.length} entries`,)
            listener(null, weakThis._private.currentMessageCenterStructure);
        } else {
            Debug.SystemState && console.log("SystemState: registerForMCUpdate(), there is no structure here yet")
        }

        Debug.SystemState && console.log("SystemState: registerForMCUpdate(), register for update...")
        return comp.on(ActiveMSComp.ECEvent.MESSAGE_CENTER_UPDATE, listener);
    };
    /**
     * Returns true if the message center structure has already been loaded
     * @returns {boolean}
     */


    MessageCenterExt.prototype.hasMessageCenterStructure = function hasMessageCenterStructure() {
        return !!weakThis._private.currentMessageCenterStructure;
    };
    /**
     * Lets anyone register for messageCenter entry updates with the specific source UUID
     * @param listener function called when new structure is received
     * @param [sourceUuids] either a single source uuid string or an array of sourceUuids. Only entries with this sourceUuid will be returned
     * @return {*}
     */


    MessageCenterExt.prototype.registerSourceUuidForMessageCenterUpdate = function registerSourceUuidForMessageCenterUpdate(listener, sourceUuids) {
        var uuids, unregisterFns;
        if (Array.isArray(sourceUuids)) {
            uuids = sourceUuids;
        } else if (typeof sourceUuids === "string") {
            uuids = [sourceUuids];
        } else {
            uuids = [null];
        }

        unregisterFns = uuids.map(uuid => {
            return this._registerSourceUuidForMessageCenterUpdate(listener, uuid);
        });

        return () => {
            unregisterFns.forEach(fn => fn());
        }
    };

    MessageCenterExt.prototype._registerSourceUuidForMessageCenterUpdate = function _registerSourceUuidForMessageCenterUpdate(listener, sourceUuid) {
        specificEntriesMap[sourceUuid] = MessageCenterHelper.findActiveEntriesWithSourceUuid(sourceUuid);

        if (weakThis._private.currentMessageCenterStructure) {
            if (sourceUuid) {
                listener(null, specificEntriesMap[sourceUuid], sourceUuid);
            }
        } // Create the unique event ID by appending the sourceUuid
        return comp.on(ActiveMSComp.ECEvent.MESSAGE_CENTER_SPECIFIC_UPDATE + sourceUuid, listener, sourceUuid);
    }

    /**
     * Fetches the messageCenter either from cache, or from the Miniserver.
     * This also triggers the onMessageCenterUpdate event if a structure has been found or loaded
     * @param forceFetch If true, the messageCenter structure will be requested from the Miniserver
     * @param [automatic] true if the command was send automatically
     */


    MessageCenterExt.prototype.fetchEntries = function fetchEntries(forceFetch, automatic) {
        Debug.SystemState && console.log(this.name, "fetchEntries: " + forceFetch);
        var def = Q.defer(),
            messageCenterUuid = SandboxComponent.getStructureManager().getMessageCenter().uuidAction;

        if (messageCenterUuid) {
            if (!forceFetch) {
                // Check, if we have a cached messageCenter and load it if not
                def.resolve(getCachedMessageCenterStructure().then(function (cachedMessageCenter) {
                    return cachedMessageCenter;
                }.bind(this), function (e) {
                    return this.safeFetchEntriesFromMS(messageCenterUuid, automatic);
                }.bind(this)));
            } else {
                def.resolve(this.safeFetchEntriesFromMS(messageCenterUuid, automatic));
            }
        } else {
            def.reject("Message center not available");
        }

        return def.promise;
    };
    /**
     * Will safeguard against launching multiple getEntries requests at once. The first one will be launched asap.
     * If a request arrives while one is active, it'll enqueue it and wait for the pending one to resolve before re-requesting
     * the entries.
     * If multiple requests arrive, while a refetch is already pending, they'll all resolve with the result of that pending refetch.
     * @param messageCenterUuid     the message center to request from.
     * @param automatic             whether or not the request was made from the status updates.
     * @returns {*}
     */


    MessageCenterExt.prototype.safeFetchEntriesFromMS = function safeFetchEntriesFromMS(messageCenterUuid, automatic) {
        var promise;

        if (this._fetchAgainDeferred && this._fetchAgainDeferred.promise.isPending()) {
            // refetch pending, return the refetech promise!
            Debug.SystemState && console.log(this.name, "safeFetchEntriesFromMS >> fetch in progress, refetch already pending, enqueue");
            promise = this._fetchAgainDeferred.promise;
            this._pendingFetchMessageCenterUuuid = messageCenterUuid; // update the automatic flag (may be requested by the screen!)  - should remain false if once was false.

            this._pendingFetchIsAutomatic = this._pendingFetchIsAutomatic || !!automatic;
        } else if (this._fetchPromise && this._fetchPromise.isPending()) {
            // request pending, store and refetch later.
            Debug.SystemState && console.log(this.name, "safeFetchEntriesFromMS >> fetch in progress, enqueue");
            this._fetchAgainDeferred = Q.defer();
            promise = this._fetchAgainDeferred.promise;
            this._pendingFetchMessageCenterUuuid = messageCenterUuid;
            this._pendingFetchIsAutomatic = !!automatic;
        } else {
            // hasn't got a pending fetch, fetch now!
            Debug.SystemState && console.log(this.name, "safeFetchEntriesFromMS >> fetch");
            this._fetchPromise = fetchEntriesFromMS(messageCenterUuid, automatic).then(function () {
                return this._private.currentMessageCenterStructure;
            }.bind(this)).finally(function () {
                this.handleFetchFromMsPassed();
            }.bind(this));
            promise = this._fetchPromise;
        }

        return promise;
    };
    /**
     * Checks for pending refetches, either refetches right away or schedules a delayed refetch if the pending request
     * originates from a screen instead of a status update.
     */


    MessageCenterExt.prototype.handleFetchFromMsPassed = function handleFetchFromMsPassed() {
        if (this._fetchAgainDeferred && this._fetchAgainDeferred.promise.isPending()) {
            if (this._pendingFetchIsAutomatic) {
                Debug.SystemState && console.log(this.name, "handleFetchFromMsPassed >> pending refetch, start timeout");
                setTimeout(function () {
                    Debug.SystemState && console.log(this.name, "handleFetchFromMsPassed >> timeout passed, refetch");

                    this._fetchAgainDeferred.resolve(fetchEntriesFromMS(this._pendingFetchMessageCenterUuuid, this._pendingFetchIsAutomatic));
                }.bind(this), 2000);
            } else {
                Debug.SystemState && console.log(this.name, "handleFetchFromMsPassed >> pending refetch, NOT automatic, refetch right away");

                this._fetchAgainDeferred.resolve(fetchEntriesFromMS(this._pendingFetchMessageCenterUuuid, this._pendingFetchIsAutomatic));
            }
        } else {
            Debug.SystemState && console.log(this.name, "handleFetchFromMsPassed >> done fetching, no refetch scheduled!");
        }
    };
    /**
     * Will return the current message center structure, if not available, it will return null;
     * @return {*}
     */


    MessageCenterExt.prototype.getActiveEntry = function getActiveEntry() {
        var activeEntry = null,
            structure = weakThis._private.currentMessageCenterStructure;

        if (structure) {
            activeEntry = structure.entries.find(function (entry) {
                return entry.entryUuid === structure.activeEntryUuid;
            });
        }

        return activeEntry;
    };

    MessageCenterExt.prototype.updateEntry = function updateEntry(entryUUID, fieldsToUpdate) {
        var structure = weakThis._private.currentMessageCenterStructure;

        if (structure) {
            var index = structure.entries.findIndex(function (e) {
                return e.entryUuid === entryUUID;
            });

            if (index !== -1) {
                structure.entries[index] = { ...structure.entries[index], ...fieldsToUpdate };
                
                structure = sanitizeEntries(structure);
                weakThis._private.currentMessageCenterStructure = structure;
                MessageCenterHelper.currentStructure = structure;
                saveMessageCenterStructure(structure);
                notifyListener(structure);
            }

        }
    };

    var notifyListener = function notifyListener(messageCenterStructure) {
        var previousEntriesForSourceUuidString;
        Object.keys(specificEntriesMap).forEach(function (sourceUuid) {
            // Stringify the already existing entries to prevent any unnecessary updates
            previousEntriesForSourceUuidString = JSON.stringify(specificEntriesMap[sourceUuid]); // Get the new active entries for the given sourceUuid

            specificEntriesMap[sourceUuid] = MessageCenterHelper.findActiveEntriesWithSourceUuid(sourceUuid); // Compare the previous entries of the map with the new entries. Just trigger an update if the entries have changed
            // Its important to also update if the entries have been removed!

            if (previousEntriesForSourceUuidString !== JSON.stringify(specificEntriesMap[sourceUuid])) {
                // Notify the listener with its specific entries
                comp.onMessageCenterSpecificUpdate(specificEntriesMap[sourceUuid], sourceUuid);
            }
        });
        comp.onMessageCenterUpdate(messageCenterStructure);
    };
    /**
     * Fetches the messageCenter structure from the Miniserver and caches it
     * @param messageCenterUuid The UUID of the messageCenter
     * @param [automatic] true if the command was send automatically
     */


    var fetchEntriesFromMS = function fetchEntriesFromMS(messageCenterUuid, automatic) {
        var tmpStructure, cmd;
        Debug.SystemState && console.log(weakThis.name, "fetchEntriesFromMS");
        cmd = Commands.format(Commands.CONTROL.COMMAND, messageCenterUuid, Commands.MESSAGE_CENTER.GET_ENTRIES);
        let defer = Q.defer();
        CommunicationComponent.sendViaHTTP(cmd, EncryptionType.NONE, automatic).depActiveMsThen(function (data) {
            tmpStructure = JSON.parse(data.LL.value);
            tmpStructure.lastSeverityChange = lastSeverityChange;
            Debug.SystemState && console.log(weakThis.name, "fetchEntriesFromMS: got result from ms => saving...")
            weakThis._private.currentMessageCenterStructure = tmpStructure;
            Debug.SystemState && console.log(weakThis.name, "fetchEntriesFromMS: promise resolved successfully => resolve()")
            defer.resolve();
        }, (data) => {
            Debug.SystemState && console.log(weakThis.name, "fetchEntriesFromMS: promise rejected => reject()")
            defer.reject();
        }, null, () => {
            // in case the connection gets lost during the promies, it wouldn't be resolved (see dependentPromise.js)
            // therefore this bailout function will be called, so we just reject the promise, so its not stuck
            Debug.SystemState && console.log(weakThis.name, "fetchEntriesFromMS: Connection was lost while promise was running => bailout()")
            defer.reject()
        });

        return defer.promise;
    };
    /**
     * Checks, if the Miniserver supports messageCenter and registers for its state changes if available
     */


    var registerForStates = function registerForStates() {
        var activeMs;

        if (MessageCenterHelper.isAvailable()) {
            activeMs = ActiveMSComponent.getActiveMiniserver(); // Save the SerialNo of the active Miniserver, its important for caching the MessageCenter entries

            currentMSSerialNo = activeMs.serialNo;
            currentUserName = activeMs.activeUser;
            getCachedMessageCenterStructure().then(function (messageCenter) {
                weakThis._private.currentMessageCenterStructure = messageCenter;
            }, function () {
                weakThis._private.currentMessageCenterStructure = null;
            }).finally(function () {
                Debug.SystemState && console.log(weakThis.name, "registerForStates");
                SandboxComponent.registerForStateChangesForUUID("messageCenter", weakThis, receivedStates.bind(weakThis));
            });
        } else {
            // Null this variable in case the user connects to a Miniserver supporting the MessageCenter prior to one who does
            weakThis._private.currentMessageCenterStructure = null;
            Debug.SystemState && console.info("MessageCenter is not supported by this Miniserver");
        }
    };
    /**
     * Just unregisters the messageCenterState
     */


    var unregisterStates = function unregisterStates() {
        SandboxComponent.unregisterForStateChangesForUUID("messageCenter", weakThis);
        weakThis._private.currentMessageCenterStructure = null;
    };
    /**
     * We got a MessageCenter state, lets compare the severityChanges and fetch the messageCenter structure
     * @param states
     */


    var receivedStates = function receivedStates(states) {
        // Only fetch the new data if the severity has changed!
        if (!weakThis._private.currentMessageCenterStructure || states.lastSeverityChange !== weakThis._private.currentMessageCenterStructure.lastSeverityChange) {
            Debug.SystemState && console.log(weakThis.name, "receivedStates changed!");

            if (!this.requestTimeout) {
                this.requestTimeout = setTimeout(function () {
                    if (this.shouldRequestEntries) {
                        lastSeverityChange = states.lastSeverityChange;
                        Debug.SystemState && console.log(weakThis.name, "lastSeverityChange: '" + lastSeverityChange + "' -> fetching Systemstate!");
                        weakThis.fetchEntries(true, true);
                    }

                    this.shouldRequestEntries = false;
                    this.requestTimeout = null;
                }.bind(this), 2000);
                lastSeverityChange = states.lastSeverityChange;
                Debug.SystemState && console.log(weakThis.name, "lastSeverityChange: '" + lastSeverityChange + "' -> fetching Systemstate!");
                weakThis.fetchEntries(true, true);
            } else {
                this.shouldRequestEntries = true;
            }
        }
    };

    var saveMessageCenterStructure = function saveMessageCenterStructure(fileContent) {
        Debug.SystemState && console.log(weakThis.name, "saveMessageCenterStructure");
        var mCFileName = currentMSSerialNo + "_" + currentUserName + MESSAGE_CENTER_FILENAME;
        PersistenceComponent.saveFile(mCFileName, fileContent, DataType.OBJECT);
    };

    var getCachedMessageCenterStructure = function getCachedMessageCenterStructure() {
        Debug.SystemState && console.log(weakThis.name, "getCachedMessageCenterStructure");
        var mCFileName = currentMSSerialNo + "_" + currentUserName + MESSAGE_CENTER_FILENAME;
        return PersistenceComponent.loadFile(mCFileName, DataType.OBJECT).then(function (structure) {
            weakThis._private.currentMessageCenterStructure = structure;
            return weakThis._private.currentMessageCenterStructure;
        });
    };
    /**
     * Sanitizes all entries of a given system state structure to ensure correct behaviour
     * @param structure
     * @return {*}
     */


    var sanitizeEntries = function sanitizeActions(structure) {
        // The structure may be null, or may not contain any entries
        if (!structure || !structure.entries) {
            return structure;
        }

        structure.entries = structure.entries.filter(function (entry) {
            if (!Feature.CORRECT_MS_UPDATE_VIA_SYSTEM_STATE && entry.eventId === MessageCenterHelper.KNOWN_ENTRY_ID.MS_UPDATE && !SandboxComponent.checkPermission(MsPermission.CONFIG)) {
                // Due to a bug in the Miniserver we need to filter out this specific entry if the user don't have the rights to access the config
                return false;
            } // Entries occurred before 10.0.7.19 don't support the setHistoricAt flag but still use the isHistoric flag
            // set the current time to ensure correct behaviour


            if (entry.isHistoric && entry.setHistoricAt === null) {
                // TODO-goelzda: Remove once the isHistoric property has been removed from the structure
                entry.setHistoricAt = moment().unix();
            } // Some actions can't be handled by the app. Remove them from the structure


            entry.actions = entry.actions.filter(function (action) {
                return (!action.location || UrlHelper.validateLocationUrlStart(action.location)) && ( // Remove any location based actions not known by the app
                    // Remove all actions which require permissions now allowed for the current user
                    !action.hasOwnProperty("requiredPermissions") || SandboxComponent.checkPermission(action.requiredPermissions));
            });
            return true;
        });
        return structure;
    };

    return MessageCenterExt;
});