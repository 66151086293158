import PairSetupCommunicator from "./PairSetupCommunicator";


export class AdminCredsRequest {
    
    constructor(ms) {
        this.name = "AdminCredsRequest";
        this._title = _("admin-rights-required.title");
        this._message = _("admin-rights-required.message");
        this._permissions = MsPermission.ADMIN;

        this._credPopupPromise = null;
        this._creds = null;
    }

    static send(ms, cmd) {
        const rq = new AdminCredsRequest();

        const authThenSend = ({status, statusText} = {}) => {
            const lockedOut = status === 403 && statusText.indexOf("temporarily blocked") >= 0;
            return rq.request(!!status, statusText === "Forbidden", lockedOut).then(creds => {
                let sendPrms = PairSetupCommunicator.send(ms, creds, cmd);
                NavigationComp.showWaitingFor(sendPrms);
                return sendPrms.then(null, (err) => {
                    const {status, statusText} = err.exception;
                    console.error(rq.name, "sending failed! " + status + ", text=" + statusText);
                    return authThenSend({status, statusText});
                });
            });
        }

        return authThenSend();
    }

    request(wasWrong = false, notAnAdmin = false, lockedOut = false) {
        Debug.PairedApp && console.log(this.name, "request");
        this._credPopupPromise = NavigationComp.showPopup(
            this._credPromptOptions(wasWrong, notAnAdmin, lockedOut),
            PopupType.LOGIN
        ).then(({user, pass}) => {
            Debug.PairedApp && console.log(this.name, "request - resolved: " + user);
            this._creds = {user, pass};
            return this._creds;
        }, err => {
            console.error(this.name, "request - rejected! ", err);
            return Q.reject(err);
        });
        return this._credPopupPromise;
    }

    _credPromptOptions(wasWrong = false, notAnAdmin = false, lockedOut = false) {
        var icon,
            title,
            message = this._message,
            color = window.Styles.colors.red;

        icon = Icon.CAUTION;
        title = this._title || ((wasWrong && !lockedOut) ? _("wrong-password") : _("password"));

        if (lockedOut) {
            message = _("pre-load-login.too-many-wrong-logins");
        }

        return {
            title: title,
            message: message,
            buttonOk: _("continue"),
            buttonCancel: true,
            icon: icon,
            color: color
        };
    }
}