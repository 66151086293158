'use strict';

define("GateCentralControlStateContainer", ["ControlCentralStateContainer"], function (ControlCentralStateContainer) {
    return class GateCentralControlStateContainer extends ControlCentralStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.nrOfClosed = 0;
            this.states.nrOfClosed_Sel = 0;
            this.states.nrOfOpened = 0;
            this.states.nrOfOpened_Sel = 0;
            this.states.nrOfOpening = 0;
            this.states.nrOfOpening_Sel = 0;
            this.states.nrOfClosing = 0;
            this.states.nrOfClosing_Sel = 0;
            this.states.nrOfPreventOpen = 0;
            this.states.nrOfPreventOpen_Sel = 0;
            this.states.nrOfPreventClose = 0;
            this.states.nrOfPreventClose_Sel = 0;
            this.states.allClosed = true;
            this.states.allClosed_Sel = true;
            this.states.isMoving = false;
            newVals.forEachState(function (subCtrlNewVals, control, isCtrlSelected) {
                if (subCtrlNewVals.isMoving) {
                    this.states.isMoving = true;

                    if (isCtrlSelected) {
                        this.states.isMoving_Sel = true;
                    }

                    if (subCtrlNewVals.direction === Direction.UP) {
                        this.states.nrOfOpening++;
                        isCtrlSelected && this.states.nrOfOpening_Sel++;
                    }

                    if (subCtrlNewVals.direction === Direction.DOWN) {
                        this.states.nrOfClosing++;
                        isCtrlSelected && this.states.nrOfClosing_Sel++;
                    }
                }

                if (subCtrlNewVals.isClosed) {
                    this.states.nrOfClosed++;
                    isCtrlSelected && this.states.nrOfClosed_Sel++;
                } else {
                    this.states.nrOfOpened++;
                    isCtrlSelected && this.states.nrOfOpened_Sel++;
                    this.states.allClosed = false;

                    if (isCtrlSelected) {
                        this.states.allClosed_Sel = false;
                    }
                }

                if (subCtrlNewVals.preventOpen) {
                    this.states.nrOfPreventOpen++;
                    isCtrlSelected && this.states.nrOfPreventOpen_Sel++;
                }

                if (subCtrlNewVals.preventClose) {
                    this.states.nrOfPreventClose++;
                    isCtrlSelected && this.states.nrOfPreventClose_Sel++;
                }
            }.bind(this));
        }

        getStateIcon() {
            // don't change the source too often. Looks like flickering if two different types of gates change often
            if (!this._keepIconSourceTimeout) {
                // update it first
                this.stateIconSource = this._getStateIconSource(); // ensure it isn't updated right away again

                this._keepIconSourceTimeout = setTimeout(function () {
                    this._keepIconSourceTimeout = null; // ensure a last state update is dispatched & the proper icon is shown, otherwise different icons
                    // may be shown on different devices.

                    if (!this.states.isMoving) {
                        this.version++; // ensures a state change is dispatched

                        this.notifyListener();
                    }
                }.bind(this), 3000);
            }

            if (this.stateIconSource) {
                return this.stateIconSource.getStates().stateIcon;

            } else {
                return this.control.getIcon();

            }
        }

        getStateColor() {
            if (this.states.allClosed) {
                return window.Styles.colors.stateActive;
            } else if (this.states.nrOfOpened > 0) {
                return window.Styles.colors.orange;
            }
        }

        getStateText() {
            if (this.states.allClosed) {
                return _("controls.gate.group.closed");
            } else {
                return _("controls.gate.group.open", {
                    count: this.states.nrOfOpened
                });
            }
        }

        /**
         * Will look up the door that is to be used to represent the groups state. It will always use the most prominent
         * control. The door that is the most open takes the lead. If there are more on the same level, the favorite
         * flag, rating and the name are used to determine the most prominent one.
         * @return {*}
         * @private
         */
        _getStateIconSource() {
            var sortArr = [],
                position,
                posDelta = 1,
                states,
                newSource,
                sortFields = ["isMoving", "position", "isFavorite", "defaultRating", "name"]; // Create separate sort array which also contains the position. The subcontrols array doesn't hold states.

            this.control.getSubControls().forEach(function (ctrl) {
                states = ctrl.getStates();
                position = states.positionState;
                position = Math.round(position * 100) / 100;
                position = position * -1; // invert value to suit sorting order. usually lower is better

                sortArr.push({
                    uuid: ctrl.uuidAction,
                    name: ctrl.name,
                    isFavorite: ctrl.isFavorite,
                    defaultRating: ctrl.defaultRating,
                    position: position,
                    isMoving: states.isMoving * -1
                });
            });

            if (sortArr.length) {
                newSource = ActiveMSComponent.getControlByUUID(sortArrByFields(sortArr, sortFields)[0].uuid); // ensure that it doesn't flicker between doors that open/close almost at the same pos. require a min delta before changing
            }

            if (this.stateIconSource) {
                posDelta = Math.abs(newSource.getStates().positionState - this.stateIconSource.getStates().positionState);
            }

            if (posDelta <= 0.01) {
                // ensure that the most favorized door is shown.
                newSource = sortArrByFields([newSource, this.stateIconSource], ["isFavorite", "defaultRating", "name"])[0];
            }

            return newSource;
        }

    };
});
