'use strict';

define("InfoViewControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class InfoViewControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);

            if (this.control.type === "InfoOnlyDigital") {
                if (this.control.details.image) {
                    this.isDigitalIcon = true;
                } else if (this.control.details.text && this.control.details.color) {
                    this.isDigitalText = true;
                }
            } else if (this.control.type === "InfoOnlyAnalog") {
                this.isAnalog = true;
            } else if (this.control.type === "InfoOnlyText") {
                this.isText = true;
            }
        }

        prepareStates(newVals) {
            if (this.isDigitalText) {
                this.states.text = newVals[this.control.states.active] === 1 ? this.control.details.text.on : this.control.details.text.off;
                this.states.textColor = newVals[this.control.states.active] === 1 ? this.control.details.color.on : this.control.details.color.off;
            } else if (this.isAnalog) {
                this.states.text = lxFormat(this.control.details.format, newVals[this.control.states.value]);
                this.states.textColor = newVals[this.control.states.error] === 1 ? window.Styles.colors.red : "";
            } else if (this.isDigitalIcon) {
                this.states.iconSrc = newVals[this.control.states.active] === 1 ? this.control.details.image.on : this.control.details.image.off;
                this.states.iconColor = newVals[this.control.states.active] === 1 ? this.control.details.image.onColor : this.control.details.image.offColor;
            } else if (this.isText) {
                this.states.text = lxFormat(this.control.details.format, newVals[this.control.states.text].text);
            }
        }

        getStateTextColor() {
            if (this.isDigitalText || this.isAnalog) {
                return this.states.textColor;
            }
        }

        getStateText() {
            if (this.isText || this.isDigitalText || this.isAnalog) {
                return this.states.text;
            } else {
                return "";
            }
        }

        getStateTextForContent() {
            return null;
        }

        getStateIcon() {
            if (this.isDigitalIcon) {
                return this.states.iconSrc;
            }
        }

        getStateIconColor() {
            if (this.states.iconColor) {
                return this.states.iconColor;
            }
        }

        getStateInfo() {
            return null;
        }

    };
});
