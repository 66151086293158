'use strict';

export default ({names}) => {
    window[names.int].factory(names.int, ['$injector', 'ActiveMSComp', 'PersistenceComp', 'PlatformComp', function ($injector, ActiveMSComp, PersistenceComp, PlatformComp) {
        // internal variables
        let weakThis, extensions, automaticDesignerUuid, ADCommunicationExt, ADRuleExt, ADMenuExt;
        CompChannel.on(CCEvent.CrucialDataLoaded, function () {
            Debug.Autopilot.General && console.log(weakThis.name, "on CrucialDataLoaded");

            if (SandboxComponent.isAutopilotAvailable()) {
                Debug.Autopilot.Communication && console.log(weakThis.name, "     isAutopilotAvailable, prepare");
                automaticDesignerUuid = ActiveMSComponent.getStructureManager().getAutopilotGenerator().uuidAction;
                SandboxComponent.registerForStateChangesForUUID(AutomaticDesignerEnums.AUTOMATIC_DESIGNER_STATE_UUID, weakThis, weakThis._receivedStates);

                _resetSearchIndexes();

                weakThis._checkCachedFiles();
            } else {
                Debug.Autopilot.Communication && console.log(weakThis.name, "     automatic designer unavailable");
            }
        }.bind(this));
        CompChannel.on(CCEvent.STRUCTURE_UPDATE_FINISHED, function (event, newControls) {
            Debug.Autopilot.General && console.log(weakThis.name, "on STRUCTURE_UPDATE_FINISHED");

            if (SandboxComponent.isAutopilotAvailable()) {
                _resetSearchIndexes();

                weakThis._checkCachedFiles();
            }
        });
        CompChannel.on(CCEvent.ConnClosed, function () {
            Debug.Autopilot.General && console.log(weakThis.name, "on ConnClosed");
            if (SandboxComponent.isAutopilotAvailable()) {
                weakThis.fetchingMsRulesDataDef && weakThis.fetchingMsRulesDataDef.reject();
                weakThis.fetchingMsScenesDataDef && weakThis.fetchingMsScenesDataDef.reject();
                weakThis.fetchingMsRulesDataDef = null;
                weakThis.fetchingMsScenesDataDef = null;

                _resetSearchIndexes();

                SandboxComponent.unregisterForStateChangesForUUID(AutomaticDesignerEnums.AUTOMATIC_DESIGNER_STATE_UUID, weakThis);
            }
        });
        CompChannel.on(CCEvent.StopMSSession, function () {
            Debug.Autopilot.General && console.log(weakThis.name, "on StopMSSession");
            weakThis.currMsRulesVersion = null;
            weakThis.currMsScenesVersion = null;
            weakThis.rulesFromMs = null;
            weakThis.scenesFromMs = null;
            weakThis.rulesVersion = null;
            weakThis.scenesVersion = null;
            weakThis.waitingForRulesDef && weakThis.waitingForRulesDef.reject();
            weakThis.waitingForScenesDef && weakThis.waitingForScenesDef.reject();
            weakThis.waitingForRulesDef = null;
            weakThis.waitingForScenesDef = null;
        });

        var ADComp = function () {
            weakThis = this
            weakThis.name = 'AutomaticDesignerComp';
            weakThis._usedGroups = {
                actions: {},
                events: {}
            };
            weakThis._searchIndex = {};
            weakThis._searchIndex[AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS] = {};
            weakThis._searchIndex[AutomaticDesignerEnums.SCREEN_TYPES.EVENTS] = {};
            weakThis.MIN_SEARCH_WEIGHT = 10;
            weakThis._registeredScreensForRuleChange = [];
            BaseComponent.call(this, arguments);
            ADCommunicationExt = new extensions.AutomaticDesignerCommunication(this);
            ADRuleExt = new extensions.AutomaticDesignerRule(this);
            ADMenuExt = new extensions.AutomaticDesignerMenu(this);
            weakThis.waitingForRulesDef = null;
            weakThis.waitingForScenesDef = null;
            var exposed = {
                convertTaskToRule: weakThis.convertTaskToRule,
                getAutomaticDesignerUuid: weakThis.getAutomaticDesignerUuid,
                getRules: weakThis.getRules.bind(weakThis),
                getRulesSync: weakThis.getRulesSync.bind(weakThis),
                getScenes: weakThis.getScenes.bind(weakThis),
                getScenesSync: weakThis.getScenesSync.bind(weakThis),
                registerForRuleChangeNotify: weakThis.registerForRuleChangeNotify,
                getRawDataForPath: weakThis.getRawDataForPath,
                getPathForItemAndType: weakThis.getPathForItemAndType,
                getDataFromCapabilities: weakThis.getDataFromCapabilities,
                getAvailableIntTypesForType: weakThis.getAvailableIntTypesForType,
                getCapabilityForDescID: weakThis.getCapabilityForDescID,
                getOperatingModeItems: weakThis.getOperatingModeItems,
                getNameForType: weakThis.getNameForType,
                getNameForEntryType: weakThis.getNameForEntryType,
                getIconForEntryType: weakThis.getIconForEntryType,
                getUsedGroupsUuidsByType: weakThis.getUsedGroupsUuidsByType,
                setRuleActive: ADCommunicationExt.setRuleActive,
                deleteRule: ADCommunicationExt.deleteRule,
                addOrEditRule: ADCommunicationExt.addOrEditRule,
                acquireVisuPasswordForObj: ADRuleExt.acquireVisuPasswordForObj,
                recordActions: weakThis.recordActions,
                setCurrentRuleToEdit: ADRuleExt.setCurrentRuleToEdit,
                getCurrentEditRule: ADRuleExt.getCurrentEditRule,
                setRuleTitle: ADRuleExt.setRuleTitle,
                setRuleDescription: ADRuleExt.setRuleDescription,
                getCurrentEditRuleEventGroups: ADRuleExt.getCurrentEditRuleEventGroups,
                getCurrentRuleActions: ADRuleExt.getCurrentRuleActions,
                setCurrentEditSectionIndex: ADRuleExt.setCurrentEditSectionIndex,
                addEventToRule: ADRuleExt.addEventToRule,
                removeActionFromRule: ADRuleExt.removeActionFromRule,
                removeEventFromRule: ADRuleExt.removeEventFromRule,
                getEventValueAtIndex: ADRuleExt.getEventValueAtIndex,
                getEventOperator: ADRuleExt.getEventOperator,
                setEventOperator: ADRuleExt.setEventOperator,
                getActionValue: ADRuleExt.getActionValue,
                setEventValueAtIndex: ADRuleExt.setEventValueAtIndex,
                setActionValueAtIndex: ADRuleExt.setActionValueAtIndex,
                setActionValues: ADRuleExt.setActionValues,
                addActionToRule: ADRuleExt.addActionToRule,
                setValueAtIndexForXPath: ADMenuExt.setValueAtIndexForXPath,
                setValuesForXPath: ADMenuExt.setValuesForXPath,
                getValuesForXPath: ADMenuExt.getValuesForXPath,
                getValueAtIndexForXPath: ADMenuExt.getValueAtIndexForXPath,
                setOperatorForXPath: ADMenuExt.setOperatorForXPath,
                getOperatorForXPath: ADMenuExt.getOperatorForXPath,
                resetForXPath: ADMenuExt.resetForXPath,
                executeRule: ADCommunicationExt.executeRule,
                searchFor: weakThis.searchFor,
                deleteLocalDataSet: weakThis.deleteLocalDataSet
            };
            return exposed;
        };

        BaseComponent.beInheritedBy(ADComp);
        extensions = BaseComponent.initExtensions(names.int, $injector); // methods exposed to the extensions

        ADComp.prototype.acquireVisuPasswordForObj = function acquireVisuPasswordForObj() {
            return ADRuleExt.acquireVisuPasswordForObj.apply(ADRuleExt, arguments);
        };

        ADComp.prototype.recordActions = function recordActions() {
            var def = Q.defer();
            NavigationComp.showState(ScreenState.OnboardingScreen, {
                iconSrc: Icon.TaskRecorderV2.RECORD,
                title: _("automatic-designer.task-recorder.record-action.title"),
                message: _("automatic-designer.task-recorder.desc"),
                continueBtnText: _("automatic-designer.task-recorder.record-action.record"),
                primaryColor: window.Styles.colors.red,
                continueDef: def
            });
            def.promise.then(function (shouldContinue) {
                if (shouldContinue) {
                    NavigationComp.showState(ScreenState.ActiveMiniserverScreen);
                    NavigationComp.startActivity(GUI.ActiveMiniserverViewController.Activity.TaskRecorder, null, {
                        rule: AutomaticDesignerComponent.getCurrentEditRule(),
                        extension: ADRuleExt.getCurrentEditRule().type
                    });
                }
            });
        };

        ADComp.prototype.convertTaskToRule = function convertTaskToRule(task) {
            return ADCommunicationExt.convertTaskToRule(task);
        };

        ADComp.prototype.getCachedVisuPw = function getCachedVisuPw() {
            return ADRuleExt.getCachedVisuPw.apply(ADRuleExt, arguments);
        };

        ADComp.prototype.resetCachedVisuPw = function resetCachedVisuPw() {
            return ADRuleExt.resetCachedVisuPw.apply(ADRuleExt, arguments);
        };

        ADComp.prototype.getAutomaticDesignerUuid = function getAutomaticDesignerUuid() {
            return automaticDesignerUuid;
        };

        ADComp.prototype.getRules = function getRules(controlFilter) {
            Debug.Autopilot.General && console.log(weakThis.name, "getRules");
            var def = Q.defer();

            if (weakThis.fetchingMsRulesDataDef) {
                Debug.Autopilot.General && console.log(weakThis.name, "    fetching rules, wait for promise");
                weakThis.fetchingMsRulesDataDef.promise.then(function (rules) {
                    Debug.Autopilot.General && console.log(weakThis.name, "    fetchingMsRulesDataDef - resolved!");
                    def.resolve(rules);
                });
            } else if (weakThis.rulesFromMs) {
                Debug.Autopilot.General && console.log(weakThis.name, "    rules already fetched, resolve promise");
                def.resolve(weakThis.rulesFromMs);
            } else {
                Debug.Autopilot.General && console.log(weakThis.name, "    not fetched yet, wait for it");

                if (!weakThis.waitingForRulesDef) {
                    weakThis.waitingForRulesDef = Q.defer();
                }

                weakThis.waitingForRulesDef.promise.then(function (rules) {
                    Debug.Autopilot.General && console.log(weakThis.name, "    waitingForRulesDef resolved!");
                    def.resolve(rules);
                });
            }

            return def.promise.then(function (rules) {
                Debug.Autopilot.General && console.log(weakThis.name, "    getRules promise resolved");

                if (!rules) {
                    Debug.Autopilot.General && console.error(weakThis.name, "    no rules  promise resolved");
                    return Q.reject();
                }

                if (controlFilter && controlFilter.length) {
                    Debug.Autopilot.General && console.log(weakThis.name, "    control filter defined, return filtered version of rules");
                    return rules.filter(function (rule) {
                        return _applyFilterToRule(rule, controlFilter);
                    });
                } else {
                    Debug.Autopilot.General && console.log(weakThis.name, "    #nofilter, return rules");
                    return rules;
                }
            });
        };

        ADComp.prototype.getScenes = function getScenes(controlFilter) {
            Debug.Autopilot.General && console.log(this.name, "getScenes");
            var def = Q.defer();

            if (weakThis.fetchingMsScenesDataDef) {
                Debug.Autopilot.General && console.log(this.name, "  already loading, wait for it");
                weakThis.fetchingMsScenesDataDef.promise.then(function (scenes) {
                    def.resolve(scenes);
                });
            } else if (weakThis.scenesFromMs) {
                Debug.Autopilot.General && console.log(this.name, "  already loaded, proceed");
                def.resolve(weakThis.scenesFromMs);
            } else {
                Debug.Autopilot.General && console.log(this.name, "  not loaded yet, wait for download");

                if (!weakThis.waitingForScenesDef) {
                    weakThis.waitingForScenesDef = Q.defer();
                }

                weakThis.waitingForScenesDef.promise.then(function (scenes) {
                    def.resolve(scenes);
                });
            }

            return def.promise.then(function (scenes) {
                Debug.Autopilot.General && console.log(this.name, "  scenes loaded - proceed");

                if (controlFilter && controlFilter.length) {
                    return scenes.filter(function (scene) {
                        return _applyFilterToRule(scene, controlFilter);
                    });
                } else {
                    return scenes;
                }
            }.bind(this));
        };

        ADComp.prototype.getScenesSync = function getScenesSync(controlFilter) {
            if (weakThis.scenesFromMs) {
                if (controlFilter && controlFilter.length) {
                    return weakThis.scenesFromMs.filter(function (scene) {
                        return _applyFilterToRule(scene, controlFilter);
                    });
                } else {
                    return weakThis.scenesFromMs;
                }
            } else {
                return [];
            }
        };

        ADComp.prototype.getRulesSync = function getRulesSync(controlFilter) {
            if (weakThis.scenesFromMs) {
                if (controlFilter && controlFilter.length) {
                    return weakThis.rulesFromMs.filter(function (rule) {
                        return _applyFilterToRule(rule, controlFilter);
                    });
                } else {
                    return weakThis.rulesFromMs;
                }
            } else {
                return [];
            }
        };

        ADComp.prototype.getHistory = function getHistory() {
            return weakThis.rulesHistory;
        };

        ADComp.prototype.registerForRuleChangeNotify = function registerForRuleChangeNotify(callback) {
            weakThis._registeredScreensForRuleChange.push(callback);

            return weakThis._unregisterFromRuleChangedNotify.bind(this, callback);
        };

        ADComp.prototype.getCapabilityForDescID = function getCapabilityForDescID(descID, screenType) {
            var capability = null;

            if (descID) {
                weakThis._currentCapabilitiesFile.entries.find(function (entry) {
                    if (entry.hasOwnProperty(screenType)) {
                        capability = entry[screenType].find(function (cap) {
                            return cap.descID === descID;
                        });
                    }

                    if (capability) {
                        capability._adType = entry.type;
                    }

                    return capability; // returning stops the find iteration
                });
            }

            if (!capability) {
                capability = {
                    name: "",
                    descID: AutomaticDesignerEnums.UNKNOWN_CAPABILITY_DESC_ID
                };
            }

            return capability;
        }; // ----------------------------------------------
        // rule operations
        // ----------------------------------------------


        ADComp.prototype.getRawDataForPath = function getRawDataForPath(path) {
            var splitPath = path.split('.'),
                tmpStruct = weakThis._baseDataSet;

            if (splitPath.length === 1) {
                tmpStruct = tmpStruct[splitPath[0]];
            } else {
                splitPath.forEach(function (entry) {
                    if (tmpStruct[entry].hasOwnProperty('entries')) {
                        tmpStruct = tmpStruct[entry].entries;
                    } else {
                        tmpStruct = tmpStruct[entry];
                    }
                });
            }

            return tmpStruct;
        };

        ADComp.prototype.getPathForItemAndType = function getPathForItemAndType(item, interactionType, itemType) {
            var baseObj = weakThis._baseDataSet[interactionType],
                typeIndex = baseObj.findIndex(function (typeItem) {
                    return typeItem.type === itemType;
                }),
                pathComps = [interactionType, typeIndex];
            baseObj = baseObj[typeIndex];

            if (typeIndex === -1) {
                if (item.hasOwnProperty("groupType")) {
                    // Just append two indexes which represent the selected segment and the index which are not realy needed
                    pathComps.push(0, 0);
                } else {
                    pathComps.push(0, 0);
                }
            } else {
                if (baseObj.hasOwnProperty("entries")) {
                    var finalIdx = baseObj.entries.findIndex(function (entry) {
                        return entry.name === item.name;
                    });

                    if (finalIdx !== -1) {
                        pathComps.push(finalIdx);
                    }
                }
            }

            return pathComps.join(".");
        };
        /**
         * Tries to return a name for a given type and interactionType
         * @note It will also handle GroupTypes as a type!
         * @param type
         * @param interactionType
         * @return {*}
         */


        ADComp.prototype.getNameForType = function getNameForType(type, interactionType) {
            if (Object.values(GroupTypes).includes(type)) {
                var groupTitles = ActiveMSComponent.getStructureManager().getCustomGroupTitles();
                return groupTitles[type];
            } else if (type === "capabilities") {
                if (interactionType === AutomaticDesignerEnums.SCREEN_TYPES.EVENTS) {
                    return _('automatic-designer.rule.events.name_plural');
                } else {
                    return _('actions');
                }
            } else if (type === "roomsOrCats") {
                return _("functions");
            } else {
                var wantedObj = weakThis._baseDataSet[interactionType].find(function (entry) {
                    return entry.type === type;
                });

                if (wantedObj) {
                    return wantedObj.name;
                }
            }
        };
        /**
         * Tries to return a name for a given type and interactionType
         * @note It will also handle GroupTypes as a type!
         * @param type
         * @param interactionType
         * @return {*}
         */


        ADComp.prototype.getNameForEntryType = function getNameForType(type, interactionType) {
            var wantedObj = weakThis._baseDataSet[interactionType].find(function (entry) {
                return entry.type === type;
            });

            if (wantedObj) {
                if (wantedObj.hasOwnProperty("uuidAction")) {
                    return wantedObj.getName();
                }

                return wantedObj.name;
            }
        };
        /**
         * Tries to return a name for a given type and interactionType
         * @note It will also handle GroupTypes as a type!
         * @param type
         * @param interactionType
         * @return {*}
         */


        ADComp.prototype.getIconForEntryType = function getIconForEntryType(type, interactionType) {
            var wantedObj = weakThis._baseDataSet[interactionType].find(function (entry) {
                return entry.type === type;
            });

            if (wantedObj) {
                return wantedObj.iconSrc;
            }
        };

        ADComp.prototype.getDataFromCapabilities = function getDataFromCapabilities(filter) {
            var data = [],
                filterNode;

            if (weakThis._currentCapabilitiesFile && weakThis._currentCapabilitiesFile.hasOwnProperty("entries")) {
                weakThis._currentCapabilitiesFile.entries.forEach(function (entry) {
                    if (isNaN(filter)) {
                        filterNode = 'controlType';
                    } else {
                        filterNode = 'intType';
                    }

                    entry[filterNode].forEach(function (entryIntType) {
                        if (entryIntType === filter) {
                            data.push(entry);
                        }
                    });
                });
            }

            return cloneObjectDeep(data);
        };
        /**
         * Returns an array of int types available for the given type
         * @param type
         * @param screenType
         * @return {*[]}
         */


        ADComp.prototype.getAvailableIntTypesForType = function getAvailableIntTypesForType(type, screenType) {
            var wantedTypes = weakThis._currentCapabilitiesFile.entries.filter(function (entries) {
                return entries.controlType.includes(type) && entries.hasOwnProperty(screenType) && entries[screenType].length;
            });

            return [].concat.apply([], wantedTypes.map(function (capability) {
                return capability.intType;
            }));
        };

        ADComp.prototype.getUsedGroupsUuidsByType = function getUsedGroupsUuidsByType(type, screenType) {
            return weakThis._usedGroups[screenType][type] || [];
        };
        /**
         * Searches with the given phrase in the given searchIndexTypes (events|actions) in the given sectionTypes
         * @param phrase
         * @param searchIndexType
         * @param [sectionTypes]
         */


        ADComp.prototype.searchFor = function searchFor(phrase, searchIndexType, sectionTypes) {
            var wantedIndexCategory = weakThis._searchIndex[searchIndexType],
                result = {};
            sectionTypes = sectionTypes || Object.keys(wantedIndexCategory);
            sectionTypes.forEach(function (sectionType) {
                result[sectionType] = wantedIndexCategory[sectionType].lookup(phrase);
            });
            return SearchUtils.Index.prepareResult(result, weakThis.MIN_SEARCH_WEIGHT, true);
        }; // ----------------------------------------------
        // private functions
        // ----------------------------------------------


        ADComp.prototype._prepareBaseDataSet = function _prepareBaseDataSet() {
            // for easier navigation use an array --> we can easily call the index
            weakThis._baseDataSet = {
                events: [],
                actions: []
            };
            var functionsIsLegacy = !!weakThis._currentStructureFile.events.find(function (event) {
                return event.type === AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.ROOMS.STRING || event.type === AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.ROOMS.STRING;
            }); // Reset the groups

            Object.values(GroupTypes).forEach(function (groupType) {
                weakThis._usedGroups.events[groupType] = [];
                weakThis._usedGroups.actions[groupType] = [];
            });

            weakThis._currentStructureFile.events.forEach(function (event) {
                var iconType = event.type,
                    iconSrc;

                if (iconType === AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.ROOMS.STRING || iconType === AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.CATS.STRING) {
                    iconType = AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.FUNCTIONS.STRING;
                }

                iconSrc = "resources/Images/ActiveMiniserver/AutomaticDesigner/entryTypes/" + iconType + ".svg";

                if (ResourceImageCache[iconSrc]) {
                    event.iconSrc = iconSrc;
                }

                switch (event.type) {
                    case AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.TIMES.STRING:
                        weakThis._baseDataSet.events.push(event);

                        _populateSearchIndexForGenericType(event, AutomaticDesignerEnums.SCREEN_TYPES.EVENTS);

                        break;

                    case AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.ROOMS.STRING:
                    case AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.CATS.STRING:
                        var controlBaseData = ADMenuExt.getControlsForRoomOrCatsBaseDataSet(event, AutomaticDesignerEnums.SCREEN_TYPES.EVENTS);

                        weakThis._baseDataSet.events.push(controlBaseData);

                        _populateSearchIndexFunctionForRoomsOrCatsType(controlBaseData, AutomaticDesignerEnums.SCREEN_TYPES.EVENTS);

                        break;

                    case AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.FUNCTIONS.STRING:
                        var controlLegacyBaseData = ADMenuExt.getControlsLegacyBaseDataSet(event, AutomaticDesignerEnums.SCREEN_TYPES.EVENTS);
                        controlLegacyBaseData.isLegacy = functionsIsLegacy;

                        if (!controlLegacyBaseData.isLegacy) {
                            weakThis._baseDataSet.events.push(controlLegacyBaseData);

                            _populateSearchIndexFunctionLegacyType(controlLegacyBaseData, AutomaticDesignerEnums.SCREEN_TYPES.EVENTS);

                            _populateUsedGroupsForDataSetAndScreenType(controlLegacyBaseData, AutomaticDesignerEnums.SCREEN_TYPES.EVENTS);
                        }

                        break;

                    case AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.WEATHER.STRING:
                        var baseDataSet = ADMenuExt.getWeatherBaseDataSet(event, AutomaticDesignerEnums.SCREEN_TYPES.EVENTS);

                        weakThis._baseDataSet.events.push(baseDataSet);

                        _populateSearchIndexForGenericType(baseDataSet, AutomaticDesignerEnums.SCREEN_TYPES.EVENTS);

                        break;

                    case AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.OPERATING_MODES.STRING:
                        weakThis._baseDataSet.events.push(ADMenuExt.getOperatingModesBaseDataSet(event, AutomaticDesignerEnums.SCREEN_TYPES.EVENTS));

                        _populateSearchIndexForGenericType(weakThis._baseDataSet.events[weakThis._baseDataSet.events.length - 1], AutomaticDesignerEnums.SCREEN_TYPES.EVENTS);

                        break;
                }
            });

            weakThis._currentStructureFile.actions.forEach(function (action) {
                var iconType = action.type,
                    iconSrc;

                if (iconType === AutomaticDesignerEnums.KNOWN_TYPES.ACTIONS.ROOMS.STRING || iconType === AutomaticDesignerEnums.KNOWN_TYPES.ACTIONS.CATS.STRING) {
                    iconType = AutomaticDesignerEnums.KNOWN_TYPES.ACTIONS.FUNCTIONS.STRING;
                }

                iconSrc = "resources/Images/ActiveMiniserver/AutomaticDesigner/entryTypes/" + iconType + ".svg";

                if (ResourceImageCache[iconSrc]) {
                    action.iconSrc = iconSrc;
                }

                switch (action.type) {
                    case AutomaticDesignerEnums.KNOWN_TYPES.ACTIONS.OPERATING_MODES.STRING:
                        weakThis._baseDataSet.actions.push(ADMenuExt.getOperatingModesBaseDataSet(action, AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS));

                        _populateSearchIndexForGenericType(weakThis._baseDataSet.actions[weakThis._baseDataSet.actions.length - 1], AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS);

                        break;

                    case AutomaticDesignerEnums.KNOWN_TYPES.ACTIONS.ROOMS.STRING:
                    case AutomaticDesignerEnums.KNOWN_TYPES.ACTIONS.CATS.STRING:
                        var controlBaseData = ADMenuExt.getControlsForRoomOrCatsBaseDataSet(action, AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS);

                        weakThis._baseDataSet.actions.push(controlBaseData);

                        _populateSearchIndexFunctionForRoomsOrCatsType(controlBaseData, AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS);

                        break;

                    case AutomaticDesignerEnums.KNOWN_TYPES.ACTIONS.FUNCTIONS.STRING:
                        var controlLegacyBaseData = ADMenuExt.getControlsLegacyBaseDataSet(action, AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS);
                        controlLegacyBaseData.isLegacy = functionsIsLegacy;

                        if (!controlLegacyBaseData.isLegacy) {
                            weakThis._baseDataSet.actions.push(controlLegacyBaseData);

                            _populateSearchIndexFunctionLegacyType(controlLegacyBaseData, AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS);

                            _populateUsedGroupsForDataSetAndScreenType(controlLegacyBaseData, AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS);
                        }

                        break;

                    case AutomaticDesignerEnums.KNOWN_TYPES.ACTIONS.NOTIFICATIONS.STRING:
                        weakThis._baseDataSet.actions.push(ADMenuExt.getNotificationBaseDataSet(action));

                        _populateSearchIndexForGenericType(weakThis._baseDataSet.actions[weakThis._baseDataSet.actions.length - 1], AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS);

                        break;

                    case AutomaticDesignerEnums.KNOWN_TYPES.ACTIONS.SCENES.STRING:
                        weakThis._baseDataSet.actions.push(ADMenuExt.getScenesBaseDataSet(action));

                        _populateSearchIndexForGenericType(weakThis._baseDataSet.actions[weakThis._baseDataSet.actions.length - 1], AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS);

                        break;

                    case AutomaticDesignerEnums.KNOWN_TYPES.ACTIONS.SCRIPTS.STRING:
                        weakThis._baseDataSet.actions.push(action);

                        _populateSearchIndexForGenericType(action, AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS);

                        break;
                }
            });
        };

        ADComp.prototype._receivedStates = function _receivedStates(states) {
            // verify rules
            if (states.changeDate !== this.rulesVersion) {
                this.currMsRulesVersion = states.changeDate;

                this._loadOrFetchRules(states.changeDate).then(function (rules) {
                    this.rulesVersion = states.changeDate;
                    this.rulesFromMs = rules;
                }.bind(this));
            } // verify scenes


            if (states.changeDate !== this.scenesVersion) {
                this.currMsScenesVersion = states.changeDate;

                this._loadOrFetchScenes(states.changeDate).then(function (scenes) {
                    this.scenesVersion = states.changeDate;
                    this.scenesFromMs = scenes;
                }.bind(this));
            }

            this.rulesHistory = states.historyEntries;
        }; // ---------- NEW rule & scene handling


        ADComp.prototype._loadOrFetchRules = function _loadOrFetchRules(version) {
            return this._loadAndCheckRules(version).then(function (rules) {
                return rules;
            }.bind(this), function (err) {
                return this._downloadRulesFromMs(version);
            }.bind(this)).then(function (rules) {
                this._handleRulesReady(rules);

                return rules;
            }.bind(this));
        };

        ADComp.prototype._downloadRulesFromMs = function _downloadRulesFromMs(version) {
            // don't recreate the deferred, leave one to resolve, even if two download calls are made one after the other.
            // if a second download is started, the result of the first download will be ignored.
            this.fetchingMsRulesDataDef = this.fetchingMsRulesDataDef || Q.defer();
            ADCommunicationExt.fetchRulesFromMS(AutomaticDesignerEnums.RULE_TYPE.RULE).then(function (rules) {
                if (version !== this.currMsRulesVersion) {
                    // compare with the one from "this" to verify it's still up to date.
                    return;
                }

                this.rulesFromMs = rules;
                this.fetchingMsRulesDataDef.resolve(this.rulesFromMs);
                this.fetchingMsRulesDataDef = null;

                this._saveRules(this.rulesFromMs, version);

                this._notifyRegisteredScreensForRuleChanged(this.rulesFromMs);
            }.bind(this), function (err) {
                if (version !== this.currMsRulesVersion) {
                    // compare with the one from "this" to verify it's still up to date.
                    return;
                }

                console.error(this.name, err);
                this.fetchingMsRulesDataDef.reject(err);
                this.fetchingMsRulesDataDef = null;

                if (this.waitingForRulesDef) {
                    this.waitingForRulesDef.reject(err);
                    this.waitingForRulesDef = null;
                }
            }.bind(this));
            return this.fetchingMsRulesDataDef.promise;
        };

        ADComp.prototype._handleRulesReady = function _handleRulesReady(rules) {
            if (this.waitingForRulesDef) {
                this.waitingForRulesDef.resolve(rules);
                this.waitingForRulesDef = null;
            }
        };

        ADComp.prototype._loadOrFetchScenes = function _loadOrFetchScenes(version) {
            return this._loadAndCheckScenes(version).then(function (scenes) {
                return scenes;
            }.bind(this), function () {
                return this._downloadScenesFromMs(version);
            }.bind(this)).then(function (scenes) {
                this._handleScenesReady(scenes);

                return scenes;
            }.bind(this));
        };

        ADComp.prototype._downloadScenesFromMs = function _downloadScenesFromMs(version) {
            // don't recreate the deferred, leave one to resolve, even if two download calls are made one after the other.
            // if a second download is started, the result of the first download will be ignored.
            this.fetchingMsScenesDataDef = this.fetchingMsScenesDataDef || Q.defer();

            if (Feature.AUTOMATIC_DESIGNER_SCENES) {
                ADCommunicationExt.fetchRulesFromMS(AutomaticDesignerEnums.RULE_TYPE.SCENE).then(function (scenes) {
                    if (version !== this.currMsScenesVersion) {
                        // compare with the one from "this" to verify it's still up to date.
                        return;
                    }

                    this.scenesFromMs = scenes;
                    this.fetchingMsScenesDataDef.resolve(this.scenesFromMs);
                    this.fetchingMsScenesDataDef = null;

                    this._saveScenes(this.scenesFromMs, version);

                    this.registerForRuleChangeNotify(this.rulesFromMs, this.scenesFromMs);
                }.bind(this), function (err) {
                    if (version !== this.currMsScenesVersion) {
                        // compare with the one from "this" to verify it's still up to date.
                        return;
                    }

                    console.error(this.name, err);
                    this.fetchingMsScenesDataDef.reject(err);
                    this.fetchingMsScenesDataDef = null;

                    if (this.waitingForScenesDef) {
                        this.waitingForScenesDef.reject(err);
                        this.waitingForScenesDef = null;
                    }
                }.bind(this));
            } else {
                // not supported, return empty
                this.fetchingMsScenesDataDef.resolve([]);
            }

            return this.fetchingMsScenesDataDef.promise;
        };

        ADComp.prototype.mergeSceneWithDataSet = function mergeSceneWithDataSet(editedSceneObj) {
            var editedSceneIdx;
            editedSceneIdx = weakThis.scenesFromMs.findIndex(function (scene) {
                return scene.id === editedSceneObj.id;
            });
            weakThis.scenesFromMs.splice(editedSceneIdx, 1, editedSceneObj);
        };

        ADComp.prototype.mergeRuleWithDataSet = function mergeRuleWithDataSet(editedRuleObj) {
            var editedSceneIdx;
            editedSceneIdx = weakThis.scenesFromMs.findIndex(function (rule) {
                return rule.id === editedRuleObj.id;
            });
            weakThis.rulesFromMs.splice(editedSceneIdx, 1, editedRuleObj);
        };

        ADComp.prototype.deleteLocalDataSet = function deleteLocalDataSet(isScene) {
            var def;

            if (isScene) {
                weakThis.scenesFromMs = null;
                weakThis.fetchingMsScenesDataDef = Q.defer();
                def = weakThis.fetchingMsScenesDataDef;
            } else {
                weakThis.rulesFromMs = null;
                weakThis.fetchingMsRulesDataDef = Q.defer();
                def = weakThis.fetchingMsRulesDataDef;
            }

            NavigationComp.showWaitingFor(def.promise);
        };

        ADComp.prototype._handleScenesReady = function _handleScenesReady(scenes) {
            if (this.waitingForScenesDef) {
                this.waitingForScenesDef.resolve(scenes);
                this.waitingForScenesDef = null;
            }
        };

        ADComp.prototype._unregisterFromRuleChangedNotify = function _unregisterFromRuleChangedNotify(callback) {
            var callbackIndex = weakThis._registeredScreensForRuleChange.indexOf(callback);

            if (callbackIndex >= 0) {
                weakThis._registeredScreensForRuleChange.splice(callbackIndex, 1);
            }
        };

        ADComp.prototype._notifyRegisteredScreensForRuleChanged = function _notifyRegisteredScreensForRuleChanged(results) {
            weakThis._registeredScreensForRuleChange.forEach(function (callback) {
                if (typeof callback === "function") {
                    callback(results);
                }
            });
        };

        ADComp.prototype._checkCachedFiles = function _checkCachedFiles() {
            return Q.all([weakThis._checkAndLoadSectionsJson(), weakThis._checkAndLoadControlsJson()]).then(function () {
                try {
                    weakThis._prepareBaseDataSet();
                } catch (e) {
                    console.warn("AutomaticDesignerComp can't process base data set");
                    console.error(e);
                }
            });
        };

        ADComp.prototype._checkAndLoadSectionsJson = function _checkAndLoadSectionsJson() {
            return weakThis._load(AutomaticDesignerEnums.SECTIONS_VERSION_FILE_NAME, DataType.STRING).then(function (version) {
                return ADCommunicationExt.getSectionsFileVersionFromMS().then(function (versionFromMS) {
                    if (version === versionFromMS) {
                        return weakThis._load(AutomaticDesignerEnums.SECTIONS_FILE_NAME, DataType.OBJECT).then(function (sections) {
                            weakThis._currentStructureFile = sections;
                        });
                    } else {
                        return weakThis._loadSectionsJson();
                    }
                });
            }.bind(this), function (err) {
                return weakThis._loadSectionsJson();
            });
        };

        ADComp.prototype._loadSectionsJson = function _loadSectionsJson() {
            return ADCommunicationExt.getSectionsFileFromMS().then(function (sections) {
                weakThis._save(AutomaticDesignerEnums.SECTIONS_VERSION_FILE_NAME, sections.version, DataType.STRING);

                weakThis._save(AutomaticDesignerEnums.SECTIONS_FILE_NAME, sections, DataType.OBJECT);

                weakThis._currentStructureFile = sections;
                weakThis._currentStructureFile.events = sortArrByFields(weakThis._currentStructureFile.events, ["name"]);
                weakThis._currentStructureFile.events = weakThis._currentStructureFile.events.map(function (event) {
                    if (event.hasOwnProperty("entries")) {
                        event.entries = sortArrByFields(event.entries, ["name"]);
                    }

                    return event;
                });
                weakThis._currentStructureFile.actions = sortArrByFields(weakThis._currentStructureFile.actions, ["name"]);
                weakThis._currentStructureFile.actions = weakThis._currentStructureFile.actions.map(function (action) {
                    if (action.hasOwnProperty("entries")) {
                        action.entries = sortArrByFields(action.entries, ["name"]);
                    }

                    return action;
                });
            });
        };

        ADComp.prototype._checkAndLoadControlsJson = function _checkAndLoadControlsJson() {
            return weakThis._load(AutomaticDesignerEnums.CAPABILITIES_VERSION_FILE_NAME, DataType.STRING).then(function (version) {
                return ADCommunicationExt.getCapabilitiesFileVersionFromMS().then(function (versionFromMS) {
                    if (version === versionFromMS) {
                        return weakThis._load(AutomaticDesignerEnums.CAPABILITIES_FILE_NAME, DataType.OBJECT).then(function (capabilities) {
                            weakThis._currentCapabilitiesFile = capabilities;
                        });
                    } else {
                        return weakThis._loadControlsJson();
                    }
                });
            }.bind(this), function (err) {
                return weakThis._loadControlsJson();
            });
        };

        ADComp.prototype._loadControlsJson = function _loadControlsJson() {
            return ADCommunicationExt.getCapabilitiesFileFromMS().then(function (capabilities) {
                weakThis._currentCapabilitiesFile = capabilities;
                weakThis._currentCapabilitiesFile.entries = weakThis._currentCapabilitiesFile.entries.filter(function (capability) {
                    if (typeof capability.type === "undefined") {
                        if (capability.hasOwnProperty("controlType")) {
                            capability.type = AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.FUNCTIONS.INT[0];
                        } else {
                            developerAttention("Capability misses type property! -> " + (capability.name || capability.objName) + ": " + JSON.stringify(capability));
                        }
                    }

                    return typeof capability.type === "number";
                });

                try {
                    // Adopt the caller capability to add our callers to the file
                    var availableCallers = Object.values(ActiveMSComponent.getStructureManager().getCallerServices()),
                        callerObject = weakThis._currentCapabilitiesFile.entries.find(function (entry) {
                            return entry.controlType.includes("caller");
                        }),
                        callerCapabilityDetail = callerObject.actions[0].details.find(function (detail) {
                            return detail.hasOwnProperty("source") && detail.source === "callers";
                        });

                    if (callerCapabilityDetail) {
                        callerCapabilityDetail.values = availableCallers.map(function (caller) {
                            return {
                                id: caller.id,
                                name: caller.name
                            };
                        });
                    }
                } catch (e) {
                    // Don't need to do anything
                    console.error(e);
                    console.warn("Error while adopting the capabilities file for Caller");
                }

                try {
                    // Adopt the audioZoneV2 favorite capabilities to be able to show the favorites as a select
                    var audioZoneV2Object = weakThis._currentCapabilitiesFile.entries.find(function (entry) {
                            return entry.controlType.includes("AudioZoneV2");
                        }),
                        audioZoneV2FavCapabilityAction = audioZoneV2Object.actions.find(function (action) {
                            return action.descID === AutomaticDesignerEnums.KNOWN_DESC_IDS.AUDIO_ZONE_V2_FAV;
                        });

                    audioZoneV2FavCapabilityAction.details = [{
                        type: AutomaticDesignerEnums.ENTRY_TYPES.ANALOG_SINGLE_SELECT,
                        min: 0,
                        max: 255,
                        sourceState: "favorites",
                        defaultText: "..."
                    }];
                } catch (e) {
                    // Don't need to do anything
                    console.error(e);
                    console.warn("Error while adopting the capabilities file for AudioZoneV2");
                } // Sort everything!


                capabilities.entries = capabilities.entries.map(function (capabilityEntry, idx) {
                    if (capabilityEntry.hasOwnProperty("events")) {
                        capabilityEntry.events = sortArrByFields(capabilityEntry.events, ["name"]);
                    }

                    if (capabilityEntry.hasOwnProperty("actions")) {
                        capabilityEntry.actions = sortArrByFields(capabilityEntry.actions, ["name"]);
                    }

                    return capabilityEntry;
                });

                weakThis._save(AutomaticDesignerEnums.CAPABILITIES_VERSION_FILE_NAME, capabilities.version, DataType.STRING);

                weakThis._save(AutomaticDesignerEnums.CAPABILITIES_FILE_NAME, capabilities, DataType.OBJECT);
            });
        }; // ----- Persisting & Loading Scenes/Rules


        ADComp.prototype._saveScenes = function _saveScenes(scenes, version) {
            var storageObj = {
                version: version,
                scenes: scenes
            };
            return weakThis._save(AutomaticDesignerEnums.SCENES_FILE_NAME, storageObj, DataType.OBJECT);
        };

        ADComp.prototype._loadAndCheckScenes = function _loadAndCheckScenes(verifyVersion) {
            return this._load(AutomaticDesignerEnums.SCENES_FILE_NAME, DataType.OBJECT).then(function (storageObj) {
                if (storageObj.version !== verifyVersion) {
                    return Q.reject();
                } else {
                    return storageObj.scenes;
                }
            }.bind(this));
        };

        ADComp.prototype._saveRules = function _saveRules(rules, version) {
            var storageObj = {
                version: version,
                rules: rules
            };
            return this._save(AutomaticDesignerEnums.RULES_FILE_NAME, storageObj, DataType.OBJECT);
        };

        ADComp.prototype._loadAndCheckRules = function _loadAndCheckRules(verifyVersion) {
            return this._load(AutomaticDesignerEnums.RULES_FILE_NAME, DataType.OBJECT).then(function (storageObj) {
                if (storageObj.version !== verifyVersion) {
                    return Q.reject();
                } else {
                    return storageObj.rules;
                }
            }.bind(this));
        }; // ----- General Persisting & Loading


        ADComp.prototype._save = function _save(filename, payload, type) {
            var fn = _getMsSpecificFileName(filename);

            return PersistenceComponent.saveFile(fn, payload, type);
        };

        ADComp.prototype._load = function _load(filename, type) {
            var fn = _getMsSpecificFileName(filename);

            return PersistenceComponent.loadFile(fn, type);
        }; // ----- Other private methods


        var _populateSearchIndexForGenericType = function _populateSearchIndexForGenericType(genericTypeItem, indexType) {
            if (genericTypeItem.hasOwnProperty("showAdFunc") && genericTypeItem.showAdFunc(true)) {
                // Don't index genericTypes with intercept function as those should be intercepted instead of presented within the search to present their Ads
                return;
            }

            weakThis._searchIndex[indexType][genericTypeItem.type] = new SearchUtils.Index(genericTypeItem.type);
            genericTypeItem.entries.forEach(function (item) {
                var uuid = prepareAsUuid(JSON.stringify(item));

                weakThis._searchIndex[indexType][genericTypeItem.type].addItem(uuid, item.name, item.desc || item.description, item); // keywords is an optional property provided by the miniserver


                if (item.hasOwnProperty("keywords")) {
                    item.keywords.forEach(function (keyword) {
                        weakThis._searchIndex[indexType][genericTypeItem.type].addKeyword(keyword, uuid);
                    });
                }

                _populateCapabilitySearchIndexForGenericTypeItem(genericTypeItem, item, indexType);
            });
        };

        var _populateSearchIndexFunctionForRoomsOrCatsType = function _populateSearchIndexFunctionForRoomsOrCatsType(controlTypeItem, indexType) {
            controlTypeItem.entries.forEach(function (group) {
                if (!weakThis._searchIndex[indexType].hasOwnProperty("roomsOrCats")) {
                    weakThis._searchIndex[indexType]["roomsOrCats"] = new SearchUtils.ControlIndex();
                }

                if (!weakThis._searchIndex[indexType].hasOwnProperty(GroupTypes.ROOM)) {
                    weakThis._searchIndex[indexType][GroupTypes.ROOM] = new SearchUtils.Index(SearchLocation.ROOMS);
                }

                if (!weakThis._searchIndex[indexType].hasOwnProperty(GroupTypes.CATEGORY)) {
                    weakThis._searchIndex[indexType][GroupTypes.CATEGORY] = new SearchUtils.Index(SearchLocation.CATEGORIES);
                }

                weakThis._searchIndex[indexType][group.groupType].addItem(group.uuid, group.name, null, group);

                ActiveMSComponent.getStructureManager().getControlsInGroup(group.groupType, group.uuid).forEach(function (control) {
                    if (control.isAutomaticScene) {
                        return;
                    }

                    weakThis._searchIndex[indexType]["roomsOrCats"].addControl(control);

                    _populateCapabilitySearchIndexForFunctionTypeItem("roomsOrCats", control, indexType);
                });
            });
        };

        var _populateSearchIndexFunctionLegacyType = function _populateSearchIndexFunctionLegacyType(controlTypeItem, indexType) {
            if (!weakThis._searchIndex[indexType].hasOwnProperty(controlTypeItem.type)) {
                weakThis._searchIndex[indexType][controlTypeItem.type] = new SearchUtils.ControlIndex();
            }

            if (!weakThis._searchIndex[indexType].hasOwnProperty(GroupTypes.ROOM)) {
                weakThis._searchIndex[indexType][GroupTypes.ROOM] = new SearchUtils.Index(SearchLocation.ROOMS);
            }

            if (!weakThis._searchIndex[indexType].hasOwnProperty(GroupTypes.CATEGORY)) {
                weakThis._searchIndex[indexType][GroupTypes.CATEGORY] = new SearchUtils.Index(SearchLocation.CATEGORIES);
            }

            controlTypeItem.entries.forEach(function (control) {
                // Don't index Scenes as controls, they have its own category
                if (control.isAutomaticScene) {
                    return;
                }

                var room = ActiveMSComponent.getStructureManager().getGroupByUUID(control.room, GroupTypes.ROOM),
                    cat = ActiveMSComponent.getStructureManager().getGroupByUUID(control.cat, GroupTypes.CATEGORY);

                weakThis._searchIndex[indexType][controlTypeItem.type].addControl(control);

                if (room) {
                    weakThis._searchIndex[indexType][GroupTypes.ROOM].addItem(room.uuid, room.name, null, room);
                }

                if (cat) {
                    weakThis._searchIndex[indexType][GroupTypes.CATEGORY].addItem(cat.uuid, cat.name, null, cat);
                }

                _populateCapabilitySearchIndexForFunctionTypeItem(controlTypeItem, control, indexType);
            });
        };

        var _populateCapabilitySearchIndexForGenericTypeItem = function _populateCapabilitySearchIndexForGenericTypeItem(typeItem, specificEntry, indexType) {
            if (specificEntry.hasOwnProperty("showAdFunc") && specificEntry.showAdFunc(true)) {
                // Don't index capabilities with intercept function as those should be intercepted instead of presented within the search to present their Ads
                return;
            }

            if (!weakThis._searchIndex[indexType].hasOwnProperty("capabilities")) {
                weakThis._searchIndex[indexType].capabilities = new SearchUtils.Index("capabilities");
            } // get all available capabilities for the given type


            var wantedIntTypes = [],
                relevantCapabilityEntries;

            if (specificEntry.hasOwnProperty("entries")) {
                wantedIntTypes = [].concat.apply([], specificEntry.entries.map(function (entry) {
                    return entry.intType;
                }));
            } else if (specificEntry.hasOwnProperty("intType")) {
                wantedIntTypes = specificEntry.intType;
            } else if (specificEntry.hasOwnProperty("details") && typeof specificEntry.details.type === "number") {
                wantedIntTypes = [specificEntry.details.type];
            }

            relevantCapabilityEntries = [].concat.apply([], weakThis._currentCapabilitiesFile.entries.filter(function (capability) {
                return capability.intType.hasCommonEntries(wantedIntTypes);
            }).map(function (capability) {
                return capability[indexType] || [];
            }));
            if (specificEntry.type === AutomaticDesignerEnums.KNOWN_TYPES.ACTIONS.SCENES.STRING) {
                relevantCapabilityEntries = relevantCapabilityEntries.filter(capability => {
                    return ActiveMSComponent.getStructureManager().getControlByUUID(specificEntry.uuidAction).meetsCapabilityConditions(capability);
                });
            }        relevantCapabilityEntries.forEach(function (capabilityEntry) {
                var uuid = prepareAsUuid(capabilityEntry.descID + JSON.stringify(specificEntry));

                weakThis._searchIndex[indexType].capabilities.addItem(uuid, capabilityEntry.name, capabilityEntry.desc || capabilityEntry.description, {
                    sourceItem: specificEntry,
                    capability: capabilityEntry
                });

                _addFurtherCapabilityKeywordsToSearchIndex(weakThis._searchIndex[indexType].capabilities, capabilityEntry, uuid, specificEntry);
            });
        };

        var _populateCapabilitySearchIndexForFunctionTypeItem = function _populateCapabilitySearchIndexForFunctionTypeItem(typeItem, specificEntry, indexType) {
            if (!weakThis._searchIndex[indexType].hasOwnProperty("capabilities")) {
                weakThis._searchIndex[indexType].capabilities = new SearchUtils.Index("capabilities");
            } // get all available capabilities for the given control


            var relevantCapabilityEntries = [].concat.apply([], weakThis._currentCapabilitiesFile.entries.filter(function (capability) {
                return capability.controlType.includes(specificEntry.type);
            }).map(function (capability) {
                return capability[indexType] || [];
            }));
            relevantCapabilityEntries.forEach(function (capabilityEntry) {
                if (!specificEntry.meetsCapabilityConditions(capabilityEntry)) {
                    return;
                }

                var uuid = specificEntry.uuidAction + "_" + capabilityEntry.descID; // use it as it is unique per capability

                weakThis._searchIndex[indexType].capabilities.addItem(uuid, capabilityEntry.name, capabilityEntry.desc || capabilityEntry.description, {
                    sourceItem: specificEntry,
                    capability: capabilityEntry
                });

                weakThis._searchIndex[indexType].capabilities.addKeyword(specificEntry.getName(), uuid);

                if (specificEntry.getRoom()) {
                    weakThis._searchIndex[indexType].capabilities.addKeyword(specificEntry.getRoom().name, uuid);
                }

                if (specificEntry.getCategory()) {
                    weakThis._searchIndex[indexType].capabilities.addKeyword(specificEntry.getCategory().name, uuid);
                }

                weakThis._searchIndex[indexType].capabilities.addKeyword(specificEntry.getControlTypeName(), uuid);

                _addFurtherCapabilityKeywordsToSearchIndex(weakThis._searchIndex[indexType].capabilities, capabilityEntry, uuid, specificEntry);
            });
        };

        var _addFurtherCapabilityKeywordsToSearchIndex = function _addFurtherCapabilityKeywordsToSearchIndex(searchIdx, capability, uuid, specificEntry) {
            var nameParts = capability.name.replace(/\\n/g, " ").split(" ");
            nameParts.forEach(function (part) {
                searchIdx.addKeyword(part, uuid);
            });

            if (specificEntry.hasOwnProperty("name")) {
                searchIdx.addKeyword(specificEntry.name, uuid);
            }

            if (capability.hasOwnProperty("keywords")) {
                capability.keywords.forEach(function (keyword) {
                    searchIdx.addKeyword(keyword, uuid);
                });
            }

            if (capability.hasOwnProperty("details")) {
                capability.details.forEach(function (detail) {
                    if (detail.hasOwnProperty("values")) {
                        detail.values.forEach(function (value) {
                            searchIdx.addKeyword(value.name, uuid);
                        });
                    } else if (detail.hasOwnProperty("sourceState") && specificEntry.hasOwnProperty("uuidAction")) {
                        var stateContainer = SandboxComponent.getStateContainerForUUID(specificEntry.uuidAction),
                            values;

                        if (stateContainer) {
                            values = stateContainer.getAutomaticDesignerStateObjectsFromState(detail.sourceState);
                        }

                        if (values) {
                            values.forEach(function (value) {
                                searchIdx.addKeyword(value.name, uuid);
                            });
                        }
                    }
                });
            }
        };

        var _applyFilterToRule = function _applyFilterToRule(rule, controlFilter) {
            var filterUuids = controlFilter.map(function (control) {
                    return control.uuidAction;
                }),
                controlIsInEvents = (rule.eventGroups || []).map(function (group) {
                    if (group.hasOwnProperty("events")) {
                        return group.events.map(function (event) {
                            if (event.type === AutomaticDesignerEnums.EVENT_TYPES.CONTROL) {
                                return filterUuids.includes(event.controlUUID || event.id);
                            } else {
                                return false;
                            }
                        }).reduce(function (left, right) {
                            return left || right;
                        }, false);
                    } else {
                        return false;
                    }
                }).reduce(function (left, right) {
                    return left || right;
                }, false),
                controlIsInActions = (rule.actions || []).map(function (action) {
                    if (action.type === AutomaticDesignerEnums.EVENT_TYPES.CONTROL) {
                        return filterUuids.includes(action.controlUUID || action.id);
                    } else {
                        return false;
                    }
                }).reduce(function (left, right) {
                    return left || right;
                }, false);
            return controlIsInEvents || controlIsInActions;
        };

        var _populateUsedGroupsForDataSetAndScreenType = function _populateUsedGroupsForDataSetAndScreenType(dataSet, screenType) {
            dataSet.entries.forEach(function (control) {
                if (control.room) {
                    weakThis._usedGroups[screenType][GroupTypes.ROOM].pushIfNoDuplicate(control.room);
                }

                if (control.cat) {
                    weakThis._usedGroups[screenType][GroupTypes.CATEGORY].pushIfNoDuplicate(control.cat);
                }
            });
        };

        var _resetSearchIndexes = function _resetSearchIndexes() {
            Object.values(AutomaticDesignerEnums.SCREEN_TYPES).forEach(function (type) {
                Object.values(weakThis._searchIndex[type]).forEach(function (searchIdx) {
                    searchIdx.destroy();
                });
            });
            weakThis._searchIndex = {};
            weakThis._searchIndex[AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS] = {};
            weakThis._searchIndex[AutomaticDesignerEnums.SCREEN_TYPES.EVENTS] = {};
        };

        var _getMsSpecificFileName = function _getMsSpecificFileName(fileNameBase) {
            return fileNameBase + "_" + ActiveMSComponent.getActiveMiniserver().serialNo;
        };

        window[names.ext] = new ADComp();
        return window[names.ext];
    }]);
}
