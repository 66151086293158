'use strict';

import Icons from "IconLib";
import {
    LxReactCircularProgressbar
} from "LxComponents"
import globalStyles from "GlobalStyles";
import TrustManager from "./TrustUserManagement/TrustManager";

ActiveMSComp.factory('UserManagementExt', function () {
    // internal variables
    var activeMsComp = {},
        //usergroups = null,
        users = null,
        userPropOptions = null, // e.g. list of departments used
        customFieldCaptions = {};

    /**
     * c-tor for User Management Extension
     * @param comp reference to the ActiveMSComponent
     * @constructor
     */

    function UserManagementExt(comp) {
        activeMsComp = comp;
        activeMsComp.on(ActiveMSComp.ECEvent.STRUCTURE_READY, function (ev, newStructure) {
            if (newStructure) {
                //usergroups = null;
                users = null;

                // Just like the users, the customField-captions may change every time one saves into the ms.
                customFieldCaptions = null;
                userPropOptions = null;
            }
        });
        this.trustManager = new TrustManager();
    } // public methods

    UserManagementExt.prototype.getTrustManager = function getTrustManager() {
        return this.trustManager;
    }

    /**
     * requests all usergroups from the Miniserver
     * @returns {Promise}
     */

    /*UserManagementExt.prototype.getUsergroups = function getUsergroups() {
        var def = Q.defer();
        if (usergroups !== null) {
            def.resolve(cloneObject(usergroups));
        } else {
            activeMsComp.send(Commands.USERS.GET_USERGROUPS).done(function(res) {
                usergroups = JSON.parse(decodeURIComponent(res.LL.value));
                def.resolve(cloneObject(usergroups));
            }, function() {
                def.reject();
            });
        }
        return def.promise;
    };*/

    /**
     * sends a create usergroup request to the Miniserver
     * @param [name]
     * @returns {Promise}
     */

    /*UserManagementExt.prototype.createUsergroup = function createUsergroup(name) {
        if (!name) {
            name = "Benutzergruppe"; // TODO translate
        }
        var def = Q.defer();
        activeMsComp.send(Commands.format(Commands.USERS.CREATE_USERGROUP, name)).done(function(res) {
            // TODO update locally
            def.resolve({
                name: name,
                uuid: res.LL.value
            });
        }, function() {
            def.reject();
        });
        return def.promise;
    };*/

    /**
     * updates the usergroup's name and/or permissions
     * @param usergroup
     * @returns {Promise}
     */

    /*UserManagementExt.prototype.updateUsergroup = function updateUsergroup(usergroup) {
        var def = Q.defer();
        var cleanGroup = {
            name: usergroup.name || "Benutzergruppe", // TODO translate
            permissions: (typeof usergroup.permissions === "number" ? usergroup.permissions : 1)
        };
        activeMsComp.send(Commands.format(Commands.USERS.UPDATE_USERGROUP, encodeURIComponent(JSON.stringify(cleanGroup)))).done(function(res) {
            // TODO update locally
            def.resolve();
        }, function() {
            def.reject();
        });
        return def.promise;
    };*/

    /*UserManagementExt.prototype.deleteUsergroup = function deleteUsergroup(uuid) {
        var def = Q.defer();
        activeMsComp.send(Commands.format(Commands.USERS.DELETE_USERGROUP, uuid)).done(function(res) {
            // TODO update locally
            def.resolve();
        }, function() {
            def.reject();
        });
        return def.promise;
    };*/


    UserManagementExt.prototype.getUsers = function getUsers(force) {
        var def = Q.defer(),
            promise,
            cmd;

        if (users !== null && !force) {
            promise = def.promise;
            def.resolve(users);
        } else {
            if (Feature.USER_MANAGEMENT_REWORK) {
                cmd = Commands.USERS.GET_USERS_V2;
            } else {
                cmd = Commands.USERS.GET_USERS;
            }

            promise = this._send(cmd).then(function (res) {
                users = getLxResponseValue(res);

                _sortUsers();

                return users;
            });
        }

        return promise;
    };


    /**
     * Fn used to query the captions for the custom defined userFields (customField1-5)
     * @returns {Q.Promise<unknown>|Q.Promise<{}>|*}
     */
    UserManagementExt.prototype.getCustomFieldCaptions = function getCustomFieldCaptions() {

        if (customFieldCaptions) {
            return Q.resolve(customFieldCaptions);

        } else if (Feature.USER_MANAGEMENT_ADDITIONAL_USER_FIELDS) {
            return this._send(Commands.USERS.GET_CUSTOM_CAPTIONS).then((res) => {
                customFieldCaptions = getLxResponseValue(res);
                return customFieldCaptions;
            });
        } else {
            return Q.resolve({});
        }
    };

    /**
     * Used to check the unique-ness of an user id. Returns an object with name and uuid-property. Empty strings if no
     * user was found that has that unique id.
     * @param uniqueId  the unique id to look for.
     * @returns {Q.Promise<{}>|*}
     */
    UserManagementExt.prototype.getUserFromUniqueId = function getUserFromUniqueId(uniqueId = "") {
        if (Feature.USER_MANAGEMENT_ADDITIONAL_USER_FIELDS) {
            return this._send(Commands.format(Commands.USERS.VERIFY_UNIQUE_ID, uniqueId)).then((res) => {
                return getLxResponseValue(res);
            });
        } else {
            return Q.resolve({});
        }
    }

    /**
     * Used to get a list of all the user properties existing on a Miniserver. E.g. contains all companies/departments
     * used on a miniserver throughout all users
     * @returns {Q.Promise<{}>|*}
     */
    UserManagementExt.prototype.getUserPropertyOptions = function getUserPropertyOptions() {
        if (Feature.USER_MANAGEMENT_ADDITIONAL_USER_FIELDS) {
            return this._send(Commands.USERS.GET_PROPERTY_OPTIONS).then((res) => {
                userPropOptions = getLxResponseValue(res);
                return userPropOptions;
            }, (err) => {
                console.error("UserManagementExt", "Failed to acquire user properties!");
                return {};
            });
        } else {
            return Q.resolve({});
        }
    }

    /**
     * Used to populate the cached data with e.g. companies added to it.
     * @param userObj
     * @private
     */
    UserManagementExt.prototype._updateUserPropertyOptions = function _updateUserPropertyOptions(userObj) {
        const checkAndAdd = (option) => {
            if (userPropOptions && userObj.hasOwnProperty(option) && userPropOptions.hasOwnProperty(option) && Array.isArray(userPropOptions[option])) {
                let val = userObj[option];
                if (!userPropOptions[option].find(exist => exist === val)) {
                    userPropOptions[option].push(option);
                }

            }
        }
        checkAndAdd("company");
        checkAndAdd("department");
    }

    /**
     * takes the user from the structure and adds the password
     * @param [noCreds] optional, used to avoid retrieving the (encrypted) credentials, which would've to be decrypted first.
     * @returns {{}}
     */


    UserManagementExt.prototype.getCurrentUser = function getCurrentUser(noCreds) {
        var creds = noCreds ? {} : activeMsComp.getCurrentCredentials(),
            user = {
                password: creds.password,
                name: creds.username
            },
            currentUser = activeMsComp.getCurrentUserFromStructure(),
            // take user from structure file
            activeMS = ActiveMSComponent.getActiveMiniserver();

        if (currentUser) {
            user.name = currentUser.name;
            user.uuid = currentUser.uuid;
            user.isAdmin = currentUser.isAdmin;
        } // Remove the Trust host from the username


        if (activeMS && activeMS.isInTrust && user.name && user.name.indexOf("@") !== -1) {
            var ogName = user.name;
            user.name = ogName.replace(/@[^@]*$/g, "");
            user.trustMember = ogName.split("@").splice(-1)[0];
        }

        return user;
    };
    /*UserManagementExt.prototype.createUser = function createUser(name) {
        if (!name) {
            name = "Benutzer"; // TODO translate
        }
        var def = Q.defer();
        activeMsComp.send(Commands.format(Commands.USERS.CREATE_USER, name)).done(function(res) {
            var user = {
                name: name,
                uuid: res.LL.value,
                isAdmin: false,
                sortByRating: true,
                changePassword: true,
                groups: [] // TODO add "user" group as default
            };
            _addUser(user);
            def.resolve(user);
        }, function() {
            def.reject();
        });
        return def.promise;
    };*/

    /*UserManagementExt.prototype.updateUser = function updateUser(user) {
        var def = Q.defer();
        var cleanUser = {
            name: user.name,
            password: user.password,
            visuPassword: user.visuPassword,
            sortByRating: user.sortByRating,
            changePassword: user.changePassword,
            groups: user.groups
        };
        activeMsComp.send(Commands.format(Commands.USERS.UPDATE_USER, encodeURIComponent(JSON.stringify(cleanUser))), EncryptionType.REQUEST).done(function(res) {
            _updateUser(user.uuid, cleanUser);
            def.resolve();
        }, function() {
            def.reject();
        });
        return def.promise;
    };*/

    /*UserManagementExt.prototype.deleteUser = function deleteUser(uuid) {
        var def = Q.defer();
        activeMsComp.send(Commands.format(Commands.USERS.DELETE_USER, uuid)).done(function(res) {
            _deleteUser(uuid);
            def.resolve();
        }, function() {
            def.reject();
        });
        return def.promise;
    };*/

    /**
     * Tries to change the password of the current user. Will require user management rights to perform this action.
     * @param newPassword
     * @param user          the user object containing both the username and the uuid
     * @returns {Promise}
     */


    UserManagementExt.prototype.changePassword = function changePassword(newPassword, user, scorePw) {
        var cmd,
            prms,
            hash,
            currentUser = ActiveMSComponent.getCurrentUser();

        const userName = user.trustMember ? `${user.name}@${user.trustMember}` : user.name; // if the user is a trust member, the username needs to be a fully qualified name

        if (Feature.HASHES_ONLY) {
            // 3rd param: Forces an algorithm based on the Miniserver version.
            // We need to transition from SHA1 to SHA256 to prevent collisions. The transition takes place when changing password
            prms = CommunicationComponent.getUserHashFor(newPassword, userName, VendorHub.Crypto.getHashAlgorithmForMs()).then(function (passHash) {
                // if we want to delete the password, we have to send an empty string
                if (newPassword !== "") {
                    hash = encodeURIComponent(passHash);
                } else {
                    hash = "";
                }

                cmd = Commands.format(Commands.USERS.HASHED_CHANGE_PWD, user.uuid, hash);

                if (Feature.USER_MANAGEMENT_REWORK) {
                    cmd += "|" + scorePw;
                }

                return this._send(cmd, MsPermission.CHANGE_PWD).then(function () {
                    // Only reset all tokens of the current user if the password of the current user has been changed
                    if (user.uuid === currentUser.uuid) {
                        // when tokens are in use, they need to be updated too when a password was changed.
                        return CommunicationComponent.respondToPasswordChange(newPassword, userName);
                    } else {
                        return Q.resolve();
                    }
                });
            }.bind(this));
        } else {
            prms = this._legacyChangePassword(newPassword, user);
        }

        return prms.then(function () {
            return this._handlePasswordChangeResponse(newPassword, {...user, name: userName}, currentUser);
        }.bind(this));
    };
    /**
     * Handles the update of the secret in the iOS Keychain/Android KeyStore
     * @param newPassword
     * @param user          user object from miniserver
     * @param currentUser   user object saved in the app (trust) --> needed to check if the current logged in user is the edited user
     * @param isVisuPass
     * @returns {Q.Promise<unknown>|*}
     * @private
     */


    UserManagementExt.prototype._handlePasswordChangeResponse = function _handlePasswordChangeResponse(newPassword, user, currentUser, isVisuPass) {
        var biometricPhrase,
            popupMessage,
            secretType = isVisuPass ? BiometricHelper.SecretType.VisuPw : BiometricHelper.SecretType.UserPw;

        if (user.uuid === currentUser.uuid && BiometricHelper.hasEnrolledBiometrics && !!PersistenceComponent.getBiometricTokenForSecretType(secretType)) {
            biometricPhrase = BiometricHelper.getBiometricTypePhrase();

            if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.IOS) {
                popupMessage = _('user.pass-changed.popup.ios.message', {
                    biometricType: biometricPhrase
                });
            } else {
                popupMessage = _('user.pass-changed.popup.android.message', {
                    biometricType: biometricPhrase
                });
            }

            return NavigationComp.showPopup({
                title: _('user.pass-changed.popup.title', {
                    biometricType: biometricPhrase
                }),
                message: popupMessage,
                buttonOk: _('ok'),
                icon: BiometricHelper.getBiometricGlyph()
            }).then(function () {
                return BiometricHelper.setSecretOfType(newPassword, secretType);
            }.bind(this));
        } else {
            return Q.resolve();
        }
    };
    /**
     * Legacy version of changing a password.
     * @param newPassword
     * @param user
     * @returns {Promise}
     */


    UserManagementExt.prototype._legacyChangePassword = function _legacyChangePassword(newPassword, user) {
        var cmd;

        if (Feature.CHANGE_USER_PASSWORDS_AND_CODE) {
            cmd = Commands.format(Commands.USERS.UPDATE_USER_PWD, user.uuid, encodeURIComponent(newPassword));
        } else {
            cmd = Commands.format(Commands.USERS.CHANGE_USER_PASSWORD, user.name, encodeURIComponent(newPassword));
        }

        return this._send(cmd).then(function () {
            var currentUser = activeMsComp.getCurrentUserFromStructure();

            if (currentUser.uuid === user.uuid) {
                if (Feature.TOKENS) {
                    // when tokens are in use, they need to be updated too when a password was changed.
                    // Don't store the new password, as the token is the only authentication cred required.
                    return CommunicationComponent.respondToPasswordChange();
                } else {
                    // save new password..
                    var encryptedPw = VendorHub.Crypto.encrypt(newPassword);
                    ActiveMSComponent.getActiveMiniserver().password = encryptedPw;
                    PersistenceComponent.setNewPassword(activeMsComp.getMiniserverSerialNo(), encryptedPw);
                }
            }
        });
    };
    /**
     * Will change the visu password of the user provided. Will require user management rights to perform this operation.
     * @param newVisuPassword
     * @param user          the user object containing both the username and the uuid
     * @return {*}
     */


    UserManagementExt.prototype.changeVisuPassword = function changeVisuPassword(newVisuPassword, user, scoreVisuPw) {
        var prms,
            cmd,
            hash,
            currentUser = ActiveMSComponent.getCurrentUser();

        if (Feature.HASHES_ONLY) {
            // create a userspecific hash of the new visu password before sending it to the Miniserver.
            // 3rd param: Forces an algorithm based on the Miniserver version.
            // We need to transition from SHA1 to SHA256 to prevent collisions. The transition takes place when changing password
            prms = CommunicationComponent.getVisuPassHashes(newVisuPassword, user.name, VendorHub.Crypto.getHashAlgorithmForMs()).then(function (hashes) {
                // if we want to delete the password, we have to send an empty string
                if (newVisuPassword !== "") {
                    hash = hashes.hash;
                } else {
                    hash = "";
                }

                cmd = Commands.format(Commands.USERS.HASHED_CHANGE_VISU_PWD, user.uuid, hash);

                if (Feature.USER_MANAGEMENT_REWORK) {
                    cmd += "|" + scoreVisuPw;
                }

                return this._send(cmd, MsPermission.CHANGE_PWD);
            }.bind(this));
        } else {
            prms = this._send(Commands.format(Commands.USERS.UPDATE_USER_VISU_PWD, user.uuid, newVisuPassword), MsPermission.CHANGE_PWD);
        }

        return prms.then(function () {
            // Also set the newly set password to the current users Biometric store if the device has
            // biometric authentication capabilities and a visu password is already stored
            return this._handlePasswordChangeResponse(newVisuPassword, user, currentUser, true);
        }.bind(this));
    };
    /**
     * Will change the access code of the user provided. Will require user management rights to perform this operation.
     * @param newAccessCode
     * @param user          the user object containing both the username and the uuid
     * @return {*}
     */


    UserManagementExt.prototype.changeAccessCode = function changeAccessCode(newAccessCode, user) {
        return this._send(Commands.format(Commands.USERS.UPDATE_USER_ACCESS_CODE, user.uuid, newAccessCode), MsPermission.CHANGE_PWD);
    };
    /**
     * Sends the provided command with permission
     * @param cmd
     * @param [permission] Will override msPermission if provided
     * @returns {*}
     * @private
     */


    UserManagementExt.prototype._send = function _send(cmd, permission) {
        var requestPermission = permission;

        if (!requestPermission) {
            if (Feature.USER_MANAGEMENT_REWORK) {
                requestPermission = MsPermission.USER_MANAGEMENT;
            } else {
                requestPermission = MsPermission.CHANGE_PWD;
            }
        }

        return SandboxComponent.sendWithPermission(cmd, requestPermission);
    };

    UserManagementExt.prototype.addNfcTag = function addNfcTag(userUuid, tagId, tagName) {
        var addNfcTagCommand = Commands.format(Commands.NFC_CODE_TOUCH.ADD_NFC_TAG_TO_USER, userUuid, tagId, tagName);
        return this._send(addNfcTagCommand);
    };

    UserManagementExt.prototype.removeNfcTag = function removeNfcTag(userUuid, tagId) {
        var removeUserNfc = Commands.format(Commands.NFC_CODE_TOUCH.REMOVE_USER_NFC, userUuid, tagId);
        return this._send(removeUserNfc);
    };

    UserManagementExt.prototype.getGroupList = function getGroupList() {
        var promise, userGroups;
        promise = this._send(Commands.USER_MANAGEMENT.GETGROUPLIST).then(function (result) {
            userGroups = JSON.parse(result.LL.value);
            userGroups.sort(function (a, b) {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
            return userGroups;
        }.bind(this));
        return promise;
    };

    UserManagementExt.prototype.deleteUser = function deleteUser(userUuid) {
        var deleteUser = Commands.format(Commands.USER_MANAGEMENT.DELETE_USER, userUuid);
        return this._send(deleteUser);
    };

    UserManagementExt.prototype.getAdminUserGroupType = function getAdminUserGroupType() {
        var adminUserGroupType;

        if (Feature.MS_PERMISSION_REWORK) {
            adminUserGroupType = UserManagement.USER_GROUP_TYPE.FULL_ACCESS;
        } else {
            adminUserGroupType = UserManagement.USER_GROUP_TYPE.ADMIN;
        }

        return adminUserGroupType;
    };
    /**
     * Adds or edit a given user object
     * NOTE: This Webservice may take a long time (a few minutes), a WaitingFor popup will be shown if the response takes longer then WAITING_INFO_TIMEOUT
     * @param user The user object that has been added or edited
     * @returns {*}
     */


    UserManagementExt.prototype.addOrEditUser = function addOrEditUser(user) {
        var clonedUser = cloneObjectDeep(user); // clone to avoid modifying the original data.

        if (clonedUser.name) {
            // if a username exists, ensure it's sent uri-encoded to the Miniserver.
            clonedUser.name = encodeURIComponent(clonedUser.name);
        }

        // remember potentially added company/department names for next time.
        this._updateUserPropertyOptions(clonedUser);

        var addOrEditUser = Commands.format(Commands.USER_MANAGEMENT.ADDOREDIT_USER, JSON.stringify(clonedUser)),
            sendPrms = this._send(addOrEditUser);

        return NavigationComp.showWaitingFor(sendPrms);
    };

    UserManagementExt.prototype.getUser = function getUser(userUuid) {
        var cmd = Commands.format(Commands.USER_MANAGEMENT.GETUSER, userUuid);
        return this._send(cmd);
    };

    UserManagementExt.prototype.getTrustPeers = function getTrustPeers() {
        var msType = ActiveMSComponent.getMiniserverType(); // This feature is only available on Gen. 2 Miniservers

        if (msType === MiniserverType.MINISERVER_V2 || msType === MiniserverType.MINISERVER_GO_V2 || msType === MiniserverType.MINISERVER_COMPACT) {
            return CommunicationComponent.sendViaHTTPWithPermission(Commands.USER_MANAGEMENT.GET_TRUST_PEERS, MsPermission.USER_MANAGEMENT);
        } else {
            return Q.resolve({
                peers: []
            });
        }
    };

    UserManagementExt.prototype.getUsersFromPeer = function getUsersFromPeer(peerSnr) {
        return CommunicationComponent.sendViaHTTPWithPermission(Commands.format(Commands.USER_MANAGEMENT.GET_PEER_USERS, peerSnr), MsPermission.USER_MANAGEMENT);
    };

    UserManagementExt.prototype.editPeerUsers = function editPeerUsers(peerUsers) {
        return CommunicationComponent.sendViaHTTPWithPermission(Commands.USER_MANAGEMENT.EDIT_PEER_USERS, MsPermission.USER_MANAGEMENT, EncryptionType.NONE, false, HTTP_METHODS.POST, JSON.stringify(peerUsers));
    };

    UserManagementExt.prototype.getRequiredUserManagementPermissionInfos = function getRequiredUserManagementPermissionInfos() {
        var requiredPermissions = [],
            hasUserManagementPermissions;

        if (Feature.USER_MANAGEMENT_REWORK) {
            requiredPermissions.push(MsPermission.USER_MANAGEMENT); // The CHANGE_PWD permission is also required, if the user has the overall capability of changing the password
            // This prevents the password dialog when entering the changePasswordScreen

            if (SandboxComponent.checkPermission(MsPermission.CHANGE_PWD)) {
                requiredPermissions.push(MsPermission.CHANGE_PWD);
            }
        } else {
            requiredPermissions.push(MsPermission.CHANGE_PWD);
            requiredPermissions.push(MsPermission.ADMIN);
        }

        hasUserManagementPermissions = SandboxComponent.checkPermission(requiredPermissions.reduce(function (sum, right) {
            return sum | right;
        }));
        return {
            hasUserManagementPermissions: hasUserManagementPermissions,
            requiredPermissions: requiredPermissions
        };
    };

    UserManagementExt.prototype.getUserStateDescription = function getUserStateDescription(user) {
        var returnObj = {},
            msTimestamp;
        SandboxComponent.getMiniserverTimeInfo(this, function (msDateTime) {
            msTimestamp = msDateTime;
        }.bind(this), TimeValueFormat.MINISERVER_DATE_TIME);

        switch (user.userState) {
            case UserManagement.USER_STATES.ACTIVE:
                returnObj.stateDescription = _('active');
                returnObj.reactIcon = Icons.User;
                returnObj.descriptionColor = globalStyles.colors.stateActive;
                break;

            case UserManagement.USER_STATES.DISABLED:
                returnObj.stateDescription = _('user.inactive');
                returnObj.reactIcon = Icons.User;
                returnObj.descriptionColor = Color.TEXT_SECONDARY_B;
                break;

            case UserManagement.USER_STATES.VALID_UNTIL:
                if (msTimestamp.isAfter(new LxDate(user.validUntil, true))) {
                    returnObj.stateDescription = _('user.expired');
                    returnObj.reactIcon = Icons.UserWithClock;
                    returnObj.descriptionColor = Color.TEXT_SECONDARY_B;
                } else {
                    returnObj.stateDescription = _('active');
                    returnObj.reactIcon = Icons.UserWithClock;
                    returnObj.descriptionColor = globalStyles.colors.stateActive;
                }

                returnObj.subTextUntil = new LxDate(user.validUntil, true).format(LxDate.getDateTimeFormat(null, true));
                break;

            case UserManagement.USER_STATES.VALID_FROM:
                if (msTimestamp.isBefore(new LxDate(user.validFrom, true))) {
                    returnObj.stateDescription = _('user.inactive');
                    returnObj.reactIcon = Icons.UserWithClock;
                    returnObj.descriptionColor = Color.TEXT_SECONDARY_B;
                } else {
                    returnObj.stateDescription = _('active');
                    returnObj.reactIcon = Icons.UserWithClock;
                    returnObj.descriptionColor = globalStyles.colors.stateActive;
                }

                returnObj.subTextFrom = new LxDate(user.validFrom, true).format(LxDate.getDateTimeFormat(null, true));
                break;

            case UserManagement.USER_STATES.VALID_FROM_UNTIL:
                if (msTimestamp.isBefore(new LxDate(user.validFrom, true)) || msTimestamp.isAfter(new LxDate(user.validUntil, true))) {
                    returnObj.stateDescription = _('user.inactive');
                    returnObj.reactIcon = Icons.UserWithClock;
                    returnObj.descriptionColor = Color.TEXT_SECONDARY_B;
                } else {
                    returnObj.stateDescription = _('active');
                    returnObj.reactIcon = Icons.UserWithClock;
                    returnObj.descriptionColor = globalStyles.colors.stateActive;
                }

                returnObj.subTextFrom = new LxDate(user.validFrom, true).format(LxDate.getDateTimeFormat(null, true));
                returnObj.subTextUntil = new LxDate(user.validUntil, true).format(LxDate.getDateTimeFormat(null, true));
                break;

            default: {
                returnObj.stateDescription = "";
                returnObj.reactIcon = Icons.User;
                returnObj.descriptionColor = null;
            }
        }

        return returnObj;
    };

    UserManagementExt.prototype.checkUserStateTimes = function checkUserStateTimes(isFromPicker, fromTime, untilTime) {
        var invalidUntilTime = false;

        if (fromTime.unix() >= untilTime.unix()) {
            if (isFromPicker) {
                untilTime = cloneObjectDeep(fromTime).add(1, 'hour');
            } else {
                invalidUntilTime = true;
            }
        }

        return {
            fromTime: fromTime,
            untilTime: untilTime,
            invalidUntilTime: invalidUntilTime
        };
    };

    UserManagementExt.prototype.getDatePickerCell = function getDatePickerCell(currentUser, title, fromTime, untilTime, isFromPicker, invalidUntilTime, onPickerChangedFn) {
        var checkedUserTimes,
            value = isFromPicker ? fromTime : untilTime,
            cellObj = {
                type: GUI.TableViewV2.CellType.DATE_TIME_PICKER,
                content: {
                    title: title,
                    value: value,
                    disclosureColor: !isFromPicker && invalidUntilTime ? globalStyles.colors.red : null,
                },
                onPickerChanged: (sectionIdx, rowIdx, table, value) => {
                    if (isFromPicker) {
                        fromTime = cloneObjectDeep(value);
                    } else {
                        untilTime = cloneObjectDeep(value);
                    }

                    if (currentUser.userState === UserManagement.USER_STATES.VALID_FROM_UNTIL) {
                        checkedUserTimes = ActiveMSComponent.checkUserStateTimes(isFromPicker, fromTime, untilTime);
                        fromTime = cloneObjectDeep(checkedUserTimes.fromTime);
                        untilTime = cloneObjectDeep(checkedUserTimes.untilTime);
                        invalidUntilTime = checkedUserTimes.invalidUntilTime;
                    }

                    onPickerChangedFn(fromTime, untilTime, invalidUntilTime);
                }
            };

        switch (currentUser.userState) {
            case UserManagement.USER_STATES.VALID_FROM_UNTIL:
                if (isFromPicker) {
                    cellObj.content.maxDate = untilTime;
                } else {
                    cellObj.content.minDate = fromTime;
                }
                break;
            case UserManagement.USER_STATES.VALID_UNTIL:
                cellObj.content.minDate = ActiveMSComponent.getMiniserverTimeInfo();
                break;
        }

        return cellObj;
    };


    UserManagementExt.prototype.getPasswordStrength = function getPasswordStrength(pwdScore) {
        let color;

        switch (pwdScore) {
            case UserManagement.EMPTY_PWD:
                color = null;
                break;

            case UserManagement.PWD_STRENGTH.LOW:
                color = globalStyles.colors.red;
                break;

            case UserManagement.PWD_STRENGTH.GOOD:
                color = globalStyles.colors.orange;
                break;

            case UserManagement.PWD_STRENGTH.VERY_GOOD:
                color = globalStyles.colors.green;
                break;

            case UserManagement.PWD_STRENGTH.MOST_POWER_FULL:
                color = globalStyles.colors.green;
                break;

            default:
                color = null;
                break;
        }


        return {
            color: color,
            percent: 25 * (pwdScore + 1),
        }
    };


    UserManagementExt.prototype.sortUsers = users => {
        let firstLetter,
            tmpSortedUsers = {};

        users && users.forEach((user) => {
            firstLetter = (user.name.match(/^./)[0] || "#").toUpperCase();

            tmpSortedUsers[firstLetter] = tmpSortedUsers[firstLetter] || [];
            tmpSortedUsers[firstLetter].push(user);
        });

        return tmpSortedUsers;
    }

    /**
     *
     * @param user              the user object containing the scores/isAdmin-flag
     * @param originalUsername  the original username of the user is required so we don't accidentally grab the salt for a user that doesn't exist yet (i.e. when changing the  username and password at the same time)
     * @param isVisuPw          true if this cell is for the ui pass
     * @param navigation        navigation reference to allow for navigaiton calls
     * @param hideScore         if true, the score-circle isn't shown
     * @param fromPairing       set when this call is made from the managed tablet pairing dialog (i.e. no connection to ms)
     * @returns {{action: UserManagementExt.action, content: {titleStyle: {color}, title, mainRightContent: (null|{comp: *, props: {color: *, value: *}})}}}
     */
    UserManagementExt.prototype.getPasswordCell = ({user, isVisuPw, navigation, originalUsername, hideScore, fromPairing = false}) => {
        let pwScore = isVisuPw ? user.scoreVisuPWD : user.scorePWD,
            { color, percent } = ActiveMSComponent.getPasswordStrength(pwScore),
            title,
            mainRightContent = {
                comp: LxReactCircularProgressbar,
                props: {
                    value: percent,
                    color: color
                }
            };

        // Don't check for "!pwScore" --> 0 is a valid score
        if (pwScore === undefined) {
            pwScore = UserManagement.EMPTY_PWD;
        }

        if (!Feature.USER_MANAGEMENT_REWORK && !fromPairing) {
            title = isVisuPw ? _("change-visu-password") : _("change-password");
            mainRightContent = null;
        } else if (pwScore === UserManagement.EMPTY_PWD) {
            title = isVisuPw ? _('add-visu-password') : _('user.addpass');
            mainRightContent.props.value = 0;
            delete mainRightContent.props.color;
        } else if (!isVisuPw && user.isAdmin) {
            title = _("change-password");
        } else {
            title = isVisuPw ? _('visu-password') : _('password');
        }
        return {
            content: {
                title: title,
                titleStyle: {
                    color: globalStyles.colors.brand
                },
                mainRightContent: hideScore ?  null : mainRightContent
            },
            action: () => {
                let screenDetails = {
                        title,
                        user,
                        type: isVisuPw ? "visuPw" : "userPw",
                        initSecretScore: pwScore,
                        originalUsername,
                        fromPairing
                    },
                    pwTypeString = isVisuPw ? _("visu-password") : _("password");

                if (fromPairing || SandboxComponent.checkPermission(MsPermission.CHANGE_PWD)) {
                    if (isVisuPw) {
                        screenDetails.changeVisuPassword = true;
                    }

                    navigation.navigate(ScreenState.ChangeSecretScreen, screenDetails);
                } else {
                    NavigationComp.showPopup({
                        title: _("change-password.not-allowed-dialog.title", {
                            passwordType: pwTypeString
                        }),
                        message: _("change-password.not-allowed-dialog.desc", {
                            passwordType: pwTypeString
                        }),
                        buttonOk: _("change-password.not-allowed-dialog.ok"),
                        icon: Icon.INFO,
                        color: globalStyles.colors.stateActive
                    });
                }
            }
        }
    }

    // private methods

    /*var _addUser = function(user) {
        users.push(user);
        _sortUsers();
    };
     var _updateUser = function(uuid, cleanUser) {
        var user,
            i;
        for (i = 0; i < users.length; i++) {
            user = users[i];
            if (user.uuid === uuid) {
                for (var prop in cleanUser) {
                    if (cleanUser.hasOwnProperty(prop)) {
                        user[prop] = cleanUser[prop];
                    }
                }
                break;
            }
        }
        _sortUsers();
    };
     var _deleteUser = function(uuid) {
        var user,
            i;
        for (i = 0; i < users.length; i++) {
            user = users[i];
            if (user.uuid === uuid) {
                users.splice(i, 1);
                break;
            }
        }
    };*/


    var _sortUsers = function () {
        users.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
    };

    return UserManagementExt;
});
