'use strict';

export default ({ names }) => {
    /**
     * PersistenceComponent is responsible to communicate with the local filesystem.
     */

    window[names.int].factory(names.int, ['$injector', function ($injector) {
        // internal variables
        let weakThis,
            persExt,
            settingsExt,
            archiveExt,
            sharedUserExt,
            backupAndSyncExt,
            extension = {},
            finishedLoading,
            settingsLoaded,
            archiveLoaded;

        /**
         * c-tor for Persistence Component
         * @returns {object} exposed functions for other components
         * @constructor
         */

        function PersistenceComp() {
            weakThis = this
            this.name = names.int; // initializes this component as observer

            Observer.call(this);
            finishedLoading = Q.defer();
            settingsLoaded = false;
            archiveLoaded = false; // storing a reference to all extensions

            backupAndSyncExt = new extension.BackupAndSync(this);
            persExt = new extension.Persistence(this);
            settingsExt = new extension.Settings(this, persExt); // TODO remove persExt after a while (and the handling in there :)

            archiveExt = new extension.Archive(this);
            sharedUserExt = new extension.SharedUserSettings(this); // make methods of extensions publicly available

            CompChannel.on(CCEvent.StartMSSession, function (ev, msObj) {
                settingsExt.ensureActiveMsSettings(msObj);
            });
            CompChannel.on(CCEvent.ConnEstablished, function () {
                settingsExt.ensureActiveMsSettings();
            });

            var exposed = {
                getLoadingPromise: weakThis.getLoadingPromise,
                // persExt
                saveFile: weakThis.saveFile,
                loadFile: weakThis.loadFile,
                deleteFile: weakThis.deleteFile,
                // settingsExt
                getDefaultSettings: settingsExt.getDefaultSettings.bind(settingsExt),
                setAnimationState: settingsExt.setAnimationState.bind(settingsExt),
                getAnimationState: settingsExt.getAnimationState.bind(settingsExt),
                setTileRepresentationState: settingsExt.setTileRepresentationState.bind(settingsExt),
                getTileRepresentationState: settingsExt.getTileRepresentationState.bind(settingsExt),
                setDarkModeState: settingsExt.setDarkModeState.bind(settingsExt),
                getDarkModeState: settingsExt.getDarkModeState.bind(settingsExt),
                setSimpleDesignSetting: settingsExt.setSimpleDesignSetting.bind(settingsExt),
                getSimpleDesignSetting: settingsExt.getSimpleDesignSetting.bind(settingsExt),
                setBiometricState: settingsExt.setBiometricState.bind(settingsExt),
                getBiometricState: settingsExt.getBiometricState.bind(settingsExt),
                setBiometricTokenForSecretType: settingsExt.setBiometricTokenForSecretType.bind(settingsExt),
                getBiometricTokenForSecretType: settingsExt.getBiometricTokenForSecretType.bind(settingsExt),
                setBiometricSecretWithSecretTypeEnabled: settingsExt.setBiometricSecretWithSecretTypeEnabled.bind(settingsExt),
                isBiometricSecretWithTypeEnabled: settingsExt.isBiometricSecretWithTypeEnabled.bind(settingsExt),
                setShowBiometricOnboardDialogForSecretType: settingsExt.setShowBiometricOnboardDialogForSecretType.bind(settingsExt),
                didShowBiometricOnboardDialogForSecretType: settingsExt.didShowBiometricOnboardDialogForSecretType.bind(settingsExt),
                setAppInstructionFlag: settingsExt.setAppInstructionFlag.bind(settingsExt),
                getAppInstructionFlag: settingsExt.getAppInstructionFlag.bind(settingsExt),
                setSyncEnabled: settingsExt.setSyncEnabled.bind(settingsExt),
                getSyncEnabled: settingsExt.getSyncEnabled.bind(settingsExt),
                setLastSync: settingsExt.setLastSync.bind(settingsExt),
                getLastSync: settingsExt.getLastSync.bind(settingsExt),
                getLastKnownBackup: settingsExt.getLastKnownBackup.bind(settingsExt),
                setLastKnownBackup: settingsExt.setLastKnownBackup.bind(settingsExt),
                getFirstPopupShown: settingsExt.getFirstPopupShown.bind(settingsExt),
                setFirstPopupShown: settingsExt.setFirstPopupShown.bind(settingsExt),
                backupAndSyncICloudStatus: backupAndSyncExt.backupAndSyncICloudStatus,
                backupAndSyncSetBackup: backupAndSyncExt.backupAndSyncSetBackup,
                backupAndSyncGetBackup: backupAndSyncExt.backupAndSyncGetBackup,
                backupAndSyncSetEnabled: backupAndSyncExt.backupAndSyncSetEnabled,
                backupAndSyncGetLastSync: backupAndSyncExt.backupAndSyncGetLastSync,
                backupAndSyncSetLastSync: backupAndSyncExt.backupAndSyncSetLastSync,
                backupAndSyncGetLastKnownModificationDate: backupAndSyncExt.backupAndSyncGetLastKnownModificationDate,
                backupAndSyncIsEnabled: backupAndSyncExt.backupAndSyncIsEnabled,
                backupAndSyncMerge: backupAndSyncExt.backupAndSyncMerge,
                backupAndSyncCheckForDifferentUser: backupAndSyncExt.backupAndSyncCheckForDifferentUser,
                backupAndSyncSynchronize: backupAndSyncExt.backupAndSyncSynchronize,
                backupAndSyncRestore: backupAndSyncExt.backupAndSyncRestore,
                backupAndSyncGetInitialDataPromise: backupAndSyncExt.backupAndSyncGetInitialDataPromise,
                backupAndSyncGetSyncUserChange: backupAndSyncExt.backupAndSyncGetSyncUserChange,
                backupAndSyncSetSyncUserChange: backupAndSyncExt.backupAndSyncSetSyncUserChange,
                backupAndSyncGetLastConnectedDeleted: backupAndSyncExt.backupAndSyncGetLastConnectedDeleted,
                backupAndSyncGetUserChanged: backupAndSyncExt.backupAndSyncGetUserChanged,
                activateHomeScreen: settingsExt.activateHomeScreen.bind(settingsExt),
                setHomeScreenWidget: settingsExt.setHomeScreenWidget.bind(settingsExt),
                getHomeScreenWidget: settingsExt.getHomeScreenWidget.bind(settingsExt),
                activateManualFavorites: weakThis.activateManualFavorites,
                setManualFavorites: settingsExt.setManualFavorites.bind(settingsExt),
                activateDeviceFavorites: weakThis.activateDeviceFavorites,
                setDeviceFavorites: settingsExt.setDeviceFavorites.bind(settingsExt),
                getDeviceFavoritesForUser: settingsExt.getDeviceFavoritesForUser.bind(settingsExt),
                activateSortByRating: weakThis.activateSortByRating,
                setPresenceRoom: settingsExt.setPresenceRoom.bind(settingsExt),
                setLocalStorageItem: settingsExt.setLocalStorageItem.bind(settingsExt),
                getLocalStorageItem: settingsExt.getLocalStorageItem.bind(settingsExt),
                setLocalStorageItemWithTtl: settingsExt.setLocalStorageItemWithTtl.bind(settingsExt),
                getLocalStorageItemWithTtl: settingsExt.getLocalStorageItemWithTtl.bind(settingsExt),
                removeLocalStorageItem: settingsExt.removeLocalStorageItem.bind(settingsExt),
                getMSInstructionFlag: settingsExt.getMSInstructionFlag.bind(settingsExt),
                setMSInstructionFlag: settingsExt.setMSInstructionFlag.bind(settingsExt),
                getMiniserverSettings: settingsExt.getMiniserverSettings.bind(settingsExt),
                setScreenSaverActivationTime: settingsExt.setScreenSaverActivationTime.bind(settingsExt),
                setScreenSaverBrightness: settingsExt.setScreenSaverBrightness.bind(settingsExt),
                getScreenSaverSettings: settingsExt.getScreenSaverSettings.bind(settingsExt),
                setScreenSaverAudioPlayerSettings: settingsExt.setScreenSaverAudioPlayerSettings.bind(settingsExt),
                activateEntryPoint: settingsExt.activateEntryPoint.bind(settingsExt),
                setEntryPointLocation: weakThis.setEntryPointLocation.bind(weakThis),
                setWelcomeScreenViewed: settingsExt.setWelcomeScreenViewed.bind(settingsExt),
                getEntryPointSettings: settingsExt.getEntryPointSettings.bind(settingsExt),
                setLastSelectedCodeTouch: settingsExt.setLastSelectedCodeTouch.bind(settingsExt),
                getLastSelectedCodeTouch: settingsExt.getLastSelectedCodeTouch.bind(settingsExt),
                shouldShowQuickActionMenuAcceptScreen: settingsExt.shouldShowQuickActionMenuAcceptScreen.bind(settingsExt),
                setQuickActionMenuAcceptScreenViewed: settingsExt.setQuickActionMenuAcceptScreenViewed.bind(settingsExt),
                //expertModeEnabled: settingsExt.expertModeEnabled.bind(settingsExt),
                //setExpertModeEnabled: settingsExt.setExpertModeEnabled.bind(settingsExt),
                // key-value settings storage.
                saveForMiniserver: settingsExt.saveForMiniserver.bind(settingsExt),
                getForMiniserver: settingsExt.getForMiniserver.bind(settingsExt),
                // for development purposes only
                setDevQuickActions: settingsExt.setDevQuickActions.bind(settingsExt),
                getDevQuickActions: settingsExt.getDevQuickActions.bind(settingsExt),
                // archiveExt
                getAllMiniserver: archiveExt.getAllMiniserver,
                hasMiniservers: archiveExt.hasMiniservers,
                getLastConnectedMiniserver: archiveExt.getLastConnectedMiniserver,
                getMiniserver: archiveExt.getMiniserver,
                addMiniserver: weakThis.addMiniserver,
                updateMiniserver: archiveExt.updateMiniserver,
                removeMiniserver: weakThis.removeMiniserver,
                replaceMiniserver: weakThis.replaceMiniserver,
                updateSecuredDetails: archiveExt.updateSecuredDetails,
                getSecuredDetails: archiveExt.getSecuredDetails,
                applyStoredAuthInfoForMiniserver: archiveExt.applyStoredAuthInfoForMiniserver,
                setNewPassword: archiveExt.setNewPassword,
                setToken: archiveExt.setToken,
                setNewPublicKey: archiveExt.setNewPublicKey,
                setBrandingDate: archiveExt.setBrandingDate,
                deleteConnectionInformation: archiveExt.deleteConnInfo,
                deleteAllStructures: archiveExt.deleteAllStructures,
                deleteStructureForUser: archiveExt.deleteStructureForUser,
                resetArchive: archiveExt.resetArchive,
                getControlOfMiniserver: weakThis.getControlOfMiniserver,
                getAutomaticDesignerOfMiniserver: weakThis.getAutomaticDesignerOfMiniserver,
                setSavePassword: archiveExt.setSavePassword,
                getSavePassword: archiveExt.getSavePassword,
                saveArchive: archiveExt.saveArchive,
                updateMiniserverList: archiveExt.updateMiniserverList,
                // SharedUserSettingsExt
                registerForSharedKeyChange: sharedUserExt.registerForKeyChange.bind(sharedUserExt),
                unregisterFromSharedKeyChange: sharedUserExt.unregisterFromKeyChange.bind(sharedUserExt),
                synchronizeShared: sharedUserExt.synchronize.bind(sharedUserExt),
                setShared: sharedUserExt.set.bind(sharedUserExt),
                getShared: sharedUserExt.get.bind(sharedUserExt),
                getSharedSettingsReadyPromise: sharedUserExt.getReadyPromise.bind(sharedUserExt),
                registerForCloudKitChangeEvent: weakThis.registerForCloudKitChangeEvent.bind(weakThis),
                removeFromCloudKitChangeEvent: weakThis.removeFromCloudKitChangeEvent.bind(weakThis),

                // New Eco Mode & Ambient Mode Settings
                getAmbientModeSettings: settingsExt.getAmbientModeSettings.bind(settingsExt),
                setAmbientModeSettings: settingsExt.setAmbientModeSettings.bind(settingsExt),
                updateAmbientModeSetting: weakThis.updateAmbientModeSetting.bind(weakThis),
                getStartAmbientModeInitially: weakThis.getStartAmbientModeInitially.bind(weakThis),
                dispatchAmbientModeSettingChanged: weakThis.dispatchAmbientModeSettingChanged.bind(weakThis),
                getEcoScreenSettings: settingsExt.getEcoScreenSettings.bind(settingsExt),
                setEcoScreenSettings: settingsExt.setEcoScreenSettings.bind(settingsExt),
                updateEcoScreenSetting: weakThis.updateEcoScreenSetting.bind(weakThis),
                dispatchEcoScreenSettingChanged: weakThis.dispatchEcoScreenSettingChanged.bind(weakThis),
                setAmbientOnboardingViewed: settingsExt.setAmbientOnboardingViewed.bind(settingsExt),
                saveAmbientWallpaper: settingsExt.saveWallpaper.bind(settingsExt),
                removeAmbientWallpaper: settingsExt.removeWallpaper.bind(settingsExt),

                setDefaultSettingsForRoom: weakThis.setDefaultSettingsForRoom.bind(weakThis),
                getOldEcoPlayerRoomUuid: settingsExt.getOldEcoPlayerRoomUuid.bind(settingsExt)
            };
            return exposed;
        }

        BaseComponent.beInheritedBy(PersistenceComp);
        extension = BaseComponent.initExtensions('PersistenceComp', $injector);

        PersistenceComp.prototype.getLoadingPromise = function getLoadingPromise() {
            return finishedLoading.promise;
        };

        PersistenceComp.prototype.extensionFinishedLoading = function extensionFinishedLoading(ext) {
            if (ext === settingsExt) {
                settingsLoaded = true;
            } else if (ext === archiveExt) {
                archiveLoaded = true;
            }

            if (settingsLoaded && archiveLoaded) {
                finishedLoading.resolve("Successfully loaded settings and archive file!");
            }
        }; // Public methods
        // PersistenceExt

        /**
         * @see PersistenceExt.prototype.loadFile
         */


        PersistenceComp.prototype.loadFile = extension.Persistence.prototype.loadFile;
        /**
         * @see PersistenceExt.prototype.saveFile
         */

        PersistenceComp.prototype.saveFile = extension.Persistence.prototype.saveFile;
        /**
         * @see PersistenceExt.prototype.deleteFile
         */

        PersistenceComp.prototype.deleteFile = extension.Persistence.prototype.deleteFile; // SettingsExt

        /**
         * @see SettingsExt.prototype.setSortByRating
         */

        PersistenceComp.prototype.activateSortByRating = function activateSortByRating(active, serialNo, preventActiveMSComponent) {
            settingsExt.activateSortByRating(active, serialNo);
            !preventActiveMSComponent && ActiveMSComponent.getStructureManager().setSortByRating(active);
        };
        /**
         * Activates or deactivates the manual favorites. Sets default values if the manual favorites are activated for the
         * first time. Tries to use the miniserver favorites, uses an empty set of favorites if it doesn't work.
         * @param active        whether or not manual favorites are active
         * @param [serialNo]    the serial number of the Miniserver to configure - if not provided it's assumed to be the activeMs
         * @returns {*}
         */


        PersistenceComp.prototype.activateManualFavorites = function activateManualFavorites(active, serialNo) {
            // TODO-woessto does it make sense to implement a try catch block here?
            var manualFavs = settingsExt.getMiniserverSettings(serialNo).manualFavorites.favorites;

            try {
                if (active && !manualFavs) {
                    if (!serialNo || ActiveMSComponent.getMiniserverSerialNo() === serialNo) {
                        window[names.ext].setManualFavorites(ActiveMSComponent.getStructureManager().getMiniserverFavorites());
                        manualFavs = true;
                    } else {
                        console.error("Could not initalize manual favorites properly - " + serialNo + " not an active MS");
                        manualFavs = false;
                    }
                }
            } catch (ex) {
                console.error(ex);
                manualFavs = false;
            }

            if (!manualFavs) {
                // didn't work. Use empty set for initial values.
                window[names.ext].setManualFavorites(settingsExt.getEmptyManualFavorites.apply(settingsExt));
            }

            return settingsExt.activateManualFavorites.apply(settingsExt, arguments);
        };
        /**
         * Activates or deactivates the manual favorites. Sets default values if the manual favorites are activated for the
         * first time. Tries to use the Miniserver favorites, uses an empty set of favorites if it doesn't work.
         * @param active        whether or not manual favorites are active
         * @param [serialNo]    the serial number of the Miniserver to configure - if not provided it's assumed to be the activeMs
         * @param [activeUser] - the username of the active user
         * @param [reset] - true if the device specific sorting should be reseted to initial star rating
         * @returns {*}
         */


        PersistenceComp.prototype.activateDeviceFavorites = function activateDeviceFavorites(active, serialNo, activeUser, reset) {
            var devFavs = settingsExt.getDeviceFavoritesForUser().favorites,
                sManager = ActiveMSComponent.getStructureManager(),
                ratedControls,
                favControls,
                favRooms,
                favCats;

            if ((active || reset) && !devFavs) {
                if (!serialNo || ActiveMSComponent.getMiniserverSerialNo() === serialNo) {
                    // initialize with the current favorites.
                    ratedControls = sManager.getRatedControls();
                    favControls = sManager.getFavoriteControls();
                    favRooms = sManager.getFavoriteGroupsByGroupType(GroupTypes.ROOM);
                    favCats = sManager.getFavoriteGroupsByGroupType(GroupTypes.CATEGORY);
                }

                settingsExt.initializeDeviceFavorites(serialNo, ratedControls, favControls, favRooms, favCats);
            }

            return settingsExt.activateDeviceFavorites.apply(settingsExt, arguments);
        }; // PersistenceComp


        /**
         * @see SettingsExt.prototype.addMiniserver
         * @see ArchiveExt.prototype.addMiniserver
         * @params arguments, list of Miniservers
         */
        PersistenceComp.prototype.addMiniserver = function addMiniserver() {
            settingsExt.addMiniserver.apply(settingsExt, arguments);
            archiveExt.addMiniserver.apply(archiveExt, arguments);
        };


        /**
         * @see SettingsExt.prototype.removeMiniserver
         * @see ArchiveExt.prototype.removeMiniserver
         * @param serialNo
         */
        PersistenceComp.prototype.removeMiniserver = function removeMiniserver(serialNo) {
            settingsExt.removeMiniserver(serialNo);
            archiveExt.removeMiniserver(serialNo);
        };
        /**
         * @see SettingsExt.prototype.replaceMiniserver
         * @param serialNoOld
         * @param serialNoNew
         */


        PersistenceComp.prototype.replaceMiniserver = function replaceMiniserver(serialNoOld, serialNoNew) {
            settingsExt.replaceMiniserver(serialNoOld, serialNoNew);
            weakThis.removeMiniserver(serialNoOld);
        };
        /**
         * tries to load the Miniserver for the given mac and then searches for the control
         * @param mac
         * @param controlUUID
         * @param additionalInfo    object with attributes such as 'playerid' for an audioZone
         * @returns {Promise}
         */


        PersistenceComp.prototype.getControlOfMiniserver = function getControlOfMiniserver(mac, controlUUID, additionalInfo) {
            var ms = window[names.ext].getMiniserver(mac);

            if (ms) {
                // create helper method
                var searchControlInFile = function (fileName) {
                    return weakThis.loadFile(fileName, DataType.OBJECT).then(function (structure) {
                        // TODO - it would be a much better approach, to make the normalize control function from the structureExt public, and do it there
                        // duplicate implementation, nobody will remember to adopt this here too
                        var room, cat, control;

                        if (structure.controls && structure.controls[controlUUID]) {
                            control = structure.controls[controlUUID];
                            room = structure.rooms[control.room] || null;
                            cat = structure.cats[control.cat] || null;
                            addGroupDetailsToControl(control, room, cat);
                            return control;
                        } // might be an audioZone


                        if (additionalInfo) {
                            if (additionalInfo.hasOwnProperty('playerid')) {
                                var i,
                                    keys = Object.keys(structure.controls);

                                for (i = 0; i < keys.length; i++) {
                                    control = structure.controls[keys[i]]; // verify it's the right control

                                    if (control.type === "AudioZone" && control.details.server === controlUUID && control.details.playerid === additionalInfo.playerid) {
                                        room = structure.rooms[control.room] || null;
                                        cat = structure.cats[control.cat] || null;
                                        addGroupDetailsToControl(control, room, cat);
                                        return control;
                                    }
                                }
                            }
                        }

                        return ControlLoadError.CONTROL_NOT_AVAILABLE;
                    }, function () {
                        return ControlLoadError.CONTROL_NOT_AVAILABLE;
                    });
                }; // try first remote, we should always be able to connect to remote


                var fileNameRemote = buildLoxAPP3Filename(ms, ReachMode.REMOTE),
                    fileNameLocal = buildLoxAPP3Filename(ms, ReachMode.LOCAL);
                return searchControlInFile(fileNameRemote).then(function (res) {
                    if (res === ControlLoadError.CONTROL_NOT_AVAILABLE) {
                        // try local
                        return searchControlInFile(fileNameLocal);
                    } else {
                        return res;
                    }
                }, function () {
                    // try local
                    return searchControlInFile(fileNameLocal);
                });
            } else {
                var def = Q.defer();
                def.resolve(ControlLoadError.MINISERVER_NOT_AVAILABLE);
                return def.promise;
            }
        }; // TODO remove this after a few releases


        PersistenceComp.prototype.saveArchive = extension.Archive.prototype.saveArchive;

        PersistenceComp.prototype.registerForCloudKitChangeEvent = function registerForCloudKitChangeEvent(fn) {
            return this.on("iCloudDataChange", fn);
        };

        PersistenceComp.prototype.removeFromCloudKitChangeEvent = function removeFromCloudKitChangeEvent(unregisterFn) {
            this.off(unregisterFn);
        };

        PersistenceComp.prototype.updateAmbientModeSetting = function updateAmbientModeSetting() {
            return settingsExt.updateAmbientModeSetting(...arguments).then(
                () => {
                    this.dispatchAmbientModeSettingChanged();
                });
        };

        PersistenceComp.prototype.getStartAmbientModeInitially = function getStartAmbientModeInitially() {
            let timeout = 0;
            const settings = PersistenceComponent.getAmbientModeSettings();
            if (settings) {
                timeout = settings.timeout;
            }
            return timeout > 0;
        }

        PersistenceComp.prototype.dispatchAmbientModeSettingChanged = function dispatchAmbientModeSettingChanged() {
            CompChannel.emit(CCEvent.AmbientModeSettingChanged);
        };

        PersistenceComp.prototype.updateEcoScreenSetting = function updateEcoScreenSetting() {
            settingsExt.updateEcoScreenSetting(...arguments);
            this.dispatchEcoScreenSettingChanged();
        };

        PersistenceComp.prototype.dispatchEcoScreenSettingChanged = function dispatchEcoScreenSettingChanged() {
            CompChannel.emit(CCEvent.EcoScreenSettingChanged);
        };

        PersistenceComp.prototype.setDefaultSettingsForRoom = function setDefaultSettingsForRoom() {
            return settingsExt.setDefaultSettingsForRoom.apply(settingsExt, arguments).then(() => {
                this.dispatchEcoScreenSettingChanged();
                this.dispatchAmbientModeSettingChanged();
            })
        };

        PersistenceComp.prototype.setEntryPointLocation = function setEntryPointLocation() {
            settingsExt.setEntryPointLocation.apply(settingsExt, arguments);
            setTimeout(() => {
                CompChannel.emit(CCEvent.EntryPointSettingChanged);
            }, 1);
        };

        window[names.ext] = new PersistenceComp();
        return window[names.ext];
    }]);
}
