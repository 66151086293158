import PairedAppEnums from "../pairedAppEnums";


const dbgStaticName = "SPairingFile";
const PairingPassKey = "PairedAppPassword"
export default class PairingFile {

    constructor(deviceUuid, serialNo, username, password, publicKey, localUrl, host, remoteUrl) {
        if (!!serialNo && !!deviceUuid && !!username && !!password && (!!host || !!remoteUrl || !!localUrl)) {
            this._serialNo = serialNo;
            this._username = username;
            this._password = password;
            this._publicKey = publicKey;
            this._localUrl = localUrl;
            this._remoteUrl = remoteUrl;
            this._host = host;
            this._deviceUuid = deviceUuid;
        } else {
            const errorArgs = [];
            !deviceUuid && errorArgs.push("deviceUuid")
            !serialNo && errorArgs.push("serialNo")
            !username && errorArgs.push("username")
            !password && errorArgs.push("password")
            !publicKey && errorArgs.push("publicKey")

            if (!localUrl && !host && !remoteUrl) {
                !localUrl && errorArgs.push("localUrl")
                !host && errorArgs.push("host")
                !remoteUrl && errorArgs.push("remoteUrl")
            }
            throw new Error("Missing arguments: " + errorArgs.join(", "));
        }
    }

    get serialNo() {
        return this._serialNo;
    }

    get username() {
        return this._username;
    }

    get password() {
        return this._password;
    }

    get publicKey() {
        return this._publicKey;
    }

    get localUrl() {
        return this._localUrl;
    }

    get remoteUrl() {
        return this._remoteUrl;
    }

    get host() {
        return this._host;
    }

    get deviceUuid() {
        return this._deviceUuid;
    }

    get JSON() {
        return {
            serialNo: this._serialNo,
            username: this._username,
            password: this._password,
            publicKey: this._publicKey,
            localUrl: this._localUrl,
            remoteUrl: this._remoteUrl,
            host: this._host,
            deviceUuid: this._deviceUuid,
        }
    }

    static readFromObject(jsonObject) {
        try {
            return new PairingFile(
                jsonObject.deviceUuid,
                jsonObject.serialNo,
                jsonObject.username,
                jsonObject.password,
                jsonObject.publicKey,
                jsonObject.localUrl,
                jsonObject.host,
                jsonObject.remoteUrl
            );
        } catch (ex) {
            console.error(PairingFile.name, "readFromObject failed: " + ex.message);
            return null;
        }

    }

    /**
     * Returns an instance of pairing file if all required props are provided
     * At lest on of localUrl, host or remoteUrl must be provided, the others are optional.
     * @param deviceUuid    the uuid identifying the paired device (not the user or the local device-id)
     * @param serialNo      serial number of the miniserver
     * @param username      username of the pairing (will be the UUID of the user in case of pairings)
     * @param password      the password used to login, this must be persisted and must not change.
     * @param publicKey     public key of the miniserver the pairing has been established with.
     * @param localUrl      the local connection path to the miniserver.
     * @param host          local hostname of the miniserver
     * @param remoteUrl     remote connection url of the miniserver
     * @returns {PairingFile}
     */
    static create(deviceUuid, serialNo, username, password, publicKey, localUrl, host, remoteUrl) {
        try {
            return new PairingFile(
                deviceUuid,
                serialNo,
                username,
                password,
                publicKey,
                localUrl,
                host,
                remoteUrl
            );
        } catch (ex) {
            console.error(PairingFile.name, "create failed: " + ex.message);
            return null;
        }

    }

    static saveToStorage(pairingFile) {
        let prms;
        if (this.__hasSecureStore()) {
            Debug.PairedApp && console.log(dbgStaticName, "saveToStorage [SECURED]: " + pairingFile.serialNo);
            prms = this.__updateSecureStore(pairingFile.password).then(() => {
                Debug.PairedApp && console.log(dbgStaticName, "saveToStorage [SECURED] - pass persisted " + pairingFile.serialNo + ", user=" + pairingFile.username);
                let cleanedJson = pairingFile.JSON;
                delete cleanedJson.password;
                Debug.PairedApp && console.log(dbgStaticName, "saveToStorage [SECURED] - now saving pairingFile: " + JSON.stringify(cleanedJson));
                return PersistenceComponent.saveFile(PairedAppEnums.FileName, cleanedJson, DataType.OBJECT);
            });
        } else {
            Debug.PairedApp && console.log(dbgStaticName, "saveToStorage [no-ms-info-store]: " + pairingFile.serialNo);
            prms = PersistenceComponent.saveFile(PairedAppEnums.FileName, pairingFile.JSON, DataType.OBJECT);
        }

        prms.then(() => {
            Debug.PairedApp && console.log(dbgStaticName, "saveToStorage > success! " + pairingFile.serialNo);
        }, (err) => {
            console.error(dbgStaticName, "saveToStorage FAILED! " + pairingFile.serialNo + ", " + (err.message || JSON.stringify(err)));
        })
        return prms;
    }

    static loadFromStorage() {
        Debug.PairedApp && console.log(dbgStaticName, "loadFromStorage")
        let prms = PersistenceComponent.loadFile(PairedAppEnums.FileName, DataType.OBJECT).then(json => {
            Debug.PairedApp && console.log(dbgStaticName, "loadFromStorage --> loaded: " + json.serialNo + ", user=" + json.username);

            if (this.__hasSecureStore()) {
                Debug.PairedApp && console.log(dbgStaticName, "loadFromStorage [SECURED] - pass retrieving password for " + json.serialNo + ", user=" + json.username);
                return this.__readSecureStore().then(password => {
                    Debug.PairedApp && console.log(dbgStaticName, "loadFromStorage [SECURED] - pass retrieved for " + json.serialNo + ", user=" + json.username);
                    let jsonWithPass = {...json, password};
                    return PairingFile.readFromObject(jsonWithPass);
                });
            } else {
                return PairingFile.readFromObject(json);
            }
        });

        prms.then(res => {
            if (res) {
                Debug.PairedApp && console.log(dbgStaticName, "loadFromStorage > success! " + res.serialNo);
            } else {
                Debug.PairedApp && console.log(dbgStaticName, "loadFromStorage > no pairing file found!");
            }
        }, (err) => {
            console.error(dbgStaticName, "loadFromStorage FAILED! " + (err.message || JSON.stringify(err)));
        })

        return prms;
    }

    static deleteFromStorage() {
        Debug.PairedApp && console.log(dbgStaticName, "deleteFromStorage")
        return PersistenceComponent.deleteFile(PairedAppEnums.FileName);
    }

    // region secure password storage

    static __hasSecureStore() {
        return Boolean(window.miniserverInfoStore);
    }

    static __secureStoreKey() {
        return PairingPassKey + "@" + PlatformComponent.getPlatformInfoObj().uuid;
    }

    static __readSecureStore() {
        let def = Q.defer();
        miniserverInfoStore.getFromSecureStore(def.resolve, def.reject, this.__secureStoreKey());
        return def.promise;
    }

    static __updateSecureStore(data) {
        let def = Q.defer();
        try {
            miniserverInfoStore.saveToSecureStore(def.resolve, def.reject, this.__secureStoreKey(), data);
        } catch (ex) {
            return def.reject();
        }
        return def.promise;
    }

    static __clearSecureStore(snr) {
        let def = Q.defer();
        miniserverInfoStore.clear(def.resolve, def.reject, this.__secureStoreKey());
        return def.promise;
    }

    // endregion

}