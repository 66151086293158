'use strict';

PlatformComp.factory('DeviceInfoExt', ['NetworkInfoAdapter', 'BatteryStateAdapter', function (NetworkInfoAdapter, BatteryStateAdapter) {
    // private variables
    let weakThis,
        platComp = {};

    /**
     * c-tor for DeviceInfo Extension
     * @param comp reference to the parent component
     * @constructor
     */

    function DeviceInfoExt(comp) {
        weakThis = this
        platComp = comp;
        this.init();
    }

    DeviceInfoExt.prototype.init = function init() {
        let weakThis = this;
        this.lang = navigator.languages && navigator.languages.length > 0 ? navigator.languages[0] : navigator.language || navigator.userLanguage; // this fallback is necessary, if the user removes all languages from the settings in firefox
        // in chrome the user can't delete all languages

        if (!this.lang || this.lang === "") {
            this.lang = "en-gb";
        } else {
            this.lang = this.lang.toLowerCase();
        }

        if (navigator.globalization) {
            return navigator.globalization.getPreferredLanguage(function langReceived(result) {
                // TODO-woessto: from lindosi:  if the lang code changes, mobiscroll won't our defined language! (see pickerBaseCell.js:50)
                // TODO-woessto: from lindosi:  on german iPad the languages goes from de-de to de!
                weakThis.lang = result.value && result.value.toLowerCase();
            });
        }
    }; // public methods

    /**
     * @see VendorHub.DeviceInfo.getPlatformInfoObj
     */


    DeviceInfoExt.prototype.getPlatformInfoObj = VendorHub.DeviceInfo.getPlatformInfoObj;
    /**
     * @see NetworkInfoAdapter.prototype.getNetworkStatus
     */

    DeviceInfoExt.prototype.getNetworkStatus = NetworkInfoAdapter.getNetworkStatus;
    /**
     * @see BatteryStateAdapter.prototype.getBatteryStateObj
     */

    DeviceInfoExt.prototype.getBatteryStateObj = BatteryStateAdapter.getBatteryStateObj;

    DeviceInfoExt.prototype.getLanguage = function getLanguage() {
        return this.lang;
    };

    DeviceInfoExt.prototype.isModernBrowser = function isModernBrowser() {
        var platformInfo = weakThis.getPlatformInfoObj();
        return platformInfo.platform !== PlatformType.Android || normalizeAndroidVersion(platformInfo.version) >= 4.4;
    };

    return DeviceInfoExt;
}]);
