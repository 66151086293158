'use strict';

import AmbientUtils from "../../react-comps/AmbientMode/AmbientUtils";

PersistenceComp.factory('SettingsExt', ['PlatformComp', function (PlatformComp) {
    var SETTINGS_FILENAME = "LoxSettings.json";
    var SettingsStoreKeys = {
        FINGERPRINT: "fingerprint",
        BIOMETRIC_SECRETS: "biometricSecrets"
    }; // IMPORTANT: using an objects here, requires a clone object when using later

    const EcoScreenDefaults = {
        LOCATION: {},
        TIMEOUT: ScreenSaverTimes[2].value, // 5 Minutes
        BEDROOM_TIMEOUT: ScreenSaverTimes[0].value, // 30 Seconds
    }
    const AmbientModeDefaults = {
        TIMEOUT: ScreenSaverTimes[1].value, // 1 Minute
        BEDROOM_TIMEOUT: ScreenSaverTimes[0].value, // 30 seconds
    }

    var DefaultSettings = {
        ANIMATIONS: true,
        TILE_REPRESENTATION: true,
        DARK_MODE: true,
        SIMPLE_DESIGN: false,
        // "schlichte Darstellung", no colors for Groups.. // update: 20220511 - no longer our default
        Miniserver: {
            HomeScreen: {
                ACTIVATED: true,
                BUILDING: 0,
                SKYLINE: 0
            },
            ManualFavorites: {
                ACTIVATED: false,
                FAVORITES: undefined
            },
            DeviceFavorites: {
                ACTIVATED: false,
                FAVORITES: undefined
            },
            SORT_BY_RATING: undefined,
            // to check if it should be set from structure the first time
            ENTRY_POINT_LOCATION: UrlStartLocation.FAVORITES,
            PRESENCE_ROOM: "",
            INSTRUCTION_FLAGS: {},
            USER_MANAGEMENT: {
                SELECTED_NFC_CODE_TOUCH: undefined
            } //EXPERT_MODE: false

        },
        ScreenSaver: {
            ACTIVATION_TIME: ScreenSaverTimes[2].value,
            BRIGHTNESS: 10
        },
        ENTRY_POINT: {
            ACTIVATED: true
        },
        SYNC: {
            FIRST_POPUP_SHOWN: false,
            ENABLED: false,
            LAST_SYNC: undefined,
            LAST_KNOWN_BACKUP: undefined
        }
    };
    DefaultSettings[SettingsStoreKeys.FINGERPRINT] = false;
    DefaultSettings[SettingsStoreKeys.BIOMETRIC_SECRETS] = {};
    DefaultSettings[SettingsStoreKeys.BIOMETRIC_SECRETS][BiometricHelper.SecretType.VisuPw] = {
        enabledMap: {},
        tokenMap: {}
    };
    DefaultSettings[SettingsStoreKeys.BIOMETRIC_SECRETS][BiometricHelper.SecretType.UserPw] = {
        enabledMap: {},
        tokenMap: {}
    };
    DefaultSettings.LOCAL_STORAGE = {}; // internal variables

    var persComp = {},
        settings,
        nativeSettings = {};

    /**
     * c-tor for the Settings Extension
     * @param comp reference to the parent component
     * @constructor
     */

    function SettingsExt(comp, persExt) {
        this.name = "SettingsExt";
        persComp = comp;
        var platform = PlatformComponent.getPlatformInfoObj().platform; // load archive from persistence component

        persComp.loadFile(SETTINGS_FILENAME, DataType.OBJECT).done(function (res) {
            settings = fixSettingsStructure(res);
            loadSettingsStore().then(function () {
                persComp.extensionFinishedLoading(this);
            }.bind(this));
        }.bind(this), function () {
            // initialize settings
            settings = {
                animations: DefaultSettings.ANIMATIONS,
                darkMode: DefaultSettings.DARK_MODE,
                tileRepresentation: DefaultSettings.TILE_REPRESENTATION,
                fingerprint: DefaultSettings.FINGER_PRINT,
                simpleDesign: DefaultSettings.SIMPLE_DESIGN,
                miniservers: {},
                instructionFlags: {},
                // will be filled up, after adding a ms
                LOCAL_STORAGE: DefaultSettings.LOCAL_STORAGE // don't touch!!!!!! --> black screen

            };
            var entryPointLocation = UrlStartLocation.FAVORITES;

            if (platform === PlatformType.Mac || platform === PlatformType.Windows) {
                entryPointLocation = UrlStartLocation.LAST_POSITION;
            }

            settings.entryPoint = {
                activated: DefaultSettings.ENTRY_POINT.ACTIVATED,
                entryPointLocation: entryPointLocation
            };
            settings.SYNC = {
                ENABLED: DefaultSettings.SYNC.ENABLED,
                LAST_SYNC: DefaultSettings.SYNC.LAST_SYNC,
                LAST_KNOWN_BACKUP: DefaultSettings.SYNC.LAST_KNOWN_BACKUP
            };

            if (HD_APP || platform === PlatformType.DeveloperInterface || platform === PlatformType.Webinterface) {
                settings.screenSaver = {
                    activationTime: DefaultSettings.ScreenSaver.ACTIVATION_TIME,
                    brightness: DefaultSettings.ScreenSaver.BRIGHTNESS
                };
            }

            loadSettingsStore().then(function () {
                persComp.extensionFinishedLoading(this);
                this.saveSettings();
            }.bind(this));
        }.bind(this));

        /**
         * Iterates over every settings-key and reads them
         * @returns {*}
         */

        function loadSettingsStore() {
            var promises = [true];

            try {
                if (settingsStore) {
                    for (var storeKey in SettingsStoreKeys) {
                        if (SettingsStoreKeys.hasOwnProperty(storeKey)) {
                            var promise = getFromSettingsStore(SettingsStoreKeys[storeKey]);
                            promise.then(function (settingsObj) {
                                var key = Object.keys(settingsObj)[0];
                                var value = settingsObj[key];

                                if (value === undefined || value === null) {
                                    value = DefaultSettings[key];
                                }

                                nativeSettings[key] = value;
                            });
                            promises.push(promise);
                        }
                    }
                }
            } catch (e) {// Do nothing, we don't want to show an Error on not supported platforms
            }

            return Q.all(promises);
        }
    } // Public methods

    /**
     * To get access to the default settings
     * @returns {{ANIMATIONS: boolean, DARK_MODE: boolean, Miniserver: {HomeScreen: {ACTIVATED: boolean, BUILDING: number, SKYLINE: number}, MANUAL_FAVORITES_ACTIVATED: boolean, SORT_BY_RATING: undefined, WALLMOUT_ROOM: string}, ScreenSaver: {ACTIVATION_TIME: *, BRIGHTNESS: number}, Wallmount: {ACTIVATED: boolean, BACKGROUND_IMAGE, ACTIVATION_TIME: *, ALWAYS_USE_LAST_ROOM: boolean}}}
     */


    SettingsExt.prototype.getDefaultSettings = function getDefaultSettings() {
        return DefaultSettings;
    };
    /**
     * Returns an empty default set of manualFavorites
     * @returns {{controls: {}, rooms: {}, cats: {}}}
     */


    SettingsExt.prototype.getEmptyManualFavorites = function getEmptyManualFavorites() {
        return {
            controls: {},
            rooms: {},
            cats: {}
        };
    }; // TODO make this private after a few releases

    /**
     * Saves the current settings object to the filesystem
     * @param dispatchToLoxoneControl
     */


    SettingsExt.prototype.saveSettings = function saveSettings(dispatchToLoxoneControl) {
        if (settings) {
            var promise = persComp.saveFile(SETTINGS_FILENAME, settings, DataType.OBJECT);
            promise.then(function (res) {
                Debug.Persistence && console.info("saved successfully the " + SETTINGS_FILENAME);

                if (LoxoneControl.hasLoxoneControl() && dispatchToLoxoneControl) {
                    LoxoneControl.settingsDidChange();
                }
            }, function (error) {
                console.error("couldn't save the settings, " + error);
            });
            return promise;
        } else {
            console.warn("Couldn't save the settings, no settings exists.");
            return Q.resolve();
        }
    };
    /**
     * Creates a new miniserver setting object to store all settings from this miniserver. Called after structureReady
     * @param miniserver
     */


    SettingsExt.prototype.addMiniserver = function addMiniserver() {
        Array.from(arguments).forEach(function (miniserver) {
            if (settings.miniservers[miniserver.serialNo]) {
                console.warn("Miniserver settings already available, don't override it.");
            } else if (!miniserver.serialNo) {
                developerAttention("Got Miniserver Object without serialNo!!");
            } else {
                settings.miniservers[miniserver.serialNo] = {
                    homeScreen: {
                        activated: DefaultSettings.Miniserver.HomeScreen.ACTIVATED,
                        widget: {
                            building: DefaultSettings.Miniserver.HomeScreen.BUILDING,
                            skyline: DefaultSettings.Miniserver.HomeScreen.SKYLINE
                        }
                    },
                    manualFavorites: {
                        activated: DefaultSettings.Miniserver.ManualFavorites.ACTIVATED,
                        // will be filled up in the favorite settings screen
                        favorites: DefaultSettings.Miniserver.ManualFavorites.FAVORITES
                    },
                    deviceFavorites: {
                        activated: DefaultSettings.Miniserver.DeviceFavorites.ACTIVATED,
                        // will be filled up in the favorite settings screen
                        favorites: DefaultSettings.Miniserver.DeviceFavorites.FAVORITES
                    },
                    sortByRating: DefaultSettings.Miniserver.SORT_BY_RATING,
                    entryPointLocation: DefaultSettings.Miniserver.ENTRY_POINT_LOCATION,
                    presenceRoom: DefaultSettings.Miniserver.PRESENCE_ROOM,
                    instructionFlags: cloneObject(DefaultSettings.Miniserver.INSTRUCTION_FLAGS),
                    userManagement: {
                        selectedNfcCodeTouch: DefaultSettings.Miniserver.USER_MANAGEMENT.SELECTED_NFC_CODE_TOUCH
                    } //expertMode: DefaultSettings.Miniserver.EXPERT_MODE

                };
                settings.miniservers[miniserver.serialNo].sortingDeviceFavorites = {};
                settings.miniservers[miniserver.serialNo].sortingDeviceFavorites[miniserver.activeUser] = {
                    activated: DefaultSettings.Miniserver.DeviceFavorites.ACTIVATED,
                    favorites: DefaultSettings.Miniserver.DeviceFavorites.FAVORITES
                };
            }
        }.bind(this));
        this.saveSettings();
    };

    SettingsExt.prototype.replaceMiniserver = function replaceMiniserver(serialNoOld, serialNoNew) {
        settings.miniservers[serialNoNew] = cloneObjectDeep(settings.miniservers[serialNoOld]);
    };

    SettingsExt.prototype.removeMiniserver = function removeMiniserver(serialNo) {
        if (settings.miniservers[serialNo]) {
            if (BiometricHelper.hasEnrolledBiometrics) {
                Object.values(BiometricHelper.SecretType).forEach(function (secretType) {
                    //PersistenceComponent used, because Miniserver may not be active ->  no activeUser available
                    PersistenceComponent.setBiometricTokenForSecretType(null, secretType, serialNo, PersistenceComponent.getMiniserver(serialNo).activeUser);
                    PersistenceComponent.setShowBiometricOnboardDialogForSecretType(false, secretType, serialNo, PersistenceComponent.getMiniserver(serialNo).activeUser);
                });
            }

            AmbientAndEcoUtils.removeWallpaper(serialNo);
            delete settings.miniservers[serialNo];
            this.saveSettings();
        } else {
            console.warn("Miniserver settings not available to delete.");
        }
    }; // General settings

    /**
     * saves a flag if animations are active
     * @param animationsActive
     */


    SettingsExt.prototype.setAnimationState = function setAnimationState(animationsActive) {
        settings.animations = animationsActive;
        this.saveSettings();
    };
    /**
     * returns flag if animations are active
     * @returns {boolean}
     */


    SettingsExt.prototype.getAnimationState = function getAnimationState() {
        return settings.animations;
    };
    /**
     * saves a flag if animations are active
     * @param tileActive
     */


    SettingsExt.prototype.setTileRepresentationState = function setTileRepresentationState(tileActive) {
        settings.tileRepresentation = tileActive;
        this.saveSettings();
    };
    /**
     * returns flag if animations are active
     * @returns {boolean}
     */


    SettingsExt.prototype.getTileRepresentationState = function setTileRepresentationState() {
        return settings.tileRepresentation;
    };
    /**
     * saves a flag if dark mode is active
     * @param darkModeActive
     */


    SettingsExt.prototype.setDarkModeState = function setDarkModeState(darkModeActive) {
        settings.darkMode = darkModeActive;
        this.saveSettings();
    };
    /**
     * returns flag if dark mode is active
     * @returns {boolean}
     */


    SettingsExt.prototype.getDarkModeState = function getDarkModeState() {
        return settings.darkMode;
    };

    SettingsExt.prototype.setSyncEnabled = function setSyncEnabled(enabled) {
        settings.SYNC.ENABLED = enabled;
        this.saveSettings();
    };

    SettingsExt.prototype.getSyncEnabled = function getSyncEnabled() {
        return settings.SYNC.ENABLED;
    };

    SettingsExt.prototype.setLastSync = function setLastSync(date) {
        settings.SYNC.LAST_SYNC = date;
        this.saveSettings();
    };

    SettingsExt.prototype.getLastSync = function getLastSync() {
        return settings.SYNC.LAST_SYNC;
    };

    SettingsExt.prototype.setFirstPopupShown = function setFirstPopupShown(shown) {
        settings.SYNC.FIRST_POPUP_SHOWN = shown;
        this.saveSettings();
    };

    SettingsExt.prototype.getFirstPopupShown = function getFirstPopupShown() {
        return settings.SYNC.FIRST_POPUP_SHOWN;
    };

    SettingsExt.prototype.setLastKnownBackup = function setLastKnownBackup(date) {
        settings.SYNC.LAST_KNOWN_BACKUP = date;
        this.saveSettings();
    };

    SettingsExt.prototype.getLastKnownBackup = function getLastKnownBackup(date) {
        return settings.SYNC.LAST_KNOWN_BACKUP;
    };

    SettingsExt.prototype.setSimpleDesignSetting = function setSimpleDesignSetting(value) {
        if (settings.simpleDesign !== value) {
            settings.simpleDesign = value;
            NavigationComp.dispatchEventToUI(NavigationComp.UiEvents.SimpleDesignChanged, value);
        }

        this.saveSettings();
    };

    SettingsExt.prototype.getSimpleDesignSetting = function getSimpleDesignSetting() {
        if (typeof settings.simpleDesign === "boolean") {
            return settings.simpleDesign;
        } else {
            return DefaultSettings.SIMPLE_DESIGN;
        }
    }; // For development purposes only


    SettingsExt.prototype.setDevQuickActions = function setDevQuickActions(value) {
        if (settings.devQuickActions !== value) {
            settings.devQuickActions = value;
        }

        this.saveSettings();
    }; // For development purposes only


    SettingsExt.prototype.getDevQuickActions = function getDevQuickActions() {
        if (settings.hasOwnProperty("devQuickActions")) {
            return settings.devQuickActions;
        } else {
            return [];
        }
    };
    /**
     * saves a flag if fingerprint is active
     * @param fingerPrintActive
     */


    SettingsExt.prototype.setBiometricState = function setBiometricState(fingerPrintActive) {
        Debug.Biometric && console.log(this.name, "setBiometricState: " + fingerPrintActive);
        nativeSettings[SettingsStoreKeys.FINGERPRINT] = fingerPrintActive;
        setToSettingsStore(SettingsStoreKeys.FINGERPRINT, fingerPrintActive);
    };
    /**
     * returns flag if fingerprint is active
     * @returns {boolean}
     */


    SettingsExt.prototype.getBiometricState = function getBiometricState() {
        Debug.Biometric && console.log(this.name, "getBiometricState: " + nativeSettings[SettingsStoreKeys.FINGERPRINT]);
        return nativeSettings[SettingsStoreKeys.FINGERPRINT];
    };
    /**
     *
     * @param secretToken
     * @param secretType
     * @param serialNo
     * @param activeUser
     */


    SettingsExt.prototype.setBiometricTokenForSecretType = function setBiometricTokenForSecretType(secretToken, secretType, serialNo, activeUser) {
        var snNr = detectSerialNo(serialNo),
            user = detectActiveUser(activeUser);

        if (!user) {
            console.error("Couldn't save biometricVisuPw, no activeUser given or available!");
            return;
        }

        if (!snNr) {
            console.error("Couldn't save biometricVisuPw, no serialNo given or available!");
            return;
        }

        nativeSettings[SettingsStoreKeys.BIOMETRIC_SECRETS][secretType].tokenMap[snNr + "-" + user] = secretToken; //PersistenceComponent used, because Miniserver may not be active ->  no activeUser available

        PersistenceComponent.setBiometricSecretWithSecretTypeEnabled(secretType, secretToken !== null, snNr, user);
        setToSettingsStore(SettingsStoreKeys.BIOMETRIC_SECRETS, nativeSettings[SettingsStoreKeys.BIOMETRIC_SECRETS]);
    };
    /**
     * Sets the biometric token for the given secretType
     * @param secretType BiometricHelper.SecretType
     * @param serialNo
     * @param activeUser
     * @returns {*}
     */


    SettingsExt.prototype.getBiometricTokenForSecretType = function getBiometricTokenForSecretType(secretType, serialNo, activeUser) {
        var snNr = detectSerialNo(serialNo),
            user = detectActiveUser(activeUser);

        if (!user) {
            console.error("Couldn't execute getBiometricTokenForSecretType(" + secretType + "), no activeUser given or available!");
            return;
        }

        if (!snNr) {
            console.error("Couldn't execute getBiometricTokenForSecretType(" + secretType + "), no serialNo given or available!");
            return;
        }

        return nativeSettings[SettingsStoreKeys.BIOMETRIC_SECRETS][secretType].tokenMap[snNr + "-" + user];
    };
    /**
     * Sets if the biometric secret is enabled (VisuPw or UserPW)
     * @param secretType BiometricHelper.SecretType
     * @param enabled
     * @param [serialNo]
     * @param [activeUser]
     */


    SettingsExt.prototype.setBiometricSecretWithSecretTypeEnabled = function setBiometricSecretWithSecretTypeEnabled(secretType, enabled, serialNo, activeUser) {
        var snNr = detectSerialNo(serialNo),
            user = detectActiveUser(activeUser);

        if (!user) {
            console.error("Couldn't execute setBiometricSecretWithSecretTypeEnabled(" + secretType + "), no activeUser given or available!");
            return;
        }

        if (!snNr) {
            console.error("Couldn't execute setBiometricSecretWithSecretTypeEnabled(" + secretType + "), no serialNo given or available!");
            return;
        }

        nativeSettings[SettingsStoreKeys.BIOMETRIC_SECRETS][secretType].enabledMap[snNr + "-" + user] = enabled;
        setToSettingsStore(SettingsStoreKeys.BIOMETRIC_SECRETS, nativeSettings[SettingsStoreKeys.BIOMETRIC_SECRETS]);
    };
    /**
     * Checks if the biometric secret is enabled (VisuPw or UserPW)
     * @param secretType BiometricHelper.SecretType
     * @param [serialNo]
     * @param [activeUser]
     * @returns {*}
     */


    SettingsExt.prototype.isBiometricSecretWithTypeEnabled = function isBiometricSecretWithTypeEnabled(secretType, serialNo, activeUser) {
        var snNr = detectSerialNo(serialNo),
            user = detectActiveUser(activeUser);

        if (!user) {
            console.error("Couldn't get isBiometricSecretWithTypeEnabled(" + secretType + "), no activeUser given or available!");
            return;
        }

        if (!snNr) {
            console.error("Couldn't get isBiometricSecretWithTypeEnabled(" + secretType + "), no serialNo given or available!");
            return;
        }

        return nativeSettings[SettingsStoreKeys.BIOMETRIC_SECRETS][secretType].enabledMap[snNr + "-" + user];
    };
    /**
     * Sets the onboarding state of the given secretType
     * @param secretType BiometricHelper.SecretType
     * @param didOnboard
     * @param [serialNo]
     */


    SettingsExt.prototype.setShowBiometricOnboardDialogForSecretType = function setShowBiometricOnboardDialogForSecretType(didOnboard, secretType, serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr) {
            if (typeof settings.miniservers[snNr].didOnboardBiometricSecretTypeMap !== "object") {
                settings.miniservers[snNr].didOnboardBiometricSecretTypeMap = {};
            }

            settings.miniservers[snNr].didOnboardBiometricSecretTypeMap[secretType] = didOnboard;
            this.saveSettings();
        } else {
            console.error("Couldn't save setShowBiometricOnboardDialogForSecretType flag for secretType '" + secretType + "', no serialNo given or available!");
        }
    };
    /**
     * Gets the onboarding state of the given secretType
     * @param secretType BiometricHelper.SecretType
     * @param [serialNo]
     */


    SettingsExt.prototype.didShowBiometricOnboardDialogForSecretType = function didShowBiometricOnboardDialogForSecretType(secretType, serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr) {
            if (typeof settings.miniservers[snNr].didOnboardBiometricSecretTypeMap !== "object") {
                settings.miniservers[snNr].didOnboardBiometricSecretTypeMap = {};
            }

            return settings.miniservers[snNr].didOnboardBiometricSecretTypeMap[secretType];
        } else {
            console.error("Couldn't get didShowBiometricOnboardDialogForSecretType flag for secretType '" + secretType + "', no serialNo given or available!");
        }
    };
    /**
     * saves a flag if a specific instruction was visible for the user
     * @param flagType of the instruction
     */


    SettingsExt.prototype.setAppInstructionFlag = function setAppInstructionFlag(flagType) {
        settings.instructionFlags[flagType] = true;
        this.saveSettings();
    };
    /**
     * returns the value of a specific instruction flag
     * @param flagType
     * @returns {boolean}
     */


    SettingsExt.prototype.getAppInstructionFlag = function getAppInstructionFlag(flagType) {
        return !!settings.instructionFlags[flagType];
    }; // Miniserver settings

    /**
     * saves a flag if the home screen should be active or not
     * @param active
     * @param [serialNo]
     */


    SettingsExt.prototype.activateHomeScreen = function activateHomeScreen(active, serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr) {
            settings.miniservers[snNr].homeScreen.activated = active;
            this.saveSettings();
        } else {
            console.error("Couldn't save home screen activation, no serialNo given or available!");
        }
    };
    /**
     * saves the widget object
     * @param widget
     * @param [serialNo]
     */


    SettingsExt.prototype.setHomeScreenWidget = function setHomeScreenWidget(widget, serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr) {
            settings.miniservers[snNr].homeScreen.widget = widget;
            this.saveSettings();
        } else {
            console.error("Couldn't save widget object, no serialNo given or available!");
        }
    };

    SettingsExt.prototype.getHomeScreenWidget = function setHomeScreenWidget(serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr) {
            return settings.miniservers[snNr].homeScreen.widget;
        } else {
            console.error("Couldn't save widget object, no serialNo given or available!");
        }
    };
    /**
     * used to save an arbitrary data-package for this Miniserver
     * @param key   the key used to retrieve data from the MS Info Store.
     * @param value an object containing the infos to store for this miniserver
     * @param [serialNo]
     * @returns {boolean}   whether or not the information was stored.
     */


    SettingsExt.prototype.saveForMiniserver = function saveForMiniserver(key, value, serialNo) {
        var kvStore = this._getKvStoreFor(serialNo),
            result = false;

        if (kvStore) {
            kvStore[key] = value;
            this.saveSettings();
            result = true;
        } else {
            console.error("Couldn't save value for " + key + ", no kvStore for this Miniserver!");
            result = false;
        }

        return result;
    };
    /**
     * used to save an arbitrary data-package for this Miniserver
     * @param key   the key used to retrieve data from the MS Info Store.
     * @param [serialNo]
     */


    SettingsExt.prototype.getForMiniserver = function getForMiniserver(key, serialNo) {
        var kvStore = this._getKvStoreFor(serialNo),
            value = null;

        if (kvStore) {
            value = kvStore[key];
        }

        return value;
    };
    /**
     * saves a flag if the manual configured favorites should be active or not
     * @param active
     * @param [serialNo]
     */


    SettingsExt.prototype.activateManualFavorites = function activateManualFavorites(active, serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr) {
            settings.miniservers[snNr].manualFavorites.activated = active;
            this.saveSettings();
        } else {
            console.error("Couldn't save manual favorites activation, no serialNo given or available!");
        }
    };
    /**
     * saves the manual configured favorites object
     * @param favorites
     * @param [serialNo]
     */


    SettingsExt.prototype.setManualFavorites = function setManualFavorites(favorites, serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr) {
            settings.miniservers[snNr].manualFavorites.favorites = favorites;
            this.saveSettings();
        } else {
            console.error("Couldn't save manual favorites object, no serialNo given or available!");
        }
    };
    /**
     * saves a flag if the manual configured device specific favorites should be active or not
     * @param active
     * @param [serialNo]
     */


    SettingsExt.prototype.activateDeviceFavorites = function activateDeviceFavorites(active, serialNo, username) {
        var snNr = detectSerialNo(serialNo),
            usrName = detectActiveUser(username);

        if (snNr && usrName) {
            if (!settings.miniservers[snNr].sortingDeviceFavorites.hasOwnProperty(usrName)) {
                settings.miniservers[snNr].sortingDeviceFavorites[usrName] = {
                    activated: DefaultSettings.Miniserver.DeviceFavorites.ACTIVATED,
                    favorites: DefaultSettings.Miniserver.DeviceFavorites.FAVORITES
                };
            }

            settings.miniservers[snNr].sortingDeviceFavorites[usrName].activated = active;
            this.saveSettings();
        } else {
            console.error("Couldn't save device favorites activation, no serialNo given or available!");
        }
    };
    /**
     *
     * @param serialNo
     * @param ratedCtrls
     * @param favCtrls
     * @param favRooms
     * @param favCats
     */


    SettingsExt.prototype.initializeDeviceFavorites = function _initializeDeviceFavorites(serialNo, ratedCtrls, favCtrls, favRooms, favCats) {
        var favorites = {
                controls: {},
                rooms: {},
                cats: {},
                sorting: {}
            },
            mapFn = function (src, trgt) {
                // iterate over each obj inside src (if src is provided)
                src && src.forEach(function (obj) {
                    // check if it qualifies to be stored inside the deviceFavorites
                    if (obj.defaultRating > 0 || obj.isFavorite) {
                        // it is, save. No need to check for duplicates.
                        trgt[obj.uuidAction || obj.uuid] = {
                            isFavorite: obj.isFavorite,
                            rating: obj.defaultRating || 0
                        };
                    }
                });
            };

        try {
            mapFn(ratedCtrls, favorites.controls);
            mapFn(favCtrls, favorites.controls);
            mapFn(favRooms, favorites.rooms);
            mapFn(favCats, favorites.cats);
            favorites.sorting = ActiveMSComponent.getSortingStructure();
        } catch (ex) {
            console.error("An error occurred while trying to initialize the device favorites.");
        }

        return this.setDeviceFavorites(favorites, serialNo);
    };
    /**
     * saves the manually configured devices specific favorites object
     * @param favorites
     * @param [serialNo]
     */


    SettingsExt.prototype.setDeviceFavorites = function setDeviceFavorites(favorites, serialNo, activeUser) {
        var snNr = detectSerialNo(serialNo),
            user = detectActiveUser(activeUser);

        if (!user) {
            console.error("Couldn't set device favs, no activeUser given or available!");
            return;
        }

        if (!snNr) {
            console.error("Couldn't set device favs, no serialNo given or available!");
            return;
        }

        if (snNr && settings.miniservers[snNr].hasOwnProperty("sortingDeviceFavorites") && settings.miniservers[snNr].sortingDeviceFavorites.hasOwnProperty(user)) {
            settings.miniservers[snNr].sortingDeviceFavorites[user].favorites = favorites;
            this.saveSettings();
        } else {
            console.error("Couldn't save manual favorites object, no serialNo given or available!");
        }
    };

    SettingsExt.prototype.getDeviceFavoritesForUser = function getDeviceFavoritesForUser(serialNo, activeUser) {
        var snNr = detectSerialNo(serialNo),
            user = detectActiveUser(activeUser);

        if (!user) {
            console.error("Couldn't get device favs, no activeUser given or available!");
            return;
        }

        if (!snNr) {
            console.error("Couldn't get device favs, no serialNo given or available!");
            return;
        }

        if (!settings.miniservers[snNr]) {
            console.error(this.name, "no settings stored for MS with SNR: " + snNr);
        } else if (settings.miniservers[snNr].hasOwnProperty("sortingDeviceFavorites") && settings.miniservers[snNr].sortingDeviceFavorites.hasOwnProperty(user)) {
            return settings.miniservers[snNr].sortingDeviceFavorites[user];
        }

        return DefaultSettings.Miniserver.DeviceFavorites;
    };
    /**
     * saves a flag if sort by rating should be active or not
     * @param active
     * @param [serialNo]
     */


    SettingsExt.prototype.activateSortByRating = function activateSortByRating(active, serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr && snNr in settings.miniservers) {
            settings.miniservers[snNr].sortByRating = active;
            this.saveSettings();
        } else {
            console.error("Couldn't save sort by rating activation, no serialNo given or available!");
        }
    };
    /**
     * sets a flag of an instruction notification, stored for each miniserver
     * @param flag {UserInstructionFlag}
     * @param serialNo {string}
     */


    SettingsExt.prototype.setMSInstructionFlag = function setMSInstructionFlag(flag, serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr) {
            if (!settings.miniservers[snNr].hasOwnProperty("instructionFlags")) {
                settings.miniservers[snNr].instructionFlags = cloneObject(DefaultSettings.Miniserver.INSTRUCTION_FLAGS);
            }

            settings.miniservers[snNr].instructionFlags[flag] = true;
            this.saveSettings();
        } else {
            console.error("Couldn't set MSInstructionFlag, no serialNo given or available!");
        }
    };
    /**
     * returns the instruction flag for the given Miniserver
     * @param flag
     * @param serialNo
     * @returns {boolean}
     */


    SettingsExt.prototype.getMSInstructionFlag = function getMSInstructionFlag(flag, serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr && settings.miniservers[snNr] && settings.miniservers[snNr].instructionFlags[flag] !== undefined) {
            return settings.miniservers[snNr].instructionFlags[flag];
        } else {
            return false;
        }
    };
    /**
     * returns the whole miniserver settings object
     * @param serialNo
     * @returns {object}
     */


    SettingsExt.prototype.getMiniserverSettings = function getMiniserverSettings(serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr && settings.miniservers[snNr]) {
            if (!settings.miniservers[snNr].hasOwnProperty("instructionFlags")) {
                settings.miniservers[snNr].instructionFlags = cloneObject(DefaultSettings.Miniserver.INSTRUCTION_FLAGS);
            }

            return cloneObject(settings.miniservers[snNr]);
        } else {
            //console.error("Couldn't return miniserver settings, no serialNo given or available!");
            return null;
        }
    }; // ScreenSaver settings

    /**
     * saves the screen saver activation time
     * @param time
     */


    SettingsExt.prototype.setScreenSaverActivationTime = function setScreenSaverActivationTime(time) {
        settings.screenSaver.activationTime = time;
        this.saveSettings();
    };
    /**
     * saves the screen saver brighntess
     * @param brightness
     */


    SettingsExt.prototype.setScreenSaverBrightness = function setScreenSaverBrightness(brightness) {
        settings.screenSaver.brightness = brightness;
        this.saveSettings();
    };
    /**
     * saves the uuid of the audio zone the screensaver should be showing
     * @param active
     * @param [uuid]
     */


    SettingsExt.prototype.setScreenSaverAudioPlayerSettings = function setScreenSaverAudioPlayerSettings(mac, active, uuid) {
        settings.screenSaver[mac] = {
            shouldShowAudioPlayer: active,
            audioPlayerUuid: active ? uuid : null
        };
        this.saveSettings();
    };

    /**
     * returns the whole screenSaver settings object
     * @returns {object}
     */
    SettingsExt.prototype.getScreenSaverSettings = function getScreenSaverSettings() {
        return cloneObject(settings.screenSaver);
    };

    // region generic settings default selection

    SettingsExt.prototype.setDefaultSettingsForRoom = function setDefaultSettingsForRoom(useAmbientMode, roomUuid) {
        (Debug.AmbientMode || Debug.EcoScreen) && console.log("SettingsExt", "setDefaultSettingsForRoom: " + roomUuid + ", useAmbientMode=" + !!useAmbientMode);
        let ambientHandler = new AmbientModeSettings(settings);
        let ecoHandler = new EcoScreenSettings(settings);

        // detect entry point location
        const audioZones = roomUuid ? AmbientAndEcoUtils.getControlsOfTypes([ControlType.AUDIO_ZONE_V2], roomUuid) : [],
            defLocPlayer = audioZones.length > 0 ? audioZones[0] : null;
        let entryPointLocation = UrlStartLocation.LAST_POSITION;
        if (defLocPlayer) {
            entryPointLocation = UrlStartLocation.CONTROL + "/" + audioZones[0].uuidAction;
        }
        (Debug.AmbientMode || Debug.EcoScreen) && console.log("SettingsExt", "   -> entryPointLocation = " + entryPointLocation);
        PersistenceComponent.setEntryPointLocation(entryPointLocation);

        // configure ambient+screensaver
        let room = ActiveMSComponent.getStructureManager().getGroupByUUID(roomUuid, GroupTypes.ROOM);
        return Q.allSettled([
            ambientHandler.configureForRoom(useAmbientMode, room, defLocPlayer),
            ecoHandler.configureForRoom(room)
        ]);
    }

    /**
     * Returns the uuid of the room that the previously selected eco player was in.
     * @param [msSerialNo]  optional, activeMS is used if not provided.
     * @returns {string|null} uuid of room, or null.
     */
    SettingsExt.prototype.getOldEcoPlayerRoomUuid = function getOldEcoPlayerRoomUuid(msSerialNo) {
        let mac = msSerialNo || AmbientAndEcoUtils.activeMsMac();
        const oldEcoSettings = settings && settings.screenSaver && settings.screenSaver[mac];
        let audioPlayer = AmbientAndEcoUtils.getOldEcoAudioPlayer(oldEcoSettings);
        return audioPlayer ? audioPlayer.room : null;
    }

    // endregion

    /**
     * Safety net, ensures that settings for an active MS exist.
     */
    SettingsExt.prototype.ensureActiveMsSettings = function ensureActiveMsSettings(msObj) {
        let activeMs = msObj || ActiveMSComponent.getActiveMiniserver();
        if (!activeMs || !activeMs.serialNo) {
            console.error("SettingsExt", "ensureActiveMsSettings won't work without an active MS!");
        } else if (settings.miniservers[activeMs.serialNo]) {
            console.log("SettingsExt", "ensureActiveMsSettings --> settings ready!");
            // exists, all okay
        } else {
            console.log("SettingsExt", "ensureActiveMsSettings --> no settings, initialize!");
            this.addMiniserver(activeMs);
        }
    }

    // region ambient mode settings

    SettingsExt.prototype.getAmbientModeSettings = function getAmbientModeSettings(initialize = true) {
        let handler = new AmbientModeSettings(settings);
        let ambientSettings = handler.getAmbientModeSettings(initialize);
        return ambientSettings;
    }

    SettingsExt.prototype.setAmbientModeSettings = function setAmbientModeSettings({ quickAccessControls, timeout, imageObject }) {
        let handler = new AmbientModeSettings(settings);
        if (!handler.setAmbientModeSettings({quickAccessControls, timeout, imageObject})) {
            console.error("SettingsExt", "setAmbientModeSettings failed, not ready");
        }
        return this.saveSettings();
    }

    SettingsExt.prototype.updateAmbientModeSetting = function updateAmbientModeSetting(updateSet = {}) {
        if (!AmbientAndEcoUtils.isReady()) {
            return;
        }
        let currSettings = this.getAmbientModeSettings(false) || {};
        let updatedSettings = {
            ...currSettings,
            ...updateSet
        };
        return this.setAmbientModeSettings(updatedSettings);
    }

    SettingsExt.prototype.saveWallpaper = function (imageData, sizingData) {
        return AmbientAndEcoUtils.saveWallpaper(imageData, sizingData);
    }

    SettingsExt.prototype.removeWallpaper = function (mac) {
        return AmbientAndEcoUtils.removeWallpaper(mac);
    }

    // endregion

    // region New Eco Screen

    SettingsExt.prototype.getEcoScreenSettings = function getEcoScreenSettings(initialize = true) {
        let handler = new EcoScreenSettings(settings);
        let ecoSettings = handler.getEcoScreenSettings(initialize);
        return ecoSettings;
    }

    SettingsExt.prototype.setEcoScreenSettings = function setEcoScreenSettings({ timeout, showTime, usePresence, presenceControls }) {
        let handler = new EcoScreenSettings(settings);
        if (!handler.setEcoScreenSettings({timeout, showTime, usePresence, presenceControls})) {
            console.error("SettingsExt", "setEcoScreenSettings failed - not ready");
        }
        this.saveSettings();
    }

    SettingsExt.prototype.updateEcoScreenSetting = function updateEcoScreenSetting(updateSet = {}) {
        if (!AmbientAndEcoUtils.isReady()) {
            return;
        }
        let currSettings = this.getEcoScreenSettings(false) || {};
        let updatedSettings = {};
        Object.assign(updatedSettings, currSettings);
        Object.assign(updatedSettings, updateSet);
        this.setEcoScreenSettings(updatedSettings);
    }

    // endregion


    // EntryPoint settings
    /**
     * saves a flag if the entry point should be active or not
     * @param active
     */


    SettingsExt.prototype.activateEntryPoint = function activateEntryPoint(active) {
        settings.entryPoint.activated = active;
        this.saveSettings();
    };
    /**
     * returns the whole entry point settings object
     * @returns {object}
     */


    SettingsExt.prototype.getEntryPointSettings = function getEntryPointSettings() {
        return cloneObject(settings.entryPoint);
    };


    /**
     * saves the entry point location uuid, this can be a Room, Cat, or a Tabs ScreenState
     * @param location
     * @param [serialNo]
     */
    SettingsExt.prototype.setEntryPointLocation = function setEntryPointLocation(location, serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr) {
            settings.miniservers[snNr].entryPointLocation = location;
            this.saveSettings();
        } else {
            console.error("Couldn't save entry point location, no serialNo given or available!");
        }
    };


    /**
     * saves the entry point location uuid, this can be a Room, Cat, or a Tabs ScreenState
     * @param viewed
     * @param [serialNo]
     */
    SettingsExt.prototype.setWelcomeScreenViewed = function setWelcomeScreenViewed(viewed, serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr) {
            settings.miniservers[snNr].welcomeScreenViewed = viewed;
            this.saveSettings();
        } else {
            console.error("Couldn't save welcomeScreenViewed flag, no serialNo given or available!");
        }
    };

    SettingsExt.prototype.setAmbientOnboardingViewed = function setWAmbientOnboardingViewed(viewed, serialNo) {
        var snNr = detectSerialNo(serialNo);

        if (snNr) {
            settings.miniservers[snNr].ambientOnboardingShown = viewed;
            this.saveSettings();
        } else {
            console.error("Couldn't save ambientOnboardingShown flag, no serialNo given or available!");
        }
    };


    SettingsExt.prototype.setLastSelectedCodeTouch = function setLastSelectedCodeTouch(codeTouchUuid, msSerialNumber) {
        var snNr = detectSerialNo(msSerialNumber);

        if (snNr) {
            if (codeTouchUuid) {
                settings.miniservers[snNr].userManagement.selectedNfcCodeTouch = codeTouchUuid;
                this.saveSettings();
            }
        } else {
            console.error("Couldn't save selectedNfcCodeTouch flag, no serialNo given or available!");
        }
    };

    SettingsExt.prototype.getLastSelectedCodeTouch = function getLastSelectedCodeTouch(msSerialNumber) {
        var snNr = detectSerialNo(msSerialNumber);

        if (snNr) {
            return cloneObject(settings.miniservers[snNr].userManagement.selectedNfcCodeTouch);
        }
    };
    /**
     * returns if the quickActionMenuAcceptScreen should be shown
     * @returns {boolean}
     */


    SettingsExt.prototype.shouldShowQuickActionMenuAcceptScreen = function shouldShowQuickActionMenuAcceptScreen() {
        return !cloneObject(settings.quickActionMenuAcceptScreenViewed);
    };
    /**
     * set the flag if the quickActionMenuAcceptScreen has already been viewed
     * @param viewed
     */


    SettingsExt.prototype.setQuickActionMenuAcceptScreenViewed = function setQuickActionMenuAcceptScreenViewed(viewed) {
        settings.quickActionMenuAcceptScreenViewed = viewed;
        this.saveSettings();
    };
    /**
     * saves the presence room uuid
     * @param roomUUID
     * @param serialNo
     * @param alsoEntryPointLocation
     */


    SettingsExt.prototype.setPresenceRoom = function setPresenceRoom(roomUUID, serialNo, alsoEntryPointLocation) {
        var snNr = detectSerialNo(serialNo);

        if (snNr) {
            settings.miniservers[snNr].presenceRoom = roomUUID;
            this.saveSettings(true);
        } else {
            console.error("Couldn't save presence room uuid, no serialNo given or available!");
        }

        alsoEntryPointLocation && this.setEntryPointLocation(UrlStartLocation.LIKE_PRESENCE_DETECTION);
    };
    /**
     * Stores a key-value pair in the local storage
     * @param key identifier of the entry
     * @param {string} value of the entry
     */


    SettingsExt.prototype.setLocalStorageItem = function setLocalStorage(key, value) {
        if (PlatformComponent.isIOS()) {
            // iOS localStorage is volatile, so we need to make our own storage
            settings.LOCAL_STORAGE[key] = value;
            this.saveSettings();
        } else {
            localStorage.setItem(key, value);
        }
    };

    SettingsExt.prototype.setLocalStorageItemWithTtl = function setLocalStorageItemWithTtl(key, value, ttl = 3600) {
        this.setLocalStorageItem(key, JSON.stringify({value: value, validUntil: moment().unix() + ttl}));
    };
    SettingsExt.prototype.getLocalStorageItemWithTtl = function getLocalStorageItemWithTtl(key) {
        let rawResult = this.getLocalStorageItem(key),
            ttldObject, result;
        try {
            ttldObject = JSON.parse(rawResult);
            if (ttldObject.hasOwnProperty("validUntil") && ttldObject.validUntil > moment().unix()) {
                result = ttldObject.value;
            }
        } catch (ex) {
        }
        return result;
    };

    /**
     * Retrieves a value of the local storage
     * @param key identifier of the entry
     * @returns {*} value of the entry
     */


    SettingsExt.prototype.getLocalStorageItem = function getLocalStorage(key) {
        if (PlatformComponent.isIOS()) {
            // iOS localStorage is volatile, so we need to make our own storage
            return settings.LOCAL_STORAGE[key];
        } else {
            return localStorage.getItem(key);
        }
    };
    /**
     * Removes a given key from the local storage
     * @param key identifier of the entry
     */


    SettingsExt.prototype.removeLocalStorageItem = function removeLocalStorageItem(key) {
        if (PlatformComponent.isIOS()) {
            // iOS localStorage is volatile, so we need to make our own storage
            delete settings.LOCAL_STORAGE[key];
            this.saveSettings();
        } else {
            localStorage.removeItem(key);
        }
    }; // Expert Mode

    /**
     * returns true/false whether the expert mode is enabled or disabled
     * @param serialNo {string}
     * @returns {boolean}
     */

    /*SettingsExt.prototype.expertModeEnabled = function expertModeEnabled(serialNo) {
        var snNr = detectSerialNo(serialNo);
        if (snNr) {
            return !!settings.miniservers[snNr].expertMode;
        } else {
            console.error("Couldn't return miniserver settings, no serialNo given or available!");
            return false;
        }
    };*/

    /**
     * enables/disables expert mode
     * @param enabled {boolean}
     * @param serialNo {string}
     */

    /*SettingsExt.prototype.setExpertModeEnabled = function setExpertModeEnabled(enabled, serialNo) {
        var snNr = detectSerialNo(serialNo);
        if (snNr) {
            settings.miniservers[snNr].expertMode = enabled;
            this.saveSettings();
            NavigationComp.dispatchEventToUI(NavigationComp.UiEvents.ExpertModeChanged, enabled);
        } else {
            console.error("Couldn't save expert mode setting, no serialNo given or available!");
        }
    };*/
    // Private methods

    /**
     * Used to get a reference to the kvStore of a miniserver identified by its serial. The result may be null
     * if there are no settings for this Miniserver at all.
     * @param serialNo  the serial for which to retrieve the kvStore
     * @returns {*} the reference to the kvStore of this Miniserver. May be null!
     * @private
     */


    SettingsExt.prototype._getKvStoreFor = function _getKvStoreFor(serialNo) {
        var snNr = detectSerialNo(serialNo),
            kvStore = null;

        if (snNr && settings.miniservers[snNr]) {
            if (!settings.miniservers[snNr].hasOwnProperty("kvStore")) {
                settings.miniservers[snNr].kvStore = {};
            }

            kvStore = settings.miniservers[snNr].kvStore;
        }

        return kvStore;
    };
    /**
     * Tries to get a valid serialNo of a given number or the currently connected miniserver serialNo
     * @param [givenSerialNo]
     * @returns {*}
     */


    var detectSerialNo = function detectSerialNo(givenSerialNo) {
        var snNr;

        if (givenSerialNo) {
            snNr = givenSerialNo;
        } else if (ActiveMSComponent.getMiniserverSerialNo()) {
            snNr = ActiveMSComponent.getMiniserverSerialNo(); // use this method, to make sure we get a serialNo!
        }

        return snNr;
    };
    /**
     * Gets the activeUser of the currently connected Miniserver
     * @param [givenActiveUser]
     * @returns {*}
     */


    var detectActiveUser = function detectActiveUser(givenActiveUser) {
        var activeUser,
            activeMS = ActiveMSComponent.getActiveMiniserver();

        if (givenActiveUser) {
            activeUser = givenActiveUser;
        } else if (activeMS && activeMS.activeUser) {
            activeUser = activeMS.activeUser;
        }

        return activeUser;
    };
    /**
     * This function adds the property instructionFlags, manualFavorites, screenSaver and wallmount/entryPoint to the given object
     * @param settings the settings
     * @returns {*}
     */


    var fixSettingsStructure = function fixSettingsStructure(settings) {
        if (!settings.hasOwnProperty("instructionFlags")) {
            settings.instructionFlags = {};
        }

        for (var serialNo in settings.miniservers) {
            if (settings.miniservers.hasOwnProperty(serialNo)) {
                var miniserverSettings = settings.miniservers[serialNo];

                if (!miniserverSettings.hasOwnProperty("instructionFlags")) {
                    miniserverSettings.instructionFlags = cloneObject(DefaultSettings.Miniserver.INSTRUCTION_FLAGS);
                }

                if (miniserverSettings.hasOwnProperty("wallmountRoom")) {
                    miniserverSettings.entryPointLocation = miniserverSettings.wallmountRoom;
                    delete miniserverSettings.wallmountRoom;
                }

                if (!miniserverSettings.userManagement) {
                    // initialize the last selected nfc code touch in the nfcLearnTagScreen
                    miniserverSettings.userManagement = {
                        selectedNfcCodeTouch: DefaultSettings.Miniserver.USER_MANAGEMENT.SELECTED_NFC_CODE_TOUCH
                    };
                }
            }
        }

        if (!settings.hasOwnProperty("manualFavorites")) {
            settings.manualFavorites = {};
        }

        if (!settings.hasOwnProperty("screenSaver")) {
            settings.screenSaver = {};
        }

        if (!settings.hasOwnProperty("entryPoint")) {
            settings.entryPoint = {};
        }

        convertWallmountToEntryPointSettings(settings); // The App is in darkMode per default now, override any other old settings to ensure we use the DarkMode!

        if (!settings.darkMode) {
            Debug.Persistence && console.info("Enabling DarkMode per default!");
            settings.darkMode = DefaultSettings.DARK_MODE;
        } // Activate tile representation


        if (!settings.hasOwnProperty("tileRepresentation")) {
            settings.tileRepresentation = DefaultSettings.TILE_REPRESENTATION;
        }

        if (!settings.hasOwnProperty("SYNC")) {
            settings.SYNC = {
                ENABLED: DefaultSettings.SYNC.ENABLED,
                LAST_SYNC: DefaultSettings.SYNC.LAST_SYNC,
                LAST_KNOWN_BACKUP: DefaultSettings.SYNC.LAST_KNOWN_BACKUP
            };
        }

        if (!settings.hasOwnProperty("LOCAL_STORAGE")) {
            settings.LOCAL_STORAGE = DefaultSettings.LOCAL_STORAGE;
        }

        return settings;
    };

    var getFromSettingsStore = function getFromSettingsStore(key) {
        var def = Q.defer();
        var objTemp = {};
        objTemp[key] = undefined;
        settingsStore.get(function (value) {
            objTemp[key] = JSON.parse(value);
            def.resolve(objTemp);
        }, function (e) {
            def.resolve(objTemp);
        }, key);
        return def.promise;
    };

    var setToSettingsStore = function setToSettingsStore(key, value) {
        var def = Q.defer();
        var objTemp = {};
        objTemp[key] = JSON.stringify(value);
        settingsStore.set(function (value) {
            def.resolve();
        }, function (e) {
            def.reject(e);
        }, objTemp);
        return def.promise;
    };
    /**
     * Converts the Wallmount Settings to EntryPoint Settings
     */


    var convertWallmountToEntryPointSettings = function convertWallmountToEntryPointSettings(settings) {
        var wallmountSettings = settings.wallmount;

        if (wallmountSettings) {
            if (wallmountSettings.hasOwnProperty("activated")) {
                settings.entryPoint.activated = settings.wallmount.activated;
            }

            if (wallmountSettings.hasOwnProperty("alwaysUseLastRoom")) {
                settings.entryPoint.entryPointLocation = UrlStartLocation.LAST_POSITION;
            } // delete the wallmount settings, we now have the new room Mode settings


            delete settings.wallmount;
        }
    };

    class AmbientAndEcoUtils {
        constructor() {
        }

        static identifyRoomByLocation({location}) {
            let room = null;
            if (location.hasPrefix(UrlStartLocation.ROOM)) {
                try {
                    let roomUuid = location.split("/")[1];
                    room = ActiveMSComponent.getStructureManager().getGroupByUUID(roomUuid, GroupTypes.ROOM);
                } catch (ex) {
                    room = null;
                }
            }
            return room;
        }

        static getAppEntryPoint(mac, ignoreAmbient = false) {
            const entryPointLocation = EntryPointHelper.getLocation();
            const entryPointName = EntryPointHelper.getLocationName(entryPointLocation);

            if (!entryPointLocation || !entryPointName) {
                return {
                    name: _('favorites.title'),
                    location: UrlStartLocation.FAVORITES
                }
            }

            return {
                name: entryPointName,
                location: entryPointLocation
            }
        }

        static getOldEcoAudioPlayer(oldEco) {
            (Debug.AmbientMode || Debug.EcoScreen) && console.log("AmbientAndEcoUtils", "getOldEcoAudioPlayer: oldEco=" + JSON.stringify(oldEco));
            if (oldEco.audioPlayerUuid) {
                (Debug.AmbientMode || Debug.EcoScreen) && console.log("AmbientAndEcoUtils", "getOldEcoAudioPlayer >> uuid " + oldEco.audioPlayerUuid);
                let playerCtrl = ActiveMSComponent.getStructureManager().getControlByUUID(oldEco.audioPlayerUuid);
                (Debug.AmbientMode || Debug.EcoScreen) && console.log("AmbientAndEcoUtils", "getOldEcoAudioPlayer >> player = " + JSON.stringify(playerCtrl));
                return playerCtrl;
            } else {
                return null;
            }
        }

        static ensureBool(toCheck) {
            if (typeof toCheck === "boolean") {
                return toCheck;
            } else if (typeof toCheck === "string") {
                return toCheck === "true";
            } else if (typeof toCheck === "number") {
                return toCheck !== 0;
            } else {
                return !!toCheck;
            }
        }

        static isReady() {
            return !!ActiveMSComponent.getActiveMiniserver();
        }

        static activeMsMac() {
            return ActiveMSComponent.getActiveMiniserverSerial();
        }

        /**
         *
         * @param types:array the types for which to filter
         * @param roomUuid:string only controls within the specified room are returend.
         */
        static getControlsOfTypes(types, roomUuid) {
            let typeMap = {};
            types.forEach(type => {
                typeMap[type] = true
            });
            return ActiveMSComponent.getStructureManager().getControlsInGroup(GroupTypes.ROOM, roomUuid, true).filter(ctrl => {
                return typeMap[ctrl.controlType] === true;
            });
        }

        static saveWallpaper(imageData, sizingData) {
            let imageObject = {
                source: {
                    uri: "",
                    sizing: sizingData
                },
                author: imageData.author || "",
                authorLink: imageData.authorLink || ""
            }
            const mac = this.activeMsMac();
            return NavigationComp.showWaitingFor(Q.all([
                PersistenceComponent.updateAmbientModeSetting({
                    imageObject
                }),
                PersistenceComponent.saveFile("AmbientBG_" + mac, imageData.uri).catch(err => {
                    // do something if the file couldn't be saved, because it is too large for example
                    /*NavigationComp.showPopup({
                        title: "Error",
                        message: "Image couldn't be saved",
                        buttonOk: true
                    }); */
                })
            ]));
        }

        static removeWallpaper(mac) {
            if (mac) {
                return PersistenceComponent.deleteFile("AmbientBG_" + mac);
            }
        }
    }

    class AmbientModeSettings {
        constructor(settings) {
            this._settings = settings;
        }

        getAmbientModeSettings(initialize) {
            if (!AmbientAndEcoUtils.isReady()) {
                return null;
            }
            try {
                const mac = AmbientAndEcoUtils.activeMsMac();
                return this._getStoredAmbientSettings(mac) || (initialize ? this._initializeNewAmbientSettings(mac) : null);
            } catch(ex) {
                console.error("AmbientModeSettings", "getAmbientModeSettings failed! " + JSON.stringify(ex), ex);
                return null;
            }
        }

        configureForRoom(useAmbientMode, room, defLocPlayer) {
            const energyFlowMonitors = ActiveMSComponent.getStructureManager().getControlsByType(ControlType.EFM, true),
                efmUuid = energyFlowMonitors.length > 0 ? energyFlowMonitors[0].uuidAction : null;

            let timeout = useAmbientMode ? AmbientModeDefaults.TIMEOUT : 1,
                quickAccessControls = [];

            // configure quick-access-controls
            efmUuid && quickAccessControls.push(efmUuid);
            quickAccessControls.push(...this._getTopFavCtrlUuids(9, room ? room.uuid : null).filter(
                (ctrlUuid, idx) => {
                    return idx < (10 - quickAccessControls.length) && // don't add too many
                        ctrlUuid !== efmUuid && // don't add the efm twice
                        (defLocPlayer ? ctrlUuid !== defLocPlayer.uuidAction : true); // don't add the audioZone, if its the default loc.
                }));

            Debug.AmbientMode && console.log("AmbientModeSettings", "configureForRoom: active=" + useAmbientMode + ", room: " + (room ? room.name : "(no specific)") + ", audioPlayer? " + !!defLocPlayer);
            Debug.AmbientMode && console.log("AmbientModeSettings", "       -> timeout = " + timeout);
            Debug.AmbientMode && console.log("AmbientModeSettings", "   -> QuickAccess = " + quickAccessControls.join(", "));
            return this.setAmbientModeSettings({
                quickAccessControls, timeout
            });
        }

        setAmbientModeSettings({quickAccessControls, timeout, imageObject}) {
            if (!AmbientAndEcoUtils.isReady()) {
                return false;
            }
            const mac = AmbientAndEcoUtils.activeMsMac();
            this._settings.miniservers[mac].ambientModeSettings = this._createAmbientSettingsObject(timeout, quickAccessControls, imageObject);
            return true;
        }

        _getStoredAmbientSettings(mac) {
            if (!this._settings.miniservers[mac]) {
                console.error("AmbientModeSettings", "_getStoredAmbientSettings failed - no settings for ms " + mac);
                return null;
            }
            if (!this._settings.miniservers[mac].hasOwnProperty("ambientModeSettings")) {
                console.log("AmbientModeSettings", "_getStoredAmbientSettings, no ambientMode settings for ms " + mac);
                return null;
            }

            let ambientModeSettings = this._settings.miniservers[mac].ambientModeSettings

            if (ambientModeSettings.quickAccessControls?.length > AmbientUtils.MAX_SHORTCUTS) {
                // shortcuts can be more than the limit, if they were created before the limit was added to the app, we have to shrink the quickaccesscontrols to the length and save this in the settings
                console.warn("Too many shortcuts found from old settings: ", ambientModeSettings.quickAccessControls.length);
                ambientModeSettings.quickAccessControls = ambientModeSettings.quickAccessControls.slice(0, AmbientUtils.MAX_SHORTCUTS)
                PersistenceComponent.setAmbientModeSettings(ambientModeSettings);
            }

            if (ambientModeSettings) {
                // no more defaultLocation within the ambient mode.
                delete ambientModeSettings.defaultLocation;

                ambientModeSettings.timeout = parseInt(ambientModeSettings.timeout);
            }

            return ambientModeSettings;
        }

        _initializeNewAmbientSettings(mac) {
            return this._createAmbientSettingsObject(
                -1,
                this._getTopFavCtrlUuids());
        }

        _migrateAmbientFromOldEcoSettings(mac, oldEco) {
            let ambientTimeout, ambientQuickAccess;

            // timeout
            ambientTimeout = this._settings.screenSaver ? this._settings.screenSaver.timeout : AmbientModeDefaults.TIMEOUT;

            // quick access controls
            ambientQuickAccess = this._getTopFavCtrlUuids();

            return this._createAmbientSettingsObject(ambientTimeout, ambientQuickAccess);
        }

        _createAmbientSettingsObject(timeout = DefaultSettings.ScreenSaver.ACTIVATION_TIME, quickAccessCtrls = [], imageObject) {
            return {
                quickAccessControls: quickAccessCtrls, // array of uuids
                timeout: parseInt(timeout), // in seconds, -1 if disabled
                imageObject: imageObject
            }
        }

        _getTopFavCtrlUuids(maxControls = 9, roomUuid) {
            let ctrls;
            if (roomUuid) {
                ctrls = ActiveMSComponent.getStructureManager().getRatedControls(roomUuid, false);
            } else {
                ctrls = ActiveMSComponent.getStructureManager().getFavoriteControls();
            }

            return ctrls.splice(0, maxControls).map(ctrl => { return ctrl.uuidAction; });
        }
    }

    class EcoScreenSettings {

        constructor(settings) {
            this._settings = settings;
        }

        configureForRoom(room) {
            let presenceControls = room ? AmbientAndEcoUtils.getControlsOfTypes([ControlType.PRESENCE_DETECTOR], room.uuid) : [],
                isBedRoom = room && (room.type === RoomType.BEDROOM),
                usePresence = !isBedRoom && presenceControls.length > 0,
                timeout = usePresence ? 0 : (isBedRoom ? EcoScreenDefaults.BEDROOM_TIMEOUT : EcoScreenDefaults.TIMEOUT);

            Debug.EcoScreen && console.log("EcoScreenSettings", "configureForRoom: " + (room ? room.name : "(no specific)"));
            Debug.EcoScreen && console.log("EcoScreenSettings", "       -> timeout = " + timeout);
            Debug.EcoScreen && console.log("EcoScreenSettings", "      -> presence = " + presenceControls.map(obj => JSON.stringify(obj)).join(", "));
            Debug.EcoScreen && console.log("EcoScreenSettings", "      -> showTime = " + !isBedRoom);
            Debug.EcoScreen && console.log("EcoScreenSettings", "   -> usePresence = " + presenceControls.length > 0);
            return this.setEcoScreenSettings({
                timeout,
                presenceControls,
                showTime: !isBedRoom,
                usePresence: usePresence
            })
        }

        getEcoScreenSettings(initialize) {
            Debug.EcoScreen && console.log("EcoScreenSettings", "getEcoScreenSettings()");
            if (!AmbientAndEcoUtils.isReady()) {
                Debug.EcoScreen && console.log("EcoScreenSettings", "getEcoScreenSettings() => not ready yet");
                return;
            }
            const mac = AmbientAndEcoUtils.activeMsMac();
            Debug.EcoScreen && console.log("EcoScreenSettings", "getEcoScreenSettings() => mac: ", mac);
            let result = this._getStoredEcoScreenSettings(mac) || (initialize ? this._initializeEcoScreenSettings(mac) : null);
            Debug.EcoScreen && console.log("EcoScreenSettings", "getEcoScreenSettings() initialized: " + JSON.stringify(result));
            return result;
        }

        setEcoScreenSettings({timeout, showTime, usePresence, presenceControls}) {
            if (!AmbientAndEcoUtils.isReady()) {
                return false;
            }
            const mac = AmbientAndEcoUtils.activeMsMac();

            const newSettings = this._createEcoSettingsObject(timeout, showTime, usePresence, presenceControls);
            Debug.EcoScreen && console.log("EcoScreenSettings", "setEcoScreenSettings: " + JSON.stringify(newSettings));
            this._settings.miniservers[mac].ecoScreenSettings = newSettings;
            return true;
        }

        _getStoredEcoScreenSettings(mac) {
            Debug.EcoScreen && console.log("EcoScreenSettings", "_getStoredEcoScreenSettings() "  + mac);
            if (!this._settings.miniservers[mac]) {
                Debug.EcoScreen && console.error("EcoScreenSettings", "_getStoredEcoScreenSettings failed, no miniserver-settings for " + mac);
                return null;
            }
            if (!this._settings.miniservers[mac].hasOwnProperty("ecoScreenSettings")) {
                Debug.EcoScreen && console.error("EcoScreenSettings", "_getStoredEcoScreenSettings, no eco-screen-settings stored for " + mac);
                return null;
            }
            let storedSettings = this._settings.miniservers[mac].ecoScreenSettings;
            Debug.EcoScreen && console.error("EcoScreenSettings", "_getStoredEcoScreenSettings, stored eco screen settings= " + JSON.stringify(storedSettings));


            // filter types that are no longer supported - also there can only be one control to detect presence.
            let presenceCtrls = storedSettings.presenceControls;
            if (presenceCtrls && Array.isArray(presenceCtrls)) {
                try {
                    presenceCtrls = presenceCtrls.filter(pObj => {
                        return pObj.controlType === ControlType.PRESENCE_DETECTOR;
                    });
                    presenceCtrls = presenceCtrls.splice(0, 1);
                } catch (ex) {
                    console.error("EcoScreenSettings", "_getStoredEcoScreenSettings: failed trying to filter presenceControls!" + JSON.stringify(ex));
                    presenceCtrls = [];
                }
            }
            storedSettings.presenceControls = this._reducePresenceControlsForStorage(presenceCtrls);

            // ensure it's an int not a string.
            try {
                if (isNaN(storedSettings.timeout)) {
                    storedSettings.timeout = EcoScreenDefaults.TIMEOUT;
                }
                storedSettings.timeout = parseInt(storedSettings.timeout);
                storedSettings.showTime = AmbientAndEcoUtils.ensureBool(storedSettings.showTime);
                storedSettings.usePresence = AmbientAndEcoUtils.ensureBool(storedSettings.usePresence);
                if (storedSettings.usePresence && storedSettings.timeout > 0) {
                    storedSettings.timeout = 0;
                }
            } catch (ex) {
                console.error("EcoScreenSettings", "_getStoredEcoScreenSettings failed during ensureBool or parseInt " + JSON.stringify(ex));
            }

            Debug.EcoScreen && console.log("EcoScreenSettings", "getStoredSettings() got stored settings " + JSON.stringify(storedSettings));

            return storedSettings;
        }

        _initializeEcoScreenSettings(mac) {
            let oldEcoSettings = this._settings.screenSaver[mac];
            if (oldEcoSettings) {
                return this._migrateFromOldEcoScreenSettings(mac, oldEcoSettings);
            } else {
                return this._initializeNewEcoScreenSettings(mac);
            }
        }

        _migrateFromOldEcoScreenSettings(oldEco) {
            Debug.EcoScreen && console.log("EcoScreenSettings", "migrating settings from old settings " + JSON.stringify(oldEco));
            let presenceControls = [],
                timeout = EcoScreenDefaults.TIMEOUT,
                showTime = true; // if at all, we only have an audio player, so showing the time is ok.

            const audioPlayer = AmbientAndEcoUtils.getOldEcoAudioPlayer(oldEco);
            Debug.EcoScreen && console.log("EcoScreenSettings", "got audio player from old eco settings " + JSON.stringify(audioPlayer));

            if (audioPlayer) {
                presenceControls.push({
                    uuidAction: oldEco.audioPlayerUuid,
                    controlType: audioPlayer.controlType
                });
            }

            if (this._settings.screenSaver) {
                timeout = parseInt(this._settings.screenSaver.activationTime);
            }

            Debug.EcoScreen && console.log("EcoScreenSettings", "migrated settings ", {timeout, showTime, presenceControls});

            return this._createEcoSettingsObject(timeout, showTime, presenceControls.length > 0, presenceControls);
        }

        _initializeNewEcoScreenSettings(mac) {
            Debug.EcoScreen && console.log("EcoScreenSettings", "initializing new settings");

            let timeout = EcoScreenDefaults.TIMEOUT,
                showTime = true,
                presenceControls = [],
                ambientDefLoc = AmbientAndEcoUtils.getAppEntryPoint(mac, true);

            Debug.EcoScreen && console.log("EcoScreenSettings", "default location = ", ambientDefLoc);

            // eco timeout depends on the room type, 30seconds for bedrooms, 5 minutes for others
            let room, isBedRoom;
            room = ambientDefLoc ? AmbientAndEcoUtils.identifyRoomByLocation(ambientDefLoc) : null
            Debug.EcoScreen && console.log("EcoScreenSettings", "room = ", room);

            isBedRoom = room && room.type === RoomType.BEDROOM;

            if (isBedRoom) {
                timeout = EcoScreenDefaults.BEDROOM_TIMEOUT;
            } else if (room) {
                presenceControls.pushObject(this._getEcoPresenceControlFromRoom(room));
            }

            // if a presenceDetector is available, don't show the time, dimm the screen fully.
            if (presenceControls.length > 0 && presenceControls[0].controlType === ControlType.PRESENCE_DETECTOR) {
                showTime = false;
                timeout = 0;
            }

            return this._createEcoSettingsObject(timeout, showTime, presenceControls.length > 0, presenceControls);
        }

        _getEcoPresenceControlFromRoom(room) {
            const controls = ActiveMSComponent.getStructureManager().getControlsInGroup(GroupTypes.ROOM, room.uuid, true);
            let presenceControls = [];
            controls.forEach(ctrl => {
                switch (ctrl.controlType) {
                    case ControlType.PRESENCE_DETECTOR:
                        presenceControls.push(ctrl);
                        break;
                    default:
                        break;
                }
            });

            let chosenControl = null;
            if (presenceControls.length > 0) {
                chosenControl = presenceControls[0];
            }
            return chosenControl ? {
                uuidAction: chosenControl.uuidAction,
                controlType: chosenControl.controlType
            } : null;
        }

        _reducePresenceControlsForStorage(presenceControls) {
            if (!presenceControls) {
                console.error("EcoScreenSettings", "_reducePresenceControlsForStorage failed, no presenceControls!");
                return [];
            }
            return presenceControls.map(ctrl => {
                return {
                    uuidAction: ctrl.uuidAction,
                    controlType: ctrl.controlType
                }
            });
        }

        _createEcoSettingsObject(timeout, showTime, usePresence, presenceControls) {
            return {
                timeout: parseInt(timeout), // in seconds, -1 if disabled
                showTime: AmbientAndEcoUtils.ensureBool(showTime), // bool, whether or not to show the time or dimm the screen entirely
                usePresence: AmbientAndEcoUtils.ensureBool(usePresence), // bool, whether or not the selected controls are to be used to keep the screen lit
                presenceControls: presenceControls // uuid of the control to use for presence detection
            }
        }
    }

    return SettingsExt;
}]);
