'use strict';

define("ValueSelectorControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class ValueSelectorControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.min = newVals[this.control.states.min];
            this.states.max = newVals[this.control.states.max];
            this.states.step = newVals[this.control.states.step];
            this.states.value = newVals[this.control.states.value];

            if (!this.control.details.increaseOnly) {
                this.states.minReached = this.states.value <= this.states.min;
                this.states.maxReached = this.states.value >= this.states.max;
            }

            if (this.control.details.format) {
                this.states.formatedValue = lxFormat(this.control.details.format, this.states.value);
            } else if (this.states.value % 1 !== 0) {
                this.states.formatedValue = lxFormat("%.2f", this.states.value);
            } else {
                this.states.formatedValue = lxFormat("%.0f", this.states.value);
            }
        }

        getStateText() {
            return this.states.formatedValue;
        }

    };
});
