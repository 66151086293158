'use strict';

define("EnergyFlowMonitorControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    return class EnergyFlowMonitorControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {

            // put all states that arrive onto the states object.
            Object.keys(this.control.states).forEach(stateKey => {
                this.states[stateKey] = newVals[this.control.states[stateKey]];
            });

            this.states.productionPower = newVals[this.control.states.Ppwr];
            this.states.storagePower = newVals[this.control.states.Spwr];
            this.states.gridPower = newVals[this.control.states.Gpwr];

            this.states.priceImport = newVals[this.control.states.Pri];
            this.states.priceExport = newVals[this.control.states.Pre];
            this.states.co2Factor = newVals[this.control.states.CO2];

            // compute consumption
            this.states.consumptionPower = this.states.gridPower + this.states.productionPower + this.states.storagePower;
        }

        getStateTextForContent() {
            return null; // in the content, there is no state text!
        }

        getStateText() {
            let texts = [];
            if (this.states.productionPower !== 0) {
                texts.push(_("controls.energy-monitor.prod-short", {
                    production: lxUnitConverter.convertAndApply(this.control.actualFormat, this.states.productionPower, true)
                }))
            }
            if (this.states.consumptionPower !== 0) {
                texts.push(_("controls.energy-monitor.cons-short", {
                    consumption: lxUnitConverter.convertAndApply(this.control.actualFormat, this.states.consumptionPower, true)
                }))
            }
            return texts.join(SEPARATOR_SYMBOL);
        }

        getStateColor() {
            return window.Styles.colors.stateActive;
        }
    };
});
