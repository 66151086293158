'use strict';

/**
 * SandboxComponent
 * @param CommunicationComp required to send/receive commands
 * @param NavigationComp required for showing bad connection dialog
 */
export default ({names}) => {
    window[names.int].factory('SandboxComp', ['$injector', 'CommunicationComp', 'ActiveMSComp', 'PersistenceComp', 'SipAdapter', 'VideoAdapter', function ($injector, CommunicationComp, ActiveMSComp, PersistenceComp, SipAdapter, VideoAdapter) {
        // internal variables
        let weakThis, extension, ecoScreenExt, ambientModeExt, deviceActivityTrackerExt, commandExt, stateExt, statisticExt, statisticV2Ext, taskRecExt, notificationExt, menuSettingsExt, menuItemsExt,
            urlCmdHdlrExt, permissionExt, presetExt, badConnPopup;

        /**
         * c-tor of the SandboxComponent
         * @returns {object} exposed functions for other components
         * @constructor
         */

        function Sandbox() {
            weakThis = this
            this.name = "Sandbox"; // storing a reference to all extensions

            stateExt = new extension.State(this);
            commandExt = new extension.Command(this);
            statisticExt = new extension.Statistic(this);
            statisticV2Ext = new extension.StatisticV2(this);
            taskRecExt = new extension.TaskRecorder(this);
            notificationExt = new extension.Notification(this);
            menuSettingsExt = new extension.MenuSettings(this, this);
            menuItemsExt = new extension.MenuItems(this, this);
            urlCmdHdlrExt = new extension.UrlStartCommandHandler(this, this);
            permissionExt = new extension.Permission(this, this);
            deviceActivityTrackerExt = new extension.DeviceActivityTracker(this, this);
            ecoScreenExt = new extension.EcoScreen(this, this);
            ambientModeExt = new extension.AmbientMode(this, this);
            presetExt = new extension.ControlPreset(this, this); // external broadcasts

            CompChannel.on(CCEvent.StructureReady, function (event, newStructure) {
                CommunicationComp.register4StatusUpdates(weakThis);
                weakThis.emit(SandboxComp.ECEvent.ConnReady, newStructure);

                if (commandExt.sendPendingCommands() === false) {
                    weakThis.openStatusStream();
                } else {
                    setTimeout(function () {
                        weakThis.openStatusStream();
                    }, 1000);
                }
            });
            weakThis._allStatesReceivedPassed = false;
            CompChannel.on(CCEvent.ConnClosed, () => {
                weakThis.emit(SandboxComp.ECEvent.ConnClosed);
                weakThis._allStatesReceivedPassed = false;
            });
            CompChannel.on(CCEvent.ALL_STATES_RECEIVED, () => {
                // dispatching this each time it is called would result in spamming of the
                // quick actions & the persistence.

                // notify listener every time --> prevents in app notification issue
                weakThis.emit(SandboxComp.ECEvent.ALL_STATES_RECEIVED);

                if (!weakThis._allStatesReceivedPassed) {
                    weakThis._allStatesReceivedPassed = true;
                    QuickActionUtility.verifyQuickActions();
                }
            });
            CompChannel.on(CCEvent.StopMSSession, function () {
                weakThis.emit(SandboxComp.ECEvent.Reset);

                if (badConnPopup) {
                    // hide badConnectionPopup if visible!
                    NavigationComp.removePopup(badConnPopup);
                    badConnPopup = null;
                }
            }); // when someone requests a state update, do it.

            CompChannel.on(CCEvent.ForceStateUpdate, function () {
                weakThis.emit(SandboxComp.ECEvent.ForceStateUpdate);
            }); // Forward the Task-Recorder-Events onto the Component channel, e.g. the MediaServerComp needs to respond to it.

            weakThis.on(SandboxComp.ECEvent.TaskRecorderStart, function () {
                CompChannel.emit(CCEvent.TaskRecorderStart);
            });
            weakThis.on(SandboxComp.ECEvent.TaskRecorderEnd, function () {
                CompChannel.emit(CCEvent.TaskRecorderEnd);
                CompChannel.emit(CCEvent.ForceStateUpdate);
            }); // Forward Notification Events

            weakThis.on(SandboxComp.ECEvent.NotificationReceived, function (ev, notification) {
                CompChannel.emit(CCEvent.NotificationReceived, notification);
            });
            weakThis.on(SandboxComp.ECEvent.UnreadNotificationCount, function (ev, count) {
                CompChannel.emit(CCEvent.UnreadNotificationCount, count);
            });
            weakThis.on(SandboxComp.ECEvent.DeviceActivityChanged, (ev, {active}) => {
                CompChannel.emit(CCEvent.DeviceActivityChanged, {active})
            });


            // forward ambient & eco screen events to extensions.
            CompChannel.on(CCEvent.AmbientModeSettingChanged, function (ev, arg) {
                weakThis.emit(SandboxComp.ECEvent.AmbientModeSettingChanged, arg);
            });
            CompChannel.on(CCEvent.EcoScreenSettingChanged, function (ev, arg) {
                weakThis.emit(SandboxComp.ECEvent.EcoScreenSettingChanged, arg);
            });

            // forward compChannel events 1:1 to extensions
            CompChannel.on(CCEvent.Pause, function () {
                weakThis._isPaused = true;
                weakThis.emit(CCEvent.Pause);
            });
            CompChannel.on(CCEvent.Resume, function () {
                weakThis._isPaused = false;
                weakThis.emit(CCEvent.Resume);
            });
            CompChannel.on(CCEvent.Resign, function () {
                weakThis._isResigned = true;
                weakThis.emit(CCEvent.Resign);
            });
            CompChannel.on(CCEvent.Active, function () {
                weakThis._isResigned = false;
                weakThis.emit(CCEvent.Active);
            });
            CompChannel.on(CCEvent.StartMSSession, function () {
                weakThis.emit(CCEvent.StartMSSession);
            });
            CompChannel.on(CCEvent.PairedAppPropertiesChanged, function (ev, arg) {
                weakThis.emit(CCEvent.PairedAppPropertiesChanged, arg);
            });
            CompChannel.on(CCEvent.EcoScreenDarkenerActive, function (ev, arg) {
                weakThis.emit(CCEvent.EcoScreenDarkenerActive, arg);
            });


            return {
                isInForeground: () => {
                    return !weakThis._isPaused && !weakThis._isResigned;
                },
                send: weakThis.send.bind(weakThis),
                // with bad connection popup handling
                sendCommand: commandExt.sendCommand.bind(commandExt),
                // handles the task recorder & command queueing.
                sendWithPermission: weakThis.sendWithPermission.bind(weakThis),
                // will request a password if the permission isn't granted
                download: weakThis.download.bind(weakThis),
                // triggers a download via a download websocket
                checkPermission: permissionExt.checkPermission.bind(permissionExt),
                checkGrantedPermission: permissionExt.checkGrantedPermission.bind(permissionExt),
                getPermission: permissionExt.getPermission.bind(permissionExt),
                registerForPermission: permissionExt.registerForPermission.bind(permissionExt),
                registerForPermissions: permissionExt.registerForPermissions.bind(permissionExt),
                getPermissionFriendlyName: permissionExt.getPermissionFriendlyName.bind(permissionExt),
                hasAdminPermission: permissionExt.hasAdminPermission.bind(permissionExt),
                setVisuPasswordAutoInvalidation: commandExt.setVisuPasswordAutoInvalidation,
                hasCachedVisuPassword: commandExt.hasCachedVisuPassword,
                acquireVisuPassword: weakThis.acquireVisuPassword,
                setVisuPassword: commandExt.setVisuPassword.bind(commandExt),
                resetVisuPassword: commandExt.resetVisuPassword.bind(commandExt),
                on: this.on,
                // exposes the on method to be used by controls
                getImage: ActiveMSComp.getImage,
                getCurrentMiniserverUrl: weakThis.getCurrentMiniserverUrl,
                getStructureManager: weakThis.getStructureManager,
                getMiniserverTimeInfo: ActiveMSComp.getMiniserverTimeInfo,
                getMiniserverTimeAsFakeUTC: ActiveMSComp.getMiniserverTimeAsFakeUTC,
                removeFromTimeInfo: ActiveMSComp.removeFromTimeInfo,
                getTemperatureUnit: weakThis.getTemperatureUnit,
                getTemperatureForUI: weakThis.getTemperatureForUI,
                getTemperatureForComm: weakThis.getTemperatureForComm,
                loadStateContainers: stateExt.loadStateContainers,
                getStatesForUUID: stateExt.getStatesForUUID,
                getStateContainerForUUID: stateExt.getStateContainerForUUID,
                forceStatusUpdate: CompChannel.emit.bind(this, CCEvent.ForceStateUpdate),
                registerForStateChangesForUUID: stateExt.registerForStateChangesForUUID,
                registerFunctionForStateChangesForUUID: stateExt.registerFunctionForStateChangesForUUID,
                unregisterForStateChangesForUUID: stateExt.unregisterForStateChangesForUUID,
                getStatisticOutputsForUUID: stateExt.getStatisticOutputsForUUID,
                // TaskRecorder
                registerForTasks: taskRecExt.registerForTasks.bind(taskRecExt),
                unregisterForTasks: taskRecExt.unregisterForTasks.bind(taskRecExt),
                isRecordingTask: taskRecExt.isRecordingTask.bind(taskRecExt),
                // editing
                deleteTask: taskRecExt.deleteTask.bind(taskRecExt),
                updateTask: taskRecExt.updateTask.bind(taskRecExt),
                updateTaskStartTime: taskRecExt.updateTaskStartTime.bind(taskRecExt),
                // recording new task
                startTaskRecording: taskRecExt.startTaskRecording.bind(taskRecExt),
                stopTaskRecording: taskRecExt.stopTaskRecording.bind(taskRecExt),
                registerForNewTaskCommands: taskRecExt.registerForNewTaskCommands.bind(taskRecExt),
                unregisterForNewTaskCommands: taskRecExt.unregisterForNewTaskCommands.bind(taskRecExt),
                getRecordedTask: taskRecExt.getRecordedTask.bind(taskRecExt),
                addTask: taskRecExt.addTask.bind(taskRecExt),
                getTaskNameForControlCommand: taskRecExt.getTaskNameForControlCommand.bind(taskRecExt),

                // Statistic
                getMinimumDateOfControl: statisticExt.getMinimumDateOfControl.bind(stateExt),
                getStatisticData: statisticExt.getStatisticData.bind(statisticExt),
                stopLoadingStatisticData: statisticExt.stopLoadingStatisticData.bind(statisticExt),
                deleteAllStatisticData: statisticExt.deleteAllStatisticData.bind(statisticExt),
                deleteStatisticDataOf: statisticExt.deleteStatisticDataOf.bind(statisticExt),

                // StatisticV2
                getStatisticRaw: statisticV2Ext.getStatisticRaw.bind(statisticV2Ext),
                getStatisticDiff: statisticV2Ext.getStatisticDiff.bind(statisticV2Ext),
                combineStatistics: statisticV2Ext.combineStatistics.bind(statisticV2Ext),
                getCsvOfPackage: statisticV2Ext.getCsvOfPackage.bind(statisticV2Ext),

                // Notifications
                notificationsAvailableForActiveMiniserver: notificationExt.notificationsAvailableForActiveMiniserver.bind(notificationExt),
                getInAppNotificationSettings: notificationExt.getInAppSettings.bind(notificationExt),
                isInAppNotificationSettingTurnedOn: notificationExt.isInAppNotificationSettingTurnedOn.bind(notificationExt),
                getPushNotificationSettings: notificationExt.getPushSettings.bind(notificationExt),
                updateInAppNotificationSettings: notificationExt.updateInAppSettings.bind(notificationExt),
                updatePushNotificationSettings: notificationExt.updatePushSettings.bind(notificationExt),
                determineNotificationStyle: notificationExt.determineNotificationStyle.bind(notificationExt),
                registerNotificationHandler: notificationExt.registerNotificationHandler.bind(notificationExt),
                unregisterNotificationHandler: notificationExt.unregisterNotificationHandler.bind(notificationExt),
                getNumberOfUnreadNotifications: notificationExt.getNumberOfUnreadNotifications.bind(notificationExt),
                showNotificationHistory: notificationExt.showNotificationHistory.bind(notificationExt),
                markNotificationAsRead: notificationExt.markNotificationAsRead.bind(notificationExt),
                markAllNotificationAsRead: notificationExt.markAllNotificationAsRead.bind(notificationExt),
                setNotificationsDndActive: notificationExt.setDndActive.bind(notificationExt),
                // Menu Items Extension
                getMenuItems: menuItemsExt.getMenuItems.bind(menuItemsExt),
                getBurgerMenuFirstSectionItems: menuItemsExt.getBurgerMenuFirstSectionItems.bind(menuItemsExt),
                getBurgerMenuSecondSectionItems: menuItemsExt.getBurgerMenuSecondSectionItems.bind(menuItemsExt),
                getMenuSettingsSectionItems: menuItemsExt.getMenuSettingsSectionItems.bind(menuItemsExt),
                getMenuUserManagementItem: menuItemsExt.getUserManagementItem.bind(menuItemsExt),
                getDeviceSearchItem: menuItemsExt.getDeviceSearchItem.bind(menuItemsExt),
                getDeviceSearchTreeItem: menuItemsExt.getDeviceSearchTreeItem.bind(menuItemsExt),
                getMenuAboutItems: menuItemsExt.getAboutItems.bind(menuItemsExt),
                getAboutApp: menuItemsExt.getAboutApp.bind(menuItemsExt),
                getAboutAppHelpFeedbackMenu: menuItemsExt.getAboutAppHelpFeedbackMenu.bind(menuItemsExt),
                getAboutLoxone: menuItemsExt.getAboutLoxone.bind(menuItemsExt),
                getAboutLoxoneHelpFeedbackMenu: menuItemsExt.getAboutLoxoneHelpFeedbackMenu.bind(menuItemsExt),
                getPartnerBrandingItem: menuItemsExt.getPartnerBrandingItem.bind(menuItemsExt),
                getMenuAutomaticDesignerItem: menuItemsExt.getAutopilotItem.bind(menuItemsExt),
                getSystemStateItem: menuItemsExt.getSystemStateItem.bind(menuItemsExt),
                getMenuOperatingModesItem: menuItemsExt.getOperatingModeItem.bind(menuItemsExt),
                getMenuBatteryMonitorItem: menuItemsExt.getBatteryMonitorItem.bind(menuItemsExt),
                getMenuQRCodeItem: menuItemsExt.getQRItem.bind(menuItemsExt),
                getMenuQuickActionItem: menuItemsExt.getQuickActionsItem.bind(menuItemsExt),
                getMenuNFCItem: menuItemsExt.getNFCSmartTagItem.bind(menuItemsExt),
                getNFCSmartTagItem: menuItemsExt.getNFCSmartTagItem.bind(menuItemsExt),
                getQRItem: menuItemsExt.getQRItem.bind(menuItemsExt),
                getRebootItem: menuItemsExt.getRebootItem.bind(menuItemsExt),
                // Menu Settings Extension
                getMiniserverSettings: menuSettingsExt.getMiniserverSettings.bind(menuSettingsExt),
                getGeneralMiniserverSettings: menuSettingsExt.getGeneralMiniserverSettings.bind(menuSettingsExt),
                getMiniserverUserSettings: menuSettingsExt.getMiniserverUserSettings.bind(menuSettingsExt),
                getAppSettings: menuSettingsExt.getAppSettings.bind(menuSettingsExt),
                getHomeKitItem: menuSettingsExt.getHomeKitItem.bind(menuSettingsExt),
                getAlexaItem: menuSettingsExt.getAlexaItem.bind(menuSettingsExt),
                getBackupAndSyncItem: menuSettingsExt.getBackupAndSyncItem.bind(menuSettingsExt),
                getNotificationItem: menuSettingsExt.getNotificationItem.bind(menuSettingsExt),
                getCurrentMiniserverUserItem: menuSettingsExt.getCurrentMiniserverUserItem.bind(menuSettingsExt),
                getPresentationSettingsItem: menuSettingsExt.getPresentationSettingsItem.bind(menuSettingsExt),
                getEntryPointSelectionItem: menuSettingsExt.getEntryPointSelectionItem.bind(menuSettingsExt),
                getDeviceFavoritesSettingsItem: menuSettingsExt.getDeviceFavoritesSettingsItem.bind(menuSettingsExt),
                getSecurityItem: menuSettingsExt.getSecurityItem.bind(menuSettingsExt),
                getSwitchUserItem: menuSettingsExt.getSwitchUserItem.bind(menuSettingsExt),
                getEcoModeSettingsItem: menuSettingsExt.getEcoModeSettingsItem.bind(menuSettingsExt),
                getAmbientModeSettingsItem: menuSettingsExt.getAmbientModeSettingsItem.bind(menuSettingsExt),
                getLoxoneControlMenuBar: menuSettingsExt.getLoxoneControlMenuBar.bind(menuSettingsExt),
                // Control Presets
                getPresetContextMenuEntryFor: presetExt.getPresetContextMenuEntryFor.bind(presetExt),
                updatePreset: presetExt.updatePreset.bind(presetExt),
                // Url Start Command Handling
                handleUrlCommand: urlCmdHdlrExt.handleCommand.bind(urlCmdHdlrExt),
                isWeatherServerAvailable: weakThis.isWeatherServerAvailable,
                isMediaServerAvailable: weakThis.isMediaServerAvailable,
                isAutopilotAvailable: weakThis.isAutopilotAvailable,
                isMessageCenterAvailable: weakThis.isMessageCenterAvailable,
                hasNfcCodeTouchs: weakThis.hasNfcCodeTouchs,

                // Device Activity Handling
                activityTick: deviceActivityTrackerExt.activityTick.bind(deviceActivityTrackerExt),
                isDeviceActivityDetected: deviceActivityTrackerExt.isActive.bind(deviceActivityTrackerExt),

                // Ambient+Eco Handling
                toggleEcoModeShown: ecoScreenExt.toggleEcoModeShown.bind(ecoScreenExt),
                canShowAmbientMode: ambientModeExt.canShow.bind(ambientModeExt),
                toggleAmbientModeShown: ambientModeExt.toggleAmbientModeShown.bind(ambientModeExt),
                needToShowAmbientOnboarding: ambientModeExt.needToShowAmbientOnboarding.bind(ambientModeExt),
                getAmbientNavigation: ambientModeExt.getAmbientNavigation.bind(ambientModeExt),
                setAmbientNavigation: ambientModeExt.setAmbientNavigation.bind(ambientModeExt),
                requiredAmbientModeWindowSize: ambientModeExt.requiredWindowSize.bind(ambientModeExt),
                isEcoModeActive: ecoScreenExt.isEcoModeActive.bind(ecoScreenExt),
                blockAmbientAndScreensaver: weakThis.blockAmbientAndScreensaver,
                blockScreensaver: weakThis.blockScreensaver,
                dispatchAmbientTabEvent: weakThis.dispatchAmbientTabEvent,
                cacheAmbientTabStateToPersist: ambientModeExt.cacheTabStateToPersist.bind(ambientModeExt),
                persistCachedAmbientTabState: ambientModeExt.persistCachedTabState.bind(ambientModeExt),
                getPersistedAmbientTabState: ambientModeExt.getPersistedTabState.bind(ambientModeExt),
                updateBrightnessSettings: ecoScreenExt.updateBrightnessSettings.bind(ecoScreenExt),
                updatePairedAppPresence: ecoScreenExt.setMsPresence.bind(ecoScreenExt)
            };
        }

        BaseComponent.beInheritedBy(Sandbox);
        extension = BaseComponent.initExtensions(names.int, $injector); // extensions
        // StateExt

        /**
         * Keeps both ambient & eco mode blocked until the returned fn is called (will remain blocked, if others have
         * called this method too and have not yet released the lock)
         * @returns {(function(): void)|*}
         */
        Sandbox.prototype.blockAmbientAndScreensaver = function blockAmbientAndScreensaver() {
            if (!HD_APP && !AMBIENT_MODE) {
                return; // unavailable, don't forward.
            }
            let unregEco = ecoScreenExt.blockEcoScreen.apply(ecoScreenExt, arguments);
            let unregAmbient = ambientModeExt.blockAmbientMode.apply(ambientModeExt, arguments);

            return () => {
                unregAmbient();
                unregEco();
            }
        }

        /**
         * Keeps eco mode blocked until the returned fn is called (will remain blocked, if others have
         * called this method too and have not yet released the lock)
         * @returns {(function(): void)|*}
         */
        Sandbox.prototype.blockScreensaver = function blockScreensaver() {
            if (!HD_APP && !AMBIENT_MODE) {
                return; // unavailable, don't forward.
            }
            let unregEco = ecoScreenExt.blockEcoScreen.apply(ecoScreenExt, arguments);

            return () => {
                unregEco();
            }
        }

        Sandbox.prototype.dispatchAmbientTabEvent = function dispatchAmbientTabEvent(data) {
            //Dispatches delayed, avoids multiple events & ensures the right icon is active on ambient screen initially.
            this._dispatchTimeout && clearTimeout(this._dispatchTimeout);
            this._dispatchTimeout = setTimeout(() => {
                CompChannel.emit(CCEvent.AmbientTabEvent, data);
            }, 10);
        }

        /**
         * @see StateExt.prototype.setStatusUpdates
         */

        Sandbox.prototype.onEventReceived = extension.State.prototype.setStatusUpdates;/**

         * @see StateExt.registerUUIDs
         */

        Sandbox.prototype.registerUUIDs = extension.State.prototype.registerUUIDs;
        /**
         * @see StateExt.unregisterUUIDs
         */

        Sandbox.prototype.unregisterUUIDs = extension.State.prototype.unregisterUUIDs; // components
        // ActiveMSComp

        /**
         * @see ActiveMSComp.getMiniserverSerialNo
         */

        Sandbox.prototype.getMiniserverSerialNo = ActiveMSComp.getMiniserverSerialNo;
        /**
         * @see ActiveMSComp.getMiniserverTimeInfo
         */

        Sandbox.prototype.getMiniserverTimeInfo = ActiveMSComp.getMiniserverTimeInfo;
        /**
         * @see ActiveMSComp.removeFromTimeInfo
         */

        Sandbox.prototype.removeFromTimeInfo = ActiveMSComp.removeFromTimeInfo;
        /**
         * @see ActiveMSComp.getStructureManager
         */

        Sandbox.prototype.getStructureManager = ActiveMSComp.getStructureManager;
        /**
         * @see ActiveMSComp.getTemperatureUnit
         */

        Sandbox.prototype.getTemperatureUnit = ActiveMSComp.getTempUnit;
        /**
         * Will convert the input temperature (provided in celcius) into whatever tempUnit the Miniserver is set to use.
         * @param celsius
         * @returns {*}
         */

        Sandbox.prototype.getTemperatureForUI = function getTemperatureForUI(celsius) {
            if (SandboxComponent.getTemperatureUnit() === TempAppendix(TempUnit.FAHRENHEIT) && celsius !== undefined) {
                return celsius * 1.8 + 32;
            } else {
                return celsius;
            }
        };
        /**
         * Will convert the input temperature (provided in celsius or fahrenheit) into celsius, which is the temperature
         * the Miniserver is set to use internally.
         * @param tempInput
         * @returns {*}     the celsius representation of the temperature passed into here.
         */


        Sandbox.prototype.getTemperatureForComm = function getTemperatureForComm(tempInput) {
            var celsius;

            if (SandboxComponent.getTemperatureUnit() === TempAppendix(TempUnit.FAHRENHEIT) && tempInput !== undefined) {
                celsius = (tempInput - 32) / 1.8;
            } else {
                celsius = tempInput;
            }

            return celsius;
        };
        /**
         * returns the url of the miniserver with which the connection was established
         * @returns {*} url as string
         */


        Sandbox.prototype.getCurrentMiniserverUrl = ActiveMSComp.getCurrentUrl;
        /*Sandbox.prototype.getCurrentMiniserverUrl = function getCurrentMiniserverUrl() {
         var rm = CommunicationComp.getCurrentReachMode();
         if (rm === ReachMode.LOCAL) {
         return ActiveMSComp.getLocalUrl();
         } else if (rm === ReachMode.REMOTE) {
         return ActiveMSComp.getRemoteUrl();
         }
         };*/

        /**
         * enables the status updates!
         */

        Sandbox.prototype.openStatusStream = function openStatusStream() {
            CommunicationComp.send(Commands.ENABLE_STATE_UPDATE).then(function () {
                Debug.States && console.info("awaiting status updates...");
            }, function () {
                console.error("ERROR: " + Commands.ENABLE_STATE_UPDATE + " failed?");
            });
        };

        Sandbox.prototype.download = function download(cmd, encryptionType, hasPrio) {
            return CommunicationComp.download(cmd, encryptionType, hasPrio);
        };

        /**
         * sends a command to the communication component plus it handles the bad connection popup
         * @param cmd command to send
         * @param encryptionType
         * @param useBadConnectionPopup if a popup should be shown when the connection is bad
         * @param [rqFlags] e.g. noLLRepsonse = if true, the response will not be wrapped into an LL object, but returned raw.
         * @returns {*} promise
         */
        Sandbox.prototype.send = function send(cmd, encryptionType, useBadConnectionPopup, rqFlags) {
            Debug.Commands && console.warn("Sandbox send:" + cmd + " useBadConnectionPopup:" + useBadConnectionPopup);
            var promise = CommunicationComp.send(cmd, encryptionType); // socketExt has no use for rqFlags right now, don't pass.
            promise.then(function (res) {
                Debug.Commands && console.warn("Sandbox send success: " + JSON.stringify(res));

                if (useBadConnectionPopup && badConnPopup) {
                    Debug.Commands && console.warn("Sandbox send: remove badConnPopup");
                    NavigationComp.removePopup(badConnPopup);
                    badConnPopup = null;
                }
            }, function (response) {
                Debug.Commands && console.warn("Sandbox send error: " + JSON.stringify(response));

                if (!useBadConnectionPopup || badConnPopup) {
                    return;
                }

                var showPopup = false;

                if (response === SupportCode.WEBSOCKET_TIMEOUT || response === SupportCode.WEBSOCKET_NOT_READY) {
                    showPopup = true;
                } else {
                    var code = getLxResponseCode(response);

                    switch (code) {
                        case ResponseCode.SECURED_CMD_FAILED:
                            console.error("Secured command failed! " + response.LL.control);
                            break;

                        case ResponseCode.FORBIDDEN:
                            console.error("Not allowed to perform command! " + response.LL.control);
                            break;

                        case ResponseCode.NOT_FOUND:
                            console.error("Command not found! " + response.LL.control);
                            break;

                        case ResponseCode.TRUST_PEER_NOT_REACHABLE: // more likely a client that is offline.
                            console.error("Command should be performed on other MS, which isn't reachable! " + response.LL.control);
                            _showUnreachablePeerPopup(response);
                            break;

                        default:
                            showPopup = true;
                            break;
                    }
                }

                if (showPopup) {
                    _showBadConnPopup();
                } else {
                    return response;
                }
            }.bind(this));
            return promise;
        };


        /**
         * Will send the command along with an token that ensures that the permission for that specific action has been
         * granted. Used e.g. for user management. It will use the PermissionExt in the Sandbox to acquire the permission.
         * These commands must always be encrypted for both the request and the response.
         * @param cmd           the command to send
         * @param msPermission  the msPermission to use for this command.
         * @param viaHttp       if true, the command passed in will be sent via http instead of the socket.
         * @param [rqFlags]     flags to pass on to comm-logic, such as noLLResponse --> where the expected response is not in {LL:{value,code}} format.
         */
        Sandbox.prototype.sendWithPermission = function sendWithPermission(cmd, msPermission, viaHttp, rqFlags = RQ_FLAGS_DEFAULT) {
            var tokenObj; // at first, request the permission to send the command.

            return SandboxComponent.getPermission(msPermission).then(function () {
                // the permission is granted (either because the token is known, or because it's an old Miniserver).
                if (Feature.TOKENS) {
                    // New Miniservers need a specific token when they use specific permissions.
                    tokenObj = CommunicationComp.getToken(msPermission);

                    if (!tokenObj) {
                        console.error("Empty tokenObj for permission: " + msPermission);
                        throw new Error(_('authenticate.internal-issue'));
                    }

                    return _sendWithToken(cmd, tokenObj.token, tokenObj.username, viaHttp, rqFlags);
                } else {
                    // for old miniservers, just send the command.
                    return _send(cmd, EncryptionType.REQUEST_RESPONSE_VAL, viaHttp);
                }
            }.bind(this));
        };

        Sandbox.prototype.isAutopilotAvailable = function isAutopilotAvailable() {
            return Feature.AUTO_PILOT && SandboxComponent.checkPermission(MsPermission.AUTOPILOT) && !!ActiveMSComponent.getStructureManager().getAutopilotGenerator() && ActiveMSComponent.getGatewayType() !== GatewayType.CLIENT;
        };

        Sandbox.prototype.isMessageCenterAvailable = function isMessageCenterAvailable() {
            return !!ActiveMSComponent.getStructureManager().getMessageCenter();
        };

        Sandbox.prototype.isWeatherServerAvailable = function isWeatherServerAvailable() {
            return !!ActiveMSComponent.getStructureManager().getWeatherServer();
        };

        Sandbox.prototype.isMediaServerAvailable = function isMediaServerAvailable() {
            return Feature.MULTI_MUSIC_SERVER && !!ActiveMSComponent.getStructureManager().getMediaServerSet() || !!ActiveMSComponent.getStructureManager().getMediaServer();
        };

        Sandbox.prototype.hasNfcCodeTouchs = function hasNfcCodeTouchs() {
            return ActiveMSComponent.getStructureManager().getControlsByType(ControlType.NFC_CODE_TOUCH).length > 0;
        };
        /**
         * Will return a promise that resolves with the visu password. either a cached one or it will request one from
         * the user.
         * @param [wasWrong]    if true, it won't use a cached version and passes it along to the password prompt.
         * @return {*}
         */


        Sandbox.prototype.acquireVisuPassword = function acquireVisuPassword(wasWrong) {
            var visuPassPromise = null;

            if (this.hasCachedVisuPassword() && !wasWrong) {
                visuPassPromise = Q.when(commandExt.getVisuPassword());
            } else {
                visuPassPromise = NavigationComp.showVisuPasswordPrompt(wasWrong).then(function (resObj) {
                    return resObj.result;
                });
            }

            return visuPassPromise;
        };
        /**
         * @see CommunicationComp.download
         */


        Sandbox.prototype.download = CommunicationComp.download; // PersistenceComp

        /**
         * @see PersistenceComp.loadFile
         */

        Sandbox.prototype.loadFile = PersistenceComp.loadFile;
        /**
         * @see PersistenceComp.saveFile
         */

        Sandbox.prototype.saveFile = PersistenceComp.saveFile;

        /**
         * Sends the command to the miniserver without a token or alike. Either via http or via websocket.
         * @param cmd
         * @param encryptionType
         * @param viaHttp
         * @param [rqFlags] e.g. noLLResponse, where the response will not be in {LL:{code,value}} format
         * @returns {*}
         * @private
         */
        var _send = function _send(cmd, encryptionType, viaHttp = false, rqFlags = RQ_FLAGS_DEFAULT) {
            var promise;

            if (viaHttp) {
                promise = CommunicationComponent.sendViaHTTP(cmd, encryptionType, null, null, null, rqFlags);
            } else {
                promise = CommunicationComponent.send(cmd, encryptionType);
            }

            return promise;
        };


        /**
         * Sends the command to the miniserver with an additional token authentication. Either using http or via websocket
         * @param cmd
         * @param token
         * @param user
         * @param viaHttp
         * @param [rqFlags] optional, an object providing infos on the request.
         * @returns {*}
         * @private
         */
        var _sendWithToken = function _sendWithToken(cmd, token, user, viaHttp, rqFlags = RQ_FLAGS_DEFAULT) {
            var promise;

            if (viaHttp) {
                promise = CommunicationComponent.sendViaHTTPWithToken(cmd, token, user, rqFlags);
            } else {
                promise = CommunicationComponent.sendWithToken(cmd, token, user, null, rqFlags);
            }

            return promise;
        };

        var _showBadConnPopup = function _showBadConnPopup() {
            Debug.Commands && console.warn("Sandbox send: show badConnPopup");

            var message = _("connection.bad.message");
            /*if (Debug.Commands) {
             message = message + " ('" + cmd + "')";
             }*/


            var content = {
                title: _("connection.bad.title"),
                message: message,
                buttonOk: _('cancel'),
                // use cancel as ok!
                icon: Icon.CAUTION,
                color: window.Styles.colors.red
            };
            badConnPopup = NavigationComp.showPopup(content); // tricky! (.then returns another promise -> can't dismiss popup again!!)

            badConnPopup.then(function () {
                badConnPopup = null;
                commandExt.resetCommandQueue();
            }, function () {
                badConnPopup = null;
            });
        };

        var _showUnreachablePeerPopup = function _showUnreachablePeerPopup(response = {}) {
            Debug.Commands && console.warn("Sandbox send: show unreachable peer popup. " + JSON.stringify(response));

            var unreachablePeer = null;
            try {
                unreachablePeer = nullEmptyString(getLxResponseValue(response, true));
            } catch (ex) {
            }


            var msgParts = [];
            msgParts.push(_("request.peer-timeout.message-ms"))
            unreachablePeer ? msgParts.push("(" + unreachablePeer + ").") : msgParts.push(".");

            var content = {
                title: _("request.peer-timeout.title"),
                message: msgParts.join(" "),
                buttonOk: _('okay'),
                // use cancel as ok!
                icon: Icon.CAUTION,
                color: window.Styles.colors.red
            };
            badConnPopup = NavigationComp.showPopup(content); // tricky! (.then returns another promise -> can't dismiss popup again!!)

            badConnPopup.then(function () {
                badConnPopup = null;
            }, function () {
                badConnPopup = null;
            });
        };

        window[names.ext] = new Sandbox();
        return window[names.ext];
    }]);

}
