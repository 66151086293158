'use strict';

define('DaytimerControlStateContainer', ["DaytimerControlStateContainerBase", "DaytimerControlEnums"], function (DaytimerControlStateContainerBase, DaytimerControlEnums) {
    class DaytimerControlStateContainer extends DaytimerControlStateContainerBase {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            super.prepareStates(...arguments);
            this.states.activeMode = newVals[this.control.states.mode];
            this.states.activeModeName = SandboxComponent.getStructureManager().getOperatingModes(this.states.activeMode);
            this.states.value = newVals[this.control.states.value];
            this.states.defaultValue = newVals[this.control.states.entriesAndDefaultValue].defValue;
            this.states.remainingTime = newVals[this.control.states.override]; // store the isOn separate to the daytimerState, as the state could be timer active, but then the info
            // whether the timer did turn it on or off is missing.

            this.states.isOn = !this.control.details.analog && this.states.value === 1 || this.control.details.analog && this.states.value !== this.states.defaultValue;

            if (Feature.DAYTIMER_RESET_STATE) {
                this.states.resetActive = newVals[this.control.states.resetActive] !== 0;
            } else {
                this.states.resetActive = false;
            }

            if (this.states.resetActive) {
                this.states.daytimerState = DaytimerControlEnums.DaytimerState.RESET_ACTIVE;
            } else if (newVals[this.control.states.override]) {
                this.states.daytimerState = DaytimerControlEnums.DaytimerState.TIMER;
            } else if (newVals[this.control.states.needsActivation]) {
                this.states.daytimerState = DaytimerControlEnums.DaytimerState.NEEDS_ACTIVATION;
            } else if (this.states.isOn) {
                this.states.daytimerState = DaytimerControlEnums.DaytimerState.ON;
            } else {
                this.states.daytimerState = DaytimerControlEnums.DaytimerState.OFF;
            }

            this.states.currentTimeSpan = this._getCurrentTimeSpanInformation.call(this);
            this.states.entryWasActivated = this.states.daytimerState === DaytimerControlEnums.DaytimerState.ON && this.states.currentTimeSpan.needActivate === 1;
        }

        getStateTextForContent() {
            return this._prepareStateText(this.states);
        }

        getStateText() {
            if (this.states.resetActive) {
                return _("controls.daytimer.reset-active.short");
            } else if (this.states.daytimerState === DaytimerControlEnums.DaytimerState.ON || this.states.daytimerState === DaytimerControlEnums.DaytimerState.OFF) {
                if (this.control.details.analog) {
                    return lxFormat(this.control.details.format, this.states.value);
                } else {
                    return this.states.value === 1 ? this.control.details.text.on : this.control.details.text.off;
                }
            } else if (this.states.daytimerState === DaytimerControlEnums.DaytimerState.TIMER) {
                return _('controls.daytimer.timer-active');
            } else if (this.states.daytimerState === DaytimerControlEnums.DaytimerState.NEEDS_ACTIVATION) {
                if (this.control.details.analog) {
                    return lxFormat(this.control.details.format, this.states.value) + " (" + lxFormat(this.control.details.format, this.states.currentTimeSpan.value) + ")";
                } else {
                    return _('controls.daytimer.activation-required');
                }
            }
        }

        getStateColor() {
            if (this.states.isOn) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateIconSmall() {
            if (this.states.resetActive) {
                return {
                    iconSrc: Icon.INFO2,
                    color: window.Styles.colors.orange
                };
            } else if (this.states.daytimerState === DaytimerControlEnums.DaytimerState.TIMER) {
                return {
                    iconSrc: Icon.Daytimer.TIMER,
                    color: window.Styles.colors.orange
                };
            } else if (this.states.daytimerState === DaytimerControlEnums.DaytimerState.NEEDS_ACTIVATION && !this.states.entryWasActivated) {
                return {
                    iconSrc: Icon.Daytimer.LOCK.CLOSED,
                    color: window.Styles.colors.orange
                };
            }
        }

        getStateInfo() {
            var result = null;

            if (this.states.resetActive) {
                result = {
                    message: _("controls.daytimer.reset-active.info")
                };
            } else if (this.states.daytimerState === DaytimerControlEnums.DaytimerState.TIMER) {
                result = {
                    message: _("controls.daytimer.timer-active")
                };
            }

            return result;
        }

        _getCurrentTimeSpanInformation() {
            var minutesSinceMidnight = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINUTES_SINCE_MIDNIGHT); // default value

            var currDef;

            if (this.control.details.analog) {
                currDef = this.states.defaultValue;
            } else {
                currDef = 1;
            }

            var res;

            if (this.states.entries && (this.states.activeMode || this.states.activeMode === 0) && // 0 = holiday / feast day
                this.states.entries[this.states.activeMode]) {
                var modeEntries = this.states.entries[this.states.activeMode];

                for (var i = 0; i < modeEntries.length; i++) {
                    var entry = modeEntries[i]; // entry from must be greater than minutes since midnight or minutes since midnight plus one (time difference
                    // and the value of the entry must match with the current value, to get sure that this is the correct entry

                    if ((entry.from <= minutesSinceMidnight || entry.from <= minutesSinceMidnight + 1) && entry.to > minutesSinceMidnight && (entry.value === this.states.value || entry.needActivate)) {
                        // this is the currentEntry
                        res = entry;
                        break;
                    } else if (entry.from > minutesSinceMidnight) {
                        // there is a gap between entries - calculate it and create dummy entry
                        res = {
                            to: entry.from,
                            value: currDef
                        };

                        if (i === 0) {
                            res.from = 0;
                        } else {
                            res.from = modeEntries[i - 1].to;
                        }

                        break;
                    }
                }

                if (!res) {
                    // couldn't find a period, have to be the last one
                    res = {
                        from: modeEntries[modeEntries.length - 1].to,
                        to: 24 * 60,
                        value: currDef
                    };
                }
            } else {
                // no entries are available - current time period is the whole day
                res = {
                    from: 0,
                    to: 24 * 60,
                    value: currDef
                };
            }

            return res;
        }

        /**
         * Will create a proper state text as shown in the control content.
         * @param states
         * @private
         */
        _prepareStateText(states) {
            var fromDate = moment(0, "HH").minutes(states.currentTimeSpan.from).format("HH:mm");
            var toDate = moment(0, "HH").minutes(states.currentTimeSpan.to).format("HH:mm");

            if (toDate === "00:00") {
                toDate = "24:00";
            }

            var isAnalog = this.control.details.analog,
                translationArguments;

            if (states.resetActive) {
                return _("controls.daytimer.reset-active.short");
            } else if (states.daytimerState === DaytimerControlEnums.DaytimerState.TIMER) {
                var manualModeEnd = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_DATE_TIME).add('seconds', states.remainingTime).format(LxDate.getDateFormat(DateType.DateTextAndTimeNoYear));

                if (isAnalog) {
                    // analog
                    translationArguments = {
                        valText: lxFormat(this.control.details.format, states.value),
                        valTo: manualModeEnd
                    };
                    return _("controls.daytimer.value-to", translationArguments);
                } else {
                    // digital
                    translationArguments = {
                        onOffText: states.value === 1 ? this.control.details.text.on : this.control.details.text.off,
                        onOffUntil: manualModeEnd
                    };
                    return _("controls.daytimer.onoff-until", translationArguments);
                }
            } else if (states.daytimerState === DaytimerControlEnums.DaytimerState.ON) {
                if (isAnalog) {
                    // analog
                    translationArguments = {
                        valText: lxFormat(this.control.details.format, states.value),
                        valFrom: fromDate,
                        valTo: toDate
                    };

                    if (states.entryWasActivated) {
                        return _("controls.daytimer.value-to", translationArguments);
                    } else {
                        return _("controls.daytimer.value-from-to", translationArguments);
                    }
                } else {
                    // digital
                    translationArguments = {
                        onOffText: this.control.details.text.on,
                        onOffSince: fromDate,
                        onOffUntil: toDate
                    };

                    if (states.entryWasActivated) {
                        return _("controls.daytimer.onoff-until", translationArguments);
                    } else {
                        return _("controls.daytimer.onoff-since-until", translationArguments);
                    }
                }
            } else if (states.daytimerState === DaytimerControlEnums.DaytimerState.NEEDS_ACTIVATION) {
                if (isAnalog) {
                    return _("controls.daytimer.value-when-activated", {
                        valueWhenActive: lxFormat(this.control.details.format, states.currentTimeSpan.value)
                    });
                } else {
                    return _("controls.daytimer.activation-required");
                }
            } else if (states.daytimerState === DaytimerControlEnums.DaytimerState.OFF) {
                if (isAnalog) {
                    translationArguments = {
                        valText: lxFormat(this.control.details.format, states.value),
                        valFrom: fromDate,
                        valTo: toDate
                    };
                    return _("controls.daytimer.value-from-to", translationArguments);
                } else {
                    translationArguments = {
                        onOffText: this.control.details.text.off,
                        onOffSince: fromDate,
                        onOffUntil: toDate
                    };
                    return _("controls.daytimer.onoff-since-until", translationArguments);
                }
            }
        }

    }

    return DaytimerControlStateContainer;
});
