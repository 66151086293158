'use strict';

ActiveMSComp.factory('TrustExt', function () {
    // internal variables
    let weakThis, activeMsComp;

    const TRUST_STRUCT_FILE = "Trust.json";

    function TrustExt(comp) {
        weakThis = this
        activeMsComp = comp;
        this.name = "TrustExt";
        activeMsComp.on(ActiveMSComp.ECEvent.StartMSSession, this._prepare.bind(this));
        activeMsComp.on(CCEvent.StateContainersCreated, this._stateContainersReady.bind(this));
        activeMsComp.on(ActiveMSComp.ECEvent.StopMSSession, this._tearDown.bind(this));

        this.isWebIf = PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface;
    }

    // region public methods

    TrustExt.prototype.registerForStructure = function registerForStructure(fn) {
        this._registrations = this._registrations || {};

        let uniqueId;
        do {
            uniqueId = getRandomIntInclusive(0, 99999999) + "";
        } while (this._registrations.hasOwnProperty(uniqueId));
        this._registrations[uniqueId] = fn;

        let delayNotificationTimeout;
        if (this._structure) {
            delayNotificationTimeout = setTimeout(() => {
                fn(this._structure);
            }, 1);
        }

        return () => {
            clearTimeout(delayNotificationTimeout);
            delete this._registrations[uniqueId];
        }
    }

    TrustExt.prototype._isActiveMS = function _isActiveMS(ms) {
        let providedSerial = ms.serialNo || ms.serialNr,
            activeMsSerial = ActiveMSComponent.getActiveMiniserverSerial();
        return providedSerial && activeMsSerial && (providedSerial.toLowerCase() === activeMsSerial.toLowerCase());
    }

    TrustExt.prototype._transferAdresses = function _transferAdresses(archiveMs, trustMs) {
        archiveMs.localUrl = trustMs.localUrl || archiveMs.localUrl;
        archiveMs.remoteUrl = trustMs.remoteUrl || archiveMs.remoteUrl;
        return archiveMs
    };

    TrustExt.prototype.switchToMiniserver = function switchToMiniserver(ms, ignoreFw = false) {
        Debug.Trusts && console.log(this.name, "switchToMiniserver: " + JSON.stringify(ms));

        if (this._isActiveMS(ms)) {
            Debug.Trusts && console.log(this.name, "     is current Miniserver, just jump to entry point!");
            NavigationComp.showEntryPointLocation();
            return;
        } else if (ms.updateRequired && !ignoreFw) {
            Debug.Trusts && console.log(this.name, "     Miniserver is too old, will not work!");
            NavigationComp.showPopup({
                title: _("update.ms.overdue.title"),
                message: _("trust.linking-not-supported"),
                buttonOk: _("trust.unsupported-try-anyway"),
                buttonCancel: true
            }).then((res) => {
                this.switchToMiniserver(ms, true);
            })
            return;
        }

        let targetSerialNo = ms.serialNo || ms.serialNr,
            storedInArchive = PersistenceComponent.getMiniserver(targetSerialNo),
            newMS = storedInArchive ? this._transferAdresses(storedInArchive, ms) : this._ensureMsObjProps(ms),
            tokenRqObject = {
                tokenObj: this._getCurrentConnectionTokenObject(),
                msPermission: MsPermission.WEB
            };

        if (Feature.TRUST_JWT_AUTH) {
            tokenRqObject.msPermission = setBit(tokenRqObject.msPermission, MsPermission.TRUST_JWT_AUTH);
        }

        Debug.Trusts && console.log(this.name, "   > request a temporary token from src ms first");
        CommunicationComponent.requestToken(tokenRqObject).then((tempTokenObj) => {
            Debug.Trusts && console.log(this.name, "   > temporary token from src ms received");
            if (this.isWebIf) {
                try {
                    let url = new URL((newMS.remoteUrl || newMS.localUrl));
                    url.searchParams.set("username", this._structure.user);
                    NavigationComp.openWebsite(url.href, null, () => {
                        Debug.Trusts && console.log(this.name, "   > failed to open website with trust token!");
                    });
                } catch (e) {
                    if(e instanceof TypeError) {
                        let url = new URL(`${newMS.remoteUrl ? "https" : "http"}://${(newMS.remoteUrl || newMS.localUrl)}`);
                        url.searchParams.set("username", this._structure.user);
                        NavigationComp.openWebsite(url.href, null, () => {
                            Debug.Trusts && console.log(this.name, "   > failed to open website with trust token!");
                        });
                    } else {
                        Debug.Trusts && console.log(this.name, "   > unknown error has occurred!");
                    }
                    
                }
                
            } else {
                newMS.trustToken = VendorHub.Crypto.encrypt(tempTokenObj.token);
                newMS.activeUser = this._structure.user; // important, as otherwise a different user would be attempted, if the MS is already in the archive!

                Debug.Trusts && console.log(this.name, "   > applying stored auth info for target ms: " + newMS.msName);
                return PersistenceComponent.applyStoredAuthInfoForMiniserver(newMS).then(() => {
                    Debug.Trusts && console.log(this.name, "   > changing to target ms: " + newMS.msName);
                    Debug.Trusts && console.log(this.name, "                      user: " + newMS.activeUser);
                    Debug.Trusts && console.log(this.name, "                trustToken: " + !!newMS.trustToken);
                    Debug.Trusts && console.log(this.name, "                     token: " + !!newMS.token);
                    Debug.Trusts && console.log(this.name, "                  password: " + !!newMS.password);
                    Debug.Trusts && console.log(this.name, "                  localUrl: " + newMS.localUrl);
                    Debug.Trusts && console.log(this.name, "                 remoteUrl: " + newMS.remoteUrl);
                    NavigationComp.switchToMiniserver(newMS);
                })
            }
        });
    }

    TrustExt.prototype.showMiniserverInfo = function showMiniserverInfo(ms) {
        let miniserverObj = this._ensureMsObjProps(ms);
        NavigationComp.showState(ScreenState.AboutMiniserver, {
            serialNo: miniserverObj.serialNo,
            miniserver: miniserverObj,
            fromTrustList: true
        });
    }

    /**
     * Returns an array that holds all miniservers that are part of the trusted miniserver structure.
     * @returns {*[]}
     */
    TrustExt.prototype.getMiniserverList = function getMiniserverList() {
        const list = [];
        let msObj;
        const recursiveAdd = (group, path) => {
            let newPath = (group.type !== TrustListType.ROOT) ? [ ...path, group.name ] : [];
            Array.isArray(group.items) && group.items.forEach((item) => {
               if (item.type !== TrustListType.MINISERVER) {
                   recursiveAdd(item, newPath);
               } else {
                   msObj = this._ensureMsObjProps(item);
                   msObj.trustPath = newPath.join(SEPARATOR_SYMBOL);
                   msObj.isTrustedMs = true;
                   list.push(msObj);
               }
            });
        }
        recursiveAdd(this._structure, []);
        return list;
    }

    // endregion


    // region private methods

    TrustExt.prototype._ensureMsObjProps = function _ensureMsObjProps(ms) {
        let newMS = cloneObject(ms);

        // ensure serialNumber has correct prop name
        newMS.serialNo = newMS.serialNo || newMS.serialNr;
        delete newMS.serialNr;

        newMS.activeUser = this._structure.user;

        newMS.msName = newMS.msName || newMS.name;
        delete newMS.name;

        // remove type info.
        delete newMS.type;

        return newMS;
    }

    TrustExt.prototype._prepare = function _prepare() {
        const activeMS = ActiveMSComponent.getActiveMiniserver();
        Debug.Trusts && console.log(this.name, "_prepare: isInTrust=" + activeMS.isInTrust + ", serial=" + ActiveMSComponent.getActiveMiniserverSerial());
        this.isInTrust = activeMS.isInTrust;

        this._setStructure();
        if (this.isInTrust) {
            this._loadPersistedTrustStructure();
        }
    };

    TrustExt.prototype._stateContainersReady = function _stateContainersReady() {
        this.isInTrust = ActiveMSComponent.getActiveMiniserver().isInTrust;
        Debug.Trusts && console.log(this.name, "_stateContainersReady: isInTrust=" + this.isInTrust + ", feature=" + Feature.TRUST_STRUCTURE_REQUEST);

        if (this.isInTrust && Feature.TRUST_STRUCTURE_REQUEST) {
            this._stateUnregFn = SandboxComponent.registerFunctionForStateChangesForUUID(GLOBAL_UUID,
                this._statesChanged.bind(this), this._statesChanged.bind(this));
        }
    };

    TrustExt.prototype._tearDown = function _tearDown() {
        Debug.Trusts && console.log(this.name, "_tearDown");
        this._stateUnregFn && this._stateUnregFn();
        this._stateUnregFn = null;

        this._setStructure();
    };

    TrustExt.prototype._statesChanged = function _statesChanged(newStates) {
        if (newStates.hasOwnProperty("trustVersion") && newStates.trustVersion !== this._structure.version) {
            Debug.Trusts && console.log(this.name, "_statesChanged: " + this._structure.version + " -> " + newStates.trustVersion);
            this._downloadTrustStructure(newStates.trustVersion);
        } else {
            Debug.Trusts && console.log(this.name, "_statesChanged - trust version unchanged at " + this._structure.version);
        }
    };

    TrustExt.prototype._downloadTrustStructure = function _downloadTrustStructure(newVersion) {

        if (PairedAppComponent.isPaired()) {
            Debug.Trusts && console.log(this.name, "_downloadTrustStructure - PAIRED tab, not supporting trust linking!");
            this._setStructure();
            return Q.resolve();
        }
        Debug.Trusts && console.log(this.name, "_downloadTrustStructure: " + newVersion);

        return CommunicationComponent.sendViaHTTP("jdev/sps/trustcmd/getStructure").then(res => {
            Debug.Trusts && console.log(this.name, "_downloadTrustStructure -> responded");
            this._setStructure(this._validateMsStructure(res));
            this._persistTrustStructure();
        }, (err) => {
            Debug.Trusts && console.log(this.name, "_downloadTrustStructure -> failed " + JSON.stringify(err));
            this._setStructure();
        });
    }

    TrustExt.prototype._loadPersistedTrustStructure = function _loadPersistedTrustStructure() {
        const fileName = this._structFileName();
        if (!fileName) {
            Debug.Trusts && console.warn(this.name, "_loadPersistedTrustStructure: failed - user or serial unknown");
            return;
        }
        Debug.Trusts && console.log(this.name, "_loadPersistedTrustStructure: " + fileName);
        return PersistenceComponent.loadFile(fileName, DataType.OBJECT).depActiveMsThen(res => {
            Debug.Trusts && console.log(this.name, "_loadPersistedTrustStructure > loaded!");
            return this._setStructure(res);
        }, (err) => {
            Debug.Trusts && console.log(this.name, "_loadPersistedTrustStructure > failed! " + JSON.stringify(err));
            return this._setStructure();
        });
    }

    TrustExt.prototype.deleteTrustStructure = function deleteTrustStructure(serialNo, user) {
        var fileName = this._structFileName(serialNo, user);
        if (!fileName) {
            Debug.Trusts && console.warn(this.name, "deleteTrustStructure: failed - user or serial unknown");
            return;
        }
        Debug.Trusts && console.log(this.name, "deleteTrustStructure: " + fileName);
        PersistenceComponent.deleteFile(fileName);
    }

    TrustExt.prototype._persistTrustStructure = function _persistTrustStructure() {
        const fileName = this._structFileName();
        if (!fileName) {
            Debug.Trusts && console.warn(this.name, "_persistTrustStructure: failed - user or serial unknown");
            return;
        }
        Debug.Trusts && console.log(this.name, "_persistTrustStructure: " + fileName);
        PersistenceComponent.saveFile(fileName, this._structure, DataType.OBJECT).depActiveMsThen(res => {
            Debug.Trusts && console.log(this.name, "_persistTrustStructure > persisted!");
        }, (err) => {
            Debug.Trusts && console.log(this.name, "_persistTrustStructure > failed! " + JSON.stringify(err));
        });
    }

    TrustExt.prototype._setStructure = function _setStructure(structure = null) {
        Debug.Trusts && console.log(this.name, "_setStructure: " + JSON.stringify(structure));
        this._structure = structure || defaultStructure();

        this._structure.noTrusts = false;
        this._structure.multipleTrusts = false;
        if (this._structure.items.length === 0) {
            Debug.Trusts && console.log(this.name, "   >> no trusts!");
            this._structure.noTrusts = true;
        } else if (this._structure.items.length > 1) {
            Debug.Trusts && console.log(this.name, "   >> multiple trusts!");
            this._structure.multipleTrusts = true;
        } else {
            Debug.Trusts && console.log(this.name, "   >> a single trust!");
        }

        this._registrations && Object.values(this._registrations).forEach(fn => { fn(this._structure); });
        if (ActiveMSComponent.getActiveMiniserver()) {
            activeMsComp.emit(ActiveMSComp.ECEvent.TRUST_STRUCTURE_UPDATED, this._structure);
        }
    }

    TrustExt.prototype._validateMsStructure = function _validateMsStructure(structure) {
        Debug.Trusts && console.log(this.name, "_validateMsStructure: " + JSON.stringify(structure));
        let newStructure = cloneObject(structure);

        newStructure.name = newStructure.name || _("trust.tab-name_plural");
        newStructure.type = (newStructure.hasOwnProperty("type") ? newStructure.type : TrustListType.ROOT);

        // if the structure only contains one trust, asap show its content
        if (newStructure.items && newStructure.items.length === 1 && newStructure.items[0].type === TrustListType.TRUST) {
            let initial = newStructure.items[0];
            newStructure.name = initial.name;
            newStructure.items = initial.items;
            newStructure.type = initial.type;
            Debug.Trusts && console.log(this.name, "   >> single trust in this list!");
        } else {
            Debug.Trusts && console.log(this.name, "   >> none or more than one trust in this list!");
        }

        // recursively iterate over structure and validate each MS entry.
        const checkGroupMiniservers = (grp) => {
            grp && Array.isArray(grp.items) && grp.items.forEach(item => {
                if (item.type === TrustListType.MINISERVER) {
                    this._validateMsEntry(item);
                } else {
                    checkGroupMiniservers(item);
                }
            })
        }
        checkGroupMiniservers(newStructure);

        return newStructure;
    }

    TrustExt.prototype._validateMsEntry = function _validateMsEntry(ms) {
        Debug.Trusts && console.log(this.name, "_validateMsEntry: " + JSON.stringify(ms));
        // check if ms.version is provided, if not - fall back to an version that is definitely outdated.
        // also --> ms.version is a number, not a string, covert to one for transition to our internal format.
        let version = (ms.version || "14040905") + "";
        if (version.indexOf(".") < 0) {
            let parts = version.match(/.{1,2}/g);
            if (Array.isArray(parts)) {
                version = parts.join(".");
            }
        }
        ms.updateRequired = new ConfigVersion(version).lessThan(new ConfigVersion("14.4.9.7"));
    }

    TrustExt.prototype._getCurrentConnectionTokenObject = function _getCurrentConnectionTokenObject() {
        let token = CommunicationComponent.getToken(this.isWebIf ? MsPermission.WEB : MsPermission.APP);
        if (!token && ActiveMSComponent.getCurrentUsername()) {
            const creds = ActiveMSComponent.getCurrentCredentials();
            token = creds.token ? { // mimic the tokenhandler's format of the token.
                token: creds.token,
                jwt: parseJwt(creds.token)
            } : null;
        }
        return token;
    }

    TrustExt.prototype._getConnectedUsername = function _getConnectedUsername() {
        let connUsername = ActiveMSComponent.getCurrentUsername(),
            tokenObj = this._getCurrentConnectionTokenObject(),
            tkUsrParts = [];

        // prefer using the username from the token, as there it will be sure on what MS the logged in user is from.
        if (tokenObj && tokenObj.jwt && tokenObj.jwt.payload && tokenObj.jwt.payload.user) {
            tkUsrParts.push(tokenObj.jwt.payload.user);
            if (tokenObj.jwt.payload.user.indexOf("@") < 0) {
                // always try to have the fully qualified user in here. with the serial of the owning ms.
                tkUsrParts.pushObject(tokenObj.jwt.payload.msSerial);
            }
            connUsername = tkUsrParts.join("@");
        }

        return connUsername;
    }

    TrustExt.prototype._structFileName = function _structFileName(serial, user) {
        let snr = serial ||ActiveMSComponent.getActiveMiniserverSerial(),
            userName = user || this._getConnectedUsername();
        if (snr && userName) {
            return snr + "-" + userName + "-" + TRUST_STRUCT_FILE;
        } else {
            console.warn(this.name, "Cannot construct filename! snr=" + snr + ", user=" + userName);
            return null;
        }
    }

    // endregion

    // region util fns

    var defaultStructure = () => {
        return {
            version: -1,
            type: TrustListType.ROOT,
            user: null,
            items: []
        }
    }

    // endregion

    return TrustExt;
});
