'use strict';

define('StatusMonitorControlStateContainer', [
    'ControlStateContainer',
], function (ControlStateContainer) {
    return class StatusMonitorControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);

            this.states.stateTextParts = []; // when accesing text of an empty submonitor when the main is configured with empty text, the app crashes because it tries to join an undefined array
            this._unregFns = [];
            this._boundRegFn = () => {
                this._unregisterFromSubCtrls();
                this._registerForSubCtrls();
            };
            CompChannel.on(CCEvent.StateContainersCreated, this._boundRegFn);
        }

        destroy() {
            CompChannel.off(CCEvent.StateContainersCreated, this._boundRegFn);
            this._unregisterFromSubCtrls();
            return super.destroy();
        }

        prepareStates(newVals) {
            Debug.Control.StatusMonitor &&
                console.log(this.name, 'prepareStates');
            this.states.outputCounts = this._prepareOutputCounts(newVals);

            this.states.inputNumberArray = newVals[
                this.control.states.inputStates
            ].text
                .split(',')
                .map((text) => Number(text));

            this.states.inputObjectArray = this._prepareInputObjectArray();

            this.states.sortedInputObjectArray = this._inputObjectArrayForUI(
                this.states.inputObjectArray,
            );

            this.states.stateTextParts = this._prepareStateTextParts();

            Debug.Control.StatusMonitor &&
                console.log(
                    this.name,
                    'preparedStates: ',
                    Object.entries(this.states),
                );
        }

        getStateText() {
            // if the stateTextParts array is empty (it is when statuses with defined values and blank names are present), return a space to avoid jumping ui
            const finalStateText = this.states.stateTextParts.join(', ');
            if(this.control.isConfigured()) {
                return finalStateText.length > 0 ? finalStateText : ' ';
            } else {
                return _('unconfigured.title');
            }
        }

        /**
         * Returns the color associated with the highest priority state.
         * The priority is determined by the 'prio' property of each state.
         * If multiple states have the same highest priority, the color of the first state found will be returned.
         * @returns {string|undefined} The color associated with the highest priority state, or undefined if no state is found.
         */
        getStateColor() {
            const inputPriorities = this.states.inputNumberArray
                .map((input) => {
                    // map input numbers to their priorities
                    // since the input number is the state id, we can use it to find the priority of the state
                    // this is necessary as the array order doesn't change when user changes the priority only prio field on this.control.details.status changes
                    const desiredState = Object.values(
                        this.control.details.status,
                    ).find((state) => state.id === input);
                    return desiredState ? desiredState.prio : undefined;
                })
                .filter((priority) => priority !== undefined);

            const highestPriority = Math.min(...inputPriorities);
            const desiredState = Object.values(
                this.control.details.status,
            ).find((state) => state.prio === highestPriority);

            return desiredState ? desiredState.color : undefined;
        }

        // region subcontrol state handling

        _prepareInputObjectArray() {
            let result = this.states.inputObjectArray
                ? cloneObject(this.states.inputObjectArray)
                : null;

            if (!result) {
                // clone the input-list from the details
                result = cloneObject(this.control.details.inputs); // resolve the rooms uuid to an object.

                let room;
                result.forEach(function (input) {
                    room =
                        ActiveMSComponent.getStructureManager().getGroupByUUID(
                            input.room,
                            GroupTypes.ROOM,
                        );

                    if (room) {
                        // room known from structure file.
                        input.room = room;
                    } else if (input.room === '') {
                        // "Don't use"-Room --> don't provide room
                        input.room = null;
                    } else {
                        // Room that isn't in our structure file.
                        input.room = {
                            name: _('unknown-room'),
                        };
                    }
                });
            }

            result.forEach((input, idx) => {
                let status = this._getStateObjectForInputNumber(idx);
                if (status) {
                    input.stateText = status.name;
                    input.stateColor = status.color;
                    input.stateNumber = status.id;
                    input.statePriority = status.prio;
                }
                const subStateText = this._getStateTextFromSubStateMonitor(
                    input.uuid,
                    idx,
                );
                if (subStateText) {
                    input.stateText = subStateText;
                }
            });

            return result;
        }

        _getStateObjectForInputNumber(inputIndex) {
            // what state is the input currently in?
            const stateNumber = this.states.inputNumberArray[inputIndex];

            if(stateNumber === 255) { // 255 is the default state for unconfigured subcontrols
                return { name: _('unconfigured.title'), color: Color.ORANGE, prio: 10 };
            }

            // get the status object for the state number
            return Object.values(this.control.details.status).find(
                (status) => status.id === stateNumber,
            );
        }

        /**
         * Iterates over the input objects in the details & registers for each one that has a control linked to it.
         * @private
         */
        _registerForSubCtrls() {
            let unregFn;
            this.control.details.inputs.forEach((inputObject, idx) => {
                if (inputObject.hasOwnProperty('uuid')) {
                    unregFn =
                        SandboxComponent.registerFunctionForStateChangesForUUID(
                            inputObject.uuid,
                            this._handleSubCtrlChanged.bind(
                                this,
                                idx,
                                inputObject,
                            ),
                            this._handleSubCtrlChanged.bind(
                                this,
                                idx,
                                inputObject,
                            ),
                        );
                    unregFn && this._unregFns.push(unregFn);
                }
            });
        }

        /**
         * Fired when a control linked to an input changes its state. Will update the state text & trigger a state change
         * @param idx
         * @param inputObject
         * @private
         */
        _handleSubCtrlChanged(idx, inputObject) {
            if (
                !Array.isArray(this.states.inputObjectArray) ||
                !inputObject.uuid
            ) {
                return;
            }
            const prevText = this.states.inputObjectArray[idx].stateText;
            const newText = this._getStateTextFromSubStateMonitor(
                inputObject.uuid,
                idx,
            );

            // avoid unnecessary listener notifications (& re-renders)
            if (prevText !== newText) {
                this.states.inputObjectArray[idx].stateText = newText;
                this.version++; // count up version with each update
                this.notifyListener();
            }
        }

        _unregisterFromSubCtrls() {
            this._unregFns.forEach((fn) => fn());
            this._unregFns = [];
        }

        /**
         * Will retrieve the new state text either from the control linked, or if it doesn't provide any from the internal
         * @param inputUuid    the uuid of the control this input references to.
         * @param inputIdx           index representing the state
         * @returns {*}
         * @private
         */
        _getStateTextFromSubStateMonitor(inputUuid, inputIdx) {
            let result;
            const control =
                ActiveMSComponent.getStructureManager().getControlByUUID?.(
                    inputUuid,
                );

            const { states } = SandboxComponent.getStatesForUUID(inputUuid);
            // only status monitor controls can be used for the stateText, otherwise nesting monitors would be corrupted.
            if (
                states &&
                states?.stateText?.length > 0 &&
                control?.type === ControlType.STATUS_MONITOR
            ) {
                result = states.stateText;
            } else {
                let { name = null } =
                    this._getStateObjectForInputNumber(inputIdx);
                result = name;
            }

            return result;
        }

        // endregion subcontrol state handling

        _prepareStateTextParts() {
            let stateTextParts = [];
            this.states.outputCounts &&
                Object.entries(this.control.details.status).forEach(
                    ([key, value]) => {
                        const adjustedKey = key.replace('status', 'numState'); // remap status to numState since key names in outputCounts are numState0, numState1, ... numState10 (numDef) and keys in details.status are status0, status1, ... status10
                        if (
                            this.states.outputCounts[adjustedKey] > 0 &&
                            value.name
                        ) {
                            // only add states with a count > 0 and defined name to the stateTextParts to avoid blank coma separated stateText
                            stateTextParts.push(
                                value.name
                                    ? `${this.states.outputCounts[adjustedKey]} ${value.name}`
                                    : ' ', // if no name is provided, use a space to avoid jumping ui
                            );
                        }
                    },
                );
            return stateTextParts;
        }

        /**
         * Prepares the output counts based on the provided new values.
         *
         * @param {Object} newVals - The new values to be processed.
         * @returns {Object} - The output counts.
         */
        _prepareOutputCounts(newVals) {
            const outputCounts = {};
            Object.entries(newVals).forEach(([valueUUID, actualValue]) => {
                Object.entries(this.control.states)
                    .filter((value) => value[0].startsWith('num')) // remove inputStates from the list
                    .forEach(([stateName, stateValueUUID]) => {
                        // map valueUUID-actualValue to stateName-actualValue
                        if (stateValueUUID === valueUUID) {
                            // because default state is numDef, we need to remap it to numState10 for easier handling
                            if (stateName === 'numDef') {
                                outputCounts['numState10'] = actualValue;
                            } else {
                                outputCounts[stateName] = actualValue;
                            }
                        }
                    });
            });

            return outputCounts;
        }

        /**
         * Returns a sorted array where inputs without a valid state are filtered from - ready to be rendered on the ui
         * @param {*} inputObjectArray
         * @returns
         */
        _inputObjectArrayForUI(inputObjectArray) {
            let sortedArray = [...inputObjectArray], // stateText for subcontrols is updated in inputObjectArray, we create a shallow copy to sort the references and avoid recreating the array on every update
                sortFields = ['statePriority', 'room.name', 'name'];
            sortArrByFields(sortedArray, sortFields);
            return sortedArray;
        }
    };
});
