'use strict';

PersistenceComp.factory('BackupAndSyncExt', function () {
    let weakThis,
        persComp = {},
        getInitialDataPromise,
        lastConnectedDeleted = false,
        syncTimeout,
        platform = PlatformComponent.getPlatformInfoObj().platform,
        syncUserChange = false;

    function BackupAndSyncExt(comp) {
        this.name = "BackupAndSyncExt";
        weakThis = this
        persComp = comp;
        document.addEventListener("iCloud", _handleCKNotification.bind(this), false); // here initial get from sync data -> Promise

        getInitialDataPromise = this.backupAndSyncGetBackup().then(function (res) {
            CompChannel.on(ActiveMSComp.ECEvent.ConnEstablished, _handleFirstConnectionBackupPopup.bind(this));
            return persComp.loadFile("LoxArchive.json", DataType.OBJECT).then(function (archive) {
                _handleInitialLoad(res, archive);
            }.bind(this));
        }.bind(this), function (error) {
            error && console.error(error);
            return Q({});
        }.bind(this));
    }

    BackupAndSyncExt.prototype.backupAndSyncGetSyncUserChange = function backupAndSyncGetSyncUserChange() {
        return syncUserChange;
    };

    BackupAndSyncExt.prototype.backupAndSyncSetSyncUserChange = function backupAndSyncSetSyncUserChange(value) {
        syncUserChange = value;
    };

    BackupAndSyncExt.prototype.backupAndSyncGetInitialDataPromise = function backupAndSyncGetInitialDataPromise() {
        return getInitialDataPromise;
    };

    BackupAndSyncExt.prototype.backupAndSyncGetLastConnectedDeleted = function backupAndSyncGetLastConnectedDeleted() {
        return lastConnectedDeleted;
    };

    BackupAndSyncExt.prototype.backupAndSyncIsEnabled = function backupAndSyncIsEnabled() {
        return PersistenceComponent && PersistenceComponent.getSyncEnabled();
    };

    BackupAndSyncExt.prototype.backupAndSyncSetEnabled = function backupAndSyncSetEnabled(enabled) {
        PersistenceComponent && PersistenceComponent.setSyncEnabled(enabled);
    };

    BackupAndSyncExt.prototype.backupAndSyncGetLastSync = function backupAndSyncGetLastSync() {
        return PersistenceComponent && PersistenceComponent.getLastSync();
    };

    BackupAndSyncExt.prototype.backupAndSyncSetLastSync = function backupAndSyncSetLastSync(date) {
        PersistenceComponent && PersistenceComponent.setLastSync(date.format());
    };

    BackupAndSyncExt.prototype.backupAndSyncGetLastKnownModificationDate = function backupAndSyncGetLastKnownModificationDate() {
        return PersistenceComponent && PersistenceComponent.getLastKnownBackup();
    };

    BackupAndSyncExt.prototype.backupAndSyncICloudStatus = function backupAndSyncICloudStatus() {
        var def = Q.defer();

        if (platform === PlatformType.DeveloperInterface) {
            def.resolve(true);
        } else if (window.hasOwnProperty("syncPlugin")) {
            syncPlugin.iCloudStatus(function (res) {
                def.resolve();
            }.bind(this), function () {
                def.reject(_("backup-and-sync.permission.message.icloud"));
            }.bind(this));
        }

        return def.promise;
    };

    BackupAndSyncExt.prototype.backupAndSyncGetBackup = function backupAndSyncGetBackup() {
        var def = Q.defer();

        if (window.hasOwnProperty("syncPlugin")) {
            if (platform === PlatformType.IOS) {
                this.backupAndSyncICloudStatus().then(function () {
                    syncPlugin.getBackup(function (res) {
                        if (res.miniservers) {
                            var miniserversArray = JSON.parse(res.miniservers);

                            if (Debug.Sync) {
                                console.warn("### | getBackup | ###");
                                Object.values(miniserversArray).forEach(function (miniserver) {
                                    console.warn(miniserver.lastConnectedDate + " | " + miniserver.msName);
                                }.bind(this));
                                console.warn("### | getBackup END | ###");
                            }

                            res.miniservers = miniserversArray;
                        } else {
                            res.miniservers = {};
                        }

                        if (res.modificationDate) {
                            _setLastKnownModificationDate(moment(res.modificationDate));
                        }

                        def.resolve(res);
                    }.bind(this), function (e) {
                        def.reject(e);
                    });
                }.bind(this), function (error) {
                    error && console.error(error);
                    def.reject(error);
                }.bind(this));
            } else {
                def.reject(this.name + ": Unsupported Platform: " + platform);
            }
        } else {
            def.reject(this.name + ": SyncPlugin not available");
        }

        return def.promise;
    };

    BackupAndSyncExt.prototype.backupAndSyncSetBackup = function backupAndSyncSetBackup(data) {
        Debug.Sync && console.warn("Set iCloud backup");

        if (Debug.Sync) {
            console.warn("### | setBackup | ###");
            Object.values(data).forEach(function (miniserver) {
                console.warn(miniserver.lastConnectedDate + " | " + miniserver.msName);
            }.bind(this));
            console.warn("### | setBackup END | ###");
        }

        syncTimeout && clearTimeout(syncTimeout);
        syncTimeout = setTimeout(function () {
            Debug.Sync && console.warn("### | iCloud Sync | ###");

            if (platform === PlatformType.DeveloperInterface) {
                _setLastKnownModificationDate(moment());
            } else if (window.hasOwnProperty("syncPlugin")) {
                PersistenceComponent.backupAndSyncICloudStatus().then(function () {
                    _removeAllTokens();

                    syncPlugin.setBackup(function (res) {
                        if (res) {
                            _setLastKnownModificationDate(moment(res));
                        }
                    }.bind(this), function (error) {
                        error && console.error(error);
                    }.bind(this), JSON.stringify(data));
                }.bind(this), function (error) {
                    error && console.error(error);
                }.bind(this));
            }
        }.bind(this), 2000);
    };

    BackupAndSyncExt.prototype.backupAndSyncMerge = function backupAndSyncMerge(iCloudBackup, user) {
        if (iCloudBackup.miniservers) {
            var localMiniservers = PersistenceComponent.getAllMiniserver();
            Object.keys(localMiniservers).forEach(function (localKey) {
                if (!iCloudBackup.miniservers[localKey]) {
                    iCloudBackup.miniservers[localKey] = localMiniservers[localKey];
                }
            }.bind(this));

            _resetTokens(iCloudBackup);

            PersistenceComponent.backupAndSyncSetBackup(iCloudBackup.miniservers);
            PersistenceComponent.saveArchive(iCloudBackup.miniservers);

            if (user) {
                var activeMiniserver = ActiveMSComponent.getActiveMiniserver();
                iCloudBackup.miniservers[activeMiniserver.serialNo].activeUser = user;
                syncUserChange = true;
                ActiveMSComponent.logOffUser(true);
            }
        }

        console.error("No miniservers to merge");
    };

    BackupAndSyncExt.prototype.backupAndSyncCheckForDifferentUser = function backupAndSyncCheckForDifferentUser(res) {
        var activeMiniserver = ActiveMSComponent.getActiveMiniserver();

        if (res[activeMiniserver.serialNo] && res[activeMiniserver.serialNo].activeUser !== activeMiniserver.activeUser) {
            return {
                localUser: activeMiniserver.activeUser,
                backupUser: res[activeMiniserver.serialNo].activeUser
            };
        }

        return false;
    };

    BackupAndSyncExt.prototype.backupAndSyncSynchronize = function backupAndSyncSynchronize() {
        PersistenceComponent.backupAndSyncSetLastSync(moment());
        return PersistenceComponent.backupAndSyncGetBackup().then(function (res) {
            var activeMiniserverSerial = ActiveMSComponent.getActiveMiniserver().serialNo,
                localMiniservers = PersistenceComponent.getAllMiniserver(),
                activeMiniserver = localMiniservers[activeMiniserverSerial];
            activeMiniserver && (res.miniservers[activeMiniserverSerial] = activeMiniserver);

            _resetTokens(res);

            PersistenceComponent.saveArchive(res.miniservers).then(function () {
                PersistenceComponent.addMiniserver.apply(PersistenceComponent, Object.values(res.miniservers));
            }.bind(this));
            PersistenceComponent.backupAndSyncSetLastSync(moment());
            res.modificationDate && _setLastKnownModificationDate(moment(res.modificationDate));
            PersistenceComponent.backupAndSyncSetBackup(res.miniservers);
            return res.miniservers;
        }.bind(this));
    };

    BackupAndSyncExt.prototype.backupAndSyncRestore = function backupAndSyncRestore() {
        return PersistenceComponent.backupAndSyncGetBackup().then(function (res) {
            var activeMiniserver = ActiveMSComponent.getActiveMiniserver();

            if (activeMiniserver) {
                var localMiniservers = PersistenceComponent.getAllMiniserver(),
                    activeArchiveMiniserver = localMiniservers[activeMiniserver.serialNo];
                res.miniservers[activeMiniserver.serialNo] = activeArchiveMiniserver;
            }

            if (Object.keys(res.miniservers).length) {
                _resetTokens(res);

                PersistenceComponent.saveArchive(res.miniservers).then(function () {
                    PersistenceComponent.addMiniserver.apply(PersistenceComponent, Object.values(res.miniservers));
                }.bind(this));
                PersistenceComponent.backupAndSyncSetLastSync(moment());
                PersistenceComponent.backupAndSyncSetEnabled(true);
                res.modificationDate && _setLastKnownModificationDate(moment(res.modificationDate));
            }
        }.bind(this));
    }; // Private functions


    var _setLastKnownModificationDate = function _setLastKnownModificationDate(date) {
        PersistenceComponent && PersistenceComponent.setLastKnownBackup(date.format());
    };

    var _handleFirstConnectionBackupPopup = function _handleFirstConnectionBackupPopup() {
        var platform = PlatformComponent.getPlatformInfoObj().platform;
        PersistenceComponent.backupAndSyncICloudStatus().then(function () {
            return PersistenceComponent.backupAndSyncGetInitialDataPromise().then(function (res) {
                if (!PersistenceComponent.backupAndSyncIsEnabled() && !PersistenceComponent.getFirstPopupShown()) {
                    var title, message;
                    PersistenceComponent.setFirstPopupShown(true);

                    if (platform === PlatformType.IOS) {
                        title = _("backup-and-sync.first-start.title.icloud");
                        message = _("backup-and-sync.first-start.message.icloud");
                    } else {
                        title = _("backup-and-sync.first-start.title.google");
                        message = _("backup-and-sync.first-start.message.google");
                    }

                    NavigationComp.showPopup({
                        title: title,
                        message: message,
                        buttonOk: _("activate"),
                        buttonCancel: _("not-now")
                    }).then(function () {
                        if (res) {
                            _resetTokens(res);

                            _addNewLocalMiniservers(res);

                            PersistenceComponent.saveArchive(res.miniservers);
                            PersistenceComponent.backupAndSyncSetBackup(res.miniservers);
                        }

                        PersistenceComponent.backupAndSyncSetEnabled(true);
                        PersistenceComponent.backupAndSyncSetLastSync(moment());
                    });
                }
            }.bind(this));
        }.bind(this));
    };

    var _handleInitialLoad = function _handleInitialLoad(res, archive) {
        var tempArchive = {},
            localLastConnected = PersistenceComponent.getLastConnectedMiniserver(archive),
            backupLastConnected;

        if (res.miniservers && localLastConnected) {
            var foundDeleted;

            _resetTokens(res);

            tempArchive.deviceList = res.miniservers;
            foundDeleted = res.miniservers[localLastConnected.serialNo];
            backupLastConnected = PersistenceComponent.getLastConnectedMiniserver(tempArchive);
            Debug.Sync && localLastConnected && console.warn(localLastConnected.lastConnectedDate + " | " + localLastConnected.msName + " | localLastConnected");
            Debug.Sync && backupLastConnected && console.warn(backupLastConnected.lastConnectedDate + " | " + backupLastConnected.msName + " | backupLastConnected");

            if (!foundDeleted && !backupLastConnected || localLastConnected.serialNo !== backupLastConnected.serialNo && !foundDeleted) {
                // The last connected Mininserver was deleted -> show popup at NavigationComp: showInitialView()
                lastConnectedDeleted = true;
            } else if (localLastConnected.serialNo === backupLastConnected.serialNo && localLastConnected.activeUser !== backupLastConnected.activeUser) {
                if (localLastConnected.lastConnectedDate > backupLastConnected.lastConnectedDate) {
                    tempArchive.deviceList[backupLastConnected.serialNo].lastConnectedDate = backupLastConnected.lastConnectedDate;
                    tempArchive.deviceList[backupLastConnected.serialNo].activeUser = backupLastConnected.activeUser;
                }
            } else {
                lastConnectedDeleted = false;
            }

            _avoidMiniserverSwitching(res, localLastConnected, backupLastConnected);
        }

        return res;
    };

    var _handleCKNotification = function _handleCKNotification() {
        Debug.Sync && console.warn("Received iCloud Notification");

        if (PersistenceComponent.backupAndSyncIsEnabled()) {
            PersistenceComponent.backupAndSyncICloudStatus().then(function () {
                return PersistenceComponent.backupAndSyncGetBackup().then(function (res) {
                    Debug.Sync && console.info("iCloud Backup", JSON.stringify(res));
                    var tempArchive = {},
                        activeMiniserver = ActiveMSComponent.getActiveMiniserver(),
                        backupLastConnected;

                    if (res.miniservers) {
                        var foundDeleted;

                        _resetTokens(res);

                        tempArchive.deviceList = cloneObjectDeep(res.miniservers);
                        activeMiniserver && (foundDeleted = res.miniservers[activeMiniserver.serialNo]);
                        backupLastConnected = PersistenceComponent.getLastConnectedMiniserver(tempArchive);
                        activeMiniserver && _avoidMiniserverSwitching(res, activeMiniserver, backupLastConnected);
                        PersistenceComponent.addMiniserver.apply(PersistenceComponent, Object.values(tempArchive.deviceList));
                        PersistenceComponent.saveArchive(tempArchive.deviceList);
                        Debug.Sync && activeMiniserver && console.warn(activeMiniserver.lastConnectedDate + " | " + activeMiniserver.msName + " | activeMiniserver");
                        Debug.Sync && backupLastConnected && console.warn(backupLastConnected.lastConnectedDate + " | " + backupLastConnected.msName + " | backupLastConnected");

                        if (activeMiniserver && (!foundDeleted && !backupLastConnected || !foundDeleted && activeMiniserver.serialNo !== backupLastConnected.serialNo)) {
                            NavigationComp.showPopup({
                                title: _("backup-and-sync.popup.not-found.title"),
                                message: _("backup-and-sync.popup.not-found.message", {
                                    name: activeMiniserver.msName
                                }),
                                buttonOk: true
                            }).then(function () {
                                return NavigationComp.showArchive();
                            }.bind(this));
                        } else if (activeMiniserver && activeMiniserver.serialNo === backupLastConnected.serialNo && activeMiniserver.activeUser !== backupLastConnected.activeUser) {
                            syncUserChange = true;
                            ActiveMSComponent.logOffUser(true);
                        }

                        PersistenceComponent.backupAndSyncSetLastSync(moment());
                        persComp.emit("iCloudDataChange");
                    }
                }.bind(this));
            }.bind(this), function (error) {
                error && console.error(error);
            }.bind(this));
        }
    };

    var _avoidMiniserverSwitching = function _avoidMiniserverSwitching(res, localLastConnected, backupLastConnected) {
        var localArchive = PersistenceComponent.getAllMiniserver();
        Object.values(res.miniservers).forEach(function (ms) {
            if (localArchive[ms.serialNo] && localLastConnected.serialNo !== backupLastConnected.serialNo) {
                // To prevent the Miniserver switching we have to set the lastConnectionDate from the local archive to the backup archive which will be saved as the new local archive
                ms.lastConnectedDate = localArchive[ms.serialNo].lastConnectedDate;
            }
        }.bind(this));
    };

    var _addNewLocalMiniservers = function _addNewLocalMiniservers(res) {
        var localDevices = PersistenceComponent.getAllMiniserver();
        Object.values(localDevices).forEach(function (ms) {
            if (!res.miniservers[ms.serialNo]) {
                // Add the new miniserver
                res.miniservers[ms.serialNo] = ms;
            }
        }.bind(this));
    };

    var _resetTokens = function _resetTokens(res) {
        var localMiniservers = PersistenceComponent.getAllMiniserver();
        Object.values(res.miniservers).forEach(function (ms) {
            if (!localMiniservers[ms.serialNo] || localMiniservers[ms.serialNo].activeUser !== ms.activeUser) {
                // backup contains a new miniserver with a token from another device -> reset token
                delete ms.token;
            } else {
                // backup contains a known miniserver with a token from another device -> set token for this device
                ms.token = localMiniservers[ms.serialNo].token;
            }
        }.bind(this));
    };

    var _removeAllTokens = function _removeAllTokens(miniservers) {
        if (miniservers) {
            miniservers.forEach(function (miniserver) {
                delete miniserver.token;
            }.bind(this));
        }
    };

    return BackupAndSyncExt;
});
