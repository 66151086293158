'use strict';

import ScreenBrightness from "../../PairedAppComp/helper/ScreenBrightness";
import AutoLock from "../../PairedAppComp/helper/AutoLock";

SandboxComp.factory('EcoScreenExt', function () {
    const PLATFORM = PlatformComponent.getPlatformInfoObj().platform;

    /**
     * @class EcoExtHelper
     * base class for all helpers in the ecoExt.
     */
    class EcoExtHelper {
        constructor(changeCallback, extChannel, comp, ecoExt) {
            this._changeCallback = changeCallback;
            this._extChannel = extChannel;
            this._comp = comp;
            this._ecoExt = ecoExt;

            if (this.shouldRegisterForEcoSettingChanges()) {
                this.registerForEvent(SandboxComp.ECEvent.EcoScreenSettingChanged, this.onSettingChanged.bind(this)); // NEW
            }
        }

        shouldRegisterForEcoSettingChanges() {
            return false;
        }

        onSettingChanged() {
            // nothing to do in base.
        }

        registerForEvent(ev, cb) {
            return this._extChannel.on(ev, cb);
        }

        getProps() {
            console.error("getProps not overridden!", getStackObj());
            return {};
        }

        dispatchChange() {
            this._changeCallback(this, this.getProps())
        }
    }

    /**
     * Brigthness handler is solely responsible to dim/brighten up the devices screen.
     * @type {number}
     */
    const DIMMED_BRIGHTNESS = 15;
    const DEFAULT_FULL_BRIGHTNESS = 100;

    class ScreenBrightnessHandler extends EcoExtHelper {
        constructor() {
            super(...arguments);

            this._isDimmed = false;
            this._ecoBrightness = DIMMED_BRIGHTNESS;
            this._initialBrightness = DEFAULT_FULL_BRIGHTNESS;
            this._enforcedActiveBrightness = -1;

            this.updateInitialBrightness();
        }

        getProps() {
            return {
                screenDimmed: this._isDimmed,
                initialBrightness: this._initialBrightness,
                dimmedBrightness: this._getDimmedBrightness()
            };
        }

        get screenDimmed() {
            return this._isDimmed;
        }

        /**
         * Paired tablets must ensure that a certin level of screen brightness is maintained.
         * @param activeBrightness
         * @param ecoBrightness
         */
        updateBrightnessSettings(activeBrightness, ecoBrightness) {
            this._enforcedActiveBrightness = activeBrightness;
            this._ecoBrightness = ecoBrightness;

            // enforce the new screen brightness!
            this.setDimmScreen(this.screenDimmed, true);
        }

        updateInitialBrightness(shouldDispatch = true) {
            return this._getCurrentScreenBrightness().then((currBrightness) => {
                this._initialBrightness = currBrightness;
                if (shouldDispatch) { // only dispatch in certain cases, so there is no dispatch cycle
                    this.dispatchChange();
                }
            });
        }

        setDimmScreen(dimmed, enforce = false) {
            Debug.EcoScreen && console.log(ScreenBrightnessHandler.name + " (EcoScreenExt)", "setDimmScreen: " + dimmed)
            if (dimmed === this._isDimmed && !enforce) {
                // screen is already correct dimmed,do nothing
                return;
            }
            if (dimmed) {
                this.updateInitialBrightness(false).then(() => {
                    // update the initial brightness which is used for resetting after the screensaver ends
                    // otherwise its only set on app start which is not as precise
                    this._setScreenBrightness(this._getDimmedBrightness());
                });
            } else {
                // when closing the screensaver, the current brightness has to be compared to the dimmed brightness because the auto brightness feature of iOS
                // may change the brightness during the screensaver, if this was the case, an appropriate brightness is already set
                // so we don't have to reset the brightness to an older value => More details:
                // https://docs.google.com/document/d/133LmL0iN-k-lEsPG7Y1k5Mm226_yyrO5w-Jl15E3oMQ/edit#
                this._getCurrentScreenBrightness().then(currBrightness => {
                    const brightnessChangedByAuto = currBrightness !== this._getDimmedBrightness();
                    if (this._enforcedActiveBrightness >= 0) {
                        this._setScreenBrightness(this._enforcedActiveBrightness);

                    }  else if (brightnessChangedByAuto) {
                        // do nothing because auto brightness already regulated
                    } else {
                        // reset the brightness to the value before the screensaver started, since we don't have more information
                        this._setScreenBrightness(this._initialBrightness);
                    }
                })
            }
            this._isDimmed = dimmed;
            this.dispatchChange();
        }

        _getCurrentScreenBrightness() {
            return ScreenBrightness.get();
        }

        _setScreenBrightness(newValue) {
            return ScreenBrightness.set(newValue);
        }

        _getDimmedBrightness() {
            let settings = PersistenceComponent.getEcoScreenSettings();
            if (settings && settings.hasOwnProperty("showTime")) {
                return settings.showTime ? this._ecoBrightness : 0;
            } else {
                return this._ecoBrightness;
            }
        }
    }

    /**
     * @class AutoLockHandler
     * Takes care of disabling/enabling the devices auto-lock featuers.
     */
    class AutoLockHandler extends EcoExtHelper {
        constructor() {
            super(...arguments);
            this._autoLockDisabled = AutoLock.isDisabled();
        }

        getProps() {
            return {autoLockDisabled: this.autoLockDisabled};
        }

        get autoLockDisabled() {
            return this._autoLockDisabled;
        }

        setAutoLockDisabled(keepOn) {
            if (this._autoLockDisabled !== keepOn) {
                this._autoLockDisabled = keepOn;
                AutoLock.setDisabled(keepOn);
                this.dispatchChange();
            }
        }
    }

    /**
     * @class PowerInfoHandlerReturns
     * Provides & monitores the battery/power supply situation.
     */
    class PowerInfoHandler extends EcoExtHelper {
        constructor() {
            super(...arguments);
            this._batteryState = {isPlugged: true, level: 100};
            this._mobileDevice = PLATFORM === PlatformType.IOS || PLATFORM === PlatformType.Android;

            this._mobileDevice && CompChannel.on(CCEvent.BatteryStateChanged, function (event, val) {
                this._batteryState = val;
                this.dispatchChange();
            }.bind(this));
        }

        getProps() {
            return {hasSufficientPower: this.hasSufficientPower, batteryState: this._batteryState};
        }

        get hasSufficientPower() {
            return this._batteryState.isPlugged; // || this._batteryState.level > 30; //TODO-woessto: should battery state be minded depending on settings?
        }
    }

    /**
     * @class AppStateMonitor
     * Informs on whether the app state is paused or not.
     */
    class AppStateMonitor extends EcoExtHelper {
        constructor() {
            super(...arguments);

            this._isPaused = false;
            CompChannel.on(CCEvent.Pause, this._updateAppPaused.bind(this, true));
            CompChannel.on(CCEvent.Resign, this._updateAppPaused.bind(this, true));
            CompChannel.on(CCEvent.Resume, this._updateAppPaused.bind(this, false));
            CompChannel.on(CCEvent.Active, this._updateAppPaused.bind(this, false));
        }

        getProps() {
            return {appPaused: this.appPaused};
        }

        get appPaused() {
            return this._isPaused;
        }

        _updateAppPaused(newVal) {
            if (this._isPaused !== newVal) {
                this._isPaused = !!newVal;
                this.dispatchChange();
            }
        }
    }

    /**
     * @class PresenceMonitor
     * Informs on whether or not there is presence in front of the device.
     * Collected e.g. via a configured audio zone currently playing music.
     */
    class PresenceMonitor extends EcoExtHelper {
        constructor() {
            super(...arguments);
            this._isActive = false;
            this._stateUnregFns = [];
            this._presenceControls = this._getCurrentEcoPresenceControls();
            CompChannel.on(CCEvent.StateContainersCreated, () => {
                this._checkEcoPresenceControls(true);
            });
            CompChannel.on(CCEvent.StopMSSession, () => {
                this._checkEcoPresenceControls();
            });
        }

        getProps() {
            return {presenceActive: this._isActive};
        }

        /**
         * For paired tablets, the MS reports presence via JSON RPC
         * @param active
         */
        forcePresence(active) {
            this._updateActivity(active)
        }

        _updateActivity(newVal) {
            if (!!newVal !== this._isActive) {
                this._isActive = !!newVal;
                if (!newVal) {
                    Debug.EcoScreen && console.log(PresenceMonitor.name + " --> New Presence is false, starting timeout to show EcoScreen");
                    this._startPresenceEndTimeout();
                } else {
                    Debug.EcoScreen && console.log(PresenceMonitor.name + " --> New Presence is true, stopping timeout to hide EcoScreen");
                    this._clearPresenceEndTimeout();
                    this.dispatchChange();
                }
            }
        }

        shouldRegisterForEcoSettingChanges() {
            return true;
        }

        onSettingChanged() {
            this._checkEcoPresenceControls();
        }

        _startPresenceEndTimeout() {
            if (this._presenceEndTimeout) {
                return; // no need to restart
            }
            let presenceEndDelay = this._getPresenceEndDelay();
            if (presenceEndDelay > 0) {
                Debug.EcoScreen && console.log(PresenceMonitor.name + " (EcoScreenExt)", "_startPresenceEndTimeout: start delay timer (" + presenceEndDelay + "s)");
                this._presenceEndTimeout = setTimeout(() => {
                    Debug.EcoScreen && console.log(PresenceMonitor.name + " (EcoScreenExt)", " --> Presence Timeout passed, dispatching false presence");
                    this._presenceEndTimeout = null;
                    this._isActive = false;
                    this.dispatchChange();
                }, presenceEndDelay * 1000);
            } else {
                Debug.EcoScreen && console.log(PresenceMonitor.name + " (EcoScreenExt)", "_startPresenceEndTimeout - no delay, asap!");
                this._isActive = false;
                this.dispatchChange();
            }
        }

        _getPresenceEndDelay() {
            let settings = PersistenceComponent.getEcoScreenSettings(),
                delay = 0;

            if (settings.usePresence) {
                delay = settings.timeout; // an additional delay to the detected presence may be set.
            }
            return delay;
        }

        _clearPresenceEndTimeout() {
            Debug.EcoScreen && console.log(PresenceMonitor.name + " --> Stopping Presence Timeout...");
            this._presenceEndTimeout && clearTimeout(this._presenceEndTimeout);
            this._presenceEndTimeout = null;
        }

        _getCurrentEcoPresenceControls() {
            let ecoSettings = PersistenceComponent.getEcoScreenSettings(false);
            if (ecoSettings && ecoSettings.usePresence) {
                const storedCtrls = ecoSettings.presenceControls || [];
                const structureManager = ActiveMSComponent.getStructureManager();
                let ctrlObjects = storedCtrls.map(storedObj => {
                    return structureManager.getControlByUUID(storedObj.uuidAction);
                })
                return ctrlObjects.filter((ctrl) => {
                    return !!ctrl;
                });
            }
            return [];
        }

        _checkEcoPresenceControls(forceRegister) {
            let registered = false,
                newControls = this._getCurrentEcoPresenceControls(),
                newHash = newControls.map(ctrl => ctrl.uuidAction).join(",").hashCode(),
                previousHash = this._presenceControls.map(ctrl => ctrl.uuidAction).join(",").hashCode();
            if (newHash !== previousHash) {
                this._unregisterFromEcoPresenceControls();
                this._presenceControls = newControls.map((tmpObj) => {
                    return ActiveMSComponent.getStructureManager().getControlByUUID(tmpObj.uuidAction);
                });
                this._registerForEcoPresenceControls();
                registered = true;
            }
            if (!registered && forceRegister) {
                this._registerForEcoPresenceControls();
            }
            this._presenceControlsStateChanged();
        }

        _registerForEcoPresenceControls() {
            Debug.EcoScreen && console.log(PresenceMonitor.name + " --> Register presenceControls... ", JSON.stringify(this._presenceControls.map(ctrl => {
                return {
                    name: ctrl.name,
                    uuidAction: ctrl.uuidAction
                }
            })));
            this._unregisterFromEcoPresenceControls();
            this._stateUnregFns = this._presenceControls.map(ctrl => SandboxComponent.registerFunctionForStateChangesForUUID(ctrl.uuidAction, this._presenceControlsStateChanged.bind(this), this._presenceControlsStateChanged.bind(this)));
        }

        _unregisterFromEcoPresenceControls() {
            Debug.EcoScreen && console.log(PresenceMonitor.name + " --> Unregister presenceControls... ", JSON.stringify(this._presenceControls.map(ctrl => {
                return {
                    name: ctrl.name,
                    uuidAction: ctrl.uuidAction
                }
            })));
            this._stateUnregFns.forEach(unregFn => {
                unregFn();
            })
            this._stateUnregFns = [];
        }

        _presenceControlsStateChanged() {
            Debug.EcoScreen && console.log(PresenceMonitor.name + " --> PresenceControls StateChanged");
            let presenceActive = false;
            this._presenceControls.forEach(presenceControl => {
                let ctrlStates = presenceControl.getStates();
                switch (presenceControl.controlType) {
                    case ControlType.PRESENCE_DETECTOR:
                        if (ctrlStates.active) {
                            Debug.EcoScreen && console.log(PresenceMonitor.name + " --> Presence is active, setting presenceActive to true");
                        }
                        presenceActive = presenceActive || !!ctrlStates.active;
                        break;
                    default:
                        Debug.EcoScreen && console.warn(PresenceMonitor.name, "control with type " + presenceControl.controlType + " not supported!");
                        break;
                }
            })
            Debug.EcoScreen && console.log(PresenceMonitor.name + " --> new PresenceState: " + presenceActive);

            this._updateActivity(presenceActive);
        }
    }

    /**
     * @class ConnectionMonitor
     * Ensures that short connection outages won't interfere with the eco-screen.
     */
    class ConnectionMonitor extends EcoExtHelper {
        constructor() {
            super(...arguments);

            this._disconnected = false;

            CompChannel.on(CCEvent.StructureReady, this._handleStructureReady.bind(this));
            CompChannel.on(CCEvent.ConnClosed, function (ev, code) {
                this._handleConnClosed(code);
            }.bind(this));
        }

        getProps() {
            return {disconnected: this.disconnected};
        }

        get disconnected() {
            return this._disconnected;
        }

        _handleStructureReady() {
            this._stopDisconnectedTimeout();
            this._updateDisconnected(false);
        }

        _handleConnClosed(code) {
            this._stopDisconnectedTimeout();
            if (code === SupportCode.WEBSOCKET_OUT_OF_SERVICE) {
                this._updateDisconnected(true);
            } else {
                this._startDisconnectedTimeout();
            }
        }

        _startDisconnectedTimeout() {
            this._disconnectTimeout = setTimeout(() => {
                this._disconnectTimeout = null;
                this._updateDisconnected(true)
            }, 5000);
        }

        _stopDisconnectedTimeout() {
            this._disconnectTimeout && clearTimeout(this._disconnectTimeout);
            this._disconnectedTimeout = null;
        }

        _updateDisconnected(newVal) {
            if (!!newVal !== this._disconnected) {
                this._disconnected = !!newVal;
                this.dispatchChange();
            }
        }
    }

    /**
     * @class DeviceActivityTimeout
     * Needs to be explicitly started & stopped, will fire after the configured delay when the eco screen should appear.
     */
    class DeviceActivityTimeout extends EcoExtHelper {
        constructor() {
            super(...arguments);
            this._timeoutPassed = false;
            this._showEcoDelay = this._loadDeviceActivityDelaySetting();
        }

        getProps() {
            return {
                deviceActivityTimeoutPassed: this._timeoutPassed,
                showDarkenerDelay: this._showEcoDelay
            };
        }

        deviceActivityChanged(isActive) {
            Debug.EcoScreen && console.log(DeviceActivityTimeout.name + "(EcoScreenExt)", "deviceActivityChanged: " + !!isActive);
            if (isActive) {
                this._stop();
            } else {
                this._start();
            }
        }

        forcePassed() {
            this._timeoutPassed = true;
            this.dispatchChange();
        }

        _start() {
            this._timeoutPassed = false;
            const currentTimeout = this._getDeviceActivityTimeout();
            if (this._activityTimeout) {
                clearTimeout(this._activityTimeout);
                this._activityTimeout = null;
            }
            if (currentTimeout > 0) {
                Debug.EcoScreen && console.log(DeviceActivityTimeout.name + "(EcoScreenExt)", "start with duration: " + currentTimeout + " seconds");
                this._activityTimeout = setTimeout(() => {
                    this._activityTimeout = null;
                    this._timeoutPassed = true;
                    this.dispatchChange();
                }, currentTimeout * 1000);
                this.dispatchChange();
            } else {
                Debug.EcoScreen && console.log(DeviceActivityTimeout.name + "(EcoScreenExt)", "start, no timeout specified, dispatch asap! Timeout='" + currentTimeout + "'");
                this._timeoutPassed = true;
                this.dispatchChange();
            }
        }

        _stop() {
            this._activityTimeout && clearTimeout(this._activityTimeout);
            this._activityTimeout = null;
            this._timeoutPassed = false;
            this.dispatchChange();
        }

        _getDeviceActivityTimeout() {
            let currentDelay = this._loadDeviceActivityDelaySetting();
            if (currentDelay !== this._showEcoDelay) {
                this._showEcoDelay = currentDelay;
                this.dispatchChange();
            }
            return this._showEcoDelay;
        }

        _loadDeviceActivityDelaySetting() {
            let settings = PersistenceComponent.getEcoScreenSettings(),
                delay;
            if (!settings || settings.usePresence) {
                delay = 30; // the device activity must always be 30, unless larger.
            } else {
                delay = settings.timeout;
            }
            if (Debug.Test.QUICK_AMBIENT_ECO) {
                return 5;
            }
            return delay;
        }
    }

    return class EcoScreen extends Components.Extension {
        static SettingsHelper = "SettingsHelper";
        constructor(component, extensionChannel) {
            super(...arguments);
            this.name = "EcoScreenExt";
            this.props = {};
            this._isActive = false;
            this._blocked = false;

            if (!HD_APP && !AMBIENT_MODE) {
                return;
            }

            const initExtHelper = (ctor) => {
                return new ctor(this._helperChanged.bind(this), extensionChannel, component, this);
            }
            this._showDeviceActivityTimeout = initExtHelper(DeviceActivityTimeout);
            this._screenBrightnessHandler = initExtHelper(ScreenBrightnessHandler);
            this._powerInfoHandler = initExtHelper(PowerInfoHandler);
            this._autoLockHandler = initExtHelper(AutoLockHandler);
            this._appStateMonitor = initExtHelper(AppStateMonitor);
            this._presenceMonitor = initExtHelper(PresenceMonitor);
            this._connectionMonitor = initExtHelper(ConnectionMonitor);

            this.registerExtensionEv(SandboxComp.ECEvent.DeviceActivityChanged, (ev, props) => {
                // props =  { active: true|false }
                if (!props.active && this.isEcoModeActive()) {
                    // ignore, the ambient mode is already active.
                    Debug.EcoScreen && console.log(this.name, "deviceActivityChanged: " + props.active + ", IGNORE! ecoShown: " + !!this.isEcoModeActive());
                } else {
                    Debug.EcoScreen && console.log(this.name, "deviceActivityChanged: " + props.active + ", ecoShown: " + !!this.isEcoModeActive());
                    this._showDeviceActivityTimeout.deviceActivityChanged(props.active);
                }
            });
            this.registerExtensionEv(SandboxComp.ECEvent.ActivityTick, (ev, data) => {
                if (this.props.deviceActivityTimeoutPassed && !this.isEcoModeActive()) {
                    this._showDeviceActivityTimeout.deviceActivityChanged(true)
                }
            })
            this.registerExtensionEv(SandboxComp.ECEvent.EcoScreenSettingChanged, (ev, props) => {
                this._handleEcoSettingChange();
            });
            this.registerExtensionEv(CCEvent.StartMSSession, (ev, props) => {
                this._handleEcoSettingChange();
            });
            setTimeout(() => {
                this._showDeviceActivityTimeout.deviceActivityChanged(true); // Initial Device Activity on App Start, can be set to true
            }, 100);

            // auto lock handler, powerInfoHandler may not dispatch a change - request.
            this._autoLockHandler.dispatchChange();
            this._powerInfoHandler.dispatchChange();
        }

        /**
         * sets the eco screen to blocked & keeps it blocked until the return fn is called. Will not be unlocked
         * if other sources did trigger the lock.
         * @returns {(function(): void)|*}
         */
        blockEcoScreen() {
            this._ecoBlockers = this._ecoBlockers || {};
            let rdId;
            do {
                rdId = getRandomIntInclusive(0, 999999) + "";
            } while (this._ecoBlockers.hasOwnProperty(rdId));
            Debug.EcoScreen && console.log(this.name, "blockEcoScreen " + rdId);

            this._ecoBlockers[rdId] = true;
            this._checkIsBlockedUpdate();
            return () => {
                Debug.EcoScreen && console.log(this.name, "blockEcoScreen " + rdId + " --> UNBLOCK!");
                delete this._ecoBlockers[rdId];
                this._checkIsBlockedUpdate();
            }
        }

        updateBrightnessSettings(activeBrightness, ecoBrightness) {
            this._screenBrightnessHandler.updateBrightnessSettings(activeBrightness, ecoBrightness);
        }
        setMsPresence(active) {
            this._presenceMonitor.forcePresence(active);
        }

        /**
         * Checks if isBlocked needs to be changed & a response is required.
         * @private
         */
        _checkIsBlockedUpdate() {
            let isBlocked = this._ecoBlockers && Object.values(this._ecoBlockers).length > 0;
            if (isBlocked !== this._blocked) {
                Debug.EcoScreen && console.log(this.name, "_checkIsBlockedUpdate: changed --> blocked = " + isBlocked);
                this._blocked = isBlocked;
                if (this._blocked) {
                    this._showDeviceActivityTimeout.deviceActivityChanged(true);
                }
                this._helperChanged(null, {blocked: isBlocked})
            }
        }

        isEcoModeActive() {
            return this._isActive;
        }

        toggleEcoModeShown(active, fromManual) {
            if (active !== this._isActive) {
                this._handleSetDimmed(active, fromManual)
            }
        }

        _helperChanged(helper, props) {
            Debug.EcoScreen && console.log(this.name, "helperChanged: " + JSON.stringify(props) + ", current: " + JSON.stringify(this.props));
            Object.assign(this.props, props);

            if (helper === this._screenBrightnessHandler) {
                // nothing to do regarding the screen dimmed prop.
            } else if (this._shouldDimScreen(props)) {
                this._handleSetDimmed(true);
            } else {
                this._handleSetDimmed(false);
            }

            this._checkConfigureAutoLock(helper, props);
        }

        _shouldDimScreen(changedHelper, changedProp) {
            Debug.EcoScreen && console.log(this.name, "_shouldDimScreen");
            let shouldDim = true;
            const checkCondition = (isValid, id) => {
                if (!isValid) {
                    Debug.EcoScreen && console.log(this.name, "   --> false due to: " + id + " = " + JSON.stringify(this.props[id]));
                }
                return isValid;
            }
            shouldDim = shouldDim && checkCondition(this.props.isEcoAllowed, "isEcoAllowed");
            shouldDim = shouldDim && checkCondition(!this.props.presenceActive, "presenceActive");
            shouldDim = shouldDim && checkCondition(this.props.deviceActivityTimeoutPassed, "deviceActivityTimeoutPassed");
            shouldDim = shouldDim && checkCondition(!this.props.disconnected, "disconnected");
            shouldDim = shouldDim && checkCondition(!this.props.appPaused, "appPaused");
            shouldDim = shouldDim && checkCondition(!this.props.blocked, "blocked");

            return shouldDim;
        }

        _checkConfigureAutoLock(changedHelper, change) {
            if (changedHelper === this._autoLockHandler || changedHelper === this._powerInfoHandler || changedHelper === this._appStateMonitor || changedHelper === EcoScreen.SettingsHelper) {
                if (this.props.autoLockDisabled && (!this.props.hasSufficientPower || !this.props.isEcoAllowed)) {
                    Debug.EcoScreen && console.log(this.name, "_checkConfigureAutoLock: insufficient power, enable auto lock, device may lock! " + JSON.stringify(this.props.batteryState));
                    this._autoLockHandler.setAutoLockDisabled(false);
                } else if (!this.props.autoLockDisabled && this.props.hasSufficientPower && this.props.isEcoAllowed) {
                    Debug.EcoScreen && console.log(this.name, "_checkConfigureAutoLock: sufficient power, disable auto lock, keep screen on! " + JSON.stringify(this.props.batteryState));
                    this._autoLockHandler.setAutoLockDisabled(true);
                }
            }
        }

        _handleEcoSettingChange() {
            const settings = PersistenceComponent.getEcoScreenSettings();
            const isEcoAllowed = settings.timeout >= 0 || settings.usePresence;
            if (isEcoAllowed !== this.props.isEcoAllowed) {
                this._helperChanged(EcoScreen.SettingsHelper, { isEcoAllowed });
            }
        }


        // region external calls

        _handleSetDimmed(dimmed, fromManual) {
            if (dimmed) {
                fromManual && this._showDeviceActivityTimeout.forcePassed();
            }
            this._screenBrightnessHandler.setDimmScreen(dimmed);
            this._triggerEcoModeActive(dimmed, fromManual);
        }

        _triggerEcoModeActive(active, fromManual) {
            Debug.EcoScreen && console.log(this.name, "_triggerEcoModeActive: " + !!active);
            ActiveMSComponent.setEcoDarkenerActive(active, fromManual ? "manual" : "logic");
            this._isActive = active;
        }

        // endregion
    }

});
