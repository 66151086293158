'use strict';

define("SmokeAlarmControlStateContainer", ["AlarmControlStateContainer", "SmokeAlarmControlEnums"], function (AlarmControlStateContainer, SmokeAlarmControlEnums) {
    return class SmokeAlarmControlStateContainer extends AlarmControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            // TODO-goelzda Check if all parameters are used
            // #####################################################################################
            // We call AlarmControlStateContainer, because we need all the states with the same logic + the additional stuff below...
            super.prepareStates(...arguments); // #####################################################################################
            // This always is true, because it is not good if a SmokeAlarm is off...

            this.states.armed = true;
            this.states.acousticAlarm = !!newVals[this.control.states.acousticAlarm];
            this.states.testAlarmActive = !!newVals[this.control.states.testAlarm];
            this.states.alarmCause = newVals[this.control.states.alarmCause]; //this.states.startTime = !!newVals[this.control.states.startTime];

            this.states.timeServiceModeSeconds = newVals[this.control.states.timeServiceMode];
            this.states.timeServiceMode = LxDate.formatSecondsShort(this.states.timeServiceModeSeconds, true);
            this.states.serviceModeActive = this.states.timeServiceModeSeconds > 0;

            if (Feature.ARE_ALARM_SIGNALS_OFF) {
                this.states.areAlarmSignalsOff = !!newVals[this.control.states.areAlarmSignalsOff];
            } // We override properties from the AlarmControl here...


            if (this.states.serviceModeActive) {
                this.states.armedStateTitle = _("controls.alarm.fireAlarm.service-mode-active");
                this.states.armedStateSubTitle = this.states.timeServiceModeSeconds === 1 || this.states.timeServiceModeSeconds === 99999 ? "" : "(" + _("controls.alarm.fireAlarm.remaining-time") + " " + this.states.timeServiceMode + ")";
            } else {
                this.states.armedStateTitle = _("controls.alarm.fireAlarm.surveillance.active");
                this.states.armedStateSubTitle = _("controls.alarm.fireAlarm.surveillance.all-ok");
            }
        }

        getStateText() {
            if (this.states.serviceModeActive) {
                if (this.states.timeServiceModeSeconds === 99999) {
                    return _("controls.alarm.fireAlarm.service-mode-active");
                }
                return `${_("controls.alarm.fireAlarm.service-mode")} (${this.states.timeServiceMode.decodeHtmlEntities()})`;
            } else if (this.states.testAlarmActive) {
                return _("controls.alarm.fireAlarm.service-mode-active");
            } else if (!this.states.alarmActive) {
                return _("controls.alarm.fireAlarm.surveillance.all-ok");
            }

            return super.getStateText(...arguments);
        }

        getStateTextShort() {
            if (this.states.serviceModeActive || this.states.testAlarmActive) {
                return _("controls.alarm.fireAlarm.service-mode");
            } else if (!this.states.alarmActive) {
                return _("controls.alarm.fireAlarm.surveillance.all-ok");
            }

            return super.getStateTextShort(...arguments);
        }

        getStateIcon() {
            // check if old or new config
            if (this.control.details && this.control.details.availableAlarms) {
                // new configuration
                if (this.states.alarmActive || this.states.testAlarmActive) {
                    // alarm active -> return icon depending on state (fire, water, fire and water)
                    return this.control.getIconForAlarmState(this.states.alarmCause);
                } else {
                    //alarm NOT active -> return the icon depending on configuration (fire, water, fire and water)
                    return this.control.getIconForAlarmState(this.control.details.availableAlarms);
                }
            } else {
                // old configuration
                return Icon.SmokeAlarm.Types.FIRE;
            }
        }

        getStateIconSmall() {// just override this function to prevent us from showing the small icon from the base class (AlarmControl)
        }

        getStateColor() {
            if (this.states.serviceModeActive || this.states.testAlarmActive) {
                return window.Styles.colors.orange;
            }

            return super.getStateColor(...arguments);
        }

        getStateTextColor() {
            if (this.states.serviceModeActive || this.states.testAlarmActive) {
                return window.Styles.colors.orange;
            }

            return super.getStateTextColor(...arguments);
        }


        _updateAlarmLevel() {
            if (this.states.currentAlarmLevel === SmokeAlarmControlEnums.ALARM_LEVEL.PRE_ALARM) {
                this.states.currentAlarmLevelDesc = _("controls.alarm.fireAlarm.pre-alarm.active");
            } else if (this.states.currentAlarmLevel === SmokeAlarmControlEnums.ALARM_LEVEL.MAIN_ALARM) {
                this.states.currentAlarmLevelDesc = _("controls.alarm.fireAlarm.main-alarm.active");
            } else {
                this.states.currentAlarmLevelLong = "";
                this.states.currentAlarmLevelDesc = "";
            }

            this.states.currentAlarmLevelLong = this.states.currentAlarmLevelDesc.split(" ")[0];

            if (this.states.nextAlarmLevel === SmokeAlarmControlEnums.ALARM_LEVEL.PRE_ALARM) {
                this.states.nextAlarmLevelDesc = _("controls.alarm.fireAlarm.pre-alarm.active");
            } else if (this.states.nextAlarmLevel === SmokeAlarmControlEnums.ALARM_LEVEL.MAIN_ALARM) {
                this.states.nextAlarmLevelDesc = _("controls.alarm.fireAlarm.main-alarm.active");
            } else {
                this.states.nextAlarmLevelDesc = "";
            }

            this.states.nextAlarmLevelLong = this.states.nextAlarmLevelDesc.split(" ")[0];
        }

    };
});
