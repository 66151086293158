/**
 * Used to access files in the resource store.
 * E.g. resources/misc/forbiddenPasswords.txt
 */
class ResourceFileLoader {
    static getFile(fileRequest) {
        return new ResourceFileLoader(fileRequest).getFile();
    }

    static ShowLog = false;

    /**
     * @param fileRequest:FileRequest
     */
    constructor(fileRequest) {
        this._debugName = "ResourceFileAccess@" + fileRequest.name;
        this._fileRequest = fileRequest
    }

    getFile() {
        let def = Q.defer();
        $.get(this._fileRequest.resourcePath, content => {
            this._fileRequest.data = content;
            def.resolve(this._fileRequest);
        });
        return def.promise;
    }
}


export default ResourceFileLoader;