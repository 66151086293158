'use strict';

define("UniversalControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class UniversalControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        /**
         * handles incoming values and prepares the states
         * @param newVals
         */
        prepareStates(newVals) {
            this._clearResetNewValTimer();

            this.states.newStates = [];
            var statesNames = Object.keys(this.control.states); // Iterate over the states, and extract useful information like text of textEvents

            statesNames.forEach(function (stateName) {
                var stateUuid = this.control.states[stateName],
                    stateValue = newVals[stateUuid],
                    valueEvent = true;

                if (typeof stateValue === "object") {
                    valueEvent = false;
                    stateValue = stateValue.text;

                    if (stateValue === undefined) {
                        stateValue = JSON.stringify(stateValue);
                    }
                }

                this.states[stateName] = {
                    isValueEvent: valueEvent,
                    value: stateValue
                }; // Keep a record on what states are new. This information will be presented in the UI

                if (this.currentStates && this.currentStates[stateName].value !== stateValue) {
                    this.states.newStates.push(stateName);
                }
            }.bind(this));
            this.currentStates = cloneObject(this.states);

            if (this.states.newStates.length > 0) {
                this._startResetNewValTimer(newVals);
            }
        }

        /**
         * gets called when the connection got lost
         */
        connectionLost() {
            super.connectionLost(...arguments);

            this._clearResetNewValTimer(); // the newStates will be reset anyway when the conn is reestablished.

        }

        /**
         * Start a timer that fires after 5 seconds to reset the newStates. This is required to be able to show
         * new states in a different color on the UI.
         * @private
         */
        _startResetNewValTimer(newVals) {
            this.newValTimer = setTimeout(function () {
                this.newStatesReceived(newVals);
            }.bind(this), 5 * 1000);
        }

        _clearResetNewValTimer() {
            this.newValTimer && clearTimeout(this.newValTimer);
            this.newValTimer = null;
        }

    };
});
