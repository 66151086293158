'use strict';

define("AalEmergencyControlStateContainer", ["ControlStateContainer", "AalEmergencyControlEnums"], function (ControlStateContainer, AalEmergencyControlEnums) {
    return class AalEmergencyControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.status = newVals[this.control.states.status];
            this.states.disableEndTime = newVals[this.control.states.disableEndTime];
            this.states.resetActive = newVals[this.control.states.resetActive].text; // handle if alarm is active -> show alert

            if (this.states.status === AalEmergencyControlEnums.Status.TRIGGERED) {
                SandboxComponent.isInAppNotificationSettingTurnedOn(this.control.type).done(function () {
                    NavigationComp.showControlAlert(this.control.uuidAction);
                }.bind(this));
            }
        }

        getStateText() {
            var str = "";

            if (this.states.status === AalEmergencyControlEnums.Status.NORMAL) {
                str = _("controls.aalemergency.ready");
            } else if (this.states.status === AalEmergencyControlEnums.Status.TRIGGERED) {
                str = _("controls.aalemergency.alarm-active");
            } else if (this.states.status === AalEmergencyControlEnums.Status.LOCKED) {
                str = _("controls.aalemergency.locked");
            } else if (this.states.status === AalEmergencyControlEnums.Status.DISABLED) {
                str = _("controls.aalemergency.disabled");
            }

            return str;
        }

        getStateColor() {
            var color;

            if (this.states.status === AalEmergencyControlEnums.Status.NORMAL) {
                color = window.Styles.colors.stateActive;
            }

            return color;
        }

        getStateTintColor() {
            var color;

            if (this.states.status === AalEmergencyControlEnums.Status.TRIGGERED) {
                color = window.Styles.colors.red;
            }

            return color;
        }

        /**
         * return a state text color to be displayed in eg. comfort mode
         * @returns {string|null|undefined}
         */
        getStateTextColor() {
            var color;

            if (this.states.status === AalEmergencyControlEnums.Status.LOCKED) {
                color = window.Styles.colors.orange;
            } else if (this.states.status === AalEmergencyControlEnums.Status.NORMAL) {
                color = window.Styles.colors.green;
            }

            return color;
        }

        /**
         * return a state icon to be displayed in eg. comfort mode
         * @returns {string|null|undefined}
         */
        getStateIcon() {
            return Icon.AalEmergency.DEFAULT_ICON;
        }

        getStateIconSmall() {
            if (this.states.status === AalEmergencyControlEnums.Status.DISABLED) {
                return {
                    iconSrc: Icon.AalEmergency.TIMER,
                    color: window.Styles.colors.orange
                };
            } else if (this.states.status === AalEmergencyControlEnums.Status.LOCKED) {
                return {
                    iconSrc: Icon.AalEmergency.LOCK,
                    color: window.Styles.colors.orange
                };
            }
        }

        getStateInfo() {
            var info, message, title;

            if (this.states.status === AalEmergencyControlEnums.Status.DISABLED) {
                message = _("controls.aalemergency.disabled-state", {
                    endDuration: this._getEndTimeString(this.states.disableEndTime)
                });
                info = {
                    message: message
                };
            } else if (this.states.status === AalEmergencyControlEnums.Status.LOCKED) {
                title = _("controls.aalemergency.locked-state", {
                    resetActive: this.states.resetActive
                });
                message = _("controls.aalemergency.locked-state-text");
                info = {
                    title: title,
                    message: message
                };
            }

            return info;
        }

        /**
         * If its a paket safe device, it'll return a paket safe icon.
         * @returns {string}
         * @private
         */
        _getControlIcon() {
            return Icon.AalEmergency.DEFAULT_ICON;
        }

        /**
         * Converts seconds from 1.1.2009 to a short date time string
         * @param endDateInSeconds
         * @returns {string}
         * @private
         */
        _getEndTimeString(endDateInSeconds) {
            return LxDate.formatFutureDateDynamic(new LxDate(endDateInSeconds, true), true);
        }

    };
});
