'use strict';

define("LightsceneRGBControlStateContainer", ["ColorPickerControlStateContainer"], function (ColorPickerControlStateContainer) {
    return class LightsceneRGBControlStateContainer extends ColorPickerControlStateContainer {
        constructor(control) {
            super(control);
        }

    };
});
