'use strict';

define("SolarPumpControlStateContainer", ["ControlStateContainer", "SolarPumpControlEnums"], function (ControlStateContainer, SolarPumpControlEnums) {
    return class SolarPumpControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        /**
         * handles incoming values and prepares the states
         * @param newVals
         */
        prepareStates(newVals) {
            // Dynamically create a state for every available buffer
            this.control.forEachBuffer(function (buffer, idx) {
                this.states["bufferTemp" + idx] = newVals[this.control.states["bufferTemp" + idx]];
                this.states["bufferState" + idx] = newVals[this.control.states["bufferState" + idx]];
            }.bind(this));
            this.states.generalizedBufferState = this._getGeneralizedBufferState();
            this.states.logicOverride = newVals[this.control.states.logicOverride];
            this.states.collectorTemp = newVals[this.control.states.collectorTemp];
            this.states.heatOverload = newVals[this.control.states.heatOverload];
        }

        getStateText() {
            var text;

            switch (this.states.generalizedBufferState) {
                case SolarPumpControlEnums.BUFFER_STATE.WAITING:
                    text = _("solarpumpcontroller.solarPump.off");
                    break;

                case SolarPumpControlEnums.BUFFER_STATE.HEATING_UP:
                    text = _('solarpumpcontroller.bufferState.heatingUp');
                    break;

                case SolarPumpControlEnums.BUFFER_STATE.OK_TEMP_REACHED:
                    text = _("solarpumpcontroller.bufferState.tempReached");
                    break;
            }

            return text;
        }

        getStateIcon() {
            return Icon.SolarPump.SOLAR_PANEL;
        }

        getStateColor() {
            if (this.states.generalizedBufferState === SolarPumpControlEnums.BUFFER_STATE.HEATING_UP) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateIconSmall() {
            var iconSrc,
                color = window.Styles.colors.stateActive;

            switch (this.states.generalizedBufferState) {
                case SolarPumpControlEnums.BUFFER_STATE.HEATING_UP:
                    iconSrc = Icon.SolarPump.ARROW_RIGHT_UP;
                    color = window.Styles.colors.orange;
                    break;

                case SolarPumpControlEnums.BUFFER_STATE.OK_TEMP_REACHED:
                    iconSrc = Icon.SolarPump.CHECKMARK;
                    break;
            }

            if (iconSrc) {
                return {
                    iconSrc: iconSrc,
                    color: color
                };
            }
        }

        getStateTextColor() {
            if (this.states.generalizedBufferState === SolarPumpControlEnums.BUFFER_STATE.HEATING_UP || this.states.generalizedBufferState === SolarPumpControlEnums.BUFFER_STATE.OK_TEMP_REACHED) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateInfo() {
            if (this.states.logicOverride) {
                return {
                    message: _("solarpumpcontroller.logic-override")
                };
            }
        }

        /**
         * Gets the generalized buffer state
         * Example:
         * All buffers are on temp -> SolarPumpControlEnums.BUFFER_STATE.OK_TEMP_REACHED
         * Majority of all buffers is on temp -> SolarPumpControlEnums.BUFFER_STATE.HEATING_UP
         * At lease one buffer is heating up -> SolarPumpControlEnums.BUFFER_STATE.HEATING_UP
         * @return {number}
         * @private
         */
        _getGeneralizedBufferState() {
            var bufferStateMap = {},
                existingBufferState,
                availableBuffersCnt = this.control.details.buffers.length,
                mapObj,
                state;
            Object.values(SolarPumpControlEnums.BUFFER_STATE).forEach(function (state) {
                bufferStateMap[state] = 0;
            });

            for (var i = 0; i < availableBuffersCnt; i++) {
                existingBufferState = bufferStateMap[this.states["bufferState" + i]];
                mapObj = existingBufferState + 1;
                bufferStateMap[this.states["bufferState" + i]] = mapObj;
            }

            if (bufferStateMap[SolarPumpControlEnums.BUFFER_STATE.OK_TEMP_REACHED] === availableBuffersCnt) {
                // All buffers reached its target temperatures
                state = SolarPumpControlEnums.BUFFER_STATE.OK_TEMP_REACHED;
            } else if (bufferStateMap[SolarPumpControlEnums.BUFFER_STATE.WAITING] === availableBuffersCnt) {
                // All buffers are waiting (pump is off)
                state = SolarPumpControlEnums.BUFFER_STATE.WAITING;
            } else if (bufferStateMap[SolarPumpControlEnums.BUFFER_STATE.HEATING_UP] > 0) {
                // At least one buffer is heating up
                state = SolarPumpControlEnums.BUFFER_STATE.HEATING_UP;
            } else {
                state = SolarPumpControlEnums.BUFFER_STATE.COOLING_DOWN; // not in use right now
            }

            return state;
        }

    };
});
