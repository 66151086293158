'use strict';

define("LoadManagerControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class LoadManagerControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.currentPower = newVals[this.control.states.currentPower];

            if (this.states.currentPower < 0) {
                this.states.currentPower = 0;
            }
            this.states.currentPowerText = lxFormat("%.2f", this.states.currentPower);

            this.states.maxPower = newVals[this.control.states.maxPower];
            this.states.maxPowerText = lxFormat("%.2f", this.states.maxPower);
            this.states.availablePower = newVals[this.control.states.availablePower];
            this.states.availablePowerText = lxFormat("%.2f", this.states.availablePower);
            this.states.maxPowerExceeded = !!newVals[this.control.states.maxPowerExceeded];
            this.states.lockedLoads = newVals[this.control.states.lockedLoads];
            this.states.statusLoads = newVals[this.control.states.statusLoads];
            this.states.amountLockedLoads = 0;
            this.control.details.loads.forEach(function (load) {
                if (hasBit(this.states.lockedLoads, 1 << load.id)) {
                    this.states.amountLockedLoads++;
                }
            }.bind(this));
        }

        getStateText() {
            if (this.states.lockedLoads === 0) {
                return _("controls.loadManager.available-power", {
                    power: this.states.availablePowerText
                });
            } else if (this.states.maxPowerExceeded) {
                return _("controls.loadManager.max-exceeded");
            } else if (this.states.amountLockedLoads !== 0) {
                return _("controls.loadManager.locked-loads", {
                    number: this.states.amountLockedLoads
                });
            }
        }

        getStateColor(states) {
            if (this.states.lockedLoads === 0) {
                return window.Styles.colors.green;
            } else if (this.states.maxPowerExceeded) {
                return window.Styles.colors.red;
            } else if (this.states.amountLockedLoads !== 0) {
                return window.Styles.colors.red;
            } else {
                return window.Styles.colors.stateActive;
            }
        }

        getStateIcon() {
            if (this.states.universalIsLocked) {
                return Icon.LoadManager.LOAD_MANAGER;
            } else if (this.states.currentPower > this.states.maxPower) {
                return Icon.LoadManager.LOAD_MANAGER;
            } else {
                var defaultMaxPointValue = 24;
                var progress = defaultMaxPointValue - this.states.currentPower * defaultMaxPointValue / this.states.maxPower;
                const rand = Math.floor(Math.random() * 1000);
                const svgContent = this._appendRandomNumberToIds(
                    ImageBox.getResourceImage(
                        Icon.LoadManager.LOAD_MANAGER_MASKED,
                    ),
                    rand,
                );
                this.snapContainer = Snap($(svgContent)[0]);
                this.snapContainer.select(`#Mask-${rand}`).attr("points", "24 0 0 0 0 " + progress + " 24 " + progress); // Color set in javascript for branding reason

                this.snapContainer.select(`#stop-1-${rand}`).attr("stop-color", window.Styles.colors.green);
                this.snapContainer.select(`#stop-2-${rand}`).attr("stop-color", window.Styles.colors.orange);
                this.snapContainer.select(`#stop-3-${rand}`).attr("stop-color", window.Styles.colors.red);
                return this.snapContainer.outerSVG();
            }
        }

        getLiveStateIcon() {
            return this.getStateIcon();
        }

        _appendRandomNumberToIds(svgString, rand) { // This function is used to append a random number to the id of the svg to avoid conflicts when switching views, as the svg is used multiple times in the DOM
            // Use a regular expression to find all occurrences of id=".*"
            var regexPath = /id="([^"]*)"/g;
            var modifiedSvgString = svgString.replace(
                regexPath,
                function (match, idValue) {
                    // Check if it's inside a url(#...) reference, and if so, don't modify
                    if (idValue.startsWith('url(') && idValue.endsWith(')')) {
                        return match;
                    }

                    // Append a random number to the existing id value
                    var newId = idValue + '-' + rand;
                    return 'id="' + newId + '"';
                },
            );

            // Update references to the modified IDs in xlink:href
            modifiedSvgString = modifiedSvgString.replace(
                /xlink:href="#([^"]*)"/g,
                function (match, idValue) {
                    // Check if the referenced ID was modified, and update the reference accordingly
                    if (idValue.startsWith('url(') && idValue.endsWith(')')) {
                        return match;
                    }

                    // Replace the old ID with the modified ID
                    var modifiedId = idValue + '-' + rand;
                    return 'xlink:href="#' + modifiedId + '"';
                },
            );

            // Update references to the modified IDs in url(#...)
            modifiedSvgString = modifiedSvgString.replace(
                /url\(#([^)]*)\)/g,
                function (match, idValue) {
                    // Check if the referenced ID was modified, and update the reference accordingly
                    if (idValue.startsWith('url(') && idValue.endsWith(')')) {
                        return match;
                    }

                    // Replace the old ID with the modified ID
                    var modifiedId = idValue + '-' + rand;
                    return 'url(#' + modifiedId + ')';
                },
            );

            return modifiedSvgString;
        }
    };
});
