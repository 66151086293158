'use strict';

SandboxComp.factory('NotificationExt', function () {
    let NotificationSoundIdMap = {
        '0': "DEFAULT",
        '1': "BELL",
        '2': "ALARM",
        '3': "ALARM",
    }

    let NotificationSound = {
        DEFAULT: 'notification',
        BELL: 'bell',
        ALARM: 'alarm'
    }

    let weakThis,
        comp,
        DEFAULT_TITLE = document.title,
        BELL_SOUND = "bell.mp3",
        DEFAULT_SOUND = "notification.mp3",
        NOTIFICATION_ICON_DEFAULTS = {
            iconSrc: null,
            iconColor: Color.ICON_B
        };
    return class NotificationExt {
        constructor(sandboxComp) {
            weakThis = this
            comp = sandboxComp;
            weakThis.name = "NotificationExt";
            this.appIsPaused = false;

            this._isDnd = false;
            this._dndQueue = [];
            this._deviceIsActive = true;

            this._history = []; // "union" history

            this._pnHistory = []; // history for push notifications

            this._wsHistory = []; // history for websocket notifications

            this._remoteNotificationHandlers = [];
            this._controlStateListeners = [];
            comp.on(SandboxComp.ECEvent.ConnClosed, function (event, args) {
                if (typeof this._notificationUUID === "string") {
                    // we used In-App-Notifications this session, do stuff
                    comp.unregisterUUIDs(this, [this._notificationUUID]);
                    delete this._notificationUUID;
                    this._wsHistory = []; // only reset the wsHistory, other histories must remain!
                }

                if (this._enableRequestPopup) {
                    NavigationComp.removePopup(this._enableRequestPopup);
                    delete this._enableRequestPopup;
                }

                clearTimeout(this._enableRequestPopupTimeout);
            }.bind(this)); // reset state values and wait for new

            comp.on(CCEvent.EcoScreenDarkenerActive, (ev, { dark }) => {
                Debug.PushNotification && console.log(weakThis.name, "CCEvent.EcoScreenDarkenerActive: " + dark);
                weakThis._handleDeviceActiveChanged(!dark);
            });

            comp.on(SandboxComp.ECEvent.ConnReady, function (event, newStructure) {
                this._allStatesReg = comp.on(SandboxComp.ECEvent.ALL_STATES_RECEIVED, function () {
                    // unregister to avoid duplicate calls!
                    this._allStatesReg(); // Clients will send an invalid Notification payload, thus we won't register for notifications on the client.


                    if (this.notificationsAvailableForActiveMiniserver()) {
                        // listen for In-App-Notifications
                        var globalStatesUuids = comp.getStructureManager().getGlobalStateUUIDs(),
                            hasInternet = true;

                        if (globalStatesUuids.notifications) {
                            this._notificationUUID = globalStatesUuids.notifications;
                            comp.registerUUIDs(this, [this._notificationUUID]);
                        }

                        if (Feature.GLOBAL_HAS_INTERNET_STATE) {
                            hasInternet = SandboxComponent.getStatesForUUID(GLOBAL_UUID).states.hasInternet;
                        } // Only ask for Push Notification registration if the Miniserver has an active internet connection


                        if (hasInternet) {
                            this._enableRequestPopupTimeout = setTimeout(this._checkPushRegistration.bind(this), 1000);
                        }
                    }
                }.bind(this));
            }.bind(this));
            if ("powerMonitor" in window) {
                powerMonitor.on("suspend", () => {
                    this.appIsPaused = true;
                    console.log("WANTED: App is suspended");
                });
                powerMonitor.on("resume", () => {
                    this.appIsPaused = false;
                    console.log("WANTED: App is resumed");
                });
            }
            CompChannel.on(CCEvent.Pause, function () {
                // update count!
                this._updateUnreadNotifications();
            }.bind(this)); // listen to PushNotifications

            pushNotificationService.onNewNotification = this._pushNotificationReceived.bind(this);
        }

        setDndActive(active = false) {
            Debug.PushNotification && console.log(weakThis.name, "setDndActive: " + active);
            weakThis._isDnd = active;
            if (!active) {
                weakThis._processDndQueue()
            }
        }

        _shouldParkNotification(pn) {
            if (!weakThis._isDnd || weakThis._passesThroughDnd(pn)) {
                return false;
            }
            if (weakThis._deviceIsActive) {
                // let through, but without sound - as regular notification.
                pn.dndQueued = true;
                return false;
            }
            return true;
        }

        _handleDeviceActiveChanged(active) {
            const becameActive = !weakThis._deviceIsActive && active,
                becameInactive = weakThis._deviceIsActive && !active;
            Debug.PushNotification && console.log(weakThis.name, "_handleDeviceActiveChanged: didBecomeActive=" + active);
            weakThis._deviceIsActive = active;
            if (weakThis._isDnd) {
                if (becameActive) {
                    Debug.PushNotification && console.log(weakThis.name, "     dnd is active -- process queue!");
                    // ensure that the parked notifications are dispatched
                    weakThis._processDndQueue();
                    NavigationComp.toggleNotifications(true);
                } else if (becameInactive) {
                    Debug.PushNotification && console.log(weakThis.name, "     dnd is active -- hide notifications!");
                    NavigationComp.toggleNotifications(false);
                }
            }
        }

        _removeFromDndQueue({ uids = [] }) {
            Debug.PushNotification && console.log(weakThis.name, "_removeFromDndQueue - pn-uids: " + (uids.join(", ")));
            const readUidsMap = {};
            uids.forEach((uid => { readUidsMap[uid] = true }));
            weakThis._dndQueue = weakThis._dndQueue.filter(parkedPn => {
                return !readUidsMap[parkedPn.uid];
            });
        }

        _addToDndQueue(pn) {
            Debug.PushNotification && console.log(weakThis.name, "_addToDndQueue - pn: " + (pn.title || pn.message)+ " #" + pn.uid, pn);
            pn.dndQueued = true; // set a flag so it can be hanled separately later.
            weakThis._dndQueue = weakThis._dndQueue.filter(parkedPn => parkedPn.uid !== pn.uid);
            weakThis._dndQueue.push(pn);
        }

        /**
         * Go through the parked notifications and make them appear!
         * @private
         */
        _processDndQueue() {
            Debug.PushNotification && console.log(weakThis.name, "_processDndQueue: " + weakThis._dndQueue.length + " pns parked!");
            weakThis._dndQueue.forEach(pn => {
                try {
                    Debug.PushNotification && console.log(weakThis.name, "  -> forwarding: " + (pn.title || pn.message) + " #" + pn.uid, pn);
                    weakThis._forwardNotification(pn);
                } catch (ex) {
                    console.error(weakThis.name, "_processDndQueue - failed at pn: " + (pn.title || pn.message) + " #" + pn.uid);
                }
            });
            weakThis._dndQueue = [];
        }

        /**
         * Returns true if the PN is allowed to punch through the DND mode.
         * @param pn the pn to check if it's allowed to pass through.
         * @returns {boolean}
         * @private
         */
        _passesThroughDnd(pn) {
            let passThrough = false;
            var control = ActiveMSComponent.getControlByUUID(pn.data.uuid);
            if (control) {
                switch (control.type) {
                    case ControlType.ALARM:
                    case ControlType.AAL_EMERGENCY:
                    case ControlType.AAL_SMART_ALARM:
                    case ControlType.SMOKE_ALARM:
                        Debug.PushNotification && console.log(weakThis.name, "alarm pn - pass through DND! " + (pn.title || pn.message) + " #" + pn.uid);
                        passThrough = true;
                        break;
                    default:
                        break;
                }
            }

            return passThrough;
        }

        /**
         * called when we receive a notification over status updates..
         * @param values
         */
        newStatesReceived(values) {
            if (values[this._notificationUUID].text) {
                try {
                    //console.log("============= WS PUSH ============");
                    //console.log(values[this._notificationUUID].text);
                    //console.log("==================================");
                    var pn = JSON.parse(values[this._notificationUUID].text.replace(/\\"/gi, '"'));
                } catch (e) {
                    console.error("ERROR: received invalid PN over WS:" + values[this._notificationUUID].text);
                    console.error(e.stack);
                }

                if (pn) {
                    if (pn.type === PushNotificationType.READ_NOTIFICATION) {
                        Debug.PushNotification && console.log(" - received In-App-Read-Notification for uid's: " + JSON.stringify(pn.uids));

                        this._handleInAppReadNotification(pn);

                        return;
                    } // bring to same level as push notifications!


                    pn.coldstart = false;
                    pn.foreground = true;
                    if ("sound" in pn) {
                        pn.sound = NotificationSound[NotificationSoundIdMap[pn.sound]] || NotificationSound.DEFAULT;
                    } else {
                        pn.sound = "default"
                    }

                    if (!pn.data) {
                        pn.data = {};
                    }

                    pn.data.mac = ActiveMSComponent.getActiveMiniserver().serialNo;
                    pn["ms_name"] = ActiveMSComponent.getActiveMiniserver().msName;
                    Debug.PushNotification && console.info(" - received In-App-Notification (" + pn.uid + ")");
                    Debug.PushNotification && console.log(JSON.stringify(pn)); // show only configured notifications
                    // load control before, we need to know the control type

                    var control = ActiveMSComponent.getControlByUUID(pn.data.uuid);
                    return this.getInAppSettings().then(function (settings) {
                        Debug.PushNotification && console.log(" - got settings!"); // look if we have a control

                        if (control) {
                            Debug.PushNotification && console.log(" - control available, check settings"); // -> control available, check settings

                            var setting = settings[control.type];

                            if (typeof setting === "boolean") {
                                // supported
                                if (setting) {
                                    Debug.PushNotification && console.log(" - control type '" + control.type + "' enabled in settings!");

                                    this._inAppNotificationReceived(pn);
                                } else {
                                    Debug.PushNotification && console.log(" - control type '" + control.type + "' disabled in settings!");
                                }
                            } else {
                                // not handled
                                Debug.PushNotification && console.log(" - control type '" + control.type + "' not handled atm!");
                            }
                        } else if (pn.data.lvl === PushNotificationLevel.SYSTEM_ERROR) {
                            // -> system error
                            if (settings[PushNotificationSettingType.ERRORS]) {
                                Debug.PushNotification && console.log(" - system errors enabled in settings!");

                                this._inAppNotificationReceived(pn);
                            } else {
                                Debug.PushNotification && console.log(" - system errors disabled in settings!");
                            }
                        } else {
                            // -> must be a custom notification
                            if (settings[PushNotificationSettingType.CUSTOM]) {
                                Debug.PushNotification && console.log(" - custom notifications enabled in settings!");

                                this._inAppNotificationReceived(pn);
                            } else {
                                Debug.PushNotification && console.log(" - custom notifications disabled in settings!");
                            }
                        }
                    }.bind(this), function (e) {
                        console.error(e);
                        Debug.PushNotification && console.log(" - get settings failed!");
                    });
                }
            } else {//console.info("============= WS PUSH CLEAN ============");
                // no notification, the "clean" event or initial event! (Miniserver Event Cache)
            }
        }

        /**
         * goes through all uids and removes the notifications
         * @param pn
         * @private
         */
        _handleInAppReadNotification(pn) {
            var i;

            this._removeFromDndQueue(pn);

            for (i = 0; i < pn.uids.length; i++) {
                this.markNotificationAsRead(pn.uids[i], true);
            }

            for (i = 0; i < this._remoteNotificationHandlers.length; i++) {
                this._remoteNotificationHandlers[i](this._minimizedNotifications());
            }
        }

        _inAppNotificationReceived(pn) {
            // call native plugin
            var platform = PlatformComponent.getPlatformInfoObj().platform;

            if (platform === PlatformType.Android || platform === PlatformType.IOS) {
                pushNotification.receivedNotification(null, null, pn.uid);
            }

            this._notificationReceived(pn);

            this._wsHistory.unshift(pn);

            this._listenForStateOfControlNotification(pn);
        }

        _pushNotificationReceived(pn) {
            this._notificationReceived(pn); // Prevent the MessageCenter notifications from unread handling (Notification history and application badge)
            // Such notifications shouldn't trigger any badge and shouldn't be visible in the notification history


            if (pn.type !== PushNotificationType.MESSAGE_CENTER) {
                this._pnHistory.unshift(pn);
            }
        }

        _notificationReceived(pn) {
            // look if we have received it before!
            // TODO-woessto: from lindosi:  for now we only have one control (SmokeAlarm) which uses the Level Test, if there are multiple controls
            // in future, we have to check the control type at this point
            // replace the push notification title (=> set to "test alarm")
            if (pn.data.lvl === PushNotificationLevel.TEST) {
                pn.title = _("controls.alarm.fireAlarm.testalarm.name");
            }

            if (!this._checkForNotification(pn)) {
                // adds notification to history if not there yet!
                Debug.PushNotification && console.info(" - notification '" + pn.uid + "' already received!");
                return;
            }

            Debug.PushNotification && console.info(" - new notification '" + pn.uid + "' received!");

            this._forwardNotification(pn);
        }

        _forwardNotification(pn) {
            // get all necessary info
            var ms = ActiveMSComponent.getActiveMiniserver(),
                mac = ms && ms.serialNo,
                sameMiniserver = mac === pn.data.mac;
            if (pn.foreground) {
                if (weakThis._shouldParkNotification(pn)) {
                    // Dnd active, store for later!
                    weakThis._addToDndQueue(pn);
                } else {
                    // Notification came in while app was running
                    if (sameMiniserver) {
                        this._handleForegroundNotificationFromSameMiniserver(pn);
                    } else {
                        this._handleForegroundNotificationFromOtherMiniserver(pn);
                    }
                }
            } else {
                // user tapped the OS's Notification
                this._handleOSTappedNotification(pn, sameMiniserver);
            }
        }

        _handleForegroundNotificationFromSameMiniserver(pn) {
            Debug.PushNotification && console.log(this.name, "_handleForegroundNotificationFromSameMiniserver"); // handle controls with an alert view:

            var control = ActiveMSComponent.getControlByUUID(pn.data.uuid);

            if (!pn.dndQueued && control && (control.type === ControlType.ALARM || control.type === ControlType.AAL_EMERGENCY || control.type === ControlType.SMOKE_ALARM || control.type === ControlType.AAL_SMART_ALARM && pn.data.lvl !== PushNotificationLevel.TEST || // if test alarm: do not show alert -> just show notification
                control.type === ControlType.INTERCOM || control.type === ControlType.INTERCOM_GEN_2)) {
                // this is a control with an alert
                if (pn.sound === "default" && control.type === ControlType.INTERCOM || control.type === ControlType.INTERCOM_GEN_2) {
                    pn.sound = NotificationSound.BELL;
                }

                this._removeNotificationFromHistory(pn.uid);

                this._playSoundFor(pn);

                NavigationComp.showControlAlert(pn.data.uuid, !pn.foreground);
            } else if (pn.type === PushNotificationType.MESSAGE_CENTER) {// Don't show these notifications if they are from the same miniserver, there is a custom UI for them
            } else {
                this._handleNotification(pn, function onClick() {
                    // -> show notification
                    switch (pn.type) {
                        case PushNotificationType.NORMAL:
                            NavigationComp.navigateToControl(pn.data.uuid).done(function () {
                                // mark as read..
                                this.markNotificationAsRead(pn.uid);
                            }.bind(this), function () {
                                // control couldn't be shown, show history screen
                                NavigationComp.showState(ScreenState.NotificationHistory);
                            }.bind(this));
                            break;

                        case PushNotificationType.MS_DEVICE_MANAGER:
                            this.markNotificationAsRead(pn.uid);
                            NavigationComp.showBatteryMonitor(pn.data.uuids, pn.data.mac);
                            break;

                        default:
                            break;
                    }
                }.bind(this));
            }
        }

        _handleForegroundNotificationFromOtherMiniserver(pn) {
            switch (pn.type) {
                case PushNotificationType.NORMAL:
                case PushNotificationType.INTERCOM_RICH:
                case PushNotificationType.INTERCOM_RICH_PAST:
                    // is another miniserver, show notification
                    PersistenceComponent.getControlOfMiniserver(pn.data.mac, pn.data.uuid).done(function (result) {
                        if (result === ControlLoadError.MINISERVER_NOT_AVAILABLE) {
                            // no Miniserver stored, unregister from these notifications!
                            this._removeNotificationFromHistory(pn.uid);

                            pushNotificationService.unregisterMiniserver4Push(pn.data.mac);
                        } else if (result === ControlLoadError.CONTROL_NOT_AVAILABLE) {
                            // show notification
                            this._handleNotification(pn);
                        } else {
                            this._handleNotification(pn, function onClick() {
                                UrlHelper.apply(UrlHelper.createURLStart({
                                    mac: pn.data.mac
                                }, {
                                    control: pn.data.uuid
                                }));
                            }.bind(this));
                        }
                    }.bind(this)); // error handling not needed (result with error codes)

                    break;

                case PushNotificationType.MS_DEVICE_MANAGER:
                    this._handleNotification(pn, function onClick() {
                        this.markNotificationAsRead(pn.uid);
                        NavigationComp.showBatteryMonitor(pn.data.uuids, pn.data.mac);
                    }.bind(this));

                    break;

                case PushNotificationType.MESSAGE_CENTER:
                    this._handleNotification(pn, function onClick() {
                        this._handleMessageCenterNotification(pn);
                    }.bind(this));

                    break;

                default:
                    break;
            }
        }

        _handleOSTappedNotification(pn, fromCurrentMiniserver) {
            Debug.PushNotification && console.info("NE: _handleOSTappedNotification fromCurrentMiniserver:" + fromCurrentMiniserver);

            if (pn.type === PushNotificationType.MESSAGE_CENTER) {
                this._handleMessageCenterNotification(pn);
            } else {
                this._handleControlNotification.apply(this, arguments);
            }
        }

        /**
         * If the notification is an alert notification depending on the control type and the notification level
         * @param control
         * @param pn
         * @returns {boolean|boolean}
         * @private
         */
        _isAlertNotification(control, pn) {
            return control.type === ControlType.ALARM || control.type === ControlType.AAL_EMERGENCY || control.type === ControlType.INTERCOM || control.type === ControlType.INTERCOM_GEN_2 && pn.type !== PushNotificationType.INTERCOM_RICH_PAST || control.type === ControlType.SMOKE_ALARM || control.type === ControlType.AAL_SMART_ALARM && pn.data.lvl !== PushNotificationLevel.TEST;
        }

        /**
         * Validates if the URL start to the location succeeded, show the notification again if not
         * @param pn
         * @param fromCurrentMs
         * @private
         */
        _validateUrlStart(pn, fromCurrentMs) {
            // wait 3 seconds, to start with the connection..
            setTimeout(function () {
                // Check if we navigated to the desired control
                if (NavigationComp.getURL().indexOf(pn.data.uuid) !== -1) {
                    if (fromCurrentMs) {
                        // we are at the control, remove from history
                        this._removeNotificationFromHistory(pn.uid);
                    }

                    Debug.PushNotification && console.info(" - we are there, stay there!");
                } else {
                    this._handleNotification(pn);
                }
            }.bind(this), 3000);
        }

        /**
         * Will open the MessageCenter or a messageCenterEntry from a given PushNotification
         * @param pn
         * @private
         */
        _handleMessageCenterNotification(pn) {
            var locationObj = UrlStartLocation.MESSAGE_CENTER;

            this._removeNotificationFromHistory(pn.uid); // Navigate to the messageCenter if no entryUuid is defined


            if (pn.data.entryUuid) {
                locationObj = {};
                locationObj[UrlStartLocation.MESSAGE_CENTER] = pn.data.entryUuid;
            }

            UrlHelper.apply(UrlHelper.createURLStart({
                mac: pn.data.mac
            }, locationObj));
        }

        _handleControlNotification(pn, fromCurrentMiniserver) {
            var activeMsControl = fromCurrentMiniserver && ActiveMSComponent.getControlByUUID(pn.data.uuid),
                loadedControl = PersistenceComponent.getControlOfMiniserver(pn.data.mac, pn.data.uuid),
                ctrl = activeMsControl || loadedControl,
                urlStartLocObj,
                isAlert = false;
            Q(ctrl).done(function (control) {
                if (control === ControlLoadError.MINISERVER_NOT_AVAILABLE) {
                    Debug.PushNotification && console.info(" - no Miniserver stored, unregister from these notifications!");
                    pushNotificationService.unregisterUnusedMiniserverFromPush(pn["ms_name"], pn.data.mac, pn.title + SEPARATOR_SYMBOL + pn.message);
                } else if (control === ControlLoadError.CONTROL_NOT_AVAILABLE) {
                    Debug.PushNotification && console.info(" - no control available"); // we can't navigate to any place -> show notification

                    if (!fromCurrentMiniserver) {
                        UrlHelper.apply(UrlHelper.createURLStart({
                            mac: pn.data.mac
                        }));
                    }

                    this._handleNotification(pn);
                } else {
                    isAlert = this._isAlertNotification(control, pn);
                    urlStartLocObj = {
                        control: pn.data.uuid,
                        alert: isAlert
                    };

                    if (pn.type === PushNotificationType.INTERCOM_RICH_PAST) {
                        // Directly navigate into the activities screen
                        urlStartLocObj.control += "%2Factivities";
                    }

                    UrlHelper.apply(UrlHelper.createURLStart({
                        mac: pn.data.mac
                    }, urlStartLocObj));

                    if (isAlert) {
                        this._removeNotificationFromHistory(pn.uid);
                    } else {
                        this._validateUrlStart(pn, fromCurrentMiniserver);
                    }
                }
            }.bind(this), function (e) {
                console.error(e);

                if (!fromCurrentMiniserver) {
                    UrlHelper.apply(UrlHelper.createURLStart({
                        mac: pn.data.mac
                    }, {
                        control: pn.data.uuid
                    }));
                }

                this._handleNotification(pn);
            }.bind(this));
        }

        _handleNotification(pn, onClick) {
            if (pn.foreground && !pn._notified && !pn.dndQueued) {
                this._playSoundFor(pn);

                pn._notified = true; // to play sound only once!
            }

            if (this._remoteNotificationHandlers.length === 0) {
                // finally show a notification
                this._presentNotification(pn, onClick);
            } else {
                // forward to handlers
                for (var i = 0; i < this._remoteNotificationHandlers.length; i++) {
                    this._remoteNotificationHandlers[i](this._minimizedNotifications());
                }
            }
        }

        _presentNotification(pn, onClick) {
            // get all necessary info
            var ms = ActiveMSComponent.getActiveMiniserver(),
                mac = ms && ms.serialNo,
                sameMiniserver = mac === pn.data.mac;
            var notificationType = NotificationType.INFO;

            switch (pn.data.lvl) {
                case PushNotificationLevel.TEST:
                    notificationType = NotificationType.TEST;
                    break;

                case PushNotificationLevel.ERROR:
                    notificationType = NotificationType.ERROR;
                    break;
            }

            if (this._firstNotification || this._firstNotificationInProgress) {
                Debug.PushNotification && console.log(" - 2nd notification"); // 2nd notification comes in, group the notifications

                if (this._firstNotificationInProgress) {
                    Debug.PushNotification && console.info(" - first notification still in progress, set flag to forbid it and show grouped instead");
                    delete this._firstNotificationInProgress;
                    this._groupedNotificationShownInMeantime = true;
                } // show group notification


                this._groupedNotification = GUI.Notification.createGeneralNotification({
                    title: _('notifications.new-notifications', {
                        count: this._history.length
                    }),
                    clickable: true,
                    closeable: true
                }, NotificationType.INFO);

                this._groupedNotification.on(GUI.Notification.CLICK_EVENT, function () {
                    // show history screen
                    NavigationComp.showState(ScreenState.NotificationHistory);

                    this._groupedNotification.remove(false);
                }.bind(this));

                this._groupedNotification.on(GUI.Notification.MARK_AS_READ_EVENT, this.markAllNotificationAsRead.bind(this)); // register for destroy, can be swiped away!


                this._groupedNotification.on("destroy", function () {
                    delete this._groupedNotification;
                }.bind(this));

                this._groupedNotification.uids = [pn.uid];

                if (this._firstNotification) {
                    this._groupedNotification.uids.push(this._firstNotification.uid); // remove the first notification afterwards


                    this._firstNotification.remove();

                    delete this._firstNotification;
                }
            } else if (this._groupedNotification) {
                Debug.PushNotification && console.log(" - notification #" + this._history.length); // 3rd, 4th, .. notification
                // only update title..

                this._groupedNotification.setTitle(_('notifications.new-notifications', {
                    count: this._history.length
                }));

                this._groupedNotification.uids.push(pn.uid);
            } else {
                // first notification
                Debug.PushNotification && console.log(" - first notification");

                var showNotification = function (iconInfo) {
                    Debug.PushNotification && console.log(" - we got the iconInfo, now show the first notification");

                    if (this._groupedNotificationShownInMeantime) {
                        Debug.PushNotification && console.info(" - grouped notification shown in meantime, don't show the first notification");
                        delete this._groupedNotificationShownInMeantime;
                        return;
                    }

                    delete this._firstNotificationInProgress; // flag not needed anymore
                    // lvl error -> red notification -> white icon!

                    var iconColor = pn.data.lvl === PushNotificationLevel.ERROR ? Color.ICON_B : iconInfo.iconColor,
                        iconSrc = sameMiniserver ? iconInfo.iconSrc : Icon.Notification.EXTERNAL;
                    var title = sameMiniserver ? pn.title : pn["ms_name"],
                        message = sameMiniserver ? pn.message : pn.title + SEPARATOR_SYMBOL + pn.message;
                    var notification = GUI.Notification.createGeneralNotification({
                        iconSrc: iconSrc,
                        iconColor: iconColor,
                        time: pn.ts,
                        title: title,
                        subtitle: message,
                        clickable: true,
                        closeable: true
                    }, notificationType);
                    notification.on(GUI.Notification.CLICK_EVENT, function () {
                        if (typeof onClick === "function") {
                            onClick();
                        } else {
                            // default -> history
                            NavigationComp.showState(ScreenState.NotificationHistory);
                        }

                        notification.remove(false);
                    }.bind(this));
                    notification.on(GUI.Notification.MARK_AS_READ_EVENT, this.markNotificationAsRead.bind(this, pn.uid, false)); // register for destroy, can be swiped away!

                    notification.on("destroy", function () {
                        delete this._firstNotification;
                    }.bind(this));
                    notification.uid = pn.uid; // to be able to reference it

                    this._firstNotification = notification;
                }.bind(this);

                this._firstNotificationInProgress = true; // due to the fact that this notification will be shown async, we need a flag!

                switch (pn.type) {
                    case PushNotificationType.NORMAL:
                        if (sameMiniserver) {
                            var control = ActiveMSComponent.getControlByUUID(pn.data.uuid);
                            this.determineNotificationStyle(control, pn.data.lvl).done(showNotification);
                        } else {
                            this.determineNotificationStyle(null, pn.data.lvl, pn.data.mac, pn.data.uuid).done(function (iconInfo) {
                                showNotification(iconInfo);
                            });
                        }

                        break;

                    case PushNotificationType.MS_DEVICE_MANAGER:
                        showNotification(this._getIconInfoForDeviceManagerNotification(pn));
                        break;

                    default:
                        showNotification(NOTIFICATION_ICON_DEFAULTS);
                        break;
                }
            }
        }

        registerNotificationHandler(notificationsCallback) {
            if (this._remoteNotificationHandlers.length === 0) {
                // toggle notifications
                if (this._firstNotification) {
                    this._firstNotification.remove();
                } else if (this._groupedNotification) {
                    this._groupedNotification.remove();
                }

                clearTimeout(this._delayedNotificationTimeout);
            }

            this._remoteNotificationHandlers.push(notificationsCallback);

            return this._minimizedNotifications();
        }

        unregisterNotificationHandler(notificationsCallback) {
            var idx = this._remoteNotificationHandlers.indexOf(notificationsCallback);

            if (idx !== -1) {
                this._remoteNotificationHandlers.splice(idx, 1);
            }

            if (this._remoteNotificationHandlers.length === 0) {
                // start a timeout, because sometimes a screen unregisters and another registers again, we don't want to display the notifications in the meantime
                this._delayedNotificationTimeout = setTimeout(function () {
                    // no notification to toggle, present it the first time
                    var pn;

                    for (var i = 0; i < this._history.length; i++) {
                        pn = this._history[i];

                        this._forwardNotification(pn);
                    }
                }.bind(this), 1000);
            }
        }

        getNumberOfUnreadNotifications() {
            return this._history.length;
        }

        showNotificationHistory(customViewController) {
            if (this._history.length > 0) {
                if (this._firstNotification) {
                    this._firstNotification.remove();
                } else if (this._groupedNotification) {
                    this._groupedNotification.remove();
                }

                if (customViewController) {
                    return customViewController.showState(ScreenState.NotificationHistory);
                } else {
                    return NavigationComp.showState(ScreenState.NotificationHistory);
                }
            } else {
                throw new Error("no history available");
            }
        }

        /**
         * marks the notification as read (-> removes it)
         * sends out a command to the Miniserver to broadcast to others
         * @param notificationUid
         * @param receivedFromWebsocket if true, don't send out the command again to the Miniserver (would be a loop)
         */
        markNotificationAsRead(notificationUid, receivedFromWebsocket) {
            var pn,
                idx = 0;

            while (idx < this._history.length) {
                pn = this._history[idx];

                if (pn.uid === notificationUid) {
                    this._history.splice(idx, 1);

                    this._updateUnreadNotifications();

                    break;
                } else {
                    pn = null; // set to null, we check below
                }

                idx++;
            } // check if the notification is currently shown (alone or grouped) and remove it if so


            if (this._firstNotification && this._firstNotification.uid === notificationUid) {
                this._firstNotification.remove();

                delete this._firstNotification;
            } else if (this._groupedNotification && this._groupedNotification.uids.indexOf(notificationUid) !== -1) {
                this._groupedNotification.uids.splice(this._groupedNotification.uids.indexOf(notificationUid), 1);

                if (this._groupedNotification.uids.length > 0) {
                    this._groupedNotification.setTitle(_('notifications.new-notifications', {
                        count: this._history.length
                    }));
                } else {
                    this._groupedNotification.remove();

                    delete this._groupedNotification;
                }
            }

            if (!receivedFromWebsocket) {
                var uids = [notificationUid];
                SandboxComponent.send(Commands.format(Commands.PNS.MARK_READ, encodeURIComponent(JSON.stringify(uids))));
            } // if we found the notification, remove the state listeners!


            if (pn) {
                this._removeListenerForStateOfControlNotification(pn);
            }
        }

        markAllNotificationAsRead() {
            var pn,
                uids = [];

            while (this._history.length > 0) {
                pn = this._history.pop();

                this._removeListenerForStateOfControlNotification(pn);

                uids.push(pn.uid);
            }

            this._updateUnreadNotifications();

            SandboxComponent.send(Commands.format(Commands.PNS.MARK_READ, encodeURIComponent(JSON.stringify(uids))));
        }

        /**
         * Checks if the Notifications Feature (Push and Websocket) is available for the active Miniserver and if the active Miniserver
         * is not one of our Demo Miniservers. Also clients will send incorrect Notification payloads. Notifications are not available on clients.
         * We do not allow notifications to be activated on our Demo Miniservers, because they
         * would annoy customers when they close the app or need to handle the PushNotification Popup.
         * @param ignoreGatewayType Ignores the Gateway type, so it just checks the feature and if it isn't a Demo Miniserver
         * @returns {boolean}
         */
        notificationsAvailableForActiveMiniserver(ignoreGatewayType) {
            var activeMs = ActiveMSComponent.getActiveMiniserver(),
                gwType = ActiveMSComponent.getGatewayType(),
                validGwType = ignoreGatewayType ? true : gwType === GatewayType.NONE || gwType === GatewayType.GATEWAY;
            return Feature.PUSH_NOTIFICATIONS && activeMs && !CommunicationComponent.isDemoMiniserver(activeMs.remoteUrl) && validGwType;
        }

        getInAppSettings() {
            Debug.PushNotification && console.log("NE getInAppSettings");
            var ms = ActiveMSComponent.getActiveMiniserver(),
                mac = ms.serialNo;
            return pushNotificationService._getMiniserverForMac(mac).then(function (miniserver) {
                Debug.PushNotification && console.log(" - got miniserver");

                if (miniserver.hasOwnProperty("inAppSettings")) {
                    if (typeof miniserver.inAppSettings === "object") {
                        Debug.PushNotification && console.log(" - has settings, return");
                        return this._validateSettings(miniserver.inAppSettings);
                    } else {
                        Debug.PushNotification && console.log(" - is disabled, disable all settings");
                        return this._validateSettings(false);
                    }
                } else {
                    Debug.PushNotification && console.log(" - has no settings, use default..");
                    return this._validateSettings();
                }
            }.bind(this), function () {
                return this._validateSettings();
            }.bind(this));
        }

        /**
         * check whether the InApp Notification setting is turned on or off for the given type
         * @param type
         * @returns Promise
         */
        isInAppNotificationSettingTurnedOn(type) {
            return this.getInAppSettings().then(function (settings) {
                return !!settings[type];
            });
        }

        getPushSettings(requestAgain) {
            Debug.PushNotification && console.log("NE getPushSettings");
            var ms = ActiveMSComponent.getActiveMiniserver(),
                mac = ms.serialNo,
                platform = PlatformComponent.getPlatformInfoObj().platform;
            return pushNotificationService._getMiniserverForMac(mac).then(function (miniserver) {
                Debug.PushNotification && console.log(" - got miniserver");

                if (requestAgain) {
                    Debug.PushNotification && console.log(" - request again (due to requestAgain flag)"); // no settings, request from Miniserver (to be able to restore settings for this token)

                    return this._requestSettingsFromMiniserver(mac).then(function (settings) {
                        settings = this._validateSettings(settings); // save

                        pushNotificationService._getMiniserverForMac(mac).then(function (miniserver) {
                            miniserver.settings = settings;
                            return pushNotificationService._savePNSFile();
                        });

                        return settings;
                    }.bind(this), function () {
                        return this._validateSettings(false);
                    }.bind(this));
                } else if (miniserver.hasOwnProperty("token") && miniserver.hasOwnProperty("settings")) {
                    Debug.PushNotification && console.log(" - is enabled, has settings, return"); // settings stored, use it!

                    return this._validateSettings(miniserver.settings);
                } else if (miniserver.hasOwnProperty("token")) {
                    Debug.PushNotification && console.log(" - is enabled, has no settings, use default..");
                    return this._validateSettings();
                } else if (platform === PlatformType.Webinterface || platform === PlatformType.DeveloperInterface || !pushNotificationService.deviceSupportsPushNotifications) {
                    // or if the device doesn't support push anyway!
                    if (miniserver.hasOwnProperty("settings")) {
                        Debug.PushNotification && console.log(" - is webinterface, has settings, return");
                        return this._validateSettings(miniserver.settings);
                    } else {
                        Debug.PushNotification && console.log(" - is webinterface, has no settings, use default..");
                        return this._validateSettings();
                    }
                } else {
                    Debug.PushNotification && console.log(" - is disabled, disable all settings");
                    return this._validateSettings(false);
                }
            }.bind(this), function () {
                return this._validateSettings(false);
            }.bind(this));
        }

        updateInAppSettings(settings) {
            var ms = ActiveMSComponent.getActiveMiniserver(),
                mac = ms.serialNo; // validate settings

            var newSettings = this._validateSettings(settings);

            Debug.PushNotification && console.log(weakThis.name, "updateInAppSettings: ms=" + mac + ", " + JSON.stringify(newSettings));
            return this._storeInAppSettings(mac, newSettings);
        }

        updatePushSettings(settings) {
            var ms = ActiveMSComponent.getActiveMiniserver(),
                mac = ms.serialNo; // validate settings

            var newSettings = this._validateSettings(settings);

            if (pushNotificationService.deviceSupportsPushNotifications) {
                return pushNotificationService.isPushEnabledForMiniserver(mac).then(function () {
                    // enabled, send it to MS
                    return this._sendSettingsToMiniserver(mac, settings).then(function () {
                        // settings sent, now store!
                        return pushNotificationService._getMiniserverForMac(mac).then(function (miniserver) {
                            miniserver.settings = newSettings;
                            return pushNotificationService._savePNSFile();
                        });
                    });
                }.bind(this), function () {
                    // not enabled, only store it
                    return this._storePushSettings(mac, newSettings);
                }.bind(this));
            } else {
                // maybe we are in webinterface or device doesn't support push..
                return this._storePushSettings(mac, newSettings);
            }
        }

        /**
         * returns an object with iconSrc and iconColor to be used for the notification
         * returns an Promise if the control is not given (please provide mac and controlUUID) -> I'll search for the control!
         * @param control
         * @param lvl
         * @param [mac]
         * @param [controlUUID]
         * @returns {Promise || {}}
         */
        determineNotificationStyle(control, lvl, mac, controlUUID) {
            var def = Q.defer();

            if (lvl === PushNotificationLevel.SYSTEM_ERROR) {
                // handle system errors
                def.resolve({
                    iconSrc: Icon.Notification.ERROR,
                    iconColor: window.Styles.colors.orange
                });
            } else if (control) {
                // control give, get style
                def.resolve(this._determineNotificationStyle(control, lvl));
            } else if (mac && controlUUID) {
                // no control give, but mac and controlUUID, try to load it
                def.resolve(PersistenceComponent.getControlOfMiniserver(mac, controlUUID).then(function (result) {
                    // got control, get style
                    if (result === ControlLoadError.CONTROL_NOT_AVAILABLE || result === ControlLoadError.MINISERVER_NOT_AVAILABLE) return NOTIFICATION_ICON_DEFAULTS;
                    return this._determineNotificationStyle(result, lvl);
                }.bind(this), function () {
                    // no control found, use default style
                    return NOTIFICATION_ICON_DEFAULTS;
                }));
            } else {
                // use default
                def.resolve(NOTIFICATION_ICON_DEFAULTS);
            }

            return def.promise;
        }

        _storeInAppSettings(mac, newSettings) {
            return pushNotificationService._getMiniserverForMac(mac, true).then(function (miniserver) {
                miniserver.inAppSettings = newSettings;
                return pushNotificationService._savePNSFile();
            });
        }

        _storePushSettings(mac, newSettings) {
            return pushNotificationService._getMiniserverForMac(mac, true).then(function (miniserver) {
                miniserver.settings = newSettings;
                return pushNotificationService._savePNSFile();
            });
        }

        _determineNotificationStyle(ctrl, lvl) {
            // search an icon for the notification
            var iconSrc = NOTIFICATION_ICON_DEFAULTS.iconSrc,
                iconColor = NOTIFICATION_ICON_DEFAULTS.iconColor;

            if (ctrl.type === ControlType.ALARM) {
                iconSrc = Icon.Notification.ALARM;

                if (lvl === PushNotificationLevel.ERROR) {
                    iconColor = window.Styles.colors.red;
                }
            } else if (ctrl.type === ControlType.SMOKE_ALARM) {
                iconSrc = Icon.Notification.SMOKE_ALARM; //TODO-woessto: from lindosi:  -> depending on configuration?

                if (lvl === PushNotificationLevel.ERROR) {
                    iconColor = window.Styles.colors.red;
                }
            } else if (ctrl.type === ControlType.INTERCOM) {
                iconSrc = Icon.Notification.INTERCOM;
                iconColor = window.Styles.colors.green;
            } else if (ctrl.type === ControlType.HOURCOUNTER) {
                iconSrc = Icon.Notification.HOURCOUNTER;
                iconColor = window.Styles.colors.red;
            } else if (ctrl.type === ControlType.SAUNA) {
                iconSrc = Icon.Notification.SAUNA;

                if (lvl === PushNotificationLevel.ERROR) {
                    iconColor = window.Styles.colors.red;
                } else {
                    iconColor = window.Styles.colors.green;
                }
            } else if (ctrl.type === ControlType.AAL_SMART_ALARM) {
                iconSrc = Icon.AaLSmartAlarm.CROSS;

                if (lvl === PushNotificationLevel.ERROR) {
                    iconColor = window.Styles.colors.red;
                }
            }

            return {
                iconSrc: iconSrc,
                iconColor: iconColor
            };
        }

        // PRIVATE Methods

        /**
         * validates the settings
         * either merges/validates settings object or sets all settings to the boolean value
         * @param [settings] {boolean|object}
         * @returns {{}}
         * @private
         */
        _validateSettings(settings) {
            var newSettings = cloneObjectDeep(PushNotificationSettingDefaults); // The push notification type should be activated per default for admin users

            newSettings[PushNotificationSettingType.ERRORS] = ActiveMSComponent.getCurrentUser().isAdmin;

            if (typeof settings === "object") {
                if (typeof settings[PushNotificationSettingType.ALARM] === "boolean") {
                    newSettings[PushNotificationSettingType.ALARM] = settings[PushNotificationSettingType.ALARM];
                }

                if (typeof settings[PushNotificationSettingType.SMOKE_ALARM] === "boolean") {
                    newSettings[PushNotificationSettingType.SMOKE_ALARM] = settings[PushNotificationSettingType.SMOKE_ALARM];
                }

                if (typeof settings[PushNotificationSettingType.HOURCOUNTER] === "boolean") {
                    newSettings[PushNotificationSettingType.HOURCOUNTER] = settings[PushNotificationSettingType.HOURCOUNTER];
                }

                if (typeof settings[PushNotificationSettingType.SAUNA] === "boolean") {
                    newSettings[PushNotificationSettingType.SAUNA] = settings[PushNotificationSettingType.SAUNA];
                }

                if (typeof settings[PushNotificationSettingType.INTERCOM] === "boolean") {
                    newSettings[PushNotificationSettingType.INTERCOM] = settings[PushNotificationSettingType.INTERCOM];
                }

                if (typeof settings[PushNotificationSettingType.INTERCOM_GEN_2] === "boolean") {
                    newSettings[PushNotificationSettingType.INTERCOM_GEN_2] = settings[PushNotificationSettingType.INTERCOM_GEN_2];
                }
                /*if (typeof settings[PushNotificationSettingType.I_ROOM] === "boolean") {
                    newSettings[PushNotificationSettingType.I_ROOM] = settings[PushNotificationSettingType.I_ROOM];
                }*/


                if (typeof settings[PushNotificationSettingType.POOL] === "boolean") {
                    newSettings[PushNotificationSettingType.POOL] = settings[PushNotificationSettingType.POOL];
                }

                if (typeof settings[PushNotificationSettingType.STEAK] === "boolean") {
                    newSettings[PushNotificationSettingType.STEAK] = settings[PushNotificationSettingType.STEAK];
                }

                if (typeof settings[PushNotificationSettingType.CUSTOM] === "boolean") {
                    newSettings[PushNotificationSettingType.CUSTOM] = settings[PushNotificationSettingType.CUSTOM];
                }

                if (typeof settings[PushNotificationSettingType.ERRORS] === "boolean") {
                    newSettings[PushNotificationSettingType.ERRORS] = settings[PushNotificationSettingType.ERRORS];
                }

                if (typeof settings[PushNotificationSettingType.AAL_SMART_ALARM] === "boolean") {
                    newSettings[PushNotificationSettingType.AAL_SMART_ALARM] = settings[PushNotificationSettingType.AAL_SMART_ALARM];
                }

                if (typeof settings[PushNotificationSettingType.MAILBOX] === "boolean") {
                    newSettings[PushNotificationSettingType.MAILBOX] = settings[PushNotificationSettingType.MAILBOX];
                }

                if (typeof settings[PushNotificationSettingType.AAL_EMERGENCY] === "boolean") {
                    newSettings[PushNotificationSettingType.AAL_EMERGENCY] = settings[PushNotificationSettingType.AAL_EMERGENCY];
                }
            } else if (typeof settings === "boolean") {
                newSettings[PushNotificationSettingType.ALARM] = settings;
                newSettings[PushNotificationSettingType.SMOKE_ALARM] = settings;
                newSettings[PushNotificationSettingType.HOURCOUNTER] = settings;
                newSettings[PushNotificationSettingType.SAUNA] = settings;
                newSettings[PushNotificationSettingType.INTERCOM] = settings;
                newSettings[PushNotificationSettingType.INTERCOM_GEN_2] = settings; //newSettings[PushNotificationSettingType.I_ROOM] = settings;

                newSettings[PushNotificationSettingType.POOL] = settings;
                newSettings[PushNotificationSettingType.STEAK] = settings;
                newSettings[PushNotificationSettingType.CUSTOM] = settings;
                newSettings[PushNotificationSettingType.ERRORS] = settings;
                newSettings[PushNotificationSettingType.AAL_SMART_ALARM] = settings;
                newSettings[PushNotificationSettingType.MAILBOX] = settings;
                newSettings[PushNotificationSettingType.AAL_EMERGENCY] = settings;
            }
            /*if (!Feature.I_ROOM_CONTROL_NOTIFICATIONS) {
                delete newSettings[PushNotificationSettingType.I_ROOM];
            }*/


            if (!Feature.POOL_CONTROL_NOTIFICATIONS) {
                delete newSettings[PushNotificationSettingType.POOL];
            }

            if (!Feature.STEAK) {
                delete newSettings[PushNotificationSettingType.STEAK];
            }

            if (!Feature.AAL_SMART_ALARM) {
                delete newSettings[PushNotificationSettingType.AAL_SMART_ALARM];
            }

            if (!Feature.MAILBOX) {
                delete newSettings[PushNotificationSettingType.MAILBOX];
            }

            if (!Feature.AAL_EMERGENCY) {
                delete newSettings[PushNotificationSettingType.AAL_EMERGENCY];
            }

            if (!Feature.INTERCOM_GEN_2) {
                delete newSettings[PushNotificationSettingType.INTERCOM_GEN_2];
            }

            return newSettings;
        }

        _checkPushRegistration() {
            var platform = PlatformComponent.getPlatformInfoObj().platform;

            if ((platform === PlatformType.Android || platform === PlatformType.IOS) && pushNotificationService.deviceSupportsPushNotifications) {
                var ms = ActiveMSComponent.getActiveMiniserver(),
                    mac = ms.serialNo,
                    msName = ms.msName;
                pushNotificationService.isPushConfiguredForMiniserver(mac).then(function () {
                    // configured, check if push is enabled
                    return pushNotificationService.isPushEnabledForMiniserver(mac).then(function () {
                        // enabled, check if Miniserver has the token also!
                        return pushNotificationService.checkRegistrationOnMiniserver(mac); // success -> everything ok again!
                    }, function () {// disabled, do nothing (but handle the error!)
                    });
                }.bind(this), function () {
                    // not configured, ask for registration!
                    var enableContent = {
                        title: _('notifications.push-notifications'),
                        message: _('notifications.first-connect.enable', {
                            msName: msName
                        }),
                        buttons: [{
                            title: _('yes'),
                            color: window.Styles.colors.green
                        }, {
                            title: _('no'),
                            color: Color.STATE_INACTIVE
                        }, {
                            title: _('settings'),
                            color: Color.STATE_INACTIVE
                        }]
                    };
                    this._enableRequestPopup = NavigationComp.showPopup(enableContent); // store ref to be able to remove it (avoid multiple popups!)

                    this._enableRequestPopup.then(function (index) {
                        delete this._enableRequestPopup;

                        if (index === 0) {
                            // user said yes!
                            // check if push is enabled for App
                            pushNotificationService.isPushEnabledForApp(true).done(function () {
                                // -> enabled, activate! -> show waiting popup
                                var activateContent = {
                                    title: _('please-wait'),
                                    message: _('notifications.registering'),
                                    icon: Icon.INFO,
                                    buttonCancel: true
                                };
                                this._enableRequestPopup = NavigationComp.showPopup(activateContent);

                                this._enableRequestPopup.done(null, function () {
                                    delete this._enableRequestPopup; // user canceled
                                }.bind(this));

                                pushNotificationService.registerMiniserver4Push(mac).done(function () {
                                    // -> registered
                                    if (this._enableRequestPopup) {
                                        NavigationComp.removePopup(this._enableRequestPopup);
                                        delete this._enableRequestPopup;
                                    }
                                }.bind(this), function (e) {
                                    if (this._enableRequestPopup) {
                                        NavigationComp.removePopup(this._enableRequestPopup);
                                        delete this._enableRequestPopup;
                                        GUI.Notification.createGeneralNotification({
                                            title: "Push Notifications registration failed!",
                                            subtitle: e,
                                            removeAfter: 3
                                        }, NotificationType.ERROR);
                                    }
                                }.bind(this));
                            }.bind(this), function () {
                                var content = {
                                    title: _('caution'),
                                    message: _('notifications.enable-first'),
                                    buttonOk: _('okay'),
                                    icon: Icon.CAUTION
                                };
                                this._enableRequestPopup = NavigationComp.showPopup(content);
                            }.bind(this));
                        } else {
                            // unregister.. -> simply stores the "disabled" flag
                            pushNotificationService.unregisterMiniserver4Push(mac, true);

                            if (index === 2) {
                                // -> settings
                                NavigationComp.showState(ScreenState.NotificationsSettings, null, AnimationType.MODAL);
                            }
                        }
                    }.bind(this), function () {
                        delete this._enableRequestPopup;
                    }.bind(this));
                }.bind(this)).fail(function (e) {
                    console.error(e);
                    /*GUI.Notification.createGeneralNotification({
                     title: "Something went wrong while registering Push Notifications!",
                     subtitle: e,
                     removeAfter: 3
                     }, NotificationType.ERROR);*/
                });
            }
        }

        _requestSettingsFromMiniserver(mac) {
            Debug.PushNotification && console.log("PNS _requestSettingsFromMiniserver");
            return pushNotificationService._getStoredMiniserverPushToken(mac).then(function (token) {
                return SandboxComponent.send(Commands.format(Commands.PNS.GET_SETTINGS, token)).then(function (res) {
                    Debug.PushNotification && console.log(" - got settings!");
                    return getLxResponseValue(res);
                });
            });
        }

        _sendSettingsToMiniserver(mac, settings) {
            Debug.PushNotification && console.log("PNS _sendSettingsToMiniserver:" + mac + " settings: " + JSON.stringify(settings));
            return pushNotificationService._getStoredMiniserverPushToken(mac).then(function (token) {
                var encodedSettings = encodeURIComponent(JSON.stringify(settings));
                return SandboxComponent.send(Commands.format(Commands.PNS.SET_SETTINGS, token, encodedSettings), null, true).then(function () {
                    Debug.PushNotification && console.log(" - settings sent!");
                    return true;
                }, function (e) {
                    console.log("Error while sending settings: " + e);
                });
            });
        }

        /**
         * checks if we have already received the same notification
         * @param newPN
         * @returns {boolean} if we haven't received this notification before!
         * @private
         */
        _checkForNotification(newPN) {
            var i = 0,
                pn; // look if we have received it over websocket

            while (i < this._wsHistory.length) {
                pn = this._wsHistory[i];

                if (pn.uid === newPN.uid) {
                    Debug.PushNotification && console.log(" - already received over WebSocket");
                    return false;
                }

                i++;
            }

            i = 0; // look if we have received it over push

            while (i < this._pnHistory.length) {
                pn = this._pnHistory[i];

                if (pn.uid === newPN.uid) {
                    Debug.PushNotification && console.log(" - already received over Push");
                    return false;
                }

                i++;
            } // Prevent the MessageCenter notifications from unread handling (Notification history and application badge)
            // Such notifications shouldn't trigger any badge and shouldn't be visible in the notification history


            if (newPN.type !== PushNotificationType.MESSAGE_CENTER) {
                this._history.unshift(newPN);

                this._updateUnreadNotifications();
            }

            return true;
        }

        _removeNotificationFromHistory(uid) {
            var i = 0,
                pn;

            while (i < this._history.length) {
                pn = this._history[i];
                if (pn.uid === uid) break;
                i++;
            }

            this._history.splice(i, 1);

            this._updateUnreadNotifications();
        }

        _updateUnreadNotifications() {
            Debug.PushNotification && console.log("PNS", "_updateUnreadNotifications");
            var count = this._history.length,
                platform = PlatformComponent.getPlatformInfoObj().platform;
            comp.emit(SandboxComp.ECEvent.UnreadNotificationCount, count);

            if (PlatformComponent.isCordova) {
                Debug.PushNotification && console.log("PNS", " history:" + JSON.stringify(this._history));
                Debug.PushNotification && console.log("PNS", " history length: " + this._history.length);
                pushNotification.setBadge(function () {// badge set!
                    Debug.PushNotification && console.log("PNS", " badge set!");
                }, function () {// badge not set!
                    Debug.PushNotification && console.error("PNS", " badge not set!");
                }, count);
            } else if (PlatformComponent.isElectron && platform === PlatformType.Mac) {
                try {
                    // Only works in macOS
                    if (count > 0) {
                        window.electron.remote.app.dock.setBadge("" + count);
                    } else {
                        window.electron.remote.app.dock.setBadge("");
                    }
                } catch (e) {// Do nothing
                }
            } else if (count > 0) {
                document.title = "(" + count + ") " + DEFAULT_TITLE;
            } else {
                document.title = DEFAULT_TITLE;
            }
        }

        _playSoundFor(pn) {
            if (this.appIsPaused) {
                // We will not ring the bell if the app is in the background, specifically on a Desktop/Laptop
                return;
            }
            var soundName = pn.sound,
                control = ActiveMSComponent.getControlByUUID(pn.data.uuid),
                controlStates = control ? control.getStates() : null;

            if (pn.type === PushNotificationType.INTERCOM_RICH_PAST) {
                return;
            } else if (pn.type === PushNotificationType.INTERCOM_RICH && controlStates && controlStates.muted) {
                // Don't play a sound if the control is muted!
                return;
            }

            Debug.PushNotification && console.log("PNS _playSound:" + soundName);

            if (soundName === "default" || !soundName) {
                soundName = NotificationSound.DEFAULT;
            }

            if (PlatformComponent.isCordova) {
                pushNotification.playNotificationSound(null, null, `${soundName}.mp3`);
            } else {
                this._playBrowserSound(soundName);
            }

            this._vibrate();
        }

        _playBrowserSound(soundName) {
            var audioObj, stopTimeout;

            try {
                if (window.Audio) {
                    audioObj = new Audio("resources/Audio/" + soundName + ".mp3"); // start a timeout that stops the audio file, in browsers the file might start playing too late
                    // if e.g. the browser tab is in the background.

                    stopTimeout = setTimeout(function () {
                        Debug.PushNotification && console.error("PNS      audio didn't play soon enough. so stop.");
                        audioObj && audioObj.pause();
                        audioObj = null;
                    }.bind(this), 2000); // when it starts playing, remove the timeout - so it isn't stopped suddenly

                    audioObj.addEventListener('playing', function () {
                        Debug.PushNotification && console.log("PNS      audio playing");
                        clearTimeout(stopTimeout);
                        stopTimeout = null;
                        audioObj = null;
                    }.bind(this));
                    audioObj.play();
                } else {
                    var audioElement = $('<audio id="' + soundName + '" src="/resources/Audio/' + soundName + ".wav" + '" type="audio/wav" preload="auto" />')[0]; // other attrs: autoplay loop

                    audioElement.onpause = function () {
                        $(this).remove();
                    };

                    $(document.body).append(audioElement);
                    audioElement.play();
                }
            } catch (e) {// According to Microsoft, Windows versions that ends with N or KN are editions of Windows
                // that are missing media-related features, hence playing audio in Edge is not possible
                // Catch this error to ensure that the Intercom will be displayed
            }
        }

        _vibrate() {
            if (typeof navigator.vibrate === "function") {
                navigator.vibrate([300, 150, 300]);
            } else {
                Debug.PushNotification && console.info("vibrating not supported on this device");
            }
        }

        _minimizedNotifications() {
            var minimizedResult = [];

            this._history.forEach(function (not) {
                minimizedResult.push({
                    type: not.type,
                    uid: not.uid,
                    title: not.title,
                    message: not.message,
                    time: not.ts,
                    level: not.data.lvl,
                    miniserver: not.ms_name,
                    mac: not.data.mac,
                    uuid: not.data.uuid,
                    original: not
                });
            }.bind(this));

            return minimizedResult;
        }

        /**
         * creates a listener for states of a control
         * if all states are 0, the notification will be removed automatically
         * @param pn
         * @private
         */
        _listenForStateOfControlNotification(pn) {
            if (!pn.data || !pn.data.uuid) {
                return;
            }

            var control = ActiveMSComponent.getControlByUUID(pn.data.uuid);

            if (!control) {
                return;
            }

            var stateUUIDs = [];

            switch (control.type) {
                case ControlType.HOURCOUNTER:
                    stateUUIDs.push(control.states.overdue);
                    break;

                /*case ControlType.SAUNA:       // not possible, because the 2 errors behave differently (error isn't true for max. operating time) (https://www.wrike.com/open.htm?id=165261473)
                    stateUUIDs.push(control.states.active);
                    stateUUIDs.push(control.states.error);
                    break;*/

                case ControlType.POOL:
                    if (Feature.POOL_ERROR_HANDLING) {
                        stateUUIDs.push(control.states.error);
                        break;
                    } else {
                        return;
                    }

                default:
                    return;
            }

            var listener = {
                uid: pn.uid,
                uuid: control.uuidAction,
                stateUUIDs: stateUUIDs,
                // all states for these uuids must be 0!
                newStatesReceived: function newStatesReceived(values) {
                    for (var i = 0; i < listener.stateUUIDs.length; i++) {
                        if (values[listener.stateUUIDs[i]] !== 0) {
                            return;
                        }
                    }

                    this.markNotificationAsRead(pn.uid, false);
                }.bind(this)
            };

            this._controlStateListeners.push(listener);

            comp.registerUUIDs(listener, listener.stateUUIDs);
        }

        /**
         * removes the state listener (eg. if the control is swiped away)
         * @param pn
         * @private
         */
        _removeListenerForStateOfControlNotification(pn) {
            if (!pn.data || !pn.data.uuid) {
                return;
            }

            var control = ActiveMSComponent.getControlByUUID(pn.data.uuid);

            if (!control) {
                return;
            }

            var listener;

            for (var idx = 0; idx < this._controlStateListeners.length; idx++) {
                listener = this._controlStateListeners[idx];

                if (listener.uid === pn.uid && listener.uuid === control.uuidAction) {
                    comp.unregisterUUIDs(listener, listener.stateUUIDs);

                    this._controlStateListeners.splice(idx, 1);

                    break;
                }
            }
        }

        /**
         * returns the correct icon info depending on the type of the device notification
         * @param pn
         * @returns {*}
         * @private
         */
        _getIconInfoForDeviceManagerNotification(pn) {
            switch (pn.data.type) {
                case DeviceMonitorNotificationType.OFFLINE:
                    return {
                        iconSrc: Icon.Notification.ERROR,
                        iconColor: window.Styles.colors.orange
                    };

                case DeviceMonitorNotificationType.BATTERY_WEAK:
                    return {
                        iconSrc: Icon.BATTERY_WEAK,
                        iconColor: Color.STATE_INACTIVE_B
                    };

                default:
                    return NOTIFICATION_ICON_DEFAULTS;
            }
        }

    };
});
