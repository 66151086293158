'use strict';

define("CarChargerControlStateContainer", ["ControlStateContainer", "CarChargerControlEnums"], function (ControlStateContainer, CarChargerControlEnums) {
    return class CarChargerControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.status = newVals[this.control.states.status];
            this.states.isOffline = this.states.status === CarChargerControlEnums.Status.OFFLINE;
            this.states.isInitializing = this.states.status === CarChargerControlEnums.Status.INITIALIZING;
            this.states.isOnline = this.states.status === CarChargerControlEnums.Status.ONLINE;
            this.states.carConnected = this.states.isOnline && newVals[this.control.states.connected] === 1;
            this.states.isCharging = this.states.isOnline && newVals[this.control.states.charging] === 1;
            this.states.chargingFinished = this.states.isOnline && newVals[this.control.states.chargingFinished] === 1;
            this.states.power = this.states.isOnline ? newVals[this.control.states.power] : 0; // kW

            this.states.sessionEnergy = newVals[this.control.states.energySession]; // kWh

            this.states.chargeDuration = newVals[this.control.states.chargeDuration]; // secs

            this.states.limitMode = newVals[this.control.states.limitMode];
            this.states.minLimit = newVals[this.control.states.minLimit]; // kW

            this.states.maxLimit = newVals[this.control.states.maxLimit]; // kW

            this.states.currentLimit = newVals[this.control.states.currentLimit]; // kW

            this.states.limitModeText = this._getLimitModeString(this.states.limitMode);
            this.states.powerText = lxUnitConverter.convertAndApply("%.2f kW", this.states.power);
            this.states.sessionEnergyText = lxUnitConverter.convertAndApply("%.2f kWh", this.states.sessionEnergy);

            this._parseProfiles(newVals);

            this._detectLoadManagement(newVals);
        }

        getStateTextForContent() {
            if (this.states.isOffline) {
                return _('controls.car-charger.offline');
            } else if (this.states.isInitializing) {
                return _('requesting-status');
            } else if (this.states.isCharging) {
                if (this.states.power) {
                    return _("controls.car-charger.charging");
                } else {
                    return _('controls.car-charger.car-connected');
                }
            } else if (this.states.carConnected) {
                return _("controls.car-charger.car-connected");
            } else if (!this.states.carConnected) {
                return _("controls.car-charger.car-disconnected");
            }
        }

        getStateText() {
            if (this.states.isOffline) {
                return _('controls.car-charger.offline');
            } else if (this.states.isInitializing) {
                return _('requesting-status');
            } else if (this.states.isCharging) {
                return this.states.powerText + SEPARATOR_SYMBOL + this.states.sessionEnergyText;
            } else if (this.states.carConnected) {
                return _("controls.car-charger.not-charging");
            } else if (!this.states.carConnected) {
                return _("controls.car-charger.short-car-disconnected");
            }
        }

        getStateTextColor() {
            if (this.states.isCharging) {
                return window.Styles.colors.green;
            }
        }

        getStateColor() {
            if (this.states.isCharging) {
                return window.Styles.colors.green;
            }
        }

        getStateIconSmall() {
            if (this.states.isCharging) {
                return {
                    iconSrc: Icon.CarCharger.BOLT,
                    color: window.Styles.colors.green
                };
            } else if (this.states.carConnected) {
                return {
                    iconSrc: Icon.CarCharger.PLUG,
                    color: window.Styles.colors.orange
                };
            }
        }

        /**
         * Returns an array of object with at least a "name" and "id" property
         * @param sourceState
         * @return {*[]}
         */
        getAutomaticDesignerStateObjectsFromState(sourceState) {
            var objectStates;

            switch (sourceState) {
                case "profiles":
                    objectStates = this.states.profiles.map(function (name, id) {
                        return {
                            id: id,
                            name: name
                        };
                    });
                    break;

                default:
                    objectStates = super.getAutomaticDesignerStateObjectsFromState(...arguments);
            }

            return objectStates;
        }

        /**
         * Will try to parse the profiles provided in the newVals. Returns an empty array and -1 if it cannot be read.
         * @param newVals
         * @private
         */
        _parseProfiles(newVals) {
            // profiles
            var profileString = newVals[this.control.states.profiles] != null && newVals[this.control.states.profiles].text;

            if (typeof profileString === "string" && profileString.length > 0) {
                if (profileString.indexOf("|") !== -1) {
                    this.states.profiles = profileString.split("|"); // String, | seperated
                } else {
                    this.states.profiles = [profileString];
                }

                this.states.currentProfile = this.states.isOnline ? newVals[this.control.states.currentProfile] : -1; // 0,1
            } else {
                this.states.profiles = [];
                this.states.currentProfile = -1;
            }
        }

        /**
         * Previously the load management was always present. But after some releases, the load managment was made
         * optionally - that is why this method checks for the capability to handle load management. 60364252.
         * @param newVals
         * @private
         */
        _detectLoadManagement(newVals) {
            //
            if (this.control.states.hasOwnProperty("showLoadManagement")) {
                this.states.showLoadManagement = newVals[this.control.states.showLoadManagement] === 1;
            } else {
                this.states.showLoadManagement = true;
            }
        }

        _getLimitModeString(mode) {
            if (mode === CarChargerControlEnums.LimitMode.OFF) {
                return _('controls.car-charger.charging-limit.off');
            } else if (mode === CarChargerControlEnums.LimitMode.MANUAL) {
                return _('controls.car-charger.charging-limit.manual');
            } else if (mode === CarChargerControlEnums.LimitMode.AUTO) {
                return _('controls.car-charger.charging-limit.auto');
            }
        }

    };
});
