'use strict';
/**
 * Handles the MiniserverInformation
 */

ActiveMSComp.factory('MSInformationExt', function () {
    // internal variables
    let weakThis,
        activeMsComp = {};
    var configVersion = "",
        brandingDate = "",
        deviceMonitorUuid = "",
        structureDate = "",
        localUrl = "",
        remoteUrl = "",
        originalRemoteUrl = "",
        tempUnit = "",
        squareMeasure = "",
        currencyString = "",
        msName = "",
        location = "",
        serialNo = "",
        tlsInfo = TlsState.UNKNOWN,
        msType = MiniserverType.MINISERVER,
        gatewayType = GatewayType.NONE,
        dataCenter = null,
        currentUser = null,
        isAdmin = false,
        isUserAllowedToChangePwFlag = false,
        availablePermissions = 0,
        // newer Miniservers support a more powerful permission handling.
        hk,
        initInfoObj = null;
    var partnerName, partnerImage, partnerDetails;

    /**
     * c-tor for Miniserver Information Extension
     * @param comp reference to the ActiveMSComponent
     * @constructor
     */

    function MSInformationExt(comp) {
        weakThis = this
        activeMsComp = comp; // internal broadcasts

        activeMsComp.on(ActiveMSComp.ECEvent.ConnEstablished, function () {
            // Load all initial required app information like the StructureDate, MsDateTime and the UTCOffset
            downloadInitialAppInfo();
        });
        activeMsComp.on(ActiveMSComp.ECEvent.StopMSSession, function () {
            // reset all data
            resetMiniserverInformationData();
        });
    } // public methods

    /**
     * Receiving structure infos after getting the newest structure
     * @param msInfoObject
     * @param partnerInfoObject
     */


    MSInformationExt.prototype.setStructureInfos = function setStructureInfos(msInfoObject, partnerInfoObject) {
        brandingDate = msInfoObject.brandingDate;
        deviceMonitorUuid = msInfoObject.deviceMonitor;
        localUrl = msInfoObject.localUrl;
        remoteUrl = msInfoObject.remoteUrl;
        originalRemoteUrl = msInfoObject.originalRemoteUrl;
        tempUnit = TempAppendix(msInfoObject.tempUnit);
        squareMeasure = msInfoObject.squareMeasure;
        currencyString = msInfoObject.currency;
        msName = msInfoObject.msName;
        location = msInfoObject.location; //serialNo = msInfoObject.serialNr;  // don't use "msInfoObject.serialNo", because it can be different to the actual serialNo!

        msType = msInfoObject.miniserverType;

        if (msInfoObject.currentUser) {
            // check, for older versions..
            currentUser = msInfoObject.currentUser;
            isAdmin = !!msInfoObject.currentUser.isAdmin && !PairedAppComponent.isPaired();
        } else {
            isAdmin = false;
        }

        if (Feature.PERMISSION_HANDLING) {
            // bitmask that indicates what permissions can be acquired by this user. These can be requested using tokens.
            availablePermissions = PairedAppComponent.filterPermissions(currentUser.userRights);
        } else {
            availablePermissions = 0;
        }

        if (Feature.CHANGE_PASSWORD_OPTIONAL) {
            isUserAllowedToChangePwFlag = msInfoObject.currentUser.changePassword && !PairedAppComponent.isPaired();
        } else {
            // before that, everyone was allowed to change the password
            isUserAllowedToChangePwFlag = true;
        } // Always set a gatewayType to handle non-client, client, non-client switch while the app is connected


        if (typeof msInfoObject.gatewayType === "number") {
            gatewayType = msInfoObject.gatewayType;
        } else {
            // set the gatewayType to None if the gatewayType property is missing. this will reset the gatewayType
            gatewayType = GatewayType.NONE;
        }

        if (Boolean(partnerInfoObject)) {
            partnerName = partnerInfoObject.name;
            partnerImage = partnerInfoObject.image;
            var keys = Object.keys(partnerInfoObject.details);

            for (var i = 0; i < keys.length; i++) {
                if (partnerInfoObject.details[keys[i]]) {
                    partnerDetails = partnerInfoObject.details;
                    break;
                }
            }
        }
    };
    /**
     * Receiving structure infos from the newest miniserver object of the ActiveMSComponent
     * @param msObject
     */


    MSInformationExt.prototype.setTemporarilyStructureInfos = function (msObject) {
        // only set connection relevant infos from the object
        if (msObject.localUrl) {
            localUrl = msObject.localUrl;
        }

        if (msObject.remoteUrl) {
            remoteUrl = msObject.remoteUrl;
        }

        if (msObject.msName) {
            msName = msObject.msName;
        }

        if (msObject.serialNo) {
            serialNo = msObject.serialNo;
        }

        if (msObject.tlsInfo) {
            tlsInfo = msObject.tlsInfo;
        }

        if (msObject.dataCenter) {
            dataCenter = msObject.dataCenter;
        }
    };
    /**
     * Informs whether or not the Miniserver has a HomeKit access token.
     * The token is downloaded after a reboot and if one couldn't be retrieved at that time, every 24hours.
     * Once the token is present on the Miniserver, it'll remain present, even when offline.
     */


    MSInformationExt.prototype.hasHomeKitToken = function hasHomeKitToken() {
        return !!hk;
    };
    /**
     * Sets the current Config Version which is on the Miniserver.
     */


    MSInformationExt.prototype.setConfigVersion = function setConfigVersion(cfgVersion) {
        configVersion = cfgVersion;
    };
    /**
     * Returns the current Config Version which is on the Miniserver.
     */


    MSInformationExt.prototype.getConfigVersion = function getConfigVersion() {
        return configVersion;
    };
    /**
     * Returns the current Structure Date.
     */


    MSInformationExt.prototype.getStructureDate = function getStructureDate() {
        return structureDate;
    };
    /**
     * Sets the current Structure Date.
     */


    MSInformationExt.prototype.setStructureDate = function setStructureDate(structDate) {
        structureDate = structDate;
    };

    MSInformationExt.prototype.getBrandingDate = function getBrandingDate() {
        return brandingDate;
    };

    MSInformationExt.prototype.getDeviceMonitorUuid = function getDeviceMonitorUuid() {
        return deviceMonitorUuid;
    };
    /**
     * Returns the local URL of the Miniserver
     */


    MSInformationExt.prototype.getLocalUrl = function getLocalUrl() {
        return localUrl;
    };
    /**
     * Returns the remote URL of the Miniserver
     */


    MSInformationExt.prototype.getRemoteUrl = function getRemoteUrl() {
        return remoteUrl;
    };

    MSInformationExt.prototype.getOriginalRemoteUrl = function getOriginalRemoteUrl() {
        return originalRemoteUrl;
    };
    /**
     * Returns the Temperature Unit of the Miniserver
     */


    MSInformationExt.prototype.getTempUnit = function getTempUnit() {
        return tempUnit;
    };
    /**
     * Returns the square measure of the Miniserver
     * eg. square meter, square feet..
     */


    MSInformationExt.prototype.getSquareMeasure = function getSquareMeasure() {
        return squareMeasure;
    };
    /**
     * Returns the currency of the Miniserver as string
     */


    MSInformationExt.prototype.getCurrencyString = function getCurrencyString() {
        return currencyString;
    };
    /**
     * Returns the name of the Miniserver
     */


    MSInformationExt.prototype.getMiniserverName = function getMiniserverName() {
        return msName;
    };

    MSInformationExt.prototype.getMiniserverLocation = function getMiniserverLocation() {
        return location;
    };
    /**
     * Sets the serial number of the Miniserver
     */


    MSInformationExt.prototype.setMiniserverSerialNo = function setMiniserverSerialNo(snr) {
        serialNo = snr;
    };
    /**
     * Returns the serial number of the Miniserver
     */


    MSInformationExt.prototype.getMiniserverSerialNo = function getMiniserverSerialNo() {
        return serialNo;
    };
    /**
     * Returns the type of the Miniserver
     */


    MSInformationExt.prototype.getMiniserverType = function getMiniserverType() {
        return msType;
    };
    /**
     * returns the gateway type of the Miniserver
     * @returns {GatewayType}
     */


    MSInformationExt.prototype.getGatewayType = function getGatewayType() {
        return gatewayType;
    };
    /**
     * Returns the name of the Partner
     */


    MSInformationExt.prototype.getPartnerName = function getPartnerName() {
        return partnerName;
    };
    /**
     * Returns the image uuid of the Partner
     */


    MSInformationExt.prototype.getPartnerImageUUID = function getPartnerImageUUID() {
        return partnerImage;
    };
    /**
     * Returns detail information of the Partner (e.g. website, e-mail, telephone)
     */


    MSInformationExt.prototype.getPartnerDetails = function getPartnerDetails() {
        return partnerDetails;
    };
    /**
     * returns current user object
     * @returns {object}
     */


    MSInformationExt.prototype.getCurrentUser = function getCurrentUser() {
        return currentUser;
    };
    /**
     * set current user name
     */


    MSInformationExt.prototype.setCurrentUserName = function setCurrentUserName(name) {
        currentUser.name = name;
    };
    /**
     * returns true/false if user is admin
     * @returns {boolean}
     */


    MSInformationExt.prototype.isAdminUser = function isAdminUser() {
        return isAdmin;
    };
    /**
     * Will return the bitmask specifying what permissions a user can acquire. It does not mean he already has them, it
     * just says the can request that permission and if authenticated successfully, a token granting that permission
     * will be created.
     * @returns {number}
     */


    MSInformationExt.prototype.getAvailablePermissions = function getAvailablePermissions() {
        return availablePermissions;
    };
    /**
     * returns true/false if the user is allowed to change the password
     * @returns {boolean}
     */


    MSInformationExt.prototype.isUserAllowedToChangePW = function isUserAllowedToChangePW() {
        return isUserAllowedToChangePwFlag;
    };
    /**
     * Will update the miniservers TLS info.
     * @param newTlsInfo
     */


    MSInformationExt.prototype.setTlsInfo = function setTlsInfo(newTlsInfo) {
        tlsInfo = newTlsInfo;
    };
    /**
     * Retrieves the Miniservers TLS info.
     * @return {*}
     */


    MSInformationExt.prototype.getTlsInfo = function getTlsInfo() {
        return tlsInfo || {};
    };
    /**
     * Will update the miniservers datacenter info.
     * @param newDataCenter
     */


    MSInformationExt.prototype.setDataCenter = function setDataCenter(newDataCenter) {
        dataCenter = newDataCenter;
    };
    /**
     * Retrieves the Datacenter Info.
     * @return {*}
     */


    MSInformationExt.prototype.getDataCenter = function getDataCenter() {
        return dataCenter || null;
    }; // private methods

    /**
     * reset Minserver information data - also used to reset data.
     */


    var resetMiniserverInformationData = function resetMiniserverInformationData() {
        configVersion = "";
        brandingDate = "";
        deviceMonitorUuid = "";
        structureDate = "";
        localUrl = "";
        remoteUrl = "";
        dataCenter = null;
        tempUnit = "";
        squareMeasure = "";
        currencyString = "";
        msName = "";
        location = "";
        serialNo = "";
        tlsInfo = TlsState.UNKNOWN;
        msType = MiniserverType.MINISERVER;
        gatewayType = GatewayType.NONE;
        currentUser = null;
        isAdmin = false;
        isUserAllowedToChangePwFlag = false;
        availablePermissions = 0;
        partnerName = null;
        partnerImage = null;
        partnerDetails = null;
        hk = null;
        initInfoObj = null;
    };
    /**
     * Downloads the initial information required by the app when establishing a connection to the Miniserver
     */


    var downloadInitialAppInfo = function downloadInitialAppInfo() {
        var all = [],
            value; //console.time("downloadInitialAppInfo");

        if (Feature.INITIAL_APP_INFO) {
            all.push(activeMsComp.send(Commands.INITIAL_APP_INFO).then(function (res) {
                try {
                    value = getLxResponseValue(res); // == StructureDate ==

                    structureDate = value.structureVersion;
                    activeMsComp.receivedStructureDate(structureDate); // == StructureDate ==
                    // == UtcTimeOffset ==
                    // First set the UTC Offset, it is used to calculate the correct Miniserver time, the next function depends on it

                    activeMsComp.receivedMsUtc(value.timezoneOffset); // == UtcTimeOffset ==
                    // == DateTime ==

                    activeMsComp.receivedMsDateTime(value.dateTime); // == DateTime ==

                    if(Feature.TIMEZONE_ID_INFO && value.hasOwnProperty("timezone")) {
                        activeMsComp.receivedTimezoneId(value.timezone);
                    }

                    if (value.hasOwnProperty("dateUserSettings")) {// published via AppInitInfoReceived-Event.
                    }

                    if (value.hasOwnProperty("currentUser")) {
                        // bitmask that indicates what permissions can be acquired by this user. These can be requested using tokens.
                        availablePermissions = PairedAppComponent.filterPermissions(value.currentUser.userRights);
                    }

                    if (value.hasOwnProperty("hk")) {
                        hk = value.hk;
                    }

                    initInfoObj = value;
                } catch (e) {
                    console.error("Error processing initial App information!");
                    console.error(e);
                }
            }));
        } else {
            initInfoObj = {}; //download structure file date

            all.push(downloadStructureFileDate()); // download miniserver utc time offset
            // First download the UTC Offset, it is used to calculate the correct Miniserver time, the next function depends on it

            all.push(downloadMiniserverUtcTimeOffset()); // download miniserver date

            all.push(downloadMiniserverDate());
        }

        return Q.all(all).then(function () {
            //console.timeEnd("downloadInitialAppInfo");
            activeMsComp.emit(ActiveMSComp.ECEvent.AppInitInfoReady, initInfoObj);
        }, function (error) {
            console.error("Promise handling failed for initial App information " + error);
        });
    };
    /**
     * Trigger Download Structure File Date from Miniserver.
     */


    var downloadStructureFileDate = function downloadStructureFileDate() {
        var promise = activeMsComp.send(Commands.STRUCTURE_FILE_DATE);
        promise.then(function (res) {
            structureDate = res.LL.value;
            initInfoObj.structureDate = structureDate;
            activeMsComp.receivedStructureDate(structureDate);
        }, function (error) {
            console.error("Promise handling failed for structure date - " + error);
        });
        return promise;
    };
    /**
     * Trigger Download Miniserver Date from Miniserver.
     */


    var downloadMiniserverDate = function downloadMiniserverDate() {
        var promise = activeMsComp.send(Commands.GET_DATE_TIME);
        promise.then(function (res) {
            activeMsComp.receivedMsDateTime(res.LL.value);
            initInfoObj.dateTime = res.LL.value;
        }, function (error) {
            console.error("Promise handling failed for miniserver date - " + error);
        });
        return promise;
    };
    /**
     * Trigger Download Miniserver UTC Time Offset from Miniserver.
     */


    var downloadMiniserverUtcTimeOffset = function downloadMiniserverUtcTimeOffset() {
        var promise = activeMsComp.send(Commands.TIMEZONE);
        promise.then(function (res) {
            activeMsComp.receivedMsUtc(res.LL.value);
            initInfoObj.timezoneOffset = res.LL.value;
        }, function (error) {
            console.error("Promise handling failed for miniserver utc offset - " + error);
        });
        return promise;
    };

    return MSInformationExt;
});
