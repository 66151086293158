'use strict';

define("IntercomControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var PARSE_FORMAT = 'YYYYMMDDHHmmss';
    return class IntercomControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);

            if (SipAdapter.hasSip && this.control.hasAudioInfo()) {
                // register also at SIP Plugin!
                SipAdapter.registerForSIPCallChanges(this, this.control.details.audioInfo.user, this.control.details.audioInfo.host);
            }

            this.name = "IntercomStateContainer (" + this.control.name + ")";
        }

        /**
         * Intercoms register for SIP Call Changes if a valid audio info is present. So a "recheck" is needed as soon as the
         * secure details have been loaded and the audio info is present.
         */
        updateWithSecuredDetails() {
            // base call is important - as in the base class the control is being merged with the secured details.
            super.updateWithSecuredDetails(...arguments);

            if (SipAdapter.hasSip && this.control.hasAudioInfo()) {
                // register also at SIP Plugin!
                SipAdapter.registerForSIPCallChanges(this, this.control.details.audioInfo.user, this.control.details.audioInfo.host);
            }
        }

        getUUIDsToRegister() {
            var uuids = super.getUUIDsToRegister(); // take state uuid from subControls - we don't use seperate stateContainers here!

            if (this.control.subControls) {
                for (var uuidAction in this.control.subControls) {
                    if (this.control.subControls.hasOwnProperty(uuidAction)) {
                        uuids.push(this.control.subControls[uuidAction].states.active);
                    }
                }
            }

            return uuids;
        }

        allStatesReceived(allStatesArrived) {
            super.allStatesReceived(...arguments);

            if (allStatesArrived && typeof this.states.version === "string") {
                // We update the features for this Intercom
                // This adds the feature property to the control, from there we can check it easily
                this.control.checkFeatures(this.states.version);
            }
        }

        prepareStates(newVals) {
            Debug.Control.Intercom.StateContainer && console.log(this.name, "prepareStates: " + JSON.stringify(newVals));
            var i; // cam images
            //Reverse because we want the latest on top of the list

            var tsTxt = newVals[this.control.states.lastBellEvents].text;
            var ts = tsTxt ? tsTxt.split("|").reverse() : [];
            var dateFormat = LxDate.getDateTimeFormat(DateType.Date, false);
            var dateTextFormat = LxDate.getDateFormat(DateType.WeekdayAndDateText);
            var regDateTimeFormat = LxDate.getDateTimeFormat(DateType.ShortWeekdayAndDate, false);
            var timeFormat = LxDate.getTimeFormat(false);

            if (ts.length > 0 && ts[0] !== "") {
                this.states.hasLastActivities = true;
                this.states.lastActivitiesTimestamps = [];

                for (i = 0; i < ts.length; i++) {
                    this.states.lastActivitiesTimestamps.push({
                        timestamp: ts[i],
                        timestampString: moment(ts[i], PARSE_FORMAT).format(dateFormat)
                    });
                } // create date/time-map


                this.states.lastActivitiesDateMap = {};

                for (i = 0; i < ts.length; i++) {
                    var momentJsObj = moment(ts[i], PARSE_FORMAT);
                    var date = momentJsObj.format(dateTextFormat);
                    var time = momentJsObj.format(timeFormat);

                    if (!this.states.lastActivitiesDateMap.hasOwnProperty(date)) {
                        this.states.lastActivitiesDateMap[date] = [];
                    }

                    this.states.lastActivitiesDateMap[date].push({
                        timestamp: ts[i],
                        timestampString: time,
                        dateAndTimeString: momentJsObj.format(regDateTimeFormat)
                    });
                }

                this.states.lastActivityTimestamp = this.states.lastActivitiesTimestamps[this.states.lastActivitiesTimestamps.length - 1];
            } else {
                this.states.hasLastActivities = false;
                this.states.lastActivitiesTimestamps = null;
                this.states.lastActivityTimestamp = null;
            }

            this.states.isBellRinging = newVals[this.control.states.bell] === 1;
            this.states.lastBellTimestamp = newVals[this.control.states.lastBellTimestamp].text;

            if (this.control.subControls) {
                this.states.outputStates = {};
                var subControl;

                for (var uuid in this.control.subControls) {
                    if (this.control.subControls.hasOwnProperty(uuid)) {
                        subControl = this.control.subControls[uuid];
                        this.states.outputStates[subControl.uuidAction] = newVals[subControl.states.active] === 1;
                    }
                }
            }

            if (Feature.INTERCOM_VERSION && this.control.isIntercom()) {
                var versionInfo = newVals[this.control.states.version];

                if (typeof versionInfo === "object") {
                    this.states.version = versionInfo.text;
                } else {
                    Debug.Control.Intercom.StateContainer && console.warn(this.name, "prepareStates: The version state does not contain the text property: " + JSON.stringify(versionInfo));
                }
            } // handle if bell is ringing -> show alert


            if (this.isFirstUpdate() && this.states.isBellRinging) {
                SandboxComponent.isInAppNotificationSettingTurnedOn(this.control.type).done(function () {
                    NavigationComp.showControlAlert(this.control.uuidAction);
                }.bind(this));
            }
        }

        getStateText() {
            if (this.states.isBellRinging) {
                return _("controls.intercom.notification.ringing");
            } else if (this.states.isCalling) {
                return _("controls.intercom.voice.establishing-connection");
            } else if (this.states.isTalking) {
                return _("controls.intercom.voice.connection-active");
            } else if (this.states.isWorking) {
                // if not calling or talking - but working, the connection must be closing.
                return _("controls.intercom.voice.closing-connection");
            } else {
                return NBR_SPACE; // return this to not hide the subtitle (we don't want the content to jump)
            }
        }

        getStateColor() {
            if (this.states.isBellRinging) {
                return window.Styles.colors.stateActive;
            }
        }

        destroy() {
            if (SipAdapter.hasSip && this.control.hasAudioInfo()) {
                // unregister SIP Plugin!
                SipAdapter.unregisterForSIPCallChanges(this);
            }

            this._stopRinging();

            super.destroy();
        }

        receivedSIPCallStates(states) {
            Debug.Control.Intercom.StateContainer && console.log(this.name, "receivedSIPCallStates: " + JSON.stringify(states));
            this.states.isCalling = states.calling;
            this.states.isTalking = states.talking;
            this.states.isWorking = states.isWorking; // Only assign the error state if there haven't been an error before
            // This prevents us from showing multiple error popups

            if (states.hasOwnProperty('error') && !this.states.hasError) {
                if (this.control.hasSecuredSIPModule(this.control)) {
                    // LxIntercom BadRequest is equal to Unauthorized
                    if (states.error.code === SipAdapter.CallCode.BadRequest) {
                        Debug.Control.Intercom.StateContainer && console.warn(this.name, "receivedSIPCallStates: Got BadRequest, changing to Unauthorized because it has a secured SIP Module!");
                        states.error.code = SipAdapter.CallCode.Unauthorized;
                    }
                }

                this.states.error = states.error;
                this.states.hasError = true;
            } else if (this.states.hasOwnProperty('error')) {
                // Delete the error if there has been an error before and the error property is still in the states object
                // This prevents us from showing multiple error popups
                delete this.states.error;
                this.states.hasError = false;
            }

            if (states.hasOwnProperty('codec')) {
                this.states.codecKnown = true;
                this.states.codec = states.codec;
            } else {
                this.states.codecKnown = false;
                delete this.states.codec;
            }

            this.notifyListener();
        }

        _stopRinging() {
            if (window.Media && this.ringtoneMedia) {
                this.ringtoneMedia.stop();
                this.ringtoneMedia.release();
                this.ringtoneMedia = null;
                clearInterval(this.ringInterval);
                this.ringInterval = null;
            } else if (this.audioElement) {
                this.audioElement.pause();
                this.audioElement = null;
                clearInterval(this.ringInterval);
                this.ringInterval = null;
            }
        }

    };
});
