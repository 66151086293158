'use strict';

define("WallboxManagerControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class WallboxManagerControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        destroy() {
            super.destroy(...arguments);
        }

        prepareStates(newVals) {

            this.states.priceEco = newVals[this.control.states.priceEco];
            this.states.pricePrio = newVals[this.control.states.pricePrio];
            this.states.ecoPower = newVals[this.control.states.Peco];
            this.states.maxPower = newVals[this.control.states.Pmax];
            this.states.currentPower = newVals[this.control.states.Cp];
            this.states.assignedPower = newVals[this.control.states.Ap];
            this.states.limitedBy = newVals[this.control.states.limitedBy];

            // put actual0...n onto the states object.
            Object.keys(this.control.states)
                .filter(key => {
                    return key.startsWith("actual") || key.startsWith("limitedBy");
                })
                .forEach(stateKey => {
                this.states[stateKey] = newVals[this.control.states[stateKey]];
            });
        }

        getStateColor() {
            if (this.states.currentPower > 0 || this.states.limitedBy > 0) {
                return window.Styles.colors.orange;
            } else {
                return;
            }
        }

        getStateTextColor() {
            return this.getStateColor();
        }

        getStateText() {
            let current = lxUnitConverter.convertSplitAndApply(this.control.powerFormat, this.states.assignedPower, true),
                max = lxUnitConverter.convertSplitAndApply(this.control.powerFormat, this.states.maxPower, true);

            if (current.succTxt === max.succTxt) {
                return current.valueTxt + " / " + max.valueTxt + " " + max.succTxt;
            } else {
                return current.valueTxt + " " + current.succTxt + " / " + max.valueTxt + " " + max.succTxt;
            }
        }
    };
});
