'use strict';
/**
 * woessto, 2021.05.07 - Reworked version, reduce downloading effort.
 *
 * Manages the storage of user settings that are shared across devices using the Miniservers user settings storage.
 * Behaviour:
 *      * receives the currentUser-UUID either from AppInitInfo (new Miniservers) or via Structure-Ready
 *      * receives the current user-settings-timestamp initially via AppInitInfo and via Status-Update while connected.
 *      * keeps a locally stored version of the settings to reduce downloading effort
 */

PersistenceComp.factory("SharedUserSettingsExt", function () {
    var UNKNOWN_TS = -1337;

    var persComp = null,
        settings = null,
        _previousSettings = {},
        _currentMSSerialNo = null,
        _currentUserUuid = null,
        currMsTs = UNKNOWN_TS,
        currLocalTs = UNKNOWN_TS,
        downloadPrms = null,
        delayedDownloadTimeout = null,
        persistencePrms = null,
        persistenceLoaded = false,
        settingsReadyDef = null,
        _autoClearTimeInMs = 2 * 1000,
        _autoSyncTimeout = null,
        _registrations = {};

    /**
     * Shared Settings between multiple clients based on the user
     * @param comp
     * @constructor
     */


    function SharedUserSettingsExt(comp) {
        persComp = comp;
        this.name = "SharedUserSettingsExt";
        CompChannel.on(CCEvent.StartMSSession, this.onSessionStarted.bind(this));
        CompChannel.on(CCEvent.AppInitInfoReady, this.onInitInfoReady.bind(this));
        CompChannel.on(CCEvent.StateContainersCreated, this.onStateContainersCreated.bind(this));
        CompChannel.on(CCEvent.StructureReady, this.onStructureReady.bind(this));
        CompChannel.on(CCEvent.ConnClosed, this.onConnectionClosed.bind(this));
        CompChannel.on(CCEvent.StopMSSession, this.onSessionStopped.bind(this));
    } // --------------------------------------------------------------------------------
    //     Listener
    // --------------------------------------------------------------------------------


    SharedUserSettingsExt.prototype.onSessionStarted = function onSessionStarted(ev, ms) {
        Debug.SharedUserSettings && console.log(this.name, "onSessionStarted - " + JSON.stringify(ms));
        settingsReadyDef = Q.defer();
        _currentMSSerialNo = ms.serialNo;

        this._handleVerifyData();
    };

    SharedUserSettingsExt.prototype.onInitInfoReady = function onInitInfoReady(event, initInfo) {
        Debug.SharedUserSettings && console.log(this.name, "onInitInfoReady " + JSON.stringify(initInfo));

        if (initInfo.hasOwnProperty("currentUser") && initInfo.currentUser.uuid) {
            _currentUserUuid = initInfo.currentUser.uuid;
        }

        if (initInfo.hasOwnProperty("dateUserSettings")) {
            currMsTs = initInfo.dateUserSettings;
        }

        if (!_currentMSSerialNo) {
            _currentMSSerialNo = ActiveMSComponent.getActiveMiniserver().serialNo;
        }

        this._handleVerifyData();
    };

    SharedUserSettingsExt.prototype.onStateContainersCreated = function onStateContainersCreated() {
        Debug.SharedUserSettings && console.log(this.name, "onStateContainersCreated - register");
        SandboxComponent.registerForStateChangesForUUID(GLOBAL_UUID, this, this.onReceivedStates.bind(this));
    };

    SharedUserSettingsExt.prototype.onStructureReady = function onStructureReady() {
        Debug.SharedUserSettings && console.log(this.name, "onStructureReady");

        if (!_currentUserUuid) {
            _currentUserUuid = ActiveMSComponent.getCurrentUser().uuid;
        }

        this._handleVerifyData();
    };

    SharedUserSettingsExt.prototype.onConnectionClosed = function onConnectionClosed() {
        Debug.SharedUserSettings && console.log(this.name, "onConnectionClosed");
        currMsTs = UNKNOWN_TS;
    };

    SharedUserSettingsExt.prototype.onSessionStopped = function onSessionStopped() {
        Debug.SharedUserSettings && console.log(this.name, "onSessionStopped");
        SandboxComponent.unregisterForStateChangesForUUID(GLOBAL_UUID, this);
        settings = null;
        _previousSettings = {};
        _currentMSSerialNo = null;
        _currentUserUuid = null;
        currMsTs = UNKNOWN_TS;
        currLocalTs = UNKNOWN_TS;
        downloadPrms = null;
        persistencePrms = null;
        persistenceLoaded = false;
        settingsReadyDef = null;
        delayedDownloadTimeout && clearTimeout(delayedDownloadTimeout);
        delayedDownloadTimeout = null;
    };

    SharedUserSettingsExt.prototype.onReceivedStates = function onReceivedStates(states) {
        var interestedTs,
            currentUser = ActiveMSComponent.getCurrentUser();

        if (states.hasOwnProperty("userSettings")) {
            interestedTs = states.userSettings[currentUser.uuid];

            if (interestedTs && interestedTs !== currMsTs) {
                Debug.SharedUserSettings && console.log(this.name, "onReceivedStates - ts changed");
                currMsTs = interestedTs;

                this._handleVerifyData(true);
            }
        }
    }; // --------------------------------------------------------------------------------
    //     Public Methods
    // --------------------------------------------------------------------------------


    SharedUserSettingsExt.prototype.getReadyPromise = function getReadyPromise() {
        Debug.SharedUserSettings && console.log(this.name, "getReadyPromise");
        return settingsReadyDef.promise.then(function () {
            Debug.SharedUserSettings && console.log(this.name, "getReadyPromise - resolved");
        }.bind(this));
    };
    /**
     * Synchronizes the settings (Uploads them to the Miniserver)
     * @return {*}
     */


    SharedUserSettingsExt.prototype.synchronize = function synchronize() {
        Debug.SharedUserSettings && console.log(this.name, "synchronize");
        _autoSyncTimeout && clearTimeout(_autoSyncTimeout);

        if (Feature.SHARED_USER_SETTINGS) {
            return CommunicationComponent.sendViaHTTP(Commands.SHARED_USER_SETTINGS.SAVE, EncryptionType.NONE, true, HTTP_METHODS.POST, settings.ts + "/" + JSON.stringify(settings));
        } else {
            return Q(true);
        }
    };
    /**
     * Set a specific key and value to the shared settings
     * @param key
     * @param value
     */


    SharedUserSettingsExt.prototype.set = function set(key, value) {
        Debug.SharedUserSettings && console.log(this.name, "set " + key + " - " + JSON.stringify(value));

        if (typeof settings[key] !== "object" && settings[key] === value) {
            Debug.SharedUserSettings && console.log(this.name, "Won't set identical Value!");
        } else {
            settings[key] = cloneObject(value);
            settings.ts = new LxDate().getSecondsSince2009();
            currLocalTs = settings.ts;

            this._notifyListeners();

            _autoSyncTimeout && clearTimeout(_autoSyncTimeout);
            _autoSyncTimeout = setTimeout(this.synchronize.bind(this), _autoClearTimeInMs);
            PersistenceComponent.saveFile(this._getSettingsFileName(), settings, DataType.OBJECT);
        }
    };
    /**
     * (Async!) Gets a specific value with a Key
     * This function will await any currently ongoing fetching promises
     * @param key
     * @param avoidWaiting - skips the fetchPrm
     * @return {*}
     */


    SharedUserSettingsExt.prototype.get = function get(key, avoidWaiting) {
        Debug.SharedUserSettings && console.log(this.name, "get " + key);

        if (avoidWaiting) {
            return (persistencePrms || Q.resolve()).then(function () {
                return cloneObject(settings[key]);
            });
        }

        return settingsReadyDef.promise.then(function () {
            return cloneObject(settings[key]);
        });
    };
    /**
     * Register for changes of a specific key
     * @param key
     * @param listener
     * @param [preventInitial] Prevents the initial call
     */


    SharedUserSettingsExt.prototype.registerForKeyChange = function registerForKeyChange(key, listener, preventInitial) {
        Debug.SharedUserSettings && console.log(this.name, "registerForKeyChange: " + key);
        this.get(key).then(function (value) {
            value !== undefined && value !== null && !preventInitial && listener(key, value);
        }.bind(this)).finally(function () {
            if (!_registrations.hasOwnProperty(key)) {
                _registrations[key] = [];
            }

            _registrations[key].push(listener);
        }.bind(this));
    };
    /**
     * Simple unregister
     * @param key
     * @param listener
     */


    SharedUserSettingsExt.prototype.unregisterFromKeyChange = function unregisterFromKeyChange(key, listener) {
        Debug.SharedUserSettings && console.log(this.name, "unregisterFromKeyChange: " + key);

        if (_registrations && _registrations.hasOwnProperty(key)) {
            var regIdx = _registrations[key].indexOf(listener);

            if (regIdx !== -1) {
                _registrations[key].splice(regIdx, 1);
            }
        }
    }; // --------------------------------------------------------------------------------
    //     Private Methods
    // --------------------------------------------------------------------------------

    /**
     * Central method that is called from the various methods called by outside events. It decides what to do.
     * @param delayedLoad
     * @private
     */


    SharedUserSettingsExt.prototype._handleVerifyData = function _handleVerifyData(delayedLoad) {
        Debug.SharedUserSettings && console.log(this.name, "_handleVerifyData");
        var downloadFromMs = false,
            dataReady = false;

        if (currMsTs !== UNKNOWN_TS && currLocalTs !== UNKNOWN_TS) {
            if (currMsTs <= currLocalTs) {
                Debug.SharedUserSettings && console.log(this.name, "    local data up to date");
                dataReady = true;
            } else {
                Debug.SharedUserSettings && console.log(this.name, "    local data outdated, re-download");
                downloadFromMs = true;
            }
        }

        if (dataReady) {
            settingsReadyDef.resolve();

            this._notifyListeners();
        } else if (downloadFromMs) {
            if (delayedLoad) {
                this._loadFromMiniserverDelayed();
            } else {
                this._loadFromMiniserver();
            }
        } else if (!persistenceLoaded) {
            this._loadFromPersistence();
        }
    };

    SharedUserSettingsExt.prototype._loadFromMiniserverDelayed = function _loadFromMiniserverDelayed() {
        Debug.SharedUserSettings && console.log(this.name, "_loadFromMiniserverDelayed");
        delayedDownloadTimeout && clearTimeout(delayedDownloadTimeout);
        delayedDownloadTimeout = setTimeout(function () {
            delayedDownloadTimeout = null;

            this._loadFromMiniserver();
        }.bind(this), getRandomIntInclusive(0, 5) * 1000);
    };

    SharedUserSettingsExt.prototype._loadFromMiniserver = function _loadFromMiniserver() {
        Debug.SharedUserSettings && console.log(this.name, "_loadFromMiniserver");

        if (Feature.SHARED_USER_SETTINGS && !downloadPrms) {
            downloadPrms = CommunicationComponent.sendViaHTTP(Commands.SHARED_USER_SETTINGS.FETCH).then(function (res) {
                if (typeof res === "string") {
                    try {
                        res = JSON.parse(res);
                    } catch (e) {
                        console.warn(e);
                        res = {};
                    }
                }

                this._handleDownloadedSettings(res);
            }.bind(this), function () {
                this._handleDownloadedSettings({});
            }.bind(this));
        } else if (downloadPrms) {
            Debug.SharedUserSettings && console.log(this.name, "    .. already downloading"); // already downloading.
        } else {
            this._handleDownloadedSettings({});
        }
    };
    /**
     * Notifies all listeners about their subscribed key change
     */


    SharedUserSettingsExt.prototype._notifyListeners = function _notifyListeners() {
        Debug.SharedUserSettings && console.log(this.name, "_notifyListeners");
        Object.keys(settings).forEach(function (key) {
            if (key !== "ts" && _registrations.hasOwnProperty(key)) {
                if (JSON.stringify(settings[key]) !== JSON.stringify(_previousSettings[key])) {
                    _registrations[key].forEach(function (clb) {
                        try {
                            clb(key, settings[key]);
                        } catch (e) {
                            console.warn(e); // Don't crash because of faulty callbacks!
                        }
                    }.bind(this));
                }
            }
        }.bind(this));
        _previousSettings = cloneObject(settings);
    };
    /**
     * Simply returns a generic filename for our own cached shared settings in the App
     * @return {string}
     */


    SharedUserSettingsExt.prototype._getSettingsFileName = function _getSettingsFileName() {
        return _currentMSSerialNo + "_" + _currentUserUuid + "_settings.json";
    };

    SharedUserSettingsExt.prototype._loadFromPersistence = function _loadFromPersistence() {
        if (persistenceLoaded || // already loaded
            !_currentUserUuid || !_currentMSSerialNo || // data missing, cannot load
            persistencePrms) {
            // already loading
            Debug.SharedUserSettings && console.log(this.name, "_loadFromPersistence - " + (persistenceLoaded || persistencePrms ? "already loaded/loading" : "rq data"));
            return;
        }

        Debug.SharedUserSettings && console.log(this.name, "_loadFromPersistence");
        persistencePrms = PersistenceComponent.loadFile(this._getSettingsFileName()).then(function (file) {
            if (typeof file === "string") {
                try {
                    file = JSON.parse(file);
                } catch (e) {
                    console.warn(e);
                    file = {};
                }
            }

            this._handlePersistenceSettings(file);
        }.bind(this), function () {
            this._handlePersistenceSettings({});
        }.bind(this));
    };

    SharedUserSettingsExt.prototype._handlePersistenceSettings = function _handlePersistenceSettings(loadedSettings) {
        Debug.SharedUserSettings && console.log(this.name, "_handlePersistenceSettings");
        persistencePrms = null;
        currLocalTs = loadedSettings.hasOwnProperty("ts") ? loadedSettings.ts : 0;
        settings = loadedSettings;
        persistenceLoaded = true;

        if (Feature.SHARED_USER_SETTINGS) {
            this._handleVerifyData();
        } else {
            // _handleVerifyData checks the timestamp of the miniserver, if there is no timestamp because the miniserver
            // hasn't this feature it would never resolve.
            // Issue "BG-I13226" MusicServerZone showed no data at all
            settingsReadyDef.resolve();
        }
    };

    SharedUserSettingsExt.prototype._handleDownloadedSettings = function _handleDownloadedSettings(loadedSettings) {
        Debug.SharedUserSettings && console.log(this.name, "_handleDownloadedSettings");
        downloadPrms = null;
        currMsTs = loadedSettings.hasOwnProperty("ts") ? loadedSettings.ts : 0;
        currLocalTs = currMsTs;
        settings = loadedSettings;
        PersistenceComponent.saveFile(this._getSettingsFileName(), settings, DataType.OBJECT);

        this._handleVerifyData();
    };

    return SharedUserSettingsExt;
});
