


export default class AutoLock {

    constructor() {
        this._disabled = false;
        const pf = PlatformComponent.getPlatformInfoObj().platform;
        this._hasAutoLock = pf === PlatformType.IOS || pf === PlatformType.Android;
        this._lockListeners = {};
    }

    get hasAutoLock() {
        return this._hasAutoLock;
    }

    get disabled() {
        if (this.forceOff) {
            return true;
        }
        return this._disabled;
    }

    set disabled(newVal) {
        console.log("AutoLock", "setDisabled=" + !!newVal);
        this._disabled = !!newVal;

        if (!this.isForcedOff) {
            this._internalDisable(this._disabled);
        }
    }

    set forceOff(newVal) {
        console.log("AutoLock", "setForceOff=" + !!newVal);
        this._forceOff = !!newVal;
        if (!this._forceOff) {
            // use the value that has been set by ecoScreenExt.
            this._internalDisable(this.disabled);
        } else {
            // ignore disabled-prop, force off!
            this._internalDisable(true);
        }
    }
    get isForcedOff() {
        return this._forceOff;
    }

    static forceOff(shouldKeepDisabled = false) {
        return AutoLock.instance().forceOff = shouldKeepDisabled;
    }

    static instance() {
        if (!window.autoLock) {
            window.autoLock = new AutoLock();
        }
        return window.autoLock;
    }

    static isDisabled() {
        return AutoLock.instance().disabled;
    }

    static disable() {
        return AutoLock.instance().disabled = true;
    }

    static enable() {
        return AutoLock.instance().disabled = false;
    }

    static setDisabled(disabled) {
        return AutoLock.instance().disabled = disabled;
    }

    static register(cb) {
        return AutoLock.instance().register(cb);
    }

    _internalDisable(disableAutoLock) {
        console.log("AutoLock", "_internalDisable: disabled=" + !!disableAutoLock );
        this.hasAutoLock && screen.setKeepScreenOn(null, null, disableAutoLock);
        Object.values(this._lockListeners).forEach(cb => cb(disableAutoLock));
    }

    register(cb) {
        let rndId;
        do {
            rndId = getRandomIntInclusive(0, 10000) + "";
        } while (this._lockListeners.hasOwnProperty(rndId));
        this._lockListeners[rndId] = cb;

        return () => {
            delete this._lockListeners[rndId];
        }
    }
}