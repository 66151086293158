'use strict';

define("TextStateControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class TextStateControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            let event = newVals[this.control.states.textAndIcon];
            let iconAndColor = newVals[this.control.states.iconAndColor];
            let iconInfoObj;
            if (iconAndColor && iconAndColor.text) {
                // should be {"icon": "path-to-icon", "color": "iconColor"}
                try {
                    iconInfoObj = JSON.parse(iconAndColor.text);
                    if (typeof (iconInfoObj.color) === "number") { // may have been transferred as int
                        iconInfoObj.color = hexIntToColorString(iconInfoObj.color);
                    }
                } catch (ex) {
                    // nothing to do.
                }
            }

            if (iconInfoObj) {
                this.states.hasIcon = true;
                this.states.iconSrc = iconInfoObj.icon;
                this.states.iconColor = iconInfoObj.color;

            } else if (typeof event.uuidIcon === "string" && event.uuidIcon !== "00000000-0000-0000-0000000000000000") {
                this.states.hasIcon = true;
                this.states.iconSrc = event.uuidIcon;
                this.states.iconColor = null;

            } else {
                this.states.hasIcon = false;
                this.states.iconSrc = null;
                this.states.iconColor = null;

            }

            if (event.text && event.text !== "") {
                this.states.hasText = true;
                this.states.text = event.text;
            } else {
                this.states.hasText = false;
                this.states.text = null;
            }
        }

        getStateText() {
            if (this.states.hasText) {
                return this.states.text;
            }
        }

        getStateTextForContent() {
            return null;
        }

        getStateIcon() {
            if (this.states.hasIcon) {
                return this.states.iconSrc;
            }
        }

        getStateIconColor() {
            if (this.states.iconColor) {
                return this.states.iconColor;
            }
        }

        getStateInfo() {
            if (this.states.hasText) {
                return {
                    message: this.getStateText()
                };
            }
        }

    };
});
