'use strict';

define("GlobalStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class GlobalStateContainer extends ControlStateContainer {
        constructor(globalStates) {
            // "copy" these states to only use implemented ones!
            // otherwise someone could add a state, we don't need it, and the initial update is missed -> no sunrise, sunset etc.!
            var control = {
                uuidAction: 'global',
                type: 'global',
                states: {
                    sunrise: globalStates.sunrise,
                    sunset: globalStates.sunset
                }
            };

            if (Feature.TIME_UPDATES) {
                control.states.miniserverTime = globalStates.miniserverTime;
            }

            if (Feature.GLOBAL_HAS_INTERNET_STATE) {
                control.states.hasInternet = globalStates.hasInternet;
            }

            if (Feature.LEARN_DEVICE_OVER_APP) {
                control.states.liveSearch = globalStates.liveSearch;
            }

            if (Feature.GLOBAL_CLOUD_STATES) {
                control.states.cloudservice = globalStates.cloudservice;
            }

            if (globalStates.userSettings) {
                control.states.userSettings = globalStates.userSettings;
            }

            if (Feature.TRUST_STRUCTURE_REQUEST) {
                control.states.trustVersion = globalStates.trustVersion;
            }

            if (globalStates.propsVersion) {
                control.states.propsVersion = globalStates.propsVersion;
            }

            super(control);
        }

        prepareStates(newVals) {
            var sunriseMin = parseInt(newVals[this.control.states.sunrise]) || 0;
            var sunsetMin = parseInt(newVals[this.control.states.sunset]) || 0;
            this.states.sunrise = LxDate.fromMinutesSinceMidnight(sunriseMin);
            this.states.sunset = LxDate.fromMinutesSinceMidnight(sunsetMin);

            if (Feature.TIME_UPDATES) {
                this.states.miniserverTime = newVals[this.control.states.miniserverTime].text;
            }

            if (Feature.GLOBAL_HAS_INTERNET_STATE) {
                this.states.hasInternet = !!newVals[this.control.states.hasInternet];
            } else {
                this.states.hasInternet = true; // Assume the Miniserver has an active internet connection if the feature doesn't exist yet
            }

            if (Feature.LEARN_DEVICE_OVER_APP) {
                if (!!newVals[this.control.states.liveSearch].text) {
                    this.states.liveSearch = JSON.parse(newVals[this.control.states.liveSearch].text);
                } else {
                    this.states.liveSearch = {};
                }
            }

            if (Feature.GLOBAL_CLOUD_STATES) {
                // debug data
                // '{"cloudstatus":1,"services":[{"name":"caller","status":0},{"name":"weather","status":0},{"name":"registration","status":0}]}'
                this.states.cloudservice = JSON.parse(newVals[this.control.states.cloudservice].text);
            }

            if (this.control.states.userSettings) {
                this.states.userSettings = JSON.parse(newVals[this.control.states.userSettings].text);
            }

            if (Feature.TRUST_STRUCTURE_REQUEST) {
                this.states.trustVersion = newVals[this.control.states.trustVersion];
            } else {
                this.states.trustVersion = -1;
            }

            // app pairing props
            this.states.propsVersion = newVals[this.control.states.propsVersion];
        }

        stateTimeoutStarted() {
            // unset the ms date time property, when the connection is reestablished reusing this state would cause
            // a "considerable time diff" message in the time ext, which is listening for these states.
            this.states.miniserverTime = null;
        }

    };
});
