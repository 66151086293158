'use strict';

define("JalousieControlStateContainer", ["ControlStateContainer", "JalousieControlEnums"], function (ControlStateContainer, CtrlEnums) {
    return class JalousieControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        /**
         * This method is called every time the controls structure changes (ExpertMode)
         */
        structureDidChange() {
            // The structure did change, update the icon to reflect the potential changed animation type
            this._prepareLiveIcon();

            // also required to inform listeners, so that they update their UI
            this.version++;
            this.notifyListener();
        }

        prepareStates(newVals) {
            this.states.direction = newVals[this.control.states.up] ? Direction.UP : newVals[this.control.states.down] ? Direction.DOWN : Direction.NOT_MOVING;
            this.states.isMoving = this.states.direction === Direction.UP || this.states.direction === Direction.DOWN;
            this.states.positionState = newVals[this.control.states.position];
            this.states.shadeState = newVals[this.control.states.shadePosition];
            this.states.safetyActive = newVals[this.control.states.safetyActive];
            this.states.autoAllowed = newVals[this.control.states.autoAllowed];
            this.states.controlUUID = this.control.uuidAction; // info texts are transporting the cause of e.g. a safety shutdown.

            this.states.locked = newVals[this.control.states.locked];

            if (Feature.JALOUSIE_INFO_TEXT && this.control.states.infoText) {
                this.states.infoText = nullEmptyString(newVals[this.control.states.infoText].text);
            } else {
                this.states.infoText = null;
            }

            if (this.control.details.isAutomatic) {
                this.states.autoActive = Boolean(newVals[this.control.states.autoActive]);

                if (this.states.locked) {
                    this.states.jalousieMode = CtrlEnums.JalousieMode.LOCKED;
                    this.states.autoAllowed = false;
                } else if (this.states.safetyActive) {
                    this.states.jalousieMode = CtrlEnums.JalousieMode.SAFETY;
                    this.states.autoAllowed = false;
                } else if (!this.states.autoAllowed) {
                    this.states.jalousieMode = CtrlEnums.JalousieMode.AUTO_NOT_ALLOWED;
                    this.states.autoAllowed = false;
                } else if (!this.states.autoActive) {
                    this.states.jalousieMode = CtrlEnums.JalousieMode.AUTO_INACTIVE;
                    this.states.autoAllowed = true;
                } else if (this.states.autoActive) {
                    this.states.jalousieMode = CtrlEnums.JalousieMode.AUTO_ACTIVE;
                    this.states.autoAllowed = true;
                }
            }
            /**
             * until now we use the details property, maybe we make it dynamically one day
             * so everything is implemented to work properly when simply changing this state!
             */


            this.states.serviceModeAvailable = this._checkAvailableModes(this.control.details.availableModes);

            if (this.states.serviceModeAvailable) {
                this.states.currentMode = newVals[this.control.states.mode];
                this.states.currentStep = newVals[this.control.states.learningStep]; // save this.__hasEndposition, so we don't go from true to false (is while in learning mode)

                this.__hasEndposition = this.__hasEndposition || newVals[this.control.states.hasEndposition] === 1;
                this.states.configurationNeeded = !this.__hasEndposition;

                if (Debug.Control.JalousieConfig) {
                    console.warn("------------------------------" + this.control.name + "-----------------------------");
                    console.warn("- currentMode:           ------> " + this.states.currentMode);
                    console.warn("- currentStep:           ------> " + this.states.currentStep);
                    console.warn("- positionState:         ------> " + this.states.positionState);
                    console.warn("- hasEndPosition:        ------> " + this.__hasEndposition);
                    console.warn("- configurationNeeded:   ------> " + this.states.configurationNeeded);
                    console.warn("------------------------------------------------------------------------------------------------");
                }
            } else {
                // set to false explicitly! (toggle won't work with undefined)
                this.states.configurationNeeded = false;
            } // Info on shading device online state, avilable since 11.3


            if (this.control.states.deviceState) {
                this.states.deviceState = newVals[this.control.states.deviceState];
                this.states.deviceOffline = this.states.deviceState === CtrlEnums.DeviceState.OFFLINE;
            } else {
                this.states.deviceState = CtrlEnums.DeviceState.UNKNOWN;
                this.states.deviceOffline = false;
            } // End position adjustment feature.


            if (this.control.states.adjustingEndPos) {
                this.states.adjustingEndPosBits = newVals[this.control.states.adjustingEndPos];
            } else {
                this.states.adjustingEndPosBits = 0;
            }

            this.states.isAdjustingEndPos = hasBit(this.states.adjustingEndPosBits, CtrlEnums.EndPosAdjustBit.ACTIVE);
        }

        getStateText(short) {
            if (this.states.deviceOffline) {
                return _("device-offline");
            }

            if (this.states.configurationNeeded) {
                return _("controls.jalousie.config.not-configured");
            }

            var mode = this.states.jalousieMode,
                position = Math.ceil(this.states.positionState * 100),
                // positionState is between 0 and 1
                isOpened = position === 0,
                isClosed = position === 100,
                shadeState = this.states.shadeState,
                stateString; // only represent locked & safety active if the text is used for the cell/card, inside the content, locked &
            // safety active texts are represented using getStateTextForContent and would be shown twice otherwise..

            if (mode === CtrlEnums.JalousieMode.LOCKED && short) {
                stateString = this._getLockedText();
            } else if (mode === CtrlEnums.JalousieMode.SAFETY && short && this.states.safetyActive !== CtrlEnums.SAFETY_STATES.DOOR_OPENED) {
                stateString = _('controls.jalousie.safety-shutdown');
            } else if (this.control.isCurtain()) {
                if (isOpened) {
                    stateString = short ? _('opened') : _('controls.jalousie.curtain.opened');
                } else if (isClosed) {
                    stateString = short ? _('closed') : _('controls.jalousie.curtain.closed');
                } else {
                    stateString = _(short ? 'controls.jalousie.state-string.short' : 'controls.jalousie.state-string.curtain', {
                        closePercentage: position
                    });
                }
            } else if (this.control.isAwning()) {
                if (isOpened) {
                    stateString = short ? _('controls.jalousie.awning.fully-in.short') : _('controls.jalousie.awning.fully-in');
                } else if (isClosed) {
                    stateString = short ? _('controls.jalousie.awning.fully-out.short') : _('controls.jalousie.awning.fully-out');
                } else {
                    stateString = _(short ? 'controls.jalousie.state-string.awning.short' : 'controls.jalousie.state-string.awning', {
                        closePercentage: position
                    });
                }
            } else if (this.control.details.animation === CtrlEnums.AnimationType.SHUTTERS) {
                if (isOpened) {
                    stateString = short ? _('opened') : _('controls.jalousie.shutters.opened');
                } else if (isClosed) {
                    stateString = short ? _('closed') : _('controls.jalousie.shutters.closed');
                } else {
                    stateString = _(short ? 'controls.jalousie.state-string.short' : 'controls.jalousie.state-string.shutters', {
                        closePercentage: position
                    });
                }
            } else {
                if (isOpened) {
                    stateString = short ? _('opened') : _('controls.jalousie.jalousie.opened');
                } else if (isClosed) {
                    var slatsState = this._getSlatsStateString(shadeState); // Here we need to make the first letter an uppercase letter


                    slatsState = slatsState.replaceAt(0, slatsState.charAt(0).toUpperCase());
                    stateString = short ? _('closed') : _('controls.jalousie.jalousie.closed') + " " + slatsState;
                } else {
                    stateString = _(short ? _('closed') + " (" + lxFormat("%.0f%", position) + ")" : 'controls.jalousie.state-string.jalousie', {
                        closePercentage: position,
                        slatsState: this._getSlatsStateString(shadeState)
                    });
                }
            }

            return stateString;
        }

        getStateIcon() {
            if (this.states.configurationNeeded) {
                return Icon.General.CAUTION_NO_CIRCLE;
            } // We need to react to changes of the animation property by the ExpertMode
            // So we prepare the lives icon if no pastControl exists or animation property has changed


            if (!this.pastControl || this.pastControl.details.animation !== this.control.details.animation) {
                this._prepareLiveIcon(); // This property is needed to validate any animation type change via the ExpertMode


                this.pastControl = cloneObject(this.control);
            }

            return this._getLiveIcon();
        }

        getLiveStateIcon() {
            if (this.states.configurationNeeded) {
                return Icon.General.CAUTION_NO_CIRCLE;
            } // We need to react to changes of the animation property by the ExpertMode
            // So we prepare the lives icon if no pastControl exists or animation property has changed

            if (!this.pastControl || this.pastControl.details.animation !== this.control.details.animation) {
                this._prepareLiveIcon(); // This property is needed to validate any animation type change via the ExpertMode
                this.pastControl = cloneObject(this.control);
            }

            return this._getLiveIcon(true);
        }

        getStateTextForContent() {
            if (this.states.deviceOffline) {
                return null; // handled by getStateInfo;
            }

            if (this.states.locked) {
                return this._getLockedText();
            } else if (this.states.safetyActive) {
                if (hasBit(this.states.safetyActive, CtrlEnums.SAFETY_STATES.STOP)) {
                    return _('controls.jalousie.safety-shutdown');
                } else {
                    return this.getStateText(true);
                }
            } else if (this.control.details.isAutomatic) {
                return this.states.autoActive ? _('controls.jalousie.sun-pos-shading.active') : _('controls.jalousie.sun-pos-shading.inactive');
            }
        }

        getStateColor() {
            if (this.states.configurationNeeded || this.states.deviceOffline) {
                return window.Styles.colors.red;
            }

            if (this.states.direction !== Direction.NOT_MOVING) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateTextColor() {
            if (this.states.deviceOffline) {
                return window.Styles.colors.red;
            } else if (this.states.configurationNeeded || this.states.locked || hasBit(this.states.safetyActive, CtrlEnums.SAFETY_STATES.STOP)) {
                return window.Styles.colors.red;
            }

            if (this.states.direction !== Direction.NOT_MOVING) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateIconSmall() {
            if (this.states.deviceOffline) {
                return {
                    iconSrc: Icon.General.CAUTION_NO_CIRCLE,
                    color: window.Styles.colors.red
                };
            }

            if (this.states.configurationNeeded) {
                return;
            }

            if (this.states.jalousieMode === CtrlEnums.JalousieMode.LOCKED || hasBit(this.states.safetyActive, CtrlEnums.SAFETY_STATES.STOP)) {
                return {
                    iconSrc: Icon.Jalousie.ComfortMode.LOCK,
                    color: window.Styles.colors.red
                };
            } else if (hasBit(this.states.safetyActive, CtrlEnums.SAFETY_STATES.DOOR_OPENED)) {
                // The door open state (CtrlEnums.SAFETY_STATES.DOOR_OPENED) has the lowest prioritization so just compare to this state, of other higher prioritized states are active show them instead
                return {
                    iconSrc: Icon.IRCV2.REASON.WINDOW_OPEN,
                    // Same icon as irc
                    color: window.Styles.colors.orange
                };
            } else if (this.states.isMoving) {
                return {
                    iconSrc: this.getMovingIcon(),
                    color: window.Styles.colors.stateActive
                };
            } else if (this.control.details.isAutomatic) {
                return {
                    iconSrc: Icon.Jalousie.ComfortMode.AUTO,
                    color: this.states.autoActive ? window.Styles.colors.stateActive : this.states.autoAllowed ? null : window.Styles.colors.orange
                };
            }
        }

        getStateInfo() {
            var stateText = this.getStateText(),
                res;

            if (this.states.deviceOffline) {
                res = this._createStateInfo(_("device-offline.message"), _("device-offline"), window.Styles.colors.red);
            } else if (this.states.jalousieMode !== CtrlEnums.JalousieMode.LOCKED && !hasBit(this.states.safetyActive, CtrlEnums.SAFETY_STATES.STOP) && hasBit(this.states.safetyActive, CtrlEnums.SAFETY_STATES.DOOR_OPENED)) {
                res = {
                    title: _('controls.jalousie.door-open.info.title'),
                    message: _('controls.jalousie.door-open.info.text') + ' ' + stateText
                };
            } else if (this.states.infoText) {
                res = {
                    title: this.states.infoText,
                    message: stateText
                };
            } else {
                res = {
                    message: stateText
                };
            }

            return res;
        }

        getMovingIcon() {
            var direction = this.states.direction;

            if (direction === Direction.UP) {
                return this.control.getOpenIcon();
            } else if (direction === Direction.DOWN) {
                return this.control.getCloseIcon();
            }
        }

        getStaticIcon() {
            var position = this.states.positionState,
                isOpened = position === 0,
                isClosed = position >= 0.95; // above 95% -> "closed" icon

            if (this.control.isCurtain()) {
                if (isOpened) {
                    if (this.control.details.animation === CtrlEnums.AnimationType.CURTAIN_BOTH_SIDES) {
                        return Icon.Jalousie.ComfortMode.CURTAIN.OPEN_DOUBLE;
                    } else if (this.control.details.animation === CtrlEnums.AnimationType.CURTAIN_LEFT) {
                        return Icon.Jalousie.ComfortMode.CURTAIN.OPEN_LEFT;
                    } else if (this.control.details.animation === CtrlEnums.AnimationType.CURTAIN_RIGHT) {
                        return Icon.Jalousie.ComfortMode.CURTAIN.OPEN_RIGHT;
                    }
                } else {
                    return Icon.Jalousie.ComfortMode.CURTAIN.CLOSE;
                }
            } else if (this.control.isAwning()) {
                if (isOpened) {
                    return Icon.Jalousie.ComfortMode.AWNING.OPEN;
                } else {
                    return Icon.Jalousie.ComfortMode.AWNING.CLOSE;
                }
            } else {
                if (isOpened) {
                    return Icon.Jalousie.ComfortMode.BLINDS.OPEN;
                } else if (isClosed) {
                    return Icon.Jalousie.ComfortMode.BLINDS.CLOSE;
                } else {
                    return Icon.Jalousie.ComfortMode.BLINDS.SHADE;
                }
            }
        }

        _checkAvailableModes(modes) {
            if (typeof modes !== "string" || modes.length === 0) {
                return false;
            }

            modes = modes.split(",");
            return modes.length > 0;
        }

        _getSlatsStateString(shadeState) {
            switch (this._getSlatsOrientation(shadeState)) {
                case CtrlEnums.SlatsOrientation.HORIZONTAL:
                    return _('controls.jalousie.slat-state.horizontal');
                    break;

                case CtrlEnums.SlatsOrientation.VERTICAL:
                    return _('controls.jalousie.slat-state.vertical');
                    break;

                default:
                    return _('controls.jalousie.slat-state.shade');
                    break;
            }
        }

        _getSlatsOrientation(pos) {
            // We need to ignore the position if the animationType Blinds, blinds don't have any slats,
            // so we directly can return the vertical slats orientation
            if (this.control.details.animation !== CtrlEnums.AnimationType.BLINDS) {
                return CtrlEnums.SlatsOrientation.VERTICAL;
            }

            var res = CtrlEnums.SlatsOrientation.BETWEEN;

            if (pos < 0.05) {
                res = CtrlEnums.SlatsOrientation.HORIZONTAL;
            } else if (pos > 0.95) {
                res = CtrlEnums.SlatsOrientation.VERTICAL;
            }

            return res;
        }

        _getLiveIcon(useTransformations) {
            if (this.control.isCurtain()) {
                this._animateCurtainIcon();
            } else if (this.control.isAwning()) {
                this._animateAwningIcon();
            } else {
                this._animateJalousieIcon();
            }

            return useTransformations ? this.snapContainerLive.outerSVG() : this.snapContainer.outerSVG();
        }

        _animateJalousieIcon() {
            let slatOrientation = this._getSlatsOrientation(this.states.shadeState),
                value = 20 * (1 - this.states.positionState) * -1,
                isVertical = slatOrientation === CtrlEnums.SlatsOrientation.VERTICAL,
                isHorizontal = slatOrientation === CtrlEnums.SlatsOrientation.HORIZONTAL,
                hiddenPos = -20;

            this.verticalSlats.transform(`t0,${isVertical ? value : hiddenPos}`);
            this.horizontalSlats.transform(`t0,${isHorizontal ? value : hiddenPos}`);
            this.shadingSlats.transform(`t0,${!isVertical && !isHorizontal ? value : hiddenPos}`);

            this.states.transformations = {
                jal_slats_vertical: `translate(0,${isVertical ? value : hiddenPos})`,
                jal_slats_horizontal: `translate(0,${isHorizontal ? value : hiddenPos})`,
                jal_slats_shading: `translate(0,${!isVertical && !isHorizontal ? value : hiddenPos})`
            }
        }

        _animateAwningIcon() {
            let value = 1 - this.states.positionState,
                mvFlags = 9 * value * -1,
                mvCover = mvFlags / 2,
                // only move halve as the rest moves due to the scaling.
                scaleBars = 1 + value,
                animation = "S1," + this.states.positionState;

            if (this.states.positionState > 0) {
                // cannot translate sth that has no height.
                animation += "T0," + mvCover;
            }

            let awningCoverMatrix = this.awningCover.transform(animation).matrix;
            let awningCoverMatrixString = Object.values(awningCoverMatrix).join(",");
            let awningFlagMatrix = this.awningFlags.transform(`T0,${mvFlags}`).matrix;
            let awningFlagMatrixString = Object.values(awningFlagMatrix).join(",");
            let windowBarMatrix = this.windowBars.transform(`T0,${mvFlags / 2}S1,${scaleBars}`).matrix;
            let windowBarMatrixString = Object.values(windowBarMatrix).join(",");
            this.states.transformations = {
                awning_cover: `matrix(${awningCoverMatrixString})`,
                awning_flags: `matrix(${awningFlagMatrixString})`,
                window_bars: `matrix(${windowBarMatrixString})`
            };
        }

        _animateCurtainIcon() {
            let value = 1 - this.states.positionState,
                // how much is to be hidden
                moveVal,
                animation,
                translateHBar = 0,
                moveLeft,
                moveRight,
                showLeftBar = false,
                showRightBar = false;

            switch (this.control.details.animation) {
                case CtrlEnums.AnimationType.CURTAIN_BOTH_SIDES:
                    moveVal = 8 * value;
                    moveLeft = moveVal * -1;
                    moveRight = moveVal;
                    break;

                case CtrlEnums.AnimationType.CURTAIN_LEFT:
                    moveVal = 19 * value;
                    moveLeft = moveVal * -1;
                    moveRight = moveLeft;
                    showRightBar = true;
                    translateHBar = 10 * (1 - value);
                    break;

                case CtrlEnums.AnimationType.CURTAIN_RIGHT:
                    moveVal = 19 * value;
                    moveLeft = moveVal;
                    moveRight = moveVal;
                    showLeftBar = true;
                    translateHBar = 10 * (1 - value) * -1;
                    break;

                default:
                    break;
            }

            this.curtain0.transform(`t${moveLeft},0`);
            this.curtain1.transform(`t${moveRight},0`); // animate the horizontal bars
            animation = "S" + value + ",1";

            if (value > 0) {
                animation += `T${translateHBar},0`;
            }

            // here the scale and translate values cannot be used since the snap svg transform method process these values differently
            // therefore we are using the result of the transform function for passing it to the transformations object
            let barAnimationMatrix = this.topWindowBar.transform(animation).matrix;
            let barAnimationMatrixString = Object.values(barAnimationMatrix).join(",");
            this.bottomWindowBar.transform(animation);


            // optionally hide the left or the right bar.
            !showLeftBar && this.leftWindowBar.transform("s0,0");
            !showRightBar && this.rightWindowBar.transform("s0,0");

            this.states.transformations = {
                curtains_left: `translate(${moveLeft},0)`,
                curtains_right: `translate(${moveRight},0)`,
                window_top: `matrix(${barAnimationMatrixString})`,
                window_bottom: `matrix(${barAnimationMatrixString})`
            }

            if (!showLeftBar) {
                this.states.transformations.window_left = "scale(0,0)"
            }

            if (!showRightBar) {
                this.states.transformations.window_right = "scale(0,0)"
            }
        }

        _prepareLiveIcon() {
            if (this.control.isCurtain()) {
                this._prepareSnapContainer(Icon.Jalousie.Type.CURTAIN);

                this.topWindowBar = this.snapContainer.select("#window_top");
                this.bottomWindowBar = this.snapContainer.select("#window_bottom");
                this.leftWindowBar = this.snapContainer.select("#window_left");
                this.rightWindowBar = this.snapContainer.select("#window_right");
                this.curtain0 = this.snapContainer.select("#curtains_left");
                this.curtain1 = this.snapContainer.select("#curtains_right");
            } else if (this.control.isAwning()) {
                this._prepareSnapContainer(Icon.Jalousie.Type.AWNING);

                this.windowBars = this.snapContainer.select("#window_bars");
                this.awningCover = this.snapContainer.select("#awning_cover");
                this.awningFlags = this.snapContainer.select("#awning_flags");
            } else {
                this._prepareSnapContainer(Icon.Jalousie.Type.REGULAR);

                this.verticalSlats = this._lookupPartInSvg("#jal_slats_vertical");
                this.shadingSlats = this._lookupPartInSvg("#jal_slats_shading");
                this.horizontalSlats = this._lookupPartInSvg("#jal_slats_horizontal");
            }
        }

        _prepareSnapContainer(iconSrc) {
            this.snapContainer = Snap($(ImageBox.getResourceImage(iconSrc))[0]);
            this.snapContainerLive = Snap($(ImageBox.getResourceImage(iconSrc))[0]);
        }

        _lookupPartInSvg(svgId) {
            return this.snapContainer.select("g").select(svgId);
        }

        _getLockedText() {
            var type = _('controls.jalousie.jalousie');

            if (this.control.isCurtain()) {
                type = _('controls.jalousie.curtain');
            } else if (this.control.details.animation === CtrlEnums.AnimationType.SHUTTERS) {
                type = _('controls.jalousie.shutters');
            } else if (this.control.isAwning()) {
                type = _('controls.jalousie.awning');
            }

            return _('controls.jalousie.locked', {
                type: type
            });
        }

    };
});
