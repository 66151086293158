'use strict';
/**
 * This extension is used to handle everything related with the control preset feature.
 */

SandboxComp.factory('ControlPresetExt', function () {
    return class ControlPreset extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.name = "ControlPresetExtension";
        }

        // ----------------------------------------------------------------------------------------------------
        //              Public Methods
        // ----------------------------------------------------------------------------------------------------

        /**
         * Returns an object for the contextMenu screen content. Contains a loadFn if the info required for the
         * content is to be fetched from the Miniserver first. The loadFn is handled by the ContextMenuScreen.
         * Returns null if the control doesn't make use of
         * presets.
         * @param control
         * @returns {{loadFn}|null}
         */
        getPresetContextMenuEntryFor(control) {
            Debug.ControlPreset && console.log(this.name, "getPresetInfos: " + control.name);

            if (!control.preset) {
                Debug.ControlPreset && console.log(this.name, "     has no presets!");
                return null;
            }

            return {
                loadFn: this._loadFnForPresetContextMenu.bind(this, control)
            };
        }

        /**
         * Will update the preset based on the current controls setting, then ask if other control should also be
         * updated. This method is used in the expert mode - it requires the expert mode permission.
         * @param preset    the preset to update.
         * @param control   the control, whichs settings are to be used as the new preset
         * @returns {*}
         */
        updatePreset(preset, control) {
            Debug.ControlPreset && console.log(this.name, "updatePreset");

            var prms = this._handleUpdatePreset(preset, control);

            if (preset.numAffectedControls > 1) {
                Debug.ControlPreset && console.log(this.name, "   more than one, after the update - show reset all confirmation"); // other controls are affected too - update them as well

                prms = prms.then(this._showResetAllToPresetConfirmation.bind(this, preset));
            } else {
                Debug.ControlPreset && console.log(this.name, "   only one, after the update - show preset update success!");
                prms = prms.then(this._showPresetUpdateSuccessPopup.bind(this, preset));
            }

            return prms.then(function (succ) {
                Debug.ControlPreset && console.log(this.name, "updatePreset >> success");
                return succ;
            }.bind(this));
        }

        // ----------------------------------------------------------------------------------------------------
        //              Update Preset - Private Methods
        // ----------------------------------------------------------------------------------------------------
        _handleUpdatePreset(preset, control) {
            var cmd = Commands.format(Commands.ControlPreset.UPDATE_CTRL_PRESET, control.uuidAction);
            Debug.ControlPreset && console.log(this.name, "_handleUpdatePreset: update the preset: " + cmd);
            return this._sendWithEmPermission(cmd).fail(function (err) {
                console.error(this.name, "_handleUpdatePreset: failed to update preset!", err);
                return this._showPresetUpdateFailPopup(preset, err);
            }.bind(this));
        }

        _showResetAllToPresetConfirmation(preset) {
            return NavigationComp.showPopup({
                title: _("preset.updated.title", {
                    name: preset.userfriendlyValue(preset.value)
                }),
                message: _("preset.update-controls.question"),
                buttonOk: _("preset.update-controls.do-update"),
                buttonCancel: _("not-now")
            }).then(this._handleResetAllToPreset.bind(this, preset));
        }

        _handleResetAllToPreset(preset) {
            var cmd = Commands.format(Commands.ControlPreset.RESET_ALL, preset.presetUuid);
            Debug.ControlPreset && console.log(this.name, "_handleResetAllToPreset: " + cmd);
            return this._sendWithEmPermission(cmd).fail(function (err) {
                console.error(this.name, "_handleResetAllToPreset failed!", err);
                return this._showResetAllToPresetFailPopup(preset, err);
            }.bind(this));
        }

        _showPresetUpdateSuccessPopup(preset) {
            return NavigationComp.showPopup({
                title: _("preset.updated.title", {
                    name: preset.userfriendlyValue(preset.value)

                }),
                buttonOk: _("okay")
            });
        }

        _showPresetUpdateFailPopup(preset, err) {
            return NavigationComp.showPopup({
                title: _("preset.update.error", {
                    name: preset.userfriendlyValue(preset.value)

                }),color: window.Styles.colors.red,
                buttonOk: _("okay")
            }).then(function () {
                throw err;
            });
        }

        _showResetAllToPresetFailPopup(preset, err) {
            return NavigationComp.showPopup({
                title: _("preset.update-controls.error"),
                color: window.Styles.colors.red,
                buttonOk: _("okay")
            }).then(function () {
                throw err;
            });
        }

        // ----------------------------------------------------------------------------------------------------
        //              Context Menu - Private Methods
        // ----------------------------------------------------------------------------------------------------

        /**
         * Will download the current preset infos, e.g. if it has been modified, what parts of the control are
         * affected, when it is being reset to default.
         * @param control
         * @returns {*}
         */
        _getPresetInfos(control) {
            Debug.ControlPreset && console.log(this.name, "getPresetInfos: " + control.name);
            var cmd = Commands.format(Commands.ControlPreset.GET_CTRL_INFO, control.uuidAction);
            return this._send(cmd).then(function (result) {
                Debug.ControlPreset && console.log(this.name, "    preset infos received for: " + control.name, result);
                return getLxResponseValue(result);
            }.bind(this));
        }

        /**
         * Returns an object that will be presented in the ContextMenuScreen
         * @param presetInfo    the preset info of the control
         * @param control       the control this entry is for
         * @returns {{action: (*), disabled: boolean, loadFn: (*), title: string}}
         * @private
         */
        _createPresetContextMenuEntry(presetInfo, control) {
            Debug.ControlPreset && console.log(this.name, "_createPresetContextMenuEntry", presetInfo);
            return {
                title: _("preset.reset-control.title"),
                disabled: !presetInfo.presetModified,
                action: this._handleResetToTemplate.bind(this, control, presetInfo.presetResetHelp),
                loadFn: this._loadFnForPresetContextMenu.bind(this, control) // pass load fn again, to update ctx menu each time its opened

            };
        }

        /**
         * LoadFn called by the ContextMenu to load the infos required and then create a contextMenu option object
         * from it.
         * @param control
         * @returns {*}
         * @private
         */
        _loadFnForPresetContextMenu(control) {
            return this._getPresetInfos(control).then(function (presInfo) {
                return this._createPresetContextMenuEntry(presInfo, control);
            }.bind(this));
        }

        /**
         * Presents a reset confirmation popup.
         * @param control   The control that is to be resetted
         * @param text      Explains what properties and settings of the control are affected when resetting.
         * @returns {*}
         * @private
         */
        _handleResetToTemplate(control, text) {
            return NavigationComp.showPopup({
                title: _("preset.reset-control.title"),
                message: text,
                buttonOk: _("preset.reset-control.do-reset"),
                buttonCancel: true
            }).then(this._resetToPreset.bind(this, control));
        }

        /**
         * Will reset the control provided back to its presets values & settings.
         * @param control
         * @returns {*}
         */
        _resetToPreset(control) {
            Debug.ControlPreset && console.log(this.name, "resetToPreset: " + control.name);
            var cmd = Commands.format(Commands.ControlPreset.RESET_CTRL, control.uuidAction);
            return this._send(cmd).then(function (result) {
                Debug.ControlPreset && console.log(this.name, "    control has been reset to preset: " + control.name, result);
                return getLxResponseValue(result);
            }.bind(this));
        }

        // ----------------------------------------------------------------------------------------------------
        //              Common Private Methods
        // ----------------------------------------------------------------------------------------------------
        _send(cmd) {
            Debug.ControlPreset && console.log(this.name, "_send: " + cmd);
            return SandboxComponent.send(cmd, EncryptionType.NONE);
        }

        _sendWithEmPermission(cmd) {
            Debug.ControlPreset && console.log(this.name, "_sendWithEmPermission: " + cmd);
            return SandboxComponent.sendWithPermission(cmd, MsPermission.EXPERT_MODE);
        }

    };
});
