'use strict';

SandboxComp.factory('AmbientModeExt', function () {

    /**
     * @class AmbientExtHelper
     * base class for all helpers in the ambientModeExt.
     */
    class AmbientExtHelper {
        constructor(changeCallback, extChannel, comp, ambientModeExt) {
            this._changeCallback = changeCallback;
            this._extChannel = extChannel;
            this._comp = comp;
            this._ext = ambientModeExt;

            if (this.shouldRegisterForSettingChanges()) {
                this.registerForEvent(SandboxComp.ECEvent.AmbientModeSettingChanged, this.onSettingChanged.bind(this)); // NEW
            }
        }

        onSettingChanged() {
            // nothing to do in base.
        }

        registerForEvent(ev, cb) {
            return this._extChannel.on(ev, cb);
        }

        shouldRegisterForSettingChanges() {
            return false;
        }

        getProps() {
            console.error("getProps not overridden!", getStackObj());
            return {};
        }

        dispatchChange() {
            this._changeCallback(this, this.getProps())
        }
    }

    /**
     * @class ShowAmbientTimeout
     * Needs to be explicitly started & stopped, will fire after the configured delay when the ambient mode should appear.
     */
    class ShowAmbientTimeout extends AmbientExtHelper {
        constructor() {
            super(...arguments);
            this.name = "ShowAmbientTimeoutHelper";
            this._showTimeoutPassed = false;
            this._showAmbientDelay = this._loadDelaySetting();
        }

        getProps() {
            return {
                showAmbientTimeoutPassed: this._showTimeoutPassed,
                showAmbientTimeoutActive: !!this._showTimeout,
                showAmbientDelay: this._showAmbientDelay
            };
        }

        start() {
            this._showTimeoutPassed = false;
            let currentTimeout = this._getTimeout();
            if (currentTimeout > 0) {
                this._showTimeout = setTimeout(() => {
                    this._showTimeout = null;
                    this._showTimeoutPassed = true;
                    this.dispatchChange();
                }, currentTimeout * 1000);
            }
            this.dispatchChange();
        }

        stop() {
            this._showTimeout && clearTimeout(this._showTimeout);
            this._showTimeout = null;
            this._showTimeoutPassed = false;
            this.dispatchChange();
        }

        _getTimeout() {
            let currentDelay = this._loadDelaySetting();
            if (currentDelay !== this._showAmbientDelay) {
                this._showAmbientDelay = currentDelay;
                this.dispatchChange();
            }
            if (Debug.Test.QUICK_AMBIENT_ECO) {
                return 7;
            }
            return this._showAmbientDelay;
        }

        _loadDelaySetting() {
            let settings = PersistenceComponent.getAmbientModeSettings();
            return settings ? settings.timeout : 10;
        }
    }

    return class AmbientMode extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            window.addEventListener("beforeunload",  (event) => {
                this._persistTimeout && clearTimeout(this._persistTimeout);
                this._persistTimeout = null;
                this.persistCachedTabState()
            });
            this._blocked = false;
            this.name = "AmbientModeExt";
            this.props = {};

            if (!HD_APP && !AMBIENT_MODE) {
                return; // there's no ambient mode on SD
            }

            const initExtHelper = (ctor) => {
                return new ctor(this._helperChanged.bind(this), extensionChannel, component, this);
            }
            this._showAmbientModeTimeout = initExtHelper(ShowAmbientTimeout);

            this.registerExtensionEv(SandboxComp.ECEvent.DeviceActivityChanged, (ev, props) => {
                // props =  { active: true|false }
                this._helperChanged(null, {deviceActivity: props.active});
            });

            // perist when resign is received, as it may be killed after that..
            this.registerExtensionEv(CCEvent.Resign, () => {
                this.persistCachedTabState(false);
            });
            // when switching MS  - stopMSSession will
            this.registerExtensionEv(SandboxComp.ECEvent.Reset, () => {
                this.persistCachedTabState(true)
            });

            setTimeout(() => {
                this._helperChanged(null, {deviceActivity: true}) // Initial Device Activity on App Start, can be set to true
            }, 1);
        }

        needToShowAmbientOnboarding() {
            var msSettings = PersistenceComponent.getMiniserverSettings();

            if (window.hasHDSize() && msSettings && !PairedAppComponent.isPaired) {
                if (!msSettings.ambientOnboardingShown) {
                    return true;
                }
            }

            return false;
        }

        // region public

        toggleAmbientModeShown(shown) {
            this._helperChanged(null, {
                ambientModeShown: shown
            })
        }

        setAmbientNavigation(navigation) {
            this._navigation = navigation;
        }

        getAmbientNavigation(navigation) {
            return this._navigation;
        }



        /**
         * sets the ambient mode to blocked & keeps it blocked until the return fn is called. Will not be unlocked
         * if other sources did trigger the lock.
         * @returns {(function(): void)|*}
         */
        blockAmbientMode() {
            this._ambientBlockers = this._ambientBlockers || {};
            let rdId;
            do {
                rdId = getRandomIntInclusive(0, 999999) + "";
            } while (this._ambientBlockers.hasOwnProperty(rdId));

            this._ambientBlockers[rdId] = true;
            this._checkIsBlockedUpdate();
            return () => {
                delete this._ambientBlockers[rdId];
                this._checkIsBlockedUpdate();
            }
        }

        /**
         * Checks if isBlocked needs to be changed & a response is required.
         * @private
         */
        _checkIsBlockedUpdate() {
            let isBlocked = this._ambientBlockers && Object.values(this._ambientBlockers).length > 0;
            if (isBlocked !== this._blocked) {
                this._blocked = isBlocked;
                this._helperChanged(null, { blocked: isBlocked })
            }
        }

        toggleAmbientModeBlock(blocked) {
            if (blocked !== this._blocked) {
                this._blocked = blocked;
                this._helperChanged(null, { blocked })
            }
        }

        // region state persisting

        persistedStateFileName(snr) {
            const mac = snr || ActiveMSComponent.getActiveMiniserverSerial();
            return mac + "-ambient-last-location-state";
        }

        /**
         * The state provided will be cached internally. Persisting will be done automatically (connClose, resign, reset)
         * or triggered from outside (e.g. when the ambient screen closes)
         * In non-mobile devices, it'll be automatically persisted after 5 seconds, as we cannot rely on e.g. the resign
         * event being triggered before being killed.
         * @param state
         */
        cacheTabStateToPersist(state) {
            Debug.AmbientMode && console.log(this.name, "cacheTabStateToPersist: ", state);
            this._lastAmbientTabState = cloneObjectDeep(state);
            this._lastAmbientTabStateMs = ActiveMSComponent.getActiveMiniserverSerial();

            // on web/dev-if/mac/win/linux/.. it may very well happen, that the app is killed without a warning.
            // hence store the data after a timeout here. Android/iOS will only persist when sent to background.
            if (!PlatformComponent.isMobileDevice()) {
                this._persistTimeout && clearTimeout(this._persistTimeout);
                this._persistTimeout = setTimeout(() => {
                    this.persistCachedTabState();
                }, 5000);
            }
        }

        /**
         * Will persist the state and eventually also reset the cached state (e.g. before MS change)
         * @param reset
         */
        persistCachedTabState(reset = false) {
            this._persistTimeout && clearTimeout(this._persistTimeout);
            this._persistTimeout = null;

            if (this._lastAmbientTabState) {
                let cleanedState = this._validateState(this._lastAmbientTabState);
                Debug.AmbientMode && console.log(this.name, "persistCachedTabState, reset=" + reset, cleanedState);
                PersistenceComponent.setLocalStorageItemWithTtl(
                    this.persistedStateFileName(this._lastAmbientTabStateMs),
                    cleanedState ? JSON.stringify(cleanedState) : null,
                    3600 * 12
                );
                if (reset) {
                    this._lastAmbientTabState = null;
                    this._lastAmbientTabStateMs = true;
                }
            }
        }

        /**
         * Either returns a cached state or the one locally stored.
         * @returns {null|any}
         */
        getPersistedTabState() {
            if (this._lastAmbientTabState &&
                this._lastAmbientTabStateMs === ActiveMSComponent.getActiveMiniserverSerial()) {
                Debug.AmbientMode && console.log(this.name, "getPersistedTabState - reuse cache: ", this._lastAmbientTabState);
                return this._lastAmbientTabState;
            }
            let stored = PersistenceComponent.getLocalStorageItemWithTtl(this.persistedStateFileName()),
                recoveredState = stored ? JSON.parse(stored) : null;
            this._lastAmbientTabState = (recoveredState ? this._validateState(recoveredState) : recoveredState);
            this._lastAmbientTabStateMs = ActiveMSComponent.getActiveMiniserverSerial();
            Debug.AmbientMode && console.log(this.name, "getPersistedTabState: ", this._lastAmbientTabState);
            return this._lastAmbientTabState;
        }

        _validateState(state) {
            let newState = {...state};
            delete newState.stale;
            delete newState.routeNames;
            delete newState.key;
            if (Array.isArray(newState.routes)) {
                newState.routes = newState.routes.map(routeEntry => this._validateRouteEntry(routeEntry));
            }
            return newState;
        }

        _validateRouteEntry(routeEntry) {
            let newRouteEntry = {...routeEntry};
            if (newRouteEntry.params) {
                newRouteEntry.params = {...newRouteEntry.params};
                delete newRouteEntry.params.control;
                delete newRouteEntry.params.screen;
                delete newRouteEntry.params.params;
                delete newRouteEntry.params.didReset; // important for EFM Control Content, it sets it when it has to be reset.
                if (Object.values(newRouteEntry).length === 0) {
                    delete newRouteEntry.params;
                }
            }

            if (newRouteEntry.state) {
                newRouteEntry.state = this._validateState(newRouteEntry.state);
            }

            return newRouteEntry;
        }

        // endregion

        /**
         *
         * @returns {*|boolean}
         */
        canShow() {
            return this._windowLargeEnough();
        }

        // endregion

        _currentWindowSize() {
            let viewPort = window.visualViewport,
                width = viewPort.width * viewPort.scale,
                height = viewPort.height * viewPort.scale;
            return {
                width,
                height,
                scale: viewPort.scale,
                unscaledWidth: viewPort.width,
                unscaledHeight: viewPort.height
            };
        }

        requiredWindowSize() {
            return {
                width: 1024,
                height: 700
            }
        }

        _windowLargeEnough() {
            let { width, height } = this._currentWindowSize(),
                requiredSize = this.requiredWindowSize();
            return width >= requiredSize.width && height >= requiredSize.height;
        }

        // region private

        _helperChanged(helper, props) {
            Debug.AmbientMode && console.log(this.name, "helperChanged: " + JSON.stringify(props) + ", current: " + JSON.stringify(this.props), helper);
            Object.assign(this.props, props);

            this._checkShowAmbientMode(helper, props);
            this._checkAmbientModeTimeout(helper, props);
        }

        _checkAmbientModeTimeout(changedHelper, change) {
            if (changedHelper === this._showAmbientModeTimeout) {
                // nothing to do
            } else if (!this.props.deviceActivity && !this.props.ambientModeShown && !this.props.blocked) {
                Debug.AmbientMode && console.log(this.name, "_checkAmbientModeTimeout: START!", this.props);
                this._showAmbientModeTimeout.start();

            } else if (change.deviceActivity || this.props.blocked && this.props.showAmbientTimeoutActive) {
                Debug.AmbientMode && console.log(this.name, "_checkAmbientModeTimeout: STOP!", this.props);
                this._showAmbientModeTimeout.stop();
            }
        }

        _checkShowAmbientMode(changedHelper, change) {
            if (this._windowLargeEnough() && changedHelper === this._showAmbientModeTimeout && change.showAmbientTimeoutPassed && !this.props.ambientModeShown && !this.props.blocked) {
                Debug.AmbientMode && console.log(this.name, "_checkShowAmbientMode: SHOW AMBIENT MODE", this.props);
                this._showAmbientMode();
                this.props.ambientModeShown = true;
            }
        }
        // endregion

        // region external calls

        _showAmbientMode() {
            Debug.AmbientMode && console.log(this.name, "_showAmbientMode");
            NavigationComp.showAmbientMode();
        }

        // endregion
    }

});
