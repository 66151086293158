'use strict';

define("RemoteControlStateContainer", ["ControlStateContainer", "RemoteControlEnums"], function (ControlStateContainer, RemoteControlEnums) {
    return class RemoteControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            this.hasModes = Object.keys(this.control.details.modeList).length > 0;
        }

        prepareStates(newVals) {
            // check if the mode still exists --> if not show the remoteControl as off
            // prevents Blackscreens when a currently active mode is removed via config.
            if (this._checkIfModeStillExists(newVals)) {
                this.states.timeout = newVals[this.control.states.timeout];
                this.states.activeMode = newVals[this.control.states.mode];
                this.states.needsWaiting = !!newVals[this.control.states.active];
                this.states.hasActiveMode = this.hasModes && this.states.activeMode > RemoteControlEnums.Modes.AllOff;
                this.states.waitingForOff = this.hasModes && this.states.activeMode === RemoteControlEnums.Modes.AllOff && this.states.needsWaiting === true;
                this.states.isOff = this.hasModes && this.states.activeMode === RemoteControlEnums.Modes.AllOff || !this.control.details.modeList[this.states.activeMode];
            } else {
                this.states.activeMode = RemoteControlEnums.Modes.AllOff;
                this.states.needsWaiting = true;
                this.states.hasActiveMode = false;
                this.states.waitingForOff = false;
                this.states.isOff = true;
            }
        }

        getStateText() {
            // We only want to update the current mode if we have modes!
            // Mode 0 means, that no mode is active!
            if (this.states.hasActiveMode) {
                return this.control.details.modeList[this.states.activeMode].name;
            } else {
                return _("controls.lightcontroller.all-off");
            }
        }

        getStateTextForContent() {
            if (this.states.hasActiveMode) {
                return null; // if a mode is active dpad/numpad are shown - the mode is displayed inside the action btn
            } else {
                return this.getStateText();
            }
        }

        getStateColor() {
            if (this.states.hasActiveMode) {
                return window.Styles.colors.stateActive;
            }
        }

        _checkIfModeStillExists(newVals) {
            return this.control.details.modeList[newVals[this.control.states.mode]] || newVals[this.control.states.mode] === 0;
        }

    };
});
