'use strict';

define("RadioControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class RadioControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.activeChoiceNr = newVals[this.control.states.activeOutput];
        }

        getStateText() {
            if (this.states.activeChoiceNr > 0) {
                if (this.control.details.outputs.hasOwnProperty(this.states.activeChoiceNr)) {
                    return this.control.details.outputs[this.states.activeChoiceNr];
                } else {
                    return "-";
                }
            } else if (this.control.details.allOff && this.control.details.allOff !== "") {
                return this.control.details.allOff;
            } else {
                return "-";
            }
        }

    };
});
