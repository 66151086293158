'use strict';
import {getControlForType} from "Controls";

/**
 * Handles the items displayed in the menu tab of the loxone app.
 */

SandboxComp.factory('UrlStartCommandHandlerExt', function () {
    var CMD_PREFIX = "jdev/sps/io/";
    var URL_START_FILENAME = "UrlStart.json";
    return class UrlStartCommandHandler extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            /**
             * Test case code
             setTimeout(function () {
          // regular
          //var cmd = "loxonecmd://ms?mac=EEE000240011&cmd=jdev/sps/io/0d13c6bc-0392-513e-ffff504f94000000/pulse";
           // secured
          var cmd = "loxonecmd://ms?mac=EEE000240011&cmd=jdev/sps/io/0cfae7d6-016b-8501-ffff504f94000000/1/on&cmd2=jdev/sps/io/0cfae7d6-016b-8501-ffff504f94000000/1/pulse";
          SandboxComponent.handleUrlCommand(cmd);
      }, 2000);
             */
        }

        /**
         * Called when an url start with an command is detected.
         * @param url   the url that contains the command(s - it could be two cmds)
         */
        handleCommand(url) {
            let cmdObj, msObj, promise, creds, hosts;

            try {
                cmdObj = this._parseUrlCommand(url);
                msObj = this._getMiniserverObj(cmdObj);
                promise = this._checkPermissionForCommand(url, msObj.serialNo, msObj.msName, cmdObj.cmds).then(() =>  {
                    // if this cmd affects a different Miniserver than the current one, connect to that one.
                    if (!this._isActiveMS(msObj.serialNo)) {
                        NavigationComp.connectTo(msObj);
                    }

                    const msObjWithAuthInfo = cloneObjectDeep(msObj);
                    PersistenceComponent.applyStoredAuthInfoForMiniserver(msObjWithAuthInfo).then(() => {
                        creds = this._getCreds(msObjWithAuthInfo);
                        hosts = this._getHosts(msObjWithAuthInfo); // Checks if the control is an centralControl. These controls must be handle
                        // separately if one subControl requires a visuPW

                        return this._checkCentralControlVisuPw(url, creds).then(() => {
                            // request a text that represents this url command
                            return this._getTextForCommand(cmdObj.cmds[0], msObjWithAuthInfo.serialNo, msObjWithAuthInfo.msName).then((text) => {
                                // everything is fine, proceed with sending the command.
                                return this._sendHttpRequest(cmdObj.cmds, hosts, creds, text);
                            })
                        })
                    })})
            } catch (ex) {
                console.error(ex);
                promise = Q.reject(ex);
            } // oddly handling the error in the fail section of any then above won't work. this is why it is handled separately here.


            return promise.then(null, function (error) {
                return this._handleCmdError(error);
            }.bind(this));
        }

        // -----------------------------------------------------------------------------------------------------

        /**
         * Will launch a http reqeust using the communication component trying to send the request.
         * @param cmds      the commands to send (at most 2 supported as of now)
         * @param hosts     a list of the possible hosts to use for sending this request.
         * @param creds     the credentials object containing all the info required to send the request
         * @param text      a command text that will be shown while the command is being sent.
         * @return {*}
         * @private
         */
        _sendHttpRequest(cmds, hosts, creds, text) {
            // present a notification
            this._showSendingNotification(text);

            return CommunicationComponent.sendHttpRequests(cmds, hosts, creds).then(function (results) {
                this._hideSendingNotification(); // when a visu pass was used for this request, inform the sandbox so the pass is stored for reuse.


                creds.visuPw && SandboxComponent.setVisuPassword(creds.visuPw);

                this._handleCmdSuccess(results);

                return results;
            }.bind(this), function (error) {
                this._hideSendingNotification();

                return this._handleCmdError(error, cmds, hosts, creds, text);
            }.bind(this));
        }

        /**
         * Will parse the url provided and return an object with all the url arguments in it.
         * It will throw an exception if the url cannot be parsed!
         * @param url
         * @returns {{}}
         * @private
         */
        _parseUrlCommand(url) {
            var adopted = url.slice(UrlHelper.LOXONE_CMD_SCHEME.length),
                // cut off the 'loxonecmd://'
                cmdObj;

            if (adopted.indexOf("ms?") !== 0) {
                throw new Error("'" + url + "' is not a valid Command-URL-Scheme!");
            }

            cmdObj = getURIParameters(url); // create commands array.

            cmdObj.cmds = [];
            cmdObj.cmd && cmdObj.cmds.push(cmdObj.cmd);
            cmdObj.cmd2 && cmdObj.cmds.push(cmdObj.cmd2);
            return cmdObj;
        }

        /**
         * Will "translate" the cmd object into a miniserver object. Either by looking up a Miniserver from the
         * archive using the mac provided or by creating a new object with the credentials provided.
         * @param cmdObj    the cmd object containg all the information passed in with the URL
         * @returns {*}
         * @private
         */
        _getMiniserverObj(cmdObj) {
            var msObj;

            if (cmdObj.mac && cmdObj.mac !== "") {
                // load from persistence
                msObj = PersistenceComponent.getMiniserver(cmdObj.mac);
            } else {
                msObj = {
                    activeUser: cmdObj.usr,
                    password: VendorHub.Crypto.encrypt(cmdObj.pwd),
                    msName: cmdObj.host
                };
                msObj = prepareConnAddress(cmdObj.host, msObj, ActiveMSComponent.getDataCenter());
            }

            return msObj;
        }

        /**
         * Checks the msObj and retrieves a credentials object containing both the type and the authentication
         * payload (token or user+pass) for authenticating later on.
         * @param msObj
         * @returns {*}
         * @private
         */
        _getCreds(msObj) {
            var pwd = this._getCredItem(msObj, "password"),
                token = this._getCredItem(msObj, "token"),
                user = msObj.activeUser,
                creds;

            if (token && token !== "") {
                creds = {
                    type: AuthType.TOKEN,
                    payload: token,
                    user: user
                };

                if (pwd && pwd !== "") {
                    console.error("Password still stored, even though a token exists!");
                }
            } else if (pwd && pwd !== "") {
                creds = {
                    type: AuthType.PASSWORD,
                    payload: user + ":" + pwd
                };
            }

            if (msObj.publicKey) {
                if (!creds) {
                    Debug.UrlHandler && console.warn("No token or password are provided for the command")
                    creds = {};
                }
                creds.publicKey = msObj.publicKey;
            }

            return creds;
        }

        /**
         * Retrieves and decrypts an item from the msObj specified by the item ID (either the token or password)
         * @param msObj     the source for the item.
         * @param itemId    either token or password
         * @returns {*}     either a string or undefined
         * @private
         */
        _getCredItem(msObj, itemId) {
            var item;

            try {
                item = msObj.hasOwnProperty(itemId) ? VendorHub.Crypto.decrypt(msObj[itemId]) : null;

                if (item && item.length === 0) {
                    item = msObj[itemId];
                }
            } catch (e) {
                console.error(e);
                item = msObj[itemId];
            }

            return item;
        }

        /**
         * Returns a priorized array of possible targets for this command.
         * @param msObj     the miniserver object to get the hosts for.
         * @returns {Array}
         * @private
         */
        _getHosts(msObj) {
            var hosts = [];
            this._isActiveMS(msObj.serialNo) && hosts.push(ActiveMSComponent.getCurrentUrl());
            msObj.localUrl && hosts.push(msObj.localUrl);

            if (msObj.remoteUrl) {
                if (isCloudDnsUrl(msObj.remoteUrl)) {
                    var idx = msObj.remoteUrl.lastIndexOf("/"),
                        mac = msObj.remoteUrl.substr(idx + 1);
                    hosts.push(getCloudDnsHost(ActiveMSComponent.getDataCenter()) + "/?" + mac); // https://www.wrike.com/open.htm?id=73219296
                } else {
                    hosts.push(msObj.remoteUrl);
                }
            }

            return hosts;
        }

        /**
         * Will check if the permission for this url has already been granted. if not it'll request the permission
         * from the user.
         * @param url
         * @param serialNo
         * @param msName
         * @param cmds
         * @returns {Promise}
         * @private
         */
        _checkPermissionForCommand(url, serialNo, msName, cmds) {
            var promise;

            if (serialNo) {
                // We're reusing stored credentials, ask for permission.
                promise = BiometricHelper.unlockApp().then(function () {
                    return this._isUrlKnown(url).fail(function () {
                        return this._getTextForCommand(cmds[0], serialNo, msName, true).then(function (txt) {
                            return this._requestPermissionFromUser(txt).then(function (always) {
                                always && this._addToKnownUrls(url);
                            }.bind(this));
                        }.bind(this));
                    }.bind(this));
                }.bind(this));
            } else {
                // the credentials need to be provided, no worries.
                promise = Q.fcall(function () {
                    return true;
                });
            }

            return promise;
        }

        /**
         * Creates the text that is shown when asking for permission to send the command.
         * @param cmd
         * @param serialNo      the serial number of the Miniserver
         * @param msName        the name of the Miniserver
         * @param allowHtml     optional, if true the resulting text will make use of html tags.
         * @returns {Promise}
         * @private
         */
        _getTextForCommand(cmd, serialNo, msName, allowHtml) {
            var controlUUID = UrlHelper.getControlUUIDFromCommandString(cmd),
                separator = allowHtml ? "<br/>" : " ",
                text;
            return this._getInitializedControl(serialNo, controlUUID).then(function (control) {
                // try to create a text for the command, otherwise display the command itself
                var groupDetail = "",
                    cmdParts;
                text = cmd.replace(CMD_PREFIX, "");
                cmdParts = text.split("/");

                if (control.groupDetail) {
                    groupDetail = separator + "(" + control.groupDetail + ")";
                }

                if (this._isActiveMS(serialNo)) {
                    msName = ""; // Don't show the name of the active miniserver
                } else {
                    // if it isn't the current ms, add the name
                    msName = msName + ":" + separator;
                }

                try {
                    text = createCmdText(control, cmdParts);
                } catch (e) {
                    console.log(e.stack);
                }

                return msName + text + groupDetail;
            }.bind(this), function () {
                // couldn't look  up the control, use the cmd as text.
                if (msName) {
                    text = msName + separator + cmd;
                }

                return cmd;
            });
        }

        /**
         * Will look up the proper control and return an object that also has the methods stored onto it.
         * @param serialNo
         * @param controlUUID
         * @return {Promise}
         * @private
         */
        _getInitializedControl(serialNo, controlUUID) {
            return PersistenceComponent.getControlOfMiniserver(serialNo, controlUUID).then(function (control) {
                addControlTypeToControl(control);
                try {
                    let typedControl = getControlForType(control.controlType);
                    //typedControl may be an empty object
                    return Object.keys(typedControl).length > 0 ? typedControl : control;
                } catch (e) {
                    developerAttention(e.message);
                    return control;
                }
            });
        }

        /**
         * Returns a deferred that resolves if the url is already known and rejects if it's not.
         * @param url
         * @private
         */
        _isUrlKnown(url) {
            var urlWithScheme = this._validateSchemeOfUrl(UrlHelper.LOXONE_CMD_SCHEME, url);

            return this._getUrlStartFile().then(function (urlStartFile) {
                var knownUrls = urlStartFile.knownUrls;

                if (knownUrls.indexOf(urlWithScheme) === -1) {
                    throw new Error("Unknown URL!"); // rejects the promise
                }
            });
        }

        /**
         * Will add the url to the known url list.
         * @param url
         * @private
         */
        _addToKnownUrls(url) {
            var urlWithScheme = this._validateSchemeOfUrl(UrlHelper.LOXONE_CMD_SCHEME, url);

            return this._getUrlStartFile().then(function (urlStartFile) {
                urlStartFile.knownUrls.push(urlWithScheme);

                this._saveUrlStartFile(urlStartFile);
            }.bind(this));
        }

        /**
         * Will present a popup asking the user for permission to send a command.
         * @param message
         * @returns {Promise.promise|*}
         * @private
         */
        _requestPermissionFromUser(message) {
            var content = {
                title: _('command.execute.question'),
                message: message,
                buttons: [{
                    title: _('execute'),
                    color: window.Styles.colors.orange
                }, {
                    title: _('execute.always'),
                    color: window.Styles.colors.orange
                }],
                buttonCancel: true,
                icon: Icon.CAUTION,
                color: window.Styles.colors.orange
            };
            return NavigationComp.showPopup(content).then(function (res) {
                return res === 1; // 1 = ALWAYS
            }.bind(this));
        }

        // -----------------------------------------------------------------------------------------------------

        /**
         * Will resolve with an object that contains an array of knownUrls that can be sent without asking the user.
         * @private
         */
        _getUrlStartFile() {
            return PersistenceComponent.loadFile(URL_START_FILENAME, DataType.OBJECT).then(null, function () {
                return {
                    knownUrls: []
                };
            });
        }

        /**
         * Stores the object that contains the knownUrls array.
         * @param file
         * @returns {*|promise}
         * @private
         */
        _saveUrlStartFile(file) {
            return PersistenceComponent.saveFile(URL_START_FILENAME, file, DataType.OBJECT);
        }

        /**
         * Returns true if the serial provided is the current miniserver.
         * @param serialNo
         * @returns {*|boolean}
         * @private
         */
        _isActiveMS(serialNo) {
            var activeMs = ActiveMSComponent.getActiveMiniserver();
            return activeMs && activeMs.serialNo === serialNo;
        }

        // -------------------------------------------------------------------------------------------
        // ---------------------------------  UI Feedback  -------------------------------------------
        _handleCmdSuccess(result) {
            Debug.UrlHandler && console.log(" - command successful: " + JSON.stringify(result));
            GUI.Notification.createGeneralNotification({
                title: _('command.successful'),
                //subtitle: url,
                removeAfter: 5
            }, NotificationType.SUCCESS);
        }

        /**
         * Will request a new visupass if needed, otherwise it'll inform that sending the request did fail.
         * @param error
         * @param [cmds]
         * @param [hosts]
         * @param [creds]
         * @param [text]
         * @return {*}
         * @private
         */
        _handleCmdError(error, cmds, hosts, creds, text) {
            Debug.UrlHandler && console.log(" - command failed");
            var code = getLxResponseCode(error),
                wasSecured = creds && creds.hasOwnProperty("visuPw"),
                level = error !== "cancel" ? NotificationType.ERROR : NotificationType.WARNING;

            if (code === ResponseCode.SECURED_CMD_FAILED) {
                // if no visu pass has been provided before, don't show an error, simply ask for a visu pass.
                return SandboxComponent.acquireVisuPassword(wasSecured).then(function success(visuPass) {
                    // add/update the visuPw to/of the credObj object & retry.
                    creds.visuPw = visuPass;
                    return this._sendHttpRequest(cmds, hosts, creds, text);
                }.bind(this));
            } else {
                GUI.Notification.createGeneralNotification({
                    title: _('unable-to-send-command'),
                    //subtitle: url,
                    removeAfter: 5
                }, level);
            }
        }

        /**
         * Will notify the user that a command is being sent.
         * @param text     the text to show in the sending notification
         * @returns {*}
         * @private
         */
        _showSendingNotification(text) {
            this._hideSendingNotification(); // show after a timeout to avoid flickering notifications


            this._showSendingTimeout = setTimeout(function () {
                this.sendingNotification = GUI.Notification.createGeneralNotification({
                    title: _('command.sending'),
                    subtitle: text
                }, NotificationType.INFO);
                this._showSendingTimeout = null;
            }.bind(this), 400);
        }

        /**
         * Hides the sending notification.
         * @private
         */
        _hideSendingNotification() {
            this._showSendingTimeout && clearTimeout(this._showSendingTimeout);
            this._showSendingTimeout = null;
            this.sendingNotification && this.sendingNotification.remove();
            this.sendingNotification = null;
        }

        /**
         * Validates if the url is already prefixed by the given scheme.
         * The scheme will be prefixed if the scheme is not part of the url
         * @param scheme
         * @param url
         * @returns {*}
         * @private
         */
        _validateSchemeOfUrl(scheme, url) {
            if (url.indexOf(scheme) !== 0) {
                url = scheme + url;
            }

            return url;
        }

        /**
         * Checks if the control is of type GroupControl and if visuPassword Authentication is needed.
         * The visuPassword is requested if needed and saved to the creds object
         * @param url
         * @param creds
         * @returns {Promise}
         * @private
         */
        _checkCentralControlVisuPw(url, creds) {
            var uriParams = getURIParameters(url),
                affectedControlUuid = UrlHelper.getControlUUIDFromCommandString(uriParams.cmd),
                checked = true; // if it's not a central control, there is nothing to do.
            // Check if the affected is a centralControl and handle secured sub controls

            return PersistenceComponent.getControlOfMiniserver(uriParams.mac, affectedControlUuid).then(function (control) {
                if (control === ControlLoadError.MINISERVER_NOT_AVAILABLE || control === ControlLoadError.CONTROL_NOT_AVAILABLE) {
                    throw _('error') + ": " + _('control.no-longer-exists');
                } // Check if the control is  a centralControl


                if (control.details && control.details.controls) {
                    // if so, look through its controls and check if one of them requires a visu password.
                    checked = this._requestVisuPWForCentralControl(control, creds, uriParams);
                }

                return checked;
            }.bind(this));
        }

        /**
         * Checks the given centralControl and requests the visu password if needed by a subcontrol
         * @param centralControl    the central control including the controls in the details.
         * @param creds             credentials object - the visu pass will be stored here.
         * @param uriParams         url parameter with mac
         * @private
         */
        _requestVisuPWForCentralControl(centralControl, creds, uriParams) {
            var subControlsObjs = centralControl.details.controls,
                // not all controls have details!
                subCtrlPromises;
            subCtrlPromises = [true];
            subControlsObjs.forEach(function (ctrl, idx) {
                subCtrlPromises.push(PersistenceComponent.getControlOfMiniserver(uriParams.mac, ctrl.uuid));
            }); // Iterate over all subControls and require the Visu Pw is needed. Return true if no subControl is given

            return Q.all(subCtrlPromises).then(function (subControls) {
                for (var i = 0; i < subControls.length; i++) {
                    var subCtrl = subControls[i];

                    if (subCtrl.isSecured) {
                        return SandboxComponent.acquireVisuPassword().then(function success(visuPass) {
                            // set the visuPw, so we can use it later
                            creds.visuPw = visuPass;
                        }.bind(this));
                    }
                }

                return true;
            });
        }

    };
});
