'use strict';

define("MeterV2ControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class MeterV2ControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.actualValue = newVals[this.control.states.actual];
            this.states.totalValue = newVals[this.control.states.total];
            this.states.totalDayValue = newVals[this.control.states.totalDay];
            this.states.totalMonthValue = newVals[this.control.states.totalMonth];
            this.states.totalYearValue = newVals[this.control.states.totalYear];
            if (this.control.hasTotalWeek) {
                this.states.totalWeekValue = newVals[this.control.states.totalWeek];
            }
            this.states.isActive = this.states.actualValue !== 0;

            var splitTexts = lxUnitConverter.convertSplitAndApply(this.control.actualFormat, this.states.actualValue, true);
            this.states.actualSuccText = splitTexts.succTxt;
            this.states.actualValueText = splitTexts.valueTxt;
            this.states.actualText = lxUnitConverter.convertAndApply(this.control.actualFormat, this.states.actualValue);
            splitTexts = lxUnitConverter.convertSplitAndApply(this.control.totalFormat, this.states.totalValue, true);
            this.states.totalSuccText = splitTexts.succTxt;
            this.states.totalValueText = splitTexts.valueTxt;
            this.states.totalText = lxUnitConverter.convertAndApply(this.control.totalFormat, this.states.totalValue);

            if (this.control.isBidirectional()) {
                this.states.totalNegValue = newVals[this.control.states.totalNeg];
                this.states.totalNegDayValue = newVals[this.control.states.totalNegDay];
                this.states.totalNegMonthValue = newVals[this.control.states.totalNegMonth];
                this.states.totalNegYearValue = newVals[this.control.states.totalNegYear];
                if (this.control.hasTotalWeek) {
                    this.states.totalNegWeekValue = newVals[this.control.states.totalNegWeek];
                }

                splitTexts = lxUnitConverter.convertSplitAndApply(this.control.totalFormat, this.states.totalNegValue, true);
                this.states.totalNegSuccText = splitTexts.succTxt;
                this.states.totalNegValueText = splitTexts.valueTxt;
                this.states.totalNegText = lxUnitConverter.convertAndApply(this.control.totalFormat, this.states.totalNegValue);
            }
            if (this.control.hasStorage()) {
                this.states.storageValue = newVals[this.control.states.storage];
                splitTexts = lxUnitConverter.convertSplitAndApply(this.control.storageFormat, this.states.storageValue, true);
                this.states.storageSuccText = splitTexts.succTxt;
                this.states.storageValueText = splitTexts.valueTxt;
                this.states.storageText = lxUnitConverter.convertAndApply(this.control.storageFormat, this.states.storageValue);
            }
        }

        _getStorageActualText() {
            let actualText;
            if (this.states.actualValue === 0) {
                // no text to show
            } else if (this.states.actualValue < 0) {
                actualText = _("meter2.storage.charging") + ", " + lxUnitConverter.convertAndApply(this.control.actualFormat, this.states.actualValue * -1);
            } else {
                actualText = _("meter2.storage.delivering") + ", " + lxUnitConverter.convertAndApply(this.control.actualFormat, this.states.actualValue);
            }
            return actualText;
        }

        getStateText() {
            let result;
            if (this.control.hasStorage()) {
                let storageText = this._getStorageActualText();
                return this.states.storageText + (storageText ? (SEPARATOR_SYMBOL + this._getStorageActualText()) : "");

            } else if (this.control.isBidirectional()) {
                return this.states.actualText;
            } else {
                return this.states.actualText + SEPARATOR_SYMBOL + this.states.totalText;
            }
        }

        getStateTextForContent() {
            return null;
        }

        getStateIcon() {
            return this.control.getIcon();
        }

        getStateColor() {
            let color;
            if (this.states.isActive) {
                if (this.control.isBidirectional()) {
                    if (this.states.actualValue < 0) {
                        color = window.Styles.colors.stateActive;
                    } else {
                        color = window.Styles.colors.orange;
                    }
                } else {
                    color = window.Styles.colors.stateActive;
                }
            }
            return color;
        }

    };
});
