'use strict';

define("AutopilotGeneratorStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class AutopilotGeneratorStateContainer extends ControlStateContainer {
        constructor(autopilotGenerator) {
            var control = {
                uuidAction: 'autopilotGenerator',
                type: 'autopilotGenerator',
                states: autopilotGenerator.states
            };
            super(control);
            this.autopilotGenerator = autopilotGenerator;
        }

        prepareStates(newVals) {
            // parse history
            this.states.historyEntries = [];
            var receivedChangeDate = newVals[this.autopilotGenerator.states.changed]; // unix timestamp

            if (!this.states.changeDate || this.states.changeDate < receivedChangeDate) {
                this.states.changeDate = receivedChangeDate;
                Debug.Autopilot.Communication && console.log("received autopilot update " + receivedChangeDate);
                this.states.changed = true;
            } else {
                this.states.changed = false;
            }

            this.states.historyEntries = []; // split text like the tracker does

            if (newVals[this.autopilotGenerator.states.history].text) {
                var entriesArray = newVals[this.autopilotGenerator.states.history].text.split('|');
                var dateEntries = {},
                    entry,
                    entryDate,
                    entryTime,
                    entryTitle,
                    entryUUID,
                    whitespaceIdx;

                for (var i = 0; i < entriesArray.length; i++) {
                    entry = entriesArray[i]; // find out date

                    whitespaceIdx = entry.indexOf(" ");
                    entryDate = entry.slice(0, whitespaceIdx); // slice entry to rest

                    entry = entry.slice(whitespaceIdx + 1, entry.length); // find out time

                    whitespaceIdx = entry.indexOf(" ");
                    entryTime = entry.slice(0, whitespaceIdx); // bugfix if milliseconds are there (10:11:36 normal, 10:11:36.574 buggy)

                    if (entryTime.indexOf(".") !== -1) {
                        entryTime = entryTime.slice(0, entryTime.indexOf("."));
                    }

                    entry = entry.slice(whitespaceIdx + 1, entry.length);
                    whitespaceIdx = entry.indexOf(" ");
                    entryUUID = entry.slice(0, whitespaceIdx);
                    entry = entry.slice(whitespaceIdx + 1, entry.length); // rest is the text

                    entryTitle = entry.slice(0, entry.length);

                    if (!dateEntries[entryDate]) {
                        dateEntries[entryDate] = [];
                    }

                    var timestamp = moment(entryDate + " " + entryTime, "YYYY-MM-DD HH:mm:ss");
                    dateEntries[entryDate].push({
                        time: entryTime,
                        title: entryTitle.trim(),
                        uuid: entryUUID.trim(),
                        timestamp: timestamp
                    });
                }

                var sortedDays = orderObjectByKey(dateEntries, true);
                var dateTextFormat = LxDate.getDateFormat(DateType.DateText);
                var sortedEntries = [];

                for (i = 0; i < sortedDays.length; i++) {
                    var day = sortedDays[i];
                    var sortedDayEntries = orderObjectByValue(dateEntries[day], 'time', true);
                    var formatedDay = moment(day, 'YYYY-MM-DD').format(dateTextFormat).toUpperCase();
                    sortedEntries.push({
                        date: day,
                        formatedDate: formatedDay,
                        entries: sortedDayEntries
                    });
                }

                var shortTextFormat = LxDate.getDateFormat(DateType.Date);
                this.states.lastEntry = {
                    time: moment(sortedEntries[0].date, 'YYYY-MM-DD').format(shortTextFormat) + " " + sortedEntries[0].entries[0].time,
                    text: sortedEntries[0].entries[0].text
                };
                this.states.historyEntries = sortedEntries;
            }
        }

    };
});
