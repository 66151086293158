'use strict';

define("JalousieCentralControlStateContainer", ["ControlCentralStateContainer"], function (ControlCentralStateContainer) {
    return class JalousieCentralControlStateContainer extends ControlCentralStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.nrOfActiveAutopilots = 0;
            this.states.nrOfActiveAutopilots_Sel = 0; // _Sel states are used to calculate only the states for the selected controls

            this.states.allAutopilotsActive = true;
            this.states.allAutopilotsActive_Sel = true;
            this.states.nrOfAutoAllowed = 0;
            this.states.nrOfAutoAllowed_Sel = 0;
            this.states.autopilotsAvailable = false;
            this.states.autopilotsAvailable_Sel = false;
            newVals.forEachState(function (subCtrlNewVals, control, isCtrlSelected) {
                if (subCtrlNewVals.autoActive) {
                    this.states.nrOfActiveAutopilots++;
                    isCtrlSelected && this.states.nrOfActiveAutopilots_Sel++;
                } else {
                    this.states.allAutopilotsActive = false;

                    if (isCtrlSelected) {
                        this.states.allAutopilotsActive_Sel = false;
                    }
                }

                if (subCtrlNewVals.autoAllowed) {
                    this.states.nrOfAutoAllowed++;
                    isCtrlSelected && this.states.nrOfAutoAllowed_Sel++;
                }

                if (control.details.isAutomatic) {
                    this.states.autopilotsAvailable = true;

                    if (isCtrlSelected) {
                        this.states.autopilotsAvailable_Sel = true;
                    }
                }
            }.bind(this));
        }

        getStateText() {
            if (!this.states.autopilotsAvailable) {// return nothing. If someone missuses the block for curtains or sth else, nothing is shown.
            } else if (this.states.allAutopilotsActive) {
                return _('controls.central-shading.sun-pos-shading.all');
            } else if (this.states.nrOfActiveAutopilots === 0) {
                return _('controls.central-shading.sun-pos-shading.none');
            } else {
                return _("controls.central-shading.sun-pos-shading.active", {
                    count: this.states.nrOfActiveAutopilots
                });
            }
        }

        getStateIconSmall() {
            if (this.states.autopilotsAvailable) {
                return {
                    iconSrc: Icon.Jalousie.ComfortMode.AUTO,
                    color: this.states.allAutopilotsActive ? window.Styles.colors.green : null
                };
            } else {// no autopilots. nothing to do.
            }
        }

        getStateTextColor() {
            if (this.states.allAutopilotsActive) {
                return window.Styles.colors.stateActive;
            }
        }

    };
});
