'use strict';

define("EnergyManager2ControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class EnergyManager2ControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            this._didShowIconWarning = false;
        }

        prepareStates(newVals) {
            this.states.gridPower = newVals[this.control.states.Gpwr];
            this.states.storagePower = newVals[this.control.states.Spwr];
            this.states.productionPower = newVals[this.control.states.Ppwr];
            this.states.stateOfCharge = newVals[this.control.states.Ssoc];
            this.states.minStateOfCharge = newVals[this.control.states.MinSoc];
            this.states.maxStorageChargePower = newVals[this.control.states.MaxSpwr];
            this.states.loads = this._prepareLoadObjects(newVals[this.control.states.loads].text);

            // computed values
            this.states.gridImportPower = this.states.gridPower > 0 ? this.states.gridPower : 0;
            this.states.gridExportPower = this.states.gridPower < 0 ? Math.abs(this.states.gridPower) : 0;

            this.states.storageDischargePower = this.states.storagePower > 0 ? this.states.storagePower : 0;
            this.states.storageChargePower = this.states.storagePower < 0 ? Math.abs(this.states.storagePower) : 0;

            this.states.consumptionPower = this.states.gridPower + this.states.storagePower + this.states.productionPower;

            this.states.availablePower = this._getAvailablePower();

            this.states.isProducingPower = this.states.productionPower > 0;
            this.states.isImportingPower = this.states.gridImportPower > 0;
            this.states.hasAvailablePower = this.states.availablePower > 0;

            this.states.selfConsumptionPower = (this.states.productionPower - this.states.availablePower);

            this._detectInternalNotificationInfos();

            if (this.states.productionPower > 0) {
                this.states.selfConsumptionPercentage = Math.round(this.states.selfConsumptionPower / this.states.productionPower * 100);
            } else {
                this.states.selfConsumptionPercentage = 0;
            }


            Debug.Control.EnergyManager && console.log(this.name, "--------------------------------------------");
            Debug.Control.EnergyManager && console.log(this.name, "  Available power = " + this.states.availablePower);
            Debug.Control.EnergyManager && console.log(this.name, "       Grid power = " + this.states.gridPower);
            Debug.Control.EnergyManager && console.log(this.name, " Production power = " + this.states.productionPower);
            Debug.Control.EnergyManager && console.log(this.name, "    Storage power = " + this.states.storagePower);
            Debug.Control.EnergyManager && console.log(this.name, "Consumption power = " + this.states.consumptionPower);
            Debug.Control.EnergyManager && console.log(this.name, "--------------------------------------------");
        }

        getStateText() {
            if (this.states.isProducingPower) {
                return `${this.states.selfConsumptionPercentage}% ${_("energymanager2.self-consumption")}`;
            }
        }

        getStateColor() {
            if (this.states.isProducingPower) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateIcon() {
            return Icon.EnergyManager.ENERGY_MANAGER;
        }

        /**
         * Used by the control to inform the state container that a manual action has been triggered. Will set the
         * manualInteractionTarget temporarily
         * @param loadUuid
         * @param active
         * @param [err]     optional, only provided if the command failed.
         */
        manualInteractionTriggered(loadUuid, active, err) {
            clearTimeout(this.states.manualInteractionTimeout);

            // set the manualInteractionTarget
            this.states.manualInteractionTarget = { loadUuid: loadUuid, active: active, error: err };
            this._detectInternalNotificationInfos();
            this._dispatchInternalStateUpdate();


            this.states.manualInteractionTimeout = setTimeout(() => {
                this.states.manualInteractionTarget = null;
                this._detectInternalNotificationInfos();
                this._dispatchInternalStateUpdate();
            }, 4000);
        }

        // region private methods

        _prepareLoadObjects(loadsText) {
            let newLoads;
            try {
                // loads is an array of objects, each containing the following properties:
                //      prio
                //      uuid
                //      name
                //      id
                //      pwr
                //      active
                newLoads = JSON.parse(loadsText);

                !this._didShowIconWarning && newLoads.forEach((loadObj) => {
                    if (loadObj.hasOwnProperty("icon") || loadObj.hasOwnProperty("iconSrc")) {
                        console.log(this.name, "load " + loadObj.name + " has an icon! ", loadObj);
                    } else {
                        console.warn(this.name, "load " + loadObj.name + " is missing an icon! ", loadObj);
                    }
                });
                this._didShowIconWarning = true;
            } catch (ex) {
                console.error(this.name, "Failed to parse loads list! ", ex);
                newLoads = [];
            }
            return newLoads;
        }

        _detectInternalNotificationInfos() {
            let loadObj;

            if (this.states.manualInteractionTarget) {
                loadObj = this._getLoadObj(this.states.manualInteractionTarget.loadUuid);

                if (this.states.manualInteractionTarget.error) {
                    this.states.notificationText = _("energymanager2.load-manual-interaction-error", {
                        loadName: loadObj.name,
                        state: (loadObj.active ? _("on") : _("off")).toLowerCase()
                    });
                    this.states.notificationColor = window.Styles.colors.red;

                } else {
                    this.states.notificationText = _("energymanager2.load-on-or-off-until-midnight", {
                        loadName: loadObj.name,
                        state: (loadObj.active ? _("on") : _("off")).toLowerCase()
                    });
                }
                this.states.notificationShown = true;

            } else {
                this.states.notificationShown = false;
            }
        }

        _getLoadObj(loadUuid) {
            let loadObj = false;
            Array.isArray(this.states.loads) && this.states.loads.some((currLoadObj) => {
                if (currLoadObj.uuid === loadUuid) {
                    loadObj = currLoadObj;
                    return true;
                }
            })
            return loadObj;
        }

        /**
         * Returns whether or not the load specified by the uuid is currently active.
         * @param loadUuid
         * @returns {boolean}
         * @private
         */
        _getLoadActiveState(loadUuid) {
            let loadObj = this._getLoadObj(loadUuid);
            return loadObj ? loadObj.active : false;
        }

        /**
         * How much power is available to be be "managed".
         * Keeps in mind that some power must be reserved for charging the storage above its minimum level.
         * @returns {number|number}
         * @private
         */
        _getAvailablePower() {
            let availablePower = this.states.gridExportPower;

            if (!this.control.hasStorageStateOfChargeInfo) {
                Debug.Control.EnergyManager && console.warn(this.name, "battery state of charge info unavailable, cannot consider for available energy");

            } else if (this.states.stateOfCharge > this.states.minStateOfCharge) {
                Debug.Control.EnergyManager && console.log(this.name, "battery min storage already reached: " + this.states.minStateOfCharge + ", currently: " + this.states.stateOfCharge);
                availablePower += this.states.storageChargePower;

            } else if (this.control.hasStorageChargePowerInfo) {
                if (this.states.maxStorageChargePower > 0 && this.states.storageChargePower > this.states.maxStorageChargePower) {
                    Debug.Control.EnergyManager && console.warn(this.name, "battery min storage not reached, but storage power may be diverted: " + this.states.minStateOfCharge + ", currently: " + this.states.stateOfCharge);
                    availablePower += (this.states.storageChargePower - this.states.maxStorageChargePower);
                } else {
                    Debug.Control.EnergyManager && console.warn(this.name, "battery min storage not reached yet: " + this.states.minStateOfCharge + ", currently: " + this.states.stateOfCharge);
                }

            } else {
                Debug.Control.EnergyManager && console.warn(this.name, "battery charge power info unavailable, cannot consider for available energy");
            }

            // We don't want to show a negative power usage, thus we cap the available power at a positive productionPower
            return Math.min(availablePower, Math.max(0, this.states.productionPower));
        }

        /**
         * Helper fn that triggers a state udpate to listeners
         * @private
         */
        _dispatchInternalStateUpdate() {
            if (this.hasAllStates) {
                this.version++; // count up version with each update
            }
            this.notifyListener();
        }

        // endregion
    };
});
