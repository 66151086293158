'use strict';

define("HourcounterControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    class HourcounterControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.active = newVals[this.control.states.active] === 1;
            this.states.totalSeconds = newVals[this.control.states.total];
            this.states.total = getTimeObjectFromSeconds(this.states.totalSeconds);
            this.states.activeSinceSeconds = newVals[this.control.states.total] - newVals[this.control.states.lastActivation];
            this.states.activeSince = getTimeObjectFromSeconds(this.states.activeSinceSeconds);
            this.states.hasMaintenanceInterval = newVals[this.control.states.maintenanceInterval] > 0; // TODO check all our state containers that we handle cases like this "else" everywhere!
            // we have to make sure, that the states.xx variables are "valid" at every time

            if (this.states.hasMaintenanceInterval) {
                this.states.maintenanceIntervalSeconds = newVals[this.control.states.maintenanceInterval];
                this.states.maintenanceInterval = getTimeObjectFromSeconds(this.states.maintenanceIntervalSeconds);
                this.states.overdue = newVals[this.control.states.overdue] > 0;
                this.states.overdueSinceSeconds = newVals[this.control.states.overdueSince];
                this.states.overdueSince = getTimeObjectFromSeconds(this.states.overdueSinceSeconds);
                this.states.remainingSeconds = newVals[this.control.states.remaining];
                this.states.remaining = getTimeObjectFromSeconds(this.states.remainingSeconds);
                this.states.remainingPercent = this.states.remainingSeconds / this.states.maintenanceIntervalSeconds;
            } else {
                this.states.maintenanceInterval = getTimeObjectFromSeconds(0);
                this.states.maintenanceIntervalSeconds = 0;
                this.states.overdue = false;
                this.states.overdueSince = getTimeObjectFromSeconds(0);
                this.states.overdueSinceSeconds = 0;
                this.states.remainingSeconds = 0;
                this.states.remaining = getTimeObjectFromSeconds(0);
                this.states.remainingPercent = 0;
            }
        }

        /**
         * Overwrite as a different text is to be used for the content/action-screen then for the card/cell
         * @return {*}
         */
        getStateTextForContent() {
            var str = "";
            str += this.states.active ? _("active") : _("inactive");

            if (this.states.overdue) {
                str += SEPARATOR_SYMBOL + _("controls.hourcounter.maintenance.overdue");
            }

            return str;
        }

        getStateText() {
            var str = "";

            if (this.states.active) {
                str = LxDate.formatSeconds(this.states.activeSinceSeconds);
            } else {
                str = LxDate.formatSeconds(this.states.totalSeconds);
            }

            str = str.replace(/&nbsp;/g, " "); // TODO implement properly!

            return str;
        }

        getStateColor() {
            if (this.states.active) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateTextColor() {// no color..
        }

        getStateIconSmall() {
            if (this.states.overdue) {
                return {
                    iconSrc: "resources/Images/General/wrench.svg",
                    color: window.Styles.colors.red
                };
            }
        }

    }

    var cntr = HourcounterControlStateContainer;
    return cntr;
});
