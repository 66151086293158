'use strict';

define("TrackerControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class TrackerControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            if (newVals[this.control.states.entries].text) {
                var entriesArray = newVals[this.control.states.entries].text.split('|');
                var dateEntries = {},
                    entry,
                    entryDate,
                    entryTime,
                    entryText,
                    whitespaceIdx;

                for (var i = 0; i < entriesArray.length; i++) {
                    entry = entriesArray[i]; // find out date

                    whitespaceIdx = entry.indexOf(" ");
                    entryDate = entry.slice(0, whitespaceIdx); // slice entry to rest

                    entry = entry.slice(whitespaceIdx + 1, entry.length); // find out time

                    whitespaceIdx = entry.indexOf(" ");
                    entryTime = entry.slice(0, whitespaceIdx); // bugfix if milliseconds are there (10:11:36 normal, 10:11:36.574 buggy)

                    if (entryTime.indexOf(".") !== -1) {
                        entryTime = entryTime.slice(0, entryTime.indexOf("."));
                    } // rest is the text


                    entryText = entry.slice(whitespaceIdx + 1, entry.length);

                    if (!dateEntries[entryDate]) {
                        dateEntries[entryDate] = [];
                    }

                    var timestamp = moment(entryDate + " " + entryTime, "YYYY-MM-DD HH:mm:ss");
                    dateEntries[entryDate].push({
                        time: entryTime,
                        text: entryText,
                        timestamp: timestamp
                    });
                }

                var sortedDays = orderObjectByKey(dateEntries, true);
                var dateTextFormat = LxDate.getDateFormat(DateType.DateText);
                var sortedEntries = [];

                for (i = 0; i < sortedDays.length; i++) {
                    var day = sortedDays[i];
                    var sortedDayEntries = orderObjectByValue(dateEntries[day], 'time', true);
                    var formatedDay = moment(day, 'YYYY-MM-DD').format(dateTextFormat).toUpperCase();
                    sortedEntries.push({
                        date: day,
                        formatedDate: formatedDay,
                        entries: sortedDayEntries
                    });
                }

                var shortTextFormat = LxDate.getDateFormat(DateType.Date);
                this.states.lastEntry = {
                    time: moment(sortedEntries[0].date, 'YYYY-MM-DD').format(shortTextFormat) + " " + sortedEntries[0].entries[0].time,
                    text: sortedEntries[0].entries[0].text
                };
                this.states.trackerEntries = sortedEntries;
            } else {
                this.states.trackerEntries = [];
            }
        }

        getStateText() {
            let text;

            if (!!this.states.lastEntry) {
                if (this.control.showLatestDate) {
                    text = this.states.lastEntry.time;
                } else {
                    text = null; // avoids showing the time as subtitle if entries exist. e.g. irrigationcontrol content
                }
            } else {
                text = _("controls.tracker.no-entries");
            }

            return text;
        }
    };
});
