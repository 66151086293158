'use strict';

import CountdownTimer from "../../shared/CountdownTimer";

define("AlarmControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class AlarmControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            this.delayCountdownTimer = new CountdownTimer(0);
            this.delayCountdownTimer.onTick().subscribe(remainingSeconds => {
                this.states.delayedArmRemainingTime = remainingSeconds;
                this.dispatchInternalStateChange()
            });
            /**
             * When the connection is closed, stop the countdown timer so it doesn't become out of sync after the app is resumed
             * Re-establishing the connection will restart the countdown timer as new states are received which triggers prepareStates() to fire
             */
            this.deregister = CompChannel.on(CCEvent.ConnClosed, () => {
                this.delayCountdownTimer.stop();
            });
        }

        destroy() {
            this.deregister(); // remove the event listener when the state container is destroyed
            super.destroy();
        }

        dispatchInternalStateChange() {
            this.version++;
            this.notifyListener();
        }

        prepareStates(newVals) {
            // #####################################################################################
            // This is also called from SmokeAlarmControlStateContainer, because we nearly need the identical states...
            // #####################################################################################
            this.states.armed = newVals[this.control.states.armed] === 1;
            this.states.armedAt = newVals[this.control.states.armedAt];
            this.states.controlUUID = this.control.uuidAction; // this is used in room mode

            this.states.currentAlarmLevel = newVals[this.control.states.level];
            this.states.nextAlarmLevel = newVals[this.control.states.nextLevel];
            this.states.nextAlarmLevelTotalTime = newVals[this.control.states.nextLevelDelayTotal];
            this.states.nextAlarmLevelTimeLeft = newVals[this.control.states.nextLevelDelay]; // TODO-goelzda this is GUI-specific and used only two times, give it away from the state container

            this.states.nextAlarmLevelTimeLeftString = LxDate.formatSecondsShort(this.states.nextAlarmLevelTimeLeft, true);

            if (newVals[this.control.states.startTime]) {
                this.states.alarmFireTimestamp = newVals[this.control.states.startTime].text;
            } else {
                this.states.alarmFireTimestamp = false;
            }

            this._updateAlarmLevel();

            this.states.alarmActive = this.states.currentAlarmLevel > 0 || this.states.nextAlarmLevel > 0;
            this.states.delayedArmTotalTime = newVals[this.control.states.armedDelayTotal];
            this.states.delayedArmTimeLeft = newVals[this.control.states.armedDelay];
            this.states.delayedArmPercent = this.states.delayedArmTimeLeft / this.states.delayedArmTotalTime;
            this.states.disableMove = !!newVals[this.control.states.disabledMove];
            this.states.armedStateSubTitle = "";

            if (this.states.armed) {
                this.states.armedStateTitle = _("controls.alarm.armed");

                if (this.states.disableMove) {
                    this.states.armedStateSubTitle = _("controls.alarm.without.precence-detector");
                } else {
                    this.states.armedStateSubTitle = _("controls.alarm.with.precence-detector");
                }
            } else if (this.states.delayedArmTimeLeft > 0) {
                this.states.armedStateTitle = _("controls.alarm.armed-in");
                this.states.armedStateSubTitle = LxDate.formatSecondsShort(this.states.delayedArmTimeLeft, true);
            } else {
                this.states.armedStateTitle = _("controls.alarm.disarmed");
            } // fix for Miniserver (sending "isArmed" and "delayedArmTimeLeft" > 0...)


            if (this.states.armed) {
                this.states.delayedArmTimeLeft = 0;
                this.states.delayedArmPercent = 0;
            }

            if (!this.states.armed && this.states.armedAt && this.states.delayedArmTotalTime) {
                this.startInternalArmDelayCountdown();
            } else if (this.delayCountdownTimer.isRunning()) {
                this.delayCountdownTimer.stop()
            }

            this._createLastEntries(newVals[this.control.states.sensors], this.states.alarmFireTimestamp); // handle if alarm is active -> show alert


            if (this.isFirstUpdate() && this.states.alarmActive) {
                SandboxComponent.isInAppNotificationSettingTurnedOn(this.control.type).done(function () {
                    NavigationComp.showControlAlert(this.control.uuidAction);
                }.bind(this));
            }
        }

        /**
         * Calculates the remaining delay seconds based on the miniserver date and states.armedAt
         */
        calculateRemainingArmDelay() {
            if (!this.states.armedAt) {
                return this.states.delayedArmTimeLeft;
            }
            const currentTimeStamp = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_DATE_TIME).unix();
            const remainingSeconds = this.states.armedAt - currentTimeStamp;
            return remainingSeconds > 0 ? remainingSeconds : 0;
        }

        /**
         * Starts an internal countdown based on the remaining seconds of the delay calculated based on states.armedAt
         */
        startInternalArmDelayCountdown() {
            if (!this.delayCountdownTimer.isRunning()) {
                const remainingSeconds = this.calculateRemainingArmDelay();
                this.states.delayedArmRemainingTime = remainingSeconds;

                if (remainingSeconds > 0) {
                    this.delayCountdownTimer.setDuration(remainingSeconds, true);
                    this.delayCountdownTimer.start()
                }
            }
        }

        getArmDelayTimeLeft() {
            if (this.states.armedAt) {
                return this.states.delayedArmRemainingTime;
            } else {
                return this.states.delayedArmTimeLeft;
            }
        }

        getStateTextShort() {
            // TODO-goelzda rework the strings in the StateContainers (Alarm,SmokeAlarm)
            if (this.states.alarmActive) {
                return this.states.currentAlarmLevelDesc;
            } else if (this.states.armed) {
                return _("controls.alarm.armed-short").capitalize();
            } else if (this.states.delayedArmTimeLeft > 0) {
                return _("controls.alarm.delayed.armed-in", {
                    timeLeft: LxDate.formatSecondsIntoDigits(this.getArmDelayTimeLeft(), false, true)
                });
            } else {
                return _("controls.alarm.disarmed-short").capitalize();
            }
        }

        getStateText() {
            // TODO-goelzda rework the strings in the StateContainers (Alarm,SmokeAlarm)
            if (this.states.alarmActive) {
                return this.states.currentAlarmLevelLong + SEPARATOR_SYMBOL + this.states.currentAlarmLevelDesc;
            } else if (this.states.armed) {
                let armedText = _("controls.alarm.armed-short").capitalize();
                if (!this.states.disableMove) {
                    armedText += " (" + _("loxone-control.presence-detector") + ")"
                }
                return armedText
            } else if (this.states.delayedArmTimeLeft > 0) {
                return _("controls.alarm.delayed.armed-in", {
                    timeLeft: LxDate.formatSecondsIntoDigits(this.getArmDelayTimeLeft(), false, true)
                });
            } else {
                return _("controls.alarm.disarmed-short").capitalize();
            }
        }

        getStateColor() {
            if (this.states.alarmActive) {
                return null;
            } else if (this.states.armed) {
                return window.Styles.colors.stateActive;
            } else if (this.states.delayedArmTimeLeft > 0) {
                return window.Styles.colors.orange;
            }
        }

        getStateTextColor() {
            // The cell will have a red tint if the alarm is active, so no need to also tint the text
            if (this.states.alarmActive) {
                return "";
            } else if (this.states.armed) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateTintColor() {
            // Only the alarm state should have a tint
            if (this.states.alarmActive) {
                return window.Styles.colors.red;
            }
        }

        getStateIcon() {
            // check if old or new config
            if (this.states.currentAlarmLevel > 0) {
                return Icon.Alarm.ALARM_ICON;
            } else {
                return Icon.Alarm.SHIELD;
            }
        }

        getStateIconSmall() {
            if (this.states.currentAlarmLevel === 0) {
                var iconSrc;

                if (this.states.disableMove) {
                    iconSrc = Icon.Alarm.MOTION_INACTIVE;
                } else {
                    iconSrc = Icon.Alarm.MOTION_ACTIVE;
                }

                return {
                    iconSrc: iconSrc,
                    color: this.getStateColor()
                };
            }

            return null;
        }

        _updateAlarmLevel() {
            if (this.states.currentAlarmLevel > 0) {
                var currAlarmLevelRom = dec2rom(this.states.currentAlarmLevel);
                this.states.currentAlarmLevelShort = _("controls.alarm.level.short") + " " + currAlarmLevelRom;
                this.states.currentAlarmLevelLong = _("controls.alarm.level") + " " + currAlarmLevelRom;
                this.states.currentAlarmLevelDesc = this.control.getDescForLevel(this.states.currentAlarmLevel);
            } else {
                this.states.currentAlarmLevelShort = "";
                this.states.currentAlarmLevelLong = "";
                this.states.currentAlarmLevelDesc = "";
            }

            if (this.states.nextAlarmLevel > 0) {
                var nextAlarmLevelRom = dec2rom(this.states.nextAlarmLevel);
                this.states.nextAlarmLevelShort = _("controls.alarm.level.short") + " " + nextAlarmLevelRom;
                this.states.nextAlarmLevelLong = _("controls.alarm.level") + " " + nextAlarmLevelRom;
                this.states.nextAlarmLevelDesc = this.control.getDescForLevel(this.states.nextAlarmLevel);
            } else {
                this.states.nextAlarmLevelShort = null;
                this.states.nextAlarmLevelLong = null;
                this.states.nextAlarmLevelDesc = null;
            }
        }

        _createLastEntries(sensors, alarmFireTime) {
            this.states.lastEntry = [];

            if (!isNaN(sensors)) {
                sensors = {
                    text: ""
                };
            }

            var sensorTrackerEntries = sensors.text.split("|").reverse();
            alarmFireTime = alarmFireTime ? moment(alarmFireTime.trim(), "YYYY-MM-DD HH:mm:ss") : false;
            var triggeredSensors = [];

            for (var i = 0; i < sensorTrackerEntries.length; i++) {
                var sensorEntry = sensorTrackerEntries[i];
                if (sensorEntry === null || sensorEntry === "") continue;
                var dateParts = sensorEntry.split(" ");
                var dateStr = dateParts[0];
                var timeStr = dateParts[1].split(".")[0];
                var entryTime = moment(dateStr + " " + timeStr, "YYYY-MM-DD HH:mm:ss"); // ignore old tracker entries, if alarm fire time is given by the miniserver

                if (alarmFireTime) {
                    if (entryTime.unix() < alarmFireTime.unix()) {
                        continue;
                    }
                }

                var sensorObj = {
                    date: dateStr,
                    time: timeStr,
                    dateAndTime: dateStr + " " + timeStr,
                    timestamp: entryTime,
                    name: sensorEntry.replace(dateStr + " " + timeStr + " ", "")
                };
                triggeredSensors.push(sensorObj);

                if (triggeredSensors.length >= 2) {
                    break;
                }
            }

            this.states.lastEntry = triggeredSensors;
        }

    };
});
