'use strict';

define("HeatmixerControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class HeatmixerControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.targetValue = newVals[this.control.states.tempTarget];
            this.states.actualValue = newVals[this.control.states.tempActual];
        }

        getStateText() {
            return _('controls.heatmixer.identifier.actual') + " " + lxFormat(this.control.VALUE_FORMAT, this.states.actualValue) + SEPARATOR_SYMBOL + _('controls.heatmixer.identifier.target') + " " + lxFormat(this.control.VALUE_FORMAT, this.states.targetValue);
        }

        getStateTextForContent() {
            return null;
        }

    };
});
