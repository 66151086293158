'use strict';

define("MailBoxControlStateContainer", ["ControlStateContainer", "MailBoxControlEnums"], function (ControlStateContainer, MailBoxControlEnums) {
    return class MailBoxControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.packetReceived = !!newVals[this.control.states.packetReceived];
            this.states.mailReceived = !!newVals[this.control.states.mailReceived];
            this.states.notifDisabledInput = !!newVals[this.control.states.notificationsDisabledInput];
            this.states.notifDisableEndTime = newVals[this.control.states.disableEndTime];
            this.states.notifDisabledTimerActive = this.states.notifDisableEndTime > 0; // either the notifications are disabled via input or via timer, in both cases they're disabled!

            this.states.notifDisabled = this.states.notifDisabledInput || this.states.notifDisabledTimerActive;
            this.states.mailAndPacketReceived = this.states.mailReceived && this.states.packetReceived;
        }

        getStateText() {
            var str = "";

            if (this.states.mailAndPacketReceived) {
                str = _("controls.mailbox.packet-and-mail-received");
            } else if (this.states.packetReceived) {
                str = _("controls.mailbox.packet-received");
            } else if (this.states.mailReceived) {
                str = _("controls.mailbox.mail-received");
            } else {
                str = _("controls.mailbox.empty");
            }

            return str;
        }

        getStateColor() {
            if (this.states.mailReceived || this.states.packetReceived) {
                return window.Styles.colors.stateActive;
            }
        }

        /**
         * return a state icon to be displayed in eg. comfort mode
         * @returns {string|null|undefined}
         */
        getStateIcon() {
            var icon;

            if (this.states.mailAndPacketReceived) {
                icon = Icon.MailBox.MAIL_PACKET;
            } else if (this.states.mailReceived) {
                icon = Icon.MailBox.MAIL;
            } else if (this.states.packetReceived) {
                icon = Icon.MailBox.PACKET;
            } else {
                icon = this._getControlIcon();
            }

            return icon;
        }

        getStateIconSmall() {
            if (this.states.notifDisabled) {
                return {
                    iconSrc: Icon.MailBox.NOTIF_DIS,
                    color: window.Styles.colors.red
                };
            }
        }

        getStateInfo() {
            var info, message;

            if (this.states.notifDisabled) {
                if (this.states.notifDisabledInput) {
                    message = _("controls.mailbox.notifications-disabled.message-input");
                } else if (this.states.notifDisabledTimerActive) {
                    message = _("controls.mailbox.notifications-disabled.message-timed", {
                        endDateTime: this._getEndTimeString(this.states.notifDisableEndTime)
                    });
                }

                info = {
                    title: _("controls.mailbox.notifications-disabled.title"),
                    message: message
                };
            }

            return info;
        }

        /**
         * If its a paket safe device, it'll return a paket safe icon.
         * @returns {string}
         * @private
         */
        _getControlIcon() {
            var icon;

            if (this.control.details.deviceType !== MailBoxControlEnums.DeviceType.NONE) {
                icon = Icon.MailBox.PAKET_SAFE;
            } else {
                icon = Icon.MailBox.DEFAULT_ICON;
            }

            return icon;
        }

        /**
         * Converts seconds from 1.1.2009 to a short date time string
         * @param endDateInSeconds
         * @returns {string}
         * @private
         */
        _getEndTimeString(endDateInSeconds) {
            return LxDate.formatFutureDateDynamic(new LxDate(endDateInSeconds, true), true);
        }

    };
});
