'use strict';
/**
 * This extension will handle all things related to operating modes. It will get and modify a schedule for operating
 * modes. Entries on that schedule can be updated/created or deleted.
 */

import OperatingModeEnums from "OperatingModeEnums";
let {
    OperatingMode,
    CalendarMode,
    WeekdayRepeatMode,
    WeekdayEveryMonth
} = OperatingModeEnums;

ActiveMSComp.factory('OperatingModeExt', function () {
    // internal variables
    let weakThis, activeMsComp, getSchedulesPromise, currentSchedules, resetCacheTimeout;
    var RESET_CACHE_INTERVAL = 60 * 5 * 1000; // Reset it every 5 Minutes - avoids having outdated lists.

    /**
     * c-tor for Operating Mode Ext
     * @param comp reference to the ActiveMSComponent
     * @constructor
     */

    function OperatingModeExt(comp) {
        weakThis = this
        activeMsComp = comp;
        this.name = "OperatingModeExt";
        activeMsComp.on(ActiveMSComp.ECEvent.ConnClosed, this._resetCachedData.bind(this));
        activeMsComp.on(ActiveMSComp.ECEvent.ConnEstablished, this._resetCachedData.bind(this));
    } // public methods

    /**
     * Will update an existing entry or create a new one. It will also cause a reload of the local schedule if successful.
     * @returns {*}
     */


    OperatingModeExt.prototype.saveEntry = function saveEntry(entry) {
        var cmdArgs = [],
            cmd,
            promise;

        if (entry.hasOwnProperty("uuid")) {
            // update it!
            cmdArgs.push(Commands.OPMSchedule.UPDATE);
            VendorHub.Usage.opModeSchedule(FeatureUsage.OperatingMode.UPDATE);
        } else {
            // it's a new one.
            cmdArgs.push(Commands.OPMSchedule.NEW);
            VendorHub.Usage.opModeSchedule(FeatureUsage.OperatingMode.CREATE);
        }

        _pushEntryArgs(cmdArgs, entry);

        cmd = Commands.format.apply(this, cmdArgs);
        promise = this._sendModifyCommand(cmd);
        return promise;
    };
    /**
     * Will delete the entry and if successful, it will reload the local schedule.
     * @returns {*}
     */


    OperatingModeExt.prototype.deleteEntry = function deleteEntry(entry) {
        var cmd;

        if (!entry.hasOwnProperty("uuid")) {
            throw new Error("Cannot delete an entry that does not have a uuid!");
        } else if (!_isDeletable(entry)) {
            throw new Error("That entry mustn't be deleted!");
        }

        cmd = Commands.format(Commands.OPMSchedule.DELETE, entry.uuid);
        VendorHub.Usage.opModeSchedule(FeatureUsage.OperatingMode.DELETE);
        return this._sendModifyCommand(cmd);
    };
    /**
     * Will return the most recent schedules from the Miniserver.
     * @returns {*}
     */


    OperatingModeExt.prototype.getSchedules = function getSchedules() {
        Debug.OperatingMode && console.log(this.name, "getSchedules");
        var promise;

        if (currentSchedules) {
            promise = Q.fcall(function () {
                return currentSchedules;
            });
        } else if (getSchedulesPromise) {
            promise = getSchedulesPromise;
        } else {
            promise = this._requestCurrentSchedules();
        }

        return promise;
    };
    /**
     * Will return an object containing the entries for both heating and cooling. The operating mode number is the key,
     * the entries are the values.
     * These are complete entry objects.
     * @returns {*}
     */


    OperatingModeExt.prototype.getHeatCoolPeriodEntries = function getHeatCoolPeriodEntries() {
        Debug.OperatingMode && console.log(this.name, "getHeatCoolPeriodEntries");
        return this.getSchedules().then(function (schedules) {
            var result = {}; // access the list of entries (the other attributes are passed, active and next).

            schedules.list.forEach(function (entry) {
                switch (entry.operatingMode.nr) {
                    case OperatingMode.HEATING:
                    case OperatingMode.COOLING:
                        result[entry.operatingMode.nr] = entry;
                        entry.start = sprintf("%02d-%02d", entry.startMonth, entry.startDay);
                        entry.end = sprintf("%02d-%02d", entry.endMonth, entry.endDay);
                        break;

                    default:
                        break;
                }
            });
            return result;
        });
    };
    /**
     * Will return an object containing the heating and cooling period starts and ends. The operating mode number is the
     * key, the value objects containt the start- & end-texts.
     * These are NOT entry objects as returned by other methods here.
     * @returns {*}
     */


    OperatingModeExt.prototype.getHeatCoolPeriodSpans = function getHeatCoolPeriodSpans() {
        Debug.OperatingMode && console.log(this.name, "getHeatCoolPeriodSpans");

        var heatPrms = this._send(Commands.OPMSchedule.GET_HEAT, true),
            coolPrms = this._send(Commands.OPMSchedule.GET_COOL, true);

        return Q.all([heatPrms, coolPrms]).spread(function (heatRes, coolRes) {
            Debug.OperatingMode && console.log("Heating and cooling resulted!");
            Debug.OperatingMode && console.log("   HeatRes: " + JSON.stringify(heatRes.LL.value));
            Debug.OperatingMode && console.log("   CoolRes: " + JSON.stringify(coolRes.LL.value));
            var heatPrts = heatRes.LL.value.split("/"),
                coolPrts = coolRes.LL.value.split("/"),
                res = {};
            res[OperatingMode.COOLING] = {
                start: coolPrts[0],
                end: coolPrts[1]
            };
            res[OperatingMode.HEATING] = {
                start: heatPrts[0],
                end: heatPrts[1]
            };
            return res;
        });
    }; // private methods

    /**
     * Will make sure that the data cached locally is being reset.
     */


    OperatingModeExt.prototype._resetCachedData = function _resetCachedData() {
        Debug.OperatingMode && console.log(this.name, "_resetCachedData");
        currentSchedules = null;
        getSchedulesPromise = null; // reset any potential running cache reset timer! important if the connection is closed!

        resetCacheTimeout && clearTimeout(resetCacheTimeout);
    };
    /**
     * resets cache and reloads the data
     */


    OperatingModeExt.prototype._reloadCachedData = function _reloadCachedData() {
        Debug.OperatingMode && console.log(this.name, "_reloadCachedData");

        this._resetCachedData();

        this.getSchedules();
    };
    /**
     * Will download the current schedule. Before resolving it will also make sure the data is processed properly.
     * @returns {*}
     * @private
     */


    OperatingModeExt.prototype._requestCurrentSchedules = function _requestCurrentSchedules() {
        Debug.OperatingMode && console.log(this.name, "_requestCurrentSchedules");
        var entries;
        getSchedulesPromise = this._send(Commands.OPMSchedule.GET).then(function (res) {
            entries = getLxResponseValue(res);

            VendorHub.Usage.opModeSchedule(FeatureUsage.OperatingMode.LIST, null, entries.length);
            currentSchedules = this._processSchedule(entries); // we've got a new schedule. it has been cached - so ensure it will be invalidated after a certain time

            this._startResetCacheTimeout();

            return currentSchedules;
        }.bind(this), null);
        return getSchedulesPromise;
    };
    /**
     * Process schedule
     */


    OperatingModeExt.prototype._processSchedule = function _processSchedule(entries) {
        Debug.OperatingMode && console.log(this.name, "_processSchedule: " + JSON.stringify(entries));
        var currMsDate = ActiveMSComponent.getMiniserverTimeInfo(),
            separated = {
                passed: [],
                active: [],
                next: []
            }; // sanitize each entry.

        entries.forEach(function (entry) {
            this._sanitizeEntry(entry, currMsDate);
        }.bind(this)); // sort them by the date.

        entries.sort(function (a, b) {
            if (a.startObj.isBefore(b.startObj)) {
                return -1;
            } else if (a.startObj.isAfter(b.startObj)) {
                return 1;
            }

            return 0;
        }); // split up into passed and ahead.

        var entry, i;

        for (i = 0; i < entries.length; i++) {
            entry = entries[i];

            if (entry.isPassed) {
                separated.passed.push(entry);
            } else if (entry.isActive) {
                separated.active.push(entry);
            } else {
                separated.next.push(entry);
            }
        } // also store the list-version, aside the split object.


        separated.list = entries;
        return separated;
    };
    /**
     * Will make sure the entries attributes are ready for the UI. E.g. a dateText is being created
     * */


    OperatingModeExt.prototype._sanitizeEntry = function _sanitizeEntry(entry, currDate) {
        // create moment objects for different calender modes.
        if (entry.calMode === CalendarMode.WEEKDAY) {
            entry.startObj = _getNextExecutionDate(entry, currDate);
        } else {
            // easter means there is only an easter offset, not a real date. adopt it.
            if (entry.calMode === CalendarMode.EASTER) {
                _getEasterDate(entry, currDate);
            } // now with the proper date, ensure the moment.js objects are updated.


            _createMomentObjects(entry, currDate);
        } // detect if this entry has already been passed.


        if (!entry.startObj) {
            entry.isPassed = false;
            console.error("The entry " + JSON.stringify(entry) + " has no startObj!");
        } else {
            entry.isPassed = entry.endObj && entry.endObj.isBefore(currDate, "day") || !entry.endObj && entry.startObj.isBefore(currDate, "day");
        } // detect if the entry is recurring.


        entry.isRecurring = _isRecurringEntry(entry); // if a recurring entry is passed, move it to the next year, where it will reoccur

        if (entry.isPassed && entry.isRecurring) {
            _moveToNextYear(entry);
        } // create a pretty date text for this entry.


        entry.dateText = _prettyDate(entry, currDate); // check if the entry is currently active or not.

        entry.isActive = _isActiveEntry(entry, currDate); // resolve the op mode number.

        entry.operatingMode = this._resolveOpMode(entry.operatingMode); // is everything ok with this entry?

        entry.hasError = _hasError(entry);

        if (!entry.hasError) {
            // heating and cooling period entries are handled different (not deletable, not renameable).
            entry.isHeatCool = entry.operatingMode.nr === OperatingMode.HEATING;
            entry.isHeatCool |= entry.operatingMode.nr === OperatingMode.COOLING;
        } // some entries cannot be deleted.


        entry.isDeletable = _isDeletable(entry);
    };
    /**
     * Will return an object containing both the id and the name for the OP mode passed in.
     * @param opModeNr
     * @returns {{nr: *, name: string}}
     * @private
     */


    OperatingModeExt.prototype._resolveOpMode = function _resolveOpMode(opModeNr) {
        var opModes = ActiveMSComponent.getStructureManager().getOperatingModes(true),
            resolved;

        if (opModes.hasOwnProperty(opModeNr)) {
            resolved = {
                nr: opModeNr,
                name: opModes[opModeNr]
            };
            Debug.OperatingMode && console.log(this.name, "_resolveOpMode: " + opModeNr + " = " + JSON.stringify(resolved));
        } else if (opModeNr === OperatingMode.HEATING) {
            resolved = {
                nr: opModeNr,
                name: _("controls.irc.heating.period")
            };
        } else if (opModeNr === OperatingMode.COOLING) {
            resolved = {
                nr: opModeNr,
                name: _("controls.irc.cooling.period")
            };
        } else {
            console.error("The operating mode " + opModeNr + " does not exist!");
            resolved = false;
        }

        return resolved;
    };
    /**
     * Will send the cmd provided to the Miniserver. As soon as it is confirmed, it will trigger reloading the local
     * schedule - this way the next time the user requests it, it is updated.
     * @param cmd
     * @returns {Promise|*}
     * @private
     */


    OperatingModeExt.prototype._sendModifyCommand = function _sendModifyCommand(cmd) {
        return this._send(cmd).then(function (res) {
            // the command was confirmed, clear the local cache data and then reload.
            this._reloadCachedData();

            return res;
        }.bind(this), null);
    };
    /**
     * There is no mechanism notifying an app on a modified operating mode schedule. It's also not important that such
     * a schedule is updated live. That is why the cache is simply cleared after a certain timespan.
     * @private
     */


    OperatingModeExt.prototype._startResetCacheTimeout = function _startResetCacheTimeout() {
        // if there is a timer running already, stop it. (happens if a reload response is received after a modification)
        resetCacheTimeout && clearTimeout(resetCacheTimeout);
        resetCacheTimeout = setTimeout(function () {
            this._resetCachedData(); // don't redownload, the data might not even be needed anymore. would only cause unnecessary traffic.

        }.bind(this), RESET_CACHE_INTERVAL); // The timer will be stopped inside resetCachedData, so a conn loss will stop it too.
    };
    /**
     * Sends the command provided with the operating-mode- or the regular permission level.
     * @param cmd
     * @param regularPermission
     * @returns {*}
     * @private
     */


    OperatingModeExt.prototype._send = function _send(cmd, regularPermission) {
        if (regularPermission) {
            return activeMsComp.send(cmd);
        } else {
            return SandboxComponent.sendWithPermission(cmd, MsPermission.OP_MODES);
        }
    };
    /**
     * Converts an entries date/timespan attributes into a userfriendly and short text.
     * @param entry
     * @param currMsDate
     * @returns {string}
     * @private
     */


    var _prettyDate = function _prettyDate(entry, currMsDate) {
        var date;

        try {
            switch (entry.calMode) {
                case CalendarMode.EASTER:
                case CalendarMode.YEARLY:
                case CalendarMode.SPECIFIC:
                    date = _dateHelper(entry.startDay, entry.startMonth, entry.startYear, DateType.DateText);
                    break;

                case CalendarMode.SPAN_EACH_YEAR:
                case CalendarMode.SPAN_THIS_YEAR:
                    date = _prettyDateSpan(entry);
                    break;

                case CalendarMode.WEEKDAY:
                    date = _prettyWeekDay(entry);
                    break;

                default:
                    date = "-unknown-mode=" + entry.calMode + "-";
                    break;
            }
        } catch (ex) {
            console.error(ex);
            date = "-error-";
        }

        return date;
    };
    /**
     * Returns a pretty timespan string for an entry that is depending on the date of easter.
     * @param entry
     * @param [currDate]    used if not start object exists
     * @returns {string}
     * @private
     */


    var _getEasterDate = function _getEasterDate(entry, currDate) {
        var year = entry.startObj ? entry.startObj.year() : currDate.year(),
            easterDate = LxDate.easter(year),
            adoptedDate = easterDate.add(entry.easterOffset, 'day'),
            splitUp = _parseDateAttrs(adoptedDate); // since we need to sort the entries by the date and we need to know which of them are active or
        // have already been passed this year, we need to assign a startMonth and startDay for the entry.


        entry.startDay = splitUp.day;
        entry.startMonth = splitUp.month;
        entry.startYear = splitUp.year;
    };
    /**
     * Will compute when the next execution of a weekday specific entry is going to be.
     * @param entry
     * @param currDate
     * @private
     */


    var _getNextExecutionDate = function _getNextExecutionDate(entry, currDate) {
        var iDate = cloneObjectDeep(currDate),
            // iteration date
            nextDate = null,
            nthWeekDay,
            startToday = entry.weekDayInMonth === WeekdayRepeatMode.EVERY,
            everyMonth = entry.startMonth === WeekdayEveryMonth,
            backwards = entry.weekDayInMonth === WeekdayRepeatMode.LAST; // identify the how maniest we're looking for and from where.

        switch (entry.weekDayInMonth) {
            case WeekdayRepeatMode.EVERY:
            case WeekdayRepeatMode.LAST:
                // stop at the first occurence
                nthWeekDay = 1;
                break;

            default:
                // for all others, start at the first day of the month, stop at the nth occurence
                nthWeekDay = entry.weekDayInMonth;
                break;
        } // iterate month by month until the helper function returns a date that has not been passed yet.


        while (nextDate === null) {
            if (everyMonth || entry.startMonth === iDate.month() + 1) {
                // if backwards, go to the end of the month - otherwise go to the start.
                if (backwards) {
                    iDate.endOf("month");
                } else if (startToday) {
                    // start with today. The past days are no longer interesting & this method would skip the whole month.
                    iDate.startOf("day");
                    startToday = false; // only do that once. In the next month, start with the first day of the month.
                } else {
                    iDate.startOf("month");
                }

                nextDate = _findWeekDayDateInMonth(entry.weekDay, iDate, nthWeekDay, backwards); // the next execution date has already been passed.

                if (nextDate && nextDate.isBefore(currDate, "day")) {
                    nextDate = null;
                } else if (nextDate) {
                    break;
                }
            }

            iDate.add(1, "month");
        }

        return nextDate;
    };
    /**
     * This function returns a date object representing the nth occurence of a given weekday in a month specified by
     * the date-object.
     * @param dow           day of the week - 0 = monday.
     * @param date          the date from which to start.
     * @param nthWeekDay    e.g. the third friday in that month.
     * @param backwards     when looking for the last friday, backwards will be true.
     * @returns {*}        null if not found
     * @private
     */


    var _findWeekDayDateInMonth = function _findWeekDayDateInMonth(dow, date, nthWeekDay, backwards) {
        var result = null,
            cDow;

        var getIsoWeekDay = function getIsoWeekDay(dt) {
            var wd = dt.day() - 1;
            return wd < 0 ? 6 : wd;
        }; // look for the nth weekday in a given month


        var iDate = cloneObjectDeep(date),
            hitCount = 0; // iterate day by day as long as the month is still the current one and the nthDow isn't reached.

        while (date.month() === iDate.month()) {
            // leaves if the month was passed.
            cDow = getIsoWeekDay(iDate);

            if (cDow === dow) {
                // hit.
                hitCount++;

                if (hitCount === nthWeekDay) {
                    result = cloneObjectDeep(iDate);
                    break;
                }
            }

            iDate.add(backwards ? -1 : 1, 'days');
        }

        return result;
    };
    /**
     * Returns a pretty timespan string for an entry that is depending on the date of easter.
     * @param entry
     * @returns {string}
     * @private
     */


    var _prettyDateSpan = function _prettyDateSpan(entry) {
        var from,
            to,
            showYear = entry.calMode === CalendarMode.SPAN_THIS_YEAR,
            format = showYear ? DateType.DateTextShort : DateType.DayAndMonthShort;
        to = _dateHelper(entry.endDay, entry.endMonth, entry.endYear, format); // avoid returning 24. Feb 2017 - 26. Jun 2017, instead show 24. Feb - 26. Jun 2017

        showYear = showYear && entry.startYear !== entry.endYear;

        if (entry.startMonth === entry.endMonth && !showYear) {
            // don't show 24. Feb - 26.Feb, instead show 24. - 26. Feb
            format = DateType.Day;
        } else if (!showYear) {
            format = DateType.DayAndMonthShort;
        }

        from = _dateHelper(entry.startDay, entry.startMonth, entry.startYear, format);
        return from + " - " + to;
    };
    /**
     * Returns a pretty timespan string for an entry that is depending on the date of easter.
     * @param entry
     * @returns {string}
     * @private
     */


    var _prettyWeekDay = function _prettyWeekDay(entry) {
        return entry.startObj.format(LxDate.getDateFormat(DateType.DateText));
    };
    /**
     * Returns a userfriendly text explaining at what interval a repeating weekday-dependant entry is going to reoccur
     * @param entry
     * @returns {*}
     * @private
     */


    var _getWeekDayRepeatingText = function _getWeekDayRepeatingText(entry) {
        Debug.OperatingMode && console.log("OperatingModeExt: _getWeekDayRepeatingText: " + JSON.stringify(entry)); // moment.js starts the week with 0;

        var dow = (entry.weekDay + 1) % 7;
        var weekday = moment().day(dow).format(DateType.Weekday),
            month,
            text,
            translationArgs;

        if (entry.startMonth === 0) {
            month = _("mobiscroll.year"); // all year long
        } else {
            // moment.js starts the year with 0 = january;
            month = moment().month(entry.startMonth - 1).format(DateType.MonthText);
        }

        translationArgs = {
            month: month,
            weekday: weekday
        };

        switch (entry.weekDayInMonth) {
            case WeekdayRepeatMode.EVERY:
                text = _("opmodes.weekday.every", translationArgs);
                break;

            case WeekdayRepeatMode.FIRST:
                text = _("opmodes.weekday.first", translationArgs);
                break;

            case WeekdayRepeatMode.SECOND:
                text = _("opmodes.weekday.second", translationArgs);
                break;

            case WeekdayRepeatMode.THIRD:
                text = _("opmodes.weekday.third", translationArgs);
                break;

            case WeekdayRepeatMode.FOURTH:
                text = _("opmodes.weekday.fourth", translationArgs);
                break;

            case WeekdayRepeatMode.LAST:
                text = _("opmodes.weekday.last", translationArgs);
                break;
        }

        return text;
    };
    /**
     * Returns a pretty timespan string for an entry that is depending on the date of easter.
     * @param entry
     * @returns {boolean}
     * @private
     */


    var _isRecurringEntry = function _isRecurringEntry(entry) {
        var recurring = false;

        switch (entry.calMode) {
            case CalendarMode.SPAN_EACH_YEAR:
            case CalendarMode.YEARLY:
            case CalendarMode.EASTER:
            case CalendarMode.WEEKDAY:
                recurring = true;
                break;

            default:
                break;
        }

        return recurring;
    };
    /**
     * Converts the given day/month and year to a userfriendly string with the format specified.
     * @param day
     * @param month
     * @param year
     * @param format
     * @returns {*}
     * @private
     */


    var _dateHelper = function _dateHelper(day, month, year, format) {
        var date = _p2d(month) + "-" + _p2d(day),
            inputFormat = "MM-DD";

        if (year) {
            date = year + "-" + date;
            inputFormat = DateType.Norm_Date;
        }

        return moment(date, inputFormat).format(LxDate.getDateFormat(format));
    };

    var _p2d = function _p2d(val) {
        var padded = "" + val;

        if (val < 10) {
            padded = "0" + val;
        }

        return padded;
    };
    /**
     * Will create a moment.js object for each the startDate and (if set) the end date.
     * @param entry
     * @param [currDate]    optional. if provided, it can decide what year the start & end should be in (if not provided)
     * @private
     */


    var _createMomentObjects = function _createMomentObjects(entry, currDate) {
        // ensure the date infos - especially the startYear & endYear - are properly set
        currDate && _isRecurringEntry(entry) && _adoptRecurringDates(entry, currDate);
        entry.startObj = _createDate(entry.startYear, entry.startMonth, entry.startDay);

        if (entry.endMonth) {
            entry.endObj = _createDate(entry.endYear, entry.endMonth, entry.endDay);
        } // validate the new attributes. Especially ranges. the end obj must not be before the start obj


        if (entry.endObj && entry.endObj.isBefore(entry.startObj, "day")) {
            console.error(entry.name + " has an end date before the start date!");
            console.error(JSON.stringify(entry));
        }
    };
    /**
     * Ensures that the year attribute is properly set in these entries.
     * @param entry
     * @param currDate
     * @private
     */


    var _adoptRecurringDates = function _adoptRecurringDates(entry, currDate) {
        Debug.OperatingMode && console.log("OperatingModeExt", "_adoptRecurringDates");
        var cStart, cEnd; // if there is no year defined, simply use the current year - used for sorting.

        if (!entry.startYear) {
            entry.startYear = currDate.year();
        }

        cStart = _createDate(entry.startYear, entry.startMonth, entry.startDay);

        if (entry.endMonth && !entry.endYear) {
            entry.endYear = currDate.year();
            cEnd = _createDate(entry.endYear, entry.endMonth, entry.endDay); // if an entry started last year and is still ongoing, move the start a year back.

            if (cEnd.isBefore(cStart) && currDate.isBefore(cEnd)) {
                Debug.OperatingMode && console.log(entry.name + " started last year and is still ongoing, move the " + "start one year back.");
                entry.startYear = entry.startYear - 1;
                cStart = _createDate(entry.startYear, entry.startMonth, entry.startDay);
            } // end date cannot be before start date.


            if (cEnd.isBefore(cStart)) {
                Debug.OperatingMode && console.log(entry.name + ": end date cannot be before start date.");
                entry.endYear = cEnd.year() + 1;
            }
        }
    };
    /**
     * Will move this entry to the next year.
     * @param entry
     * @private
     */


    var _moveToNextYear = function _moveToNextYear(entry) {
        if (entry.calMode === CalendarMode.EASTER) {
            entry.startObj.add(1, "year");

            _getEasterDate(entry);
        } else {
            entry.startYear++;

            if (entry.endYear) {
                entry.endYear++;
            }
        } // recurring entries can never be "passed"


        entry.isPassed = false; // create new moment objects again.

        _createMomentObjects(entry);
    };
    /**
     * Creates a moment.js date object using the arguments provided
     * @param year
     * @param month
     * @param day
     * @private
     */


    var _createDate = function _createDate(year, month, day) {
        return moment(year + "-" + month + "-" + day, DateType.Norm_Date);
    };
    /**
     * Will return true if the entry passed in is currently active.
     * @param entry
     * @param currDate
     * @private
     */


    var _isActiveEntry = function _isActiveEntry(entry, currDate) {
        // check if the day is today -> important: compare based on the day with isSame!
        var result = entry.startObj.isSame(currDate, "day");

        if (!result && entry.endObj) {
            // if not, make sure the entry is in the right region.
            result = currDate.isAfter(entry.startObj) && currDate.isBefore(entry.endObj) || currDate.isSame(entry.endObj, "day");
        }

        return result;
    };
    /**
     * Will push the arguments needed to create/update an entry onto an array.
     * @param args  an array where to push the arguments of the entry onto.
     * @param entry the entry whos arguments are to be pushed onto the array
     * @private
     */


    var _pushEntryArgs = function _pushEntryArgs(args, entry) {
        entry.hasOwnProperty("uuid") && args.push(entry.uuid);
        args.push(entry.name);
        args.push(entry.operatingMode.nr);
        args.push(entry.calMode);
        args.push(_getCalendarModeArgs(entry));
    };
    /**
     * Will return the arguments for the calendar mode as a string.
     * @param entry         the entry whos calendar mode string we need.
     * @returns {string}    the resulting argument string for the entries calendar mode.
     * @private
     */


    var _getCalendarModeArgs = function getCalendarModeArgs(entry) {
        // if start & end times are being adopted, they are changed in the moment.js object, not the individual attributes.
        _updateDateAttributes(entry);

        var args = [];

        switch (entry.calMode) {
            case CalendarMode.YEARLY:
                args.push(entry.startMonth);
                args.push(entry.startDay);
                break;

            case CalendarMode.EASTER:
                args.push(entry.easterOffset);
                break;

            case CalendarMode.SPECIFIC:
                args.push(entry.startYear);
                args.push(entry.startMonth);
                args.push(entry.startDay);
                break;

            case CalendarMode.SPAN_EACH_YEAR:
                args.push(entry.startMonth);
                args.push(entry.startDay);
                args.push(entry.endMonth);
                args.push(entry.endDay);
                break;

            case CalendarMode.SPAN_THIS_YEAR:
                args.push(entry.startYear);
                args.push(entry.startMonth);
                args.push(entry.startDay);
                args.push(entry.endYear);
                args.push(entry.endMonth);
                args.push(entry.endDay);
                break;

            case CalendarMode.WEEKDAY:
                args.push(entry.startMonth);
                args.push(entry.weekDay);
                args.push(entry.weekDayInMonth);
                break;
        }

        return args.join("/");
    };
    /**
     * This method will make sure that the entries date attributes conform with the calendarMode currently selected.
     * It will convert the moment.js start & end objects to attributes (year, month, date - or offset) so they can be
     * sent to the Miniserver.
     * @param entry
     * @private
     */


    var _updateDateAttributes = function _updateDateAttributes(entry) {
        var newAttrs = {},
            startAttrs = _parseDateAttrs(entry.startObj),
            endAttrs = _parseDateAttrs(entry.endObj);

        switch (entry.calMode) {
            case CalendarMode.YEARLY:
                newAttrs.startMonth = startAttrs.month;
                newAttrs.startDay = startAttrs.day;
                break;

            case CalendarMode.EASTER:
                // when it comes to easter, the start object defines at what date the entry should occur.
                newAttrs.easterOffset = _getEasterOffset(entry.startObj);
                break;

            case CalendarMode.SPECIFIC:
                newAttrs.startYear = startAttrs.year;
                newAttrs.startMonth = startAttrs.month;
                newAttrs.startDay = startAttrs.day;
                break;

            case CalendarMode.SPAN_EACH_YEAR:
                newAttrs.startMonth = startAttrs.month;
                newAttrs.startDay = startAttrs.day;
                newAttrs.endMonth = endAttrs.month;
                newAttrs.endDay = endAttrs.day;
                break;

            case CalendarMode.SPAN_THIS_YEAR:
                newAttrs.startYear = startAttrs.year;
                newAttrs.startMonth = startAttrs.month;
                newAttrs.startDay = startAttrs.day;
                newAttrs.endYear = endAttrs.year;
                newAttrs.endMonth = endAttrs.month;
                newAttrs.endDay = endAttrs.day;
                break;

            case CalendarMode.WEEKDAY:
                // Weekday isn't relying on the start-/endObjects.
                break;
        } // merge the updated attributes onto the entry.


        Object.keys(newAttrs).forEach(function (aid) {
            entry[aid] = newAttrs[aid];
        });
    };
    /**
     * Takes a moment.js object and returns an object that contains the year, month and day of the month.
     * @param momentObj
     * @returns {*}     either the resulting object or null if the momentObj was corrupt/missing
     * @private
     */


    var _parseDateAttrs = function _parseDateAttrs(momentObj) {
        var res = null;

        if (momentObj) {
            res = {};
            res.day = momentObj.date(); // day would be the day of the week, date is the date in month.

            res.month = momentObj.month() + 1; // moment.js starts january with 0

            res.year = momentObj.year();
        }

        return res;
    };
    /**
     * Takes a moment.js object that defines a date within a year and it will return the corresponding offset in days
     * in the year specified by the object passed in.
     * @param momentObj
     * @returns {number}
     * @private
     */


    var _getEasterOffset = function _getEasterOffset(momentObj) {
        var easterOffset,
            easterDate = LxDate.easter(momentObj.year()); // get the diff in days between easter and the desired date (already negative if momentObj is before easter)

        easterOffset = momentObj.diff(easterDate, 'days');
        Debug.OperatingMode && console.log("OperatingModeExt: _getEasterOffset: date: " + momentObj.format(DateType.Date) + " - easter: " + easterDate.format(DateType.Date) + " = " + easterOffset);
        return easterOffset;
    };
    /**
     * Will check if everything is okay with this entry.
     * @param entry         the entry in question
     * @returns {boolean}   true if it can be deleted
     * @private
     */


    var _hasError = function _hasError(entry) {
        var hasError = false; // e.g. if the opMode could not be resolved (does no longer exist) - it's an error!

        if (!entry.operatingMode) {
            hasError = true;
        }

        return hasError;
    };
    /**
     * Will return whether or not that entry can be deleted. E.g. heating and cooling period mustn't be deleted as the
     * IRCs rely on them.
     * @param entry     the entry in question
     * @returns {boolean}   true if it can be deleted
     * @private
     */


    var _isDeletable = function _isDeletable(entry) {
        var deletable = true;

        if (entry.isHeatCool) {
            deletable = false;
        }

        return deletable;
    };

    return OperatingModeExt;
});
