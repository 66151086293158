import WallpaperOptions from "./WallpaperOptions";
import defaultBG from "../../../resources/Images/EcoScreen/wallpaper.png";
import CacheFileHandler from "../../shared/logic/fileAccess/CacheFileHandler";
import PersistentStorageHandler from "../../projectSpecific/logic/storage/PersistentStorageHandler";
import FileRequest from "../../shared/logic/fileAccess/FileRequest";


export default class PairedAppProperties {
    constructor({
                    propsVersion = 0,
                    entryPointLocation = UrlStartLocation.LAST_POSITION,
                    userInteractionOverrun = 60,
                    ambientModeSettings = {},
                    ecoScreenSettings = {},
                    notifications = {},
                    appearance = {}
                }) {
        this._version = propsVersion;
        this._userInteractionOverrun = userInteractionOverrun;
        this._notifications = new PairedNotificationSettings(notifications);
        this._ambientMode = new PairedAmbientModeSettings(ambientModeSettings);
        this._ecoScreenSettings = new PairedEcoScreenSettings(ecoScreenSettings, this._userInteractionOverrun);
        this._entryPointLocation = entryPointLocation;
        this._appearance = new PairedAppAppearanceSettings(appearance)
    }

    get json() {
        return {
            propsVersion: this.version,
            entryPointLocation: this.entryPointLocation,
            userInteractionOverrun: this.userInteractionOverrun,
            ambientModeSettings: this.ambientMode.json,
            notifications: this.notifications.json,
            ecoScreenSettings: this.ecoScreen.json,
            appearance: this.appearance.json
        }
    }

    get notifications() {
        return this._notifications;
    }
    get ambientMode() {
        return this._ambientMode;
    }
    get version() {
        return this._version;
    }
    get entryPointLocation() {
        return this._entryPointLocation;
    }
    get userInteractionOverrun() {
        return this._userInteractionOverrun;
    }
    get ecoScreen() {
        return this._ecoScreenSettings;
    }
    get appearance() {
        return this._appearance;
    }
}

export class PairedEcoScreenSettings {
    constructor({screenSaverAppearance = 0}, userInteractionOverrun) {
        this._appearance = screenSaverAppearance;
        this._screenSaverDelay = userInteractionOverrun;
    }

    get json() {
        return {
            screenSaverAppearance: this.appearance
        }
    }

    get jsonForApp() {
        return {
            showTime: this.showTime,
            timeout: this._screenSaverDelay,
            usePresence: false,
            presenceControls: []
        }
    }

    get appearance() {
        return this._appearance;
    }

    get showTime() {
        // 0 = fully dim, 1 = show date/time/weather
        return (this.appearance === 1)
    }
}

export class PairedAmbientModeSettings {
    constructor({quickAccessControls= [], timeout = 60, wallpaper = { }}) {
        this._quickAccessUuids = quickAccessControls;
        this._timeout = timeout;
        if (timeout === 4294967295) {
            this._timeout = -1; // this will be fixed with config 14.5.12.4
        }
        this._wallpaper = new AmbientModeWallpaperOption(wallpaper);
    }

    get json() {
        return {
            quickAccessControls: this.quickAccessCtrlUuids,
            timeout: this.timeout,
            wallpaper: this.wallpaper.json
        }
    }

    get jsonForApp() {
        return {
            quickAccessControls: this.quickAccessCtrlUuids,
            timeout: this.timeout
        }
    }

    /**
     * An array of UUIDs that should be shown in the ambient quick-access.
     * @returns {*[]}
     */
    get quickAccessCtrlUuids() {
        return this._quickAccessUuids;
    }

    /**
     * Seconds after the end of user interaction, when the ambient mode should re-appear automatically
     * @returns {number} -1...n seconds, -1 = off
     */
    get timeout() {
        return this._timeout;
    }

    get showAsDefault() {
        return this._timeout >= 0;
    }

    /**
     * Returns the object containing either the id or the string to be used.
     * @returns {AmbientModeWallpaperOption}
     */
    get wallpaper() {
        return this._wallpaper;
    }
}

export class AmbientModeWallpaperOption {

    constructor({id, msSrc}) {
        this._id = id;
        this._msSrc = msSrc;

        this._src = null;
    }

    /**
     * Return the JSON just like it was acquired from the ms.
     * @returns {{id, msSrc}}
     */
    get json() {
        return {
            id: this._id,
            msSrc: this._msSrc
        }
    }

    get id() {
        return this._id;
    }

    get src() {
        return this._src;
    }

    /**
     * Used for persisting the device image.
     * @returns {{uri: null}}
     */
    get imageData() {
        return {
            uri: this.src
        }
    }

    /**
     * used for persisting the device image
     * @returns {{translateY: number, translateX: number, scale: number}}
     */
    get sizingData() {
        return  {
            translateX: 0,
            translateY: 0,
            scale: 1
        };
    }

    apply() {
        return (this.src ? Q.resolve() : this.resolveSrc()).then(() => {
            PersistenceComponent.saveAmbientWallpaper(this.imageData, this.sizingData);
        });
    }

    resolveSrc() {
        if (this.id) {
            return WallpaperOptions.get().then(wallpapers => {
                const selected = wallpapers.find(wp => wp.id === this.id);

                if (selected) {
                    this._src = selected.src
                } else {
                    return Q.reject("unavailable");
                }
            }).then(null, err => {
                console.error("AmbientModeWallpaperOption", "default img - failed to acquire selected option src, " + err.message, err);
                this._toDefault();
            });
        } else if (this._msSrc) {
            return MsAmbientWallpaper.get(this._msSrc).then(res => {
                this._src = res;
            }, err => {
                console.error("AmbientModeWallpaperOption", "MS img - failed to acquire user defined wallpaper, " + err.message, err);
                this._toDefault();
            })
        } else {
            console.error("AmbientModeWallpaperOption", "neither a predefined nor a MS-wallpaper!");
        }
        this._toDefault()
        return Q.resolve();
    }

    /**
     * Default imgsrc
     * @private
     */
    _toDefault() {
        this._src = defaultBG;
    }
}

export class PairedNotificationSettings {
    /**
     * Instance to read/modify notification settings.
     * @param settings JSON object, e.g. { "Errors": true, "Wallbox": true }
     */
    constructor(settings) {
        this._settings = settings;
    }

    get json() {
        return this._settings;
    }
}


/**
 * Utility class to acquire and cache the ambient mode wallpaper loaded from the MS.
 */
class MsAmbientWallpaper {

    constructor(src) {
        this.name = "MsAmbientWallpaper-" + src;
        this._msSrc = src;

        this._fileRq = FileRequest.cache(src, DataType.FILE)
        this._cacheFileHandler = new CacheFileHandler(new PersistentStorageHandler());
    }

    static get(src) {

        return new MsAmbientWallpaper(src).get();
    }

    getFromCache() {
        Debug.PairedApp && console.log(this.name, "getFromCache");
        return this._cacheFileHandler.getFile(this._fileRq).then(fileRequest => {
            return fileRequest.data;
        });
    }

    persistToCache(payload) {
        Debug.PairedApp && console.log(this.name, "persistToCache", payload);
        this._fileRq.data = payload;
        return this._cacheFileHandler.saveFile(this._fileRq);
    }

    get() {
        Debug.PairedApp && console.log(this.name, "get");
        return this.getFromCache().then((res) => {
            Debug.PairedApp && console.log(this.name, "get - fromCache");
            return res;
        }, (err) => {
            // not in cache
            Debug.PairedApp && console.log(this.name, "get - download");
            return this.download();
        });
    }

    download() {
        Debug.PairedApp && console.log(this.name, "download");
        const imageLoader = ImageLoader.shared(ImageLoader.DOWNLOADER.DL_SOCK),
            ttl = moment().add(1, "month").unix,
            responseType = ImageLoader.RESPONSE_TYPE.DATA_URI;

        return imageLoader.get(this._msSrc, ttl,responseType ).then(res => {
            Debug.PairedApp && console.log(this.name, "download - responded!", res);
            this.persistToCache(res);
            return res;
        }, err => {
            console.error(this.name, "download - failed!", err);
            return Q.reject(err);
        });
    }
}

export class PairedAppAppearanceSettings {
    constructor({plain = false}) {
        this._plainDesign = plain;
    }

    get json() {
        return {
            plain: this.plainDesign
        }
    }

    get plainDesign() {
        return this._plainDesign;
    }
}

