'use strict';
/**
 * Can send http requests to arbitrary Miniservers.
 */
// ************************************************************************************************
// ******** the results of the individual requests aren't passed back! Implement if needed ********
// ************************************************************************************************

CommunicationComp.factory('LxHttpRequestExt', function () {
    var INITIAL_TIMEOUT = 3 * 1000; // 03 Seconds

    var EXTENDED_TIMEOUT = 10 * 1000; // 10 Seconds

    return class LxHttpRequest extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        /**
         * If the command is known and the permission to send it has been granted, proceed to sending the command,
         * which includes checking the reachability as it may be local or remote and
         * @param cmds      array of cmds to send. maximum = 2 at the moment.
         * @param hosts     hosts array (priorized list of possible hosts)
         * @param credObj     credentials object
         * @private
         */
        sendRequests(cmds, hosts, credObj) {
            if (cmds.length > 2) {
                throw new Error("sendRequests of lxHttpRequests does not support more than 2 cmds as of now!");
            }

            return this._tryToSend(hosts, cmds[0], credObj, INITIAL_TIMEOUT).then(function (resObj) {
                // command successfully sent!
                if (cmds[1]) {
                    // try to send no longer needed, if cmd 1 succeeds, this one will do so too!
                    return this._send(resObj.target, cmds[1], credObj, resObj.timeout);
                }
            }.bind(this), function (err) {
                console.error("Error while sending HttpRequests!");
                throw err; // rethrow
            });
        }

        /**
         * Will try to connect to the host at the position 0 with the timeout provided, if this fails, it will
         * proceed with the other hosts with an increased timeout.
         * @param hosts     an array of hosts to try for sending this command.
         * @param cmd       the command to send
         * @param credObj     the credentials to use for authenticating
         * @param timeout   the timeout to use for the host at 0
         * @private
         */
        _tryToSend(hosts, cmd, credObj, timeout) {
            var nextHosts = cloneObject(hosts);
            return this._send(hosts[0], cmd, credObj, timeout).then(function (succ) {
                // host is the one that successfully responded!
                return succ;
            }, function (err) {
                if (err === "cancel" || nextHosts.length === 1) {
                    // user canceled (presumably by cancelling the visu pw input popup) or no more hosts to try
                    throw err;
                } else {
                    // still some things to try.
                    nextHosts.splice(0, 1);
                    return this._tryToSend(nextHosts, cmd, credObj, EXTENDED_TIMEOUT);
                }
            }.bind(this));
        }

        /**
         * Will try to send the cmd to the target provided. Optionally with a visuPw. It will request a visu pass
         * and retry sending if needed. It will reject if the server could not be reached or the visu pass wasn't
         * provided.
         * @param trgt      the target to send to
         * @param cmd       the cmd to send
         * @param credObj     the credentials to use for authentication
         * @param timeout   what timeout to use for the request
         * @private
         */
        _send(trgt, cmd, credObj, timeout) {
            return this._prepareCommand(trgt, cmd, credObj).then(function (cmdObj) {
                return this._sendRq(trgt, cmdObj, timeout).then(function _rqConfirmed(res) {
                    return {
                        target: trgt,
                        cmd: cmd,
                        result: res
                    };
                });
            }.bind(this));
        }

        /**
         * Sends an ajax request identified by the cmdUrl, with a given timeout. It'll parse the response and if
         * successful, it'll resolve with the data returned or it will reject with the response code (if available).
         * @param host
         * @param cmdObj
         * @param timeout
         * @private
         */
        _sendRq(host, cmdObj, timeout) {
            var def = Q.defer();
            Debug.CommandsHTTP && console.log(this.name, "_sendRq: " + cmdObj.command);
            var cmdUrl = this._prepareHostForHttp(host) + cmdObj.command;
            Debug.Communication && CommTracker.commSent(CommTracker.Transport.HTTP_UNIV, cmdObj.command);
            $.ajax({
                url: cmdUrl,
                type: 'GET',
                dataType: "text",
                // We need this to support Firefox If no dataType is set Firefox tries to parse data. It will fail on plain/text requests
                timeout: timeout,
                success: function (data, succText, responseObj) {
                    try {
                        data = JSON.parse(data);
                    } catch (e) {// Noting to do here everything is handled below
                    }

                    if (cmdObj.encryptionType === EncryptionType.REQUEST_RESPONSE_VAL) {
                        try {
                            data = this._decryptResponse(data, cmdObj.aesKey, cmdObj.aesIV);
                        } catch (e) {
                            def.reject(e);
                        }
                    }

                    if (typeof data === "object" && data.LL) {
                        var code = getLxResponseCode(data);

                        if (code >= 200 && code < 300) {
                            def.resolve(data);
                        } else {
                            def.reject(data);
                        }
                    } else {
                        def.resolve(data);
                    }
                }.bind(this),
                error: function (responseObj, errorText, errorMessage) {
                    Debug.CommandsHTTP && console.log("   error for " + cmdObj.command + "! " + errorText);
                    def.reject();
                }
            });
            return def.promise;
        }

        /**
         * Will resolve with a well defined http request url that can be sent (includes authentication)
         * @param host
         * @param cmd
         * @param credObj
         * @private
         */
        _prepareCommand(host, cmd, credObj) {
            Debug.CommandsHTTP && console.log(this.name, "_prepareCommand: " + host + " - " + cmd);
            host = this._prepareHostForHttp(host);
            return this._createHashesFor(host, credObj.payload, credObj).then(function (hashes) {
                if (hashes[1]) {
                    Debug.CommandsHTTP && console.log(" - adding the visu password..");
                    var uuid = UrlHelper.getControlUUIDFromCommandString(cmd);
                    var cmdWithoutPrefix = UrlHelper.getCommandFromCommandString(cmd);
                    cmd = Commands.format(Commands.CONTROL.SECURED_COMMAND, hashes[1], uuid, cmdWithoutPrefix);
                } // adding credObj


                if (credObj.type === AuthType.PASSWORD) {
                    Debug.CommandsHTTP && console.log(" - adding hash for pw authentication..");
                    cmd = Commands.format(Commands.HTTP_AUTH, cmd, hashes[0]);
                } else if (credObj.type === AuthType.TOKEN) {
                    Debug.CommandsHTTP && console.log(" - adding hash for token based authentication..");
                    cmd = Commands.format(Commands.TOKEN.HTTP_AUTH, cmd, hashes[0], encodeURIComponent(credObj.user));
                } else {
                    console.error("Unsupported auth type " + credObj.type);
                    throw new Error("Unsupported auth type " + credObj.type);
                }

                if (credObj.publicKey) {
                    return getEncryptedCommand(cmd, credObj.publicKey, checkEncryptionTypeForHttp(EncryptionType.REQUEST_RESPONSE_VAL, cmd));
                } else {
                    return {
                        command: cmd,
                        encryptionType: EncryptionType.NONE
                    };
                }
            });
        }

        /**
         * Ensures the host is fit and well defined to be used in an http request.
         * @param host
         * @returns {*}
         * @private
         */
        _prepareHostForHttp(host) {
            if (!host.hasPrefix("http://") && !host.hasPrefix("https://")) {
                host = CommunicationComponent.getRequestProtocol() + host;
            }

            if (!host.hasSuffix("/")) {
                host = host + "/";
            }

            return host;
        }

        /**
         * Will request a salt and create a hash for both the authPayload and the visu password
         * @param host              the host to acquire the visu password from.
         * @param authPayload       either user&pass or the token used to authenticate
         * @param [creds]           the credentials used for this command.
         * @returns {*}
         * @private
         */
        _createHashesFor(host, authPayload, creds) {
            var keys = [];
            keys.push(this._requestKeyAndHash(host, authPayload));

            if (typeof creds.visuPw === "string") {
                if (creds.type === AuthType.TOKEN) {
                    // Debug using: SandboxComponent.handleUrlCommand("loxonecmd://ms?mac=EEE000240011&cmd=jdev/sps/io/0cfae76e-02b5-989c-ffff504f94000000/pulse");
                    keys.push(this._requestUserKeyAndHash(host, creds.user, creds.visuPw));
                } else {
                    keys.push(this._requestKeyAndHash(host, creds.visuPw));
                }
            }

            return Q.all(keys);
        }

        /**
         * Requests a oneTime salt from the host provided & then it will resolve the promise with a hash of the payload.
         * @param host          the host to acquire the salt from
         * @param payload       the payload to hash
         * @param [retryCnt]    an optional retry count --> if is at 3 and the request fails, it rejects.
         * @private
         */
        _requestKeyAndHash(host, payload, retryCnt) {
            Debug.CommandsHTTP && console.log(this.name, "_requestKeyAndHash: from " + host);
            var salt,
                hashAlg = VendorHub.Crypto.getHashAlgorithmForMs();
            return this._sendRq(host, {
                command: Commands.GET_KEY
            }, retryCnt ? retryCnt * 1000 : 1000).then(function (res) {
                salt = getLxResponseValue(res, true);
                return VendorHub.Crypto["Hmac" + hashAlg](payload, "utf8", salt, "hex", "hex");
            }, function (err) {
                if (retryCnt < 3) {
                    if (!retryCnt || retryCnt === 0) {
                        retryCnt = 1;
                    } else {
                        retryCnt++;
                    }

                    this._requestKeyAndHash(host, payload, retryCnt);
                } else {
                    // enough retries - reject it!
                    throw new Error(err);
                }
            }.bind(this));
        }

        /**
         * Requests both a oneTimeSalt and a userSalt specific for the user provided. This is required as newer
         * Miniservers don't store passwords anymore and hence need the same hash they have stored for comparing.
         * @param host          the host to acquire the salt from
         * @param user          the user for which to create a specific hash for
         * @param payload       the payload to hash
         * @param [retryCnt]    an optional retry count --> if is at 3 and the request fails, it rejects.
         * @private
         */
        _requestUserKeyAndHash(host, user, payload, retryCnt) {
            Debug.CommandsHTTP && console.log(this.name, "_requestUserKeyAndHash: from " + host + " for " + user);
            var resObj,
                userSalt,
                oneTimeSalt,
                hashAlg,
                userSpecificHash,
                cmd = Commands.format(Commands.TOKEN.GET_VISUSALT, encodeURIComponent(user));
            return this._sendRq(host, {
                command: cmd
            }, retryCnt ? retryCnt * 1000 : 1000).then(function (res) {
                resObj = getLxResponseValue(res); // retrieve the salts from the result

                oneTimeSalt = resObj.key;
                userSalt = resObj.salt;
                hashAlg = resObj.hashAlg || VendorHub.Crypto.HASH_ALGORITHM.SHA1;
                userSpecificHash = VendorHub.Crypto[hashAlg](payload + ":" + userSalt);
                userSpecificHash = userSpecificHash.toUpperCase();
                return VendorHub.Crypto["Hmac" + hashAlg](userSpecificHash, "utf8", oneTimeSalt, "hex", "hex");
            }, function (err) {
                if (retryCnt < 3) {
                    if (!retryCnt || retryCnt === 0) {
                        retryCnt = 1;
                    } else {
                        retryCnt++;
                    }

                    this._requestUserKeyAndHash(host, user, payload, retryCnt);
                } else {
                    // enough retries - reject it!
                    throw new Error(err);
                }
            }.bind(this));
        }

        /**
         * Will decrypt & parse the response provided using the key and IV provided. If not decryptable it will throw an
         * exception. It will also throw an exception if the result was successfully decrypted but the response code
         * indicates an error.
         * @param response
         * @param aesKey
         * @param aesIV
         * @returns {*}
         */
        _decryptResponse(response, aesKey, aesIV) {
            var code;
            Debug.Encryption && console.log("encrypted response base64: " + response);
            Debug.Encryption && console.log("encrypted response hex: " + CryptoJS.enc.Base64.parse(response).toString(CryptoJS.enc.Hex));
            response = VendorHub.Crypto.aesDecrypt(response, aesKey, aesIV);
            Debug.Encryption && console.log("decrypted response: " + response);
            response = JSON.parse(response);
            code = getLxResponseCode(response);

            if (code >= ResponseCode.OK && code < ResponseCode.BAD_REQUEST) {
                return response;
            } else {
                throw response; // will reject the promise
            }
        }

    };
});
