'use strict';
/**
 * This extension will handle all things needed for searching inside the app. This search can be compared to the
 * global search inside loxone config.
 */
import Icons from "IconLib";

ActiveMSComp.factory('SearchExt', function () {
    // internal variables
    let weakThis,
        activeMsComp,
        controlIndex,
        roomIndex,
        categoryIndex,
        menuIndex,
        archiveIndex,
        trustIndex;

    // Date object used for debugging the timing.
    var date;

    // minimium this weight needs to pass in order to create a hit.
    var MIN_WEIGHT = 10;

    /**
     * c-tor for Operating Mode Ext
     * @param comp reference to the ActiveMSComponent
     * @constructor
     */

    function SearchExt(comp) {
        weakThis = this
        activeMsComp = comp;
        this.name = "SearchExt";
        activeMsComp.on(ActiveMSComp.ECEvent.StopMSSession, this._clearUp.bind(this));
        activeMsComp.on(ActiveMSComp.ECEvent.TRUST_STRUCTURE_UPDATED, this.updateIndex.bind(this));
    } // public methods

    /**
     *
     * @param [showStateFn]     if passed in, this method will be used to present any potential states.
     */


    SearchExt.prototype.updateIndex = function updateIndex(showStateFn) {
        Debug.Search.General && console.log(this.name, "updateIndex");

        this._clearUp();

        this._buildIndex(showStateFn);
    };
    /**
     * Launches a search for a text in
     * @param text              the text to search for
     * @param [location]        where to search for it
     * @param [showStateFn]     if passed in, this method will be used to present any potential states.
     * @returns {{}}        a map with an array of results for each of the search locations
     */


    SearchExt.prototype.searchFor = function searchFor(text, location, showStateFn) {
        Debug.Search.General && console.log(this.name, "searchFor: '" + text + "'");
        var loc = location ? location : SearchLocation.ALL,
            searchAll = loc === SearchLocation.ALL,
            result = {},
            delta;

        if (showStateFn !== this._showStateFn) {
            // a different showStateFn has been used. update the index!
            this._clearUp();

            this._buildIndex(showStateFn);
        }

        var _searchIn = function _searchIn(idx) {
            if (searchAll || loc === idx.location) {
                result[idx.location] = idx.lookup(text); //_printResult(result[idx.location], idx.location);
            }
        };

        date = new Date();

        _searchIn(controlIndex);

        _searchIn(roomIndex);

        _searchIn(categoryIndex);

        _searchIn(menuIndex);

        _searchIn(archiveIndex);

        _searchIn(trustIndex);

        result = SearchUtils.Index.prepareResult(result, MIN_WEIGHT);
        result.keyword = text;
        delta = (new Date().getTime() - date.getTime()) / 1000;

        if (Debug.Search.Timing) {
            console.log(this.name, "searchFor '" + text + "' found " + result.count + " items and took " + delta + "s. " + "Quality: " + result.maxWeight);
            console.log("Best result: " + JSON.stringify(result.bestResult ? result.bestResult.item : null));
        }

        VendorHub.Usage.search(FeatureUsage.Search.RESULTED, text.length + "/t" + delta, result.count);
        return result;
    };
    /**
     * Retuns the number of items indexed. e.g.: Basecamp: >350, Showhome >170, Test-Miniserver >110
     * @param location
     * @returns {number}
     */


    SearchExt.prototype.getIndexSize = function getIndexSize(location) {
        if (!controlIndex) {
            console.warn("No search index in place while getIndexSize was called - created one!");

            this._buildIndex();
        }

        var result = 0,
            _getSize = function _getSize(idx) {
                var size = 0;

                if (location === SearchLocation.ALL || idx.location === location) {
                    size = idx.getSize();
                }

                return size;
            };

        result += _getSize(controlIndex);
        result += _getSize(roomIndex);
        result += _getSize(categoryIndex);
        result += _getSize(menuIndex);
        result += _getSize(archiveIndex);
        result += _getSize(trustIndex);
        return result;
    }; // private methods

    /**
     * Will build up all indexes for the currently active miniserver.
     * @param [showStateFn]     if passed in, this method will be used to present any potential states.
     */


    SearchExt.prototype._buildIndex = function _buildIndex(showStateFn) {
        Debug.Search.General && console.log(this.name, "_buildIndex");
        var allControls = Object.values(ActiveMSComponent.getStructureManager().getSupportedControls() || {}),
            linkedControls = Object.values(ActiveMSComponent.getStructureManager().getLinkedControlsOf(allControls, true) || {}),
            allRooms = Object.values(ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM) || {}),
            allCats = Object.values(ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.CATEGORY) || {}); // store the showStateFn --> so the index isn't recreated on future searches

        this._showStateFn = showStateFn; // controls index

        controlIndex = new SearchUtils.ControlIndex();
        allControls.forEach(function (ctrl) {
            controlIndex.addControl(ctrl);
        }); // as all controls only contains those visible to the user on their own, also add the controls made visible by those.

        linkedControls.forEach(function (ctrl) {
            controlIndex.addControl(ctrl);
        }); // room index

        roomIndex = new SearchUtils.Index(SearchLocation.ROOMS);
        allRooms.forEach(function (room) {
            roomIndex.addItem(room.uuid, room.name, room.name, room);
        }); // category index

        categoryIndex = new SearchUtils.Index(SearchLocation.CATEGORIES);
        allCats.forEach(function (cat) {
            categoryIndex.addItem(cat.uuid, cat.name, cat.name, cat);
        }); // other indexes

        this._prepareMenuIndex(showStateFn);
        this._prepareArchiveIndex(showStateFn);
        this._prepareTrustIndex(showStateFn);

    };

    SearchExt.prototype._clearUp = function _clearUp() {
        Debug.Search.General && console.log(this.name, "_clearUp");

        _destroyIdx(controlIndex);
        _destroyIdx(roomIndex);
        _destroyIdx(categoryIndex);
        _destroyIdx(menuIndex);
        _destroyIdx(archiveIndex);
        _destroyIdx(trustIndex);
    };

    SearchExt.prototype._prepareMenuIndex = function _prepareMenuIndex(showStateFn) {
        var _showState = function _showState(state, details) {
            NavigationComp.navigateBack();
            var animation = AnimationType.MODAL;
            return NavigationComp.showState(state, details, animation);
        }.bind(this);

        var menuItems = SandboxComponent.getMenuItems(this._hasWeather(), _showState);
        menuIndex = this._prepareIndex(SearchLocation.MENU, menuItems);
    };

    SearchExt.prototype._prepareArchiveIndex = function _prepareTrustIndex(showStateFn) {
        let switchToFunction = (ms) => {
            NavigationComp.connectTo(ms);
        }
        let showAboutMS = (ms) => {
            NavigationComp.showState(ScreenState.AboutMiniserver, {
                serialNo: ms.serialNo,
            });
        }

        const trustedMap = {};
        ActiveMSComponent.getTrustedMiniserverList().forEach(trustedMs => {
           trustedMap[trustedMs.serialNo] = true;
        });
        const items = PersistenceComponent.getAllMiniserver(true)
            .filter(archiveMs => {
                // ensure the miniserver isn't shown twice, but preferably in the trusted list. This ensures that when
                // changing miniservers the trustToken is always there as fallback when the stored token is no longer valid!
                return !trustedMap.hasOwnProperty(archiveMs.serialNo);
            })
            .map((ms) => {
            return this._getMsSearchEntry("archive", ms, switchToFunction, showAboutMS, [ms.location]);
        });

        archiveIndex = this._prepareIndex(SearchLocation.ARCHIVE, items);
    };

    SearchExt.prototype._prepareTrustIndex = function _prepareTrustIndex(showStateFn) {
        let switchToFunction = (ms) => {
            ActiveMSComponent.switchToTrustedMiniserver(ms);
        }
        let showAboutMS = (ms) => {
            ActiveMSComponent.showTrustedMiniserverInfo(ms);
        }

        const items = [];
        // We can't use PlatformComponent.isWebInterface() here
        if (PlatformComponent.getPlatformInfoObj().platform !== PlatformType.Webinterface) {
            items.push(...ActiveMSComponent.getTrustedMiniserverList().map((ms) => {
                return this._getMsSearchEntry("trusted", ms, switchToFunction, showAboutMS, ms.trustPath.split(SEPARATOR_SYMBOL));
            }))
        }

        trustIndex = this._prepareIndex(SearchLocation.TRUST, items);
    };

    SearchExt.prototype._getMsSearchEntry = function _getMsSearchEntry(prfx, ms, switchFn, aboutFn, keywords = []) {
        return {
            uuid: prfx + "-" + ms.serialNo,
            action: () => { switchFn(ms); },
            type: window.GUI.TableViewV2.CellType.BUTTON,
            buttonTapped: () => { aboutFn(ms); },
            content: {
                iconSrc: Icon.Menu.MINISERVER,
                title: ms.msName,
                subtitle: ms.trustPath || ms.location,
                clickable: true,
                color: window.Styles.colors.green,
                buttonSrc: Icon.INFO2
            },
            keywords: [ "Miniserver", ms.serialNo, ...keywords ]
        }
    };

    SearchExt.prototype._prepareIndex = function _prepareIndex(loc, items) {
        var idx = new SearchUtils.Index(loc);
        items.forEach(function (item) {
            idx.addItem(item.uuid, item.content.title, null, item); // an item might have a list of keywords using which it can also be looked up.

            item.keywords && item.keywords.forEach(function (keyword) {
                idx.addKeyword(keyword, item.uuid);
            });
        }.bind(this));
        return idx;
    };

    SearchExt.prototype._hasWeather = function _hasWeather() {
        var hasWeather = false,
            weatherStates = SandboxComponent.getStatesForUUID(WEATHER_UUID);

        if (!!weatherStates) {
            hasWeather = weatherStates.states.hasValidData;
        }

        return hasWeather;
    };

    var _destroyIdx = function _destroyIdx(idx) {
        idx && idx.destroy();
    };

    var _printResult = function _printResult(result, loc) {
        var i, txt, resItem;
        debugLog("Search", "Printing search result.. " + result.length + " matches in " + loc);

        for (i = 0; i < 10 && i < result.length; i++) {
            txt = "";
            resItem = result[i];
            resItem.matches.forEach(function (match) {
                txt += "'" + match.word + "' & '" + match.key + "' = " + match.weight + ", ";
            });
            console.log("Search", "   " + (i + 1) + ": " + resItem.weight + " = " + resItem.name + ", " + resItem.description + " (" + txt + ")");
        }
    };

    return SearchExt;
});
/**
 * Where to search or where the results have been found.
 * @type {{CONTROLS: string, ROOMS: string, CATEGORIES: string, FEATURES: string}}
 */

window.SearchLocation = {
    ALL: "all",
    CONTROLS: "Controls",
    ROOMS: "Rooms",
    CATEGORIES: "Categories",
    MENU: "Features",
    MINISERVER_SETTINGS: "MiniserverSettings",
    APP_SETTINGS: "AppSettings",
    TRUST: "Trust",
    ARCHIVE: "Archive"
};
