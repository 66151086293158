'use strict';
import CancelablePromise, { cancelable } from 'cancelable-promise';

SandboxComp.factory('StatisticV2Ext', function () {
    let comp,
        statExtName;

    const StatIndexFileName = "STATISTIC_V2_INDEX.json";
    const TempCacheIntervalSecs = 10; // recheck each 10 seconds

    function StatisticV2Ext(sandboxComp) {
        statExtName = this.name = "StatisticV2Ext";
        comp = sandboxComp;
        this._useDlSocket = false;
        this._isGen2Ms = false;
        this._tempCache = {};
        this._requestQueue = [];
        this._requestMap = {};

        this._connected = false;
        sandboxComp.on(SandboxComp.ECEvent.Reset, () => {
            this._stop(true);
        });
        sandboxComp.on(SandboxComp.ECEvent.ConnReady, (ev, newStructure) => {
            this._start(newStructure);
        });
        sandboxComp.on(SandboxComp.ECEvent.ConnClosed, () => {
            this._stop(true);
        });
    }

    StatisticV2Ext.prototype._stop = function _stop(reset = false) {
        this._stopTempCacheClearer();
        this._pendingRequests ??= {};
        if (this._activeMsSerial && this._cacheIndex) {
            this._persistIndex(true);
        }
        this._connected = false;
        this._processQueueTimeout && clearTimeout(this._processQueueTimeout);
        this._processQueueTimeout = null;
        if (reset) {
            this._requestQueue?.forEach(({requestKey}) => {
                this._requestMap?.[requestKey]?.forEach(({reject}) => {
                    reject("reset");
                });
            });

            this._requestQueue = [];
        }
    }

    StatisticV2Ext.prototype._start = function _start(newStructure) {
        this._connected = true;

        // new miniserver will return data via HTTP-Get, older ones via DL-Socket (not enough TCP sockets available)
        switch (ActiveMSComponent.getMiniserverType()) {
            case window.MiniserverType.MINISERVER_GO:
            case window.MiniserverType.MINISERVER:
                this._isGen2Ms = false;
                break;
            default:
                this._isGen2Ms = true;
                break;
        }

        this._startTempCacheClearer();
        this._prepareCache(newStructure);
        this._processQueue();
    }

    /**
     * Requests a raw statistic containing all datapoints recorded in the timestamp unprocessed
     * @param controlUUID
     * @param fromUnixUtc   unixUtc-timestamp of the first (included) datapoint.
     * @param toUnixUtc     unixUtc-timestamp of the last (included) datapoint.
     * @param groupId       the groups statistic data to be requested
     * @param nValues       how many values are requested (important for parsing the binary result)
     * @param [outputName]  optional, only required if a group needs to be filtered
     * @returns {CancelablePromise<[{vals: number[], ts: number}]>}
     */
    StatisticV2Ext.prototype.getStatisticRaw = function getStatisticRaw({controlUUID, fromUnixUtc, toUnixUtc, groupId, nValues, outputName = ""}) {
        Debug.StatisticV2 && console.log(this.name, "getStatisticRaw");
        let cmd = Commands.format(Commands.StatisticV2.GET, controlUUID, "raw", fromUnixUtc, toUnixUtc, "all", groupId, outputName);

        return this._getDataForCmd(cmd, toUnixUtc, nValues).then(this._verifyResult);
    };

    /**
     * Requests a differential statistic in the requested timespan, with a datapoint for each unit provided
     * @param controlUUID
     * @param fromUnixUtc   unixUtc-timestamp of the first (included) datapoint.
     * @param toUnixUtc     unixUtc-timestamp of the last (included) datapoint.
     * @param dataPointUnit what period of time should the individual datapoints returned cover (hour, day, month, year)
     * @param nValues       how many values are requested (important for parsing the binary result)
     * @param groupId       the groups statistic data to be requested
     * @param [outputName]  optional, only required if a group needs to be filtered
     * @returns {CancelablePromise<[{values: number[], ts: number}]>}
     */
    StatisticV2Ext.prototype.getStatisticDiff = function getStatisticDiff({controlUUID, fromUnixUtc, toUnixUtc, dataPointUnit, groupId, nValues, outputName}) {
        Debug.StatisticV2 && console.log(this.name, "getStatisticDiff");
        // ms requires e.g. 23.2.2023 00:00:00 until 24.2.2023 00:00:00 --> but we use 23.2.2023 23:59:59 internally, as moment.js does so
        let msToUnixUtc = toUnixUtc + 1; // if not, the past energy statstics will always lack data from the end of day
        let cmd = Commands.format(Commands.StatisticV2.GET, controlUUID, "diff", fromUnixUtc, msToUnixUtc, dataPointUnit, groupId, outputName || "");
        verifyDiffDataPointUnit(dataPointUnit);

        return this._getDataForCmd(cmd, msToUnixUtc, nValues).then(this._verifyResult);
    };


    /**
     * Combines the statistics provided into one statistic dataset.
     * E.g. one package with dp for total & totalNeg, the other one only with total.
     *          -> results with one dataset that has summed up the total values of the two and retained the totalNeg of the first.
     * @param statPackages:{data: [{values: number[], ts: number}], header: [{output: string, format: string, title: string}]}[]
     * @returns {CancelablePromise<{data: [{values: number[], ts: number}], header: [{output: string, format: string, title: string}]}>}
     */
    StatisticV2Ext.prototype.combineStatistics = function combineStatistics(statPackages) {
        Debug.StatisticV2 && console.log(this.name, "combineStatistics: " + statPackages.length);
        return cancelable(lxWorker.evalFunction(function combineStatistics(statPackages) {
            return statPackages.reduce(StatisticV2.combineChildNodeStatistics);
        }, [statPackages]));
    };

    /**
     * Returns a CSV string representation of the statPackage provided.
     * @param statPackage
     * @returns {string}
     */
    StatisticV2Ext.prototype.getCsvOfPackage = function getCsvOfPackage(statPackage) {
        const separator = ";";
        let lineList = [],
            lines = [],
            ts;

        // generate header
        lineList.push(_("mobiscroll.date") + "/" + _("mobiscroll.clocktime"));
        statPackage.header.forEach((header) => {
            lineList.push(header.title + " [" + lxSplitFormat(header.format).succTxt.trim() + "]");
        })
        lines.push(lineList.join(separator));

        // generate rows
        statPackage.data.forEach((dataRow) => {
            lineList = [];
            lineList.push(new LxDate((dataRow.ts) * 1000, false).format(DateType.DateAndTimeShort));
            dataRow.values.forEach((value) => {
                lineList.push(lxFormat("%.3f", value));
            })
            lines.push(lineList.join(separator));
        });

        return lines.join("\r\n");
    }

    StatisticV2Ext.prototype._verifyResult = function _verifyResult(res) {
        let cleanedRes;
        if (res && Array.isArray(res)) {
            let didFilter = false;
            cleanedRes = res.filter((entry) => {
                if (!entry) {
                    didFilter = true;
                }
                return !!entry; // ensure no "undefined" datapoints are contained
            });
        } else {
            cleanedRes = res;
        }
        return cleanedRes;
    }


    /**
     * Either returns cached data or downloads from the MS
     * @param cmd           the command to use for the request
     * @param toUnixUtc     required to check if cache should be used
     * @param nValues       required for parsing the response
     * @returns {*}
     * @private
     */
    StatisticV2Ext.prototype._getDataForCmd = function _getDataForCmd(cmd, toUnixUtc, nValues) {
        let rqPromise;

        // check if result has already been received in the past few seconds
        if ((this._tempCache?.[cmd]?.data?.length ?? -1) >= 0) {
            Debug.StatisticV2 && console.log(this.name, "_getDataForCmd - using tempCache!");
            return CancelablePromise.resolve(this._tempCache[cmd].data);
        }

        // check if result has been fully cached already
        if (this._hasCacheFor(cmd)) {
            return this._getFromCache(cmd).then(value => value, (err) => {
                return this._getDataForCmd(cmd, toUnixUtc, nValues);
            });
        }

        // check if request is already pending (and not canceled already while pending --> it won't call finally on
        // promises attached after being cancelled)
        this._pendingRequests ??= {};
        if (cmd in this._pendingRequests && !this._pendingRequests[cmd].isCanceled()) {
            // Wrap it into a new CancelablePromise, so it won't stall once the original pending promise gets canceled
            return new CancelablePromise((resolve, reject, onCancel) => {
                this._pendingRequests[cmd].then(resolve, reject).finally(() => {
                    // Check if the Promise has been canceled
                    if (this._pendingRequests[cmd]?.isCanceled()) {
                        reject("Promise has been canceled!");
                    }
                }, true); // The 'true' is crucial as it tells the finally to be also called when the promise is canceled
            });
        }

        // ensure the communication won't get overloaded (resulting in lock-outs)
        if (Object.keys(this._pendingRequests).length > this._getMaxPendingRequests()) { // enqueue
            return this._enqueueRequest(cmd, toUnixUtc, nValues);
        }

        if (!this._connected) {
            console.warn(this.name, "_getDataForCmd - not connected --> enqueue! " + cmd);
            return this._enqueueRequest(cmd, toUnixUtc, nValues);
        }

        rqPromise = this._send(cmd).then(binResult => {
            Debug.StatisticV2 && console.log(this.name, "_getDataForCmd > data received");
            return parseBinaryResult(binResult, nValues).then(res => {
                res = this._verifyResult(res);
                this._checkAndCache(res, cmd, toUnixUtc);
                this._storeInTempCache(cmd, res);
                return res;
            });
        }, err => {
            console.error(this.name, "_getDataForCmd > failed to load data!" + JSON.stringify(err));
            try {
                // no data should resolve with null - that's okay!
                return this._handleErrorForCmd(err, cmd, toUnixUtc);
            } catch (ex) {
                // go on.
            }
            return Q.reject(err);
        });

        // store promise
        this._pendingRequests[cmd] = cancelable(rqPromise);
        rqPromise.finally(() => {
            delete this._pendingRequests[cmd];

            // ensure that a certain delay is between the request batches. (4 requests, short pause, start again)
            if (!this._processQueueTimeout && this._connected) {
                this._processQueueTimeout = setTimeout(() => {
                    this._processQueueTimeout = null;
                    this._processQueue();
                }, 50);
            }
        });

        return this._pendingRequests[cmd];
    };

    StatisticV2Ext.prototype._handleErrorForCmd = function _handleErrorForCmd(err, cmd, toUnixUtc) {
        if (err === SupportCode.STATISTIC_NO_DATA_AVAILABLE) {
            this._checkAndCache(null, cmd, toUnixUtc);
            return null; // should resolve with null, that's okay!
        } else {
            return CancelablePromise.reject(err);
        }
    };

    StatisticV2Ext.prototype._enqueueRequest = function _enqueueRequest(cmd, toUnixUtc, nValues) {
        this._requestMap ??= {};

        return new CancelablePromise((resolve, reject, onCancel) => {
            let requestKey = `${cmd}${toUnixUtc}/${nValues}`;

            onCancel(() => {
                let requestMapIdx = this._requestMap?.[requestKey]?.findIndex(({requestKey: rKey}) => {
                    return requestKey === rKey;
                }) ?? -1;
                if (requestMapIdx >= 0) {
                    this._requestMap?.[requestKey]?.splice(requestMapIdx, 1);
                }
                Debug.StatisticV2_Queue && console.log(this.name, `onCancel ${cmd} -  ${this._requestQueue.length} in queue - ${this._requestMap?.[requestKey]?.length ?? "empty"} in requestMap`);

                if (!this._requestMap?.[requestKey]?.length) {
                    delete this._requestMap?.[requestKey];

                    let requestQueueIdx = this._requestQueue.findIndex(({requestKey: rKey}) => {
                        return requestKey === rKey;
                    });
                    if (requestQueueIdx >= 0) {
                        this._requestQueue.splice(requestQueueIdx, 1);
                        Debug.StatisticV2_Queue && console.log(this.name, "onCancel, removed from queue - " + cmd + " - " + this._requestQueue.length + " in queue");
                    }
                }
            });

            this._requestMap[requestKey] ??= [];

            this._requestMap[requestKey].push(
                {
                    requestKey,
                    cmd,
                    toUnixUtc,
                    nValues,
                    resolve: value => {
                        resolve(value);
                        if (this._requestMap?.[requestKey]?.length === 0) {
                            delete this._requestMap?.[requestKey];
                        }
                    },
                    reject: e => {
                        reject(e);
                        if (this._requestMap?.[requestKey]?.length === 0) {
                            delete this._requestMap?.[requestKey];
                        }
                    }
                }
            );

            this._requestQueue ??= [];

            // first, check if there is not already a request in the queue.
            let existingIdx = this._requestQueue.findIndex(({requestKey: rKey}) => {
                return requestKey === rKey;
            });
            if (existingIdx >= 0) {
                Debug.StatisticV2_Queue && console.log(this.name, "_enqueueRequest " + cmd + " --> already in queue! idx=" + existingIdx);
                this._requestQueue.unshift(...this._requestQueue.splice(existingIdx, 1));
            } else {
                // if not, add it.
                this._requestQueue.push({
                    cmd,
                    toUnixUtc,
                    nValues,
                    requestKey
                });
            }
            Debug.StatisticV2_Queue && console.log(this.name, `_enqueueRequest ${cmd} - ${this._requestQueue.length} in queue - ${this._requestMap?.[requestKey]?.length} in requestMap`);

        });
    }

    StatisticV2Ext.prototype._processQueue = function _processQueue() {
        Debug.StatisticV2_Queue && console.log(this.name, "_processQueue - currently " + this._requestQueue.length + " in queue, " + Object.keys(this._pendingRequests).length + " pending");
        while (this._requestQueue && this._requestQueue.length > 0 && Object.keys(this._pendingRequests).length <= this._getMaxPendingRequests()) {
            let {
                cmd,
                toUnixUtc,
                nValues,
                requestKey
            } = this._requestQueue.splice(0, 1)[0];

            Debug.StatisticV2_Queue && console.log(this.name, "    dequeued " + requestKey + " - " + this._requestQueue.length + " remaining in queue, pending = " + Object.keys(this._pendingRequests).length);

            this._getDataForCmd(
                cmd,
                toUnixUtc,
                nValues
            ).then(value => {
                this._requestMap?.[requestKey]?.forEach(({resolve}) => {
                    resolve(value);
                });
                delete this._requestMap?.[requestKey];
            }, e => {
                this._requestMap?.[requestKey]?.forEach(({reject}) => {
                    reject(e);
                });
                delete this._requestMap?.[requestKey];
            });
        }
    }

    StatisticV2Ext.prototype._getMaxPendingRequests = function _getMaxPendingRequests() {
        return this._isGen2Ms ? 4 : 1; // on a G1, don't make many requests at once, TCP connections are sparse.
    }

    // region caching

    StatisticV2Ext.prototype._checkAndCache = function _checkAndCache(statisticResult, cmd, toUnixUtc) {
        Debug.StatisticV2 && console.log(this.name, "_checkAndCache " + cmd);

        // only cache responses with data whichs toUnixUtc is already in the past.
        if (ActiveMSComponent.getMiniserverUnixUtcTimestamp() > toUnixUtc) {
            setTimeout(() => {
                this._storeInCache(statisticResult, cmd);
            }, 1);
        } else {
            // don't cache requests that have to be repeated, as new data may have been received
        }
        return statisticResult;
    };

    StatisticV2Ext.prototype._storeInCache = function _storeInCache(statisticResult, cmd) {
        Debug.StatisticV2 && console.log(this.name, "_storeInCache " + cmd + " " + statisticResult.length + " entries");
        saveStatisticData(statisticResult, cmd, this._activeMsSerial).then(() => {
            Debug.StatisticV2 && console.log(this.name, "_storeInCache " + cmd + " ==> done");
            this._addToIndex(cmd);
        }, (err) => {
            Debug.StatisticV2 && console.error(this.name, "_storeInCache " + cmd + " failed! " + JSON.stringify(err));
            this._removeFromIndex(cmd);
        });
    };

    StatisticV2Ext.prototype._hasCacheFor = function _hasCacheFor(cmd) {
        let result = this._cacheIndex && this._cacheIndex.hasOwnProperty(cmd);
        Debug.StatisticV2 && console.log(this.name, "_hasCacheFor: " + cmd + " ==> " + !!result);
        return result;
    };

    StatisticV2Ext.prototype._getFromCache = function _getFromCache(cmd) {
        Debug.StatisticV2 && console.log(this.name, "_getFromCache: " + cmd);
        return cancelable(loadCachedStatisticData(cmd, this._activeMsSerial).then((res) => {
            Debug.StatisticV2 && console.log(this.name, "_getFromCache: " + cmd + " => " + res.length + " entries");
            return res;
        }, (err) => {
            this._removeFromIndex(cmd);
            return CancelablePromise.reject(err);
        }));
    };

    StatisticV2Ext.prototype._prepareCache = function _prepareCache(gotNewStructure) {
        Debug.StatisticV2 && console.log(this.name, "_prepareCache: update statIndex = " + !!gotNewStructure);
        if (gotNewStructure) {
            let newMsSerial = ActiveMSComponent.getActiveMiniserver().serialNo;
            if (this._activeMsSerial !== newMsSerial && this._cacheIndex) {
                this._persistIndex(true);
            }

            if (this._activeMsSerial !== newMsSerial) {
                this._activeMsSerial = newMsSerial;
                this._loadIndex();

                //TODO-woessto: iterate over index & check against loaded structure --> remove everything no longer available
            }
        }
    };

    StatisticV2Ext.prototype._addToIndex = function _addToIndex(cmd) {
        Debug.StatisticV2 && console.log(this.name, "_addToIndex " + cmd);
        this._cacheIndex[cmd] = true;
        this._persistIndex();
    };

    StatisticV2Ext.prototype._removeFromIndex = function _removeFromIndex(cmd) {
        Debug.StatisticV2 && console.log(this.name, "_removeFromIndex " + cmd);
        delete this._cacheIndex[cmd];
        this._persistIndex();
    };

    StatisticV2Ext.prototype._persistIndex = function _persistIndex(force) {
        if (force) {
            Debug.StatisticV2 && console.log(this.name, "_persistIndex " + this._activeMsSerial);
            clearTimeout(this._indexWriteTimeout);
            this._indexWriteTimeout = null;
            saveIndexFile(this._cacheIndex, this._activeMsSerial);

        } else if (this._indexWriteTimeout) {
            // write pending, nothing to do.
        } else {
            this._indexWriteTimeout = setTimeout(() => {
                this._persistIndex(true);
            }, 5000);
        }
    };

    StatisticV2Ext.prototype._loadIndex = function _loadIndex() {
        Debug.StatisticV2 && console.log(this.name, "_loadIndex " + this._activeMsSerial);
        return loadIndexFile(this._activeMsSerial).then((index) => {
            if (typeof index === "string") {
                this._cacheIndex = JSON.parse(index);
            } else if (typeof index === "object") {
                this._cacheIndex = index;
            } else {
                console.warn(this.name, "_loadIndex - responded with a unknown type! " + typeof index);
                this._cacheIndex = {};
            }
        }, (err) => {
            this._cacheIndex = {};
        });
    };

    // region temporary cache - only valid for at most 19 seconds

    StatisticV2Ext.prototype._startTempCacheClearer = function _startTempCacheClearer() {
        clearInterval(this._tempCacheClearer);
        this._tempCacheClearer = setInterval(() => {
            this._checkTempCache();
        }, TempCacheIntervalSecs * 1000);
    };

    StatisticV2Ext.prototype._stopTempCacheClearer = function _stopTempCacheClearer() {
        delete this._tempCache;
        this._tempCache = {};
        clearInterval(this._tempCacheClearer);
        this._tempCacheClearer = null;
    };

    StatisticV2Ext.prototype._storeInTempCache = function _storeInTempCache(cmd, data) {
        this._tempCache[cmd] = {
            data: data,
            ttl: moment().unix() + TempCacheIntervalSecs // caching entries for 10 seconds at most ~19 seconds old data.
        };
    }

    StatisticV2Ext.prototype._checkTempCache = function _checkTempCache() {
        let clearList = [],
            currTs = moment().unix();
        Object.keys(this._tempCache).forEach((cmd) => {
            if (this._tempCache[cmd].ttl < currTs) {
                clearList.push(cmd);
            }
        });
        if (clearList.length > 0) {
            clearList.forEach((cmd) => {
                delete this._tempCache[cmd];
            });
            Debug.StatisticV2 && console.log(this.name, "_checkTempCache cleared " + clearList.length + " entries, remaining: " + Object.keys(this._tempCache).length);
        }
    }

    // endregion

    var getIndexFileName = function getIndexFileName(snr) {
        return snr + "_" + StatIndexFileName;
    }

    var saveIndexFile = function safeIndexFile(index, snr) {
        return comp.saveFile(getIndexFileName(snr), index, DataType.OBJECT);
    }

    var loadIndexFile = function loadIndexFile(snr) {
        return comp.loadFile(getIndexFileName(snr), DataType.OBJECT);
    }

    var getStatDataFileName = function getStatDataFileName(snr, cmd) {
        return snr + "_" + cmd.replaceAll("/", "-");
    }

    var saveStatisticData = function saveStatisticData(data, cmd, snr) {
        return comp.saveFile(getStatDataFileName(snr, cmd), data, DataType.OBJECT);
    };

    var loadCachedStatisticData = function loadCachedStatisticData(cmd, snr) {
        return comp.loadFile(getStatDataFileName(snr, cmd), DataType.OBJECT);
    };

    // endregion


    // region helpers

    /**
     * Sends the command to the miniserver & returns a promise.
     * @param cmd
     * @param [isRetry]
     * @returns {*}
     */
    StatisticV2Ext.prototype._send = function _send(cmd, isRetry = false) {
        let encryptionType = this._isGen2Ms ? EncryptionType.NONE : EncryptionType.REQUEST; // no encryption required via HTTPS, but should be Request when on G1
        let dlPromise;

        Debug.StatisticV2 && console.log(statExtName, "send " + cmd + " - via HTTP!");
        dlPromise = CommunicationComponent.sendViaHTTP(cmd, encryptionType, true);

        return dlPromise.then((res) => {
            if (typeof res === "string") {
                return handleJsonTextResponse(cmd, res);
            } else {
                return res;
            }
        }, (err) => {
            if (!isRetry && this._connected) { // at least retry once, poor connections or installations under high laod tend to fail occasionally.
                Debug.StatisticV2 && console.log(statExtName, "***** initial request failed, retry " + cmd);
                return this._send(cmd, true);
            } else {
                return handleJsonTextResponse(cmd, err);
            }
        });
    }

    var handleJsonTextResponse = function handleJsonTextResponse(cmd, response) {
        let statError = SupportCode.STATISTIC_DOWNLOAD_ERROR;

        if (typeof response === "string") {
            try {
                response = JSON.parse(response);
            } catch (ex) {
                console.error(statExtName, "Failed to parse error response: " + response);
                return Q.reject(statError)
            }
        }
        let responseCode = getLxResponseCode(response),
            responseValue = getLxResponseValue(response, true);

        console.error(statExtName, "send " + cmd + " failed! Code=" + responseCode + ", value: ", responseValue);
        switch (responseCode) {
            case window.ResponseCode.BAD_REQUEST:
                statError = SupportCode.STATISTIC_NO_DATA_AVAILABLE;
                break;
            default:
                break;
        }
        return Q.reject(statError)
    }

    /**
     * Uses the existing async Statistic.Parser to convert these statistics into datapoints
     * @param binRes
     * @param nValues
     * @returns {CancelablePromise<[{values: number[], ts: number}]>}
     */
    var parseBinaryResult = function parseBinaryResult(binRes, nValues) {
        Debug.StatisticV2 && console.log(statExtName, "parseBinaryResult start processing " + binRes.byteLength + " bytes, " + nValues + " per DP");
        return cancelable(Statistic.Parser.asyncParseBinaryStats(binRes, nValues, true).then((parsedData) => {
            Debug.StatisticV2 && console.log(statExtName, "parseBinaryResult succeeded with " + parsedData.length + " datapoints");
            return parsedData
        }, (e) => {
            console.error(statExtName, "parseBinaryResult failed with " + JSON.stringify(e));
            return Q.reject(SupportCode.STATISTIC_PARSE_ERROR);
        }));
    }

    var verifyDiffDataPointUnit = function verifyDiffDataPointUnit(provided) {
        switch (provided) {
            case StatisticV2DiffDataPointUnit.Hour:
            case StatisticV2DiffDataPointUnit.Day:
            case StatisticV2DiffDataPointUnit.Month:
            case StatisticV2DiffDataPointUnit.Year:
                break;
            default:
                console.error(statExtName, "incorrect DataPointUnit provided: " + provided);
                break;
        }
    }

    // endregion


    return StatisticV2Ext;
});
