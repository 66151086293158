'use strict';

define("WindowControlStateContainer", ["ControlStateContainer", "WindowControlEnums"], function (ControlStateContainer, WindowControlEnums) {
    return class WindowControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.position = newVals[this.control.states.position];
            this.states.direction = newVals[this.control.states.direction];
            this.states.lockedReason = newVals[this.control.states.lockedReason].text;
            this.states.isLocked = !!this.states.lockedReason;
        }

        getStateText(short) {
            var stateText = null;

            if (this.states.isLocked) {
                stateText = _("window.blocked");
            } else if (this.states.direction === WindowControlEnums.DIR.NOT_MOVING) {
                stateText = this.states.position === 0 ? _("closed") : _("opened");

                if (this.states.position > 0 && this.states.position < 1) {
                    stateText += " (" + Math.round(this.states.position * 100) + "%)";
                }
            } else {
                stateText = this.states.direction === WindowControlEnums.DIR.CLOSING ? _("close") : _("open");

                if (this.states.position > 0 && this.states.position < 1) {
                    stateText += " (" + Math.round(this.states.position * 100) + "%)";
                }
            }

            return stateText;
        }

        getStateTextColor() {
            return this.states.isLocked ? window.Styles.colors.red : this.getStateColor();
        }

        getStateIcon() {
            return this.states.position === 0 ? Icon.Window.IS_CLOSED : Icon.Window.IS_OPENED;
        }

        getStateColor() {
            return this.states.direction ? window.Styles.colors.stateActive : null;
        }

        getStateIconSmall() {
            var iconObj = null;

            if (this.states.isLocked) {
                iconObj = {
                    iconSrc: Icon.Window.SHIELD,
                    color: window.Styles.colors.red
                };
            } else if (this.states.direction !== WindowControlEnums.DIR.NOT_MOVING) {
                iconObj = this._getSmallStateIconForDir();
            }

            return iconObj;
        }

        getStateInfo() {
            var message;

            if (this.states.isLocked) {
                message = _("window.lock.message") + " ";

                if (this.states.position) {
                    message += _("window.lock.addition.opened", {
                        percentage: Math.round(this.states.position * 100)
                    });
                } else {
                    message += _("window.lock.addition.closed");
                }

                return {
                    title: this.states.lockedReason,
                    message: message
                };
            }
        }

        _getSmallStateIconForDir() {
            var iconObj = {
                color: window.Styles.colors.stateActive
            };

            switch (this.states.direction) {
                case WindowControlEnums.DIR.OPENING:
                    iconObj.iconSrc = Icon.Window.OPEN;
                    break;

                case WindowControlEnums.DIR.CLOSING:
                    iconObj.iconSrc = Icon.Window.CLOSE;
                    break;

                default:
                    iconObj = null;
            }

            return iconObj;
        }

    };
});
