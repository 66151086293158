'use strict';

import PairedAppEnums from "../pairedAppEnums";

ActiveMSComp.factory('UserInteractionExt', function () {
    let weakThis;

    function UserInteractionExt(comp) {
        this.name = "UserInteractionExt";
        this.comp = comp;
        weakThis = this

        this._overrunTime = -1; // not initialized.
        this._activeAfterOverrun = true;
        this._setDeviceActive(false);

        this.comp.on(CCEvent.PairedAppPropertiesChanged, (ev, properties) => {
            Debug.PairedApp && console.log(weakThis.name, "PairedAppPropertiesChanged: overrun=" + properties.userInteractionOverrun);
            this._overrunTime = properties.userInteractionOverrun;
            if (!this._deviceActive) {
                this._startOverrunTimeout();
            }
        });
        this.comp.on(CCEvent.DeviceActivityChanged, (ev, { active = false }) => {
            Debug.PairedApp && console.log(weakThis.name, "DeviceActivityChanged: " + active);
            this._setDeviceActive(active);
        })
    }

    UserInteractionExt.prototype._setDeviceActive = function _setDeviceActive(active = false) {
        Debug.PairedApp && console.log(weakThis.name, "_setDeviceActive: " + active);
        this._deviceActive = active;

        if (active) {
            this._setActiveWithOverrun(true);
            this._stopOverrunTimeout();
        } else {
            this._startOverrunTimeout();
        }
    };

    /**
     * This method is called when the state is to be reported to the outside, e.g. the Miniserver.
     * @param active
     * @private
     */
    UserInteractionExt.prototype._setActiveWithOverrun = function _setActiveWithOverrun(active) {
        if (active !== this._activeAfterOverrun) {
            this._activeAfterOverrun = active;
            Debug.PairedApp && console.log(weakThis.name, "_setActiveWithOverrun: " + active + " --> Notify!");
            this.comp.emit(PairedAppEnums.ECEvent.UserInteractionChanged, { active });
        } else {
            Debug.PairedApp && console.log(weakThis.name, "_setActiveWithOverrun: " + active + " (unchanged)");
        }
    };

    UserInteractionExt.prototype._startOverrunTimeout = function _startOverrunTimeout() {
        Debug.PairedApp && console.log(weakThis.name, "_startOverrunTimeout: " + this._overrunTime);
        this._overrunTimeout && clearTimeout(this._overrunTimeout);
        if (this._overrunTime > 0) {
            this._overrunTimeout = setTimeout(this._overrunPassed.bind(this), this._overrunTime * 1000)
        } else if (this._overrunTime === 0) {
            this._overrunPassed();
        } else {
            // not starting overrun, not defined yet!
        }
    };

    UserInteractionExt.prototype._stopOverrunTimeout = function _stopOverrunTimeout() {
        Debug.PairedApp && console.log(weakThis.name, "_stopOverrunTimeout");
        this._overrunTimeout && clearTimeout(this._overrunTimeout);
        this._overrunTimeout = null;
    }

    UserInteractionExt.prototype._overrunPassed = function _overrunPassed() {
        Debug.PairedApp && console.log(weakThis.name, "_overrunPassed: " + this._overrunTime);
        this._setActiveWithOverrun(false);
    }


    return UserInteractionExt;
});
