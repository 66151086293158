'use strict';

window.Components = function (Components) {
    function InfoScreenComp() {
        this.extensions = {};
        this.name = "InfoScreenComp";
        this.persistentFileName = "displayedFeatures.json"; //Initialize properties of InfoScreenComponent

        this.features = {};
        this._ogFeatureKeys = [];
        Observer.call(this);
        this.extensions.musicServerResolverExt = initExtension(Components.InfoScreenComp.extensions.MusicServerResolverExt, this); //Register for Component-Channel Events

        CompChannel.on(CCEvent.StructureReady, this._structureReady.bind(this, true));
        CompChannel.on(CCEvent.MUSIC_ZONE_CHANGED, this._musicZoneChanged.bind(this)); //Initial load of features available

        this.features = $$APP_FEATURES$$;
        this._ogFeatureKeys = Object.keys(this.features); //remove already displayed features
        //check available features if they have any requirements

        this._removeShownFeatures().finally(function () {
            this._checkRequirements();
            // Simply check that we don't navigate
            let initialNavTimeout = null,
                uiEventReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.UrlUpdate, function (ev, url) {
                initialNavTimeout && clearTimeout(initialNavTimeout);
                initialNavTimeout = setTimeout(() => {
                    // this.displayChangeLog(); --> whish from tom.
                    initialNavTimeout = null;
                    NavigationComp.removeFromUIEvent(uiEventReg);
                }, 250);
            }.bind(this));
        }.bind(this));
    }

    /* Available Extension-Channel-Events */


    InfoScreenComp.prototype.ECEvent = {
        STRUCTURE_READY: "StructureReady",
        MUSIC_ZONE_CHANGED: "MusicZoneChanged",
        MUSICSERVER_FEATURES_UPDATED: "MusicserverFeaturesUpdated"
    };
    /**
     * This is just an Overview what keys have already been used
     * @type {string[]}
     */

    InfoScreenComp.prototype.HISTORIC_FEATURES = ["APPLE_WATCH_FEATURE_1", "APPLE_WATCH_FEATURE_2"];
    /* public methods */

    /**
     * Method to be called when features should be shown to the user -> returns rejection Function of promise, to cancel
     * function resolving if needed
     * @param {Array} featureKeys
     * @param {String} customTitle
     * @param {Boolean} useUnfilteredList
     * @param {Boolean} showPendingFeatures
     * @param {Boolean} forceDisplay
     * @returns {function} Reject-function of pending promise, to dismiss InfoScreen if not longer needed to be shown.
     */

    InfoScreenComp.prototype.displayFeatureSet = function displayFeatureSet(featureKeys, customTitle, useUnfilteredList, showPendingFeatures, forceDisplay) {
        var availableFeatureKeys = [];
        var def;

        if (customTitle === undefined) {
            customTitle = "";
        }

        if (useUnfilteredList === undefined) {
            useUnfilteredList = false;
        }

        if (showPendingFeatures === undefined) {
            showPendingFeatures = false;
        }

        if (forceDisplay === undefined) {
            forceDisplay = false;
        } //Sort out old or already shown keys


        featureKeys.forEach(function (key) {
            if (this.features[key]) {
                availableFeatureKeys.push(key);
            }
        }.bind(this));
        def = Q.defer();

        if (useUnfilteredList || showPendingFeatures) {
            def.resolve();
        } else {
            if (this._checkHasPendingFeatures(availableFeatureKeys)) {
                this.on(this.ECEvent.STRUCTURE_READY, this._checkHasPendingFeatures.bind(this, availableFeatureKeys, def.resolve));
                this.on(this.ECEvent.MUSICSERVER_FEATURES_UPDATED, this._checkHasPendingFeatures.bind(this, availableFeatureKeys, def.resolve));
            } else {
                def.resolve();
            }
        }

        def.promise.then(function () {
            this._finalSortOut(featureKeys, customTitle, useUnfilteredList, showPendingFeatures, forceDisplay);
        }.bind(this, featureKeys, customTitle, useUnfilteredList, showPendingFeatures, forceDisplay), function () {
        });
        return def.reject;
    };
    /**
     * Function to be called when current changelog should be displayed
     * @param forceDisplay
     */


    InfoScreenComp.prototype.displayChangeLog = function displayChangeLog(forceDisplay) {
        if (forceDisplay === undefined) {
            forceDisplay = false;
        }

        var featureList = [],
            bundle,
            customTitle; //Filter out available Changelog-"Features"

        var changeLogKeys = Object.keys(this.features).filter(function (key) {
            return key.startsWith("CHANGELOG");
        });

        if (changeLogKeys.length !== 0) {
            featureList = [];
            changeLogKeys.forEach(function (key) {
                featureList.push(this.features[key]);
            }.bind(this));
            bundle = this._buildFeatureBundle(featureList, changeLogKeys, "Changelog");
            customTitle = "Changelog of Version " + UpdateComp.extensions.appUpdateExt.getCurrentVersion();

            this._showBundle(bundle, forceDisplay, customTitle);
        }
    };
    /**
     * Stores a reference to recently-displayed features to prevent showing them again. Also refreshes features and filteredFeatures properties.
     * @param featureKeys
     */


    InfoScreenComp.prototype.persistFeatures = function persistFeatures(featureKeys) {
        featureKeys.forEach(function (key) {
            delete this.features[key];
        }.bind(this));
        PersistenceComponent.loadFile(this.persistentFileName, DataType.OBJECT).then(function (res) {
            var relevantFeatureKeys = featureKeys;
            res.forEach(function (perstKey) {
                // Only add relevantKeys which are still part of the features file
                if (this._ogFeatureKeys.indexOf(perstKey) !== -1) {
                    relevantFeatureKeys.push(perstKey);
                }
            }.bind(this));
            PersistenceComponent.saveFile(this.persistentFileName, relevantFeatureKeys, DataType.OBJECT).then(function () {
                this._removeShownFeatures().then(function () {
                    this._applyFilters();
                }.bind(this));
            }.bind(this));
        }.bind(this), function () {
            PersistenceComponent.saveFile(this.persistentFileName, featureKeys, DataType.OBJECT).then(function () {
                this._removeShownFeatures().then(function () {
                    this._applyFilters();
                }.bind(this));
            }.bind(this));
        }.bind(this));
    };


    /**
     * used to display features straightaway, no matter where in the application
     * @private
     */
    InfoScreenComp.prototype.displayStaticFeatures = function displayStaticFeatures() {
        var staticFeatures = []; //Display Features straightaway

        var changeLogKeys = Object.keys(this.features).filter(function (key) {
            return key.startsWith("CHANGELOG");
        });

        if (changeLogKeys.length !== 0) {
            staticFeatures.unshift(changeLogKeys[0]);
        }

        this.displayFeatureSet(staticFeatures);
    };

    /* event listeners */

    /**
     * Listens to StructureReady event. Loads features, filtered Features and stores them in the corresponding
     * InfoScreen-Properties
     * @private
     */


    InfoScreenComp.prototype._structureReady = function _structureReady() {
        this._applyFilters();

        this.emit(this.ECEvent.STRUCTURE_READY);
    };
    /**
     * Listens to Component-Channel-Event if MusicZone was changed, emits EC-Event
     * @param event
     * @param serverUUID
     * @private
     */


    InfoScreenComp.prototype._musicZoneChanged = function _musicZoneChanged(event, serverUUID) {
        this.emit(this.ECEvent.MUSIC_ZONE_CHANGED, serverUUID);
    };
    /* private methods */
    /**
     * Initial checking if a feature has any requirements, which needs to be checked
     * @private
     */


    InfoScreenComp.prototype._checkRequirements = function _checkRequirements() {
        var feature = {};
        Object.keys(this.features).forEach(function (key) {
            feature = this.features[key];

            if (feature[Requirements.MINISERVER] || feature[Requirements.MUSICSERVER] || feature[Requirements.ENVIRONMENT]) {
                feature.state = FeatureCheckStates.PENDING;
            } else {
                feature.state = FeatureCheckStates.RESOLVED;
            }
        }.bind(this));
    };
    /**
     * called when all features which a screen wants to display are resolved
     * @param featureKeys
     * @param customTitle
     * @param useUnfilteredList
     * @param showPendingFeatures
     * @param forceDisplay
     * @private
     */


    InfoScreenComp.prototype._finalSortOut = function _finalSortOut(featureKeys, customTitle, useUnfilteredList, showPendingFeatures, forceDisplay) {
        var additionalKeys = [],
            featuresToDisplay = [],
            displayFeatureKeys = [],
            bundle = {},
            filteredVariants;
        featureKeys.forEach(function (key) {
            if (this.features[key] && (useUnfilteredList || this._isFeatureAvailable(key, showPendingFeatures))) {
                this.features[key].key = key;
                featuresToDisplay.push(this.features[key]);
                displayFeatureKeys.push(key);
            }
        }.bind(this));

        if (featuresToDisplay.length !== 0) {
            //Filter out variants, to only display one of them
            additionalKeys = [];
            filteredVariants = this._filterVariants(featuresToDisplay, displayFeatureKeys);
            featuresToDisplay = filteredVariants[0];
            additionalKeys = filteredVariants[1]; //Add variant keys which also have to be persisted

            displayFeatureKeys = displayFeatureKeys.concat(additionalKeys);
            bundle = this._buildFeatureBundle(featuresToDisplay, displayFeatureKeys, customTitle);

            this._showBundle(bundle, forceDisplay, customTitle);
        }
    };
    /**
     * Returns true/false if Feature is available for display
     * @param key
     * @param showPendingFeatures
     * @returns {boolean} should be displayed
     * @private
     */


    InfoScreenComp.prototype._isFeatureAvailable = function _isFeatureAvailable(key, showPendingFeatures) {
        var display = false;

        if (this.features[key].state === FeatureCheckStates.RESOLVED || showPendingFeatures && this.features[key].state === FeatureCheckStates.PENDING) {
            display = true;
        }

        return display;
    };
    /**
     * check if any of the features currently have the state "pending"
     * @param featureKeys
     * @param resolve: resolver Function for pending Promise
     * @returns {boolean}
     * @private
     */


    InfoScreenComp.prototype._checkHasPendingFeatures = function _checkHasPendingFeatures(featureKeys, resolve) {
        var pending = false;
        featureKeys.forEach(function (key) {
            if (this.features[key].state !== "undefined") {
                if (this.features[key].state === FeatureCheckStates.PENDING) {
                    pending = true;
                }
            } else {
                pending = true;
            }
        }.bind(this));

        if (!pending && resolve) {
            resolve();
        }

        return pending;
    };
    /**
     * Removes Features of current feature Property based on features which have already been shown to the user/tester
     * @returns {Promise}
     * @private
     */


    InfoScreenComp.prototype._removeShownFeatures = function _removeShownFeatures() {
        return PersistenceComponent.loadFile(this.persistentFileName, DataType.OBJECT).then(function (res) {
            res.forEach(function (featureKey) {
                if (this.features[featureKey]) {
                    delete this.features[featureKey];
                }
            }.bind(this));
            var featureKeys = Object.keys(this.features); // Also check if historic keys are used

            this.HISTORIC_FEATURES.forEach(function (historicKey) {
                if (featureKeys.indexOf(historicKey) !== -1) {
                    console.warn(this.name, "Did find historic key '" + historicKey + "' in feature file. Don't reuse this key!");
                    delete this.features[historicKey];
                }
            }.bind(this));
        }.bind(this));
    };
    /**
     * Filters out features which do not meet requirements to the system, and stores result in the filteredFeatures-Property
     * @private
     */


    InfoScreenComp.prototype._applyFilters = function _applyFilters() {
        var hasRequirements, needsFurtherChecking, featureKey, feature;

        for (featureKey in this.features) {
            feature = this.features[featureKey];
            hasRequirements = true;
            needsFurtherChecking = false;

            if (feature[Requirements.MINISERVER]) {
                feature[Requirements.MINISERVER].forEach(function (featureID) {
                    if (!Feature[featureID]) {
                        hasRequirements = false;
                    }
                }.bind(this));
            }

            if (hasRequirements && feature[Requirements.MUSICSERVER]) {
                //Currently checks if Musicserver is available
                if (typeof ActiveMSComponent.getStructureManager().getMediaServerSet() === 'undefined') {
                    hasRequirements = false;
                } else {
                    needsFurtherChecking = true;
                }
            }

            if (hasRequirements && feature[Requirements.ENVIRONMENT]) {
                if (Array.isArray(feature[Requirements.ENVIRONMENT]) && feature[Requirements.ENVIRONMENT].length) {
                    if (!PlatformComponent.evaluateRequirementsForFeature(feature[Requirements.ENVIRONMENT])) {
                        hasRequirements = false;
                    }
                }
            }

            if (hasRequirements) {
                if (needsFurtherChecking) {
                    this.features[featureKey].state = FeatureCheckStates.PENDING;
                } else {
                    this.features[featureKey].state = FeatureCheckStates.RESOLVED;
                }
            } else {
                this.features[featureKey].state = FeatureCheckStates.UNRESOLVED;
            }
        }
    };
    /**
     * Filters out ocurring variants of features, to only display one of them
     * @param features
     * @param displayFeatureKeys
     * @returns {[]}
     * @private
     */


    InfoScreenComp.prototype._filterVariants = function _filterVariants(features, displayFeatureKeys) {
        var featureKeys = Object.keys(this.features);
        var additionalKeys = [],
            filteredFeatures = [],
            searchKeyArr = [];
        var i, key, searchKey, variants, variantKeys;

        for (i = 0; i < features.length; i++) {
            if (features[i].hasVariants) {
                key = features[i].key.split("_");
                key.pop();
                searchKey = key.join("_");

                if (!searchKeyArr.includes(searchKey)) {
                    searchKeyArr.push(searchKey);
                    variants = features.filter(function (obj) {
                        return obj.key.startsWith(searchKey);
                    });
                    variants.sort(function (a, b) {
                        return a.key > b.key ? 1 : -1;
                    }); //Show feature with highest priority available

                    if (!filteredFeatures.includes(variants[0])) {
                        filteredFeatures.push(variants[0]);
                    } //get the keys of variants to be persisted, to prevent showing them to the user,
                    //but not if the key is already in the persisting array


                    variantKeys = featureKeys.filter(function (key) {
                        return key.startsWith(searchKey) && !displayFeatureKeys.includes(key);
                    });
                    additionalKeys = additionalKeys.concat(variantKeys);
                }
            } else {
                filteredFeatures.push(features[i]);
            }
        }

        return [filteredFeatures, additionalKeys];
    };
    /**
     * Internal function for actually calling the NavigationComp to display the InfoScreen
     * @param bundle
     * @param forceDisplay
     * @param customTitle
     * @private
     */


    InfoScreenComp.prototype._showBundle = function _showBundle(bundle, forceDisplay, customTitle) {
        var shouldDisplay;

        if (!forceDisplay) {
            //Check if an notification already exists
            shouldDisplay = true;

            if (this._featureInfoNotification) {
                if (this._featureInfoNotification.isVisible()) {
                    shouldDisplay = false;
                }
            }

            if (shouldDisplay) {
                this._featureInfoNotification = GUI.Notification.createGeneralNotification({
                    title: customTitle !== "" ? customTitle : _("infoscreen.new-in-version", {
                        version: UpdateComp.extensions.appUpdateExt.getCurrentVersion()
                    }),
                    clickable: true,
                    closeable: true
                }, NotificationType.INFO);

                this._featureInfoNotification.on(GUI.Notification.CLICK_EVENT, function () {
                    this._featureInfoNotification.remove();

                    NavigationComp.showState(ScreenState.InfoScreen, bundle);
                }.bind(this));

                this._featureInfoNotification.on(GUI.Notification.MARK_AS_READ_EVENT, function () {
                    this.persistFeatures(bundle.featureKeys);
                }.bind(this));
            }
        } else {
            NavigationComp.showState(ScreenState.InfoScreen, bundle);
        }
    };
    /**
     * Creates Bundle out of a bunch (array) of features
     * @param features
     * @param featureKeys
     * @param customTitle
     * @returns {{}}
     * @private
     */


    InfoScreenComp.prototype._buildFeatureBundle = function _buildFeatureBundle(features, featureKeys, customTitle) {
        var bundleHeadings = [];
        var bundle = {};
        bundle.features = features;
        bundle.featureKeys = featureKeys;
        features.forEach(function (feature) {
            if (!bundleHeadings.includes(feature.bundleHeading)) {
                bundleHeadings.push(feature.bundleHeading);
            }
        });
        bundle.title = customTitle !== "" ? customTitle : _("infoscreen.new-in-version", {
            version: UpdateComp.extensions.appUpdateExt.getCurrentVersion()
        });
        bundleHeadings.forEach(function (heading, index) {
            if (heading) {
                bundleHeadings[index] = _(heading);
            }
        });
        bundle.bundleHeading = bundleHeadings.join(', ');
        return bundle;
    };

    Components.InfoScreenComp = {
        Init: InfoScreenComp,
        extensions: {}
    };
    return Components;
}(window.Components || {});
