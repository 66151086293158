'use strict';

define("WindowMonitorControlStateContainer", [ "ControlStateContainer", "WindowMonitorControlEnums" ], function (ControlStateContainer, WindowMonitorControlEnums) {
    return class WindowMonitorControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);

            // as soon as all state containers have been created, register for the states of the controls the monitored
            // windows are linked to --> show their state, not only the "open/closed/.." text provided by the monitor itself
            this._unregFns = [];
            this._boundRegFn = () => {
                this._unregisterFromSubCtrls();
                this._registerForSubCtrls();
            }
            CompChannel.on(CCEvent.StateContainersCreated, this._boundRegFn);
        }

        destroy() {
            CompChannel.off(CCEvent.StateContainersCreated, this._boundRegFn);
            this._unregisterFromSubCtrls();
            return super.destroy();
        }

        /**
         * handles incoming values and prepares the states
         * @param newVals
         */
        prepareStates(newVals) {
            Debug.Control.WindowMonitor && console.log(this.name, "prepareStates");
            this.states.numOpen = newVals[this.control.states.numOpen];
            this.states.numClosed = newVals[this.control.states.numClosed];
            this.states.numTilted = newVals[this.control.states.numTilted];
            this.states.numUnknown = newVals[this.control.states.numOffline];

            if (Feature.LOCKABLE_WINDOWS) {
                this.states.numLocked = newVals[this.control.states.numLocked];
                this.states.numUnlocked = newVals[this.control.states.numUnlocked];
            } else {
                // miniservers that do not yet have this capability just assign locked and unlocked with 0
                this.states.numLocked = 0;
                this.states.numUnlocked = 0;
            } // are all windows closed?


            this.states.allClosed = this.states.numOpen === 0 && this.states.numTilted === 0 && this.states.numUnknown === 0; // do we have lockable windows - if so, are they all locked?

            this.states.allClosedAndLocked = this.states.allClosed && this.states.numLocked > 0 && this.states.numUnlocked === 0; // don't do anything if the states did not change!

            if (this.states.rawWindowStates !== newVals[this.control.states.windowStates].text) {
                this.states.rawWindowStates = newVals[this.control.states.windowStates].text;
                this.states.windowStates = this._prepareWindowStates(newVals[this.control.states.windowStates]);
                this.states.stateMap = this._prepareStateMap();
                this.states.sortedStateArray = this._prepareSortedStateArray();
            }
        }

        getStateText() {
            Debug.Control.WindowMonitor && console.log(this.name, "getStateText");
            var texts = [],
                result;
            this.states.numUnknown > 0 && texts.push(_("window-monitor.nr-unknown", {
                count: this.states.numUnknown
            }));
            this.states.numOpen > 0 && texts.push(_("window-monitor.nr-open", {
                count: this.states.numOpen
            }));
            this.states.numTilted > 0 && texts.push(_("window-monitor.nr-tilted", {
                count: this.states.numTilted
            })); // even if all are closed, there might still be some that aren't locked!

            this.states.numUnlocked > 0 && texts.push(_("window-monitor.nr-unlocked", {
                count: this.states.numUnlocked
            })); // either all are closed and locked, or all are closed (without locked/unlocked)

            if (this.states.allClosedAndLocked) {
                texts.push(_("window-monitor.all-closed-and-locked"));
            } else if (this.states.allClosed) {
                texts.push(_("window-monitor.all-closed"));
            }

            result = texts.join(", ");
            Debug.Control.WindowMonitor && console.log("       --> result: " + result);
            return result;
        }

        getStateIcon() {
            if (this.states.numUnknown > 0) {
                return Icon.WindowMonitor.ERROR;
            } else if (this.states.numOpen > 0) {
                return Icon.WindowMonitor.OPEN;
            } else if (this.states.numTilted > 0) {
                return Icon.WindowMonitor.TILTED;
            } else if (this.states.numUnlocked > 0) {
                return Icon.WindowMonitor.UNLOCKED;
            } else if (this.states.allClosedAndLocked) {
                return Icon.WindowMonitor.LOCKED;
            } else if (this.states.allClosed) {
                return Icon.WindowMonitor.CLOSED;
            }

            return Icon.WindowMonitor.WINDOW_MONITOR;
        }

        getStateColor() {
            var state;

            if (this.states.numUnknown > 0) {
                state = WindowMonitorControlEnums.State.UNKNOWN;
            } else if (this.states.numOpen > 0) {
                state = WindowMonitorControlEnums.State.OPEN;
            } else if (this.states.numTilted > 0) {
                state = WindowMonitorControlEnums.State.TILTED;
            } else if (this.states.numUnlocked > 0) {
                state = WindowMonitorControlEnums.State.UNLOCKED;
            } else if (this.states.allClosedAndLocked) {
                state = WindowMonitorControlEnums.State.LOCKED;
            } else if (this.states.allClosed) {
                state = WindowMonitorControlEnums.State.CLOSED;
            }

            return this._getColorForState(state);
        }

        /**
         * Will return an array that has mapped the current window states onto the window-info objects from the details. The
         * result contains an array that contains an object per window.
         * @param newVals   the value of the "windowStates".
         * @returns {*[]}
         * @private
         */
        _prepareWindowStates(newVals) {
            Debug.Control.WindowMonitor && console.log(this.name, "_prepareWindowStates: " + JSON.stringify(newVals));
            var i,
                newStates = newVals.text.split(','),
                result = this.states.windowStates ? cloneObject(this.states.windowStates) : null,
                room;

            // ensure the window states are properly prepared
            if (!result) {
                // clone the window-list from the details
                result = cloneObject(this.control.details.windows); // resolve the rooms uuid to an object.

                result.forEach(function (window) {
                    room = ActiveMSComponent.getStructureManager().getGroupByUUID(window.room, GroupTypes.ROOM);

                    if (room) {
                        // room known from structure file.
                        window.room = room;
                    } else if (window.room === "") {
                        // "Don't use"-Room --> don't provide room
                        window.room = null;
                    } else {
                        // Room that isn't in our structure file.
                        window.room = {
                            name: _("unknown-room")
                        };
                    }
                });
            }

            // set/update the windowStates of each window.
            for (i = 0; i < newStates.length; i++) {
                result[i].state = this._getStateFromBitmap(parseInt(newStates[i]));
                result[i].stateText = this._getTextForState(result[i].state);
                result[i].stateColor = this._getColorForState(result[i].state);

                if (this.control.details.windows[i].hasOwnProperty("uuid")) {
                    // since 14.7.1.9+ the windows may have a uuid pointing to the control they're referencing to, in such
                    // cases ensure that the proper stat is shown. E.g. another window monitor or a roof window could be
                    // linked in the window monitor. it should then use the color/state text provided by those controls.
                    let windowUuid = this.control.details.windows[i].uuid;
                    result[i].stateText = this._getStateTextFromSubCtrl(windowUuid, result[i].state);
                }
            }

            Debug.Control.WindowMonitor && console.log("   windowStates: " + JSON.stringify(result));
            return result;
        }

        /**
         * Uses the mapped windowStates array to create a map that has an array per state. This makes it easier for listing
         * them in a table.
         * @returns {{}}
         * @private
         */
        _prepareStateMap() {
            Debug.Control.WindowMonitor && console.log(this.name, "_prepareStateMap");
            var stateMap = {};
            this.states.windowStates.forEach(function (window) {
                if (!stateMap.hasOwnProperty(window.state)) {
                    stateMap[window.state] = [];
                }

                stateMap[window.state].push(window);
            });
            return stateMap;
        }

        /**
         * Returns an array containing all an entry for each state, if in windows currently are in these states.
         * E.g. if one window is open and 3 windows are closed, it contains two arrays - one for the open state (containing
         * one window object for the open window) and another array for the closed state (containing the 3 window objects
         * that are open right now).
         * This is being used for presenting a sorted list easier.
         * @returns {Array}
         * @private
         */
        _prepareSortedStateArray() {
            var statesArray = [],
                sortFields = [ "room.name", "name" ];

            var checkAndPush = function checkAndPush(state) {
                if (this.states.stateMap.hasOwnProperty(state)) {
                    statesArray.push(this.states.stateMap[state]);
                }
            }.bind(this);

            checkAndPush(WindowMonitorControlEnums.State.UNKNOWN);
            checkAndPush(WindowMonitorControlEnums.State.OPEN);
            checkAndPush(WindowMonitorControlEnums.State.TILTED);
            checkAndPush(WindowMonitorControlEnums.State.UNLOCKED);
            checkAndPush(WindowMonitorControlEnums.State.CLOSED);
            checkAndPush(WindowMonitorControlEnums.State.LOCKED);
            PersistenceComponent.getMiniserverSettings().sortByRating && sortFields.unshift("room.defaultRating");
            statesArray.forEach(function (arr) {
                sortArrByFields(arr, sortFields);
            });
            return statesArray;
        }

        /**
         * The states are communicated as bitmaps because certain situations indicate multiple states: e.g. locked and closed.
         * This method will "translate" them, it will return the optimum state, so we only have to deal with one.
         * @param bitmap
         * @returns {number}    the most important state (closed and unlocked === unlocked, closed and locked === locked)
         */
        _getStateFromBitmap(bitmap) {
            Debug.Control.WindowMonitor && console.log("WindowMonitorStateContainer: _getStateFromBitmap: " + bitmap);
            var result = WindowMonitorControlEnums.State.UNKNOWN;

            if (hasBit(bitmap, WindowMonitorControlEnums.State.OPEN)) {
                result = WindowMonitorControlEnums.State.OPEN;
            } else if (hasBit(bitmap, WindowMonitorControlEnums.State.TILTED)) {
                result = WindowMonitorControlEnums.State.TILTED;
            } else if (hasBit(bitmap, WindowMonitorControlEnums.State.UNLOCKED)) {
                result = WindowMonitorControlEnums.State.UNLOCKED;
            } else if (hasBit(bitmap, WindowMonitorControlEnums.State.LOCKED)) {
                result = WindowMonitorControlEnums.State.LOCKED;
            } else if (hasBit(bitmap, WindowMonitorControlEnums.State.CLOSED)) {
                // check for closed after checking locked & unlocked. Otherwise it'd show "closed" while its really unlocked or locked.
                result = WindowMonitorControlEnums.State.CLOSED;
            } else {// if any other state is active other than the ones we know, consider it unknown!
            }


            return result;
        }

        /**
         * Translates a window state into a representative color
         * @param windowState   the window state to translate.
         */
        _getColorForState(windowState) {
            switch (windowState) {
                case WindowMonitorControlEnums.State.OPEN:
                case WindowMonitorControlEnums.State.TILTED:
                case WindowMonitorControlEnums.State.UNLOCKED:
                    return window.Styles.colors.orange;

                case WindowMonitorControlEnums.State.CLOSED:
                case WindowMonitorControlEnums.State.LOCKED:
                    return window.Styles.colors.green;

                default:
                    return window.Styles.colors.red;
                // unknown === red, since it could also be open right now!
            }
        }

        /**
         * Translates a window state into a text
         * @param windowState   the window state to translate.
         */
        _getTextForState(windowState) {
            switch (windowState) {
                case WindowMonitorControlEnums.State.OPEN:
                    return _("window-monitor.open");

                case WindowMonitorControlEnums.State.TILTED:
                    return _("window-monitor.tilted");

                case WindowMonitorControlEnums.State.CLOSED:
                    return _("window-monitor.closed");

                case WindowMonitorControlEnums.State.UNLOCKED:
                    return _("window-monitor.unlocked");

                case WindowMonitorControlEnums.State.LOCKED:
                    return _("window-monitor.locked");

                default:
                    return _("window-monitor.unknown");
            }
        }


        // region subcontrol state handling

        /**
         * Iterates over the window objects in the details & registers for each one that has a control linked to it.
         * @private
         */
        _registerForSubCtrls() {
            let unregFn;
            this.control.details.windows.forEach((windowObj, idx) => {
                if (windowObj.hasOwnProperty("uuid")) {
                    unregFn = SandboxComponent.registerFunctionForStateChangesForUUID(windowObj.uuid,
                        this._handleSubCtrlChanged.bind(this, idx, windowObj),
                        this._handleSubCtrlChanged.bind(this, idx, windowObj)
                    );
                    unregFn && this._unregFns.push(unregFn);
                }
            })
        }

        /**
         * Fired when a control linked to a window changes its state. Will update the state text & trigger a state change
         * This is required as even though it may still be "open" it may have changed it's position.
         * @param idx
         * @param window
         * @private
         */
        _handleSubCtrlChanged(idx, window) {
            if (!Array.isArray(this.states.windowStates) || !window.uuid) {
                return;
            }
            const ogState = this.states.windowStates[idx].state
            const prevText = this.states.windowStates[idx].stateText;
            const newText = this._getStateTextFromSubCtrl(window.uuid, ogState)

            // avoid unnecessary listener notifications (& re-renders)
            if (prevText !== newText) {
                this.states.windowStates[idx].stateText = newText;
                this.version++; // count up version with each update
                this.notifyListener();
            }
        }

        _unregisterFromSubCtrls() {
            this._unregFns.forEach((fn) => fn());
            this._unregFns = [];
        }

        /**
         * Will retrieve the new state text either from the control linked, or if it doesn't provide any from the internal
         * open/close state that the monitor itself has.
         * @param windowUuid    the uuid of the control this window references to.
         * @param ogWindowState   number representing the state (open/close/locked/..) - used by the monitor itself
         * @returns {*}
         * @private
         */
        _getStateTextFromSubCtrl(windowUuid, ogWindowState) {
            let result;
            const { states } = SandboxComponent.getStatesForUUID(windowUuid)
            if (states && states.stateTextShort) {
                result = states.stateTextShort;
            } else {
                result = this._getTextForState(ogWindowState);
            }
            return result;
        }

        // endregion subcontrol state handling

    };
});
