import {Subject, Observable} from 'rxjs';

/**
 * Counts down from a specified number in a customizable interval
 * The remaining time can be observed via subscribing to onTick()
 */
class CountdownTimer {
    static DEFAULT_INTERVAL = 1000;

    constructor(duration, tickInterval = CountdownTimer.DEFAULT_INTERVAL) {
        this.duration = duration;
        this.tickInterval = tickInterval;
        this.remainingTime = duration;
        this.timer = new Subject();
        this.intervalId = null;
    }

    /**
     * Starts the timer that is counting down until stop() is called or 0 is reached
     */
    start() {
        this.intervalId = setInterval(() => {
            this.remainingTime--;
            this.timer.next(this.remainingTime);

            if (this.remainingTime === 0) {
                this.stop();
            }
        }, this.tickInterval);
    }

    /**
     * If timer is running, stops it
     */
    stop() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    /**
     * indicates whether the timer is actively couting down
     * @returns {boolean}
     */
    isRunning() {
        return this.intervalId != null;
    }

    /**
     * Resets the remaining duration to the initial duration
     */
    reset() {
        this.remainingTime = this.duration;
        this.timer.next(this.remainingTime);
    }

    /**
     * sets the desired duration, if timer is active, remaining time wil be updated to this value
     * @param newDuration
     */
    setDuration(newDuration) {
        this.duration = newDuration;
        this.remainingTime = newDuration;
        this.timer.next(this.remainingTime);
    }

    /**
     * Fires when remaining time changes, provides the remaining time
     * @returns {Observable<T>}
     */
    onTick() {
        return this.timer.asObservable();
    }
}

export default CountdownTimer
