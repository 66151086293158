'use strict';

define("NfcCodeTouchControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class NfcCodeTouchControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.historyDate = newVals[this.control.states.historyDate];
            this.states.hasHistory = this.states.historyDate > 0;
            this.states.codeDate = newVals[this.control.states.codeDate];
            this.states.keyPadAuthType = newVals[this.control.states.keyPadAuthType]; // since 15.0.0.0 this can change on runtime
            this.states.hasCodes = this.states.codeDate > 0; //not needed for testing, no version available with this feature

            if (Feature.ADD_NFC_TAG_OVER_APP) {
                if (newVals[this.control.states.nfcLearnResult].text !== "") {
                    this.states.nfcLearnResult = JSON.parse(newVals[this.control.states.nfcLearnResult].text);
                } else {
                    this.states.nfcLearnResult = null;
                }

                this.states.deviceState = newVals[this.control.states.deviceState];
            } else {
                this.states.deviceState = null;
                this.states.nfcLearnResult = null;
            }
        }

        getStateTextShort() {
            if (this.control.defaultOutput) {
                return this.control.defaultOutput.name;
            } else {
                return this.getStateText();
            }
        }

        getStateText() {
            if (!this.control.defaultOutput) {
                return _("controls.access-keypad.no-action-available.title");
            }
        }

        getStateInfo() {
            if (!this.control.defaultOutput) {
                return {
                    message: _("controls.access-keypad.no-action-available.description")
                };
            }
        }

        /**
         * return a state text to be displayed in e.g. the system scheme
         * @returns {string|null|undefined}
         */
        getStateTextForInfo() {
            return this.control.getName();
        }

    };
});
