'use strict';

define("UpDownLeftRightAnalogControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class UpDownLeftRightAnalogControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.value = newVals[this.control.states.value];
            this.states.formatedValue = lxFormat(this.control.details.format, this.states.value);
            this.states.minReached = this.states.value <= this.control.details.min;
            this.states.maxReached = this.states.value >= this.control.details.max;
            this.states.error = newVals[this.control.states.error] === 1;
        }

        getStateText() {
            return this.states.formatedValue;
        }

        getStateTextColor() {
            if (this.states.error) {
                return window.Styles.colors.red;
            }
        }

    };
});
