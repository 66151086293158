import {EventEmitter} from "events";
import {v4 as uuidV4} from "uuid";

/*
    interface Queue
    push()
    prioritze()
    pause()
    continue()
    onExecuted()
 */
const TaskQueueConstants = {
    DEFAULT_CONCURRENT_LIMIT: 1,
    DEFAULT_DELAY: 1
}
class TaskQueue {
    static debugName = "TaskQueue"

    constructor(maxConcurrentTasksAmount = TaskQueueConstants.DEFAULT_CONCURRENT_LIMIT, taskDelay = TaskQueueConstants.DEFAULT_DELAY) {
        this._maxConcurrentTasksAmount = maxConcurrentTasksAmount;
        this._taskDelay = taskDelay;
        this.pendingTasks = [];
        this.waitingTasks = [];
        this.onExecutedEmitter = new EventEmitter();
        this.paused = false;
        Debug.TaskQueue && console.log(TaskQueue.debugName, `initialized, max concurrent requests = ${maxConcurrentTasksAmount}, delay = ${taskDelay}`)
    }

    //region private methods
    _remove(task, isPending = false) {
        const tasks = isPending ? this.pendingTasks : this.waitingTasks;
        const taskIndex = tasks.indexOf(task);
        if (taskIndex !== -1) {
            tasks.splice(taskIndex, 1)
            Debug.TaskQueue && console.log(TaskQueue.debugName, `Task ${task.key} removed from ${isPending ? " pending tasks" : " waiting tasks"}`)
        }
    }

    _checkIfExecutedInWaiting(key) {
        Debug.TaskQueue && console.log(TaskQueue.debugName, `Check if executed task ${key} was added to waiting list because of pause`)
        const task = this.waitingTasks.find(task => task.key === key);
        if (task) {
            Debug.TaskQueue && console.log(TaskQueue.debugName, `Executed task ${key} is also in waiting list. removing...`)
            this._remove(task);
        }
    }

    _executeNext(task) {
        if (this.isConcurrentLimitReached()) {
            if (task) {
                Debug.TaskQueue && console.log(TaskQueue.debugName, `Maximum concurrent requests reached. New Task ${task.key} is added to waiting list`)
                this.waitingTasks.push(task);
            }
            return;
        }
        let nextTask = task;

        if (!nextTask && this.waitingTasks.length) {
            nextTask = this.waitingTasks.shift();
            Debug.TaskQueue && console.log(TaskQueue.debugName, `Next Task chosen from waiting list:  ${nextTask.key}`)
        }

        if (nextTask) {
            this.pendingTasks.push(nextTask)
            Debug.TaskQueue && console.log(TaskQueue.debugName, `Executing ${nextTask.key} now...`)
            Q(nextTask.task()).then((resultArgs) => {
                Debug.TaskQueue && console.log(TaskQueue.debugName, `Task ${nextTask.key} has been executed`)
                this._finish(nextTask, resultArgs)
            })
        }
    }

    _finish(task, resultArgs) {
        Debug.TaskQueue && console.log(TaskQueue.debugName, "Task has been finished. Next Task will be executed maybe")
        this.onExecutedEmitter.emit("executed", {key: task.key, resultArgs})
        this._remove(task, true)
        this._checkIfExecutedInWaiting(task.key);

        if (this.paused) {
            Debug.TaskQueue && console.log(TaskQueue.debugName, `is paused. Next Task won't be executed`)
        } else {
            setTimeout(() => this._executeNext(), this._taskDelay);
        }
    }

    //endregion

    //region public methods
    push(taskFn, customKey) {
        const taskKey = customKey || uuidV4()
        const task = {
            key: taskKey,
            task: taskFn
        }
        this._executeNext(task);
        return taskKey;
    }

    prioritise(key) {
        const waitingTask = this.waitingTasks.find(task => task.key === key)
        if (waitingTask) {
            Debug.TaskQueue && console.log(TaskQueue.debugName, `Prioritising Task with key ${key}...`)
            Debug.TaskQueue && console.log(TaskQueue.debugName, `Prioritisation: old waiting list ${JSON.stringify(this.waitingTasks.map(task => task.key))}...`)
            this._remove(waitingTask);
            this.waitingTasks.unshift(waitingTask);
            Debug.TaskQueue && console.log(TaskQueue.debugName, `Prioritisation: new waiting list ${JSON.stringify(this.waitingTasks.map(task => task.key))}...`)
        }
    }

    onExecuted(callbackFn, listenerKey) {
        this.onExecutedEmitter.on("executed", (key, args) => {
            if (!listenerKey || listenerKey && key === listenerKey) {
                callbackFn(key, args);
            }
        })
    }

    pause() {
        Debug.TaskQueue && console.log(TaskQueue.debugName, `pausing...`)
        this.paused = true;
        Debug.TaskQueue && console.log(TaskQueue.debugName, `Pause: adding ${this.pendingTasks.length} to waiting list`)
        this.waitingTasks.push(...this.pendingTasks)
        this.pendingTasks = []
    }

    continue() {
        if (this.paused) {
            Debug.TaskQueue && console.log(TaskQueue.debugName, `continuing...`)
            this.paused = false;
            while(!this.isConcurrentLimitReached() && !!this.waitingTasks.length) {
                this._executeNext();
            }
        }
    }

    isConcurrentLimitReached() {
        return this.pendingTasks.length >= this._maxConcurrentTasksAmount;
    }

    isPaused() {
        return this.paused;
    }

    clear() {
        Debug.TaskQueue && console.log(TaskQueue.debugName, `clearing queue...`)
        this.waitingTasks = [];
        this.pendingTasks = [];
    }

    // endregion
}

export default TaskQueue
