'use strict';

define("PushbuttonControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class PushbuttonControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.isActive = newVals[this.control.states.active] === 1;
        }

        getStateText() {
            if (this.control.isAutomaticScene) {
                return this.states.isActive ? _("scenes.state.exec") : _("scenes.name");
            } else {
                // Request made by AH+TM: Remove "inactive" from virtual inputs and push button blocks
                return null;
            }
        }

        getStateTextForContent() {
            if (this.control.isAutomaticScene) {
                return super.getStateTextForContent(...arguments);
            } else {
                // no state text
                return null;
            }
        }

        getStateColor() {
            if (this.states.isActive) {
                return window.Styles.colors.stateActive;
            }
        }

    };
});
