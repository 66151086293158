'use strict';

define("CentralWindowControlStateContainer", ["ControlCentralStateContainer"], function (ControlCentralStateContainer) {
    return class WindowCentralControlStateContainer extends ControlCentralStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.nrOfClosed = 0;
            this.states.nrOfClosed_Sel = 0;
            this.states.nrOfOpened = 0;
            this.states.nrOfOpened_Sel = 0;
            this.states.allClosed = true;
            this.states.allClosed_Sel = true;
            newVals.forEachState(function (subCtrlStates, control, isCtrlSelected) {
                if (subCtrlStates.position === 0) {
                    this.states.nrOfClosed++;
                    isCtrlSelected && this.states.nrOfClosed_Sel++;
                } else {
                    this.states.nrOfOpened++;
                    isCtrlSelected && this.states.nrOfOpened_Sel++;
                    this.states.allClosed = false;

                    if (isCtrlSelected) {
                        this.states.allClosed_Sel = false;
                    }
                }
            }.bind(this));
        }

        getStateIcon() {
            if (this.states.allClosed) {
                return Icon.Window.IS_CLOSED;
            } else {
                return Icon.Window.IS_OPENED;
            }
        }

        getStateColor() {
            if (this.states.allClosed) {
                return window.Styles.colors.green;
            } else {
                return window.Styles.colors.orange;
            }
        }

        getStateText() {
            if (this.states.allClosed) {
                return _("controls.gate.group.closed");
            } else {
                return _("controls.gate.group.open", {
                    count: this.states.nrOfOpened
                });
            }
        }

    };
});
