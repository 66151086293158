'use strict';
import DemoMsSource from "./extensions/DemoMs/demoMsSource";
import ForbiddenPasswordList from "../../resources/misc/forbiddenPasswords.txt";
import LxJsonRPC from "./utilities/LxJsonRPC";
/**
 * CommunicatonComponent is responsible for network interaction
 * @param PersistenceComp required to get stored preferences when resuming a session
 * @param PlatformComp required for informations regarding the network state
 * @param NavigationComp required for showing alert/popup, if the serialNo changes
 * @returns CommComp instance of the CommunicationComponent
 */
export default ({names}) => {
    window[names.int].factory(names.int, ['$injector', 'ActiveMSComp', 'ActiveMSCommunicationService', 'PersistenceComp', 'PlatformComp', function ($injector, ActiveMSComp, ActiveMSCommunicationService, PersistenceComp, PlatformComp) {
        // internal variables
        let weakThis,
            extension = {},
            connManagerExt,
            socketExt,
            downloadSocketExt,
            tokenHandlerExt,
            lxHttpRequestExt,
            commandHandlerExt,
            statusReceiver,
            jsonRpcHandler = new LxJsonRPC(),
            reachabilityInfo,
            enqueuedResumeCntr = 0;

        /**
         * c-tor for Communication Component
         * @returns {object} exposed functions for other components
         * @constructor
         */

        function CommComp() {
            weakThis = this
            this.name = "CommComp";
            BaseComponent.call(this, arguments); // storing a reference to all extensions

            socketExt = new extension.Socket(this);
            downloadSocketExt = new extension.DownloadSocket(this);
            connManagerExt = new extension.ConnectionManager(this);
            tokenHandlerExt = new extension.TokenHandler(this, this);
            commandHandlerExt = new extension.CommandHandler(this, this);
            lxHttpRequestExt = new extension.LxHttpRequest(this, this); // load the well known user/password combination list

            weakThis.demoMsSource = new DemoMsSource(PlatformComponent.getLanguage());

            CompChannel.on(CCEvent.StartMSSession, function (event, miniserver) {
                let authPromise;
                if (miniserver.activeUser && (miniserver.password || miniserver.token || miniserver.trustToken) ) {
                    Debug.Connectivity && console.log("CommunicationComp: StartMSSession: Auth Provided: user=" + miniserver.activeUser + ", token=" + !!miniserver.token + ", password=" + !!miniserver.password + ", trustToken=" + !!miniserver.trustToken);
                    authPromise = Q.resolve();
                } else {
                    Debug.Connectivity && console.log("CommunicationComp: StartMSSession: No Auth provided, load: user=" + miniserver.activeUser + ", token=" + !!miniserver.token + ", password=" + !!miniserver.password);
                    authPromise = PersistenceComponent.applyStoredAuthInfoForMiniserver(miniserver);
                }
                authPromise.then(() => {
                    Debug.Connectivity && console.log("CommunicationComp: StartMSSession: connect with user=" + miniserver.activeUser + ", token=" + !!miniserver.token + ", password=" + !!miniserver.password);
                    connManagerExt.connectToMiniserver(miniserver);

                    if (PlatformComp.getNetworkStatus().status === NetworkStatus.NOT_REACHABLE) {
                        // only show popup if no loxone control is available or it wasn't a system start
                        // TODO - better solution would be, to notify the JS part if the app is in foreground and decide on the basis of that information
                        console.log("NetworkStatus is NOT_REACHABLE, Loxone control: " + LoxoneControl.hasLoxoneControl() + ", starting with system: " + LoxoneControl.isStartingWithSystem());

                        if (!LoxoneControl.hasLoxoneControl() || !LoxoneControl.isStartingWithSystem()) {
                            var content = {
                                title: _('no-network.title'),
                                message: _('no-network.message'),
                                buttonOk: _('okay'),
                                icon: Icon.CAUTION
                            };
                            this.noNetworkPopup = NavigationComp.showPopup(content);
                        }
                    }
                })
            }.bind(this));
            CompChannel.on(CCEvent.StructureReady, function (event, args) {
                enqueuedResumeCntr = 0;
                Debug.Connectivity && console.log("CommunicationComp: StructureReady");
                weakThis.emit(window[names.int].ECEvent.StructureReady);
            });
            CompChannel.on(CCEvent.Pause, function () {
                Debug.Connectivity && console.log("CommunicationComp: Pause");
                weakThis.emit(window[names.int].ECEvent.Pause);
            });
            CompChannel.on(CCEvent.Resume, function () {
                Debug.Connectivity && console.log("CommunicationComp: Resume");
                weakThis.emit(window[names.int].ECEvent.Resume);
            });
            CompChannel.on(CCEvent.StopMSSession, function () {
                Debug.Connectivity && console.log("CommunicationComp: StopMSSession");
                weakThis.emit(window[names.int].ECEvent.StopMSSession);
                reachabilityInfo = null;
            });
            CompChannel.on(CCEvent.NetworkChanged, function (event, status) {
                Debug.Connectivity && console.log("CommunicationComp: NetworkChanged");
                weakThis.emit(window[names.int].ECEvent.NetworkChanged, status);
            }); // internal broadcasts

            weakThis.on(window[names.int].ECEvent.ConnEstablished, function (event, miniserver, reachMode, url) {
                Debug.Connectivity && console.log("CommunicationComp: ConnEstablished");
                CompChannel.emit(CCEvent.ConnEstablished, miniserver, reachMode, url);
                CompChannel.emit(CCEvent.REMOVE_PRELOAD_LOGIN);
                VendorHub.Usage.recordReachMode(reachMode);
            });
            weakThis.on(window[names.int].ECEvent.ReachabiltyConfirmed, function (event, data) {
                // this popup might be shown by mistake, Bg-272 reports several occasions where a network was available but
                // the network status did show "NOT_REACHABLE".
                this.noNetworkPopup && NavigationComp.removePopup(this.noNetworkPopup);
                this.noNetworkPopup = null; // store all infos on the Miniservers reachability

                reachabilityInfo = data;
                CompChannel.emit(CCEvent.REACHABILITY_UPDATE, reachabilityInfo.reachMode);
            }.bind(this));
            weakThis.on(window[names.int].ECEvent.ConnClosed, function (ev, code) {
                Debug.Connectivity && console.log("CommunicationComp: ConnClosed Code --> " + code);
                CompChannel.emit(CCEvent.ConnClosed, code);
                CompChannel.emit(CCEvent.REACHABILITY_UPDATE, ReachMode.NONE);
            });
            weakThis.on(window[names.int].ECEvent.DownloadingLargeData, function (event, data) {
                CompChannel.emit(CCEvent.DownloadingLargeData, data);
            });
            weakThis.on(window[names.int].ECEvent.TokenReceived, this._handleTokenReceived.bind(this));
            weakThis.on(window[names.int].ECEvent.TokenConfirmed, this._handleTokenConfirmed.bind(this));
            weakThis.on(window[names.int].ECEvent.InvalidToken, this._handleInvalidToken.bind(this));
            weakThis.on(window[names.int].ECEvent.NoMoreEventSlots, function () {
                CompChannel.emit(CCEvent.REMOVE_PRELOAD_LOGIN);
            });
            ActiveMSCommunicationService.send = weakThis.send.bind(weakThis);
            ActiveMSCommunicationService.download = weakThis.download.bind(weakThis);
            ImageBox.setDownloader(weakThis.downloadImage.bind(weakThis)); // make methods of extensions publicly available

            var exposed = {
                getDemoMiniserver: weakThis.demoMsSource.getMiniserver.bind(weakThis.demoMsSource),
                isDemoMiniserver: weakThis.demoMsSource.isDemoMiniserver.bind(weakThis.demoMsSource),
                getCurrentReachMode: weakThis.getCurrentReachMode,
                getRequestProtocol: weakThis.getRequestProtocol,
                getRequiredConnectionPermission: weakThis.getRequiredConnectionPermission,
                getCurrentConnectionType: weakThis.getCurrentConnectionType,
                getSocketProtocol: weakThis.getSocketProtocol,
                getResolvedCloudDnsIp: weakThis.getResolvedCloudDnsIp,
                getHostName: weakThis.getHostName,
                send: weakThis.send.bind(weakThis),
                download: weakThis.download.bind(weakThis),
                closeWebSocket: weakThis.close,
                register4StatusUpdates: register4StatusUpdates,
                getHashForVisuPass: weakThis.getHashForVisuPass.bind(weakThis),
                getVisuPassHashes: weakThis.getVisuPassHashes.bind(weakThis),
                getUserHashFor: weakThis.getUserHashFor.bind(weakThis),
                // password security level check
                isKnownUserPassword: weakThis.isKnownUserPassword.bind(weakThis),
                // Tokens
                requestToken: tokenHandlerExt.requestToken.bind(tokenHandlerExt),
                requestTokenWithBearer: tokenHandlerExt.requestTokenWithBearer.bind(tokenHandlerExt),
                keepMsPermissionAlive: tokenHandlerExt.keepPermissionTokenAlive.bind(tokenHandlerExt),
                stopMsPermissionKeepAlive: tokenHandlerExt.stopMsPermissionKeepAlive.bind(tokenHandlerExt),
                getToken: tokenHandlerExt.getToken.bind(tokenHandlerExt),
                getVerifiedToken: tokenHandlerExt.getVerifiedToken.bind(tokenHandlerExt),
                killTokenWithMsPermission: tokenHandlerExt.killTokenWithMsPermission.bind(tokenHandlerExt),
                killAllTokens: tokenHandlerExt.killAllTokens.bind(tokenHandlerExt),
                sendWithToken: tokenHandlerExt.sendWithToken.bind(tokenHandlerExt),
                isTokenPassInsecure: tokenHandlerExt.hasInsecurePassword.bind(tokenHandlerExt),
                respondToPasswordChange: tokenHandlerExt.respondToPasswordChange.bind(tokenHandlerExt),
                // Commands
                sendViaHttpUnauthorized: weakThis.sendViaHttpUnauthorized.bind(weakThis),
                sendViaHTTP: weakThis.sendViaHTTP.bind(weakThis),
                sendViaHTTPWithToken: weakThis.sendViaHTTPWithToken.bind(weakThis),
                sendViaHTTPWithPermission: weakThis.sendViaHTTPWithPermission.bind(weakThis),
                // Miniserver Http Reqeusts (to arbitraty miniservers)
                sendHttpRequests: weakThis.sendHttpRequests.bind(weakThis),


                // JSON-rpc handling.
                registerForJsonRPCMethod: weakThis.registerForJsonRPCMethod.bind(this),
            };
            return exposed;
        }

        BaseComponent.beInheritedBy(CommComp);
        extension = BaseComponent.initExtensions(names.int, $injector); // methods exposed to the extensions

        // ConnectionManagerExt

        /**
         * @see ConnectionManagerExt.prototype.getCurrentReachMode
         */

        CommComp.prototype.getCurrentReachMode = extension.ConnectionManager.prototype.getCurrentReachMode;
        /**
         * @see ConnectionManagerExt.prototype.getResolvedCloudDnsIp
         */

        CommComp.prototype.getResolvedCloudDnsIp = extension.ConnectionManager.prototype.getResolvedCloudDnsIp;
        /**
         * @see ConnectionManagerExt.prototype.getResolvedCloudDnsIp
         */

        CommComp.prototype.getCurrentConnectionType = extension.ConnectionManager.prototype.getCurrentConnectionType; // SocketExt

        /**
         * @see SocketExt.prototype.open
         */

        CommComp.prototype.open = extension.Socket.prototype.open;
        /**
         * @see SocketExt.prototype.close
         */

        CommComp.prototype.close = extension.Socket.prototype.close;
        /**
         * Received data from socket extension --> forward to SandboxComp
         * @param events
         * @param type
         */

        CommComp.prototype.onEventReceived = function onEventReceived(events, type) {
            statusReceiver && statusReceiver.onEventReceived(events, type);
        };


        CommComp.prototype.onJsonRPC = function onJsonRPC(text) {
            jsonRpcHandler.handleRpc(text);
        };
        CommComp.prototype.registerForJsonRPCMethod = function registerForJsonRPCMethod(cb, methodPath, includeSubMethods) {
            return jsonRpcHandler.registerForMethod(cb, methodPath, includeSubMethods);
        }


        /**
         * If the error provided indicates a socket/connection outage, it'll retry the fn provided
         * as soon as the connection resumed (with a delay of 100 ms inbetween individual resumed calls, ensures sequen-
         * tiality of retries and prevents polluting the MS.
         * @param err   error callback provided --> used to check for a potential outage.
         * @param fn    fn to retry when the connection is back up.
         * @returns {Q.Promise<unknown>|Q.Promise<*>}
         */
        CommComp.prototype.processAfterOutage = function processAfterOutage(err, fn) {
            if (typeof err === "number" && err >= 2000 && err <= 4999) {
                // websocket error, retry later - but optionally place a delay inbetween the individual scheduled retries.
                let def = Q.defer(),
                    delay = enqueuedResumeCntr * 100;
                enqueuedResumeCntr++;

                // ensure that the connection is ready again & that the MS didn't change.
                ActiveMSComponent.comReadyPrms.depActiveMsDone(() => {
                    if (delay > 0) {
                        setTimeout(() => {
                            def.resolve();
                        }, delay)
                    } else {
                        def.resolve();
                    }
                });

                return def.promise.then(() => {
                    return fn();
                });
            } else {
                return Q.reject(err);
            }
        };


        /**
         * Will request a oneTimeSalt and return a hashed version of the payload required.
         * @param payload   the payload to create the onetime hash from.
         */
        CommComp.prototype.getSaltedHash = function getSaltedHash(payload) {
            // Detect if encryption is supported at all.
            var encryptionType = Feature.ENCRYPTED_CONNECTION ? EncryptionType.REQUEST_RESPONSE_VAL : EncryptionType.NONE;
            return this.send(Commands.GET_KEY, encryptionType).then(function (res) {
                var oneTimeSalt = getLxResponseValue(res, true),
                    hashAlg = VendorHub.Crypto.getHashAlgorithmForMs();
                return VendorHub.Crypto["Hmac" + hashAlg](payload, "utf8", oneTimeSalt, "hex", "hex");
            }, (err) => {
                return this.processAfterOutage(err, this.getSaltedHash.bind(this, payload));
            });
        };
        /**
         * Will request the proper salts (with usersalt if supported) and create a hash for the payload provided. Used e.g.
         * for the visu password
         * @param payload           the text to hash
         * @param [user]            optional, active user is be used otherwise.
         */


        CommComp.prototype.getHashForVisuPass = function getHashForVisuPass(payload, user) {
            var theUser;

            if (Feature.TOKENS) {
                theUser = user || ActiveMSComponent.getActiveMiniserver().activeUser;
                return this.getVisuPassHashes(payload, theUser).then(function (result) {
                    return result.hmac; // the hmac is a userspecific hash, that has also been secured against replay attacks using a oneTimeSalt.
                }, (err) => {
                    return this.processAfterOutage(err, this.getHashForVisuPass.bind(this, payload, user));
                });
            } else {
                return this.getSaltedHash(payload);
            }
        };
        /**
         * Will resolve with an object containing both the hash and the replay protected hmac of the visu
         * password provided. The hash is used to alter the visu pass, the hmac is used to send the visu pass
         * in a command.
         * @param visuPass
         * @param user
         * @param [forcedHashAlg]
         */


        CommComp.prototype.getVisuPassHashes = function getVisuPassSalts(visuPass, user, forcedHashAlg) {
            var getKeyCmd = Commands.format(Commands.TOKEN.GET_VISUSALT, encodeURIComponent(user));
            return this.send(getKeyCmd, EncryptionType.REQUEST_RESPONSE_VAL, true).then(function (result) {
                var hmac,
                    hash,
                    value = getLxResponseValue(result),
                    oneTimeSalt = value.key,
                    userSalt = value.salt,
                    hashAlg = forcedHashAlg ? forcedHashAlg : value.hashAlg || VendorHub.Crypto.HASH_ALGORITHM.SHA1; // first create a Hash with the user salt

                hash = VendorHub.Crypto[hashAlg](visuPass + ":" + userSalt);
                hash = hash.toUpperCase(); // hash with user and otSalt

                hmac = VendorHub.Crypto["Hmac" + hashAlg](hash, "utf8", oneTimeSalt, "hex", "hex");
                return {
                    hash: hash,
                    hmac: hmac
                };
            });
        };
        /**
         * Will request the usersalt for the user provided from the miniserver, then create a hash of the payload and
         * return it. It will NOT apply the one time salt!
         * @param payload           the text to hash
         * @param user              the user using whoms usersalt the payload is to be hashed
         * @param [forcedHashAlg]
         * @returns {promise}       either resolves with the hash or rejects.
         */


        CommComp.prototype.getUserHashFor = function getUserHashFor(payload, user, forcedHashAlg) {
            var cmd = Commands.format(Commands.TOKEN.GET_USERSALT, encodeURIComponent(user)),
                result,
                userSalt,
                oneTimeSalt,
                userSpecificHash,
                hashAlg;
            return this.send(cmd, EncryptionType.REQUEST_RESPONSE_VAL).then(function (resp) {
                result = getLxResponseValue(resp);
                userSalt = result.salt;
                oneTimeSalt = result.key;
                hashAlg = forcedHashAlg ? forcedHashAlg : value.hashAlg || VendorHub.Crypto.HASH_ALGORITHM.SHA1;
                userSpecificHash = VendorHub.Crypto[hashAlg](payload + ":" + userSalt);
                userSpecificHash = userSpecificHash.toUpperCase();
                return userSpecificHash;
            });
        };

        CommComp.prototype.isKnownUserPassword = function isKnownUserPassword(username, password) {
            return !PasswordEval.isKnownUserPassword(username, password, ForbiddenPasswordList);
        };
        /**
         * When a token was received, this information needs to be updated in the active miniserver object. It will be
         * stored once the connection was established. Also called if a token is invalid (token is null then)
         * @param ev
         * @param [evObj]   the token that was received after authenticating with the password. null if invalid.
         * @private
         */


        CommComp.prototype._handleTokenReceived = function _handleTokenReceived(ev, evObj) {
            if (evObj && evObj.token) {
                Debug.Tokens && console.log(this.name, "_handleTokenReceived: " + JSON.stringify(evObj));
            } else {
                Debug.Tokens && console.error(this.name, "_handleTokenReceived: Invalid TOKEN! " + JSON.stringify(evObj));
            }

            var ms = ActiveMSComponent.getActiveMiniserver(),
                tkObj;

            if (ms) {
                if (evObj && evObj.token) {
                    Debug.Tokens && console.log(this.name, "    >> active ms exists, store token on MS " + ms.msName);
                    tkObj = tokenHandlerExt.addToHandledTokens(evObj, evObj.username);
                    ms.token = VendorHub.Crypto.encrypt(tkObj.token);
                    delete ms.password;
                    PersistenceComp.setToken(ms.serialNo, ms.token);
                } else {
                    Debug.Tokens && console.warn(this.name, "    >> no token provided, remove token of  " + ms.msName);
                    delete ms.token;
                    PersistenceComp.setToken(ms.serialNo, null);
                }
            } else {
                Debug.Tokens && console.log(this.name, "    >> No active MS, nothing to do");
            }
        };
        /**
         * Called when the websocket authentication returns with 401 on an token based authenticatoin
         * @param ev
         * @param evObj
         * @private
         */


        CommComp.prototype._handleInvalidToken = function _handleInvalidToken(ev, evObj) {
            Debug.Tokens && console.warn(this.name, "_handleInvalidToken: " + JSON.stringify(evObj));
            var ms = ActiveMSComponent.getActiveMiniserver();

            if (ms && evObj.invalidToken) {
                // e.g. when the dlsocket attempts to auth with a token that has been renewed (getJWT/Kill) it reports
                // an invalid token, while a new token is already in place. Without this check, it would null the miniservers
                // valid connection token.
                var decryptedToken = VendorHub.Crypto.decrypt(ms.token);
                var decryptedTrustToken = nullEmptyString(ms.trustToken) ? VendorHub.Crypto.decrypt(ms.trustToken) : null;

                if (evObj.invalidToken === decryptedToken) {
                    Debug.Tokens && console.warn(this.name, "   deleting invalid token! ", evObj.invalidToken);
                    delete ms.token;
                    PersistenceComp.setToken(ms.serialNo, null);
                } else if (evObj.invalidToken === decryptedTrustToken) {
                    Debug.Tokens && console.warn(this.name, "   deleting invalid trustToken! ", evObj.invalidToken);
                    delete ms.trustToken;

                } else {
                    Debug.Tokens && console.warn(this.name, "   token marked as invalid isn't the current MS token! Nothing to do.");
                    Debug.Tokens && console.warn(this.name, "       invalid token: " + evObj.invalidToken);
                    Debug.Tokens && console.warn(this.name, "        currMs token: " + decryptedToken);
                }
            } else if (ms) {
                Debug.Tokens && console.log(this.name, "    >> No active MS, nothing to do");
            } else {
                Debug.Tokens && console.error(this.name, "    >> No info on invalid token provided, nothing to do");
            }
        };
        /**
         * Called once an auth with a token was successful. This is especially important for the WI, as there the token will
         * be requested outside the app and then passed in using the URL start. So no TokenReceived-Event will be fired up
         * front.
         * @param ev
         * @param evObj  event object, contains the token, it's type and the user.
         * @private
         */


        CommComp.prototype._handleTokenConfirmed = function _handleTokenConfirmed(ev, evObj) {
            Debug.Tokens && console.log(this.name, "_handleTokenConfirmed: " + JSON.stringify(evObj));
            tokenHandlerExt.addToHandledTokens(evObj, evObj.username);
        };
        /**
         * Will return the proper host to communicate with.
         * @param urlOrIp         the ip (local/resolved cloudDNS) or URL (webinterface) to connect to
         * @return {string}
         */


        CommComp.prototype.getHostName = function getHostName(urlOrIp) {
            return reachabilityInfo ? reachabilityInfo.host : urlOrIp;
        };
        /**
         * Returns either ws or wss, depending on the location (http or https)
         * @returns {string}
         */


        CommComp.prototype.getSocketProtocol = function getSocketProtocol() {
            return _useTLS() ? "wss://" : "ws://";
        };
        /**
         * Returns the locations protocol. Either http:// or https://
         * @returns {string}
         */


        CommComp.prototype.getRequestProtocol = function getRequestProtocol() {
            return _useTLS() ? "https://" : "http://";
        };
        /**
         * usesd to keep track of image downloads
         * @param path      the path to the img to download
         * @param hasPrio   if true - the image will be put in front of the download queue.
         * @returns {*}
         */


        CommComp.prototype.downloadImage = function downloadImage(path, hasPrio) {
            var start = Debug.ImageBox ? window.performance.now() : null,
                end;
            Debug.ImageBox && console.log(weakThis.name, "downloadImage " + path + (hasPrio ? " !PRIO!" : ""));
            return weakThis.download(path, EncryptionType.NONE, hasPrio).then(function (result) {
                end = Debug.ImageBox ? window.performance.now() : null;
                Debug.ImageBox && console.log(weakThis.name, "downloadImage " + path + " successful, took " + Math.round(end - start) + "ms");
                return result;
            }.bind(weakThis));
        }; // PlatformComp

        /**
         * Get the current network state from window[names.int].
         * @returns {*}
         */


        CommComp.prototype.getNetworkStatus = PlatformComp.getNetworkStatus; // ActiveMSComp

        /**
         * Get the current active Miniserver (from ActiveMiniserver Component)
         * @returns the current active Miniserver
         */

        CommComp.prototype.getActiveMiniserver = ActiveMSComp.getActiveMiniserver;
        /**
         * A different permission is required depending on the platform and the Miniserver. E.g. Demo Miniservers need to
         * use a web-based token to ensure that its memory doesn't get crammed up with long lived app tokens. If the login
         * isn't persisted on the device, ensure a short lived token is used.
         * @param host
         * @return {number}
         * @private
         */

        CommComp.prototype.getRequiredConnectionPermission = function getRequiredConnectionPermission(host = ActiveMSComponent.getMiniserverConnectionUrl()) {
            if (PlatformComponent.isWebInterface() || weakThis.demoMsSource.isDemoMiniserver(host) || !PersistenceComponent.getSavePassword()) {
                return MsPermission.WEB;
            } else {
                return MsPermission.APP;
            }
        }; //Sandbox

        /**
         * Register for Status updates from socket extension.
         * @param sandBox registered sandbox component
         */


        var register4StatusUpdates = function register4StatusUpdates(sandBox) {
            statusReceiver = sandBox;
        };
        /**
         * Returns true if HTTPS / WSS are already known to be working.
         * @return {boolean}
         * @private
         */


        var _useTLS = function _useTLS() {
            var useTLS = reachabilityInfo ? reachabilityInfo.useTLS : false; // if tls has been confirmed by the reachability, use it.

            if (!useTLS) {
                // Tls has not been confirmed by reachability checks, if the page has been
                // loaded via https, still go for tls!
                switch (PlatformComp.getPlatformInfoObj().platform) {
                    case PlatformType.Webinterface:
                        useTLS = location.protocol === "https:";
                        break;

                    default:
                        break;
                }
            }

            return useTLS;
        }; // ------------------------------------------------------------------------------------------------
        // INTERCEPT COMM FOR DIAGNOSE PURPOSES
        // ------------------------------------------------------------------------------------------------


        CommComp.prototype.send = function send(cmd) {
            var promise = socketExt.send.apply(socketExt, arguments);
            Debug.Communication && CommTracker.track(promise, CommTracker.Transport.SOCKET, cmd);
            return promise;
        };

        CommComp.prototype.download = function download(cmd) {
            var promise = downloadSocketExt.download.apply(socketExt, arguments);
            Debug.Communication && CommTracker.track(promise, CommTracker.Transport.DL_SOCKET, cmd);
            return promise;
        };

        CommComp.prototype.sendViaHttpUnauthorized = function sendViaHttpUnauthorized(cmd) {
            var promise = commandHandlerExt.sendViaHttpUnauthorized.apply(commandHandlerExt, arguments);
            Debug.Communication && CommTracker.track(promise, CommTracker.Transport.HTTP, cmd);
            return promise;
        };

        CommComp.prototype.sendEncryptedHttpCmdToHost = function sendEncryptedHttpCmdToHost(host, cmd) {
            var promise = commandHandlerExt.sendEncryptedHttpCmdToHost.apply(commandHandlerExt, arguments);
            Debug.Communication && CommTracker.track(promise, CommTracker.Transport.HTTP, cmd);
            return promise;
        };

        CommComp.prototype.sendViaHTTP = function sendViaHTTP(cmd, encryptionType, automatic, httpMethod, payload) {
            var promise = commandHandlerExt.sendViaHTTP.apply(commandHandlerExt, arguments);
            Debug.Communication && CommTracker.track(promise, CommTracker.Transport.HTTP, cmd, payload);
            return promise;
        };

        CommComp.prototype.sendViaHTTPWithToken = function sendViaHTTPWithToken(cmd) {
            var promise = commandHandlerExt.sendViaHTTPWithToken.apply(commandHandlerExt, arguments);
            Debug.Communication && CommTracker.track(promise, CommTracker.Transport.HTTP, cmd);
            return promise;
        };

        CommComp.prototype.sendViaHTTPWithPermission = function sendViaHTTPWithPermission(cmd) {
            var promise = commandHandlerExt.sendViaHTTPWithPermission.apply(commandHandlerExt, arguments);
            Debug.Communication && CommTracker.track(promise, CommTracker.Transport.HTTP, cmd);
            return promise;
        };

        CommComp.prototype.sendHttpRequests = function sendHttpRequests(rqs) {
            var promise = lxHttpRequestExt.sendRequests.apply(lxHttpRequestExt, arguments);
            Debug.Communication && CommTracker.track(promise, CommTracker.Transport.HTTP_UNIV, rqs[0]);
            return promise;
        };

        window[names.ext] = new CommComp();
        return window[names.ext];
    }]);
}
