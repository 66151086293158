import {BehaviorSubject} from "rxjs";
import {
    PeerData,
    PeerState,
    PeerStateInfo
} from "../../../GUI/ActiveMiniserver/menu/miniserverSettings/userManagement/trustUsers/TrustModels";
import {EventEmitter} from "events";
import ConnectionAwareTaskQueue from "../../../projectSpecific/logic/tasks/ConnectionAwareTaskQueue";

const MAX_CONCURRENT_REQUESTS = 4;

class TrustManager {
    static debugName = "TrustManager";

    constructor() {
        CompChannel.on(CCEvent.ConnEstablished, () => {
            const activeSerial = ActiveMSComponent.getActiveMiniserverSerial();
            if (!this._initialized) {
                this._init(activeSerial);
            } else if (activeSerial !== this._clientSerial) {
                Debug.TrustManager && console.log(TrustManager.debugName, `Switched to new Minserver ${activeSerial}, resetting...`);
                this.reset(activeSerial);
            }
        })
    }

    _init(clientSerial) {
        Debug.TrustManager && console.log(TrustManager.debugName, `Initializing Trust Manager for ${clientSerial}...`);
        this._initialized = true;
        this._clientSerial = clientSerial;
        this._trustUsers = new BehaviorSubject([]);
        this._usersChanged = new EventEmitter();
        this._peersData = [];
        this._requestQueue = new ConnectionAwareTaskQueue(MAX_CONCURRENT_REQUESTS);
    }

    //region private methods
    _createRequest(serial, observablePeerData, initialPeerData) {
        const updatedPeerData = observablePeerData ? observablePeerData.getValue() : initialPeerData || new PeerData(serial, null, new PeerStateInfo(serial, PeerState.Waiting));

        updatedPeerData.stateInfo.state = PeerState.Waiting;

        if (!!observablePeerData) {
            observablePeerData.next(updatedPeerData);
        } else {
            observablePeerData = new BehaviorSubject(updatedPeerData);
        }

        this._requestQueue.push(this._fetchTrustUsersFromMS.bind(this, serial, observablePeerData, this._clientSerial), serial);
        return observablePeerData;
    }

    _fetchTrustUsersFromMS(serial, observablePeerData, clientSerial) {
        Debug.TrustManager && console.log(TrustManager.debugName, `Fetching Trust users from ${serial}...`);
        return ActiveMSComponent.getUsersFromPeer(serial).then((res) => {
            const updatedPeerData = observablePeerData.getValue();
            if (res.error) {
                updatedPeerData.setStateInfo(new PeerStateInfo(serial, PeerState.Failed, 0, Date.now()));
            } else if (clientSerial === this._clientSerial) {
                Debug.TrustManager && console.log(TrustManager.debugName, `Fetching done. Got ${res.users.length} users from ${serial}`);
                const users = res.users;
                this._addUsers(serial, users);
                updatedPeerData.setStateInfo(new PeerStateInfo(serial, PeerState.Done, users.length, Date.now()));
                updatedPeerData.setUsers(users);
            } else {
                //do nothing because the answer is corrupted or the client serial has changed
            }
            observablePeerData.next(updatedPeerData);
        })
    }

    _addUsers(serial, newUsers) {
        newUsers.forEach(user => user.peerSerial = serial);
        const updatedTrustUsers = this._trustUsers.getValue();
        updatedTrustUsers.push(...newUsers);
        this._trustUsers.next(updatedTrustUsers);
    }

    _getPeerData(serial) {
        return this._peersData.find(peerState => peerState.getValue().stateInfo.serial === serial);
    }
    // endregion

    // region public methods
    getClientSerial() {
        return this._clientSerial;
    }

    getTrustUsersForPeer(serial, forceFetch = false, initialPeerData) {
        let observablePeerData = this._getPeerData(serial);
        const peerDataExists = !!observablePeerData;

        if (peerDataExists) {
            const currentPeerData = observablePeerData.getValue();
            const wasExecuted = currentPeerData.hasState(PeerState.Done) || currentPeerData.hasState(PeerState.Failed);

            if (wasExecuted && forceFetch) {
               this._createRequest(serial, observablePeerData);
            } else if (currentPeerData.hasState(PeerState.Waiting)) {
                this.prioritiseRequest(serial);
            }
            Debug.TrustManager && console.log(TrustManager.debugName, `The trust ${serial} has already been fetched. Returning local data`);
            return observablePeerData;
        } else {
            Debug.TrustManager && console.log(TrustManager.debugName, `There is no local data for ${serial}, start request`);
            observablePeerData = this._createRequest(serial, null, initialPeerData);
        }

        this._peersData.push(observablePeerData);
        return observablePeerData;
    }

    prioritiseRequest(serial) {
        this._requestQueue.prioritise(serial);
    }

    trustUsersChanged() {
        this._usersChanged.emit("changed", true);
    }

    onTrustUsersChanged(listenerFn) {
        this._usersChanged.on("changed", listenerFn);
    }

    offTrustUsersChanged() {
        return this._usersChanged.removeAllListeners("changed");
    }

    refreshPeer(serial) {
        this.getTrustUsersForPeer(serial, true);
    }

    refreshAll(serials) {
        this._peersData.forEach(peerData => this.refreshPeer(peerData.getValue().stateInfo.serial));
    }

    isLoadingPeers() {
        return !!this._peersData.find(peerData => [PeerState.Waiting, PeerState.Pending, PeerState.New].includes(peerData.getValue().stateInfo.state));
    }

    reset(newSerial) {
        if (this._requestQueue) {
            this._requestQueue.clear();
        }
        this._init(newSerial);
    }
    //endregion
}

export default TrustManager
