'use strict';
/**
 * RFC6455 Testfälle:
 * nicht authentifizieren (10sek):
 *      420 Policy not Fulfilled, Socket close
 *
 * falsch authentifizieren:
 *      401 Unauthorized, Socket close
 *
 * jeder andere Befehl:
 *      400 Bad Request, Socket close
 *
 */
export default function SocketExtRFC6455(isDownloadSocket) {
    var DLSOCKET_MAX_IDLE_TIME = 30 * 1000; // after 30 secs close!

    var HASH_VALID_TIME = 5 * 1000 - 500; // about 5s (minus ping time..)

    const SALT_EXPIRY = 5 * 60 * 1000; // 5 minutes

    let weakThis,
        commComp,
        ws,
        socketPromise,
        requests = [],
        closingTimer,
        // DL Socket only
        waitingQueue = [],
        // DL Socket only
        currentRequest;
    var _authActive = false,
        // set to true while authentication is in progress.
        _authTokenBased = false,
        // set to true if a token is used to authenticate.
        wrongPassword = false,
        lastPwdChange = 0,
        authStartTime;
    var invalidToken = false;
    var encryption = {
        saltTimestamp: 0,
        salt: null,
        // current salt, must alternate with each encrypted command
        key: null,
        // AES key for the current session
        iv: null // AES iv

    };

    /**
     * SocketExtension handles communication via the websocket
     * @param comp reference to the parent component
     * @constructor
     */

    function SocketExt(comp) {
        weakThis = this // always work with self to prevent issues with this-keyword

        weakThis.name = isDownloadSocket ? "DLSocket: " : "WebSocketExt: ";
        weakThis.saltIntervalID = null;
        commComp = comp;
        commComp.on(CommunicationComp.ECEvent.Pause, function () {
            weakThis.close();
        });
        commComp.on(CommunicationComp.ECEvent.StopMSSession, function () {
            weakThis.close();
        });
        commComp.on(CommunicationComp.ECEvent.StructureReady, function () {
            if (!isDownloadSocket) {
                // DLSocket has no keepalive
                ws.startKeepalive();
            }
        });
    } // public methods

    /**
     * initializes websocket, opens it and attaches listeners
     * @param host address to which the connection should be established
     * @param user credentials required for secure authentication
     * @param password credentials required for secure authentication
     * @param encryptionAllowed if encryption is allowed (depends on config version)
     * @param supportsTokens    if this config version supports tokens
     * @param [authToken]       if the initial authentication has already been passed, tokens will be used instead of pwds.
     * @returns {promise|*} promise whether the attempt was successful or not
     */


    SocketExt.prototype.open = function open(host, user, password, encryptionAllowed, supportsTokens, authToken) {
        Debug.Socket.Basic && console.log(weakThis.name + "try to open WebSocket to host:", host);

        if (ws && ws.ws && !ws.socketClosed) {
            console.warn(weakThis.name + "===============================================================");
            console.warn(weakThis.name + "WARNING: WebSocket is maybe still opened! readyState:", ws.ws.readyState);
            console.warn(weakThis.name + " - we now open another new WebSocket..");
            console.warn(weakThis.name + " - old WebSocket will be closed..");
            console.warn(weakThis.name + "===============================================================");
            ws.close(SupportCode.WEBSOCKET_MANUAL_CLOSE);
        }

        socketPromise = Q.defer();
        wrongPassword = false;
        invalidToken = false;

        if (isDownloadSocket) {
            ws = new LxWebSocket(host, true, true); // long timeout, no keepalive
        } else {
            ws = new LxWebSocket(host);
        }

        ws.onOpen = weakThis.wsOpened.bind(weakThis, host, user, password, encryptionAllowed, supportsTokens, authToken);
        ws.onMessageError = messageErrorHandler;
        ws.onTextMessage = textMessageHandler;
        ws.onBinaryMessage = binaryMessageHandler;
        ws.onClose = closeHandler;
        ws.onError = errorHandler;

        ws.incomingDataProgress = function (progress) {
            if (isDownloadSocket) {
                commComp.emit(CommunicationComp.ECEvent.DownloadingLargeData, progress);
            }
        };

        return socketPromise.promise;
    };

    SocketExt.prototype.wsOpened = function wsOpened(host, user, password, encryptionAllowed, supportsTokens, authToken) {
        Debug.Socket.Basic && console.log(this.name, "wsOpened");

        if (encryptionAllowed) {
            exchangeKeys(host).done(function (oneTimeSalt) {
                if (supportsTokens) {
                    var msPermission = commComp.getRequiredConnectionPermission(host);

                    if (authToken) {
                        Debug.Socket.Basic && console.log(this.name, "Authenticate with a token " + msPermission);
                        authWithToken(user, authToken, msPermission, oneTimeSalt);
                    } else {
                        Debug.Socket.Basic && console.log(this.name, "Request a new token " + msPermission);
                        acquireToken(user, password, msPermission);
                    }
                } else {
                    authenticate(user, password, oneTimeSalt, true);
                }
                !isDownloadSocket && _startSaltRefreshTimer();
            }.bind(this), function (res) {
                // res can be an error too (.done)
                // handle code 401 -> means that the publicKey has changed!
                if (typeof res === "object" && res.LL && getLxResponseCode(res) === 401) {
                    // close the socket..
                    console.error("keyexchange responded 401 - the public key might have changed! don't allow " + "connection -> security!");
                    this.close(); //TODO-woessto: shouldn't there be some kind of information?
                } else if (res === SupportCode.WEBSOCKET_NOT_READY) {
                    // Do nothing so far, a close with the blocked-error-code will follow
                    Debug.Socket.Basic && console.log(this.name, "exchanging keys failed, socket not open - probably blocked");
                } else {
                    // simply close the socket, don't fallback to unencrypted!
                    this.close();
                }
            }.bind(this));
        } else {
            getKeyAndAuthenticate(user, password);
        }
    };
    /**
     *  closes the WebSocket
     */


    SocketExt.prototype.close = function close(reason) {
        Debug.Socket.Basic && console.log(weakThis.name + "closing the connection");
        ws && ws.close(reason || SupportCode.WEBSOCKET_MANUAL_CLOSE);

        if (isDownloadSocket) {
            clearTimeout(closingTimer);
        } else {
            _stopSaltRefreshTimer();
        }
    };
    /**
     *  closes the WebSocket
     */


    SocketExt.prototype.isOpen = function isOpen() {
        var isOpen = ws && ws.ws && !ws.socketClosed;
        Debug.Socket.Basic && console.log(weakThis.name + "isOpen: " + JSON.stringify(!!isOpen));
        return isOpen;
    };

    const _startSaltRefreshTimer = () => {
        if(!weakThis.saltIntervalID) {
            weakThis.saltIntervalID = setInterval(() => {
                const newSalt = VendorHub.Crypto.generateSalt();
                const cmd = Commands.format(Commands.STRUCTURE_FILE_DATE, encryption.salt, newSalt, Commands.CONFIG_VERSION);
                // As we don't care about the response, we don't need to handle it but due to specific command structure we need to send something so I chose a command with a smallest possible footprint
                weakThis.send(cmd, EncryptionType.REQUEST, true).then((response) => {
                    Debug.Encryption && console.log("WebSocketExt", "Salt refreshed");
                    encryption.salt = newSalt;
                    encryption.saltTimestamp = Date.now();
                }).catch((e) => {
                    Debug.Encryption && console.error("WebSocketExt", "Salt refresh failed", e);
                });
            }, SALT_EXPIRY); // 5 minutes
            Debug.Encryption && console.log("WebSocketExt", "Salt refresh timer started");
        } else {
            console.error("WebSocketExt", "Salt refresh timer already running");
        }
    }

    const _stopSaltRefreshTimer = () => {
        if (weakThis.saltIntervalID) {
            clearInterval(weakThis.saltIntervalID);
            weakThis.saltIntervalID = null;
            Debug.Encryption && console.log("WebSocketExt", "Salt refresh timer stopped");
        }
    }

    var getKeyAndAuthenticate = function (user, password) {
        authStartTime = Date.now();
        console.log("WebSocketExt", "GetKey will be sent - setting authStartTime")
        weakThis.send(Commands.GET_KEY, EncryptionType.NONE).done(function (result) {
            Debug.Socket.Basic && console.log(weakThis.name + "getkey successful!");
            authenticate(user, password, result.LL.value, false);
        }, function (e) {
            console.error(weakThis.name + "getkey failed " + e);
        });
    };

    var authenticate = function authenticate(user, password, oneTimeSalt, encrypted) {
        var creds = user + ":" + password,
            hashAlg = VendorHub.Crypto.getHashAlgorithmForMs(),
            hash = VendorHub.Crypto["Hmac" + hashAlg](creds, "utf8", oneTimeSalt, "hex", "hex"),
            cmd; // starting pw based authentication.

        _setAuthenticating(true);

        if (encrypted) {
            Debug.Encryption && console.log(weakThis.name + "hash hex: " + hash);
            var encryptedHash = VendorHub.Crypto.aesEncrypt(hash + "/" + user, encryption.key, encryption.iv);
            var ciphertext = encryptedHash.ciphertext;
            var ciphertextBase64 = ciphertext.toString(CryptoJS.enc.Base64);
            Debug.Encryption && console.log(weakThis.name + "ciphertext base64: " + ciphertextBase64);
            cmd = Commands.format(Commands.ENCRYPTION.AUTHENTICATE, ciphertextBase64);
        } else {
            cmd = Commands.format(Commands.AUTHENTICATE, hash);
        }

        weakThis.send(cmd, EncryptionType.NONE).then(handleSuccessFullAuth, handleBadAuthResponse);
    };

    var handleSuccessFullAuth = function handleSuccessFullAuth() {
        Debug.Socket.Basic && console.log(weakThis.name + "authenticate successful!"); // authenticated, reset flags.

        _setAuthenticating(false);

        socketPromise.resolve(ResponseCode.OK);
        socketPromise = null;
    };
    /**
     * Will create a HMAC hash based on the token and the oneTimeSalt provided & send it to
     * the Miniserver in an authentication request.
     * @param user          the user for which the authentication request is made
     * @param token         the authentication token for this user
     * @param permission     either a short lived WI token or a long lived app token.
     * @param oneTimeSalt   a short lived one time salt provided by the miniserver
     */


    var authWithToken = function authWithToken(user, token, permission, oneTimeSalt) {
        Debug.Socket.Basic && console.log(weakThis.name, "authenticate with token");
        var hashAlg = VendorHub.Crypto.getHashAlgorithmForMs(),
            hash = VendorHub.Crypto["Hmac" + hashAlg](token, "utf8", oneTimeSalt, "hex", "hex"),
            cmd = Commands.format(Commands.TOKEN.AUTHENTICATE, hash, encodeURIComponent(user)),
            response; // about to start token based authentication, set flags

        _setAuthenticating(true, true);

        weakThis.send(cmd, EncryptionType.REQUEST_RESPONSE_VAL).then(function (result) {
            Debug.Socket.Basic && console.log(weakThis.name, "authenticate with token successful!");
            response = getLxResponseValue(result); // the token result might contain a new rights value.

            response.msPermission = response.tokenRights; //The username is a central part of the tokenObj, store it on the response & return it.

            response.username = user; // store the token itself on the tokenObj

            response.token = token; // emit the new token so it'll be stored.

            var diff = Date.now() - authStartTime;
            console.log("WebSocketExt", "SUCCESSFUL authentication took " + diff + "ms");

            commComp.emit(CommunicationComp.ECEvent.TokenConfirmed, response); // make use of this helper as it ensures all attributes are properly set.

            handleSuccessFullAuth();
        }, function (result) {
            var diff = Date.now() - authStartTime;
            console.error("SocketExt", "token authentication failed! took  " + diff + "ms"); // no invalid password was sent, it was a bad authentication response based on an invalid token

            return handleBadAuthResponse(result, true, token);
        });
    };
    /**
     * Will use the tokenExt in the commComponent to acquire, store & handle the token.
     * @param user
     * @param password
     * @param msPermission
     */


    var acquireToken = function acquireToken(user, password, msPermission) {
        // starting authentication by requesting a token based on the passord. Don't set the uses token flag yet.
        _setAuthenticating(true);

        CommunicationComponent.requestToken({
            user: user,
            password: password,
            msPermission: msPermission
        }).done(function (result) {
            if (result && result.token) {
                Debug.Socket.Basic && console.log(weakThis.name, "Token received!"); // emit the new token so it'll be kept alive.

                commComp.emit(CommunicationComp.ECEvent.TokenReceived, result); // no need to authenticate after getToken success, the socket is authenticated as of now.

                handleSuccessFullAuth();
            } else {
                console.error("Could not acquire token!");
            }
        }.bind(this), handleBadAuthResponse);
    };
    /**
     * Called whenever the authentication failed.
     * @param result        the authentication result
     * @param [tokenBasedAuth]    optional, if true an invalid token was used for authentication
     * @param [token]             optional, the token used for authentication
     */


    var handleBadAuthResponse = function handleBadAuthResponse(result, tokenBasedAuth, token) {
        Debug.Socket.Basic && console.log(weakThis.name + "authenticate using " + (tokenBasedAuth ? "token" : "credentials") + " failed! " + JSON.stringify(result));
        var code; // auth failed, reset flags.

        _setAuthenticating(false); // If the user is disabled and want to login, their is no LL wrapper


        if (result.code === ResponseCode.FORBIDDEN || result.code === ResponseCode.NO_PERMISSION) {
            commComp.emit(CommunicationComp.ECEvent.ConnClosed, result.code);
            weakThis.close();
        } else if (result.LL) {
            code = getLxResponseCode(result);

            if (code === ResponseCode.UNAUTHORIZED) {
                _handleUnauthorized(tokenBasedAuth, result.LL, token);
            } else if (code === ResponseCode.BAD_REQUEST) {
                weakThis.close();
            } else if (code === ResponseCode.USER_CHANGED_ON_ABSENCE) {
                // handle like unauhtorized, so in trust-linking, the trust token is a valid fallback
                _handleUnauthorized(tokenBasedAuth, result.LL, token);
            }
        }
    };
    /**
     * Called when the authenticate command came back negative with 401
     * @param tokenBasedAuth    true if a token was used to authenticate
     * @param resContent        the returned results LL-Object.
     * @param [token]           the token used for authentication
     * @private
     */


    var _handleUnauthorized = function _handleUnauthorized(tokenBasedAuth, resContent, token) {
        var diff = Date.now() - authStartTime;
        console.log("WebSocketExt", "authentication took " + diff + "ms");

        if (resContent.value && typeof resContent.value.unix === "number") {
            lastPwdChange = resContent.value.unix;
        } else {
            lastPwdChange = 0;
        } // check how long it did take, the hash may have been invalid due to bad connection!


        if (diff > HASH_VALID_TIME) {
            console.log("WebSocketExt", "authentication response took too long, oneTimeSalt may no longer be valid, retry");
            // it took too longer, the hash was was probably invalid, try again
            wrongPassword = false; // handled in closeHandler
            invalidToken = false; // also don't throw away tokens - they are probably also still valid.

        } else if (tokenBasedAuth) {
            console.log("WebSocketExt", "authentication didn't take too long, invalidate token!");
            // it was fast enough, the token is most certainly invalid
            // emit the new token so it'll be stored.
            commComp.emit(CommunicationComp.ECEvent.InvalidToken, {
                invalidToken: token
            });
            invalidToken = true; // handled in closeHandler
        } else {
            // it was fast enough, the credentials are most certainly invalid
            wrongPassword = true; // handled in closeHandler
        }

        weakThis.close();
    };
    /**
     * Generates a random AES key & IV, RSA-encrypts it and sends it to the Miniserver. The Miniserver then responds with
     * a oneTimeSalt that can be used for authentication right after this step. If the Public Key for RSA is not yet known,
     * it will acquire it.
     * @param host          the host for whom to acquire the public key
     * @returns {Promise}
     */


    var exchangeKeys = function exchangeKeys(host) {
        var cmd, rsaEncryptedSessionKey_base64;
        return ActiveMSComponent.getPublicKey(host).then(function (publicKey) {
            encryption.key = VendorHub.Crypto.generateAesKey();
            encryption.iv = VendorHub.Crypto.generateAesIV();
            encryption.salt = null; // reset salt + ts

            encryption.saltTimestamp = null;
            Debug.Encryption && console.info(weakThis.name + "session key: " + encryption.key);
            Debug.Encryption && console.info(weakThis.name + "session iv: " + encryption.iv);
            rsaEncryptedSessionKey_base64 = VendorHub.Crypto.rsaEncrypt(encryption.key + ":" + encryption.iv, publicKey);
            cmd = Commands.format(Commands.ENCRYPTION.KEY_EXCHANGE, rsaEncryptedSessionKey_base64);

            console.log("WebSocketExt", "about to exchange keys - setting authStartTime")
            authStartTime = Date.now(); // important for handleBadAuthResponse - retry on poor connections

            return weakThis.send(cmd, EncryptionType.NONE).then(function (result) {
                return VendorHub.Crypto.aesDecrypt(result.LL.value, encryption.key, encryption.iv); // -> the key (like from getkey)
            });
        });
    };
    /**
     * handles error from the websocket
     * @param error
     * @param code
     */


    var errorHandler = function errorHandler(error, code) {
        console.error(weakThis.name + "ERROR: websocket error='" + JSON.stringify(error) + "', code='" + JSON.stringify(code) + "'");
        closeHandler.apply(this, arguments); // simply forward to closeHandler!
    };
    /**
     * called when WebSocket did close
     * @param error
     * @param code
     * @param [reason] of websocket close (not available when called from onerror)
     */


    var closeHandler = function closeHandler(error, code, reason) {
        console.info(weakThis.name + "closeHandler: " + JSON.stringify(error) + ", " + code + ": reason = " + (reason ? reason.code : "--"));
        var blocked = false,
            onUserChanged = reason && reason.code === WebSocketCloseCode.ON_USER_CHANGED,
            onThisUserChanged = reason && reason.code === WebSocketCloseCode.ON_THIS_USER_CHANGED,
            onMiniserverUpdate = reason && reason.code === WebSocketCloseCode.MS_UPDATING,
            onStructureModified = reason && reason.code === WebSocketCloseCode.STRUCTURE_MODIFIED,
            onExpiredPermission = reason && reason.code === WebSocketCloseCode.EXPIRED_PERMISSION,
            remTxt,
            remaining = -1; // check if the closing feedback indicates that the IP has been temporarily blocked by the Miniserver

        if (reason && reason.code === WebSocketCloseCode.BLOCKED) {
            blocked = true;
            remTxt = reason.reason;

            try {
                remaining = parseInt(remTxt.substring(remTxt.lastIndexOf("(") + 1, remTxt.length - 1));
            } catch (e) {
            }
        } // queued requests need to be informed about the connection close too.


        resetRequestQueue(code);

        if (isDownloadSocket) {
            clearTimeout(closingTimer);
            socketPromise = null; // important, otherwise the DL socket wouldn't try to reconnect!
        } else if (socketPromise) {
            if (blocked) {
                console.log(weakThis.name + reason.reason);
                socketPromise.reject({
                    errorCode: ResponseCode.BLOCKED_TEMP,
                    remaining: remaining
                });
            } else if (invalidToken) {
                socketPromise.reject({
                    errorCode: ResponseCode.INVALID_TOKEN,
                    lastPwdChange: lastPwdChange
                });
            } else if (wrongPassword) {
                socketPromise.reject({
                    errorCode: ResponseCode.UNAUTHORIZED,
                    lastPwdChange: lastPwdChange
                });
            } else if (onUserChanged) {
                socketPromise.reject({
                    errorCode: ResponseCode.ON_USER_CHANGED
                });
            } else if (onThisUserChanged) {
                socketPromise.reject({
                    errorCode: ResponseCode.ON_THIS_USER_CHANGED
                });
            } else if (onMiniserverUpdate) {
                socketPromise.reject({
                    errorCode: ResponseCode.OOS_MINISERVER_UPDATING,
                    updating: true
                });
            } else if (onExpiredPermission) { // user permission to access has ended.
                socketPromise.reject({
                    errorCode: ResponseCode.EXPIRED_PERMISSION
                });
            } else {
                console.error(weakThis.name + " socket failed! WS Close Code: " + (reason ? reason.code : "-unknown-"));
                socketPromise.reject({
                    errorCode: ResponseCode.SOCKET_FAILED
                });
            }

            socketPromise = null;
        } else {
            if (onUserChanged) {
                commComp.emit(CommunicationComp.ECEvent.ConnClosed, ResponseCode.ON_USER_CHANGED);
            } else if (onThisUserChanged) {
                commComp.emit(CommunicationComp.ECEvent.ConnClosed, ResponseCode.ON_THIS_USER_CHANGED);
            } else if (onMiniserverUpdate) {
                commComp.emit(CommunicationComp.ECEvent.ConnClosed, ResponseCode.OOS_MINISERVER_UPDATING);
            } else if (onStructureModified) {
                commComp.emit(CommunicationComp.ECEvent.ConnClosed, ResponseCode.ON_STRUCTURE_MODIFIED);
            } else if (onExpiredPermission) { // user permission to access has ended.
                commComp.emit(CommunicationComp.ECEvent.ConnClosed, ResponseCode.EXPIRED_PERMISSION);
            } {
                commComp.emit(CommunicationComp.ECEvent.ConnClosed, code);
            }
        }
    };
    /**
     * Used for sending commands.
     * @param request           the request (a string or an deferred with the cmd) to be sent
     * @param encryptionType    type of encryption for this command
     * @param hasPrio           if true, it is being pushed on top of the queue
     * @returns send command or rejects error.
     */


    SocketExt.prototype.send = function send(request, encryptionType, hasPrio) {
        var def = _getDeferredForRequest(request, encryptionType),
            shouldEncrypt = usesEncryption(def.encryptionType),
            cmd = def.command; // inside getDeferredForCommand, the encryption type might be


        encryptionType = def.encryptionType;

        if (isDownloadSocket) {
            // stop closing!
            clearTimeout(closingTimer);
        }

        if (_isSocketReadyForCmd(cmd)) {
            if (!currentRequest) {
                currentRequest = def;

                if (shouldEncrypt && _encryptionSupported()) {
                    cmd = _getEncryptedCommand(cmd, encryptionType);
                } else if (shouldEncrypt) {
                    // cannot send encrypted commands when the socket itself is not ready for it.
                    def.reject(SupportCode.WEBSOCKET_NOT_SECURED);
                    setTimeout(sendNextRequest);
                    return def.promise;
                }

                Debug.Communication && CommTracker.commSent(isDownloadSocket ? CommTracker.Transport.DL_SOCKET : CommTracker.Transport.SOCKET, def.command);

                if (shouldEncrypt) {
                    if (isDownloadSocket) {
                        Debug.DownloadSocketExt && console.info(weakThis.name + "App->Miniserver: DLSocket: ENC " + def.command);
                    } else {
                        Debug.Socket.Detailed && console.info(weakThis.name + "App->Miniserver: Socket: ENC " + def.command);
                    }
                } else {
                    if (isDownloadSocket) {
                        Debug.DownloadSocketExt && console.info(weakThis.name + "App->Miniserver: DLSocket " + def.command);
                    } else {
                        Debug.Socket.Detailed && console.info(weakThis.name + "App->Miniserver Socket" + def.command);
                    }
                }

                ws.send(cmd);
                currentRequest.promise.then(sendNextRequest, sendNextRequest);
            } else if (hasPrio) {
                if (isDownloadSocket) {
                    Debug.DownloadSocketExt && console.info(weakThis.name + "Queuing Prio Request up front " + def.command);
                } else {
                    Debug.Socket.Detailed && console.info(weakThis.name + "Queuing Prio Request up front " + def.command);
                }

                requests.splice(0, 0, def);
            } else {
                if (isDownloadSocket) {
                    Debug.DownloadSocketExt && console.info(weakThis.name + "Queuing Request " + def.command);
                } else {
                    Debug.Socket.Detailed && console.info(weakThis.name + "Queuing Request " + def.command);
                }

                requests.push(def);
            }
        } else if (isDownloadSocket) {
            Debug.DownloadSocketExt && console.log(weakThis.name + "send '" + cmd + "'");

            if (hasPrio) {
                Debug.DownloadSocketExt && console.log("    not ready -> waitingQueue, PRIO, up front!");
                waitingQueue.splice(0, 0, def);
            } else {
                Debug.DownloadSocketExt && console.log("    not ready -> waitingQueue");
                waitingQueue.push(def);
            }
        } else {
            def.reject(SupportCode.WEBSOCKET_NOT_READY);
        }

        return def.promise;
    };
    /**
     * Called to set whether or not the authentication is currently in progress. Updates the internal state of this
     * websocket class. Required to respond properly to errors (such as 401 responses) during the process.
     * @param active        true if the authentication is currently in progress
     * @param usingToken    true if the authentication is based on tokens.
     * @private
     */


    var _setAuthenticating = function _setAuthenticating(active, usingToken) {
        _authActive = active;
        _authTokenBased = usingToken;
    };
    /**
     * Will return ture if the authentication is currently in progress.
     * @param usingToken    if true, it will only return true if token based authentication is in progress.
     * @return {boolean}
     * @private
     */


    var _isAuthenticating = function _isAuthenticating(usingToken) {
        return _authActive && (!usingToken || _authTokenBased);
    };
    /**
     * Will check if encryption is supported or not.
     * @returns {boolean}
     * @private
     */


    var _encryptionSupported = function _encryptionSupported() {
        return encryption.key && encryption.iv;
    };
    /**
     * check if this is a new request = string, or a request from the queue = promise!
     * @param cmd
     * @param encryptionType
     * @returns {*}
     * @private
     */


    var _getDeferredForRequest = function _getDeferredForRequest(cmd, encryptionType) {
        var def;

        if (typeof cmd === 'string') {
            def = Q.defer();
            def.command = cmd; // check if the encryption type is okay for both the socket and cmd

            def.encryptionType = checkEncryptionTypeForSocket(isDownloadSocket, encryptionType, cmd);
        } else {
            def = cmd;
        }

        return def;
    };
    /**
     * we have to determine if we can send the command at this point:
     * on normal socket, we only have one queue. DL Socket has 2, and all "download" commands must be queued in the waitingQueue
     * all commands during authentication can be sent directly.
     * @param cmd
     * @returns {*}
     * @private
     */


    var _isSocketReadyForCmd = function _isSocketReadyForCmd(cmd) {
        var socketReadyToGo = ws && ws.socketOpened;

        if (isDownloadSocket) {
            socketReadyToGo = socketReadyToGo && (!socketPromise || // no socketPromise means at this point that the socket is ready (authenticated)!
                cmd.startsWith(Commands.GET_KEY) || cmd.startsWith(Commands.format(Commands.AUTHENTICATE, "")) || cmd.startsWith(Commands.format(Commands.ENCRYPTION.KEY_EXCHANGE, "")) || cmd.startsWith(Commands.format(Commands.ENCRYPTION.AUTHENTICATE, "")) || _isTokenAuthCmd(cmd));
        }

        return socketReadyToGo;
    };
    /**
     * True if the command is either used to authenticate with a token or to acquire a token.
     * @param cmd
     * @returns {boolean}
     * @private
     */


    var _isTokenAuthCmd = function _isTokenAuthCmd(cmd) {
        var isTokenAuth = false;
        isTokenAuth |= cmd.startsWith(Commands.format(Commands.TOKEN.GET_USERSALT, ""));
        isTokenAuth |= cmd.startsWith(Commands.TOKEN.GET_TOKEN_ID);
        isTokenAuth |= cmd.startsWith(Commands.TOKEN.AUTHENTICATE_ID);
        return isTokenAuth;
    };
    /**
     * Will encrypt the command and insert it into the proper encrypted command (fenc or enc) based on the encryptionType
     * @param cmd
     * @param encryptionType
     * @returns {*|string}
     */


    var _getEncryptedCommand = function _getEncryptedCommand(cmd, encryptionType) {
        var salt = encryption.salt;

        var plaintext = "";

        if (!salt) {
            encryption.salt = VendorHub.Crypto.generateSalt();
            encryption.saltTimestamp = Date.now();
            salt = encryption.salt;
        }

        plaintext = Commands.format(Commands.ENCRYPTION.AES_PAYLOAD, salt, cmd);
        const nextRefresh = moment.unix(encryption.saltTimestamp / 1000).add(SALT_EXPIRY, 'ms');
        const remaining = nextRefresh.diff(moment(), 'ms');
        const refreshDuration = moment.duration(remaining);
        Debug.Encryption && console.log(weakThis.name + "Time to next salt refresh: ", `${refreshDuration.minutes().toString().padStart(2, '0')}:${refreshDuration.seconds().toString().padStart(2, '0')}`); // Time remaining to refresh the salt
        Debug.Encryption && console.log(weakThis.name + "plaintext: " + plaintext); // AES encryption

        return VendorHub.Crypto.getLxAesEncryptedCmd(plaintext, encryption.key, encryption.iv, encryptionType);
    };
    /**
     * If this socket is flaged as downloadSocket, the download method is added to the prototype
     */


    if (isDownloadSocket) {
        SocketExt.prototype.readyForDownload = function readyForDownload() {
            Debug.DownloadSocketExt && console.log(weakThis.name + "readyForDownload");
            var reachMode = CommunicationComponent.getCurrentReachMode(),
                connectionReady = reachMode === ReachMode.LOCAL || reachMode === ReachMode.REMOTE;

            if (connectionReady) {
                Debug.DownloadSocketExt && console.log(weakThis.name + "opening socket");
                var host = ActiveMSComponent.getCurrentUrl(),
                    creds = ActiveMSComponent.getCurrentCredentials(),
                    useTokens = Feature.TOKENS; // newer miniservers are capable of using tokens instead of passwords.

                weakThis.open(host, creds.username, creds.password, Feature.ENCRYPTED_SOCKET_CONNECTION, useTokens, creds.token);
                socketPromise.promise.then(function () {
                    Debug.DownloadSocketExt && console.log(weakThis.name + "socket ready, start requests");
                    requests = waitingQueue;
                    waitingQueue = [];
                    sendNextRequest();
                });
            } else {
                Debug.DownloadSocketExt && console.log(weakThis.name + "cannot ready download socket, Miniserver " + "isn't reachable/connected atm.");
            }
        };

        SocketExt.prototype.download = function download(cmd, encryptionType, hasPrio) {
            Debug.DownloadSocketExt && console.log(weakThis.name + "download '" + cmd + "'" + (hasPrio ? " WITH PRIO" : "")); // send now, the command will be put into the queue if the socket isn't opened yet..

            var promise = weakThis.send(cmd, encryptionType, hasPrio);

            if (!socketPromise && (!ws || ws.socketClosed)) {
                weakThis.readyForDownload();
            }

            return promise;
        };
    }
    /**
     * Sends next request in the request Queue
     */


    var sendNextRequest = function sendNextRequest() {
        Debug.Socket.Detailed && console.log(weakThis.name + "sendNextRequest");
        currentRequest = null;

        if (requests.length) {
            var def = requests.shift();
            weakThis.send(def);
            Debug.Socket.Basic && console.info(weakThis.name + "pending requests: " + requests.length);
        } else if (isDownloadSocket) {
            closingTimer = setTimeout(function closeAfterTimeout() {
                Debug.DownloadSocketExt && console.info(weakThis.name + "closes after being", DLSOCKET_MAX_IDLE_TIME / 1000, "seconds idle");
                weakThis.close();
            }, DLSOCKET_MAX_IDLE_TIME);
        }
    };
    /**
     * rejects pending commands after WebSocket did close
     * code SupportCode
     */


    var resetRequestQueue = function resetRequestQueue(code) {
        Debug.Socket.Basic && console.log(weakThis.name + "resetRequestQueue");

        if (currentRequest) {
            currentRequest.reject(code);
            currentRequest = null;
        }

        while (requests.length) {
            var def = requests.shift();
            def.reject(code);
        }
    };
    /**
     * Handles errors of received messages
     * @param error
     * @param code
     */


    var messageErrorHandler = function messageErrorHandler(error, code) {
        currentRequest && currentRequest.reject(code);
    };
    /**
     * Handles response of the websocket, directs the data to the responsible handlers
     * @param text received from the websocket
     * @param type type of message
     */


    var textMessageHandler = function textMessageHandler(text, type) {
        if (isDownloadSocket) {
            Debug.DownloadSocketExt && console.info(weakThis.name + "Miniserver->App: DLSocket " + text);
        } else {
            Debug.Socket.Detailed && console.info(weakThis.name + "Miniserver->App: Socket" + text);
        }

        if (type === BinaryEvent.Type.TEXT) {
            handleResponse(text);
        } else if (type === BinaryEvent.Type.FILE) {
            Debug.Socket.Detailed && console.info(weakThis.name + "received file with text content!");
            handleFile(text);
        } else if (type === BinaryEvent.Type.JSON_RPC) {
            // The Miniserver (currently) does not expect or even parse a response to rpc messages sent to the app.
            handleJsonRPC(text);
        }
    };
    /**
     * handles binary messages from websocket
     * @param data binary data package
     * @param type of message
     */


    var binaryMessageHandler = function binaryMessageHandler(data, type) {
        try {
            switch (type) {
                case BinaryEvent.Type.TEXT:
                    handleBinaryText(data);
                    break;

                case BinaryEvent.Type.FILE:
                    Debug.Socket.Detailed && console.info(weakThis.name + "received file with binary content!");
                    handleFile(data);
                    break;

                case BinaryEvent.Type.JSON_RPC:
                    Debug.Socket.Detailed && console.info(weakThis.name + " received JSON RPC via binary message!");
                    handleJsonRPC(data);
                    break;

                case BinaryEvent.Type.EVENT:
                case BinaryEvent.Type.EVENTTEXT:
                case BinaryEvent.Type.DAYTIMER:
                case BinaryEvent.Type.WEATHER:
                    handleBinaryEvent(data, type);
                    break;

                default:
                    console.warn(weakThis.name + "unknown binaryEvent type received (", type, ")");
                    break;
            }
        } catch (e) {
            console.error(e.stack);
        }
    };
    /**
     * Handles response of webservice requests
     * @param response as string
     * example:
     * {"LL":{"control":"dev/sps/version","value":"6.0.9.12","Code":"200"}}
     * must have a 'control', 'value' and 'Code' property!
     */


    var handleResponse = function handleResponse(response) {
        Debug.Socket.Detailed && console.info(weakThis.name + "received response from request!");

        if (!currentRequest) {
            Debug.Socket.Basic && console.info(weakThis.name + "received some response without request!");
            console.log(JSON.stringify(response));
            return;
        }

        response = _decryptResponse(response, currentRequest.encryptionType);

        try {
            response = JSON.parse(response.trim());
        } catch (e) {
            console.warn(weakThis.name + "ERROR while parsing string: '" + response + "'");
            console.error(e.stack);
            response = _recoverResponse(response);

            if (!response) {
                // recovering failed.
                currentRequest.reject(SupportCode.PARSING_MESSAGE_FAILED);
                return;
            }
        } // request decrypted and parsed, now handle it based on the response code.


        _handleResponseByCode(response);
    };
    /**
     * Expects a parsed and decrypted request which will then be handled further based on the response code.
     * @param response
     * @private
     */


    var _handleResponseByCode = function _handleResponseByCode(response) {
        var code = getLxResponseCode(response);

        if (code >= 200 && code < 300) {
            // ok
            currentRequest.resolve(response);
        } else if (code >= 300 && code < 400) {
            // redirects
            currentRequest.reject(response);
        } else if (code >= 400 && code < 500) {
            // client errors
            // usually this is being checked for inside the handleBadAuthResponse handler. But since on some devices, the
            // promises reject handler is called after the socket has already been closed - it is needed to be handled here.
            if (code === 401 && _isAuthenticating()) {
                // a 401 while authenticating either means the password or the token are invalid.
                invalidToken = _isAuthenticating(true);
                wrongPassword = !invalidToken; // if it's not due to an invalid token, it has to be due to a invalid pass!

                if (invalidToken) {
                    console.error(weakThis.name + "401 returned during token based authentication");
                } else if (wrongPassword) {
                    console.error(weakThis.name + "401 returned during password based authentication");
                } else {
                    console.error(weakThis.name + "401 returned during authentication - nothing set.");
                }
            }

            currentRequest.reject(response);
        } else if (code >= 500 && code < 600) {
            // server errors
            currentRequest.reject(response);
        } else if (code >= 600 && code < 1000) {
            // proprietary errors
            currentRequest.reject(response);
        } else {
            currentRequest.reject(response);
        }
    };
    /**
     * Will check the responses encryption type and decrypt the response from the Miniserver if needed.
     * @param response  the fenc-response from the Miniserver
     * @param encryptionType    if full encryption was used, this method will decrypt it.
     * @returns {*}
     * @private
     */


    var _decryptResponse = function _decryptResponse(response, encryptionType) {
        if (encryptionType !== EncryptionType.REQUEST_RESPONSE_VAL) {
            // response not encrypted
            return response;
        }

        Debug.Encryption && console.log(weakThis.name + "encrypted response: " + response);

        try {
            response = VendorHub.Crypto.aesDecrypt(response, encryption.key, encryption.iv);
            Debug.Encryption && console.log(weakThis.name + "decrypted response: " + response);
        } catch (e) {
            console.error(e.stack);
            currentRequest.reject(SupportCode.DECRYPTING_RESPONSE_VALUE_FAILED);
            return;
        }

        return response;
    };
    /**
     * If parsing a response fails, this method is our backup that tries to retrieve the responses content manually
     * @param response
     * @returns {*}
     * @private
     */


    var _recoverResponse = function _recoverResponse(response) {
        // only try to parse, if 14 " are in string! otherwise it won't work probably
        if (occurrences(response, '"') !== 14) {
            // response cannot be recovered
            return null;
        }

        console.info(weakThis.name + "trying to parse response manually!");
        var res = {
            LL: {}
        };
        /*
         "{"LL": { "control": "dev/sps/listcmds", "value": "2014-10-09 09:50:08 Alarm Česká zbrojovka/0a5fa3e5-0182-8541-ffff112233445566/10.000000", "Code": "200"}}
         "
         */
        //response = response.slice(21);

        response = response.slice(response.indexOf('"') + 1); // go first next property

        response = response.slice(response.indexOf('"') + 1);
        response = response.slice(response.indexOf('"') + 1);
        response = response.slice(response.indexOf('"') + 1);
        response = response.slice(response.indexOf('"') + 1);
        res.LL.control = response.slice(0, response.indexOf('"'));
        response = response.slice(response.indexOf('"') + 1); // go to next property

        response = response.slice(response.indexOf('"') + 1);
        response = response.slice(response.indexOf('"') + 1);
        response = response.slice(response.indexOf('"') + 1);
        res.LL.value = response.slice(0, response.indexOf('"'));
        response = response.slice(response.indexOf('"') + 1); // go to next property

        response = response.slice(response.indexOf('"') + 1);
        response = response.slice(response.indexOf('"') + 1);
        response = response.slice(response.indexOf('"') + 1);
        res.LL.Code = response.slice(0, response.indexOf('"'));
        return cloneObjectDeep(res); // stringify and parse again to be sure it went correct!
    };
    /**
     * Creates an event object from the received bytes and broadcast the result
     * @param data as byte array
     * @param type of binary event
     */


    var handleBinaryEvent = function handleBinaryEvent(data, type) {
        Debug.Socket.Detailed && console.log(weakThis.name + "handleBinaryEvent");
        var msg = new BinaryEvent(data, type);
        commComp.onEventReceived(msg.events, msg.type);
    };
    /**
     * handles a received file
     * @param file (can be binary data or a string)
     */


    var handleFile = function handleFile(file) {
        Debug.Socket.Detailed && console.log(weakThis.name + "handleFile");

        if (currentRequest) {
            currentRequest.resolve(file);
        } else {
            console.error(weakThis.name + "ERROR: received unexpected file!");
        }
    };

    var handleJsonRPC = function handleJsonRPC(text) {
        Debug.Socket.Detailed && console.log(weakThis.name, "handleJsonRPC: " + text);
        commComp.onJsonRPC(text);
    }


    /**
     * Handles binary texts, if miniserver sends some -> should be changed to a Websocket-Text-Message after RFC6455!
     * @param data as byte array
     * DEPRECATED! only here to be able to find mistakes from Miniserver :)
     */


    var handleBinaryText = function handleBinaryText(data) {
        Debug.Socket.Detailed && console.log(weakThis.name + "handleBinaryText");
        console.error(weakThis.name + "ERROR: received binary text: " + arrayBufferToString(data));
        console.info("  please file a bugreport");
    }; // only return the function declaration; it will be instantiated in the component


    return SocketExt;
};
