'use strict';

import globalStyles from "GlobalStyles";
import {getControlForType} from "Controls";
/**
 * Handles the Structure File.
 */

ActiveMSComp.factory('StructureExt', function () {
    // internal variables
    let weakThis,
        activeMsComp = {},
        structureDataAvailable = false,
        structureReachMode = ReachMode.NONE,
        structure = null,
        controlCtors = {},
        // all needed control ctors for this Miniserver
        controls = {},
        rooms = {},
        sortedRooms = [],
        categories = {},
        sortedCategories = [],
        customGroupTitles = {},
        globalStates = {},
        operatingModes = {},
        filteredOperatingModes = {},
        // All operatingModes not used by logic
        weatherServer = null,
        mediaServer = {},
        multipleMediaServer,
        presenceRooms = [],
        times = {},
        caller = {},
        autopilotGenerator = {},
        messageCenter = {};

    /**
     * c-tor for Structure-Extension
     * @param comp reference to the ActiveMSComponent
     * @constructor
     */

    function StructureExt(comp) {
        weakThis = this
        activeMsComp = comp; // internal broadcasts

        activeMsComp.on(ActiveMSComp.ECEvent.StopMSSession, function (event, args) {
            // reset all data
            resetStructureData();
        });
        activeMsComp.on(ActiveMSComp.ECEvent.STRUCTURE_UPDATE, handleStructureUpdateReceived.bind(this));
    } // public methods

    /**
     * received the current structure date of the miniserver from the msInfoExt
     * @param date the current structure date
     */


    StructureExt.prototype.receivedStructureDate = function receivedStructureDate(date) {
        // try to load structure file from local json
        var fileName = activeMsComp.buildStructureFilename();
        var promise = activeMsComp.loadFile(fileName, DataType.OBJECT);
        promise.depActiveMsThen(function (structure) {
            Debug.Structure && console.log(this.name, "successfully loaded structure from FS, check structure date");


            if (date !== structure.structureDate) {
                Debug.Structure && console.log(this.name, "    structure date doesn't match, delete & download");
                ActiveMSComponent.resetSecuredDetails(structure.msInfo.serialNr);
                activeMsComp.deleteAllStructures(CommunicationComponent.getCurrentReachMode()); // download structure from MS

                downloadStructure();
            } else {
                Debug.Structure && console.log(this.name, "     structure is up to date");
                var reachModeDidChange = structureReachMode !== CommunicationComponent.getCurrentReachMode(),
                    brandingDate = structure.msInfo.brandingDate;

                if (!structureDataAvailable || reachModeDidChange) {
                    /*if (!structureDataAvailable) {
                        console.log(" - no structure data available");
                    } else if (reachModeDidChange) {
                        console.log(" - reachModeDidChange");
                    }*/
                    handleStructure(structure).depActiveMsDone(null, handleStructureProcessFail);
                } else if (brandingDate && !AppBranding.isBrandingActive()) {
                    AppBranding.brandApp(brandingDate).depActiveMsThen().finally(function () {
                        //console.log(" - current structure is up to date");
                        activeMsComp.structureReady(structure, false, false);
                    });
                } else {
                    //console.log(" - current structure is up to date");
                    activeMsComp.structureReady(structure, false, false);
                }
            }
        }.bind(this), function (error) {
            Debug.Structure && console.log(this.name, "   couldn't load structure from filesystem, download it"); // download structure from MS

            downloadStructure();
        }.bind(this)).fail(function (e) {
            console.error(e.stack);
        });
    };
    /**
     * Returns the group by the given uuid and group type.
     * @param groupUUID id of the group.
     * @param [groupType] type of the group
     */


    StructureExt.prototype.getGroupByUUID = function getGroupByUUID(groupUUID, groupType) {
        if (groupType === GroupTypes.ROOM) {
            return rooms[groupUUID];
        } else if (groupType === GroupTypes.CATEGORY) {
            return categories[groupUUID];
        } else if (rooms[groupUUID] != null) {
            return rooms[groupUUID];
        } else if (categories[groupUUID] != null) {
            return categories[groupUUID];
        } else {
            console.error("ERROR: can't find group " + groupUUID + " for type " + groupType);
        }
    };

    StructureExt.prototype.getGroupTypeByUUID = function getGroupTypeByUUID(uuid) {
        if (rooms.hasOwnProperty(uuid)) {
            return GroupTypes.ROOM;
        }

        if (categories.hasOwnProperty(uuid)) {
            return GroupTypes.CATEGORY;
        }

        return null;
    };
    /**
     * Returns all groups by the given type
     * @param groupType
     * @param [asObject=false] {boolean} if groups should be returned as object/map
     */


    StructureExt.prototype.getGroupsByType = function getGroupsByType(groupType, asObject) {
        if (groupType === GroupTypes.ROOM) {
            return asObject ? rooms : sortedRooms;
        } else if (groupType === GroupTypes.CATEGORY) {
            return asObject ? categories : sortedCategories;
        } else {
            console.error("ERROR: undefined groupType: " + groupType);
        }
    };

    StructureExt.prototype.getGroupContentByUUID = function getGroupContentByUUID(groupUUID, groupType) {
        var content = {},
            control,
            group,
            groupKey = groupType === GroupTypes.ROOM ? 'room' : 'cat',
            otherGroupKey = groupType === GroupTypes.ROOM ? 'cat' : 'room',
            otherGroupType = groupType === GroupTypes.ROOM ? GroupTypes.CATEGORY : GroupTypes.ROOM;
        var alsoByRating = activeMsComp.getSortByRating(),
            manualRating = false,
            manFavSettings,
            manFavs;

        if (alsoByRating) {
            // when manual favorites are active & sortByRating is active, the content is about to be extended by the
            // manualRatting-attribute
            manFavSettings = activeMsComp.getManualFavoriteSettings();
            manualRating = manFavSettings.activated;
            manFavs = manFavSettings.favorites;
        }

        for (var uuid in controls) {
            if (controls.hasOwnProperty(uuid)) {
                control = controls[uuid];

                if (supportedControlTypes(false).indexOf(control.type) === -1) {
                    // control isn't shown in the control list
                    continue;
                }

                if (control[groupKey] === groupUUID) {
                    if (control.controlType === "Light" || control.controlType === "LightV2") {
                        if (Object.keys(control.subControls).length === 1) {
                            var subCtrl = control.subControls[Object.keys(control.subControls)[0]];

                            if (subCtrl.controlType === ControlType.SWITCH) {
                                //Changing control to the switch-subControl
                                subCtrl.parentControl = control; // set the parent control (if set, ExpertMode will be enabled for this Control!)

                                control = subCtrl;
                            }
                        }
                    } // assign manual rating attribute to control


                    if (manualRating) {
                        if (manFavs.controls.hasOwnProperty(control.uuidAction)) {
                            control.manualRating = manFavs.controls[control.uuidAction];
                        } else {
                            control.manualRating = 0;
                        }
                    }

                    if (control[otherGroupKey] && control[otherGroupKey] !== "") {
                        if (!content[control[otherGroupKey]]) {
                            // subgroup doesn't exist yet
                            group = this.getGroupByUUID(control[otherGroupKey], otherGroupType);
                            group.controls = []; // assign manual rating attribute to group

                            if (manualRating) {
                                if (otherGroupType === GroupTypes.ROOM) {
                                    group.manualRating = manFavs.rooms[group.uuid];
                                } else {
                                    group.manualRating = manFavs.cats[group.uuid];
                                }

                                if (!group.manualRating) {
                                    group.manualRating = 0;
                                }
                            }

                            content[control[otherGroupKey]] = group;
                        }

                        content[control[otherGroupKey]].controls.push(control);
                    } else {
                        if (!content[UnassignedUUID]) {
                            content[UnassignedUUID] = {
                                name: UnassignedUUID,
                                uuid: UnassignedUUID,
                                defaultRating: -1,
                                controls: []
                            };
                        }

                        content[UnassignedUUID].controls.push(control);
                    }
                }
            }
        } // sort!


        var contentArray = [];

        for (var contentUUID in content) {
            if (content.hasOwnProperty(contentUUID)) {
                // sort controls
                sortArrayByName(content[contentUUID].controls);
                alsoByRating && sortArrayByDefOrManRating(content[contentUUID].controls, manualRating);
                contentArray.push(content[contentUUID]);
            }
        } // sort groups


        sortArrayByName(contentArray);
        alsoByRating && sortArrayByDefOrManRating(contentArray, manualRating); // sort unassigned rooms to end!

        var e;

        for (var i = 0; i < contentArray.length; i++) {
            if (e === contentArray[i]) {
                break;
            } // prevent endless loop


            e = contentArray[i];

            if (e.uuid === UnassignedUUID) {
                contentArray.splice(i, 1);
                contentArray.push(e);
                i--;
            }
        } // last but not least sort unassigned to end! -> don't work properly!

        /*contentArray.sort(function (a, b) {
         if (a.uuid === UnassignedUUID) {
         return 1;
         } else if (b.uuid === UnassignedUUID) {
         return -1;
         } else {
         // check index to stay equal!
         if (contentArray.indexOf(a) > contentArray.indexOf(b)) {
         return 1;
         } else {
         return -1;
         }
         }
         });*/


        return contentArray;
    };
    /**
     * Returns the control by the given uuid
     * @param uuid
     */


    StructureExt.prototype.getControlByUUID = function getControlByUUID(uuid) {
        if (Object.keys(controls).length > 0) {
            return controls[uuid] || getSubControlByUUID.call(this, uuid);
        } else {
            console.info("requesting a control, but controls not loaded yet!");
        }
    };
    /**
     * returns all controls (grouped and normal)
     * @param groupType
     * @param groupUuid
     * @param sortByRating if true, sorted by rating instead of name.
     * @returns {Array}
     */


    StructureExt.prototype.getControlsInGroup = function getControlsInGroup(groupType, groupUuid, sortByRating) {
        var ctrls = [];
        var supportedControls = this.getSupportedControls(false);
        supportedControls.forEach(function (ctrl) {
            if (ctrls.indexOf(ctrl) === -1 && (groupType === GroupTypes.ROOM && ctrl.room === groupUuid || groupType === GroupTypes.CATEGORY && ctrl.cat === groupUuid)) {
                ctrls.push(ctrl);
            }
        });

        if (ctrls.length > 0) {
            if (sortByRating) {
                sortArrayByName(ctrls);
            } else {
                sortArrayByDefOrManRating(ctrls);
            }
        }

        return ctrls;
    };
    /**
     * returns all controls for a specific group which are relevant for creating a new sorting structure
     * @param groupType - the type of the group you want all controls (UrlStartLocation.ROOM or .CATEGORY)
     * @param groupUuid - the uuid of the group
     * @param sortByName - if true the controls are sorted only by star rating
     * @param sortByFavorite - if true the controls are sorted by star rating and favorite flag
     * @returns {[]}
     */


    StructureExt.prototype.getControlsInGroupForSorting = function getControlsInGroupForSorting(groupType, groupUuid, sortByName, sortByFavorite) {
        var ctrls = [];
        var supportedControls = this.getSupportedControls(false);
        supportedControls.forEach(function (ctrl) {
            if (groupType === GroupTypes.ROOM && ctrl.room === groupUuid || groupType === GroupTypes.CATEGORY && ctrl.cat === groupUuid) {
                ctrls.pushIfNoDuplicate(ctrl);
            }
        });

        if (ctrls.length > 0) {
            if (sortByName) {
                sortArrayByName(ctrls);
            } else {
                sortArrayByDefOrManRatingSorting(ctrls, null, null, sortByFavorite);
            }
        }

        return ctrls;
    };
    /**
     * returns all central controls that have been rated (for comfort mode)
     * @returns {Array}
     */


    StructureExt.prototype.getCentralControls = function getCentralControls() {
        var centralRooms = this.getCentralRooms(),
            res = [],
            devFavs = activeMsComp.getDeviceFavoriteSettings();
        centralRooms.forEach(function (centralRoom) {
            // if the device favorites have been activated, adopt the central rooms manual rating flag.
            if (devFavs.activated) {
                if (devFavs.favorites && devFavs.favorites.rooms.hasOwnProperty(centralRoom.uuid)) {
                    centralRoom.manualRating = devFavs.favorites.rooms[centralRoom.uuid].rating;
                } else {
                    centralRoom.manualRating = 0;
                }
            }

            res.push({
                room: centralRoom,
                controls: this.getRatedControls(centralRoom.uuid)
            });
        }.bind(this));
        return res;
    };
    /**
     * returns the central room (for comfort mode)
     * @returns [] rooms
     */


    StructureExt.prototype.getCentralRooms = function getCentralRooms() {
        var centralRooms = [],
            roomUuid,
            room;

        for (roomUuid in rooms) {
            if (rooms.hasOwnProperty(roomUuid)) {
                room = rooms[roomUuid];

                if (room.type === RoomType.CENTRAL) {
                    centralRooms.push(room);
                }
            }
        }

        return centralRooms;
    };

    /**
     * Searches the Structure for all controls that have a certain type.
     * @param type The type we're looking for. (AudioZone, Meter, ..)
     * @param sorted
     * @returns {Array}
     */
    StructureExt.prototype.getControlsByType = function getControlsByType(type, sorted = false) {
        // TODO change to appType!
        var resultControls = [];

        for (var uuid in controls) {
            var control = controls[uuid];

            if (control.type === type || control.controlType === type) {
                resultControls.push(control);
            }
        }

        if (resultControls.length > 0 && sorted) {
            sortArrayByDefOrManRating(resultControls);
        }

        return resultControls;
    };
    /**
     * Returns all controls
     */


    StructureExt.prototype.getAllControls = function getAllControls() {
        return controls;
    };
    /**
     * Returns all controls
     */


    StructureExt.prototype.getAllSceneControls = function getAllSceneControls(ignoreSecured) {
        return this.getControlsByType(ControlType.PUSHBUTTON).filter(function (control) {
            if (ignoreSecured) {
                return !control.isSecured && control.isAutomaticScene;
            } else {
                return control.isAutomaticScene;
            }
        });
    };
    /**
     * returns an array of all used control types
     * @param {Array|null} controlsToCheck if null, all controls will be checked
     * @param {Array|null} usedControlTypes used to avoid duplicates
     * @returns {Array}
     */


    StructureExt.prototype.getAllUsedControlTypes = function getAllUsedControlTypes(controlsToCheck, usedControlTypes) {
        controlsToCheck = controlsToCheck || Object.values(this.getAllControls());
        usedControlTypes = usedControlTypes || [];
        controlsToCheck.forEach(function (ctrl) {
            if (usedControlTypes.indexOf(ctrl.controlType) === -1) {
                usedControlTypes.push(ctrl.controlType);
            }

            if (ctrl.subControls) {
                this.getAllUsedControlTypes(Object.values(ctrl.subControls), usedControlTypes);
            }
        }.bind(this));
        return usedControlTypes;
    };
    /**
     * Returns an array containing all supported controls in this structure.
     * @param includeNonVisualized  optional, if missing those without an UI are not included (e.g. presence)
     * @param includeRestricted  optional
     * @returns {Array}
     */


    StructureExt.prototype.getSupportedControls = function getSupportedControls(includeNonVisualized, includeRestricted) {
        var ctrlTypes = supportedControlTypes(includeNonVisualized),
            ctrls = [];
        Object.values(controls).forEach(function (ctrl) {
            if (!_isControlRestricted(ctrl) || includeRestricted) {
                if (ctrlTypes.indexOf(ctrl.type) >= 0) {
                    ctrls.push(ctrl);
                }
            }
        });
        return ctrls;
    };
    /**
     * Returns an array containing all controls that are linked from the controls passed in via listControls
     * Covers both linked objects and those referenced by
     * @param listControls          the controls of which to fetch the links/references from.
     * @param [withSystemScheme]    if true, the controls referenced within the system schemes are also part of the result.
     * @returns {*[]} a map of the controls linked/referenced by those passed in via listControls
     */


    StructureExt.prototype.getLinkedControlsOf = function getLinkedControlsOf(listControls, withSystemScheme) {
        var linkedControls = {};
        listControls.forEach(function (ctrl) {
            ctrl.links && ctrl.links.forEach(function (ctrlUuid) {
                if (controls.hasOwnProperty(ctrlUuid) && !linkedControls.hasOwnProperty(ctrlUuid)) {
                    linkedControls[ctrlUuid] = controls[ctrlUuid];
                }
            }); // if allowed, also retrieve the system scheme controls

            !!withSystemScheme && ctrl.details && ctrl.details.controlReferences && ctrl.details.controlReferences.forEach(function (refObj) {
                if (controls.hasOwnProperty(refObj.uuidAction) && !linkedControls.hasOwnProperty(refObj.uuidAction)) {
                    linkedControls[refObj.uuidAction] = controls[refObj.uuidAction];
                }
            });
        });
        return linkedControls;
    };
    /**
     * Returns the favorite groups by the given type.
     * @param groupType The group type of the group (e.g. ROOM...)
     * @param [limit] The max number of favorite groups (0 = unlimited)
     */


    StructureExt.prototype.getFavoriteGroupsByGroupType = function getFavoriteGroupsByGroupType(groupType, limit, forFavoriteChanges) {
        var i,
            favGroups = [],
            deviceFavs = {},
            deviceFavSettings = activeMsComp.getDeviceFavoriteSettings(),
            devFavsGroupType = groupType === GroupTypes.ROOM ? "rooms" : "cats";

        if (deviceFavSettings.activated && deviceFavSettings.hasOwnProperty("favorites") && !!deviceFavSettings.favorites && !!deviceFavSettings.favorites[devFavsGroupType]) {
            if (groupType === GroupTypes.ROOM) {
                deviceFavs = deviceFavSettings.favorites.rooms;
            } else if (groupType === GroupTypes.CATEGORY) {
                deviceFavs = deviceFavSettings.favorites.cats;
            }

            for (var uuid in deviceFavs) {
                // only show those who have been marked as favorite.
                if (deviceFavs.hasOwnProperty(uuid) && deviceFavs[uuid].isFavorite) {
                    var group = weakThis.getGroupByUUID(uuid, groupType);
                    group.manualRating = deviceFavs[uuid].rating;

                    if (!ActiveMSComponent.getSortingStructure()) {
                        // old star rating
                        if (!deviceFavs[uuid].newSorting) {
                            favGroups.push(group);
                        }
                    } else {
                        // new sorting
                        if (forFavoriteChanges) {
                            if (deviceFavs[uuid].isFavorite) {
                                favGroups.push(group);
                            }
                        } else {
                            var devSpecSortingObject = ActiveMSComponent.getSortingStructureForObject(uuid, UrlStartLocation.FAVORITES);

                            if (devSpecSortingObject && devSpecSortingObject.isFav) {
                                favGroups.pushObject(group);
                            }
                        }
                    }
                }
            }
        } else {
            var groups = weakThis.getGroupsByType(groupType);

            for (i = 0; i < groups.length; i++) {
                if (!ActiveMSComponent.getSortingStructure()) {
                    // use old star rating
                    if (groups[i].isFavorite) {
                        favGroups.push(groups[i]);
                    }
                } else {
                    // new sorting
                    var sortingObject = ActiveMSComponent.getSortingStructureForObject(groups[i].uuid, UrlStartLocation.FAVORITES);

                    if (sortingObject && sortingObject.isFav) {
                        favGroups.push(groups[i]);
                    }
                }
            }
        } // sorting


        sortArrayByName(favGroups); // always sort fav-groups!

        sortArrayByDefOrManRating(favGroups, deviceFavSettings.activated); // delete the manual rating again, only used for sort function before

        if (deviceFavSettings.activated) {
            for (i = 0; i < favGroups.length; i++) {
                delete favGroups[i].manualRating;
            }
        } // just get favorite function to limit


        return favGroups.slice(0, limit);
    };
    /**
     * Returns the favorite controls
     * @param [limit] The max number of favorite functions (0 = unlimited)
     */


    StructureExt.prototype.getFavoriteControls = function getFavoriteControls(limit = 0) {
        var favControls = [],
            deviceFavSettings = activeMsComp.getDeviceFavoriteSettings(),
            control,
            devFavs,
            i;

        if (deviceFavSettings.activated && deviceFavSettings.hasOwnProperty("favorites") && !!deviceFavSettings.favorites && deviceFavSettings.favorites.hasOwnProperty("controls")) {
            devFavs = deviceFavSettings.favorites.controls;
            Object.keys(devFavs).forEach(function (uuid) {
                if (devFavs[uuid].isFavorite) {
                    control = weakThis.getControlByUUID(uuid);

                    if (!ActiveMSComponent.getSortingStructure()) {
                        // old star rating
                        // Control will be undefined if the required control has not been loaded yet
                        if (control && !devFavs[uuid].newSorting) {
                            control.manualRating = devFavs[uuid].rating;
                            favControls.push(control);
                        }
                    } else {
                        // new sorting
                        var sortingObject = ActiveMSComponent.getSortingStructureForObject(uuid, UrlStartLocation.FAVORITES);

                        if (sortingObject && control && sortingObject.isFav) {
                            control.manualRating = devFavs[uuid].rating;
                            favControls.push(control);
                        }
                    }
                }
            });
        } else {
            var supportedControls = this.getSupportedControls(false);
            supportedControls.forEach(function (control) {
                if (!ActiveMSComponent.getSortingStructure()) {
                    // use old star rating
                    if (control.isFavorite) {
                        favControls.push(control);
                    }
                } else {
                    var sortingObject = ActiveMSComponent.getSortingStructureForObject(control.uuidAction, UrlStartLocation.FAVORITES);

                    if (sortingObject && sortingObject.isFav) {
                        favControls.push(control);
                    }
                }
            }.bind(this));
        } // sorting


        sortArrayByName(favControls); // always sort fav-groups!

        sortArrayByDefOrManRating(favControls, deviceFavSettings.activated); // delete the manual rating again, only used for sort function before

        if (deviceFavSettings.activated) {
            for (i = 0; i < favControls.length; i++) {
                delete favControls[i].manualRating;
            }
        } // just get favorite function to limit


        if (limit > 0) {
            favControls.slice(0, limit);
        }

        return favControls;
    };
    /**
     * Returns the rated controls from a group.
     * @param [filterGroupUuid]     filters for the given room or category uuid
     * @param [filterFavorites]     if true, only favorites are returned.
     */


    StructureExt.prototype.getRatedControls = function getRatedControls(filterGroupUuid, filterFavorites) {
        var uuid,
            favControls = [],
            deviceFavSettings = activeMsComp.getDeviceFavoriteSettings(),
            devFavsActive = deviceFavSettings.activated,
            deviceFavs,
            control,
            groupType,
            groupFilterFn = function (ctrl) {
                if (!filterGroupUuid) {
                    return true;
                }

                return ctrl.room === filterGroupUuid || ctrl.cat === filterGroupUuid;
            };

        if (filterGroupUuid) {
            groupType = this.getGroupByUUID(filterGroupUuid).groupType;
        }

        if (devFavsActive && deviceFavSettings.hasOwnProperty("favorites") && !!deviceFavSettings.favorites && deviceFavSettings.favorites.hasOwnProperty("controls")) {
            deviceFavs = deviceFavSettings.favorites.controls;
            Object.keys(deviceFavs).forEach(function (uuid) {
                if (deviceFavs[uuid].rating > 0 || deviceFavs[uuid].newSorting) {
                    control = weakThis.getControlByUUID(uuid); // Control will be undefined if the required control has not been loaded yet

                    if (control) {
                        control.manualRating = deviceFavs[uuid].rating;
                        var sortingFav = true;

                        if (!ActiveMSComponent.getSortingStructure()) {
                            // old star rating
                            if (groupFilterFn(control) && !deviceFavs[uuid].newSorting) {
                                favControls.push(control);
                            }
                        } else {
                            sortingFav = getFavSortingObectIfExists(control, filterGroupUuid);

                            if (sortingFav && sortingFav.isFav && groupFilterFn(control)) {
                                favControls.push(control);
                            }
                        }
                    }
                }
            });
        } else {
            var supportedControls = this.getSupportedControls(false);
            supportedControls.forEach(function (control) {
                var sortingFav = true;

                if (!ActiveMSComponent.getSortingStructure()) {
                    // old star rating
                    if (control.defaultRating > 0 && groupFilterFn(control) && (filterFavorites ? control.isFavorite : true)) {
                        // optionally also filter by isFavorites
                        favControls.push(control);
                    }
                } else {
                    sortingFav = getFavSortingObectIfExists(control, filterGroupUuid);

                    if (groupFilterFn(control) && sortingFav && sortingFav.isFav) {
                        // optionally also filter by isFavorites
                        favControls.push(control);
                    }
                }
            }.bind(this));
        } // sorting


        sortArrayByName(favControls); // always sort fav-groups!

        sortArrayByDefOrManRating(favControls, devFavsActive, null, groupType);
        return favControls;
    };

    var getFavSortingObectIfExists = function getFavSortingObectIfExists(control, filterGroupUuid) {
        var location;

        if (control.room === filterGroupUuid) {
            location = UrlStartLocation.ROOM;
        }

        if (control.cat === filterGroupUuid) {
            location = UrlStartLocation.CATEGORY;
        }

        return ActiveMSComponent.getSortingStructureForObject(control.uuidAction, location);
    };
    /**
     * Returns the Miniserver favorites in the same format as manual favorites are listed
     * @returns {object} an object containing all the rooms/cats/controls that are markes ad favorites in the structure
     *          file {{rooms: {..}, cats: {..}, controls: {..}}}
     */


    StructureExt.prototype.getMiniserverFavorites = function getMiniserverFavorites() {
        var miniserverFavs = {
            rooms: {},
            cats: {},
            controls: {}
        };

        var _mapFn = function (obj, trgt, uuidSel) {
            if (obj.isFavorite) {
                trgt[obj[uuidSel]] = obj.defaultRating;
            }
        };

        Object.keys(rooms).forEach(function (key) {
            _mapFn(rooms[key], miniserverFavs.rooms, "uuid");
        });
        Object.keys(categories).forEach(function (key) {
            _mapFn(categories[key], miniserverFavs.cats, "uuid");
        });
        Object.keys(controls).forEach(function (key) {
            _mapFn(controls[key], miniserverFavs.controls, "uuidAction");
        });
        return miniserverFavs;
    };
    /**
     * Returns the custom group names set in Loxone Config
     * @returns {*}
     */


    StructureExt.prototype.getCustomGroupTitles = function getCustomGroupTitles() {
        return customGroupTitles;
    };
    /**
     * Sets the current sortByRating flag
     * @param sbr
     * @param serialNo
     */


    StructureExt.prototype.setSortByRating = function setSortByRating(sbr, serialNo) {
        if (sortedRooms && sortedCategories) {
            sortGroups(sortedRooms, sbr);
            sortGroups(sortedCategories, sbr);
            sortGroups(presenceRooms, sbr, true);
        }
    };
    /**
     * Returns the sef of global states
     * @returns {*} map with all UUIDs globally available
     */


    StructureExt.prototype.getGlobalStateUUIDs = function getGlobalStateUUIDs() {
        return globalStates;
    };

    StructureExt.prototype.getOperatingModes = function getOperatingModes(modeNr, filtered) {
        var opModesObj = filtered ? filteredOperatingModes : operatingModes;

        if (modeNr === true) {
            return opModesObj;
        } else if (modeNr || modeNr === 0) {
            // 0 === holiday/feast day
            return opModesObj[modeNr];
        } else {
            return false;
        }
    };

    StructureExt.prototype.getTimes = function getTimes(timeNr) {
        if (!times) {
            return false;
        } else if (timeNr) {
            return times[timeNr];
        } else {
            return times;
        }
    };

    StructureExt.prototype.getCallerServices = function getCallerServices(callerNr) {
        if (!caller) {
            return false;
        } else if (callerNr) {
            return caller[callerNr];
        } else {
            return caller;
        }
    };

    StructureExt.prototype.getWeatherFieldTypes = function getWeatherFieldTypes(typeNr) {
        if (!weatherServer) {
            return false;
        } else if (typeNr) {
            return weatherServer.details.weatherFieldTypes[typeNr];
        }

        return weatherServer.details.weatherFieldTypes;
    };

    StructureExt.prototype.getWeatherServer = function getWeatherServer() {
        return weatherServer;
    };

    StructureExt.prototype.getAutopilotGenerator = function getAutopilotGenerator() {
        var theOne = null;

        try {
            if (autopilotGenerator != null) {
                var keys = Object.keys(autopilotGenerator);

                if (keys.length > 0) {
                    theOne = autopilotGenerator[keys[0]];
                }
            }
        } catch (e) {
            console.error("Error while looking for the Autopilot Generator!");
            console.error(e.stack);
        }

        return theOne;
    };

    StructureExt.prototype.getMessageCenter = function getMessageCenter() {
        var theOne = null;

        try {
            if (messageCenter != null) {
                var keys = Object.keys(messageCenter);

                if (keys.length > 0) {
                    theOne = messageCenter[keys[0]];
                }
            }
        } catch (e) {
            console.error("Error while looking for the MessageCenter!");
            console.error(e.stack);
        }

        return theOne;
    };

    StructureExt.prototype.getMediaServer = function getMediaServer() {
        // mediaServer might hold more than one MediaServer, but we are interested in the Loxone Multimedia Server and
        // just like in "The highlander", there can only be one!
        var theOne = null;

        try {
            if (mediaServer != null) {
                var keys = Object.keys(mediaServer);

                for (var i = 0; i < keys.length; i++) {
                    var candidate = mediaServer[keys[i]];

                    if (candidate.type === MediaServerType.LXMUSIC) {
                        theOne = candidate;
                        break; // we've found him, lets get outta here.
                    }
                }
            }
        } catch (e) {
            console.error("Error while looking for the Loxone Multimedia Server!");
            console.error(e.stack);
        }

        return theOne;
    };
    /**
     * Returns the mediaServers set. Contains different types of mediaServers (e.g. Loxone Music Server, Casatunes)
     * @returns {{}}
     */


    StructureExt.prototype.getMediaServerSet = function getMediaServerSet() {
        return mediaServer;
    };
    /**
     * All rooms which are supported by a presence controller
     * @returns {Array}
     */


    StructureExt.prototype.getPresenceRooms = function getPresenceRooms() {
        return presenceRooms;
    };

    StructureExt.prototype.isPresenceControlUUID = function isPresenceControlUUID(uuid) {
        var controlForUUID;
        controlForUUID = presenceRooms.find(function (obj) {
            return obj.control.uuidAction === uuid;
        });
        return !!controlForUUID;
    }; // Private methods

    /**
     * Trigger download Structure File from Miniserver
     */


    var downloadStructure = function downloadStructure() {
        Debug.Structure && console.log("Download structure from MS"); //activeMsComp.emit(ActiveMSComp.ECEvent.DownloadingStructure);

        activeMsComp.showDownloadingStructureWaiting();
        CommunicationComponent.sendViaHTTP(Commands.STRUCTURE_FILE).depActiveMsDone(function (structureString) {
            try {
                var structure = structureString;

                if (typeof structure === "object") {
                    structureString = JSON.stringify(structure);
                } else {
                    structure = JSON.parse(structureString);
                }
            } catch (e) {
                console.error("Can't parse structure!");
                console.error(e.stack);
                activeMsComp.structureDownloadFailed(ErrorCode.StructureParserError);
                return;
            }

            handleDownloadedStructure(structure, structureString);
        }, function (error) {
            console.error("Promise handling for Structure Download failed (" + JSON.stringify(error) + ")");

            if (error && parseInt(error) === ResponseCode.FORBIDDEN) {
                activeMsComp.structureDownloadFailed(ResponseCode.FORBIDDEN);

            } else if (error && error.message && error.message.includes(ResponseCode.FORBIDDEN)) {
                activeMsComp.structureDownloadFailed(ResponseCode.FORBIDDEN);

            } else {
                activeMsComp.structureDownloadFailed(ErrorCode.StructureDownloadError);
            }
        }, function (progress) {
            Debug.Structure && console.log("LoxAPP3.json download progress: " + progress);

            if (typeof progress === "number") {
                activeMsComp.showDownloadingStructureWaiting(progress);
            }
        });
    };

    var handleDownloadedStructure = function handleDownloadedStructure(structure, structureString) {
        try {
            if (structureIsOkay(structure.rooms, structure.cats)) {
                handleStructure(structure).depActiveMsDone(function () {
                    validateStoredStructureInfos(); // Mantis 7377: take the webservice date (= file change date) and save it to the structure!
                    // lastModified = last modification from .loxone file!

                    var structureToSave = JSON.parse(structureString); // use the "plain" string again, not the reference (got modified)

                    structureToSave.structureDate = activeMsComp.getStructureDate();
                    saveStructure(structureToSave);
                }, handleStructureProcessFail);
            } else {
                console.error("Structure has no controls, rooms and categories");
                activeMsComp.structureDownloadFailed(ErrorCode.StructureIncomplete);
            }
        } catch (e) {
            console.error("Can't parse structure!");
            console.error(e.stack);
            handleStructureProcessFail();
        }
    };

    var structureIsOkay = function structureIsOkay(rooms, cats) {
        return typeof rooms === "object" && Object.keys(rooms).length > 0 && typeof cats === "object" && Object.keys(cats).length > 0;
    };
    /**
     * 1) requires all used controls
     * 2) initializes the used controls
     */


    var initializeControls = function initializeControls(structure) {

        let controlPrms = ActiveMSComponent.getStructureManager().getAllUsedControlTypes().map(cType => {
            return getControlForType(cType)
        });

        return Q.all([
            ...controlPrms,
            getControlForType()
        ]).then(cTors => {
            controlCtors = {};
            cTors.forEach(cTor => controlCtors[cTor.name] = cTor); // now init each control

            initControlObjects(controls); // add the central references after all controls have been initialized, so that the refs are correctly!
            addCentralControlRefs(controls);
        }, e => {
            console.error(e.stack);
            handleStructureProcessFail();
        });
    };
    /**
     * goes through all controls (and subControls) and inits an object for each one
     * (either the correct one or a plain "Control")
     * @param ctrls
     * @param hasParentControl
     */


    var initControlObjects = function initControlObjects(ctrls, hasParentControl) {
        var ctrl, ctorName, uuid;

        for (uuid in ctrls) {
            if (ctrls.hasOwnProperty(uuid) && ctrls[uuid].constructor === Object) {
                // Only initialize uninitialized controls
                ctrl = ctrls[uuid];
                ctorName = ctrl.controlType + "Control";

                if (!controlCtors.hasOwnProperty(ctorName)) {
                    console.info("No specific Control Implementation available for: " + ctrl.name + " (" + ctorName + ") - using generic");
                    ctrls[uuid] = new controlCtors["Control"](ctrl, hasParentControl);
                } else {
                    ctrls[uuid] = new controlCtors[ctorName](ctrl, hasParentControl);
                }
                if (ctrl.subControls) {
                    initControlObjects(ctrl.subControls, true);
                }
            }
        }
    };
    /**
     * 1) requires the WeatherControl if a weatherServer is configured
     * 2) initializes a new WeatherControl
     * @return Always resolved promise
     * @param structure
     */


    var initWeatherControl = function initWeatherControl(structure) {
        var def = Q.defer(),
            hasWeather = structure.weatherServer && Object.keys(structure.weatherServer).length > 0;

        if (hasWeather) {
            try {
                weatherServer = new (require("WeatherControl"))(structure.weatherServer);
                def.resolve();
            } catch (e) {
                console.error(e.stack);
                handleStructureProcessFail();
                def.reject();
            }
        } else {
            def.resolve();
        }

        return def.promise;
    };
    /**
     * adds the actual ControlObject refs to the central objects (to details.controls)
     * @param ctrls
     */


    var addCentralControlRefs = function addCentralControlRefs(ctrls) {
        var ctrl, uuid;

        for (uuid in ctrls) {
            if (ctrls.hasOwnProperty(uuid)) {
                ctrl = ctrls[uuid];

                if (ctrl.isGrouped()) {
                    ctrl.details.controls.forEach(function (ctrlInfo, idx) {
                        ctrl.details.controls[idx].control = controls[ctrlInfo.uuid];
                    });
                }
            }
        }
    };
    /**
     * This method needs to be called at least once when the app connects to a miniserver & builds up a new UI. It
     * will e.g. apply the branding and then call "setStructure" which will take care of "preparing" it's data for the
     * UI.
     * @param structure
     * @returns {Promise}
     */


    var handleStructure = function handleStructure(structure) {
        var brandingDate = structure.msInfo.brandingDate,
            serialNo = ActiveMSComponent.getActiveMiniserver().serialNo; // save current serialNo to closure, so that we use the correct one when saving branding date (if we cancel, it may have changed - depActiveMsThen)

        if (brandingDate) {
            return AppBranding.brandApp(brandingDate).depActiveMsThen(function (success) {
                return processStructure(structure).then(function () {
                    if (success) {
                        // set the branding date after setStructure (= structure ready..) because after that the Miniserver is in the archive.
                        PersistenceComponent.setBrandingDate(serialNo, brandingDate);
                    }
                });
            }, function (e) {
                console.error(e.stack);
                return processStructure(structure);
            });
        } else {
            return processStructure(structure);
        }
    };
    /**
     * loads and normalizes rooms, categories and controls
     * and initializes everything
     * finally setStructure will be called
     * @param structure
     * @param calledFromStructureUpdate
     * @returns {Promise}
     */


    var processStructure = function processStructure(structure, calledFromStructureUpdate) {
        resetStructureData(); // reset, before set again (RoomMode rooms etc. would otherwise be duplicated...)

        sortedRooms = normalizeGroups(structure.rooms, GroupTypes.ROOM, structure.controls); // = rooms, but only those with controls to display

        rooms = structure.rooms; // = all rooms (with eg. only presencedetectors..)

        sortedCategories = normalizeGroups(structure.cats, GroupTypes.CATEGORY, structure.controls); // = cats, but only those with controls to display

        categories = structure.cats; // = all categories (with eg. only presencedetectors..)

        mediaServer = readMediaServers(structure);
        normalizeControls(structure.controls);
        controls = structure.controls;
        return initializeControls(structure).then(function () {
            return initWeatherControl(structure).then(function () {
                return setStructure(structure, calledFromStructureUpdate);
            });
        });
    };
    /**
     * received Structure, either from the Miniserver or from the Filesystem. It will make sure that everything is ready
     * to be shown on the UI.
     * @param data received structure json string
     * @param preventStructureReady if true, no structureReady event will be fired (eg. when only updating a single control)
     */


    var setStructure = function setStructure(data, preventStructureReady) {
        structureReachMode = CommunicationComponent.getCurrentReachMode();
        structure = data; // don't clone, we need references everywhere (eg. switch on statistics via ExpertMode -> the statistics property must be available everywhere (eg. StateContainer of EnergyMonitor)

        try {
            data.msInfo.serialNr = data.msInfo.serialNr.toUpperCase(); // in Loxone Config, the mac is editable (eg. to lowercase). sanitize here - otherwise settings etc. won't work (126319309)
            //determine whether or not this is the first structure received.

            var serialNo = activeMsComp.getMiniserverSerialNo(),
                // don't use "data.msInfo.serialNo", because it can be different to the actual serialNo!
                settings = PersistenceComponent.getMiniserverSettings(serialNo),
                isInitialStructure = !settings;
            mediaServer = readMediaServers(data);
            globalStates = data.globalStates;
            operatingModes = data.operatingModes;

            if (data.hasOwnProperty("modes")) {
                filteredOperatingModes = {};
                Object.values(data.modes).forEach(function (opModeObj) {
                    // Check if the mode is locked -> Used in logic
                    if (!opModeObj.locked) {
                        filteredOperatingModes[opModeObj.id] = opModeObj.name;
                    }
                });
            }

            autopilotGenerator = data.autopilot;
            messageCenter = data.messageCenter;
            times = data.times;
            caller = data.caller; // e.g. presence rooms

            loadControlSpecificRooms();
            var tmpSorting = isInitialStructure ? data.msInfo.sortByRating : settings.sortByRating;
            weakThis.setSortByRating(tmpSorting, serialNo); // not needed to go over components, info is already from the persistence
            // read custom room & category titles --> due to a miniserver issue, this might not exist in the structure.json

            if (data.msInfo.hasOwnProperty('roomTitle') && data.msInfo['roomTitle'] !== "") {
                customGroupTitles[GroupTypes.ROOM] = data.msInfo['roomTitle'];
            } else {
                customGroupTitles[GroupTypes.ROOM] = _("room");
            }

            if (data.msInfo.hasOwnProperty('catTitle') && data.msInfo['catTitle'] !== "") {
                customGroupTitles[GroupTypes.CATEGORY] = data.msInfo['catTitle'];
            } else {
                customGroupTitles[GroupTypes.CATEGORY] = _("category");
            }

            structureDataAvailable = true;
            var structureReadyPromise;

            if (!preventStructureReady) {
                // structure ready --> forward to NavigationComp
                structureReadyPromise = activeMsComp.structureReady(data, true);
            } // if this was the initial structure, some default settings might need to be initialized
            // important to do this after structure ready, otherwise the miniserver isn't added to the settings yet


            if (isInitialStructure) {
                // sorting - take initial value from the structure!
                activeMsComp.setSortByRating(data.msInfo.sortByRating, serialNo);
            }

            return Q.fcall(function () {
                return structureReadyPromise;
            });
        } catch (e) {
            console.error("Can't process structure!");
            console.error(e.stack);
            return Q.fcall(function () {
                throw e;
            });
        }
    };

    var handleStructureUpdateReceived = function handleStructureUpdateReceived(event, updatePacket) {
        console.info("RECEIVED STRUCTURE UPDATE!");
        var newControls = [];

        try {
            var update = function (updateKey, structureToAdopt) {
                if (typeof updatePacket[updateKey] === "object") {
                    var uuidsToUpdate = Object.keys(updatePacket[updateKey]);
                    uuidsToUpdate && uuidsToUpdate.forEach(function (uuidToUpdate) {
                        if (updatePacket[updateKey][uuidToUpdate] === null) {
                            delete structureToAdopt[updateKey][uuidToUpdate];
                        } else if (typeof updatePacket[updateKey][uuidToUpdate] === "object") {
                            if (!structureToAdopt[updateKey][uuidToUpdate]) {
                                newControls.push(updatePacket[updateKey][uuidToUpdate]);
                                structureToAdopt[updateKey][uuidToUpdate] = updatePacket[updateKey][uuidToUpdate];
                            } else {
                                // update the object, don't replace it (to keep references)
                                updateObject(structureToAdopt[updateKey][uuidToUpdate], updatePacket[updateKey][uuidToUpdate]); // eg controls may need to respond to the structure updated

                                if (structureToAdopt[updateKey][uuidToUpdate].onStructureUpdated) {
                                    structureToAdopt[updateKey][uuidToUpdate].onStructureUpdated.call(structureToAdopt[updateKey][uuidToUpdate]);
                                }
                            }

                            console.info(JSON.stringify(updatePacket[updateKey][uuidToUpdate]));
                        }
                    });
                }
            }; // method to update a structure object


            var updateStructure = function (structureToAdopt) {
                update("controls", structureToAdopt);
                update("rooms", structureToAdopt);
                update("cats", structureToAdopt);
                structureToAdopt.structureDate = updatePacket.lastModified;
            };

            updateStructure(structure); // update the currently-in-use-structure

            ActiveMSComponent.setStructureDate(updatePacket.lastModified); // as soon as the structure of the current reach mode changes, the structure of the other reachmode becomes invalid.

            deleteOtherStructure();
            processStructure(structure, true).finally(function () {
                validateStoredStructureInfos();
                CompChannel.emit(CCEvent.STRUCTURE_UPDATE_FINISHED, newControls);
                NavigationComp.dispatchEventToUI(NavigationComp.UiEvents.StructureChanged, updatePacket); // update the persistent structure

                var fileName = activeMsComp.buildStructureFilename();
                activeMsComp.loadFile(fileName, DataType.OBJECT).depActiveMsThen(function (structureToAdopt) {
                    updateStructure(structureToAdopt);
                    saveStructure(structureToAdopt);
                });
            });
        } catch (e) {
            console.error(e.stack);
        }
    };
    /**
     * Will populate return the mediaServers attribute and adopt the multipleMediaServer-Attributes.
     * @param structureObj
     * @returns {*}
     */


    var readMediaServers = function readMediaServers(structureObj) {
        var servers = structureObj.mediaServer;

        if (servers) {
            var keys = Object.keys(servers);
            var musicSvrCnt = 0;

            for (var i = 0; i < keys.length; i++) {
                var candidate = servers[keys[i]];

                if (candidate.type === MediaServerType.LXMUSIC || candidate.type === MediaServerType.LXMUSIC_V2) {
                    musicSvrCnt++;
                }
            }

            multipleMediaServer = musicSvrCnt > 1;
        }

        return servers;
    };
    /**
     * Validates if the data the app may has stored offline is still available in this new structure.
     * E.g. removes any manual favorites (rooms/cats/controls) that are no longer available in this structure
     */


    var validateStoredStructureInfos = function validateStoredStructureInfos() {
        var defaultSettings = activeMsComp.getDefaultMiniserverSettings(); // FAVORITES

        _validateDeviceFavorites();

        _validateManualFavorites(); // PRESENCE


        if (LoxoneControl.hasLoxoneControl()) {
            // verify if the currently set presence room still exists in this structure
            var presenceRoomUUID = activeMsComp.getPresenceRoom();

            if (presenceRoomUUID && !_checkIfControlStillExists(presenceRoomUUID)) {
                activeMsComp.setPresenceRoom(defaultSettings.PRESENCE_ROOM);
            } else {
                console.log("PresenceControl: Do nothing! --> Control with uuid " + presenceRoomUUID + "still exists");
            }
        }
    };

    var _checkIfControlStillExists = function _checkIfControlStillExists(controlUuid) {
        return !!controls[controlUuid];
    };
    /**
     * Iterates over the current favorites and ensures each favorite stored there still exists. If it does not exist any
     * longer, it will be removed from the device favorites & the upated favorites will be stored again.
     * @private
     */


    var _validateDeviceFavorites = function _validateDeviceFavorites() {
        var deviceFavs = activeMsComp.getDeviceFavoriteSettings().favorites,
            favsChanged = false,
            uuid;

        if (deviceFavs) {
            for (uuid in deviceFavs.rooms) {
                if (deviceFavs.rooms.hasOwnProperty(uuid) && !weakThis.getGroupByUUID(uuid, GroupTypes.ROOM)) {
                    favsChanged = true;
                    delete deviceFavs.rooms[uuid];
                }
            }

            for (uuid in deviceFavs.cats) {
                if (deviceFavs.cats.hasOwnProperty(uuid) && !weakThis.getGroupByUUID(uuid, GroupTypes.CATEGORY)) {
                    favsChanged = true;
                    delete deviceFavs.cats[uuid];
                }
            }

            for (uuid in deviceFavs.controls) {
                if (deviceFavs.controls.hasOwnProperty(uuid) && !weakThis.getControlByUUID(uuid)) {
                    favsChanged = true;
                    delete deviceFavs.controls[uuid];
                }
            }
        }

        favsChanged && activeMsComp.setDeviceFavorites(deviceFavs);
    };

    var _validateManualFavorites = function validateManualFavorites() {
        var favsChanged = false;

        if (!!activeMsComp.getManualFavoriteSettings()) {
            var manualFavs = activeMsComp.getManualFavoriteSettings().favorites,
                uuid;

            if (manualFavs) {
                for (uuid in manualFavs.rooms) {
                    if (manualFavs.rooms.hasOwnProperty(uuid) && !weakThis.getGroupByUUID(uuid, GroupTypes.ROOM)) {
                        favsChanged = true;
                        delete manualFavs.rooms[uuid];
                    }
                }

                for (uuid in manualFavs.cats) {
                    if (manualFavs.cats.hasOwnProperty(uuid) && !weakThis.getGroupByUUID(uuid, GroupTypes.CATEGORY)) {
                        favsChanged = true;
                        delete manualFavs.cats[uuid];
                    }
                }

                for (uuid in manualFavs.controls) {
                    if (manualFavs.controls.hasOwnProperty(uuid) && !weakThis.getControlByUUID(uuid)) {
                        favsChanged = true;
                        delete manualFavs.controls[uuid];
                    }
                }
            }
        }

        favsChanged && activeMsComp.setManualFavoriteSettings(manualFavs);
    };

    var handleStructureProcessFail = function handleStructureProcessFail(reason) {
        if (reason !== ErrorCode.StructureDownloadError) {
            // the only known parameter is StructureDownloadError, everything else is something else from a rejection
            console.error("StructureExt", "handleStructureProcessFail : " + JSON.stringify(reason), reason);
            reason = ErrorCode.StructureProcessError;
        } // also delete structures, will download it again!


        activeMsComp.deleteAllStructures(CommunicationComponent.getCurrentReachMode()); // delete before showing error (closes the connection)

        activeMsComp.structureDownloadFailed(reason);
    };

    var resetStructureData = function resetStructureData() {
        Object.values(controls).forEach(function (control) {
            try {
                control.destroy();
            } catch (e) {
            }
        }.bind(this));
        controls = {};
        rooms = {};
        sortedRooms = null;
        categories = {};
        sortedCategories = null;
        customGroupTitles = {};
        presenceRooms = [];
        weatherServer = null;
        structureDataAvailable = false;
    };
    /**
     * goes through all groups and checks if the controls in it are supported
     * at the end it checks if there are controls left in the room and otherwise the room won't be included in the returning array
     * @param groups
     * @param type
     * @param ctrls
     * @returns {Array}
     */


    var normalizeGroups = function normalizeGroups(groups, type, ctrls) {
        var group,
            groupsArray = [];
        var groupUuids = Object.keys(groups),
            ctrl,
            groupIdx,
            supportedCtrlTypes = supportedControlTypes(false),
            // false parameter: Partly used controls like presence are not included
            isPresenceControl;

        for (var ctrlKey in ctrls) {
            if (ctrls.hasOwnProperty(ctrlKey)) {
                ctrl = ctrls[ctrlKey];
                isPresenceControl = ctrl.type === ControlType.PRESENCE || ctrl.type === ControlType.PRESENCE_CONTROLLER;

                if (!_isControlRestricted(ctrl)) {
                    if (type === GroupTypes.ROOM) {
                        groupIdx = groupUuids.indexOf(ctrl.room);
                    } else if (type === GroupTypes.CATEGORY) {
                        groupIdx = groupUuids.indexOf(ctrl.cat);
                    } // Group has been found


                    if (groupIdx >= 0) {
                        // Not supported Controls will be displayed as a UniversalControl that displays all states, details and subControls
                        // Ignore presence controls, or we will show blank groups. These controls don't have any UI
                        // supportedCtrlTypes do not include presence controls (note the "false" parameter), so we can't include them here!
                        if (supportedCtrlTypes.indexOf(ctrl.type) >= 0 || _shouldShowUniversalControl() && !isPresenceControl) {
                            groupUuids.splice(groupIdx, 1);
                        }
                    }
                }
            }
        }

        for (var uuid in groups) {
            if (groups.hasOwnProperty(uuid)) {
                if (groupUuids.indexOf(uuid) !== -1) {
                    continue; // is an unused group
                }

                group = groups[uuid];
                groupsArray.push(group);
                group.groupType = type;

                if (!group.image || group.image === "") {
                    group.image = Icon.DEFAULT;
                }

                if (group.color) {
                    group.color = adoptConfigColor(group.color, true);
                } else {
                    group.color = globalStyles.colors.brand;
                }

                checkIsFavoriteFlag(group);
            }
        }

        return groupsArray;
    };
    /**
     * sorts the groups by name
     * @param groupsArray
     * @param byRating
     * @param hasRoomObjects means that the objects contains the room ({ room: {}, ... })
     */


    var sortGroups = function sortGroups(groupsArray, byRating, hasRoomObjects) {
        sortArrayByName(groupsArray, hasRoomObjects);
        byRating && sortArrayByDefOrManRating(groupsArray, false, hasRoomObjects);
    };

    var normalizeControls = function normalizeControls(controls, superControl) {
        var control,
            supportedCtrlTypes = supportedControlTypes(true),
            siblingMap = {},
            cleanType; // used to track siblings in rooms.

        for (var uuid in controls) {
            if (controls.hasOwnProperty(uuid)) {
                try {
                    control = controls[uuid];

                    if (control.type === ControlType.I_Room_V2 && Feature.IRC_V2021) {
                        control.type = ControlType.I_Room_V2021;
                    }
                    //TODO-woessto: remove once loxapp3.json contains MeterV2 als controlType
                    if (control.type === ControlType.METER) {
                        if (control.details && control.details.hasOwnProperty("displayType")) {
                            control.type = ControlType.METER_V2;
                        }
                    }

                    if (supportedCtrlTypes.indexOf(control.type) === -1) {
                        // Not supported Controls will be displayed as a UniversalControl that displays all states, details and subControls
                        if (_shouldShowUniversalControl()) {
                            // We will show the original type in the universalControl
                            control.originalType = control.type;
                            control.type = ControlType.UNIVERSAL;
                        } else {
                            delete controls[uuid];
                            continue; // no need to normalize this control, because it will never be used
                        }
                    } // processing subControls atm.


                    if (superControl) {
                        control.cat = superControl.cat;
                        control.room = superControl.room;
                        control.uuidParent = superControl.uuidAction;
                        control.isSecured = superControl.isSecured;
                    }

                    if (control.cat) {
                        // verification if the category specified does not exist in the structure file (config error)
                        if (weakThis.getGroupByUUID(control.cat, GroupTypes.CATEGORY)) {
                            control.catColor = weakThis.getGroupByUUID(control.cat, GroupTypes.CATEGORY).color;
                        } else {
                            console.log(control.name + "'s category wasn't found!");
                            control.catColor = globalStyles.colors.brand;
                        }
                    } else {
                        control.catColor = globalStyles.colors.brand;
                    } // group detail


                    var room = control.room ? weakThis.getGroupByUUID(control.room, GroupTypes.ROOM) : null,
                        cat = control.cat ? weakThis.getGroupByUUID(control.cat, GroupTypes.CATEGORY) : null;
                    addGroupDetailsToControl(control, room, cat);
                    checkIsFavoriteFlag(control);
                    addControlTypeToControl(control); // determine if this control has siblings (controls with the same type in the same room)

                    if (!superControl) {
                        // ignore subcontrols;
                        cleanType = control.controlType.replace("V2", ""); // e.g. LightControl vs LightV2Control

                        ensureAttribute(siblingMap, cleanType + "/" + control.groupDetail, []).pushObject(control.uuidAction);
                    }

                    if (control.type === "InfoOnlyDigital" && control.details && control.details.color) {
                        control.details.color.off = adoptConfigColor(control.details.color.off);
                        control.details.color.on = adoptConfigColor(control.details.color.on);
                    } else if (control.type === 'LightControllerV2') {
                        prepareLightControllerV2(control);
                    } else if (control.type === "IRCDaytimer") {
                        control.details.isIRCDaytimer = true;

                        if (control.name === 'Heating') {
                            control.details.isHeating = true;
                        } else if (control.name === 'Cooling') {
                            control.details.isCooling = true;
                        }
                    } else if (control.type === "IRCV2Daytimer") {
                        control.details.isIRCV2Daytimer = true;
                    } else if (control.type === "Hourcounter") {
                        // 82250328: modified statistic handling with hourcounter
                        if (control.statistic) {
                            control.statistic.outputs[0].format = "<v.pdu>"; // new format for a precise duration.
                        }
                    } else if (control.type === "Fronius") {
                        if (control.statistic && !Feature.ENERGY_MONITOR_9_STATS) {
                            // delete unused statistic outputs!
                            for (var i = 0; i < control.statistic.outputs.length; i++) {
                                // Strompreise
                                if (control.statistic.outputs[i].id === 2 || control.statistic.outputs[i].id === 3) {
                                    //control.statistic.outputs.splice(i, 1);
                                    //i--;
                                    control.statistic.outputs[i].visuType = Statistic.Type.NOT_SUPPORTED;
                                }
                            }
                        }
                    } else if (control.type === ControlType.WEBPAGE) {
                        // https://www.wrike.com/open.htm?id=37980737
                        if (control.details.url && control.details.url !== "" && control.details.url.indexOf("http://") < 0 && control.details.url.indexOf("https://") < 0) {
                            control.details.url = "http://" + control.details.url;
                        }

                        if (control.details.urlHd && control.details.urlHd !== "" && control.details.urlHd.indexOf("http://") < 0 && control.details.urlHd.indexOf("https://") < 0) {
                            control.details.urlHd = "http://" + control.details.urlHd;
                        }
                    } else if (control.type === "AudioZone") {
                        if (!control.details.hasOwnProperty("clientType")) {
                            control.details.clientType = MediaEnum.ClientType.PHYSICAL;
                        }

                        if (multipleMediaServer) {
                            control.details.serverName = mediaServer[control.details.server].name;

                            if (!Feature.MULTI_MUSIC_SERVER) {
                                // since our app doesn't support multiple media-server, simply treat audioZones
                                // as MediaClients, & route the communication via miniserver if there is more than one
                                // loxone music server.
                                control.controlType = "MediaClient";
                                control.type = control.controlType; //otherwise the room mode will deal with it as audioZone
                            }
                        }
                    } else if (control.type === ControlType.ALARM || control.type === ControlType.SMOKE_ALARM || control.type === ControlType.AAL_SMART_ALARM) {
                        var subCtrlUUID = Object.keys(control.subControls)[0];
                        control.subControls[subCtrlUUID].name = _("message-center.history");
                    } else if (control.type === "Leaf") {
                        // TODO-gooelzda Remove on new Ventilation implementation
                        control.type = ControlType.VENT_CONTROL;
                    } else if (control.type === ControlType.STEAK) {
                        var subCtrlUUID = Object.keys(control.subControls)[0];
                        control.subControls[subCtrlUUID].name = _("steak.tracker.last");
                    } else if (control.type === ControlType.I_Room_V2) {
                        if (Feature.IRC_V2021) {
                            control.type = ControlType.I_Room_V2021;
                        } // TODO-goelzda Remove once we have the correct subControl Type!


                        var subControls = Object.keys(control.subControls);
                        control.subControls[subControls[0]].type = Control.Type.IRC_DAYTIMER_V2;
                    }

                    if (control.subControls) {
                        normalizeControls(control.subControls, control);
                    }
                } catch (e) {
                    console.error(e);
                    delete controls[uuid];
                }
            }
        } // process the siblingsMap & set the hasSiblingsInRoom accordingly.


        if (!superControl) {
            // ignore subcontrols
            Object.values(siblingMap).forEach(function (sibList) {
                sibList.forEach(function (sibUuid) {
                    controls[sibUuid].hasSiblingsInRoom = sibList.length > 1;
                });
            });
        }
    };

    var getSubControlByUUID = function getSubControlByUUID(uuid) {
        for (var ctrlUuid in controls) {
            if (controls.hasOwnProperty(ctrlUuid) && controls[ctrlUuid].subControls) {
                for (var subCtrlUuid in controls[ctrlUuid].subControls) {
                    if (controls[ctrlUuid].subControls.hasOwnProperty(subCtrlUuid)) {
                        if (subCtrlUuid === uuid) {
                            return controls[ctrlUuid].subControls[subCtrlUuid];
                        }
                    }
                }
            }
        }
    };
    /**
     * Ensures the attributes of the LightControllerV2 are defined as needed.
     * @param control
     */


    var prepareLightControllerV2 = function prepareLightControllerV2(control) {
        // adopt master brightness
        if (control.details.masterValue) {
            var masterValue = control.subControls[control.details.masterValue];

            if (masterValue) {
                masterValue.details = {
                    isMaster: true
                };
            }
        } // adopt master color picker


        if (control.details.masterColor) {
            var masterColor = control.subControls[control.details.masterColor];

            if (masterColor) {
                masterColor.details.isMaster = true;
            }
        } // adopt all switches to show a different default color


        Object.values(control.subControls).forEach(function (subcontrol) {
            if (subcontrol.type === ControlType.SWITCH) {
                subcontrol.details = subcontrol.details || {};
                subcontrol.details.activeColor = Color.Dimmer.ON;
            }
        }.bind(this));
    };
    /**
     * checkes .isFavorite flag of element - if not set, check defaultRating > 0 --> isFavorite = true!
     * @param el
     */


    var checkIsFavoriteFlag = function (el) {
        if (typeof el.isFavorite !== "boolean") {
            el.isFavorite = el.defaultRating > 0;
        }
    };

    var supportedControlTypes = function supportedControlTypes(partiallyUsed) {
        var supportedCtrlTypes = [
            ControlType.ALARM, Control.Type.ALARM_CENTRAL, ControlType.AUDIO_ZONE, ControlType.AUDIO_ZONE_V2,
            Control.Type.AUDIO_ZONE_CENTRAL, ControlType.AUDIO_PLAYER_GROUP, ControlType.APPLICATION,
            ControlType.COLOR_PICKER, ControlType.COLOR_PICKER_V2, ControlType.DAYTIMER, ControlType.DIMMER,
            ControlType.DIMMER_EIB, ControlType.FRONIUS, ControlType.GATE, Control.Type.GATE_CENTRAL,
            ControlType.HEATMIXER, ControlType.HOURCOUNTER, ControlType.INFO_ONLY_DIGITAL, ControlType.INFO_ONLY_ANALOG,
            ControlType.INFO_ONLY_TEXT, ControlType.INTERCOM, ControlType.I_ROOM, Control.AppType.I_ROOM_V2,
            ControlType.I_ROOM_DAYTIMER, ControlType.IRRIGATION, ControlType.JALOUSIE, Control.Type.JALOUSIE_CENTRAL,
            ControlType.LEFT_RIGHT_ANALOG, ControlType.LEFT_RIGHT_DIGITAL, ControlType.LIGHT, ControlType.LIGHT_V2,
            Control.Type.LIGHT_CENTRAL, ControlType.LIGHTSCENE_RGB, ControlType.MAILBOX, ControlType.MEDIA,
            ControlType.MEDIA_CLIENT, ControlType.METER, ControlType.NFC_CODE_TOUCH, ControlType.POOL,
            ControlType.POOL_DAYTIMER, ControlType.PUSHBUTTON, ControlType.RADIO, ControlType.REMOTE, ControlType.SAUNA,
            ControlType.SEQUENTIAL, ControlType.SLIDER, ControlType.SWITCH, ControlType.TEXT_STATE, ControlType.TIMED_SWITCH,
            ControlType.TRACKER, ControlType.UP_DOWN_ANALOG, ControlType.UP_DOWN_DIGITAL, ControlType.VALUE_SELECTOR,
            ControlType.WEBPAGE, ControlType.WINDOW_MONITOR, ControlType.SOLAR_PUMP, ControlType.CLIMATE_CONTROLLER,
            ControlType.CLIMATE_CONTROLLER_US, ControlType.POWER_UNIT, ControlType.WALLBOX2, ControlType.AC_CONTROL,
            ControlType.SPOT_PRIZE_OPTIMIZER, ControlType.WALLBOX_MANAGER, ControlType.STATUS_MONITOR
        ];

        if (!PairedAppComponent.isPaired()) {
            // switching to other miniservers is not allowed when paired - don't offer it!
            supportedCtrlTypes.push(ControlType.MS_SHORTCUT);
        }

        if (Feature.ALARM_CLOCK) {
            supportedCtrlTypes.push(ControlType.ALARM_CLOCK);
        }

        if (Feature.CAR_CHARGER) {
            supportedCtrlTypes.push(ControlType.CAR_CHARGER);
        }

        if (Feature.SMOKE_ALARM) {
            supportedCtrlTypes.push(ControlType.SMOKE_ALARM);
        }

        if (Feature.LOAD_MANAGER) {
            supportedCtrlTypes.push(ControlType.LOAD_MANAGER);
        }

        if (Feature.PULSE_AT) {
            supportedCtrlTypes.push(ControlType.PULSE_AT);
        }

        if (Feature.ENERGY_MANAGER) {
            supportedCtrlTypes.push(ControlType.ENERGY_MANAGER);
        }

        if (Feature.ENERGY_MANAGER_V2) {
            supportedCtrlTypes.push(ControlType.ENERGY_MANAGER_V2);
        }

        if (Feature.ENERGY_FLOW_MONITOR) {
            supportedCtrlTypes.push(ControlType.ENERGY_FLOW_MONITOR);
            supportedCtrlTypes.push(ControlType.EFM);
        }

        if (Feature.METER_REWORK) {
            supportedCtrlTypes.push(ControlType.METER_V2);
        }

        if (Feature.AAL_SMART_ALARM) {
            supportedCtrlTypes.push(ControlType.AAL_SMART_ALARM);
        }

        if (Feature.VENT_CONTROL) {
            supportedCtrlTypes.push(ControlType.VENT_CONTROL);
        }

        if (Feature.STEAK) {
            supportedCtrlTypes.push(ControlType.STEAK);
        }

        if (Feature.IRCv2) {
            supportedCtrlTypes.push(Control.Type.I_ROOM_V2);
            supportedCtrlTypes.push(Control.Type.IRC_DAYTIMER_V2);
        }

        if (Feature.IRC_V2021) {
            supportedCtrlTypes.push(Control.Type.I_ROOM_V2021);
        }

        if (Feature.WINDOW_CONTROL) {
            supportedCtrlTypes.push(ControlType.WINDOW);
        }

        if (Feature.TEXT_INPUT) {
            supportedCtrlTypes.push(ControlType.TEXT_INPUT);
        }

        if (Feature.AAL_EMERGENCY) {
            supportedCtrlTypes.push(ControlType.AAL_EMERGENCY);
        } // The plugin, what is use in this control is not supported with android 4.4.2


        if (!(PlatformComponent.isAndroid() && slowDevice)) {
            supportedCtrlTypes.push(ControlType.SYSTEMSCHEME);
        }

        if (Feature.PRESENCE_DETECTOR) {
            supportedCtrlTypes.push(ControlType.PRESENCE_DETECTOR);
        }

        if (Feature.WINDOW_CENTRAL) {
            supportedCtrlTypes.push(Control.Type.WINDOW_CENTRAL);
        }

        if (Feature.INTERCOM_GEN_2) {
            supportedCtrlTypes.push(Control.Type.INTERCOM_GEN_2);
        }

        if (partiallyUsed) {
            // also add control types, which are not available in the control list
            supportedCtrlTypes.push(ControlType.PRESENCE);
            supportedCtrlTypes.push(ControlType.PRESENCE_CONTROLLER);
        }

        if (_shouldShowUniversalControl()) {
            supportedCtrlTypes.push(ControlType.UNIVERSAL);
        }

        return supportedCtrlTypes;
    };

    var loadControlSpecificRooms = function loadControlSpecificRooms() {
        var prop,
            controlsByRoomPresence = [];

        for (prop in controls) {
            if (!controls.hasOwnProperty(prop)) {
                continue;
            }

            var control = controls[prop];
            var roomUUID = control.room;
            var type = control.type; // presence

            if (roomUUID && (type === ControlType.PRESENCE || type === ControlType.PRESENCE_CONTROLLER || type === ControlType.PRESENCE_DETECTOR)) {
                controlsByRoomPresence.push({
                    room: rooms[roomUUID],
                    control: control
                });
            }
        }

        presenceRooms = controlsByRoomPresence;
    };
    /**
     * sorts the array objects by name
     * @param arr
     * @param hasRoomObjects means that the objects contains the room ({ room: {}, ... })
     */


    var sortArrayByName = function sortArrayByName(arr, hasRoomObjects) {
        return arr.sort(function (a, b) {
            var objA = hasRoomObjects ? a.room : a,
                objB = hasRoomObjects ? b.room : b;
            return compareObjByName(objA, objB);
        });
    };
    /**
     * sorts the array objects by manual rating or by default rating
     * @param arr
     * @param manualRating
     * @param hasRoomObjects means that the objects contains the room ({ room: {}, ... })
     */


    var sortArrayByDefOrManRating = function sortArrayByDefOrManRating(arr, manualRating, hasRoomObjects, groupType) {
        return arr.sort(function (a, b) {
            var objA = hasRoomObjects ? a.room : a,
                objB = hasRoomObjects ? b.room : b;

            if (manualRating) {
                if (!ActiveMSComponent.getSortingStructure()) {
                    // use star rating
                    if (objA.manualRating === objB.manualRating) {
                        return compareObjByName(objA, objB);
                    }

                    return objA.manualRating < objB.manualRating ? 1 : -1;
                } // use new sorting structure


                return _handleNewSorting(objA, objB, groupType);
            }

            if (!ActiveMSComponent.getSortingStructure()) {
                // use star rating
                if (objA.defaultRating === objB.defaultRating) {
                    return compareObjByName(objA, objB);
                }

                return objA.defaultRating < objB.defaultRating ? 1 : -1;
            } // use new sorting structure


            return _handleNewSorting(objA, objB, groupType);
        }.bind(this));
    };

    var _handleNewSorting = function _handleNewSorting(objA, objB, groupType) {
        // use new sorting structure
        var uuidKey = "uuidAction";
        var tab = UrlStartLocation.FAVORITES;

        if (groupType) {
            tab = groupType;
        }

        if (!objA[uuidKey]) {
            uuidKey = "uuid";
        }

        var sortingObjA = ActiveMSComponent.getSortingStructureForObject(objA[uuidKey], tab);
        var sortingObjB = ActiveMSComponent.getSortingStructureForObject(objB[uuidKey], tab);

        if (sortingObjA && sortingObjB) {
            return sortingObjA.position > sortingObjB.position ? 1 : -1;
        } else {
            return 0;
        }
    };
    /**
     * Sorting function used for creating the new sorting structure
     * @param arr - this array will be sorted
     * @param manualRating - can be manualRating or defaultRating
     * @param hasRoomObjects - means that the objects contains the room ({ room: {}, ... })
     * @param respectFavorite - respects beside the star rating the favorite flag
     * @returns {*} - returns the sorted array
     */


    var sortArrayByDefOrManRatingSorting = function sortArrayByDefOrManRatingSorting(arr, manualRating, hasRoomObjects, respectFavorite) {
        return arr.sort(function (a, b) {
            var objA = hasRoomObjects ? a.room : a,
                objB = hasRoomObjects ? b.room : b;

            if (manualRating) {
                if (respectFavorite) {
                    return sortByRatingAndFavorite(objA, objB, "manualRating");
                }

                if (objA.manualRating === objB.manualRating) {
                    return compareObjByName(objA, objB);
                }

                return objA.manualRating < objB.manualRating ? 1 : -1;
            }

            if (respectFavorite) {
                return sortByRatingAndFavorite(objA, objB, "defaultRating");
            }

            if (objA.defaultRating === objB.defaultRating) {
                return compareObjByName(objA, objB);
            }

            return objA.defaultRating < objB.defaultRating ? 1 : -1;
        });
    };

    var sortByRatingAndFavorite = function sortByRatingAndFavorite(objA, objB, ratingOption) {
        if (objA.isFavorite === objB.isFavorite && objA[ratingOption] === objB[ratingOption]) {
            return compareObjByName(objA, objB);
        }

        if (objA.isFavorite === objB.isFavorite) {
            return objA[ratingOption] < objB[ratingOption] ? 1 : -1;
        }

        return objA.isFavorite && !objB.isFavorite ? -1 : 1;
    };

    var saveStructure = function saveStructure(structureToSave) {
        // save the new structure
        var fileName = activeMsComp.buildStructureFilename();
        var savePromise = activeMsComp.saveFile(fileName, JSON.stringify(structureToSave), DataType.STRING);
        savePromise.then(function () {
            Debug.Structure && console.log("saved successfully the structure of " + fileName);

            if (LoxoneControl.hasLoxoneControl()) {
                LoxoneControl.structureDidChange(activeMsComp.getMiniserverSerialNo());
            }
        }, function (error) {
            console.error("couldn't save the structure of " + fileName + " (" + error + ")");
        });
    };
    /**
     * If e.g. the local structure is updated due to an structure update, this automatically means the remote one
     * becomes invalid and should be removed.
     */


    var deleteOtherStructure = function deleteOtherStructure() {
        var currentMs = ActiveMSComponent.getActiveMiniserver(),
            currRM = CommunicationComponent.getCurrentReachMode(),
            otherReachMode = currRM === ReachMode.REMOTE ? ReachMode.LOCAL : ReachMode.REMOTE,
            fileToDelete = buildLoxAPP3Filename(currentMs, otherReachMode);
        return PersistenceComponent.deleteFile(fileToDelete);
    };
    /**
     * Whether or not we should should replace unknown controls with UniversalControls
     * @returns {boolean}
     * @private
     */


    var _shouldShowUniversalControl = function _shouldShowUniversalControl() {
        return !!GUI.DebugScreen;
    };

    var _isControlRestricted = function _isControlRestricted(ctrl) {
        var restriction = 0,
            readonly = 0,
            reachMode = CommunicationComponent.getCurrentReachMode();

        if (!Feature.CONTROL_RESTRICTIONS) {
            return false;
        }

        let controlRefOnly;
        let controlReadOnly = false;

        if (reachMode === ReachMode.LOCAL) {
            restriction = Restrictions.LOCAL_REF_ONLY;
            readonly = Restrictions.LOCAL_READ_ONLY;
        } else if (reachMode === ReachMode.REMOTE) {
            restriction = Restrictions.REMOTE_REF_ONLY;
            readonly = Restrictions.REMOTE_READ_ONLY;
        }
        controlRefOnly = hasBit(ctrl.restrictions, restriction);
        controlReadOnly = hasBit(ctrl.restrictions, readonly);

        return controlRefOnly || controlReadOnly;
    };

    return StructureExt;
});
