'use strict';

define("ColorPickerControlStateContainer", ["ControlStateContainer", "LightControlEnums", "ColorPickerControlEnums"], function (ControlStateContainer, LightControlEnums, ColorPickerControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var HUE_WARM_WHITE = 43,
        HUE_COLD_WHITE = 200;
    return class ColorPickerControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control); // detect if it is a color picker from the light-control or lightscene-rgb

            this.isColorPickerControl = control.type === ControlType.COLOR_PICKER;
            this.states.selectedMode = ColorPickerControlEnums.PickerMode.NONE;
        }

        prepareStates(newVals) {
            var colorString = newVals[this.control.states.color].text;
            this.states.powerOn = colorString !== 'hsv(0,0,0)' && colorString !== 'lumitech(0,0)';
            this.states.colorObject = this._createColorObjectFromColorString(colorString);

            if (!this.states.powerOn) {
                // turned off
                this.states.selectedMode = ColorPickerControlEnums.PickerMode.NONE;
            } else if (colorString.indexOf('hsv') === 0) {
                // RGB
                if (this.states.colorObject.hue === HUE_WARM_WHITE || this.states.colorObject.hue === HUE_COLD_WHITE) {
                    this.states.selectedMode = ColorPickerControlEnums.PickerMode.LUMITECH;
                } else {
                    this.states.selectedMode = ColorPickerControlEnums.PickerMode.RGB;
                }
            } else if (colorString.indexOf('lumitech') === 0) {
                // Lumitech
                this.states.selectedMode = ColorPickerControlEnums.PickerMode.LUMITECH;
            }

            if (this.isColorPickerControl) {
                // favorites
                this.states.favoriteColors = [];
                var jsonString = newVals[this.control.states.favorites].text;
                var favoriteColorObjectStrings = JSON.parse(jsonString);

                for (var i = 0; i < favoriteColorObjectStrings.length; i++) {
                    this.states.favoriteColors.push(this._createColorObjectFromColorString(favoriteColorObjectStrings[i]));
                }
            } else {
                this.states.activeSceneNr = newVals[this.control.states.activeScene] || 0;
                this.states.haveScenesChanged = false; // no variable scenes in the lightscene rgb control
            }
        }

        getStateText() {
            if (!this.isColorPickerControl) {
                if (this.states.activeSceneNr === LightControlEnums.SceneNumbers.ALL_OFF) {
                    return _('controls.lightcontroller.all-off');
                } else if (this.states.activeSceneNr === Object.keys(this.control.details.sceneList).length + 1) {
                    return _('controls.lightcontroller.all-on');
                }

                return this.control.details.sceneList[this.states.activeSceneNr];
            }
        }

        getStateIconSmall() {
            if (this.states.powerOn) {
                return {
                    iconSrc: Icon.CIRCLE,
                    color: LxColorUtils.getCSSRGBPropertyFromColorObject(this.states.colorObject)
                };
            }
        }

        getStateColor() {
            if (this.states.powerOn) {
                return window.Styles.colors.stateActive;
            }
        }

        _createColorObjectFromColorString(colorString) {
            if (colorString.indexOf('hsv') === 0) {
                // RGB
                return this._createHSVObjectFromHSVString(colorString);
            } else if (colorString.indexOf('lumitech') === 0) {
                // Lumitech
                return this._createLumitechObjectFromLumitechString(colorString);
            }
        }

        _createHSVObjectFromHSVString(string) {
            var values = string.substring(4, string.length - 1).split(',');
            return {
                type: ColorPickerControlEnums.PickerMode.RGB,
                hue: parseInt(values[0]),
                sat: parseInt(values[1]),
                brightness: parseInt(values[2])
            };
        }

        _createLumitechObjectFromLumitechString(string) {
            var values = string.substring(9, string.length - 1).split(',');
            return {
                type: ColorPickerControlEnums.PickerMode.LUMITECH,
                brightness: parseInt(values[0]),
                kelvin: parseInt(values[1])
            };
        }

    };
});
