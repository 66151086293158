/**
 * {"jsonrpc":"2.0","method":"1bf0bace-0164-28bf-ffffed57184a04d2.donotdisturb","id":23,"params":{"enable":0}}
 * {"jsonrpc":"2.0","method":"1bf0bace-0164-28bf-ffffed57184a04d2.roompresence","id":24,"params":{"active":0}}
 */
export default class LxJsonRPC {


    constructor() {
        this.name = "LxJsonRPC";
        this._rootHandler = new LxJsonRPCMethodHandler("root");
    }

    handleRpc(rpcText) {
        const message = LxJsonRPCMessage.parse(rpcText);
        // even though the MS sends messages (with an ID) instead of notifications (without an ID), it does (currently)
        // neither expect nor parse/handle any answers --> hence this is omitted for now.
        if (message) {
            let methodHandler = this._rootHandler;
            message.methodPath.forEach(methodId => {
                methodHandler.handleMessage(message, true);
                methodHandler = methodHandler.getHandler(methodId);
            });

            methodHandler.handleMessage(message, false);
        }
    }

    /**
     * Registers for a specific
     * @param callback          callback that will be called once a new message reaches the methods (or subs of it)
     * @param methodPath        specifies the method which this cb will be registered for
     * @param includeSubMethods if true, it means that this should be notified on messages for sub-methods also
     * @returns {(function(): void)|*}
     */
    registerForMethod(callback, methodPath = [], includeSubMethods = false) {
        let methodHandler = this._rootHandler;
        Array.isArray(methodPath) && methodPath.forEach(methodId => {
            methodHandler = methodHandler.getHandler(methodId);
        });
        return methodHandler.addListener(callback, includeSubMethods);
    }
}


class LxJsonRPCMessage {

    constructor({method, id, params = null}) {
        this._method = method;
        this._methodPath = this.method.split(".");
        this._id = id;
        this._params = params;
        this._timestamp = Date.now()
    }

    /**
     * Full method path, e.g.: {uuid}.{method}
     * @returns {string}
     */
    get method() {
        return this._method;
    }

    get methodPath() {
        return this._methodPath;
    }

    get leafMethod() {
        return this._methodPath[this._methodPath.length - 1];
    }

    get id() {
        return this._id;
    }

    get params() {
        return this._params;
    }

    /**
     * Returns the timestamp when the message has been received.
     * @returns {number} milliseconds
     */
    get timestamp() {
        return this._timestamp;
    }

    static parse(rpcText) {
        try {
            let jsonObj = JSON.parse(rpcText);
            if (jsonObj.hasOwnProperty("id") && jsonObj.hasOwnProperty("method")) {
                return new LxJsonRPCMessage(jsonObj);
            }
            console.error("LxJsonRPCMessage", "method or id missing! " + rpcText)
        } catch (ex) {
            console.error("LxJsonRPCMessage", "cannot be parsed: " + rpcText);
        }
        return null;
    }
}


class LxJsonRPCMethodHandler {
    constructor(methodId) {
        this._methodId = methodId;
        this._listeners = {};
        this._subListeners = {};
        this._subHandlers = {};

        this._latestMessage = null;
    }

    /**
     * Adds a listener to be called when a message is handled.
     * @param cb    the callback to call when a message arrives
     * @param includeSubmethods if true, it means that it should also be called when the message is for a sub method.
     * @returns {(function(): void)|*}
     */
    addListener(cb, includeSubmethods = false) {
        let listenerId;
        do {
            listenerId = "" + getRandomIntInclusive(0,100000);
        } while (this._listeners.hasOwnProperty(listenerId));

        this._listeners[listenerId] = cb;
        if (includeSubmethods) {
            this._subListeners[listenerId] = cb;
        }

        return () => {
            delete this._listeners[listenerId];
            delete this._subListeners[listenerId];
        }
    }

    /**
     * Will return a topic instance for the topic-identifier provided.
     * @param methodId
     * @returns {*}
     */
    getHandler(methodId) {
        this._subHandlers[methodId] = this._subHandlers[methodId] || new LxJsonRPCMethodHandler(methodId);
        return this._subHandlers[methodId];
    }

    /**
     *
     * @param message
     * @param isForSub  if true, this means that the message is aimed at a child-handler and should only be passed to
     *
     */
    handleMessage(message, isForSub = false) {
        this._latestMessage = message;

        if (isForSub) {
            Object.values(this._subListeners).forEach(cb => cb(message));
        } else {
            Object.values(this._listeners).forEach(cb => cb(message));
        }
    }
}