'use strict';

import PairedAppEnums from "../pairedAppEnums";
import TaskQueue from "../../shared/logic/tasks/TaskQueue";
import ScreenBrightness from "../helper/ScreenBrightness";

ActiveMSComp.factory('DeviceStatusReportExt', function () {
    let weakThis;

    const SCMD = PairedAppEnums.StateCmd;

    function DeviceStatusReportExt(comp) {
        this.name = "DeviceStatusReportExt";
        this.comp = comp;
        weakThis = this

        weakThis._deviceUserInteraction = false;
        weakThis._stateMap = {};
        weakThis._isConnected = false;
        weakThis._isPaired = false;
        weakThis._sendQueue = new TaskQueue();

        weakThis._startBrightnessMonitoring();
        weakThis._initializePowerValue();

        weakThis._updateStatus(SCMD.BRIGHTNESS, 100)
        weakThis._updateStatus(SCMD.INTERACTION, true);
        weakThis._updateStatus(SCMD.SCREENSAVER, false);

        comp.on(PairedAppEnums.ECEvent.UserInteractionChanged, (ev, {active}) => {
            Debug.PairedApp && console.log(weakThis.name, "UserInteractionChanged = " + active);
            weakThis._updateStatus(SCMD.INTERACTION, active);
        });

        comp.on(CCEvent.EcoScreenDarkenerActive, (ev, {dark, src}) => {
            Debug.PairedApp && console.log(weakThis.name, "EcoScreenDarkenerActive = " + dark);
            weakThis._updateStatus(SCMD.SCREENSAVER, dark);
        });

        comp.on(CCEvent.BatteryStateChanged, (ev, {isPlugged, level}) => {
            Debug.PairedApp && console.log(weakThis.name, "BatteryStateChanged");
            weakThis._powerChanged({isPlugged, level})
        });

        comp.on(CCEvent.StartMSSession, (ev) => {
            Debug.PairedApp && console.log(weakThis.name, "StartMSSession");
            weakThis._initializePowerValue();
            weakThis._updateVersionInfos();
        })

        comp.on(CCEvent.ConnEstablished, () => {
            Debug.PairedApp && console.log(weakThis.name, "ConnEstablished");
            weakThis._isConnected = true;
            weakThis._checkShouldSend();
        });
        comp.on(CCEvent.ConnClosed, () => {
            Debug.PairedApp && console.log(weakThis.name, "ConnClosed");
            weakThis._isConnected = false;
            weakThis._checkShouldSend();
        });
        comp.on(CCEvent.PairedAppInfoReady, (ev, {isPaired}) => {
            Debug.PairedApp && console.log(weakThis.name, "PairedAppInfoReady: isPaired=" + isPaired);
            weakThis._isPaired = isPaired;
            weakThis._checkShouldSend();
        });

        comp.on(CCEvent.UnpairedApp, (ev, ms) => {
            Debug.PairedApp && console.log(weakThis.name, "UnpairedApp");
            weakThis._isPaired = false;
            weakThis._checkShouldSend();
        });
    }

    DeviceStatusReportExt.prototype._checkShouldSend = function _checkShouldSend() {
        const shouldSend = weakThis._isPaired && weakThis._isConnected;
        Debug.PairedApp && console.log(weakThis.name, "_checkShouldSend: " + shouldSend);
        if (shouldSend) {
            weakThis._sendQueue.continue();
            weakThis._sendAllStates();
        } else {
            weakThis._sendQueue.pause();
        }
    }

    DeviceStatusReportExt.prototype._sendAllStates = function _sendAllStates(isConnected) {
        Debug.PairedApp && console.log(weakThis.name, "_sendAllStates");

        Object.keys(weakThis._stateMap).forEach(cmdF => {
            weakThis._scheduleSend(cmdF, weakThis._stateMap[cmdF]);
        })
    }

    DeviceStatusReportExt.prototype._updateStatus = (stateCmdF, args) => {
        const newArgs = Array.isArray(args) ? args : [args],
            prevArgs = weakThis._stateMap[stateCmdF];
        weakThis._stateMap[stateCmdF] = newArgs;
        if (JSON.stringify(newArgs) !== JSON.stringify(prevArgs) && weakThis._isConnected && weakThis._isPaired) {
            weakThis._scheduleSend(stateCmdF, weakThis._stateMap[stateCmdF]);
        }
    };

    DeviceStatusReportExt.prototype._scheduleSend = function _scheduleSend(cmdF, args) {
        weakThis._sendQueue.push(weakThis._send.bind(weakThis, Commands.format(cmdF, ...args)), cmdF);
    };

    DeviceStatusReportExt.prototype._send = function _send(cmd) {
        Debug.PairedApp && console.log(weakThis.name, "_send: " + cmd);
        return CommunicationComponent.send(cmd).then(res => {
            Debug.PairedApp && console.log(weakThis.name, "_send -> confirmed! " + cmd);
        }, err => {
            console.error(weakThis.name, "_send -> failed! " + cmd, err);
        });
    };

    DeviceStatusReportExt.prototype._startBrightnessMonitoring = function _startBrightnessMonitoring() {
        weakThis._unregisterFromBrightness = ScreenBrightness.register(weakThis._brightnessChanged.bind(weakThis));
    };
    DeviceStatusReportExt.prototype._stopBrightnessMonitoring = function _stopBrightnessMonitoring() {
        weakThis._unregisterFromBrightness && weakThis._unregisterFromBrightness();
        weakThis._unregisterFromBrightness = null;
    };
    DeviceStatusReportExt.prototype._brightnessChanged = function _brightnessChanged(newBrightness) {
        Debug.PairedApp && console.log(weakThis.name, "_brightnessChanged: " + newBrightness);
        weakThis._updateStatus(SCMD.BRIGHTNESS, newBrightness);
    };

    DeviceStatusReportExt.prototype._initializePowerValue = function _initializePowerValue() {
        const battState = PlatformComponent.getBatteryStateObj();
        if (battState.hasOwnProperty("level")) {
            Debug.PairedApp && console.log(weakThis.name, "_initializePowerValue");
            weakThis._powerChanged(battState);
        } else {
            Debug.PairedApp && console.log(weakThis.name, "_initializePowerValue - unknown yet!");
        }
    }
    DeviceStatusReportExt.prototype._powerChanged = function _powerChanged({isPlugged, level}) {
        Debug.PairedApp && console.log(weakThis.name, "_powerChanged, isPlugged=" + isPlugged + ", level=" + level);
        weakThis._updateStatus(SCMD.POWER, [ (isPlugged ? 1:0), level ]);
    }

    DeviceStatusReportExt.prototype._updateVersionInfos = function _updateVersionInfos() {
        let osVersion = PlatformComponent.getPlatformInfoObj().version;
        if (PlatformComponent.isDeveloperInterface()) {
            osVersion = osVersion.split(" ")[0];
        }
        let appVersion = PlatformComponent.getAppInfoObj().appVersion;
        Debug.PairedApp && console.log(weakThis.name, "_updateVersionInfos, os=" + osVersion + ", app=" + appVersion);
        weakThis._updateStatus(SCMD.VERSIONS, [ encodeURIComponent(appVersion), encodeURIComponent(osVersion)  ]);
    }


    return DeviceStatusReportExt;
});
