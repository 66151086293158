'use strict';

define("MediaStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class MediaStateContainer extends ControlStateContainer {
        /**
         *  audioEvent  a state object containing the text of latest audio event received by the server
         *  apiVersion  the initial api version string is contained in this states text attribute.
         *  serverState if the server is online, offline, booting or unreachable.
         *  grouping    contains an array of all zone groups on this music server
         *  groupColors list of hexadecimal color strings used to separate the groups on this server.
         */
        constructor(mediaServer) {
            var control = {
                name: mediaServer.name,
                uuidAction: mediaServer.uuidAction,
                type: 'media',
                states: mediaServer.states,
                // this is where this stateContainer gets the id from
                isConfigured: function () {
                    return true;
                }
            };
            super(control);
            this.ServerComp = MediaServerComp;
            this.availableMediaServers = ActiveMSComponent.getStructureManager().getMediaServerSet();

            if (mediaServer.type === MediaServerType.LXMUSIC_V2) {
                delete mediaServer.states.audioEvents;

                if (!window.hasOwnProperty("audioserverComps")) {
                    window.audioserverComps = {};
                }

                if (!window.audioserverComps.hasOwnProperty(mediaServer.uuidAction)) {
                    window.audioserverComps[mediaServer.uuidAction] = new Components.Audioserver.Init(mediaServer.uuidAction);
                }

                this.ServerComp = window.audioserverComps[mediaServer.uuidAction];
            }

            this.mediaServer = mediaServer;
            Debug.Media.ServerStateContainer && console.log("MediaStateContainer", "initialize with states: " + JSON.stringify(mediaServer.states));
            this.name = "MediaStateContainer@" + this.mediaServer.name;
            this.states.features = this.ServerComp.Feature.getFeatureSetForVersion(MediaEnum.RESET_API_VERSION.firmwareVersion, MediaEnum.RESET_API_VERSION.apiVersion);

            if (mediaServer.type === MediaServerType.LXMUSIC_V2) {
                Object.keys(this.availableMediaServers).forEach(function (uuid) {
                    // don't register for itself & don't register for music servers!
                    if (uuid !== this.mediaServer.uuidAction && this.availableMediaServers[uuid].type === MediaServerType.LXMUSIC_V2) {
                        SandboxComponent.registerForStateChangesForUUID(uuid, this, function _boundOnOtherServerStateReceived(states) {
                            this.onOtherServerStatesReceived(uuid, states);
                        }.bind(this));
                    }
                }.bind(this));
            }
        }

        destroy() {
            if (this.mediaServer.type === MediaServerType.LXMUSIC_V2) {
                Object.keys(this.availableMediaServers).forEach(function (uuid) {
                    SandboxComponent.unregisterForStateChangesForUUID(uuid, this);
                }.bind(this));
                window.audioserverComps[this.mediaServer.uuidAction].destroy();
                delete window.audioserverComps[this.mediaServer.uuidAction];
            }

            super.destroy(...arguments);
        }

        prepareStates(newVals) {
            Debug.Media.ServerStateContainer && console.log(this.name, "prepareStates");

            if (newVals.hasOwnProperty(this.mediaServer.states.audioEvents)) {
                this.states.audioEvent = newVals[this.mediaServer.states.audioEvents];
            }

            if (this.mediaServer.type !== MediaServerType.LXMUSIC_V2) {
                if (newVals.hasOwnProperty(this.mediaServer.states.apiVersion)) {
                    Debug.Media.ServerStateContainer && console.log("received apiVersion:" + JSON.stringify(newVals[this.mediaServer.states.apiVersion]));
                    this.states.apiVersion = newVals[this.mediaServer.states.apiVersion];

                    this._updateFeatures(this.states.apiVersion.text);
                }
            } else {
                delete this.states.features;
            }

            if (newVals.hasOwnProperty(this.mediaServer.states.serverState)) {
                Debug.Media.ServerStateContainer && console.log("received serverState:" + JSON.stringify(newVals[this.mediaServer.states.serverState]));
                this.states.serverState = newVals[this.mediaServer.states.serverState];
            }

            if (!this.states.groupColors && this.mediaServer.type !== MediaServerType.LXMUSIC_V2) {
                // audio servers share their groups across all servers - that's why they don't need individual ones
                this._getGroupColors(Object.keys(this.availableMediaServers));
            }

            if (newVals.hasOwnProperty(this.mediaServer.states.grouping)) {
                var groupingState = newVals[this.mediaServer.states.grouping];
                Debug.Media.ServerStateContainer && console.log("     received grouping~old-state:" + JSON.stringify(groupingState));

                if (groupingState.hasOwnProperty('text')) {
                    try {
                        var event = JSON.parse(groupingState.text);

                        this._updateGroupingState(event[MediaEnum.EventIdentifier.AUDIO.SYNC], false);
                    } catch (ex) {
                        console.error("MediaStateContainer", "Failed while parsing groupingState: " + groupingState.text);
                        console.error("MediaStateContainer", "Error = " + ex.message);
                    }
                } else {
                    this._updateGroupingState([], false);
                }

                Debug.Media.ServerStateContainer && console.log("     = grouping~old:" + JSON.stringify(this.states.grouping));
            }

            if (Feature.DETAILED_SERVER_STATE) {
                // if the connection is being or cannot be established, a detailed description of the current state available.
                this.states.connState = newVals[this.mediaServer.states.connState]; // 168169685: the serverState "offline" = standby is not being represented as idle, but as ready.

                if (this.states.serverState === MediaEnum.ServerState.OFFLINE) {
                    this.states.connState = MediaEnum.ServerConnState.IDLE;
                }

                this.states.connStateText = this._translateServerConnState(this.states.connState);
                Debug.Media.ServerStateContainer && console.log("  Connection State: " + this.states.connState + " = '" + this.states.connStateText + "'");
            }

            if (this.mediaServer.type === MediaServerType.LXMUSIC_V2) {
                this.states.certificateValid = !!newVals[this.mediaServer.states.certificateValid]; // The Miniserver sends the resolved IP of the Miniserver as an state. This is reqired for hostnames!

                if (this.mediaServer.states.hasOwnProperty("host")) {
                    this.states.host = newVals[this.mediaServer.states.host].text;
                    this.mediaServer.host = this.states.host || this.mediaServer.host;
                }
            }
        }

        onOtherServerStatesReceived(uuid, states) {
            Debug.Media.ServerStateContainer && console.log(this.name, "onOtherServerStatesReceived " + uuid, states); // important to clone, otherwise no state update is detected

            if (this._updateGroupingState(cloneObjectDeep(states.grouping), true)) {
                this.version++; // count up version with each update

                this.notifyListener();
            }
        }

        newGroupingInfoReceived(newGroups) {
            Debug.Media.ServerStateContainer && console.log(this.name, "_newGroupingInfoReceived: ", newGroups);

            if (this._updateGroupingState(newGroups, false)) {
                this.version++; // count up version with each update

                this.notifyListener();
            }
        }

        onMasterVolumeChange(masterVolumeChange) {
            Debug.Control.AudioZone.MasterVolume && console.log(this.name, "onMasterVolumeChange: " + JSON.stringify(masterVolumeChange));
            Debug.Media.ServerStateContainer && console.log(this.name, "onMasterVolumeChange: ", masterVolumeChange); // the event may not contain the players info, hence needs to be merged into the readily stored groups.
            //{ "group": "18afac28-01d7-d061-ffff-012e8e69d9b7", "mastervolume": 28 }

            this.states.grouping = this.states.grouping || [];

            if (this.states.grouping.some(function (groupObj) {
                if (groupObj.group === masterVolumeChange.group) {
                    groupObj[MusicServerEnum.Event.MASTER_VOLUME] = masterVolumeChange[MusicServerEnum.Event.MASTER_VOLUME];
                    return true;
                }
            }.bind(this))) {
                // a groups volume has been updated
                this.version++; // count up version with each update

                this.notifyListener();
            }
        }

        /**
         * Checks the current grouping array and updates it with the new one.
         * @param newGroups     the new groups received by other AS, the socket or the states from the Miniserver.
         * @param fromOtherAS   if true, it means the update is received from another audioservers state container
         * @returns {boolean}   true if modified, false if not.
         * @private
         */
        _updateGroupingState(newGroups, fromOtherAS) {
            this.states.grouping = this.states.grouping || [];
            var prevHash = JSON.stringify(this.states.grouping).hashCode();

            if (!newGroups || prevHash === JSON.stringify(newGroups).hashCode()) {
                return false;
            }

            Debug.Media.ServerStateContainer && console.log(this.name, "_updateGroupingState: " + (!!fromOtherAS ? " from OTHER AS" : " from OWN AS"), cloneObject(this.states.grouping)); // check if there are new groups

            if (newGroups && newGroups.length) {
                var oldGroupMap = {};
                this.states.grouping.forEach(function (oldGroup) {
                    oldGroupMap[oldGroup.group] = oldGroup;
                });
                this.states.grouping = newGroups; // ensure any potentially previously stored masterVolumeInfo is kept. (recent versions don't always
                // retransmit the mastervolume alongside the group events, but internally in the app that info is
                // stored)

                this.states.grouping.forEach(function (newGroup) {
                    var oldGroupObj = oldGroupMap[newGroup.group];

                    if (oldGroupObj && oldGroupObj[MusicServerEnum.Event.MASTER_VOLUME] && !newGroup.hasOwnProperty(MusicServerEnum.Event.MASTER_VOLUME)) {
                        console.warn(this.name, "   group " + newGroup.group + " would've lost group volume info!", oldGroupObj[MusicServerEnum.Event.MASTER_VOLUME]);
                        newGroup[MusicServerEnum.Event.MASTER_VOLUME] = oldGroupObj[MusicServerEnum.Event.MASTER_VOLUME];
                    }
                }.bind(this));
            } else {
                // default value if there are no groups
                this.states.grouping = [];
            }

            this.ServerComp.assignZoneGroupColors(this.states.grouping, this.states.groupColors);

            if (prevHash !== JSON.stringify(this.states.grouping).hashCode()) {
                Debug.Media.ServerStateContainer && console.log(this.name, "        new groups: ", cloneObjectDeep(newGroups));
                Debug.Media.ServerStateContainer && console.log(this.name, "    updated groups: ", cloneObjectDeep(this.states.grouping));
                return true;
            } else {
                return false;
            }
        }

        /**
         * Acquires the group-colors for this music server.
         * @param serverUuids
         * @private
         */
        _getGroupColors(serverUuids) {
            Debug.Media.ServerStateContainer && console.log(this.name, "_getGroupColors");
            var idx = serverUuids.indexOf(this.control.uuidAction),
                cnt = serverUuids.length,
                colors = cloneObject(MediaEnum.GROUP_COLORS),
                colorsPerSvr = Math.floor(colors.length / cnt);
            this.states.groupColors = colors.splice(idx * colorsPerSvr, colorsPerSvr);
            Debug.Media.ServerStateContainer && console.log("     colors for this svr:" + JSON.stringify(this.states.groupColors));
        }

        /**
         * Acquires a list of features this mediaServer has or has not.
         * @param apiVersion - string representation of the api version of the mediaserver
         * @private
         */
        _updateFeatures(versionString) {
            Debug.Media.ServerStateContainer && console.log(this.name, "_updateFeatures: " + JSON.stringify(versionString));
            this.states.api = this._getApiVersion(versionString);
            this.states.version = this._getFirmwareVersion(versionString);
            this.states.features = this.ServerComp.Feature.getFeatureSetForVersion(this.states.version, this.states.api);
        }

        _getApiVersion(msg) {
            // aquire api
            var apiIdentifier = "~API:";
            var apiStartIndex = msg.indexOf(apiIdentifier);

            if (apiStartIndex < 0) {
                throw "Initial message not an API-Message!";
            }

            apiStartIndex = apiStartIndex + apiIdentifier.length;
            var apiEndIndex = msg.indexOf("~", apiStartIndex);
            var apiVersionStr = msg.substring(apiStartIndex, apiEndIndex);
            Debug.Media.Parser && console.log("MediaMessageParser", "API: '" + apiVersionStr + "'");
            var apiVersionParts = apiVersionStr.split(".");

            for (var i = 0; i < apiVersionParts.length; i++) {
                apiVersionParts[i] = parseInt(apiVersionParts[i]);
            }

            return apiVersionParts;
        }

        _getFirmwareVersion(msg) {
            // 'LWSS V 0.0.4.22b | ~API:1.0~'
            var versionIdentifier = "LWSS V ";
            var versionStartIndex = msg.indexOf(versionIdentifier);

            if (versionStartIndex < 0) {
                throw "Initial message not an API-Message!";
            }

            versionStartIndex = versionStartIndex + versionIdentifier.length;
            var versionEndIndex = msg.indexOf(" | ", versionStartIndex);
            var versionStr = msg.substring(versionStartIndex, versionEndIndex);
            Debug.Media.Parser && console.log("MediaMessageParser", "Version: '" + versionStr + "'");
            return versionStr;
        }

        /**
         * Translates the connState (error if not reachable, state if booting) into a user-friendly, localized string.
         * @param connState
         * @returns {*}
         * @private
         */
        _translateServerConnState(connState) {
            var stateText,
                error = this.states.serverState === MediaEnum.ServerState.NOT_REACHABLE;

            switch (connState) {
                case MediaEnum.ServerConnState.NO_PING:
                    stateText = error ? _("media.error.no-ping") : _("media.conn.no-ping");
                    break;

                case MediaEnum.ServerConnState.NO_WEBINTERFACE:
                    stateText = error ? _("media.error.no-webinterface") : _("media.conn.no-webinterface");
                    break;

                case MediaEnum.ServerConnState.NO_COMM_SERVICE:
                    stateText = error ? _("media.error.no-comm-service") : _("media.conn.no-comm-service");
                    break;

                case MediaEnum.ServerConnState.NO_MUSIC_SERVICE:
                    stateText = error ? _("media.error.no-music-service") : _("media.conn.no-music-service");
                    break;

                case MediaEnum.ServerConnState.READY:
                    stateText = error ? "" : _("media.conn.okay");
                    break;

                default:
                    stateText = "";
                    break;
            }

            return stateText;
        }

    };
});
