'use strict';

define("AudioZoneCentralControlStateContainer", ["ControlCentralStateContainer"], function (ControlCentralStateContainer) {
    return class AudioZoneCentralControlStateContainer extends ControlCentralStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.nrOfPlaying = 0;
            this.states.nrOfPlaying_Sel = 0;
            var rooms = {};
            var rooms_Sel = {};
            newVals.forEachState(function (subCtrlNewVals, control, isCtrlSelected) {
                if (subCtrlNewVals && subCtrlNewVals.isPlaying) {
                    rooms[control.room || UnassignedUUID] = true;

                    if (isCtrlSelected) {
                        rooms_Sel[control.room || UnassignedUUID] = true;
                    }
                }
            }.bind(this));
            this.states.nrOfPlaying = Object.keys(rooms).length;
            this.states.nrOfPlaying_Sel = Object.keys(rooms_Sel).length;
        }

        getStateText() {
            if (this.states.nrOfPlaying === 0) {
                return _("media.not-active-anywhere");
            } else {
                return _("media.active-in-rooms", {
                    count: this.states.nrOfPlaying
                });
            }
        }

        getStateColor() {
            if (this.states.nrOfPlaying > 0) {
                return window.Styles.colors.stateActive;
            }
        }

    };
});
