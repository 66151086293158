'use strict';

define("DimmerControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class DimmerControlStateContainer extends ControlStateContainer {
        static DEFAULT_STEP = 1
        constructor(control) {
            super(control);
            this.states.color = LxColorUtils.createColorObjectFromColorString(Color.Dimmer.ON);
        }

        prepareStates(newVals) {
            this.states.pos = newVals[this.control.states.position];
            this.states.step = newVals[this.control.states.step] ?? DimmerControlStateContainer.DEFAULT_STEP;

            if (this.control.type === ControlType.DIMMER_EIB) {
                this.states.min = 0;
                this.states.max = 100;
            } else {
                this.states.min = newVals[this.control.states.min];
                this.states.max = newVals[this.control.states.max];
            } // Validate the max value
            // We can't work with a max value that is smaller or equal with the min value


            if (this.states.max <= this.states.min) {
                this.states.max = 100;
            } // We can't work with a non existent step


            if (this.states.step === 0) {
                this.states.step = 1;
            }

            this.states.isOn = this.states.pos > 0 && this.states.pos >= this.states.min;
            this.states.percentage = this._getPercentage(); // dimmers represent the color per default with a warm white color, whos brightness is modified.

            this.states.color.brightness = this.states.percentage;
        }

        getStateText() {
            return lxFormat("<v.0> %", this.states.percentage);
        }

        getStateColor() {
            if (this.states.isOn) {
                return window.Styles.colors.stateActive;
            }
        }

        /**
         * Computes the percentage of the dimmer. Keeps in mind it mustn't go beyond 100%.
         * @returns {number}
         * @private
         */
        _getPercentage() {
            return map(this.states.pos, [this.states.min, this.states.max], [0, 100]);
        }

    };
});
