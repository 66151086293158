'use strict';

define("ColorPickerV2ControlStateContainer", ["ControlStateContainer", "ColorPickerV2ControlEnums"], function (ControlStateContainer, ColorPickerV2ControlEnums) {
    return class ColorPickerV2ControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            this.states.selectedMode = ColorPickerV2ControlEnums.PickerMode.NONE;
        }

        /**
         * returns array of uuids to registered.
         * This is adopted as we want to additionally register for global states.
         * @returns {*[]}
         */
        getUUIDsToRegister() {
            var uuids = super.getUUIDsToRegister(...arguments);
            var globalStates = ActiveMSComponent.getStructureManager().getGlobalStateUUIDs();
            this.sequenceFavoritesUUID = globalStates.favColorSequences;
            this.colorFavoritesUUID = globalStates.favColors;
            this.sequenceFavoritesUUID && uuids.push(this.sequenceFavoritesUUID);
            this.colorFavoritesUUID && uuids.push(this.colorFavoritesUUID);
            return uuids;
        }

        /**
         * handles incoming values and prepares the states
         * @param newVals
         */
        prepareStates(newVals) {
            this.states.color = LxColorUtils.createColorObjectFromColorString(newVals[this.control.states.color].text || "hsv(0,0,0)");

            this._prepareSequenceInfos(newVals);

            this.states.selectedMode = this._detectSelectedMode(this.states.color, this.states.sequence, this.states.sequenceColorIdx);
            this.states.powerOn = this.states.selectedMode !== ColorPickerV2ControlEnums.PickerMode.NONE;

            try {
                this.states.favColors = JSON.parse(newVals[this.colorFavoritesUUID].text);
                this.states.favColors = this._prepareColorArray(this.states.favColors);
            } catch (ex) {
                //console.error("Could not parse the favorites: " + newVals[this.colorFavoritesUUID].text);
                this.states.favColors = [];
            }

            try {
                this.states.favSequences = this._readFavoriteSequences(newVals);
            } catch (ex) {
                //console.error("Could not parse the favorite sequences: " + newVals[this.sequenceFavoritesUUID].text);
                this.states.favSequences = [];
            }

            this.states.sequenceActive = this.states.selectedMode === ColorPickerV2ControlEnums.PickerMode.SEQUENCE;

            switch (this.states.selectedMode) {
                case ColorPickerV2ControlEnums.PickerMode.HSV:
                case ColorPickerV2ControlEnums.PickerMode.TEMPERATURE:
                    this.states.activeBrightness = this.states.color.brightness;
                    this.states.activeColors = [this.states.color];
                    break;

                case ColorPickerV2ControlEnums.PickerMode.DAYLIGHT:
                case ColorPickerV2ControlEnums.PickerMode.SEQUENCE:
                    this.states.activeBrightness = this.states.sequence.colors[0].brightness;
                    this.states.activeColors = this.states.sequence.colors;
                    break;

                default:
                    this.states.activeColors = [this.states.color];
                    this.states.activeBrightness = 0;
                    break;
            }

            Debug.Control.ColorPickerV2 && console.log(this.control.name, "prepareStates");
            Debug.Control.ColorPickerV2 && console.log("    color:        " + newVals[this.control.states.color].text);
            Debug.Control.ColorPickerV2 && console.log("    sequence:     " + newVals[this.control.states.sequence].text);
            Debug.Control.ColorPickerV2 && console.log("    seqColorIdx:  " + newVals[this.control.states.sequenceColorIdx]);
            Debug.Control.ColorPickerV2 && console.log("    favColors:    " + newVals[this.colorFavoritesUUID].text);
            Debug.Control.ColorPickerV2 && console.log("    favSequences: " + newVals[this.sequenceFavoritesUUID].text);
            Debug.Control.ColorPickerV2 && console.log("    selectedMode: " + this.states.selectedMode);
        }

        /**
         * Will read and clean up the current sequence state and the current sequence color index state.
         * @param newVals
         * @private
         */
        _prepareSequenceInfos(newVals) {
            var sequence,
                sqText = newVals[this.control.states.sequence].text;

            try {
                sequence = JSON.parse(sqText);
                this.states.sequence = this._prepareSequence(sequence);
                this.states.sequenceColorIdx = newVals[this.control.states.sequenceColorIdx];
            } catch (ex) {
                sequence = {
                    interval: 60 * 30
                };

                if (this.states.color && this.states.color.brightness > 0 && this.states.color.type !== ColorPickerV2ControlEnums.PickerMode.TEMPERATURE) {
                    sequence.colors = [this.states.color];
                } else {
                    sequence.colors = [];
                }

                this.states.sequence = sequence;
                this.states.sequenceColorIdx = -1;
            }
        }

        /**
         * Will return true if the color indicates that the light is basically off.
         * @param color         the color object to evaluate
         * @returns {boolean}
         * @private
         */
        _isOff(color) {
            var isOff = !color;

            if (!isOff) {
                switch (color.type) {
                    case ColorPickerV2ControlEnums.PickerMode.TEMPERATURE:
                        isOff = color.kelvin === 0 && color.brightness === 0;
                        break;

                    case ColorPickerV2ControlEnums.PickerMode.HSV:
                        isOff = color.hue === 0 && color.sat === 0 && color.brightness === 0;
                        break;

                    default:
                        break;
                }
            }

            return isOff;
        }

        /**
         * Will detect in what mode the picker is in (Sequence, HSV or Color Temperature)
         * @param color
         * @param sequence
         * @param sqColorIdx
         * @returns {number}
         * @private
         */
        _detectSelectedMode(color, sequence, sqColorIdx) {
            var mode = ColorPickerV2ControlEnums.PickerMode.NONE;

            if (sequence.type === ColorPickerV2ControlEnums.PickerMode.DAYLIGHT) {
                mode = ColorPickerV2ControlEnums.PickerMode.DAYLIGHT;
            } else {
                if (sequence && sqColorIdx >= 0) {
                    mode = ColorPickerV2ControlEnums.PickerMode.SEQUENCE;
                } else if (!this._isOff(color)) {
                    mode = color.type; // HSV or temp
                }
            }

            return mode;
        }

        /**
         * Will read the favorites from the global state and perpare it to be used on the UI
         * @param newVals
         * @returns {*|Array}
         * @private
         */
        _readFavoriteSequences(newVals) {
            var favs = JSON.parse(newVals[this.sequenceFavoritesUUID].text),
                result = [];
            favs.forEach(function (fav) {
                result.push(this._prepareSequence(fav));
            }.bind(this));
            return result;
        }

        _prepareColorArray(colors) {
            var result = [];
            colors.forEach(function (colorStr) {
                colorStr && result.push(LxColorUtils.createColorObjectFromColorString(colorStr));
            });
            return result;
        }

        /**
         * Will ensure the type in the sequence and the colors are properly provided to be used within the app.
         * @param sequence
         * @returns {*}
         * @private
         */
        _prepareSequence(sequence) {
            if (sequence.type === ColorPickerV2ControlEnums.MS_DAYLIGHT_MODE) {
                sequence.type = ColorPickerV2ControlEnums.PickerMode.DAYLIGHT;
            } else {
                sequence.type = ColorPickerV2ControlEnums.PickerMode.SEQUENCE;
            }

            sequence.colors = this._prepareColorArray(sequence.colors);
            return sequence;
        }

    };
});
