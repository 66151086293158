'use strict';

import globalStyles from "GlobalStyles";

/**
 * Handles Time Information.
 */

ActiveMSComp.factory('TimeExt', function () {
    var MAX_TIME_DIFFERENCE = 10 * 60 * 1000; // 10 min

    var CONSEC_KEY_START = 1; // don't start at 0, it makes comparisons difficult (0 === false)
    // internal variables

    let weakThis,
        activeMsComp,
        bigDiffPopup,
        msDateTime,
        msUtcOffset,
        msTimezoneId,
        differenceMiniserverAndDevice,
        consecutiveKey = CONSEC_KEY_START,
        registeredTimeReceiver = {},
        activeIntervals = {},
        timeLog = [],
        checkTimeDiffTimeout,
        timeAdjustmentAsked = false; // app presents the time difference popup on every connection re-established which makes it very annoying on weak connections

    /**
     * c-tor for Time-Extension
     * @param comp reference to the ActiveMSComponent
     * @constructor
     */

    function TimeExt(comp) {
        weakThis = this
        activeMsComp = comp; // internal broadcasts

        activeMsComp.on(ActiveMSComp.ECEvent.STRUCTURE_READY, function () {
            if (Feature.TIME_UPDATES) {
                Debug.TimeExt && _addToLog("Structure Ready");
                this.registerForTimeState();
            }
        }.bind(this));
        activeMsComp.on(ActiveMSComp.ECEvent.StopMSSession, function () {
            // reset all data
            msDateTime = null;
            msUtcOffset = null;
            msTimezoneId = null;
            differenceMiniserverAndDevice = null; // ensure no time diff warning is shown when the MS is no longer around.

            checkTimeDiffTimeout && clearTimeout(checkTimeDiffTimeout);
            checkTimeDiffTimeout = null;

            for (var key in activeIntervals) {
                if (activeIntervals.hasOwnProperty(key)) {
                    clearInterval(activeIntervals[key]);
                }
            }

            consecutiveKey = CONSEC_KEY_START;
            registeredTimeReceiver = {};
            activeIntervals = {};
        }.bind(this));
    } // public methods

    /**
     * Received the current miniserver utc offset from the msInfoExt
     */


    TimeExt.prototype.receivedMsUtc = function receivedMsUtc(utc) {
        if (msUtcOffset !== utc) {
            msUtcOffset = utc;

            _dispatchFormats([TimeValueFormat.MINISERVER_UTC_OFFSET]);
        }
    };
    /**
     * Received the current miniserver date time from the msInfoExt
     * New: Since Feature.TIME_UDPATES it will also receive time updates when the UI is up and running via state updates
     * from the websocket.
     */


    TimeExt.prototype.receivedMsDateTime = function receivedMsDateTime(dateTime) {
        if (msDateTime === dateTime || !msUtcOffset) {
            // Don't dispatch any events if the dateTime is the same, or if we don't have any utf offset yet, as it is used to calculate the ms date
            return;
        }

        checkTimeDiffTimeout && clearTimeout(checkTimeDiffTimeout);
        if(!weakThis.timeAdjustmentAsked) {
            msDateTime = dateTime;
            var timeString = msDateTime + " " + msUtcOffset;
            var miniserverDate = moment(timeString, "YYYY-MM-DD HH:mm:ss Z").utc();
            var localDate = moment.utc();
            differenceMiniserverAndDevice = miniserverDate.diff(localDate, 'milliseconds'); // use a timeout to check the time diff later. With our new API it might happen, that for a very short period an
            // incorrect time is received, which will be corrected asap. Without this handling this could cause an eco mode
            // to stop, making it more creepy than comfortable.
            checkTimeDiffTimeout = setTimeout(function () {
                _checkTimeDiff();
            }, 5000); // dispatch to all receiver and optionally start interval timer, if not already up and running
        } else {
            // we already asked the user to adjust the time, so we don't need to check the time difference again in this app session
        }

        _dispatchFormats([TimeValueFormat.MINISERVER_DATE_TIME, TimeValueFormat.MINUTES_SINCE_MIDNIGHT]);
    };

    /**
     * Received the current miniserver timezone ID from the msInfoExt
     */


    TimeExt.prototype.receivedTimezoneId = function receivedTimezoneId(timezoneId) {
        if (timezoneId !== msTimezoneId) {
            msTimezoneId = timezoneId;
            moment.tz.setDefault(msTimezoneId);

            _dispatchFormats([TimeValueFormat.MINISERVER_TIMEZONE_ID]);
        }
    };

    /**
     * Returns the unixUtcTimesStamp the miniserver is currently on. in Seconds
     * @returns {number} SECONDS since 1970
     */
    TimeExt.prototype.getMiniserverUnixUtcTimestamp = function getMiniserverUnixUtcTimestamp() {
       return Math.round((moment.utc() + differenceMiniserverAndDevice) / 1000);
    }

    /**
     * Used to request the unixUtcTimestamp of e.g. the unixUtcTimestamp when the day starts for the Miniserver on the
     * date provided by the sourceUnixUtcTs
     * @param sourceUnixUtcTs   SECONDS! based this timestamp the start of the unit or end of the unit will be requested
     * @param unit       the unit for which the start/end is requested (year, month, week, day)
     * @returns {{start: number, end: number}}    unixUtcTs when the provided unit starts/ends for the timestamp requested in SECONDS
     */
    TimeExt.prototype.getStartAndEndOfAsUnixUtcTimestamp = function getStartAndEndOfAsUnixUtcTimestamp(sourceUnixUtcTs, unit) {
        var mObj,
            utcStart, utcEnd,
            needsToAddDay = false;

        // the incoming timestamp is UTC.
        mObj = moment.utc(sourceUnixUtcTs * 1000);

        if(Feature.TIMEZONE_ID_INFO) {
            mObj.tz(msTimezoneId);
        } else {
            // a moment object needs to be created that is in the MS' time-zone.
            mObj.utcOffset(msUtcOffset);
        }

        // then change to the start of (year month week day hour )
        switch (unit) {
            case "year":
            case "month":
            case "day":
            case "hour":
                break;
            case "week":
                unit = "isoWeek"; // always starts with monday!
                break;
            default:
                throw new Error("Day of '" + unit + "' not supported!");
        }
        mObj = mObj.startOf(unit);
        utcStart = mObj.unix();

        // get the unix UTC timestamp of that point in time.
        mObj = mObj.endOf(unit);
        utcEnd = mObj.unix();

        return { start: utcStart, end: utcEnd };
    }


    /**
     * Returns true if the unixUtcTs is within the current unit (hour/day/week/month/year)
     * @param unixUtcTs the unixUtc-Timestamp to check for in SECONDS since 1970
     * @param unit      hour/day/month/week/year
     * @returns {boolean}
     */
    TimeExt.prototype.isUnixUtcTsWithinCurrentRange = function isUnixUtcTsWithinCurrentRange(unixUtcTs, unit) {
        let currMsUnixUtc = this.getMiniserverUnixUtcTimestamp(),
            currRange = this.getStartAndEndOfAsUnixUtcTimestamp(currMsUnixUtc, unit);
        return unixUtcTs >= currRange.start && unixUtcTs <= currRange.end;
    }

    /**
     * Only dispatches the passed TimeValueFormats in formats
     * @param formats
     * @private
     */
    var _dispatchFormats = function _dispatchFormats(formats) {
        Object.keys(registeredTimeReceiver).forEach(function (key) {
            var receiverInfo = registeredTimeReceiver[key];

            if (formats.indexOf(receiverInfo.type) !== -1) {
                dispatchCurrentValue(receiverInfo); // keep in mind that there might be update intervals in place already, if so, there's no need to restart them.

                if (receiverInfo.updateInterval && !activeIntervals.hasOwnProperty(key)) {
                    // start new interval
                    activeIntervals[key] = setInterval(dispatchCurrentValue.bind(this, receiverInfo), receiverInfo.updateInterval);
                }
            }
        });
    };
    /**
     * Checks the difference between the Miniserver time and the devices time. If there is a considerable difference (aside
     * being in different time zones) it will prompt a warning, optionally offering to adopt the Miniservers time setting.
     */


    var _checkTimeDiff = function _checkTimeDiff() {
        if (Math.abs(differenceMiniserverAndDevice) >= MAX_TIME_DIFFERENCE) {
            Debug.TimeExt && _addToLog("Considerable time difference detected!"); // only show a warning if the user has the option do adopt the time!

            SandboxComponent.checkPermission(MsPermission.SYS_WS) && presentTimeDiffWarning(differenceMiniserverAndDevice);
        } else {
            Debug.TimeExt && _addToLog("No considerable time difference detected!");
            hideTimeDiffWarning();
        }

        checkTimeDiffTimeout = null;
    };
    /**
     * returns the Miniserver time and just pretend it's the UTC Time (needed for statistics)
     * @returns {moment}
     */


    TimeExt.prototype.getMiniserverTimeAsFakeUTC = function getMiniserverTimeAsFakeUTC() {
        return moment.utc(getMiniserverDateTime().format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss"); // work with format, otherwise moment will change the time along with .utc()
    };
    /**
     *
     * @param receiver context for the receiver
     * @param callback callback function will be called when time info is available or interval fired
     * @param timeValueFormat TimeValueFormat object which format/unit of date will be returned
     * @param updateInterval interval for the callback function, won't fire when not given
     * @returns {*} Returns the current Miniserver Time. When a callback function is given, it returns the key to remove the receiver
     */


    TimeExt.prototype.getMiniserverTimeInfo = function getMiniserverTimeInfo(receiver, callback, timeValueFormat, updateInterval) {
        if (!callback && typeof differenceMiniserverAndDevice === 'number') {
            // sync!
            return getValueForTimeFormat(timeValueFormat);
        } else if (!callback) {
            return weakThis.handleUnknownMsTime(timeValueFormat);
        }

        var receiverKey; // if we have time information, dispatch it, otherwise register receiver

        if (typeof differenceMiniserverAndDevice === 'number') {
            callback.call(receiver, getValueForTimeFormat(timeValueFormat)); // register callback when receiver needs updates

            receiverKey = addReceiver(receiver, callback, timeValueFormat, updateInterval);

            if (updateInterval) {
                // start interval
                activeIntervals[receiverKey] = setInterval(dispatchCurrentValue.bind(this, registeredTimeReceiver[receiverKey]), updateInterval);
            }
        } else {
            receiverKey = addReceiver(receiver, callback, timeValueFormat, updateInterval);
        }

        return receiverKey;
    };
    /**
     * Returns the current Miniserver Time
     */


    TimeExt.prototype.removeFromTimeInfo = function removeFromTimeInfo(key) {
        if (activeIntervals[key]) {
            clearInterval(activeIntervals[key]);
            delete activeIntervals[key];
        }

        delete registeredTimeReceiver[key];
    };
    /**
     * Called when a synchronous request for the current time is made, but no info on the miniservers time is available
     * yet. In this case, best guess based on the local device time is the way to go.
     * @param timeValueFormat
     * @return {*|moment.Moment}
     */


    TimeExt.prototype.handleUnknownMsTime = function handleUnknownMsTime(timeValueFormat) {
        console.warn("A Miniserver Time Information was requested while the current ms time is not known yet - best guessing!");
        var result;

        switch (timeValueFormat) {
            case TimeValueFormat.MINISERVER_UTC_OFFSET:
                result = 0; //TODO-woessto: find out!

                break;

            case TimeValueFormat.MINUTES_SINCE_MIDNIGHT:
                result = 0; //TODO-woessto: find out!

                break;

            default:
                result = moment();
                break;
        }

        return result;
    };
    /**
     * Will register for state updates that notify on time changes.
     */


    TimeExt.prototype.registerForTimeState = function registerForTimeState() {
        var globalStates = ActiveMSComponent.getStructureManager().getGlobalStateUUIDs();

        if (globalStates.miniserverTime) {
            SandboxComponent.registerForStateChangesForUUID(GLOBAL_UUID, this, this.globalStatesReceived.bind(this));
        }
    };
    /**
     * When the time changes, the state update notifies about it. It contains both the new date/time and the UTC offset.
     * @param states
     */


    TimeExt.prototype.globalStatesReceived = function globalStatesReceived(states) {
        if (states.miniserverTime) {
            Debug.TimeExt && _addToLog("Time State Update: " + states.miniserverTime);
            var parts = states.miniserverTime.split(" "),
                utcOffset = parts.splice(parts.length - 1, 1)[0],
                dateTime = parts.join(" ");
            this.receivedMsUtc(utcOffset);
            this.receivedMsDateTime(dateTime);
        } else {
            Debug.TimeExt && _addToLog("Time State Update: --not known yet--");
        }
    };
    /**
     * Will convert a given javascript date object to a moment.js date object in the miniservers time zone.
     * It will keep the jsDateObjects date & time, but it will ensure the result has the miniservers time zone.
     * @param jsDate            the date to convert to a moment.js miniserver date - ignoring the js dates time zone
     * @return {moment.Moment|*}
     */


    TimeExt.prototype.convertToMsDate = function convertToMsDate(jsDate) {
        var msUtcOffsetMins = getMiniserverDateTime().utcOffset(); // e.g. -60 for GMT

        return prepareDateWithZone(moment(jsDate), msUtcOffsetMins);
    };
    /**
     * Get javascript date object from moment.js miniserver date object. The resulting jsDate object will have the date
     * and time represented by the miniserver, but it will have the devices time zone.
     * @param momentDate    the moment.js date object to be converted to a jsDate object, it will remain unchanged.
     * @return {Date}       date & time from moment object, timezone of this device.
     */


    TimeExt.prototype.convertToJsDate = function convertToJsDate(momentDate) {
        // moment.js' .toDate will return the miniservers time, but viewed from the devices time zone.
        // e.g. 10:05 CET on the Miniserver will return 02:05 PST device time (when the device is on PST).
        // But we need a js date object, representing the miniservers local time, the time zone may be
        // ignored in this case. By passing keepLocalTime "true" to moment.js' local-method, the timezone
        // will remain in PST, but the time in the internal jsDate-Object will change to the one provided
        // in momentDate.
        return momentDate.clone().local(true).toDate();
    };

    TimeExt.prototype.getMomentFromUtcTimestamp = function getMomentFromUtcTimestamp(utcTs) {
        const mObj = moment.utc(utcTs * 1000);
        if(!Feature.TIMEZONE_ID_INFO) {
            mObj.utcOffset(msUtcOffset);
        } else {
            mObj.tz(msTimezoneId);
        }
        return mObj;
    };

    var _addToLog = function _addToLog(logEntry) {
        var i;
        timeLog.splice(0, 0, {
            entry: logEntry,
            timestamp: new Date()
        });

        if (timeLog.length > 100) {
            timeLog.splice(100, 1);
        }

        console.log("-----------------------------------------------");
        console.log(" Time Extension Log: ");

        for (i = 0; i < 20 && i < timeLog.length; i++) {
            console.log(" - " + timeLog[i].timestamp + ": " + timeLog[i].entry);
        }

        if (i < timeLog.length) {
            console.log("    .. there are " + (timeLog.length - i) + " additional entries..");
        }

        console.log("-----------------------------------------------");
    };

    var addReceiver = function addReceiver(receiver, callback, timeValueFormat, updateInterval) {
        var currentKey = consecutiveKey;
        registeredTimeReceiver[consecutiveKey] = {
            context: receiver,
            fn: callback,
            type: timeValueFormat,
            updateInterval: updateInterval
        };
        consecutiveKey++;
        return currentKey;
    };

    var dispatchCurrentValue = function dispatchCurrentValue(receiverInfo) {
        if (msDateTime == null) {
            cleanUpRegistrations();
        } else {
            receiverInfo.fn.call(receiverInfo.context, getValueForTimeFormat(receiverInfo.type));
        }
    };

    var getValueForTimeFormat = function getValueForTimeFormat(type) {
        var result;

        switch (type) {
            case TimeValueFormat.MINISERVER_DATE_TIME:
                result = getMiniserverDateTime();
                break;

            case TimeValueFormat.MINISERVER_UTC_OFFSET:
                result = getMiniserverUtcOffset();
                break;

            case TimeValueFormat.MINUTES_SINCE_MIDNIGHT:
                result = getMinutesSinceMidnight();
                break;
            case TimeValueFormat.MINISERVER_TIMEZONE_ID:
                result = getMiniserverTimezoneId();
                break;

            default:
                result = getMiniserverDateTime();
        }

        return result;
    };

    var getMiniserverDateTime = function getMiniserverDateTime() {
        var miniserverDate = moment(); // the device might not be on the same time zone as the miniserver

        if(!Feature.TIMEZONE_ID_INFO) { // we only apply the offset manually if we don't have the timezone id, otherwise moment.js will do it for us
            miniserverDate.utcOffset(msUtcOffset); // the time on the Miniserver might not be equal to the devices time (even on UTC)
        } else {
            miniserverDate.tz(msTimezoneId);
        }
        miniserverDate.add(differenceMiniserverAndDevice, 'milliseconds');
        
        return miniserverDate;
    };

    var getMiniserverUtcOffset = function getMiniserverUtcOffset() {
        return msUtcOffset;
    };

    var getMinutesSinceMidnight = function getMinutesSinceMidnight() {
        var msTime = getMiniserverDateTime();
        return msTime.get('hours') * 60 + msTime.get('minutes');
    };

    var getMiniserverTimezoneId = function getMiniserverTimezoneId() {
        return msTimezoneId;
    };
    /**
     * Will present a popup that informs the user on the severe difference between the miniservers and the devices time.
     * @param differenceMiniserverAndDevice
     */


    var presentTimeDiffWarning = function presentTimeDiffWarning(differenceMiniserverAndDevice) {
        Debug.TimeExt && _addToLog("presentTimeDiffWarning");
        var content = {
            title: _("dateTime.time-difference.title"),
            message: _("dateTime.time-difference.message", {
                timeDifference: "(" + LxDate.formatSecondsShort(Math.abs(differenceMiniserverAndDevice / 1000)) + ")"
            }),
            buttonOk: true,
            buttonCancel: true,
            icon: Icon.CAUTION
        };
        bigDiffPopup && NavigationComp.removePopup(bigDiffPopup);
        bigDiffPopup = NavigationComp.showPopup(content);
        bigDiffPopup.then(function () {
            bigDiffPopup = null;
            adoptMsTime();
        }, function (btn) {
            if (btn === GUI.PopupBase.ButtonType.CANCEL) {
                console.info("User didn't want to adjust the miniserver time. We won't ask him again in this App session.");
                weakThis.timeAdjustmentAsked = true;
                bigDiffPopup = null;
            }
        });
    };

    var hideTimeDiffWarning = function hideTimeDiffWarning() {
        Debug.TimeExt && bigDiffPopup && _addToLog("hideTimeDiffWarning");
        bigDiffPopup && NavigationComp.removePopup(bigDiffPopup);
    };
    /**
     * Will set the devices current local time as the new miniserver time.
     */


    var adoptMsTime = function adoptMsTime() {
        // Its important to always send the Miniserver time based on the Miniservers timezone
        let clientNow = moment(), cmd = '';
        if (Feature.SET_DATE_TIME_UTC) {
            clientNow = moment().utc(),
            cmd = Commands.format(Commands.SET_DATE_TIME_UTC, clientNow.format("YYYY-MM-DD HH:mm:ss"));
        } else {
            clientNow = moment().utc().utcOffset(msUtcOffset),
            cmd = Commands.format(Commands.SET_DATE_TIME, clientNow.format("YYYY-MM-DD HH:mm:ss"));
        }

        SandboxComponent.sendWithPermission(cmd, MsPermission.SYS_WS).done(function () {
            differenceMiniserverAndDevice = 0;
            NavigationComp.showPopup({
                message: _("dateTime.time-difference.success"),
                color: globalStyles.colors.green,
                icon: Icon.SUCCESS,
                buttonOk: true
            });
        }, function (btn) {
            if (btn === GUI.PopupBase.ButtonType.CANCEL) {
                return; // cancelled. nothing to do.
            }

            NavigationComp.showPopup({
                title: _("caution"),
                message: _("dateTime.time-difference.warning"),
                icon: Icon.CAUTION,
                buttonOk: true
            });
        });
    };
    /**
     * cancels all timeouts and removes all references
     */


    var cleanUpRegistrations = function () {
        // bail out!
        console.info("cleaning up time registrations..");

        for (var intervalId in activeIntervals) {
            if (activeIntervals.hasOwnProperty(intervalId)) {
                clearInterval(activeIntervals[intervalId]);
                delete activeIntervals[intervalId];
            }
        }

        for (var receiverKey in registeredTimeReceiver) {
            if (registeredTimeReceiver.hasOwnProperty(receiverKey)) {
                delete registeredTimeReceiver[receiverKey];
            }
        }
    };

    return TimeExt;
});
window.TimeValueFormat = {
    MINISERVER_DATE_TIME: 0,
    MINISERVER_UTC_OFFSET: 1,
    MINUTES_SINCE_MIDNIGHT: 2,
    MINISERVER_TIMEZONE_ID: 3
};
