'use strict';

ActiveMSComp.factory('AutomaticDesignerMenuExt', function () {
    let weakThis;

    function AutomaticDesignerMenuExt(comp) {
        this.name = "AutomaticDesignerMenuExt";
        this.savedValues = {};
        this.savedOperators = {};
        this.comp = comp;
        weakThis = this
    }

    AutomaticDesignerMenuExt.prototype.setValueAtIndexForXPath = function setValueAtIndexForXPath(xPath, value, index) {
        if (!index) {
            index = 0;
        }

        if (!weakThis.savedValues.hasOwnProperty(xPath)) {
            weakThis.savedValues[xPath] = [];
        }

        weakThis.savedValues[xPath][index] = value;
    };

    AutomaticDesignerMenuExt.prototype.setValuesForXPath = function setValuesForXPath(xPath, values) {
        weakThis.savedValues[xPath] = values;
    };

    AutomaticDesignerMenuExt.prototype.setOperatorForXPath = function setOperatorForXPath(xPath, operator) {
        weakThis.savedOperators[xPath] = operator;
    };

    AutomaticDesignerMenuExt.prototype.getValuesForXPath = function getValuesForXPath(xPath) {
        return weakThis.savedValues[xPath];
    };

    AutomaticDesignerMenuExt.prototype.getValueAtIndexForXPath = function getValueAtIndexForXPath(xPath, index) {
        var returnVal = null;

        if (weakThis.savedValues[xPath]) {
            returnVal = weakThis.savedValues[xPath][index || 0];
        }

        return returnVal;
    };

    AutomaticDesignerMenuExt.prototype.resetForXPath = function setValuesForXPath(xPath) {
        delete weakThis.savedValues[xPath];
        delete weakThis.savedOperators[xPath];
    };

    AutomaticDesignerMenuExt.prototype.getOperatorForXPath = function getOperatorForXPath(xPath) {
        return weakThis.savedOperators[xPath];
    };

    AutomaticDesignerMenuExt.prototype.getOperatingModesBaseDataSet = function getOperatingModesBaseDataSet(dataSet, screenType) {
        var items = ActiveMSComponent.getStructureManager().getOperatingModes(true, screenType === AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS),
            mappedItem = _getMappedItem(dataSet);

        mappedItem.entries = [];
        Object.keys(items).forEach(function (opId) {
            if (!dataSet.entries[0].exclude.includes(parseInt(opId))) {
                mappedItem.entries.push({
                    name: items[opId],
                    id: opId,
                    type: mappedItem.type,
                    flatList: dataSet.entries[0].flatList,
                    entries: [{
                        intType: [AutomaticDesignerEnums.KNOWN_INT_TYPES_CATEGORIES.OPERATING_MODE]
                    }]
                });
            }
        });
        return mappedItem;
    };

    AutomaticDesignerMenuExt.prototype.getWeatherBaseDataSet = function getWeatherBaseDataSet(dataSet, screenType) {
        var items = ActiveMSComponent.getStructureManager().getWeatherFieldTypes(),
            mappedItem = _getMappedItem(dataSet),
            availableWeatherFieldIds = weakThis.comp.getAvailableIntTypesForType(mappedItem.type, screenType),
            weatherServiceState,
            isWeatherServiceAvailable,
            globalStates = SandboxComponent.getStatesForUUID(GLOBAL_UUID),
            cloudServiceRegistrationStates = globalStates.states.cloudservice;

        mappedItem.entries = [];
        Object.values(items).forEach(function (item) {
            // Check if the capability is available
            if (availableWeatherFieldIds.includes(item.id)) {
                mappedItem.entries.push({
                    name: item.name,
                    id: item.id,
                    type: mappedItem.type,
                    flatList: dataSet.entries[0].flatList,
                    entries: [{
                        intType: [item.id]
                    }]
                });
            }
        });

        if (Feature.GLOBAL_CLOUD_STATES) {
            weatherServiceState = cloudServiceRegistrationStates.services.find(function (service) {
                return service.name === AutomaticDesignerEnums.WEATHER_TYPE;
            });
            isWeatherServiceAvailable = weatherServiceState.status;
        } else {
            var weatherStates = SandboxComponent.getStatesForUUID(WEATHER_UUID),
                hasWeatherService = weatherStates && weatherStates.states.hasValidData;
            isWeatherServiceAvailable = hasWeatherService && mappedItem.entries && mappedItem.entries.length;
        } // Function to show the weather add


        mappedItem.showAdFunc = function showAddFunc(prevShow) {
            if (isWeatherServiceAvailable) {
                return false;
            } else {
                if (!prevShow) {
                    var def = Q.defer();
                    NavigationComp.showState(ScreenState.OnboardingScreen, {
                        iconSrc: Icon.AutomaticDesigner.WEATHER,
                        iconColor: window.Styles.colors.green,
                        title: _("weather"),
                        message: _("automatic-designer.weather-ad.message1"),
                        message2: _("automatic-designer.weather-ad.message2"),
                        continueBtnText: _("search.more"),
                        continueDef: def
                    });
                    def.promise.done(function (viaButton) {
                        if (viaButton) {
                            NavigationComp.showState(ScreenState.WeatherAd);
                        }
                    });
                }

                return true;
            }
        };

        return mappedItem;
    };

    AutomaticDesignerMenuExt.prototype.getControlsForRoomOrCatsBaseDataSet = function getControlsForRoomBaseDataSet(dataSet, screenType) {
        var mappedItem = _getMappedItem(dataSet),
            groupType;

        if (dataSet.type === AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.ROOMS.STRING) {
            groupType = GroupTypes.ROOM;
        } else if (dataSet.type === AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.CATS.STRING) {
            groupType = GroupTypes.CATEGORY;
        }

        if (groupType) {
            mappedItem.name = _("functions");
            mappedItem.subtitle = _("sorted-after-x", {
                x: ActiveMSComponent.getStructureManager().getCustomGroupTitles()[groupType]
            });
            mappedItem.isLecacy = false;
            mappedItem.entries = ActiveMSComponent.getStructureManager().getGroupsByType(groupType);
            return mappedItem;
        } else {
            return null;
        }
    };

    AutomaticDesignerMenuExt.prototype.getControlsLegacyBaseDataSet = function getControlsLegacyBaseDataSet(dataSet, screenType) {
        var mappedItem = _getMappedItem(dataSet);

        mappedItem.entries = Object.values(ActiveMSComponent.getStructureManager().getSupportedControls(true)).filter(function (control) {
            return control.hasAutomaticDesignerCapabilities(screenType);
        });
        return mappedItem;
    };

    AutomaticDesignerMenuExt.prototype.getScenesBaseDataSet = function getScenesBaseDataSet(dataSet, screenType) {
        var mappedItem = _getMappedItem(dataSet);

        mappedItem.entries = ActiveMSComponent.getStructureManager().getAllSceneControls().map(function (scene) {
            var tmpObj = cloneObject(scene); // don't use cloneObjectDepp otherwise the control type will be overwritten!!

            tmpObj.isConfigured = scene.isConfigured; // function is needed in menu to create automaticDesignerCells --> see automaticDesignerCell _getCapabilityErrorForControl

            tmpObj.type = mappedItem.type;
            tmpObj.flatList = dataSet.entries[0].flatList;
            tmpObj.entries = dataSet.entries;
            return tmpObj;
        });
        return mappedItem;
    };

    AutomaticDesignerMenuExt.prototype.getNotificationBaseDataSet = function getNotificationBaseDataSet(dataSet, screenType) {
        var mappedItem = _getMappedItem(dataSet),
            serviceState,
            options,
            globalStates = SandboxComponent.getStatesForUUID(GLOBAL_UUID),
            cloudServiceRegistrationStates = globalStates.states.cloudservice,
            isMailer,
            isCaller,
            isNotification;

        mappedItem.entries = dataSet.entries.map(function (entry) {
            serviceState = null;
            options = {};
            isMailer = entry.entries.find(function (capability) {
                return capability.intType.includes(AutomaticDesignerEnums.KNOWN_INT_TYPES_CATEGORIES.NOTIFICATIONS.MAILER);
            });
            isCaller = entry.entries.find(function (capability) {
                return capability.intType.includes(AutomaticDesignerEnums.KNOWN_INT_TYPES_CATEGORIES.NOTIFICATIONS.CALLER);
            });
            isNotification = entry.entries.find(function (capability) {
                return capability.intType.includes(AutomaticDesignerEnums.KNOWN_INT_TYPES_CATEGORIES.NOTIFICATIONS.PUSH);
            });

            if (Feature.GLOBAL_CLOUD_STATES) {
                if (isCaller) {
                    serviceState = cloudServiceRegistrationStates.services.find(function (service) {
                        return service.name === AutomaticDesignerEnums.ENTRY_TYPES.CALLER;
                    });

                    if (serviceState.status) {
                        return entry;
                    } else {
                        entry.showAdFunc = function showAdFunc(prevShow) {
                            if (!prevShow) {
                                _showNotRegisteredScreenForCallerService();
                            }

                            return true;
                        };

                        return entry;
                    }
                } else if (isMailer) {
                    serviceState = cloudServiceRegistrationStates.services.find(function (service) {
                        return service.name === AutomaticDesignerEnums.REGISTRATION;
                    });

                    if (serviceState.status) {
                        return entry;
                    } else {
                        entry.showAdFunc = function showAdFunc(prevShow) {
                            if (!prevShow) {
                                var def = Q.defer();
                                NavigationComp.showState(ScreenState.OnboardingScreen, {
                                    iconSrc: Icon.AutomaticDesigner.MAIL,
                                    iconColor: window.Styles.colors.green,
                                    title: _("notifications.types.cloud-mailer"),
                                    message: _("automatic-designer.mail-service.message1"),
                                    message2: _("automatic-designer.mail-service.message2"),
                                    continueBtnText: _("register-now"),
                                    continueDef: def
                                });
                                def.promise.done(function (viaButton) {
                                    if (viaButton) {
                                        NavigationComp.openWebsite("https://www.loxone.com/reg");
                                    }
                                });
                            }

                            return true;
                        };

                        return entry;
                    }
                } else if (isNotification) {
                    serviceState = cloudServiceRegistrationStates.services.find(function (service) {
                        return service.name === AutomaticDesignerEnums.REGISTRATION;
                    });

                    if (serviceState.status) {
                        return entry;
                    } else {
                        entry.showAdFunc = function showAdFunc(prevShow) {
                            if (!prevShow) {
                                var def = Q.defer();
                                NavigationComp.showState(ScreenState.OnboardingScreen, {
                                    iconSrc: Icon.AutomaticDesigner.NOTIFICATIONS,
                                    iconColor: window.Styles.colors.green,
                                    title: _("notification"),
                                    message: _("automatic-designer.notification-service.message1"),
                                    message2: _("automatic-designer.notification-service.message2"),
                                    continueBtnText: _("register-now"),
                                    continueDef: def
                                });
                                def.promise.done(function (viaButton) {
                                    if (viaButton) {
                                        NavigationComp.openWebsite("https://www.loxone.com/reg");
                                    }
                                });
                            }

                            return true;
                        };

                        return entry;
                    }
                } else {
                    return entry;
                }
            } else {
                if (!!!Object.values(ActiveMSComponent.getStructureManager().getCallerServices()).length && entry.entries.find(function (capability) {
                    return capability.intType.includes(AutomaticDesignerEnums.KNOWN_INT_TYPES_CATEGORIES.NOTIFICATIONS.CALLER);
                })) {
                    entry.showAdFunc = function showAdFunc(prevShow) {
                        if (!prevShow) {
                            _showNotRegisteredScreenForCallerService();
                        }

                        return true;
                    };

                    return entry;
                } else {
                    return entry;
                }
            }
        }.bind(this));
        return mappedItem;
    };

    var _showNotRegisteredScreenForCallerService = function _showNotRegisteredScreenForCallerService() {
        var def = Q.defer();
        NavigationComp.showState(ScreenState.OnboardingScreen, {
            iconSrc: Icon.AutomaticDesigner.CALLER,
            iconColor: window.Styles.colors.green,
            title: _("automatic-designer.caller-service"),
            message: _("automatic-designer.caller-service.message1"),
            message2: _("automatic-designer.caller-service.message2"),
            continueBtnText: _("search.more"),
            continueDef: def
        });
        def.promise.done(function (viaButton) {
            if (viaButton) {
                NavigationComp.openWebsite("https://loxone.com/caller-service");
            }
        });
    };

    var _getMappedItem = function _getMappedItem(dataSet) {
        var item = {
            name: dataSet.name,
            type: dataSet.type
        };

        if (dataSet.hasOwnProperty("iconSrc")) {
            item.iconSrc = dataSet.iconSrc;
        }

        if (dataSet.hasOwnProperty("loxAppType")) {
            item.loxAppType = dataSet.loxAppType;
        }

        return item;
    };

    return AutomaticDesignerMenuExt;
});
