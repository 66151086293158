'use strict';

PlatformComp.factory('AppInfoExt', function () {
    // private variables
    let weakThis,
        platComp = {};

    /**
     * c-tor for DeviceInfo Extension
     * @param comp reference to the parent component
     * @constructor
     */

    function DeviceInfoExt(comp) {
        weakThis = this
        platComp = comp;
    } // public methods

    /**
     * @see VendorHub.DeviceInfo.getPlatformInfoObj
     */


    DeviceInfoExt.prototype.getAppInfoObj = VendorHub.DeviceInfo.getAppInfoObj;
    return DeviceInfoExt;
});
