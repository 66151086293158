'use strict';

define("MediaClientControlStateContainer", ["ControlStateContainer", "MediaClientControlEnums"], function (ControlStateContainer, MediaClientControlEnums) {
    return class MediaClientControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            // general info
            this.states.powerOn = !!newVals[this.control.states.power];
            this.states.serverState = this._determineServerState(newVals[this.control.states.serverState]);
            this.states.playState = this._determinePlayState(newVals[this.control.states.playState]); // sources

            this.states.activeSource = newVals[this.control.states.source];
            this.states.sources = this._splitUpSources(newVals[this.control.states.sourceList].text);

            if (this.states.activeSource) {
                this.states.activeSourceName = this.states.sources[this.states.activeSource];
            } else {
                this.states.activeSourceName = null;
            } // song info


            this.states.songInfo = {
                songName: nullEmptyString(newVals[this.control.states.songName].text),
                artistName: nullEmptyString(newVals[this.control.states.artist].text),
                albumName: nullEmptyString(newVals[this.control.states.album].text)
            };

            if (!this.states.songInfo.songName && !this.states.songInfo.artistName && !this.states.songInfo.albumName) {
                this.states.songInfo = null;
            } // radio or media player


            if (newVals[this.control.states.duration]) {
                this.states.isRadio = false;
                this.states.songInfo.duration = newVals[this.control.states.duration];
                this.states.progress = newVals[this.control.states.progress];
            } else {
                this.states.isRadio = true;

                if (this.states.progress) {
                    delete this.states.progress;
                }
            } // further info


            var url = newVals[this.control.states.cover].text;

            if (url[0] === '/') {
                // detect reachMode
                var msUrl = SandboxComponent.getCurrentMiniserverUrl();
                this.states.coverURL = 'http://' + msUrl + url;
            } else {
                this.states.coverURL = url;
            }

            this.states.shuffle = !!newVals[this.control.states.shuffle];
            this.states.repeat = this._determineRepeatState(newVals[this.control.states.repeat]);
            this.states.volume = newVals[this.control.states.volume];
            this.states.maxVolume = newVals[this.control.states.maxVolume];
            this.states.mute = !!newVals[this.control.states.mute];
            this.states.volumeStep = newVals[this.control.states.volumeStep];
        }

        getStateTextShort() {
            if (this.states.songInfo && this.states.powerOn) {
                return this.states.songInfo.songName + SEPARATOR_SYMBOL + this.states.songInfo.artistName;
            } else if (this.states.activeSourceName && this.states.powerOn) {
                return this.states.activeSourceName;
            }
        }

        getStateInfo() {
            var activeSrc = this.states.activeSourceName,
                album,
                title = null,
                message = null;

            if (this.states.songInfo && this.states.powerOn) {
                title = this.states.songInfo.songName;
                message = this.states.songInfo.artistName;
                album = this.states.songInfo.albumName;

                if (album && album !== message) {
                    if (!message) {
                        message = album;
                    } else {
                        message += SEPARATOR_SYMBOL + album;
                    }
                }
            }

            if (activeSrc && activeSrc !== album) {
                if (!title) {
                    title = activeSrc;
                } else if (!message) {
                    message = activeSrc;
                } else {
                    message += SEPARATOR_SYMBOL + activeSrc;
                }
            }

            if (title || message) {
                return {
                    title: title,
                    message: message
                };
            } else {
                return null;
            }
        }

        _determineServerState(state) {
            if (state === MediaClientControlEnums.ServerState.INVALID_ZONE) {
                return MediaClientControlEnums.ServerState.INVALID_ZONE;
            } else if (state === MediaClientControlEnums.ServerState.NOT_REACHABLE) {
                return MediaClientControlEnums.ServerState.NOT_REACHABLE;
            }

            if (state === MediaClientControlEnums.ServerState.OFFLINE) {
                return MediaClientControlEnums.ServerState.OFFLINE;
            } else if (state === MediaClientControlEnums.ServerState.INITIALIZING) {
                return MediaClientControlEnums.ServerState.INITIALIZING;
            } else if (state === MediaClientControlEnums.ServerState.ONLINE) {
                return MediaClientControlEnums.ServerState.ONLINE;
            } else {
                return MediaClientControlEnums.ServerState.UNKNOWN;
            }
        }

        _determinePlayState(state) {
            if (state === MediaClientControlEnums.PlayState.STOPPED) {
                return MediaClientControlEnums.PlayState.STOPPED;
            } else if (state === MediaClientControlEnums.PlayState.PAUSED) {
                return MediaClientControlEnums.PlayState.PAUSED;
            } else if (state === MediaClientControlEnums.PlayState.PLAYING) {
                return MediaClientControlEnums.PlayState.PLAYING;
            } else {
                return MediaClientControlEnums.PlayState.UNKNOWN;
            }
        }

        _splitUpSources(sources) {
            var result = {};
            var srcArray = sources.split(',');

            for (var i = 0; i < srcArray.length; i++) {
                var srcParts = srcArray[i].split('=');
                result[srcParts[1]] = srcParts[0];
            }

            return result;
        }

        _determineRepeatState(state) {
            if (state === MediaClientControlEnums.RepeatState.ONE) {
                return MediaClientControlEnums.RepeatState.ONE;
            } else if (state === MediaClientControlEnums.RepeatState.ALL) {
                return MediaClientControlEnums.RepeatState.ALL;
            } else {
                return MediaClientControlEnums.RepeatState.NONE;
            }
        }

    };
});
