'use strict';
/**
 * Handles the Secured Details.
 *
 * - it will not load anything until the first secDetails are requested.
 * - when the first secured details are requested, it tries to load all secured details into the memory from the
 *   filesystem.
 * - if there are no secDetails yet or the one requested isn't available yet, it downloads the securedDetails that have
 *   been requested (using loadSecuredDetailsFor --> getSecuredDetailsFor does only check the cache).
 * - when the details are downloaded, it updates or initializes the local cache with the new details and stores them
 *   again.
 * - when the MS connection is established or lost, the local data is being reset to avoid returning false data.
 */

ActiveMSComp.factory('SecuredDetailsExt', function () {
    // internal variables
    let weakThis,
        activeMsComp,
        secDetailsMap = null,
        secDetailsLoaderMap = {};

    /**
     * c-tor for SecuredDetails-Extension
     * @param comp reference to the ActiveMSComponent
     * @constructor
     */

    function SecuredDetailsExt(comp) {
        weakThis = this
        activeMsComp = comp;
        this.name = "SecuredDetailsExt"; // handling registrations

        this.regCntr = 1; // use 1 as 0 would need an extra handling when detecting if regCntr has been set or not (0 === false)

        this.registrations = {};
        this.readyToDownloadDef = Q.defer(); // Secured details will only be downloaded once resolved
        // internal broadcasts

        activeMsComp.on(ActiveMSComp.ECEvent.ConnClosed, this._resetCachedData.bind(this));
        activeMsComp.on(ActiveMSComp.ECEvent.ConnEstablished, this._resetCachedData.bind(this));
        activeMsComp.on(ActiveMSComp.ECEvent.REACHABILITY_UPDATE, function (event, reachMode) {
            if (reachMode === ReachMode.NONE) {
                if (this.readyToDownloadDef && this.readyToDownloadDef.promise.isPending()) {
                    this.readyToDownloadDef.reject("Reachability change");
                }

                this.readyToDownloadDef = Q.defer();
            }
        }.bind(this));
        activeMsComp.on(ActiveMSComp.ECEvent.STRUCTURE_READY, function () {
            if (this.readyToDownloadDef && this.readyToDownloadDef.promise.isPending()) {
                this.readyToDownloadDef.resolve();
            }
        }.bind(this));
    } // public methods

    /**
     * Used to remove all stored secured details of the current reach mode.
     * @param serial    the serial number of the miniserver that is to be reset.
     */


    SecuredDetailsExt.prototype.resetSecuredDetails = function resetSecuredDetails(serial) {
        serial = _serialNo(serial);
        Debug.SecuredDetails && console.log(this.name + ": resetSecuredDetails " + serial);
        secDetailsMap = null;
        PersistenceComponent.updateSecuredDetails(serial, {});
    };
    /**
     * Will return the secured details for a control specified by the uuid IF already loaded. It will not return a
     * promise. If the details aren't loaded yet, it will return false.
     * @param uuid              the controls uuid.
     * @returns {boolean|{}}    either false or the secure details object
     */


    SecuredDetailsExt.prototype.getSecuredDetailsFor = function getSecuredDetailsFor(uuid) {
        Debug.SecuredDetails && console.log(this.name + ": getSecuredDetailsFor: " + uuid);
        var result = false;

        if (secDetailsMap && secDetailsMap.hasOwnProperty(uuid)) {
            result = secDetailsMap[uuid];
        }

        return result;
    };
    /**
     * Will either load the current secured details from the storage or it will download them from the Miniserver. If
     * the details have already been cached, it'll return them async from the cache.
     * @param uuid      the control whos secured details are requested.
     * @param isIntercom we can explicitly request the internal or external secured details for the intercom
     * @returns {*} promise that will resolve once the details have been loaded (or reject if failed)
     */


    SecuredDetailsExt.prototype.loadSecuredDetailsFor = function loadSecuredDetailsFor(uuid, isIntercom) {
        Debug.SecuredDetails && console.log(this.name + ": loadSecuredDetailsFor " + uuid);
        var promise;

        if (secDetailsMap && secDetailsMap.hasOwnProperty(uuid)) {
            Debug.SecuredDetails && console.log("         async from cache."); // return a promise that resolves with the cached details

            promise = Q.fcall(this.getSecuredDetailsFor.bind(this, uuid));
        } else if (secDetailsLoaderMap.hasOwnProperty(uuid)) {
            Debug.SecuredDetails && console.log("         already loading!"); // already loading these details --> loader will be returned.

            promise = secDetailsLoaderMap[uuid];
        } else if (!secDetailsMap) {
            Debug.SecuredDetails && console.log("         no cache loaded yet, load cache first!"); // no secDetailsMap loaded from Filesystem yet, load from fileSystem first

            secDetailsLoaderMap[uuid] = this._loadSecureDetailsFromFileSystem(uuid).then(function (res) {
                delete secDetailsLoaderMap[uuid]; // the secDetails could be loaded from the filesystem.

                return res;
            }.bind(this), function (err) {
                Debug.SecuredDetails && console.log("      secure details for " + uuid + " could NOT be loaded"); // not locally stored, re-download - by returning the promise it is chained right away.

                return this._downloadSecuredDetailsForControl(uuid, isIntercom);
            }.bind(this));
            promise = secDetailsLoaderMap[uuid];
        } else {
            // secDetailsMap from fs already in cache - but this one is not contained, download for this uuid again.
            Debug.SecuredDetails && console.log("      secure details for " + uuid + " are not in CACHE, download"); // fileSystem does not contain it, if so, the detailsMap would contain it - download it.

            secDetailsLoaderMap[uuid] = this._downloadSecuredDetailsForControl(uuid, isIntercom);
            promise = secDetailsLoaderMap[uuid];
        }

        return promise;
    };
    /**
     * default is to add it to the normal control.details
     * @param control
     * @param securedDetails
     * @result {*}  returns a reference to the control including the merged secured details.
     */


    SecuredDetailsExt.prototype.mergeSecuredDetailsWithControl = function mergeSecuredDetailsWithControl(control, securedDetails) {
        Debug.SecuredDetails && console.log(this.name + ": mergeSecuredDetailsWithControl " + control.uuidAction);
        Object.deepExtend(control.details, securedDetails);
        return control;
    };
    /**
     * Will store a callback function that will be called once the secured details for this uuid have been loaded. It
     * will not start a download. If the securedDetails have already been loaded it will call the function async after
     * this method call.
     * @param uuid          the uuidAction of the control whos securedDetails are of interest
     * @param callbackFn    the function to call once the details have been loaded
     * @returns {number}    registration id - needed to remove from this registration again.
     */


    SecuredDetailsExt.prototype.registerForSecuredDetails = function registerForSecuredDetails(uuid, callbackFn) {
        Debug.SecuredDetails && console.log(this.name + ": registerForSecuredDetails " + uuid);
        var regId = ++this.regCntr % Number.MAX_VALUE; // ensure the details have not yet been loaded.

        if (secDetailsMap && secDetailsMap.hasOwnProperty(uuid)) {
            setTimeout(function () {
                callbackFn(secDetailsMap[uuid]);
            }, 0);
        } else {
            // store the callback function for later.
            if (!this.registrations.hasOwnProperty(uuid)) {
                this.registrations[uuid] = {};
            }

            this.registrations[uuid][regId] = callbackFn;
        }

        return regId;
    };
    /**
     * This method is used to cancel a registration for secured details.
     * @param uuid
     * @param registrationId
     */


    SecuredDetailsExt.prototype.unregisterFromSecuredDetails = function unregisterFromSecuredDetails(uuid, registrationId) {
        Debug.SecuredDetails && console.log(this.name + ": unregisterFromSecuredDetails " + uuid);

        if (this.registrations.hasOwnProperty(uuid) && this.registrations[uuid].hasOwnProperty(registrationId)) {
            delete this.registrations[uuid][registrationId];
        }
    }; // ------------------------------------------------------------------------------------------------------
    // Private Methods
    // ------------------------------------------------------------------------------------------------------

    /**
     * Called when the secured details for a specific control have been loaded. It will update the local cache, delete
     * the stored reference to the loader promise and then inform all registrations that the secure details have been
     * loaded.
     * @param uuid          the uuid of the control whos details have been loaded
     * @param secDetails    the secDetails that have been loaded.
     * @private
     */


    SecuredDetailsExt.prototype._securedDetailsLoaded = function _securedDetailsLoaded(uuid, secDetails) {
        delete secDetailsLoaderMap[uuid];

        if (!secDetailsMap) {
            // when the first secDetails are loaded, the map needs to be initialized.
            secDetailsMap = {};
        }

        secDetailsMap[uuid] = secDetails; // check if there are registrations to inform.

        if (this.registrations.hasOwnProperty(uuid)) {
            Object.keys(this.registrations[uuid]).forEach(function (regId) {
                this.registrations[uuid][regId](secDetails);
            }.bind(this)); // informed, reset the registrations.

            delete this.registrations[uuid];
        }
    };
    /**
     * Will load the secure details of the current miniserver from the archive & store them in a local attribute. Once
     * loaded it will check for the desired controls securedDetails and returns it.
     * @param controlUuid       the controls uuidAction.
     * @returns {promise}
     */


    SecuredDetailsExt.prototype._loadSecureDetailsFromFileSystem = function _loadSecureDetailsFromFileSystem(controlUuid) {
        Debug.SecuredDetails && console.log(this.name + "getSecuredDetailsFor " + controlUuid);
        var wantedDetails = false; // load all the details

        return PersistenceComponent.getSecuredDetails(_serialNo()).then(function (storedSecuredDetails) {
            // iterate to store the local secured details.
            Object.keys(storedSecuredDetails).forEach(function (key) {
                this._securedDetailsLoaded(key, storedSecuredDetails[key]);
            }.bind(this)); // check for the desired one.

            if (storedSecuredDetails.hasOwnProperty(controlUuid)) {
                wantedDetails = storedSecuredDetails[controlUuid];
                Debug.SecuredDetails && console.log("Secured details for " + controlUuid + " loaded.");
            } else {
                Debug.SecuredDetails && console.log("Secured details for " + controlUuid + " aren't stored yet.");
                throw new Error("Secured details for " + controlUuid + " aren't stored yet.");
            }

            return wantedDetails;
        }.bind(this));
    };
    /**
     * Will ensure the secDetailsMap and detailsLoaderMap are invalidated.
     * @private
     */


    SecuredDetailsExt.prototype._resetCachedData = function _resetCachedData() {
        Debug.SecuredDetails && console.log(this.name + ": _resetCachedData");
        secDetailsMap = null;
        secDetailsLoaderMap = {};
    };
    /**
     * Requests secured details of a given control and merges it with control.details
     * @param uuid
     * @param isIntercom
     * @returns {*}
     */


    SecuredDetailsExt.prototype._downloadSecuredDetailsForControl = function _downloadSecuredDetailsForControl(uuid, isIntercom) {
        Debug.SecuredDetails && console.log(this.name, "_downloadSecuredDetailsForControl " + uuid);
        var def = Q.defer(),
            securedDetailsCommand,
            connectionString;

        if (Feature.INTERCOM_SECURED_DETAILS && isIntercom) {
            switch (CommunicationComponent.getCurrentReachMode()) {
                case ReachMode.REMOTE:
                    connectionString = 'external';
                    break;

                case ReachMode.LOCAL:
                    connectionString = 'internal';
                    break;

                default:
                    connectionString = '';
                    break;
            }

            securedDetailsCommand = Commands.format(Commands.CONTROL.SECURED_DETAILS_V2, uuid, connectionString);
        } else {
            securedDetailsCommand = Commands.format(Commands.CONTROL.SECURED_DETAILS, uuid);
        } // Requesting the secureDetails for the given control --> use http as after a gateway reboot, the request might
        // take too long if a client has not started yet


        this.readyToDownloadDef.promise.done(function () {
            return CommunicationComponent.sendViaHTTP(securedDetailsCommand, EncryptionType.REQUEST_RESPONSE_VAL).depActiveMsThen(function success(res) {
                var result = false;

                if (res.LL.value) {
                    result = JSON.parse(res.LL.value);
                }

                Debug.SecuredDetails && console.log("     secure details for " + uuid + " successfully downloaded!");

                this._securedDetailsLoaded(uuid, result); // persist the updated details cache


                storeUpdatedDetails();
                def.resolve(result);
            }.bind(this), function (e) {
                console.error("secured details for " + uuid + " couldn't be loaded!");
                console.error(e);
                delete secDetailsLoaderMap[uuid];
                def.reject(e);
            }, null, function () {
                console.log(this.name, "Bailed out!"); // A never resolved or rejected promise is returned when the activeMS session is not active anymore
                // This results in a never ending "Please wait" popup. By rejecting the promise with the "cancel" button we
                // cancel the "Please wait" popup and also don't trigger the retry behaviour because we just want the popup to disappear

                def.reject(GUI.PopupBase.ButtonType.CANCEL);
            }.bind(this));
        }.bind(this));
        return def.promise;
    };
    /**
     * Used to ensure that the downloaded secure details are being safely stored for reusing them later.
     */


    var storeUpdatedDetails = function storeUpdatedDetails() {
        // there may not yet be any securedDetails yet - if so, initialize with an empty object.
        Debug.SecuredDetails && console.log("SecuredDetailsExt: storeUpdatedDetails");

        if (secDetailsMap) {
            PersistenceComponent.updateSecuredDetails(_serialNo(), secDetailsMap);
        } else {
            Debug.SecuredDetails && console.log("    no secured details in cache so far - don't store anything.");
        }
    };
    /**
     * Helper method to access the current miniservers serial number.
     * @param serial
     * @returns {*}
     * @private
     */


    var _serialNo = function _serialNo(serial) {
        if (!serial) {
            serial = ActiveMSComponent.getMiniserverSerialNo();
            Debug.SecuredDetails && console.log("SecuredDetailsExt: acquiring active MS serial: " + serial);
        }

        return serial;
    };

    return SecuredDetailsExt;
});
