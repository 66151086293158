'use strict';

define("PoolControlStateContainer", ["ControlStateContainer", 'PoolControlEnums'], function (ControlStateContainer, PoolControlEnums) {
    return class PoolControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            this.daytimer = this.control.subControls[Object.keys(this.control.subControls)[0]];
        }

        getUUIDsToRegister() {
            // get all uuid's from PoolControl
            var uuids = super.getUUIDsToRegister(...arguments);
            uuids.push(this.daytimer.states.entriesAndDefaultValue);
            uuids.push(this.daytimer.states.mode);
            return uuids;
        }

        prepareStates(newVals) {
            this.states.actualTemp = newVals[this.control.states.tempActual];
            this.states.targetTemp = newVals[this.control.states.tempTarget];
            this.states.waterLevel = newVals[this.control.states.waterLevel];
            this.states.custom1 = newVals[this.control.states.custom1];
            this.states.custom2 = newVals[this.control.states.custom2];
            this.states.currentOpModeNr = newVals[this.control.states.currentOpMode];
            this.states.currentTempModeNr = newVals[this.control.states.currentTempMode];
            this.states.heatingApproved = !!newVals[this.control.states.heatingApproved];
            this.states.coolingApproved = !!newVals[this.control.states.coolingApproved];
            this.states.coverPosition = newVals[this.control.states.coverPosition]; // [0.0...1.0]

            if (newVals[this.control.states.coverOpening]) {
                this.states.direction = Direction.UP;
            } else if (newVals[this.control.states.coverClosing]) {
                this.states.direction = Direction.DOWN;
            } else {
                this.states.direction = Direction.NOT_MOVING;
            }

            this.states.coverPosText = this._getCoverPosText();
            this.states.swimmingMachine = newVals[this.control.states.swimmingMachine];
            this.states.swimMachineAnalogText = lxFormat("%d %%", this.states.swimmingMachine * 100);
            this.states.currentCycleNr = newVals[this.control.states.currentCycle];
            this.states.remainingTime = newVals[this.control.states.remainingTime];
            this.states.valvePosition = newVals[this.control.states.valvePosition];
            this.states.pump = !!newVals[this.control.states.pump];
            this.states.drainValve = !!newVals[this.control.states.drainValve];
            this.states.ecoActive = !!newVals[this.control.states.ecoActive];
            this.states.filterTime = newVals[this.control.states.filterTime];
            this.states.backwashTime = newVals[this.control.states.backwashTime];
            this.states.rinseTime = newVals[this.control.states.rinseTime];
            this.states.circulateTime = newVals[this.control.states.circulateTime];
            this.states.drainTime = newVals[this.control.states.drainTime]; // Daytimer

            this.states.activeOperatingMode = newVals[this.daytimer.states.mode];
            var daytimerEntries = newVals[this.daytimer.states.entriesAndDefaultValue].entries;
            this.states.activeEntry = this._getActiveEntry(daytimerEntries); // Errors

            if (this.control.details.valveType === PoolControlEnums.ValveType.NO_VALVE) {
                this.states.error = PoolControlEnums.ErrorTypes.NO_VALVE;
            } else if (Feature.POOL_ERROR_HANDLING) {
                this.states.error = newVals[this.control.states.error];
            } else {
                this.states.error = PoolControlEnums.ErrorTypes.NO_ERROR;
            }

            this.states.hasError = this._getHasError();
            this.states.errorStillPresent = this._getErrorStillPresent();
            this.states.isOffline = this._getIsOffline();
            this.states.noValve = this.states.error === PoolControlEnums.ErrorTypes.NO_VALVE;
            this.states.serviceModeActive = this.states.currentOpModeNr === PoolControlEnums.PoolMode.SERVICE_MODE;
            this.states.isOutOfOrder = this.states.currentOpModeNr === PoolControlEnums.PoolMode.OUT_OF_ORDER;
            this.states.isActive = this.states.currentOpModeNr === PoolControlEnums.PoolMode.AUTOMATIC && !this.states.error && this.control.details.valveType;

            if (Feature.POOL_ABORTABLE_STATE) {
                this.states.isCycleAbortable = newVals[this.control.states.cycleAbortable];
            } else {
                this.states.isCycleAbortable = this.states.remainingTime > 0 && !this.states.activeEntry;
            }
        }

        _getActiveEntry(daytimerEntries) {
            var minutesSinceMidnight = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINUTES_SINCE_MIDNIGHT),
                i;

            for (i = 0; i < daytimerEntries.length; i++) {
                if (daytimerEntries[i].mode === this.states.activeOperatingMode) {
                    if (daytimerEntries[i].from <= minutesSinceMidnight && daytimerEntries[i].to > minutesSinceMidnight) {
                        return daytimerEntries[i];
                    }
                }
            }

            return null;
        }

        _getHasError() {
            if (Feature.POOL_ERROR_HANDLING) {
                if (this.states.error === PoolControlEnums.ErrorTypes.HAD_ERROR || this.states.error === PoolControlEnums.ErrorTypes.HAS_ERROR) {
                    return true;
                }
            }

            return false;
        }

        _getErrorStillPresent() {
            return Feature.POOL_ERROR_HANDLING && this.states.error === PoolControlEnums.ErrorTypes.HAS_ERROR;
        }

        _getIsOffline() {
            if (Feature.POOL_ERROR_STATE_OFFLINE) {
                return this.states.error === PoolControlEnums.ErrorTypes.IS_OFFLINE;
            } else {
                return false;
            }
        }

        /**
         * return a state icon to be displayed in eg. comfort mode
         * @returns {string|null|undefined}
         */
        getStateIcon() {
            var icon = Icon.Pool.DEFAULT;

            if (!this.states.serviceModeActive && !this.states.error && this.control.details.hasActualTemp) {
                return generateValueSvg(this.states.actualTemp);
            }

            return icon;
        }

        /**
         * return the small state icon
         * @returns {{iconSrc: "", color: ""}}
         */
        getStateIconSmall() {
            var iconName,
                iconColor,
                result = null;

            if (this.states.isOutOfOrder) {
                iconName = Icon.Pool.OUT_OF_ORDER;
            } else if (this.states.error) {
                iconName = Icon.Pool.CAUTION;
                iconColor = window.Styles.colors.orange;
            } else if (this.states.serviceModeActive) {
                iconName = Icon.Pool.WRENCH;
                iconColor = window.Styles.colors.red;
            } else if (this.states.heatingApproved) {
                iconName = Icon.Pool.HEATING;
                iconColor = window.Styles.colors.red;
            } else if (this.states.coolingApproved) {
                iconName = Icon.Pool.COOLING;
                iconColor = window.Styles.colors.blue;
            } else if (this.states.currentCycleNr) {
                iconName = this.control.getIconPathOfPoolCycleMode(this.states.currentCycleNr);
                iconColor = this.control.getColorOfPoolCycleMode(this.states.currentCycleNr);
            } else if (this.states.ecoActive) {
                iconName = Icon.Pool.ECO_MODE;
                iconColor = window.Styles.colors.green;
            }

            if (iconName) {
                result = {
                    iconSrc: iconName,
                    color: iconColor
                };
            }

            return result;
        }

        getStateTextForContent() {
            var txt;

            if (this.control.details.valveType === 0) {
                txt = _("controls.pool.no-valve");
            } else if (this.states.isOutOfOrder) {
                txt = _("pool.control.OP-modes.out-of-order");
            } else if (this.states.hasError) {
                txt = _("pool.control.warning.title");
            } else if (this.states.isOffline) {
                txt = _("pool.control.unavailable.title");
            } else if (this.states.serviceModeActive) {
                txt = _("pool.control.OP-modes.servicemode.active");
            } else {
                txt = this.control.getNameOfPoolMode(this.states.currentOpModeNr);
            }

            return txt;
        }

        getStateText() {
            var txt;

            if (this.control.details.valveType === 0) {
                txt = _("controls.pool.no-valve");
            } else if (this.states.isOutOfOrder) {
                txt = _("pool.control.OP-modes.out-of-order");
            } else if (this.states.hasError) {
                txt = _("pool.control.warning.title");
            } else if (this.states.isOffline) {
                txt = _("pool.control.unavailable.title");
            } else if (this.states.currentCycleNr) {
                txt = this.control.getNameOfPoolCycleMode(this.states.currentCycleNr);
            } else if (this.states.serviceModeActive) {
                txt = _("pool.control.OP-modes.servicemode");
            } else {
                txt = this.control.getNameOfPoolMode(this.states.currentOpModeNr);
            }

            return txt;
        }

        /**
         * return a state text color to be displayed in eg. comfort mode
         * @returns {string|null|undefined}
         */
        getStateTextColor() {
            var color = null;

            if (this.states.isActive) {
                if (this.states.currentCycleNr !== PoolControlEnums.PoolCycleMode.NO_CYCLE_ACTIVE) {
                    color = this.control.getColorOfPoolCycleMode(this.states.currentCycleNr);
                } else {
                    color = window.Styles.colors.blue;
                }
            }

            return color;
        }

        getStateInfo() {
            var info, title, message;

            if (this.control.details.valveType === 0) {
                message = _("controls.pool.no-valve-message");
                info = this._createStateInfo(message);
            } else if (this.states.isOutOfOrder) {// nothing to do.
            } else if (this.states.hasError) {
                message = _("pool.control.warning.description");
                info = this._createStateInfo(message);
            } else if (this.states.isOffline) {
                message = _("pool.control.unavailable.detailed");
                info = this._createStateInfo(message);
            } else if (this.states.currentCycleNr) {
                info = this._getCycleStateInfo();
            } else if (this.states.valvePosition < 0) {
                // when a timer is started e.g. for backwash & rinse, then it takes some time until the countdown
                // starts as the valve has to get into position first. That is why this text is important, otherwise
                // one thinks it might not do anything.
                message = _("pool.control.valve-pos.valve-moves");
                info = this._createStateInfo(message);
            }

            return info;
        }

        _getCycleStateInfo() {
            var title,
                message = "",
                tempMessage = "",
                tempTxt;
            title = this.control.getNameOfPoolCycleMode(this.states.currentCycleNr);

            if (this.states.remainingTime > 0) {
                if (!this.states.activeEntry) {
                    title += " (" + _("controls.pool.timer") + ")";
                }

                message = _("controls.pool.time-remaining");
                message += " " + LxDate.formatSecondsShort(this.states.remainingTime, false, true);
                tempMessage = ", ";
            } // it may be that we're also heating or cooling or eco is active.


            if (this.control.details.hasTargetTemp) {
                tempTxt = lxFormat("%.1f°", this.states.targetTemp);

                if (this.states.heatingApproved) {
                    tempMessage += _("control.pool.heating-up-to", {
                        temp: tempTxt
                    });
                } else if (this.states.coolingApproved) {
                    tempMessage += _("control.pool.cooling-down-to", {
                        temp: tempTxt
                    });
                } else if (this.states.ecoActive) {
                    tempMessage += _("control.pool.eco-active");
                } else {
                    tempMessage = "";
                }

                message += tempMessage;
            }

            return this._createStateInfo(message, title);
        }

        _getCoverPosText() {
            var txt,
                closeTxt = lxFormat("%d %%", Math.round(this.states.coverPosition * 100)),
                openTxt = lxFormat("%d %%", Math.round((1 - this.states.coverPosition) * 100));

            if (this.states.direction === Direction.DOWN) {
                txt = _("pool.control.cover-closing", {
                    pos: closeTxt
                });
            } else if (this.states.direction === Direction.UP) {
                txt = _("pool.control.cover-opening", {
                    pos: openTxt
                });
            } else if (this.states.coverPosition === 0) {
                txt = _("opened");
            } else if (this.states.coverPosition === 1) {
                txt = _("closed");
            } else {
                txt = _("pool.control.cover-not-fully-closed", {
                    pos: closeTxt
                });
            }

            return txt;
        }

    };
});
