'use strict';

define("LightCentralControlStateContainer", ["ControlCentralStateContainer", "LightControlEnums"], function (ControlCentralStateContainer, LightControlEnums) {
    return class LightCentralControlStateContainer extends ControlCentralStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.nrOfActiveRooms = 0;
            var rooms = {};
            newVals.forEach(function (subCtrlNewVals, idx) {
                if (subCtrlNewVals.isOn) {
                    rooms[this.getSubControlAtIdx(idx).room || UnassignedUUID] = true;
                }
            }.bind(this));
            this.states.nrOfActiveRooms = Object.keys(rooms).length;
        }

        getStateText() {
            if (this.states.nrOfActiveRooms === 0) {
                return _("controls.lightcontroller.all-off");
            } else {
                return _("active-in-rooms", {
                    count: this.states.nrOfActiveRooms
                });
            }
        }

        getStateColor() {
            if (this.states.nrOfActiveRooms > 0) {
                return window.Styles.colors.stateActive;
            }
        }

    };
});
