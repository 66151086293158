'use strict';

ActiveMSComp.factory('UserSortingExt', function () {
    // internal variables
    let weakThis,
        activeMsComp = {},
        completeStructure,
        prevCompleteStructureString,
        favoritesTab = [],
        sortingAttribute,
        devFavs,
        firstCall = true,
        sortingReadyDefer = Q.defer();
    var NoGroupEnum = {
        NO_ROOM_STRING: "not used room",
        NO_CATEGORY_STRING: "not used category"
    };

    function UserSortingExt(comp) {
        this.name = "UserSortingExt";
        weakThis = this
        activeMsComp = comp;
    }

    /**
     * Function to get the new sorting structure
     * @returns {*} - the new sorting strucutre or undefined if not yet set or present
     */


    UserSortingExt.prototype.getSortingStructure = function getSortingStructure() {
        return completeStructure;
    };
    /**
     * Removes a control/group from the new sorting structure
     * @param uuid - the uuid for the object to remove.
     */


    UserSortingExt.prototype.removeSortingStructureForObject = function removeSortingStructureForObject(uuid) {
        try {
            delete completeStructure[uuid];
        } catch (e) {
            console.warn("object in sorting structure not found: " + uuid);
        }
    };
    /**
     * Get the new sorting structure for a specific control/group at a specific tab
     * @param uuid - uuid of control/group
     * @param tab - Can be UrlStartLocation.FAVORITES, ROOMS, CATEGORY
     * @returns {*} the sorting structure if present else undefined
     */


    UserSortingExt.prototype.getSortingStructureForObject = function getSortingStructureForObject(uuid, tab) {
        try {
            return completeStructure[uuid][tab];
        } catch (e) {
            console.warn("object in sorting structure not found: " + uuid + " | " + tab);
        }
    };
    /**
     * Sets the sorting structure for a specific control/group at a specific tab
     * @param uuid - uuid of the control/group
     * @param object - the new sorting structure which will be set
     * @param tab - UrlStartLocation.FAVORITE, ROOM or CATEGORY
     */


    UserSortingExt.prototype.setSortingStructureForObject = function setSortingStructureForObject(uuid, object, tab) {
        if (!completeStructure) {
            Debug.Sorting && console.log(weakThis.name, "Sorting adjusted - initial sorting structure");
            this.createNewSortingStructure();
        }

        completeStructure[uuid][tab] = object;
    };
    /**
     * Adds a control to the group favorites or to the favorites tab - called at the new context menu
     * @param control - control to add
     * @param location - UrlStartLocation.FAVORITE, ROOM or CATEGORY
     * @param groupUuid - uuid of the group where it should be a favorite - if undefined it will be added to the favorite tab
     * @param group - the group object to add to the favorites
     */


    UserSortingExt.prototype.addFavorite = function addFavorite(control, location, groupUuid, group) {
        // create new sorting structure if not already activated
        if (!completeStructure) {
            Debug.Sorting && console.log(weakThis.name, "Favorite added - initial sorting structure");
            this.createNewSortingStructure();
        }

        if (!!group) {
            // Add group as favorite on the group tab
            var groupStructure = {
                    isFav: true,
                    position: null
                },
                lastGroupIndex = 0;
            var allFavoriteGroups = ActiveMSComponent.getStructureManager().getFavoriteGroupsByGroupType(group.groupType, undefined, true);
            allFavoriteGroups.forEach(function (currentGroup) {
                if (!!completeStructure[currentGroup.uuid][location] && completeStructure[currentGroup.uuid][UrlStartLocation.FAVORITES].isFav && lastGroupIndex <= completeStructure[currentGroup.uuid][UrlStartLocation.FAVORITES].position) {
                    lastGroupIndex = completeStructure[currentGroup.uuid][UrlStartLocation.FAVORITES].position + 1;
                }
            });
            groupStructure.position = lastGroupIndex;
            completeStructure[group.uuid][UrlStartLocation.FAVORITES] = groupStructure;

            _addToDeviceSpecificFavorites(group.uuid); // update complete structure before setting the device favorites because it emits the favsChanged event


            weakThis.updateSharedData();
        } else {
            // Add a control as favorite
            var structure = completeStructure[control.uuidAction][location],
                lastIndex = 0; // add control to the specific group favorites

            if (groupUuid) {
                var groupElement = ActiveMSComponent.getStructureManager().getGroupByUUID(groupUuid),
                    controlsInGroup = ActiveMSComponent.getStructureManager().getControlsInGroup(groupElement.groupType, groupUuid);
                controlsInGroup.forEach(function (controlIterator) {
                    if (completeStructure[controlIterator.uuidAction][location] && completeStructure[controlIterator.uuidAction][location].isFav) {
                        lastIndex++;
                    }
                }.bind(this));
            } else {
                // add control to the favorites tab
                Object.keys(completeStructure).forEach(function (key) {
                    var type = ActiveMSComponent.getStructureManager().getGroupTypeByUUID(key);

                    if (type === null && completeStructure[key][location].isFav) {
                        lastIndex++;
                    }
                }.bind(this));
            }

            structure.isFav = true;
            structure.position = lastIndex;
            completeStructure[control.uuidAction][location] = structure;

            _addToDeviceSpecificFavorites(control.uuidAction); // update complete structure before setting the device favorites because it emits the favsChanged event


            weakThis.updateSharedData();
        }
    };
    /**
     * Removes a control at the group favorites or at the favorites tab - called at the new context menu
     * @param control - control to remove
     * @param location - UrlStartLocation.FAVORITE, ROOM or CATEGORY
     * @param groupUuid - uuid of the group where it should be removed as favorite - if undefined it will be removed from the favorite tab
     * @param group - the group object to add to the favorites
     */


    UserSortingExt.prototype.removeFavorite = function removeFavorite(control, location, groupUuid, group) {
        var sortingStructure = {
            isFav: false,
            position: null
        }; // create new sorting structure if not already activated

        if (!completeStructure) {
            Debug.Sorting && console.log(weakThis.name, "Favorite removed - initial sorting structure");
            this.createNewSortingStructure();
        }

        if (!!group) {
            // Remove a group as favorite
            if (!completeStructure[group.uuid]) {
                console.warn("Group not in complete structure found - removing a favorite group with uuid: " + group.uuid);
                return;
            }

            var groupPosition = completeStructure[group.uuid][UrlStartLocation.FAVORITES].position,
                groupFavorites = ActiveMSComponent.getStructureManager().getFavoriteGroupsByGroupType(group.groupType, undefined, true);
            groupFavorites.forEach(function (currentGroup) {
                var currentGroupPosition;

                if (!completeStructure[currentGroup.uuid] || !completeStructure[currentGroup.uuid][UrlStartLocation.FAVORITES]) {
                    console.warn("Cannot find group with uuid: " + currentGroup.uuid + " in new sorting structure");
                    return;
                }

                currentGroupPosition = completeStructure[currentGroup.uuid][UrlStartLocation.FAVORITES].position;

                if (currentGroup.uuid !== group.uuid && currentGroupPosition > groupPosition) {
                    completeStructure[currentGroup.uuid][UrlStartLocation.FAVORITES].position = completeStructure[currentGroup.uuid][UrlStartLocation.FAVORITES].position - 1;
                }
            });
            completeStructure[group.uuid][UrlStartLocation.FAVORITES] = sortingStructure;

            _removeDeviceSpecificFavorite(group.uuid);

            weakThis.updateSharedData();
        } else {
            // Remove a control as favorite
            var currentIndex = completeStructure[control.uuidAction][location].position; // remove as group favorite

            if (groupUuid) {
                var group = ActiveMSComponent.getStructureManager().getGroupByUUID(groupUuid),
                    controlsInSameGroup = ActiveMSComponent.getStructureManager().getControlsInGroup(group.groupType, groupUuid);
                controlsInSameGroup.forEach(function (controlIterator) {
                    if (completeStructure[controlIterator.uuidAction][location] && completeStructure[controlIterator.uuidAction][location].position && completeStructure[controlIterator.uuidAction][location].position > currentIndex) {
                        completeStructure[controlIterator.uuidAction][location].position = completeStructure[controlIterator.uuidAction][location].position - 1;
                    }
                }.bind(this));
            } else {
                // remove as favorite at the favorite tab
                Object.keys(completeStructure).forEach(function (key) {
                    var type = ActiveMSComponent.getStructureManager().getGroupTypeByUUID(key);

                    if (type === null && completeStructure[key][location].isFav && completeStructure[key][location].position > currentIndex) {
                        completeStructure[key][location].position = completeStructure[key][location].position - 1;
                    }
                }.bind(this));
            }

            completeStructure[control.uuidAction][location] = sortingStructure;

            _removeDeviceSpecificFavorite(control.uuidAction);

            weakThis.updateSharedData();
        }
    };
    /**
     * saves the new sorting structure at the sharedUserData or at the device specific settings
     */


    UserSortingExt.prototype.updateSharedData = function updateSharedData() {
        try {
            var optimizedStructure = _optimizeSortingStructure(); // Check if the structure is a valid JSON


            JSON.stringify(optimizedStructure);

            if (devFavs.activated) {
                Debug.Sorting && console.log(weakThis.name, "Update new sorting structure for device");
                devFavs.favorites.sorting = optimizedStructure;
                PersistenceComponent.setDeviceFavorites(devFavs.favorites, ActiveMSComponent.getActiveMiniserver().serialNo);
            } else {
                Debug.Sorting && console.log(weakThis.name, "Update new sorting structure for user");
                PersistenceComponent.setShared(Sorting.USER_DEFAULT_STRUCTURE, optimizedStructure);
            }

            _notifySortingStructureChanged();
        } catch (e) {
            console.warn(e.message);
        }
    };

    var _optimizeSortingStructure = function _optimizeSortingStructure() {
        var tempStructure = cloneObject(completeStructure);

        if (tempStructure) {
            Object.keys(tempStructure).forEach(function (key) {
                if (Debug.Sorting) {
                    var control = ActiveMSComponent.getControlByUUID(key),
                        group = ActiveMSComponent.getStructureManager().getGroupByUUID(key);

                    if (control) {
                        tempStructure[key].QQname = control.name;
                    } else if (group) {
                        tempStructure[key].QQname = group.name;
                    }
                } else {
                    delete tempStructure[key]["QQname"];
                }

                Object.keys(tempStructure[key]).forEach(function (subKey) {
                    let value = tempStructure[key][subKey];

                    if (!value.isFav) {
                        delete value.isFav;
                    }

                    if (!value.position && value.position !== 0) {
                        delete value.position;
                    } // The value is empty, delete it!
                    // Has the advantage that it minimises the structure size


                    if (!Object.keys(value).length) {
                        delete tempStructure[key][subKey];
                    }
                }.bind(this));
            }.bind(this));
            return tempStructure;
        }
    };
    /**
     * Resets the sorting functionality completely to the old star rating
     */


    UserSortingExt.prototype.resetUserAndDeviceNewSorting = function resetUserAndDeviceNewSorting() {
        Debug.Sorting && console.log(weakThis.name, "Reset new sorting");
        var currentUserId = activeMsComp.getCurrentUserFromStructure().uuid;
        devFavs = activeMsComp.getDeviceFavoriteSettings(); // reset device specific new sorting structure

        if (devFavs.favorites) {
            devFavs.favorites.sorting = undefined;
            PersistenceComponent.setDeviceFavorites(devFavs.favorites, ActiveMSComponent.getActiveMiniserver().serialNo);
        } // set device favorites active = false


        PersistenceComponent.activateDeviceFavorites(false); // reset user specific new sorting structure

        PersistenceComponent.setShared(Sorting.USER_DEFAULT_STRUCTURE, null); // reset miniserver settings

        PersistenceComponent.saveForMiniserver(Sorting.NEW_SORTING_ACTIVATED + "/" + currentUserId, undefined);
        PersistenceComponent.saveForMiniserver(Sorting.CURRENT_SORTING_MODE + "/" + currentUserId, Sorting.STANDARD_SORTING);
        NavigationComp.dispatchEventToUI(NavigationComp.UiEvents.FavoritesChanged);
    };
    /**
     * Deletes the local cached new sorting structure
     */


    UserSortingExt.prototype.resetCompleteStructure = function resetCompleteStructure(sortingMode) {
        var currentSortingMode = weakThis.getCurrentSortingMode();

        if (!sortingMode) {
            // Reset current active sorting
            Debug.Sorting && console.log(weakThis.name, "Structure set to undefined");
            completeStructure = undefined;
            favoritesTab = [];

            _resetSortingReadyDefer();
        } else {
            // Reset specific sorting - triggered by the deviceFavoriteScreens reset button
            devFavs = activeMsComp.getDeviceFavoriteSettings();

            if (sortingMode === currentSortingMode) {
                Debug.Sorting && console.log(weakThis.name, "Update reset sorting structure for current sorting mode");
                completeStructure = undefined;
                favoritesTab = [];

                _resetSortingReadyDefer();

                weakThis.updateSharedData();
            } else if (sortingMode === Sorting.USER_SORTING) {
                Debug.Sorting && console.log(weakThis.name, "Update reset sorting structure for user");
                PersistenceComponent.setShared(Sorting.USER_DEFAULT_STRUCTURE, null);
                PersistenceComponent.synchronizeShared(); // We need this event to avoid the "Bitte warten" problem / sortingReadyDefer
            }

            if (sortingMode === Sorting.DEVICE_SORTING && !!devFavs.hasOwnProperty("favorites")) {
                Debug.Sorting && console.log(weakThis.name, "Update reset sorting structure for device");
                devFavs.favorites = undefined;
                PersistenceComponent.setDeviceFavorites(devFavs.favorites, ActiveMSComponent.getActiveMiniserver().serialNo);
                devFavs = activeMsComp.getDeviceFavoriteSettings();
            }
        }
    };
    /**
     * this method will be called if the structure file has changed
     * @returns {*}
     */


    UserSortingExt.prototype.handleStructureReceived = function handleStructureReceived() {
        var mode = weakThis.getCurrentSortingMode();

        if (firstCall) {
            NavigationComp.registerForUIEvent(NavigationComp.UiEvents.FavoritesChanged, function () {
                _handleFavsChanged(true, true);
            });
            NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, function () {
                _handleFavsChanged(true, true);
            });
            PersistenceComponent.registerForSharedKeyChange(Sorting.USER_DEFAULT_STRUCTURE, function () {
                _handleFavsChanged(true);
            }, true);
            activeMsComp.on(ActiveMSComp.ECEvent.StopMSSession, function () {
                weakThis.resetCompleteStructure();
            }.bind(this));
            firstCall = false;
        }

        devFavs = activeMsComp.getDeviceFavoriteSettings();

        if (devFavs.activated) {
            Debug.Sorting && console.log(weakThis.name, "Structure received device favorites are active");

            if (!ActiveMSComponent.isExpertModeLightEnabled()) {
                // user has no rights to use the new sorting at the device specific mode
                // reset and use standard star rating / sorting
                Debug.Sorting && console.log(weakThis.name, "User with no expert mode lite right wants to use device specific sorting -> reset to star rating / sorting");
                completeStructure = undefined;
                weakThis.setCurrentSortingMode(Sorting.STANDARD_SORTING);
                PersistenceComponent.activateDeviceFavorites(false);
            } else if (devFavs.favorites && devFavs.favorites.sorting) {
                var structure = devFavs.favorites.sorting,
                    structureChanged;
                structureChanged = _handleStructureChanges(structure);
                completeStructure = structure; // check structure after receiving

                _checkCompleteStructure();

                if (structureChanged) {
                    Debug.Sorting && console.log(weakThis.name, "Structure file changed and new sorting structure was adjusted");
                    weakThis.updateSharedData();
                }
            } else {// use star rating
            }

            Debug.Sorting && console.log(weakThis.name, "Resolve sortingReadyDefer");
            sortingReadyDefer.resolve();
        } else if (mode === Sorting.USER_SORTING) {
            // create new sorting structure for this specific user
            _loadSortingStructure(true, true, true);
        } else {
            // use star rating
            Debug.Sorting && console.log(weakThis.name, "Resolve sortingReadyDefer");
            sortingReadyDefer.resolve();
        }

        return sortingReadyDefer.promise;
    };
    /**
     * triggers the creation of the new sorting structure
     */


    UserSortingExt.prototype.createNewSortingStructure = function createNewSortingStructure() {
        var deviceSpecificSorting = activeMsComp.getDeviceFavoriteSettings(),
            currentUserId = activeMsComp.getCurrentUserFromStructure().uuid;

        if (deviceSpecificSorting.activated) {
            weakThis.setCurrentSortingMode(Sorting.DEVICE_SORTING);
        } else {
            weakThis.setCurrentSortingMode(Sorting.USER_SORTING);
        }

        completeStructure = {};
        sortingAttribute = activeMsComp.getDeviceFavoritesActive() ? "manualRating" : "defaultRating"; // that should probably not be there?!?!?

        sortingAttribute = "defaultRating";

        _createControlsRoomsAndCategoriesStructure();

        PersistenceComponent.saveForMiniserver(Sorting.NEW_SORTING_ACTIVATED + "/" + currentUserId, true);
    };
    /**
     * Called when the user touches the check button to accept new sorting
     * @param collectionView - collectionView with the new sorting
     * @param getFavForIndex - function to get check if a cell is in favorite section
     * @param getSortingLocation - function that returns the current location
     */


    UserSortingExt.prototype.createSortingStructureForTab = function createSortingStructureForTab(tableContent, getFavForIndex, getSortingLocation) {
        var amountOfSections = tableContent.length,
            sectionIterator;

        if (!completeStructure) {
            Debug.Sorting && console.log(weakThis.name, "Tab sorting done - initial sorting structure");
            ActiveMSComponent.createNewSortingStructure();
        }

        for (sectionIterator = 0; sectionIterator < amountOfSections; sectionIterator++) {
            var amountOfCellsInSection = tableContent[sectionIterator].rows.length,
                cellIterator;

            for (cellIterator = 0; cellIterator < amountOfCellsInSection; cellIterator++) {
                var uuid,
                    isFav = getFavForIndex(sectionIterator, tableContent.length),
                    location = getSortingLocation(sectionIterator, tableContent);
                var cell = tableContent[sectionIterator].rows[cellIterator],
                    sortingObj;

                if (cell.content.group === undefined) {
                    uuid = cell.content.control.uuidAction;
                } else {
                    uuid = cell.content.group.uuid;
                }

                sortingObj = {
                    isFav: isFav,
                    position: cellIterator
                };
                ActiveMSComponent.setSortingStructureForObject(uuid, sortingObj, location);
            }
        }
    };
    /**
     * Sets the current sorting mode
     * @param currentSorting - the sorting mode to set
     */


    UserSortingExt.prototype.setCurrentSortingMode = function setCurrentSortingMode(currentSorting) {
        var currentUserId = activeMsComp.getCurrentUserFromStructure().uuid;
        PersistenceComponent.saveForMiniserver(Sorting.CURRENT_SORTING_MODE + "/" + currentUserId, currentSorting);
    };
    /**
     * Gets the current sorting mode
     * @returns {*} - the current sorting mode
     */


    UserSortingExt.prototype.getCurrentSortingMode = function setCurrentSortingMode() {
        var currentUser = activeMsComp.getCurrentUserFromStructure();

        if (currentUser === null) {
            return;
        }

        var currentUserId = currentUser.uuid;
        return PersistenceComponent.getForMiniserver(Sorting.CURRENT_SORTING_MODE + "/" + currentUserId);
    };

    UserSortingExt.prototype.getSortingReadyPromise = function getSortingReadyPromise() {
        Debug.Sorting && console.log(this.name, "getSortingReadyPromise");
        return sortingReadyDefer.promise.then(function () {
            Debug.Sorting && console.log(this.name, "getSortingReadyPromise - resolved");
        }.bind(this));
    };
    /**
     * Get the category uuid of a control.
     * @param control
     * @returns {*|string} - uuid of the category or if not set a specific not used string
     * @private
     */


    UserSortingExt.prototype.getCategoryFor = function getCategoryFor(control) {
        return control.cat || NoGroupEnum.NO_CATEGORY_STRING;
    };
    /**
     * Get the room uuid of a control.
     * @param control
     * @returns {string} - uuid of the room or if not set a specific not used string
     * @private
     */


    UserSortingExt.prototype.getRoomFor = function getRoomFor(control) {
        return control.room || NoGroupEnum.NO_ROOM_STRING;
    }; // private functions

    /**
     * Adds the new control/group to the old device favorites structure
     * @param uuid - the uuid of the control/group
     * @private
     */


    var _addToDeviceSpecificFavorites = function _addToDeviceSpecificFavorites(uuid) {
        devFavs = activeMsComp.getDeviceFavoriteSettings();

        if (devFavs.activated && devFavs.favorites) {
            var devFavStructure = {
                isFavorite: true,
                newSorting: true
            };
            Debug.Sorting && console.log(weakThis.name, "Added new device specific favorite with uuid: " + uuid);
            ActiveMSComponent.setDeviceFavoriteSettingsFor(uuid, devFavStructure, true);
            devFavs = activeMsComp.getDeviceFavoriteSettings();
        }
    };
    /**
     * Removes a device specific favorite control or group
     * @param uuid - the uuid of the control/group
     * @private
     */


    var _removeDeviceSpecificFavorite = function _removeDeviceSpecificFavorite(uuid) {
        devFavs = activeMsComp.getDeviceFavoriteSettings();

        if (devFavs.activated && devFavs.favorites) {
            var oldDeviceSpecificEntry = activeMsComp.getDeviceFavoriteSettingsFor(uuid),
                devFavStructure;

            if (oldDeviceSpecificEntry.hasOwnProperty("newSorting") && oldDeviceSpecificEntry.newSorting) {
                // This favorite was added with the new sorting, so just remove it from the device favorites
                devFavStructure = {
                    isFavorite: false,
                    rating: 0
                };
            } else {
                // This favorite was there before the new sorting, so just set the new sorting property to false
                devFavStructure = {
                    isFavorite: oldDeviceSpecificEntry.isFavorite,
                    rating: oldDeviceSpecificEntry.rating,
                    newSorting: false
                };
            }

            Debug.Sorting && console.log(weakThis.name, "Removed device specific favorite with uuid: " + uuid);
            ActiveMSComponent.setDeviceFavoriteSettingsFor(uuid, devFavStructure, true);
            devFavs = activeMsComp.getDeviceFavoriteSettings();
        }
    };
    /**
     * will be called when the user switches between device specific and user specific sorting
     * @param shouldReload - flag if the tableViews should be reloaded
     * @returns {*} - the sorting structure
     * @private
     */


    var _handleFavsChanged = function _handleFavsChanged(shouldReload, shouldCheckStructure) {
        devFavs = activeMsComp.getDeviceFavoriteSettings();

        if (devFavs.activated) {
            // device sorting active
            if (devFavs.favorites.sorting) {
                // device sorting structure found
                var structureChanged = false; // check if there are any changes on the structure file and adjust the new sorting

                if (shouldCheckStructure === true) {
                    structureChanged = _handleStructureChanges(devFavs.favorites.sorting);
                }

                completeStructure = devFavs.favorites.sorting;

                if (structureChanged) {
                    Debug.Sorting && console.log(weakThis.name, "Structure file changed and new sorting structure was adjusted");
                    weakThis.updateSharedData();
                }

                Debug.Sorting && console.log(weakThis.name, "New device sorting activated - sorting structure known");
            } else if (!devFavs.favorites.sorting) {
                // no device sorting structure found - create one
                completeStructure = undefined;
                Debug.Sorting && console.log(weakThis.name, "New device sorting activated - no new sorting structure known");
            } else {
                // star rating for deice should never happen
                Debug.Sorting && console.warn("Star sorting on device should never happen");
            }

            Debug.Sorting && console.log(weakThis.name, "Resolve sortingReadyDefer");
            sortingReadyDefer.resolve();

            if (shouldReload) {
                _notifySortingStructureChanged();
            }
        } else {
            // user or star rating is used
            return _loadSortingStructure(shouldReload, shouldCheckStructure);
        }
    };

    var _resetSortingReadyDefer = function _resetSortingReadyDefer() {
        Debug.Sorting && console.log(weakThis.name, "Reset sortingReadyDefer");
        sortingReadyDefer.reject();
        sortingReadyDefer = Q.defer();
    };
    /**
     * loads the new sorting structure from the sharedUserSettings
     * @param shouldReload - flag if the tableViews should be reloaded
     * @param shouldCheckStructure - flag if the structure file has changed and adjustments should be done
     * @returns {*}
     * @private
     */


    var _loadSortingStructure = function _loadSortingStructure(shouldReload, shouldCheckStructure, initial) {
        Debug.Sorting && console.log(weakThis.name, "_loadSortingStructure");
        return PersistenceComponent.getShared(Sorting.USER_DEFAULT_STRUCTURE, initial).then(function (structure) {
            Debug.Sorting && console.log(weakThis.name, "   sharedUserSetting USER_DEFAULT_STRUCTURE received");
            var currentSortingMode = weakThis.getCurrentSortingMode();

            if (currentSortingMode === Sorting.USER_SORTING) {
                // user sorting is chosen
                if (!!structure) {
                    // user knows new sorting and structure exists
                    Debug.Sorting && console.log(weakThis.name, "New user sorting activated - sorting structure known");
                    var structureChanged = false;

                    if (shouldCheckStructure === true) {
                        structureChanged = _handleStructureChanges(structure);
                    }

                    completeStructure = structure; // check structure after receiving

                    _checkCompleteStructure();

                    if (structureChanged) {
                        Debug.Sorting && console.log(weakThis.name, "Structure file changed and new sorting structure was adjusted");
                    }
                } else if (!structure) {
                    Debug.Sorting && console.log(weakThis.name, "New user sorting activated - no new sorting structure known");
                    completeStructure = undefined;
                } else {
                    Debug.Sorting && console.warn("Star sorting for user should never happen");
                }
            } else {
                // use star rating
                Debug.Sorting && console.log(weakThis.name, "Standard star sorting activated");
            }

            Debug.Sorting && console.log(weakThis.name, "Resolve sortingReadyDefer");
            sortingReadyDefer.resolve();

            if (shouldReload) {
                _notifySortingStructureChanged();
            }

            return Q(true);
        }.bind(this), function (e) {
            Debug.Sorting && console.log(weakThis.name, "Resolve sortingReadyDefer");
            sortingReadyDefer.resolve();
            return e;
        }.bind(this));
    };

    var _checkCompleteStructure = function _checkCompleteStructure() {
        if (completeStructure && Object.keys(completeStructure).length) {
            // Get all uuids which should be removed because they are missing in the new structure file
            var toBeRemovedUuids = _getToBeRemovedUuids(completeStructure); // removes controls and groups from the sorting structure which are no longer present in the structure file
            // positions of the other controls and groups will be adjusted


            _removeUuidsAndAdjustSortingStructure(toBeRemovedUuids, completeStructure);

            Object.keys(completeStructure).forEach(function (key) {
                var control = ActiveMSComponent.getStructureManager().getControlByUUID(key);

                if (control || !completeStructure[key]) {
                    if (!completeStructure[key][UrlStartLocation.FAVORITES]) {
                        completeStructure[key][UrlStartLocation.FAVORITES] = {
                            isFav: false,
                            position: null
                        };
                    }

                    if (!completeStructure[key][UrlStartLocation.ROOM]) {
                        completeStructure[key][UrlStartLocation.ROOM] = {
                            isFav: false,
                            position: null
                        };
                    }

                    if (!completeStructure[key][UrlStartLocation.CATEGORY]) {
                        completeStructure[key][UrlStartLocation.CATEGORY] = {
                            isFav: false,
                            position: null
                        };
                    }

                    if (!completeStructure[key][UrlStartLocation.ROOM + "/" + weakThis.getCategoryFor(control)]) {
                        var positionToAddRoom = _getLastPositionForControl(completeStructure, control, UrlStartLocation.ROOM);

                        completeStructure[key][UrlStartLocation.ROOM + "/" + weakThis.getCategoryFor(control)] = {
                            isFav: false,
                            position: positionToAddRoom
                        };
                    }

                    if (!completeStructure[key][UrlStartLocation.CATEGORY + "/" + weakThis.getRoomFor(control)]) {
                        var positionToAddCategory = _getLastPositionForControl(completeStructure, control, UrlStartLocation.CATEGORY);

                        completeStructure[key][UrlStartLocation.CATEGORY + "/" + weakThis.getRoomFor(control)] = {
                            isFav: false,
                            position: positionToAddCategory
                        };
                    }
                }
            });
        }
    };
    /**
     * creates the new sorting structure for all controls room and categories
     * @private
     */


    var _createControlsRoomsAndCategoriesStructure = function _createControlsRoomsAndCategoriesStructure() {
        var allRooms = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM, false),
            allCategories = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.CATEGORY, false),
            favoritesTabIterator = 0; // step 1: Create an initial structure for all controls and sets positions

        _createControlsStructure(allRooms, allCategories); // step 2: Create an initial structure for all groups and set positions also for favorite group positions


        _createGroupsStructure(allRooms, allCategories, _sortByName, _createAlphabetGroupsStructure); // step 3: Sort all controls at the favorite tab by rating and name


        favoritesTab.sort(_sortByRatingAndName);
        favoritesTab.forEach(function (control) {
            completeStructure[control.uuidAction][UrlStartLocation.FAVORITES].position = favoritesTabIterator;
            favoritesTabIterator++;
        });
    };
    /**
     * creates the new sorting structure for controls
     * @param allRooms - all rooms
     * @param allCategories - all categories
     * @private
     */


    var _createControlsStructure = function _createControlsStructure(allRooms, allCategories) {
        var maxIterator = allCategories.length,
            iterator; // sort the groups

        allRooms.sort(_sortByRatingAndName);
        allCategories.sort(_sortByRatingAndName); // get the larger array
        // only iterate once for performance improvements

        if (allRooms.length > allCategories.length) {
            maxIterator = allRooms.length;
        }

        for (iterator = 0; iterator < maxIterator; iterator++) {
            // call the structure function and create the new sorting structure for all controls and set the correct positions
            if (iterator < allRooms.length) {
                _createControlsAndGroupsStructure(GroupTypes.ROOM, allRooms[iterator], iterator);
            }

            if (iterator < allCategories.length) {
                _createControlsAndGroupsStructure(GroupTypes.CATEGORY, allCategories[iterator], iterator);
            }
        }
    };
    /**
     * creates the new sorting structure for groups
     * @param allRooms - all rooms
     * @param allCategories - all categories
     * @private
     */


    var _createGroupsStructure = function _createGroupsStructure(allRooms, allCategories) {
        var maxIterator = allCategories.length,
            iterator; // sort the groups

        allRooms.sort(_sortByName);
        allCategories.sort(_sortByName); // get the larger array
        // only iterate once for performance improvements

        if (allRooms.length > allCategories.length) {
            maxIterator = allRooms.length;
        }

        for (iterator = 0; iterator < maxIterator; iterator++) {
            // call the structure function and create the new sorting structure for all groups and set the correct positions
            if (iterator < allRooms.length) {
                _createAlphabetGroupsStructure(GroupTypes.ROOM, allRooms[iterator], iterator);
            }

            if (iterator < allCategories.length) {
                _createAlphabetGroupsStructure(GroupTypes.CATEGORY, allCategories[iterator], iterator);
            }
        } // this section is setting the correct favorite position for group favorites


        var allFavoriteRooms = allRooms.filter(function (room) {
                return room.isFavorite;
            }).sort(_sortByRatingAndName),
            allFavoriteCategories = allCategories.filter(function (room) {
                return room.isFavorite;
            }).sort(_sortByRatingAndName),
            roomsFavoritesIterator = 0,
            categoriesFavoritesIterator = 0,
            maxLoopIterator = allFavoriteRooms.length,
            favIterator = 0;

        if (allFavoriteCategories.length > allFavoriteRooms.length) {
            maxLoopIterator = allFavoriteCategories.length;
        }

        for (favIterator = 0; favIterator < maxLoopIterator; favIterator++) {
            var structure = {
                isFav: true,
                position: null
            };

            if (favIterator < allFavoriteRooms.length) {
                structure.position = roomsFavoritesIterator++;
                completeStructure[allFavoriteRooms[favIterator].uuid][UrlStartLocation.FAVORITES] = structure;
            }

            if (favIterator < allFavoriteCategories.length) {
                structure.position = categoriesFavoritesIterator++;
                completeStructure[allFavoriteCategories[favIterator].uuid][UrlStartLocation.FAVORITES] = structure;
            }
        }
    };
    /**
     * creates the new sorting structure object for a specific control at a position
     * @param control - control to set new sorting structure
     * @param controlsIterator - the position for this control
     * @param group - UrlStartLocation.FAVORITE, ROOM or CATEGORY
     * @param isGroupFav - true if user specific sorting is activated OR device specific sorting activated and control is part of device specific favorite controls
     * false if device specific sorting activated but contol is not present at device specific favorite controls
     * @returns {{QQfavorite: boolean|*, QQname: *, QQstars: *}} - the create new sorting structure object for the control
     * @private
     */


    var _createDeepStructure = function _createDeepStructure(control, controlsIterator, group, isGroupFav) {
        var structure = {
            QQname: control.name // eases debugging

        };
        structure[UrlStartLocation.FAVORITES] = {
            isFav: control.isFavorite,
            position: null
        };
        structure[UrlStartLocation.ROOM] = {
            isFav: isGroupFav && group === UrlStartLocation.ROOM || isGroupFav && group === UrlStartLocation.CATEGORY ? control[sortingAttribute] > 0 : false,
            position: group === UrlStartLocation.CATEGORY && isGroupFav || group === GroupTypes.ROOM && control[sortingAttribute] > 0 && isGroupFav ? controlsIterator : null
        };
        structure[UrlStartLocation.CATEGORY] = {
            isFav: control[sortingAttribute] > 0 && control.isFavorite,
            position: group === GroupTypes.CATEGORY && control[sortingAttribute] > 0 && control.isFavorite ? controlsIterator : null
        };
        structure[UrlStartLocation.ROOM + "/" + weakThis.getCategoryFor(control)] = {
            isFav: false,
            position: null
        };
        structure[UrlStartLocation.CATEGORY + "/" + weakThis.getRoomFor(control)] = {
            isFav: false,
            position: null
        };
        return structure;
    };
    /**
     * structure function which sets the alphabetical position for controls in a group
     * @param controls - controls to sort
     * @param group - group in which should be sorted
     * @private
     */


    var _setAlphabeticGroupPosition = function _setAlphabeticGroupPosition(controls, group) {
        var groupByGroup = group === GroupTypes.ROOM ? "cat" : "room";

        var groups = _groupBy(controls, groupByGroup);

        Object.keys(groups).forEach(function (uuid) {
            var controlsInGroups = groups[uuid].sort(_sortByName),
                alphabetIterator = 0;

            for (alphabetIterator = 0; alphabetIterator < controlsInGroups.length; alphabetIterator++) {
                var uuidAction = controlsInGroups[alphabetIterator].uuidAction;
                var groupsString = group === GroupTypes.ROOM ? UrlStartLocation.ROOM + "/" + weakThis.getCategoryFor(controlsInGroups[alphabetIterator]) : UrlStartLocation.CATEGORY + "/" + weakThis.getRoomFor(controlsInGroups[alphabetIterator]);
                completeStructure[uuidAction][groupsString].position = alphabetIterator;
            }
        }.bind(this));
    };
    /**
     * structure function for creating the sorting structure for all controls and set their positions
     * @param group - UrlStartLocation.FAVORITE, ROOM or CATEGORY
     * @param groupItem - the room or category
     * @param iterator
     * @private
     */


    var _createControlsAndGroupsStructure = function _createControlsAndGroupsStructure(group, groupItem, iterator) {
        var sortByFavorite = group === UrlStartLocation.CATEGORY,
            controlsForGroupItem = ActiveMSComponent.getStructureManager().getControlsInGroupForSorting(group, groupItem.uuid, false, sortByFavorite),
            controlsIterator,
            positionIterator = 0,
            devFavsIterator = 0;
        devFavs = activeMsComp.getDeviceFavoriteSettings();

        for (controlsIterator = 0; controlsIterator < controlsForGroupItem.length; controlsIterator++) {
            if (!completeStructure[controlsForGroupItem[controlsIterator].uuidAction]) {
                // current control is not present at the new structure
                // save all favorite tab controls
                if (controlsForGroupItem[controlsIterator].isFavorite) {
                    favoritesTab.pushIfNoDuplicate(controlsForGroupItem[controlsIterator]);
                } // different handling if device specific sorting is activated


                if (devFavs.activated && group === UrlStartLocation.ROOM) {
                    if (devFavs.favorites.controls[controlsForGroupItem[controlsIterator].uuidAction]) {
                        // control is at the favorites controls at the device specific sorting
                        var structure = _createDeepStructure(controlsForGroupItem[controlsIterator], devFavsIterator, group, true);

                        devFavsIterator++;
                        completeStructure[controlsForGroupItem[controlsIterator].uuidAction] = structure;
                    } else {
                        // control is not at the favorites controls at the device specific sorting
                        var structure = _createDeepStructure(controlsForGroupItem[controlsIterator], positionIterator, group, false);

                        positionIterator++;
                        completeStructure[controlsForGroupItem[controlsIterator].uuidAction] = structure;
                    }
                } else {
                    if (devFavs.activated) {
                        var structure = _createDeepStructure(controlsForGroupItem[controlsIterator], devFavsIterator, group, true);

                        devFavsIterator++;
                        completeStructure[controlsForGroupItem[controlsIterator].uuidAction] = structure;
                    } else {
                        var structure = _createDeepStructure(controlsForGroupItem[controlsIterator], positionIterator, group, true);

                        positionIterator++;
                        completeStructure[controlsForGroupItem[controlsIterator].uuidAction] = structure;
                    } // handle not yet added control for user sorting setting

                }
            } else {
                // current control is present at the new structure
                // adjust the positions
                if (devFavs.activated && group === UrlStartLocation.ROOM) {
                    // different handling if device specific sorting is activated
                    // control is at the favorites controls at the device specific sorting
                    if (devFavs.favorites.controls[controlsForGroupItem[controlsIterator].uuidAction]) {
                        completeStructure[controlsForGroupItem[controlsIterator].uuidAction][group].position = devFavsIterator;
                        devFavsIterator++;
                    }
                } else if (group === GroupTypes.CATEGORY && controlsForGroupItem[controlsIterator].isFavorite && controlsForGroupItem[controlsIterator][sortingAttribute] > 0 || group === GroupTypes.ROOM && controlsForGroupItem[controlsIterator][sortingAttribute] > 0) {
                    // if control room favorite has star rating > 0 or control category favorite has star rating > 0 and is favorite
                    completeStructure[controlsForGroupItem[controlsIterator].uuidAction][group].position = positionIterator;
                    positionIterator++;
                }
            }
        }

        _setAlphabeticGroupPosition(controlsForGroupItem, group);

        var groupItemStructure = {
            QQname: groupItem.name // eases debugging

        };
        groupItemStructure[UrlStartLocation.FAVORITES] = {
            isFav: groupItem.isFavorite,
            position: groupItem.isFavorite ? iterator : null
        };
        completeStructure[groupItem.uuid] = groupItemStructure;
    };
    /**
     * creates the new sorting structure for a specific group item which is no favorite
     * @param group - UrlStartLocation.FAVORITE, ROOM or CATEGORY
     * @param groupItem - the old structure groupItem
     * @param iterator
     * @private
     */


    var _createAlphabetGroupsStructure = function _createAlphabetGroupsStructure(group, groupItem, iterator) {
        completeStructure[groupItem.uuid] = {
            QQname: groupItem.name // eases debugging

        };
        completeStructure[groupItem.uuid][group] = {
            isFav: false,
            position: iterator
        };
        completeStructure[groupItem.uuid][UrlStartLocation.FAVORITES] = {
            isFav: false,
            position: null
        };
    };
    /**
     * group by helper function
     * @param xs
     * @param prop
     * @returns {{}}
     * @private
     */


    var _groupBy = function _groupBy(xs, prop) {
        var grouped = {};

        for (var i = 0; i < xs.length; i++) {
            var p = xs[i][prop];

            if (!grouped[p]) {
                grouped[p] = [];
            }

            grouped[p].push(xs[i]);
        }

        return grouped;
    }; // Helper functions for structure changes

    /**
     * adjusts the new sorting structure if a structure file change was done
     * @param structure - new sorting structure to adjust
     * @returns {boolean|boolean} - true if something has been changed at the new sorting structure and it has to be saved
     * @private
     */


    var _handleStructureChanges = function _handleStructureChanges(structure) {
        var structureChanged = false; // Get all uuids which should be removed because they are missing in the new structure file

        var toBeRemovedUuids = _getToBeRemovedUuids(structure); // removes controls and groups from the sorting structure which are no longer present in the structure file
        // positions of the other controls and groups will be adjusted


        _removeUuidsAndAdjustSortingStructure(toBeRemovedUuids, structure);

        var receivedOldStructureControls = ActiveMSComponent.getStructureManager().getSupportedControls(),
            receivedOldStructureRooms = [],
            receivedOldStructureCategories = []; // iterate over all controls received from the old changed structure file

        receivedOldStructureControls.forEach(function (control) {
            var key = control.uuidAction,
                sortingObject = structure[key],
                // get the sorting structure for the received control
                addedObject = control,
                // the control of the old structure
                addedObjectRoomUuid = weakThis.getRoomFor(addedObject),
                addedObjectCategoryUuid = weakThis.getCategoryFor(addedObject);
            receivedOldStructureRooms.pushIfNoDuplicate(addedObjectRoomUuid);
            receivedOldStructureCategories.pushIfNoDuplicate(addedObjectCategoryUuid);

            if (!sortingObject) {
                structureChanged = true; // object not found in new sorting structure
                // add object to new sorting structure at the end

                structure[key] = {
                    QQname: addedObject.name
                };
                structure[key][UrlStartLocation.FAVORITES] = {
                    isFav: false,
                    position: null
                };
                structure[key][UrlStartLocation.ROOM] = {
                    isFav: false,
                    position: null
                };
                structure[key][UrlStartLocation.CATEGORY] = {
                    isFav: false,
                    position: null
                };

                var positionToAddRoom = _getLastPositionForControl(structure, addedObject, UrlStartLocation.ROOM);

                structure[key][UrlStartLocation.ROOM + "/" + weakThis.getCategoryFor(control)] = {
                    isFav: false,
                    position: positionToAddRoom
                };

                var positionToAddCategory = _getLastPositionForControl(structure, addedObject, UrlStartLocation.CATEGORY);

                structure[key][UrlStartLocation.CATEGORY + "/" + weakThis.getRoomFor(control)] = {
                    isFav: false,
                    position: positionToAddCategory
                };
            } else {
                // object in new sorting structure found
                // check if room or category changed
                // adjust all positions of affected controls
                var sortingObjectRoomUuid = _getGroupUuidFromSortingStructure(sortingObject, UrlStartLocation.ROOM),
                    sortingObjectCategoryUuid = _getGroupUuidFromSortingStructure(sortingObject, UrlStartLocation.CATEGORY);

                if (weakThis.getRoomFor(addedObject) !== sortingObjectRoomUuid) {
                    _adjustExistingSortingStructure(structure, key, addedObject, sortingObject, sortingObjectRoomUuid, sortingObjectCategoryUuid, UrlStartLocation.ROOM);

                    structureChanged = true;
                } else if (weakThis.getCategoryFor(addedObject) !== sortingObjectCategoryUuid) {
                    _adjustExistingSortingStructure(structure, key, addedObject, sortingObject, sortingObjectCategoryUuid, sortingObjectRoomUuid, UrlStartLocation.CATEGORY);

                    structureChanged = true;
                }
            }
        }); // adding new rooms and categories

        var hasAddedRooms, hasAddedCategories;
        hasAddedRooms = _addNewGroupsToSortingStructure(structure, receivedOldStructureRooms, UrlStartLocation.ROOM);
        hasAddedCategories = _addNewGroupsToSortingStructure(structure, receivedOldStructureCategories, UrlStartLocation.CATEGORY);
        return structureChanged || toBeRemovedUuids.length !== 0 || hasAddedRooms || hasAddedCategories;
    };
    /**
     * adds new rooms or categories to the new sorting structure
     * @param structure - the new sorting structure where the group item should be added
     * @param receivedOldStructureGroups - the changed old structure file
     * @param groupLocation - UrlStartLocation.FAVORITE, ROOM or CATEGORY
     * @returns {boolean} - true if something was added else false
     * @private
     */


    var _addNewGroupsToSortingStructure = function _addNewGroupsToSortingStructure(structure, receivedOldStructureGroups, groupLocation) {
        var hasAddedNewGroup = false;
        receivedOldStructureGroups.forEach(function (key) {
            var sortingObject = structure[key];

            if (!sortingObject) {
                var positionToAdd = _getLastPositionForGroup(structure, groupLocation);

                structure[key] = {};
                structure[key][UrlStartLocation.FAVORITES] = {
                    isFav: false,
                    position: null
                };
                structure[key][groupLocation] = {
                    isFav: false,
                    position: positionToAdd
                };
                hasAddedNewGroup = true;
            }
        }.bind(this));
        return hasAddedNewGroup;
    };
    /**
     * get the uuids of controls or groups which should be removed
     * @param structure - the new sorting structure file
     * @returns {[]} - array of to be removed uuids
     * @private
     */


    var _getToBeRemovedUuids = function _getToBeRemovedUuids(structure) {
        var receivedOldStructureControls = ActiveMSComponent.getStructureManager().getSupportedControls(),
            receivedOldStructureRooms = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM, true),
            receivedOldStructureCategories = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.CATEGORY, true),
            toBeRemovedUuids = [];
        Object.keys(structure).forEach(function (sortingKey) {
            var possibleOldSortingControls = receivedOldStructureControls.filter(function (control) {
                    return control.uuidAction === sortingKey;
                }.bind(this)),
                possibleOldSortingRoom = receivedOldStructureRooms[sortingKey],
                possibleOldSortingCategory = receivedOldStructureCategories[sortingKey];

            if (possibleOldSortingControls.length === 0 && !possibleOldSortingRoom && !possibleOldSortingCategory) {
                // control, room or category was removed in structure file
                if (sortingKey) {
                    toBeRemovedUuids.pushIfNoDuplicate(sortingKey);
                }
            }
        }.bind(this));
        return toBeRemovedUuids;
    };
    /**
     * function removes controls or groups of the new sorting structure file
     * @param toBeRemovedUuids - array of the uuids which should be removed
     * @param structure - the new sorting structure file where the uuids should be removed
     * @private
     */


    var _removeUuidsAndAdjustSortingStructure = function _removeUuidsAndAdjustSortingStructure(toBeRemovedUuids, structure) {
        toBeRemovedUuids.forEach(function (uuid) {
            var toBeRemovedSortingObject = structure[uuid],
                toBeRemovedRoomKey = _getGroupSubGroupKey(toBeRemovedSortingObject, UrlStartLocation.ROOM),
                toBeRemovedCategoryKey = _getGroupSubGroupKey(toBeRemovedSortingObject, UrlStartLocation.CATEGORY),
                toBeRemovedRoomUuid = _getGroupUuidFromSortingStructure(toBeRemovedSortingObject, UrlStartLocation.ROOM),
                toBeRemovedCategoryUuid = _getGroupUuidFromSortingStructure(toBeRemovedSortingObject, UrlStartLocation.CATEGORY);

            if (toBeRemovedSortingObject[UrlStartLocation.ROOM] && toBeRemovedSortingObject[UrlStartLocation.CATEGORY]) {
                // The removed uuid was a control
                Object.keys(structure).forEach(function (sortingUuid) {
                    var sortingObject = structure[sortingUuid],
                        sortingObjectRoomKey = _getGroupSubGroupKey(sortingObject, UrlStartLocation.ROOM),
                        sortingObjectCategoryKey = _getGroupSubGroupKey(sortingObject, UrlStartLocation.CATEGORY),
                        sortingObjectRoomUuid = _getGroupUuidFromSortingStructure(sortingObject, UrlStartLocation.ROOM),
                        sortingObjectCategoryUuid = _getGroupUuidFromSortingStructure(sortingObject, UrlStartLocation.CATEGORY); // adjust the position of the other controls behind on the favorite tab


                    if (_isSortingStructureControl(sortingObject) && sortingObject[UrlStartLocation.FAVORITES].isFav && toBeRemovedSortingObject[UrlStartLocation.FAVORITES] && toBeRemovedSortingObject[UrlStartLocation.FAVORITES].isFav && sortingObject[UrlStartLocation.FAVORITES].position > toBeRemovedSortingObject[UrlStartLocation.FAVORITES].position) {
                        structure[sortingUuid][UrlStartLocation.FAVORITES].position -= 1;
                    } // adjust the position of the other controls behind at room favorites on the room tab


                    if (_isSortingStructureControl(sortingObject) && sortingObject[UrlStartLocation.ROOM].isFav && toBeRemovedSortingObject[UrlStartLocation.ROOM] && toBeRemovedSortingObject[UrlStartLocation.ROOM].isFav && sortingObjectRoomUuid === toBeRemovedRoomUuid && sortingObject[UrlStartLocation.ROOM].position > toBeRemovedSortingObject[UrlStartLocation.ROOM].position) {
                        structure[sortingUuid][UrlStartLocation.ROOM].position -= 1;
                    } // adjust the position of the other controls behind at category favorites on the category tab


                    if (_isSortingStructureControl(sortingObject) && sortingObject[UrlStartLocation.CATEGORY].isFav && toBeRemovedSortingObject[UrlStartLocation.CATEGORY] && toBeRemovedSortingObject[UrlStartLocation.CATEGORY].isFav && sortingObjectCategoryUuid === toBeRemovedCategoryUuid && sortingObject[UrlStartLocation.CATEGORY].position > toBeRemovedSortingObject[UrlStartLocation.CATEGORY].position) {
                        structure[sortingUuid][UrlStartLocation.CATEGORY].position -= 1;
                    } // adjust the position of the other non favorite controls behind on the room tab


                    if (_isSortingStructureControl(sortingObject) && sortingObjectRoomKey === toBeRemovedRoomKey && sortingObjectCategoryKey === toBeRemovedCategoryKey && sortingObject[toBeRemovedRoomKey] && sortingObject[toBeRemovedRoomKey].position > toBeRemovedSortingObject[toBeRemovedRoomKey].position) {
                        structure[sortingUuid][toBeRemovedRoomKey].position -= 1;
                    } // adjust the position of the other non favorite controls behind on the category tab


                    if (_isSortingStructureControl(sortingObject) && sortingObjectRoomKey === toBeRemovedRoomKey && sortingObjectCategoryKey === toBeRemovedCategoryKey && sortingObject[toBeRemovedCategoryKey] && sortingObject[toBeRemovedCategoryKey].position > toBeRemovedSortingObject[toBeRemovedCategoryKey].position) {
                        structure[sortingUuid][toBeRemovedCategoryKey].position -= 1;
                    }
                }.bind(this));
            } else if (toBeRemovedSortingObject[UrlStartLocation.ROOM] && !toBeRemovedSortingObject[UrlStartLocation.CATEGORY]) {
                // The removed uuid was a room
                Object.keys(structure).forEach(function (sortingUuid) {
                    var sortingObject = structure[sortingUuid]; // adjust position of favorite rooms behind

                    if (_isSortingStructureRoom(sortingObject) && sortingObject[UrlStartLocation.FAVORITES].isFav && toBeRemovedSortingObject[UrlStartLocation.FAVORITES] && toBeRemovedSortingObject[UrlStartLocation.FAVORITES].isFav && sortingObject[UrlStartLocation.FAVORITES].position > toBeRemovedSortingObject[UrlStartLocation.FAVORITES].position) {
                        structure[sortingUuid][UrlStartLocation.FAVORITES].position -= 1;
                    } // adjust position of non favorite rooms behind


                    if (_isSortingStructureRoom(sortingObject) && sortingObject[UrlStartLocation.ROOM].position > toBeRemovedSortingObject[UrlStartLocation.ROOM].position) {
                        structure[sortingUuid][UrlStartLocation.ROOM].position -= 1;
                    }
                }.bind(this));
            } else if (!toBeRemovedSortingObject[UrlStartLocation.ROOM] && toBeRemovedSortingObject[UrlStartLocation.CATEGORY]) {
                // The removed uuid was a category
                Object.keys(structure).forEach(function (sortingUuid) {
                    var sortingObject = structure[sortingUuid]; // adjust position of favorite categories behind

                    if (_isSortingStructureCategory(sortingObject) && sortingObject[UrlStartLocation.FAVORITES].isFav && toBeRemovedSortingObject[UrlStartLocation.FAVORITES] && toBeRemovedSortingObject[UrlStartLocation.FAVORITES].isFav && sortingObject[UrlStartLocation.FAVORITES].position > toBeRemovedSortingObject[UrlStartLocation.FAVORITES].position) {
                        structure[sortingUuid][UrlStartLocation.FAVORITES].position -= 1;
                    } // adjust position of non favorite categories behind


                    if (_isSortingStructureCategory(sortingObject) && sortingObject[UrlStartLocation.CATEGORY].position > toBeRemovedSortingObject[UrlStartLocation.CATEGORY].position) {
                        structure[sortingUuid][UrlStartLocation.CATEGORY].position -= 1;
                    }
                }.bind(this));
            }

            delete structure[uuid];
        }.bind(this));
    };
    /**
     * get the information if new sorting structure object is a control or not
     * @param structure - the new sorting structure object
     * @returns {boolean} - true if control
     * @private
     */


    var _isSortingStructureControl = function _isSortingStructureControl(structure) {
        return !!structure[UrlStartLocation.FAVORITES] && !!structure[UrlStartLocation.ROOM] && !!structure[UrlStartLocation.CATEGORY];
    };
    /**
     * get the information if new sorting structure object is a room or not
     * @param structure - the new sorting structure object
     * @returns {boolean} - true if room
     * @private
     */


    var _isSortingStructureRoom = function _isSortingStructureRoom(structure) {
        return !!structure[UrlStartLocation.FAVORITES] && !!structure[UrlStartLocation.ROOM] && !structure[UrlStartLocation.CATEGORY];
    };
    /**
     * get the information if new sorting structure object is a category or not
     * @param structure - the new sorting structure object
     * @returns {boolean} - true if category
     * @private
     */


    var _isSortingStructureCategory = function _isSortingStructureCategory(structure) {
        return !!structure[UrlStartLocation.FAVORITES] && !structure[UrlStartLocation.ROOM] && !!structure[UrlStartLocation.CATEGORY];
    };
    /**
     * get the uuid of the subgroup (group/subGroupUuid)
     * @param object - the new sorting structure object
     * @param location - UrlStartLocation.FAVORITE, ROOM or CATEGORY - the group (group/subGroupUuid)
     * @returns {*}
     * @private
     */


    var _getGroupSubGroupKey = function _getGroupSubGroupKey(object, location) {
        var foundKey;
        Object.keys(object).forEach(function (key) {
            if (key.startsWith(location + "/")) {
                foundKey = key;
            }
        }.bind(this));
        return foundKey;
    };
    /**
     * get the group uuid of a control in a new sorting structure object
     * @param object - the new sorting structure object
     * @param group - UrlStartLocation.FAVORITE, ROOM or CATEGORY
     * @returns {*} - the group uuid or undefined if it is no control
     * @private
     */


    var _getGroupUuidFromSortingStructure = function _getGroupUuidFromSortingStructure(object, group) {
        var groupUuid,
            subGroup = group === UrlStartLocation.ROOM ? UrlStartLocation.CATEGORY : UrlStartLocation.ROOM;
        Object.keys(object).forEach(function (key) {
            if (key.startsWith(subGroup + "/")) {
                groupUuid = key.split('/').pop();
            }
        }.bind(this));
        return groupUuid;
    };
    /**
     * calculates the last position at the new sorting structure for a control at a specific group
     * @param newSortingStructure - the new sorting structure
     * @param addedControl - the the structure file control
     * @param group - UrlStartLocation.FAVORITE, ROOM or CATEGORY
     * @returns {number} - the position for the control where it should be added
     * @private
     */


    var _getLastPositionForControl = function _getLastPositionForControl(newSortingStructure, addedControl, group) {
        var positionToAdd = 0,
            subGroup = group === UrlStartLocation.ROOM ? "cat" : "room",
            subGroupSorting = group === UrlStartLocation.ROOM ? UrlStartLocation.CATEGORY : UrlStartLocation.ROOM;
        Object.keys(newSortingStructure).forEach(function (sortingKey) {
            var currentObject = newSortingStructure[sortingKey],
                subGroupUuid = group === UrlStartLocation.ROOM ? weakThis.getCategoryFor(addedControl) : weakThis.getRoomFor(addedControl),
                groupUuid = group === UrlStartLocation.ROOM ? weakThis.getRoomFor(addedControl) : weakThis.getCategoryFor(addedControl);

            if (currentObject[group + "/" + subGroupUuid] && currentObject[subGroupSorting + "/" + groupUuid] && currentObject[group + "/" + subGroupUuid] && currentObject[group + "/" + subGroupUuid].position >= positionToAdd) {
                positionToAdd = currentObject[group + "/" + subGroupUuid].position + 1;
            }
        }.bind(this));
        return positionToAdd;
    };
    /**
     * calculates the last position at the new sorting structure for a group
     * @param newSortingStructure - the new sorting structure
     * @param group - UrlStartLocation.FAVORITE, ROOM or CATEGORY
     * @returns {number} - the position for the group where it should be added
     * @private
     */


    var _getLastPositionForGroup = function _getLastPositionForGroup(newSortingStructure, group) {
        var positionToAdd = 0;
        Object.keys(newSortingStructure).forEach(function (sortingKey) {
            var currentObject = newSortingStructure[sortingKey],
                isCurrentObjectGroup = group === UrlStartLocation.ROOM ? _isSortingStructureRoom(currentObject) : _isSortingStructureCategory(currentObject);

            if (isCurrentObjectGroup && currentObject[group] && currentObject[group].position >= positionToAdd) {
                positionToAdd = currentObject[group].position + 1;
            }
        }.bind(this));
        return positionToAdd;
    };
    /**
     * adjust the positions of the existing sorting structure controls
     * @param structure - the new sorting structure
     * @param key - the uuid of the received old structure file control
     * @param addedObject - the object of the received old structure file control
     * @param sortingObject - the new sorting structure object
     * @param sortingObjectGroupUuid - group uuid
     * @param sortingObjectSubGroupUuid - subGroup uuid
     * @param groupLocation - UrlStartLocation.FAVORITE, ROOM or CATEGORY
     * @private
     */


    var _adjustExistingSortingStructure = function _adjustExistingSortingStructure(structure, key, addedObject, sortingObject, sortingObjectGroupUuid, sortingObjectSubGroupUuid, groupLocation) {
        var subGroupLocation = groupLocation === UrlStartLocation.ROOM ? UrlStartLocation.CATEGORY : UrlStartLocation.ROOM,
            lastPositionRoom = _getLastPositionForControl(structure, addedObject, groupLocation),
            lastPositionCategory = _getLastPositionForControl(structure, addedObject, subGroupLocation),
            subGroupKeyToChange = _getGroupSubGroupKey(sortingObject, groupLocation),
            // room/categoryUuid
            subGroupKeyToDelete = _getGroupSubGroupKey(sortingObject, subGroupLocation); // category/oldRoomUuid


        if (!subGroupKeyToChange) {
            Debug.Sorting && console.log(weakThis.name, "subGroupKeyToChange is undefined");
            Debug.Sorting && console.log(weakThis.name, "groupLocation: " + groupLocation);
            Debug.Sorting && console.log(weakThis.name, "sortingObject: " + JSON.stringify(sortingObject));
        }

        if (!subGroupKeyToDelete) {
            Debug.Sorting && console.log(weakThis.name, "subGroupKeyToDelete is undefined");
            Debug.Sorting && console.log(weakThis.name, "subGroupLocation: " + subGroupLocation);
            Debug.Sorting && console.log(weakThis.name, "sortingObject: " + JSON.stringify(sortingObject));
        }

        if (!!sortingObject[subGroupKeyToChange] && !sortingObject[subGroupKeyToChange].hasOwnProperty("position")) {
            NavigationComp.requestDebuglog("SUB_GROUP_KEY_TO_CHANGE");
            console.log(weakThis.name, "Tried to get subGroupKey");
            console.log(weakThis.name, "structure: " + JSON.stringify(structure));

            if (!!sortingObject[subGroupKeyToChange]) {
                console.log(weakThis.name, "sortingObject[subGroupKeyToChange]: " + JSON.stringify(sortingObject[subGroupKeyToChange]));
            } else {
                console.log(weakThis.name, "sortingObject[subGroupKeyToChange]: undefined");
            }

            console.log(weakThis.name, "key: " + key);
            console.log(weakThis.name, "addedObject: " + JSON.stringify(addedObject));
            console.log(weakThis.name, "sortingObject: " + JSON.stringify(sortingObject));
            console.log(weakThis.name, "sortingObjectGroupUuid: " + sortingObjectGroupUuid);
            console.log(weakThis.name, "sortingObjectSubGroupUuid: " + sortingObjectSubGroupUuid);
            console.log(weakThis.name, "groupLocation: " + groupLocation);
            console.log(weakThis.name, "subGroupLocation: " + subGroupLocation);
            console.log(weakThis.name, "lastPositionRoom: " + lastPositionRoom);
            console.log(weakThis.name, "lastPositionCategory: " + lastPositionCategory);
            return;
        }

        if (!!sortingObject[subGroupKeyToDelete] && !sortingObject[subGroupKeyToDelete].hasOwnProperty("position")) {
            NavigationComp.requestDebuglog("SUB_GROUP_KEY_TO_DELETE");
            console.log(weakThis.name, "Tried to get subGroupKey");
            console.log(weakThis.name, "structure: " + JSON.stringify(structure));

            if (!!sortingObject[subGroupKeyToDelete]) {
                console.log(weakThis.name, "sortingObject[subGroupKeyToDelete]: " + JSON.stringify(sortingObject[subGroupKeyToDelete]));
            } else {
                console.log(weakThis.name, "sortingObject[subGroupKeyToDelete]: undefined");
            }

            console.log(weakThis.name, "key: " + key);
            console.log(weakThis.name, "addedObject: " + JSON.stringify(addedObject));
            console.log(weakThis.name, "sortingObject: " + JSON.stringify(sortingObject));
            console.log(weakThis.name, "sortingObjectGroupUuid: " + sortingObjectGroupUuid);
            console.log(weakThis.name, "sortingObjectSubGroupUuid: " + sortingObjectSubGroupUuid);
            console.log(weakThis.name, "groupLocation: " + groupLocation);
            console.log(weakThis.name, "subGroupLocation: " + subGroupLocation);
            console.log(weakThis.name, "lastPositionRoom: " + lastPositionRoom);
            console.log(weakThis.name, "lastPositionCategory: " + lastPositionCategory);
            return;
        }

        Object.keys(structure).forEach(function (sortingKey) {
            if (!structure[sortingKey]) {
                NavigationComp.requestDebuglog("SORTING_KEY");
                Debug.Sorting && console.log(weakThis.name, "Tried to adjust sorting positions but object not found in new sorting structure.");
                Debug.Sorting && console.log(weakThis.name, "sortingKey: " + sortingKey);
                Debug.Sorting && console.log(weakThis.name, JSON.stringify(structure));
            } else {
                var iterationObject = structure[sortingKey],
                    iterationObjectGroupUuid = _getGroupUuidFromSortingStructure(iterationObject, groupLocation),
                    iterationObjectSubGroupUuid = _getGroupUuidFromSortingStructure(iterationObject, subGroupLocation),
                    isControl = _isSortingStructureControl(iterationObject); // adjust the positions of the other controls in the same category at old room


                if (isControl && iterationObject[subGroupKeyToChange] && iterationObjectSubGroupUuid === sortingObjectSubGroupUuid && iterationObjectGroupUuid === sortingObjectGroupUuid && sortingObject[subGroupKeyToChange] && iterationObject[subGroupKeyToChange].position > sortingObject[subGroupKeyToChange].position) {
                    structure[sortingKey][subGroupKeyToChange].position -= 1;
                } // adjust the favorite positions of the other controls at the old room


                if (isControl && sortingObject[groupLocation] && sortingObject[groupLocation].isFav && iterationObjectGroupUuid === sortingObjectGroupUuid && sortingObject[groupLocation] && iterationObject[groupLocation].position > sortingObject[groupLocation].position) {
                    structure[sortingKey][groupLocation].position -= 1;
                }

                if (isControl && iterationObject[subGroupKeyToDelete] && iterationObjectSubGroupUuid === sortingObjectSubGroupUuid && sortingObject[subGroupKeyToDelete] && iterationObject[subGroupKeyToDelete].position > sortingObject[subGroupKeyToDelete].position) {
                    structure[sortingKey][subGroupKeyToDelete].position -= 1;
                }
            }
        }.bind(this)); // adjust position for room/categoryUuid - room/categoryUuid is still the same

        subGroupKeyToChange && (structure[key][subGroupKeyToChange].position = lastPositionRoom);
        subGroupKeyToChange && (structure[key][subGroupKeyToChange].isFav = false); // adjust position for room

        if (groupLocation && structure[key] && structure[key][groupLocation]) {
            structure[key][groupLocation].position = null;
            structure[key][groupLocation].isFav = false; // create new category/newRoomUuid entry with

            var structureGroup = groupLocation === UrlStartLocation.ROOM ? "room" : "cat";
            var updatedGroupUuid = addedObject[structureGroup],
                updatedSubGroupKey = subGroupLocation + "/" + updatedGroupUuid;
            structure[key][updatedSubGroupKey] = {
                isFav: false,
                position: lastPositionCategory
            };
        } // delete outdated category/roomUuid entry


        delete structure[key][subGroupKeyToDelete];
    };
    /**
     * Notifies about a structure change, but only if the structure is not equal!
     * @private
     */


    var _notifySortingStructureChanged = function _notifySortingStructureChanged() {
        if (JSON.stringify(completeStructure) !== prevCompleteStructureString) {
            NavigationComp.dispatchEventToUI(NavigationComp.UiEvents.SortingStructureChanged);
        } else {
            developerAttention("Won't dispatch NavigationComp.UiEvents.SortingStructureChanged");
        }

        prevCompleteStructureString = JSON.stringify(completeStructure);
    }; // Sorting functions

    /**
     * sorting functions for alphabetically sorting
     * @param a
     * @param b
     * @returns {number}
     * @private
     */


    var _sortByName = function _sortByName(a, b) {
        if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
        }

        if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
        }

        return 0;
    };
    /**
     * sorting function for rating and name
     * @param a
     * @param b
     * @returns {number}
     * @private
     */


    var _sortByRatingAndName = function _sortByRatingAndName(a, b) {
        if (a.isFavorite < b.isFavorite) {
            return 1;
        }

        if (a.isFavorite > b.isFavorite) {
            return -1;
        }

        if (a.isFavorite === b.isFavorite) {
            if (a[sortingAttribute] < b[sortingAttribute]) {
                return 1;
            }

            if (a[sortingAttribute] > b[sortingAttribute]) {
                return -1;
            }

            if (a[sortingAttribute] === b[sortingAttribute]) {
                if (a.name.toUpperCase() > b.name.toUpperCase()) {
                    return 1;
                }

                if (a.name.toUpperCase() < b.name.toUpperCase()) {
                    return -1;
                }

                return 0;
            }
        }
    };

    return UserSortingExt;
});
