'use strict';

define("EnergyManagerControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class EnergyManagerControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.currentPower = newVals[this.control.states.currentPower];
            this.states.currentBat = newVals[this.control.states.currentBat];
            this.states.loads = JSON.parse(newVals[this.control.states.loads].text);
            this.states.currentValue = this.states.currentPower - this.states.currentBat;
            this.states.overflow = this.states.currentValue <= 0;
            this.states.activeLoads = this.states.loads.filter(function (load) {
                return load.isActive;
            }.bind(this)).length;
            this.states.loads.forEach(function (load) {
                var detailLoad = this.control.details.loads.find(function (detailLoad) {
                    return load.id === detailLoad.id;
                }.bind(this));
                load.name = detailLoad.name;
                load.minOnTime = detailLoad.minOnTime;
            }.bind(this));
        }

        getStateText() {
            if (this.states.activeLoads > 0) {
                return _("controls.energyManager.loads-on", {
                    count: this.states.activeLoads
                });
            } else {
                return _("controls.energyManager.loads-off");
            }
        }

        getStateColor() {
            return this.states.activeLoads ? window.Styles.colors.stateActive : null;
        }

        getStateTextColor() {
            return this.states.activeLoads ? window.Styles.colors.stateActive : null;
        }

        getStateIcon() {
            return Icon.EnergyManager.ENERGY_MANAGER;
        }

    };
});
