

const LxCommunicator = require("../../lxCommunicator.js")
export default class PairSetupCommunicator {

    get usePassword() {
        return this._credentials?.usePassword;
    }


    constructor(miniserver) {
        this.name = "PairSetupCommunicator";
        this._miniserver = miniserver;
        LxCommunicator.setPublicKey(miniserver.publicKey || null);
    }

    get pairSetupPermission() {
        let perm = 0;
        perm = setBit(perm, MsPermission.ADMIN);
        perm = setBit(perm, MsPermission.WEB);
        perm = setBit(perm, MsPermission.EXPERT_MODE);
        perm = setBit(perm, MsPermission.USER_MANAGEMENT);
        perm = setBit(perm, MsPermission.DEVICE_MANAGEMENT);
        return perm;
    }

    // region getters/setters

    get deviceUuid() {
        if (!this._pfUuid) {
            var pfUuid = PlatformComponent.getPlatformInfoObj().uuid;
            this._pfUuid = prepareAsUuid(pfUuid);
        }
        return this._pfUuid;
    }

    get deviceInfo() {
        return getDeviceInfo();
    }

    // region connection info
    get miniserver() {
        return this._miniserver;
    }

    get target() {
        //return the resolved reachability host - safest option.
        return this._reachabilityResult?.host;
    }

    get httpTarget() {
        if (this._reachabilityResult?.useTLS) {
            return "https://" + this.target + "/"
        }
        return "http://" + this.target + "/"
    }

    get fwVersion() {
        return this._reachabilityResult?.version;
    }

    // region creds
    set credentials(creds) {
        this._credentials = creds ? {...creds} : null;
    }

    // endregion connection info

    get user() {
        return this._credentials?.user;
    }

    get pass() {
        return this._credentials?.pass;
    }

    get token() {
        return this._credentials?.token;
    }

    //TODO-woessto: optimize so the pairSetupCommunicator isn't re-initialized each time (cloudDNS/reachability-check)
    static send(ms, {user, pass, permission, usePassword}, cmd) {
        const dbgName = "PairSetupComm-Static-SEND: " + cmd;
        Debug.PairedApp && console.log(dbgName);
        const comm = new PairSetupCommunicator(ms);

        const reachTimeout = setTimeout(() => {
            console.error(dbgName, "timeout");
            comm.stopReachabilityCheck();
        }, 30000);

        return comm.checkReachability().then(() => {
            clearTimeout(reachTimeout);
            Debug.PairedApp && console.log(dbgName, "reach confirmed");
            return comm.login({user, pass, permission, usePassword}).then(() => {
                Debug.PairedApp && console.log(dbgName, "authenticated, send");
                return comm.send(cmd).then((res) => {
                    Debug.PairedApp && console.log(dbgName, "send -> responded", res);
                    return res;
                }, (err) => {
                    console.log(dbgName, "send -> failed", err);
                    if (err.hasOwnProperty("status")) {
                        return Q.reject(err);
                    } else {
                        return Q.reject(err.exception);
                    }
                });
            }, (err) => {
                console.error(dbgName, "auth failed", err);
                return Q.reject(err.exception);
            });
        }, (err) => {
            clearTimeout(reachTimeout);
            console.error(dbgName, "reachability check failed!");
            return Q.reject(err.exception);
        });
    }

    set token(token) {
        if (!this._credentials) {
            this._credentials = {token}
        } else {
            this._credentials.token = token;
        }
    }

    get tokenExpired() {
        if (!this.token) {
            return true
        }
        const jwt = parseJwt(this.token),
            now = Math.floor(Date.now() / 1000);

        return jwt?.payload?.exp < now;
    }


    // endregion creds

    // endregion getters/setters

    checkReachability() {
        Debug.PairedApp && console.log(this.name, "_startReachabilityCheck: " + JSON.stringify(this.miniserver));
        this._reachabilityCheck = new ConnectivityTools.ReachabilityCheck();
        this._cloudDnsResolver = new ConnectivityTools.CloudDNSResolver();
        const reachCheckMS = {...this.miniserver};
        let usesCdns = false;
        if (this._usesCloudDNS() || (!this.miniserver.localUrl && this._getSerialNr())) {
            Debug.PairedApp && console.log(this.name, "  > resolve CNDS!");
            reachCheckMS.serialNo = this._getSerialNr();
            this._cloudDnsResolver.resolve(reachCheckMS.serialNo).then(this._dnsResolved.bind(this));
            delete reachCheckMS.remoteUrl;
            usesCdns = true;
        }

        this._reachNotifyCount = 0;
        this._isCheckingReachability = true;
        return this._reachabilityCheck.detectReachability(
            reachCheckMS.localUrl,
            reachCheckMS.remoteUrl,
            reachCheckMS.serialNo,
            null, usesCdns
            )
            .then(this._reachabilityConfirmed.bind(this));
    }

    stopReachabilityCheck() {
        Debug.PairedApp && console.log(this.name, "stopReachabilityCheck");
        this._reachabilityCheck && this._reachabilityCheck.stopReachabilityDetection();
        this._reachabilityCheck = null;
    }

    login({user, pass, permission = 0, usePassword = false} = {}) {
        Debug.PairedApp && console.log(this.name, "login");
        if (usePassword) {
            // when using a password, the token is not retrieved
            this.credentials = {
                usePassword,
                user, pass
            }
            return Q.resolve();
        } else {
            let tkPromise = LxCommunicator.requestToken(
                this.httpTarget,
                user,
                pass,
                permission || this.pairSetupPermission,
                this.deviceUuid,
                this.deviceInfo,
                this.fwVersion
            ).then(
                (res) => {
                    this.credentials = {
                        user, pass,
                        token: res.token
                    }
                    Debug.PairedApp && console.log(this.name, "login succeeded! ", parseJwt(this.token));
                },
                (err) => {
                    console.error(this.name, "login failed! " + err.message + ", " + JSON.stringify(err));
                    return Q.reject({ status: err.status, statusText: err.statusText });
                }
            )
            Debug.Communication && CommTracker.track(tkPromise, CommTracker.Transport.HTTP, "pairinggetjwt");
            return tkPromise;

        }
    }

    send(cmd) {
        let promise;
        if (this.usePassword) {
            promise = LxCommunicator.requestWithPassword(this.httpTarget, this.user, this.pass, cmd, true);
            Debug.Communication && CommTracker.track(promise, CommTracker.Transport.HTTP, cmd);
            return promise;
        } else {
            return this._ensureToken().then(() => {
                promise = LxCommunicator.requestWithJwtToken(this.httpTarget, this.user, this.token, cmd, true);
                Debug.Communication && CommTracker.track(promise, CommTracker.Transport.HTTP, cmd);
                return promise;
            })
        }
    }

    getPublicKey() {
        return LxCommunicator.getPublicKey(this.httpTarget);
    }

    // endregion

    // region private

    _ensureToken() {
        if (!this.token || this.tokenExpired) {
            return this.login({user: this.user, pass: this.pass});
        } else {
            return Q.resolve();
        }
    }

    // endregion

    _usesCloudDNS() {
        return this.miniserver.remoteUrl && isCloudDnsUrl(this.miniserver.remoteUrl);
    }

    _getSerialNr() {
        if (this.miniserver.serialNo) {
            return this.miniserver.serialNo;
        } else if (this._usesCloudDNS()) {
            return getSerialNo(this.miniserver.remoteUrl);
        }
    }

    _reachabilityConfirmed({ reachMode, host, useTLS, version, serialNo, isInTrust, certTld }) {
        Debug.PairedApp && console.log(this.name, "_reachabilityConfirmed: host=" + host + ", TLS=" + (!!useTLS));
        this._cloudDnsResolver && this._cloudDnsResolver.stop();
        this._isCheckingReachability = false;
        this._reachabilityResult = { reachMode, host, useTLS, version, serialNo, isInTrust, certTld };
        return this._reachabilityResult;
    }

    _dnsResolved({connectionType, ipHttp, ipHttps, dataCenter}) {
        Debug.PairedApp && console.log(this.name, "_dnsResolved: Plain=" + ipHttp + ", TLS=" + ipHttps);
        this._isCheckingReachability && this._reachabilityCheck.setNewRemoteHost(ipHttp, ipHttps, dataCenter);
    }
}