'use strict';

ActiveMSComp.factory('HomeKitAddonExt', function () {
    let weakThis,
        activeMsComp = {},
        pollingTimeout,
        pollingDefer,
        homeKitAddon,
        pluginStatus,
        currentUserType = HomeKit.VIEWER.USER,
        currentSetupStage = HomeKit.STAGE.INITIAL;

    function HomeKitAddonExt(comp) {
        this.name = "HomeKitAddonExt";
        weakThis = this
        activeMsComp = comp;

        if (PlatformComponent.isDeveloperInterface()) {
            var pluginFnWrapper = function (inputFn) {
                return function (succ, err) {
                    Debug.HomeKit && console.error("SIMULATED homeKitPlugin used!");
                    var inputFnArgs = [];

                    for (var i = 2; i < arguments.length; i++) {
                        inputFnArgs.push(arguments[i]);
                    }

                    setTimeout(function () {
                        inputFn.apply(null, inputFnArgs).then(succ, err);
                    }, getRandomIntInclusive(100, 2000));
                };
            };

            window.homeKitPlugin = {
                __devIsPluginPaired: true,
                // Debugging setting to overwrite wether or not an existing addon is paried or not.
                checkPermission: pluginFnWrapper(function () {
                    return Q.resolve("permission");
                }),
                preparePlugin: pluginFnWrapper(function (msSerial) {
                    return Q.resolve("Mein Zuhause");
                }),
                openSettings: pluginFnWrapper(function () {
                    return Q.resolve();
                }),
                assignNewAccessories: pluginFnWrapper(function (msSerial, accessories) {
                    return Q.resolve();
                }),
                addHome: pluginFnWrapper(function (homeName) {
                    return Q.resolve();
                }),
                getAllHomes: pluginFnWrapper(function () {
                    return Q.resolve(["Zuhause", "Eltern"]);
                }),
                getAccessoriesWithRooms: pluginFnWrapper(function (msSerial, accessories) {
                    return Q.resolve({
                        "1132c766-028c-5d3a-ffff9d81efc49231": {
                            name: "Automatic Blinds",
                            room: "Bathroom"
                        }
                    });
                }),
                //TODO-woessto
                assignAccessory: pluginFnWrapper(function (msSerial, accSerial, roomName, accessoryName) {
                    return Q.resolve("assignedRoomName");
                }),
                //TODO-woessto
                assignAccessories: pluginFnWrapper(function (accessories, bridgeSerialNr) {
                    return Q.resolve();
                }),
                pairMiniserver: pluginFnWrapper(function (homeName, miniserverName) {
                    return Q.resolve();
                }),
                removeMiniserver: pluginFnWrapper(function (msSerial) {
                    return Q.resolve();
                }),
                changeServiceType: pluginFnWrapper(function (msSerial, accSerial, serviceType) {
                    return Q.resolve();
                }),
                identifyAccessory: pluginFnWrapper(function (msSerial, accSerial) {
                    return Q.resolve();
                })
            };
        }
    }

    HomeKitAddonExt.Enums = {
        ADDON_ID: "HomeKit",
        ADDON_NAME: "HomeKit",
        MAX_ACCESSORIES: 149,
        MAX_ITERATIONS: 25,
        TokenState: {
            OK: "token-ready",
            FAIL: "ms-tried-but-failed-to-acquire-token",
            FAIL_OTHER: "ms-failed-other",
            REBOOT_REQUIRED: "reboot-required",
            NOT_ALLOWED: "ms-not-allowed-yet"
        },
        Cmd: {
            Provisioning: {
                RESET: "jdev/sys/appleprovisioning/reset",
                INFO: "jdev/sys/appleprovisioning/info"
            }
        },
        MsHkStatus: {
            OFF: -2,
            STOPPING: -1,
            IDLE: 0,
            STARTING: 1,
            RUNNING: 2
        },
        Error: {
            GET_ADDON_LIST_FAILED: "failed-to-get-addons-list"
        },
        RQ_ADDON_VERSION: "1.0.2" // Initial certified addon version
    };

    HomeKitAddonExt.prototype.doesActiveMiniserverSupportHomeKit = function doesActiveMiniserverSupportHomeKit() {
        var isSupported;
        var msType = ActiveMSComponent.getMiniserverType();

        switch (msType) {
            case MiniserverType.MINISERVER_GO:
            case MiniserverType.MINISERVER:
                isSupported = false;
                break;

            case MiniserverType.MINISERVER_GO_V2:
            case MiniserverType.MINISERVER_V2:
                isSupported = true;
                break;

            default:
                console.warn(this.name, "isSupportedMiniserverType: " + msType + " --> NOT official yet");
                isSupported = true;
                break;
        }

        return isSupported;
    };
    /**
     * returns a promise that will resolve if the Miniserver has got or did acquire a homeKit token.
     * @returns {*}
     */


    HomeKitAddonExt.prototype.ensureHomeKitToken = function ensureHomeKitToken() {
        var hasToken = ActiveMSComponent.hasHomeKitToken(),
            promise;

        if (hasToken) {
            promise = Q.resolve(HomeKitAddonExt.Enums.TokenState.OK);
        } else {
            promise = this._acquireProvisioningToken().then(function (res) {
                if (res === HomeKitAddonExt.Enums.TokenState.OK) {
                    return Q.resolve(res);
                } else {
                    return Q.reject(res);
                }
            });
        }

        return promise;
    };
    /**
     * Get the maximum number of accessories which can be bridged
     * @returns {number}
     */


    HomeKitAddonExt.prototype.getMaxHomeKitAccessories = function getMaxHomeKitAccessories() {
        return HomeKitAddonExt.Enums.MAX_ACCESSORIES;
    };
    /**
     * Reset the setup stage
     */


    HomeKitAddonExt.prototype.resetHomeKitSetupStage = function resetHomeKitSetupStage() {
        currentSetupStage = HomeKit.STAGE.INITIAL;
    };
    /**
     * Stops the polling of the current request
     */


    HomeKitAddonExt.prototype.stopHomeKitPolling = function stopHomeKitPolling() {
        clearTimeout(pollingTimeout);
        pollingTimeout = null;

        if (pollingDefer) {
            pollingDefer.reject("Polling was stopped");
        }
    };

    HomeKitAddonExt.prototype._restartHomeKitPlugin = function _restartHomeKitPlugin() {
        Debug.HomeKit && console.log(this.name, "_restartHomeKitPlugin");
        var cmd = Commands.format(Commands.ADDONS.HOMEKIT_RESTART); // must be declined with code 500 in order to work!

        return ActiveMSComponent.sendAddonCommand(homeKitAddon.uuid, cmd, true).then(null, function (err) {
            if (getLxResponseCode(err) === 500) {
                Debug.HomeKit && console.log(this.name, "_restartHomeKitPlugin > Success, restarting " + JSON.stringify(err));
                return this.getHomeKitAddonStatus();
            } else {
                Debug.HomeKit && console.log(this.name, "_restartHomeKitPlugin > Failed to restart! " + JSON.stringify(err));
                return Q.reject(err);
            }
        }.bind(this));
    };
    /**
     * Set the API user for the HomeKit Plugin. If stuck in switching users, just restart the plugin.
     * @param userUuid - User uuid for the API user
     * @returns {*}
     */


    HomeKitAddonExt.prototype.setHomeKitUser = function setHomeKitUser(userUuid) {
        Debug.HomeKit && console.log(this.name, "setHomeKitUser: " + userUuid);
        var cmd = Commands.format(Commands.ADDONS.HOMEKIT_SET_USER, userUuid);
        return _sendWithPolling(homeKitAddon.uuid, cmd, true).then(function (res) {
            Debug.HomeKit && console.log(this.name, "setHomeKitUser > success! " + userUuid);
            return res;
        }.bind(this), function (err) {
            Debug.HomeKit && console.log(this.name, "setHomeKitUser > failed! " + userUuid);
            return Q.reject(err);
        }.bind(this));
    };
    /**
     * Opens the iOS settings for the Loxone app
     * @returns {Q.Promise<unknown>}
     */


    HomeKitAddonExt.prototype.openIosAppSettings = function openIosAppSettings() {
        var defer = Q.defer();
        homeKitPlugin.openSettings(function () {
            defer.resolve();
        }.bind(this), function (error) {
            defer.reject();
        }.bind(this));
        return defer.promise;
    };
    /**
     * Checks if the Loxone app iOS HomeKit permission
     * @returns {Q.Promise<unknown>}
     */


    HomeKitAddonExt.prototype.checkHomeKitPermission = function checkHomeKitPermission() {
        var defer = Q.defer();
        Debug.HomeKit && console.log(this.name, "checkHomeKitPermission");
        homeKitPlugin.checkPermission(function (permission) {
            Debug.HomeKit && console.log(this.name, "checkHomeKitPermission >> resolved: " + JSON.stringify(permission));
            defer.resolve(permission);
        }.bind(this), function (error) {
            console.error(this.name, "checkHomeKitPermission >> resolved: " + JSON.stringify(permission));
            defer.reject(error);
        }.bind(this));
        return defer.promise;
    };
    /**
     * Get the HomeKit Plugin owner
     * @returns {null|{owner: *, uuid: *}}
     */


    HomeKitAddonExt.prototype.getHomeKitAddonOwner = function getHomeKitAddonOwner() {
        if (homeKitAddon) {
            return {
                owner: homeKitAddon.ownerUser,
                uuid: homeKitAddon.ownerUserUuid
            };
        }

        return null;
    };
    /**
     * Get the HomeKit home where the miniserver is paired.
     * @returns {Q.Promise<unknown>}
     */


    HomeKitAddonExt.prototype.getMiniserverHomeName = function getMiniserverHomeName() {
        var msSerial = ActiveMSComponent.getMiniserverSerialNo();
        Debug.HomeKit && console.log(this.name, "getMiniserverHomeName - snr = " + msSerial); // try at least two times with a timeout, this ensures potential short-time conneciton losses due to restarts aren't a problem.

        return this._preparePluginForMiniserver(msSerial).then(function (msName) {
            Debug.HomeKit && console.log(this.name, "getMiniserverHomeName - _preparePluginForMiniserver#1 succeeded = " + msSerial + " is called " + msName);
            return msName;
        }.bind(this), function (err) {
            var def = Q.defer();
            Debug.HomeKit && console.log(this.name, "getMiniserverHomeName - failed #1, retry after timeout " + JSON.stringify(err));
            setTimeout(function () {
                this._preparePluginForMiniserver(msSerial, true).then(function (msName2) {
                    Debug.HomeKit && console.log(this.name, "getMiniserverHomeName - #2 succeeded = " + msSerial + " is called " + msName2);
                    def.resolve(msName2);
                }.bind(this), function (err) {
                    Debug.HomeKit && console.log(this.name, "getMiniserverHomeName - failed #2, bail out. Cause " + JSON.stringify(err));
                    def.reject(err);
                }.bind(this));
            }.bind(this), 2000);
            return def.promise;
        }.bind(this));
    };
    /**
     * Get the HomeKit home where the miniserver is paired.
     * @returns {Q.Promise<unknown>}
     */


    HomeKitAddonExt.prototype._preparePluginForMiniserver = function _preparePluginForMiniserver(msSerial, isRetry) {
        var defer = Q.defer();
        Debug.HomeKit && console.log(this.name, "_preparePluginForMiniserver - snr = " + msSerial);
        homeKitPlugin.preparePlugin(function (homeName) {
            Debug.HomeKit && console.log(this.name, "_preparePluginForMiniserver - done, home name = " + homeName);
            defer.resolve(homeName);
        }.bind(this), function (err) {
            console.error(this.name, "_preparePluginForMiniserver - returned error: " + JSON.stringify(err));
            defer.reject(err);
        }.bind(this), msSerial);
        return defer.promise;
    };
    /**
     * Assign newly selected HomeKit accessories. Coming form the settings screen.
     * IMPORTANT: This needs to be started, before new accessories are expected to arrive, as it listens to a delegate!
     * @param accessories
     * @returns {Q.Promise<unknown>}
     */


    HomeKitAddonExt.prototype.assignNewHomeKitAccessories = function assignNewHomeKitAccessories(accessories) {
        Debug.HomeKit && console.log(this.name, "assignNewHomeKitAccessories (" + accessories.length + " accessories)");
        var defer = Q.defer(),
            msSerial = ActiveMSComponent.getMiniserverSerialNo();
        homeKitPlugin.assignNewAccessories(function (errors) {
            Debug.HomeKit && console.log(this.name, "assignNewHomeKitAccessories done, res=" + JSON.stringify(errors));
            defer.resolve();
        }.bind(this), function (error) {
            console.error(this.name, "assignNewHomeKitAccessories rejected with error: " + JSON.stringify(error));
            defer.reject(error);
        }.bind(this), msSerial, accessories);
        return defer.promise;
    };
    /**
     * Get the current user type that starts the HomeKit VC
     * @returns {string}
     */


    HomeKitAddonExt.prototype.getCurrentHomeKitUserType = function getCurrentHomeKitUserType() {
        return currentUserType;
    };
    /**
     * Create the HomeKit Plugin
     * @returns {*}
     */


    HomeKitAddonExt.prototype.createHomeKitPlugin = function createHomeKitPlugin() {
        if (currentSetupStage < HomeKit.STAGE.PLUGIN_READY) {
            return ActiveMSComponent.createAddonInstance(HomeKitAddonExt.Enums.ADDON_ID, HomeKitAddonExt.Enums.ADDON_NAME).then(function (res) {
                homeKitAddon = res;
                currentSetupStage = HomeKit.STAGE.PLUGIN_READY;
            }).then(function () {
                return ActiveMSComponent.getHomeKitAddonStatus();
            });
        } else {
            return Q(true);
        }
    };
    /**
     * Delete the HomeKit plugin
     * @returns {*}
     */


    HomeKitAddonExt.prototype.deleteHomeKitPlugin = function deleteHomeKitPlugin() {
        return ActiveMSComponent.deleteAddonInstance(homeKitAddon.uuid);
    };
    /**
     * Function to check if the HomeKit plugin is already created.
     * @returns {*} the uuid of the HomeKit plugin or undefined if not created
     */


    HomeKitAddonExt.prototype.isHomeKitAddonCreated = function isHomeKitAddonCreated() {
        return ActiveMSComponent.getAddonList().then(function (res) {
            var plugin,
                version = "unknown",
                apiUserUuid;
            res.forEach(function (addon) {
                if (addon.id === HomeKitAddonExt.Enums.ADDON_ID) {
                    if (addon.package && addon.package.version) {
                        version = addon.package.version;
                    }

                    if (addon.controls && addon.controls.length > 0) {
                        apiUserUuid = addon.controls[0].apiUserUuid;
                    }

                    if (addon.controls && addon.controls.length > 0) {
                        plugin = addon.controls[0];
                        return;
                    }
                }
            }.bind(this));
            homeKitAddon = plugin;

            _setCurrentUserType();

            if (homeKitAddon) {
                currentSetupStage = HomeKit.STAGE.PLUGIN_READY;
            }

            return {
                created: !!homeKitAddon,
                version: version,
                apiUserUuid: apiUserUuid
            };
        }, function (err) {
            console.error(this.name, "isHomeKitAddOnCreated - failed to acquire addons-list! " + JSON.stringify(err));
            var resultingError = err; // ensure a proper result object is returned (homeKitViewController requires it)

            if (!err || typeof err !== 'object') {
                resultingError = {
                    initialError: err
                };
            }

            resultingError.cause = HomeKitAddonExt.Enums.Error.GET_ADDON_LIST_FAILED;
            return Q.reject(resultingError);
        }.bind(this));
    };
    /**
     * Returns true if the version provided indicates that the miniserver (or it's accompanioning addons-file)
     * requires to be updated, as the homeKit plugin version is below the Minimum.
     * @param currVersion
     * @returns {boolean}
     */


    HomeKitAddonExt.prototype.homeKitAddonUpdateRequired = function homeKitAddonUpdateRequired(currVersion) {
        var rqVersion = HomeKitAddonExt.Enums.RQ_ADDON_VERSION;

        if (isOlderVersionThan(currVersion, rqVersion)) {
            Debug.HomeKit && console.log(this.name, "_verifyHomeKitMinVersion: " + currVersion + " = too old, requires " + rqVersion);
            return true;
        } else {
            Debug.HomeKit && console.log(this.name, "_verifyHomeKitMinVersion: " + currVersion + " = okay, requires " + rqVersion);
            return false;
        }
    };
    /**
     * Add a new home to HomeKit
     * @param homeName
     * @returns {Q.Promise<unknown>}
     */


    HomeKitAddonExt.prototype.addHomeKitHome = function addHomeKitHome(homeName) {
        Debug.HomeKit && console.log(this.name, "addHomeKitHome: " + homeName);
        var defer = Q.defer();
        homeKitPlugin.addHome(function () {
            Debug.HomeKit && console.log(this.name, "addHomeKitHome > passed!" + homeName);
            currentSetupStage = HomeKit.STAGE.HOME_CREATED;
            defer.resolve();
        }.bind(this), function (error) {
            console.error(this.name, "addHomeKitHome: Could not add new home: " + error);
            defer.reject(error);
        }.bind(this), homeName);
        return defer.promise;
    };
    /**
     * Get all HomeKit home name.
     * @returns {Q.Promise<unknown>}
     */


    HomeKitAddonExt.prototype.getAllHomeKitHomes = function getAllHomeKitHomes() {
        var defer = Q.defer();
        homeKitPlugin.getAllHomes(function (homes) {
            defer.resolve(homes);
        }.bind(this), function (error) {
            defer.reject(error);
        }.bind(this));
        return defer.promise;
    };
    /**
     * Get all bridged accessories with HomeKit rooms
     * @param accessories
     * @returns {Q.Promise<unknown>}
     */


    HomeKitAddonExt.prototype.getHomeKitAccessoriesWithRooms = function getHomeKitAccessoriesWithRooms(accessories) {
        var defer = Q.defer(),
            msSerial = ActiveMSComponent.getMiniserverSerialNo();
        homeKitPlugin.getAccessoriesWithRooms(function (accessoriesWithRooms) {
            defer.resolve(accessoriesWithRooms);
        }.bind(this), function (error) {
            defer.reject(error);
        }.bind(this), msSerial, accessories);
        return defer.promise;
    };
    /**
     * Assign a single accessory to his correct room at HomeKit.
     * @param accessorySerial
     * @param roomName
     * @param accessoryName
     * @returns {Q.Promise<unknown>}
     */


    HomeKitAddonExt.prototype.assignHomeKitAccessory = function assignHomeKitAccessory(accessorySerial, roomName, accessoryName) {
        var defer = Q.defer(),
            msSerial = ActiveMSComponent.getMiniserverSerialNo();
        homeKitPlugin.assignAccessory(function (assignedRoomName) {
            defer.resolve(assignedRoomName);
        }.bind(this), function (error) {
            defer.reject(error);
        }.bind(this), msSerial, accessorySerial, roomName, accessoryName);
        return defer.promise;
    };
    /**
     * Pair the Miniserver with HomeKit
     * @param homeName
     * @param accessoryName - Miniserver HomeKit name. Get from status call.
     * @returns {Q.Promise<unknown>}
     */


    HomeKitAddonExt.prototype.pairMiniserverWithHomeKit = function pairMiniserverWithHomeKit(homeName, accessoryName) {
        var defer = Q.defer(),
            miniserverName;

        if (!accessoryName) {
            miniserverName = ActiveMSComponent.getMiniserverName();
            Debug.HomeKit && console.log(this.name, "pairMiniserverWithHomeKit: >>> accessory name NOT provided, using MS name=" + miniserverName);
        } else {
            Debug.HomeKit && console.log(this.name, "pairMiniserverWithHomeKit: >>> accessory name provided=" + accessoryName);
            miniserverName = accessoryName;
        }

        this.isAllowedToPair().then(function () {
            Debug.HomeKit && console.log(this.name, "pairMiniserverWithHomeKit: homeName=" + homeName + ", accessoryName=" + miniserverName);
            homeKitPlugin.pairMiniserver(function () {
                currentSetupStage = HomeKit.STAGE.MS_PAIRED;
                defer.resolve();
            }.bind(this), function (error) {
                defer.reject(error);
            }.bind(this), homeName, miniserverName);
        }.bind(this), function (err) {
            defer.reject(err);
        });
        return defer.promise;
    };
    /**
     * Unpair the HomeKit
     * @returns {*}
     */


    HomeKitAddonExt.prototype.unpairHomeKit = function unpairHomeKit() {
        Debug.HomeKit && console.log(this.name, "unpairHomeKit");
        return ActiveMSComponent.sendAddonCommand(homeKitAddon.uuid, Commands.ADDONS.HOMEKIT_UNPAIR).then(function (response) {
            Debug.HomeKit && console.log(this.name, "unpairHomeKit: request responded: " + getLxResponseCode(response) + " - " + JSON.stringify(response));
            return response;
        }.bind(this), function (error) {
            Debug.HomeKit && console.error(this.name, "unpairHomeKit: request failed: " + getLxResponseCode(response) + " - " + JSON.stringify(response));
            return Q.reject(error);
        }.bind(this));
    };
    /**
     * Updates the visibility of the bridged accessories at the HomeKit plugin
     * @param accessoriesArray
     * @returns {*}
     */


    HomeKitAddonExt.prototype.updateHomeKitAccessoriesList = function updateHomeKitAccessoriesList(accessoriesArray) {
        Debug.HomeKit && console.log(this.name, "updateHomeKitAccessoriesList: " + accessoriesArray.length);
        var cmd = Commands.format(Commands.CONTROL.COMMAND, homeKitAddon.uuid, Commands.ADDONS.HOMEKIT_UPDATE_ACCESSORIES_LIST);
        var string = JSON.stringify(accessoriesArray).replaceAll('\\"', '"');
        return ActiveMSComponent.sendHttpAddonCommand(homeKitAddon.uuid, Commands.ADDONS.HOMEKIT_UPDATE_ACCESSORIES_LIST, true, HTTP_METHODS.POST, string).then(function (addonResult) {
            Debug.HomeKit && console.log(this.name, "updateHomeKitAccessoriesList: request responded: " + addonResult);
            return addonResult;
        }.bind(this), function (rqError) {
            var code = getLxResponseCode(rqError);

            if (code === HomeKit.ResponseCode.UNMODIFIED) {
                Debug.HomeKit && console.log(this.name, "updateHomeKitAccessoriesList: request responded: " + getLxResponseValue(rqError, true));
                return Q.resolve(code);
            } else if (code === HomeKit.ResponseCode.INVALID_REQUEST) {
                Debug.HomeKit && console.error(this.name, "updateHomeKitAccessoriesList: request failed: " + getLxResponseValue(rqError, true));
            } else {
                Debug.HomeKit && console.error(this.name, "updateHomeKitAccessoriesList: request failed: " + getLxResponseCode(rqError) + " - " + JSON.stringify(rqError));
            }

            return Q.reject(rqError);
        }.bind(this));
    };
    /**
     * Assign the bridged accessories to the correct rooms at HomeKit. Used while the initial setup and reset all process.
     * @param accessories
     * @returns {Q.Promise<unknown>}
     */


    HomeKitAddonExt.prototype.assignHomeKitAccessories = function assignHomeKitAccessories(accessories) {
        var defer = Q.defer();
        homeKitPlugin.assignAccessories(function (assigningErrors) {
            currentSetupStage = HomeKit.STAGE.ACCESSORIES_ASSIGNED;

            if (assigningErrors && Object.keys(assigningErrors).length > 0) {
                NavigationComp.requestDebuglog("HOME_KIT");
                console.error(JSON.stringify(assigningErrors));
            }

            defer.resolve();
        }.bind(this), function (error) {
            defer.reject(error);
        }.bind(this), accessories, pluginStatus.bridge.serial);
        return defer.promise;
    };
    /**
     * Get the bridged accessories from the HomeKit plugin for accessories screen
     * @param [miniserverHomeName] - optional if not present it will be detected inside this function
     * @returns {*}
     */


    HomeKitAddonExt.prototype.getHomeKitAccessoriesList = function getHomeKitAccessoriesList(miniserverHomeName) {
        Debug.HomeKit && console.log(this.name, "getHomeKitAccessoriesList");
        return _sendWithPolling(homeKitAddon.uuid, Commands.ADDONS.HOMEKIT_GET_ACCESSORIES_LIST).then(function (accessories) {
            Debug.HomeKit && console.log(this.name, "getHomeKitAccessoriesList: request responded: " + accessories.length + " accessories" + JSON.stringify(accessories)); // ensure the addon is up and running again, otherwise getMiniserverHomeName may fail, as the MS isn't
            // reachable via HomeKit

            return this.getHomeKitAddonStatus().then(function (statusRes) {
                Debug.HomeKit && console.log(this.name, "getHomeKitAccessoriesList: addon is up and running" + JSON.stringify(statusRes));
                var promise;

                if (miniserverHomeName) {
                    Debug.HomeKit && console.log(this.name, "getHomeKitAccessoriesList: home name known: " + miniserverHomeName);
                    promise = Q(miniserverHomeName);
                } else {
                    Debug.HomeKit && console.log(this.name, "getHomeKitAccessoriesList: request home name via HomeKit API");
                    promise = ActiveMSComponent.getMiniserverHomeName();
                }

                return promise.then(function (homeName) {
                    Debug.HomeKit && console.log(this.name, "getHomeKitAccessoriesList: home name acquired: " + homeName);
                    accessories.forEach(function (accessory) {
                        accessory.serial = accessory.serial.replaceAll("\\", "");
                        accessory.room = accessory.lxRoomName;
                        accessory.roomUuid = accessory.lxRoomUuid;
                        accessory.homeKitRoom = _generateHomeKitRoomName(homeName, accessory.lxRoomName);
                        accessory.typeName = _getControlTypeName(accessory.lxType);

                        if (accessory.homeKitRoom.toLowerCase() === accessory.name.toLowerCase()) {
                            accessory.homeKitName = _getControlTypeName(accessory.lxType);
                        }
                    }.bind(this));
                    return accessories;
                }.bind(this));
            }.bind(this));
        }.bind(this), function (err) {
            // {"LL":{"control":"dev/sps/io/18eab30b-031a-9181-ffff504f94a000a2/accessoriesList","value":"Switching to NoRights1BlockNoPass@504F94A000A2","Code":"503"}}
            var code = getLxResponseCode(err);

            if (code === HomeKit.ResponseCode.FAILED_TO_SWITCH_USER) {
                Debug.HomeKit && console.log(this.name, "getHomeKitAccessoriesList > failed after switching users, restart plugin! " + JSON.stringify(err)); // if switching fails, restart the addon by sending "exit", may be stuck in the "waitingForStates"

                return this._restartHomeKitPlugin().then(function () {
                    // try again!
                    return this.getHomeKitAccessoriesList(miniserverHomeName);
                }.bind(this));
            } else {
                console.error(this.name, "getHomeKitAccessoriesList failed (polling stopped)! " + JSON.stringify(err));
                return Q.reject(err);
            }
        }.bind(this));
    };
    /**
     * Get the HomeKit plugin status
     * @returns {*}
     */


    HomeKitAddonExt.prototype.getHomeKitAddonStatus = function getHomeKitAddonStatus() {
        Debug.HomeKit && console.log(this.name, "getHomeKitAddonStatus");
        return _sendWithPolling(homeKitAddon.uuid, Commands.ADDONS.HOMEKIT_STATUS).then(function (response) {
            Debug.HomeKit && console.log(this.name, "getHomeKitAddonStatus: request responded: " + getLxResponseCode(response) + " - " + JSON.stringify(response));
            pluginStatus = response; // code for testing on the dev-IF

            if (PlatformComponent.isDeveloperInterface()) {
                pluginStatus.isPaired = homeKitPlugin.__devIsPluginPaired;
            }

            if (pluginStatus && pluginStatus.isPaired) {
                currentSetupStage = HomeKit.STAGE.MS_PAIRED;
            }

            return response;
        }.bind(this), function (error) {
            Debug.HomeKit && console.error(this.name, "getHomeKitAddonStatus: request failed: " + getLxResponseCode(error) + " - " + JSON.stringify(error));
            /** Status request fails with the following error if it responds, but it's still not active after many attempts.
             * {
             * "bridge":{"aid":"1","model":"Loxone Miniserver","name":"TobisDevGo2B","numAvailableAccessories":5,"numVisibleAccessories":5,"serial":"504F94A00C93"},
             * "hkStatus":0,"isActive":false,"isPaired":false,"isWsCommActive":true,"pluginVersion":"1.0.0","wsCommUser":"admin@504F94A00C93"
             * }
             * --> Otherwise it'll respond with an Lx Request error.
             * e.g. Plugin could not start (can be forced by modifying the permissions of the kvStore)
             * e.g. Plugin not yet up and running
             * {"LL":{"control":"dev/sps/io/17f81efa-00c1-2519-ffff504f94a00c93/status","value":"status","Code":"500"}}
             *
             * if the status responses isActive flag doesn't return true after the polling, it fails with the status
             * response. As the HomeKitViewController is optimized to deal with lxRequest errors, make it look like one.
             */

            if (typeof error === "object" && error.hasOwnProperty("isActive")) {
                return Q.reject({
                    LL: {
                        Code: -500,
                        value: JSON.stringify(error),
                        control: "status"
                    }
                });
            }

            return Q.reject(error);
        }.bind(this));
    };
    /**
     * Get the pairing information for pairing the miniserver with HomeKit
     * @returns {*}
     */


    HomeKitAddonExt.prototype.getHomeKitPairingInfo = function getHomeKitPairingInfo() {
        Debug.HomeKit && console.log(this.name, "getHomeKitPairingInfo");
        return _sendWithPolling(homeKitAddon.uuid, Commands.ADDONS.HOMEKIT_PAIRING_INFO, true).then(function (res) {
            Debug.HomeKit && console.log(this.name, "getHomeKitPairingInfo: request responded: " + getLxResponseCode(res) + " - " + JSON.stringify(res));
            return JSON.parse(res.replaceAll("\\", ""));
        }.bind(this), function (error) {
            Debug.HomeKit && console.log(this.name, "getHomeKitPairingInfo: request failed: " + getLxResponseCode(error) + " - " + JSON.stringify(error));
            return Q.reject(error);
        }.bind(this));
    };
    /**
     * Resets the whole HomeKit installation at the specific setup stage
     * @returns {*}
     */


    HomeKitAddonExt.prototype.resetHomeKit = function resetHomeKit() {
        var resetPromise;

        if (currentSetupStage === HomeKit.STAGE.MS_PAIRED || currentSetupStage === HomeKit.STAGE.ACCESSORIES_ASSIGNED) {
            Debug.HomeKit && console.log(this.name, "resetHomeKit: remove ms from homekit, unpair, then delete (stage=" + currentSetupStage + ")");
            resetPromise = _removeMiniserverFromHomeKit().then(ActiveMSComponent.unpairHomeKit.bind(this)).then(ActiveMSComponent.deleteHomeKitPlugin.bind(this));
        } else if (currentSetupStage === HomeKit.STAGE.PLUGIN_READY || currentSetupStage === HomeKit.STAGE.HOME_CREATED) {
            Debug.HomeKit && console.log(this.name, "resetHomeKit: just delete (stage=" + currentSetupStage + ")");
            resetPromise = ActiveMSComponent.deleteHomeKitPlugin();
        } else if (currentSetupStage === HomeKit.STAGE.INITIAL) {
            Debug.HomeKit && console.log(this.name, "resetHomeKit: nothing to do");
            resetPromise = Q(true);
        }

        return resetPromise.then(function () {
            Debug.HomeKit && console.log(this.name, "resetHomeKit >> passed");
            currentSetupStage = HomeKit.STAGE.INITIAL;
        }.bind(this), function (err) {
            console.error(this.name, "resetHomeKit >> failed! " + JSON.stringify(err));
            return Q.reject(err);
        }.bind(this));
    };
    /**
     * Send the identify request to a specific accessory
     * @param accessorySerial
     * @returns {Q.Promise<unknown>}
     */


    HomeKitAddonExt.prototype.identifyHomeKitAccessory = function identifyHomeKitAccessory(accessorySerial) {
        var defer = Q.defer(),
            miniserverSerial = ActiveMSComponent.getMiniserverSerialNo();
        homeKitPlugin.identifyAccessory(function () {
            defer.resolve();
        }.bind(this), function (error) {
            defer.reject(error);
        }.bind(this), miniserverSerial, accessorySerial);
        return defer.promise;
    };
    /**
     * Changes the service type of a specific switch accessory
     * @param accessorySerial
     * @param serviceType
     * @returns {Q.Promise<unknown>}
     */


    HomeKitAddonExt.prototype.changeHomeKitAccessoryServiceType = function changeHomeKitAccessoryServiceType(accessorySerial, serviceType) {
        var defer = Q.defer(),
            miniserverSerial = ActiveMSComponent.getMiniserverSerialNo();
        homeKitPlugin.changeServiceType(function () {
            defer.resolve();
        }.bind(this), function (error) {
            defer.reject(error);
        }.bind(this), miniserverSerial, accessorySerial, serviceType);
        return defer.promise;
    };
    /**
     * Get the QR code svg string
     * @param location - location of the pairing QR code. Get from the getHomeKitPairingInformation call.
     * @returns {*}
     */


    HomeKitAddonExt.prototype.getHomeKitPairingQRCode = function getHomeKitPairingQRCode(location) {
        var cmd = Commands.format(Commands.GET_FILE, location);
        return CommunicationComponent.sendViaHTTP(cmd, EncryptionType.REQUEST).then(function (data) {
            return data.replaceAll("\\", "");
        }.bind(this));
    };

    HomeKitAddonExt.prototype._acquireProvisioningToken = function _acquireProvisioningToken() {
        Debug.HomeKit && console.log(this.name, "_acquireProvisioningToken");
        return this._verifyProvisioning(false).fail(function (errResp) {
            Debug.HomeKit && console.log(this.name, "_acquireProvisioningToken > verify #1 failed = no token exists!", errResp);

            if (errResp === HomeKitAddonExt.Enums.TokenState.FAIL_OTHER) {
                return Q.reject(errResp);
            }

            return this._triggerResetProvisioning().then(function (res) {
                Debug.HomeKit && console.log(this.name, "     ---> reset triggered, reverify! " + JSON.stringify(res));
                return this._verifyProvisioning(true);
            }.bind(this), function (err) {
                console.error(this.name, "_acquireProvisioningToken >> error occured: " + JSON.stringify(err));
                return HomeKitAddonExt.Enums.TokenState.FAIL_OTHER;
            }.bind(this));
        }.bind(this));
    };

    HomeKitAddonExt.prototype._verifyProvisioning = function _verifyProvisioning(resetSucceeded) {
        Debug.HomeKit && console.log(this.name, "_verifyProvisioning");
        return CommunicationComponent.sendViaHTTP(HomeKitAddonExt.Enums.Cmd.Provisioning.INFO, EncryptionType.REQUEST_RESPONSE_VAL, true).then(function (res) {
            var response = getLxResponseValue(res);

            if (!!response.available) {
                Debug.HomeKit && console.log(this.name, "_verifyProvisioning >> passed, token ready! " + JSON.stringify(response));
                return Q.resolve(HomeKitAddonExt.Enums.TokenState.OK);
            } else if (!!response.notUnlocked) {
                console.error(this.name, "_verifyProvisioning >> MS not allowed yet! " + JSON.stringify(response));

                if (resetSucceeded) {
                    // when a reset request succeeds, but a subsequent verify fails, a reboot is required. as probably
                    // the attributes of the info request aren't updated
                    return Q.reject(HomeKitAddonExt.Enums.TokenState.REBOOT_REQUIRED);
                } else {
                    return Q.reject(HomeKitAddonExt.Enums.TokenState.NOT_ALLOWED);
                }
            } else {
                console.warn(this.name, "_verifyProvisioning >> failed: " + JSON.stringify(response));
                return Q.reject(HomeKitAddonExt.Enums.TokenState.FAIL);
            }
        }.bind(this), function (fail) {
            console.error(this.name, "_verifyProvisioning >> error occured " + JSON.stringify(fail));
            return Q.reject(HomeKitAddonExt.Enums.TokenState.FAIL_OTHER);
        }.bind(this));
    };
    /**
     * used to verify homeKit pairing is allowed, may be blocked due to spam protection. The provisioning info response
     * contains a lock attribute. If this counter reaches 3, it means the next pairing attempt must be blocked. This is
     * due to flash-write-protection. After 10 Minutes or so, the counter will be decremented.
     * @returns {*}
     */


    HomeKitAddonExt.prototype.isAllowedToPair = function isAllowedToPair() {
        Debug.HomeKit && console.log(this.name, "isAllowedToPair");
        return CommunicationComponent.sendViaHTTPWithPermission(HomeKitAddonExt.Enums.Cmd.Provisioning.INFO, MsPermission.CONFIG, EncryptionType.REQUEST_RESPONSE_VAL, true).then(function (res) {
            var response = getLxResponseValue(res);

            if (response.lock >= 0 && response.lock < 3) {
                Debug.HomeKit && console.log(this.name, "isAllowedToPair >> true, lock count at " + response.lock);
                return Q.resolve();
            } else {
                console.error(this.name, "isAllowedToPair >> FALSE, lock count at " + response.lock);
                return Q.reject({
                    LL: {
                        code: HomeKit.ResponseCode.TEMP_FORBIDDEN,
                        value: "Spam Protection"
                    }
                });
            }
        }.bind(this), function (err) {
            console.error(this.name, "isAllowedToPair >> FALSE, failed to get lock info! " + JSON.stringify(err));
            return Q.reject(err);
        }.bind(this));
    };

    HomeKitAddonExt.prototype._triggerResetProvisioning = function _triggerResetProvisioning() {
        Debug.HomeKit && console.log(this.name, "_triggerResetProvisioning");
        return CommunicationComponent.sendViaHTTPWithPermission(HomeKitAddonExt.Enums.Cmd.Provisioning.RESET, MsPermission.CONFIG, EncryptionType.REQUEST_RESPONSE_VAL, true).then(function (res) {
            Debug.HomeKit && console.log(this.name, "_triggerResetProvisioning >> passed " + JSON.stringify(res)); // code 200 = provisioning passed!

            return res;
        }.bind(this), function (err) {
            // code 500 = failed to provision!
            console.error(this.name, "_triggerResetProvisioning >> error occured " + JSON.stringify(err));
            return Q.reject(HomeKitAddonExt.Enums.TokenState.FAIL_OTHER);
        }.bind(this));
    };

    var _generateHomeKitRoomName = function _generateHomeKitRoomName(homeName, roomName) {
        if (roomName.toLowerCase().replace(Regex.HOME_KIT_NAME_FILTER, " ").trim() === homeName.toLowerCase()) {
            return _("room") + " " + roomName.replace(Regex.HOME_KIT_NAME_FILTER, " ").trim();
        }

        return roomName.replace(Regex.HOME_KIT_NAME_FILTER, " ").trim();
    };

    var _setCurrentUserType = function _setCurrentUserType() {
        var currentUser = ActiveMSComponent.getCurrentUser();

        if (homeKitAddon && homeKitAddon.ownerUserUuid && currentUser.uuid === homeKitAddon.ownerUserUuid) {
            currentUserType = HomeKit.VIEWER.OWNER;
        } else if (ActiveMSComponent.isAdminUser()) {
            currentUserType = HomeKit.VIEWER.ADMIN;
        } else {
            currentUserType = HomeKit.VIEWER.USER;
        }
    };

    var _removeMiniserverFromHomeKit = function _removeMiniserverFromHomeKit() {
        var defer = Q.defer(),
            msSerial = ActiveMSComponent.getMiniserverSerialNo();
        homeKitPlugin.removeMiniserver(function () {
            defer.resolve();
        }.bind(this), function (error) {
            defer.reject(error);
        }.bind(this), msSerial);
        return defer.promise;
    };

    var _sendWithPolling = function _sendWithPolling(homeKitUuid, command, asString, counter) {
        counter = counter || 0;
        return ActiveMSComponent.sendAddonCommand(homeKitUuid, command, asString).then(function (res) {
            if (command === Commands.ADDONS.HOMEKIT_STATUS && !res.isActive) {
                console.error("HomeKit plugin is not active! " + JSON.stringify(res));

                if (!res.isPaired && res.hkStatus === HomeKitAddonExt.Enums.MsHkStatus.IDLE) {
                    // the plugins hkStatus is idle when it's sleeping, requesting pairing info while off or stopping will fail!
                    console.warn("HomeKit plugin is not paired, not active, but IDLE, wake it up using get PairingInfo request");
                    return ActiveMSComponent.sendAddonCommand(homeKitUuid, Commands.ADDONS.HOMEKIT_PAIRING_INFO, true).then(function () {
                        // pairing info acquired - triggers an accessory server start, proceed with acquiring status.
                        return _handlePolling(homeKitUuid, command, asString, counter, res);
                    }.bind(this), function (err) {
                        console.error(this.name, "Waking up the plugin using pairing-info-request failed, continue with status-polling! " + JSON.stringify(err));
                        return _handlePolling(homeKitUuid, command, asString, counter, res);
                    }.bind(this));
                } else {
                    return _handlePolling(homeKitUuid, command, asString, counter, res);
                }
            } else {
                return res;
            }
        }, function (error) {
            console.error("HomeKit command " + command + " resulted with an error: " + JSON.stringify(error));
            return _handlePolling(homeKitUuid, command, asString, counter, error);
        }.bind(this));
    };
    /**
     * Will resend the command after 1.25 seconds if the max retry iterations have not been exceeded.
     * Returns a promise that will either resolve if successful or reject if still failed after max attempts.
     * @param homeKitUuid   the uuid of the homekit plugin to send the command to
     * @param command       the command to resend after the interval
     * @param asString      whether or not the result is to be returned as string.
     * @param counter       number of attempts made so far
     * @param [error]       optional request error of the previous attempt
     * @returns {Q.Promise<any>|*}
     * @private
     */


    var _handlePolling = function _handlePolling(homeKitUuid, command, asString, counter, error) {
        if (counter <= _getMaxPollingIterations(command, error)) {
            pollingDefer = Q.defer();
            pollingTimeout = setTimeout(function () {
                pollingDefer.resolve(_sendWithPolling(homeKitUuid, command, asString, ++counter));
            }.bind(this), 1250);
            return pollingDefer.promise;
        } else {
            return _handleRequestError(command, error);
        }
    };

    var _getMaxPollingIterations = function _getMaxPollingIterations(command, error) {
        var iterations = HomeKitAddonExt.Enums.MAX_ITERATIONS; // 25 * 1250 = 31 Seconds

        if (command === Commands.ADDONS.HOMEKIT_GET_ACCESSORIES_LIST && error && JSON.stringify(error).indexOf("Switching to ") >= 0) {
            // when switching users, don't wait too long, otherwise the plugin restart to recover from being
            // stuck in waitingForStates takes too long.
            return 8; // 10 Seconds - if the list isn't returned by that time, the addon should be restartet.
        } else if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.DeveloperInterface) {
            iterations = 10; // for debugging purposes 12,5 Seconds
        }

        return iterations;
    };
    /**
     * Returns a promise that will reject with the arguments required to provide proper user feedback.
     * @param command
     * @param rqError   the error returned from the miniserver by the last polling attempt
     * @returns {Q.Promise<unknown>}
     * @private
     */


    var _handleRequestError = function _handleRequestError(command, rqError) {
        return Q.reject(rqError || command);
    };

    var _getControlTypeName = function _getControlTypeName(type) {
        var lowerType = type.toLowerCase(),
            stringId = "search.controltype." + lowerType,
            controlTypeName = _(stringId);

        if (controlTypeName === stringId || lowerType === controlTypeName) {
            // We didn't find the string try to append the type again, controls with keywords
            // in the Kerberos.xml will have this structure
            stringId += "." + lowerType;
            controlTypeName = _(stringId);
        }

        if (controlTypeName === stringId || lowerType === controlTypeName) {
            return null;
        } else {
            return controlTypeName;
        }
    };

    return HomeKitAddonExt;
});
