import CachedServerFileLoader from "../../../shared/logic/fileAccess/CachedServerFileLoader";
import ResourceFileLoader from "../../../shared/logic/fileAccess/ResourceFileLoader";
import FileRequest from "../../../shared/logic/fileAccess/FileRequest";
import CacheFileHandler from "../../../shared/logic/fileAccess/CacheFileHandler";
import PersistentStorageHandler from "../../../projectSpecific/logic/storage/PersistentStorageHandler";

/**
 * Takes care of identifying the proper demo miniserver for the language and region set on this device.
 */
class DemoMsSource {
    static debugName = "DemoMsSource";

    static AwsFilePath = "https://app-content.loxone.com/demoMs.json";
    static CacheFileName = "demoMsList";
    static ResourcePath = "resources/misc/demoMs.json";

    static DefaultAuthUser = "web";
    static DefaultAuthPass = "web";
    static DefaultDemoMsUrl = "dns.loxonecloud.com/504F94A0DDD9";

    isDemoMiniserver(host) {
        const demoMsHost = this.getMiniserver().remoteUrl;
        let isDemoMs = host === demoMsHost;

        // With RC in place, the host will look like:
        //  168-119-185-175.504F94A0DDAA.dyndns.loxonecloud.com:49891
        //  2a01-4f8-1c1c-57c8--1.504f94a1709f.dyndns.loxonecloud.com:64635
        // while the remoteUrl from the MS object will look like this "dns.loxonecloud.com/504F94A0DDD9" --> adopt check!
        if (!isDemoMs && isResolvedRemoteConnectUrl(host) && isCloudDnsUrl(demoMsHost)) {
            const inputSnr = getSerialNo(host);
            const demoSnr = getSerialNo(demoMsHost);
            isDemoMs = !!inputSnr && !!demoSnr && inputSnr.toLowerCase() === demoSnr.toLowerCase();
        }

        return isDemoMs;
    }

    getMiniserver() {
        let result = this._demoMs;
        if (!result) { // default to us
            result = this._createMsForApp(); // creates a us demo ms with web/web if nothing is provided.
        }
        return result;
    }

    get defaultAuth() {
        return {
            activeUser: DemoMsSource.DefaultAuthUser,
            password: VendorHub.Crypto.encrypt(DemoMsSource.DefaultAuthPass)
        }
    }

    constructor(lang = "de-de") {
        const langParts = lang.toLowerCase().split("-");
        this._lang = langParts[0];
        this._langWithRegion = langParts.length > 1 ? langParts.join("-") : null;
        this._demoMsFile = null;

        this._resourceFileRequest = FileRequest.resource(DemoMsSource.ResourcePath);
        ResourceFileLoader.getFile(this._resourceFileRequest).then(result => {
            // only use the resource version if nothing was received from serverFileAccess yet.
            !this._demoMsFile &&  this._updateDemoMsFile(result);
        });

        this._cacheHandler = new CacheFileHandler(new PersistentStorageHandler());
        this._remoteFileRequest = FileRequest.remote(DemoMsSource.AwsFilePath, DataType.OBJECT);
        CachedServerFileLoader.getFile(this._remoteFileRequest, { cacheHandler: this._cacheHandler}).then(
            this._updateDemoMsFile.bind(this));
    }

    _updateDemoMsFile(fileRequest) {
        if (!fileRequest || !fileRequest.data) {
            return;
        }
        this._demoMsFile = fileRequest.data;
        this._lookupDemoMs();
    }

    /**
     * Will iterate over the demoMsFile loaded & return the best match.
     * @private
     */
    _lookupDemoMs() {
        let defaultEntry = this._getDefaultMiniserver();
        let matchingEntries;
        let matchingEntry;

        if (!this._demoMsFile || typeof this._demoMsFile !== "object") {
            console.warn(DemoMsSource.debugName, "failed to lookup demoMs from file, not an object!");
        } else {
            matchingEntries = this._demoMsFile.demoMiniservers.filter(
                this._doesMatchRegion.bind(this)
            );

            if (matchingEntries.length === 0) {
                matchingEntries = this._demoMsFile.demoMiniservers.filter(this._doesMatchLanguage.bind(this));
            }
            matchingEntry = matchingEntries.length > 0 ? matchingEntries[0] : null;
        }

        const chosen = matchingEntry || defaultEntry || {};
        this._demoMs = this._createMsForApp(chosen.remoteUrl, chosen.activeUser, chosen.password);
    }

    _createMsForApp(remoteUrl = DemoMsSource.DefaultDemoMsUrl, activeUser = DemoMsSource.DefaultAuthUser, password = DemoMsSource.DefaultAuthPass) {
        return {
            remoteUrl,
            activeUser,
            password: VendorHub.Crypto.encrypt(password)
        }
    }

    _doesMatchRegion(msEntry) {
        return this._langWithRegion && !!msEntry.languages.find(lang => lang === this._langWithRegion);
    }

    _doesMatchLanguage(msEntry) {
        return !!msEntry.languages.find(lang => lang === this._lang);
    }

    _getDefaultMiniserver() {
        return this._demoMsFile.demoMiniservers.find(msEntry => {
            return msEntry.lxId === this._demoMsFile.defaultDemoMsId;
        });
    }


}


export default DemoMsSource;
