'use strict';

import PairedAppEnums from "../pairedAppEnums";

ActiveMSComp.factory('RpcCommExt', function () {
    let weakThis;

    /**
     * List of all states that have to be known in order to work properly.
     * @type {(string)[]}
     */
    const REQUIRED_INFO_METHODS = [
        PairedAppEnums.RPCMethod.DND,
        PairedAppEnums.RPCMethod.PRESENCE,
        PairedAppEnums.RPCMethod.BRIGHTNESS
    ];

    function RpcCommExt(comp) {
        this.name = "RpcCommExt";
        this.comp = comp;
        weakThis = this
        
        this._pairingFile = null;
        this._rpcUnReg = null;
        this._rpcValues = null;
        this._rpcValueListeners = {};

        this.comp.on(CCEvent.PairedAppInfoReady, (ev, { isPaired, pairingInfo }) => {
            Debug.PairedApp && console.log(weakThis.name, "PairedAppInfoReady: paired=" + !!isPaired);
            if (isPaired) {
                this.pairingInfoReady(pairingInfo);
            } else {
                this.teardown();
            }
        });

        this.comp.on(CCEvent.UnpairedApp, (ev) => {
            Debug.PairedApp && console.log(weakThis.name, "UnpairedApp");
            this.teardown();
        });
    }

    RpcCommExt.prototype.hasRequiredInfos = function hasRequierdInfos() {
        if (this._rpcValues) {
            return REQUIRED_INFO_METHODS.every(method => this._rpcValues.hasOwnProperty(method));
        } else {
            return false;
        }
    }

    RpcCommExt.prototype.pairingInfoReady = function pairingInfoReady(pairingFile) {
        Debug.PairedApp && console.log(weakThis.name, "pairingInfoReady, deviceUuid=" + pairingFile.deviceUuid, pairingFile);

        this._pairingFile = pairingFile;
        this._rpcValues = {};
        this._rpcUnReg = CommunicationComponent.registerForJsonRPCMethod(
            weakThis.messageReceived.bind(this),
            [pairingFile.deviceUuid],
            true // also listen for the specific methods
        );
    };

    RpcCommExt.prototype.teardown = function teardown() {
        Debug.PairedApp && console.log(weakThis.name, "teardown");
        this._pairingFile = null;

        this._pairedAppBlockUuid = null;

        this._rpcUnReg && this._rpcUnReg();
        this._rpcUnReg = null;
    };

    RpcCommExt.prototype.registerForRpcValues = function registerForRpcValues(cb) {
        let listenerId;
        do {
            listenerId = "" + getRandomIntInclusive(0, 100000);
        } while (this._rpcValueListeners.hasOwnProperty(listenerId));

        this._rpcValueListeners[listenerId] = cb;

        return () => {
            delete this._rpcValueListeners[listenerId];
        }
    };
    
    RpcCommExt.prototype.messageReceived = function messageReceived(rpcMessage) {
        Debug.PairedApp && console.log(this.name, "messageReceived: " + rpcMessage.leafMethod, rpcMessage.params);
        if (rpcMessage.params) {
            this._rpcValues[rpcMessage.leafMethod] = rpcMessage.params;
        }
        this._notifyRpcValListeners(rpcMessage.leafMethod);
    }

    RpcCommExt.prototype._notifyRpcValListeners = function _notifyRpcValListeners(changedMethod) {
        Object.values(this._rpcValueListeners).forEach(cb => {
            cb(this._rpcValues, changedMethod);
        });
    }
    

    return RpcCommExt;
});
