/**
 * Created by gierlma on 07/03/14.
 */
"use strict";

export default ({names}) => {
    window[names.int].factory(names.int, ['$injector', 'PersistenceComp', 'CommunicationComp', 'PlatformComp', function ($injector, PersistenceComp, CommunicationComp, PlatformComp) {
        // required components and extensions are listed here
        var SERVER_URL = 'https://crm.loxone.com'; // Events available on the extensionChannel in this component

        var Cmds = {
            GetKey: '/api/v1/getkey/%d',
            // timestamp
            Login: '/api/v1/login/%s',
            // timestamp, loginData
            ResetPassword: '/api/v1/resetpassword/%s/%s',
            // email, language
            Registration: '/api/v1/registration' // - data is transmitted as post fields

        };
        let weakThis,
            extension = {},
            NewsHubExt,
            exposed,
            usedLang,
            currentUser;
        /**
         * c-tor for LxServer Component
         * @constructor
         */

        var LxServerCompFn = function () {
            weakThis = this
            BaseComponent.call(this, arguments);
            weakThis.sessionId = 0;
            usedLang = PlatformComp.getLanguage().substr(0, 2);
            NewsHubExt = new extension.NewsHub(this);
            currentUser = loadUserInfo();
            exposed = {
                getAvailableCountries: getAvailableCountries,
                getAvailableRoles: getAvailableRoles,
                getUserInfo: getUserInfo,
                login: login,
                logout: logout,
                register: register,
                resetPwd: resetPwd,
                isUserLoggedIn: isUserLoggedIn,
                checkNewsHub: NewsHubExt.checkNewsHub.bind(NewsHubExt),
                registerForNewsHubChanges: NewsHubExt.registerForNewsHubChanges.bind(NewsHubExt),
                getLatestNewsHubEntries: NewsHubExt.getLatestNewsHubEntries.bind(NewsHubExt),
                getNewsHubOpened: NewsHubExt.getNewsHubOpened.bind(NewsHubExt),
                setNewsHubOpened: NewsHubExt.setNewsHubOpened.bind(NewsHubExt),
                onNewsHubEntryOpened: NewsHubExt.onNewsHubEntryOpened.bind(NewsHubExt),
                hideNewsHubNotification: NewsHubExt.hideNewsHubNotification.bind(NewsHubExt),
                // TODO change when handling of LoxoneID is defined
                userLoggedIn: userLoggedIn
            };
            return exposed;
        };

        BaseComponent.beInheritedBy(LxServerCompFn);
        extension = BaseComponent.initExtensions('LxServerComp', $injector);
        /**
         * Broadcasts 'NewsChanged' event with the latest news
         * @param news latest news provided by the NewsHubExtension.
         */

        LxServerCompFn.prototype.onNewsReceived = function onNewsReceived(news) {
            weakThis.emit(LxServerComp.ECEvent.NewsChanged, news);
        };

        var getAvailableCountries = function () {
            return LoxoneAccountCoutries;
        };

        var getAvailableRoles = function () {
            return [_("luring.yes.register.role.homeOwner"), _("luring.yes.register.role.electrician"), _("luring.yes.register.role.heatingEng"), _("luring.yes.register.role.itTech"), _("luring.yes.register.role.consultant"), _("luring.yes.register.role.architect"), _("luring.yes.register.role.industry"), _("luring.yes.register.role.other")];
        };

        var login = function (email, password) {
            // user data from server is added upon successful login
            currentUser = {
                email: email,
                password: password
            };
            var loginData = encodeURIComponent(email + ":" + password);
            var request = Commands.format(Cmds.Login, loginData);
            return CommunicationComp.request(SERVER_URL + request).then(loginSuccessful.bind(this));
        };

        var loginSuccessful = function loginSuccessful(response) {
            var data = response.data; // data[0] is the crm id

            currentUser.name = data[1];
            currentUser.surName = data[2];
            currentUser.isLoggedIn = true; // let the app only work with the encrypted password

            currentUser.password = VendorHub.Crypto.encrypt(currentUser.password);
            saveUserInfo(currentUser);
            return currentUser;
        };

        var logout = function logout() {
            currentUser.isLoggedIn = false;
            delete currentUser.password;
            saveUserInfo(currentUser);
        };

        var register = function (userData) {
            var data = {
                salutation: userData.gender,
                first_name: userData.firstName,
                last_name: userData.surName,
                email_address: userData.email,
                password: userData.pwd,
                primary_address_country: userData.country,
                contact_type: userData.role,
                newsletter: userData.wantsNewsletter
            };
            var config = {
                method: 'POST',
                url: SERVER_URL + Cmds.Registration,
                data: data,
                headers: {
                    'Content-Type': AjaxContentType.UrlEncoded
                }
            };
            return CommunicationComp.request(SERVER_URL + Cmds.Registration, config);
        };

        var resetPwd = function (email) {
            var request = Commands.format(Cmds.ResetPassword, encodeURIComponent(email), usedLang);
            return CommunicationComp.request(SERVER_URL + request);
        };

        var getUserInfo = function getUserInfo() {
            var defaultUser = {
                name: 'User',
                avatarUrl: 'resources/Images/ActiveMiniserver/Menu/avatar.svg',
                isMale: true
            };
            return $.extend(true, {}, defaultUser, currentUser); // return only a copy, to prevent modifications
        };

        var isUserLoggedIn = function isUserLoggedIn() {
            return currentUser && currentUser.isLoggedIn;
        };
        /***
         * loads the stored user info either from local storage or the filesystem
         * @returns {*} a promise that will resolve once the file was loaded
         */


        var loadUserInfo = function loadUserInfo() {
            var info;

            try {
                var infoStr = PersistenceComp.setLocalStorageItem(UserOutlined.USER_INFO_KEY);
                if (infoStr) {
                    info = JSON.parse(infoStr); // decrypt the password only to login
                }

                return info || {};
            } catch (exc) {
                console.error('Error while parsing user info: ' + exc);
            }

            return {
                isLoggedIn: false
            };
        };

        var saveUserInfo = function saveUserInfo(userInfo) {
            PersistenceComp.setLocalStorageItem(UserOutlined.USER_INFO_KEY, JSON.stringify(userInfo));
        }; // TODO change when handling of LoxoneID is defined


        var userLoggedIn = function userLoggedIn() {
            PersistenceComp.removeLocalStorageItem(UserOutlined.USER_INFO_KEY);
            currentUser = {
                isLoggedIn: true
            };
            saveUserInfo(currentUser);
        };

        window[names.ext] = new LxServerCompFn();
        return window[names.ext];
    }]);
}
