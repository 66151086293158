'use strict';

define("SteakControlStateContainer", ["ControlStateContainer", "SteakControlEnums"], function (ControlStateContainer, SteakControlEnums) {
    return class SteakControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        /**
         * handles incoming values and prepares the states
         * @param newVals
         */
        prepareStates(newVals) {
            this.states.currentTemperatures = JSON.parse(newVals[this.control.states.currentTemperatures].text) || []; // Map the current temperatures to Fahrenheit

            this.states.currentTemperatures = this.states.currentTemperatures.map(function (temp) {
                return SandboxComponent.getTemperatureForUI(temp);
            });
            this.states.sensors = JSON.parse(newVals[this.control.states.sensorInfo].text) || {}; // make our life easier by adding and modifying some property which will be used later

            this.states.sensors.forEach(function (sensor, idx) {
                sensor.isLeft = idx === 0;
                sensor.idx = idx;
                sensor.targetTemperature = SandboxComponent.getTemperatureForUI(sensor.targetTemperature);
            });
            this.states.sensorAlarmTexts = JSON.parse(newVals[this.control.states.sensorAlarms].text) || [];
            this.states.activeAlarmText = newVals[this.control.states.activeAlarmText].text;
            this.states.deviceState = newVals[this.control.states.deviceState];
            this.states.displayAlwaysOnBat = !!newVals[this.control.states.displayAlwaysOnBat];
            this.states.displayAlwaysOnDc = !!newVals[this.control.states.displayAlwaysOnDc];
            this.states.availableControls = JSON.parse(newVals[this.control.states.availableControls].text);
            this.states.activeControl = newVals[this.control.states.activeControl];if (this.states.activeControl !== 0 && this.states.activeControl !== 1) {
                this.states.isActive = false;
            } else {
            this.states.isActive = !!newVals[this.control.states.isActive];}
            this.states.timerRemaining = newVals[this.control.states.timerRemaining];
            this.states.timerInfo = JSON.parse(newVals[this.control.states.timerInfo].text) || {};
            this.states.timerAlarm = JSON.parse(newVals[this.control.states.timerAlarm].text) || {};
            this.states.touchProtection = !!newVals[this.control.states.touchProtection];

            if (Feature.STEAK_DISPLAY_BRIGHTNESS) {
                this.states.displayBrightness = newVals[this.control.states.displayBrightness];
            } else {
                this.states.displayBrightness = 0;
            }

            if (Feature.STEAK_BATTERY_STATE) {
                this.states.powerMode = newVals[this.control.states.powerMode];
                this.states.batteryPercentage = newVals[this.control.states.batteryStateOfCharge];
            }
        }

        /**
         * Modify the statistic names at runtime
         * @returns {*}
         */
        getStatisticOutputs() {
            var allOutputs = [],
                i;

            try {
                allOutputs = this.control.statistic.outputs;

                for (i = 0; i < allOutputs.length; i++) {
                    allOutputs[i].name = this.states.sensors[i].name;
                }
            } catch (ex) {
                console.error("Could not determine statistic outputs!");
            }

            return allOutputs;
        }

        getStateText() {
            if (this.states.deviceState === SteakControlEnums.DeviceStates.OFFLINE) {
                return _("offline");
            } else if (!this.states.isActive) {
                return _('inactive');
            } else if (this.states.deviceState === SteakControlEnums.DeviceStates.SLEEPING) {
                return _("steak.sleeping-view.description", {
                    productName: _('steak.product-name')
                });
            }

            return NBR_SPACE; // return a non breaking space to override the current text
        }

        getStateTextShort() {
            if (this.states.deviceState === SteakControlEnums.DeviceStates.OFFLINE) {
                return _("offline");
            } else if (!this.states.isActive) {
                return _('inactive');
            } else if (this.states.deviceState === SteakControlEnums.DeviceStates.SLEEPING) {
                return _("steak.sleeping-view.description-short");
            }

            return NBR_SPACE; // return a non breaking space to override the current text
        }

        getStateIcon() {
            return Icon.Steak.GRILL;
        }

        getStateColor() {
            if (!this.states.isActive || this.states.deviceState === SteakControlEnums.DeviceStates.OFFLINE) {
                return Color.INACTIVE;
            } else if (this.states.isActive && this.states.timerInfo.active) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateIconSmall() {
            var isSensorActive = this.states.sensorAlarmTexts.filter(function (alarmText) {
                    return alarmText.ringing;
                }).length > 0,
                isTimerRunning = this.states.timerInfo.active,
                isTimerRinging = this.states.timerAlarm.ringing;

            if (this.states.deviceState === SteakControlEnums.DeviceStates.OFFLINE) {
                return;
            }

            if (!this.states.isActive) {
                return {
                    iconSrc: Icon.AlarmClock.SNOOZE,
                    color: window.Styles.colors.blue
                };
            } else if (isSensorActive) {
                return {
                    iconSrc: Icon.Steak.ALARM,
                    color: window.Styles.colors.orange
                };
            } else if (isTimerRunning) {
                return {
                    iconSrc: Icon.IRC.Temp.MANUAL,
                    color: isTimerRinging ? window.Styles.colors.orange : window.Styles.colors.stateActive
                };
            }
        }

        getStateTextColor() {
            return this.getStateColor();
        }

        getStateInfo() {
            var result = {}; // Do not show any status information if the device is offline

            if (this.states.deviceState !== SteakControlEnums.DeviceStates.OFFLINE) {
                if (!this.states.isActive) {
                    // caused by an miniserver issue, we have to check if the active control is a valid
                    if (this.states.activeControl === 0 || this.states.activeControl === 1) {var structureManager = ActiveMSComponent.getStructureManager(),
                        controlUuid = this.states.availableControls[this.states.activeControl].uuid,
                        activeControl = ActiveMSComponent.getStructureManager().getControlByUUID(controlUuid),
                        targetRoom = this.control.getRoom(),
                        roomUuid,
                        room; // if there is no active control, the current user doesn't have permissions for the other control

                    if (activeControl) {
                        roomUuid = activeControl.room;
                        room = structureManager.getGroupByUUID(roomUuid, GroupTypes.ROOM);

                            if (!room) {
                                room = {
                                    name: _("dont-use")
                                };
                            }

                            result = {
                                message: _("steak.already-in-use-with-target", {
                                    room: room.name,
                                    targetRoom: targetRoom.name,
                                    productName: _('steak.product-name')
                                })
                            };
                        } else {
                            result = {
                                message: _("steak.already-in-use-without-room", {
                                    targetRoom: targetRoom.name,
                                    productName: _('steak.product-name')
                                })
                            };
                        }
                    } else {
                        result = {
                            message: _("steak.already-in-use-without-room", {
                                targetRoom: this.control.getRoom().name,
                                productName: _('steak.product-name')
                            })
                        };
                    }
                } else if (this.states.activeAlarmText) {
                    result = {
                        message: this.states.activeAlarmText
                    };
                }

                return result;
            }
        }

    };
});
