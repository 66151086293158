'use strict';

define("PresenceDetectorControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class PresenceDetectorControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.activeSince = newVals[this.control.states.activeSince];
            this.states.active = newVals[this.control.states.active];
            this.states.locked = newVals[this.control.states.locked];
            this.states.infoText = newVals[this.control.states.infoText].text;
        }

        getStateText() {
            var text;

            if (this.states.locked) {
                text = _('controls.presence.locked');
            } else if (this.states.active) {
                text = _('controls.presence.active');
            } else {
                text = _('controls.presence.no-presence');
            }

            return text;
        }

        getStateIcon() {
            return Icon.Menu.PRESENCE;
        }

        getStateColor() {
            var color = null;

            if (this.states.active) {
                color = window.Styles.colors.stateActive;
            }

            return color;
        }

        getStateTextColor() {
            var color = null;

            if (this.states.locked) {
                color = window.Styles.colors.orange;
            } else if (this.states.active) {
                color = window.Styles.colors.stateActive;
            }

            return color;
        }

        getStateIconSmall() {
            var stateIconSmall = null;

            if (this.states.locked) {
                stateIconSmall = {
                    iconSrc: Icon.LOCK,
                    color: window.Styles.colors.orange
                };
            }

            return stateIconSmall;
        }

        getStateInfo() {
            var stateInfo;

            if (this.states.locked) {
                stateInfo = {
                    title: _('controls.presence.locked-reason', {
                        input: this.states.infoText
                    })
                };
            }

            return stateInfo;
        }

    };
});
