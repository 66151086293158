'use strict';

define("TimedSwitchControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class TimedSwitchControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.timeLeft = newVals[this.control.states.deactivationDelay];
            var totalTime = newVals[this.control.states.deactivationDelayTotal];

            if (this.states.timeLeft >= 0) {
                this.states.progress = this.states.timeLeft / totalTime;
            } else {
                this.states.progress = 0;
            }

            this.states.timerActive = this.states.timeLeft > 0;
            this.states.isOn = this.states.timeLeft === -1 || this.states.timerActive;
        }

        getStateText() {
            if (this.states.timerActive) {
                return LxDate.formatSecondsShort(this.states.timeLeft, false, true);
            } else  {
                return null; // no on/off status text
            }
        }

        getStateColor() {
            if (this.states.isOn || this.states.timerActive) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateIconSmall() {
            if (this.states.timerActive) {
                return {
                    iconSrc: Icon.TimedSwitch.ICON,
                    color: window.Styles.colors.stateActive
                };
            }
        }

    };
});
