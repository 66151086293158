'use strict';

define("VentilationControlStateContainer", ["ControlStateContainer", "VentilationControlEnums"], function (ControlStateContainer, VentilationControlEnums) {
    return class VentilationControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.ventReason = newVals[this.control.states.ventReason];
            this.states.activeTimerProfile = newVals[this.control.states.activeTimerProfile];
            this.states.stoppedBy = newVals[this.control.states.stoppedBy].text;
            this.states.overwriteUntil = newVals[this.control.states.overwriteUntil];
            this.states.controlInfo = JSON.parse(newVals[this.control.states.controlInfo].text) || {};
            this.states.speed = newVals[this.control.states.speed];
            this.states.mode = newVals[this.control.states.mode];
            this.states.presenceMin = newVals[this.control.states.presenceMin];
            this.states.presenceMax = newVals[this.control.states.presenceMax];
            this.states.absenceMin = newVals[this.control.states.absenceMin];
            this.states.absenceMax = newVals[this.control.states.absenceMax];
            this.states.humidityIndoor = newVals[this.control.states.humidityIndoor];
            this.states.humidityMax = newVals[this.control.states.humidityMax];
            this.states.presence = newVals[this.control.states.presence];
            this.states.temperatureIndoor = newVals[this.control.states.temperatureIndoor];
            this.states.temperatureOutdoor = newVals[this.control.states.temperatureOutdoor];
            this.states.temperatureTarget = newVals[this.control.states.temperatureTarget];
            this.states.airQualityIndoor = newVals[this.control.states.airQualityIndoor];
            this.states.airQualityMax = newVals[this.control.states.airQualityMax];
            this.states.heatExchanger = !!newVals[this.control.states.heatExchanger];
            this.states.temperatureSupport = this._getTemperatureSupport(newVals);
            this.states.frostProtectTemp = newVals[this.control.states.frostTemp];
        }

        getStateText(short) {
            var text = null;

            if (this.control.hasError() || this.control.hasWarning()) {
                if (short) {
                    if (this.states.controlInfo.title) {
                        text = this.states.controlInfo.title;
                    }

                    if (this.states.controlInfo.level === VentilationControlEnums.CONTROL_INFO.ERROR) {
                        text = _('error').capitalize();
                    } else if (this.states.controlInfo.level === VentilationControlEnums.CONTROL_INFO.WARNING) {
                        text = _('warning').capitalize();
                    }
                }
            } else {
                switch (this.states.ventReason) {
                    case VentilationControlEnums.VENT_REASONS.FALL_ASLEEP:
                        text = _('off');
                        break;

                    case VentilationControlEnums.VENT_REASONS.STOP:
                        text = _('controls.leaf.vent-mode-desc.stopped');
                        break;

                    case VentilationControlEnums.VENT_REASONS.FROST_PROTECTION: {
                        text = _('controls.leaf.vent-mode-desc.frost-protection.locked');
                        break;
                    }

                    default:
                        if (this.states.speed > 0) {
                            text = lxFormat("%.0f%%", this.states.speed) + " " + _("controls.leaf.settings.intensity");
                        } else {
                            text = _("off");
                        }

                }
            }

            return text;
        }

        getStateIcon() {
            return Icon.Sauna.AIRING;
        }

        getStateColor() {
            var color = null;

            if (!this.control.hasError() && !this.control.hasWarning()) {
                switch (this.states.ventReason) {
                    case VentilationControlEnums.VENT_REASONS.STOP:
                        color = window.Styles.colors.orange;
                        break;

                    default:
                        if (this.states.speed > 0) {
                            color = window.Styles.colors.stateActive;
                        } else {
                            color = null;
                        }

                }
            }

            return color;
        }

        getStateTextColor() {
            var color;

            switch (this.states.ventReason) {
                case VentilationControlEnums.VENT_REASONS.FROST_PROTECTION:
                    color = window.Styles.colors.red;
                    break;

                default:
                    color = this.getStateColor();
                    break;
            }

            return color;
        }

        getStateIconSmall() {
            var iconObj, tempSupport;

            if (this.control.hasError()) {
                return {
                    iconSrc: Icon.ERROR,
                    color: window.Styles.colors.red
                };
            } else if (this.control.hasWarning()) {
                return {
                    iconSrc: Icon.WARNING,
                    color: window.Styles.colors.orange
                };
            }

            iconObj = {
                color: this.getStateColor()
            };

            switch (this.states.ventReason) {
                case VentilationControlEnums.VENT_REASONS.BASE_VENTILATION:
                    if (!this.states.presence) {
                        iconObj = null;
                    } else {
                        iconObj.iconSrc = Icon.Menu.PRESENCE;
                    }

                    break;

                case VentilationControlEnums.VENT_REASONS.HIGH_HUMIDITY:
                    iconObj.iconSrc = Icon.Sauna.HUMIDITY;
                    break;

                case VentilationControlEnums.VENT_REASONS.AUTOMATIC_TEMP:
                    tempSupport = this.states.temperatureSupport;
                    iconObj.iconSrc = Icon.Sauna.TEMPERATURE;

                    if (tempSupport === VentilationControlEnums.TEMPERATURE_SUPPORT.COOLING) {
                        iconObj.color = window.Styles.colors.blue;
                    } else if (tempSupport === VentilationControlEnums.TEMPERATURE_SUPPORT.HEATING) {
                        iconObj.color = window.Styles.colors.orange;
                    }

                    break;

                case VentilationControlEnums.VENT_REASONS.BAD_CO2:
                    iconObj.iconSrc = Icon.Ventilation.CO2;
                    break;

                case VentilationControlEnums.VENT_REASONS.STOP:
                    iconObj.iconSrc = Icon.LOCK;
                    iconObj.color = window.Styles.colors.orange;
                    break;

                case VentilationControlEnums.VENT_REASONS.OPEN_WINDOW:
                    iconObj.iconSrc = Icon.WindowMonitor.WINDOW_MONITOR;
                    iconObj.color = window.Styles.colors.orange;
                    break;

                case VentilationControlEnums.VENT_REASONS.TURBO:
                    iconObj.iconSrc = Icon.Ventilation.TURBO;
                    break;

                case VentilationControlEnums.VENT_REASONS.MANUAL:
                    if (this.states.activeTimerProfile !== VentilationControlEnums.TIMER_PRESET.SETTINGS) {
                        iconObj.iconSrc = Icon.Daytimer.TIMER;
                    } else {
                        iconObj.iconSrc = Icon.SETTINGS;
                    }

                    break;

                case VentilationControlEnums.VENT_REASONS.EXHAUST_AIR:
                    iconObj.iconSrc = Icon.Ventilation.AIR_EXHAUST;
                    break;

                case VentilationControlEnums.VENT_REASONS.FALL_ASLEEP:
                    iconObj.iconSrc = Icon.AlarmClock.SNOOZE;
                    iconObj.color = window.Styles.colors.stateActive;
                    break;

                case VentilationControlEnums.VENT_REASONS.FROST_PROTECTION: {
                    iconObj.iconSrc = Icon.LOCK;
                    iconObj.color = window.Styles.colors.red;
                    break;
                }

                default:
                    iconObj = null;
            }

            return iconObj;
        }

        getStateInfo() {
            var stateInfo, title, color, tempSupport;

            if (this.control.hasError() || this.control.hasWarning()) {
                if (this.states.controlInfo.title) {
                    title = this.states.controlInfo.title;
                }

                if (this.states.controlInfo.level === VentilationControlEnums.CONTROL_INFO.ERROR) {
                    title = _('error').capitalize();
                    color = window.Styles.colors.red;
                } else if (this.states.controlInfo.level === VentilationControlEnums.CONTROL_INFO.WARNING) {
                    title = _('warning').capitalize();
                    color = window.Styles.colors.orange;
                }

                stateInfo = {
                    title: title,
                    message: this.states.controlInfo.desc,
                    color: color
                };
            } else {
                switch (this.states.ventReason) {
                    case VentilationControlEnums.VENT_REASONS.HIGH_HUMIDITY:
                        stateInfo = {
                            title: _('controls.leaf.settings.extended-ventilation'),
                            message: this._getHumidityAiringDescription()
                        };
                        break;

                    case VentilationControlEnums.VENT_REASONS.AUTOMATIC_TEMP:
                        tempSupport = this.states.temperatureSupport;

                        if (tempSupport !== VentilationControlEnums.TEMPERATURE_SUPPORT.NONE) {
                            stateInfo = {
                                title: _('controls.leaf.settings.extended-ventilation'),
                                message: _('controls.leaf.settings.temperature-support', {
                                    mode: this._getTempSupportString(tempSupport)
                                })
                            };
                        }

                        break;

                    case VentilationControlEnums.VENT_REASONS.BAD_CO2:
                        stateInfo = {
                            title: _('controls.leaf.settings.extended-ventilation'),
                            message: this._getCO2AiringDescription()
                        };
                        break;

                    case VentilationControlEnums.VENT_REASONS.STOP:
                        if (this.states.stoppedBy) {
                            stateInfo = {
                                message: this.states.stoppedBy
                            };
                        }

                        break;

                    case VentilationControlEnums.VENT_REASONS.OPEN_WINDOW:
                        stateInfo = {
                            message: _('iroomcontroller.openwindow')
                        };
                        break;

                    case VentilationControlEnums.VENT_REASONS.TURBO:
                        stateInfo = {
                            message: _('controls.leaf.vent-mode-desc.mode-active', {
                                mode: _('controls.leaf.vent-mode-desc.turbo')
                            })
                        };
                        break;

                    case VentilationControlEnums.VENT_REASONS.MANUAL:
                        if (this.states.activeTimerProfile === VentilationControlEnums.TIMER_PRESET.SETTINGS) {
                            stateInfo = {
                                message: _("controls.leaf.vent-mode-desc.settings-mode")
                            };
                        } else {
                            stateInfo = this._getManualStateInfo();
                        }

                        break;

                    case VentilationControlEnums.VENT_REASONS.EXHAUST_AIR:
                        stateInfo = {
                            message: _('controls.leaf.vent-mode-desc.mode-active', {
                                mode: _('controls.leaf.vent-mode-desc.air-exhaust')
                            })
                        };
                        break;

                    case VentilationControlEnums.VENT_REASONS.FALL_ASLEEP:
                        stateInfo = {
                            message: _('controls.leaf.vent-mode-desc.mode-active', {
                                mode: _('controls.leaf.vent-mode-desc.fall-asleep')
                            })
                        };
                        break;

                    case VentilationControlEnums.VENT_REASONS.FROST_PROTECTION:
                        stateInfo = {
                            title: _('controls.leaf.vent-mode-desc.frost-protection.title'),
                            message: _('controls.leaf.vent-mode-desc.frost-protection.message', {
                                frostTemp: this.states.frostProtectTemp + "°"
                            })
                        };
                        break;

                    default:
                        stateInfo = null;
                }
            }

            return stateInfo;
        }

        /**
         * Returns the state info for a manual presets
         * @returns {*}
         * @private
         */
        _getManualStateInfo() {
            var now = moment(),
                today = now.clone().startOf('day'),
                activeTime = moment.unix(this.states.overwriteUntil),
                isToday = activeTime.isSame(today, "d"),
                isThisYear = activeTime.isSame(today, "year"),
                // Just display the time if the timer ends today, display the date and time if not
                activeTimeFormated = activeTime.format(isToday ? DateType.TimeFormat : LxDate.getDateFormat(isThisYear ? DateType.DateTextAndTimeNoYear : DateType.DateTextAndTime)),
                modeName,
                timerName,
                stateInfo = null; // The ventilation is inactive if the speed is 0

            if (this.states.speed === 0) {
                stateInfo = {
                    message: _('controls.leaf.timer.inactive-until', {
                        time: activeTimeFormated
                    })
                };
            } else {
                modeName = this.control.getModeWithId(this.states.mode).name;

                if (this.states.activeTimerProfile === VentilationControlEnums.TIMER_PRESET.MANUAL) {
                    if (this.states.mode === VentilationControlEnums.MODES.HEAT_EXCHANGER_ON || this.states.mode === VentilationControlEnums.MODES.HEAT_EXCHANGER_OFF) {
                        stateInfo = {
                            message: _('controls.leaf.timer.timer-active-until', {
                                time: activeTimeFormated
                            })
                        };
                    } else {
                        stateInfo = {
                            message: _('controls.leaf.timer.active-until', {
                                type: modeName,
                                time: activeTimeFormated
                            })
                        };
                    }
                } else {
                    timerName = this.control.details.timerProfiles[this.states.activeTimerProfile].name;
                    stateInfo = {
                        message: _('controls.leaf.timer.active-until', {
                            type: timerName,
                            time: activeTimeFormated
                        })
                    };
                }
            }

            return stateInfo;
        }

        /**
         * Returns the temperatureSupport from the miniserver if available
         * Fallback: Calculates the temperatureSupport by using the indoor- outdoor- and target-temperature (Used for older Miniserver versions)
         * @note The temperatureSupport state has been introduced later for convenience and to prevent and async behaviour if the algorithm changes on either side
         * @param newVals
         * @return {number}
         * @private
         */
        _getTemperatureSupport(newVals) {
            var tempSupport = VentilationControlEnums.TEMPERATURE_SUPPORT.NONE; // This state has been introduced later, not all VentilationControls will have it
            // Check, if the state is available and use it, calculate the temperatureSupport the old way was a fallback

            if (this.control.states.temperatureSupport) {
                tempSupport = newVals[this.control.states.temperatureSupport];
            } else {
                if (this.states.ventReason === VentilationControlEnums.VENT_REASONS.AUTOMATIC_TEMP) {
                    if (this.states.temperatureIndoor > this.states.temperatureTarget && this.states.temperatureOutdoor < this.states.temperatureIndoor) {
                        tempSupport = VentilationControlEnums.TEMPERATURE_SUPPORT.COOLING;
                    } else if (this.states.temperatureTarget > this.states.temperatureIndoor && this.states.temperatureOutdoor > this.states.temperatureIndoor) {
                        tempSupport = VentilationControlEnums.TEMPERATURE_SUPPORT.HEATING;
                    }
                }
            }

            return tempSupport;
        }

        _getTempSupportString(tempSupport) {
            var tempSupportString = "";

            switch (tempSupport) {
                case VentilationControlEnums.TEMPERATURE_SUPPORT.COOLING:
                    tempSupportString = _('controls.irc.cooling');
                    break;

                case VentilationControlEnums.TEMPERATURE_SUPPORT.HEATING:
                    tempSupportString = _('controls.irc.heating');
            }

            return tempSupportString;
        }

        _getCO2AiringDescription() {
            var description = "",
                ppmTarget = this.states.airQualityMax;

            if (this.states.airQualityIndoor >= ppmTarget) {
                description = _("controls.ventilation.cause.airquality", {
                    limit: this.control.formatCO2(ppmTarget)
                });
            } else {
                ppmTarget = this.states.airQualityMax * VentilationControlEnums.Hysteresis.CO2_FACTOR;
                description = _("controls.ventilation.cause.airquality-overrun", {
                    safetyLimit: this.control.formatCO2(ppmTarget)
                });
            }

            return description;
        }

        _getHumidityAiringDescription() {
            var description = "",
                humidityTarget = this.states.humidityMax;

            if (this.states.humidityIndoor >= humidityTarget) {
                description = _("controls.ventilation.cause.high-humidity", {
                    limit: this.control.formatHumidity(humidityTarget)
                });
            } else {
                humidityTarget = this.states.humidityMax - VentilationControlEnums.Hysteresis.HUMIDITY_OFFSET;
                description = _("controls.ventilation.cause.high-humidity-overrun", {
                    safetyLimit: this.control.formatHumidity(humidityTarget)
                });
            }

            return description;
        }

    };
});
