'use strict';

ActiveMSComp.factory('DeviceLearningExt', function () {
    // internal variables
    var activeMsComp = {},
        extensionSrnForSearch,
        DEVICE_JSON_MODIFY_DATE = "deviceJsonHeadData",
        DEVICE_JSON_STRUCTURE = "deviceStructureJson";

    const DEVICE_SEARCH_ID_KEY = "deviceSearchId";


    class DeviceSearchIdentifyAssistant {
        constructor(sendFn) {
            this.name = "DeviceSearchIdentifyAssistant#"+getRandomIntInclusive(0,100);
            Debug.DeviceSearch.Identify && console.log(this.name, "+CTOR");
            this._sendFn = sendFn;
            this._deviceIdentifyInfo = null;
            this._groupIdentifyUuid = null;
        }

        destroy() {
            Debug.DeviceSearch.Identify && console.log(this.name, "-DESTROY");
            this.stopDeviceIdentify();
            this.stopLightGroupIdentify();
        }

        _send(cmd) {
            Debug.DeviceSearch.Identify && console.log(this.name, "_send: " + cmd);
            return this._sendFn(cmd).then(res => {
                Debug.DeviceSearch.Identify && console.log(this.name, "_send: DONE " + cmd);
                return res;
            }, err => {
                console.error(this.name, "_send: FAILED " + cmd + " -> " + JSON.stringify(err));
                return Q.reject(err);
            });
        }

        _hasDeviceIdentify() {
            return !!this._deviceIdentifyInfo;
        }

        _hasLightGroupIdentify() {
            return !!this._groupIdentifyUuid;
        }

        identifyDevice(extensionSerial, deviceSerial) {
            if (!extensionSerial || !deviceSerial) {
                return Q.reject("identifyDevice won't work without specifying both an ext ("+ extensionSerial +") " +
                    "and a device (" + deviceSerial + ")!");
            }
            this._deviceIdentifyInfo = { extensionSerial, deviceSerial };

            if (this._hasLightGroupIdentify()) {
                Debug.DeviceSearch.Identify && console.log(this.name, "identifyDevice: PARKED: " + extensionSerial + "/" + deviceSerial + " --> group identify active! " + this._groupIdentifyUuid);
                return Q.resolve();
            }

            Debug.DeviceSearch.Identify && console.log(this.name, "identifyDevice: " + extensionSerial + "/" + deviceSerial);
            const cmd = Commands.format(Commands.DEVICE_MANAGEMENT.START_IDENTIFY, Feature.GENERIC_DEVICE_SEARCH ? cleanSerialNumber(extensionSerial): extensionSerial, Feature.GENERIC_DEVICE_SEARCH ? cleanSerialNumber(deviceSerial) : deviceSerial);
            return this._send(cmd).then((res) => {
                Debug.DeviceSearch.Identify && console.log(this.name, "identifyDevice > STARTED: " + extensionSerial + "/" + deviceSerial);
                return res;
            }, (err) => {
                console.error(this.name, "identifyDevice > FAILED: " + extensionSerial + "/" + deviceSerial, JSON.stringify(err));
                return Q.reject(err);
            });
        }

        identifyLightGroup(groupUuid) {
            if (!groupUuid) {
                return Q.reject("identifyLightGroup won't work without specifying a group uuid!");
            }

            let prepPrms = Q.resolve();
            if (this._hasDeviceIdentify() && !Feature.GROUP_IDENTIFY_STOPS_DEVICE_IDENTIFY) {
                // individual device identifies are not stopped before MS-FW 6.12.2022. After that, the MS stops it.
                prepPrms = this._send(Commands.format(Commands.DEVICE_MANAGEMENT.STOP_IDENTIFY, this._deviceIdentifyInfo.extensionSerial));
                // don't use stopDeviceIdentify - it may need to be resumed, stop unsets the deviceIdentifyInfo.
            }

            Debug.DeviceSearch.Identify && console.log(this.name, "identifyLightGroup: " + groupUuid);
            this._groupIdentifyUuid = groupUuid;
            return prepPrms.then(() => {
                return this._send(Commands.format(Commands.DEVICE_MANAGEMENT.IDENTIFY_LIGHTGROUP, groupUuid));
            });
        }

        stopDeviceIdentify() {
            if (!this._hasDeviceIdentify()) {
                Debug.DeviceSearch.Identify && console.log(this.name, "stopDeviceIdentify: not active, DONE");
                // requested to stop a specific device identify, but that one is currently not active.
                return Q.resolve();
            }
            Debug.DeviceSearch.Identify && console.log(this.name, "stopDeviceIdentify: " + JSON.stringify(this._deviceIdentifyInfo));
            const cmd = Commands.format(Commands.DEVICE_MANAGEMENT.STOP_IDENTIFY, this._deviceIdentifyInfo.extensionSerial);
            this._deviceIdentifyInfo = null;
            if (this._hasLightGroupIdentify()) {
                Debug.DeviceSearch.Identify && console.log(this.name, "stopDeviceIdentify: DONE, light group active, nothing to do");
                return Q.resolve(); // no need to stop, lightgroup identify already stops device identify
            } else {
                return this._send(cmd);
            }
        }

        stopLightGroupIdentify() {
            if (!this._hasLightGroupIdentify()) {
                Debug.DeviceSearch.Identify && console.log(this.name, "stopLightGroupIdentify: not active, DONE");
                return Q.resolve();
            }
            Debug.DeviceSearch.Identify && console.log(this.name, "stopLightGroupIdentify: " + this._groupIdentifyUuid);
            const cmd = Commands.format(Commands.DEVICE_MANAGEMENT.STOP_IDENTIFY_UNIVERSAL);
            this._groupIdentifyUuid = null;
            return this._send(cmd).finally(() => {
                if (this._hasDeviceIdentify()) {
                    Debug.DeviceSearch.Identify && console.log(this.name, "stopLightGroupIdentify: DONE, restart device identify!");
                    this.identifyDevice(this._deviceIdentifyInfo.extensionSerial, this._deviceIdentifyInfo.deviceSerial);
                }
            });
        }

    }

    function DeviceLearningExt(comp) {
        activeMsComp = comp;
        this.name = "DeviceLearningExt";
        this.oldResultsList = [];
        this._deviceIdentifier = false; // will be retrieved from storage if needed.
        this.resultsList = [];
        this.deviceCounter = 0;
        this._registeredScreensForDeviceCounterChagned = [];
        this._registeredSearchListeners = {};
        this._lightGroups = [];
        this._lightGroupsChangedRegistrations = {};
        this._switchboardsChangedRegistrations = {};
        this._registeredScreensForSearchingStateChagned = [];
        this._lastPairedDeviceSerial; // keeps track of the last successfully paired serial.
        this.availableMiniservers = [];
        this._techtags = [];
        this._supportedGenericTypes = [];
        this._extensionListeners = {};
        this._parkedSearchType = {};

        this.imageObj = {};
        CompChannel.on(CCEvent.Pause, function () {
            Debug.DeviceSearch.General && console.log(this.name, "CCEvent.Pause --> stop keepalive!");
            // Stop the search when closing the app This prevents the keepalive from queueing up in the background
            this.stopKeepalive();
        }.bind(this));
        CompChannel.on(CCEvent.ConnClosed, function () {
            Debug.DeviceSearch.General && console.log(this.name, "CCEvent.ConnClosed --> stop keepalive!");
            this.stopKeepalive();
            this._resetTechTagList();
        }.bind(this));
        CompChannel.on(CCEvent.CrucialDataLoaded, () => {
            Debug.DeviceSearch.General && console.log(this.name, "CCEvent.CrucialDataLoaded --> if still searching, resume keepalive! isSearching=" + this.isSearching);
            this.isSearching && this.startKeepalive(true);
        });
    }

    DeviceLearningExt.prototype.KEEPALIVE_TIMEOUT_IN_SECONDS = 10; // 1 Minute

    DeviceLearningExt.prototype.getRequiredDeviceSearchPermissions = function getRequiredDeviceSearchPermissions() {
        return [ MsPermission.DEVICE_MANAGEMENT, MsPermission.EXPERT_MODE ];
    }

    DeviceLearningExt.prototype.prepareForDeviceSearch = function prepareForDeviceSearch(newSearchType, miniserver) {
        let id = this.getDeviceIdentifier();
        if(newSearchType === DeviceManagement.TYPE.TECH_SELECTION && Feature.GENERIC_DEVICE_SEARCH) {
            console.warn(this.name, "prepareForDeviceSearch called with TECH_SELECTION, this is a valid search type for Generic Device Search! ID=" + id, getStackObj());
            return id;
        }
        if (!newSearchType) {
            console.warn(this.name, "prepareForDeviceSearch called without search type! ID=" + id, getStackObj());
            return id;
        }
        if (this._deviceIdentifyAssistant) {
            this._deviceIdentifyAssistant.destroy();
        }
        this._deviceIdentifyAssistant = new DeviceSearchIdentifyAssistant(this._send.bind(this));

        Debug.DeviceSearch.General && console.log(this.name, "prepareForDeviceSearch, type=" + newSearchType + ", ID=" + id);

        // Ensures extensionLists from previous device search sessions are updated
        let currentMsSerial = ActiveMSComponent.getActiveMiniserverSerial();
        if (this._activeMsSerial !== currentMsSerial || this.searchType !== newSearchType) {
            if (this._activeMsSerial !== currentMsSerial) {
                Debug.DeviceSearch.General && console.log(this.name, "    reset extensions, MS changed, previous=" + this._activeMsSerial);
            } else {
                Debug.DeviceSearch.General && console.log(this.name, "    reset extensions, searchType cahnged, previous=" + this.searchType);
            }

            this._activeMsSerial = currentMsSerial;
            this._resetExtensionList();
            this._resetTechTagList();
        } else { // MS didn't change download the new list, but keep the old one.
            this.canShowFoundDevices(newSearchType) && this._resetFoundDeviceNumbers();
            this._downloadAvailableExtensions(miniserver);
        }

        this._resetLightGroupList();
        this._prepareDevicesJSON();


        return id;
    };
    /**
     * Returns the location of the device in the correct format
     * if place !== "" the function returns room-name • place
     * if place === "" the function only returns the room-name
     * @param place
     * @param roomUuid
     * @returns {string}
     * @private
     */


    DeviceLearningExt.prototype.getDeviceLocation = function getDeviceLocation(place, roomUuid) {
        var text, roomObj;
        roomObj = ActiveMSComponent.getStructureManager().getGroupByUUID(roomUuid, GroupTypes.ROOM);

        if (!place) {
            text = roomObj ? roomObj.name : _('not-used');
        } else if (!roomUuid) {
            text = place;
        } else {
            text = roomObj ? roomObj.name : _('not-used') + SEPARATOR_SYMBOL + place;
        }

        return text;
    };
    /**
     * Returns the devices installation place or switchboard position.
     * @param device
     * @returns {string}    "Verteilerschrank R1 P5" or "Decke Fenster"
     */


    DeviceLearningExt.prototype.getDeviceMountingLocation = function getDeviceMountingLocation(device) {
        var identification = "";

        if (device.switchboard) {
            identification = device.switchboard; // while extensions have the row & position in the switchboard attribute, other devices have them as separate
            // attributes.

            if (device.hasOwnProperty("row")) {
                identification += " R" + sprintf("%02d", device.row);
            }

            if (device.hasOwnProperty("column")) {
                identification += " P" + sprintf("%02d", device.column);
            }
        } else if (device.place) {
            identification = device.place;
        }

        return identification;
    };

    DeviceLearningExt.prototype.getExtensionName = function getExtensionName(extension, branchId = null) {
        let extName = nullEmptyString(extension.description) || extension.name;
        let mountingLoc = this.getDeviceMountingLocation(extension);
        let branchName;
        if (branchId !== null && extension.subCaptions) {
            extension.subCaptions.some((subCap) => {
                if (("" + subCap.id) === ("" + branchId)) {
                    branchName = subCap.name;
                    return true;
                }
                return false;
            })
        }
        if (branchName) {
            return branchName + " (" + extName + ", " + mountingLoc + ")";
        } else {
            return extName + " (" + mountingLoc + ")"
        }
    }

    DeviceLearningExt.prototype.getMaxNumDevices = function getMaxNumDevices(extension, branchId = null) {
        let maxNumDevices = -1;
        if (branchId !== null && extension.subCaptions) {
            extension.subCaptions.some((subCap) => {
                if (("" + subCap.id) === ("" + branchId)) {
                    maxNumDevices = subCap.hasOwnProperty("maxDevices") ? subCap.maxDevices : -1;
                    return true;
                }
                return false;
            });
        }
        if (maxNumDevices < 0 && extension.hasOwnProperty("maxDevices")) {
            maxNumDevices = extension.maxDevices;
        }
        if (maxNumDevices < 0) {
            maxNumDevices = this.searchType === DeviceManagement.TYPE.AIR ? 128 : (this.searchType === DeviceManagment.TYPE.TREE ? 50 : -1);
        }
        return maxNumDevices;
    }

    /**
     * This function returns an array of all devices with the same device type as the selected
     * @param list      List of all available devices
     * @param newDevice Device was what found in search
     * @returns {*}
     */


    DeviceLearningExt.prototype.removeOtherDevices = function removeOtherDevices(list, newDevice) {
        return list.filter(function (device) {
            return device.type === newDevice.type;
        }.bind(this));
    };

    DeviceLearningExt.prototype.isSupportedExtensionType = function isSupportedExtensionType(type) {
        if(Feature.GENERIC_DEVICE_SEARCH) {
            return this._supportedGenericTypes.indexOf(type) !== -1;
        }
        var isSupported;

        switch (this.searchType) {
            case DeviceManagement.TYPE.TREE:
                isSupported = Object.values(DeviceManagement.TREE_EXTENSIONS).indexOf(type) !== -1;
                break;

            case DeviceManagement.TYPE.AIR:
                isSupported = Object.values(DeviceManagement.AIR_EXTENSIONS).indexOf(type) !== -1;
                break;

            default:
                isSupported = false;
                break;
        }

        return isSupported;
    };

    DeviceLearningExt.prototype.loadRoomFileFromLocalStorage = function loadRoomFileFromLocalStorage(fileName) {
        return PersistenceComponent.loadFile(fileName, DataType.OBJECT).then(function (data) {
            return data;
        }.bind(this));
    };
    /**
     * @param searchParam extension serialNumber or searchType (tree)
     * @return {*}
     */


    DeviceLearningExt.prototype.startSearch = function startSearch(searchParam) {
        if (this._stopSearchPromise && this._stopSearchPromise.isPending()) {
            Debug.DeviceSearch.General && console.log(this.name, "startSearch for extension " + searchParam + " --> stop search pending!");
            return this._stopSearchPromise.finally(() => {
                Debug.DeviceSearch.General && console.log(this.name, "startSearch for extension " + searchParam + " --> stop search resolved!");
                return this.startSearch(searchParam);
            });
        }
        var cmd,
            deviceId = this.getDeviceIdentifier();
        extensionSrnForSearch = searchParam; // Also clear the search results when starting the search

        this.resultsList = [];
        Debug.DeviceSearch.General && console.log(this.name, "startSearch for extension " + searchParam + " and deviceId " + deviceId);
        const [msSnr, extSnr] = searchParam.split("|");
        cmd = Commands.format(Commands.DEVICE_MANAGEMENT.START_SEARCH_ON_EXTENSION, [cleanSerialNumber(msSnr), cleanSerialNumber(extSnr)].filter(Boolean).join("|"), deviceId);
        this.startKeepalive();
        this._setSearchingState(true);
        this.registerForStateChange();
        return this._send(cmd).then(res => {
            Debug.DeviceSearch.General && console.log(this.name, "startSearch for extension " + searchParam + " and deviceId " + deviceId + " >> responded!");
            this._searchStarted = true;

            // check if a searchResults have been returned asap
            if (this.searchType === DeviceManagement.TYPE.TREE) {
                // air has no initial devices response, hence avoid handleStartResponse.
                this.handleStartResponse(res);
            }
            this._notifyRegisteredScreensForSearchingStateChanged(true);
            return res;
        });
    };

    DeviceLearningExt.prototype.switchToSearchableChild = function switchToSearchableChild(newTag, extension) {
        this._parkedSearchType[this.searchType] = { // here searchType is the parent eg. "treeturbo"
            parkedBy: newTag,
            extension,
        };
        
        return this.stopSearch(false).then(() => this._deviceIdentifyAssistant.destroy()).then(() => {
            this._deviceIdentifyAssistant = null;
            const newSearchId = this.prepareForDeviceSearch(newTag);
            Debug.DeviceSearch.TechSwitching && console.log(this.name, "switchToSearchableChild: " + newTag + " for " + extension.serialNr);
            this.setSearchType(newTag);
            return newSearchId;
        });
    };

    DeviceLearningExt.prototype.switchToSearchableParent = function switchToSearchableParent(currentSearchType) {
        let parkedTag = this._parkedSearchType[currentSearchType];
        if(parkedTag) {
            this.setSearchType(currentSearchType);
            Debug.DeviceSearch.TechSwitching && console.log(this.name, "switchToSearchableParent: " + currentSearchType + " for " + parkedTag.extension);
            this._serialWhenKicked = parkedTag.extension.extension;
            return this.stopSearch(false).then(() => this._deviceIdentifyAssistant.destroy()).then(() => {                
                this.startSearch(parkedTag.extension.requestTarget);
                delete this._parkedSearchType[currentSearchType];
                return Q.resolve(parkedTag);
            });
        }
        return Q.reject("No parked search found for " + currentSearchType);
    };

    DeviceLearningExt.prototype.restartSearch = function restartSearch() {
        this.isRestarting = true;

        let promise;
        if (this.isSearching) {
            Debug.DeviceSearch.General && console.log(this.name, "restartSearch: restarting with target " + extensionSrnForSearch);
            let ext = extensionSrnForSearch;
            promise = this.stopSearch(false).then(this.startSearch.bind(this, ext));

        } else if (this._serialWhenKicked) {
            Debug.DeviceSearch.General && console.log(this.name, "restartSearch: was kicked while searching " + this._serialWhenKicked);
            promise = this.startSearch(this._serialWhenKicked);

        } else {
            console.error(this.name, "restartSearch: cannot restart without having a serial provided!");
            promise = Q.reject("Cannot restart, no serial stored to return to!");
        }

        return promise.finally(() => {
            // add a delay, to avoid having a brief "stopped" before being actually started (socket event from ms)
            setTimeout(() => {
                this.isRestarting = false;
                this._notifyRegisteredScreensForSearchingStateChanged(this.isSearching, false);
            }, 400);
        });
    };

    /**
     * Since MS v13.1.11.16 already known search results are returned asap when the search is started.
     * @param response
     */
    DeviceLearningExt.prototype.handleStartResponse = function handleStartResponse(response) {
        let devices = null;
        try {
            devices = getLxResponseValue(response);
            // even on tree device search, the search start response doesn't always contain the results.
            if (devices && Array.isArray(devices) && devices.length > 0) {
                this.deviceCounter = devices.length;
                this.resultsList = devices;
                this._sanitizeSearchResults(this.resultsList);
                Debug.DeviceSearch.General && console.log(this.name, "handleStartResponse > got " + this.deviceCounter + " devices");
                this._notifyRegisteredScreensForDeviceCounterChanged(this.resultsList)
            } else {
                Debug.DeviceSearch.General && console.log(this.name, "handleStartResponse > got no devices!");
            }
        } catch (ex) {
            Debug.DeviceSearch.General && console.log(this.name, "handleStartResponse > got no devices, failed to parse", ex);
            // okay, maybe no results where known yet!
        }

        Debug.DeviceSearch.General && console.log(this.name, "handleStartResponse: " + this.deviceCounter);
    }

    DeviceLearningExt.prototype.stopSearch = function stopSearch(hasBeenKicked) {
        Debug.DeviceSearch.General && console.log(this.name, "stopSearch (kicked=" + hasBeenKicked + ")", getStack().join("\n"));
        // Don't stop the search if the current search session has been kicked
        // sending the command in this case will also stop the search session of the other client!
        let extSerialCache = extensionSrnForSearch;
        extensionSrnForSearch = null;
        this.resultsList = [];
        this.unregisterStateChange();

        if (hasBeenKicked) {
            Debug.DeviceSearch.General && console.log(this.name, "User has been kicked, set searching to false");
            if (this._searchStarted && extSerialCache) {
                Debug.DeviceSearch.General && console.log(this.name, "Store extension serial to quickly restart search if required: " + extSerialCache);
                this._serialWhenKicked = extSerialCache;
            }
            this._setSearchingState(false);
        }
        this._searchStarted = false;
        this.stopKeepalive();

        this.deviceCounter = 0;
        if (this._stopSearchPromise) {
            Debug.DeviceSearch.General && console.log(this.name, "    search is already being stopped, return promise!");
            return this._stopSearchPromise;

        } else if (this.isSearching) {
            var cmd = Commands.format(Commands.DEVICE_MANAGEMENT.STOP_SEARCH_ON_EXTENSION);
            this._setSearchingState(false);
            this._stopSearchPromise = this._send(cmd, true);

        } else {
            Debug.DeviceSearch.General && console.log(this.name, "    is not searching, no need to send stop command");
            this._stopSearchPromise = Q(true);
        }
        return this._stopSearchPromise.finally(() => {
            this._stopSearchPromise = null;
        });
    };

    DeviceLearningExt.prototype.getResultsFromMS = function getResultsFromMS(useFakeResult) {
        if (this._pendingResultsPromise) {
            return this._pendingResultsPromise;
        }
        this._pendingResultsPromise = this._send(Commands.DEVICE_MANAGEMENT.GET_RESULTS).then((results) => {
            Debug.DeviceSearch.General && console.log(this.name, "getResultsFromMS: responded");
            this.resultsList = getLxResponseValue(results);
            this.deviceCounter = this.resultsList.length;
            Debug.DeviceSearch.General && console.log(this.name, "             old = " + this.oldResultsList.length + " --> new = " + this.resultsList.length);

            // add a request-target which can tell apart the internal branches of compacts in Client-GW-Installations
            this._sanitizeSearchResults(this.resultsList);

            this._notifyRegisteredScreensForDeviceCounterChanged(this.resultsList);

            return this.resultsList;
        }).finally(() => {
            this._pendingResultsPromise = null;
        });
        return this._pendingResultsPromise;
    };

    /**
     * add a request-target which can tell apart the internal branches of compacts in Client-GW-Installations
     * @param results
     * @private
     */
    DeviceLearningExt.prototype._sanitizeSearchResults = function _sanitizeSearchResults(results) {
        results.forEach(foundDevice => {
            if(Feature.DEVICE_SEARCH_HARDWARE_VARIANTS) { // only add the variant if there is an image for it - Miniserver adds hwType to all devices even if there's only one variant...
                const variantType = foundDevice.type + "-" + foundDevice.hwType;
                const hasVariantImage = this.getImageUrlWithDeviceType(variantType) !== this._getDefaultIconForSearchTyp();
                if(hasVariantImage) {
                    foundDevice.type = foundDevice.type + "-" + foundDevice.hwType;
                }
            }
            if (Feature.DEV_SEARCH_CLIENT_SPECIFICATION && foundDevice.msSerial && foundDevice.extension) {
                let adoptedMsSerial = this._sanitizeMsSerialForSearch(foundDevice.msSerial);
                foundDevice.requestTarget = adoptedMsSerial + "|" + foundDevice.extension;
            } else {
                foundDevice.requestTarget = foundDevice.extension;
            }
        });
    }

    DeviceLearningExt.prototype.registerForStateChange = function registerForStateChange() {
        SandboxComponent.registerForStateChangesForUUID(GLOBAL_UUID, this, this.handleStateChanged.bind(this));
    };

    DeviceLearningExt.prototype.getSearchingState = function getDeviceSearchSearchingState() {
        return this.isSearching;
    };
    DeviceLearningExt.prototype._setSearchingState = function _setSearchingState(newState) {
        if (newState !== this.isSearching) {
            Debug.DeviceSearch.General && console.log(this.name, "_setSearchingState: " + newState, getStack().join("\n"));
        }
        this.isSearching = newState;
    };

    DeviceLearningExt.prototype.getCurrentDeviceList = function getCurrentDeviceList() {
        return this.resultsList;
    };

    DeviceLearningExt.prototype.unregisterStateChange = function unregisterStateChange() {
        SandboxComponent.unregisterForStateChangesForUUID(GLOBAL_UUID, this);
    };

    DeviceLearningExt.prototype.stopDeviceIdentify = function stopDeviceIdentify() {
        return this._deviceIdentifyAssistant.stopDeviceIdentify();
    };

    DeviceLearningExt.prototype.setDeviceIdentify = function setDeviceIdentify(requestTarget, deviceSerial) {
        if(!this._deviceIdentifyAssistant) {
            Debug.DeviceSearch.Identify && console.log(this.name, "setDeviceIdentify: no assistant, create new one!");
            this._deviceIdentifyAssistant = new DeviceSearchIdentifyAssistant(this._send.bind(this));
        }
        if (deviceSerial) {
            return this._deviceIdentifyAssistant.identifyDevice(requestTarget, deviceSerial);
        } else {
            return this._deviceIdentifyAssistant.stopDeviceIdentify();
        }
    };

    DeviceLearningExt.prototype.startLightGroupIdentify = function startLightGroupIdentify(groupUuid) {
        return this._deviceIdentifyAssistant.identifyLightGroup(groupUuid);
    }
    DeviceLearningExt.prototype.stopLightGroupIdentify = function stopLightGroupIdentify() {
        return this._deviceIdentifyAssistant.stopLightGroupIdentify();
    }

    /**
     * Either a list of all devices on all extensions, or only certain types.
     * @param deviceType
     * @returns {*}
     */
    DeviceLearningExt.prototype.getDevicesOfExtensions =
        function getDevicesOfExtensions(
            deviceType = false,
            miniserver = null,
            extension = null,
        ) {
            var devices = [],
                generationDeviceTypes =
                    DeviceManagement.GENERATION_DEVICE_TYPES.find(function (
                        types,
                    ) {
                        return types.includes(deviceType);
                    }) || [deviceType];
            if (Feature.GENERIC_DEVICE_SEARCH) {
                if(this._pendingDevsOfExtension) {
                    Debug.DeviceSearch.General && console.log(this.name, "getDevicesOfExtensions - already requesting, return promise!");
                    return this._pendingDevsOfExtension;
                }
                const cmd = Commands.format(
                    Commands.DEVICE_MANAGEMENT
                        .GET_AVAILABLE_DEVICES_FOR_EXTENSION,
                    typeof miniserver === 'object' ? miniserver.serialNr : miniserver,
                    this.searchType,
                    this.searchType === DeviceManagement.TYPE.TREE
                        ? ''
                        : extension.serialNr,
                );
                this._pendingDevsOfExtension = this._send(
                    cmd,
                    false,
                    true,
                    { noLLResponse: true },
                ).then((res) => {
                    if (this.searchType === DeviceManagement.TYPE.TREE) { // tree has different behaviour as Dummy on Ext B will be moved to Ext A when replaced so we flatmap over all MS's and extensions, not just the first one
                        return res.flatMap(({ techtags: [{devices: extensions}] }) =>
                            extensions.flatMap(
                                ({ captions = [] }) =>
                                    captions.flatMap((caption) => caption.hasOwnProperty('devices') ? caption.devices : caption)
                            ),
                        );
                    } else {
                        const [
                            {
                                techtags: [{ devices: extension }],
                            },
                        ] = res;
                        return extension.flatMap(({ captions = []}) =>
                            captions.flatMap((caption) => caption.hasOwnProperty('devices') ? caption.devices : caption)
                        );
                    }
                }).finally(() => {
                    this._pendingDevsOfExtension = null;
                });
                return this._pendingDevsOfExtension;
            } else {
                return this.getAvailableExtensions(false, miniserver).then(
                    function (extensions) {
                        extensions.forEach(
                            function (extension) {
                                devices = devices.concat(
                                    extension.devices
                                        .filter(
                                            function (device) {
                                                return (
                                                    !deviceType ||
                                                    generationDeviceTypes.includes(
                                                        device.type,
                                                    )
                                                );
                                            }.bind(this),
                                        )
                                        .map((device) => {
                                            /**
                                             * Adding info about the extension to the device, to be able to distinguish between devices in a Client-Gateway setup.
                                             * This info is crucial later for the Pairing Assistant, to prevent cross Miniserver device replacement or pairing one device with both Client and Gateway!
                                             */
                                            return {
                                                ...device,
                                                extension: {
                                                    serialNr:
                                                        extension.serialNr,
                                                    msSerial:
                                                        extension.msSerial,
                                                },
                                            };
                                        }),
                                );
                            }.bind(this),
                        );
                        return devices;
                    }.bind(this),
                );
            }
        };

    DeviceLearningExt.prototype.getExtension = function getExtension(serialNr) {
        let extension = null;
        if (this.extensionList) {
            extension = this.extensionList.find(ext => { return ext.serialNr && ext.serialNr.toUpperCase() === serialNr.toUpperCase(); });
        }
        return extension;
    }

    DeviceLearningExt.prototype.registerForExtensionChanges = function registerForExtensionChanges(cbFn, searchTag) {
        this._extensionListeners[searchTag] = this._extensionListeners[searchTag] || [];
        this._extensionListeners[searchTag].push(cbFn);
        return () => {
            this._extensionListeners[searchTag] = this._extensionListeners[searchTag].filter(fn => fn !== cbFn);
        }
    }

    DeviceLearningExt.prototype.registerForTechTagChanges = function registerForTechTagChanges(cbFn) {
        this._techTagListeners = this._techTagListeners || [];
        this._techTagListeners.push(cbFn);
        return () => {
            this._techTagListeners = this._techTagListeners.filter(fn => fn !== cbFn);
        }
    }

    DeviceLearningExt.prototype.getAvailableExtensions = function getAvailableExtensions(forceReload = false, miniserver) {
        var promise;

        if (this.extensionList && !forceReload) {
            Debug.DeviceSearch.General && console.log(this.name, "getAvailableExtensions - cached"); // Already stored, return extension-list via promise.

            promise = Q(this.extensionList);
        } else if (this._requestExtensionsPromise) {
            Debug.DeviceSearch.General && console.log(this.name, "getAvailableExtensions - already requesting, return promise!");
            promise = this._requestExtensionsPromise;
        } else {
            Debug.DeviceSearch.General && console.log(this.name, "getAvailableExtensions - Download! (forced=" + !!forceReload + ")", getStackObj()); // no data stored, reload
            promise = this._downloadAvailableExtensions(miniserver);
        }

        return promise;
    };

    DeviceLearningExt.prototype.getAvailableTechTags = function getAvailableTechTags(forceReload = false, forceResetExtensions = false) {
        var promise;

        if(forceResetExtensions) {
            this._resetExtensionList();
        }

        if (this._techtags.length > 0 && !forceReload) {
            Debug.DeviceSearch.TechTags && console.log(this.name, "getAvailableTechTags - cached"); // Already stored, return techs-list via promise.

            promise = Q(this._techtags);
        } else if (this._requestTechTagsPromise) {
            Debug.DeviceSearch.TechTags && console.log(this.name, "getAvailableTechTags - already requesting, return promise!");
            promise = this._requestTechTagsPromise;
        } else {
            Debug.DeviceSearch.TechTags && console.log(this.name, "getAvailableTechTags - Download! (forced=" + !!forceReload + ")", JSON.stringify(getStackObj())); // no data stored, reload
            promise = this._downloadAvailableTechTags();
        }

        return promise;
    };

    DeviceLearningExt.prototype.getAvailableMiniservers = function getAvailableMiniservers(forceReload = false) {
        var promise;
        if (this.availableMiniservers.length > 0 && !forceReload) {
            Debug.DeviceSearch.TechTags && console.log(this.name, "getAvailableMiniservers - cached"); // Already stored, return techs-list via promise.

            promise = Q(this.availableMiniservers);
        } else if (this._requestMiniserversPromise) {
            Debug.DeviceSearch.TechTags && console.log(this.name, "getAvailableMiniservers - already requesting, return promise!");
            promise = this._requestMiniserversPromise;
        } else {
            Debug.DeviceSearch.TechTags && console.log(this.name, "getAvailableMiniservers - Download! (forced=" + !!forceReload + ")", getStackObj()); // no data stored, reload
            promise = this._availableMSListDownload();
        }

        return promise;
    };


    /**
     * To speed up comm, the Miniserver can now send the results in an unescaped raw JSON instead of wrapping it into
     * the response's value, where it must be escaped.
     * @returns {*}
     * @private
     */
    DeviceLearningExt.prototype._extensionListDownload = function _extensionListDownload(miniserver) {
        let cmd;
        if(Feature.GENERIC_DEVICE_SEARCH) {
            cmd = Commands.format(Commands.DEVICE_MANAGEMENT.GET_AVAILABLE_TECHNOLOGIES_FOR_MINISERVER, typeof miniserver === 'object' ? miniserver.serialNr : '-1', this.searchType);
            Debug.DeviceSearch.General && console.log(this.name, "_extensionListDownload - using new API!");
            Debug.DeviceSearch.General && console.log(this.name, "_extensionListDownload - get available miniservers!");
            return this._send(cmd, false, true, { noLLResponse: true }).then((res) => {
                Debug.DeviceSearch.General && console.log(this.name, "_extensionListDownload - get available miniservers!");
                if(!res || res.length === 0) {
                    return Q.reject("No miniservers found!");
                }
                return res.filter(ms => ms.techtags).flatMap(({ techtags: [{ devices: extensions }] }) => extensions);
            }).catch((err) => {
                throw err;
            });
        } else if (Feature.DEV_SEARCH_RAW_EXTENSIONS_LIST) {
            cmd = Commands.DEVICE_MANAGEMENT.GET_AVAILABLE_EXTENSIONS_RAW;
            Debug.DeviceSearch.General && console.log(this.name, "_extensionListDownload - new raw JSON structure");
            return this._send(cmd, false, true, {noLLResponse: true}).then((res) => {
                Debug.DeviceSearch.General && console.log(this.name, "_extensionListDownload - new raw JSON structure!");
                return res;
            });
        } else {
            Debug.DeviceSearch.General && console.log(this.name, "_extensionListDownload - old lxRequest-structure");
            return this._send(Commands.DEVICE_MANAGEMENT.GET_AVAILABLE_EXTENSIONS, false, true).then((res) => {
                Debug.DeviceSearch.General && console.log(this.name, "_extensionListDownload - old escaped JSON - responded!");
                return getLxResponseValue(res);
            });
        }
    }

    DeviceLearningExt.prototype._availableMSListDownload = function _availableMSListDownload() {
        let cmd = Commands.DEVICE_MANAGEMENT.GET_AVAILABLE_MINISERVERS;
        Debug.DeviceSearch.TechTags && console.log(this.name, "_availableMSListDownload - new raw JSON structure");
        this._requestMiniserversPromise = this._send(cmd, false, true, { noLLResponse: true }).then((res) => {
            Debug.DeviceSearch.TechTags && console.log(this.name, "_availableMSListDownload - got response", res);
            this.availableMiniservers = res;
            return res;
        }).catch((err) => {
            Debug.DeviceSearch.TechTags && console.log(this.name, "_availableMSListDownload - getting response failed!");
            throw err;
        }).finally(() => {
            this._requestMiniserversPromise = null;
        });
        return this._requestMiniserversPromise;
    }

    DeviceLearningExt.prototype._downloadAvailableExtensions = function _downloadAvailableExtensions(miniserver) {
        Debug.DeviceSearch.General && console.log(this.name, "_downloadAvailableExtensions - start", getStackObj());
        let currentMsSerial = ActiveMSComponent.getActiveMiniserverSerial();

        this._requestExtensionsPromise = this._extensionListDownload(miniserver).then(function (res) {
            Debug.DeviceSearch.General && console.log(this.name, "_downloadAvailableExtensions - extension list updated!");
            if (Feature.GENERIC_DEVICE_SEARCH) {
                res.forEach((extension) => {
                    this._supportedGenericTypes = this._supportedGenericTypes.includes(extension.type) ? this._supportedGenericTypes : this._supportedGenericTypes.concat(extension.type);
                });
            }
            this.extensionList = res.filter(function (extension) {
                if (this.isSupportedExtensionType(extension.type)) {
                    if (extension.internal) {
                        if ("miniserverType" in extension && Object.values(MiniserverType).includes(extension.miniserverType)) {
                            extension.imageUrl = this.getImageUrlWithDeviceType(this._convertMsTypeToDeviceType(extension.miniserverType));
                        } else if (extension.type === DeviceManagement.TREE_EXTENSIONS.TREE_BASE) {
                            extension.imageUrl = this.getImageUrlWithDeviceType(DeviceManagement.MS_V2);
                        } else {
                            extension.imageUrl = this.getImageUrlWithDeviceType(DeviceManagement.MS_GO);
                        }
                    } else {
                        extension.type = extension.type.replace(/ /g, '');
                        extension.imageUrl = this.getImageUrlWithDeviceType(extension.type);
                    }

                    // add a request-target which can tell apart the internal branches of compacts in Client-GW-Installations
                    if (Feature.DEV_SEARCH_CLIENT_SPECIFICATION) {
                        let rqTargetParts = [];
                        let adoptedMsSerial = this._sanitizeMsSerialForSearch(extension.msSerial);

                        adoptedMsSerial && rqTargetParts.push(adoptedMsSerial);
                        rqTargetParts.push(extension.serialNr);

                        // by adding the MS Serial to the target, client-gateway installations know precisely where to
                        // perform a request on, e.g.: 50:4F:94:A0:DF:ED|13000001
                        extension.requestTarget = rqTargetParts.join("|");

                        // some features are not supported on the clients, but only on the gateway (switchboard, lightgroups, ..)
                        // hence it may be crucial to identify where this extension is located.
                        extension.isOnClient = extension.msSerial && (currentMsSerial !== extension.msSerial);

                    } else {
                        extension.requestTarget = extension.serialNr;
                    }

                    return extension;
                }
            }.bind(this));
            Debug.DeviceSearch.General && console.log(this.name, "       list is: ", this.extensionList);
            return this.extensionList;
        }.bind(this), (err) => {
            console.error(this.name, "_downloadAvailableExtensions - extension list failed to load!");
            console.error(this.name, "    err=" + JSON.stringify(err));
            return Q.reject(err);
        }).finally(() => {
            this._extensionListeners[this.searchType] && this._extensionListeners[this.searchType].forEach(cb => cb(this.extensionList));
            this._requestExtensionsPromise = null;
        });
        return this._requestExtensionsPromise;
    }

    DeviceLearningExt.prototype._downloadAvailableTechTags =
        function _downloadAvailableTechTags() {
            Debug.DeviceSearch.TechTags &&
                console.log(
                    this.name,
                    '_downloadAvailableTechTags - start',
                    JSON.stringify(getStackObj()),
                );
            this._requestTechTagsPromise = this._send(Commands.DEVICE_MANAGEMENT.GET_AVAILABLE_TECHNOLOGIES, false, true, { noLLResponse: true })
                .then((miniservers) => {
                    this._techtags = miniservers.filter(ms => ms.techtags && Array.isArray(ms.techtags) && ms.techtags.length > 0).flatMap(({techtags}) => techtags)
                    .filter(
                        (value, index, self) =>
                            self
                                .map((x) => x.name)
                                .indexOf(value.name) === index,
                    );
                        return this._techtags;
                })
                .catch((err) => {
                    throw err;
                })
                .finally(() => {
                    this._techTagListeners && this._techTagListeners.forEach(cb => cb(this._techtags));
                    this._requestTechTagsPromise = null;
                });
            return this._requestTechTagsPromise;
        };


    DeviceLearningExt.prototype._resetFoundDeviceNumbers = function _resetFoundDeviceNumbers() {
        if (this.extensionList && Array.isArray(this.extensionList)) {
            this.extensionList.forEach(ext => {
                if (ext.hasOwnProperty("foundDevices")) {
                    ext.loadingFoundDevices = true;
                }
            });

            this._extensionListeners.hasOwnProperty(this.searchType) && this._extensionListeners[this.searchType].forEach((cb) => cb(this.extensionList));
        }
    };


    DeviceLearningExt.prototype.setImageObj = function setImageObj(obj) {
        this.imageObj = obj;
    };

    DeviceLearningExt.prototype.setSearchType = function setSearchType(searchType) {
        this.searchType = searchType;
    };

    DeviceLearningExt.prototype.isValidDeviceSearchType = function isValidDeviceSearchType(searchType) {
        let isValid = false;
        switch (searchType) {
            case DeviceManagement.TYPE.TREE:
            case DeviceManagement.TYPE.AIR:
            case DeviceManagement.TYPE.TECH_SELECTION:
                isValid = true;
                break;
            default:
                if(Feature.GENERIC_DEVICE_SEARCH) {
                    isValid = this._techtags.some(tag => tag.name === searchType);
                } else {
                    console.error(this.name, "isValidDeviceSearchType '" + searchType + "' - NOPE");
                    isValid = false;
                }
                break;
        }
        
        return isValid;
    }

    DeviceLearningExt.prototype.getSearchType = function getSearchType() {
        return this.searchType;
    };

    DeviceLearningExt.prototype.getTitleForSearchType = function getTitleForSearchType(searchType) {
        let title;
        switch (searchType) {
            case DeviceManagement.TYPE.TREE:
                title = _("tree.device-learning.title");
                break;
            case DeviceManagement.TYPE.AIR:
                title = _("air.device-learning.title");
                break;
            default:
                if(Feature.GENERIC_DEVICE_SEARCH) {
                    const tag = this._techtags.find(tag => tag.name === searchType);
                    title = tag ? tag.title : _("generic.device-learning.title");
                    break;
                }
                title = searchType.debugify();
                break;
        }
        return title;
    };

    DeviceLearningExt.prototype.createNewDevice = function createNewDevice(requestTarget, deviceSerial, deviceCapabilities, device) {
        // reset the last paired device attribute
        this._lastPairedDeviceSerial = null;

        var deviceSettings = this._getDeviceSettings(deviceCapabilities, device);

        var cmd = Commands.format(Commands.DEVICE_MANAGEMENT.CREATE, requestTarget, deviceSerial, JSON.stringify(deviceSettings)); // If we create a device, we know, that the extensionList has been changed --> needs to be updated

        Debug.DeviceSearch.General && console.log(this.name, "pair device with command --> " + cmd);
        return this._send(cmd, false, true).then(function (res) {
            Debug.DeviceSearch.General && console.log(this.name, "paired device with command --> " + cmd, res);
            // update the last paired device attribute
            this._lastPairedDeviceSerial = deviceSerial;
            this.getAvailableExtensions(true);
            this._removeDeviceFromResults(deviceSerial);
            return res;
        }.bind(this)).finally(function () {
            //this.stopDeviceIdentify();
        }.bind(this));
    };

    DeviceLearningExt.prototype._removeDeviceFromResults = function _removeDeviceFromResults(deviceSerial) {
        Debug.DeviceSearch.General && console.log(this.name, "_removeDeviceFromResults: " + deviceSerial);
        this.resultsList = this.resultsList.filter(dev => dev.serialNr !== deviceSerial);
        setTimeout(this._notifyRegisteredScreensForDeviceCounterChanged.bind(this, this.resultsList), 1);
    }

    DeviceLearningExt.prototype.replaceDevice = function replaceDevice(newSerial, oldUuid) {
        // reset the last paired device attribute
        this._lastPairedDeviceSerial = null; // If we replace a device, we know, that the extensionList has been changed --> needs to be updated

        return this._send(Commands.format(Commands.DEVICE_MANAGEMENT.REPLACE, newSerial, oldUuid), false, true).then(function (res) {
            // update the last paired device attribute
            this._lastPairedDeviceSerial = newSerial;
            this.getAvailableExtensions(true);
            this._removeDeviceFromResults(newSerial);
            return res;
        }.bind(this)).finally(function () {
            //this.stopDeviceIdentify();
        }.bind(this));
    };

    DeviceLearningExt.prototype.getLastPairedDevicSerial = function getLastPairedDevicSerial() {
        return this._lastPairedDeviceSerial;
    };

    // region LightGroupHandling

    /**
     * Will create a new light group on the corresponding client (if on client/gateway)
     * @param name      name of the lightgroup
     * @param roomUuid  the room the lightgroup should be assigned to
     * @param device    if provided, the lightgroup will be created on the Miniserver which holds the device.
     * @returns {*}
     */
    DeviceLearningExt.prototype.createLightGroup = function createLightGroup(name, roomUuid, device = {}) {
        var cmd,
            configJson = {};

        if (roomUuid) {
            configJson.room = roomUuid;
        }

        cmd = Commands.format(Commands.DEVICE_MANAGEMENT.CREATE_LIGHT_GROUP, name, JSON.stringify(configJson));

        // in a client gateway installation the lightgroup must be created on the client, because they are not shared
        // across miniserver boundaries.
        if (device && device.msSerial &&
            device.msSerial !== ActiveMSComponent.getActiveMiniserverSerial() &&
            Feature.CLIENT_LIGHTGROUP_SUPPORT) {
            cmd = cmd + "?client=" + device.msSerial;
        }

        return SandboxComponent.sendWithPermission(cmd, MsPermission.DEVICE_MANAGEMENT).then(res => {
            let lightGroupObject;
            try {
                // parse infos, should look sth like this:
                // {\"name\":\"Testgruppe\",\"uuid\":\"1a21de5d-02f6-0fd1-ffff504f94a14bbc\",\"type\":0}
                lightGroupObject = getLxResponseValue(res);
            } catch (ex) {
                console.error(this.name, "createLightGroup failed, couldn't parse response: " + JSON.stringify(res), ex);
                return Q.reject("Failed to parse createLightGroup-Response!");
            }

            // as the ID-Property is missing within the createLightGroup-Result, the list must be re-requested.
            this._resetLightGroupList();
            return this._loadAvailableLightGroups().then((res) => {
                return res.find(lg => { return lg.uuid === lightGroupObject.uuid;});
            });
        });
    }

    DeviceLearningExt.prototype.registerForLightGroupChanges = function registerForLightGroupChanges(callbackFn) {
        let randomId;
        do {
            randomId = getRandomIntInclusive(0, 999999);
        } while (this._lightGroupsChangedRegistrations.hasOwnProperty(randomId));
        this._lightGroupsChangedRegistrations[randomId] = callbackFn;
        return () => {
            delete this._lightGroupsChangedRegistrations[randomId];
        }
    }

    DeviceLearningExt.prototype.getAvailableLightGroups = function getAvailableLightGroups() {
        if (!this._lightGroups) {
            this._loadAvailableLightGroups();
        }
        return this._lightGroups;
    }

    DeviceLearningExt.prototype._launchLightGroupsRequest = function _launchLightGroupsRequest() {
        if (this._useGwOptionsRq()) {
            return SandboxComponent.sendWithPermission(Commands.DEVICE_MANAGEMENT.GW_GET_LIGHT_GROUPS, MsPermission.DEVICE_MANAGEMENT)
        } else {
            return SandboxComponent.sendWithPermission(Commands.DEVICE_MANAGEMENT.GET_LIGHT_GROUPS, MsPermission.DEVICE_MANAGEMENT)
        }
    }

    DeviceLearningExt.prototype._loadAvailableLightGroups = function _loadAvailableLightGroups() {
        if (this._lightGroupRqPromise) {
            return this._lightGroupRqPromise;
        }
        Debug.DeviceSearch.General && console.log(this.name, "_loadAvailableLightGroups: ", this._lightGroups);
        this._lightGroupRqPromise = this._launchLightGroupsRequest().then(function (res) {
            let lightGroupsResult, allRooms, preparedList;
            try {
                lightGroupsResult = getLxResponseValue(res);
                allRooms = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM, true);
                preparedList = lightGroupsResult.map(lgObj => {
                    let res = { ...lgObj };
                    if (lgObj.roomUuid && allRooms && allRooms[lgObj.roomUuid]) {
                        res.room = allRooms[lgObj.roomUuid];
                    }
                    return res;
                });
                // sort list by rooms then by LG names.
                preparedList.sort((lgA, lgB) => {
                    if (lgA.room && lgB.room) {
                        let result = lgA.room.name.localeCompare(lgB.room.name);
                        if (result === 0) { // same room, sort by LG name.
                            return lgA.name.localeCompare(lgB.name);
                        }
                        return result;
                    } else if (lgA.room && !lgB.room) {
                        return -1; // lgA before lgB, as A has a room and B does not.
                    } else if (lgB.room && !lgA.room) {
                        return 1; // lgB before lgA, as B has a room and A does not
                    } else {
                        return lgA.name.localeCompare(lgB.name);
                    }
                })

                this._lightGroups = preparedList;
                this._dispatchLightGroupChanges();
                return this._lightGroups;
            } catch (ex) {
                return Q.reject(ex);
            }
        }.bind(this)).finally(() => {
            this._lightGroupRqPromise = null;
        });
        return this._lightGroupRqPromise;
    }

    DeviceLearningExt.prototype._dispatchLightGroupChanges = function _dispatchLightGroupChanges() {
        Object.values(this._lightGroupsChangedRegistrations).forEach(cb => { cb(this._lightGroups); });
    }

    DeviceLearningExt.prototype._resetLightGroupList = function _resetLightGroupList() {
        Debug.DeviceSearch.General && console.log(this.name, "_resetLightGroupList");
        this._lightGroups = null;
    }

    // endregion LightGroupHandling


    // region switchboard handling

    DeviceLearningExt.prototype.identifySwitchboardType = function identifySwitchboardType(dev = {}) {
        let sbType = DeviceManagement.SwitchboardTypes.REGULAR;

        if (DeviceManagement.WALLBOX_TYPES.find((wbType) => wbType === dev.type)) {
            sbType = DeviceManagement.SwitchboardTypes.WALLBOX;

        } else if (!hasBit(dev.capabilities, DeviceManagement.CAPABILITIES.SB_ROW)) {
            sbType = DeviceManagement.SwitchboardTypes.HEAT;
        }

        return sbType;
    }

    DeviceLearningExt.prototype.createSwitchboard = function createSwitchboard(name, sbType = DeviceManagement.SwitchboardTypes.REGULAR) {
        let sbObj = {regular: sbType === DeviceManagement.SwitchboardTypes.REGULAR, name: name, id: name, sbType };
        this._switchboards.push(sbObj);
        setTimeout(this._dispatchSwitchboardChanges.bind(this), 1);
        return Q.resolve(sbObj);
    }

    DeviceLearningExt.prototype.registerForSwitchboardChanges = function registerForSwitchboardChanges(callbackFn) {
        let randomId;
        do {
            randomId = getRandomIntInclusive(0, 999999);
        } while (this._switchboardsChangedRegistrations.hasOwnProperty(randomId));
        this._switchboardsChangedRegistrations[randomId] = callbackFn;
        return () => {
            delete this._switchboardsChangedRegistrations[randomId];
        }
    }

    DeviceLearningExt.prototype.getAvailableSwitchboards = function getAvailableSwitchboards() {
        Debug.DeviceSearch.General && console.log(this.name, "getAvailableSwitchboards: " + JSON.stringify(this._switchboards));
        if (!this._switchboards && Feature.LEARN_DEVICE_OVER_APP_REMAKE) {
            this._loadAvailableSwitchboards();
        }
        return this._switchboards;
    }

    /**
     * If true, this means that we're on a gateway and it's on a FW that allows to use the new requests that will return
     * the options (lightgroups, switchboards, heat-circuit-boards, wallbox-rows) from all it's clients also.
     * The regular request did only return them from the gateway itself and did not mind the clients.
     * @returns {boolean}
     * @private
     */
    DeviceLearningExt.prototype._useGwOptionsRq = function _useGwOptionsRequest() {
        return Feature.GW_DEVICESEARCH_OPTIONS && ActiveMSComponent.getGatewayType() === GatewayType.GATEWAY;
    }

    DeviceLearningExt.prototype._launchSwitchboardRequests = function _launchSwitchboardRequests() {
        let rqMap = {};
        if (this._useGwOptionsRq()) {
            rqMap.switchboards = SandboxComponent.sendWithPermission(Commands.DEVICE_MANAGEMENT.GW_GET_SWITCHBOARDS, MsPermission.DEVICE_MANAGEMENT);
            rqMap.heatSwitchboards = SandboxComponent.sendWithPermission(Commands.DEVICE_MANAGEMENT.GW_GET_HEAT_SWITCHBOARDS, MsPermission.DEVICE_MANAGEMENT);
            rqMap.wallboxSwitchboards = SandboxComponent.sendWithPermission(Commands.DEVICE_MANAGEMENT.GW_GET_WALLBOX_SWITCHBOARDS, MsPermission.DEVICE_MANAGEMENT);
            
        } else {
            rqMap.switchboards = SandboxComponent.sendWithPermission(Commands.DEVICE_MANAGEMENT.GET_SWITCHBOARDS, MsPermission.DEVICE_MANAGEMENT);
            rqMap.heatSwitchboards = SandboxComponent.sendWithPermission(Commands.DEVICE_MANAGEMENT.GET_HEAT_SWITCHBOARDS, MsPermission.DEVICE_MANAGEMENT);
            if (Feature.WALLBOX_SWITCHBOARDS) {
                rqMap.wallboxSwitchboards = SandboxComponent.sendWithPermission(Commands.DEVICE_MANAGEMENT.GET_WALLBOX_SWITCHBOARDS, MsPermission.DEVICE_MANAGEMENT);
            }
        }
        return rqMap;
    }

    DeviceLearningExt.prototype._loadAvailableSwitchboards = function _loadAvailableSwitchboards() {
        if (this._switchboardRqPromise) {
            return this._switchboardRqPromise;
        }
        let rqMap = this._launchSwitchboardRequests();
        let regularRq = rqMap.switchboards.then(res => {
            try {
                return getLxResponseValue(res).map(sb => {return {
                    ...sb,
                    regular: true,
                    sbType: DeviceManagement.SwitchboardTypes.REGULAR,
                    id: sb.name
                }});
            } catch (ex) {
                return Q.reject(ex);
            }
        });
        let heatRq = rqMap.heatSwitchboards.then(res => {
            try {
                return getLxResponseValue(res).map(sb => {return {
                    ...sb,
                    regular: false,
                    sbType: DeviceManagement.SwitchboardTypes.HEAT,
                    id: sb.name
                }});
            } catch (ex) {
                return Q.reject(ex);
            }
        });
        let wallboxRq = null;
        if (rqMap.wallboxSwitchboards) {
            wallboxRq = rqMap.wallboxSwitchboards.then(res => {
                try {
                    return getLxResponseValue(res).map(sb => {return {
                        ...sb,
                        regular: false,
                        sbType: DeviceManagement.SwitchboardTypes.WALLBOX,
                        id: sb.name
                    }});
                } catch (ex) {
                    return Q.reject(ex);
                }
            });
        }

        let allRequests = [regularRq, heatRq];
        allRequests.pushObject(wallboxRq);

        this._switchboardRqPromise = Q.all(allRequests).then(function (res) {
            let allswitchBoards = [];
            res.forEach((res => {
                allswitchBoards.splice(0,0, ...res);
            }))
            this._switchboards = allswitchBoards;
            this._dispatchSwitchboardChanges();
            return this._switchboards;
        }.bind(this)).finally(() => {
            this._switchboardRqPromise = null;
        });
        return this._switchboardRqPromise;
    }

    DeviceLearningExt.prototype._dispatchSwitchboardChanges = function _dispatchSwitchboardChanges() {
        Debug.DeviceSearch.General && console.log(this.name, "_dispatchSwitchboardChanges: " + JSON.stringify(this._switchboards));
        Object.values(this._switchboardsChangedRegistrations).forEach(cb => { cb(this._switchboards); });
    }

    DeviceLearningExt.prototype._resetSwitchboardList = function _resetSwitchboardList() {
        this._switchboards = null;
    }

    // endregion switchboard handling

    DeviceLearningExt.prototype._send = function _send(cmd, onlySendWithGrantedPermissions, sendViaHTTP, rqFlags = RQ_FLAGS_DEFAULT) {
        var promise; // onResume handling --> keepalive & stop command
        // If we don't have the permissions from the screens, we aren't allowed to send commands otherwise multiple authentication popups will be shown

        if (onlySendWithGrantedPermissions) {
            promise = SandboxComponent.checkGrantedPermission(MsPermission.DEVICE_MANAGEMENT, false).then(function () {
                Debug.DeviceSearch.Comm && console.log(this.name, "send (perm-dep) " + cmd);
                return SandboxComponent.sendWithPermission(cmd, MsPermission.DEVICE_MANAGEMENT, false, rqFlags);
            }.bind(this), function (err) {
                // no permission = don't send it. E.g. keepalive or stop-search.
                console.log(this.name, "In this case (" + cmd + ") it's okay if we don't have any permission token, err=" + JSON.stringify(err));
            }.bind(this));
        } else {
            Debug.DeviceSearch.Comm && console.log(this.name, "send: " + cmd);
            promise = SandboxComponent.sendWithPermission(cmd, MsPermission.DEVICE_MANAGEMENT, sendViaHTTP, rqFlags);
        }

        promise.then((res) => {
            Debug.DeviceSearch.Comm && console.log(this.name, "send: " + cmd + " > response: ", JSON.stringify(res));
        }, (err) => {
            Debug.DeviceSearch.Comm && console.error(this.name, "send: " + cmd + " > failed: ", JSON.stringify(err));
        });
        return promise;
    };

    DeviceLearningExt.prototype._resetExtensionList = function _resetExtensionList() {
        Debug.DeviceSearch.General && console.log(this.name, "_resetExtensionList");
        this.extensionList = null;
        this._extensionListeners && Object.values(this._extensionListeners).flat(1).forEach((cb) => cb(this.extensionList));
    };

    DeviceLearningExt.prototype._resetTechTagList = function _resetTechTagList() {
        Debug.DeviceSearch.General && console.log(this.name, "_resetTechTagList");
        this._techtags = [];
        this._techTagListeners && this._techTagListeners.forEach((cb) => cb(this._techtags));
    };


    DeviceLearningExt.prototype.getDeviceIdentifier = function getDeviceIdentifier() {
        if (!this._deviceIdentifier) {
            this._deviceIdentifier = PersistenceComponent.getLocalStorageItem(DEVICE_SEARCH_ID_KEY);
            if (!this._deviceIdentifier) {
                this._deviceIdentifier = this._createRandomUuid();
                PersistenceComponent.setLocalStorageItem(DEVICE_SEARCH_ID_KEY, this._deviceIdentifier);
            }
        }
        return this._deviceIdentifier;
    };

    DeviceLearningExt.prototype._createRandomUuid = function _createRandomUuid() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
        });
        return uuid;
    };

    /**
     * Will start the keepalive interval.
     * @param instant:boolean used e.g. to instantly have a keepalive sent when resuming from the backgorund
     */
    DeviceLearningExt.prototype.startKeepalive = function startKeepalive(instant = false) {
        if (!this._shouldSendKeepalive) {
            this._shouldSendKeepalive = true;

            if (instant) {
                this._sendKeepalive().finally(this._sendKeepaliveDelayed.bind(this));
            } else {
                this._sendKeepaliveDelayed();
            }
        } else {
            Debug.DeviceSearch.General && console.info(this.name, "There is already an active keepalive, won't start a new one...");
        }
    };

    DeviceLearningExt.prototype.stopKeepalive = function stopKeepalive() {
        this._shouldSendKeepalive = false;
        this._searchKeepalive && clearTimeout(this._searchKeepalive);
        this._searchKeepalive = null;
    };

    DeviceLearningExt.prototype.getImageUrlWithDeviceType = function getImageUrlWithDeviceType(deviceType) {
        var imagePath;

        if (this.imageObj.hasOwnProperty(deviceType)) {
            imagePath = this.imageObj[deviceType].image;
            Debug.DeviceSearch.General && console.log(this.name, "getImageUrlWithDeviceType: " + deviceType + " = " + imagePath);
        } else {
            imagePath = this._getDefaultIconForSearchTyp();
            Debug.DeviceSearch.General && console.warn(this.name, "getImageUrlWithDeviceType: " + deviceType + " = (default)", this.imageObj);
        }

        return imagePath;
    };


    DeviceLearningExt.prototype.getImageUrlForMiniserverType = function getImageUrlForMiniserverType(msType) {
        let deviceType = "unknown";
        switch (msType) {
            case MiniserverType.MINISERVER:
                deviceType = DeviceManagement.MS;
                break;
            case MiniserverType.MINISERVER_GO:
            case MiniserverType.MINISERVER_GO_V2:
                deviceType = DeviceManagement.MS_GO;
                break;

            case MiniserverType.MINISERVER_V2:
                deviceType = DeviceManagement.MS_V2;
                break;

            case MiniserverType.MINISERVER_COMPACT:
                deviceType = DeviceManagement.MS_COMPACT;
                break;
        }

        return this.getImageUrlWithDeviceType(deviceType);
    };

    DeviceLearningExt.prototype.handleStateChanged = function handleStateChanged(states) {
        Debug.DeviceSearch.Comm && console.log(this.name, "handleStateChange: searching=" + states.liveSearch.isSearching + ", id=" + states.liveSearch.deviceId + ", devCntr=" + states.liveSearch.deviceCounter, states.liveSearch, "searchType= ", this.searchType);
        if (this._deviceIdentifier !== states.liveSearch.deviceId && this._searchStarted) {
            // Search no longer in our hands, bail out
            Debug.DeviceSearch.Comm && console.log(this.name, "     deviceId changed, no longer searching! ownId=" + this._deviceIdentifier + ", current=" +  states.liveSearch.deviceId);
            this._notifyRegisteredScreensForSearchingStateChanged(false, true);
            this.stopSearch(true);
            return;
        } // Processes the first results immediately

        if (this.isSearching !== states.liveSearch.isSearching) {
            this._setSearchingState(states.liveSearch.isSearching);
            this._notifyRegisteredScreensForSearchingStateChanged(this.isSearching, false);
        }

        if (this.deviceCounter !== states.liveSearch.deviceCounter) {
            Debug.DeviceSearch.Comm && console.log(this.name, "DeviceCounter from " + this.deviceCounter + " to " + states.liveSearch.deviceCounter);
            this.deviceCounter = states.liveSearch.deviceCounter;

            if (!this.requestTimeout) {
                this.requestTimeout = setTimeout(function () {
                    if (this.shouldRequestNewStates) {
                        this.getResultsFromMS();
                    }

                    this.shouldRequestNewStates = false;
                    this.requestTimeout = null;
                }.bind(this), 1000);
                this.getResultsFromMS();
            } else {
                this.shouldRequestNewStates = true;
            }
        }
    };

    DeviceLearningExt.prototype.canSearchOverAllExtensions = function canSearchOverAllExtensions() {
        var enabled;

        switch (this.searchType) {
            case DeviceManagement.TYPE.TREE:
                enabled = true;
                break;

            default:
                enabled = false;
                break;
        }

        return enabled;
    };

    /**
     * Tree devices search provides search results asap, meaning that if no found devices are listed, none exist.
     * @returns {boolean}
     */
    DeviceLearningExt.prototype.canShowFoundDevices = function canShowFoundDevices(typeToCheck) {
        var canShow = false,
            toCheck = typeToCheck || this.searchType;
        switch (toCheck) {
            case DeviceManagement.TYPE.TREE:
                canShow = Feature.TREE_FOUND_DEVICES;
                break;
            default:
                break;
        }
        return canShow;
    };

    DeviceLearningExt.prototype._getDefaultIconForSearchTyp = function _getDefaultIconForSearchTyp() {
        var icon;

        switch (this.searchType) {
            case DeviceManagement.TYPE.TREE:
                icon = Icon.TREE_LOGO;
                break;

            case DeviceManagement.TYPE.AIR:
                icon = Icon.AIR_LOGO;
                break;
            default:
                icon = Icon.EMPTY;
                break;
        }

        return icon;
    };

    // ------------------ REGISTERING FOR EVENTS ------------------

    // region Registration for device list

    DeviceLearningExt.prototype.registerForDeviceCounterChangedNotify = function registerForDeviceCounterChangedNotify(callback) {
        this._registeredScreensForDeviceCounterChagned.push(callback);

        return this._unregisterForDeviceCounterChangedNotify.bind(this, callback);
    };

    DeviceLearningExt.prototype._unregisterForDeviceCounterChangedNotify = function _unregisterForDeviceCounterChangedNotify(callback) {
        var callbackIndex = this._registeredScreensForDeviceCounterChagned.indexOf(callback);

        if (callbackIndex >= 0) {
            this._registeredScreensForDeviceCounterChagned.splice(callbackIndex, 1);
        }
    };

    DeviceLearningExt.prototype._notifyRegisteredScreensForDeviceCounterChanged = function _notifyRegisteredScreensForDeviceCounterChanged(results) {
        Debug.DeviceSearch.General && console.log(this.name, "_notifyRegisteredScreensForDeviceCounterChanged: " + (results ? results.length : results));
        this._updateFoundDevicesWithResults(results);
        this._notifyDeviceSearchListeners(this.isSearching, false, results);
        this._registeredScreensForDeviceCounterChagned.forEach(function (callback) {
            callback(results);
        });
    };

    // endregion

    // region registering for the search state only

    DeviceLearningExt.prototype.registerForSearchingStateChangedNotify = function registerForSearchingStateChangedNotify(callback) {
        this._registeredScreensForSearchingStateChagned.push(callback);

        return this._unregisterForSearchingStateChangedNotify.bind(this, callback);
    };

    DeviceLearningExt.prototype._unregisterForSearchingStateChangedNotify = function _unregisterForSearchingStateChangedNotify(callback) {
        var callbackIndex = this._registeredScreensForSearchingStateChagned.indexOf(callback);

        if (callbackIndex >= 0) {
            this._registeredScreensForSearchingStateChagned.splice(callbackIndex, 1);
        }
    };

    DeviceLearningExt.prototype._notifyRegisteredScreensForSearchingStateChanged = function _notifyRegisteredScreensForSearchingStateChanged(isSearching, kicked = false) {
        Debug.DeviceSearch.General && console.log(this.name, "_notifySearchStateListeners: isSearching=" + isSearching + ", wasKicked=" + kicked);
        this._notifyDeviceSearchListeners(isSearching, kicked, this.resultsList);
        this._registeredScreensForSearchingStateChagned.forEach(function (callback) {
            callback(isSearching, kicked);
        });
    };

    // endregion

    // region Unified registration for both searching state & results

    DeviceLearningExt.prototype.registerForDeviceSearch = function registerForDeviceSearch(callback, searchType, searchId) {
        this._registeredSearchListeners[searchType] = this._registeredSearchListeners[searchType] || [];
        this._registeredSearchListeners[searchType].push(callback);
        return function () {
            this._registeredSearchListeners[searchType] = this._registeredSearchListeners[searchType].filter(cb => callback !== cb);
        }.bind(this);
    }

    DeviceLearningExt.prototype._notifyDeviceSearchListeners = function _notifyDeviceSearchListeners(searching, kicked, results) {
        Debug.DeviceSearch.General && console.log(this.name, "_notifyDeviceSearchListeners: searching=" + searching + ", kicked=" + kicked + ", results=" + (results ? results.length : results));
        if(!Array.isArray(this._registeredSearchListeners[this.searchType]) || this._registeredSearchListeners[this.searchType].length === 0) {
            Debug.DeviceSearch.General && console.log(this.name, "_notifyDeviceSearchListeners: no listeners registered for searchType: " + this.searchType);
            return;
        }
        this._registeredSearchListeners[this.searchType].forEach((callback) => {
            callback({isSearching: !!searching, isRestarting: !!this.isRestarting, wasKicked: !!kicked, results });
        });
    };

    // endregion

    /**
     * Uses a search result list provided to update the foundDevices-counters in the extension list.
     * @param results searchresult-list.
     * @private
     */
    DeviceLearningExt.prototype._updateFoundDevicesWithResults = function _updateFoundDevicesWithResults(results) {
        const getMapId = (exSerial, msSerial) => {
            return exSerial && exSerial.toUpperCase() + (msSerial ? ("|" + msSerial.toUpperCase()) : "");
        }

        // from the search results, create a map for each extension & its subcaptions (branches)
        let resultMap = {};
        results.forEach(searchResult => {
            const mapId = getMapId(searchResult.extension, searchResult.msSerial);
            let mapEntry = resultMap[mapId] || { foundDevices:0, msSerial: searchResult.msSerial, serialNr: searchResult.extension };
            mapEntry.foundDevices++;
            if (searchResult.subCaptionId) {
                let subCapEntry = mapEntry[searchResult.subCaptionId] || {foundDevices: 0};
                subCapEntry.foundDevices++;
                mapEntry[searchResult.subCaptionId] = subCapEntry;
            }
            resultMap[mapId] = mapEntry;
        });

        // now iterate over the extension list and populate it with the foundDevices counts from the results
        this.extensionList && this.extensionList.forEach(ext => {
            const mapId = getMapId(cleanSerialNumber(ext.serialNr), ext.msSerial);
            const mapEntry = resultMap[mapId];
            if (mapEntry) {
                ext.foundDevices = mapEntry.foundDevices;
                ext.subCaptions && ext.subCaptions.forEach(subCap => {
                   if (mapEntry[subCap.id]) {
                       subCap.foundDevices = mapEntry[subCap.id].foundDevices;
                   }
                });
                console.log(this.name, "_updateFoundDevicesWithResults - " + ext.serialNr + "/" + ext.name + " => foundDevices: " + ext.foundDevices, ext);
            }
        });

        if (this.extensionList && this._extensionListeners[this.searchType]) {
            this._extensionListeners[this.searchType].forEach(cb => cb(this.extensionList));
        }
    }

    
    // ------------------ State Handling ------------------

    DeviceLearningExt.prototype._getDeviceSettings = function _getDeviceSettings(deviceCapabilities, device) {
        var deviceSettings = {};

        if (device.name) {
            deviceSettings.name = device.name;
        }

        if (device.roomUuid) {
            deviceSettings.roomUuid = device.roomUuid;
        }

        if (device.description && device.description.length) {
            deviceSettings.description = device.description;
        }

        if (hasBit(deviceCapabilities, DeviceManagement.CAPABILITIES.PLACE) && device.place) {
            deviceSettings.place = device.place;
        }

        if (hasBit(deviceCapabilities, DeviceManagement.CAPABILITIES.SWITCHBOARD) && device.switchboard) {
            deviceSettings.switchboard = device.switchboard;
        }

        if (hasBit(deviceCapabilities, DeviceManagement.CAPABILITIES.SB_ROW) && device.row) {
            deviceSettings.row = parseInt(device.row);
        }

        if (hasBit(deviceCapabilities, DeviceManagement.CAPABILITIES.SB_POS) && device.column) {
            deviceSettings.column = parseInt(device.column);
        }

        if (hasBit(deviceCapabilities, DeviceManagement.CAPABILITIES.AUTO_CONFIG) && device.autoCreate) {
            deviceSettings.autoconfig = device.autoCreate;
        }

        if (hasBit(deviceCapabilities, DeviceManagement.CAPABILITIES.LIGHT_GROUP) && device.groupID) {
            deviceSettings.groupID = device.groupID;
        }

        return deviceSettings;
    };

    /**
     * Whether or not the name should have a row and position appended to it.
     * @param deviceType
     * @returns {boolean}
     */
    DeviceLearningExt.prototype.deviceTypeSupportsNumberedName = function deviceTypeSupportsNumberedName(deviceType) {
        let useNumberedNames = false;
        if (Debug.DeviceSearch.NumberedDeviceNames) {
            switch (deviceType) {
                case DeviceManagement.DeviceTypes.SPOT_RGBW:
                case DeviceManagement.DeviceTypes.PENDULUM:
                case DeviceManagement.DeviceTypes.CEILING_LIGHT_TREE:
                case DeviceManagement.DeviceTypes.CEILING_LIGHT_AIR:
                    useNumberedNames = true;
                    break;
                default:
                    break;
            }
        }
        return useNumberedNames;
    }

    DeviceLearningExt.prototype._sendKeepaliveDelayed = function _sendKeepaliveDelayed() {
        if (this._shouldSendKeepalive) {
            this._searchKeepalive && clearTimeout(this._searchKeepalive);
            this._searchKeepalive = setTimeout(function () {
                this._sendKeepalive().finally(function () {
                    this._sendKeepaliveDelayed();
                }.bind(this));
            }.bind(this), this.KEEPALIVE_TIMEOUT_IN_SECONDS * 1000);
        }
    };

    DeviceLearningExt.prototype._sendKeepalive = function _sendKeepalive() {
        Debug.DeviceSearch.General && console.info(this.name, "Sending keepalive to the miniserver");
        return this._send(Commands.DEVICE_MANAGEMENT.LEARN_KEEPALIVE, true);
    };

    DeviceLearningExt.prototype.getDevicesJSON = function getDevicesJSON() {
        Debug.DeviceSearch.General && console.log(this.name, "getDevicesJSON");
       return (this._devicesJSONPrepPromise || this._prepareDevicesJSON());
    };

    DeviceLearningExt.prototype._prepareDevicesJSON = function _prepareDevicesJSON() {
        Debug.DeviceSearch.General && console.log(this.name, "_prepareDevicesJSON");
        if (this._devicesJSONPrepPromise) {
            return this._devicesJSONPrepPromise
        }

        this._devicesJSONPrepPromise = this._isLocalDevicesJSONValid()
            .then(this._getLocalDevicesJSON.bind(this)) // if local data is up to date, use it.
            .fail(this._fetchServerDevicesJSON.bind(this))// if outdated or sth went wrong, fetch from aws
            .fail(() => {
                console.error(this.name, "Failed trying to fetch devices.json --> use empty dataset")
                // if not even fetching from server was successful, use an empty object, to still remain operable.
                return {}
            }).then((devicesObj) => {
                this.setImageObj(devicesObj);
                return devicesObj;
            }).finally(() => {
                this._devicesJSONPrepPromise = null;
            });

        return this._devicesJSONPrepPromise;
    };

    DeviceLearningExt.prototype._isLocalDevicesJSONValid = function _isLocalDevicesJSONValid() {
        let versionPromises = [ this._getServerDevicesModifiedString(), this._getLocalDevicesModifiedString() ];
        return Q.all(versionPromises).then((versionResults) => {
            if (versionResults[0] === versionResults[1]) {
                Debug.DeviceSearch.General && console.log(this.name, "_isLocalDevicesJSONValid - yes");
                return Q.resolve();
            } else {
                Debug.DeviceSearch.General && console.log(this.name, "_isLocalDevicesJSONValid - nope");
                Debug.DeviceSearch.General && console.log(this.name, "    server = " + versionResults[0]);
                Debug.DeviceSearch.General && console.log(this.name, "     local = " + versionResults[1]);
                return Q.reject();
            }
        }, (err) => {
            Debug.DeviceSearch.General && console.log(this.name, "_isLocalDevicesJSONValid - nope", err);
            return Q.reject();
        });
    }

    DeviceLearningExt.prototype._getServerDevicesModifiedString = function _getServerDevicesModifiedString() {
        const cacheBuster = new Date().getTime();
        return Q($.ajax({
            type: "HEAD",
            url: DeviceManagement.DEVICES_JSON_PATH + "?cachebuster=" + cacheBuster,
            async: true
        }).then(function (message, text, response) {
            return response.getResponseHeader("last-modified");
        }));
    };

    DeviceLearningExt.prototype._getLocalDevicesModifiedString = function _getLocalDevicesModifiedString() {
        return PersistenceComponent.loadFile(DEVICE_JSON_MODIFY_DATE, DataType.STRING);
    };

    DeviceLearningExt.prototype._getLocalDevicesJSON = function _getLocalDevicesJSON() {
        return PersistenceComponent.loadFile(DEVICE_JSON_STRUCTURE, DataType.OBJECT);
    };

    DeviceLearningExt.prototype._fetchServerDevicesJSON = function _fetchServerDevicesJSON() {
        var structure,
            devicesObj = {},
            fileSavePrms = [];
        return Q($.get(DeviceManagement.DEVICES_JSON_PATH).then(function (data, text, response) {
            // beware: the data is received in different types, string or parsed (eg. macOS other than Android)
            if (typeof data === "string") {
                structure = JSON.parse(data);
            } else {
                structure = data;
            }

            structure.forEach(function (item) {
                devicesObj[item.type] = item;
            }.bind(this));
            fileSavePrms.push(PersistenceComponent.saveFile(DEVICE_JSON_STRUCTURE, devicesObj, DataType.OBJECT));
            fileSavePrms.push(PersistenceComponent.saveFile(DEVICE_JSON_MODIFY_DATE, response.getResponseHeader("last-modified"), DataType.STRING));
            return Q.all(fileSavePrms).then(function () {
                return devicesObj;
            });
        }.bind(this), function () {
            // Resolve anyway with an empty array, this will show the default image and information per device
            return Q.resolve(devicesObj);
        }));
    };

    DeviceLearningExt.prototype._convertMsTypeToDeviceType = function _convertMsTypeToDeviceType(msType) {
        let deviceType = "unknown";

        switch (msType) {
            case MiniserverType.MINISERVER_GO:
            case MiniserverType.MINISERVER_GO_V2:
                deviceType = DeviceManagement.MS_GO;
                break;

            case MiniserverType.MINISERVER_V2:
                deviceType = DeviceManagement.MS_V2;
                break;

            case MiniserverType.MINISERVER_COMPACT:
                deviceType = DeviceManagement.MS_COMPACT;
                break;
        }

        return deviceType;
    };

    DeviceLearningExt.prototype._sanitizeMsSerialForSearch = function _sanitizeMsSerialForSearch(msSerial) {
        let adoptedMsSerial = msSerial;
        if (msSerial && msSerial.indexOf(":") < 0) {
            // convert serialNumber, must be ":"-separated. 50:4F:94:A0:DF:ED
            const result = msSerial.match(/.{1,2}/g);
            adoptedMsSerial = result.join(":");
        }
        return adoptedMsSerial;
    }

    return DeviceLearningExt;
});
