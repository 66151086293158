'use strict';
/**
 * This extension is used to check and request permissions that the current
 * user might have.
 */

SandboxComp.factory('PermissionExt', function () {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var LEGACY_TIMEOUT = 60 * 1000; // 60 seconds until a legacy permission has to be re-requested.

    return class Permission extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            this._registrations = {};
            this._legacyPermissions = {};
        }

        /**
         * This method is the new way of testing if a user has administrative rights. This info is part of the
         * permission bitmask in newer config versions.
         * @returns {*|boolean}
         */
        hasAdminPermission() {
            return this.checkPermission(MsPermission.ADMIN);
        }

        /**
         * By registering for a permission, a view can ensure that this permission will remain alive as long as the
         * registration is active. After the unregister function, the permission will be disposed after a timeout.
         * @param msPermission          the permission to register for (e.g. Autopilot)
         * @param [revokePermission]    the permission is revoked when all delegates unregisters
         * @param [errorDelegate]       called when the token is no longer valid.
         * @returns {function(this:registerForPermission)}  function used to unregister.
         */
        registerForPermission(msPermission, revokePermission, errorDelegate) {
            Debug.Permission && console.log(this.name, "registerForPermission: " + this._translPerm(msPermission));
            var pStore = this._registrations[msPermission] || (this._registrations[msPermission] = {
                    cntr: 1,
                    delegates: {}
                }),
                regIdx = pStore.cntr++; // if this is the first registration, start the keep alive

            if (Object.keys(pStore.delegates).length === 0) {
                Debug.Permission && console.log("    initial reg, start keepalive");

                if (Feature.PERMISSION_HANDLING) {
                    CommunicationComponent.keepMsPermissionAlive(msPermission);
                } else {
                    this._updateLegacyPermission(msPermission, true);
                }
            }

            pStore.delegates[regIdx] = errorDelegate || false;
            return this._unregisterFromPermission.bind(this, msPermission, regIdx, revokePermission);
        }

        /**
         * Like registerForPermission, but with more than 1 perm.
         * Takes a list of permission objects containing the permission and optionally a revoke attribute
         * Will register for all of them and return a cleanUp fn to call when unregistering.
         * @param permissionsList   the list of permission objects
         * @param errorDelegate     an error delegate to call when the permission hasn't been granted.
         * @returns {function(...[*]=)} the cleanup function to call when the permissions aren't needed anymore.
         */
        registerForPermissions(permissionsList, errorDelegate) {
            Debug.Permission && console.log(this.name, "registerForPermissions: " + permissionsList.length);
            var toRevoke = NaN,
                toKeep = NaN,
                cleanUpFns = [];
            permissionsList.forEach(function (perm) {
                if (perm.revoke) {
                    toRevoke = toRevoke | perm.permission;
                } else {
                    toKeep = toKeep | perm.permission;
                }
            });

            if (!isNaN(toKeep)) {
                Debug.Permission && console.log(this.name, "    permissions to keep: " + this._translPerm(toKeep));
                cleanUpFns.push(this.registerForPermission(toKeep, false, errorDelegate));
            }

            if (!isNaN(toRevoke)) {
                Debug.Permission && console.log(this.name, "  permissions to revoke: " + this._translPerm(toRevoke));
                cleanUpFns.push(this.registerForPermission(toRevoke, true, errorDelegate));
            }

            return function () {
                cleanUpFns.forEach(function (fn) {
                    fn();
                });
            };
        }

        /**
         * Returns true or false whether the user can get a permission at all. If true, it does not mean that
         * the user already has the permission. It might still need to be acquired using getPermission
         * @param permission
         * @returns {boolean}
         */
        checkPermission(permission) {
            Debug.Permission && console.log(this.name, "checkPermission");
            var permAvailable, currPermissions;

            if (Feature.PERMISSION_HANDLING) {
                // check if the required bits are set in the available permissions bitmask
                currPermissions = ActiveMSComponent.getAvailablePermissions();
                permAvailable = hasBit(currPermissions, permission);
            } else {
                permAvailable = this._checkLegacyPermissionAvailability(permission);
            }

            Debug.Permission && console.log("    -->  " + this._translPerm(permission) + " = " + permAvailable);
            return permAvailable;
        }

        /**
         * Will check the availability of a permission, if available it will either resolve if it's already available
         * or it will request the permission from the Miniserver (using a popup asking for credentials).
         * @param permission
         * @param [customMessage]
         * @returns {*}
         */
        getPermission(permission, customMessage) {
            Debug.Permission && console.log(this.name, "getPermission: " + this._translPerm(permission));
            var prms;

            if (Feature.PERMISSION_HANDLING) {
                prms = this.checkGrantedPermission(permission).fail(function () {
                    if (this.checkPermission(permission)) {
                        return this._requestPermission(permission, customMessage);
                    } else {
                        throw new Error(PermissionError.NOT_ALLOWED);
                    }
                }.bind(this));
            } else {
                // use legacy techniques to acquire this permission
                prms = this._checkLegacyPermissionGranted(permission).fail(function () {
                    // check if the permission is available at all
                    if (this._checkLegacyPermissionAvailability(permission)) {
                        // request the permission!
                        return this._getLegacyPermission(permission);
                    } else {
                        throw new Error(PermissionError.NOT_ALLOWED);
                    }
                }.bind(this));
            }

            return prms;
        }

        /**
         * Will resolve if the permission has already been granted. Rejects if not.
         * @param permission
         * @param dontRequest   if true, it just resolves if a token exits, but it won't verify the token on the MS.
         * @returns {*}
         * @private
         */
        checkGrantedPermission(permission, dontRequest) {
            Debug.Permission && console.log(this.name, "checkGrantedPermission: " + this._translPerm(permission));
            var currentUser = ActiveMSComponent.getCurrentUsername(),
                promise;

            if (Feature.PERMISSION_HANDLING) {
                if (!!dontRequest) {
                    promise = CommunicationComponent.getToken(permission, currentUser);
                } else {
                    promise = CommunicationComponent.getVerifiedToken(permission, currentUser);
                }
            } else {
                promise = this._checkLegacyPermissionGranted(permission);
            }

            return promise;
        }

        /**
         * Will use the UI and ask the user for credentials to acquire the permission
         * @param permission
         * @param [customMessage]
         * @returns {Promise}
         * @private
         */
        _requestPermission(permission, customMessage) {
            Debug.Permission && console.log(this.name, "_requestPermission: " + this._translPerm(permission)); //TODO-woessto: 152917053: [OPTIONAL] enable requesting the permission using a different user. NOTE: getPermission() currently rejects if the perm is not available!

            var currentCreds = ActiveMSComponent.getCurrentCredentials(),
                currentUser = currentCreds.username,
                token = CommunicationComponent.getToken(permission, currentUser),
                promise;

            if (token && Feature.TOKENS) {
                // password confirmation provided based on token.
                promise = CommunicationComponent.getVerifiedToken(permission, currentUser).fail(function (err) {
                    // the token is no longer valid, request a new one.
                    Debug.Permission && console.log(this.name, "_requestPermission: " + this._translPerm(permission) + " - rqVerifiedTokenFailed, pwConfirmation!");
                    return this._requestPasswordConfirmation(permission, null, customMessage);
                }.bind(this));
            } else {
                promise = this._requestPasswordConfirmation(permission, null, customMessage);
            }

            return promise;
        }

        /**
         * Will ask the user for the password and when provided, it will verify it.
         * @param permission
         * @param [texts]       optionally the texts have already been provided. otherwise use default texts
         * @param [customMessage]   optionally a custom message only can be provided too. Title & rest will remain as is.
         * @param [passwordWasWrong] password was wrong --> disable biometric auth
         * @returns {Promise}
         * @private
         */
        _requestPasswordConfirmation(permission, texts, customMessage, passwordWasWrong) {
            Debug.Permission && console.log(this.name, "_requestPasswordConfirmation: " + this._translPerm(permission));
            var txtObj = texts ? texts : this._getTextsForPermission(permission, customMessage);
            // no need to let requestPasswordFromCurrentUser verify the password, it will be verified by this comp anyway!
            return NavigationComp.requestPasswordFromCurrentUser(txtObj.title, txtObj.button, txtObj.message, passwordWasWrong, false, false, true).then(function (password) {
                return ActiveMSComponent.verifyPassword(password, permission).fail(function (err) {
                    var wrongPass = false;
                    if (err && err.hasOwnProperty("code")) {
                        wrongPass = err.code === ResponseCode.UNAUTHORIZED;
                        console.error("Password not correct, or could not be confirmed -- " + JSON.stringify(err));
                    }

                    return this._requestPasswordConfirmation(permission, txtObj, null, wrongPass);
                }.bind(this));
            }.bind(this));
        }

        /**
         * This method will be called when a registration for a permission is to be withdrawn.
         * @param msPermission
         * @param regIdx
         * @param revokePermission  if true, the token is to be killed when unregister is called and no delegate is registered!
         * @private
         */
        _unregisterFromPermission(msPermission, regIdx, revokePermission) {
            Debug.Permission && console.log(this.name, "_unregisterFromPermission: " + this._translPerm(msPermission));
            var pStore = this._registrations[msPermission];

            if (pStore && pStore.delegates.hasOwnProperty(regIdx)) {
                delete pStore.delegates[regIdx];

                if (Object.keys(pStore.delegates).length === 0) {
                    Debug.Permission && console.log("    no regs left, stop keepalive");

                    if (Feature.PERMISSION_HANDLING) {
                        CommunicationComponent.stopMsPermissionKeepAlive(msPermission);
                    } else {
                        // if the permission is to be revoked, immediately revoke the legacy permission. Otherwise
                        // make sure the timeout is started.
                        this._updateLegacyPermission(msPermission, !revokePermission, !revokePermission);
                    }
                }

                if (Feature.TOKENS && revokePermission && pStore.delegates.length === 0) {
                    Debug.Permission && console.log("    revoke the permission by killing the token.");
                    CommunicationComponent.killTokenWithMsPermission(msPermission);
                }
            } else {
                console.warn(this.name, "trying to unregister from a permission, but the registration no longer exists!");
            }
        }

        /**
         * Will return an object that contains both a title and a button title for the popup asking the user for his
         * password to acquire a permission.
         * @param permission
         * @param [customMessage]
         * @returns {{title, buttons}}
         * @private
         */
        _getTextsForPermission(permission, customMessage) {
            var currentUser = ActiveMSComponent.getCurrentCredentials().username,
                confirmation,
                message = customMessage;

            if (!message) {
                switch (permission) {
                    case MsPermission.CHANGE_PWD:
                        message = _("permission.message.user-edit");
                        break;

                    case MsPermission.EXPERT_MODE:
                        message = _("permission.message.expert");
                        break;

                    case MsPermission.OP_MODES:
                        message = _("permission.message.op-modes");
                        break;

                    case MsPermission.SYS_WS:
                        message = _("permission.message.sys-ws");
                        break;

                    case MsPermission.AUTOPILOT:
                        message = _("permission.message.autopilot");
                        break;

                    default:
                        message = _("permission.message.default");
                        break;
                }
            }

            return {
                title: _('password-confirmation', {
                    user: currentUser
                }),
                message: message,
                button: _('confirm-password')
            };
        }

        /**
         * Will print a userfriendly text (= the enums key) for the permission provided.
         * @param permission
         * @param noBrackets
         * @returns {*}
         * @private
         */
        _translPerm(permission, noBrackets = false) {
            return translateBitmapValue(MsPermission, permission, true, true);
        }

        getPermissionFriendlyName(permBit, asArray) {
            const permissionKeys = this._translPerm(permBit);
            const texts = permissionKeys.map(permKey => {
                const translKey = "permission.title." + permKey.toLowerCase();
                let translated = _(translKey);
                if (translated === translKey) {
                    return permKey;
                }
                return translated;
            });
            return asArray ? texts : texts.join(", ");
        }

        // --------------------------------------------------------------------------------------------------
        //              LEGACY PERMISSION HANDLING FOR OLDER MINISERVER FIRMWARE VERSIONS
        // --------------------------------------------------------------------------------------------------

        /**
         * Will return true if the user can acquire that permission. It does not mean he has the permisison.
         * @param permission
         * @returns {boolean}
         * @private
         */
        _checkLegacyPermissionAvailability(permission) {
            Debug.Permission && console.log(this.name, "_checkLegacyPermissionAvailability: " + this._translPerm(permission));
            var permAvailable;

            if (permission === MsPermission.CHANGE_PWD) {
                // some users are allowed to change their passwords. Some are only allowed to do so locally (when
                // using default user/pass combination)
                permAvailable = ActiveMSComponent.isUserAllowedToChangePW();
            } else {
                // all other permissions are available as soon as the user has administrative rights.
                permAvailable = ActiveMSComponent.isAdminUser();
            }

            return permAvailable;
        }

        /**
         * Will check if the permission is available on Miniservers that do not yet support the new permission handling.
         * @param permission
         * @result {*}  promise
         * @private
         */
        _getLegacyPermission(permission) {
            Debug.Permission && console.log(this.name, "_getLegacyPermission: " + this._translPerm(permission));
            var granted = false,
                pwRequired = false,
                promise; // determine if the permission is available on older Miniservers.

            switch (permission) {
                case MsPermission.CHANGE_PWD:
                    granted = ActiveMSComponent.isUserAllowedToChangePW();
                    pwRequired = true;
                    break;

                case MsPermission.SYS_WS:
                case MsPermission.EXPERT_MODE:
                    granted = ActiveMSComponent.isAdminUser();
                    pwRequired = true;
                    break;

                default:
                    granted = ActiveMSComponent.isAdminUser();
                    break;
            } // resolve or reject based on the result


            if (granted && pwRequired) {
                promise = this._requestPasswordConfirmation(permission);
            } else {
                promise = prmsfy(granted, null, PermissionError.NOT_ALLOWED);
            }

            return promise.then(this._updateLegacyPermission.bind(this, permission, true, true));
        }

        /**
         * This will ensure the legacy permission stays alive
         * @param permission    the permission to keep alive
         * @param available     granted if true, revoked if false
         * @param [temporary]   if true, the permission won't stick around forever, but only temporary
         * @private
         */
        _updateLegacyPermission(permission, available, temporary) {
            Debug.Permission && console.log(this.name, "_updateLegacyPermission: " + available + (temporary ? " - temp" : ""));
            var permObj = this._legacyPermissions[permission] || (this._legacyPermissions[permission] = {
                available: false
            }); // temporary is only allowed for granted permissions.

            temporary = available && temporary; // update permission availability.

            permObj.available = available; // clear potentially running timeouts

            permObj.timeout && clearTimeout(permObj.timeout); // if temporary, start the invalidation timeout

            if (temporary) {
                permObj.timeout = setTimeout(this._revokeLegacyPermission.bind(this, permission), LEGACY_TIMEOUT);
            }
        }

        /**
         * Will ensure the legacy permission is no longer granted.
         * @param permission
         * @private
         */
        _revokeLegacyPermission(permission) {
            Debug.Permission && console.log(this.name, "_revokeLegacyPermission: " + this._translPerm(permission));
            var permObj = this._legacyPermissions[permission];

            if (permObj) {
                permObj.timeout && clearTimeout(permObj.timeout);
                delete this._legacyPermissions[permission];
            }
        }

        /**
         * Will resolve if the legacy permission has been granted.
         * @param permission    the permisison in question
         * @returns {*}         the promise that will resolve if the legacy permission has been granted.
         * @private
         */
        _checkLegacyPermissionGranted(permission) {
            Debug.Permission && console.log(this.name, "_checkLegacyPermissionGranted: " + this._translPerm(permission));
            var permObj = this._legacyPermissions[permission],
                granted = permObj && permObj.available;
            return prmsfy(granted);
        }

    };
});
