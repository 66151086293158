'use strict';

ActiveMSComp.factory('AutomaticDesignerRuleExt', function () {
    let weakThis, cachedVisuPw;

    function AutomaticDesignerRuleExt(comp) {
        this.name = "AutomaticDesignerRuleExt";
        this.comp = comp;
        weakThis = this
    }

    AutomaticDesignerRuleExt.prototype.acquireVisuPasswordForObj = function acquireVisuPasswordForObj(obj, wasWrong) {
        if (obj.isSecured && !cachedVisuPw) {
            return SandboxComponent.acquireVisuPassword(wasWrong).then(function (visuPw) {
                return CommunicationComponent.getHashForVisuPass(visuPw).then(function (hash) {
                    return SandboxComponent.send(Commands.format(Commands.CONTROL.SECURED_COMMAND, hash, obj.uuid, "state")).then(function () {
                        cachedVisuPw = visuPw;
                        return visuPw;
                    }, function () {
                        return weakThis.acquireVisuPasswordForObj(obj, true);
                    });
                });
            });
        } else {
            return Q(cachedVisuPw);
        }
    };

    AutomaticDesignerRuleExt.prototype.getCachedVisuPw = function getCachedVisuPw() {
        return cachedVisuPw;
    };

    AutomaticDesignerRuleExt.prototype.resetCachedVisuPw = function getCachedVisuPw() {
        cachedVisuPw = null;
    };

    AutomaticDesignerRuleExt.prototype.setCurrentRuleToEdit = function setCurrentRuleToEdit(rule) {
        weakThis.resetCachedVisuPw();
        weakThis.currentEditRule = cloneObject(rule);
    };

    AutomaticDesignerRuleExt.prototype.getCurrentEditRule = function getCurrentEditRule() {
        return weakThis.currentEditRule;
    };

    AutomaticDesignerRuleExt.prototype.getCurrentEditRuleEventGroups = function getCurrentEditRuleEventGroups() {
        return weakThis.currentEditRule.eventGroups;
    };

    AutomaticDesignerRuleExt.prototype.getCurrentRuleActions = function getCurrentRuleActions() {
        return weakThis.currentEditRule.actions || [];
    };

    AutomaticDesignerRuleExt.prototype.setRuleTitle = function setRuleTitle(title) {
        weakThis.currentEditRule.name = title;
    };

    AutomaticDesignerRuleExt.prototype.setRuleDescription = function setRuleDescription(desc) {
        weakThis.currentEditRule.description = desc;
    };

    AutomaticDesignerRuleExt.prototype.setCurrentEditSectionIndex = function setCurrentEditSectionIndex(idx) {
        weakThis.currentEditSectionIndex = idx;
    };

    AutomaticDesignerRuleExt.prototype.addEventToRule = function addEventToRule(event) {
        if (!weakThis.currentEditRule.eventGroups[weakThis.currentEditSectionIndex]) {
            weakThis.currentEditRule.eventGroups.push(cloneObject(AutomaticDesignerEnums.EVENT_GROUP_TEMPLATE));
        }

        weakThis.currentEditRule.eventGroups[weakThis.currentEditSectionIndex].events.push(event);
    };

    AutomaticDesignerRuleExt.prototype.addActionToRule = function addActionToRule(action) {
        if (!weakThis.currentEditRule.hasOwnProperty("actions")) {
            weakThis.currentEditRule.actions = [];
        }

        weakThis.currentEditRule.actions.push(action);
    };

    AutomaticDesignerRuleExt.prototype.getEventValueAtIndex = function getEventValueAtIndex(groupIdx, eventIdx, rule) {
        rule = rule || weakThis.currentEditRule;
        var event = rule.eventGroups[groupIdx].events[eventIdx],
            val;

        if (event.arrValues) {
            val = event.arrValues;
        } else {
            val = event.value;
        }

        return [val];
    };

    AutomaticDesignerRuleExt.prototype.getEventOperator = function getEventOperator(groupIdx, eventIdx) {
        return weakThis.currentEditRule.eventGroups[groupIdx].events[eventIdx].operator;
    };

    AutomaticDesignerRuleExt.prototype.setEventOperator = function getEventOperator(groupIdx, eventIdx, operator) {
        weakThis.currentEditRule.eventGroups[groupIdx].events[eventIdx].operator = operator;
    };

    AutomaticDesignerRuleExt.prototype.getActionValue = function getActionValue(actionIdx, rule) {
        rule = rule || weakThis.currentEditRule;
        var action = rule.actions[actionIdx],
            val;

        if (action[AutomaticDesignerEnums.VAL_KEYS.ARR_VALUES]) {
            val = action[AutomaticDesignerEnums.VAL_KEYS.ARR_VALUES];
        } else {
            val = [action[AutomaticDesignerEnums.VAL_KEYS.VALUE]];
        }

        return val;
    };

    AutomaticDesignerRuleExt.prototype.setEventValueAtIndex = function setEventValueAtIndex(groupIdx, eventIdx, val) {
        var key;

        if (val instanceof Array) {
            key = AutomaticDesignerEnums.VAL_KEYS.ARR_VALUES;
        } else {
            key = AutomaticDesignerEnums.VAL_KEYS.VALUE;
        }

        weakThis.currentEditRule.eventGroups[groupIdx].events[eventIdx][key] = val;
    };

    AutomaticDesignerRuleExt.prototype.setActionValueAtIndex = function setActionValueAtIndex(actionIdx, val, valueIndex, capability) {
        var key = AutomaticDesignerEnums.VAL_KEYS.ARR_VALUES;

        if (!weakThis.currentEditRule.actions[actionIdx][key]) {
            weakThis.currentEditRule.actions[actionIdx][key] = [];
        }

        if (val instanceof Array) {
            weakThis.currentEditRule.actions[actionIdx][key] = val;
        } else {
            weakThis.currentEditRule.actions[actionIdx][key][valueIndex] = val;
        }

        if (capability.hasOwnProperty("action")) {
            weakThis.currentEditRule.actions[actionIdx].command = capability.action.replace(/<val(\d+)>/gm, function (fullMatch, index) {
                return weakThis.currentEditRule.actions[actionIdx][key][index];
            });
        }
    };

    AutomaticDesignerRuleExt.prototype.setActionValues = function setActionValues(actionIdx, values, capability) {
        var key = AutomaticDesignerEnums.VAL_KEYS.ARR_VALUES;
        weakThis.currentEditRule.actions[actionIdx][key] = [].concat.apply([], values); // Ensure we only send a flat map!

        if (capability.hasOwnProperty("action")) {
            weakThis.currentEditRule.actions[actionIdx].command = capability.action.replace(/<val(\d+)>/gm, function (fullMatch, index) {
                return weakThis.currentEditRule.actions[actionIdx][key][index];
            });
        }
    };

    AutomaticDesignerRuleExt.prototype.removeActionFromRule = function removeActionFromRule(actionIdx) {
        weakThis.currentEditRule.actions.splice(actionIdx, 1);
    };

    AutomaticDesignerRuleExt.prototype.removeEventFromRule = function removeEventFromRule(groupIdx, eventIdx) {
        weakThis.currentEditRule.eventGroups[groupIdx].events.splice(eventIdx, 1);

        if (!weakThis.currentEditRule.eventGroups[groupIdx].events.length) {
            weakThis.currentEditRule.eventGroups.splice(groupIdx, 1);
        }
    };

    return AutomaticDesignerRuleExt;
});
