'use strict';

define("PowerUnitControlStateContainer", ["ControlStateContainer", "PowerUnitControlEnums"], function (ControlStateContainer, PowerUnitControlEnums) {
    return class PowerUnitControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        destroy() {
            this._updateRemainingTimer && clearTimeout(this._updateRemainingTimer);
            return super.destroy(...arguments);
        }

        prepareStates(newVals) {
            this.states.outputPower = newVals[this.control.states.outputPower];
            this.states.batterySoC = newVals[this.control.states.batteryStateOfCharge]; // bitmap with fuses for outputs (bit 1 = fuse 1)

            this.states.trippedFuses = newVals[this.control.states.fuse]; // unix timestamp

            this.states.supplyTimeRemainingUntil = newVals[this.control.states.supplyTimeRemaining]; // device info bitmap

            this.states.deviceInfoBit = newVals[this.control.states.deviceInfo];
            this.states.backupModeActive = hasBit(this.states.deviceInfoBit, PowerUnitControlEnums.DeviceInfoBit.BACKUP_MODE_ACTIVE);
            this.states.overCurrentDetected = hasBit(this.states.deviceInfoBit, PowerUnitControlEnums.DeviceInfoBit.OVERCURRENT_DETECTED);
            this.states.isOverheating = hasBit(this.states.deviceInfoBit, PowerUnitControlEnums.DeviceInfoBit.DEVICE_OVERHEATING);
            this.states.batteryMissing = hasBit(this.states.deviceInfoBit, PowerUnitControlEnums.DeviceInfoBit.BATTERY_MISSING);
            this.states.batteryEmpty = hasBit(this.states.deviceInfoBit, PowerUnitControlEnums.DeviceInfoBit.BATTERY_EMPTY); // create a separate state object for each output, including all its states, texts & color

            this.states.outputStates = [];

            for (var j = 1; j <= this.control.getNumberOfOutputs(); j++) {
                var fuseBitId = j === 1 ? 1 : 1 << j - 1,
                    // fuseBitId starts with 1, then 2, 4, 8, ...
                    fuseTripped = !!hasBit(this.states.trippedFuses, fuseBitId),
                    outputPower = newVals[this.control.states["CP" + j]];
                Debug.Control.Power && console.log(this.name, "J=" + j + ", fuseBit=" + fuseBitId + ", tripped=" + !!fuseTripped + ", power=" + outputPower + ", name = " + JSON.stringify(this.control.getOutputObj(j)));
                this.states.outputStates.pushObject(this._prepareStatesForOutput(this.control.getOutputObj(j), outputPower, fuseTripped));
            } // low battery is detected based on the SoC


            this.states.lowBattery = this.states.batterySoC < PowerUnitControlEnums.BatteryLevel.LOW;

            if (this.control.getSupplyMode() !== PowerUnitControlEnums.SupplyMode.SUPPLY_ONLY) {
                this.states.batteryState = this._prepareStateForBattery();
                this.states.supplyState = this._prepareStateForSupply();
                this.states.remainingState = this._prepareRemainingState();
            }

            this.states.totalPowerState = this._prepareTotalPowerState();
        }

        getStateText() {
            if (this.states.backupModeActive) {
                return _("controls.power-unit.backup-mode");
            } else if (this.states.totalPowerState) {
                return this.states.totalPowerState.stateText;
            }
        }

        getStateColor(states) {
            if (this.states.backupModeActive) {
                return this.states.lowBattery ? window.Styles.colors.red : window.Styles.colors.orange;
            } else if (this.states.totalPowerState) {
                return this.states.totalPowerState.stateColor;
            }
        }

        getStateIcon() {
            if (this.states.backupModeActive) {
                if (this.states.batterySoC >= 99) {
                    return Icon.Power.BATTERY.FULL;
                } else if (this.states.batterySoC >= 70) {
                    return Icon.Power.BATTERY.THREE_QUARTER;
                } else if (this.states.batterySoC >= 45) {
                    return Icon.Power.BATTERY.HALF;
                } else if (this.states.batterySoC >= 20) {
                    return Icon.Power.BATTERY.QUARTER;
                } else {
                    return Icon.Power.BATTERY.EMPTY;
                }
            } else {
                return Icon.Power.STATE.ON;
            }
        }

        _prepareStatesForOutput(outputObj, outputPower, fuseTripped) {
            var stateColor, stateText;

            if (!outputObj || !outputObj.name || outputObj.name === "") {
                return null; // as per design, hide unused=unnamed outputs.
            }

            if (fuseTripped) {
                stateColor = window.Styles.colors.red;
                stateText = _("controls.power-unit.tripped-fuse");
            } else {
                stateColor = outputPower > 0 ? window.Styles.colors.stateActive : null;
                stateText = this._formatPower(outputPower);
            }

            return this._createStateObject(outputObj.name, stateColor, stateText, undefined, outputObj.id);
        }

        _prepareStateForBattery() {
            var stateColor, stateText, title;

            if (this.states.batteryMissing) {
                title = _("controls.power-unit.battery");
                stateColor = window.Styles.colors.red;
                stateText = _("controls.power-unit.battery-missing");
            } else {
                title = _("controls.power-unit.battery-level");
                stateColor = this.states.lowBattery || this.states.batteryEmpty ? window.Styles.colors.red : window.Styles.colors.stateActive;
                stateText = lxFormat(PowerUnitControlEnums.BATTERY_FORMAT, this.states.batterySoC);
            }

            return this._createStateObject(title, stateColor, stateText);
        }

        _prepareStateForSupply() {
            var stateColor = this.states.outputPower > 0 ? window.Styles.colors.stateActive : null,
                stateText,
                title = _("controls.power-unit.supply.title");

            if (this.states.backupModeActive) {
                stateText = _("controls.power-unit.supply.battery");
            } else {
                stateText = _("controls.power-unit.supply.power");
            }

            return this._createStateObject(title, stateColor, stateText);
        }

        _prepareRemainingState() {
            var resultState = null,
                remainingTime;
            this._updateRemainingTimer && clearTimeout(this._updateRemainingTimer);
            this._updateRemainingTimer = null;

            if (this.states.backupModeActive && this.states.supplyTimeRemainingUntil > 0) {
                var current = new moment().valueOf() / 1000;
                var shutdown = this.states.supplyTimeRemainingUntil;
                remainingTime = LxDate.formatSeconds(shutdown - current, false, false, false, true, true);
                resultState = this._createStateObject(_("controls.power-unit.remaining-supply-time"), this.states.lowBattery ? window.Styles.colors.red : window.Styles.colors.stateActive, remainingTime);
                this._updateRemainingTimer = setTimeout(() => {
                    this.states.remainingState = this._prepareRemainingState();
                    this.notifyListener();
                }, 10 * 1000);
            }

            return resultState;
        }

        _prepareTotalPowerState() {
            return this._createStateObject(_("controls.power-unit.total-power"), this.states.outputPower > 0 ? window.Styles.colors.stateActive : null, this._formatPower(this.states.outputPower));
        }

        _createStateObject(title, stateColor, stateText, subtitle, id) {
            return {
                title: title,
                subtitle: subtitle,
                stateColor: stateColor,
                stateText: stateText,
                id: id
            };
        }

        _formatPower(valKiloWatts) {
            return lxUnitConverter.convertAndApply(PowerUnitControlEnums.OUTPUT_POWER_FORMAT, valKiloWatts);
        }
    };
});
