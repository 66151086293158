'use strict';

SandboxComp.factory('StateExt', function () {
    let weakThis,
        comp,
        states = {},
        stateContainerDict = {};

    /**
     * c-tor of the State Extension
     * @param sandboxComp reference to the parent component
     * @constructor
     */

    function StateExt(sandboxComp) {
        weakThis = this
        comp = sandboxComp; // reset state values after timeout
        // timeout will be canceled if the connection gets opened soon!

        comp.on(SandboxComp.ECEvent.ConnClosed, function (event, args) {
            weakThis.resetStatus(true);
        }); // reset state values and wait for new

        comp.on(SandboxComp.ECEvent.ConnReady, function (event, newStructure) {
            if (newStructure) {
                disposeStateContainers();
                states = {};
                var uuid;

                for (uuid in stateContainerDict) {
                    if (stateContainerDict.hasOwnProperty(uuid)) {
                        try {
                            stateContainerDict[uuid].destroy.call(stateContainerDict[uuid]);
                        } catch (e) {
                            console.warn("Couldn't destroy stateContainer with UUID '" + uuid + "'");
                            console.error(e);
                        }
                    }
                }

                createStateContainers();
            } else {
                weakThis.resetStatus(false);

                if (!stateContainerDict) {
                    createStateContainers();
                }
            }
        });
        CompChannel.on(CCEvent.STRUCTURE_UPDATE_FINISHED, function (event, newControls) {
            var stateContainerRequests = [true],
                ctorName;

            if (newControls.length) {
                // Load any state containers
                newControls.forEach(function (control) {
                    ctorName = control.controlType + "ControlStateContainer";

                    if (!window.StateContainers.hasOwnProperty(ctorName)) {
                        stateContainerRequests.push(loadStateContainer(ctorName));
                    }
                });
                Q.all(stateContainerRequests).done(function () {
                    createControlStateContainers(newControls, true);
                });
            }
        }); // reset states dict if user did disconnect (maybe miniserver changes!)

        comp.on(SandboxComp.ECEvent.Reset, function (event, args) {
            disposeStateContainers();
            var uuid;

            for (uuid in states) {
                if (states.hasOwnProperty(uuid)) {
                    if (states[uuid].listeners.length > 0) {
                        console.warn("WARNING: there is still someone registered for " + uuid);
                    }
                }
            }

            states = {};

            for (uuid in stateContainerDict) {
                if (stateContainerDict.hasOwnProperty(uuid)) {
                    try {
                        stateContainerDict[uuid].destroy.call(stateContainerDict[uuid]);
                    } catch (e) {
                        console.warn("Couldn't destroy stateContainer with UUID '" + uuid + "'");
                        console.error(e);
                    }
                }
            }

            stateContainerDict = {};
        }); // Force a state update

        comp.on(SandboxComp.ECEvent.ForceStateUpdate, function () {
            forceStateUpdates();
        });
    }

    StateExt.StateContainerRequire = require.context("../..", true, /.*StateContainer\.js/)

    /**
     * Automatically informs the listener of status updates of the provided UUIDs
     * @param listenerInstance which should be informed
     * @param uuids array of UUIDs in which the listener is interested
     */


    StateExt.prototype.registerUUIDs = function registerUUIDs(listenerInstance, uuids) {
        Debug.StatesDetailed && console.log("registerUUIDs: '" + uuids.join(",") + "'");
        var listener = {
                instance: listenerInstance,
                newStates: {},
                interrestedUUIDs: uuids
            },
            state,
            uuid = "",
            i;

        for (i = 0; i < uuids.length; i++) {
            uuid = uuids[i];
            state = states[uuid];

            if (state) {
                // 2nd one who's interrested in this uuid, or the state is already known!
                if (state.value != null) {
                    listener.newStates[uuid] = state.value;
                }

                state.listeners.push(listener);
            } else {
                // first one who wants to register for that uuid!
                states[uuid] = {
                    listeners: [listener]
                };
            }
        } // check if all states have been arrived or if we should start the timeout?!


        var allStatesReceived = checkAllStatesForListener(listener, Debug.StatesDetailed);

        if (allStatesReceived) {
            listenerInstance.newStatesReceived(cloneObjectDeep(listener.newStates));
            listenerInstance.allStatesReceived && listenerInstance.allStatesReceived(true);
        } else if (uuids.length > 0) {
            setStateTimeoutFor(listener, false);
        }
    };
    /**
     * Removes the listener from listening to states UUIDs
     * @param listenerInstance which should be removed
     * @param uuids to remove
     */


    StateExt.prototype.unregisterUUIDs = function unregisterUUIDs(listenerInstance, uuids) {
        Debug.StatesDetailed && console.log("unregisterUUIDs: '" + uuids.join(",") + "'");
        var uuid = "",
            state,
            listener,
            listenerIdx,
            i;

        for (i = 0; i < uuids.length; i++) {
            uuid = uuids[i];
            state = states[uuid];

            if (state) {
                for (listenerIdx = 0; listenerIdx < state.listeners.length; listenerIdx++) {
                    listener = state.listeners[listenerIdx];

                    if (listener.instance === listenerInstance) {
                        if (listener.stateTimeout) {
                            clearTimeout(listener.stateTimeout);
                            listener.stateTimeout = null;
                        }

                        state.listeners.splice(listenerIdx, 1);
                        break;
                    }
                }
            }
        }
    };
    /**
     * Got status updates from SandboxComp
     * @param events collection of events received from the Miniserver
     * @param type type of event-collection {Normal|Text|Daytimer}
     */


    StateExt.prototype.setStatusUpdates = function setStatusUpdates(events, type) {
        Debug.StatesDetailed && console.log("setStatusUpdates:", events.length, " type:", BinaryEvent.getTypeString(type));
        var i,
            j = 0,
            listener,
            listenersToNotify = [],
            state = {},
            tmpEvent,
            receivedEventUUIDs = []; // to handle multiple events with the same UUID received in one packet (eg. 3 Notifications at the same time!)
        // TODO - group isn't available for mac, if WebKitDeveloperExtras flag is set to false

        Debug.SingleStates && console.group('new ' + BinaryEvent.getTypeString(type) + ' events (' + events.length + ')');

        for (i = 0; i < events.length; i++) {
            tmpEvent = events[i];
            Debug.StatesDetailed && console.info("Processing event with UUID: " + tmpEvent.uuid + " and value: " + tmpEvent.value,tmpEvent);
            state = states[tmpEvent.uuid];
            Debug.SingleStates && console.log(tmpEvent.toString());

            if (state) {
                if (type === BinaryEvent.Type.EVENT) {
                    // ensure no corrupt values from the miniserver can temper with the app. 143295281
                    state.value = roundN(tmpEvent.value, 6);
                } else {
                    state.value = tmpEvent;
                }

                if (receivedEventUUIDs.indexOf(tmpEvent.uuid) !== -1) {
                    // this is the 2nd, 3rd, .. event with the same UUID in the same packet
                    Debug.States && console.warn("received EventUUID '" + tmpEvent.uuid + "' multiple times in one packet, notifying now - should only be with Notifications!");
                    notifyListeners(listenersToNotify); // notify all listeners until now

                    listenersToNotify = []; // reset array
                } else {
                    receivedEventUUIDs.push(tmpEvent.uuid);
                }

                if (state.listeners.length > 0) {
                    for (j = 0; j < state.listeners.length; j++) {
                        listener = state.listeners[j];

                        if (listener.instance instanceof window.StateContainers.ControlStateContainer) {
                            var stateName = getStateNameForUUIDOfControl(listener.instance.control, tmpEvent.uuid);

                            if (stateName === "Unknown state") {//console.info(stateName + "::" + tmpEvent.toString());
                            } else {
                                Debug.States && console.info(stateName + "::" + tmpEvent.toString());
                            }
                        }

                        listener.newStates[tmpEvent.uuid] = state.value; // keep track of the original states before modifying them

                        if (listenersToNotify.indexOf(listener) === -1) {
                            listenersToNotify.push(listener);
                        }
                    }
                } else {
                    Debug.StatesDetailed && console.log("no one interrested in this status update! (" + tmpEvent.uuid + ") value: " + tmpEvent.value);
                }
            } else {
                if (type === BinaryEvent.Type.EVENT) {
                    states[tmpEvent.uuid] = {
                        value: tmpEvent.value,
                        listeners: []
                    };
                } else {
                    states[tmpEvent.uuid] = {
                        value: tmpEvent,
                        listeners: []
                    };
                }
            }
        } // TODO - group isn't available for mac, if WebKitDeveloperExtras flag is set to false


        Debug.SingleStates && console.groupEnd(); // updating all affected listeners

        notifyListeners(listenersToNotify);
    };
    /**
     * resets all values for all UUIDs
     * @param connectionWasLost
     */


    StateExt.prototype.resetStatus = function resetStatus(connectionWasLost) {
        Debug.StatesDetailed && console.log("resetStatus " + connectionWasLost);
        var state, i, listener, uuid;

        for (uuid in states) {
            if (states.hasOwnProperty(uuid)) {
                state = states[uuid];
                state.value = null;

                for (i = 0; i < state.listeners.length; i++) {
                    listener = state.listeners[i];
                    listener.newStates = {};

                    if (listener.interrestedUUIDs.length > 0) {
                        setStateTimeoutFor(listener, connectionWasLost);
                    }
                }
            }
        }
    };
    /**
     * loads all StateContainers (global..) and ControlStateContainers
     */


    StateExt.prototype.loadStateContainers = function loadStateContainers() {
        Debug.StatesDetailed && console.log("loadStateContainers");
        var usedControlTypes = ActiveMSComponent.getStructureManager().getAllUsedControlTypes(),
            usedStateContainers = [ "ControlStateContainer", "GlobalStateContainer" ],
            scName;

        usedControlTypes.forEach(controlType => {
            scName = `${controlType}ControlStateContainer`;

            if (requirePathDefined(scName)) {
                usedStateContainers.push(scName);
            } else {
                console.warn("loadStateContainers: require path for state container " + scName + " not defined!")
            }
        }
);
        if (SandboxComponent.isWeatherServerAvailable()) {
            usedStateContainers.push("WeatherControlStateContainer");
        }

        if (SandboxComponent.isMediaServerAvailable()) {
            usedStateContainers.push("MediaStateContainer");
        }

        if (SandboxComponent.isAutopilotAvailable()) {
            usedStateContainers.push("AutopilotGeneratorStateContainer");
        }

        if (SandboxComponent.isMessageCenterAvailable()) {
            usedStateContainers.push("MessageCenterStateContainer");
        } // clear up old ones


        window.StateContainers = {};
        return Q.all(usedStateContainers.map(loadStateContainer)).then(() => {
            return Q.resolve();
        }, e => {
            debugger;
        });
    };
    /**
     * requires a single StateContainer and calls the onFinished function if it either succeeds or fails
     * @param stateContainer
     * @return {Promise}
     */


    var loadStateContainer = function loadStateContainer(stateContainer) {
        let stateContainerSrcPath = getRequirePathForModule(stateContainer),
            cTor;

        try {
            cTor = StateExt.StateContainerRequire(stateContainerSrcPath);
            window.StateContainers[stateContainer] = cTor;
            return Q.resolve(cTor);
        } catch (e) {
            return Q.reject(e);

        }
    };

    /**
     *  iterates over the (static) states field and dispatches to each listener
     */


    function forceStateUpdates() {
        Debug.StatesDetailed && console.log("_forceStateUpdate");
        var i,
            state,
            uuids = Object.keys(states),
            listeners = [];

        for (i = 0; i < uuids.length; i++) {
            state = states[uuids[i]];
            listeners.push.apply(listeners, state.listeners);
        } // as all states are dispatched as "changed" - a lot is happening, make it sync to speed things up


        GUI.animationHandler.processSync(true);
        notifyListeners(listeners, true);
        GUI.animationHandler.processSync(false);
    }

    function notifyListeners(listeners, force) {
        Debug.StatesDetailed && console.log("notifyListeners");
        var listener,
            allStatesReceived = true,
            allStatesForListenerReceived,
            i;

        for (i = 0; i < listeners.length; i++) {
            listener = listeners[i];
            allStatesForListenerReceived = checkAllStatesForListener(listener, Debug.StatesDetailed);

            if (allStatesReceived) {
                allStatesReceived = allStatesForListenerReceived;
            }

            if (allStatesForListenerReceived) {
                if (listener.stateTimeout) {
                    clearTimeout(listener.stateTimeout);
                    listener.stateTimeout = null;
                }

                try {
                    if (force) {
                        // eg. after TaskRec. --> calling allStatesReceived with false will force state updates
                        listener.instance.allStatesReceived && listener.instance.allStatesReceived(false);
                    }

                    listener.instance.newStatesReceived && listener.instance.newStatesReceived(cloneObjectDeep(listener.newStates));
                    listener.instance.allStatesReceived && listener.instance.allStatesReceived(true);
                } catch (e) {
                    console.error(e.stack);
                }
            } else {// we still miss some value(s)!
            }
        } // Check if all states for all listeners have been received


        if (allStatesReceived) {
            CompChannel.emit(CCEvent.ALL_STATES_RECEIVED);
        }
    }

    /**
     * starts the timeout for states (waiting indicators..)
     * @param listener
     * @param connectionWasLost will be forwarded to ControlStateContainers, they need to know when the connection gets lost (eg. to display alerts after a reconnect, app background/foreground..)
     */


    function setStateTimeoutFor(listener, connectionWasLost) {
        Debug.StatesDetailed && console.log("setStateTimeoutFor " + connectionWasLost);

        if (connectionWasLost && listener.instance instanceof window.StateContainers.ControlStateContainer) {
            listener.instance.connectionLost();
        }

        if (listener.instance instanceof window.StateContainers.GlobalStateContainer) {
            listener.instance.stateTimeoutStarted();
        }

        if (listener.stateTimeout) {
            clearTimeout(listener.stateTimeout);
        }

        listener.stateTimeout = setTimeout(function () {
            checkAllStatesForListener(listener, Debug.StatesDetailed);
            listener.instance.allStatesReceived && listener.instance.allStatesReceived(false);
            listener.stateTimeout = null;
            listener.interrestedUUIDs.forEach(uuid => {
                const control = ActiveMSComponent.getStructureManager().getControlByUUID(uuid);
                if(control && control.controlType === "AudioZoneV2") {
                    Debug.Communication && console.warn("=".repeat(50));
                    console.warn(`AudioZoneV2: ${control.name} - ${control.uuidAction} - states disposed`);
                    Debug.Communication && console.warn("=".repeat(50));
                }
            });
        }, WAITING_FOR_STATES_TIMEOUT * 1000);
    }

    /**
     * checks if a listener has a state for each interrested uuid
     * @param listener
     * @param logOutput {boolean} if a logOutput should happen
     * @returns {boolean} weather all states has been arrived
     */


    function checkAllStatesForListener(listener, logOutput) {
        Debug.StatesDetailed && console.log("checkAllStatesForListener");
        var uuid, i;

        for (i = 0; i < listener.interrestedUUIDs.length; i++) {
            uuid = listener.interrestedUUIDs[i];

            if (listener.newStates.hasOwnProperty(uuid)) {
                if (listener.newStates[uuid] != null) {// we have a state for that!
                } else {
                    logOutput && console.info("incorrect state for " + (listener.instance.control ? "(Control: " + listener.instance.control.type + ")" : uuid));
                    return false;
                }
            } else {
                if (logOutput) {
                    var stateName = getStateNameForUUIDOfControl(listener.instance.control, uuid);
                    Debug.States && console.info("--" + (listener.instance.control ? " - (Control: " + listener.instance.control.type + ' - ' + listener.instance.control.name + ") " : "") + '[' + uuid + "] no state for " + stateName);
                }

                return false;
            }
        }

        return listener.interrestedUUIDs.length > 0;
    }

    /**
     * @see StateContainers.ControlStateContainer.prototype.getStatesForUUID
     */


    StateExt.prototype.getStatesForUUID = function getStatesForUUID(uuid) {
        Debug.StatesDetailed && console.log("getStatesForUUID: '" + uuid + "'");

        if (stateContainerDict.hasOwnProperty(uuid)) {
            return stateContainerDict[uuid].getStatesForUUID();
        }

        return false;
    };

    StateExt.prototype.getStateContainerForUUID = function getStateContainerForUUID(uuid) {
        Debug.StatesDetailed && console.log("getStateContainerForUUID: '" + uuid + "'");
        return stateContainerDict[uuid];
    };
    /**
     * @see StateContainers.ControlStateContainer.prototype.registerForStatusUpdates
     */


    StateExt.prototype.registerForStateChangesForUUID = function registerForStateChangesForUUID(uuid, listener, fn, allStatesReceivedFn, statesVersion) {
        Debug.StatesDetailed && console.log("registerForStateChangesForUUID: '" + uuid + "'");

        if (stateContainerDict.hasOwnProperty(uuid)) {
            stateContainerDict[uuid].registerForStatusUpdates(listener, fn, allStatesReceivedFn, statesVersion);
        }
    };

    StateExt.prototype.registerFunctionForStateChangesForUUID = function registerFnForStateChangesForUUID(uuid, fn, allStatesReceivedFn) {
        Debug.StatesDetailed && console.log("registerFnForStateChangesForUUID: '" + uuid + "'");

        if (stateContainerDict.hasOwnProperty(uuid)) {
            return stateContainerDict[uuid].registerFunctionForStateChanges(fn, allStatesReceivedFn);
        }
        return null;
    };

    /**
     * unregister a function from status updates for a given uuid
     * @param uuid
     * @param listener
     */


    StateExt.prototype.unregisterForStateChangesForUUID = function unregisterForStateChangesForUUID(uuid, listener) {
        Debug.StatesDetailed && console.log("unregisterForStateChangesForUUID");

        if (stateContainerDict.hasOwnProperty(uuid)) {
            stateContainerDict[uuid].unregisterFromStatusUpdates(listener);
        }
    };
    /**
     * Will return an array containing all statistic outputs currently available for a control.
     * @param uuid
     * @returns {*}
     */


    StateExt.prototype.getStatisticOutputsForUUID = function getStatisticOutputsForUUID(uuid) {
        Debug.StatesDetailed && console.log("getStatisticOutputsForUUID");
        var result = null;

        if (stateContainerDict.hasOwnProperty(uuid)) {
            result = stateContainerDict[uuid].getStatisticOutputs();
        }

        return result;
    };
    /**
     * update method for special states like "globalStates.modifications"
     * @param values
     */


    StateExt.prototype.newStatesReceived = function newStatesReceived(values) {
        var structureManager = comp.getStructureManager(),
            globalStates = structureManager.getGlobalStateUUIDs();

        if (globalStates.modifications && values[globalStates.modifications]) {
            try {
                var modificationPacket = JSON.parse(values[globalStates.modifications].text);
                CompChannel.emit(CCEvent.STRUCTURE_UPDATE, modificationPacket); // reset the state for modification, so that no update will be published after eg. task recorder

                resetStatesForStateUUID(globalStates.modifications);
            } catch (e) {
                console.error(e.stack);
            }
        }
    };
    /**
     * creates a stateContainer for a control
     * @returns {{}}
     */


    var createStateContainers = function createStateContainers() {
        var structureManager = comp.getStructureManager(),
            globalStates = structureManager.getGlobalStateUUIDs(),
            controls = structureManager.getAllControls();
        Debug.StatesDetailed && console.log("createStateContainers");
        stateContainerDict = {}; // global states

        var stateContainer = new window.StateContainers.GlobalStateContainer(globalStates);
        initStateContainer(stateContainer, stateContainer.control); // uuidAction is the id for the stateContainer on the dict
        // Weather

        if (SandboxComponent.isWeatherServerAvailable()) {
            stateContainer = new window.StateContainers.WeatherControlStateContainer(structureManager.getWeatherServer());
            initStateContainer(stateContainer, stateContainer.control);
        } // Media Server


        if (SandboxComponent.isMediaServerAvailable()) {
            var mediaServerSet = structureManager.getMediaServerSet(),
                mediaServer = structureManager.getMediaServer(); // media states

            if (Feature.MULTI_MUSIC_SERVER && mediaServerSet) {
                // create a state container for each music server!
                var keys = Object.keys(mediaServerSet);

                for (var i = 0; i < keys.length; i++) {
                    _createMediaStateCntr(mediaServerSet[keys[i]]);
                }
            } else if (mediaServer) {
                _createMediaStateCntr(mediaServer);
            }
        } // Autopilot


        if (SandboxComponent.isAutopilotAvailable()) {
            stateContainer = new window.StateContainers.AutopilotGeneratorStateContainer(structureManager.getAutopilotGenerator());
            initStateContainer(stateContainer, stateContainer.control);
        } // MessageCenter


        if (SandboxComponent.isMessageCenterAvailable()) {
            stateContainer = new window.StateContainers.MessageCenterStateContainer(structureManager.getMessageCenter());
            initStateContainer(stateContainer, stateContainer.control);
        } // register for structure changes


        if (Feature.STRUCTURE_LIVE_UPDATES && globalStates.modifications) {
            weakThis.registerUUIDs(weakThis, [globalStates.modifications]);
        } // Make sure to initialize all normal controls before the central controls, because central controls register for states at normal controls!


        var ctrls = [],
            centralCtrls = [];
        Object.values(controls).forEach(function (ctrl) {
            if (ctrl.isGrouped()) {
                centralCtrls.push(ctrl);
            } else {
                ctrls.push(ctrl);
            }
        });
        createControlStateContainers(ctrls);
        createControlStateContainers(centralCtrls);
        CompChannel.emit(CCEvent.StateContainersCreated);
    };
    /**
     * Checks the type of the server provided, if it's a mediaServer with a state container, it'll create a state
     * container for it.
     * @param mediaServer   the mediaServer to create a stateContainer for.
     * @private
     */


    var _createMediaStateCntr = function _createMediaStateCntr(mediaServer) {
        var stateContainer;

        switch (mediaServer.type) {
            case MediaServerType.LXMUSIC:
            case MediaServerType.LXMUSIC_V2:
                stateContainer = new window.StateContainers.MediaStateContainer(mediaServer);
                break;

            default:
                break;
        }

        if (stateContainer) {
            initStateContainer(stateContainer, stateContainer.control);
        }
    };
    /**
     * Initializes the control state containers
     * @param controls array of controls
     * @param [fromStructureUpdate] Will explicitly request the control states from the Miniserver if provided
     */


    var createControlStateContainers = function createControlStateContainers(controls, fromStructureUpdate) {
        var ctorName, stateContainer, controlUuids;
        controls = controls.map(function (control) {
            return ActiveMSComponent.getStructureManager().getControlByUUID(control.uuidAction);
        });
        controls.forEach(function (control) {
            ctorName = control.controlType + "ControlStateContainer";

            if (window.StateContainers[ctorName]) {
                try {
                    stateContainer = new window.StateContainers[ctorName](control);
                    initStateContainer(stateContainer, control);

                    if (control.subControls && Object.keys(control.subControls).length > 0) {
                        createControlStateContainers(Object.values(control.subControls));
                    }
                } catch (e) {
                    console.error("ERROR: failed to create stateContainer for " + control.controlType + " - ");
                    console.error(e.stack);
                }
            } else {
                console.warn("'" + ctorName + "' doesn't exist!");
            }
        });

        if (Feature.SUPPORTS_REQUEST_STATES_FOR && fromStructureUpdate) {
            controlUuids = controls.map(function (control) {
                return control.uuidAction;
            }).join(",");
            CommunicationComponent.send(Commands.format(Commands.REQUEST_STATES_FOR, controlUuids)).then(function () {
                console.info("Requesting states for '" + controlUuids + "'");
            }, function () {
                console.error("ERROR: " + Commands.REQUEST_STATES_FOR + " failed?");
            });
        }
    };

    var initStateContainer = function initStateContainer(stateContainer, control) {
        var uuids = stateContainer.getUUIDsToRegister.call(stateContainer);
        weakThis.registerUUIDs(stateContainer, uuids);
        stateContainerDict[control.uuidAction] = stateContainer;
    };

    var disposeStateContainers = function disposeStateContainers() {
        Debug.StatesDetailed && console.log("disposeStateContainers"); // unregister from structure changes

        var structureManager = comp.getStructureManager(),
            globalStates = structureManager.getGlobalStateUUIDs();

        if (Feature.STRUCTURE_LIVE_UPDATES && globalStates.modifications) {
            weakThis.unregisterUUIDs(weakThis, [globalStates.modifications]);
        }

        var stateContainer, uuid;

        for (uuid in stateContainerDict) {
            if (stateContainerDict.hasOwnProperty(uuid)) {
                stateContainer = stateContainerDict[uuid];
                weakThis.unregisterUUIDs(stateContainer, stateContainer.getUUIDsToRegister.call(stateContainer));
            }
        }
    };
    /**
     * function to find the state-name for a uuid
     * @param { object } control control object
     * @param { string } uuid which should be resolved
     * @returns { string } the property name of the provided uuid
     */


    var getStateNameForUUIDOfControl = function getStateNameForUUIDOfControl(control, uuid) {
        if (!control) return 'Unknown state';
        var states = control.states,
            res;
        res = getStateNameForUUID(states, uuid);
        if (res) return res;

        if (control.subControls) {
            var subCtrl;

            for (subCtrl in control.subControls) {
                if (control.subControls.hasOwnProperty(subCtrl)) {
                    states = control.subControls[subCtrl].states;
                    res = getStateNameForUUID(states, uuid);
                    if (res) return res;
                }
            }
        }

        return 'Unknown state';
    };

    var getStateNameForUUID = function getStateNameForUUID(states, uuid) {
        var stateName;

        for (stateName in states) {
            if (states.hasOwnProperty(stateName)) {
                if (states[stateName] === uuid) {
                    return stateName;
                }
            }
        }
    };
    /**
     * resets the states (here and from all listeners) for the given stateUuid
     * no event will be published to the listeners, only internal reset! (eg. used after a structure update)
     * @param stateUuid
     */


    var resetStatesForStateUUID = function resetStatesForStateUUID(stateUuid) {
        var state = states[stateUuid],
            i,
            listener;
        state.value = null;

        for (i = 0; i < state.listeners.length; i++) {
            listener = state.listeners[i];
            delete listener.newStates[stateUuid];
        }
    };

    return StateExt;
});
