'use strict';

define("GateControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class GateControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);

            this._prepareLiveIcon();
        }

        /**
         * This method is called every time the controls structure changes (ExpertMode)
         */
        structureDidChange() {
            // The structure did change, update the icon to reflect the potential changed animation type
            this._prepareLiveIcon();

            // also required to inform listeners, so that they update their UI
            this.version++;
            this.notifyListener();
        }

        prepareStates(newVals) {
            this.states.positionState = newVals[this.control.states.position];
            this.states.direction = newVals[this.control.states.active];
            this.states.isMoving = this.states.direction === Direction.UP || this.states.direction === Direction.DOWN;
            this.states.isClosed = this.states.positionState === 0;
            this.states.preventOpen = !!newVals[this.control.states.preventOpen];
            this.states.preventClose = !!newVals[this.control.states.preventClose];
            this.states.isLocked = this.states.preventOpen || this.states.preventClose;
        }

        getStateText() {
            if (this.states.direction === Direction.UP) {
                return _("controls.gate.opens");
            } else if (this.states.direction === Direction.DOWN) {
                return _("controls.gate.closes");
            } else if (this.states.positionState === 0) {
                return _('closed');
            } else if (this.states.positionState === 1) {
                return _('opened');
            } else {
                return _('opened') + " (" + lxFormat("%.0f%", this.states.positionState * 100) + ")";
            }
        }

        getStateColor() {
            if (this.states.isMoving) {
                return window.Styles.colors.stateActive;
            } else if (this.states.positionState > 0) {
                return window.Styles.colors.orange;
            }
        }

        getStateIcon() {
            return this._getLiveIcon();
        }

        getLiveStateIcon() {
            return this._getLiveIcon(true);
        }

        getMovingIcon() {
            if (this.states.direction === Direction.UP) {
                return this.control.getOpenIcon();
            } else {
                return this.control.getCloseIcon();
            }
        }

        getStateIconSmall() {
            if (this.states.isLocked) {
                return {
                    iconSrc: Icon.Jalousie.ComfortMode.LOCK,
                    color: window.Styles.colors.red
                };
            } else if (this.states.isMoving) {
                return {
                    iconSrc: this.getMovingIcon(),
                    color: window.Styles.colors.green
                };
            }
        }

        getStateInfo() {
            if (this.states.preventOpen) {
                return {
                    message: _("controls.gate.blocked", {
                        state: _("controls.gate.state-open")
                    }),
                    color: window.Styles.colors.red
                };
            } else if (this.states.preventClose) {
                return {
                    message: _("controls.gate.blocked", {
                        state: _("controls.gate.state-close")
                    }),
                    color: window.Styles.colors.red
                };
            }

            return null;
        }

        /**
         * Will animate the door inside the svg to the proper position & then return a snapshot
         * of the svg in with the proper position.
         * @param useTransformations    if true, the icon won't change, but the transformations state will be filled
         * @returns {string|string}
         * @private
         */
        _getLiveIcon(useTransformations) {
            if (this.control.isTurnDoorLeft()) {
                this._animateDoor(-1, 0);
            } else if (this.control.isTurnDoorRight()) {
                this._animateDoor(1, 0);
            } else if (this.control.isTurnDoorDouble()) {
                this._animateDoor(1, 0, true);
            } else if (this.control.isSectionalDoorLeft()) {
                this._animateDoor(-1, 0);
            } else if (this.control.isSectionalDoorRight()) {
                this._animateDoor(1, 0);
            } else {
                this._animateDoor(0, -1);
            }

            return useTransformations ? this.snapContainerLive.outerSVG() : this.snapContainer.outerSVG();
        }

        /**
         * Will animate the door provided to the current position.
         * @param dx    [-1, 1] .. if 0 it won't move on x axis
         * @param dy    [-1, 1] .. if 0 it won't move on y axis
         * @param [isDoubleDoor] if true two doorParts are translated separately
         * @private
         */
        _animateDoor(dx, dy, isDoubleDoor) {
            var value = this._getMovingDistance() * this.states.positionState,
                tx = (dx ? dx : 0) * value,
                ty = (dy ? dy : 0) * value;
            var translateFunc = "translate(" + tx + "," + ty + ")";

            let translationFunctions = {};

            this.doorPart0.transform("t" + tx + "," + ty); // the clip paths referenced inside the SVGs are important --> they are centralized in the index.html

            if (isDoubleDoor) {
                translationFunctions.door_left = translateFunc;
                translateFunc = "translate(" + (-tx) + "," + ty + ")";
                translationFunctions.door_right = translateFunc;
                this.doorPart1.transform("t" + -tx + "," + ty); // the clip paths referenced inside the SVGs are important --> they are centralized in the index.html

            } else {
                translationFunctions.door = translateFunc;
            }

            this.states.transformations = translationFunctions;


        }

        /**
         * Returns a the distance to move a door so it becomes fully open. Varies depending on the
         * svg clippath used. For optimal results open them fully and close them. the door has to become
         * visible immediately when its a sectional or regular garagedoor and the gate has to be visible
         * while being fully open on a gate (left, right, double).
         * @return {number}
         * @private
         */
        _getMovingDistance() {
            if (this.control.isTurnDoorLeft() || this.control.isTurnDoorRight()) {
                return 16;
            } else if (this.control.isTurnDoorDouble()) {
                return 7.5;
            } else if (this.control.isSectionalDoorLeft() || this.control.isSectionalDoorRight()) {
                return 20;
            } else {
                return 15;
            }
        }

        /**
         * Will prepare a snap container that holds the proper svg icon to animate matching the
         * UI type of the door. It will also store proper references to animate the doors later
         * on when the states arrive.
         * @private
         */
        _prepareLiveIcon() {
            if (this.control.isTurnDoorLeft()) {
                this._prepareSnapContainer(Icon.Gate.Type.TURNLEFT);

                this.doorPart0 = this._lookupDoorInSvg("#door");
            } else if (this.control.isTurnDoorRight()) {
                this._prepareSnapContainer(Icon.Gate.Type.TURNRIGHT);

                this.doorPart0 = this._lookupDoorInSvg("#door");
            } else if (this.control.isTurnDoorDouble()) {
                this._prepareSnapContainer(Icon.Gate.Type.TURNDOUBLE);

                this.doorPart0 = this._lookupDoorInSvg("#door_left");
                this.doorPart1 = this._lookupDoorInSvg("#door_right");
            } else if (this.control.isSectionalDoorLeft()) {
                this._prepareSnapContainer(Icon.Gate.Type.SECTIONALLEFT);

                this.doorPart0 = this._lookupDoorInSvg("#door");
            } else if (this.control.isSectionalDoorRight()) {
                this._prepareSnapContainer(Icon.Gate.Type.SECTIONALRIGHT);

                this.doorPart0 = this._lookupDoorInSvg("#door");
            } else {
                this._prepareSnapContainer(Icon.Gate.Type.REGULAR);

                this.doorPart0 = this._lookupDoorInSvg("#door");
            }
        }

        _prepareSnapContainer(iconSrc) {
            this.snapContainer = Snap($(ImageBox.getResourceImage(iconSrc))[0]);
            this.snapContainerLive = Snap($(ImageBox.getResourceImage(iconSrc))[0]);
        }

        _lookupDoorInSvg(svgId) {
            return this.snapContainer.select("g").select(svgId);
        }

    };
});
