'use strict';

PersistenceComp.factory('ArchiveExt', ['PlatformComp', function (PlatformComp) {
    var ARCHIVE_FILENAME = "LoxArchive.json"; // internal variables

    let weakThis,
        persComp = {},
        archive,
        savePassword = true;

    /**
     * c-tor for the Archive Extension
     * @param comp reference to the parent component
     * @constructor
     */

    function ArchiveExt(comp) {
        weakThis = this
        persComp = comp;
        var currentAppVersion = PlatformComponent.getAppInfoObj().appVersion; // load archive from persistence component

        weakThis.msInfoStore = new MsInfoStoreHandler();

        archive = persComp.loadFile(ARCHIVE_FILENAME, DataType.OBJECT);
        archive.then(function (res) {
            var backupPromise;
            archive = res;
            if (weakThis.hasFavoriteSettings(archive.deviceList)) {
                weakThis.applyAndRemoveFavorites(archive.deviceList);
                weakThis.saveArchive(archive.deviceList)
            }

            if (PersistenceComponent.backupAndSyncIsEnabled()) {
                backupPromise = PersistenceComponent.backupAndSyncGetInitialDataPromise().then(function (backupRes) {
                    if (backupRes.miniservers) {
                        archive.deviceList = backupRes.miniservers;
                        if (weakThis.hasFavoriteSettings(archive.deviceList)) {
                            weakThis.applyAndRemoveFavorites(archive.deviceList);
                        }
                        weakThis.saveArchive(archive.deviceList);
                    } else {
                        return Q(true);
                    }
                }.bind(this));
            } else {
                backupPromise = Q(true);
            }

            return backupPromise.finally(() => {
                if (archive.archiveAppVersion !== currentAppVersion) {
                    archive.archiveAppVersion = currentAppVersion; // it doesn't make sense to delete the data of structures and statistics, raw data was saved
                    // otherwise it takes effect on loxone control (no local structure)

                    /*var keys = Object.keys(archive.deviceList);
                    for (var i = 0; i < keys.length; i++) {
                        deleteAllStoredData(keys[i], false);
                    }*/
                    // -> delete only statistic data (eg. fronius got a few more stat outputs...)

                    var keys = Object.keys(archive.deviceList);

                    for (var i = 0; i < keys.length; i++) {
                        SandboxComponent.deleteAllStatisticData(keys[i]);
                    }
                }

                let archiveModified = false;
                archiveModified = weakThis._purgeInvalidTokens(archive);

                if (weakThis.msInfoStore.isAvailable) {
                    // using this call, we ensure that old archives loaded will be cleaned from confidential data right
                    // away - as the data is already stored in the miniserverInfoStore
                    if (weakThis._removeConfidentialData(archive)) {
                        console.warn("ArchiveExt", "removedConfidentialData from archive file loaded, already in secure storage!");
                        archiveModified = true;
                    }
                }
                archiveModified && weakThis.saveArchive();

                return persComp.extensionFinishedLoading(weakThis);
            });
        }, () => {
            // initialize archive
            archive = {
                archiveAppVersion: currentAppVersion,
                deviceList: {}
            };

            if (PlatformComponent.isDeveloperInterface()) {
                Q($.get("miniservers.local.env").then(function (deviceList) {
                    archive.deviceList = deviceList;
                    if (weakThis.hasFavoriteSettings(archive.deviceList)) {
                        weakThis.applyAndRemoveFavorites(archive.deviceList);
                        weakThis.saveArchive(archive.deviceList);
                    }
                }, function () {
                    developerAttention("Define file 'miniservers.local.env' in your project root and populate it for a default selection of Miniservers!");
                })).finally(function () {
                    persComp.extensionFinishedLoading(weakThis);
                });
            } else {
                persComp.extensionFinishedLoading(weakThis);
            }
        });
    } // Public methods

    /**
     * tells the Extension if it's necessary to save the password
     * @param shouldSave
     */


    ArchiveExt.prototype.setSavePassword = function setSavePassword(shouldSave) {
        savePassword = shouldSave;
    };
    /**
     * returns flag if save password is active
     * @returns {boolean}
     */


    ArchiveExt.prototype.getSavePassword = function getSavePassword() {
        return savePassword;
    };

    /**
     * Uses the list of miniservers provided to update the stored list
     * @param msList
     */
    ArchiveExt.prototype.updateMiniserverList = function updateMiniserverList(msList) {
        var deleteSerialFlags = {};
        var clonedList = cloneObjectDeep(archive.deviceList);

        // set up dataset to identify miniservers no longer in list.
        Object.keys(clonedList).forEach((storedSerial) => {
            deleteSerialFlags[storedSerial] = true;
        });

        // iterate over list to update delete flags and list index
        msList.forEach((ms, index) => {
            // still in list, keep
            deleteSerialFlags[ms.serialNo] = false;

            // updated listIdx
            clonedList[ms.serialNo].sortIdx = index;
        });

        // now remove all minservers no longer in this list.
        Object.keys(deleteSerialFlags).forEach((delSerial) => {
            if (deleteSerialFlags[delSerial]) {
                delete clonedList[delSerial];
            }
        })

        // store the updated dataSet
        this.saveArchive(clonedList);
    };

    /**
     * Saves the current archive object to the filesystem
     */


    ArchiveExt.prototype.saveArchive = function saveArchive(arch) {
        if (arch) {
            if (Array.isArray(arch)) {
                console.error(weakThis.name, "saveArchive failed - devices provides as array, not as object!");
                return Q.reject("saveArchive argument requires object instead of array!");
            } else {
                archive.deviceList = arch;
            }
        }

        if (archive) {
            let archClone = cloneObject(archive);
            if (weakThis.msInfoStore.isAvailable) {
                weakThis._removeConfidentialData(archClone);
            }

            var promise = persComp.saveFile(ARCHIVE_FILENAME, archClone, DataType.OBJECT);
            return promise.then(function () {
                Debug.Persistence && console.info("saved successfully the " + ARCHIVE_FILENAME);

                if (LoxoneControl.hasLoxoneControl()) {
                    LoxoneControl.archiveDidChange();
                }
            }, function (error) {
                console.error("couldn't save the archive, " + error);
            });
        } else {
            console.warn("Couldn't save the archive, no archive exists.");
            return Q(true);
        }
    };

    ArchiveExt.prototype.hasFavoriteSettings = function hasFavoriteSettings(deviceList) {
        return Object.values(deviceList).find(device => "favRating" in device);
    }

    ArchiveExt.prototype.applyAndRemoveFavorites = function applyAndRemoveFavorites(deviceList) {
        let maxFavRating = Number.MIN_VALUE;
        for (let serialNo in deviceList) {
            let ms = deviceList[serialNo];
            if ("favRating" in ms) {
                if (ms.favRating > maxFavRating) maxFavRating = ms.favRating;
                ms.sortIdx = ms.favRating;
            }
        }
        for (let serialNo in deviceList) {
            let ms = deviceList[serialNo];
            if ("favRating" in ms) {
                delete ms.favRating;
            } else if ("sortIdx" in ms) {
                ms.sortIdx = ms.sortIdx + 1 +maxFavRating;
            }
        }
    }

    /**
     * Will return a map of stored miniservers
     * @param sortedAsArray
     * @returns {object|array} result is miniserver map or the sorted array with the miniservers in it
     */


    ArchiveExt.prototype.getAllMiniserver = function getAllMiniserver(sortedAsArray) {
        var result = cloneObjectDeep(archive.deviceList);
        if (sortedAsArray) {
            result = mapToArrayOld(result);
            result.sort(function (a, b) {
                // if sorting is in place, make use of it.
                if (a.sortIdx !== b.sortIdx) {
                    if (a.hasOwnProperty("sortIdx") && b.hasOwnProperty("sortIdx")) {
                        // both have sort indexes, use them
                        return a.sortIdx < b.sortIdx ? -1 : 1;
                    } else if (a.hasOwnProperty("sortIdx")) {
                        // a has a sort index, b doesn't --> a before b
                        return -1;
                    } else if (b.hasOwnProperty("sortIdx")) {
                        // b has a sort index, a doesn't --> b before a
                        return 1;
                    }
                }

                // fall back to name sorting, if nothing is available
                if (a.msName != null && b.msName != null) {
                    return a.msName.localeCompare(b.msName);
                } else {
                    return 0; // keep order, cannot compare
                }
            });
        }

        return result;
    };


    ArchiveExt.prototype.hasMiniservers = function hasMiniservers() {
        var msList = this.getAllMiniserver(true);
        return Array.isArray(msList) && msList.length > 0;
    }
    /**
     * Would detect the last connected miniserver from the archive object
     * @returns {object} lastConnectedMSObject
     */


    ArchiveExt.prototype.getLastConnectedMiniserver = function getLastConnectedMiniserver(tempArchive) {
        var archiveToCheck = archive;

        if (tempArchive) {
            archiveToCheck = tempArchive;
        }

        var keys = Object.keys(archiveToCheck.deviceList);

        if (keys.length > 0) {
            var result = archiveToCheck.deviceList[keys[0]];

            for (var serialNo in archiveToCheck.deviceList) {
                if (archiveToCheck.deviceList.hasOwnProperty(serialNo)) {
                    if (result.lastConnectedDate < archiveToCheck.deviceList[serialNo].lastConnectedDate) {
                        result = archiveToCheck.deviceList[serialNo];
                    }
                }
            }

            return cloneObjectDeep(result);
        }

        return null;
    };
    /**
     * Will return a miniserver
     * @returns {object} result is miniserver map
     */


    ArchiveExt.prototype.getMiniserver = function getMiniserver(serialNo) {
        if (archive.deviceList[serialNo]) {
            return cloneObjectDeep(archive.deviceList[serialNo]);
        }

        return null;
    };
    /**
     * Will add the new miniserver to the archive
     * @param miniserver
     */


    ArchiveExt.prototype.addMiniserver = function addMiniserver() {
        var allMiniserver = PersistenceComponent.getAllMiniserver(),
            syncNeeded = false;
        Array.from(arguments).forEach(function (miniserver) {
            if (!miniserver.serialNo) {
                console.warn("WARNING: trying to add a new miniserver for undefined serialNo");
            } else {
                addMS(weakThis._cleanMs(miniserver), false);
                syncNeeded |= PersistenceComponent.backupAndSyncIsEnabled() && !allMiniserver[miniserver.serialNo] || allMiniserver[miniserver.serialNo] && allMiniserver[miniserver.serialNo].activeUser !== miniserver.activeUser;
            }
        }.bind(this));

        if (syncNeeded) {
            Debug.Sync && console.warn("### | addMiniserver | ###");
            PersistenceComponent.backupAndSyncSetBackup(archive.deviceList);
        }
    };
    /**
     * Will update an existing miniserver from the archive, all data will be synchronized
     * @param miniserver
     */


    ArchiveExt.prototype.updateMiniserver = function updateMiniserver(miniserver) {
        var allMiniserver = PersistenceComponent.getAllMiniserver();

        if (!miniserver.serialNo) {
            console.warn("WARNING: trying to update a new miniserver for undefined serialNo");
            return;
        }

        addMS(weakThis._cleanMs(miniserver), true).then(function () {
            if (PersistenceComponent.backupAndSyncIsEnabled() && (allMiniserver[miniserver.serialNo].activeUser !== miniserver.activeUser)) {
                Debug.Sync && console.warn("### | updateMiniserver | ###");
                PersistenceComponent.backupAndSyncSetBackup(archive.deviceList);
            }
        }.bind(this));
    };
    /**
     * Will create a clone of the ms object provided & will remove password and token if needed.
     * @param ms
     * @return {*}
     * @private
     */


    ArchiveExt.prototype._cleanMs = function _cleanMs(ms) {
        var cleanMs = cloneObject(ms); // clone it to ensure the original object remains unchanged!
        // ensure no sensible data is being stored withouth consent of the user

        if (!weakThis.getSavePassword()) {
            // saving credentials is not allowed.
            delete cleanMs.password;
            delete cleanMs.token;
        } else if (cleanMs.token) {
            // a token exists, no need to store a password.
            delete cleanMs.password;
        }

        return cleanMs;
    };
    /**
     * Will remove the given miniserver from the archive and save the archive afterwards
     * @param serialNo
     */


    ArchiveExt.prototype.removeMiniserver = function removeMiniserver(serialNo) {
        deleteAllStoredData(serialNo, true);
        delete archive.deviceList[serialNo];
        weakThis.saveArchive();

        if (PersistenceComponent.backupAndSyncIsEnabled()) {
            Debug.Sync && console.warn("### | removeMiniserver | ###");
            PersistenceComponent.backupAndSyncSetBackup(archive.deviceList);
        }
    };
    /**
     * Will update secureDetails in the miniserverInfoStore
     * @param {String} serialNo
     * @param {Object} secureDetails
     */


    ArchiveExt.prototype.updateSecuredDetails = function updateSecuredDetails(serialNo, secureDetails) {
        if (!serialNo) {
            console.error("WARNING: trying to update securedDetails for undefined serialNo");
            return;
        }

        var miniserver = archive.deviceList[serialNo];

        if (miniserver) {
            weakThis.msInfoStore.updateWith(miniserver, secureDetails);
        }
    };
    /**
     * Reads the secureDetails from the keychain
     * @param serialNo
     * @returns {Promise}
     */


    ArchiveExt.prototype.getSecuredDetails = function getSecuredDetails(serialNo) {
        Debug.SecuredDetails && console.log("ArchiveExt: getSecuredDetails: " + serialNo);
        return weakThis.msInfoStore.getSecuredDetailsFor(serialNo);
    };
    /**
     * Set a new password for a miniserver and save the archive afterwards
     * @param serialNo
     * @param password
     */


    ArchiveExt.prototype.setNewPassword = function setNewPassword(serialNo, password) {
        if (!serialNo) {
            console.warn("WARNING: trying to set a new password for undefined serialNo");
            return;
        }

        var miniserver = archive.deviceList[serialNo];
        miniserver.password = password;
        weakThis.saveArchive();
        weakThis.msInfoStore.updateWith(miniserver);
    };
    /**
     * Set a token for a miniserver and save the archive afterwards. Will delete
     * a potentially stored password.
     * @param serialNo
     * @param encToken         will store the (encrypted) token for this miniserver
     */


    ArchiveExt.prototype.setToken = function setToken(serialNo, encToken) {
        if (!serialNo) {
            console.warn("WARNING: trying to set a token for an undefined serialNo");
            return;
        }

        var miniserver = archive.deviceList[serialNo];

        if (miniserver) {
            miniserver.token = encToken;

            if (miniserver.token) {
                delete miniserver.password;
            }

            weakThis.saveArchive();
            weakThis.msInfoStore.updateWith(miniserver);
        } else {
            console.warn("WARNING: Token not stored, miniserver not found in archive.");
        }
    };
    /**
     * Will return true when a serial number has been provided and a device was stored along with this serial.
     * @param serialNo
     */


    ArchiveExt.prototype._verifyStoredDevice = function _verifyStoredDevice(serialNo) {
        return serialNo && archive.deviceList.hasOwnProperty(serialNo);
    };
    /**
     * Set a new public key for a miniserver and save the archive afterwards
     * @param serialNo
     * @param publicKey
     */


    ArchiveExt.prototype.setNewPublicKey = function setNewPublicKey(serialNo, publicKey) {
        if (!weakThis._verifyStoredDevice(serialNo)) {
            console.warn("WARNING: trying to set a new public key for undefined serialNo or unknown device. Snr=" + serialNo);
            return;
        }

        archive.deviceList[serialNo].publicKey = publicKey;
        weakThis.saveArchive();
    };
    /**
     * sets the brandingDate and saves the archive
     * @param serialNo
     * @param brandingDate
     */


    ArchiveExt.prototype.setBrandingDate = function setBrandingDate(serialNo, brandingDate) {
        if (!weakThis._verifyStoredDevice(serialNo)) {
            console.warn("WARNING: trying to set a new branding date for undefined serialNo or unknown device. Snr=" + serialNo);
            return;
        }

        archive.deviceList[serialNo].brandingDate = brandingDate;
        weakThis.saveArchive();
    };
    /**
     * Will delete information which is needed to establish a new connection and save the archive afterwards
     * All params which are true will be deleted
     * @param serialNo
     * @param user
     * @param pw
     * @param lastConnDate
     * @param token
     */


    ArchiveExt.prototype.deleteConnInfo = function deleteConnInfo(serialNo, user, pw, lastConnDate, token) {
        if (!serialNo) {
            console.warn("WARNING: trying to delete connection information for undefined serialNo");
            return;
        }

        var ms = archive.deviceList[serialNo];

        if (user) {
            ms.activeUser = null;
        }

        if (pw) {
            ms.password = null;
        }

        if (lastConnDate) {
            ms.lastConnectedDate = null;
        }

        if (token) {
            ms.token = null;
        }

        archive.deviceList[serialNo] = ms;

        if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface) {
            // This is essential for the Webinterfaces autologin!
            // Here we need to set Autologin to false, so when the user "signes out" we wont log him in again
            // the next time he reloads the Webinterface
            // The PreLoadLogin will take care of the autologin check!
            PersistenceComponent.setLocalStorageItem("autologin", false);
        }

        weakThis.msInfoStore.updateWith(ms);
        weakThis.saveArchive();
    };
    /**
     * Will delete all structures of every user of local and remote mode
     * @param serialNo of the miniserver
     * @param reachMode of the Miniserver
     */


    ArchiveExt.prototype.deleteAllStructures = function deleteAllStructures(serialNo, reachMode) {
        if (!serialNo) {
            console.warn("WARNING: trying to delete structures for undefined serialNo");
            return;
        }

        if (!archive.deviceList[serialNo]) {
            return;
        }

        var users = archive.deviceList[serialNo].userBox;

        for (var i = 0; i < users.length; i++) {
            var user = users[i];
            console.log("delete structures of " + user + " and ReachMode " + reachMode);

            if (reachMode !== undefined) {
                var filename = serialNo + "_" + user + "_" + ConvertReachMode(reachMode) + ".json";
                deleteFileWrapper(filename);
            } else {
                var filenameLocal = serialNo + "_" + user + "_" + ConvertReachMode(ReachMode.LOCAL) + ".json";
                var filenameRemote = serialNo + "_" + user + "_" + ConvertReachMode(ReachMode.REMOTE) + ".json";
                deleteFileWrapper(filenameLocal);
                deleteFileWrapper(filenameRemote);
            }
            ActiveMSComponent.deleteTrustStructure(serialNo, user);
        }
    };
    /**
     * Will delete the structure for the selected user
     * @param serialNo
     * @param reachMode
     * @param currentUsername
     */


    ArchiveExt.prototype.deleteStructureForUser = function deleteStructureForUser(serialNo, reachMode, currentUsername) {
        if (!serialNo) {
            console.warn("WARNING: trying to delete structures for undefined serialNo");
            return;
        }

        if (!archive.deviceList[serialNo]) {
            return;
        }

        var filenameLocal = serialNo + "_" + currentUsername + "_" + ConvertReachMode(ReachMode.LOCAL) + ".json";
        var filenameRemote = serialNo + "_" + currentUsername + "_" + ConvertReachMode(ReachMode.REMOTE) + ".json";
        deleteFileWrapper(filenameLocal);
        deleteFileWrapper(filenameRemote);
        ActiveMSComponent.deleteTrustStructure(serialNo, currentUsername);
    };
    /**
     * will reset the archive
     */


    ArchiveExt.prototype.resetArchive = function resetArchive() {
        console.warn("WARNING: resetting archive!");
        var currentAppVersion = PlatformComponent.getAppInfoObj().appVersion;
        archive = {
            archiveAppVersion: currentAppVersion,
            deviceList: {}
        };
        weakThis.saveArchive();
    };
    /**
     * Nulls all tokens we can not decrypt due to platformInfoObject uuid change.
     * This happens when switching from the current Cordova based desktop app to the
     * new Electron based desktop app (macOS and Windows)
     * @param archiveObj
     * @returns {boolean} true if an invalid token has been purged
     * @private
     */
    ArchiveExt.prototype._purgeInvalidTokens = function _purgeInvalidTokens(archiveObj) {
        // Purge all tokens we can not encode
        var archiveDidChange = false;
        Object.keys(archiveObj.deviceList).forEach(function (serialNo) {
            var ms = archiveObj.deviceList[serialNo];

            if (ms.token) {
                // Just try to decrypt the token, purge it if it is not possible,
                // this is due to a bug in Electron and won't hurt on other platforms as well.
                try {
                    // Depending on the platform it may directly throw an exception, or just return an empty string
                    if (!nullEmptyString(VendorHub.Crypto.decrypt(ms.token))) {
                        throw "Invalid token!"; // Just throw, to go to the catch and null the token
                    }
                } catch (e) {
                    ms.token = null;
                    archiveDidChange = true;
                }
            }
        });

        return archiveDidChange;
    };


    /**
     * Acquires token/password from the secure storage (if available) and resolves once they are ready - rejects when
     * they are not.
     * The token/password are then available in encrypted form on the MS object provided.
     * @param ms    object that will be populated with the token/pass props.
     * @returns {Q.Promise<{}>|*}
     */
    ArchiveExt.prototype.applyStoredAuthInfoForMiniserver = function applyStoredAuthInfoForMiniserver(ms) {
        return weakThis.msInfoStore.applyStoredAuthInfo(ms);
    }


    /**
     * Looks through the archive object passed in and deletes all confidential data from it
     * @param archiveObj will be modified during this operation
     * @returns {boolean} true if confidential data has been found.
     * @private
     */
    ArchiveExt.prototype._removeConfidentialData = function _removeConfidentialData(archiveObj) {
        var dataRemoved = false;
        Object.keys(archiveObj.deviceList).forEach(function (serialNo) {
            var ms = archiveObj.deviceList[serialNo];
            if (nullEmptyString(ms.token)) {
                dataRemoved = true;
                delete ms.token;
            }
            if (nullEmptyString(ms.password)) {
                dataRemoved = true;
                delete ms.password;
            }
            if (nullEmptyString(ms.trustToken)) {
                dataRemoved = true;
                delete ms.trustToken;
                console.log(this.name, "_removeConfidentialData did remove trustToken from MS!", ms);
            }
        });
        return dataRemoved;
    }


    // Private methods

    /**
     * Add a miniserver to the archive, an existing can be updated or it will add a new one
     * @param miniserver
     * @param updateExisting
     */


    var addMS = function addMS(miniserver, updateExisting) {
        var newMiniserver = cloneObjectDeep(miniserver); // otherwise the password isn't available for reconnecting

        if (!updateExisting) {
            newMiniserver.userBox = [newMiniserver.activeUser];
        } else {
            // miniserver with this serial number already exists, synchronize it
            var oldMiniserver = archive.deviceList[newMiniserver.serialNo];
            newMiniserver.userBox = oldMiniserver.userBox || [];
            newMiniserver.sortIdx = oldMiniserver.sortIdx;

            if (newMiniserver.userBox.indexOf(newMiniserver.activeUser) === -1) {
                newMiniserver.userBox.push(newMiniserver.activeUser);
            }
        }

        if (!savePassword || PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface) {
            delete newMiniserver.password;
        }

        archive.deviceList[newMiniserver.serialNo] = newMiniserver;
        weakThis.msInfoStore.checkAndUpdateWith(newMiniserver);
        return weakThis.saveArchive();
    };
    /**
     * deletes all stored data of a miniserver
     * @param serialNo of the miniserver
     * @param permanent if the miniserver is permanently removed
     */


    var deleteAllStoredData = function deleteAllStoredData(serialNo, permanent) {
        weakThis.deleteAllStructures(serialNo);
        SandboxComponent.deleteAllStatisticData(serialNo); // nur löschen, wenn Miniserver ganz gelöscht wird, da sich die UUIDs eh nicht ändern können (lt. Harald.)

        if (permanent) {
            ImageBox.deleteImageBoxOf(serialNo); // TODO-thallth, goelzda move to elsewhere! (emit cleanup event?)

            pushNotificationService.deleteMiniserverForMac(serialNo);
            removeQuickActionForMS(serialNo);
            weakThis.msInfoStore.removeDataOf(serialNo);
            AppBranding.deleteBrandingFile(serialNo);
        }
    };
    /**
     * This removes all the QuickActions from a specific miniserver with the given SN
     * @param serialNo The serial number of the miniserver
     */


    var removeQuickActionForMS = function removeQuickActionForMS(serialNo) {
        if (QuickActionUtility.hasQuickActions()) {
            var actions = QuickActionUtility.getItems(),
                action,
                i;

            for (i = 0; i < actions.length; i++) {
                action = actions[i];

                if (serialNo === action.serialNumber) {
                    QuickActionUtility.removeAction(action.uuid);
                }
            }
        } else {// This platform is not capable of performing 3D touch actions
        }
    };

    var deleteFileWrapper = function deleteFileWrapper(filename) {
        persComp.deleteFile(filename).then(function () {// deleted file
        }, function () {// couldn't delete file or wasn't stored
        });
    };


    // region MsInfoStore Handling

    class MsInfoStoreHandler {
        constructor() {
            this.name = "MsInfoStoreHandler";
        }

        get isAvailable() {
            return Boolean(window.miniserverInfoStore);
        }

        get deviceUuid() {
            return PlatformComponent.getPlatformInfoObj().uuid;
        }

        get supportsDeviceSpecificData() {
            // keytar on windows limits the storage to 2500 characters. (https://github.com/atom/node-keytar/issues/112)
            // Since we're exceeding this, it will fail.
            return !PlatformComp.isWindows();
        }

        _isStoredDataForActiveUser(storedUser, activeUser, serialNo) {
            if (nullEmptyString(storedUser) && storedUser !== "null") {
                if (storedUser === activeUser) {
                    return true;
                } else if (storedUser.indexOf("@" + serialNo) >= 0) {
                    let storedNamePart = storedUser.split("@" + serialNo)[0];
                    return storedNamePart === activeUser;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        applyStoredAuthInfo(ms) {
            if (this.isAvailable && ms.serialNo) {
                return this.getInfoFor(ms.serialNo).then((storedData) => {
                    if (!storedData) {
                        console.warn(this.name, "applyStoredAuthInfo - no storedData for " + ms.serialNo);
                        return;
                    }

                    // only proceed if the stored username matches the one of the ms provided!
                    if (!this._isStoredDataForActiveUser(storedData.username, ms.activeUser, ms.serialNo)) {
                        console.log(this.name, "applyStoredAuthInfo - username missmatch! Won't retrieve! Stored=" + storedData.username + ", ms=" + ms.activeUser);
                        return;
                    }

                    if (nullEmptyString(storedData.token) && storedData.token !== "null") {
                        ms.token = VendorHub.Crypto.encrypt(storedData.token);
                    }
                    if (!ms.token && nullEmptyString(storedData.password) && storedData.password !== "null") {
                        ms.password = VendorHub.Crypto.encrypt(storedData.password);
                    }
                    if (!ms.token && !ms.password && nullEmptyString(storedData.trustToken)) {
                        console.warn(this.name, "applyStoredAuthInfo - snr=" + ms.serialNo+ ": trustToken exists in storedDate, but not loaded into miniserver!");

                    }
                    console.log(this.name, "applyStoredAuthInfo - loaded for " + ms.serialNo+ ": token=" + !!ms.token + ", password=" + !!ms.password + ", username=" + ms.activeUser);

                }, (err) => {
                    console.warn(this.name, "applyStoredAuthInfo - failed to load for " + ms.serialNo);
                    console.error(this.name, JSON.stringify(err));
                });
            } else {
                return Q.resolve(); // if no ms store is available, the data must be present in the MS obj, or it isn't available.
            }
        }

        /**
         * Loads the currently stored msInfo object and compares the stored info with the one of the MS provided.
         * If changes are detected, the data is updated!
         * @param newMs
         * @returns {Q.Promise<unknown>}
         */
        checkAndUpdateWith(newMs) {
            Debug.MsInfoStore && console.log(this.name, "checkAndUpdateWith: " + newMs.serialNo);
            return this._readMiniserverInfoStore(newMs.serialNo, true).then((readResult) => {
                let newStoreData = this._extractStorePropsFromMsObj(newMs);
                let needsUpdate = false;
                let changes = [];

                if (readResult) {
                    const checkProp = (prop, onlyIfProvided = false) => {
                        let changed = newStoreData[prop] !== readResult[prop];
                        if (onlyIfProvided) { // e.g. localSecuredDetails are only available when connected locally.
                            changed = changed && newStoreData.hasOwnProperty(prop);
                        }
                        Debug.MsInfoStore && changed && changes.push(prop);
                        return changed;
                    }
                    needsUpdate = needsUpdate || checkProp("mac");
                    needsUpdate = needsUpdate || checkProp("token");
                    needsUpdate = needsUpdate || checkProp("password");
                    needsUpdate = needsUpdate || checkProp("username");
                    needsUpdate = needsUpdate || checkProp("localSecuredDetails", true);
                    needsUpdate = needsUpdate || checkProp("remoteSecuredDetails", true);
                    needsUpdate = needsUpdate || checkProp("publicKey");
                    needsUpdate = needsUpdate || checkProp("localUrl");
                    needsUpdate = needsUpdate || checkProp("remoteUrl");
                    needsUpdate = needsUpdate || checkProp("configVersion");

                    // device info isn't stored on the MS object, it is patched in the _extractDeviceSpecificData-Fn
                    // right before persisting --> hence check here.
                    if (readResult.devInfo !== getStableDeviceInfo()) {
                        needsUpdate = true;
                        changes.push("devInfo");
                    }

                    // ensure that the data is stored separately for this device, if not done already
                    if (!needsUpdate && readResult.noSpecificDeviceData) {
                        changes.push("--no-specifics-stored-yet--");
                        needsUpdate = true;
                    }

                } else {
                    changes = ["--nothing-stored-before--"];
                    needsUpdate = true;
                }

                if (!PlatformComp.isMobileDevice() && !needsUpdate) {
                    // e.g. on mac/win, the keytar update is used to trigger to set the selectedMS in the trayMenu
                    // (LoxoneControl) which in turn is key to showing something inside the statusbar menu. Unless
                    // reworked, on desktop, the stored info must always be updated. BG-I22117
                    changes = ["--desktop-app-loxone-control-init--"];
                    needsUpdate = true;
                }

                if (needsUpdate) {
                    Debug.MsInfoStore && console.log(this.name, "checkAndUpdateWith: " + newMs.serialNo + " => true! Changed: " + changes.join(","));
                    this.updateWith(newMs);
                } else {
                    Debug.MsInfoStore && console.log(this.name, "checkAndUpdateWith: " + newMs.serialNo + " => false, already stored");
                }
            }, () => {
                Debug.MsInfoStore && console.log(this.name, "checkAndUpdateWith: " + newMs.serialNo + " => true - noting stored!");
                this.updateWith(newMs);
            })
        }

        /**
         * sets the info in the native store for this Miniserver (iOS -> keychain)
         * This method is called everytime a connection to a miniserver is established - while doing so no securedDetails
         * are being passed along. The miniserver object for the connection inside the javascript is stored in the "Archive".
         * But the Archive does never store securedDetails.
         * @param miniserver        used in the native part to send commands to the Miniserver (NFC/QuickActions)
         * @param [securedDetails] optional, if not provided, the stored secured details will remain untouched.
         */
        updateWith(miniserver, securedDetails) {
            // if miniserver information store is available, also set to native keychain! (e.g. quick actions, LXControl, ...)
            if (this.isAvailable) {
                var info = this._extractStorePropsFromMsObj(miniserver, securedDetails, false);
                Debug.MsInfoStore && console.log(this.name, "updateWith of " + miniserver.serialNo + ", token=" + !!miniserver.token + ", password=" + !!miniserver.password);

                if (!this.supportsDeviceSpecificData) {
                    Debug.MsInfoStore && console.log(this.name, "   --> deviceSpecificData unsupported!");
                    this.__updateStore(info).then(null, err => {
                        console.error(this.name, "updateWith: failed - " + err.message);
                    });
                    return;
                }

                // device specific data needs to be stored separately to avoid conflicts due to keychain sync (e.g. on iOS)
                const newDeviceSpecificData = this._extractDeviceSpecificData(info);

                this._readMiniserverInfoStore(miniserver.serialNo, false).then((storedData) => {
                    if (storedData && storedData.deviceSpecificData) {
                        Debug.MsInfoStore && console.log(this.name, "updateWith of " + miniserver.serialNo + " --> deviceSpecificData exists, update!");
                        info.deviceSpecificData = JSON.parse(storedData.deviceSpecificData);
                    } else {
                        Debug.MsInfoStore && console.log(this.name, "updateWith of " + miniserver.serialNo + " --> no deviceSpecificData yet, initialize!");
                        info.deviceSpecificData = {};
                    }
                }, () => {
                    Debug.MsInfoStore && console.log(this.name, "updateWith of " + miniserver.serialNo + " --> no data stored for ms yet, initialize!");
                    info.deviceSpecificData = {};

                }).finally(() => {
                    info.deviceSpecificData[this.deviceUuid] = info.deviceSpecificData[this.deviceUuid] || {}
                    Object.assign(info.deviceSpecificData[this.deviceUuid], newDeviceSpecificData);
                    info.deviceSpecificData = JSON.stringify(info.deviceSpecificData);
                    Debug.MsInfoStore && console.log(this.name, "updateWith of " + miniserver.serialNo + " do update the store now!");
                    this.__updateStore(info).then(null, err => {
                        console.error(this.name, "updateWith: failed - " + err.message);
                    });
                })

            }
        }

        /**
         * removes info from the native store for the Miniserver with this serialNo (iOS -> keychain)
         * Ensures that it doesn't fully clear it, if there's still info stored for other devices
         * @param serialNo
         */
        removeDataOf(serialNo) {
            if (this.isAvailable) {
                Debug.MsInfoStore && console.log(this.name, "removeDataOf of " + serialNo);
                let def = Q.defer();

                if (!this.supportsDeviceSpecificData) {
                    Debug.MsInfoStore && console.log(this.name, "  ---> no support for deviceSpecificData!");
                    this.__clearStore(serialNo);
                    def.resolve("Cleared (no device specific data supported)");
                    return;
                }

                this._readMiniserverInfoStore(serialNo, false).then(storedData => {
                    if (!storedData) {
                        def.resolve("Nothing stored!");
                        return;
                    }
                    let specificData = this._getDeviceSpecificData(storedData);

                    // if there is data stored for this device, delete it.
                    if (specificData) {
                        delete specificData[this.deviceUuid];
                    }

                    // check if specific data for other devices is still present, if so update, otherwise clear
                    if (specificData && Object.keys(specificData).length > 0) {
                        Debug.MsInfoStore && console.log(this.name, "removeDataOf of " + serialNo + " > other device data present, update!");
                        // if there is still data stored for another device, update the storage!
                        storedData.deviceSpecificData = JSON.stringify(specificData);
                        this.__updateStore(storedData).then(null, err => {
                            console.error(this.name, "removeDataOf - failed to update storage!" + ex.message);
                        });
                        def.resolve("Updated (remaining info for other devices)");

                    } else {
                        // no more data, clear!
                        Debug.MsInfoStore && console.log(this.name, "removeDataOf of " + serialNo + " > no further info stored, clear!");
                        this.__clearStore(serialNo);
                        def.resolve("Cleared (no info for other devices)");
                    }

                }, () => {
                    // Nothing to read, nothing to clear!
                    def.resolve("Failed to read, not clearing!");
                });

                return def.promise;
            } else {
                return Q.resolve();
            }
        }

        /**
         * This method ensures that there are no parallel calls to read from the storage, if multiple calls are made, they
         * are combined into one read.
         * @param msSerial
         * @returns {*}
         */
        getInfoFor(msSerial) {
            this._readMsInfoStorePromises = this._readMsInfoStorePromises || {};
            if (!this._readMsInfoStorePromises.hasOwnProperty(msSerial)) {
                this._readMsInfoStorePromises[msSerial] = this._readMiniserverInfoStore(msSerial).finally(() => {
                    delete this._readMsInfoStorePromises[msSerial];
                });
            }
            return this._readMsInfoStorePromises[msSerial];
        }


        getSecuredDetailsFor(serialNo) {
            Debug.MsInfoStore && console.log(this.name, "getSecuredDetails: " + serialNo);

            if (serialNo) {
                try {
                    return this.getInfoFor(serialNo).then((data) => {
                        Debug.MsInfoStore && console.log(this.name, "getSecuredDetails: loaded for " + serialNo);
                        return this._readSecuredDetailsForReachMode(data, CommunicationComponent.getCurrentReachMode());
                    });
                } catch (ex) {
                    console.error(this.name, "getSecuredDetailsFor: Could not load info store for '" + serialNo + " - no secured details!");
                    console.error(this.name, ex.message);
                }
            } else {
                console.error(this.name, "WARNING: getSecuredDetailsFor called with undefined serialNo");
            }

            return Q.reject(false);
        }
        /**
         * Will read the securedDetails for a specific reachMode from a msInfoStore-Object
         * @param data      the object from the msInfoStore
         * @param reachMode what reachmode we need the secure details for.
         * @returns {*}
         */
        _readSecuredDetailsForReachMode(data, reachMode) {
            var securedDetails = null;
            Debug.MsInfoStore && console.log(this.name, "_readSecuredDetailsForReachMode: " + reachMode);

            if (reachMode === ReachMode.LOCAL) {
                securedDetails = data.localSecuredDetails ? JSON.parse(data.localSecuredDetails) : null;
                Debug.MsInfoStore && console.log(this.name, "      return local secure details: " + (securedDetails !== null));
            } else if (reachMode === ReachMode.REMOTE) {
                securedDetails = data.remoteSecuredDetails ? JSON.parse(data.remoteSecuredDetails) : null;
                Debug.MsInfoStore && console.log(this.name, "      return remote secure details: " + (securedDetails !== null));
            } else {
                throw new Error("Cannot decide between internal or external secured details! " + reachMode);
            }

            if (securedDetails !== null && (typeof securedDetails !== "object" || Object.keys(securedDetails).length === 0)) {
                throw new Error("Invalid securedDetails, either empty or not an object.");
            }

            return securedDetails;
        }

        /**
         * Grabs the info-store object from a miniserver identified by it's serial (if available), async - hence the promise.
         * @param serialNo
         * @param shoudMapDeviceSpecifics - if false, it won't "flatten" the result, i.e. merge the devicespecific data onto the result
         * @returns {Q.Promise<unknown>}
         */
        _readMiniserverInfoStore(serialNo, shoudMapDeviceSpecifics = true) {
            Debug.MsInfoStore && console.log(this.name, "_readMiniserverInfoStore: " + serialNo + ", mapSpecifics=" + !!shoudMapDeviceSpecifics + " - start (async)!");
            if (this.isAvailable) {
                return this.__readStore(serialNo).then((msData) => {
                    Debug.MsInfoStore && console.log(this.name, "_readMiniserverInfoStore: " + serialNo + ", mapSpecifics=" + !!shoudMapDeviceSpecifics + " >> done");
                   return shoudMapDeviceSpecifics ? this._mapDeviceSpecificDataOnResult(msData) : msData;
                });

            } else {
                return Q.reject("MiniserverInfoStore does not exist");
            }
        }

        /**
         * Returns a parsed version of the deviceSpecificData stored inside an msInfoStore entry provided.
         * @param msStoreObj
         * @returns {null|any}
         */
        _getDeviceSpecificData(msStoreObj) {
            if (msStoreObj.deviceSpecificData) {
                try {
                    return JSON.parse(msStoreObj.deviceSpecificData);
                } catch (ex) {
                    console.error(this.name, "_getDeviceSpecificData - failed to parse info! " + ex.message);
                    return null;
                }
            } else {
                return null;
            }
        }

        /**
         * Checks the provided msStore-Object of a miniserver, looks up potentially stored device specific data and maps
         * them it onto the returned object
         * @param storedData e.g.: { publicKey: xxx, deviceSpecificData: { devUuidA: {token: tkA}}}
         * @returns {*} e.g. { publicKey: xxx, token: tkA }
         */
        _mapDeviceSpecificDataOnResult(storedData) {
            if (!storedData || !this.supportsDeviceSpecificData) {
                return storedData;
            }
            let returnedData = cloneObjectDeep(storedData);

            // to ensure that initially the device-specific data will be stored during checkAndUpdate, add a flag if no
            // data could be read & the default/generic auth info is used. (flag = noSpecificDeviceData)
            if (returnedData && returnedData.deviceSpecificData) {
                try {
                    const currDeviceData = JSON.parse(returnedData.deviceSpecificData)[this.deviceUuid];
                    if (currDeviceData) {
                        Debug.MsInfoStore && console.log(this.name, "_mapDeviceSpecificDataOnResult - assign device specific data!");
                        Debug.MsInfoStore && currDeviceData.token !== returnedData.token && console.log(this.name, "    - token device-specific");
                        Debug.MsInfoStore && currDeviceData.username !== returnedData.username && console.log(this.name, "    - device-specific user");
                        Debug.MsInfoStore && currDeviceData.password !== returnedData.password && console.log(this.name, "    - device-specific password");
                        Object.assign(returnedData, currDeviceData);
                        delete returnedData.deviceSpecificData;
                    } else {
                        Debug.MsInfoStore && console.log(this.name, "_mapDeviceSpecificDataOnResult - no data found specific to this device, use base info!");
                        returnedData.noSpecificDeviceData = true;
                    }
                } catch (ex) {
                    console.error(this.name, "_mapDeviceSpecificDataOnResult - failed to extract deviceSpecificData " + ex.message);
                    returnedData.noSpecificDeviceData = true;
                }
            } else {
                Debug.MsInfoStore && console.log(this.name, "_mapDeviceSpecificDataOnResult - NO deviceSpecificData found in ms store entry");
                returnedData.noSpecificDeviceData = true;
            }

            return returnedData;
        }


        /**
         * Grabs information that is to be stored per device from the info-object provided & returns it as object to be
         * stored separately.
         * @param info
         * @returns {{password, localSecuredDetails: *, remoteSecuredDetails: *, username, token}}
         */
        _extractDeviceSpecificData(info) {
            return {
                localSecuredDetails: info.localSecuredDetails,
                remoteSecuredDetails: info.remoteSecuredDetails,
                username: info.username,
                password: info.password,
                token: info.token,
                devInfo: info.devInfo || getStableDeviceInfo() // stored additionally for potential future use.
            }
        }

        /**
         * Used to create an object to be stored into the secure msInfoStore. Will extract values from the miniserver-object
         * and secured details provided.
         * @param miniserver
         * @param securedDetails
         * @returns {{password: (*|null), configVersion: *, name, remoteUrl, publicKey, tlsInfo: *, mac, username, token: (*|null), localUrl}}
         */
        _extractStorePropsFromMsObj(miniserver, securedDetails) {
            if (!miniserver) {
                return {};
            }
            var info = {
                mac: miniserver.serialNo,
                name: miniserver.msName,
                username: miniserver.activeUser,
                password: typeof miniserver.password === "string" ? VendorHub.Crypto.decrypt(miniserver.password) : null,
                // the password may be null after logging of and receiving a new structure... will be updated later on
                token: typeof miniserver.token === "string" ? VendorHub.Crypto.decrypt(miniserver.token) : null,
                // the token may be null after logging off and receiving a new structure... will be updated later on
                localUrl: miniserver.localUrl,
                remoteUrl: miniserver.remoteUrl,
                publicKey: miniserver.publicKey,
                configVersion: miniserver.configVersion || ActiveMSComponent.getConfigVersion(),
                tlsInfo: miniserver.tlsInfo
            };

            if (securedDetails) {
                var reachMode = CommunicationComponent.getCurrentReachMode();

                if (reachMode === ReachMode.LOCAL) {
                    info.localSecuredDetails = securedDetails;
                } else if (reachMode === ReachMode.REMOTE) {
                    info.remoteSecuredDetails = securedDetails;
                } else {
                    console.error("Cannot update the secure details - cannot decide between local and remote - resetting both!");
                    info.localSecuredDetails = {};
                    info.remoteSecuredDetails = {};
                }
            }
            // Validate the Structure, only String keys and values are allowed!
            Object.keys(info).forEach(key => {
                let value = info[key];

                if (typeof value !== "string") {
                    info[key] = JSON.stringify(value);
                }
            });
            return info;
        }


        __readStore(snr) {
            let def = Q.defer();
            miniserverInfoStore.read(def.resolve, def.reject, snr);
            return def.promise;
        }

        __updateStore(data) {
            let def = Q.defer();
            try {
                miniserverInfoStore.update(def.resolve, def.reject, data);
            } catch (ex) {
                console.error(this.name, "__updateStore call failed! " + ex.message);
                console.error(this.name, " - tried to save data with length: " + JSON.stringify(data).length);
                return def.reject();
            }
            return def.promise;
        }

        __clearStore(snr) {
            let def = Q.defer();
            miniserverInfoStore.clear(def.resolve, def.reject, snr);
            return def.promise;
        }
    }

    // endregion

    return ArchiveExt;
}]);
