'use strict';

PersistenceComp.factory('PersistenceExt', ['FileSystemAdapter', function (FileSystemAdapter) {
    var ProcessType = {
        SAVE: 0,
        READ: 1,
        DELETE: 2
    }; // internal variables

    let weakThis,
        persComp = {},
        persQueue = [],
        lowPrioQueue = [],
        currentFileOperation = null,
        fsIsBusy = true; // has to be true until our platformComp is ready. Then we'll start loading stuff.

    /**
     * c-tor for the Persistence Extension
     * @param comp reference to the parent component
     * @constructor
     */

    function PersistenceExt(comp) {
        weakThis = this
        persComp = comp;

        if (FileSystemAdapter.isFilesystemReady()) {
            Debug.Persistence && console.log("PersistenceExt:      our filesystem says it's ready, let's go!");
            setFileSystemReady();
        } else {
            console.error("PersistenceExt", "PlatformComponent is ready - but the filesystem is not!");
        }
    } // Public methods

    /**
     * Saves a file in the filesystem
     * @param fileName name of the file
     * @param data content of the file
     * @param dataType {DataType.OBJECT | DataType.STRING} dataType data type of the content
     * @param [priority] {Priority.LOW | Priority.HIGH} when not provided - normal is assumed, otherwise it can be high or low.
     * @returns {promise} promise if the file was successfully written to the filesystem
     */


    PersistenceExt.prototype.saveFile = function saveFile(fileName, data, dataType, priority) {
        var binary, fileOperation;

        if (dataType === DataType.FILE) {
            // get binary data from object
            binary = btoa(data);
        } else if (dataType === DataType.OBJECT) {
            binary = JSON.stringify(data);
        } else {
            binary = data;
        }

        fileOperation = createFileOperationObj(fileName, ProcessType.SAVE, dataType, binary, priority);

        _handleNewFileOperation(fileOperation);

        return fileOperation.defer.promise;
    };
    /**
     * Loads a file from the filesystem
     * @param fileName name of the file
     * @param dataType data type of the content
     * @param [priority] {Priority.LOW | Priority.HIGH} when not provided - normal is assumed, otherwise it can be high or low.
     * @returns {promise} promise containing the file if it was successful
     */


    PersistenceExt.prototype.loadFile = function loadFile(fileName, dataType, priority) {
        var fileOperation = createFileOperationObj(fileName, ProcessType.READ, dataType, null, priority);

        _handleNewFileOperation(fileOperation);

        return fileOperation.defer.promise;
    };

    PersistenceExt.prototype.deleteFile = function deleteFile(fileName, priority) {
        var fileOperation = createFileOperationObj(fileName, ProcessType.DELETE, null, null, priority);

        _handleNewFileOperation(fileOperation);

        return fileOperation.defer.promise;
    }; // private methods


    var _handleNewFileOperation = function _handleNewFileOperation(operation) {
        Debug.Persistence && console.log("PersistenceExt: _handleNewFileOperation: " + _translateFileOperation(operation), getStackObj());

        if (!fsIsBusy && FileSystemAdapter.isFilesystemReady()) {
            Debug.Persistence && console.log("    fs ready, go ahead!");
            currentFileOperation = operation;

            _processCurrentFileOperation();
        } else {
            switch (operation.priority) {
                case Priority.HIGH:
                    persQueue.splice(0, 0, [operation]);
                    break;

                case Priority.LOW:
                    lowPrioQueue.push(operation);
                    break;

                default:
                    persQueue.push(operation);
                    break;
            }

            Debug.Persistence && console.log("    fs busy processing: " + _translateFileOperation(currentFileOperation) + ", enqueue [" + persQueue.length + " in queue, " + lowPrioQueue.length + " have low prio]");
        }
    };

    var _translateFileOperation = function _translateFileOperation(queueObj) {
        var result = "-";

        if (queueObj) {
            result = _translateProcessType(queueObj.processType);
            result += " " + queueObj.filename;

            if (queueObj.hasOwnProperty("dataType")) {
                result += " (" + queueObj.dataType + ")";
            }

            result += " PRIO=";

            switch (queueObj.priority) {
                case Priority.HIGH:
                    result += "HIGH";
                    break;

                case Priority.LOW:
                    result += "LOW";
                    break;

                default:
                    result += "REGULAR";
                    break;
            }
        }

        return result;
    };
    /**
     * Try to load or save the current object to the filesystem
     */


    var _processCurrentFileOperation = function _processCurrentFileOperation() {
        Debug.Persistence && console.log("PersistenceExt: _processCurrentFileOperation: " + _translateFileOperation(currentFileOperation));
        fsIsBusy = true;

        switch (currentFileOperation.processType) {
            case ProcessType.SAVE:
                _processSaveFile();

                break;

            case ProcessType.READ:
                _processReadFile();

                break;

            case ProcessType.DELETE:
                _processDeleteFile();

                break;
        }
    };

    var _processSaveFile = function _processSaveFile() {
        Debug.Persistence && console.log("PersistenceExt: processing save: " + currentFileOperation.filename);
        var adapterPromise = FileSystemAdapter.saveFile(currentFileOperation.filename, currentFileOperation.content);
        adapterPromise.then(function (result) {
            Debug.Persistence && console.log("PersistenceExt      file save success: " + currentFileOperation.filename);

            _resolveCurrentAndContinue(result);
        }, function (error) {
            Debug.Persistence && console.error("PersistenceExt      file save error: " + currentFileOperation.filename);

            _rejectCurrentAndContinue(error);
        });
    };

    var _processReadFile = function _processReadFile() {
        Debug.Persistence && console.log("PersistenceExt: processing read: " + currentFileOperation.filename);
        var adapterPromise = FileSystemAdapter.loadFile(currentFileOperation.filename);
        adapterPromise.then(function (result) {
            Debug.Persistence && console.log("PersistenceExt      file read success: " + currentFileOperation.filename);
            var object = {};

            if (currentFileOperation.dataType === DataType.FILE) {
                // get object from binary data
                object = atob(result);

                _resolveCurrentAndContinue(object);
            } else if (currentFileOperation.dataType === DataType.OBJECT) {
                try {
                    object = JSON.parse(result);

                    _resolveCurrentAndContinue(object);
                } catch (exc) {
                    console.error("Failed to load file from FS because: " + exc);

                    _rejectCurrentAndContinue(exc);
                }
            } else {
                object = result;

                _resolveCurrentAndContinue(object);
            }
        }, function (error) {
            Debug.Persistence && console.error("PersistenceExt      file read error: " + currentFileOperation.filename);

            _rejectCurrentAndContinue(error);
        });
    };

    var _processDeleteFile = function _processDeleteFile() {
        Debug.Persistence && console.log("PersistenceExt: processing delete: " + currentFileOperation.filename);
        var adapterPromise = FileSystemAdapter.deleteFile(currentFileOperation.filename);
        adapterPromise.then(function (result) {
            Debug.Persistence && console.log("PersistenceExt: file delete sucess: " + currentFileOperation.filename);

            _resolveCurrentAndContinue(result);
        }, function (error) {
            Debug.Persistence && console.error("PersistenceExt: file delete error: " + currentFileOperation.filename);

            _rejectCurrentAndContinue(error);
        });
    };

    var _resolveCurrentAndContinue = function _resolveCurrentAndContinue(result) {
        Debug.Persistence && console.log("PersistenceExt: _resolveCurrentAndContinue");
        currentFileOperation.defer.resolve(result);
        currentFileOperation = null;
        setFileSystemReady();
    };

    var _rejectCurrentAndContinue = function _rejectCurrentAndContinue(error) {
        Debug.Persistence && console.error("PersistenceExt: _rejectCurrentAndContinue");
        currentFileOperation.defer.reject(error);
        currentFileOperation = null;
        setFileSystemReady();
    };
    /**
     * Will return a human readable string for the process type given. For debugging purposes only.
     * @param processType
     * @return {*}
     * @private
     */


    var _translateProcessType = function _translateProcessType(processType) {
        var result;

        switch (processType) {
            case ProcessType.READ:
                result = "read";
                break;

            case ProcessType.DELETE:
                result = "delete";
                break;

            case ProcessType.SAVE:
                result = "save";
                break;

            default:
                result = "-unknown-";
                break;
        }

        return result;
    };
    /**
     * Check if the filesystem is ready or to process the next object of the queue
     */


    var setFileSystemReady = function setFileSystemReady() {
        Debug.Persistence && console.log("PersistenceExt: setFileSystemReady->true"); //console.log("setFileSystemReady");

        if (persQueue.length > 0 && currentFileOperation === null) {
            Debug.Persistence && console.log("PersistenceExt       the queue isn't empty, process next");
            currentFileOperation = persQueue.shift();

            _processCurrentFileOperation();
        } else if (lowPrioQueue.length > 0 && currentFileOperation === null) {
            Debug.Persistence && console.log("PersistenceExt       the low prio queue isn't empty, process next");
            currentFileOperation = lowPrioQueue.shift();

            _processCurrentFileOperation();
        } else {
            Debug.Persistence && console.log("PersistenceExt       the queue is empty. Done for now"); //console.log("file system is ready again");

            fsIsBusy = false;
        }
    };
    /**
     * Creates an object to process the current request (save, read or delete a file)
     * @param filename
     * @param processIdentifier content for saving or identifier for reading and deleting
     * @param [dataType] binary, string or object for saving and loading
     * @param [payload] only required if processIdentifier SAVE is used.
     * @param [priority] {Priority.LOW | Priority.HIGH} when not provided - normal is assumed, otherwise it can be high or low
     * @returns {{}}
     */


    var createFileOperationObj = function createFileOperationObj(filename, processIdentifier, dataType, payload, priority) {
        // create promise
        var def = Q.defer(); // create the queue object

        var object = {
            filename: filename,
            defer: def,
            dataType: dataType,
            processType: processIdentifier,
            priority: priority || Priority.REGULAR
        };

        if (processIdentifier === ProcessType.SAVE) {
            if (payload == null) {
                def.reject("No payload given to save");
            } else {
                object.content = payload;
            }
        }

        return object;
    };

    return PersistenceExt;
}]);
