'use strict';

define("TextInputControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class TextInputControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.text = newVals[this.control.states.text].text;
        }

        getStateTextShort() {
            return this._checkStateText();
        }

        getStateColor() {
            if (this.states.text.trim().length <= 0) {
                return window.Styles.colors.orange;
            }
        }

        _checkStateText() {
            var stateText = this.states.text;
            return stateText.trim().length > 0 ? stateText : _("controls.textinput.no-text");
        }

    };
});
