'use strict';

import React from "react"

define("SpotPriceOptimizerControlStateContainer",
    [
        "ControlStateContainer",
        "SpotPriceOptimizerControlEnums",
        "./utils/index.js",
        "IconLib"
    ], function (
        ControlStateContainer,
        SpotPriceOptimizerControlEnums,
        {
            getStatsForDataPoints,
            getColorFor,
            getHumanReadableCategorization,
            categorizeValue,
            Categorization
        }, {
            default: {
                Gauge
            }
        }
    ) {

    return class SpotPriceOptimizerControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(...arguments);
        }

        /**
         * handles incoming values and prepares the states
         * @param newVals
         */
        async prepareStates(newVals) {
            this.states.active = !!newVals[this.control.states.active];
            this.states.current = newVals[this.control.states.current];
            this.states.demand = newVals[this.control.states.demand];
            this.states.period = newVals[this.control.states.period];
            this.states.manualMax = newVals[this.control.states.manualMax];
            this.states.forecastTimestamp = newVals[this.control.states.forecastsTimestamp];
            this.states.cycleFrom = newVals[this.control.states.cycleFrom];
            this.states.cycleUntil = newVals[this.control.states.cycleUntil];
            this.states.isArmed = !!this.states.cycleFrom && !!this.states.cycleUntil;

            let msNow = new LxDate(),
                startUnix = msNow.clone().startOf("day").unix(),
                endUnix = msNow.clone().endOf("day").unix();
            const forecast = await this.control.getForecast({
                forecastTimestamp: this.states.forecastTimestamp,
                startUnix,
                endUnix
            });
            this.states.statsForCurrentForecast = getStatsForDataPoints(forecast.map(({v}) => v));
            this.states.forecast = await this.control.getForecast({
                forecastTimestamp: this.states.forecastTimestamp
            });
        }

        getStateText() {
            return lxUnitConverter.convertAndApply(this.control.details.format, this.states.current);
        }

        getStateColor() {
            return getColorFor({
                value: this.states.current,
                stats: this.states.statsForCurrentForecast
            }, this.states.manualMax);
        }
    };
});
