'use strict';

define("FroniusControlStateContainer", ["ControlStateContainer", "FroniusControlEnums"], function (ControlStateContainer, FroniusControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var MeterModes = {
        // enum of values based on the meterMode bitmask
        Offline: 0,
        Prod: 1,
        Cons: 2,
        ProdCons: 3,
        Batt: 4,
        BattProd: 5,
        BattCons: 6,
        BattConsProd: 7
    };
    return class FroniusControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            var uuids = this.control.states; // generatorType 0 == Fronius, 1 == Object Inputs, 2 == Kostal

            this.states.generatorType = newVals[uuids.generatorType]; // online state --> input generators are always marked as online.

            this.states.isOnline = newVals[uuids.online] === 1 || this.states.generatorType === FroniusControlEnums.GeneratorType.Inputs;
            this.states.inStandby = newVals[uuids.prodStatus] === FroniusControlEnums.Status.STANDBY;
            this.states.currProduction = newVals[uuids.prodCurr];
            this.states.productionDay = newVals[uuids.prodCurrDay];
            this.states.productionMonth = newVals[uuids.prodCurrMonth];
            this.states.productionYear = newVals[uuids.prodCurrYear];
            this.states.productionTotal = newVals[uuids.prodTotal];
            this.states.currConsumption = newVals[uuids.consCurr];
            this.states.consumptionDay = newVals[uuids.consCurrDay];
            this.states.earningsDay = newVals[uuids.earningsDay];
            this.states.earningsMonth = newVals[uuids.earningsMonth];
            this.states.earningsYear = newVals[uuids.earningsYear];
            this.states.earningsTotal = newVals[uuids.earningsTotal];
            this.states.priceExport = newVals[uuids.priceDelivery];
            this.states.priceImport = newVals[uuids.priceConsumption];
            this.states.co2Factor = newVals[uuids.co2Factor];
            this.states.status = newVals[uuids.prodStatus];
            this.states.error = newVals[uuids.prodError];
            this.states.currImport = this.states.currConsumption - this.states.currProduction;
            this.states.meterMode = newVals[uuids.mode];
            this.states.hasEnergyMeter = hasBit(this.states.meterMode, MeterModes.Cons);
            this.states.hasProduction = hasBit(this.states.meterMode, MeterModes.Prod);

            if (Feature.ENERGY_MONITOR_BATTERY) {
                this.states.currGrid = newVals[uuids.gridCurr];
                this.states.currBattery = newVals[uuids.batteryCurr];
                this.states.stateOfCharge = roundN(newVals[uuids.stateOfCharge], 2); // when it comes to percent, the rest isn't important.

                this.states.hasBattery = hasBit(this.states.meterMode, MeterModes.Batt); // that is the way it should be.
            } else {
                this.states.currGrid = 0; // important as the fronius view

                this.states.hasBattery = false;
            }
        }

        /**
         * the energy monitor will activate/deactivate certain statistic outputs depending on the state.
         * @returns {*}
         */
        getStatisticOutputs() {
            var allOutputs = [];

            try {
                allOutputs = this.control.statistic.outputs;

                if (Feature.ENERGY_MONITOR_9_STATS) {
                    if (!this.states.hasBattery) {
                        allOutputs[FroniusControlEnums.StatisticOutput.BATT_CHARGE].visuType = Statistic.Type.NOT_SUPPORTED;
                        allOutputs[FroniusControlEnums.StatisticOutput.BATT_POWER].visuType = Statistic.Type.NOT_SUPPORTED;
                    }

                    if (!this.states.hasEnergyMeter) {
                        allOutputs[FroniusControlEnums.StatisticOutput.GRID_POWER].visuType = Statistic.Type.NOT_SUPPORTED;
                        allOutputs[FroniusControlEnums.StatisticOutput.GRID_DELIVERY_ENERGY].visuType = Statistic.Type.NOT_SUPPORTED;
                        allOutputs[FroniusControlEnums.StatisticOutput.GRID_CONS_ENERGY].visuType = Statistic.Type.NOT_SUPPORTED;
                        allOutputs[FroniusControlEnums.StatisticOutput.CONS_POWER].visuType = Statistic.Type.NOT_SUPPORTED;
                        allOutputs[FroniusControlEnums.StatisticOutput.CONS_ENERGY].visuType = Statistic.Type.NOT_SUPPORTED;
                    }
                }
            } catch (ex) {
                console.error("Could not determine statistic outputs!");
            }

            return allOutputs;
        }

        getStateTextForContent() {
            return null; // in the content, there is no state text!
        }

        getStateText() {
            var args = {
                    production: lxFormat(FroniusControlEnums.VALUE_FORMAT, this.states.currProduction),
                    consumption: lxFormat(FroniusControlEnums.VALUE_FORMAT, this.states.currConsumption)
                },
                prodText,
                consText;

            if (!this.states.isOnline) {
                // generator offline, adopt production argument.
                args.production = this.states.inStandby ? args.production : _('offline');
            }

            prodText = _("controls.energy-monitor.prod-short", args);
            consText = _("controls.energy-monitor.cons-short", args);

            if (!this.states.isOnline && !this.states.hasEnergyMeter) {
                // both the generator are offline and there's no energy meter --> Offline
                return _('offline');
            } else if (this.states.hasEnergyMeter) {
                // the generator might even be offline, but there's an energy meter --> P: Offline C: xx kWh
                return prodText + SEPARATOR_SYMBOL + consText;
            } else {
                // theres no energymeter, only the production
                return prodText;
            }
        }

        getStateColor() {
            var isActive = this.states.currProduction > 0 || this.states.currImport > 0;

            if (isActive && this.states.isOnline) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateInfo() {
            var result = null; // if the generator is offline and the animation view isn't shown, display it as a red state info.

            if (!this.states.isOnline && !this.states.hasEnergyMeter) {
                result = {
                    message: _("offline"),
                    color: window.Styles.colors.red
                };
            }

            return result;
        }

    };
});
