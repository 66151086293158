'use strict';

LxServerComp.factory('NewsHubExt', function () {
    var language = PlatformComponent.getLanguage(),
        langHasRegion = language.indexOf("-") >= 0,
        finalLanguage = langHasRegion ? language.replace("-", "") : language + language,
        NEWS_HUB_URL = 'https://www.loxone.com/loxone-feed.php?channel=app&lang=' + finalLanguage,
        // Please refer to David Gölzhäuser for more info!
        //NEWS_HUB_URL = 'https://idoodler.de/newsHub/', // Just for debug to test the App without marketing interaction
        NEWS_HUB_FILENAME = "newsHub.json",
        UPDATE_TIMEOUT_IN_H = 12,
        NOTIFICATION_BLOCK_DURATION = 24; // Hours

    let weakThis,
        comp,
        newsHub = null,
        updateTimer,
        newsHubOpened = false;

    /**
     * c-tor of the News Extension
     * @param lxServerComp reference to the parent component
     * @constructor
     */

    function NewsHubExt(lxServerComp) {
        weakThis = this
        comp = lxServerComp;
        PersistenceComponent.loadFile(NEWS_HUB_HAS_BEEN_OPENED_FILE, DataType.OBJECT).then(function (res) {
            newsHubOpened = res;
        });
        CompChannel.on(CCEvent.CrucialDataLoaded, weakThis.checkNewsHub);
    }

    /**
     * Fetches the news hub file from the loxone server
     * Also start a timeout to have it periodically, this is useful if a tablet is running all the time
     */


    NewsHubExt.prototype.checkNewsHub = function checkNewsHub() {
        // We want to automatically check the news in UPDATE_TIMEOUT_IN_H Hours again this comes in handy if the device is
        // continuously running as a wall tablet
        clearTimeout(updateTimer); //TODO-goelzda: will this ever stop?

        updateTimer = setTimeout(weakThis.checkNewsHub.bind(weakThis), 1000 * 60 * 60 * UPDATE_TIMEOUT_IN_H);
        getCachedNewsHub().then(function (lastNewsHub) {
            newsHub = lastNewsHub; // Check if the blocked until time has been surpassed and delete the entry

            if (newsHub.blockUntil && moment(newsHub.blockUntil).diff(moment(), "seconds") < 0) {
                delete newsHub.blockUntil;
            } // Check if the cached news aga is older then UPDATE_TIMEOUT_IN_H hours we won't fetch any new news if it is younger


            var lastFetch = moment(newsHub.lastFetch),
                currentTime = moment(),
                diff = currentTime.diff(lastFetch, "hours");

            if (diff >= UPDATE_TIMEOUT_IN_H) {
                weakThis.fetchNews();
                this.setNewsHubOpened(false);
            } else {
                Debug.NewsHub && console.info("NewsHub has been checked " + diff + " hours ago, don't check for the next " + (UPDATE_TIMEOUT_IN_H - diff) + " hours.");
                notifyAboutNewNewsHubEntries.call(weakThis);
            }
        }.bind(weakThis), weakThis.fetchNews.bind(weakThis));
    };

    NewsHubExt.prototype.fetchNews = function fetchNews() {
        // Wait 10 seconds to ensure most of the important network traffic is over
        // This also improves the experience for users on slow networks
        setTimeout(function () {
            var promise = $.ajax({
                method: 'get',
                url: NEWS_HUB_URL,
                cache: false,
                success: loxoneNewsHubReceived.bind(weakThis),
                error: function failedToLoadNewsHub(reason) {
                    console.error('could not load News Hub file because: ' + reason.message);
                }
            });
            Debug.Communication && CommTracker.track(promise, CommTracker.Transport.LXSVR, NEWS_HUB_URL);
        }.bind(weakThis), 10 * 1000);
    };
    /**
     * Registers for internal changes of the number of unread news
     * @returns {function} function to deregister from this event
     */


    NewsHubExt.prototype.registerForNewsHubChanges = function registerForNewsHubChanges(listener) {
        if (newsHub) {
            // if there are already entries, return them immediately
            var latestNews = weakThis.getLatestNewsHubEntries();
            listener(null, latestNews);
        }

        return comp.on(LxServerComp.ECEvent.NewsChanged, listener);
    };
    /**
     * Fetches the latest entries currently known to the system
     * @returns {*|Array} array of news entries
     */


    NewsHubExt.prototype.getLatestNewsHubEntries = function getLatestNewsHubEntries() {
        return cloneObject(newsHub);
    };
    /**
     * Callback when news entries have been opened and thus are no longer unread
     * @param {number} newsId id of the read news entry.
     */


    NewsHubExt.prototype.onNewsHubEntryOpened = function onNewsHubEntryOpened(newsId) {
        if (newsHub) {
            // Delete the opened news from the newIds array
            var idIdx = newsHub.newIds.indexOf(newsId);

            if (idIdx > -1) {
                newsHub.newIds.splice(idIdx, 1);
            }

            notifyAboutNewNewsHubEntries.call(weakThis);
            saveNewsHubFile(newsHub);
        }
    };
    /**
     * Blocks the news notification
     * This function sets the blockUntil property of the newsHub to now + 24 hours
     * This prevents any news related notification from being shown
     */


    NewsHubExt.prototype.blockNotifications = function blockNotifications() {
        if (newsHub) {
            newsHub.blockUntil = +moment().add(NOTIFICATION_BLOCK_DURATION, "hours");
            saveNewsHubFile(newsHub);
        }
    };

    NewsHubExt.prototype.getNewsHubOpened = function getNewsHubOpened() {
        return newsHubOpened;
    };

    NewsHubExt.prototype.setNewsHubOpened = function setNewsHubOpened(opened) {
        newsHubOpened = opened;
        PersistenceComponent.saveFile(NEWS_HUB_HAS_BEEN_OPENED_FILE, newsHubOpened, DataType.OBJECT);
    };

    NewsHubExt.prototype.hideNewsHubNotification = function hideNewsHubNotification() {
        weakThis.notification && weakThis.notification.remove();
    };
    /**
     * We got News!
     * • Check if we already have cached news
     * • Save news if not
     * • Compare the cached news with the newly received news,
     *  • delete unread news if they have been deleted on the server side
     * • emit the newly cached news
     * @param feedData
     */


    var loxoneNewsHubReceived = function loxoneNewsHubReceived(feedData) {
        var newNewsHub = {
            lastFetch: +moment(),
            newIds: [],
            feed: sanitizeFeedData(feedData)
        }; // Check if we got any cached news

        getCachedNewsHub().then(function (lastNewsHub) {
            var newFeedEntries = newNewsHub.feed,
                lastFeedEntries = lastNewsHub.feed,
                lastFeedIds = []; // Only keep existing news as new news

            lastNewsHub.newIds.forEach(function (lastNewId) {
                var existingNewNews = newNewsHub.feed.filter(function (newEntry) {
                    return newEntry.timestamp === lastNewId;
                });

                if (existingNewNews.length > 0) {
                    newNewsHub.newIds.push(lastNewId);
                }
            }); // Save the old, cached news timestamps in an array to know what newly received news are new

            lastFeedEntries.forEach(function (lastEntry) {
                lastFeedIds.push(lastEntry.timestamp);
            }); // Check if the last news still exist in the newly received news,

            newFeedEntries.forEach(function (newEntry) {
                if (lastFeedIds.indexOf(newEntry.timestamp) === -1) {
                    newNewsHub.newIds.push(newEntry.timestamp);
                }
            });
            newsHub = newNewsHub;
            saveNewsHubFile(newNewsHub);
            notifyAboutNewNewsHubEntries.call(weakThis);
        }, function (error) {
            // We don't have any cached news, save the received news
            // We always set the first news as unread, so the user must not read 10 news if he opens the app for the first time
            newNewsHub.newIds.push(newNewsHub.feed[0].timestamp);
            newsHub = newNewsHub;
            saveNewsHubFile(newsHub);
            notifyAboutNewNewsHubEntries.call(weakThis);
        });
    };

    var getCachedNewsHub = function getCachedNewsHub() {
        return PersistenceComponent.loadFile(NEWS_HUB_FILENAME, DataType.OBJECT);
    };

    var saveNewsHubFile = function saveNewsHubFile(loxoneFeed) {
        // save newsIndex with a delay, so no important process will be blocked
        setTimeout(function () {
            PersistenceComponent.saveFile(NEWS_HUB_FILENAME, loxoneFeed, DataType.OBJECT);
        }, 1000);
    };
    /**
     * Emits the onNewsReceived event, also handles what Notification should be showed
     */


    var notifyAboutNewNewsHubEntries = function notifyAboutNewNewsHubEntries() {
        // Only emit the news received event if the news changed
        if (newsHub && (!weakThis._notifiedNewsHub || JSON.stringify(weakThis._notifiedNewsHub) !== JSON.stringify(newsHub))) {
            // We don't want to display notifications anymore,
            // they are appearing to frequent, that's why we don't show them until we know a better way

            /*if (!newsHub.blockUntil) {
                 // Give the user a break, don't show the notification in the first 11 seconds
                // We don't want to interrupt the users flow when he just opening the garage and is
                // using the app for a few seconds
                setTimeout(handleNewsNotification.bind(this), 1000 * 11);
            }*/
            // We still want to emit that we have new news.
            // The NewsHubScreen and GroupCardScreen are bound to this event and will perform necessary actions
            comp.onNewsReceived(newsHub);
            weakThis._notifiedNewsHub = cloneObject(newsHub);
        }
    };
    /**
     * Displays new news notification
     * Already displayed news notifications will be removed and the updated notification can be displayed
     */


    var handleNewsNotification = function handleNewsNotification() {
        var newNewsCnt = newsHub.newIds.length;

        if (newNewsCnt > 0) {
            var newsId = getNotificationNewsId(); // Remove the current notification if any and the newsIDs don't match, hence it is not the same news

            if (weakThis.currentNewsEntryId && weakThis.currentNewsEntryId !== newsId) {
                weakThis.notification && weakThis.notification.remove();
            }

            if (weakThis.currentNewsEntryId !== newsId) {
                weakThis.notification = GUI.Notification.createGeneralNotification(getNotificationPayload(), NotificationType.INFO); // Use the type INFO, we need a gray background
                // Show the NewsHubScreen on a click on the notification

                weakThis.notification.on(GUI.Notification.CLICK_EVENT, onNotificationClick.bind(weakThis));
                weakThis.notification.on(GUI.Notification.MARK_AS_READ_EVENT, weakThis.blockNotifications.bind(weakThis));
            }

            weakThis.currentNewsEntryId = newsId;
        } else {
            // Just remove any notification if we have no news, eg. the news got read
            weakThis.notification && weakThis.notification.remove();
        }
    };
    /**
     * Returns the ID used to identify notification
     * @returns {*}
     */


    var getNotificationNewsId = function getNotificationNewsId() {
        var newsId = null,
            newNewsCnt = newsHub.newIds.length; // Check if we have multiple news

        if (newNewsCnt > 1) {
            newsId = "multiple" + newNewsCnt;
        } else {
            // Get the newly received news title
            newsId = newsHub.newIds[0];
        }

        return newsId;
    };
    /**
     * Returns the payload for displaying the News notification
     * @returns {{iconSrc: string, title: *, subtitle: *, clickable: boolean, closeable: boolean}}
     */


    var getNotificationPayload = function getNotificationPayload() {
        var messageText = null,
            subtitle = null,
            newNewsCnt = newsHub.newIds.length; // Check if we have multiple news

        if (newNewsCnt > 1) {
            messageText = _('menu.miniserver.news');
            subtitle = _('news-hub.unread-news', {
                cnt: newNewsCnt
            });
        } else {
            // Get the newly received news title
            var newNews = newsHub.feed.filter(function (newsEntry) {
                return newsEntry.timestamp === newsHub.newIds[0];
            })[0]; // Only one timestamp should match the first news entry

            messageText = newNews.title;
        }

        return {
            iconSrc: Icon.Notification.NEWS_HUB,
            title: messageText.decodeHtmlEntities(),
            subtitle: subtitle,
            clickable: true,
            closeable: true
        };
    };
    /**
     * Handles clicks on Notifications
     * If only one Notification is new the click triggers the navigation to the blog link, if more
     * Notifications are unread the NewsHubScreen will be displayed instead
     */


    var onNotificationClick = function onNotificationClick() {
        // The user clicked on the notification, lets hide it!
        weakThis.notification.remove();

        if (newsHub.newIds.length === 1) {
            // 1. Get the first news entry, we only have one new news entry, so lets use idx = 0
            var firstNewsEntry = newsHub.feed.filter(function (newsEntry) {
                return newsEntry.timestamp === newsHub.newIds[0];
            })[0]; // 2. set the first new news entry as read, the user clicked on the notification

            weakThis.onNewsHubEntryOpened(firstNewsEntry.timestamp); // 3. Directly open the first and single news entry in the in-app browser

            NavigationComp.openWebsite(firstNewsEntry.link);
        } else {
            // Just open the NewsHubScreen to display all news entries
            NavigationComp.showState(ScreenState.NewsHubScreen);
        }
    };
    /**
     * Sanitize the feed data
     * It will add the correct protocol to the news link if the protocol is missing
     */


    var sanitizeFeedData = function sanitizeFeedData(feedData) {
        if (feedData.hasOwnProperty("error-code")) {
            console.warn("NewsHubExt couldn't fetch data: " + feedData["error-code"] + " (" + feedData["error-message"] + ")");
            return [];
        }

        feedData.forEach(function (entry) {
            if (!entry.link.hasPrefix("http://") && !entry.link.hasPrefix("https://")) {
                entry.link = "https://" + entry.link;
            }
        });
        return feedData;
    };

    return NewsHubExt;
});
