'use strict';

define("AlarmClockControlStateContainer", ["ControlStateContainer", "AlarmClockControlEnums"], function (ControlStateContainer, AlarmClockControlEnums) {
    return class AlarmClockControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        /**
         * This control needs the UTC offset to correctly calculate the nextEntry time for the UI
         * @return {boolean}
         */
        shouldRegisterForUTCUpdate() {
            return true;
        }

        prepareStates(newVals) {
            this.states.nextAlarmOperatingMode = newVals[this.control.states.nextEntryMode]; // Attention: EntryList is an Object, not an Array!

            this.states.entryList = JSON.parse(newVals[this.control.states.entryList].text);
            this.states.isNightLightOnline = true;
            Object.keys(this.states.entryList).forEach(function (entryId) {
                this.states.entryList[entryId].id = entryId;
            }.bind(this)); // Sort the entries and convert it to an array for easier handling

            this.states.sortedEntries = Object.values(this.states.entryList).sort(function (l, r) {
                return l.alarmTime - r.alarmTime;
            });

            if (Feature.ALARM_CLOCK_NIGHT_LIGHT) {
                this.states.deviceState = newVals[this.control.states.deviceState];
            } else {
                this.states.deviceState = AlarmClockControlEnums.DeviceState.NO_DEVICE;
            } // This function must be called after assigning the deviceState


            this._convertFaultyEntry();

            if (this.control.states.isEnabled) {
                this.states.isEnabled = !!newVals[this.control.states.isEnabled];
            } else {
                this.states.isEnabled = true;
            }

            Object.values(this.states.entryList).forEach(function (entry) {
                entry.isControlDisabled = !this.states.isEnabled;

                if (entry.nightLight) {
                    this.states.isNightLightOnline = !entry.isOffline;
                }
            }.bind(this));
            this.states.currentEntry = this.states.entryList[newVals[this.control.states.currentEntry]] || null;
            this.states.nextEntry = this.states.entryList[newVals[this.control.states.nextEntry]] || null;
            this.states.ringingTime = newVals[this.control.states.ringingTime];
            this.states.ringDuration = newVals[this.control.states.ringDuration];
            this.states.prepareDuration = newVals[this.control.states.prepareDuration];
            this.states.snoozeTime = newVals[this.control.states.snoozeTime];
            this.states.snoozeDuration = newVals[this.control.states.snoozeDuration];
            this.states.isSnoozing = this.states.snoozeTime > 0 && this.states.currentEntry && this.states.currentEntry.isActive; // There is an issue with the Miniserver:
            //  If we manually snooze the alarm the ringingTime wont be set to 0, so isSnoozing and isRinging would be both active
            // TODO-goelzda Remove this bugfix, once it is fixed by the config-team.

            this.states.isRinging = this.states.ringingTime > 0 && this.states.currentEntry && this.states.currentEntry.isActive && !this.states.isSnoozing;
            this.states.isActive = this.states.isRinging || this.states.isSnoozing;

            if (Feature.ALARM_CLOCK_NEXT_ENTRY_TIME) {
                this.states.nextEntryTime = newVals[this.control.states.nextEntryTime];
            }

            if (Feature.ALARM_CLOCK_NIGHT_LIGHT_DEVICE_SETTINGS) {
                this.states.deviceSettings = JSON.parse(newVals[this.control.states.deviceSettings].text);
            } else {
                // Our defined default Value if no Touch NightLight is connected
                this.states.deviceSettings = {};
            }

            if (Feature.ALARM_CLOCK_ADVANCED_SOUND_OPTIONS) {
                this.states.wakeAlarmSoundSettings = JSON.parse(newVals[this.control.states.wakeAlarmSoundSettings].text);
            } else {
                this.states.wakeAlarmSoundSettings = {};
            }
        }

        getStateText() {
            if (!this.states.isEnabled) {
                return null; // Return null to don't show the action screen subtitle
            } else if (this.states.isRinging) {
                return _('controls.alarmClock.activeAlarm');
            } else if (this.states.isSnoozing) {
                return _('controls.alarmClock.snooze');
            } else if (!this.states.isEnabled) {
                return _('controls.alarmClock.disabled.title');
            } else if (this.states.nextEntry) {
                return this.control.getNextEntryDateText();
            } else if (!this.states.nextEntry) {
                return _('controls.alarmClock.noActiveEntry');
            }
        }

        getStateTextShort() {
            if (!this.states.isEnabled) {
                return _('controls.alarmClock.disabled.title');
            } else if (this.states.isRinging) {
                return _('controls.alarmClock.activeAlarm');
            } else if (this.states.isSnoozing) {
                return _('controls.alarmClock.snooze');
            } else if (!this.states.isEnabled) {
                return _('controls.alarmClock.disabled.title');
            } else if (this.states.nextEntry) {
                return this.control.getNextEntryDateText(true);
            }

            return this.getStateText();
        }

        getStateInfo() {
            if (this.states.isRinging || this.states.isSnoozing) {
                return {
                    title: this.control.getActiveEntryDateText(),
                    message: this.states.currentEntry.name
                };
            } else if (!this.states.isNightLightOnline) {
                return {
                    message: _('controls.alarmClock.touchNightLight.offline', {
                        productName: _("controls.alarmClock.touchNightLight.product-name")
                    }),
                    color: window.Styles.colors.red
                };
            } else if (!this.states.isEnabled) {
                return {
                    title: _('controls.alarmClock.disabled.title'),
                    message: _('controls.alarmClock.disabled.text')
                };
            }
        }

        getStateColor() {
            if (this.states.isRinging) {
                return window.Styles.colors.stateActive;
            } else if (!this.states.isEnabled) {
                return window.Styles.colors.red;
            }
        }

        getStateTextColor() {
            if (!this.states.nextEntry) {
                return Color.INACTIVE;
            } else if (this.states.isRinging) {
                return window.Styles.colors.stateActive;
            } else if (!this.states.isActive && this.states.isEnabled) {
                return window.Styles.colors.stateActive;
            } else if (!this.states.isEnabled) {
                return window.Styles.colors.red;
            } else if (this.states.isSnoozing) {
                return window.Styles.colors.orange;
            }
        }

        getStateIconSmall() {
            if (this.states.isSnoozing) {
                return {
                    iconSrc: Icon.AlarmClock.SNOOZE,
                    color: window.Styles.colors.orange
                };
            } else if (!this.states.isEnabled) {
                return {
                    iconSrc: Icon.AlarmClock.DISABLE,
                    color: window.Styles.colors.red
                };
            }
        }

        /**
         * Returns an array of object with at least a "name" and "id" property
         * @param sourceState
         * @return {*[]}
         */
        getAutomaticDesignerStateObjectsFromState(sourceState) {
            var objectStates;

            switch (sourceState) {
                case "entryList":
                    objectStates = Object.values(this.states[sourceState]).map(function (alarmEntry) {
                        return {
                            id: alarmEntry.id,
                            name: alarmEntry.name
                        };
                    });
                    break;

                default:
                    objectStates = super.getAutomaticDesignerStateObjectsFromState(...arguments);
            }

            return objectStates;
        }

        /**
         * Check if the first entry is a NightLight Touch entry,
         * then check if the Miniserver is running the minimum required version.
         * If NightLight Touch entry will be converted to a non NightLight Touch entry if the Miniserver
         * is running an older version due to incompatible states (entry.nightLight was a string, not a bool)
         * @private
         */
        _convertFaultyEntry() {
            var entryListKeys = Object.keys(this.states.entryList || {});

            if (entryListKeys.length > 0) {
                var firstEntry = this.states.entryList[entryListKeys[0]];

                if (firstEntry && firstEntry.hasOwnProperty("nightLight")) {
                    if (Feature.ALARM_CLOCK_NIGHT_LIGHT) {
                        // Directly add the offline state to the entry to make it more comfortable to access
                        firstEntry.isOffline = this.states.deviceState === AlarmClockControlEnums.DeviceState.OFFLINE;
                    } else {
                        // Delete the nightLight property from every entry to convert it to a non NightLight Touch entry
                        // The nightLight property holds a string. the string "false" is true, this will destroy our logic
                        // manually delete this property, to simulate a pre NightLight state.
                        for (var entryKey in this.states.entryList) {
                            if (this.states.entryList.hasOwnProperty(entryKey)) {
                                delete this.states.entryList[entryKey].nightLight;
                            }
                        }
                    }
                }
            }
        }

    };
});
