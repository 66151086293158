'use strict';

import Icons from "IconLib";

SandboxComp.factory('MenuSettingsExt', function () {
    return class MenuSettings extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.isWebinterface = PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface;
        }

        /**
         * @param showStateFn
         * @returns {Array}
         */
        getAppSettings(showStateFn) {
            var rows = [];
            rows.pushObject(this.getLoxoneControlMenuBar(showStateFn));
            rows.pushObject(this.getSecurityItem(showStateFn));
            rows.push(this.getPresentationSettingsItem(showStateFn));
            return rows;
        }

        getLoxoneControlMenuBar(showStateFn) {
            var isDevIF = PlatformComponent.getPlatformInfoObj().platform === PlatformType.DeveloperInterface;

            if (LoxoneControl.hasLoxoneControl() || isDevIF) {
                return {
                    action: function () {
                        this._showState(ScreenState.LoxoneControlSettings, showStateFn);
                    }.bind(this),
                    uuid: ScreenState.LoxoneControlSettings,
                    content: {
                        leftIconSrc: Icon.TRIOLOGY_MENU,
                        reactLeftIconSrc: Icons.LoxoneTrilogy,
                        title: _("loxone-control.menu-bar"),
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand,
                        clickable: true
                    }
                };
            } else {
                return null;
            }
        }

        getSecurityItem(showStateFn) {
            if (BiometricHelper.hasBiometricSensor) {
                return {
                    action: function () {
                        if (BiometricHelper.hasEnrolledBiometrics) {
                            this._showState(ScreenState.SecuritySettings, showStateFn);
                        } else {
                            var title = _('fingerprint.touch-id-non-enrolled.title'),
                                message = _('fingerprint.touch-id-non-enrolled.message');

                            if (PlatformComponent.getBiometricType() === PlatformComponent.BiometricType.FACE_ID) {
                                title = _('fingerprint.face-id-non-enrolled.title');
                                message = _('fingerprint.face-id-non-enrolled.message');
                            }

                            NavigationComp.showPopup({
                                title: title,
                                message: message,
                                icon: Icon.INFO,
                                color: window.Styles.colors.orange,
                                buttonOk: true
                            });
                        }
                    }.bind(this),
                    uuid: ScreenState.SecuritySettings,
                    content: {
                        leftIconSrc: Icon.Menu.SECURITY,
                        reactLeftIconSrc: Icons.KeyholeCircled,
                        title: _("menu.settings.app.security.title"),
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand,
                        clickable: true
                    }
                };
            } else {
                return null;
            }
        }

        getPresentationSettingsItem(showStateFn) {
            return {
                keywords: _('search.presentation-settings.keywords').split(', '),
                action: function () {
                    this._showState(ScreenState.PresentationSettings, showStateFn);
                }.bind(this),
                uuid: ScreenState.PresentationSettings,
                content: {
                    leftIconSrc: Icon.Menu.APPEARANCE_MENU,
                    reactLeftIconSrc: Icons.PaintUtils,
                    title: _('menu.settings.app.presentation'),
                    disclosureIcon: false,
                    leftIconColor: window.Styles.colors.brand,
                    clickable: true
                }
            };
        }

        /**
         * Returns an array containing all the settings that exist for a miniserver (general, user, admin)
         * @param showStateFn
         * @returns {Array}
         */
        getMiniserverSettings(showStateFn) {
            var settings = this.getGeneralMiniserverSettings(showStateFn);
            settings = settings.concat(this.getMiniserverUserSettings(showStateFn));
            return settings;
        }

        getEcoModeSettingsItem(showStateFn) {
            // EcoScreen
            if (HD_APP || AMBIENT_MODE) {
                return {
                    action: function () {
                        this._showState(ScreenState.EcoScreenSettingsScreen, showStateFn);
                    }.bind(this),
                    uuid: ScreenState.EcoScreenSettingsScreen,
                    content: {
                        leftIconSrc: Icon.Menu.ECO,
                        reactLeftIconSrc: Icons.MoonAndStars,
                        title: _("screensaver.name"),
                        clickable: true,
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand
                    }
                };
            }

            return null;
        }

        getAmbientModeSettingsItem(showStateFn) {
            // EcoScreen
            if (HD_APP) {
                return {
                    action: function () {
                        this._showState(ScreenState.AmbientScreen, showStateFn);
                    }.bind(this),
                    uuid: ScreenState.AmbientScreen,
                    content: {
                        leftIconSrc: Icon.Menu.AMBIENT,
                        reactLeftIconSrc: Icons.AmbientLined,
                        title: _("ambient.name"),
                        clickable: true,
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand
                    }
                };
            }

            return null;
        }

        /**
         * @param showStateFn
         * @returns {Array}
         */
        getGeneralMiniserverSettings(showStateFn) {
            var rows = [];
            rows.pushObject(this.getNotificationItem(showStateFn));
            rows.pushObject(this.getEntryPointSelectionItem(showStateFn));
            rows.pushObject(this.getEcoModeSettingsItem(showStateFn));
            rows.pushObject(this.getAmbientModeSettingsItem(showStateFn));
            /*
                            rows.push({
                                action: function () {
                                    this._showState(ScreenState.ArrangementSettings, showStateFn);
                                }.bind(this),
                                uuid: ScreenState.ArrangementSettings,
                                content: {
                                    leftIconSrc: Icon.Menu.SORT,
                                    title: _('menu.settings.arrangement'),
                                    clickable: true,
                                    disclosureIcon: true
                                }
                            });
                             rows.push({
                                action: function () {
                                    this._showState(ScreenState.HomeScreenSettings, showStateFn);
                                }.bind(this),
                                uuid: ScreenState.HomeScreenSettings,
                                content: {
                                    leftIconSrc: Icon.Menu.HOME_SCREEN,
                                    title: _('menu.settings.app.home.editor'),
                                    clickable: true,
                                    disclosureIcon: true
                                }
                            });
            */

            if (!this.isWebinterface) {
                /*
                rows.push({
                    action: function () {
                        this._showState(ScreenState.FavoritesSettings, showStateFn);
                    }.bind(this),
                    uuid: ScreenState.FavoritesSettings,
                    content: {
                        leftIconSrc: Icon.Menu.FAVORITES,
                        title: _("favorites.title"),
                        clickable: true,
                        disclosureIcon: true
                    }
                }); */
                rows.pushObject(this.getDeviceFavoritesSettingsItem(showStateFn));
            }

            return rows;
        }

        getDeviceFavoritesSettingsItem(showStateFn) {
            if (ActiveMSComponent.isExpertModeLightEnabled() && !NavigationComp.getCurrentActivityType()) {
                return {
                    action: function () {
                        this._showState(ScreenState.DeviceFavoritesSettings, showStateFn);
                    }.bind(this),
                    uuid: ScreenState.FavoritesSettings,
                    content: {
                        leftIconSrc: Icon.Menu.FAVORITES_MENU,
                        reactLeftIconSrc: Icons.Star,
                        title: _("device-favorites.menu.title"),
                        clickable: true,
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand
                    }
                };
            } else {
                return null;
            }
        }

        getEntryPointSelectionItem(showStateFn) {
            return {
                action: function () {
                    this._showState(ScreenState.EntryPointLocationSelection, showStateFn);
                }.bind(this),
                uuid: ScreenState.EntryPointLocationSelection,
                content: {
                    leftIconSrc: Icon.Menu.ENTRY_POINT_MENU,
                    reactLeftIconSrc: Icons.PinWithCross,
                    title: _("entry-point.name"),
                    clickable: true,
                    disclosureIcon: false,
                    leftIconColor: window.Styles.colors.brand
                }
            };
        }

        getBackupAndSyncItem(showStateFn) {
            var platform = PlatformComponent.getPlatformInfoObj().platform;

            if (window.hasOwnProperty("syncPlugin") || platform === PlatformType.DeveloperInterface) {
                return {
                    action: function () {
                        this._showState(ScreenState.BackupAndSyncSetupViewController, showStateFn, {
                            type: BackupAndSync.MENU
                    });
                    }.bind(this),
                    uuid: ScreenState.BackupAndSyncWelcome,
                    content: {
                        leftIconSrc: Icon.BACKUP_AND_SYNC,
                        reactLeftIconSrc: Icons.ArrowCircle,
                        title: _("backup-and-sync.menu"),
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand,
                        clickable: true
                    }
                };
            }
        }

        getHomeKitItem(showStateFn) {
            var platform = PlatformComponent.getPlatformInfoObj().platform,
                isSupported = ActiveMSComponent.doesActiveMiniserverSupportHomeKit();

            if (isSupported && (platform === PlatformType.IOS && window.hasOwnProperty("homeKitPlugin") || platform === PlatformType.DeveloperInterface)) {
                // If the build is from the Alpha Track, we'll activate homeKit logging.
                try {
                    if (window.UPDATE_LEVEL === UpdateComp.UpdateLevel.ALPHA || platform === PlatformType.DeveloperInterface) {
                        Debug.HomeKit = true;
                    }
                } catch (ex) {
                }

                return {
                    action: function () {
                        this._showState(ScreenState.HomeKitSetupViewController, showStateFn);
                    }.bind(this),
                    uuid: ScreenState.HomeKitOnboarding,
                    content: {
                        leftIconSrc: Icon.HOME_KIT.HOME,
                        reactLeftIconSrc: Icons.HomeKit,
                        title: _("home-kit.menu"),
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand,
                        clickable: true
                    }
                };
            }
        }

        getAlexaItem(showStateFn) {
            if (window.UPDATE_LEVEL === UpdateComp.UpdateLevel.ALPHA && (PlatformComponent.isAndroid() || PlatformComponent.isIOS() || PlatformComponent.isDeveloperInterface())) {
                return {
                    action: function () {
                        this._showState(ScreenState.AlexaSetupViewController, showStateFn);
                    }.bind(this),
                    uuid: ScreenState.AlexaOnboardingScreen,
                    content: {
                        leftIconSrc: Icon.ALEXA.APP,
                        reactLeftIconSrc: Icons.Alexa,
                        title: _('alexa.title'),
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand,
                        clickable: true
                    }
                };
            }
        }

        getNotificationItem(showStateFn) {
            // The true flag ignores the Gateway Type, Clients don't support notification in any way (Push or Websocket)
            // We show the cell and present a popup instead of the notificationSettingsScreen when the user clicks on it
            if (SandboxComponent.notificationsAvailableForActiveMiniserver(true) && !PairedAppComponent.isPaired()) {
                var platform = PlatformComponent.getPlatformInfoObj().platform,
                    isPushNotificationSupported = (platform === PlatformType.Android || platform === PlatformType.IOS) && pushNotificationService.deviceSupportsPushNotifications;
                return {
                    action: function () {
                        if (SandboxComponent.notificationsAvailableForActiveMiniserver()) {
                            // Directly navigate to the inAppNotifications if pushNotifications are not supported
                            if (isPushNotificationSupported) {
                                this._showState(ScreenState.NotificationsSettings, showStateFn);
                            } else {
                                this._showState(ScreenState.InAppNotificationsSettings, showStateFn);
                            }
                        } else {
                            NavigationComp.showPopup({
                                message: _('notifications.gateway-client-message'),
                                icon: Icon.INFO,
                                buttonOk: _('okay')
                            });
                        }
                    }.bind(this),
                    uuid: ScreenState.NotificationsSettings,
                    content: {
                        leftIconSrc: Icon.Menu.NOTIFICATIONS,
                        reactLeftIconSrc: Icons.Bell,
                        title: _('notifications'),
                        clickable: true,
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand
                    }
                };
            } else {
                return null;
            }
        }

        /**
         * @param showStateFn
         * @returns {Array}
         */
        getMiniserverUserSettings(showStateFn) {
            var rows = [];
            rows.push(this.getCurrentMiniserverUserItem(showStateFn)); // switch user/logoff

            rows.pushObject(this.getSwitchUserItem(showStateFn));
            return rows;
        }

        getSwitchUserItem(showStateFn) {
            if (PairedAppComponent.isPaired()) {
                return null;
            }
            var currentUser = ActiveMSComponent.getCurrentUser(true);
            return {
                uuid: "switch-user-or-log-off",
                action: function () {
                    this._showSwitchUserPopup();
                }.bind(this),
                content: {
                    leftIconSrc: Icon.Menu.SWITCH_USER_MENU,
                    reactLeftIconSrc: Icons.ArrowPointingInCircle,
                    leftIconColor: window.Styles.colors.brand,
                    title: _("menu.user.logoff", {
                        username: currentUser.name
                    }),
                    clickable: true
                }
            };
        }

        getCurrentMiniserverUserItem(showStateFn) {
            var currentUser = ActiveMSComponent.getCurrentUser(true);
            return {
                uuid: ScreenState.CurrentUserScreen,
                keywords: [_("user")],
                // people might not even know the current username and just enter "user"
                content: {
                    title: _("menu.current-user", {
                        username: currentUser.name
                    }),
                    disclosureIcon: false,
                    clickable: true,
                    leftIconSrc: Icon.Menu.USER,
                    reactLeftIconSrc: Icons.UserOutlined,
                    leftIconColor: window.Styles.colors.brand
                },
                action: function () {
                    this._showUserDetails(showStateFn);
                }.bind(this)
            };
        }

        // switch user / log off
        _showSwitchUserPopup() {
            var buttons = [{
                title: _("menu.user.switch-logoff.popup.log-off"),
                color: window.Styles.colors.red
            }];

            if (!this.isWebinterface) {
                buttons.unshift({
                    title: _("menu.user.switch-logoff.popup.switch")
                });
            }

            var content = {
                message: _("menu.user.switch-logoff.popup.title", {
                    username: ActiveMSComponent.getActiveMiniserver().activeUser
                }),
                buttons: buttons,
                buttonCancel: true,
                icon: Icon.Menu.SWITCH_USER
            };
            NavigationComp.showPopup(content).done(function (buttonId) {
                if (this.isWebinterface) {
                    if (buttonId === 0) {
                        this._logUserOff();
                    }
                } else {
                    if (buttonId === 0) {
                        this._logUserOff(true);
                    } else if (buttonId === 1) {
                        this._logUserOff();
                    }
                }
            }.bind(this));
        }

        /**
         * Used to either log off or to switch the user.
         * @param resetUser if true, the active user is reset too
         * @private
         */
        _logUserOff(resetUser) {
            ActiveMSComponent.logOffUser(true).then(function () {
                this._disconnectFromMs(resetUser);
            }.bind(this));
        }

        _disconnectFromMs(switchUserOnly) {
            NavigationComp.disconnect(switchUserOnly);
        }

        _showUserDetails(showStateFn) {
            this._showState(ScreenState.UserManagementNavigator, showStateFn, {
                initialRouteName: ScreenState.CurrentUserScreen
            });
        }

        _getLastLocationName() {
            return EntryPointHelper.getLocationName(EntryPointHelper.getLocation());
        }

        /**
         * Will present the state provided - using a showStateFn if one is given, otherwise it'll use the
         * NavigationComponent to show the state.
         * @param state
         * @param showStateFn
         * @param details
         * @private
         */
        _showState(state, showStateFn, details) {
            if (showStateFn) {
                showStateFn(state, details);
            } else {
                NavigationComp.showState(state, details);
            }
        }

    };
});
