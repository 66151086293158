'use strict';

import PairedAppEnums from "./pairedAppEnums";
import PairingFile from "./helper/PairingFile";
import {AdminCredsRequest} from "./helper/AdminCredsRequest";
import AmbientUtils from "../react-comps/AmbientMode/AmbientUtils";
import AutoLock from "./helper/AutoLock";

export default ({names}) => {
    window[names.int].factory(names.int, ['$injector', 'ActiveMSComp', 'PersistenceComp', 'PlatformComp', function ($injector, ActiveMSComp, PersistenceComp, PlatformComp) {
        // internal variables
        let weakThis, extensions,
            userInteractionExt,
            pairingSetupExt,
            rpcCommExt,
            deviceStatusReportExt,
            pairingPropsExt;


        var PAComp = function () {
            weakThis = this
            weakThis.name = 'PairedAppComp';

            weakThis.isReady = false;
            weakThis.isPaired = false;
            weakThis.pairingInfo = null;
            weakThis._rpcProperties = null;
            weakThis._jsonProps = null;
            weakThis.lockedUntilTs = 0;


            // Forwarding from CompChannel to Extension-Channel
            const forwardCCEvent = (eventId) => {
                CompChannel.on(eventId, (ev, arg) => {
                    weakThis.emit(eventId, arg);
                });
            }
            forwardCCEvent(CCEvent.StartMSSession);
            forwardCCEvent(CCEvent.AppInitInfoReady);
            forwardCCEvent(CCEvent.StructureReady);
            forwardCCEvent(CCEvent.StateContainersCreated);
            forwardCCEvent(CCEvent.DeviceActivityChanged);
            forwardCCEvent(CCEvent.EcoScreenDarkenerActive);
            forwardCCEvent(CCEvent.BatteryStateChanged);

            CompChannel.on(CCEvent.ConnEstablished, (eventId, arg) => {
                weakThis._isConnected = true;
                try {
                    this.checkAndDistribute({connChanged: true});
                } catch (ex) {
                    console.error(weakThis.name, "failed to distribute connection established: " + (ex?.message || JSON.stringify(ex)), ex);
                }
                weakThis.emit(CCEvent.ConnEstablished, arg);
            });
            CompChannel.on(CCEvent.ConnClosed, (eventId, arg) => {
                weakThis._isConnected = false;
                try {
                    this.checkAndDistribute({connChanged: true});
                } catch (ex) {
                    console.error(weakThis.name, "failed to distribute connection closed: " + (ex?.message || JSON.stringify(ex)), ex);
                }
                weakThis.emit(CCEvent.ConnClosed, arg);
            });

            // Forwarding from Extension-Channel to CompChannel
            weakThis.on(CCEvent.PairedAppPropertiesChanged, (ev, properties) => {
                try {
                    this._handleJsonPropsChange(properties)
                } catch (ex) {
                    console.error(weakThis.name, "failed to handle JSON prop change: " + (ex?.message || JSON.stringify(ex)), ex);
                }
                CompChannel.emit(CCEvent.PairedAppPropertiesChanged, properties);
            });

            weakThis.on(PairedAppEnums.ECEvent.UserInteractionChanged, (ev, {active}) => {
                Debug.PairedApp && console.log(weakThis.name, "UserInteractionChanged: " + active);
            });

            weakThis.on(CCEvent.PairedAppInfoReady, (ev, {isPaired}) => {
                Debug.PairedApp && console.log(weakThis.name, "PairedAppInfoReady: paired=" + isPaired);
                AutoLock.forceOff(isPaired);
            })

            weakThis.on(CCEvent.UnpairedApp, (ev, {}) => {
                Debug.PairedApp && console.log(weakThis.name, "UnpairedApp");
                AutoLock.forceOff(false);
            })

            CompChannel.on(CCEvent.StopMSSession, (ev, {}) => {
                weakThis.isPaired && weakThis._startResumeToPairedMsTimeout();
            })
            CompChannel.on(CCEvent.StartMSSession, (ev, ms) => {
                weakThis.isPaired && weakThis.isPairedMiniserver(ms) && weakThis._stopResumeToPairedMsTimeout();
            })
            CompChannel.on(CCEvent.Resign, (ev) => {
                weakThis._stopResumeToPairedMsTimeout();
            })
            CompChannel.on(CCEvent.Pause, (ev) => {
                weakThis._stopResumeToPairedMsTimeout();
            })
            CompChannel.on(CCEvent.Resume, (ev) => {
                weakThis.isPaired && weakThis._checkAndStartResumeTimeout();
            })
            CompChannel.on(CCEvent.Active, (ev) => {
                weakThis.isPaired && weakThis._checkAndStartResumeTimeout();
            })
            CompChannel.on(CCEvent.Unauthorized, (ev, {loginState, remainingBlockedTime}) => {
                if (weakThis.isPaired && remainingBlockedTime) {
                    Debug.PairedApp && console.log(weakThis.name, "Unauthorized - Unauthorized lock, blocked for: " + remainingBlockedTime + "s");
                    weakThis.lockedUntilTs = Date.now() + (remainingBlockedTime + 1) * 1000;
                    weakThis._stopResumeToPairedMsTimeout();
                    weakThis._startResumeToPairedMsTimeout()
                }
            })

            if (weakThis.isPairableDevice()) {
                weakThis.pairingInfoPromise = PairingFile.loadFromStorage().then(res => {
                    Debug.PairedApp && console.log(weakThis.name, "paired app file ready!");
                    weakThis.pairingInfo = res;
                    if (weakThis.pairingInfo) {
                        weakThis.isPaired = true;
                        return _pairedAppEventPayload();
                    } else {
                        weakThis.isPaired = false;
                        return _pairedAppEventPayload();
                    }

                }, err => {
                    console.error(weakThis.name, "paired app file not found!", err);
                    weakThis.isPaired = false;
                    return _pairedAppEventPayload();

                }).finally(() => {
                    weakThis.isReady = true;
                    weakThis.emit(CCEvent.PairedAppInfoReady, _pairedAppEventPayload());
                });
            } else {
                weakThis.isReady = true;
                weakThis.pairingInfoPromise = Q.resolve(_pairedAppEventPayload());
                weakThis.emit(CCEvent.PairedAppInfoReady, _pairedAppEventPayload());
            }


            userInteractionExt = new extensions.UserInteraction(this);
            pairingSetupExt = new extensions.PairingSetup(this);
            rpcCommExt = new extensions.RpcComm(this);
            pairingPropsExt = new extensions.PairingProps(this);
            deviceStatusReportExt = new extensions.DeviceStatusReport(this);

            rpcCommExt.registerForRpcValues(weakThis._handleRpc.bind(weakThis));

            var exposed = {
                getPairingInfo: () => {
                    return weakThis.pairingInfoPromise;
                },
                isPairableDevice: weakThis.isPairableDevice.bind(weakThis),
                getPairedAppMiniserver: weakThis.getPairedAppMiniserver.bind(weakThis),
                getPairedAppPassword: weakThis.getPairedAppPassword.bind(weakThis),
                isPaired: () => { return weakThis.isPaired },
                isPairedMiniserver: weakThis.isPairedMiniserver.bind(weakThis),
                handleUnpairTapped: weakThis.handleUnpairTapped.bind(weakThis),
                connectToPairedMiniserver: weakThis.connectToPairedMiniserver.bind(weakThis),
                filterPermissions: weakThis.filterPermissions.bind(weakThis),

                getResumeToPairedMsTimeoutInfo: weakThis.getResumeToPairedMsTimeoutInfo.bind(weakThis),


                // RPC communication
                registerForRpc: rpcCommExt.registerForRpcValues.bind(rpcCommExt),

                // pairing setup extension
                startPairingWith: pairingSetupExt.startPairingWith.bind(pairingSetupExt),
                startPairingWithFile: pairingSetupExt.startPairingWithFile.bind(pairingSetupExt),
                validatePairingFile: pairingSetupExt.validatePairingFile.bind(pairingSetupExt),
                stopPairing: pairingSetupExt.stopPairing.bind(pairingSetupExt),
                registerForPairingSetup: pairingSetupExt.registerForPairingSetup.bind(pairingSetupExt),
                reloadPairedDevices: pairingSetupExt.reloadPairedDevices.bind(pairingSetupExt),
                authenticateAsAdmin: pairingSetupExt.authenticateAsAdmin.bind(pairingSetupExt),
                returnToAdminAuthentication: pairingSetupExt.returnToAdminAuthentication.bind(pairingSetupExt),
                replaceDevice: pairingSetupExt.replaceDevice.bind(pairingSetupExt),
                startNewDeviceCreation: pairingSetupExt.startNewDeviceCreation.bind(pairingSetupExt),
                returnToDeviceSelection: pairingSetupExt.returnToDeviceSelection.bind(pairingSetupExt),
                pairAsNewDevice: pairingSetupExt.pairAsNewDevice.bind(pairingSetupExt),
            };
            return exposed;
        };

        BaseComponent.beInheritedBy(PAComp);
        extensions = BaseComponent.initExtensions(names.int, $injector); // methods exposed to the extensions

        PAComp.prototype.isPairableDevice = function isPairableDevice() {
            const pfInfo = PlatformComponent.getPlatformInfoObj();
            const isPairable = PlatformComponent.isIpad || (PlatformComponent.isAndroid() && pfInfo.isTablet);
            Debug.PairedApp && console.log(weakThis.name, "isPairableDevice: " + isPairable);
            return isPairable || PlatformComponent.isDeveloperInterface();
        };


        PAComp.prototype.isPairedMiniserver = function isPairedMiniserver(ms) {
            const pairedMs = PairedAppComponent.getPairedAppMiniserver();
            if (ms.serialNo !== pairedMs.serialNo || ms.activeUser !== pairedMs.activeUser) {
                Debug.PairedApp && console.log(weakThis.name, "isPairedMiniserver: " + ms.serialNo + " --> false!");
                return false;
            }
            Debug.PairedApp && console.log(weakThis.name, "isPairedMiniserver: " + ms.serialNo + " --> true!");
            return true;
        }

        PAComp.prototype.getPairedAppPassword = function getPairedAppPassword() {
            return VendorHub.Crypto.encrypt(weakThis.pairingInfo.password);
        };

        PAComp.prototype.getPairedAppMiniserver = function getPairedAppMiniserver() {
            let pairedMs = PersistenceComp.getMiniserver(weakThis.pairingInfo.serialNo);
            if (!pairedMs) {
                pairedMs = {
                    serialNo: weakThis.pairingInfo.serialNo,
                    publicKey: weakThis.pairingInfo.publicKey,
                    localUrl: weakThis.pairingInfo.localUrl,
                    host: weakThis.pairingInfo.host,
                    remoteUrl: weakThis.pairingInfo.remoteUrl
                }
            }

            // ensure those are set on MS Object.
            pairedMs.activeUser = weakThis.pairingInfo.username;
            pairedMs.password = weakThis.getPairedAppPassword();

            Debug.PairedApp && console.log(weakThis.name, "getPairedAppMiniserver: " + JSON.stringify(weakThis.pairingInfo.JSON) + " = ", pairedMs);
            return pairedMs;
        };

        PAComp.prototype.handleUnpairTapped = function handleUnpairTapped(ms) {
            const unpairFromMs = ms || weakThis.getPairedAppMiniserver();
            Debug.PairedApp && console.log(weakThis.name, "handleUnpairTapped " + unpairFromMs.serialNo);
            let content = {
                title: _("managed-tablet.unpair-tablet-long"),
                message: _("managed-tablet.unpair-tablet-message"),
                buttonOk: _("managed-tablet.unpair-tablet-short"),
                buttonCancel: _("managed-tablet.stay-paired"),
                icon: Icon.DISCONNECT
            };

            // stop the resume timer (if one was running)
            weakThis._stopResumeToPairedMsTimeout();

            let unpairPromise = NavigationComp.showPopup(content).then(() => {
                const unpairCmd = Commands.format(
                    PairedAppEnums.Cmd.UNPAIR,
                    weakThis.pairingInfo.deviceUuid
                );
                this._unpairingPrms = AdminCredsRequest.send(unpairFromMs, unpairCmd).then((res) => {
                    Debug.PairedApp && console.log(weakThis.name, "handleUnpairTapped - responded: " + JSON.stringify(res));
                    try {
                        const code = getLxResponseCode(res);
                        if (code === ResponseCode.OK || code === ResponseCode.GONE) {
                            Debug.PairedApp && console.log(weakThis.name, "handleUnpairTapped - done, code=" + code);
                            weakThis.handleUnpairConfirmed(unpairFromMs, true);
                        } else {
                            console.error(weakThis.name, "handleUnpairTapped - unpair failed, code=" + code + JSON.stringify(res));
                            return Q.reject(res);
                        }
                    } catch (ex) {
                        console.error(weakThis.name, "handleUnpairTapped - unpair failed, could not determine code!" + JSON.stringify(res));
                        return Q.reject();
                    }
                });
                this._unpairingPrms.finally(() => {
                    this._unpairingPrms = null
                });

                return this._unpairingPrms;
            });

            // when done, check if the timer is to be started again.
            unpairPromise.finally(() => {
                weakThis.isPaired && weakThis._checkAndStartResumeTimeout();
            })
        }

        PAComp.prototype.handleUnpairConfirmed = function handleUnpairConfirmed(ms, fromTab = true) {
            if (!weakThis.isPaired) {
                Debug.PairedApp && console.log(weakThis.name, "handleUnpairConfirmed > already unpaired (rpc-ev?)");
                return;
            }
            Debug.PairedApp && console.log(weakThis.name, "handleUnpairConfirmed");

            weakThis._stopResumeToPairedMsTimeout();
            weakThis.emit(CCEvent.UnpairedApp, ms);

            // important, otherwise the conn remains alive in the BG!
            ActiveMSComponent.getActiveMiniserver() && NavigationComp.disconnect();

            weakThis._rpcProperties = null;
            weakThis._jsonProps = null;

            PairingFile.deleteFromStorage();
            weakThis.isPaired = false;
            weakThis.pairingInfo = null;
            weakThis.pairingInfoPromise = Q.resolve(_pairedAppEventPayload());

            SandboxComponent.updateBrightnessSettings(100, 15);
            PersistenceComponent.removeMiniserver(ms.serialNo);

            CompChannel.emit(CCEvent.UnpairedApp, ms);
            NavigationComp.showInitialView();

            if (!fromTab) {
                NavigationComp.showPopup({
                    title: _("managed-tablet.unpaired.title"),
                    message: _("managed-tablet.unpaired.message"),
                    buttonOk: true
                })
            }
        }

        PAComp.prototype.onPairingEstablished = function onPairingEstablished(pairingFile, pairingResult) {
            // important to set this flag already, as the persistence code may access the isPaired() fn of this comp
            // to decide on whether or not to keep the password.
            weakThis.isPaired = true;
            return PairingFile.saveToStorage(pairingFile).then(() => {
                Debug.PairedApp && console.log(weakThis.name, "onPairingEstablished - pairing file saved!");
                weakThis.cleanupForPairing();
                weakThis.pairingInfo = pairingFile;
                weakThis.pairingInfoPromise = Q.resolve(_pairedAppEventPayload());
                weakThis.emit(CCEvent.PairedAppInfoReady, _pairedAppEventPayload());
                NavigationComp.showInitialView(pairingFile);
            }, (err) => {
                console.error(weakThis.name, "onPairingEstablished - pairing wasn't saved! " + JSON.stringify(err), err);
            })
        }

        /**
         * Should ensure that any potentially existing data in the app doesn't interfere with the paired app mode.
         */
        PAComp.prototype.cleanupForPairing = function cleanupForPairing() {
            PersistenceComponent.getAllMiniserver(true).forEach((ms) => {
                PersistenceComponent.removeMiniserver(ms.serialNo);
            })
        }

        PAComp.prototype.connectToPairedMiniserver = function connectToPairedMiniserver() {
            NavigationComp.connectTo(this.getPairedAppMiniserver());
        }


        /**
         * Ensures that only those permissions that are supported for paired tablets are set.
         * @param permissions
         */
        PAComp.prototype.filterPermissions = function filterPermissions(permissions) {
            let cleanedPermission = 0;

            if (weakThis.isPaired) {
                PairedAppEnums.SupportedPermissions.forEach((perm => {
                    if (hasBit(permissions, perm)) {
                        cleanedPermission = setBit(cleanedPermission, perm);
                    }
                }))
            } else {
                cleanedPermission = permissions;
            }
            if (cleanedPermission !== permissions) {
                Debug.PairedApp && console.log(weakThis.name, "filterPermissions: " + permissions + " --> " + cleanedPermission);
            }
            return cleanedPermission;
        }

        /**
         * This method splits stateful messages (brightness/presence/dnd) and action messages (goto-default, unpair)
         * and forwards then for additional processing
         * @param values        map containing all values, key = method
         * @param changedMethod the method that triggered this change.
         * @private
         */
        PAComp.prototype._handleRpc = function _handleRpc(values, changedMethod) {
            if (!weakThis.isPaired) {
                Debug.PairedApp && console.log(weakThis.name, "_handleRpc - " + changedMethod + " --> not paired, don't handle!");
                return;
            }
            switch (changedMethod) {
                case PairedAppEnums.RPCMethod.DND:
                case PairedAppEnums.RPCMethod.BRIGHTNESS:
                case PairedAppEnums.RPCMethod.PRESENCE:
                    this._handleRpcProperties(values, changedMethod);
                    break;

                case PairedAppEnums.RPCMethod.GOTO_DEFAULT:
                    Debug.PairedApp && console.log(weakThis.name, "_handleRpc - GOTO_DEFAULT");
                    this._handleShowDefaulltLocation();
                    break;

                case PairedAppEnums.RPCMethod.NAVIGATE_TO:
                    Debug.PairedApp && console.log(weakThis.name, "_handleRpc - NAVIGATE_TO: " + values[changedMethod].path);
                    this._handleNavigateToLocation(values[changedMethod].path);
                    break;

                case PairedAppEnums.RPCMethod.UNPAIR:
                    Debug.PairedApp && console.log(weakThis.name, "_handleRpc - UNPAIR");
                    this.handleUnpairConfirmed(this.getPairedAppMiniserver(), !!this._unpairingPrms);
                    break;

                case PairedAppEnums.RPCMethod.IDENTIFY:
                    Debug.PairedApp && console.log(weakThis.name, "_handleRpc - IDENTIFY: " + JSON.stringify(values[changedMethod]));
                    this.handleIdentify(values[changedMethod]);
                    break;

                default:
                    Debug.PairedApp && console.log(weakThis.name, "_handleRpc - " + changedMethod + ": val=" + JSON.stringify(values[changedMethod]));
                    break;
            }
        }

        PAComp.prototype.handleIdentify = function handleIdentify(
            {active = false, visual = false, audible = false, duration = -1}) {
            const identifyArguments = { active, visual, audible, duration }

            clearTimeout(weakThis._identifyStopTimeout);
            weakThis._identifyStopTimeout = null;

            if (active && duration > 0) {
                Debug.PairedApp && console.log(weakThis.name, "handleIdentify - start timeout with " + duration + "s");
                weakThis._identifyStopTimeout = setTimeout(() => {
                    Debug.PairedApp && console.log(weakThis.name, "handleIdentify - timeout passed!");
                    weakThis.handleIdentify({active: false});
                }, duration * 1000);
            }

            CompChannel.emit(CCEvent.PairedIdentify, identifyArguments);
            if (active && visual) {
                if (!weakThis._identifyShown) {
                    weakThis._identifyShown = true;
                    NavigationComp.showPairedAppIdentify(identifyArguments);
                } else {
                    // already shown.
                }
            } else {
                weakThis._identifyShown = false;
            }
            if (active && audible) {
                weakThis._startIdentifySound();
            } else {
                weakThis._stopIdentifySound();
            }
        }

        /**
         * Stateful RPC messages are passed to this message, it then collects them and as soon as all required are
         * received, it'll distribute them.
         * @param values
         * @param changedMethod
         * @private
         */
        PAComp.prototype._handleRpcProperties = function _handleRpcProperties(values, changedMethod) {
            if (!rpcCommExt.hasRequiredInfos()) {
                this._rpcProperties = null;
                return;
            }

            const rpcChanges = {};
            const rpcProps = {
                dnd: values[PairedAppEnums.RPCMethod.DND]?.enable ?? false,
                presence: values[PairedAppEnums.RPCMethod.PRESENCE]?.active ?? true,
                brightness: values[PairedAppEnums.RPCMethod.BRIGHTNESS] || {}
            }
            if (!this._rpcProperties) {
                rpcChanges.dnd = true;
                rpcChanges.presence = true;
                rpcChanges.brightness = true;
            } else {
                switch (changedMethod) {
                    case PairedAppEnums.RPCMethod.DND:
                        rpcChanges.dnd = true;
                        break;
                    case PairedAppEnums.RPCMethod.PRESENCE:
                        rpcChanges.presence = true;
                        break;
                    case PairedAppEnums.RPCMethod.BRIGHTNESS:
                        rpcChanges.brightness = true;
                        break;
                    default:
                        break;
                }
            }
            this._rpcProperties = rpcProps;

            Debug.PairedApp && console.log(weakThis.name, "_handleRpcProperties: " + JSON.stringify(rpcProps));
            this.checkAndDistribute({rpcChanges});
        }

        /**
         * Called when the properties object is changed, this contains those things that are configured within
         * Loxone Config and don't change unless saving into the Miniserver (for now).
         * @param newProps  the object from the PairingPropsExt that contains all the information set in config.
         * @private
         */
        PAComp.prototype._handleJsonPropsChange = function _handleJsonPropsChange(newProps) {
            Debug.PairedApp && console.log(weakThis.name, "_handleJsonPropsChange");
            weakThis._jsonProps = newProps;
            weakThis.checkAndDistribute({jsonChanged: true});
        }

        /**
         * Method called whenever an RPC prop changes or the rather static JSON props arrive.
         * It will ensure that the proper values are distributed.
         */
        PAComp.prototype.checkAndDistribute = function checkAndDistribute(
            {rpcChanges = {}, jsonChanged = false, connChanged = false}) {
            if (!weakThis.isPaired) {
                return;
            }
            const updateInfo = [];
            Debug.PairedApp && console.log(weakThis.name, "checkAndDistribute rpc=" + JSON.stringify(rpcChanges) + ", json=" + jsonChanged + ", conn=" + connChanged);

            if (rpcChanges.brightness) {
                Debug.PairedApp && updateInfo.push("brightness:" + weakThis._rpcProperties?.brightness?.active + "/" + weakThis._rpcProperties?.brightness?.inactive);
                SandboxComponent.updateBrightnessSettings(weakThis._rpcProperties?.brightness?.active, weakThis._rpcProperties?.brightness?.inactive);
            }
            
            if (jsonChanged) {
                Debug.PairedApp && updateInfo.push("entryPoint:" + weakThis._jsonProps.entryPointLocation);
                PersistenceComponent.setEntryPointLocation(weakThis._jsonProps.entryPointLocation);
                Debug.PairedApp && updateInfo.push("ambientModeSetting:" + JSON.stringify(weakThis._jsonProps.ambientMode.jsonForApp));
                PersistenceComponent.updateAmbientModeSetting(weakThis._jsonProps.ambientMode.jsonForApp);

                Debug.PairedApp && updateInfo.push("ecoScreenSetting:" + weakThis._jsonProps.ecoScreen.jsonForApp);
                PersistenceComponent.updateEcoScreenSetting(weakThis._jsonProps.ecoScreen.jsonForApp);

                Debug.PairedApp && updateInfo.push("plainDesign:" + weakThis._jsonProps.appearance.plainDesign);
                PersistenceComponent.setSimpleDesignSetting(weakThis._jsonProps.appearance.plainDesign);
            }

            if ((jsonChanged || connChanged) && weakThis._isConnected) {
                // wallpaper needs to be prepared before being stored. -- requires a connection!
                Debug.PairedApp && updateInfo.push("wallpaper:" + JSON.stringify(weakThis._jsonProps?.ambientMode?.wallpaper?.json));
                weakThis._jsonProps?.ambientMode?.wallpaper?.apply();
            }

            // the DND mode impacts also on the presence/notifications --> hence handle it here
            if (weakThis._rpcProperties?.dnd && rpcChanges.dnd) {
                Debug.PairedApp && updateInfo.push("dnd:on -> notifications:off, presence:false");
                // DND turned on!
                SandboxComponent.setNotificationsDndActive(true);
                SandboxComponent.updatePairedAppPresence(false);

            } else if (!weakThis._rpcProperties?.dnd && rpcChanges.dnd) {
                Debug.PairedApp && updateInfo.push("dnd:off -> notifications:fromMs, presence:" + weakThis._rpcProperties?.presence);
                // DND turned off!
                // update notification settings to configured ones
                SandboxComponent.setNotificationsDndActive(false);
                // forward presence again
                SandboxComponent.updatePairedAppPresence(weakThis._rpcProperties?.presence);

            } else if (!weakThis._rpcProperties?.dnd) {
                // dnd is not on, forward anything that has changed.
                if (jsonChanged) {
                    Debug.PairedApp && updateInfo.push("notifications:fromMs");
                    SandboxComponent.updateInAppNotificationSettings(weakThis._jsonProps?.notifications?.json);
                }
                if (rpcChanges.presence) {
                    Debug.PairedApp && updateInfo.push("presence:" + weakThis._rpcProperties?.presence);
                    SandboxComponent.updatePairedAppPresence(weakThis._rpcProperties?.presence);
                }
            }

            // provoide a list of changes.
            Debug.PairedApp && console.log(this.name, "checkAndDistribute >> done: ");
            Debug.PairedApp && updateInfo.forEach(change => {
                console.log(this.name, "    checkAndDistribute -> " + change);
            });
        }

        PAComp.prototype._handleNavigateToLocation = function _handleNavigateToLocation(location) {
            Debug.PairedApp && console.log(weakThis.name, "_handleNavigateToLocation: " + location);
            const urlParts = location.split("/");
            NavigationComp.handleURL(urlParts);
        }

        PAComp.prototype._handleShowDefaulltLocation = function _handleShowDefaulltLocation() {
            let ambientByDefault = AmbientUtils.shouldStartWithAmbient(EntryPointHelper.getLocation());
            if (pairingPropsExt.getCurrent()) {
                ambientByDefault = pairingPropsExt.getCurrent().ambientMode.showAsDefault;
            }
            if (ambientByDefault) {
                if (!AMBIENT_MODE) {
                    NavigationComp.showAmbientMode();
                } else {
                    CompChannel.emit(CCEvent.ResetAmbientToDefaultLocation, {fromButton: true});
                }
            } else {
                NavigationComp.showEntryPointLocation(false);
            }
        }

        PAComp.prototype.getResumeToPairedMsTimeoutInfo = function getResumeToPairedMsTimeoutInfo() {
            return {
                active: !!weakThis.resumeTimeout,
                resumeTs: weakThis.resumeTs
            }
        }

        PAComp.prototype._checkAndStartResumeTimeout = function _checkAndStartResumeTimeout() {
            Debug.PairedApp && console.log(weakThis.name, "_checkAndStartResumeTimeout");
            let activeMs = ActiveMSComp.getActiveMiniserver();
            if (activeMs && weakThis.isPairedMiniserver(activeMs)) {
                Debug.PairedApp && console.log(weakThis.name, " --> already on pairedMS");
                return; // everything ok
            } else {
                Debug.PairedApp && console.log(weakThis.name, " --> not on pairedMS --> start resume timer");
                this._startResumeToPairedMsTimeout();
            }
        }

        PAComp.prototype._startResumeToPairedMsTimeout = function _startResumeToPairedMsTimeout() {
            weakThis.resumeTimeout && weakThis._stopResumeToPairedMsTimeout();
            let delay = 30;
            Debug.PairedApp && console.log(weakThis.name, "_startResumeToPairedMsTimeout: " + delay + "s");
            weakThis.resumeTs = Date.now() + (delay * 1000);

            //ensure the retry doesn't happen before the lock ends as it would prolong the lock
            if (weakThis.resumeTs < weakThis.lockedUntilTs) {
                delay = Math.floor((weakThis.lockedUntilTs - Date.now()) / 1000) + delay;
                weakThis.resumeTs = Date.now() + (delay * 1000);
                Debug.PairedApp && console.log(weakThis.name, "   extend delay due to Unauthorized lock: " + delay + "s");
            }

            CompChannel.emit(CCEvent.PairingResumeTimer, { active: true, resumeTs: weakThis.resumeTs });
            weakThis.resumeTimeout = setTimeout(() => {
                weakThis.resumeTimeout = false;
                Debug.PairedApp && console.log(weakThis.name, "_startResumeToPairedMsTimeout > fired!");
                CompChannel.emit(CCEvent.PairingResumeTimer, { active: false });
                weakThis.connectToPairedMiniserver();
            }, (delay + 1) * 1000);

        }

        PAComp.prototype._stopResumeToPairedMsTimeout = function _stopResumeToPairedMsTimeout() {
            Debug.PairedApp && console.log(weakThis.name, "_stopResumeToPairedMsTimeout");
            CompChannel.emit(CCEvent.PairingResumeTimer, { active: false });
            clearTimeout(weakThis.resumeTimeout);
            weakThis.resumeTimeout = false;
        }

        var _pairedAppEventPayload = function _pairedAppEventPayload() {
            return {
                isPaired: weakThis.isPaired,
                pairingInfo: weakThis.pairingInfo
            }
        }

        PAComp.prototype._startIdentifySound = function _startIdentifySound() {
            Debug.PairedApp && console.log(weakThis.name, "_startIdentifySound");
            weakThis._loopingIdentify = false;
            weakThis._identifySoundActive = true;
            const {promise, stop, playAgain} = weakThis._playSound(
                PairedAppEnums.Identify.MP3,
                PairedAppEnums.Identify.SHOULD_LOOP
            );
            weakThis._stopSoundFn = stop;
            promise.then(() => {
                if (PairedAppEnums.Identify.SHOULD_LOOP && weakThis._identifySoundActive) {
                    Debug.PairedApp && console.log(weakThis.name, "_startIdentifySound > done -> repeat!");
                    playAgain && playAgain();
                } else {
                    Debug.PairedApp && console.log(weakThis.name, "_startIdentifySound > done");
                }
            });
        }

        PAComp.prototype._stopIdentifySound = function _stopIdentifySound() {
            Debug.PairedApp && console.log(weakThis.name, "_stopIdentifySound");
            weakThis._identifySoundActive = false;
            weakThis._stopSoundFn && weakThis._stopSoundFn();
        }

        PAComp.prototype._playSound = function _playSound(soundfile, loop = false) {
            Debug.PairedApp && console.log(weakThis.name, "_playSound - " + soundfile);
            const soundHandle = {
                promise: null,
                stop: null,
                playAgain: null
            }
            var audioObj, stopTimeout;

            try {
                if (window.Audio) {
                    let def = Q.defer();
                    audioObj = new Audio("resources/Audio/" + soundfile); // start a timeout that stops the audio file, in browsers the file might start playing too late
                    audioObj.loop = loop;
                    // if e.g. the browser tab is in the background.

                    stopTimeout = setTimeout(function () {
                        console.error(weakThis.name, "_playSound - audio didn't play soon enough. so stop.");
                        audioObj && audioObj.pause();
                        audioObj = null;
                        def.reject();
                    }.bind(this), 2000); // when it starts playing, remove the timeout - so it isn't stopped suddenly

                    audioObj.addEventListener('ended', function() {
                        Debug.PairedApp && console.log(weakThis.name, "_playSound - audio finished playing!");
                        def.resolve();
                    }, false);

                    audioObj.addEventListener('playing', function () {
                        Debug.PairedApp && console.log(weakThis.name, "_playSound - audio playing!");
                        clearTimeout(stopTimeout);
                        stopTimeout = null;

                    }.bind(this));
                    audioObj.play();

                    soundHandle.promise = def.promise;
                    soundHandle.stop = () => {
                        Debug.PairedApp && console.log(weakThis.name, "_playSound - stop called!");
                        audioObj && audioObj.pause();
                    }
                    soundHandle.playAgain = () => {
                        Debug.PairedApp && console.log(weakThis.name, "_playSound - playAgain called!");
                        audioObj && audioObj.play();
                    }
                } else {
                    soundHandle.promise = Q.reject()
                    soundHandle.stop = () => {};
                    soundHandle.playAgain = () => {};
                }

            } catch (e) {// According to Microsoft, Windows versions that ends with N or KN are editions of Windows
                // that are missing media-related features, hence playing audio in Edge is not possible
                // Catch this error to ensure that the Intercom will be displayed
                soundHandle.promise = Q.reject()
                soundHandle.stop = () => {};
                soundHandle.playAgain = () => {};
            }

            return soundHandle;
        }

        window[names.ext] = new PAComp();
        return window[names.ext];
    }]);
}
