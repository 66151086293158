'use strict';

define("LightControlStateContainer", ["ControlStateContainer", "LightControlEnums"], function (ControlStateContainer, LightControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var SubCtrlType = {
        DIMMER: 'Dimmer',
        SWITCH: 'Switch',
        COLOR_PICKER: 'Colorpicker'
    };
    return class LightControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            this.subCtrlStates = {}; // keep track of states of subControls to properly update extra scenes

            var subCtrl, state;

            for (var ctrlId in control.subControls) {
                if (control.subControls.hasOwnProperty(ctrlId)) {
                    subCtrl = control.subControls[ctrlId];
                    state = {
                        isOn: false,
                        isOff: true,
                        type: subCtrl.type,
                        states: {},
                        uuids: subCtrl.states
                    };
                    this.subCtrlStates[ctrlId] = state;
                }
            }
        }

        /**
         * returns array of uuids to registered
         * @returns {*[]}
         */
        getUUIDsToRegister() {
            var uuids = Object.values(this.control.states);
            var subCtrls = this.control.subControls,
                subCtrl;

            for (var ctrlId in subCtrls) {
                if (subCtrls.hasOwnProperty(ctrlId)) {
                    subCtrl = subCtrls[ctrlId];

                    if (subCtrl.type === SubCtrlType.ColorPicker) {
                        uuids.push(subCtrl.states.color);
                    } else {
                        uuids = uuids.concat(Object.values(subCtrl.states));
                    }
                }
            }

            return uuids;
        }

        prepareStates(newVals) {
            this._updateSubControlStates.call(this, newVals);

            var currSceneNr = newVals[this.control.states.activeScene] || 0;

            var scenes = this._parseSceneListToObject(newVals[this.control.states.sceneList]);

            this.states.haveScenesChanged = this._haveScenesChanged(scenes, this.states.scenes);
            this.states.scenes = scenes;
            this.control.currentScenes = cloneObject(scenes);

            if (currSceneNr === LightControlEnums.SceneNumbers.ALL_OFF) {
                this.states.isOn = false; // Hotfix for Miniserver Bug (Wrike 135028770)
            } else if (currSceneNr === LightControlEnums.SceneNumbers.MANUAL) {
                currSceneNr = this._getCalculatedSceneByOutputs(this.subCtrlStates);
            }

            this.states.activeSceneNr = currSceneNr;
        }

        getStateIcon() {
            return Icon.Light.ComfortMode.BULB;
        }

        getStateText() {
            if (!this.control.hasSingleSwitch()) {
                if (this.states.activeSceneNr === LightControlEnums.SceneNumbers.ALL_OFF) {
                    return _('controls.lightcontroller.all-off');
                } else if (this.states.activeSceneNr === LightControlEnums.SceneNumbers.ALL_ON) {
                    return _('controls.lightcontroller.all-on');
                } else if (this.states.activeSceneNr === LightControlEnums.SceneNumbers.MANUAL) {
                    return _('controls.lightcontroller.manual-scene');
                } else {
                    return this.states.scenes[this.states.activeSceneNr];
                }
            }
        }

        getStateTextShort() {
            if (this.control.hasSingleSwitch()) {
                return this.states.isOn ? _('on') : _('off');
            }

            return super.getStateTextShort(...arguments);
        }

        getStateColor() {
            if (this.states.isOn) {
                return window.Styles.colors.stateActive;
            }
        }

        // Private Methods
        _parseSceneListToObject(sceneList) {
            var scenes = {};

            if (sceneList && sceneList.text) {
                // sceneList.text = '1="Scene1",2="Scene2",3="Scene3",4="Scene4",5="Scene5",6="Scene6",7="Scene7"'
                // We remove the last character, which is an ", so don't have to think about it anymore
                // We then split up the string
                var entries = sceneList.text.slice(0, sceneList.text.length - 1).split('",');
                var id, name;

                for (var i = 0; i < entries.length; i++) {
                    // Here we split the entry, so we can get the ID and the Name
                    var entryComponents = entries[i].split("="); // entryComponents[0] is a string

                    id = parseInt(entryComponents[0]); // entryComponents[1] is '"Scene1', so we need to remove the first character which is a "

                    name = entryComponents[1].slice(1, entryComponents[1].length);
                    scenes[id] = name;
                }
            }

            return scenes;
        }

        _updateSubControlStates(newStates) {
            var subStates = this.subCtrlStates,
                subState,
                val;
            this.states.isOn = false;

            for (var subId in subStates) {
                if (subStates.hasOwnProperty(subId)) {
                    subState = this.subCtrlStates[subId];

                    if (subState.type === SubCtrlType.DIMMER) {
                        val = newStates[subState.uuids.position];
                        subState.states.pos = val;
                        subState.states.min = newStates[subState.uuids.min];
                        subState.states.max = newStates[subState.uuids.max];
                        subState.isOn = val >= subState.states.max;
                        subState.isOff = val === 0 || val < subState.states.min;
                    } else if (subState.type === SubCtrlType.SWITCH) {
                        val = newStates[subState.uuids.active] === 1;
                        subState.states.isActive = val;
                        subState.isOn = val;
                        subState.isOff = !val;
                    } else if (subState.type === SubCtrlType.COLOR_PICKER) {
                        val = newStates[subState.uuids.color].text;
                        subState.states.color = val;
                        subState.isOn = val === 'hsv(0,0,100)' || val === 'lumitech(100,4608)';
                        subState.isOff = val === 'hsv(0,0,0)' || val === 'lumitech(0,0)';
                    }

                    if (!this.states.isOn) {
                        this.states.isOn = !subState.isOff;
                    }
                }
            }
        }

        _haveScenesChanged(newScenes, oldScenes) {
            var newVals = JSON.stringify(newScenes),
                oldVals = JSON.stringify(oldScenes);
            return newVals !== oldVals;
        }

        _getCalculatedSceneByOutputs(subStates) {
            var isOn = true,
                isOff = true,
                subState;

            for (var subId in subStates) {
                if (subStates.hasOwnProperty(subId)) {
                    subState = subStates[subId];
                    isOn = isOn && subState.isOn;
                    isOff = isOff && subState.isOff;
                }
            }

            if (isOff) {
                return 0;
            } else if (isOn) {
                return 9;
            } else {
                return -1; // -1 means it's nether completely off nor completely on
            }
        }

    };
});
