'use strict';
/**
 * responsible for recording/deleting/editing tasks
 */

SandboxComp.factory('TaskRecorderExt', function () {
    var comp;
    var TASK_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
    var ScreenSaverAvoidTimeout = 20 * 1000; // in ms

    /**
     * c-tor of the TaskRecorderExt
     * @param sandboxComp reference to the parent component
     * @constructor
     */

    function TaskRecorderExt(sandboxComp) {
        comp = sandboxComp;
        this.registrations = [];
        comp.on(SandboxComp.ECEvent.ConnClosed, function () {
            Debug.TaskRecorder && console.log("ECEvent.ConnClosed");
            this.connectionReady = false;
        });
        CompChannel.on(CCEvent.CrucialDataLoaded, function () {
            Debug.TaskRecorder && console.log("CCEvent.CrucialDataLoaded");
            this.connectionReady = true;
            this._plannedTasks = [];
            this._pastTasks = [];

            if (this._isForAutomaticDesigner) {
                var globalStates = comp.getStructureManager().getGlobalStateUUIDs();

                if (globalStates.plannedTasks && globalStates.pastTasks) {
                    this._plannedUUID = globalStates.plannedTasks;
                    this._pastUUID = globalStates.pastTasks;
                    comp.registerUUIDs(this, [this._plannedUUID, this._pastUUID]);
                }
            } else {
                this._reloadTasks();
            }
        }.bind(this));
        comp.on(SandboxComp.ECEvent.Reset, function () {
            Debug.TaskRecorder && console.log("ECEvent.Reset");
            this.connectionReady = false;
            delete this._currentTasksString;
        });
        comp.on(SandboxComp.ECEvent.CommandForTaskRecorder, function (event, cmdObj) {
            Debug.TaskRecorder && console.log("ECEvent.CommandForTaskRecorder: " + cmdObj.cmd);
            this.onCommandReceived(cmdObj);
        }.bind(this));
    }

    TaskRecorderExt.prototype._startUpdateInterval = function _startUpdateInterval() {
        Debug.TaskRecorder && console.log("_startUpdateInterval");

        if (!this.refreshInterval) {
            this.refreshInterval = setInterval(this._reloadTasks.bind(this), 2000);

            this._reloadTasks();
        }
    };

    TaskRecorderExt.prototype._stopUpdateInterval = function _stopUpdateInterval() {
        Debug.TaskRecorder && console.log("_stopUpdateInterval");
        clearInterval(this.refreshInterval);
        this.refreshInterval = null;
    };

    TaskRecorderExt.prototype._reloadTasks = function _reloadTasks() {
        Debug.TaskRecorder && console.log("_reloadTasks");
        let weakThis = this;

        if (weakThis.connectionReady) {
            comp.send(Commands.TASK_RECORDER.GET_TASKS).then(function (res) {
                weakThis.tasksReceived(res.LL.value);
            }, function (e) {
                console.error(e);
                weakThis.tasksReceived(null);
            });
        }
    }; // NEW API


    TaskRecorderExt.prototype.newStatesReceived = function newStatesReceived(values) {
        this._plannedTasks = JSON.parse(values[this._plannedUUID].text);
        this._pastTasks = JSON.parse(values[this._pastUUID].text);
        comp.getMiniserverTimeInfo(this, function (utcOffset) {
            console.dir(this._plannedTasks);
            console.dir(this._pastTasks);

            this._sanitizeTasks(this._plannedTasks, utcOffset);

            this._sanitizeTasks(this._pastTasks, utcOffset);

            this._notifyListeners();

            console.dir(this._plannedTasks);
            console.dir(this._pastTasks);
        }.bind(this), TimeValueFormat.MINISERVER_UTC_OFFSET);
    }; // helpers


    TaskRecorderExt.prototype._sanitizeTasks = function _sanitizeTasks(tasks, utcOffset) {
        tasks.forEach(function (task) {
            task.name = decodeURIComponent(task.name);
            var date = task.time;
            task.commands.forEach(function (command) {
                var uuidAction = command.cmd.substring(0, command.cmd.indexOf("/"));
                addControlDetailsToCommand(command, uuidAction);
                command.date = prepareDateWithZone(moment(date, TASK_DATE_FORMAT), utcOffset);
                task.isSecured = task.isSecured || command.isSecured;
            });
            task.startDate = task.commands[0].date;
        });
    };

    TaskRecorderExt.prototype._cleanUpTasks = function _cleanUpTasks(task) {
        Object.keys(task).forEach(function (key) {
            if (key !== "uuid" && key !== "name" && key !== "time" && key !== "repeat" && key !== "commands") {
                delete task[key];
            }
        });
        task.commands.forEach(function (command) {
            Object.keys(command).forEach(function (key) {
                if (key !== "delta" && key !== "cmd") {
                    delete command[key];
                }
            });
        });
        return task;
    };

    TaskRecorderExt.prototype._notifyListeners = function _notifyListeners() {
        for (var i = 0; i < this.registrations.length; i++) {
            this.registrations[i](this._plannedTasks, this._pastTasks);
        }
    }; // OLD API


    TaskRecorderExt.prototype.tasksReceived = function tasksReceived(tasksString) {
        Debug.TaskRecorder && console.log("tasksReceived:", tasksString);

        if (tasksString && tasksString.length > 0) {
            if (this._currentTasksString !== tasksString) {
                this._currentTasksString = tasksString;
                comp.getMiniserverTimeInfo(this, function (utcOffset) {
                    this._plannedTasks = parseTaskString(tasksString, utcOffset);
                    console.dir(this._plannedTasks);

                    this._notifyListeners();
                }.bind(this), TimeValueFormat.MINISERVER_UTC_OFFSET);
            }
        } else {
            if (this._currentTasksString !== tasksString) {
                delete this._currentTasksString;
                this._plannedTasks = [];

                this._notifyListeners();
            }
        }
    };

    TaskRecorderExt.prototype.registerForTasks = function registerForTasks(updateFn) {
        Debug.TaskRecorder && console.log("registerForTasks");
        this.registrations[this.registrations.length] = updateFn;
        updateFn(this._plannedTasks, this._pastTasks);

        if (!this._isForAutomaticDesigner) {
            this._startUpdateInterval();
        }

        return this.registrations.length - 1; // returns index = regID
    };

    TaskRecorderExt.prototype.unregisterForTasks = function unregisterForTasks(listenerID) {
        Debug.TaskRecorder && console.log("unregisterForTasks");
        this.registrations.splice(listenerID, 1);

        if (!this._isForAutomaticDesigner) {
            if (this.registrations.length === 0) {
                // stop updating..
                this._stopUpdateInterval();
            }
        }
    };
    /**
     * Will return true if task recording is currently active.
     * @return {boolean}
     */


    TaskRecorderExt.prototype.isRecordingTask = function isRecordingTask() {
        Debug.TaskRecorder && console.log("isRecordingTask");
        return !!this.getRecordedTask();
    };

    TaskRecorderExt.prototype.registerForNewTaskCommands = function registerForNewTaskCommands(updateFn) {
        Debug.TaskRecorder && console.log("registerForNewTaskCommands");
        this.newTaskCommandsUpdateFn = updateFn;
    };

    TaskRecorderExt.prototype.unregisterForNewTaskCommands = function unregisterForNewTaskCommands() {
        Debug.TaskRecorder && console.log("unregisterForNewTaskCommands");
        this.newTaskCommandsUpdateFn = null;
    };

    TaskRecorderExt.prototype.deleteTask = function deleteTask(task) {
        Debug.TaskRecorder && console.log("deleteTask: " + JSON.stringify(task));

        if (this._isForAutomaticDesigner) {
            comp.send(Commands.format(Commands.TASK_RECORDER.V2.DELETE_TASK, task.uuid)).then(function () {
                console.log(" - task " + task.uuid + " deleted!");
            }, function (e) {
                console.error(e);
            });
        } else {
            var cmd;

            for (var i = 0; i < task.commands.length; i++) {
                cmd = task.commands[i];
                comp.send(getDeleteTaskCommand(task, cmd), null, true).then(updateTasks.bind(this), updateTasks.bind(this), updateTasks.bind(this));
            }
        }
    };

    TaskRecorderExt.prototype.updateTask = function updateTask(oldTask, newTask) {
        Debug.TaskRecorder && console.log("updateTask oldTask: " + JSON.stringify(oldTask) + ", " + "newTask: " + JSON.stringify(newTask));

        if (!compareTasks(oldTask, newTask)) {
            Debug.TaskRecorder && console.log("    task different, update!");

            if (this._isForAutomaticDesigner) {
                newTask.time = newTask.startDate.format(TASK_DATE_FORMAT);
                newTask.name = encodeURIComponent(newTask.name);

                var isSecured = newTask.isSecured,
                    cleanedTask = this._cleanUpTasks(newTask);

                if (isSecured) {
                    this._sendSecuredTask(cleanedTask, true, false);
                } else {
                    var cmd = Commands.format(Commands.TASK_RECORDER.V2.UPDATE_TASK, cleanedTask.uuid, JSON.stringify(cleanedTask));
                    comp.send(cmd).done(function () {
                        // TODO-thallth change to task uuid once supported from miniserver
                        console.log(" - task " + task.uuid + " updated!");
                    }, function (e) {
                        console.error(e);
                    });
                }
            } else {
                if (newTask.isSecured) {
                    this.addSecuredTask(newTask, oldTask); // try to add edited task first, then delete old task!
                } else {
                    this.deleteTask(oldTask);
                    this.addTask(newTask);
                }
            }
        } else {
            Debug.TaskRecorder && console.log("    task not different!");
        }
    };

    TaskRecorderExt.prototype._sendSecuredTask = function (task, update, wasWrong) {
        // request password
        Debug.TaskRecorder && console.log("    request password..");
        return SandboxComponent.acquireVisuPassword(wasWrong).then(function success(visuPassword) {
            Debug.TaskRecorder && console.log("    ..got password"); // request key

            Debug.TaskRecorder && console.log("    request key..");
            return comp.send(Commands.GET_KEY, null, true).then(function (result) {
                Debug.TaskRecorder && console.log("    ..got key");
                var hashAlg = VendorHub.Crypto.getHashAlgorithmForMs(),
                    hash = VendorHub.Crypto["Hmac" + hashAlg](visuPassword, "utf8", result.LL.value, "hex", "hex"),
                    tskStr = JSON.stringify(task),
                    cmd;

                if (update) {
                    cmd = Commands.format(Commands.TASK_RECORDER.V2.UPDATE_SEC_TASK, task.uuid, hash, tskStr);
                } else {
                    cmd = Commands.format(Commands.TASK_RECORDER.V2.ADD_SEC_TASK, hash, tskStr);
                }

                return comp.send(cmd, null, true).then(function success() {
                    Debug.TaskRecorder && console.log("    sent secured task successful");
                    return true;
                }, function error(res) {
                    if (res.LL && getLxResponseCode(res) === ResponseCode.SECURED_CMD_FAILED) {
                        console.error("    sending secured task failed due to wrong password, try again..");
                        return this._sendSecuredTask(task, update, true);
                    } else {
                        console.error("    sending secured task failed!");
                    }
                }.bind(this));
            }.bind(this), function (e) {
                console.error("requesting key failed! (" + e + ")");
            });
        }.bind(this));
    };

    TaskRecorderExt.prototype.addTask = function addTask(task) {
        Debug.TaskRecorder && console.log("addTask");

        if (this._isForAutomaticDesigner) {
            task.time = task.startDate.format(TASK_DATE_FORMAT);
            task.repeat = "0"; // TODO

            var isSecured = task.isSecured,
                cleanedTask = this._cleanUpTasks(task);

            if (isSecured) {
                return this._sendSecuredTask(cleanedTask);
            } else {
                comp.send(Commands.format(Commands.TASK_RECORDER.V2.ADD_TASK, JSON.stringify(cleanedTask)), null, true).then(function () {
                    // TODO-thallth change to task uuid once supported from miniserver
                    console.log(" - task " + task.uuid + " added!");
                }, function (e) {
                    console.error(e);
                });
            }
        } else {
            task.name = checkString(task.name);

            if (task.isSecured) {
                return this.addSecuredTask(task);
            }

            var cmd;

            for (var i = 0; i < task.commands.length; i++) {
                cmd = task.commands[i];
                comp.send(getAddTaskCommand(task, cmd), null, true).then(updateTasks.bind(this), updateTasks.bind(this), updateTasks.bind(this));
            }
        }
    };

    TaskRecorderExt.prototype.addSecuredTask = function addSecuredTask(task, oldTask, wasWrong) {
        Debug.TaskRecorder && console.log("addSecuredTask"); // request password

        Debug.TaskRecorder && console.log("    request password..");
        SandboxComponent.acquireVisuPassword(wasWrong).then(function success(visuPassword) {
            Debug.TaskRecorder && console.log("    ..got password");
            var nrOfCommandsToBeConfirmed = task.commands.length;
            var commandFailed = false;

            for (var i = 0; i < task.commands.length; i++) {
                var cmd = task.commands[i];

                if (cmd.isSecured) {
                    this._sendAddCmdWithVisuPass(task, cmd, visuPassword).then(function success() {
                        Debug.TaskRecorder && console.log("    add task successful");
                        nrOfCommandsToBeConfirmed--;
                    }, function error(e) {
                        console.error("    add task failed!");
                        console.error("wrong password:", e); // error while adding task command

                        commandFailed = true;
                    });
                } else {
                    // send normally
                    comp.send(getAddTaskCommand(task, cmd), null, true).then(function success() {
                        nrOfCommandsToBeConfirmed--;
                    }, function error() {
                        // error while adding task command
                        commandFailed = true;
                    });
                }
            } // start interval to check, if all tasks where added, then continue


            var checkInterval = setInterval(function () {
                if (nrOfCommandsToBeConfirmed > 0 && !commandFailed) {// wait...
                } else {
                    clearInterval(checkInterval);

                    if (oldTask && !commandFailed) {
                        // if we edited a task, delete now the old one!
                        this.deleteTask(oldTask);
                    }

                    if (commandFailed) {
                        // show prompt again!
                        this.addSecuredTask(task, oldTask, true);
                    }

                    updateTasks.call(this);
                }
            }.bind(this), 200);
        }.bind(this), function error() {
            // error handling
            updateTasks.call(this);
        }.bind(this));
    };
    /**
     * Will request the proper salt, hash the visuPassword with it and then send the add command.
     * @param task
     * @param cmd
     * @param visuPassword
     * @private
     */


    TaskRecorderExt.prototype._sendAddCmdWithVisuPass = function _sendAddCmdWithVisuPass(task, cmd, visuPassword) {
        return CommunicationComponent.getHashForVisuPass(visuPassword).then(function (hash) {
            return SandboxComponent.send(getAddTaskCommand(task, cmd, hash), null, true);
        }.bind(this), function (e) {
            console.error("requesting key failed! (" + JSON.stringify(e) + ")");
            updateTasks.call(this);
        }.bind(this));
    };

    TaskRecorderExt.prototype.updateTaskStartTime = function updateTaskStartTime(task, newStartTime) {
        Debug.TaskRecorder && console.log("updateTaskStartTime: " + newStartTime.format(TASK_DATE_FORMAT));
        var diff = task.startDate.diff(newStartTime);
        task.startDate = newStartTime;
        var cmd;

        for (var i = 0; i < task.commands.length; i++) {
            cmd = task.commands[i]; // update date

            cmd.date = cmd.date.subtract(diff, 'milliseconds'); // update delta

            cmd.delta = cmd.date.diff(task.startDate);
        }
    };

    TaskRecorderExt.prototype.startTaskRecording = function startTaskRecording(isForAutomaticDesigner) {
        Debug.TaskRecorder && console.log("startTaskRecording");
        this.recordedTask = {
            name: _('taskrecorder.new-task'),
            commands: []
        };
        this._isForAutomaticDesigner = !!isForAutomaticDesigner; // stop state updates, listen to commands

        comp.emit(SandboxComp.ECEvent.TaskRecorderStart);

        _startScreenSaverTicks.call(this);
    };

    TaskRecorderExt.prototype.onCommandReceived = function onCommandReceived(cmdObj) {
        Debug.TaskRecorder && console.log("onCommandReceived: " + cmdObj.cmd);
        Debug.TaskRecorder && console.log("    request miniserver time");
        var miniserverTime = comp.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_DATE_TIME);
        Debug.TaskRecorder && console.log("    time: " + miniserverTime.format(TASK_DATE_FORMAT) + ", adding 1 hour");
        miniserverTime.add(1, "hours");

        if (this.recordedTask.commands.length === 0) {
            this.recordedTask.startDate = miniserverTime;
        }

        var command = {
            cmd: cmdObj.cmd,
            date: miniserverTime,
            delta: miniserverTime.diff(this.recordedTask.startDate)
        };
        var uuidAction = cmdObj.cmd.substring(0, cmdObj.cmd.indexOf("/"));
        addControlDetailsToCommand(command, uuidAction, cmdObj.argumentTexts);
        this.recordedTask.commands.push(command); // check if we added the first command, create a name!

        if (this.recordedTask.commands.length === 1) {
            this.recordedTask.name = this.recordedTask.commands[0].subText + ' - ' + this.recordedTask.commands[0].mainText;
            this.recordedTask.name = checkString(this.recordedTask.name);
        }

        this.recordedTask.isSecured = this.recordedTask.isSecured || command.isSecured;
        this.newTaskCommandsUpdateFn && this.newTaskCommandsUpdateFn(this.recordedTask.commands);
    };

    TaskRecorderExt.prototype.stopTaskRecording = function stopTaskRecording(task) {
        Debug.TaskRecorder && console.log("stopTaskRecording"); // enable state updates, stop listening to commands

        this.recordedTask = null;
        comp.emit(SandboxComp.ECEvent.TaskRecorderEnd);

        _stopScreenSaverTicks.call(this);
    };
    /**
     * Used by the QuickActionUtility to keep the names up to date.
     * @param cmd               the command to be sent (without jdev/sps/io)
     * @param ctrlUuid          the uuid of the control this command is for
     * @param [argumentTexts]   optional argument texts, if known.
     * @returns {*}             the userfriendly name for the task.
     */


    TaskRecorderExt.prototype.getTaskNameForControlCommand = function getTaskNameForControlCommand(cmd, ctrlUuid, argumentTexts) {
        Debug.TaskRecorder && console.log("getDetailsForCommand: " + cmd);
        var cmdObj = {
            cmd: cmd
        };
        addControlDetailsToCommand(cmdObj, ctrlUuid, argumentTexts);
        return cmdObj.mainText;
    };

    TaskRecorderExt.prototype.getRecordedTask = function getRecordedTask() {
        Debug.TaskRecorder && console.log("getRecordedTask");
        return this.recordedTask;
    };

    var updateTasks = function updateTasks() {
        Debug.TaskRecorder && console.log("updateTasks");
        clearTimeout(this.updateTasksTimeout);
        this.updateTasksTimeout = setTimeout(this._reloadTasks.bind(this), 200);
    };

    var parseTaskString = function parseTaskString(taskString, utcOffset) {
        Debug.TaskRecorder && console.log("parseTaskString: " + taskString);
        var singleCmds = taskString.split(", ");
        var tasks = [],
            taskPart,
            date,
            name,
            cmd,
            command,
            task;

        while (singleCmds.length) {
            taskPart = singleCmds.pop();
            date = taskPart.substring(0, 19);
            name = taskPart.substring(20, taskPart.indexOf("/"));
            cmd = taskPart.substring(taskPart.indexOf("/") + 1); // look if we have already a task named like this

            task = null;

            for (var i = 0; i < tasks.length; i++) {
                if (tasks[i].name === name) {
                    // stop at this index!
                    task = tasks[i];
                    break;
                }
            }

            if (task) {// we have already this task!
            } else {
                // add new tasks
                task = {
                    name: name,
                    commands: []
                };
                tasks.push(task);
            }

            var valWithZone = prepareDateWithZone(moment(date, TASK_DATE_FORMAT), utcOffset);
            command = {
                cmd: cmd,
                date: valWithZone,
                delta: 0 // relative time from startDate in ms

            };
            addControlDetailsToCommand(command, cmd.split("/")[0]);
            task.commands.push(command);
            task.isSecured = task.isSecured || command.isSecured;
        } // sort commands of each task, find startDate, set the relative time for each command


        prepareTasks(tasks); // sort all tasks

        tasks = sortByKey(tasks, "startDate");
        return tasks;
    };

    var prepareTasks = function prepareTasks(tasks) {
        var task;

        for (var i = 0; i < tasks.length; i++) {
            task = tasks[i]; // sort

            task.commands = sortByKey(tasks[i].commands, "date"); // and set the startDate

            task.startDate = task.commands[0].date; // set the delta for each command

            for (var j = 0; j < task.commands.length; j++) {
                task.commands[j].delta = task.commands[j].date.diff(task.startDate);
            }
        }
    };

    var addControlDetailsToCommand = function addControlDetailsToCommand(command, uuidAction, argumentTexts) {
        var control = ActiveMSComponent.getControlByUUID(uuidAction);

        if (control) {
            command.mainText = control.name;
            command.subText = control.groupDetail;
            command.isSecured = control.isSecured; // The Type is required for Client/Gateway Handling: BG-I11569

            command.type = control.type;

            try {
                command.mainText = createCmdText(control, command.cmd.split("/"), argumentTexts);
            } catch (exc) {
                console.error("Could not create userfriendly name for this task: " + command.cmd);
                console.error("     Reason: " + exc);
            }
        } else {
            var cleanCmd = cloneObjectDeep(command.cmd).replace(uuidAction + "/", ""); // remove uuid

            control = MediaServerComp.getZoneFromCommand(uuidAction, cleanCmd);

            if (control) {
                var cmdText = null;

                try {
                    cmdText = createCmdText(control, command.cmd.split("/"), argumentTexts);
                } catch (exc) {
                    console.error("Could not create cmd for audioZone!");
                }

                command.mainText = cmdText != null ? cmdText : control.name;
                command.subText = control.groupDetail;
            } else {
                command.mainText = command;
                command.subText = uuidAction;
            }

            command.isSecured = false;
        }
    };

    var compareTasks = function compareTasks(a, b) {
        var result = JSON.stringify(a.commands) === JSON.stringify(b.commands);
        result = result && a.isSecured === b.isSecured;
        result = result && a.name === b.name;
        result = result && a.startDate.format(TASK_DATE_FORMAT) === b.startDate.format(TASK_DATE_FORMAT);
        return result;
    };

    var checkString = function checkString(a) {
        // chars, digits, underscore, whitespace
        // multiple whitespaces are replaced with a single
        //a = a.replace(/[^\w\s]/gi, '').replace(/\s\s+/g, ' ' );
        a = a.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        a = a.replace(/[/]/gi, '').trim();
        if (!a) return _('taskrecorder.new-task'); else return a;
    };

    var getDeleteTaskCommand = function getDeleteTaskCommand(task, command) {
        return Commands.format(Commands.TASK_RECORDER.DELETE_TASK, command.date.format(TASK_DATE_FORMAT), task.name, command.cmd);
    };

    var getAddTaskCommand = function getAddTaskCommand(task, command, hash) {
        if (command.isSecured && hash) {
            return Commands.format(Commands.TASK_RECORDER.ADD_SEC_TASK, command.date.format(TASK_DATE_FORMAT), hash, task.name, command.cmd);
        } else {
            return Commands.format(Commands.TASK_RECORDER.ADD_TASK, command.date.format(TASK_DATE_FORMAT), task.name, command.cmd);
        }
    };

    var _startScreenSaverTicks = function _startScreenSaverTicks() {
        this.screenSaverPingInterval && _stopScreenSaverTicks.call(this);
        this.screenSaverPingInterval = setInterval(function () {
            SandboxComponent.activityTick()
        }.bind(this), ScreenSaverAvoidTimeout);
    };

    var _stopScreenSaverTicks = function _stopScreenSaverTicks() {
        this.screenSaverPingInterval && clearInterval(this.screenSaverPingInterval);
        this.screenSaverPingInterval = null;
    };

    return TaskRecorderExt;
});
