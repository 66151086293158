'use strict';

define("IRoomControllerV2ControlStateContainer", ["ControlStateContainer", 'IRoomControllerV2ControlEnums'], function (ControlStateContainer, IRoomControllerV2ControlEnums) {
    return class IRoomControllerV2ControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            var msNow = SandboxComponent.getMiniserverTimeAsFakeUTC(),
                // Add the UTC offset, the fake UTC is missing this information, this will lead to missing hours when using moment.diff()
                startOfToday = msNow.clone().startOf("day"),
                endOfToday = msNow.clone().endOf("day"),
                startOfOverrideEntry,
                overrideEntryStartsBeforeMidnight = false,
                overrideEntryEndsTomorrowOrLater = false;
            this.states.activeMode = newVals[this.control.states.activeMode];
            this.states.operatingMode = newVals[this.control.states.operatingMode];
            this.states.overrideEntries = JSON.parse(newVals[this.control.states.overrideEntries].text); // Add some properties we will need later

            this.states.overrideEntries.forEach(function (entry) {
                entry.startDate = entry.start ? new LxDate(entry.start, true) : startOfToday;
                entry.stopDate = entry.end ? new LxDate(entry.end, true) : endOfToday;
                startOfOverrideEntry = entry.startDate.clone().startOf('day');
                overrideEntryStartsBeforeMidnight = entry.startDate.isBefore(startOfToday);
                overrideEntryEndsTomorrowOrLater = entry.stopDate.isAfter(endOfToday); // Create minutes since midnight our self

                if (entry.start === 0 || overrideEntryStartsBeforeMidnight) {
                    entry.from = 0;
                } else {
                    entry.from = entry.startDate.diff(startOfOverrideEntry, "minutes");
                }

                if (overrideEntryEndsTomorrowOrLater) {
                    entry.to = 24 * 60;
                } else {
                    // Add the UTC offset manually, the diff somehow removes it
                    entry.to = entry.stopDate.diff(startOfToday, "minutes") + entry.stopDate.utcOffset();
                } // This property has been introduced later (still while in development)
                // to differentiate between timer entries and just overwrite entries


                if (!entry.hasOwnProperty("isTimer")) {
                    entry.isTimer = false;
                }
            });
            this.states.hasCurrentOverrideEntry = !!this.states.overrideEntries.length;
            this.states.prepareState = newVals[this.control.states.prepareState];
            this.states.overrideReason = newVals[this.control.states.overrideReason]; //=== Temperatures ===

            this.states.tempActual = newVals[this.control.states.tempActual];
            this.states.tempTarget = newVals[this.control.states.tempTarget];
            this.states.formattedTempActual = lxFormat(this.control.details.format, this.states.tempActual);
            this.states.comfortTemperature = newVals[this.control.states.comfortTemperature];
            this.states.comfortTolerance = newVals[this.control.states.comfortTolerance];
            this.states.absentMinOffset = newVals[this.control.states.absentMinOffset];
            this.states.absentMaxOffset = newVals[this.control.states.absentMaxOffset];
            this.states.frostProtectTemperature = newVals[this.control.states.frostProtectTemperature];
            this.states.heatProtectTemperature = newVals[this.control.states.heatProtectTemperature];
            this.states.comfortTemperatureOffset = newVals[this.control.states.comfortTemperatureOffset]; // excessTemperatureOffset, is available since 11.2 - caused by EC or EH from climate controllers

            if (this.control.states.excessEnergyTempOffset) {
                this.states.excessEnergyTempOffset = newVals[this.control.states.excessEnergyTempOffset];
            } else {
                this.states.excessEnergyTempOffset = 0;
            } //=== Temperatures ===
            // perpare flags to easily respond to excess energy usage.


            this.states.excessEnergyHeat = this.states.excessEnergyTempOffset > 0;
            this.states.excessEnergyCool = this.states.excessEnergyTempOffset < 0;

            if (this._prevComfortOffset !== this.states.comfortTemperatureOffset) {
                // The current mode should be displayed if the offset is 0, not 0°
                if (this.states.comfortTemperatureOffset === 0) {
                    this.comfortTempTimeout && clearTimeout(this.comfortTempTimeout);
                    this.comfortTempTimeout = null;
                } else {
                    if (this.hasOwnProperty("_prevComfortOffset")) {
                        this.comfortTempTimeout && clearTimeout(this.comfortTempTimeout);
                        this.comfortTempTimeout = setTimeout(function () {
                            this.comfortTempTimeout = null;
                            this.version++; // count up version with each update
                            // use this.newVals in favour of newVals. newVals may still have an old state, however this.newVals are the updated most up to date values sent by the miniserver

                            this.prepareStates(this.newVals);
                            this.notifyListener();
                        }.bind(this), 3000);
                    }
                }

                this._prevComfortOffset = this.states.comfortTemperatureOffset;
            } // can support picking heating/cooling based on outside temperature limits and provides info on
            // whether or not it is currently in heating or cooling mode.


            if (Feature.IRC_TEMP_LIMITS) {
                this.states.currentMode = newVals[this.control.states.currentMode];
                this.states.currentAutomatic = newVals[this.control.states.currentAutomatic];
                this.states.heatingTempBoundary = newVals[this.control.states.heatingTempBoundary];
                this.states.coolingTempBoundary = newVals[this.control.states.coolingTempBoundary];
                this.states.temperatureBoundaryInfo = newVals[this.control.states.temperatureBoundaryInfo];
                this.states.actualOutdoorTemp = newVals[this.control.states.actualOutdoorTemp];
                this.states.averageOutdoorTemp = newVals[this.control.states.averageOutdoorTemp];
            } else {
                this.states.currentAutomatic = IRoomControllerV2ControlEnums.CurrentAutomatic.REGULAR;
                this.states.currentMode = IRoomControllerV2ControlEnums.OperatingMode.UNDEFINED;
                this.states.temperatureBoundaryInfo = IRoomControllerV2ControlEnums.BoundaryInfo.NO_DATA;
            }
        }

        getStateTextShort() {
            if (this.comfortTempTimeout) {
                return this.control.getTempStringOfMode(this.states.activeMode, true);
            } else if (this.states.activeMode === IRoomControllerV2ControlEnums.Mode.ECO_PLUS) {
                return _("off");
            } else {
                return this.control.getTempModeDesc(this.states.activeMode, true);
            }
        }

        /**
         * return a state text to be displayed in e.g. the system scheme
         * @returns {string|null|undefined}
         */
        getStateTextForInfo() {
            return this.states.formattedTempActual + " (" + this.getStateTextShort() + ")";
        }

        getStateTextColor() {
            return this.control.getColorOfIRCTempMode(this.states.activeMode);
        }

        getStateIcon() {
            return this._generateTempSvg();
        }

        getStateIconSmall() {
            if (this.states.activeMode === IRoomControllerV2ControlEnums.Mode.MANUAL && (!this.states.hasCurrentOverrideEntry || !this.states.overrideEntries[0].isTimer)) {
                return null;
            } else if (this.states.isOff) {
                return null;
            }

            return {
                iconSrc: this.control.getIconOfIRCTempMode(),
                color: this.getStateTextColor()
            };
        }

        // Private
        _generateTempSvg() {
            var temp = this.states.tempActual;

            if (!this.control.isConfigured()) {
                temp = NaN;
            }

            return generateValueSvg(temp);
        }

    };
});
