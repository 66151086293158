'use strict';

define("SwitchControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class SwitchControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.isActive = newVals[this.control.states.active] === 1;
            this.states.lockedOn = !!newVals[this.control.states.lockedOn];
        }

        getStateText() {
            return null; // no state text required with switches.
        }

        getStateColor() {
            if (this.states.isActive) {
                return window.Styles.colors.stateActive;
            }
        }

    };
});
