'use strict';

define("SliderControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class SliderControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
        }

        prepareStates(newVals) {
            this.states.pos = newVals[this.control.states.value];
            this.states.hasError = newVals[this.control.states.error] > 0;
        }

        getStateText() {
            // In some formats the html entities will be encoded
            return lxFormat(this.control.details.format, this.states.pos).decodeHtmlEntities();
        }

    };
});
