'use strict';

CommunicationComp.factory('ConnectionManagerExt', ['PersistenceComp', function (PersistenceComp) {
    var CLOUDDNS_ERROR_RETRY_TIMEOUT = 5 * 1000,
        // 5s must be enough, to reduce server load
        UNAUTHORIZED_RETRY_TIMEOUT = 10 * 1000,
        // 10s timeout between retries with same password..
        OUT_OF_SERVICE_RETRY_TIMEOUT = 30 * 1000,
        // 30s timeout after outofservice + up to 10s random
        OUT_OF_SERVICE_RETRY_TIMEOUT_SHORT = 10 * 1000; // 10s timeout after outofservice + up to 5s random

    var CLOUDDNS_REFETCH_RETRIES = 10; // each x retries, refetch the IP+Port from CloudDNS

    var commComp,
        reachability,
        cloudDnsResolver,
        networkStatus,
        miniserver,
        currentReachMode,
        currentConfigVersion,
        currConnectionUrl,
        currentMacAddress,
        currReachInfo,
        currentConnectionType = CONNECTION_TYPE.NONE,
        lastWorkingReachMode = ReachMode.NONE,
        numberOfConnections = 0,
        numberOfCloudDNSResolveRetries = 0,
        numberOfSocketRetries = 0,
        unauthorizedRetries = 0,
        resolvedCloudDnsIp,
        bailOutCloudDNS,
        localSerialNoIsDifferentDetails = false,
        // if local reachability is faster (and says "serialNo changed") then remote answer from CloudDNS - needed to show popup if remote connection not possible
        socketErrorReconnectTimeout,
        // used to add a second delay after a socket error to prevent (fast) retry loop
        serialNoChangeProm,
        demoMsRetryCnt = 0,
        demoMsRetryLimit = 5,
        miniserverIsRebooting = false;

    /**
     * c-tor for ConnectionManager-Extension
     * @param comp reference to the CommunicationComponent
     * @constructor
     */

    function ConnectionManagerExt(comp) {
        commComp = comp;
        this.name = "ConnectionManagerExt";
        reachability = new ConnectivityTools.ReachabilityCheck();
        cloudDnsResolver = new ConnectivityTools.CloudDNSResolver();
        networkStatus = commComp.getNetworkStatus().status;
        reachability.setNetworkStatus(networkStatus);
        currentReachMode = ReachMode.NONE;
        currentConnectionType = CONNECTION_TYPE.NONE;
        currReachInfo = null;
        commComp.on(CommunicationComp.ECEvent.StartMSSession, function () {
            reachability.stopReachabilityDetection();
        }.bind(this));
        commComp.on(CommunicationComp.ECEvent.ConnEstablished, function () {
            // remove notification if shown
            this._removeConnNotification();

            miniserverIsRebooting = false;
            demoMsRetryCnt = 0;
        }.bind(this));
        commComp.on(CommunicationComp.ECEvent.StructureReady, function () {
            numberOfConnections++;
        }.bind(this));
        commComp.on(CommunicationComp.ECEvent.ConnClosed, function (ev, code) {

            NavigationComp.syncEntryPointURL() // EntrypointURL is now different from LastURL and only set on App launch, here is synchronized for miniserver reconnect

            currentReachMode = ReachMode.NONE;
            currentConnectionType = CONNECTION_TYPE.NONE;
            currReachInfo = null; // don't set back number of connections, because it was an unwanted interrupt

            unauthorizedRetries = 0;

            switch (code) {
                case SupportCode.WEBSOCKET_OUT_OF_SERVICE:
                    this._handleOutOfService(); // displays reboot message


                    this._startOutOfServiceRetry(); // starts retry timeout


                    break;

                case ResponseCode.OOS_MINISERVER_UPDATING:
                    this._handleOutOfService(true); // displays update message


                    this._startOutOfServiceRetry(); // starts retry timeout


                    break;

                case ResponseCode.NO_PERMISSION:
                    this._handleError({
                        code: code
                    });

                    break;

                case ResponseCode.INVALID_TOKEN:
                    var tokenAffected = cloneObject(miniserver.token); // ensure no connection data is kept in the archive, as it is no longer valid.

                    PersistenceComponent.deleteConnectionInformation(miniserver.serialNo, false, true, true, true);
                    delete miniserver.token; // remove the token on the object too, otherwise it will be used for reconnects.

                    this._handleError({
                        code: code,
                        token: tokenAffected
                    });

                    break;

                case ResponseCode.UNAUTHORIZED:
                case ResponseCode.BLOCKED_TEMP:
                case ResponseCode.FORBIDDEN:
                case ResponseCode.ON_USER_CHANGED:
                case ResponseCode.ON_THIS_USER_CHANGED:
                    // ensure no connection data is kept in the archive, as it is no longer valid.
                    PersistenceComponent.deleteConnectionInformation(miniserver.serialNo, false, true, true, true);
                    delete miniserver.token; // remove the token on the object too, otherwise it will be used for reconnects.

                    this._handleError({
                        code: code
                    });

                    break;

                case ResponseCode.ON_STRUCTURE_MODIFIED:
                    NavigationComp.showPopup({
                        buttonOk: true,
                        title: _("miniserver.conn-structure-modified.title"),
                        message: _("miniserver.conn-structure-modified.message"),
                        timeout: 10 * 1000
                    });

                    this._startReachModeCheck(commComp.getActiveMiniserver());

                    break;

                default:
                    if (code !== SupportCode.WEBSOCKET_MANUAL_CLOSE) {
                        // only if the connection wasn't closed manually
                        // use the miniserver from the active ms comp to ensure that the miniserver has all the new information from the structure
                        var ms = cloneObjectDeep(commComp.getActiveMiniserver());

                        if (PersistenceComp.backupAndSyncGetSyncUserChange()) {
                            var allMinservers = PersistenceComponent.getAllMiniserver();
                            ms.activeUser = allMinservers[ms.serialNo].activeUser;
                            delete ms.token;
                            PersistenceComp.backupAndSyncSetSyncUserChange(false);
                        }

                        this._startReachModeCheck(ms);
                    }

                    break;
            }
        }.bind(this));
        commComp.on(CommunicationComp.ECEvent.StopMSSession, function () {
            reachability.stopReachabilityDetection();
            miniserver = null;
            currentReachMode = ReachMode.NONE;
            currentConnectionType = CONNECTION_TYPE.NONE;
            lastWorkingReachMode = ReachMode.NONE;
            currReachInfo = null;
            numberOfConnections = 0;
            unauthorizedRetries = 0;

            this._resetCloudDnsStuff();

            this._lastDnsPopupContentHash = 0; // remove notification if shown

            this._removeConnNotification();

            this._stopReconnectTimeout();

            this._resetIgnoredSerialNumbers();
        }.bind(this));
        commComp.on(CommunicationComp.ECEvent.NetworkChanged, function (event, newStatus) {
            reachability.setNetworkStatus(newStatus);
            var currentStatus = networkStatus; // save to var!

            networkStatus = newStatus; // detect if we are connected remotely and the network changes from Cell to Lan -> reconnect!

            if (currentReachMode === ReachMode.REMOTE && currentStatus === NetworkStatus.CELL && newStatus === NetworkStatus.LAN) {
                this._checkForLocalReachability();
            }
        }.bind(this));
        commComp.on(CommunicationComp.ECEvent.Pause, function () {
            reachability.stopReachabilityDetection();
            miniserver = null;
            currentReachMode = ReachMode.NONE;
            currentConnectionType = CONNECTION_TYPE.NONE;
            currReachInfo = null; // don't set back numberOfConnections to avoid the big waiting view

            unauthorizedRetries = 0; // TODO don't request the address so often (Tom standup: 9.2.2016)

            this._resetCloudDnsStuff();

            this._stopReconnectTimeout();
        }.bind(this));
        commComp.on(CommunicationComp.ECEvent.Resume, function () {
            var ms = commComp.getActiveMiniserver();

            if (ms) {
                this._startReachModeCheck(ms);
            }
        }.bind(this));
    }

    ConnectionManagerExt.prototype.connectToMiniserver = function connectToMiniserver(ms) {
        Debug.Connectivity && console.log("ConnectionManager: connectToMiniserver: " + ms.msName);

        if (ms.lastConnectedDate) {
            var dateObj = new LxDate(moment.unix(ms.lastConnectedDate));
            Debug.Connectivity && console.log("   last connected on: " + dateObj.format(DateType.DateTextAndTime));
        }

        if (ms.url) {
            ms = prepareConnAddress(ms.url, ms, ActiveMSComponent.getDataCenter());
            delete ms.url;
        } // Never connect locally to test miniservers!


        if (CommunicationComponent.isDemoMiniserver(ms.remoteUrl)) {
            delete ms.localUrl;
        } // show always the waitingView


        NavigationComp.showWaitingView({
            state: WaitingState.ReachabilityCheck,
            miniserver: ms,
            connectionRetryCount: 0
        }); // reset connectionRetryCount to 0

        this._startReachModeCheck(ms);
    };

    ConnectionManagerExt.prototype.getCurrentConnectionType = function getCurrentConnectionType() {
        return currentConnectionType;
    };

    ConnectionManagerExt.prototype.getCurrentReachMode = function getCurrentReachMode() {
        return currentReachMode;
    };

    ConnectionManagerExt.prototype.getResolvedCloudDnsIp = function getResolvedCloudDnsIp(withPort) {
        // we might not have a resolvedCloudDnsIp, because the miniserver isn't configured to use cloudDNS, but a
        // control has set "clouddns" (e.g. intercom). Happend to LX-US and Martin Ö amongst others.
        var result = "";

        if (!!resolvedCloudDnsIp) {
            if (miniserver && miniserver.tlsInfo[ReachMode.REMOTE] === TlsState.PROVEN) {
                result = resolvedCloudDnsIp.tls;
            } else {
                result = resolvedCloudDnsIp.plain;
            }
        } else {
            result = miniserver.remoteUrl;
        }

        if (!withPort) {
            // remove the port (e.g. the intercom will specify it's own port)
            result = splitHostAndPort(result).host;
        }

        return result;
    };

    ConnectionManagerExt.prototype._startReachModeCheck = function _startReachModeCheck(ms) {
        Debug.Connectivity && console.log("ConnectionManager: _startReachModeCheck");

        if (!this._isInitialConnection()) {
            this._showConnNotification(_("miniserver.waiting.reachability"), 0);
        }

        miniserver = ms;
        numberOfCloudDNSResolveRetries = 0;

        this._stopReconnectTimeout();

        var remote = miniserver.remoteUrl,
            usesCloudDNS = typeof remote === "string" && isCloudDnsUrl(remote);

        if (usesCloudDNS) {
            // ensure the serialNo is known, it might be part of the remote url passed in.
            miniserver.serialNo = ms.serialNo || getSerialNo(remote); // if clouddns, remove the remote URL, as the IP will be resolved first.

            remote = null;
        }

        this._detectReachability(miniserver.localUrl, remote, miniserver.serialNo, usesCloudDNS);

        usesCloudDNS && this._requestAndHandleCloudDnsIp();
    };

    ConnectionManagerExt.prototype._requestAndHandleCloudDnsIp = function _requestAndHandleCloudDnsIp() {
        this._setBailOutCloudDNS(false);

        var splittedURL, snr; // retrieve the SNR from the remoteURL

        if (Regex.CLOUD_DNS.test(miniserver.remoteUrl)) {
            // dns.loxonecloud.com/504f941014d6
            splittedURL = miniserver.remoteUrl.split("/");
            snr = splittedURL[splittedURL.length - 1];
        } else {
            // 504f941014d6.dns.loxonecloud.com
            splittedURL = miniserver.remoteUrl.split(".");
            snr = splittedURL[0];
        }

        return cloudDnsResolver.resolve(snr, miniserver.dataCenter || getDataCenterFromRemoteUrl(miniserver.remoteUrl)).done(function (resolvedInfo) {
            if (bailOutCloudDNS) {
                return;
            }

            currentConnectionType = resolvedInfo.connectionType;
            resolvedCloudDnsIp = {
                plain: resolvedInfo.ipHttp,
                tls: resolvedInfo.ipHttps
            };
            miniserver.dataCenter = resolvedInfo.dataCenter;

            this._newRemoteHostDetected(resolvedInfo.ipHttp, resolvedInfo.ipHttps, resolvedInfo.dataCenter);
        }.bind(this), function (error) {
            Debug.Connectivity && console.error("ConnectionManager: _requestAndHandleCloudDnsIp failed with: " + JSON.stringify(error) + ", bailOutCloudDNS active? " + !!bailOutCloudDNS);
            !bailOutCloudDNS && this._handleError(error);
        }.bind(this));
    };

    ConnectionManagerExt.prototype._detectReachability = function _detectReachability(local, remote, serial, usesCloudDns) {
        var tld = usesCloudDns ? getCertTldFromDataCenter(ActiveMSComponent.getDataCenter(remote)) : false;
        Debug.Connectivity && console.log("ConnectionManager: _detectReachability: local: " + local + ", remote: " + remote + ", serial: " + serial + ", Cert-TLD: " + tld);
        this._reachNotifyCount = 0;
        reachability.detectReachability(local, remote, serial, this._detectTlsSupport(), usesCloudDns, tld).done(this._receivedReachMode.bind(this), this._handleError.bind(this), this._handleReachabilityNotification.bind(this));
    };

    ConnectionManagerExt.prototype._newRemoteHostDetected = function _newRemoteHostDetected(newHost, newHostTLS, dataCenter) {
        // when a different dataCenter than the one used so far is returned, update the waiting screen and the data stored for this ms.
        if (dataCenter) {
            var currDc = ActiveMSComponent.getDataCenter();

            if (currDc !== dataCenter && miniserver.remoteUrl.indexOf(currDc) >= 0) {
                console.warn(this.name, "_newRemoteHostDetected - updates the dataCenter!");
                console.warn(this.name, "          dataCenter stored: " + JSON.stringify(currDc));
                console.warn(this.name, "        dataCenter received: " + JSON.stringify(dataCenter));
                console.warn(this.name, "                 remote url: " + JSON.stringify(miniserver.remoteUrl)); // update the data stored for this miniserver!

                miniserver.remoteUrl = miniserver.remoteUrl.replace(currDc, dataCenter);
                ActiveMSComponent.setDataCenter(dataCenter);
                PersistenceComp.updateMiniserver(miniserver);
                console.warn(this.name, " > about to show/update waitingView! Updated remoteUrl: " + JSON.stringify(miniserver.remoteUrl)); // update the waiting screen so it'll show the right host!

                NavigationComp.showWaitingView({
                    state: WaitingState.ReachabilityCheck,
                    miniserver: miniserver,
                    reachMode: currentReachMode,
                    connectionRetryCount: numberOfCloudDNSResolveRetries
                });
            }
        }

        reachability.setNewRemoteHost(newHost, newHostTLS, dataCenter);
    };

    ConnectionManagerExt.prototype._receivedReachMode = function _receivedReachMode(result) {
        var reachMode = result.reachMode,
            useTLS = result.useTLS,
            isInTrust = result.isInTrust;
        currentConfigVersion = result.version;
        currentMacAddress = result.serialNo;
        currReachInfo = result;
        Debug.Connectivity && console.log("ConnectionManager: _receivedReachMode: " + ConvertReachMode(reachMode) + ", version: " + currentConfigVersion + ", mac: " + currentMacAddress);
        var reachModeChanged = false;

        if (reachMode !== ReachMode.NONE) {
            reachModeChanged = lastWorkingReachMode !== reachMode;
            lastWorkingReachMode = reachMode;

            if (reachMode === ReachMode.LOCAL) {
                // abort the CloudDns request, it may fail and display an error but we are already locally reachable
                cloudDnsResolver.stop();
            } // If the currentConnectionType has already been set by the cloudDNS resolver we are directly connected


            if (currentConnectionType === CONNECTION_TYPE.NONE) {
                currentConnectionType = CONNECTION_TYPE.DIRECT;
            } // inform other extensions, they might need to prepare


            commComp.emit(CommunicationComp.ECEvent.ReachabiltyConfirmed, result);
            currConnectionUrl = CommunicationComponent.getRequestProtocol() + result.host;

            this._stopReconnectTimeout();
        } else {
            currentConnectionType = CONNECTION_TYPE.NONE;
        } // ensure no serial number change dialog is visible, as a miniserver is reachable now.


        this._hideSerialNoChanged();

        if (PairedAppComponent.isPaired()) {
            miniserver.password = PairedAppComponent.getPairedAppPassword();
        }

        currentReachMode = reachMode;
        var user = miniserver.activeUser && miniserver.activeUser !== "";
        var password = miniserver.password && miniserver.password !== "";
        var token = miniserver.token && miniserver.token !== "";
        var trustToken = miniserver.trustToken && miniserver.trustToken !== "";
        miniserver.isInTrust = isInTrust;

        Debug.Trusts && trustToken && !useTLS && console.log(this.name, "   IGNORING trustToken, TLS not in place!");

        if (!user || (!password && !token && !trustToken)) {
            this._setBailOutCloudDNS(true); // otherwise the popup will appear when connecting the first time (after logout) (if eg. admin:admin is used)!


            this._handleError({
                code: ResponseCode.MISSING_CREDS,
                detail: currentReachMode
            });
        } else if (this._targetRequiresUpdate(result)) {
            this._handleError({
                code: ResponseCode.MS_UPDATE_REQUIRED,
                detail: currentReachMode
            });
        } else {
            this._removeCloudDnsErrorPopup();

            var url;

            if (currentReachMode === ReachMode.LOCAL) {
                url = miniserver.localUrl;

                if (typeof miniserver.remoteUrl === "string" && isCloudDnsUrl(miniserver.remoteUrl)) {
                    // set flag that the remote request bails out.
                    this._setBailOutCloudDNS(true);
                }
            } else if (currentReachMode === ReachMode.REMOTE) {
                if (isCloudDnsUrl(miniserver.remoteUrl)) {
                    this._setBailOutCloudDNS(true);

                    if (resolvedCloudDnsIp) {
                        url = result.host;
                    } else {
                        if (this._isInitialConnection()) {
                            // only show the first time we connect
                            // If we know, that the miniserver is rebooting, we don't show the bad connection screen
                            if (!miniserverIsRebooting) {
                                NavigationComp.showWaitingView({
                                    state: WaitingState.RequestingCloudDNS,
                                    miniserver: miniserver,
                                    reachMode: currentReachMode,
                                    connectionRetryCount: numberOfCloudDNSResolveRetries
                                });
                            }
                        } else {
                            this._showConnNotification(_("miniserver.waiting.requesting-clouddns"), numberOfCloudDNSResolveRetries);
                        }

                        if (miniserver && miniserver.tlsInfo[ReachMode.REMOTE] === TlsState.PROVEN) {
                            url = resolvedCloudDnsIp.tls;
                        } else {
                            url = resolvedCloudDnsIp.plain;
                        }
                    }
                } else {
                    url = miniserver.remoteUrl;
                }
            }

            numberOfSocketRetries = 0; // set back the socket retries, before trying to open the socket again

            Feature.update(currentConfigVersion); // we must update the features straight away, the WebSocket already uses it!

            var useEncryption = Feature.ENCRYPTED_SOCKET_CONNECTION;
            ActiveMSComponent.setIsInTrust(isInTrust);
            Q.when(url).done(this._openNewSocket.bind(this, reachModeChanged, useEncryption, useTLS), this._handleError.bind(this));
        }
    };

    ConnectionManagerExt.prototype._isInitialConnection = function _isInitialConnection() {
        return numberOfConnections === 0;
    };

    ConnectionManagerExt.prototype._openNewSocket = function _openNewSocket(reachModeDidChange, useEncryption, useTLS, url) {
        Debug.Connectivity && console.log("ConnectionManager: _openNewSocket reachModeDidChange: " + reachModeDidChange + ", encrypted: " + useEncryption + " useTLS: " + useTLS);
        var preparedUrl = CommunicationComponent.getHostName(url); // Sometimes the events are timed in a way that the miniserver object is been nulled right before the _openNewSocket call
        // This mainly occurs on Windows

        if (!miniserver) {
            Debug.Connectivity && console.log("ConnectionManager: _openNewSocket: Tried to open a new socket without a known miniserver!");
            return;
        } // if we connect the first time -> update waiting view


        if (this._isInitialConnection() || reachModeDidChange) {
            // If we know, that the miniserver is rebooting, we don't show the bad connection screen
            if (!miniserverIsRebooting) {
                NavigationComp.showWaitingView({
                    state: WaitingState.Connecting,
                    miniserver: miniserver,
                    reachMode: currentReachMode,
                    connectionRetryCount: numberOfSocketRetries,
                    useTLS: useTLS,
                    resolvedUrl: url
                }); // pass through the reachMode, because we didn't do it before
            }
        } else {
            this._showConnNotification(_("miniserver.waiting.establishing"), numberOfSocketRetries);
        } // passwords/tokens are passed around in its encrypted form, so we have to decrypt it first


        var useTokens = Feature.TOKENS, // newer miniservers are capable of using tokens instead of passwords.
            token = miniserver.token ? VendorHub.Crypto.decrypt(miniserver.token) : null,
            password = miniserver.password ? VendorHub.Crypto.decrypt(miniserver.password) : null,
            trustToken = miniserver.trustToken ? VendorHub.Crypto.decrypt(miniserver.trustToken) : null,
            trustTokenUsed = false,
            authReadyPrms;

        if (Feature.BEARER_TOKEN_PARAMETER &&
            !this._checkTokenValidity(token, miniserver.activeUser) && trustToken) {
            trustTokenUsed = true;
            authReadyPrms = this._resolveTrustToken(preparedUrl, trustToken, miniserver.activeUser).then((res) => {
                Debug.Connectivity && console.log(this.name, "_openNewSocket: proceeding with resolved trust token! ", res);
                return res;
            });
        } else {
            Debug.Connectivity && console.log(this.name, "_openNewSocket: proceeding with token or password! token=" + !!token, token);
            authReadyPrms = Q.resolve(token);
        }

        authReadyPrms.then((resolvedToken) => {
            return commComp.open(preparedUrl, miniserver.activeUser, password, useEncryption, useTokens, resolvedToken).then(() => {
                if (trustTokenUsed) {
                    // the trust token was successfully resolved!
                    commComp.emit(CommunicationComp.ECEvent.TokenReceived, { token: resolvedToken });
                }
                // since the connection is up, no need to keep (potential) trustTokens around.
                delete miniserver.trustToken;

                _updateActiveMiniserver(); // Use the ActiveMSComponent.getActiveMiniserver() instead of "miniserver" as miniserver may lack information set in "_updateActiveMiniserver()"


                commComp.emit(CommunicationComp.ECEvent.ConnEstablished, ActiveMSComponent.getActiveMiniserver(), currentReachMode, preparedUrl);

                if (password && CommunicationComponent.isKnownUserPassword(miniserver.activeUser, password)) {
                    console.error(this.name, "_openNewSocket > success, but the password provided is not safe! " + miniserver.activeUser + " " + password);

                    if (CommunicationComponent.isDemoMiniserver(miniserver.remoteUrl)) {
                        console.warn(this.name, "ignore unsafe password on demo miniserver! " + _("miniserverlist.add.test.url"));
                    } else {
                        this._handleUnsafePassword();
                    }
                }
            });
        }).then(null, function (error) {
            var errorCode = error.errorCode;

            if (errorCode === ResponseCode.SOCKET_FAILED && miniserver) {
                // miniserver is null if the user cancels the connection
                numberOfSocketRetries++; // count up socket fails

                Debug.Connectivity && console.log("ConnectionManager: establishing socket failed, try again in 3 secs");

                this._startReconnectTimeout(this._startReachModeCheck.bind(this, miniserver), 3000); // 3s timeouts

            } else if (errorCode === ResponseCode.INVALID_TOKEN && trustTokenUsed) {
                Debug.Connectivity && console.log("ConnectionManager: authorization using trustToken failed! " + JSON.stringify(error));
                delete miniserver.trustToken;

                this._handleError({
                    code: errorCode,
                    token: trustToken
                });
            } else if (errorCode === ResponseCode.INVALID_TOKEN) {
                Debug.Connectivity && console.log("ConnectionManager: socket authorization using token failed! " + JSON.stringify(error)); // the token on the ms object is removed already (invalid token event on commComp, use the locally stored one)

                if (trustToken && !trustTokenUsed) {
                    // retry using trustToken:
                    Debug.Connectivity && console.log("ConnectionManager:       --> but there is still a trustToken to try!");
                    this._openNewSocket(reachModeDidChange, useEncryption, useTLS, url);

                } else if (PairedAppComponent.isPaired()) {
                    delete miniserver.token;
                    this._openNewSocket(reachModeDidChange, useEncryption, useTLS, url);

                } else {
                    this._handleError({
                        code: errorCode,
                        detail: error.lastPwdChange,
                        token: token
                    });
                }

            } else if (errorCode === ResponseCode.UNAUTHORIZED) {
                Debug.Connectivity && console.log("ConnectionManager: socket authorization using password failed!");

                this._handleError({
                    code: errorCode,
                    detail: error.lastPwdChange
                });
            } else if (errorCode === ResponseCode.BLOCKED_TEMP) {
                Debug.Connectivity && console.log("ConnectionManager: temporarily blocked");
                PersistenceComponent.deleteConnectionInformation(miniserver.serialNo, false, true, true, true);
                delete miniserver.token; // remove the token on the object too, otherwise it will be used for reconnects.

                this._handleError({
                    code: errorCode,
                    detail: error.remaining
                });
            } else {
                console.warn("unhandled error code: " + errorCode);
            }
        }.bind(this));
    };

    ConnectionManagerExt.prototype._resolveTrustToken = function _resolveTrustToken(targetHost, trustToken, username, useTLS = false) {
        Debug.Connectivity && console.log("ConnectionManager: _resolveTrustToken: user="+ username);

        return CommunicationComponent.requestTokenWithBearer({
            targetHost,
            bearer: trustToken,
            user: username,
            msPermission: MsPermission.APP
        }).then(res => {
            Debug.Connectivity && console.log("ConnectionManager: _resolveTrustToken - resolved!" + JSON.stringify(res));
            return res.token;
        }, (err) => {
            Debug.Connectivity && console.error("ConnectionManager: _resolveTrustToken - failed!" + JSON.stringify(err));
            return Q.reject({
                errorCode: ResponseCode.INVALID_TOKEN
            });
        })
    }

    ConnectionManagerExt.prototype._checkTokenValidity = function _checkTokenValidity(token, username) {
        Debug.Connectivity && console.log(this.name, "_checkTokenValidity : " + username);
        let jwt = token ? parseJwt(token) : null;
        if (jwt && jwt.hasOwnProperty("payload")) {
            const expiresAtUnix = jwt.payload.exp;
            const now = moment().unix();
            if (jwt.payload.user !== username) {
                Debug.Connectivity && console.log(this.name, "    > invalid user! token has: " + jwt.payload.user + ", required:" + username);
                return null;
            } else if (expiresAtUnix < now) {
                Debug.Connectivity && console.log(this.name, "    > token expired already! " + new LxDate(moment.unix(expiresAtUnix), false).format(DateType.DateTextAndTime));
                return null;
            } else {
                Debug.Connectivity && console.log(this.name, "    > token is valid! expires: " + new LxDate(moment.unix(expiresAtUnix), false).format(DateType.DateTextAndTime));
                return token;
            }
        } else {
            Debug.Connectivity && console.log(this.name, "    > no token or not a JWT!");
            return null;
        }
    }

    ConnectionManagerExt.prototype._handleReachabilityNotification = function _handleReachabilityNotification(notification) {
        Debug.Connectivity && console.log("ConnectionManager: _handleReachabilityNotification: " + JSON.stringify(notification));
        var maxFail = notification.reachMode === ReachMode.LOCAL ? 5 : 3; // prevents error messages with bad connection

        if (notification.hasOwnProperty("retryCount") && notification.retryCount > maxFail) {
            // keep track of counts in here, as the retryCount from probes will vary between local & remote -> hence the
            // dots inside the waiting view will "jump" otherwise (local may be at retry 32 and remote on 15)
            this._reachNotifyCount++;

            if (this._isInitialConnection()) {
                // only show the waiting at the first time
                // If we know, that the miniserver is rebooting, we don't show the bad connection screen
                if (!miniserverIsRebooting) {
                    NavigationComp.showWaitingView({
                        state: WaitingState.ReachabilityCheck,
                        miniserver: miniserver,
                        connectionRetryCount: this._reachNotifyCount
                    });
                }
            } else {
                this._showConnNotification(_("miniserver.waiting.reachability"), this._reachNotifyCount);
            }
        }

        if (notification.hasOwnProperty("code")) {
            switch (notification.code) {
                case ResponseCode.SERIAL_NO_CHANGED:
                    this._handleSerialNoChanged(notification.alternative);

                    break;

                case ResponseCode.MS_OUT_OF_SERVICE:
                    this._handleOutOfService(notification.updating);

                    break;

                case ResponseCode.NO_MORE_EVENT_SLOTS:
                    this._handleNoEventSlotsLeft();

                    break;

                default:
                    break;
            }
        }

        if (notification.reachMode === ReachMode.REMOTE) {
            this._tryRefetchCloudDnsInfo(notification);
        }
    };
    /**
     * There are situations where the IP or the Port of a Miniserver might change. As of now, we would continue to
     * connect to the old info forever. As of now, we'll refetch the cloud dns info in an interval.
     * @param reachNotif    the notification contianing the reachmode and the retry interval.
     * @private
     */


    ConnectionManagerExt.prototype._tryRefetchCloudDnsInfo = function _tryRefetchCloudDnsInfo(reachNotif) {
        var shouldRefetch = true; // Is cloudDNS used at all?

        shouldRefetch = shouldRefetch && isCloudDnsUrl(miniserver.remoteUrl); // was CloudDNS bail out set in the meantime?

        shouldRefetch = shouldRefetch && !bailOutCloudDNS; // Have there been enough retry attempts already?

        shouldRefetch = shouldRefetch && reachNotif.retryCount % CLOUDDNS_REFETCH_RETRIES === 0; // if everything still is on GO - refresh the info

        shouldRefetch && this._requestAndHandleCloudDnsIp();
    };

    ConnectionManagerExt.prototype._handleError = function _handleError(error) {
        Debug.Connectivity && console.error("ConnectionManager: _handleError: Code=" + error.code + ", Translated: '" + translateEnum(ResponseCode, error.code) + "'. Full: " + JSON.stringify(error));
        var keepActiveMs = false;

        if (error.code === ResponseCode.OK) {
            // okay, manual cancel..
            return;
        } // remove the bad connection notification always, except when it's a cloud dns error (-> we would show it again immediately)


        if (error.code < ResponseCode.CLOUDDNS_ERROR) {
            // >= 700 = CloudDnsErrors!
            this._removeConnNotification();
        } // when a demo miniserver presents an error, it might have to be handled different.


        if (this._handleDemoMiniserverError(error.code)) {
            return; // nothing more to do.
        }

        switch (error.code) {
            case ResponseCode.MS_OUT_OF_SERVICE:
                keepActiveMs = true;
                miniserverIsRebooting = true;

                this._handleOutOfService();

                this._startOutOfServiceRetry(true);

                break;

            case ResponseCode.MISSING_CREDS:
                this._showCredentialsView({
                    state: LoginState.WAITING_FOR_CREDS,
                    miniserver: miniserver,
                    reachMode: currentReachMode
                });
                break;

            case ResponseCode.UNAUTHORIZED:
                this._showCredentialsView({
                    state: LoginState.INVALID_CREDS,
                    miniserver: miniserver,
                    reachMode: currentReachMode,
                    lastPwdChange: error.detail
                });
                break;

            case ResponseCode.INVALID_TOKEN:
                if (!error.token) {
                    console.error("InvalidToken received, while token not provided!");
                }

                if (!error.detail || error.detail === 0) {
                    console.error("InvalidToken received, while not providing the lastPwChange! " + JSON.stringify(error));
                }

                this._showCredentialsView({
                    state: LoginState.INVALID_TOKEN,
                    miniserver: miniserver,
                    reachMode: currentReachMode,
                    lastPwdChange: error.detail,
                    affectedToken: error.token
                });
                break;

            case ResponseCode.MS_UPDATE_REQUIRED:
                if (error.detail) {
                    NavigationComp.showErrorView({
                        errorCode: ErrorCode.MsUpdateRequired,
                        miniserver: miniserver,
                        reachMode: error.detail
                    });
                } else {
                    NavigationComp.showErrorView({
                        errorCode: ErrorCode.MsUpdateRequired,
                        miniserver: miniserver
                    });
                }

                break;

            case ResponseCode.NOT_FOUND:
                NavigationComp.showErrorView({
                    errorCode: ErrorCode.MsNotFound,
                    miniserver: miniserver,
                    reachMode: error.detail
                });
                break;

            case ResponseCode.NO_PERMISSION:
                NavigationComp.showErrorView({
                    errorCode: ErrorCode.NoPermission,
                    miniserver: miniserver
                });
                break;

            case ResponseCode.FORBIDDEN:
            case ResponseCode.EXPIRED_PERMISSION: // the socket was closed as the permission to access has expired
                this._showCredentialsView({
                    state: LoginState.USER_IS_DISABLED,
                    miniserver: miniserver
                });
                break;
            // CloudDNS

            case ResponseCode.CLOUDDNS_VPN_TIMEOUT:
            case ResponseCode.CLOUDDNS_MISSING_MQTT_CONNECTION:
                keepActiveMs = true;

                if (this._checkLocalSerialNoDifferent()) {
                    // only show show an cloudDns error when the MS isn't known to be rebooting!
                    !miniserverIsRebooting && this._showCloudDnsError(error);

                    this._startCloudDnsErrorRetry();
                }

                break;

            case ResponseCode.CLOUDDNS_NOT_REGISTERED:
            case ResponseCode.CLOUDDNS_NOT_CONFIGURED:
            case ResponseCode.CLOUDDNS_PORT_CLOSED:
            case ResponseCode.CLOUDDNS_SECURE_PWD_REQUIRED:
            case ResponseCode.CLOUDDNS_DENIED_CUSTOM_MESSAGE:
                keepActiveMs = true;

                if (this._checkLocalSerialNoDifferent()) {
                    this._showCloudDnsError(error);

                    this._startCloudDnsErrorRetry();
                }

                break;

            case ResponseCode.CLOUDDNS_ERROR:
                keepActiveMs = true;

                if (this._checkLocalSerialNoDifferent()) {
                    this._startCloudDnsErrorRetry();
                }

                break;

            case ResponseCode.BLOCKED_TEMP:
                this._showCredentialsView({
                    state: LoginState.TEMP_BLOCKED,
                    miniserver: miniserver,
                    remainingBlockedTime: error.detail
                });
                break;

            case ResponseCode.ON_USER_CHANGED:
                this._showCredentialsView({
                    state: LoginState.ON_USER_CHANGED,
                    miniserver: miniserver
                });
                break;

            case ResponseCode.ON_THIS_USER_CHANGED:
                this._showCredentialsView({
                    state: LoginState.ON_THIS_USER_CHANGED,
                    miniserver: miniserver
                });
                break;

            default:
                console.error("ConnectionManager: unhandled error! " + JSON.stringify(error));
                break;
        } // only in few cases, keeping the active miniserver set does make sense. In all others it should be reset.


        if (!keepActiveMs) {
            // in the other cases, it can cause issues (e.g. after cancelling a connection attempt), it might still be set
            ActiveMSComponent.disconnectMiniserver(true);
        }
    };



    ConnectionManagerExt.prototype._showCredentialsView = function _showCredentialsView({
                                                                                            miniserver,
                                                                                            state,
                                                                                            remainingBlockedTime,
                                                                                            reachMode,
                                                                                            lastPwdChange,
                                                                                            affectedToken
                                                                                        }) {

        NavigationComp.showCredentialsView({
            state, miniserver, remainingBlockedTime, reachMode, lastPwdChange, affectedToken
        });
    }

    /**
     * Checks if the current miniserver is a demo miniserver. If so, it'll also check if the erro code provided should
     * be treated different than with other Miniservers.
     * @param errCode
     * @return {boolean}
     * @private
     */


    ConnectionManagerExt.prototype._handleDemoMiniserverError = function _handleDemoMiniserverError(errCode) {
        var handled = false;

        try {
            if (CommunicationComponent.isDemoMiniserver(miniserver.remoteUrl) && demoMsRetryCnt < demoMsRetryLimit) {
                switch (errCode) {
                    case ResponseCode.MISSING_CREDS:
                    case ResponseCode.UNAUTHORIZED:
                    case ResponseCode.INVALID_TOKEN:
                    case ResponseCode.SERIAL_NO_CHANGED:
                        handled = true;
                        demoMsRetryCnt++;
                        Debug.Connectivity && console.log("ConnectionManager: error " + errCode + " for testminiserver, retry " + demoMsRetryCnt);
                        NavigationComp.connectToDemoMiniserver();
                        break;

                    default:
                        break;
                }
            }
        } catch (ex) {
            console.error("Could not determine if the current Miniserver is a demo miniserver!");
        }

        return handled; // nothing more to do.
    };
    /**
     * displays the waiting screen with out of service
     * @param [isUpdating]
     * @private
     */


    ConnectionManagerExt.prototype._handleOutOfService = function _handleOutOfService(isUpdating) {
        numberOfConnections = 0;

        this._removeConnNotification();

        NavigationComp.showWaitingView({
            state: isUpdating ? WaitingState.MsUpdating : WaitingState.MsOutOfService,
            miniserver: miniserver,
            reachMode: ReachMode.OUT_OF_SERVICE
        });
    };
    /**
     * displays the waiting screen with no more event slots left
     * @param [isUpdating]
     * @private
     */


    ConnectionManagerExt.prototype._handleNoEventSlotsLeft = function _handleNoEventSlotsLeft() {
        numberOfConnections = 0;

        this._removeConnNotification();

        commComp.emit(CommunicationComp.ECEvent.NoMoreEventSlots);
        NavigationComp.showWaitingView({
            state: WaitingState.NoMoreEventSlots,
            miniserver: miniserver
        });
    }; // ---------------------------------------------------------------------------------------------------------------
    //  Serial Number handling
    // ---------------------------------------------------------------------------------------------------------------


    ConnectionManagerExt.prototype._handleSerialNoChanged = function _handleSerialNoChanged(info) {
        var hasCloudDNS = typeof miniserver.remoteUrl === "string" && isCloudDnsUrl(miniserver.remoteUrl);

        if (hasCloudDNS && !resolvedCloudDnsIp && cloudDnsResolver.isPending()) {
            localSerialNoIsDifferentDetails = info; // wait for it..
        } else {
            // don't bail out clouddns, if it returns an IP, where the check is Okay, we should connect.
            this._showSerialNoChanged(info.data.serialNo);
        }
    };

    ConnectionManagerExt.prototype._showSerialNoChanged = function _showSerialNoChanged(serialNo) {
        if (serialNoChangeProm) {
            return;
        }

        Debug.Connectivity && console.log(this.name, "Ask if SNR should be changed: " + serialNo);
        var content = {
            title: _("warning"),
            message: _("misc.serialNoChanged", {
                newSerialNo: serialNo,
                miniserverName: miniserver.msName,
                oldSerialNo: miniserver.serialNo
            }),
            buttonOk: _("yes"),
            buttonCancel: _("no"),
            icon: Icon.CAUTION
        };
        serialNoChangeProm = NavigationComp.showPopup(content);
        serialNoChangeProm.then(function () {
            // connect to "new" miniserver
            // copies the old settings from the miniserver and adds them with the new serialNo
            PersistenceComp.replaceMiniserver(miniserver.serialNo, serialNo);
            miniserver.serialNo = serialNo; // reset the public key, will change!

            delete miniserver.publicKey; // when a miniserver changes, tokens will also be discarded and users need to log in again.

            delete miniserver.token;
            this.connectToMiniserver(miniserver);
            serialNoChangeProm = null;
        }.bind(this), function (btn) {
            // check if the popup was really dismissed by the user, otherwise an unwanted reload takes place.
            if (btn === GUI.PopupBase.ButtonType.CANCEL) {
                // from now on, ignore this snr, the reachabilityChecks will continue.
                this._ignoreSerialNumber(serialNo);
            }

            serialNoChangeProm = null;
        }.bind(this));
    };

    ConnectionManagerExt.prototype._hideSerialNoChanged = function _hideSerialNoChanged() {
        serialNoChangeProm && NavigationComp.removePopup(serialNoChangeProm);
        serialNoChangeProm = null;
    };

    ConnectionManagerExt.prototype._ignoreSerialNumber = function _ignoreSerialNumber(serial) {
        // no need to store this somewhere for next time, because if the right miniserver responds - the popup will disappear.
        reachability && reachability.ignoreSerialNumbers([serial]);
    };

    ConnectionManagerExt.prototype._resetIgnoredSerialNumbers = function _resetIgnoredSerialNumbers() {
        reachability && reachability.ignoreSerialNumbers([]);
    };

    ConnectionManagerExt.prototype._handleUnsafePassword = function _handleUnsafePassword() {
        var color = window.Styles.colors.orange,
            title = _("change-password.request-to-change-passwort");

        this._unsafePassNotification = GUI.Notification.createGeneralNotification({
            title: title,
            clickable: true,
            iconSrc: Icon.CAUTION,
            iconColor: color,
            removeAfter: 60
        }, NotificationType.WARNING);

        this._unsafePassNotification.on(GUI.Notification.CLICK_EVENT, function () {
            this._unsafePassNotification.remove();

            var canChange = SandboxComponent.checkPermission(MsPermission.CHANGE_PWD),
                message = _("change-password.explained");

            if (!canChange) {
                message += "<br><br>" + _("change-password.ask-admin-to-change-password");
            }

            NavigationComp.showPopup({
                title: title,
                color: color,
                message: message,
                buttonOk: canChange ? _("change-password") : _("okay"),
                buttonCancel: canChange ? _("not-now") : false
            }).then(function () {
                canChange && SandboxComponent.getCurrentMiniserverUserItem().action();
            });
        }.bind(this));

        this._unsafePassNotification.on("destroy", function () {
            this._unsafePassNotification = null;
        }.bind(this));
    }; // ---------------------------------------------------------------------------------------------------------------
    //  CloudDNS handling
    // ---------------------------------------------------------------------------------------------------------------


    ConnectionManagerExt.prototype._showCloudDnsError = function _showCloudDnsError(jqXHR, textStatus, errorThrown) {
        if (this._cloudDnsErrorPopup) {
            return; // we only display one error at a time..
        }

        var content = null;
        var keepConnectingOnOkay = false;

        switch (jqXHR.code) {
            case ResponseCode.CLOUDDNS_NOT_REGISTERED:
                if (this._shouldShowDnsUnregistered()) {
                    this._handleCloudDnsUnregistered();
                }

                break;

            case ResponseCode.CLOUDDNS_NOT_CONFIGURED:
                keepConnectingOnOkay = true;
                content = {
                    title: _("clouddns.configure-clouddns"),
                    message: _("clouddns.configure-clouddns-message"),
                    buttonOk: _("miniserver.conn-failed.keep-trying"),
                    buttonCancel: true
                };
                break;

            case ResponseCode.CLOUDDNS_PORT_CLOSED:
                keepConnectingOnOkay = true;
                content = {
                    title: _("clouddns.port-forwarding"),
                    message: _("clouddns.port-forwarding-message"),
                    buttonOk: _("miniserver.conn-failed.keep-trying"),
                    buttonCancel: true
                };
                break;

            case ResponseCode.CLOUDDNS_SECURE_PWD_REQUIRED:
                keepConnectingOnOkay = true;
                content = {
                    title: _('miniserver.credentials.default-pw-message-external.title'),
                    message: _('miniserver.credentials.default-pw-message-external.message'),
                    buttonOk: _("miniserver.conn-failed.keep-trying"),
                    buttonCancel: true
                };
                break;

            case ResponseCode.CLOUDDNS_MISSING_MQTT_CONNECTION:
                keepConnectingOnOkay = true;
                content = {
                    title: _('clouddns.connection-failed'),
                    message: _('clouddns.missing-mqtt-connection.message'),
                    buttonOk: _("miniserver.conn-failed.keep-trying"),
                    buttonCancel: true
                };
                break;

            case ResponseCode.CLOUDDNS_VPN_TIMEOUT:
                keepConnectingOnOkay = true;
                content = {
                    title: _('clouddns.connection-failed'),
                    message: _('clouddns.vpn-connection-timeout.message'),
                    buttonOk: _("miniserver.conn-failed.keep-trying"),
                    buttonCancel: true
                };
                break;

            case ResponseCode.CLOUDDNS_DENIED_CUSTOM_MESSAGE:
                var info = jqXHR.data.info;

                if (info && (info.title || info.message)) {
                    content = {
                        title: info.title,
                        message: info.message
                    };

                    if (info.link) {
                        content.buttonOk = _('media.preferences.services.editor.more-info');
                        content.buttonCancel = _('close');
                    } else {
                        content.buttonOk = true;
                    }
                }

                break;

            default:
                break;
        }

        if (content) {
            content.icon = Icon.CAUTION; // ignore popups that have already been confirmed (e.g. not registered, default password).

            var contentHash = JSON.stringify(content).hashCode();

            if (contentHash === this._lastDnsPopupContentHash) {
                return;
            }

            this._cloudDnsErrorPopup = NavigationComp.showPopup(content);

            this._cloudDnsErrorPopup.then(function () {
                delete this._cloudDnsErrorPopup;

                if (keepConnectingOnOkay) {
                    this._lastDnsPopupContentHash = contentHash;
                    return;
                }

                this._lastDnsPopupContentHash = 0;

                this._handleCloudDnsUserResponse();

                if (jqXHR.code === ResponseCode.CLOUDDNS_DENIED_CUSTOM_MESSAGE && jqXHR.data.info.link) {
                    NavigationComp.openWebsite(jqXHR.data.info.link);
                }
            }.bind(this), function (e) {
                delete this._cloudDnsErrorPopup;
                this._lastDnsPopupContentHash = 0; // only go to archive if the user taps cancel (the popup may also be removed by code if connection works again)

                if (e === GUI.PopupBase.ButtonType.CANCEL) {
                    this._handleCloudDnsUserResponse();
                }
            }.bind(this));
        }
    };

    ConnectionManagerExt.prototype._removeCloudDnsErrorPopup = function _cloudDnsErrorPopup() {
        if (this._cloudDnsErrorPopup) {
            NavigationComp.removePopup(this._cloudDnsErrorPopup);
            delete this._cloudDnsErrorPopup;
        }
    };
    /**
     * Presents the unregistered popup and handle the responses of the user to it. When hitting "ignore", hide the popup
     * for 6hrs.
     * @private
     */


    ConnectionManagerExt.prototype._handleCloudDnsUnregistered = function _handleCloudDnsUnregistered() {
        var content = {
            title: _("clouddns.register-miniserver"),
            message: _("clouddns.not-registered-error") + " " + _("clouddns.register-miniserver-message"),
            buttonOk: _("register"),
            buttonCancel: _("not-now")
        };
        this._cloudDnsErrorPopup = NavigationComp.showPopup(content);

        this._cloudDnsErrorPopup.then(function (btn) {
            // link to register homepage
            NavigationComp.openWebsite('https://www.loxone.com/reg'); // removed serial number argument as our website couldn't handle it.

            this._handleCloudDnsUserResponse();

            delete this._cloudDnsErrorPopup;
        }.bind(this), function (btn) {
            delete this._cloudDnsErrorPopup;

            if (btn === GUI.PopupBase.ButtonType.CANCEL) {
                // ignore the popup for the next 6 hours.
                miniserver._ignoreDnsRegisteredUntil = new LxDate().add(6, "hours");
            }
        }.bind(this));
    };
    /**
     * Only show the popup if the user didn't hit "ignore" earlier.
     * @private
     */


    ConnectionManagerExt.prototype._shouldShowDnsUnregistered = function _shouldShowDnsUnregistered() {
        var now = new LxDate();
        var shouldShow = true;

        if (miniserver._ignoreDnsRegisteredUntil) {
            shouldShow = now.isAfter(miniserver._ignoreDnsRegisteredUntil);
            Debug.Connectivity && console.log(this.name, "_shouldShowDnsUnregistered: " + shouldShow);
            Debug.Connectivity && console.log(this.name, "                hide until: " + miniserver._ignoreDnsRegisteredUntil.format(LxDate.getDateTimeFormat(DateType.WeekdayAndDateTextNumeralAndTime)));
        }

        return shouldShow;
    };
    /**
     * checks if the local reach mode check had returned that the serialNo is different
     * if so, it shows the popup and returns false, otherwise true
     * @returns {boolean}
     * @private
     */


    ConnectionManagerExt.prototype._checkLocalSerialNoDifferent = function _checkLocalSerialNoDifferent() {
        if (localSerialNoIsDifferentDetails) {
            this._showSerialNoChanged(localSerialNoIsDifferentDetails.data.serialNo);

            localSerialNoIsDifferentDetails = null;
            return false;
        }

        return true;
    };
    /**
     * creates and shows a connection notification --> it will not be shown asap, but after a 500ms delay to avoid that
     * a notification is shown only for a very brief moment (e.g. when locally reconnecting, which is very quick)
     * @param title
     * @param retryCount
     * @private
     */


    ConnectionManagerExt.prototype._showConnNotification = function _showConnNotification(title, retryCount) {
        Debug.Connectivity && console.log(this.name, "_showConnNotification: " + title + ", retryCount = " + retryCount);
        title += " ";
        var count = retryCount % 4;

        for (var i = 0; i < count; i++) {
            title += ".";
        }

        this._connNotificationTitle = title;

        if (this._connectionNotification) {
            Debug.Connectivity && console.log(this.name, "    already visible, update title"); // already visible, update!

            this._connectionNotification.setTitle(this._connNotificationTitle);
        } else if (this._connNotificationDelayed) {
            Debug.Connectivity && console.log(this.name, "    show is pending, updated title will be shown"); // will be shown later the title var is updated already --> will show proper title once shown.
        } else {
            Debug.Connectivity && console.log(this.name, "    show notification after a 500ms delay!");
            this._connNotificationDelayed = setTimeout(function () {
                Debug.Connectivity && console.log(this.name, "showConnNotification - showing scheduled notification");
                this._connNotificationDelayed = null;
                this._connectionNotification = GUI.Notification.createGeneralNotification({
                    title: this._connNotificationTitle
                }, NotificationType.SILENT);
            }.bind(this), 500);
        }
    };

    ConnectionManagerExt.prototype._removeConnNotification = function _removeConnNotification() {
        Debug.Connectivity && console.log(this.name, "_removeConnNotification");

        if (this._connectionNotification) {
            Debug.Connectivity && console.log(this.name, "   notification shown, remove");

            this._connectionNotification.remove();

            this._connectionNotification = null;
        }

        if (this._connNotificationDelayed) {
            Debug.Connectivity && console.log(this.name, "   notification pending, cancel");
            clearTimeout(this._connNotificationDelayed);
            this._connNotificationDelayed = null;
        }
    };

    ConnectionManagerExt.prototype._startReconnectTimeout = function _startReconnectTimeout(fn, delta) {
        socketErrorReconnectTimeout && clearTimeout(socketErrorReconnectTimeout);
        socketErrorReconnectTimeout = setTimeout(fn, delta);
    };

    ConnectionManagerExt.prototype._stopReconnectTimeout = function _stopReconnectTimeout() {
        socketErrorReconnectTimeout && clearTimeout(socketErrorReconnectTimeout);
        socketErrorReconnectTimeout = null;
    };

    ConnectionManagerExt.prototype._startCloudDnsErrorRetry = function _startCloudDnsErrorRetry() {
        numberOfCloudDNSResolveRetries++;

        this._startReconnectTimeout(function () {
            if (bailOutCloudDNS) {
                return;
            }

            this._startReachModeCheck(miniserver);
        }.bind(this), CLOUDDNS_ERROR_RETRY_TIMEOUT);
    };

    ConnectionManagerExt.prototype._startOutOfServiceRetry = function _startOutOfServiceRetry(useCloudDnsTimeout) {
        var timeout;

        if (isProbablyWallmountedTablet()) {
            timeout = Random.number(OUT_OF_SERVICE_RETRY_TIMEOUT, OUT_OF_SERVICE_RETRY_TIMEOUT + 10 * 1000); // range of 10s for random
        } else {
            timeout = Random.number(OUT_OF_SERVICE_RETRY_TIMEOUT_SHORT, OUT_OF_SERVICE_RETRY_TIMEOUT_SHORT + 5 * 1000); // range of 5s for random
        }

        if (useCloudDnsTimeout) {
            timeout = CLOUDDNS_ERROR_RETRY_TIMEOUT;
        }

        Debug.Connectivity && console.log("    using random timeout: " + timeout);

        this._startReconnectTimeout(function () {
            this._startReachModeCheck(miniserver);
        }.bind(this), timeout);
    };

    ConnectionManagerExt.prototype._startUnauthorizedRetry = function _startUnauthorizedRetry() {
        unauthorizedRetries++;

        this._startReconnectTimeout(function () {
            this._receivedReachMode([currentReachMode, currentConfigVersion, currentMacAddress]);
        }.bind(this), UNAUTHORIZED_RETRY_TIMEOUT);
    };
    /**
     * handles a response (okay, cancel) of the user to the popup of a clouddns error
     * cancels the connection and shows archive...
     * @private
     */


    ConnectionManagerExt.prototype._handleCloudDnsUserResponse = function _handleCloudDnsUserResponse() {
        Debug.Connectivity && console.log("ConnectionManager: _handleCloudDnsUserResponse");

        if (PlatformComponent.getPlatformInfoObj().platform !== PlatformType.Webinterface) {
            NavigationComp.showArchive();
        }
    };
    /**
     * Returns what TLS state this Miniserver has. is it capable of supporting it at all? Is it known to support it?
     * @return {number} the TlsState of the current target miniserver.
     * @private
     */


    ConnectionManagerExt.prototype._detectTlsSupport = function _detectTlsSupport() {
        var tlsSupport = ActiveMSComponent.getTlsInfo(),
            state;

        if (miniserver && miniserver.serialNo) {
            // detect tls support based on mac
            if (miniserver.serialNo.indexOf(TLS_MAC) === 0 || miniserver.serialNo.indexOf(TLS_MAC_COMPACT) === 0) {
                state = TlsState.POSSIBLE;
            } else {
                state = TlsState.UNSUPPORTED;
            } // ensure unknown is replaced with the detected support


            if (!tlsSupport.hasOwnProperty(ReachMode.LOCAL) || tlsSupport[ReachMode.LOCAL] === TlsState.UNKNOWN) {
                tlsSupport[ReachMode.LOCAL] = state;
            }

            if (!tlsSupport.hasOwnProperty(ReachMode.REMOTE) || tlsSupport[ReachMode.REMOTE] === TlsState.UNKNOWN) {
                tlsSupport[ReachMode.REMOTE] = state;
            }
        }

        return tlsSupport;
    };

    var isProbablyWallmountedTablet = function () {
        var platformInfo = PlatformComponent.getPlatformInfoObj();
        return platformInfo.platform !== PlatformType.Webinterface && platformInfo.platform !== PlatformType.DeveloperInterface && platformInfo.isTablet && PlatformComponent.getBatteryStateObj().isPlugged;
    };
    /**
     * Will ensure the current reachability infos are stored for this Miniserver.
     * @param unsafePassword
     * @private
     */


    var _updateActiveMiniserver = function _updateActiveMiniserver(unsafePassword) {
        // set the config and mac address so we don't have to request it again/have it available immediately..
        ActiveMSComponent.setConfigVersion(currentConfigVersion);
        ActiveMSComponent.setMiniserverSerialNo(currentMacAddress);
        ActiveMSComponent.setMiniserverConnectionUrl(currConnectionUrl); // Ensure the TLS state is stored too.

        var tlsState;

        if (currReachInfo.useTLS) {
            tlsState = TlsState.PROVEN;
        } else if (currentMacAddress.indexOf(TLS_MAC) === 0) {
            tlsState = TlsState.POSSIBLE;
        } else {
            tlsState = TlsState.UNSUPPORTED;
        }

        ActiveMSComponent.setTlsInfo(currReachInfo.reachMode, tlsState);
    };
    /**
     * Will determine whether or not a Miniserver that responded is capable of communicating with this app.
     * @param result
     * @private
     */


    ConnectionManagerExt.prototype._targetRequiresUpdate = function _targetRequiresUpdate(result) {
        var currentVersion = result.version,
            needsUpdate = new ConfigVersion(minimumConfigVersion).greaterThan(currentVersion);
        Debug.Connectivity && console.log("ConnectionManager: _targetRequiresUpdate: " + currentVersion + " -> " + needsUpdate);

        if (needsUpdate) {
            Debug.Connectivity && console.log("     " + minimumConfigVersion + " required for this App!");
        }

        return needsUpdate;
    };

    ConnectionManagerExt.prototype._setBailOutCloudDNS = function _setBailOutCloudDNS(shouldBailOut) {
        if (shouldBailOut) {
            cloudDnsResolver.stop();
        }

        bailOutCloudDNS = !!shouldBailOut;
    };

    ConnectionManagerExt.prototype._resetCloudDnsStuff = function _resetCloudDnsStuff() {
        Debug.Connectivity && console.log("ConnectionManager: _resetCloudDnsStuff");
        resolvedCloudDnsIp = null;
        cloudDnsResolver.stop();
    };
    /**
     * Will launch a local reachability verifier, if successful, the socket will be closed, which will
     * trigger a reconnect.
     * @private
     */


    ConnectionManagerExt.prototype._checkForLocalReachability = function _checkForLocalReachability() {
        Debug.Connectivity && console.log("ConnectionManager: _checkForLocalReachability");
        this.reachVerifier = this.reachVerifier || new ConnectivityTools.ReachabilityVerifier();

        if (this.reachVerifier.isActiveTarget(miniserver.localUrl, miniserver.serialNo)) {
            // nothing to do, already checking.
            Debug.Connectivity && console.log("   check already in progress..");
        } else {
            var usesCloudDNS = typeof miniserver.remoteUrl === "string" && isCloudDnsUrl(miniserver.remoteUrl);
            this.reachVerifier.verify(miniserver.localUrl, miniserver.serialNo, this._detectTlsSupport(), usesCloudDNS, 4).then(function () {
                // success, locally reachable!
                Debug.Connectivity && console.log("ConnectionManager: NetworkChanged - is now locally reachable, reconnect!");
                CommunicationComponent.closeWebSocket(SupportCode.WEBSOCKET_CLOSE);
            }, function () {
                Debug.Connectivity && console.log("ConnectionManager: NetworkChanged - still not locally reachable!");
            });
        }
    };

    return ConnectionManagerExt;
}]);
