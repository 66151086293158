'use strict';

ActiveMSComp.factory('AddonsExt', function () {
    let weakThis,
        activeMsComp = {},
        addonsPermissions = MsPermission.ADMIN | MsPermission.CONFIG | MsPermission.EXPERT_MODE | MsPermission.MANAGE_MS_PLUGINS;

    function AddonsExt(comp) {
        this.name = "AddonsExt";
        weakThis = this
        activeMsComp = comp;
    }

    /**
     * Get a list of all plugins at the miniserver
     * @returns {*}
     */


    AddonsExt.prototype.getAddonList = function getAddonList() {
        if (!Feature.ADDONS) {
            return Q([]);
        }

        var cmd = Commands.format(Commands.ADDONS.GET_ADDON_LIST);
        return SandboxComponent.sendWithPermission(cmd, addonsPermissions).then(function (res) {
            return getLxResponseValue(res);
        }.bind(this));
    };
    /**
     * Creates a specific miniserver plugin
     * @param identifier
     * @param name
     * @returns {*}
     */


    AddonsExt.prototype.createAddonInstance = function createAddonInstance(identifier, name) {
        if (!Feature.ADDONS) {
            return Q({});
        }

        var cmd = Commands.format(Commands.ADDONS.CREATE_ADDON, identifier),
            object = {
                name: name
            };
        return CommunicationComponent.sendViaHTTPWithPermission(cmd, addonsPermissions, EncryptionType.NONE, true, HTTP_METHODS.POST, JSON.stringify(object)).then(function (res) {
            return getLxResponseValue(res);
        });
    };
    /**
     * Deletes a specific miniserver plugin instance
     * @param uuid
     * @returns {*}
     */


    AddonsExt.prototype.deleteAddonInstance = function deleteAddonInstance(uuid) {
        if (!Feature.ADDONS) {
            return Q(true);
        }

        var cmd = Commands.format(Commands.ADDONS.DELETE_ADDON, uuid);
        return SandboxComponent.checkGrantedPermission(addonsPermissions).then(function () {
            return SandboxComponent.sendWithPermission(cmd, addonsPermissions).then(function (res) {
                return getLxResponseCode(res);
            }.bind(this));
        }.bind(this));
    };
    /**
     * Sends a plugin command
     * @param addonUuid
     * @param addonCommand
     * @param asString
     * @returns {*}
     */


    AddonsExt.prototype.sendAddonCommand = function sendAddonCommand(addonUuid, addonCommand, asString) {
        if (!Feature.ADDONS) {
            return Q({});
        }

        var cmd = Commands.format(Commands.CONTROL.COMMAND, addonUuid, addonCommand);
        return SandboxComponent.sendWithPermission(cmd, addonsPermissions).then(function (res) {
            return getLxResponseValue(res, asString);
        }.bind(this));
    };
    /**
     * Sends a plugin command via HTTP to the Miniserver, resolves with the addonCmds value if successful
     * @param addonUuid
     * @param addonCommand
     * @param asString
     * @param [httpMethod]  defaults to HTTP_METHODS.GET
     * @param [payload]     a payload
     * @returns {*}
     */


    AddonsExt.prototype.sendHttpAddonCommand = function sendHttpAddonCommand(addonUuid, addonCommand, asString, httpMethod, payload) {
        if (!Feature.ADDONS) {
            return Q({});
        }

        var cmd = Commands.format(Commands.CONTROL.COMMAND, addonUuid, addonCommand);
        return CommunicationComponent.sendViaHTTP(cmd, EncryptionType.NONE, true, httpMethod || HTTP_METHODS.GET, payload).then(function (res) {
            return getLxResponseValue(res, asString);
        }.bind(this));
    };

    return AddonsExt;
});
