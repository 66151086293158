'use strict';

define("ControlCentralStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class ControlCentralStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            this.subControlStates = [];
            this.control.forEachSubControl(function (control, cID, cIdx) {
                this.subControlStates.push({
                    control: control,
                    states: null,
                    allStatesReceived: control.isConfigured() ? false : true
                });

                this._registerSubControlStates(control, cIdx);
            }.bind(this));

            if (this.subControlStates.length === 0) {
                // if there are no subcontrols, no states will be received and therefore, no state updates will be
                // dispatched to anyone who may have already registered for them. (e.g. controlActionScreen & URL-Start)
                this._checkAllStatesReceived(true);
            }
        }

        getUUIDsToRegister() {
            if (this.control.supportsLocking()) {
                return super.getUUIDsToRegister(...arguments);
            } else {
                // return empty array, central controls take care of the states by registering to other StateContainers!
                return [];
            }
        }

        destroy() {
            this.control.forEachSubControl(function (control) {
                SandboxComponent.unregisterForStateChangesForUUID(control.uuidAction, this);
            }.bind(this));
            super.destroy(...arguments);
        }

        updateStatesForSelectedControls() {
            this._checkAllStatesReceived(true);
        }

        getSubControlAtIdx(idx) {
            return this.subControlStates[idx].control;
        }

        newStatesReceived(newVals) {
            // the collection of states from subcontrols is handled as usual
            if (Array.isArray(newVals)) {
                super.newStatesReceived(...arguments);
            } else {
                // the states for this control itself are to be handled separately.
                this._prepareUniversalStates(newVals);
            }
        }

        // Private methods
        _registerSubControlStates(control, cIdx) {
            SandboxComponent.registerForStateChangesForUUID(control.uuidAction, this, this._subControlReceivedStates.bind(this, control, cIdx), this._subControlAllStatesReceived.bind(this, control, cIdx));
        }

        _subControlReceivedStates(control, cIdx, states) {
            this.subControlStates[cIdx].states = states; // Use schedule instead of direct calls to checkAllStatesReceived, which greatly reduces the ammount of
            // calls to the registered listeners.

            this.__scheduleCheckAllStatesReceived(true);
        }

        _subControlAllStatesReceived(control, cIdx, allStatesReceived) {
            var asrBefore = this._checkAllStatesReceived();

            this.subControlStates[cIdx].allStatesReceived = allStatesReceived; // e.g. due to forceStatusUpdate after task-recording or a failed visu password request there may be
            // many many calls of _subControlAllStatesReceived - this would furthermore lead to tons of updateStates
            // calls via checkAllStatesReceived(true) and after that tons of calls with allStatesReceived.
            // To avoid that, use scheduleCheckAllStatesReceived and don't call schedule a successive call to
            // allStatesReceived after each subcontrol reported allStatesReceived (true or false).

            if (!this._checkSubCtrlAllStatesReceivedPromise || !this._checkSubCtrlAllStatesReceivedPromise.isPending()) {
                this._checkSubCtrlAllStatesReceivedPromise = this.__scheduleCheckAllStatesReceived(true).then(function _allStatesReceivedAsync(asr) {
                    if (asr) {
                        this.allStatesReceived(true);
                    } else if (asrBefore) {
                        this.allStatesReceived(false);
                    }
                }.bind(this));
            }
        }

        /**
         * Ensures that checkAllStatesReceived is only called once if several calls are being performed one after
         * another.
         * It reduces the number of dispatchStates-calls that may be performed after each checkAllStatesReceived.
         * This would occur if forceStateUpdates is used, e.g. after a visu pass request failed or a task recording
         * session has ended.
         * @param dispatch
         * @returns {*}
         * @private
         */
        __scheduleCheckAllStatesReceived(dispatch) {
            if (!this._checkAllStatesReceivedDef || !this._checkAllStatesReceivedDef.promise.isPending()) {
                this._checkAllStatesReceivedDef = Q.defer();
                setTimeout(function __scheduledCheckAll() {
                    var asr = this._checkAllStatesReceived(this._checkAllStatesReceivedShouldDispatch);

                    this._checkAllStatesReceivedDef.resolve(asr);
                }.bind(this), 100);
            }

            this._checkAllStatesReceivedShouldDispatch = this._checkAllStatesReceivedShouldDispatch || dispatch;
            return this._checkAllStatesReceivedDef.promise;
        }

        _checkAllStatesReceived(dispatch) {
            var asr = true,
                i;

            for (i = 0; i < this.subControlStates.length; i++) {
                if (!this.subControlStates[i].allStatesReceived) {
                    asr = false;
                    break;
                }
            }

            if (asr && dispatch) {
                this._dispatchStates();
            }

            return asr;
        }

        _dispatchStates() {
            var states = [];

            for (var i = 0; i < this.subControlStates.length; i++) {
                states.push(this.subControlStates[i].states);
            }

            states.forEachState = this._forEachStateHelperFn.bind(this, states);

            try {
                this.newStatesReceived(states);
            } catch (e) {
                console.error(e.stack);
            }
        }

        /**
         * helper function to get easy access to the needed information
         * @param states (from .bind)
         * @param fn    function to get called
         * @private
         */
        _forEachStateHelperFn(states, fn) {
            states.forEach(function (state, idx) {
                var ctrl = this.control.getSubControl(false, false, idx),
                    isCtrlSelected = this.control.isControlSelected(ctrl.uuidAction);

                if (ctrl.isConfigured()) {
                    fn(state, ctrl, isCtrlSelected);
                }
            }.bind(this));
        }

        _prepareUniversalStates(newVals) {
            // intercept, as the collected states of the subcontrols can't be used for the lockInfo
            if (this.control.supportsLocking() && !Array.isArray(newVals)) {
                super._prepareUniversalStates(...arguments);
            }
        }

    };
});
