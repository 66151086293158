'use strict';

define("SaunaControlStateContainer", ["ControlStateContainer", "SaunaControlEnums"], function (ControlStateContainer, SaunaControlEnums) {
    return class SaunaControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.power = !!newVals[this.control.states.power];

            this._prepareTemperatures(newVals);

            this.states.drying = !!newVals[this.control.states.drying];
            this.states.fanActive = !!newVals[this.control.states.fan];

            if (this.control.states.hasOwnProperty("tempAndHumidity")) {
                this.states.tempAndHumidity = newVals[this.control.states.tempAndHumidity];
            }

            this.states.doorClosed = newVals[this.control.states.doorClosed] === 1;
            this.states.presence = newVals[this.control.states.presence] === 1;
            this.states.timerTotal = getTimeObjectFromSeconds(newVals[this.control.states.timerTotal]);
            this.states.timerTotalString = LxDate.formatSecondsShort(newVals[this.control.states.timerTotal]);
            this.states.timerRemaining = newVals[this.control.states.timer];
            this.states.timerActive = this.states.timerRemaining > 0;
            this.states.timer = getTimeObjectFromSeconds(this.states.timerRemaining);
            this.states.timerString = LxDate.formatSecondsShort(this.states.timerRemaining);
            this.states.isActive = newVals[this.control.states.active] === 1;
            this.states.isActiveForUI = this.states.isActive && !this.states.fanActive && !this.states.drying; // we only show the sauna as active, if we aren't in drying or cooldown phase!

            this.states.error = newVals[this.control.states.error] === 1;
            this.states.saunaError = newVals[this.control.states.saunaError];

            if (this.control.details.hasVaporizer) {
                this.states.humidActualVal = newVals[this.control.states.humidityActual];
                this.states.humidTargetVal = newVals[this.control.states.humidityTarget];
                this.states.humidityActual = lxFormat("%.0f", this.states.humidActualVal);
                this.states.humidityTarget = lxFormat("%.1f", this.states.humidTargetVal, true);
                this.states.lessWaterWarning = newVals[this.control.states.lessWater] === 1;
                this.states.mode = this._getModeForNr(newVals[this.control.states.mode]);
            } else {
                this.states.lessWaterWarning = false;
                this.states.mode = this._getModeForNr(SaunaControlEnums.SaunaModes.NO_VAPOR.nr);
            }
        }

        _prepareTemperatures(newVals) {
            var newBenchTemp = newVals[this.control.states.tempBench],
                newRoomTemp = newVals[this.control.states.tempActual]; // actual temps

            this.states.hasBenchTemp = newBenchTemp !== 0;

            if (this.states.hasBenchTemp) {
                this.states.tempActualVal = newBenchTemp; // only show the room temp as secondary if it is neither 0 nor equal to the bench temp.

                this.states.hasSecondaryTemp = newRoomTemp !== 0 && newRoomTemp !== newBenchTemp;
                this.states.secondaryTemp = newRoomTemp;
                this.states.secondaryTempText = lxFormat("%.1f", newRoomTemp, true);
            } else {
                this.states.tempActualVal = newRoomTemp;
            }

            this.states.tempActual = lxFormat("%.0f", this.states.tempActualVal); // target temperatures

            this.states.tempTargetVal = newVals[this.control.states.tempTarget];
            this.states.tempTarget = lxFormat("%.1f", this.states.tempTargetVal, true);
        }

        getStateText() {
            if (this.states.isActive) {
                if (this.control.details.hasVaporizer) {
                    return this.states.mode.name;
                } else {
                    return _('active');
                }
            } else {
                return _("off");
            }
        }

        getStateTextShort() {
            if (this.states.saunaError) {
                return _("controls.sauna.notification.safety-shutdown.message.temp") + SEPARATOR_SYMBOL + _("controls.sauna.off");
            } else if (this.states.timerActive) {
                return _("controls.sauna.timer.active");
            } else if (this.states.fanActive) {
                return _("controls.sauna.airing-name");
            } else if (this.states.drying) {
                return _("controls.sauna.drying-name");
            } else if (this.states.lessWaterWarning) {
                return _('controls.sauna.less-water');
            } else if (this.states.power && this.states.tempActualVal < this.states.tempTargetVal) {
                return _("controls.sauna.heating-name");
            }

            return this.getStateText();
        }

        getStateColor() {
            if (this.states.error) {
                return window.Styles.colors.red;
            }
        }

        getStateTextColor() {
            if (this.states.error) {
                return window.Styles.colors.red;
            } else if (this.states.isActive) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateIcon() {
            if (this.states.isActiveForUI || this.states.timerActive) {
                return this._generateTempSvg();
            }
        }

        getStateIconSmall() {
            if (this.states.saunaError) {
                return {
                    iconSrc: Icon.Sauna.TEMPERATURE,
                    color: window.Styles.colors.red
                };
            } else if (this.states.lessWaterWarning) {
                return {
                    iconSrc: Icon.Sauna.LESS_WATER,
                    color: window.Styles.colors.blue
                };
            } else if (this.states.timerActive) {
                return {
                    iconSrc: Icon.Sauna.TIMER,
                    color: window.Styles.colors.stateActive
                };
            } else if (this.states.fanActive) {
                return {
                    iconSrc: Icon.Sauna.AIRING,
                    color: window.Styles.colors.stateActive
                };
            } else if (this.states.drying) {
                return {
                    iconSrc: Icon.Sauna.DRYING,
                    color: window.Styles.colors.stateActive
                };
            } else if (this.states.power) {
                return {
                    iconSrc: Icon.Sauna.SAUNA_CONTROL,
                    color: window.Styles.colors.stateActive
                };
            }
        }

        /**
         * return the state info
         * { title: "", message: "", color: "" }
         */
        getStateInfo() {
            var messages = [];

            if (this.states.saunaError) {
                messages.push(_("controls.sauna.notification.safety-shutdown.message.temp") + SEPARATOR_SYMBOL + _("controls.sauna.off"));
            }

            if (this.states.fanActive) {
                messages.push(_("controls.sauna.airing"));
            } else if (this.states.drying) {
                messages.push(_("controls.sauna.drying"));
            } else if (this.states.power && this.states.tempActualVal < this.states.tempTargetVal) {
                messages.push(_("controls.sauna.heating-up"));
            }

            if (this.states.lessWaterWarning) {
                messages.push(_("controls.sauna.less-water"));
            }

            if (this.states.timerActive) {
                messages.push(_("controls.sauna.time-left", {
                    time: LxDate.formatSecondsShort(this.states.timerRemaining, true, true)
                }));
            }

            return this._createStateInfo(messages.join(SEPARATOR_SYMBOL));
        }

        _getModeForNr(nr) {
            var modes = SaunaControlEnums.SaunaModes;

            for (var mode in modes) {
                if (modes.hasOwnProperty(mode)) {
                    if (modes[mode].nr === nr) {
                        return modes[mode];
                    }
                }
            }
        }

        _generateTempSvg() {
            var temp = this.states.tempActualVal;

            if (typeof temp !== "number") {
                temp = 0;
            }

            return generateValueSvg(temp);
        }

    };
});
