'use strict';

define("SystemSchemeControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class SystemSchemeControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control); // we have a statecontainer for a control without states on its own - at least an empty object is required.

            Object.assign(this, StateHandler.Mixin);
            control.states = {}; // register for main control's states - if there is one

            control.details.mainControl && CompChannel.on(CCEvent.StructureReady, function (event, updatePacket) {
                this.mainControl = ActiveMSComponent.getStructureManager().getControlByUUID(control.details.mainControl); // the user might not have rights for this control, only register if it has rights.

                this.mainControl && this._registerForStates(this.mainControl.uuidAction, ['stateText', 'stateTextColor']);
            }.bind(this));
        }

        /**
         * returns either the current states and the states version or false if not all states has been received yet
         * @returns {{states:{object}, version: {number}}|boolean}
         */
        getStatesForUUID() {
            var res = super.getStatesForUUID(...arguments);

            if (!this.mainControl) {
                res = {
                    states: this.states,
                    version: this.version
                };
            }

            return res;
        }

        prepareStates(newVals) {// nothing to do, but required for state udpates
        }

        receivedStates(states) {
            this.newStatesReceived();
        }

        getStateTextShort() {
            if (this.control.details.imagePath) {
                if (this.mainControl) {
                    return this.mainControl.getStateTextShort.apply(this.mainControl, arguments);
                } else {
                    return super.getStateText(...arguments);
                }
            } else {
                return _('system-scheme.error-title.no-image.title');
            }
        }

        getStateTextColor() {
            if (this.mainControl) {
                return this.mainControl.getStates().stateTextColor;
            } else {
                return super.getStateTextColor(...arguments);
            }
        }

    };
});
