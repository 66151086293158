'use strict';

ActiveMSComp.factory('AutomaticDesignerCommunicationExt', function () {
    let weakThis;

    function AutomaticDesignerCommunicationExt(comp) {
        this.name = "AutomaticDesignerCommunicationExt";
        this.comp = comp;
        weakThis = this
    }

    AutomaticDesignerCommunicationExt.prototype.convertTaskToRule = function convertTaskToRule(task) {
        if (Feature.AUTOMATIC_DESIGNER_TASK_RECORDER_CONVERT_AS_POST) {
            return CommunicationComponent.sendViaHTTP(Commands.format(Commands.CONTROL.COMMAND, AutomaticDesignerComponent.getAutomaticDesignerUuid(), Commands.format(Commands.AUTOMATIC_DESIGNER.CONVERT_TASK_POST)), EncryptionType.NONE, true, HTTP_METHODS.POST, JSON.stringify(task)).then(function (res) {
                if (typeof res !== "object") {
                    res = JSON.parse(res);
                }

                return res;
            });
        } else {
            return CommunicationComponent.sendViaHTTP(Commands.format(Commands.CONTROL.COMMAND, AutomaticDesignerComponent.getAutomaticDesignerUuid(), Commands.format(Commands.AUTOMATIC_DESIGNER.CONVERT_TASK, JSON.stringify(task))), EncryptionType.REQUEST).then(function (res) {
                if (typeof res !== "object") {
                    res = JSON.parse(res);
                }

                return res;
            });
        }
    };

    AutomaticDesignerCommunicationExt.prototype.fetchRulesFromMS = function fetchRulesFromMS(ruleType) {
        var cmd = Commands.format(Commands.CONTROL.COMMAND, AutomaticDesignerComponent.getAutomaticDesignerUuid(), Commands.format(Commands.AUTOMATIC_DESIGNER.GET_RULES, ruleType));
        Debug.Autopilot.Communication && console.log(this.name, "fetchRulesFromMS - type: " + ruleType);
        var start = timingNow();
        return CommunicationComponent.sendViaHTTP(cmd).then(function (res) {
            Debug.Autopilot.Communication && console.log(this.name, "fetchRulesFromMS - passed took " + timingDelta(start));
            var resultObj = getLxResponseValue(res);
            return resultObj.rules;
        }.bind(this));
    };

    AutomaticDesignerCommunicationExt.prototype.getSectionsFileVersionFromMS = function getSectionsFileVersionFromMS() {
        var cmd = Commands.format(Commands.CONTROL.COMMAND, AutomaticDesignerComponent.getAutomaticDesignerUuid(), Commands.AUTOMATIC_DESIGNER.GET_SECTIONS_VERSION);
        Debug.Autopilot.Communication && console.log(this.name, "getSectionsFileVersionFromMS - type: " + cmd);
        var start = timingNow();
        return SandboxComponent.send(cmd).then(function (res) {
            var valueStr = getLxResponseValue(res, true);
            Debug.Autopilot.Communication && console.log(this.name, "getSectionsFileVersionFromMS - passed: " + valueStr + ", took " + timingDelta(start));
            return valueStr;
        }.bind(this));
    };

    AutomaticDesignerCommunicationExt.prototype.getSectionsFileFromMS = function getSectionsFileFromMS() {
        Debug.Autopilot.Communication && console.log(this.name, "getSectionsFileFromMS");
        var start = timingNow(),
            promise;

        if (Feature.AUTOMATIC_DESIGNER_RAW_RESPONSE) {
            promise = CommunicationComponent.sendViaHTTP(Commands.format(Commands.CONTROL.COMMAND, AutomaticDesignerComponent.getAutomaticDesignerUuid(), Commands.AUTOMATIC_DESIGNER.GET_SECTIONS_DATA), EncryptionType.REQUEST).then(function (res) {
                if (typeof res !== "object") {
                    res = JSON.parse(res);
                }

                return res;
            });
        } else {
            promise = CommunicationComponent.sendViaHTTP(Commands.format(Commands.CONTROL.COMMAND, AutomaticDesignerComponent.getAutomaticDesignerUuid(), Commands.AUTOMATIC_DESIGNER.LEGACY_GET_SECTIONS_DATA), EncryptionType.REQUEST).then(function (res) {
                return getLxResponseValue(res);
            });
        }

        promise.then(function () {
            Debug.Autopilot.Communication && console.log(this.name, "getSectionsFileFromMS - passed, took " + timingDelta(start));
        }.bind(this));
        return promise;
    };

    AutomaticDesignerCommunicationExt.prototype.getCapabilitiesFileVersionFromMS = function getCapabilitiesFileVersionFromMS() {
        var cmd = Commands.format(Commands.CONTROL.COMMAND, AutomaticDesignerComponent.getAutomaticDesignerUuid(), Commands.AUTOMATIC_DESIGNER.GET_CONTROLS_VERSION);
        Debug.Autopilot.Communication && console.log(this.name, "getCapabilitiesFileVersionFromMS - type: " + cmd);
        var start = timingNow();
        return SandboxComponent.send(cmd).then(function (res) {
            var valueStr = getLxResponseValue(res, true);
            Debug.Autopilot.Communication && console.log(this.name, "getCapabilitiesFileVersionFromMS - passed: " + valueStr + ", took " + timingDelta(start));
            return valueStr;
        }.bind(this));
    };

    AutomaticDesignerCommunicationExt.prototype.getCapabilitiesFileFromMS = function getCapabilitiesFileFromMS() {
        Debug.Autopilot.Communication && console.log(this.name, "getCapabilitiesFileFromMS");
        var start = timingNow(),
            promise;

        if (Feature.AUTOMATIC_DESIGNER_RAW_RESPONSE) {
            promise = CommunicationComponent.sendViaHTTP(Commands.format(Commands.CONTROL.COMMAND, AutomaticDesignerComponent.getAutomaticDesignerUuid(), Commands.AUTOMATIC_DESIGNER.GET_CONTROLS_DATA), EncryptionType.REQUEST).then(function (res) {
                if (typeof res !== "object") {
                    res = JSON.parse(res);
                }

                return res;
            });
        } else {
            promise = CommunicationComponent.sendViaHTTP(Commands.format(Commands.CONTROL.COMMAND, AutomaticDesignerComponent.getAutomaticDesignerUuid(), Commands.AUTOMATIC_DESIGNER.LEGACY_GET_CONTROLS_DATA), EncryptionType.REQUEST).then(function (res) {
                return getLxResponseValue(res);
            });
        }

        promise.then(function (res) {
            Debug.Autopilot.Communication && console.log(this.name, "getCapabilitiesFileFromMS - passed, took " + timingDelta(start));
        }.bind(this));
        return promise;
    };

    AutomaticDesignerCommunicationExt.prototype.setRuleActive = function setRuleActive(rule, activeState) {
        var cmd = Commands.format(Commands.AUTOMATIC_DESIGNER.ACTIVATE_RULE, rule.id, activeState);
        return _sendCommand(cmd, !!(rule.isSecured || (rule.visu || {}).useVisuPwd)).then(function (res) {
            weakThis.comp.resetCachedVisuPw();
            return getLxResponseValue(res).rules;
        });
    };

    AutomaticDesignerCommunicationExt.prototype.deleteRule = function deleteRule(rule, isScene) {
        var cmd = Commands.format(Commands.AUTOMATIC_DESIGNER.DELETE_RULE, rule.id);
        return _sendCommand(cmd, !!(rule.isSecured || (rule.visu || {}).useVisuPwd)).then(function (res) {
            // deleting the local dataSet so the component has to wait until the miniserver is ready and provides the new dataset
            AutomaticDesignerComponent.deleteLocalDataSet(isScene);
            weakThis.comp.resetCachedVisuPw();
            return getLxResponseValue(res).rules;
        });
    };

    AutomaticDesignerCommunicationExt.prototype.executeRule = function executeRule(rule) {
        var cmd = Commands.format(Commands.AUTOMATIC_DESIGNER.EXECUTE_RULE, rule.id);
        return _sendCommand(cmd, !!(rule.isSecured || (rule.visu || {}).useVisuPwd)).then(function (res) {
            weakThis.comp.resetCachedVisuPw();
            return getLxResponseValue(res).rules;
        });
    };

    AutomaticDesignerCommunicationExt.prototype.addOrEditRule = function addOrEditRule(isScene) {
        var cmd = Commands.AUTOMATIC_DESIGNER.ADD_OR_EDIT_RULE,
            rule = AutomaticDesignerComponent.getCurrentEditRule(),
            visuPw = weakThis.comp.getCachedVisuPw(),
            cmdPrms;

        if (visuPw) {
            cmdPrms = CommunicationComponent.getHashForVisuPass(visuPw).then(function (hash) {
                return Commands.format(Commands.CONTROL.SECURED_COMMAND, hash, AutomaticDesignerComponent.getAutomaticDesignerUuid(), cmd);
            });
        } else {
            cmdPrms = Q(Commands.format(Commands.CONTROL.COMMAND, AutomaticDesignerComponent.getAutomaticDesignerUuid(), cmd));
        }

        return cmdPrms.then(function (fullCommand) {
            // deleting the local dataSet so the component has to wait until the miniserver is ready and provides the new dataset
            AutomaticDesignerComponent.deleteLocalDataSet(isScene);
            weakThis.comp.resetCachedVisuPw();
            return NavigationComp.showWaitingFor(CommunicationComponent.sendViaHTTP(fullCommand, EncryptionType.NONE, true, HTTP_METHODS.POST, JSON.stringify(rule)));
        });
    };

    var _sendCommand = function _sendCommand(cmd, isSecured) {
        // Check if we have acquired a VisuPW for this editing session and send the command secured
        var aquireVisuPwPrms = Q(weakThis.comp.getCachedVisuPw()),
            cmdPrms;

        if (isSecured) {
            aquireVisuPwPrms = weakThis.comp.acquireVisuPasswordForObj({
                uuid: AutomaticDesignerComponent.getAutomaticDesignerUuid(),
                isSecured: isSecured
            });
        }

        return aquireVisuPwPrms.then(function (visuPw) {
            if (visuPw) {
                cmdPrms = CommunicationComponent.getHashForVisuPass(visuPw).then(function (hash) {
                    return Commands.format(Commands.CONTROL.SECURED_COMMAND, hash, AutomaticDesignerComponent.getAutomaticDesignerUuid(), cmd);
                });
            } else {
                cmdPrms = Q(Commands.format(Commands.CONTROL.COMMAND, AutomaticDesignerComponent.getAutomaticDesignerUuid(), cmd));
            }

            return cmdPrms.then(function (fullCommand) {
                return SandboxComponent.sendWithPermission(fullCommand, MsPermission.AUTOPILOT, true);
            });
        });
    };

    return AutomaticDesignerCommunicationExt;
});
