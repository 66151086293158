'use strict';

define("PulseAtControlStateContainer", ["ControlStateContainer", "PulseAtControlEnums"], function (ControlStateContainer, CtrlEnums) {
    return class PulseAtControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.isActive = !!newVals[this.control.states.isActive];
            this.states.startTime = LxDate.fromMinutesSinceMidnight(newVals[this.control.states.startTime] / 60);
            this.states.startTimeString = LxDate.formatTimeFromSecondsToLocal(newVals[this.control.states.startTime], false);

            if (newVals[this.control.states.oneTimePulseDate] !== 0) {
                this.states.oneTimePulseDate = new LxDate(newVals[this.control.states.oneTimePulseDate], true).utc();
            } else {
                this.states.oneTimePulseDate = null;
            }

            this.states.pulseDuration = newVals[this.control.states.pulseDuration];
            this.states.type = newVals[this.control.states.type] || CtrlEnums.TYPE.TIME;
        }

        getStateText() {
            if (this.states.isActive) {
                return _("active");
            } else if (!this.states.oneTimePulseDate && this.states.type === CtrlEnums.TYPE.TIME) {
                return _("controls.pulseAt.daily", {
                    time: this.states.startTimeString
                });
            } else if (this.states.oneTimePulseDate && this.states.oneTimePulseDate.isAfter(ActiveMSComponent.getMiniserverTimeInfo()) && this.states.type === CtrlEnums.TYPE.TIME) {
                return _("controls.pulseAt.time", {
                    time: this.states.oneTimePulseDate.format(DateType.DateTextAndTimeSeconds)
                });
            } else if (!this.states.oneTimePulseDate && (this.states.type === CtrlEnums.TYPE.NIGHTFALL || this.states.type === CtrlEnums.TYPE.DAYBREAK || this.states.type === CtrlEnums.TYPE.SUNSET || this.states.type === CtrlEnums.TYPE.SUNRISE)) {
                return _("controls.pulseAt.daily", {
                    time: this.control.getNameForType(this.states.type)
                });
            } else if (this.states.oneTimePulseDate && this.states.oneTimePulseDate.isAfter(ActiveMSComponent.getMiniserverTimeInfo()) && (this.states.type === CtrlEnums.TYPE.NIGHTFALL || this.states.type === CtrlEnums.TYPE.DAYBREAK || this.states.type === CtrlEnums.TYPE.SUNSET || this.states.type === CtrlEnums.TYPE.SUNRISE)) {
                return this.states.oneTimePulseDate.format(DateType.DateText) + ", " + this.control.getNameForType(this.states.type);
            } else if (this.states.type === CtrlEnums.TYPE.YEAR || this.states.type === CtrlEnums.TYPE.MONTH || this.states.type === CtrlEnums.TYPE.DAY || this.states.type === CtrlEnums.TYPE.HOUR || this.states.type === CtrlEnums.TYPE.MINUTE || this.states.type === CtrlEnums.TYPE.SECOND || this.states.type === CtrlEnums.TYPE.START) {
                return this.control.getNameForType(this.states.type);
            } else if (this.states.oneTimePulseDate && this.states.oneTimePulseDate.isBefore(ActiveMSComponent.getMiniserverTimeInfo())) {
                return _("controls.pulseAt.inactive");
            } else {
                return null;
            }
        }

        getStateColor() {
            if (this.states.isActive) {
                return window.Styles.colors.stateActive;
            }

            return super.getStateColor(...arguments);
        }

    };
});
