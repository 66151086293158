'use strict';

define("MessageCenterStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class MessageCenterStateContainer extends ControlStateContainer {
        constructor(messageCenter) {
            var control = {
                uuidAction: 'messageCenter',
                type: 'messageCenter',
                states: messageCenter.states
            };
            super(control);
            this.messageCenter = messageCenter;
        }

        prepareStates(newVals) {
            Debug.SystemState && console.log("SystemState", "received new SystemState state");
            this.states.lastSeverityChange = newVals[this.messageCenter.states.changed]; // TODO-goelzda: M9-T129: Add timestamp for "No entry available", this will reduce communication with the Miniserver
        }

    };
});
