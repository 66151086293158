'use strict';

SandboxComp.factory('CommandExt', function () {
    var COMMAND_PENDING_TIMEOUT = 1500; // 1,5 secs

    var COMMAND_SENDING_TIMEOUT = 20; // 15ms in iOS/50ms in iOS earlier

    var VISU_PASSWORD_VALID_TIME = 60000; // 60secs valid

    let weakThis,
        sandbox,
        currentCommand,
        commandQueue = [],
        sendTimeout; // secured commands support

    var visuPassword,
        visuPasswordTimeout,
        visuPasswordAutoInvalidate = true,
        // if the visu pwd should be invalidated automatically
        requestVisuPasswordPrompt,
        securedCommandQueue = [];

    /**
     * c-tor of the Command Extension
     * @param comp reference to the parent component
     * @constructor
     */

    function CommandExt(comp) {
        weakThis = this
        sandbox = comp; // Reset Commands, Password

        sandbox.on(SandboxComp.ECEvent.Reset, function () {
            weakThis.resetVisuPassword();
            weakThis.resetCommandQueue();
        }); // TaskRecorder

        sandbox.on(SandboxComp.ECEvent.TaskRecorderStart, function () {
            weakThis.taskRecorderActive = true;
        });
        sandbox.on(SandboxComp.ECEvent.TaskRecorderEnd, function () {
            weakThis.taskRecorderActive = false;
        });
    }

    /**
     * Preproccesses the command before being sent to the Miniserver
     * @param sender control which sends the command
     * @param uuid to be used for sending
     * @param {string} command which should be sent
     * @param type of command handling
     * @param isSecured
     * @param automatic -   if the app sent the command by itself without user interaction. e.g. used by the
     *                      MediaServerComp to avoid prompting "bad connection" popups for automatically sent
     *                      commands. otherwise the user'd be confused as to why he sees a "command not sent"
     *                      popup without hitting a single button.
     * @param dontRecord    specifies if a command is to be ignored by the task recorder.
     * @param argumentTexts arguments texts array used for creating userfriendly texts describing the cmd, e.g. for QuickActions
     * @param encryptionType encryption type to be used
     * @returns {promise|*} promise whether the command has been sent or not
     */


    CommandExt.prototype.sendCommand = function sendCommand(sender, uuid, command, type, isSecured, automatic, dontRecord, argumentTexts, encryptionType) {
        Debug.Commands && console.log("sendCommand uuid:", uuid, "command:", command, "type:", type, "secured:", isSecured, "automatic", !!automatic, "dontRecord", !!dontRecord); // TaskRecorder

        if (weakThis.taskRecorderActive && !dontRecord) {
            // also check the dontRecord flag from the queue.
            return this._forwardToTaskRecorder(uuid, command, argumentTexts);
        } // create a cmd-deferred to be handled (queue, sending, ..)


        var def = Q.defer();
        def.isSecured = isSecured;

        if (def.isSecured) {
            def.secCommand = command;
            def.uuid = uuid;
        } else {
            def.uuid = uuid;
            def.command = Commands.format(Commands.CONTROL.COMMAND, uuid, command);
        }

        var control = ActiveMSComponent.getStructureManager().getControlByUUID(uuid);

        if (window.hasOwnProperty("siriShortcuts") && !automatic && !isSecured && control && !dontRecord) {
            this._donateSiriIntent(command, argumentTexts, control, def, uuid);
        }

        def.sender = sender;
        def.type = type || Commands.Type.QUEUE; // default is override!

        def.automatic = automatic; // store the dontRecord flag in the cmdQueue too!

        def.encryptionType = encryptionType;

        if (!def.isSecured) {
            // start timeout here only if not secured.. (maybe we must enter pw first...)
            Debug.Commands && console.log("start command timeout!");
            def.timeout = setTimeout(function () {
                def.notify(Commands.SendState.PENDING);
                callSetCommandsInQueue(def.sender, true);
                def.timeout = null;
            }, COMMAND_PENDING_TIMEOUT);
        }

        return this._sendCommandDef(def);
    };

    CommandExt.prototype._donateSiriIntent = function _donateSiriIntent(command, argumentTexts, control, def, uuid) {
        var serial = ActiveMSComponent.getActiveMiniserver().serialNo,
            text;

        try {
            text = createCmdText(control, [uuid].concat(command.split("/")), argumentTexts) + " (" + control.groupDetail + SEPARATOR_SYMBOL + ActiveMSComponent.getActiveMiniserver().msName + ")";
        } catch (e) {
            text = "";
        }

        if (text !== "") {
            siriShortcuts.donate(function () {
                console.log("Donation succeed");
            }, function () {
                console.log("Donation failed");
            }, serial, text, def.command);
        }
    },
        /**
         * resets the commandQueue after user canceled
         */
        CommandExt.prototype.resetCommandQueue = function resetCommandQueue() {
            Debug.Commands && console.log("resetCommandQueue");

            while (commandQueue.length) {
                var def = commandQueue.shift();
                def.reject(Commands.SendState.FAILED); // reset timeout

                clearTimeout(def.timeout);
                def.timeout = null; // inform controlDisabler

                callSetCommandsInQueue(def.sender, false);
            } // reset also the secured queue!


            resetSecuredCommandQueue();
        };
    /**
     * sends pending commands after connection was re-established
     */

    CommandExt.prototype.sendPendingCommands = function sendPendingCommands() {
        var nrOfCommands = commandQueue.length + securedCommandQueue.length;

        if (nrOfCommands > 0) {
            console.log("sending pending commands (" + nrOfCommands + ")");
            securedCommandQueue.forEach(function (secCmdObj) {
                // ensure the securedReadyToSend flag is reset, this ensures that either a new salt is retrieved or - if
                // the cmd was in the queue for a longer amount of time - the visupassword is requested again from the
                // user. Otherwise an outdated hash (salt no longer valid) is sent, that will fail and potentially delete
                // a visu password that has been stored using biometrics on the device.
                if (secCmdObj.securedReadyToSend) {
                    console.warn("   - enqueued secured command (" + secCmdObj.command + ") was already hashed, re-hash it before sending!");
                    secCmdObj.securedReadyToSend = false;
                }
            });
            var nexCmd = dequeueCommand();

            if (nexCmd) {
                weakThis._sendCommandDef(nexCmd);
            }

            return true;
        } else {
            return false;
        }
    };
    /**
     * resets the visu password after it was invalidated due to timeout or connection reset...
     */


    CommandExt.prototype.resetVisuPassword = function resetVisuPassword() {
        Debug.Commands && console.log("resetVisuPassword");
        visuPassword = null;
    };
    /**
     * resets the visu password after it was invalidated due to timeout or connection reset...
     */


    CommandExt.prototype.getVisuPassword = function getVisuPassword() {
        Debug.Commands && console.log("getVisuPassword");
        return visuPassword;
    };
    /**
     * Will store the new visu password provided & start the auto invalidation timer.
     */


    CommandExt.prototype.setVisuPassword = function setVisuPassword(newVisuPass) {
        Debug.Commands && console.log("setVisuPassword");
        visuPassword = newVisuPass; // restart the auto invalidation timer!

        this.setVisuPasswordAutoInvalidation(true);
    };
    /**
     * checks if a cached visu password is available
     * @returns {boolean}
     */


    CommandExt.prototype.hasCachedVisuPassword = function hasCachedVisuPassword() {
        Debug.Commands && console.log("hasCachedVisuPassword: " + !!visuPassword);
        return !!visuPassword;
    };
    /**
     * if the visu password should be automatically be invalidated after some time
     * @param autoInvalidate {boolean}
     */


    CommandExt.prototype.setVisuPasswordAutoInvalidation = function setVisuPasswordAutoInvalidation(autoInvalidate) {
        Debug.Commands && console.log("setVisuPasswordAutoInvalidation " + autoInvalidate);
        clearTimeout(visuPasswordTimeout);

        if (autoInvalidate && visuPassword !== null) {
            // start invalidation again...
            visuPasswordTimeout = setTimeout(weakThis.resetVisuPassword, VISU_PASSWORD_VALID_TIME);
        }

        visuPasswordAutoInvalidate = autoInvalidate;
    };

    CommandExt.prototype._forwardToTaskRecorder = function _forwardToTaskRecorder(uuid, command, argumentTexts) {
        Debug.Commands && console.log("     forward to task recorder!");
        var def,
            taskObject = {
                cmd: uuid + "/" + command,
                argumentTexts: argumentTexts
            },
            resp = {
                LL: {
                    control: "dev/sps/io/" + taskObject.cmd,
                    value: "",
                    code: 200
                }
            };
        sandbox.emit(SandboxComp.ECEvent.CommandForTaskRecorder, taskObject);
        def = Q.defer();
        def.notify("task recorded");
        def.resolve(resp);
        return def.promise;
    };
    /**
     * Will check if a current command is active. If not, it'll attempt to send the cmdDef now.
     * @param cmdDef
     * @returns {*}
     * @private
     */


    CommandExt.prototype._sendCommandDef = function _sendCommandDef(cmdDef) {
        if (!currentCommand && !sendTimeout) {
            currentCommand = cmdDef;

            if (currentCommand.isSecured) {
                if (currentCommand.securedReadyToSend) {
                    // command already hashed and ready to be sent!
                    // start timeout and send it off!
                    currentCommand.timeout = setTimeout(function () {
                        currentCommand.notify(Commands.SendState.PENDING);
                        callSetCommandsInQueue(currentCommand.sender, true);
                        currentCommand.timeout = null;
                    }, COMMAND_PENDING_TIMEOUT);
                } else {
                    return this._requestDataForSecuredCommand(currentCommand.encryptionType);
                }
            } // actually send the command.


            sandbox.send(currentCommand.command, currentCommand.encryptionType, !currentCommand.automatic // show badConnPopups, but never when commands are sent automatically
            ).then(this._responseReceived.bind(this), this._errorReceived.bind(this));
        } else {
            enqueueCommand(cmdDef);
        }

        return cmdDef.promise;
    };
    /**
     * Called when a command successfully responds. Will resolve the promise and then send the next cmd.
     * @param result
     * @private
     */


    CommandExt.prototype._responseReceived = function _responseReceived(result) {
        // inform controlDisabler - get all timed out commands..
        var commandsInQueue = getNrOfQueuedCommandsForSender(currentCommand.sender, true, currentCommand.isSecured) > 0;
        callSetCommandsInQueue(currentCommand.sender, commandsInQueue);

        if (currentCommand.isSecured && BiometricHelper.hasEnrolledBiometrics && !PersistenceComponent.getBiometricTokenForSecretType(BiometricHelper.SecretType.VisuPw)) {
            BiometricHelper.setSecretOfType(visuPassword, BiometricHelper.SecretType.VisuPw);
        }

        currentCommand.resolve(result);
        clearTimeout(currentCommand.timeout);
        currentCommand.timeout = null;
        currentCommand = null; // start timeout for sending next cmd!

        sendTimeout = setTimeout(function () {
            sendTimeout = null;
            var nextCommand = dequeueCommand();

            if (nextCommand) {
                weakThis._sendCommandDef(nextCommand);
            }
        }, COMMAND_SENDING_TIMEOUT);
    };
    /**
     * Called when a command responds with an error. If the VisuPW was in use and wrong the user will be asked for it
     * again. Otherwise the currentCommand will be rejected and the queue proceeds to the next one.
     * @param response
     * @private
     */


    CommandExt.prototype._errorReceived = function _errorReceived(response) {
        clearTimeout(currentCommand.timeout);
        currentCommand.timeout = null; // response could be a support code like socket closed

        if (typeof response !== 'number') {
            if (this._didSecureCommandFail(currentCommand, response)) {
                currentCommand.command = null;
                currentCommand.securedReadyToSend = false;
                weakThis.resetVisuPassword();
                requestVisuPassword(true);
            } else {
                currentCommand.reject(response); // inform controlDisabler

                callSetCommandsInQueue(currentCommand.sender, false);
                currentCommand = null;
                return;
            }
        }

        enqueueCommand(currentCommand, true); // inform controlDisabler

        callSetCommandsInQueue(currentCommand.sender, true);
        currentCommand = null;
    };
    /**
     * Detects if this command was a secure command, and if so, it additionally checks if it failed due to an invalid
     * visu password or due to some other error. E.g. code 500 indicates  an invalid visu pass, but if the value also
     * contains a text, it indicates that the pass was correct, but something was wrong with the request itself.
     * @param cmd
     * @param response
     * @return {boolean}
     * @private
     */


    CommandExt.prototype._didSecureCommandFail = function _didSecureCommandFail(cmd, response) {
        var code = getLxResponseCode(response),
            value = getLxResponseValue(response, true),
            secureCommandFail = false;

        if (cmd.isSecured && code === ResponseCode.SECURED_CMD_FAILED) {
            secureCommandFail = value === ""; // if a secured command fails without an empty value, it was not declined due to an invalid password.
        }

        return secureCommandFail;
    };
    /**
     * Will prepare the a secured command for being sent. it requests the visu pw from the user and a key from the
     * Miniserver so the visuPw can be sent without being compromised.
     * @returns {*}
     * @private
     */


    CommandExt.prototype._requestDataForSecuredCommand = function _requestDataForSecuredCommand() {
        var promise;

        if (!visuPassword) {
            // no visuPassword yet - put request it and put it back into the queue.
            requestVisuPassword();
            enqueueCommand(currentCommand);
            promise = currentCommand.promise;
            currentCommand = null;
        } else {
            CommunicationComponent.getHashForVisuPass(visuPassword).done(function (hash) {
                currentCommand.command = Commands.format(Commands.CONTROL.SECURED_COMMAND, hash, currentCommand.uuid, currentCommand.secCommand);
                currentCommand.securedReadyToSend = true; // call send with the currentCommand again!

                var nextCmd = currentCommand;
                currentCommand = null;

                weakThis._sendCommandDef(nextCmd);
            }, function (err) {
                console.error("Could not create the visu password hash! " + JSON.stringify(err));
                currentCommand.reject(Commands.SendState.FAILED);
                currentCommand = null;
            });
            promise = currentCommand.promise;
        }

        return promise;
    };

    var getQueuePosOfSender = function getQueuePosOfSender(queue, senderId) {
        var i;

        for (i = 0; i < queue.length; i++) {
            if (queue[i].isSecured) {
                if (queue[i].secCommand.indexOf(senderId) >= 0) {
                    return i;
                }
            } else {
                if (queue[i].command.indexOf(senderId) >= 0) {
                    return i;
                }
            }
        }

        return -1;
    };

    var getNrOfQueuedCommandsForSender = function getNrOfQueuedCommandsForSender(sender, timedOut, secured) {
        var count = 0;
        var cmdQueue = secured ? securedCommandQueue : commandQueue;

        for (var i = 0; i < cmdQueue.length; i++) {
            if (cmdQueue[i].sender === sender) {
                if (timedOut) {
                    if (cmdQueue[i].timeout === null) {
                        count++;
                    }
                } else {
                    count++;
                }
            }
        }

        return count;
    };
    /**
     * enqueues the command in the correct queue
     * @param {Promise} def
     * @param {Boolean} [unshift=false] if command should be unshifted (put to index 0!)
     */


    var enqueueCommand = function enqueueCommand(def, unshift) {
        Debug.Commands && console.log("enqueueCommand"); // put either in secCommandQueue or in normal commandQueue!

        var cmdQueue = def.isSecured ? securedCommandQueue : commandQueue;

        if (unshift) {
            def.notify(Commands.SendState.QUEUED);
            cmdQueue.unshift(def);
        } else if (def.type === Commands.Type.OVERRIDE) {
            var queuePos = getQueuePosOfSender(cmdQueue, def.uuid);

            if (queuePos > -1) {
                Debug.Commands && console.log("override command");
                cmdQueue[queuePos].notify(Commands.SendState.OVERRIDDEN);
                clearTimeout(cmdQueue[queuePos].timeout);
                cmdQueue[queuePos].timeout = null;
                cmdQueue[queuePos] = def;
            } else {
                Debug.Commands && console.log("queued command");
                def.notify(Commands.SendState.QUEUED);
                cmdQueue.push(def);
            }
        } else {
            Debug.Commands && console.log("queued command");
            def.notify(Commands.SendState.QUEUED);
            cmdQueue.push(def);
        }
    };

    var dequeueCommand = function dequeueCommand() {
        Debug.Commands && console.log("dequeueCommand");

        if (securedCommandQueue.length) {
            return securedCommandQueue.shift();
        } else if (commandQueue.length) {
            return commandQueue.shift();
        }

        return null;
    };

    var requestVisuPassword = function requestVisuPassword(wasWrong) {
        if (!requestVisuPasswordPrompt) {
            requestVisuPasswordPrompt = NavigationComp.showVisuPasswordPrompt(wasWrong).then(function success(result) {
                requestVisuPasswordPrompt = null;
                visuPassword = result.result;

                if (visuPasswordAutoInvalidate) {
                    visuPasswordTimeout = setTimeout(weakThis.resetVisuPassword, VISU_PASSWORD_VALID_TIME);
                } // start processing secured commands!


                weakThis._sendCommandDef(securedCommandQueue.shift());
            }, function error() {
                requestVisuPasswordPrompt = null;
                resetSecuredCommandQueue();
                SandboxComponent.forceStatusUpdate();
            });
        }
    };

    var resetSecuredCommandQueue = function resetSecuredCommandQueue() {
        if (requestVisuPasswordPrompt) {
            NavigationComp.removePopup(requestVisuPasswordPrompt);
            requestVisuPasswordPrompt = null;
            weakThis.resetVisuPassword();
        }

        while (securedCommandQueue.length) {
            var def = securedCommandQueue.shift();
            def.reject(Commands.SendState.FAILED);

            if (def.timeout) {
                clearTimeout(def.timeout);
                def.timeout = null;
            } // inform controlDisabler


            var commandsInQueue = getNrOfQueuedCommandsForSender(def.sender, true, true) > 0;
            callSetCommandsInQueue(def.sender, commandsInQueue);
        }
    };
    /**
     * we use this method, because the sender may be destroyed in the meantime (eg. navigate back..)
     * @param sender
     * @param commandsInQueue
     */


    var callSetCommandsInQueue = function (sender, commandsInQueue) {
        try {
            sender && sender.setCommandsInQueue && sender.setCommandsInQueue.call(sender, commandsInQueue);
        } catch (e) {
            console.error(e.stack);
        }
    };

    return CommandExt;
});
