'use strict';

define("AlarmCentralControlStateContainer", ["ControlCentralStateContainer"], function (ControlCentralStateContainer) {
    return class AlarmCentralControlStateContainer extends ControlCentralStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.nrOfArmed = 0;
            this.states.nrOfArmed_Sel = 0;
            this.states.nrOfAlarmActive = 0;
            this.states.nrOfAlarmActive_Sel = 0;
            this.states.nrOfDelayedArmed = 0;
            this.states.nrOfDelayedArmed_Sel = 0;
            this.states.allArmed = true;
            this.states.allArmed_Sel = true;
            newVals.forEachState(function (subCtrlNewVals, control, isCtrlSelected) {
                if (subCtrlNewVals.armed) {
                    this.states.nrOfArmed++;
                    isCtrlSelected && this.states.nrOfArmed_Sel++;
                } else {
                    this.states.allArmed = false;

                    if (isCtrlSelected) {
                        this.states.allArmed_Sel = false;
                    }
                }

                if (subCtrlNewVals.alarmActive) {
                    this.states.nrOfAlarmActive++;
                    isCtrlSelected && this.states.nrOfAlarmActive_Sel++;
                }

                if (subCtrlNewVals.delayedArmTimeLeft > 0) {
                    this.states.nrOfDelayedArmed++;
                    isCtrlSelected && this.states.nrOfDelayedArmed_Sel++;
                }
            }.bind(this));
        }

        getStateText() {
            if (this.states.nrOfAlarmActive > 0) {
                return _("controls.alarm.level.alarm");
            } else if (this.states.nrOfArmed === 0) {
                return _("controls.alarm.disarmed-short").capitalize();
            } else {
                return _("controls.alarm.group.armed", {
                    count: this.states.nrOfArmed
                });
            }
        }

        getStateColor() {
            if (this.states.nrOfAlarmActive > 0) {
                return window.Styles.colors.red;
            } else if (this.states.nrOfArmed > 0) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateIcon() {
            // check if old or new config
            if (this.states.nrOfAlarmActive > 0) {
                return Icon.Alarm.ALARM_ICON;
            } else {
                return Icon.Alarm.SHIELD;
            }
        }

    };
});
