'use strict';

define("AtheneControlStateContainer", ["ControlStateContainer", "AtheneMediaHandler", "AtheneControlEnums", "ActivityImageCache"], function (ControlStateContainer, MediaHandler, AtheneControlEnums, ActivityImageCache) {
    return class AtheneControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            this.name = this.name + " (" + this.control.name + ")";
        }

        prepareStates(newVals) {
            this.newVals = newVals;
            this.states.address = nullEmptyString(newVals[this.control.states.address].text);
            this.states.deviceState = newVals[this.control.states.deviceState];
            Debug.Control.Athene.StateContainer && console.log(this.name, "DeviceState: " + translateEnum(AtheneControlEnums.DEVICE_STATE, this.states.deviceState));
            this._previousBellState = this.states.bell;
            this.states.bell = !!newVals[this.control.states.bell]; // We reset the cached last Activity image on every new Bell event to ensure we always show the most up to date
            // but still be able to get any additional LastActivity images from the cache if there can't be a new one
            // (while the bell has not been activated another time)

            if (this._previousBellState !== this.states.bell && this.states.bell) {
                ActivityImageCache.shared(this.control.uuidAction).clear(MediaHandler.LAST_ACTIVITY_PATH);
                this.states.lastBellUnix = moment().unix();
            }

            this.states.muted = !!newVals[this.control.states.muted];
            this.states.answers = JSON.parse(nullEmptyString((newVals[this.control.states.answers] || {}).text) || "[]");
            this.states.localStreamState = this.mediaHandler ? this.mediaHandler.localStreamState : MediaHandler.LOCAL_STREAM_STATE.IDLE;

            if (!this.mediaHandler) {
                this.mediaHandler = MediaHandler.shared(this.control);
                this.mediaHandler.delegate = this;
            }

            if (this.control.isTrustMember) {
                this.states.comReady = true;
            } else {
                this.states.comReady = !!this._comReady;
            }

            this.states.callState = this._callState || MediaHandler.CALL_STATE.AVAILABLE;
            this.states.videoStreamQuotaExeeded = this._videoStreamQuotaExeeded;
            this.states.videoSettings = {};
            this.states.videoSettings[ReachMode.LOCAL] = {
                fps: newVals[this.control.states.videoSettingsIntern] & 0xffff,
                res: (newVals[this.control.states.videoSettingsIntern] & 0xffff) >> 16
            };
            this.states.videoSettings[ReachMode.REMOTE] = {
                fps: newVals[this.control.states.videoSettingsExtern] & 0xffff,
                res: (newVals[this.control.states.videoSettingsExtern] & 0xffff) >> 16
            };

            if (this.isFirstUpdate() && this.states.bell) {
                SandboxComponent.isInAppNotificationSettingTurnedOn(this.control.type).done(function () {
                    NavigationComp.showControlAlert(this.control.uuidAction);
                }.bind(this));
            }

            if ("version" in this.control.states) {
                this.states.version = nullEmptyString(newVals[this.control.states.version].text);
            } else if (this.mediaHandler && "version" in this.mediaHandler.deviceInfo) {
                this.states.version = this.mediaHandler.deviceInfo.version;
            }
        }

        /**
         * Returns an array of object with at least a "name" and "id" property
         * @param sourceState
         * @return {*[]}
         */
        getAutomaticDesignerStateObjectsFromState(sourceState) {
            var objectStates;

            switch (sourceState) {
                case "answers":
                    if (this.states[sourceState] instanceof Array) {
                        objectStates = this.states[sourceState].map(function (answer, idx) {
                            return {
                                id: idx,
                                name: answer
                            };
                        });
                    } else {
                        // Just in case there is something wrong with the state
                        objectStates = [];
                    }

                    break;

                default:
                    objectStates = super.getAutomaticDesignerStateObjectsFromState(...arguments);
            }

            return objectStates;
        }

        getStateText() {
            if (this.states.deviceState === AtheneControlEnums.DEVICE_STATE.OK) {
                if (!this.states.comReady) {
                    return _("controls.intercom.video.establish-connection");
                } else if (this.states.bell) {
                    return _("controls.intercom.notification.ringing");
                } else {
                    switch (this.states.localStreamState) {
                        case MediaHandler.LOCAL_STREAM_STATE.ERROR:
                        case MediaHandler.LOCAL_STREAM_STATE.IDLE:
                            return NBR_SPACE;

                        case MediaHandler.LOCAL_STREAM_STATE.ESTABLISHED:
                            return _("controls.intercom.voice.connection-active");

                        case MediaHandler.LOCAL_STREAM_STATE.ESTABLISHING:
                            return _("miniserver.credentials.login");
                    }
                }
            } else {
                switch (this.states.deviceState) {
                    case AtheneControlEnums.DEVICE_STATE.UNKNOWN:
                        return _("offline.x", {
                            x: this.control.getName()
                        });

                    case AtheneControlEnums.DEVICE_STATE.REBOOTING:
                        return _("booting.x", {
                            x: this.control.getName()
                        });

                    case AtheneControlEnums.DEVICE_STATE.INITIALIZING:
                        return _("controls.intercom.video.establish-connection");
                }
            }

            return NBR_SPACE;
        }

        getStateTextShort() {
            if (this.states.deviceState === AtheneControlEnums.DEVICE_STATE.OK && !this.states.comReady) {
                return NBR_SPACE;
            }

            return this.getStateText();
        }

        getStateIcon() {
            if (this.states.muted) {
                return Icon.Athene.BELL_MUTED_TILE;
            } else {
                return Icon.Athene.BELL_TILE;
            }
        }

        getStateColor() {
            if (this.states.deviceState === AtheneControlEnums.DEVICE_STATE.OK) {
                if (this.states.muted) {
                    return window.Styles.colors.red;
                }

            if (this.states.bell) {
                return window.Styles.colors.green;
            }
            }

            return super.getStateColor(...arguments);
        }

        getStateInfo() {
            if (this.mediaHandler.availableFeatures.localizedIncompatibilityNote) {
                return {
                    message: this.mediaHandler.availableFeatures.localizedIncompatibilityNote,
                    color: window.Styles.colors.orange
                };
            } else if (this.mediaHandler && this.mediaHandler.isActivelyCalling && MediaHandler.requiresIOSSpeakerFix) {
                return {
                    message: _("athene.ios-earpiece-bug-message"),
                    color: window.Styles.colors.orange
                };
            } else if (MediaHandler.mayCrashOnVideo) {
                return {
                    message: _("athene.iphone14-crash-message", {
                        modelName: MediaHandler.CrashableDeviceMap[PlatformComponent.getPlatformInfoObj().model] || _("loxone-control.configuration.device")
                    }),
                    color: window.Styles.colors.orange
                };
            }
        }

        // Media Handler delegates
        onComReady() {
            this._comReady = true;
            this.version++;
            this.newVals && this.prepareStates(this.newVals);
            this.notifyListener();
        }

        onComClosed(e) {
            this._comReady = false;
            this.version++;
            this.newVals && this.prepareStates(this.newVals);
            this.notifyListener();
        }

        onCallStateChanged(callState) {
            this._callState = callState;
            this.version++;
            this.newVals && this.prepareStates(this.newVals);
            this.notifyListener();
        }

        onLocalStreamStateChanged() {
            this.version++;
            this.newVals && this.prepareStates(this.newVals);
            this.notifyListener();
        }

        onRemoteStreamStateChanged() {
            var streamState = this.mediaHandler.remoteStreamState,
                error = this.mediaHandler.remoteStreamError;
            this._videoStreamQuotaExeeded = streamState === MediaHandler.REMOTE_STREAM_STATE.ERROR && error.info.code === MediaHandler.OFFER_ERROR.NUMBER_OF_VIDEO_STREAMS_EXCEEDED;
            this.version++;
            this.newVals && this.prepareStates(this.newVals);
            this.notifyListener();
        }

    };
});
