'use strict';

define("ClimateControlStateContainer", ["ControlStateContainer", "ClimateControlEnums"], function (ControlStateContainer, ClimateControlEnums) {
    return class ClimateControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.controls = JSON.parse(newVals[this.control.states.controls].text) || [];
            this.states.currentMode = newVals[this.control.states.currentMode];
            this.states.overwriteReason = newVals[this.control.states.overwriteReason];
            this.states.serviceMode = newVals[this.control.states.serviceMode];
            this.states.nextMaintenance = newVals[this.control.states.nextMaintenance]; // Unix timestamp!

            this.states.ventilation = newVals[this.control.states.ventilation];

            if (Feature.FULL_CLIMATE) {
                this.states.autoMode = newVals[this.control.states.autoMode];
                this.states.isAvgAutomatic = !!newVals[this.control.states.currentAutomatic];
                this.states.temperatureBoundaryInfo = newVals[this.control.states.temperatureBoundaryInfo]; // Graphic: https://drive.google.com/open?id=1pcMLsKtn-nfGFXwNRhRPjKTej30QNvk4

                this.states.onlyHeatingBoundary = newVals[this.control.states.heatingTempBoundary]; // if the "isAvgAutomatic" is under the "onlyHeatingBoundary" temperature the climateController changes to heating mode

                this.states.onlyCoolingBoundary = newVals[this.control.states.coolingTempBoundary]; // if the "isAvgAutomatic" is over the the "onlyCoolingBoundary" temperature the climateController changes to cooling mode

                this.states.actualOutdoorTemp = newVals[this.control.states.actualOutdoorTemp];
                this.states.infoText = newVals[this.control.states.infoText].text;
            }
            if (Feature.CLIMATE_CONTROLLER_OFF) {
                this.states.isOff = this.states.autoMode === ClimateControlEnums.AUTO_MODE.OFF &&
                    this.states.currentMode === ClimateControlEnums.MODE.NO_DEMAND;
            } else {
                this.states.isOff = false;
            }

            if (Feature.CLIMATE_AVG_OUTDOOR_TEMP) {
                // INFO: the variable will be -1000 if no avgOutdoorTemp is defined on the Miniserver!
                this.states.avgOutdoorTemp = newVals[this.control.states.averageOutdoorTemp];
            } // whether or not excess energy is available


            if (this.control.states.excessEnergy) {
                this.states.excessEnergyBitmask = newVals[this.control.states.excessEnergy];
            } else {
                this.states.excessEnergyBitmask = ClimateControlEnums.ExcessEnergyBit.NONE;
            }

            this.states.excessEnergyHeat = hasBit(this.states.excessEnergyBitmask, ClimateControlEnums.ExcessEnergyBit.HEAT);
            this.states.excessEnergyCool = hasBit(this.states.excessEnergyBitmask, ClimateControlEnums.ExcessEnergyBit.COOL);
        }

        getStateText() {
            var stateText;

            if (this.states.overwriteReason) {
                switch (this.states.overwriteReason) {
                    case ClimateControlEnums.OVERWRITE_REASON.EXTERNAL_HEATER:
                        stateText = _("controls.climate.overwrite.reason.external");
                        break;

                    case ClimateControlEnums.OVERWRITE_REASON.STOP:
                        stateText = _("controls.climate.overwrite.reason.stopped");
                        break;
                }
            }

            if (!stateText && this.states.isOff) {
                // climate controller can be turned off.
                stateText = _("off");
            }

            if (!stateText) {
                stateText = this._getNameOfCurrentMode();
            }

            return stateText;
        }

        getStateIcon() {
            return Icon.Climate.THERMOMETER;
        }

        getStateColor() {
            if (this.states.currentMode !== ClimateControlEnums.MODE.NO_DEMAND &&
                this.states.currentMode !== ClimateControlEnums.MODE.SERVICE_MODE &&
                this.states.overwriteReason !== ClimateControlEnums.OVERWRITE_REASON.STOP &&
                !this.states.isOff) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateIconSmall() {
            var icon = null;

            if (this.states.isOff) {
                return null;
            }

            if (this.states.overwriteReason === ClimateControlEnums.OVERWRITE_REASON.STOP) {
                return {
                    iconSrc: Icon.Climate.LOCKED
                };
            } else {
                switch (this.states.currentMode) {
                    case ClimateControlEnums.MODE.HEATING:
                        icon = {
                            iconSrc: Icon.Climate.ARROW_UP
                        };
                        break;

                    case ClimateControlEnums.MODE.COOLING:
                        icon = {
                            iconSrc: Icon.Climate.ARROW_DOWN
                        };
                        break;

                    case ClimateControlEnums.MODE.HEATING_BOOST:
                    case ClimateControlEnums.MODE.EXTERNAL_HEATER:
                        icon = {
                            iconSrc: Icon.Climate.BOOST.HEATING
                        };
                        break;

                    case ClimateControlEnums.MODE.COOLING_BOOST:
                        icon = {
                            iconSrc: Icon.Climate.BOOST.COOLING
                        };
                        break;

                    case ClimateControlEnums.MODE.SERVICE_MODE:
                        icon = {
                            iconSrc: Icon.WRENCH
                        };
                        break;
                }
            }

            if (icon) {
                icon.color = this.getStateTextColor();
            }

            return icon;
        }

        getStateTextColor() {
            var color = null;

            if (this.states.overwriteReason === ClimateControlEnums.OVERWRITE_REASON.STOP || this.states.isOff) {
                return color;
            }

            switch (this.states.currentMode) {
                case ClimateControlEnums.MODE.SERVICE_MODE:
                    color = window.Styles.colors.red;
                    break;

                case ClimateControlEnums.MODE.NO_DEMAND:
                    color = Color.TEXT_SECONDARY_B;
                    break;

                case ClimateControlEnums.MODE.EXTERNAL_HEATER:
                    color = window.Styles.colors.orange;
                    break;

                default:
                    color = window.Styles.colors.stateActive;
            }

            return color;
        }

        getStateInfo() {
            var info = null;

            if (nullEmptyString(this.states.infoText)) {
                if ((this.states.overwriteReason === 0 && this.states.currentMode === 0) ||
                    this.states.overwriteReason === ClimateControlEnums.OVERWRITE_REASON.CUSTOM_INFO) { // BG-I16333 the MS specifies the info entirely via infoText
                    info = {
                        // BG-I11692 - remove additional text, the MS shows the cause itself.
                        message: this.states.infoText
                    };
                } else {
                    info = {
                        message: _("controls.climate.override.logic.input", {
                            input: this.states.infoText
                        })
                    };
                }
            } else {
                switch (this.states.overwriteReason) {
                    case ClimateControlEnums.OVERWRITE_REASON.BOOST:
                        if (this.states.currentMode === ClimateControlEnums.MODE.HEATING_BOOST) {
                            info = {
                                message: _("controls.climate.overwrite.logic.boost.heating")
                            };
                        } else if (this.states.currentMode === ClimateControlEnums.MODE.COOLING_BOOST) {
                            info = {
                                message: _("controls.climate.overwrite.logic.boost.cooling")
                            };
                        }

                        break;

                    case ClimateControlEnums.OVERWRITE_REASON.EXTERNAL_HEATER:
                        info = {
                            message: _("controls.climate.overwrite.logic.external-heater")
                        };
                        break;

                    case ClimateControlEnums.OVERWRITE_REASON.STOP:
                        info = {
                            message: _("controls.climate.overwrite.logic.stopped")
                        };
                        break;

                    default:
                        info = this._getExcessEnergyInfo();
                        break;
                }
            }

            return info;
        }

        /**
         * Returns the name of the current mode
         * @return {*}
         * @private
         */
        _getNameOfCurrentMode() {
            var modeName;

            switch (this.states.currentMode) {
                case ClimateControlEnums.MODE.NO_DEMAND:
                    modeName = _("controls.climate.demand.none");
                    break;

                case ClimateControlEnums.MODE.HEATING:
                    modeName = _("controls.climate.mode.heating");
                    break;

                case ClimateControlEnums.MODE.COOLING:
                    modeName = _("controls.climate.mode.cooling");
                    break;

                case ClimateControlEnums.MODE.HEATING_BOOST:
                    modeName = _("controls.climate.mode.heating.boost");
                    break;

                case ClimateControlEnums.MODE.COOLING_BOOST:
                    modeName = _("controls.climate.mode.cooling.boost");
                    break;

                case ClimateControlEnums.MODE.SERVICE_MODE:
                    modeName = _("controls.irc.mode.service");
                    break;

                case ClimateControlEnums.MODE.EXTERNAL_HEATER:
                    modeName = _("controls.climate.mode.external-heater");
                    break;
            }

            return modeName;
        }

        _getExcessEnergyInfo() {
            var info = null,
                message = null; // only show if excess energy is available AND it is used.

            if (this.states.excessEnergyHeat && this._getIsHeating()) {
                message = _("controls.climate.excess-energy.heating");
            } else if (this.states.excessEnergyCool && this._getIsCooling()) {
                message = _("controls.climate.excess-energy.cooling");
            }

            if (message !== null) {
                info = {
                    message: message
                };
            }

            return info;
        }

        _getIsHeating() {
            var isHeating = false;

            switch (this.states.currentMode) {
                case ClimateControlEnums.MODE.HEATING:
                case ClimateControlEnums.MODE.HEATING_BOOST:
                case ClimateControlEnums.MODE.EXTERNAL_HEATER:
                    isHeating = true;
                    break;

                default:
                    break;
            }

            return isHeating;
        }

        _getIsCooling() {
            var isCooling = false;

            switch (this.states.currentMode) {
                case ClimateControlEnums.MODE.COOLING:
                case ClimateControlEnums.MODE.COOLING_BOOST:
                    isCooling = true;
                    break;

                default:
                    break;
            }

            return isCooling;
        }

    };
});
