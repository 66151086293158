'use strict';

import PairedAppEnums from "../pairedAppEnums";
import PairSetupCommunicator from "../helper/PairSetupCommunicator";
import PairingFile from "../helper/PairingFile";

ActiveMSComp.factory('PairingSetupExt', ['CommunicationComp', function (CommComp) {
    let weakThis;

    function PairingSetupExt(comp) {
        this.name = "PairingSetupExt";
        this.comp = comp;
        this._setupListeners = {};

        this._reachabilityCheck = null;
        this._ms = null;
        this._reachabilityResult = null;
        this._loginCreds = null;
        this._pairSetupComm = null;

        this._deviceList = [];
        this._roomMap = {};
        this._userGroupMap = {};

        this._pairSetupState = PairedAppEnums.PairSetupState.IDLE;
        this._pairSetupStateArgs = null;

        weakThis = this
    }

    PairingSetupExt.prototype.startPairingWith = function startPairingWith(ms) {
        Debug.PairedApp && console.log(weakThis.name, "startPairingWith: " + JSON.stringify(ms));

        if (this._ms) {
            this.stopPairing();
        }
        this._isPairingViaFile = false;
        this._pairSetupSrc = PairedAppEnums.SetupSource.MANUAL;
        this._oneTimeLogin = null;
        this._pairSetupComm = new PairSetupCommunicator(ms);
        this._ms = ms;

        this._startReachabilityCheck();
    };

    /**
     * Called when a pairing file has been provided.
     * @param pairingFile   the pairing (json) to setup the pairing with
     * @param src           where did it come from? QR or a file?
     * @returns {boolean}
     */
    PairingSetupExt.prototype.validatePairingFile = function validatePairingFile(pairingFile, src) {
        Debug.PairedApp && console.log(weakThis.name, "validatePairingFile (via " + src + ")");
        let isValid = !!pairingFile;

        isValid = isValid && !!nullEmptyString(pairingFile.username);
        isValid = isValid && !!nullEmptyString(pairingFile.password);
        isValid = isValid && !!nullEmptyString(pairingFile.serialNr);
        isValid = isValid && !!nullEmptyString(pairingFile.uuid);
        isValid = isValid && (!!nullEmptyString(pairingFile.localUrl) || !!nullEmptyString(pairingFile.remoteUrl));

        return isValid;
    }

    /**
     * Called when scanned a QR or imported a pairing file.
     * @param password !! ONE-TIME-PASSWORD!
     * @param src      how was the setup file received? QR,File?
     */
    PairingSetupExt.prototype.startPairingWithFile = function startPairingWithFile(
        {username, password, uuid, localUrl, remoteUrl, serialNr}, src) {
        Debug.PairedApp && console.log(weakThis.name, "startPairingWithFile: serial=" + serialNr + ", localUrl=" + localUrl + ", remoteUrl=" + remoteUrl);
        if (this._ms) {
            this.stopPairing();
        }

        this._isPairingViaFile = true;
        this._pairSetupSrc = src;
        this._ms = {
            localUrl,
            remoteUrl,
            serialNo: serialNr,
            activeUser: username
        }
        this._oneTimeLogin = {
            uuid,
            usePassword: true,
            permission: MsPermission.WEB, // important, as it ensures we're not requesting an invalid permission
            user: username,
            pass: password
        }

        this._pairSetupComm = new PairSetupCommunicator(this._ms);
        this._startReachabilityCheck();
    }

    PairingSetupExt.prototype.stopPairing = function stopPairing() {
        Debug.PairedApp && console.log(weakThis.name, "stopPairing: " + JSON.stringify(this._ms));
        this._stopReachabilityCheck();

        this._ms = null;
        this._reachabilityResult = null;
        this._loginCreds = null;
        this._pairSetupComm = null;
        this._oneTimeLogin = null;

        this._changeState(PairedAppEnums.PairSetupState.IDLE, null);
    };

    PairingSetupExt.prototype.registerForPairingSetup = function registerForPairingSetup(cb) {
        let rndId;
        do {
            rndId = getRandomIntInclusive(0, 10000) + "";
        } while (this._setupListeners.hasOwnProperty(rndId));
        Debug.PairedApp && console.log(weakThis.name, "registerForPairingSetup: #" + rndId);
        this._setupListeners[rndId] = cb;

        cb(this._getListenerCbArg());

        return () => {
            Debug.PairedApp && console.log(weakThis.name, "removeFromPairingSetupListeners: #" + rndId);
            delete this._setupListeners[rndId];
        }
    }

    PairingSetupExt.prototype.authenticateAsAdmin = function authenticateAsAdmin(user, pass) {
        this._loginCreds = {user, pass};
        this._changeState(PairedAppEnums.PairSetupState.ADMIN_AUTH_VERIFY, user);
        this._checkAdminAuth();
    }

    PairingSetupExt.prototype.returnToAdminAuthentication = function returnToAdminAuthentication() {
        delete this._loginCreds.pass;
        this._changeState(PairedAppEnums.PairSetupState.ADMIN_AUTH_REQUIRED, this._loginCreds);
    }

    PairingSetupExt.prototype.replaceDevice = function replaceDevice(existingDevice) {
        Debug.PairedApp && console.log(weakThis.name, "replaceDevice: " + JSON.stringify(existingDevice));
        this._changeState(PairedAppEnums.PairSetupState.PAIRING, existingDevice);

        const command = Commands.format(
            PairedAppEnums.Cmd.REPLACE,
            existingDevice.uuid,
            JSON.stringify(this._getDeviceSpecificJson())
        );
        this._pairSetupComm.send(command).then((res) => {
            Debug.PairedApp && console.log(weakThis.name, "replaceExistingDevice > success! ");
            this._handlePairingResult(res);
            this._changeState(PairedAppEnums.PairSetupState.PAIRED_DONE, res);

        }, err => {
            console.error(weakThis.name, "replaceExistingDevice > failed! ", err);
            this._changeState(PairedAppEnums.PairSetupState.PAIRING_FAILED, err);
        })
    }

    PairingSetupExt.prototype.startNewDeviceCreation = function startNewDeviceCreation(selectedGroupUuids = []) {
        Debug.PairedApp && console.log(weakThis.name, "startNewDeviceCreation");
        this._changeState(PairedAppEnums.PairSetupState.PAIR_AS_NEW, {
            rooms: Object.values(this._roomMap),
            availableUserGroupMap: this._userGroupMap || {},
            selectedGroupUuids
        });
    }

    PairingSetupExt.prototype.returnToDeviceSelection = function returnToDeviceSelection() {
        Debug.PairedApp && console.log(weakThis.name, "returnToDeviceSelection");
        this._changeState(PairedAppEnums.PairSetupState.LOADING_DEVICES);
        this._loadDeviceSelectionData();
    }

    PairingSetupExt.prototype.pairAsNewDevice = function pairAsNewDevice({name = null, room = null, userGroups = [], visuPassword = null}) {
        Debug.PairedApp && console.log(weakThis.name, "pairAsNewDevice: " + name);
        const createDevicePayload = {
            ...this._getDeviceSpecificJson(),
            name: (name || _("managed-tablet.title")),
            room, userGroups, visuPassword
        };
        this._changeState(PairedAppEnums.PairSetupState.PAIRING, createDevicePayload);

        const command = Commands.format(
            PairedAppEnums.Cmd.CREATE_DEVICE,
            JSON.stringify(createDevicePayload)
        );
        this._pairSetupComm.send(command).then((res) => {
            Debug.PairedApp && console.log(weakThis.name, "pairAsNewDevice > success!");
            this._handlePairingResult(res);
            this._changeState(PairedAppEnums.PairSetupState.PAIRED_DONE, res);

        }, err => {
            console.error(weakThis.name, "pairAsNewDevice > failed! ", err);
            this._changeState(PairedAppEnums.PairSetupState.PAIRING_FAILED, err);
        })
    }

    PairingSetupExt.prototype.reloadPairedDevices = function reloadPairedDevices() {
        this._changeState(PairedAppEnums.PairSetupState.LOADING_DEVICES);
        this._loadDeviceSelectionData();
    }



    // ------------------------------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------------------------------------
    // private methods
    // ------------------------------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------------------------------------

    PairingSetupExt.prototype._ensureState = function _ensureState(desiredState) {
        if (this._pairSetupState !== desiredState) {
            console.warn(this.name, "PairingSetupExt no longer in state '" + desiredState + "', but moved to '" + this._pairSetupState);
            return false;
        }
        return true;
    }

    /**
     * Updates the internal state and notifies registered listeners.
     * @param newState
     * @param stateArgs
     * @private
     */
    PairingSetupExt.prototype._changeState = function _changeState(newState, stateArgs) {
        Debug.PairedApp && console.log(this.name, "_changeState: " + this._pairSetupState + " -> " + newState);
        this._pairSetupState = newState;
        this._pairSetupStateArgs = stateArgs ? cloneObject(stateArgs) : null;
        Object.values(this._setupListeners).forEach(listenerFn => {
            listenerFn(this._getListenerCbArg());
        })
    }

    /**
     * Returns a defined object structure for the callback
     * @returns {{ms: null, state: string, stateArgs: null}}
     * @private
     */
    PairingSetupExt.prototype._getListenerCbArg = function _getListenerCbArg() {
        return {
            pairingState: this._pairSetupState,
            ms: this._ms,
            stateArgs: this._pairSetupStateArgs
        };
    }

    // region reachability

    PairingSetupExt.prototype._startReachabilityCheck = function _startReachabilityCheck() {
        this._changeState(PairedAppEnums.PairSetupState.REACHABILITY_CHECK, this._ms);

        Debug.PairedApp && console.log(this.name, "_startReachabilityCheck: " + JSON.stringify(this._ms));
        this._pairSetupComm.checkReachability().then(
            this._onReachabilityConfirmed.bind(this),
            this._onReachabilityError.bind(this),
            this._onReachabilityInfo.bind(this)
        );
    };

    PairingSetupExt.prototype._stopReachabilityCheck = function _stopReachabilityCheck() {
        Debug.PairedApp && console.log(this.name, "_stopReachabilityCheck");
        this._pairSetupComm.stopReachabilityCheck();
    };

    PairingSetupExt.prototype._onReachabilityConfirmed = function _onReachabilityConfirmed(result) {
        Debug.PairedApp && console.log(this.name, "_onReachabilityConfirmed: " + JSON.stringify(result));
        this._changeState(PairedAppEnums.PairSetupState.REACHABILITY_CONFIRMED, result);
        if (!Feature.hasFeature(result.version, PairedAppEnums.FwVersion)) {
            this._handleStopWith(PairedAppEnums.PairingStoppedReason.OUTDATED_FW, result.version);
        } else {
            this._reachabilityResult = result;
            if (this._isPairingViaFile) {
                this._pairUsingOneTimePassword();
            } else {
                this._changeState(PairedAppEnums.PairSetupState.ADMIN_AUTH_REQUIRED, {});
            }
        }
    };

    PairingSetupExt.prototype._onReachabilityError = function _onReachabilityError(error) {
        console.error(this.name, "_onReachabilityError: " + JSON.stringify(error));
    };

    PairingSetupExt.prototype._onReachabilityInfo = function _onReachabilityInfo(notification) {
        Debug.PairedApp && console.log(this.name, "_onReachabilityInfo: " + JSON.stringify(notification));
    };

    // endregion reachability

    PairingSetupExt.prototype._handleStopWith = function _handleStopWith(reason, payload) {
        console.error(this.name, "_handleStopWith: " + reason + ", pl=" + JSON.stringify(payload));
        this._changeState(PairedAppEnums.PairSetupState.STOPPED, {
            stopReason: reason,
            stopPayload: payload
        });
    };


    // region authentication

    PairingSetupExt.prototype._stillWaitingForLogin = function _stillWaitingForLogin() {
        return this._ensureState(PairedAppEnums.PairSetupState.ADMIN_AUTH_VERIFY);
    }

    PairingSetupExt.prototype._checkAdminAuth = function _checkAdminAuth() {
        Debug.PairedApp && console.log(this.name, "_checkAdminAuth");

        this._pairSetupComm.login(this._loginCreds).then(() => {
            if (!this._stillWaitingForLogin()) return;
            Debug.PairedApp && console.log(this.name, "_checkAdminAuth succeeded!");
            this._changeState(PairedAppEnums.PairSetupState.LOADING_DEVICES);
            this._loadDeviceSelectionData();
        }, err => {
            if (!this._stillWaitingForLogin()) return;
            console.error(this.name, "_checkAdminAuth failed!" + JSON.stringify(err));
            delete this._loginCreds.pass;

            let loginState = null,
                notAnAdmin = false;
            if (err.exception) {
                switch (err.exception.status) {
                    case ResponseCode.UNAUTHORIZED:
                        loginState = LoginState.INVALID_CREDS;
                        break;
                    case ResponseCode.BLOCKED_TEMP:
                        if (err.exception.statusText === "Forbidden") {
                            console.error(this.name, "  forbidden returned --> means that it's not a user.");
                            loginState = LoginState.INVALID_CREDS;
                            notAnAdmin = true;
                        } else {
                            loginState = LoginState.TEMP_BLOCKED;
                        }
                        break;
                    default:
                        break;
                }
            }



            this._changeState(PairedAppEnums.PairSetupState.ADMIN_AUTH_REQUIRED, {
                ...this._loginCreds,
                loginState,
                notAnAdmin
            });
        });
    }

    PairingSetupExt.prototype._pairUsingOneTimePassword = function _pairUsingOneTimePassword() {
        Debug.PairedApp && console.log(this.name, "_pairUsingOneTimePassword");
        this._changeState(PairedAppEnums.PairSetupState.PAIRING);

        const command = Commands.format(
            PairedAppEnums.Cmd.REPLACE,
            this._oneTimeLogin.uuid,
            JSON.stringify(this._getDeviceSpecificJson())
        );
        PairSetupCommunicator.send(this._ms, this._oneTimeLogin, command).then(res => {
            //when pairing fails (e.g. with 403) --> it returns an LL-Object with the "Code" prop inside it.
            if (res.hasOwnProperty("LL")) {
                const code = getLxResponseCode(res);
                if (code === 200) {
                    return Q.resolve(getLxReponseValue(res));
                } else {
                    return Q.reject({status: code, statusText: getLxResponseValue(res)});
                }
            } else {
                return Q.resolve(res);
            }
        }).then(res => {
            Debug.PairedApp && console.log(this.name, "_pairUsingOneTimePassword --> success: ", res);
            this._handlePairingResult(res);
            this._changeState(PairedAppEnums.PairSetupState.PAIRED_DONE, res);
        }, err => {
            const {status, statusText} = err?.exception ?? { status: -1 };
            if (status === PairedAppEnums.PairingFileError.INVALID) {
                console.error(this.name, "_pairUsingOneTimePassword --> unauthorized!");
            } else if (status === PairedAppEnums.PairingFileError.ALREADY_USED) {
                console.error(this.name, "_pairUsingOneTimePassword --> gone, setup code already used!");
            } else {
                console.error(this.name, "_pairUsingOneTimePassword --> failed: " + status + ", " + statusText);
            }
            this._changeState(PairedAppEnums.PairSetupState.PAIRING_FAILED,
                {status, statusText, infoSrc: this._pairSetupSrc}
            );
        })
    }


    // endregion

    // region device handling

    PairingSetupExt.prototype._stillWaitingForDevices = function _stillWaitingForDevices() {
        return this._ensureState(PairedAppEnums.PairSetupState.LOADING_DEVICES);
    }

    PairingSetupExt.prototype._loadDeviceSelectionData = function _loadDeviceSelectionData() {
        Debug.PairedApp && console.log(this.name, "_loadDeviceSelectionData");
        return Q.all([this._requestDeviceList(), this._loadRooms(), this._loadUserGroups()]).then(() => {
            this._deviceList.forEach(dev => {
               dev.roomObj = this._roomMap[dev.room] || null;
            });
            if (!this._stillWaitingForDevices()) {return;}
            Debug.PairedApp && console.log(this.name, "_loadDeviceSelectionData - done, " + this._deviceList.length + " devices");
            this._changeState(PairedAppEnums.PairSetupState.SELECT_DEVICE, this._deviceList);
        }, (err) => {
            if (!this._stillWaitingForDevices()) {return;}
            console.error(this.name, "_loadDeviceSelectionData - failed!", err);
            this._changeState(PairedAppEnums.PairSetupState.SELECT_DEVICE, null);
        })
    }

    PairingSetupExt.prototype._requestDeviceList = function _requestDeviceList() {
        Debug.PairedApp && console.log(this.name, "_requestDeviceList");

        return this._pairSetupComm.send(PairedAppEnums.Cmd.LIST_DEVICES).then(res => {
            this._deviceList = res || [];
            this._deviceList.forEach(device => {
                // if the device comes with an id property, it's considered as already paired.
                device.isPaired = device.hasOwnProperty("deviceId") && !!nullEmptyString(device.deviceId);
            });
            Debug.PairedApp && console.log(this.name, "_requestDeviceList > success: ", this._deviceList);
            /**
             * [{"uuid": "1bef970a-00a6-cb77-ffffed57184a04d2", "name": "TestTab",
             *  "room": "15ea0aa5-0127-3bc5-ffffed57184a04d2", inst": "",
             *   "user": "1bef970a-00a6-cb87-ffffed57184a04d2"}]
             */
        }, (err) => {
            console.error(this.name, "_requestDeviceList > failed: ", err);
            this._deviceList = [];
        })
    }

    PairingSetupExt.prototype._loadRooms = function _loadRooms() {
        Debug.PairedApp && console.log(this.name, "_loadRooms");
        return this._pairSetupComm.send("jdev/sps/getroomlist").then(res => {
            this._roomMap = {};
            Array.isArray(res) && res.forEach(room => {
                this._roomMap[room.uuid] = room;
            });
            Debug.PairedApp && console.log(this.name, "_loadRooms > success: " + Object.keys(this._roomMap).length + " rooms");
        }, (err) => {
            console.error(this.name, "_loadRooms > failed to load rooms: " + JSON.stringify(err), err);
            this._roomMap = {}; // could not load rooms :/
        });
    }


    PairingSetupExt.prototype._loadUserGroups = function _loadUserGroups() {
        Debug.PairedApp && console.log(this.name, "_loadUserGroups");
        return this._pairSetupComm.send(Commands.USER_MANAGEMENT.GETGROUPLIST).then(res => {
            this._userGroupMap = {}
            const groupArr = getLxResponseValue(res);
            groupArr.forEach(grp => {
                this._userGroupMap[grp.uuid] = grp;
            })
            Debug.PairedApp && console.log(this.name, "_loadUserGroups > success: ", this._userGroupMap );
        }, (err) => {
            console.error(this.name, "_loadUserGroups > failed to load usergroups: " + JSON.stringify(err), err);
            this._userGroupMap = {}; // could not load rooms :/
        });
    }

    /**
     * Ensures the device-specific attributes are properly set in the config provided.
     * @param inputCfg
     * @param [addUserDefaults] if true, it will e.g. specify a default name.
     * @private
     */
    PairingSetupExt.prototype._ensureDeviceConfig = function _ensureDeviceConfig(inputCfg = {}, addUserDefaults = false) {
        const resultDeviceConfig = {...inputCfg, ...this._getDeviceSpecificJson() };

        if (addUserDefaults) {
            if (!resultDeviceConfig.hasOwnProperty("name")) {
                resultDeviceConfig.name = "No-Name-Provided";
            }
        }

        return resultDeviceConfig;
    }


    PairingSetupExt.prototype._getDeviceSpecificJson = function _getDeviceSpecificJson() {
        const deviceJson = {};
        const platformObj = PlatformComponent.getPlatformInfoObj();

        deviceJson.model = platformObj.model;
        deviceJson.platform = platformObj.platform;
        deviceJson.deviceId = this._pairSetupComm.deviceUuid;
        deviceJson.type = "application";

        return deviceJson;
    }

    PairingSetupExt.prototype._handlePairingResult = function _handlePairingResult(pairResult) {
        Debug.PairedApp && console.log(this.name, "_handlePairingResult: ", pairResult);
        /**
         * {
         *     "username": "1bef970a-00a6-cb87-ffffed57184a04d2",
         *     "password": "0D4DB931F3F9EF3D228575419DD509C3EF5B11D82195B3BB31938C6641B12E7B",
         *     "uuid": "1bef970a-00a6-cb77-ffffed57184a04d2",
         *     "name": "TestTab",
         *     "room": "00000000-0000-0000-0000000000000000",
         *     "inst": "",
         *     "user": "1bef970a-00a6-cb87-ffffed57184a04d2"
         * }
         */
        this._pairSetupComm.getPublicKey().then((pk) => {

            const pairingFile = PairingFile.create(
                pairResult.uuid,
                this._reachabilityResult.serialNo,
                pairResult.username,
                pairResult.password,
                pk,
                this._ms.localUrl,
                this._ms.host,
                this._ms.remoteUrl
            );
            if (pairingFile) {
                Debug.PairedApp && console.log(this.name, "_handlePairingResult --> file= ", pairingFile ? pairingFile.JSON : null);
                this.comp.onPairingEstablished(pairingFile, pairResult);
            } else {
                console.error(this.name, "_handlePairingResult, missing arguments!");
            }
        });
    }

    // endregion device handling

    return PairingSetupExt;
} ]);

