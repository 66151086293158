'use strict';

import globalStyles from "GlobalStyles";

ActiveMSComp.factory('ExpertModeExt', function () {
    // internal variables
    let weakThis,
        activeMsComp,
        hasChangesForObjectSettings = false,
        // variable to cache whether we had changes while editing an object (control or group)
        currentExpertModePermission = null,
        devSpecificSettings;

    /**
     * c-tor for Expert Mode Ext
     * @param comp reference to the ActiveMSComponent
     * @constructor
     */

    function ExpertModeExt(comp) {
        weakThis = this
        activeMsComp = comp;
        this.name = "ExpertModeExt";
    }

    /**
     * @returns {boolean} if the expert mode is enabled
     */


    ExpertModeExt.prototype.isExpertModeEnabled = function isExpertModeEnabled() {
        return Feature.EXPERT_MODE && SandboxComponent.checkPermission(MsPermission.EXPERT_MODE)
            /* &&
            PersistenceComponent.expertModeEnabled()*/
            ;
    };
    /**
     * @returns {boolean} if the expert mode light is enabled
     */


    ExpertModeExt.prototype.isExpertModeLightEnabled = function isExpertModeLightEnabled() {
        return Feature.EXPERT_MODE_LIGHT && SandboxComponent.checkPermission(MsPermission.EXPERT_MODE_LIGHT);
    };
    /**
     * loads the settings config for the objectUuid (either a control or a group!)
     * @param objectUuid
     * @param permission
     */


    ExpertModeExt.prototype.getSettingsConfigForObject = function getSettingsConfigForObject(objectUuid, permission) {
        currentExpertModePermission = permission;
        var isExpertMode = currentExpertModePermission === MsPermission.EXPERT_MODE; // Only "invalidate" the settings in the full ExpertMode
        // The settings should always be sent to the Miniserver

        if (isExpertMode) {
            devSpecificSettings = false;
        }

        var cmdToUse = isExpertMode ? Commands.ControlSettings.LOAD : Commands.ControlSettingsLight.LOAD,
            cmd = Commands.format(cmdToUse, objectUuid),
            promise = SandboxComponent.sendWithPermission(cmd, currentExpertModePermission, true).then(function (res) {
                hasChangesForObjectSettings = false;
                return getLxResponseValue(res);
            }, function (res) {
                var message;

                if (res) {
                    var code = getLxResponseCode(res);

                    if (code === ControlSettingResponseCode.ALREADY_EDITING) {
                        message = _('expert-mode.already-editing');
                    } else if (code === ControlSettingResponseCode.NOT_POSSIBLE) {
                        message = _('expert-mode.editing-not-possible');
                    } else {
                        // return the error message to show the user what went wrong
                        message = res.message;
                    }
                }

                throw new Error(message);
            });
        var waitingText = currentExpertModePermission === MsPermission.EXPERT_MODE ? _('expert-mode.activate') : null;
        NavigationComp.showWaitingFor(promise, waitingText);
        return promise;
    };


    /**
     * Used to conveniently create a room
     * @param roomName      the name of the room to create
     */
    ExpertModeExt.prototype.createRoom = function createRoom(roomName) {
        if (!(SandboxComponent.checkPermission(MsPermission.EXPERT_MODE) && Feature.CREATE_ROOMS)) {
            return Q.reject("Not allowed!");
        }
        const cmd = Commands.format(Commands.DEVICE_MANAGEMENT.CREATE_ROOM, roomName);
        return SandboxComponent.sendWithPermission(cmd, MsPermission.EXPERT_MODE).then((result) => {
            try {
                return getLxResponseValue(result).uuid;
            } catch (ex) {
                console.error(this.name, "Failed to retrieve room uuid from MS response: " + JSON.stringify(result));
                return Q.reject(this.name + ": Failed to retrieve room uuid! " + result);
            }
        }, (err) => {
            console.error(this.name, "Failed to create room! MS response: " + JSON.stringify(err));
            return Q.reject(err);
        });
    }

    /**
     * Used to get specifically one setting field from an object.
     * @param objectUuid    the uuid of the object whoms setting field is of interest
     * @param fieldId       the ID of the field we need to know.
     * @param permission    if it should be loaded via Expert-Mode or Expert-Mode-Light
     * @return {*}
     */


    ExpertModeExt.prototype.getSettingsFieldForObject = function getSettingsFieldForObject(objectUuid, fieldId, permission) {
        var settings = this.getSettingsConfigForObject(objectUuid, permission);
        return settings.then(function (settings) {
            return this.getSettingsFieldFromObject(settings, fieldId);
        }.bind(this));
    };
    /**
     * Extracts a field id info from a settings-object of a room or a control. Regardless of the group (allgemein, parameter, ...)
     * @param objectSettings        the object to retrieve the field from
     * @param fieldId               the id of the field of interest
     * @return {*}
     */


    ExpertModeExt.prototype.getSettingsFieldFromObject = function getSettingsFieldFromObject(objectSettings, fieldId) {
        var result = null; // iterate over groups (allgemein, parameter, ..)

        Object.keys(objectSettings).some(function (groupKey) {
            // iterate over groups field objects
            objectSettings[groupKey].some(function (fieldObj) {
                // check if the desired field was found.
                if (fieldObj["id"] === fieldId) {
                    result = fieldObj;
                }

                return result !== null;
            });
            return result != null;
        });
        return result;
    };
    /**
     * prepares and returns a settings config for ExpertMode Light
     * all infos are available from the LoxAPP3.json, so no API call is needed at this point
     * @param object control or group
     * @returns Promise
     */


    ExpertModeExt.prototype.getLightSettingsConfigForObject = function getLightSettingsConfigForObject(object) {
        hasChangesForObjectSettings = false;
        devSpecificSettings = false;
        var isControl = !!object.uuidAction,
            filteredSettingsCfg;

        if (isControl) {
            filteredSettingsCfg = prepareLightSettingsConfigForControl(object);
        } else {
            filteredSettingsCfg = prepareLightSettingsConfigForGroup(object);
        }

        return Q.fcall(function () {
            return filteredSettingsCfg;
        });
    };
    /**
     * verifies a value after changed from user
     * sends the value to the Miniserver and checks if the Miniserver accepts the new value or if the value was adopted/validated..
     * @param objectUuid
     * @param setting
     * @param value
     * @returns {*}
     */


    ExpertModeExt.prototype.verifySettingForObject = function verifySettingForObject(objectUuid, setting, value) {
        if (_isDeviceSpecificSetting(setting)) {
            // e.g. rating and isFavorite attributes can be modified per device.
            return _verifyDeviceSpecificSetting(setting, value);
        }

        if (!currentExpertModePermission) {
            // if eg. editing rating or favorite setting, we need to start the expert mode before validate works!
            var args = arguments;
            return this.getSettingsConfigForObject(objectUuid, MsPermission.EXPERT_MODE_LIGHT).then(function () {
                return this.verifySettingForObject.apply(this, args);
            }.bind(this));
        }

        var cmdToUse = currentExpertModePermission === MsPermission.EXPERT_MODE ? Commands.ControlSettings.VERIFY : Commands.ControlSettingsLight.VERIFY,
            cmd = Commands.format(cmdToUse, objectUuid, setting.id, encodeURIComponent(value)),
            promise;
        VendorHub.Usage.expertMode(FeatureUsage.ExpertMode.VERIFY);
        promise = SandboxComponent.sendWithPermission(cmd, currentExpertModePermission).then(function (res) {
            hasChangesForObjectSettings = true;
            return sanitizeValue(setting, getLxResponseValue(res, true));
        }.bind(this), function (err) {
            handleVerificationError(setting, err);
        }.bind(this));
        NavigationComp.showWaitingFor(promise, _('expert-mode.validating'));
        return promise;
    };
    /**
     * saves (or cancels if no changes) expert mode (light)
     * @param objectUuid
     * @param objectName
     */


    ExpertModeExt.prototype.saveSettingsForObject = function saveSettingsForObject(objectUuid, objectName) {
        var saveCmdToUse = currentExpertModePermission === MsPermission.EXPERT_MODE ? Commands.ControlSettings.SAVE : Commands.ControlSettingsLight.SAVE,
            cancelToUse = currentExpertModePermission === MsPermission.EXPERT_MODE ? Commands.ControlSettings.CANCEL : Commands.ControlSettingsLight.CANCEL,
            cmdFormat = hasChangesForObjectSettings ? saveCmdToUse : cancelToUse,
            cmd = Commands.format(cmdFormat, objectUuid),
            permission = currentExpertModePermission;
        VendorHub.Usage.expertMode(FeatureUsage.ExpertMode.SAVE);
        currentExpertModePermission = null; // reset permission

        if (devSpecificSettings) {
            // no need to look for changes, as this operation returns instantly.
            // parts of the light mode settings (rating and isFavorit) might have been adopted device specific.
            _saveDeviceSpecificSettings(objectUuid);
        }

        return SandboxComponent.sendWithPermission(cmd, permission).then(function (res) {
            // okay fine, now we may reload!
            if (hasChangesForObjectSettings && getLxResponseCode(res) === ControlSettingResponseCode.UPDATE) {// 205 means, the Miniserver sends an update package (to modifications uuid)
                // this is handled now over status updates, so nothing to do here.
            }
        }, function (res) {
            if (!hasChangesForObjectSettings) {
                return;
            }

            var code = getLxResponseCode(res),
                message;

            if (code === ControlSettingResponseCode.TIMEOUT) {
                // we are not in editing mode anymore (eg. timeout!)
                message = _('timeout');
            } else {
                message = _('error.occured') + " " + _('expert-mode.error', {
                    controlName: objectName
                });
            }

            NavigationComp.showPopup({
                title: _('error'),
                message: message,
                icon: Icon.CAUTION,
                color: globalStyles.colors.red,
                buttonOk: true
            });
            throw code;
        });
    };

    ExpertModeExt.prototype.sendRefresh = function sendRefresh(objectUuid) {
        var cmd = Commands.format(Commands.ControlSettings.REFRESH, objectUuid);
        return SandboxComponent.sendWithPermission(cmd, currentExpertModePermission);
    }; // Private methods

    /**
     * prepares a settingsConfig for a control with all known stuff, no need to load something from Miniserver at this point
     * @param control
     * @returns {{}}
     */


    var prepareLightSettingsConfigForControl = function prepareLightSettingsConfigForControl(control) {
        var customGroupTitles = ActiveMSComponent.getStructureManager().getCustomGroupTitles(),
            filteredSettingsCfg = {},
            section0 = [],
            section1 = [],
            section2 = [],
            section3 = [];
        section0.push({
            "id": ControlSettingID.DESCRIPTION_VISU,
            "name": _('description'),
            "format": ControlSettingType.STRING,
            "value": control.name
        }); // if device specific favorites/ratings are activated, a different handling is needed

        if (ActiveMSComponent.getDeviceFavoritesActive()) {
            section1 = _getDeviceFavoritesSection(control, false);
        } else {
            section1 = _getRegularFavoritesSection(control, false);
        }

        section2.push({
            "id": ControlSettingID.ROOM,
            "name": customGroupTitles[GroupTypes.ROOM],
            "format": ControlSettingType.RADIO,
            "value": control.room,
            "validValues": [{
                "id": control.room,
                "name": control.getRoom() && control.getRoom().name
            }]
        });
        section2.push({
            "id": ControlSettingID.CATEGORY,
            "name": customGroupTitles[GroupTypes.CATEGORY],
            "value": control.cat,
            "format": ControlSettingType.RADIO,
            "validValues": [{
                "id": control.cat,
                "name": control.getCategory() && control.getCategory().name
            }]
        });

        if (control.hasOwnProperty("defaultIcon")) {
            // not all Controls supports defaultIcons!
            var icon = control.defaultIcon || control.getIcon();
            section3.push({
                "id": ControlSettingID.DEFAULT_ICON,
                "name": _('icon'),
                "value": icon,
                "format": ControlSettingType.RADIO,
                "validValues": [{
                    "id": icon,
                    "name": ""
                }]
            });
        }

        if (section0.length > 0) {
            filteredSettingsCfg["0"] = section0;
        }

        filteredSettingsCfg["1"] = section1;
        filteredSettingsCfg["2"] = section2;

        if (section3.length > 0) {
            filteredSettingsCfg["3"] = section3;
        }

        return filteredSettingsCfg;
    };
    /**
     * prepares a settingsConfig for a group with all known stuff, no need to load something from Miniserver at this point
     * @param group
     * @returns {{}}
     */


    var prepareLightSettingsConfigForGroup = function prepareLightSettingsConfigForGroup(group) {
        var filteredSettingsCfg = {},
            section0 = [],
            section1;
        section0.push({
            "id": ControlSettingID.DESCRIPTION,
            "name": _('description'),
            "format": ControlSettingType.STRING,
            "value": group.name
        });
        section0.push({
            "id": ControlSettingID.IMAGE,
            "name": _('icon'),
            "value": group.image,
            "format": ControlSettingType.RADIO,
            "validValues": [{
                "id": group.image,
                "name": ""
            }]
        });

        if (group.groupType === GroupTypes.CATEGORY) {
            section0.push({
                "id": ControlSettingID.CATEGORY_COLOR,
                "name": _('background-color'),
                "value": group.color,
                "format": ControlSettingType.RADIO,
                "validValues": [{
                    "id": group.color,
                    "name": ""
                }]
            });
        } // if device specific favorites/ratings are activated, a different handling is needed


        if (ActiveMSComponent.getDeviceFavoritesActive()) {
            section1 = _getDeviceFavoritesSection(group, true);
        } else {
            section1 = _getRegularFavoritesSection(group, true);
        }

        filteredSettingsCfg["0"] = section0;
        filteredSettingsCfg["1"] = section1;
        return filteredSettingsCfg;
    };
    /**
     * Helper fn that prepares the section for adopting the rating and the isFavorite flag when device specific settings
     * are disabled.
     * @param obj       the room, category or control to create these entries for
     * @param isGroup   true, if the obj is either a room or a category, impacts the IDs used.
     * @return {Array}  the section.
     * @private
     */


    var _getRegularFavoritesSection = function _getRegularFavoritesSection(obj, isGroup) {
        var section = [];
        section.push(_getRatingSettingObj(obj.defaultRating, isGroup));
        section.push(_getIsFavoriteSettingObj(obj.isFavorite, isGroup));
        return section;
    };
    /**
     * Helper function to create a rating settings object. Used to adopt the rating of a room/category or control.
     * @param rating    the current rating of the object.
     * @param isGroup   true if its a room or a category
     * @return {{id: number, name, format: number, minValue: number, maxValue: number, value: *, stepValue: number}}
     * @private
     */


    var _getRatingSettingObj = function _getRatingSettingObj(rating, isGroup) {
        return {
            "id": isGroup ? ControlSettingID.RATING_GROUPS : ControlSettingID.RATING_CONTROLS,
            "name": _('rating-popup.title'),
            "format": ControlSettingType.NUMBER,
            "minValue": 0,
            "maxValue": 10,
            "value": rating,
            "stepValue": 1
        };
    };
    /**
     * used to create a settings object for adopting the isFavorite flag of a room/category or control
     * @param isFavorite
     * @param isGroup
     * @return {{id: number, name, format: number, value: *}}
     * @private
     */


    var _getIsFavoriteSettingObj = function _getIsFavoriteSettingObj(isFavorite, isGroup) {
        return {
            "id": isGroup ? ControlSettingID.FAVORITE_GROUPS : ControlSettingID.FAVORITE_CONTROLS,
            "name": _('favorites.show-as'),
            "format": ControlSettingType.BOOLEAN,
            "value": isFavorite
        };
    };

    var sanitizeValue = function sanitizeValue(setting, value) {
        try {
            // use try catch to prevent crashes if an unexpected value is returned.
            var format = setting.format;

            if (format === ControlSettingType.NUMBER) {
                return parseFloat(value);
            } else if (format === ControlSettingType.STRING) {// value is string..
            } else if (format === ControlSettingType.BOOLEAN) {
                return JSON.parse(value);
            }

            return value;
        } catch (e) {
            console.error(e.stack);
            return "";
        }
    };

    var handleVerificationError = function handleVerificationError(setting, error) {
        if (!error) {
            throw error;
        }

        var code = getLxResponseCode(error),
            title = _('warning'),
            message = _('expert-mode.value.error'),
            color = globalStyles.colors.red;

        if (code === ControlSettingResponseCode.VALUE_VALIDATED) {
            hasChangesForObjectSettings = true; // show popup that value was validated..

            var verifiedValue = sanitizeValue(setting, getLxResponseValue(error));
            title = _('caution');
            message = _('expert-mode.value.updated', {
                value: setting.userfriendlyValue(verifiedValue)
            });
            color = globalStyles.colors.orange;
            return verifiedValue;
        } else if (code === ControlSettingResponseCode.TIMEOUT) {
            // we are no longer in edit mode (eg timeout)!
            title = _('error');
            message = _('timeout');
        }

        NavigationComp.showPopup({
            title: title,
            message: message,
            icon: Icon.CAUTION,
            color: color,
            buttonOk: true
        });
        throw code;
    }; // ---------------------------------------------------------------------
    //             ------- Device Specific Methods ---------
    // ---------------------------------------------------------------------

    /**
     * Helper fn that prepares the section for adopting the rating and the isFavorite flag when device specific settings
     * are used.
     * @param obj       the room, category or control to create these entries for
     * @param isGroup   true, if the obj is either a room or a category, impacts the IDs used.
     * @return {Array}  the section.
     * @private
     */


    var _getDeviceFavoritesSection = function _getDeviceFavoritesSection(obj, isGroup) {
        var section = [];
        devSpecificSettings = ActiveMSComponent.getDeviceFavoriteSettingsFor(isGroup ? obj.uuid : obj.uuidAction);
        section.push(_getRatingSettingObj(devSpecificSettings.rating, isGroup));
        section.push(_getIsFavoriteSettingObj(devSpecificSettings.isFavorite, isGroup));
        section.headerTitle = _('device-favorites.title');
        section.footerTitle = _('device-favorites.description.active.a') + " " + _('device-favorites.description.active.a.ignored');
        return section;
    };
    /**
     * Returns true if the setting provided is device specific and device specific settings are enabled.
     * @param setting
     * @return {boolean}
     * @private
     */


    var _isDeviceSpecificSetting = function _isDeviceSpecificSetting(setting) {
        var result = false;

        switch (setting.id) {
            case ControlSettingID.RATING_CONTROLS:
            case ControlSettingID.RATING_GROUPS:
            case ControlSettingID.FAVORITE_CONTROLS:
            case ControlSettingID.FAVORITE_GROUPS:
                // also check if specficSettings have been loaded yet, e.g. the expert mode is still capable of editing a rating while device specific settings are in use.
                result = ActiveMSComponent.getDeviceFavoritesActive() && devSpecificSettings;
                break;
        }

        return result;
    };
    /**
     * Will verify the modified setting. Rejects with a corrected value when the input wasn't correct.
     * @param setting
     * @param value
     * @private
     */


    var _verifyDeviceSpecificSetting = function _verifyDeviceSpecificSetting(setting, value) {
        var result = true;

        switch (setting.id) {
            case ControlSettingID.RATING_CONTROLS:
            case ControlSettingID.RATING_GROUPS:
                result = value >= 0 && value <= 10;

                if (result) {
                    devSpecificSettings.rating = value;
                } else {
                    value = devSpecificSettings.rating;
                }

                break;

            case ControlSettingID.FAVORITE_CONTROLS:
            case ControlSettingID.FAVORITE_GROUPS:
                result = value === true || value === false;

                if (result) {
                    devSpecificSettings.isFavorite = value;
                } else {
                    value = devSpecificSettings.isFavorite;
                }

                break;
        }

        return prmsfy(result, value, value);
    };
    /**
     * Stores the device specific settings for the current object.
     * @param uuid
     * @private
     */


    var _saveDeviceSpecificSettings = function _saveDeviceSpecificSettings(uuid) {
        ActiveMSComponent.setDeviceFavoriteSettingsFor(uuid, devSpecificSettings);
    };

    return ExpertModeExt;
});
