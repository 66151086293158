'use strict';

define("IrrigationControlStateContainer", ["ControlStateContainer", "IrrigationControlEnums"], function (ControlStateContainer, CtrlEnums) {
    return class IrrigationControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.currentZoneId = newVals[this.control.states.currentZone];

            try {
                this.states.zones = JSON.parse(newVals[this.control.states.zones].text);
            } catch (ex) {
                console.error(this.name, "Failed to parse zones-array from " + newVals[this.control.states.zones]);
                this.states.zones = [];
            }

            this.states.active = this.states.currentZoneId !== CtrlEnums.ZONE_ID_OFF;
            this.states.allZonesActive = this.states.currentZoneId === CtrlEnums.ZONE_ID_ALL;
            this.states.hasZones = this.states.zones.length > 0; // retrieve the current zone.

            this.states.currentZone = null;
            this.states.zones.forEach(function (zone) {
                if (zone.id === this.states.currentZoneId) {
                    this.states.currentZone = zone;
                }
            }.bind(this)); // retrieve current zone attributes

            if (this.states.currentZone) {
                this.states.currentZoneName = this.states.currentZone.name;
                this.states.currentZoneDuration = this.states.currentZone.duration;
            } else {
                this.states.currentZoneName = _("controls.irrigation.default-zone-name", {
                    id: this.states.currentZoneId
                });
                this.states.currentZoneDuration = 0;
            }
        }

        getStateText(short) {
            var text;

            if (!this.states.hasZones) {
                text = _("controls.irrigation.no-zones");
            } else if (this.states.allZonesActive) {
                text = _("controls.irrigation.all-zones-active");
            } else if (this.states.active) {
                text = _("controls.irrigation.active-zone", {
                    zone: this.states.currentZoneName
                });
            } else {
                text = _("controls.irrigation.off");
            }

            return text;
        }

        getStateColor() {
            var color = null;

            if (!this.states.hasZones) {
                color = window.Styles.colors.red;
            } else if (this.states.active) {
                color = window.Styles.colors.stateActive;
            }

            return color;
        }

    };
});
