"use strict";

define("AalSmartAlarmControlStateContainer", ["ControlStateContainer", "AalSmartAlarmControlEnums"], function (ControlStateContainer, AalSmartAlarmControlEnums) {
    return class AalSmartAlarmControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.alarmLevel = newVals[this.control.states.alarmLevel];
            this.states.alarmCause = newVals[this.control.states.alarmCause];
            this.states.alarmTime = newVals[this.control.states.alarmTime];
            this.states.isLeaveActive = newVals[this.control.states.isLeaveActive];
            this.states.isLocked = newVals[this.control.states.isLocked];
            this.states.disableEndTime = newVals[this.control.states.disableEndTime]; //disabledEndTime appears to be huge in some cases (e.g. at Tobis Home while leave is active its 4294963696

            if (this.states.disableEndTime >= 4294963696) {
                //TODO-woessto: remove once BG-T1529 is clearified.
                this.states.disableEndTime = 0;
            }

            this.states.alarmActive = this.states.alarmLevel > AalSmartAlarmControlEnums.ALARM_LEVEL.NO_ALARM && !this.states.isLocked;
            this.states.disabledTemp = this.states.disableEndTime > 0;

            if (this.isFirstUpdate() && this.states.alarmActive) {
                SandboxComponent.isInAppNotificationSettingTurnedOn(this.control.type).done(function () {
                    NavigationComp.showControlAlert(this.control.uuidAction);
                }.bind(this));
            }
        }

        getStateTextShort() {
            var sText = null;

            if (this.states.isLocked) {
                sText = _("controls.aal-smart-alarm.locked");
            } else if (this.states.alarmActive) {
                sText = _("alarm.level") + " " + this.states.alarmLevel;
            } else if (this.states.isLeaveActive || this.states.disabledTemp) {
                sText = _("inactive");
            } else {
                sText = _("controls.alarm.armed-short").capitalize();
            }

            return sText;
        }

        getStateText() {
            var text = null;

            if (this.states.isLocked) {
                text = _("controls.aal-smart-alarm.locked");
            } else if (this.states.alarmActive) {
                text = _("alarm.level") + " " + dec2rom(this.states.alarmLevel);
            } else if (this.states.isLeaveActive || this.states.disabledTemp) {
                text = _("inactive");
            } else {
                text = _("controls.aal-smart-alarm.level.no-alarm");
            }

            return text;
        }

        getStateColor() {
            var stateColor = null;

            if (this.states.alarmActive || this.states.isLeaveActive || this.states.isLocked || this.states.disabledTemp) {
                stateColor = null;
            } else {
                stateColor = window.Styles.colors.stateActive;
            }

            return stateColor;
        }

        getStateTextColor() {
            var color = null; // The cell will have a red tint if the alarm is active, so no need to also tint the text

            if (this.states.isLocked || this.states.disabledTemp) {
                color = window.Styles.colors.orange;
            } else if (this.states.alarmActive) {
                color = null;
            } else if (this.states.isLeaveActive) {
                color = window.Styles.colors.blue;
            } else {
                color = window.Styles.colors.stateActive;
            }

            return color;
        }

        getStateTintColor() {
            var stateTintColor = null; // Only the alarm state should have a tint

            if (this.states.alarmActive) {
                stateTintColor = window.Styles.colors.red;
            }

            return stateTintColor;
        }

        getStateIcon() {
            return Icon.AaLSmartAlarm.CROSS;
        }

        getStateIconSmall() {
            var stateIconSmall = null;

            if (this.states.isLocked) {
                stateIconSmall = {
                    iconSrc: Icon.LOCK,
                    color: window.Styles.colors.orange
                };
            } else if (this.states.disabledTemp) {
                stateIconSmall = {
                    iconSrc: Icon.Daytimer.TIMER,
                    color: window.Styles.colors.orange
                };
            }

            return stateIconSmall;
        }

        getStateInfo() {
            var stateInfo = null;

            if (this.states.isLocked) {
                stateInfo = {
                    message: _("controls.aal-smart-alarm.locked.info.text")
                };
            } else if (this.states.isLeaveActive && this.states.alarmLevel === AalSmartAlarmControlEnums.ALARM_LEVEL.NO_ALARM) {
                stateInfo = {
                    title: _("controls.aal-smart-alarm.inactive.info.title"),
                    message: _("controls.aal-smart-alarm.inactive.info.text")
                };
            } else if (this.states.disabledTemp) {
                stateInfo = {
                    message: _("controls.alarm.deactivated-until", {
                        endDateTime: this._getEndTimeString(this.states.disableEndTime)
                    })
                };
            }

            return stateInfo;
        }

        /**
         * Converts seconds from 1.1.2009 to a short date time string
         * @param endDateInSeconds
         * @returns {string}
         * @private
         */
        _getEndTimeString(endDateInSeconds) {
            return LxDate.formatFutureDateDynamic(new LxDate(endDateInSeconds, true), true);
        }

    };
});
