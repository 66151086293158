'use strict';

import PairedAppEnums from "../pairedAppEnums";
import PairedAppProperties from "../helper/PairedAppProperties";

ActiveMSComp.factory('PairingPropsExt', function () {
    let weakThis;
    const PAIRING_PROPS_FILE = "PairedAppProps.json";

    function PairingPropsExt(comp) {
        this.name = "PairingPropsExt";
        this.comp = comp;
        weakThis = this;

        this._stateUnregFn = null;
        this._properties = null;

        this._msReady = false;
        this._noCachedFile = false;
        this._isPaired = false;

        this.comp.on(CCEvent.PairedAppInfoReady, (ev, {isPaired}) => {
            Debug.PairedApp && console.log(weakThis.name, "PairedAppInfoReady");
            this._isPaired = isPaired;
            isPaired && this._initialize();
        });

        this.comp.on(CCEvent.StartMSSession, (ev, ms) => {
            Debug.PairedApp && console.log(weakThis.name, "StartMSSession: isPaired=" + this._isPaired);
            if (!this._isPaired) { return; }
            this._msReady = true;
            this._properties && this._distributeProperties();
        });

        this.comp.on(CCEvent.UnpairedApp, (ev, ms) => {
            Debug.PairedApp && console.log(weakThis.name, "UnpairedApp: isPaired=" + this._isPaired);
            if (!this._isPaired) { return; }
            this._msReady = false;
            this._noCachedFile = true;
            this._properties = null;
            this._deletePropsFromCache();
            this._isPaired = false;
        });

        this.comp.on(CCEvent.AppInitInfoReady, (ev) => {
            Debug.PairedApp && console.log(weakThis.name, "AppInitInfoReady: isPaired=" + this._isPaired);
            if (!this._isPaired) { return; }
            if (this._noCachedFile) {
                Debug.PairedApp && console.log(weakThis.name, "AppInitInfoReady --> nothing in chache!");
                this._downloadPropsFile().then(loadedProperties => {
                    this._onPropsFileReady(loadedProperties);
                    this._savePropsToCache(loadedProperties);
                });
            }
        })

        this.comp.on(CCEvent.StateContainersCreated, (ev) => {
            Debug.PairedApp && console.log(weakThis.name, "StateContainersCreated: isPaired=" + this._isPaired);
            if (!this._isPaired) { return; }

            var globalStates = ActiveMSComponent.getStructureManager().getGlobalStateUUIDs();

            this._stateUnregFn && this._stateUnregFn();
            this._stateUnregFn = null;

            if (globalStates.propsVersion) {
                this._stateUnregFn = SandboxComponent.registerFunctionForStateChangesForUUID(GLOBAL_UUID,
                    this._globalStatesChanged.bind(this));
            }
        });
    }

    PairingPropsExt.prototype.getCurrent = function getCurrent() {
        return this._properties;
    }

    PairingPropsExt.prototype._initialize = function _initialize() {
        Debug.PairedApp && console.log(weakThis.name, "_initialize");
        this._cachePromise = this._loadPropsFromCache().then(cachedProperties => {
            this._noCachedFile = false;
            this._onPropsFileReady(cachedProperties);
        }, (err) => {
            this._noCachedFile = true;
        });
    }

    PairingPropsExt.prototype._globalStatesChanged = function _globalStatesChanged({propsVersion}) {
        if (this._properties && this._properties.version === propsVersion) {
            Debug.PairedApp && console.log(weakThis.name, "_globalStatesChanged: version = " + propsVersion + " - unchanged!");
            // nothing to do
        } else {
            Debug.PairedApp && console.log(weakThis.name, "_globalStatesChanged: version = " + propsVersion);
            this._downloadPropsFile().then(loadedProperties => {
                this._onPropsFileReady(loadedProperties);
                this._savePropsToCache(loadedProperties);
            })
        }

    };

    PairingPropsExt.prototype._onPropsFileReady = function _onPropsFileReady(propObj) {
        Debug.PairedApp && console.log(weakThis.name, "_onPropsFileReady: msReady=" + !!this._msReady);
        this._properties = propObj;
        this._msReady && this._distributeProperties();
    }

    PairingPropsExt.prototype._loadPropsFromCache = function _loadPropsFromCache() {
        Debug.PairedApp && console.log(weakThis.name, "_loadPropsFromCache");
        return PersistenceComponent.loadFile(PAIRING_PROPS_FILE, DataType.OBJECT).then((res) => {
            Debug.PairedApp && console.log(weakThis.name, "_loadPropsFromCache -> responded: ", res);
            return new PairedAppProperties(res);
        }, (err) => {
            console.error(weakThis.name, "_loadPropsFromCache -> failed: ", err);
            return Q.reject(err);
        });
    }

    PairingPropsExt.prototype._downloadPropsFile = function _downloadPropsFile() {
        Debug.PairedApp && console.log(weakThis.name, "_downloadPropsFile");
        return CommunicationComponent.sendViaHTTP(
            PairedAppEnums.Cmd.PROPS,
            EncryptionType.REQUEST_RESPONSE_VAL,
            true,
            null,
            null,
            {noLLResponse: true}
        ).then((propsJson) => {
            if (typeof propsJson !== "object") {
                console.error(weakThis.name, "_downloadPropsFile - did not return a valid JSON!" + JSON.stringify(propsJson));
            } else {
                Debug.PairedApp && console.log(weakThis.name, "_downloadPropsFile > responded: " + JSON.stringify(propsJson));
                //{ "propsVersion": 468766084, "entryPointLocation": "last-position", ... }
                return new PairedAppProperties(propsJson);
            }
        }, (err) => {
            console.error(weakThis.name, "_downloadPropsFile > failed: ", err);
            return Q.reject(err);
        });
    };

    PairingPropsExt.prototype._savePropsToCache = function _savePropsToCache(properties) {
        const jsonToStore = properties.json;
        Debug.PairedApp && console.log(weakThis.name, "_saveProperties", jsonToStore);
        this._noCachedFile = false;
        PersistenceComponent.saveFile(PAIRING_PROPS_FILE, properties.json, DataType.OBJECT);
    }

    PairingPropsExt.prototype._deletePropsFromCache = function _deletePropsFromCache() {
        Debug.PairedApp && console.log(weakThis.name, "_deletePropsFromCache");
        PersistenceComponent.deleteFile(PAIRING_PROPS_FILE);
    }


    // region Properties Distribution

    PairingPropsExt.prototype._distributeProperties = function _distributeProperties() {
        Debug.PairedApp && console.log(weakThis.name, "_distributeProperties", this._properties, getStackObj());
        weakThis.comp.emit(CCEvent.PairedAppPropertiesChanged, this._properties);
    }


    return PairingPropsExt;
});

