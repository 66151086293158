import TaskQueue from "../../../shared/logic/tasks/TaskQueue";

class ConnectionAwareTaskQueue extends TaskQueue {
    constructor() {
        super(...arguments);
        CompChannel.on(CCEvent.ConnEstablished, () => {
            this.continue();
        })
        CompChannel.on(CCEvent.ConnClosed, () => {
            this.pause()
        })
    }
}

export default ConnectionAwareTaskQueue
