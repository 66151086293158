'use strict';

ActiveMSComp.factory('AlexaAddonExt', function () {
    let weakThis,
        activeMsComp = {},
        alexaAddOn,
        endpoints,
        pollingTimeout,
        getAuthPollingTimeout,
        addonVersion,
        authStatusDef,
        permissionTimeout,
        lifeCycleDef = Q.defer(),
        requiredPermissions = MsPermission.ADMIN | MsPermission.CONFIG | MsPermission.EXPERT_MODE | MsPermission.MANAGE_MS_PLUGINS | MsPermission.USER_MANAGEMENT | MsPermission.CHANGE_PWD;
    lifeCycleDef.resolve();

    function AlexaAddonExt(comp) {
        this.name = "AlexaAddonExt";
        weakThis = this
        activeMsComp = comp;
        document.addEventListener("pause", function () {
            if (!lifeCycleDef || lifeCycleDef.promise.inspect().state !== "pending") {
                lifeCycleDef = Q.defer();
            }
        }.bind(this));
        document.addEventListener("resume", function () {
            lifeCycleDef.resolve();
        }.bind(this));
    }

    AlexaAddonExt.Enums = {
        ADDON_ID: "Alexa",
        ADDON_NAME: "Alexa",
        COMMANDS: {
            SET_AUTH: 'jdev/sps/io/%s/setauth',
            GET_AUTH: 'getauth',
            UNSET_AUTH: 'unsetauth',
            STATUS: 'status',
            GET_ENDPOINTS: 'endpoints',
            UPDATE_ENDPOINTS: 'jdev/sps/io/%s/endpointupdate',
            SETUP_COMPLETE: 'setupcomplete'
        }
    };
    /**
     * Function to check if the HomeKit plugin is already created.
     * @returns {*} the uuid of the HomeKit plugin or undefined if not created
     */

    AlexaAddonExt.prototype.isAlexaAddonCreated = function isAlexaAddonCreated() {
        return ActiveMSComponent.getAddonList().then(function (res) {
            var plugin, apiUserUuid, ownerUserUuid, ownerUserName;
            addonVersion = "unknown";
            res.forEach(function (addon) {
                if (addon.id === AlexaAddonExt.Enums.ADDON_ID) {
                    if (addon.package && addon.package.version) {
                        addonVersion = addon.package.version;
                    }

                    if (addon.controls && addon.controls.length > 0) {
                        apiUserUuid = addon.controls[0].apiUserUuid;
                    }

                    if (addon.controls && addon.controls.length > 0) {
                        ownerUserUuid = addon.controls[0].ownerUserUuid;
                    }

                    if (addon.controls && addon.controls.length > 0) {
                        ownerUserName = addon.controls[0].ownerUser;
                    }

                    if (addon.controls && addon.controls.length > 0) {
                        plugin = addon.controls[0];
                        return;
                    }
                }
            }.bind(this));
            alexaAddOn = plugin;
            Debug.Alexa && console.warn("AlexaAddonsExt isAlexaAddonCreated success --> " + JSON.stringify(plugin));
            return {
                created: !!alexaAddOn,
                version: addonVersion,
                apiUserUuid: apiUserUuid,
                ownerUserUuid: ownerUserUuid,
                ownerUserName: ownerUserName
            };
        }, function (err) {
            Debug.Alexa && console.warn("AlexaAddonsExt isAlexaAddonCreated error --> " + JSON.stringify(err));
            return false;
        }.bind(this));
    };

    AlexaAddonExt.prototype.getAlexaAddonVersion = function getAddonVersion() {
        return addonVersion;
    };
    /**
     * Create the HomeKit Plugin
     * @returns {*}
     */


    AlexaAddonExt.prototype.createAlexaPlugin = function createAlexaPlugin() {
        return ActiveMSComponent.createAddonInstance(AlexaAddonExt.Enums.ADDON_ID, AlexaAddonExt.Enums.ADDON_NAME).then(function (res) {
            alexaAddOn = res;
        }).then(function (err) {//can fail
            // don't reject the promise here
        });
    };

    AlexaAddonExt.prototype.loginAmazonUser = function loginAmazonUser(data) {
        var cmd = Commands.format(AlexaAddonExt.Enums.COMMANDS.SET_AUTH, alexaAddOn.uuid); // stringify the JSON object otherwise request will fail

        return CommunicationComponent.sendViaHTTP(cmd, EncryptionType.NONE, true, HTTP_METHODS.POST, JSON.stringify(data));
    };

    AlexaAddonExt.prototype.getEndpoints = function getEndpoints(forceUpdate) {
        if (endpoints && !forceUpdate) {
            return Q.resolve(endpoints);
        } else {
            Debug.Alexa && console.log('AlexaAddonExt', "LOG!!!! --> getEndpoints");
            return _sendWithPolling(AlexaAddonExt.Enums.COMMANDS.GET_ENDPOINTS, true).then(function (response) {
                //work around for Miniserver issue
                Debug.Alexa && console.log('AlexaAddonExt', "LOG!!!! --> got endpoints --> " + response.length);
                endpoints = response;
                return endpoints;
            }.bind(this), function (err) {
                Debug.Alexa && console.warn("AlexaAddonExt LOG!!!!! error getEndpoints --> " + JSON.stringify(err));
                return Q.reject(err);
            }.bind(this));
        }
    };

    AlexaAddonExt.prototype.startAuthFlow = function startAuthFlow() {
        return _sendWithPolling(AlexaAddonExt.Enums.COMMANDS.GET_AUTH, true).then(function (res) {
            return res;
        }.bind(this), function (err) {
            Debug.Alexa && console.warn("AlexaAddonExt LOG!!!!! error getauth --> " + JSON.stringify(err));
            return Q.reject(err);
        }.bind(this));
    };

    AlexaAddonExt.prototype.getAuthState = function getAuthState() {
        console.warn("AlexaAddonExt", "start status request");

        if (!authStatusDef) {
            authStatusDef = Q.defer();
        }

        Debug.Alexa && console.warn("AlexaAddonExt", "got send");

        _sendWithPolling(AlexaAddonExt.Enums.COMMANDS.STATUS, true).then(function (res) {
            Debug.Alexa && console.warn("AlexaAddonExt", "got status response --> " + JSON.stringify(res));

            if (res.hasAuth) {
                authStatusDef.resolve();
                authStatusDef = null;
            } else if (res.isAuthenticating) {
                getAuthPollingTimeout = setTimeout(function () {
                    Debug.Alexa && console.warn("AlexaAddonExt", "retry after timeout");
                    return getAuthState();
                }, 5 * 1000);
            } else if (!res.hasAuth && !res.isAuthenticating) {
                authStatusDef.reject();
            }
        });

        return authStatusDef.promise;
    };

    AlexaAddonExt.prototype.getUserMail = function getUserMail() {
        return _sendWithPolling(AlexaAddonExt.Enums.COMMANDS.STATUS).then(function (res) {
            return res.profile.email;
        }, function (err) {
            Debug.Alexa && console.warn("AlexaAddonExt LOG!!!!! error status email --> " + JSON.stringify(err));
        });
    };

    AlexaAddonExt.prototype.checkPluginConfiguredState = function checkPluginConfiguredState() {
        return _sendWithPolling(AlexaAddonExt.Enums.COMMANDS.STATUS).then(function (res) {
            return res.setupComplete;
        }, function (err) {
            Debug.Alexa && console.warn("AlexaAddonExt LOG!!!!! error status setupComplete --> " + JSON.stringify(err));
        });
    };

    AlexaAddonExt.prototype.updateEndpoint = function updateEndpoint(updatedEndpointObj) {
        var endpointObjIndex = endpoints.findIndex(function (endpoint) {
            return updatedEndpointObj.endpointId === endpoint.endpointId || updatedEndpointObj.serial === endpoint.serial;
        });
        endpoints.splice(endpointObjIndex, 1, updatedEndpointObj);
    };

    AlexaAddonExt.prototype.getLocalEndpoints = function getLocalEndpoints() {
        return endpoints;
    };

    AlexaAddonExt.prototype.updateAllEndpoints = function updateAllEndpoints(endpointsObj) {
        endpoints = endpointsObj;
    };

    AlexaAddonExt.prototype.sendUpdateEndpoints = function sendUpdateEndpoints() {
        var cmd = Commands.format(AlexaAddonExt.Enums.COMMANDS.UPDATE_ENDPOINTS, alexaAddOn.uuid),
            modifiedEndpoints = endpoints.map(function (endpoint) {
                return {
                    endpointId: endpoint.endpointId,
                    visible: endpoint.visible
                };
            });
        endpoints = null; // stringify the JSON object otherwise request will fail

        return CommunicationComponent.sendViaHTTP(cmd, EncryptionType.NONE, true, HTTP_METHODS.POST, JSON.stringify(modifiedEndpoints));
    };

    AlexaAddonExt.prototype.logoutUser = function logoutUser() {
        return _sendWithPolling(AlexaAddonExt.Enums.COMMANDS.UNSET_AUTH);
    };

    AlexaAddonExt.prototype.clearRequestTimeouts = function clearRequestTimeouts() {
        pollingTimeout && clearTimeout(pollingTimeout);
        getAuthPollingTimeout && clearTimeout(getAuthPollingTimeout);
        permissionTimeout && clearTimeout(permissionTimeout);
    };

    AlexaAddonExt.prototype.deleteAlexaPlugin = function deleteAlexaPlugin() {
        this.clearRequestTimeouts();
        return _sendWithPolling(AlexaAddonExt.Enums.COMMANDS.UNSET_AUTH).then(function () {
            return ActiveMSComponent.deleteAddonInstance(alexaAddOn.uuid).then(function (res) {
                alexaAddOn = res;
            }).then(function (err) {
                Debug.Alexa && console.warn("LOG!!!!! deleteAddonInstance --> " + JSON.stringify(err));
            });
        }.bind(this));
    };

    AlexaAddonExt.prototype.setAlexaAPIUser = function setAlexaAPIUser(userUuid) {
        var cmd = Commands.format(Commands.ADDONS.HOMEKIT_SET_USER, userUuid);
        return _sendWithPolling(cmd).then(function (res) {
            return res;
        }.bind(this), function (err) {
            Debug.Alexa && console.warn("AlexaAddonExt LOG!!!!! setAlexaAPIUser --> " + JSON.stringify(err));
            return Q.reject(err);
        }.bind(this));
    };

    AlexaAddonExt.prototype.sendConfigurationComplete = function sendConfigurationComplete(userUuid) {
        return _sendWithPolling(AlexaAddonExt.Enums.COMMANDS.SETUP_COMPLETE).then(function (res) {
            return res;
        }.bind(this), function (err) {
            Debug.Alexa && console.warn("AlexaAddonExt LOG!!!!! error sendConfigurationComplete --> " + JSON.stringify(err));
            return Q.reject(err);
        }.bind(this));
    };

    AlexaAddonExt.prototype.getControlNameForType = function getControlNameForType(type) {
        var lowerType = type.toLowerCase(),
            stringId = "search.controltype." + lowerType,
            controlTypeName = _(stringId);

        if (controlTypeName === stringId || lowerType === controlTypeName) {
            // We didn't find the string try to append the type again, controls with keywords
            // in the Kerberos.xml will have this structure
            stringId += "." + lowerType;
            controlTypeName = _(stringId);
        }

        if (controlTypeName === stringId || lowerType === controlTypeName) {
            return null;
        } else {
            return controlTypeName;
        }
    };

    var _sendWithPolling = function _sendWithPolling(cmd, retryUntilSuccess, def = Q.defer()) {
        Debug.Alexa && console.log('AlexaAddonExt', "LOG!!!! --> _sendWithPolling: " + cmd);
        SandboxComponent.checkGrantedPermission(requiredPermissions).then(() => {
            lifeCycleDef.promise.then(function () {
                ActiveMSComponent.sendAddonCommand(alexaAddOn.uuid, cmd, true).then(function (res) {
                    Debug.Alexa && console.log('AlexaAddonExt', "LOG!!!! --> _sendWithPolling: handle responsefor request" + cmd);
                    if (typeof res === "string") {
                        try {
                            def.resolve(JSON.parse(res.replaceAll('\\', '')));
                        } catch (err) {
                            console.log("error");
                            def.resolve(res);
                        }
                    } else {
                        def.resolve(res);
                    }
                }, function (err) {
                    Debug.Alexa && console.log('AlexaAddonExt', "LOG!!!! --> _sendWithPolling: handle error" + JSON.stringify(err)); // 103 === not ready

                    if (getLxResponseCode(err) === 103 || retryUntilSuccess && getLxResponseCode(err) === 500) {
                        pollingTimeout = setTimeout(function () {
                            _sendWithPolling(cmd, retryUntilSuccess, def);
                        }.bind(this), 1250);
                    } else {
                        if (typeof err === "string") {
                            def.reject(JSON.parse(err.replaceAll('\\', '')));
                        } else {
                            def.reject(err);
                        }
                    }
                });
            });
        }, () => {
            permissionTimeout = setTimeout(() => {
                _sendWithPolling(cmd, retryUntilSuccess, def);
            }, 1250);
        });
        return def.promise.then(res => {
            return lifeCycleDef.promise.then(() => {
                return Q.resolve(res);
            });
        });
    };

    return AlexaAddonExt;
});
