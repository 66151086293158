'use strict';

window.Components = function (Components) {
    class MusicServerResolverExt extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.musicServerStates = {};
            this.featureLists = {};
            this.registerExtensionEv(this.component.ECEvent.STRUCTURE_READY, this._registerForMusicServerStates.bind(this));
            this.registerExtensionEv(this.component.ECEvent.MUSIC_ZONE_CHANGED, this._musicZoneChanged.bind(this));
        }

        /**
         * Registers to available Musicserver-State-Changes
         * @private
         */
        _registerForMusicServerStates() {
            var availableMiniservers = ActiveMSComponent.getStructureManager().getMediaServerSet();

            if (availableMiniservers) {
                Object.keys(availableMiniservers).forEach(function (uuid) {
                    this.featureLists[uuid] = {};
                    SandboxComponent.registerForStateChangesForUUID(uuid, this, this._musicServerStatesChanged.bind(this));
                }.bind(this));
            }
        }

        /**
         * Called when any Musicserver changes it's states
         * @param states
         * @private
         */
        _musicServerStatesChanged(states) {
            //Add Musicserver if not known to the Extension
            if (states.hasOwnProperty("audioEvent")) {
                if (!this.musicServerStates.hasOwnProperty(states.audioEvent.uuid)) {
                    this.musicServerStates[states.audioEvent.uuid] = {};
                }

                this.musicServerStates[states.audioEvent.uuid].features = states.features;
                this.musicServerStates[states.audioEvent.uuid].api = states.api;
                this.musicServerStates[states.audioEvent.uuid].version = states.version;

                this._updateFeatureListForMusicserver(states.audioEvent.uuid);
            }
        }

        /**
         * Updates private list of available features for the corresponding Musicserver
         * @param uuid
         * @private
         */
        _updateFeatureListForMusicserver(uuid) {
            var feature = {};
            var resolved, featureKey;

            if (!this.featureLists.hasOwnProperty(uuid)) {
                this.featureLists[uuid] = {};
            }

            for (featureKey in this.component.features) {
                feature = {}; //Copy object by value - not by reference - would also update InfoScreenComp-Features if copied by reference

                Object.assign(feature, this.component.features[featureKey]);

                if (feature[Requirements.MUSICSERVER]) {
                    resolved = true;
                    feature[Requirements.MUSICSERVER].forEach(function (requirement) {
                        if (!this.musicServerStates[uuid].features[requirement]) {
                            resolved = false;
                        }
                    }.bind(this));

                    if (resolved) {
                        feature.state = FeatureCheckStates.RESOLVED;
                    } else {
                        feature.state = FeatureCheckStates.UNRESOLVED;
                    }

                    this.featureLists[uuid][featureKey] = {};
                    Object.assign(this.featureLists[uuid][featureKey], feature);
                }
            }
        }

        /**
         * Called when Music Zone changed - we have knoledge about which Musicserver is used currently.
         * Updates Features of InfoScreenComp based on features available for current Musicserver
         * @param event
         * @param serverUUID
         * @private
         */
        _musicZoneChanged(event, serverUUID) {
            this._replaceRuntimeFeatures(this.featureLists[serverUUID]);

            this.channel.emit(this.component.ECEvent.MUSICSERVER_FEATURES_UPDATED);
        }

        _replaceRuntimeFeatures(features) {
            Object.keys(features).forEach(function (key) {
                //Copy object by value - not by reference
                Object.assign(this.component.features[key], features[key]);
            }.bind(this));
        }

    }

    Components.InfoScreenComp.extensions.MusicServerResolverExt = MusicServerResolverExt;
    return Components;
}(window.Components || {});
