'use strict';

define(
    "AcControlStateContainer",
    [
        "ControlStateContainer",
        "AcControlEnums"
    ], function (
        ControlStateContainer,
        AcControlEnums
    ) {
    return class AcControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.status = !!newVals[this.control.states.status];
            // temperature is provided as fahrenheit or celsius depending on the unit setting on the miniserver
            this.states.currentTemperature = newVals[this.control.states.temperature];
            this.states.currentTemperatureText = lxFormat("%.1f", this.states.currentTemperature);
            this.states.targetTemperature = newVals[this.control.states.targetTemperature];
            this.states.targetTemperatureText = lxFormat("%.1f", this.states.targetTemperature, true);
            this.states.mode = newVals[this.control.states.mode];
            this.states.fan = newVals[this.control.states.fan];
            this.states.ventMode = newVals[this.control.states.ventMode];
            this.states.operatingModes = JSON.parse(newVals[this.control.states.operatingModes].text)
                .map(mode => this._duplicateKeysWithMapping({ id: "value", name: "title" }, mode));
            this.states.fanSpeeds = JSON.parse(newVals[this.control.states.fanspeeds].text)
                .map(mode => this._duplicateKeysWithMapping({ id: "value", name: "title" }, mode));
            this.states.airflows = JSON.parse(newVals[this.control.states.airflows].text)
                .map(mode => this._duplicateKeysWithMapping({ id: "value", name: "title" }, mode));

            if (Feature.AC_CONTROL_UPDATES) {
                this.states.pauseUntil = newVals[this.control.states.pauseUntil];
                this.states.pauseTime = newVals[this.control.states.pauseTime];
                this.states.pauseReason = newVals[this.control.states.pauseReason];
                this.states.sleepPause = !!hasBit(this.states.pauseReason, AcControlEnums.PauseReasonsBitmask.sleep);
                this.states.windowOpenPause = !!hasBit(this.states.pauseReason, AcControlEnums.PauseReasonsBitmask.windowOpen);
                this.states.loadSheddingPause = !!hasBit(this.states.pauseReason, AcControlEnums.PauseReasonsBitmask.loadShedding);
            } else {
                this.states.pauseUntil = 0;
                this.states.pauseTime = false;
                this.states.pauseReason = false;
                this.states.sleepPause = false;
                this.states.windowOpenPause = false;
                this.states.loadSheddingPause = false;
            }

            if (Feature.AC_CONTROL_UPDATES_V2) {
                this.states.isExternalDisabled = !!newVals[this.control.states.disableExt];
                this.states.isOperatedExternally = this.control.details.externalControlled && !this.states.isExternalDisabled;
                this.states.overrideTimer = JSON.parse(newVals[this.control.states.override].text);
                this.states.overrideTimerActive = this.states.overrideTimer.until !== 0 || this.states.pauseUntil !== 0;
                this.states.silentModeActive = !!newVals[this.control.states.silentMode];
                this.states.pauseHvacHeatProhibited = !!hasBit(this.states.pauseReason, AcControlEnums.PauseReasonsBitmask.pause_hvac_heat_prohibited);
                this.states.pauseHvacCoolProhibited = !!hasBit(this.states.pauseReason, AcControlEnums.PauseReasonsBitmask.pause_hvac_cool_prohibited);
                this.states.pauseDiffmode = !!hasBit(this.states.pauseReason, AcControlEnums.PauseReasonsBitmask.pauseDiffmode);
            } else {
                this.states.isExternalDisabled = false;
                this.states.isOperatedExternally = false;
                this.states.overrideTimer = { until: 0, time: 0 };
                this.states.overrideTimerActive = false;
                this.states.silentModeActive = false;
                this.states.pauseHvacHeatProhibited = false
                this.states.pauseHvacCoolProhibited = false;
                this.states.pauseDiffmode = false;
            }

            if (Feature.AC_CONTROL_SETTINGS_AS_STATE) {
                this.states.settings = JSON.parse(newVals[this.control.states.settings].text);
            } else {
                this.states.settings = {
                    SilentMode: -255,
                    DefaultAirflow: -255,
                    DefaultFanspeed: -255,
                    DefaultTargetTemp: -255,
                };
            }

            Debug.Control.AcControl && console.log(this.name, "--------------------------------------------");
            Debug.Control.AcControl && console.log(this.name, "  status = " + this.states.status);
            Debug.Control.AcControl && console.log(this.name, "  current temperature = " + this.states.currentTemperature);
            Debug.Control.AcControl && console.log(this.name, "  target temperature = " + this.states.targetTemperature);
            Debug.Control.AcControl && console.log(this.name, "  mode = " + this.states.mode);
            Debug.Control.AcControl && console.log(this.name, "  fan = " + this.states.fan);
            Debug.Control.AcControl && console.log(this.name, "  vent mode = " + this.states.ventMode);
            Debug.Control.AcControl && console.log(this.name, "--------------------------------------------");
        }

        getStateText() {
            if (this.states.sleepPause && !Feature.AC_CONTROL_UPDATES_V2) {
                return (
                    _("controls.ac-control.sleep-mode")
                )
            } else if (this.states.loadSheddingPause) {
                return (
                    _("controls.ac-control.load-shedding")
                )
            } else if (this.states.windowOpenPause) {
                return (
                    _("controls.ac-control.window-open")
                )
            } else if (this.states.pauseHvacHeatProhibited) {
                return (
                    _("controls.ac-control.pause-heat-prohibited-short")
                )
            } else if (this.states.pauseHvacCoolProhibited) {
                return (
                    _("controls.ac-control.pause-cool-prohibited-short")
                )
            } else if (this.states.pauseDiffmode) {
                return (
                    _("controls.ac-control.pause-diffmode")
                )
            } else if (this.states.silentModeActive) {
                return `${
                    this.states.targetTemperatureText
                }°${SEPARATOR_SYMBOL}${_(
                    'controls.ac-control.settings.silent-mode',
                )}`;
            } else {
                return (
                    `${this.states.targetTemperatureText}°${SEPARATOR_SYMBOL}${this.states.status ? _("on") : _("off")}`
                )
            }
        }

        getStateTextForContent() {
            // pause timer highest prio, then window contact, then load shedding
            if (this.states.sleepPause) {
                return _("controls.ac-control.sleep-mode-long")
            } else if (this.states.loadSheddingPause) {
                return _("controls.ac-control.load-shedding-long")
            } else if (this.states.pauseUntil !== 0) {
                return _("controls.ac-control.sleep-mode")
            } else if (this.states.windowOpenPause) {
                return _("controls.ac-control.window-open-long")

            } else if (this.states.pauseHvacHeatProhibited || this.states.pauseHvacCoolProhibited) {
                var heat = this.states.pauseHvacHeatProhibited,
                    text;
                const parentCC = this.control.getParentClimateController(),
                    parentCCName = parentCC ? parentCC.name : _("controls.ac-control.climate-controller");
                if (heat) {
                    text = _('controls.ac-control.pause-heat-prohibited-long', {
                        parentName: `"${parentCCName}"`,
                    });
                } else {
                    text = _('controls.ac-control.pause-cool-prohibited-long', {
                        parentName: `"${parentCCName}"`,
                    });
                }
                return text;

            } else if (this.states.pauseDiffmode) {
                const parentClimateController = this.control.getParentClimateController();
                if (parentClimateController) {
                    return _('controls.ac-control.pause-diffmode-long-linked', {
                        parentName: `"${parentClimateController.name}"`,
                    });
                } else {
                    return _("controls.ac-control.pause-diffmode-long");
                }
            }

            if(Feature.AC_CONTROL_UPDATES_V2 ) {
                if (this.states.overrideTimerActive || this.states.pauseUntil !== 0) {
                    return `${this.states.status ? _("on") : _("off")}${SEPARATOR_SYMBOL}${_("controls.ac-control.override-active")}`;
                } else if (this.states.silentModeActive) {
                    return `${this.states.status ? _("on") : _("off")}${SEPARATOR_SYMBOL}${_("controls.ac-control.settings.silent-mode")}`;
                }
                if(this.states.isOperatedExternally) {
                    return `${this.states.status ? _("on") : _("off")}${SEPARATOR_SYMBOL}${_("controls.ac-control.managed-by-irc")}`;
                }
            }

            return this.states.status ? _("on") : _("off")
        }

        getStateTextColor() {
            if (
                this.states.sleepPause ||
                this.states.windowOpenPause ||
                this.states.loadSheddingPause ||
                this.states.pauseHvacHeatProhibited ||
                this.states.pauseHvacCoolProhibited ||
                this.states.pauseDiffmode
            ) {
                return window.Styles.colors.orange;
            }

            if(Feature.AC_CONTROL_UPDATES_V2 && this.states.overrideTimerActive) {
                return this.states.status ? window.Styles.colors.stateActive : null;
            }

            if (this.states.status) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateColor() {
            if(Feature.AC_CONTROL_UPDATES_V2 && this.states.overrideTimerActive && !this.states.status) {
                return window.Styles.colors.white;
            }
            if (this.states.status) {
                return window.Styles.colors.stateActive;
            }
        }

        getStateIcon() {
            return Icon.AcControl.AC_ICON;
        }

        getStateIconSmall() {
            if(Feature.AC_CONTROL_UPDATES_V2) {
                if (this.states.overrideTimerActive) {
                    return {
                        iconSrc: Icon.AcControl.TIMER,
                        color: window.Styles.colors.stateActive
                    }
                }
                if (this.states.silentModeActive) {
                    return {
                        iconSrc: Icon.AcControl.SILENT_MODE,
                        color: window.Styles.colors.stateActive
                    }
                }
            }
            if (this.states.sleepPause || this.states.loadSheddingPause || this.states.pauseUntil !== 0 || this.states.pauseHvacCoolProhibited || this.states.pauseHvacHeatProhibited || this.states.pauseDiffmode) {
                return {
                    iconSrc: Icon.Wallbox2.SmallPause,
                    color: window.Styles.colors.orange
                }
            } else if (this.states.windowOpenPause) {
                return {
                    iconSrc: Icon.AcControl.DOOR,
                    color: window.Styles.colors.orange
                }
            }
        };

        _duplicateKeysWithMapping = (keysMap, obj) => {
            return Object.keys(obj).reduce(
                (acc, key) => ({
                    ...acc,
                    ...{ [key]: obj[key] },
                    ...(keysMap[key] ? { [keysMap[key]]: obj[key] } : {})
                }),
                {}
            );
        }
    }
});
