'use strict';

define("AudioZoneControlStateContainer", ["ControlStateContainer", 'AudioZoneControlEnums'], function (ControlStateContainer, AudioZoneControlEnums) {
    return class AudioZoneControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            this.name = "AudioZoneStateContainer " + this.control.name;
            this.states.texts = {};

            if (Feature.MULTI_MUSIC_SERVER) {
                SandboxComponent.registerForStateChangesForUUID(this.control.details.server, this, this._receivedServerStates);
            } else {
                this.playerRegID = MediaServerComp.registerForAudioZone(control.details.playerid, this.serverStateReceived.bind(this), this.audioEventReceived.bind(this), null);
            }
        }

        destroy() {
            if (Feature.MULTI_MUSIC_SERVER) {
                SandboxComponent.unregisterForStateChangesForUUID(this.control.details.server, this);
            } else {
                MediaServerComp.unregisterFromAudioZone(this.playerRegID, this.control.details.playerid);
            }

            super.destroy(...arguments);
        }

        // ------------------------------------------------------------------------
        //                    States from the Miniserver
        // ------------------------------------------------------------------------

        /**
         * handles incoming values and prepares the states (is also called when the MediaServerComp or the server state
         * container delivers new data)
         * values returned here are concerning both the zone and the server itself.
         * @param newVals
         */
        prepareStates(newVals) {
            this.newVals = newVals;
            Debug.Control.AudioZone.StateContainer && debugLog(this.name, "prepareStates");

            if (Feature.MULTI_MUSIC_SERVER) {
                this._getMediaInfoFromMiniserver(newVals);
            } // if a zone is synchronized and prepare a text.


            if (this.states.hasOwnProperty('isSynced') && this.states.hasOwnProperty(MediaEnum.Event.SYNCED_ZONES)) {
                this.states.texts.titlebar = this.control.getName();
            } // when the first states arrive from the miniserver, there is no server state yet!


            if (!this.states.hasOwnProperty('serverState') || !this.states.serverState && this.states.serverState !== 0) {
                Debug.Control.AudioZone.StateContainer && debugLog("ServerState undefined - set it to initializing!");
                this.states.serverState = MediaEnum.ServerState.INITIALIZING;
            } // "read" states from the miniserver on this zone
            // has the mediaServer got an connection to this client?


            this.states.clientReady = this.states[MediaEnum.Event.ZONE_STATE] === MediaEnum.EventAttr.ZONE_STATE.ON;

            if (Feature.MULTI_MUSIC_SERVER) {
                // try to acquire the server state
                if (newVals.hasOwnProperty(this.control.states.serverState)) {
                    this.states.serverState = newVals[this.control.states.serverState];
                } // we don't wait for the socket to open up.


                this.states.serverReady = this.states.serverState === MediaEnum.ServerState.ONLINE;
            } else {
                this.states.serverReady = this.states.serverState === MediaEnum.ServerState.ONLINE && this.states.isCompCommReady;
            }

            if (this.control.isNetworkClient()) {
                if (newVals.hasOwnProperty(this.control.states.clientState)) {
                    this.states.clientState = newVals[this.control.states.clientState];
                }

                if (newVals.hasOwnProperty(this.control.states.power)) {
                    this.states.clientPowerOn = newVals[this.control.states.power];
                } // if the mediaServer says the client isn't ready, maybe the Miniserver just turned on the power and
                // therefore knows that the client will go online in at last one minute


                this.states.clientBooting = !this.states.clientReady && this.states.clientState === MediaEnum.ClientState.INITIALIZING;
            } else {
                this.states.clientState = MediaEnum.ClientState.ONLINE;
                this.states.clientPowerOn = true;
                this.states.clientBooting = false;
            } // Time


            if (this.states[MediaEnum.Event.TIME] >= 0 && this.states[MediaEnum.Event.DURATION] >= 0 && this.states[MediaEnum.Event.MODE]) {
                this._updateTimer(this.states[MediaEnum.Event.TIME], this.states[MediaEnum.Event.DURATION], this.states[MediaEnum.Event.MODE]);
            } // isPlaying


            this.states.isPlaying = this.states[MediaEnum.Event.MODE] === MediaEnum.EventAttr.Mode.PLAY && this.states.serverReady && this.states.clientReady;

            if (!this.states.isPlaying) {
                this._stopTimeUpdater();
            } // Shuffle


            if (this.states.hasOwnProperty(MediaEnum.Event.SHUFFLE)) {
                this.states.shuffleOn = this.states[MediaEnum.Event.SHUFFLE] !== 0;
            } // Repeat


            if (this.states.hasOwnProperty(MediaEnum.Event.REPEAT)) {
                this.states.repeatMode = this.states[MediaEnum.Event.REPEAT];
            } // A volume change should lead to a temporary change in the state texts (at least for the cards)


            if (this.prevVolume !== this.states.volume) {
                if (this.hasOwnProperty("prevVolume")) {
                    this.adoptedVolumeTimeout && clearTimeout(this.adoptedVolumeTimeout);
                    this.adoptedVolumeTimeout = setTimeout(function () {
                        this.adoptedVolumeTimeout = null;

                        this._updateStates();
                    }.bind(this), 3000);
                }

                this.prevVolume = this.states.volume;
            }

            this._prepareMediaInfo();

            this._prepareConnectivityInfo(); // supported since about version 6.5.8.3


            if (this.control.states.hasOwnProperty("maxVolume") && typeof newVals[this.control.states.maxVolume] === "number") {
                this.states.maxVolume = newVals[this.control.states.maxVolume];
            }

            if (typeof this.states.maxVolume !== "number" || this.states.maxVolume === 0) {
                this.states.maxVolume = 100; // set default if not already set
            }

            if (this.control.states.hasOwnProperty("source") && typeof newVals[this.control.states.source] === "number") {
                this.states.source = newVals[this.control.states.source];
            } // supported since about version 6.5.8.3


            if (this.control.states.hasOwnProperty("volumeStep") && typeof newVals[this.control.states.volumeStep] === "number") {
                this.states.volumeStep = newVals[this.control.states.volumeStep];
            }

            if (typeof this.states.volumeStep !== "number" || this.states.volumeStep === 0) {
                this.states.volumeStep = 1; // set default if not already set
            }

            this._prepareCoverOrIcon();

            Debug.Control.AudioZone.StateContainer && debugLog("        serverState: " + this._getServerStateText(this.states.serverState).text + " (serverReady: " + this.states.serverReady + ")");
        }

        getStateIcon() {
            return Icon.AudioZone.AUDIO_ZONE;
        }

        getStateText() {
            if (this.states.texts.connectivityText) {
                return this.states.texts.connectivityText;
            } else {
                return this.states.texts.subText;
            }
        }

        getStateTextColor() {
            var color;

            try {
                if (this.states.serverState === MediaEnum.ServerState.OFFLINE || this.states.serverState === MediaEnum.ServerState.NOT_REACHABLE || !this.states.clientReady && this.control.isNetworkClient() && !this.states.clientBooting) {
                    color = window.Styles.colors.red;
                } else if (this.states.isPlaying) {
                    color = window.Styles.colors.stateActive;
                }
            } catch (e) {
                color = window.Styles.colors.stateInActive;
            }

            return color;
        }

        getStateTextShort() {
            if (this.adoptedVolumeTimeout) {
                // temporarily show the current volume as text
                return _("cmdtext.audiozone.volume", {
                    vol: lxFormat("%.0f%%", this.states.volume)
                });
            } else if (!this.states.texts.connectivityText) {
                return this.states.texts.mainText;
            }
        }

        getStateColor() {
            var color;

            if (this.states.isPlaying) {
                color = window.Styles.colors.stateActive;
            }

            return color;
        }

        getStateTextForContent() {
            if (this.states.texts.connectivityText) {
                return this.states.texts.connectivityText;
            } else if (this.states.isPlaying) {
                return _("media.item.playing");
            } else {
                return _("media.item.paused");
            }
        }

        /**
         * If a zone is synced, the small icon will represent it.
         */
        getStateIconSmall() {
            var icon = null;

            if (this.states.isSynced) {
                icon = {
                    iconSrc: Icon.AudioZone.GROUPED,
                    color: this.states.syncColor
                };
            }

            return icon;
        }

        /**
         * States from the mediaStateContainer that receives the updates of the MediaServer. Only used if the
         * Multiple Music Server Feature is available on the Miniserver.
         * @param states    music server states, adopted by mediaStateContainer
         * @private
         */
        _receivedServerStates(states) {
            Debug.Control.AudioZone.StateContainer && console.log(this.name, "_receivedServerStates");
            var keepSearching = true;
            this.states.isSynced = false;
            this.states.syncColor = null;
            this.states[MediaEnum.Event.SYNCED_ZONES] = [];
            this.states.connStateText = states.connStateText;
            this.states.connState = states.connState; // Miniservers and Music Servers must have a certain level in order for the source idx to be reliable.

            this.states.hasReliableSourceIndex = states.features && states.features.AUDIO_ZONE_SOURCE && Feature.AUDIO_ZONE_SOURCE;

            if (MediaServerComp.isRestricted()) {
                Debug.Control.AudioZone.StateContainer && console.log("      restricted, bail out");
                return; // no group info while externally connected.
            } // check every group


            states.grouping && states.grouping.every(function (currGroup) {
                // check every groups player
                currGroup[MediaEnum.Event.PLAYERS].every(function (currPlayer) {
                    // is this state containers player in this group?
                    if (currPlayer[MediaEnum.Event.PLAYER_ID] === this.control.details.playerid) {
                        // yes, set the states and return.
                        this.states.isSynced = true;
                        this.states.syncColor = currGroup.color;
                        this.states[MediaEnum.Event.SYNCED_ZONES] = currGroup[MediaEnum.Event.PLAYERS];
                        keepSearching = false;
                    }

                    return keepSearching;
                }.bind(this));
                return keepSearching;
            }.bind(this));
            Debug.Control.AudioZone.StateContainer && console.log("  - isSynced: " + JSON.stringify(this.states.isSynced));
            Debug.Control.AudioZone.StateContainer && console.log("  - " + JSON.stringify(this.states[MediaEnum.Event.SYNCED_ZONES]));

            this._updateStates();
        }

        // ------------------------------------------------------------------------
        //                    States from the MediaServerComponent
        // ------------------------------------------------------------------------

        /**
         * Receives events concerning the state of the music server itself. Calls prepareStates and notifyListeners
         * @param newVals
         */
        serverStateReceived(newVals) {
            Debug.Control.AudioZone.StateContainer && debugLog(this.name, "serverStateReceived: " + JSON.stringify(newVals)); // convert "state" to "serverState" before merging

            var tmpVals = cloneObjectDeep(newVals);

            if (Feature.MULTI_MUSIC_SERVER) {// don't use the media components server state, the zone itself has one. Use it instead.
            } else {
                tmpVals.serverState = tmpVals.state;
            }

            delete tmpVals.state;

            this._mergeWithStates(tmpVals);

            this.states.isCompCommReady = !!newVals.reachable && tmpVals.serverState === MediaEnum.ServerState.ONLINE;

            if (Feature.MULTI_MUSIC_SERVER) {// don't use the media components server state, the zone itself has one. Use it instead.
            } else {
                this.states.serverState = tmpVals.hasOwnProperty("serverState") ? tmpVals.serverState : MediaEnum.ServerState.INITIALIZING;
            }

            this._updateStates();
        }

        /**
         * Receives audio events from the MediaServerComp concerning this very zone. Calls prepareStates and notifyListeners
         * @param newVals
         */
        audioEventReceived(newVals) {
            Debug.Control.AudioZone.StateContainer && debugLog(this.name, "audioEventReceived: " + JSON.stringify(newVals));

            this._mergeWithStates(newVals); // Respond to audioType


            if (newVals.hasOwnProperty(MediaEnum.Event.AUDIO_TYPE)) {
                this._updateAudioType(newVals);
            } // Sync


            if (this.states.hasOwnProperty(MediaEnum.Event.SYNCED_ZONES)) {
                this.states.isSynced = this.states[MediaEnum.Event.SYNCED_ZONES].length > 0;
                this.states.syncColor = this.states[MediaEnum.Event.SYNCED_COLOR];
            } else {
                this.states.isSynced = false;
            }

            this._updateStates();
        }

        // ------------------------------------------------------------------------
        //                    Helper
        // ------------------------------------------------------------------------
        _mergeWithStates(newVals) {
            updateFields(this.states, newVals);
        }

        /**
         * All listeners will be informed after prepareStates is being called. Also takes care of the version counter that
         * is used for views to identify whether or not they need to update their UI
         * @private
         */
        _updateStates() {
            this.version++; // count up version with each update

            this.newVals && this.prepareStates(this.newVals);
            this.notifyListener();
        }

        // ------------------------------------------------------------------------
        //                    Handling Audio Events
        // ------------------------------------------------------------------------
        //  Audio Type
        _updateAudioType(event) {
            var audioType = event[MediaEnum.Event.AUDIO_TYPE];
            this.states.isStream = MediaServerComp.isStream(audioType);

            if (!this.states.isStream) {
                if (event.hasOwnProperty(MediaEnum.Event.DURATION) && event[MediaEnum.Event.DURATION] === 0) {
                    console.warn("This 'track' is not a stream, but it has 0 duration!");
                }

                if (!event.hasOwnProperty(MediaEnum.Event.DURATION)) {
                    console.warn("This 'track' is not a stream and it has no duration! it must have a duration");
                }
            }

            if (this.states.isStream) {
                var hasDuration = event.hasOwnProperty(MediaEnum.Event.DURATION);
                hasDuration = hasDuration && event[MediaEnum.Event.DURATION] > 0;
                this.states.isStream = !hasDuration; // if it is a stream, but we have a duration, don't treat it as one
            }
        }

        //  Time/Duration
        _updateTimer(time, duration, mode) {
            this.currTime = time;
            this.duration = duration;
            this.states[MediaEnum.Event.TIME] = this.currTime;
            this.states[MediaEnum.Event.DURATION] = this.duration;
            this.startTimeStamp = new Date().getTime();
            this.startTime = time;

            this._updateProgress(time, duration); // update right away


            if ((mode === "play" || mode === "resume") && !this.timeUpdater && this.duration > 0) {
                this.timeUpdater = setInterval(function () {
                    var delta = new Date().getTime() - this.startTimeStamp;
                    this.currTime = parseInt(this.startTime + delta / 1000);

                    this._updateProgress(this.currTime, this.duration);

                    this.notifyListener();
                }.bind(this), 1000);
            } else if (mode === "stop" || mode === "pause") {
                this._stopTimeUpdater();
            } // if the server or client is down - the timer is stopped inside prepare states

        }

        _stopTimeUpdater() {
            clearInterval(this.timeUpdater);
            this.timeUpdater = null;
        }

        _checkTimeString(time) {
            return time < 10 ? '0' + time : time;
        }

        _updateProgress(time, duration) {
            if (!this.states.texts.hasOwnProperty("time")) {
                this.states.texts.time = {
                    current: "-10",
                    total: "-10"
                };
            }

            this.states[MediaEnum.Event.TIME] = time;
            this.states[MediaEnum.Event.DURATION] = duration;

            var minutes = this._checkTimeString(Math.floor(time / 60));

            var seconds = this._checkTimeString(Math.floor(time - minutes * 60));

            var bygoneTime = minutes + ':' + seconds;
            var totalTime = null;

            if (duration - time >= 0) {
                minutes = this._checkTimeString(Math.floor(duration / 60));
                seconds = this._checkTimeString(Math.floor(duration - minutes * 60));
                totalTime = minutes + ':' + seconds;
            }

            if (this.states.isStream) {
                bygoneTime = "";
                totalTime = "";
            }

            if (bygoneTime !== this.states.texts.time.current || totalTime !== this.states.texts.time.total) {
                this.states.texts.time = {
                    current: bygoneTime,
                    total: totalTime
                };
            }
        }

        /**
         * Reads the media info from the miniserver state update.
         * @param states    the state update data.
         * @private
         */
        _getMediaInfoFromMiniserver(states) {
            var mapFn = function (uuid, target, isText) {
                if (states.hasOwnProperty(uuid)) {
                    if (isText) {
                        this.states[target] = states[uuid].text;
                    } else {
                        this.states[target] = states[uuid];
                    }
                }
            }.bind(this);

            mapFn(this.control.states.songName, MediaEnum.Event.TITLE, true);
            mapFn(this.control.states.artist, MediaEnum.Event.ARTIST, true);
            mapFn(this.control.states.station, MediaEnum.Event.STATION, true);
            mapFn(this.control.states.cover, MediaEnum.Event.COVER, true);
            mapFn(this.control.states.duration, MediaEnum.Event.DURATION);
            mapFn(this.control.states.progress, MediaEnum.Event.TIME);
            mapFn(this.control.states.volume, MediaEnum.Event.VOLUME);
            mapFn(this.control.states.maxVolume, MediaEnum.Event.MAX_VOLUME);
            mapFn(this.control.states.shuffle, MediaEnum.Event.SHUFFLE);
            mapFn(this.control.states.repeat, MediaEnum.Event.REPEAT);

            if (Feature.NEW_AUDIO_ZONE_STATES) {
                mapFn(this.control.states.queueIndex, MediaEnum.Event.QUEUE_INDEX_MINISERVER);
                mapFn(this.control.states.enableAirPlay, MediaEnum.Event.AIRPLAY_MINISERVER);
                mapFn(this.control.states.enableSpotifyConnect, MediaEnum.Event.SPOTIFY_CONNECT_MINISERVER);
                mapFn(this.control.states.alarmVolume, MediaEnum.Event.ALARM_VOLUME_MINISERVER);
                mapFn(this.control.states.bellVolume, MediaEnum.Event.BELL_VOLUME_MINISERVER);
                mapFn(this.control.states.buzzerVolume, MediaEnum.Event.BUZZER_VOLUME_MINISERVER);
                mapFn(this.control.states.ttsVolume, MediaEnum.Event.TTS_VOLUME_MINISERVER);
                mapFn(this.control.states.defaultVolume, MediaEnum.Event.DEFAULT_VOLUME_MINISERVER);
                mapFn(this.control.states.maxVolume, MediaEnum.Event.MAX_VOLUME_MINISERVER);
                mapFn(this.control.states.equalizerSettings, MediaEnum.Event.EQ_SETTINGS_MINISERVER, true);
            }

            if (this.control.states.hasOwnProperty("masterVolume")) {
                mapFn(this.control.states.masterVolume, MediaEnum.Event.MASTER_VOLUME);
            }

            if (states.hasOwnProperty(this.control.states.clientState)) {
                // ClientState is an enum on the Miniserver, but it is called ZoneState and handled as string by the app & music server.
                var zoneState = MediaEnum.EventAttr.ZONE_STATE.OFF;

                switch (states[this.control.states.clientState]) {
                    case MediaEnum.ClientState.ONLINE:
                        zoneState = MediaEnum.EventAttr.ZONE_STATE.ON;
                        break;

                    case MediaEnum.ClientState.OFFLINE:
                        zoneState = MediaEnum.EventAttr.ZONE_STATE.OFFLINE;
                        break;

                    default:
                        break;
                }

                this.states[MediaEnum.Event.ZONE_STATE] = zoneState;
            }

            if (states.hasOwnProperty(this.control.states.playState)) {
                var mode = MediaEnum.EventAttr.Mode.STOP;

                switch (states[this.control.states.playState]) {
                    case MediaEnum.PlayState.PLAYING:
                        mode = MediaEnum.EventAttr.Mode.PLAY;
                        break;

                    default:
                        break;
                }

                this.states[MediaEnum.Event.MODE] = mode;
            }

            this.states.isStream = this.states[MediaEnum.Event.DURATION] <= 0;

            if (states.hasOwnProperty(this.control.states.sourceList)) {
                this._prepareSourceList(states[this.control.states.sourceList]);
            }
        }

        // ------------------------------------------------------------------------
        //                    Preparing States
        // ------------------------------------------------------------------------

        /**
         * Parses the sourceList-State-Text form the Miniserver and ensures the zoneFavorites & zoneFavoritesResult
         * states are properly set.
         * @param sourceListState
         * @private
         */
        _prepareSourceList(sourceListState) {
            var NO_SOURCE_ID = "Please Reopen Room Mode",
                // Source name returned if no sources are known yet.
                responseText = sourceListState.text; // legacy fallback if an empty string was returned as text.
            // Other Miniservers send a "dummy" favorite for our old apps running on the room mode, we no longer need it and it will be removed. https://www.wrike.com/open.htm?id=175584503

            if (responseText === "" || !responseText || responseText.indexOf(NO_SOURCE_ID) > -1) {
                // there are no favorites yet. prepare a dummy empty list.
                var cmd = "audio/cfg/getroomfavs/" + this.control.details.playerid + "/0/10";
                responseText = '{ "getroomfavs_result": [ {"id": 3, "type":4, "totalitems":0, "start":0, "items": [ ], "command": "' + cmd + '" } ] }';
            }

            try {
                this.states.zoneFavoritesResult = JSON.parse(responseText)[MediaEnum.ResultId.ROOM_FAV][0];
                this.states.zoneFavorites = this.states.zoneFavoritesResult.items; // it happens that the zone favorites aren't in the proper order (1,2,3,4,5,8,6)

                sortArrByFields(this.states.zoneFavorites, ["slot"]);
            } catch (ex) {
                console.error("Could not parse zone favorites of " + this.control.name + "!");
                this.states.zoneFavoritesResult = "";
                this.states.zoneFavorites = [];
            }
        }

        _prepareMediaInfo() {
            var mainText = "";
            var subText = "";

            if (this.states.hasOwnProperty(MediaEnum.Event.TITLE)) {
                mainText = this.states[MediaEnum.Event.TITLE];

                if (this.states[MediaEnum.Event.ARTIST] && this.states[MediaEnum.Event.ARTIST] !== "") {
                    subText = this.states[MediaEnum.Event.ARTIST];
                }

                if (this.states[MediaEnum.Event.ALBUM] && this.states[MediaEnum.Event.ALBUM] !== "") {
                    if (subText) {
                        subText += " (" + this.states[MediaEnum.Event.ALBUM] + ")";
                    } else {
                        subText = this.states[MediaEnum.Event.ALBUM];
                    }
                }

                if (mainText === "") {
                    // if the title was empty, swap main and subtext
                    mainText = subText;
                    subText = "";
                }
            } // maybe we have a radioStation set?


            if (this.states.hasOwnProperty(MediaEnum.Event.STATION) && this.states[MediaEnum.Event.STATION] !== "") {
                if (subText !== "") {
                    mainText += " - " + subText;
                }

                subText = mainText;
                mainText = this.states[MediaEnum.Event.STATION];
            } // if we have nothing - maybe the Music Server isn't ready yet? if so, don't show "no music selected"


            if (mainText === "" && subText === "" && this.states.serverReady) {
                mainText = _("media.no-music-selected");
                subText = "";
            } // at last, ensure that no encoding was used that now messes with the media info.


            try {
                mainText = decodeURIComponent(mainText);
                subText = decodeURIComponent(subText);
            } catch (ex) {// don't worry if decoding isn't possible.
            }

            this.states.texts.mainText = mainText;
            this.states.texts.subText = subText;
        }

        _getServerStateText(serverState) {
            var serverText = "",
                shortText = null;

            switch (serverState) {
                case MediaEnum.ServerState.ONLINE:
                    // nothing to worry about
                    break;

                case MediaEnum.ServerState.OFFLINE:
                    serverText = _("media.server-in-standby", {
                        server: MediaServerComp.getServerName(this.control.details.server)
                    });
                    break;

                case MediaEnum.ServerState.NOT_REACHABLE:
                    serverText = _("media.conn.offline");
                    break;

                case MediaEnum.ServerState.INITIALIZING:
                    serverText = _("media.conn.booting");
                    break;

                case MediaEnum.ServerState.UNKNOWN:
                    serverText = "-UNKNOWN-";
                    break;

                default:
                    console.error("The audioZoneStateContainer received an invalid server state! " + this.states.serverState); // some unhandled error, maybe invalid zone?

                    break;
            }

            return {
                text: serverText,
                short: shortText
            };
        }

        _prepareConnectivityInfo() {
            var connTextObj = this._getServerStateText(this.states.serverState),
                connectivityText = connTextObj.text,
                connTextShort = connTextObj.short;

            if (connectivityText === "") {
                // nothing to worry about from the server, check the client
                if (!this.states.clientReady) {
                    // the client isn't online, why?
                    if (this.control.isNetworkClient()) {
                        if (this.states.clientBooting) {
                            // it's not the power, it's just still booting
                            connectivityText = _("media.conn.zone.booting");
                        } else {
                            // it's not booting, it's offline or unreachable!
                            connectivityText = _("media.conn.zone.offline");
                        }
                    }
                }
            }

            if (connectivityText === "") {
                this.states.texts.connectivityText = null;
            } else {
                this.states.texts.connectivityText = connectivityText;
            }

            this.states.texts.connectivityTextShort = connTextShort;
        }

        _prepareCoverOrIcon() {
            Debug.Control.AudioZone.StateContainer && debugLog(this.name, "_prepareCoverOrIcon");
            var newAudioType = MediaEnum.AudioType.FILE;

            if (this.states.hasOwnProperty(MediaEnum.Event.AUDIO_TYPE)) {
                newAudioType = this.states[MediaEnum.Event.AUDIO_TYPE];
            } //Debug.Control.AudioZone.StateContainer && console.log("   AudioType: " + newAudioType);
            // line in has no cover (ignore waveinput.png from Music Server)


            if (newAudioType === MediaEnum.AudioType.LINEIN) {
                Debug.Control.AudioZone.StateContainer && console.log("   Remove potential LINEIN-Cover!");
                delete this.states[MediaEnum.Event.COVER];
            }

            var newCover = null;

            if (this.states.hasOwnProperty(MediaEnum.Event.COVER)) {
                newCover = this.states[MediaEnum.Event.COVER];
            }

            this.states.updateCover = newCover !== this.prevCover; //Debug.Control.AudioZone.StateContainer && console.log("   New Cover: " + newCover + " - update: "
            //    + JSON.stringify(this.states.updateCover));

            var newIconSrc = MediaServerComp.defaultIconForAudioType(newAudioType);

            if (!this.states.hasOwnProperty('iconSrc') || newIconSrc !== this.states.iconSrc) {
                this.states.iconSrc = newIconSrc;
                this.states.updateCover = true; //Debug.Control.AudioZone.StateContainer && console.log("   New Icon: " + newIconSrc + " - update: true");
            }

            if (this.states.hasOwnProperty('serverReady') && !this.states.serverReady) {
                delete this.states.iconSrc;
                delete this.states.cover;
                this.states.updateCover = true;
            }

            this.prevCover = newCover;
        }

    };
});
