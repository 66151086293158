'use strict';

export default ({names}) => {
    /**
     * Handles all information about the active platform
     */
    if (names.ext in window) {
        // Already initialized!
        return;
    }
    window[names.int].factory('PlatformComp', ['$injector', function ($injector) {
        // private variables
        let weakThis,
            appInfoExt,
            devInfoExt,
            extension = {},
            finishedInitialization;

        // region Comp
        /**
         * c-tor for the Platform Component
         * @returns {object} exposed functions for other components
         * @constructor
         */

        function PlatComp() {
            weakThis = this
            finishedInitialization = Q.defer(); // storing a reference to all extensions

            appInfoExt = new extension.AppInfo(this);
            devInfoExt = new extension.DeviceInfo(this); // storing a reference to all extensions

            var exposed = {
                getInitializationPromise: weakThis.getInitializationPromise,
                evaluateRequirementsForFeature: weakThis.evaluateRequirementsForFeature,
                getLanguage: devInfoExt.getLanguage.bind(devInfoExt),
                getAppInfoObj: appInfoExt.getAppInfoObj,
                getPlatformInfoObj: devInfoExt.getPlatformInfoObj,
                usesSoftKeyboard: weakThis.usesSoftKeyboard,
                isMobileDevice: weakThis.isMobileDevice,
                isIOS: weakThis.isIOS,
                isMacOS: weakThis.isMacOS,
                isIphoneX: weakThis.isIphoneX,
                BiometricType: weakThis.BiometricType,
                getBiometricType: weakThis.getBiometricType,
                isAndroid: weakThis.isAndroid,
                isWindows: weakThis.isWindows,
                isWebInterface: weakThis.isWebInterface,
                isDeveloperInterface: weakThis.isDeveloperInterface,
                getNetworkStatus: devInfoExt.getNetworkStatus,
                getBatteryStateObj: devInfoExt.getBatteryStateObj,
                getLicenses: weakThis.getLicenses,
                isModernBrowser: devInfoExt.isModernBrowser,
                isCordova: !!window.cordova,
                isElectron: navigator.userAgent.toLowerCase().indexOf(' electron/') > -1,
                isIpad: weakThis.isIpad(),
                shouldDisplayCardsAsCell: weakThis.shouldDisplayCardsAsCell
            }; // dispatch an event once the initialisation has passed, so other components start working
            // required for Kerberos4Mac

            return exposed;
        }

        BaseComponent.beInheritedBy(PlatComp);
        extension = BaseComponent.initExtensions(names.int, $injector);

        PlatComp.prototype.getInitializationPromise = function getInitializationPromise() {
            return finishedInitialization.promise;
        };
        /**
         * Will return true if we're on the WI Platform.
         * @returns {boolean}
         */


        PlatComp.prototype.isWebInterface = function isWebInterface() {
            return devInfoExt.getPlatformInfoObj().platform === PlatformType.Webinterface;
        };
        /**
         * Will return true if we're on the WI Platform.
         * @returns {boolean}
         */


        PlatComp.prototype.isDeveloperInterface = function isDeveloperInterface() {
            return devInfoExt.getPlatformInfoObj().platform === PlatformType.DeveloperInterface;
        };
        /**
         * Will return true if we're on the Android Platform.
         * @returns {boolean}
         */


        PlatComp.prototype.isAndroid = function isAndroid() {
            return devInfoExt.getPlatformInfoObj().platform === PlatformType.Android;
        };


        /**
         * Will return true if we're on the Windows Platform.
         * @returns {boolean}
         */
        PlatComp.prototype.isWindows = function isWindows() {
            return devInfoExt.getPlatformInfoObj().platform === PlatformType.Windows;
        };
        /**
         * Will return true if we're on the Android Platform.
         * @returns {boolean}
         */


        PlatComp.prototype.isMacOS = function isMacOS() {
            return devInfoExt.getPlatformInfoObj().platform === PlatformType.Mac;
        };
        /**
         * Will return whether or not the current platform uses a software keyboard.
         * @returns {boolean}
         */


        PlatComp.prototype.usesSoftKeyboard = function usesSoftKeyboard() {
            var result = false;

            switch (devInfoExt.getPlatformInfoObj().platform) {
                case PlatformType.IOS:
                case PlatformType.Android:
                    result = true;
                    break;
                // Windows has a separate button to hide the SW keyboard. so it's okay.

                default:
                    break;
            }

            return result;
        };
        /**
         *
         * Will return true if the platform is Android or iOS.
         * @returns {boolean}
         */


        PlatComp.prototype.isMobileDevice = function isMobileDevice() {
            var isMobile = false;

            switch (devInfoExt.getPlatformInfoObj().platform) {
                case PlatformType.IOS:
                case PlatformType.Android:
                    isMobile = true;
                    break;

                default:
                    break;
            }

            return isMobile;
        };

        PlatComp.prototype.isIOS = function isIOS() {
            return devInfoExt.getPlatformInfoObj().platform === PlatformType.IOS;
        };
        /**
         * Will return true if the device is an iPhoneX
         * @returns {boolean}
         */


        PlatComp.prototype.isIphoneX = function isIphoneX() {
            // Attention:
            // iOS 11.1 and down doesn't support env() but constant()
            // iOS 11.2 and up doesn't support constant() but env()
            // Always use the bottom safe area to check if this is an iPhoneX. The top safeArea may not exist on WebClips
            var hasEnv = CSS.supports('padding-bottom: env(safe-area-inset-bottom)'),
                hasConstant = CSS.supports('padding-bottom: constant(safe-area-inset-bottom)');

            if (hasEnv || hasConstant) {
                var div = document.createElement('div'),
                    prefix = hasEnv ? "env" : "constant";
                div.style.paddingBottom = prefix + '(safe-area-inset-bottom)';
                document.body.appendChild(div);
                var calculatedPadding = parseInt(window.getComputedStyle(div).paddingBottom, 10);
                document.body.removeChild(div);

                if (calculatedPadding > 0) {
                    return true;
                }
            }

            return false;
        };
        /**
         * Will return true if the device is an iPad
         * @returns {boolean}
         */


        PlatComp.prototype.isIpad = function isIpad() {
            var deviceInfo = devInfoExt.getPlatformInfoObj();
            return deviceInfo.platform === PlatformType.IOS && deviceInfo.isTablet;
        };

        PlatComp.prototype.BiometricType = {
            FACE_ID: "faceID",
            TOUCH_ID: "touchID",
            UNKNOWN: "unknown",
            NONE: "none"
        };

        PlatComp.prototype.getBiometricType = function getBiometricType() {
            return window[names.ext].getPlatformInfoObj().biometricType;
        };

        PlatComp.prototype.getLicenses = function getLicenses() {
            var licences = Licences.general;
            var currPlatform = this.getPlatformInfoObj().platform;

            if (currPlatform === PlatformType.DeveloperInterface) {
                for (var platformKey in PlatformType) {
                    if (PlatformType.hasOwnProperty(platformKey) && Licences.hasOwnProperty(platformKey)) {
                        licences = licences.concat(Licences[platformKey]);
                    }
                }
            } else if (Licences[currPlatform].length > 0) {
                licences = licences.concat(Licences[currPlatform]);
            }

            return licences;
        };

        PlatComp.prototype.shouldDisplayCardsAsCell = function shouldDisplayCardsAsCell() {
            return $("body").width() < MIN_CARDS_DEVICE_WIDTH;
        };
        /**
         * Checks requirements for Features, as needed in InfoScreenComponent
         * @param {Array} reqirements
         * @returns {boolean}
         */


        PlatComp.prototype.evaluateRequirementsForFeature = function evaluateRequirementsForFeature(requirements) {
            var i = 0;
            var meetsRequirements = true;
            var Req = Requirements.environmentRequirements; //Use old-style for-loop to break

            for (i = 0; i < requirements.length; i++) {
                switch (requirements[i]) {
                    case Req.NO_WEBINTERFACE:
                        if (weakThis.isWebInterface) {
                            meetsRequirements = false;
                        }

                        break;

                    case Req.TEST:
                        meetsRequirements = false;
                        break;

                    case Req.WATCH:
                        if (this.isIOS() || this.isAndroid()) {
                            if (!WatchAdapter.hasWatch()) {
                                meetsRequirements = false;
                            }
                        } else {
                            meetsRequirements = false;
                        }

                        break;

                    case Req.WATCH_APP_INSTALLED:
                        if (this.isIOS()) {
                            if (!WatchAdapter.watchAppInstalled()) {
                                meetsRequirements = false;
                            }
                        } else {
                            meetsRequirements = false;
                        }

                        break;

                    default:
                        break;
                }

                if (!meetsRequirements) {
                    break;
                }
            }

            return meetsRequirements;
        };
// endregion

        window[names.ext] = new PlatComp(); // ------------------------------------------------
        // Detect language and pass it to moment & i18n
        // ------------------------------------------------

        var platformLanguage = window[names.ext].getLanguage(); // ensure the parts are capitalized properly, e.g: en-US instead of en-us, EN-us,..

        var langParts = platformLanguage.split("-");
        langParts[0] = langParts[0].toLowerCase();

        if (langParts.length > 1) {
            langParts[1] = langParts[1].toUpperCase();
        }


        // Setting the correct Numbro Locale based on our apps language
        let [lang, region] = langParts,
            possibleNumbroLanguages = [
                lang
            ],
            availableNumbroLanguages = Object.keys(numbro.languages()),
            matchingLanguages = [];

        if (region) {
            possibleNumbroLanguages.push(`${lang}-${region.toUpperCase()}`);
        }
        possibleNumbroLanguages.push(`${lang}-${lang.toUpperCase()}`);
        possibleNumbroLanguages.push("en-GB");

        matchingLanguages = possibleNumbroLanguages.filter(language => {
            return availableNumbroLanguages.includes(language);
        });

        if (matchingLanguages.length) {
            numbro.setLanguage(...matchingLanguages);
        } else {
            numbro.setLanguage(""); // set the language to numbros default language
        }

        platformLanguage = langParts.join("-"); // detect possible fallback language: e.g. de instead of de-AT

        var options = {
            debug: Debug.Translations,
            compatibilityJSON: "v3" // https://www.i18next.com/misc/migration-guide#v-20-x-x-to-v-21-0-0
        };

        if (platformLanguage.indexOf("-") > -1) {
            // it contains a country code - add the base language to the default languages
            //console.log("language including locale " + platformLanguage);
            var defLanguage = platformLanguage.split("-")[0].toLowerCase();
            options.fallbackLng = [defLanguage, "en", "dev"];
        } else {
            options.fallbackLng = ["en", "dev"];
        }

        options.lng = platformLanguage;

        initTranslations(options).then(() => {
            //Localize Mobiscroll
            var timeWheels = "HHii";
            var timeFormat = "HH:ii"; //Appending "A" if country uses it.

            if (useAmPm()) {
                timeWheels = "hhiiA";
                timeFormat = "hh:ii A";
            }

            $.mobiscroll.i18n[langParts[0]] = $.extend($.mobiscroll.i18n[langParts[0]], {
                // Core
                setText: _("mobiscroll.set"),
                cancelText: _("mobiscroll.cancel"),
                clearText: _("mobiscroll.clear"),
                selectedText: _("mobiscroll.selected"),
                // Datetime component
                dateFormat: 'dd.mm.yy',
                dateOrder: 'ddMyy',
                dayNames: [_("mobiscroll.sunday"), _("mobiscroll.monday"), _("mobiscroll.tuesday"), _("mobiscroll.wednesday"), _("mobiscroll.thursday"), _("mobiscroll.friday"), _("mobiscroll.saturday")],
                dayNamesShort: [_("mobiscroll.sunday-short"), _("mobiscroll.monday-short"), _("mobiscroll.tuesday-short"), _("mobiscroll.wednesday-short"), _("mobiscroll.thursday-short"), _("mobiscroll.friday-short"), _("mobiscroll.saturday-short")],
                dayText: _("mobiscroll.day"),
                hourText: _("mobiscroll.hour"),
                minuteText: _("mobiscroll.minute"),
                monthNames: [_("mobiscroll.january"), _("mobiscroll.february"), _("mobiscroll.march"), _("mobiscroll.april"), _("mobiscroll.may"), _("mobiscroll.june"), _("mobiscroll.july"), _("mobiscroll.august"), _("mobiscroll.september"), _("mobiscroll.october"), _("mobiscroll.november"), _("mobiscroll.december")],
                monthNamesShort: [_("mobiscroll.january-short"), _("mobiscroll.february-short"), _("mobiscroll.march-short"), _("mobiscroll.april-short"), _("mobiscroll.may-short"), _("mobiscroll.june-short"), _("mobiscroll.july-short"), _("mobiscroll.august-short"), _("mobiscroll.september-short"), _("mobiscroll.october-short"), _("mobiscroll.november-short"), _("mobiscroll.december-short")],
                monthText: _("mobiscroll.month"),
                secText: _("mobiscroll.seconds"),
                amText: 'am',
                pmText: 'pm',
                timeFormat: timeFormat,
                timeWheels: timeWheels,
                yearText: _("mobiscroll.year"),
                nowText: _("mobiscroll.now"),
                // Calendar component
                firstDay: 0,
                dateText: _("mobiscroll.date"),
                timeText: _("mobiscroll.time"),
                calendarText: _("mobiscroll.calendar"),
                closeText: _("mobiscroll.close"),
                // Daterange component
                fromText: _("mobiscroll.start"),
                toText: _("mobiscroll.end"),
                // Measurement components
                wholeText: _("mobiscroll.whole"),
                fractionText: _("mobiscroll.fraction"),
                unitText: _("mobiscroll.unit"),
                // Time / Timespan component
                labels: [_("mobiscroll.years"), _("mobiscroll.months"), _("mobiscroll.days"), _("mobiscroll.hours"), _("mobiscroll.minutes"), _("mobiscroll.seconds"), ''],
                labelsShort: [_("mobiscroll.years-short"), _("mobiscroll.months-short"), _("mobiscroll.days-short"), _("mobiscroll.hours-short"), _("mobiscroll.minutes-short"), _("mobiscroll.seconds-short"), ''],
                // Timer component
                startText: _("mobiscroll.start"),
                stopText: _("mobiscroll.stop"),
                resetText: _("mobiscroll.reset"),
                lapText: _("mobiscroll.lap"),
                hideText: _("mobiscroll.hide")
            });

            [
                platformLanguage.toLowerCase(),
                langParts[0],
                ...options.fallbackLng
            ].filter(lang => lang !== "dev").every(lang => {
                try {
                    moment.locale(lang);
                    return false;
                } catch (e) {
                    console.error(e)
                    return true;
                }
            })

            finishedInitialization.resolve("PlatformComponent successfully initialized!");
        })

        return window[names.ext];
    }]);

}
