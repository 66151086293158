'use strict';
/**
 * Handles everything that has to do with sending commands via Socket or HTTP - that does not include any UI related
 * topics.
 */

CommunicationComp.factory('CommandHandlerExt', function () {
    return class CommandHandler extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        /**
         * sends an HTTP Request without credentials, may return either a text or a binary.
         * @param command
         * @param host
         * @param [allowCache]  if true, a cache buster is not used. Rq. for the music server.
         * @param [automatic] true if the command was send automatically
         * @param [httpMethod] the preferred HTTP Method to send the command
         * @param [payload] optional Payload to send in the command
         * @param [rawCmd] used for logging, the unencrypted version of the cmd.
         * @param [rqFlags] e.g. noLLResponse, where the response will not be in {LL:{code,value}} format
         * @param [headerDict] used to provide custom headers to the request, e.g. for bearer authentication
         * @returns {*}
         */
        sendViaHttpUnauthorized(command, host, allowCache, automatic, httpMethod, payload, rawCmd, rqFlags = RQ_FLAGS_DEFAULT, headerDict = null) {
            Debug.CommandsHTTP && console.log("sendViaHttpUnauthorized: " + host + " command: " + (rawCmd ? rawCmd : command));
            var preparedHost = this._prepareHttpHostUrl(host);
            if (!httpMethod) {
                httpMethod = HTTP_METHODS.GET;
            }

            let fullRequest = this._prepareHttpRequestUrl(preparedHost, command, allowCache);
            Debug.CommandsHTTP && console.log("App->Miniserver HTTP", rawCmd ? "ENC: " + rawCmd : command);
            Debug.Communication && CommTracker.commSent(CommTracker.Transport.HTTP, rawCmd || command, fullRequest);

            var def = Q.defer();
            var isBinary = false;
            const xhr = new XMLHttpRequest();
            xhr.open(httpMethod, fullRequest, true);

            if (headerDict) {
                Object.keys(headerDict).forEach(headerKey => {
                    xhr.setRequestHeader(headerKey, headerDict[headerKey])
                });
            }

            xhr.onreadystatechange = () => {

                switch (xhr.readyState) {
                    case XMLHttpRequest.OPENED:
                        if (!allowCache) {
                            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, must-revalidate");
                        }
                        break;
                    case XMLHttpRequest.HEADERS_RECEIVED:
                        let respType = xhr.getResponseHeader("content-type");
                        Debug.CommandsHTTPFullLength && console.log("*Miniserver->App HTTP-Header: " + (rawCmd || command) + " --> ContentType = " + respType);
                        switch (respType) {
                            case "application/octet-stream":
                                xhr.responseType = "blob";
                                isBinary = true;
                                break;
                            default:
                                xhr.responseType = "text";
                                break;
                        }
                        break;
                    default:
                        break;
                }
            };
            xhr.onprogress = (evt) => {
                if (evt.lengthComputable) {
                    def.notify(evt.loaded / evt.total);
                }
            };
            xhr.onloadend = (event) => {
                if (xhr.status === 200) {
                    Debug.CommandsHTTP && console.log((rawCmd || command) + " success");
                    if (isBinary) {
                        this._handleBinaryResponse(def, xhr, automatic);
                    } else {
                        this._handleTextResponse(def, xhr, automatic, rqFlags);
                    }
                } else {
                    console.error((rawCmd || command) + " failed! " + xhr.status);
                    this._handleErrorResponse(def, xhr, automatic, "");
                }
            };
            xhr.onerror = (event) => {
                console.error((rawCmd || command) + " onerror! " + xhr.status);
                this._handleErrorResponse(def, xhr, automatic, "error");
            }
            if (httpMethod === HTTP_METHODS.POST) {
                xhr.send(payload);
            } else {
                xhr.send();
            }
            return def.promise;
        }

        _prepareHttpRequestUrl(host, cmd, cacheAllowed = false) {
            var fullStr = host + cmd;
            if (!cacheAllowed) {
                try {
                    let url = new URL(fullStr);
                    let rndNr = "" + getRandomIntInclusive(0, 10000);
                    if (!url.searchParams.get('noCacheRnd')) {
                        url.searchParams.set('noCacheRnd', rndNr);
                        fullStr = url.toString();
                    }

                } catch (ex) {
                    console.error(this.name, "Failed to _prepareHttpRequestUrl: " + fullStr);
                }
            }
            return fullStr;
        }

        _prepareHttpHostUrl(host) {
            var preparedHost;
            try {
                preparedHost = host || ActiveMSComponent.getCurrentUrl();
            } catch (ex) {
                console.error("Could not determine the host to contact! " + JSON.stringify(ex));
                throw ex; // re-throw it.
            }

            if (!preparedHost.hasPrefix("http://") && !preparedHost.hasPrefix("https://")) {
                preparedHost = CommunicationComponent.getRequestProtocol() + preparedHost;
            }

            if (!preparedHost.hasSuffix("/")) {
                preparedHost = preparedHost + "/";
            }
            return preparedHost;
        }

        _handleBinaryResponse(def, xhr, automatic) {
            const blob = xhr.response;
            Debug.CommandsHTTP && console.log("Miniserver->App HTTP (Binary)", blob.size + " bytes");
            blob.arrayBuffer().then((buffer) => {
                def.resolve(buffer);
            }, (err) => {
                console.error((rawCmd || command) + " failed to get arrayBuffer! " + xhr.status);
                this._handleErrorResponse(def, xhr, automatic, err);
            });
        }

        /**
         *
         * @param def
         * @param xhr
         * @param automatic
         * @param [rqFlags] e.g. noLLResponse, where the response will not be in {LL:{code,value}} format
         * @private
         */
        _handleTextResponse(def, xhr, automatic, rqFlags = RQ_FLAGS_DEFAULT) {
            Debug.CommandsHTTP && console.log("CommandHandlerExt", "_handleTextResponse (flags=" + JSON.stringify(rqFlags) + ")");
            let responseText = xhr.responseText
            if (Debug.CommandsHTTP) {
                if (responseText.length > 1000 && !Debug.CommandsHTTPFullLength) {
                    console.log("Miniserver->App HTTP (Shortened)", responseText.substring(0, 40) + " ... " + responseText.substring(responseText.length - responseText, responseText.length - 1));
                } else {
                    console.log("Miniserver->App HTTP", responseText);
                }
            }

            try {
                responseText = JSON.parse(responseText);
            } catch (e) {// Noting to do here everything is handled below
            }

            if (typeof responseText === "object" && responseText.LL) {
                var code = getLxResponseCode(responseText);

                if (code >= 200 && code < 300) {
                    def.resolve(responseText);
                } else {
                    def.reject(responseText);
                }
            } else {
                def.resolve(responseText);
            }
        }

        _handleErrorResponse(def, xhr, isAutomatic, errorThrown) {
            let errStatus = xhr.status;
            let textStatus = xhr.statusText;
            if (!isAutomatic && errStatus === ResponseCode.SOCKET_LIMIT_EXCEEDED) {
                NavigationComp.showPopup({
                    title: _('miniserver.waiting.no-event-slots-left.popup.title'),
                    message: _('miniserver.waiting.no-event-slots-left.popup.message'),
                    buttonOk: _('ok'),
                    buttonCancel: false,
                    color: window.Styles.colors.orange
                }, PopupType.GENERAL);
            } else {
                if (textStatus === 'timeout') {
                    def.reject(new Error("The request timed out!"));
                } else {
                    def.reject(errStatus);
                }
            }
        }

        /**
         * sends an HTTP request to the Miniserver (supported since version xxx)
         * with auth parameter
         * @param command
         * @param encryptionType type of encryption for this command
         * @param [automatic] true if the command was send automatically
         * @param [httpMethod] defaults to get
         * @param [payload] e.g. for post requests
         * @param [rqFlags]  e.g. noLLResponse, where the response will not be in {LL:{code,value}} format
         * @returns {*}
         */
        sendViaHTTP(command, encryptionType, automatic, httpMethod, payload, rqFlags = RQ_FLAGS_DEFAULT) {
            let credentials = ActiveMSComponent.getCurrentCredentials();
            if (credentials && JSON.stringify(credentials) !== "{}") {
                return this._sendViaHTTPWithToken(credentials.token, command, encryptionType, automatic, httpMethod, payload, rqFlags);
            } else {
                return Q.reject(new Error("Failed to obtain credentials!"));
            }
        }

        sendViaHTTPWithPermission(command, permission, encryptionType, automatic, httpMethod, payload) {
            return SandboxComponent.getPermission(permission).then(function () {
                var tokenObj = CommunicationComponent.getToken(permission);
                return this._sendViaHTTPWithToken(tokenObj.token, command, encryptionType, automatic, httpMethod, payload);
            }.bind(this));
        }

        /**
         *
         * @param token
         * @param command
         * @param [encryptionType]
         * @param automatic
         * @param [httpMethod] defaults to get
         * @param [payload] defaults to null, for post requests
         * @param [rqFlags]  e.g. noLLResponse, where the response will not be in {LL:{code,value}} format
         * @returns {*}
         * @private
         */
        _sendViaHTTPWithToken(token, command, encryptionType, automatic, httpMethod, payload, rqFlags = RQ_FLAGS_DEFAULT) {
            Debug.CommandsHTTP && console.log("sendViaHTTP (encType: " + encryptionType + "): " + command);
            var adoptedCmd = command;

            if (Feature.SECURE_HTTP_REQUESTS) {
                Debug.CommandsHTTP && console.log(" - use HTTP"); // everything is supported, go on

                return this.sendViaHttpUnauthorized(Commands.GET_KEY, null, null, automatic).then(function (res) {
                    Debug.CommandsHTTP && console.log(" - got key");
                    var key = res.LL.value,
                        creds = ActiveMSComponent.getCurrentCredentials(),
                        hashAlg = VendorHub.Crypto.getHashAlgorithmForMs(),
                        hash;
                    encryptionType = checkEncryptionTypeForHttp(encryptionType, command); // we might already have a token, if so - use it!

                    if (token) {
                        hash = VendorHub.Crypto["Hmac" + hashAlg](token, "utf8", key, "hex", "hex");
                        adoptedCmd = adoptedCmd + "?autht=" + hash;
                        Debug.CommandsHTTP && console.log(" - authenticate using token: " + adoptedCmd);
                    } else {
                        hash = VendorHub.Crypto["Hmac" + hashAlg](creds.username + ":" + creds.password, "utf8", key, "hex", "hex");
                        adoptedCmd = adoptedCmd + "?auth=" + hash;
                        Debug.CommandsHTTP && console.log(" - authenticate using password: " + adoptedCmd);
                    }

                    if (usesEncryption(encryptionType) && Feature.ENCRYPTED_CONNECTION) {
                        if (Feature.ENCRYPTED_CONNECTION_HTTP_USER) {
                            adoptedCmd = adoptedCmd + "&user=" + encodeURIComponent(creds.username);
                        }

                        return this.sendEncryptedHttpCmd(adoptedCmd, encryptionType, httpMethod, payload, command, rqFlags);
                    } else {
                        adoptedCmd = adoptedCmd + "&user=" + encodeURIComponent(creds.username);
                        return this.sendViaHttpUnauthorized(adoptedCmd, null, null, null, httpMethod, payload, command);
                    }
                }.bind(this));
            } else {
                Debug.CommandsHTTP && console.log(" - use WebSocket, HTTP not supported"); // send over websocket

                return this.component.send(command);
            }
        }

        /**
         * Will send the command provided with the (optional) encryption type provided as HTTP request to the Miniserver.
         * The resulting response will be decrypted and parsed already.
         * @param command           the command to send to the server
         * @param encryptionType    optional encryption type. Default = request and response.
         * @param [httpMethod]
         * @param [payload]
         * @param [rawCmd]          used for logging purposes
         * @param [rqFlags]         used to pass on infos, such as noLLResponse, indicating that the response is not in the {LL: {code, value}} format.
         */
        sendEncryptedHttpCmd(command, encryptionType, httpMethod, payload, rawCmd, rqFlags = RQ_FLAGS_DEFAULT) {
            Debug.CommandsHTTP && console.log("CommandHandlerExt", "sendEncryptedHttpCmd " + rawCmd);
            return this._getEncryptedHttpCmd(command, encryptionType).then(function (encObj) {
                return this.sendViaHttpUnauthorized(encObj.command, null, false, null, httpMethod, payload, rawCmd || command, rqFlags).then(function (response) {
                    if (!encryptionType || encryptionType === EncryptionType.REQUEST_RESPONSE_VAL) {
                        return this._decryptResponse(response, encObj.aesKey, encObj.aesIV, rqFlags);
                    } else {
                        return response;
                    }
                }.bind(this));
            }.bind(this));
        }

        sendEncryptedHttpCmdToHost(host, command, encryptionType, { automatic, httpMethod, payload, rqFlags } = {}) {
            Debug.CommandsHTTP && console.log("CommandHandlerExt", "sendEncryptedHttpCmdToHost: " + host + ", cmd=" + command);
            return this._getEncryptedHttpCmd(command, encryptionType, host).then(function (encObj) {
                return this.sendViaHttpUnauthorized(encObj.command, host, false, null, httpMethod, payload, command, rqFlags)
                    .then(function (response) {
                    if (!encryptionType || encryptionType === EncryptionType.REQUEST_RESPONSE_VAL) {
                        return this._decryptResponse(response, encObj.aesKey, encObj.aesIV, rqFlags);
                    } else {
                        return response;
                    }
                }.bind(this));
            }.bind(this));
        }

        /**
         * Will send an encrypted http request that is authorized with the token provided.
         * @param cmd
         * @param token
         * @param user
         * @param [rqFlags] e.g. noLLRepsonse = if true, the response will not be wrapped into an LL object, but returned raw.
         */
        sendViaHTTPWithToken(cmd, token, user, rqFlags = RQ_FLAGS_DEFAULT) {
            var fullCmd;
            return this.component.getSaltedHash(token).then(function (saltedHash) {
                fullCmd = cmd + "?" + Commands.format(Commands.TOKEN.AUTH_ARG, saltedHash, encodeURIComponent(user));
                return this.sendEncryptedHttpCmd(fullCmd, EncryptionType.REQUEST_RESPONSE_VAL, null, null, cmd, rqFlags);
            }.bind(this));
        }

        /**
         * Will decrypt & parse the response provided using the key and IV provided. If not decryptable it will throw an
         * exception. It will also throw an exception if the result was successfully decrypted but the response code
         * indicates an error.
         * @param response
         * @param aesKey
         * @param aesIV
         * @param [rqFlags] optional, may provide infos such as noLLResponse, if true means it's not returned in {LL: {value, code}}
         * @returns {*}
         */
        _decryptResponse(response, aesKey, aesIV, rqFlags = RQ_FLAGS_DEFAULT) {
            var code;
            Debug.Encryption && console.log("encrypted response base64: " + response);
            Debug.Encryption && console.log("encrypted response hex: " + CryptoJS.enc.Base64.parse(response).toString(CryptoJS.enc.Hex));
            response = VendorHub.Crypto.aesDecrypt(response, aesKey, aesIV);
            Debug.Encryption && console.log("decrypted response: " + response);
            response = JSON.parse(response);
            code = getLxResponseCode(response);

            if (rqFlags.noLLResponse) {
                return response;
            } else if (code >= ResponseCode.OK && code < ResponseCode.BAD_REQUEST) {
                return response;
            } else {
                throw response; // will reject the promise
            }
        }

        /**
         * Will acquire the public key and then format the command so it's well defined and encrypted so it can be sent
         * over http in a secure way.
         * @param command
         * @param [encryptionType]  if full encryption or command only encryption is to be used. Full = default
         * @param [host] if used, it will grab the PB from the host provided
         * @returns {*}
         */
        _getEncryptedHttpCmd(command, encryptionType, host = null) {
            return ActiveMSComponent.getPublicKey(host).then(function (publicKey) {
                Debug.Commands && console.log("App->Miniserver HTTP-enc", command);
                return getEncryptedCommand(command, publicKey, encryptionType);
            }.bind(this));
        }

    };
});
