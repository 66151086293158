"use strict";

import {
    useDeviceOrientation
} from "LxComponents";
import globalStyles from "GlobalStyles";

let CompReqCtx = {
    Communication: {
        ECEvent: {
            ConnEstablished: "ConnEstablished",
            StructureReady: "StructureReady",
            ReachabiltyConfirmed: "ReachabilityConfirmed",
            // called as soon as the reachability check confirms (before conn established)
            ConnClosed: "ConnClosed",
            StopMSSession: "StopSession",
            Pause: "Pause",
            Resume: "Resume",
            NetworkChanged: "NetworkChanged",
            DownloadingLargeData: "DownloadingLargeData",
            TokenReceived: "TokenReceived",
            // called when a new token was received
            TokenConfirmed: "TokenConfirmed",
            // called when authentication using a token was successful
            InvalidToken: "InvalidToken",
            // called when authenticate using token fails
            NoMoreEventSlots: "NoMoreEventSlots" // called when no more event slots are available

        },
        comp: require.context("./CommunicationComp", false, /CommunicationComp.js$/),
        exts: require.context("./CommunicationComp/extensions", false, /\.js$/)
    },
    Utility: {
        comp: require.context("./UtilityComp", false, /UtilityComp.js$/)
    },
    ActiveMS: {
        ECEvent: {
            StartMSSession: "StartSession",
            ConnEstablished: "ConnEstablished",
            ConnClosed: "ConnClosed",
            StopMSSession: "StopSession",
            AppInitInfoReady: "AppInitInfoReady",
            STRUCTURE_UPDATE: "StructureUpdate",
            // update packet
            STRUCTURE_READY: "StructureReady",
            // the structure is ready - args: newStructure
            MESSAGE_CENTER_UPDATE: 'MessageCenterUpdate',
            // The MessageCenter structure itself
            MESSAGE_CENTER_SPECIFIC_UPDATE: 'MessageCenterSpecificUpdate-',
            // The entries referenced to the UUID after the "-" NOTE: the specific UUID will be added after the "-" when registering for this event
            REACHABILITY_UPDATE: CCEvent.REACHABILITY_UPDATE,
            TRUST_STRUCTURE_UPDATED: "TrustStructureUpdated"
        },
        comp: require.context("./ActiveMSComp", false, /ActiveMSComp.js$/),
        exts: require.context("./ActiveMSComp/extensions", false, /\.js$/)
    },
    Platform: {
        comp: require.context("./PlatformComp", false, /PlatformComp.js$/),
        exts: require.context("./PlatformComp/extensions", false, /\.js$/)
    },
    LxServer: {
        ECEvent: {
            NewsChanged: 'NewsChanged'
        },
        comp: require.context("./LxServerComp", false, /LxServerComp.js$/),
        exts: require.context("./LxServerComp/extensions", false, /\.js$/)
    },
    Sandbox: {
        comp: require.context("./SandboxComp", false, /SandboxComp.js$/),
        exts: require.context("./SandboxComp/extensions", false, /\.js$/),
        ECEvent: {
            ConnReady: "ConnReady",
            ConnClosed: "ConnClosed",
            Reset: "Reset",
            ALL_STATES_RECEIVED: "AllStatesReceived",
            // TaskRecorder
            TaskRecorderStart: "TaskRecorderStart",
            TaskRecorderEnd: "TaskRecorderEnd",
            CommandForTaskRecorder: "CommandForTaskRecorder",
            // Notifications
            NotificationReceived: "NotificationReceived",
            // notification
            UnreadNotificationCount: "NotificationCount",
            // unreadCount
            ForceStateUpdate: "ForceStateUpdate", // used e.g. when the Dark/Light mode was activated

            DeviceActivityChanged: "DeviceActivityChanged", // Used to inform on when activity has been detected or not (on the device)
            ActivityTick: "ActivityTick",
            AmbientModeSettingChanged: "AmbientModeSettingChanged", // new ambient mode setting modified
            EcoScreenSettingChanged: "2023EcoScreenSettingChanged", // new eco screen setting modified
        }
    },
    Persistence: {
        comp: require.context("./PersistenceComp", false, /PersistenceComp.js$/),
        exts: require.context("./PersistenceComp/extensions", false, /\.js$/)
    },
    InfoScreen: {
        comp: require.context("./InfoScreenComp", false, /InfoScreenComp.js$/),
        exts: require.context("./InfoScreenComp/extensions", false, /\.js$/)
    },
    AutomaticDesigner: {
        comp: require.context("./AutomaticDesignerComp", false, /AutomaticDesignerComp.js$/),
        exts: require.context("./AutomaticDesignerComp/extensions", false, /\.js$/)
    },
    PairedApp: {
        comp: require.context("./PairedAppComp", false, /PairedAppComp.js$/),
        exts: require.context("./PairedAppComp/extensions", false, /\.js$/)
    },
    require(compObj) {
        let { comp, exts } = compObj;
        if (comp) {
            comp.keys().forEach(key => {
                let compRes,
                    compName = this.getModuleName(key),
                    intName = CompReqCtx.getInternalName(compName),
                    extName = CompReqCtx.getExternalName(compName)

                if (!(extName in window)) {
                    compRes = comp(key);

                    if (compRes.__esModule && "default" in compRes) {
                        compRes.default({
                            names: {
                                int: intName,
                                ext: extName
                            }
                        });
                    }
                    if (exts) {
                        exts.keys().forEach(exts);
                    }
                } else {
                    // Comp already initialized!
                }
            });
        }
    },
    getExtensions({ exts }) {
        let extensions = [],
            modules = [];

        if (exts) {
            modules = exts.keys();
        }

        modules.forEach(module => {
            extensions.pushIfNoDuplicate(`${this.getModuleName(module)}`);
        });

        return extensions;
    },
    getInternalName(compKey) {
        return `${compKey}Comp`;
    },
    getExternalName(compKey) {
        return `${compKey}Component`;
    },
    getModuleName(module) {
        return module.split("/").pop().replace(".js", "").replace("Comp", "");
    }
}
let compNames = Object.keys(CompReqCtx);
compNames.forEach(property => {
    let intName = CompReqCtx.getInternalName(property);

    if (typeof CompReqCtx[property] !== "function" && !(intName in window)) {
        window[intName] = angular.extend(angular.module(intName, []), {
            extensions: CompReqCtx.getExtensions(CompReqCtx[property])
        });
        if ("ECEvent" in CompReqCtx[property]) {
            window[intName].ECEvent = CompReqCtx[property].ECEvent;
        }
    }
});
compNames.forEach(property => {
    if (typeof CompReqCtx[property] !== "function") {
        CompReqCtx.require(CompReqCtx[property]);
    }
});

(function () {
    /**
     * tell angular to get things started
     */
    var initializeApp = async function () {
        if (!window.electron) {
            // Fixes stuck promise chains on iOS 14 devices: https://github.com/kriskowal/q/issues/849
            // Note: Electron is node.js based, as Node.js uses a different tick system we can not use this code
            //       as it would lead to broken Q.js promises.
            // We use the Q.js in Cordova and in the Webinterface and Q.js from npm for Electron based apps
            // the npm Q.js is more up to date and does not support Vanilla Javascript any more thus we need to use both from npm and bower
            Q.nextTick = function (fn) {
                Promise.resolve().then(fn);
            };
        }

        triggerEvent(document, "initializeApp");
        angular.bootstrap(document, ["lxApp"]); // start fastclick.js

        addEvent("load", window, function () {
            FastClick.attach(document.body);
        }); // Keep in mind to not load any Require stuff before this line!


        // init NFC, needs to be initialized immediately because of URL Start!
        window.nfcService = new NFCService(); // start tracking usage

        VendorHub.Usage.initialize();

        // platform component must be initialized to go on with app initialization
        await PlatformComponent.getInitializationPromise();
        await PersistenceComponent.getLoadingPromise();

        // init Components
        window.MediaServerComp = new Components.MediaServer.Init();
        window.NavigationComp = new Components.Navigation.Init();
        window.UpdateComp = new Components.Update.Init();
        window.InfoScreenComp = new Components.InfoScreenComp.Init();
        var platform = PlatformComponent.getPlatformInfoObj().platform;
        window.QuickActionUtility = new QuickActionUtility();
        window.LoxoneControl = new LoxoneControl();
        window.AppBranding = new AppBranding();
        window.BiometricHelper = new BiometricHelper();
        window.MessageCenterHelper = new MessageCenterHelper();
        window.CommTracker = new CommTracker();

        if (platform === PlatformType.IOS || platform === PlatformType.Android) {
            WatchAdapter = new WatchAdapter();
        }

        // without deps
        // window.lxWorker = new LxWebWorker();
        // with deps

        try {
            let workerFiles = [
                    "scripts/legacy/enums.js?worker=true",
                    "scripts/SandboxComp/StatisticExt/statistic.js?worker=true",
                    "scripts/SandboxComp/StatisticV2Ext/statistic.js?worker=true",
                    "scripts/UtilityComp/LxUtils.js?worker=true"
                ],
                basePath = location.origin;
            if (PlatformComponent.isElectron) {
                basePath = document.baseURI.replace("/index.html#", "");
            }
            window.lxWorker = new LxWebWorker(workerFiles.map(relPath => {
                return `${basePath}/${relPath}`
            }));
        } catch (e) {
            // If there is an error we inititalize the LxWebWorker without any dependencies
            // We also disable WebWorkers
            window.workerAvailable = false;
            window.lxWorker = new LxWebWorker();
            console.error(e);
        }

        if (platform === PlatformType.Android || platform === PlatformType.IOS) {
            pushNotificationService.initPushNotifications().done(function () {
                console.info("PushNotifications up and running");
            }, function (e) {
                console.info(e); // isn't an error really (not used or not available)
            });
        }

        window.addEventListener('keyup', function (e) {
            if (e.keyCode === 27) {
                // Escape
                NavigationComp.navigateBackQueue();
            }
        }, false);
        GUI.LxVirtualKeyboard = new GUI.LxVirtualKeyboard();

        if (window.electron) {
            // Will hide the pointer if the argument "--noPointer" is given
            if (window.electron.remote.argv.indexOf("--noPointer") !== -1) {
                $(document.body).addClass("no-pointer");
            }

            if (window.electron.remote.argv.indexOf("--zoom") !== -1) {
                var zoomIdx = window.electron.remote.argv.indexOf("--zoom");
                $(document.body).css("zoom", window.electron.remote.argv[zoomIdx + 1]);
            }
        }


        if (GUI.DebugScreen.enabled) {
            GUI.DebugScreen = new GUI.DebugScreen();

            var registerDebugging = function registerDebugging() {
                if (platform === PlatformType.Android || platform === PlatformType.IOS) {
                    GUI.DebugScreen.registerShake();
                } else {
                    GUI.DebugScreen.registerKeypress();
                }
            };

            registerDebugging();
            CompChannel.on(CCEvent.Resume, function () {
                registerDebugging();
            });
            CompChannel.on(CCEvent.Pause, function () {
                if (platform === PlatformType.Android || platform === PlatformType.IOS) {
                    GUI.DebugScreen.unregisterShake();
                } else {
                    GUI.DebugScreen.unregisterKeypress();
                }
            });
        }

        EntryPointHelper.init();

        /*
         We need this to detect if the device we run on has the canvas error described in Wrike:
         https://www.wrike.com/open.htm?id=80220444
         hasCanvasError is a global Variable
         */


        var version = parseFloat(PlatformComponent.getPlatformInfoObj().version);
        var isAndroid = platform === PlatformType.Android;
        var isNexusDevice = PlatformComponent.getPlatformInfoObj().model.indexOf("Nexus") !== -1;
        var isInErrorVersion = version >= 5.0;
        hasCanvasError = isAndroid && isNexusDevice && isInErrorVersion; // check if it's a slow device

        if (platform === PlatformType.Android && version < 5 || platform === PlatformType.IOS && version < 10 //platform === PlatformType.DeveloperInterface || // only for testing purposes
        ) {
            slowDevice = true;
        }

        if ((platform === PlatformType.IOS || platform === PlatformType.Android) && slowDevice) {
            CompChannel.on(CCEvent.Resume, function () {
                checkLegacyDrawingBug();
            });
        } // Register for usage tracking


        CompChannel.on(CCEvent.Resume, function () {
            VendorHub.Usage.newSession(CCEvent.Resume);
        });
        VendorHub.Usage.newSession("Start"); // For automatic Dark- LightMode switch

        /*window.matchMedia("(prefers-color-scheme: dark)").addListener(function(e) {
            NavigationComp.setDarkModeState(e.matches);
        });
        window.matchMedia("(prefers-color-scheme: light)").addListener(function(e) {
            NavigationComp.setDarkModeState(!e.matches);
        });*/


        PairedAppComponent.getPairingInfo().then(({isPaired, pairingInfo}) => {
            Debug.PairedApp && console.log("PairedApp", "ready to boot, isPaired = " + isPaired);

            NavigationComp.showInitialView(pairingInfo);

            if (!HD_APP) {
                useDeviceOrientation().set(DeviceOrientation.PORTRAIT_PRIMARY);
            }

            if (PlatformComponent.isCordova && "CordovaPluginMemoryAlert" in cordova.plugins) {
                cordova.plugins.CordovaPluginMemoryAlert.activate(true);

                if (Debug.MEMORY) {
                    addEvent("cordova-plugin-memory-alert.memoryWarning", window, () => {
                        console.warn("⚠️ applicationDidReceiveMemoryWarning ⚠️");

                        if (!window.lowMemNotification) {
                            window.lowMemNotification = GUI.Notification.createGeneralNotification({
                                title: "Low Memory Warning",
                                subtitle: "Click here to capture DebugLog",
                                iconSrc: Icon.WARNING,
                                iconColor: globalStyles.colors.orange,
                                clickable: true
                            }, NotificationType.WARNING);
                            window.lowMemNotification.on(GUI.Notification.CLICK_EVENT, function () {
                                GUI.DebugScreen.show();
                            }.bind(this));
                            setTimeout(function () {
                                window.lowMemNotification.remove();
                                delete window.lowMemNotification;
                            }, 10 * 1000);
                        }
                    });
                }
            } // Ensure everything is initialized, so initialize it last!

            RatingRequester = new RatingRequester();
            RatingRequester.start();
        })
    };

    if (window.cordova) {
        addEvent("deviceready", document, initializeApp);
    } else {
        addEvent("DOMContentLoaded", document, initializeApp);
    }
})();

var Hub = angular.module("lxApp", [
    "CommunicationComp", "ActiveMSComp", "VendorHub", "PlatformComp", "PersistenceComp", "SandboxComp", "UtilityComp",
    "LxServerComp", "AutomaticDesignerComp", "PairedAppComp"]);
window.CompChannel = new Observer();
/**
 * Runs through all components and instantiates them
 */

Hub.run(["$injector", function ($injector) {
    var dependencies = Hub.requires;

    for (var i = 0; i < dependencies.length; i++) {
        if (dependencies[i].indexOf("Comp") > 0) {
            $injector.get(dependencies[i]);
        }
    }
}]);

/**
 * initializes the extension
 * @param Ext c-tor of extension
 * @param comp component
 * @param [arg] optional argument to pass on with the constructor
 * @returns {*} Extension
 */
window.initExtension = function initExtension(Ext, comp, arg) {
    let extension = new Ext(comp, comp, arg); // pass in the component and the channel (the comp is the channel)
    extension.component = comp;
    extension.channel = comp;
    return extension;
};
