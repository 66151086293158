'use strict';

import Icons from "IconLib";

/**
 * Handles the items displayed in the menu tab of the loxone app.
 */

SandboxComp.factory('MenuItemsExt', function () {
    var DEFAULT_MENU_CELL_TYPE = GUI.TableViewV2.CellType.HALO_ICON,
        DEFAULT_MENU_CELL_SEARCH_TYPE = GUI.TableViewV2.CellType.GENERAL;
    return class MenuItems extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            var platform = PlatformComponent.getPlatformInfoObj().platform;
            this.isAndroid = platform === PlatformType.Android;
            this.isWebinterface = platform === PlatformType.Webinterface;
            this.isDeveloperInterface = platform === PlatformType.DeveloperInterface;
        }

        /**
         * Will return an array containing all the items displayed within the menu, these will be used for building
         * up a search index & using those items from search right away.
         * @param hasWeather    whether or not the weather service is registered (=receives proper data)
         * @param showStateFn   the function using which screenstates are to be shown.
         * @return {Array}
         */
        getMenuItems(hasWeather, showStateFn) {
            var result = [];

            if (!SandboxComponent.isRecordingTask()) {
                result = result.concat(this.getBurgerMenuFirstSectionItems(hasWeather, showStateFn));
                result = result.concat(this.getBurgerMenuSecondSectionItems(showStateFn));
                !PairedAppComponent.isPaired() && result.pushObject(this._getDisconnectItem(showStateFn));
                result.pushObject(this.getAboutApp(showStateFn));
                result.pushObject(this.getAboutLoxone(showStateFn));
                result.pushObject(this.getPartnerBrandingItem(showStateFn));
                result.pushObject(this.getRebootItem(showStateFn));
                result.pushObject(this._getAboutMiniserverItem(showStateFn));

                this._applyDefaultCellSearchType(result);
            }

            return result;
        }

        getBurgerMenuFirstSectionItems(hasWeather, showStateFn) {
            var result = [];

            if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.DeveloperInterface) {
                result.pushObject(this._getTableViewShowcaseItem(showStateFn));
                result.pushObject(this._getReactDebugItem(showStateFn));
                result.pushObject(this._getTableDebugItem(showStateFn));
                result.pushObject(this._getTableViewDebugItem(showStateFn));
                result.pushObject(this._getIconLibShowcaseItem(showStateFn));
            }

            result.pushObject(this._getNewsHubItem(showStateFn));
            result.pushObject(SandboxComponent.getAmbientModeSettingsItem(showStateFn));
            result.pushObject(this.getAutopilotItem(showStateFn));
            result.pushObject(this.getScenesItem(showStateFn));
            result.pushObject(this.getBatteryMonitorItem(showStateFn));
            result.pushObject(this.getOperatingModeItem(showStateFn));
            if(Feature.GENERIC_DEVICE_SEARCH) {
                result.pushObject(this.getGenericDeviceSearchItem(showStateFn));
            } else {
                result.pushObject(this.getDeviceSearchItem(showStateFn));
                result.pushObject(this.getDeviceSearchTreeItem(showStateFn));
            }
            result.pushObject(this.getSystemStateItem(showStateFn));
            result.pushObject(this._getTaskRecorderItem(showStateFn));
            result.pushObject(this._getWeatherItem(hasWeather, showStateFn));
            return result;
        }

        getBurgerMenuSecondSectionItems(showStateFn) {
            var result = [],
                isPaired = PairedAppComponent.isPaired();

            if (!isPaired) {
                result.pushObject(SandboxComponent.getHomeKitItem(showStateFn));
                result.pushObject(SandboxComponent.getAlexaItem(showStateFn));
                result.pushObject(SandboxComponent.getNotificationItem(showStateFn));
                result.pushObject(SandboxComponent.getCurrentMiniserverUserItem(showStateFn));
            }

            // user management may still be a permission granted to the tablet
            result.pushObject(this.getUserManagementItem(showStateFn));

            if (!isPaired) {
                result.pushObject(SandboxComponent.getDeviceFavoritesSettingsItem(showStateFn));
                result.pushObject(SandboxComponent.getPresentationSettingsItem(showStateFn));
                result.pushObject(SandboxComponent.getEcoModeSettingsItem(showStateFn));
                result.pushObject(SandboxComponent.getEntryPointSelectionItem(showStateFn));
                result.pushObject(SandboxComponent.getLoxoneControlMenuBar(showStateFn));
                result.pushObject(this._getPresenceDetectionItem(showStateFn));
                result.pushObject(this.getNFCSmartTagItem(showStateFn));
                result.pushObject(this.getQRItem(showStateFn));
                result.pushObject(this.getQuickActionsItem(showStateFn));
                result.pushObject(SandboxComponent.getSecurityItem(showStateFn));
                result.pushObject(SandboxComponent.getBackupAndSyncItem(showStateFn));
            }

            return result;
        }

        getMenuSettingsSectionItems(showStateFn) {
            if (PairedAppComponent.isPaired()) {
                return [];
            }
            var result = [];
            result.pushObject(SandboxComponent.getSecurityItem(showStateFn));
            result.pushObject(SandboxComponent.getLoxoneControlMenuBar(showStateFn));
            result.pushObject(SandboxComponent.getPresentationSettingsItem(showStateFn));

            this._applyDefaultCellType(result);

            return result;
        }

        /// -----------------------------------------------------------------
        /// ----------- Individual Menu Categories --------------------------
        /// -----------------------------------------------------------------
        getAboutItems(showStateFn) {
            var result = [];
            result.pushObject(this.getAboutApp(showStateFn));
            result.pushObject(this.getAboutLoxone(showStateFn));

            this._applyDefaultCellType(result);

            return result;
        }

        /// ----------------------------------------------------------------
        /// --------- Public Individual Items ------------------------------
        /// ----------------------------------------------------------------
        getOperatingModeItem(showStateFn) {
            if (Feature.EDITABLE_OPERATING_MODES && !SandboxComponent.isRecordingTask() && SandboxComponent.checkPermission(MsPermission.OP_MODES)) {
                return {
                    action: this._showState.bind(this, ScreenState.OperatingMode.MainScreen, showStateFn),
                    uuid: ScreenState.OperatingMode.MainScreen,
                    content: {
                        leftIconSrc: Icon.Menu.OP_MODE_SCHEDULE,
                        reactLeftIconSrc: Icons.Clock,
                        title: _("opmodes.schedule.name"),
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand,
                        clickable: true
                    }
                };
            } else {
                return null;
            }
        }

        getAutopilotItem(showStateFn) {
            if (!SandboxComponent.isRecordingTask() && SandboxComponent.isAutopilotAvailable()) {
                if (Feature.AUTOMATIC_DESIGNER) {
                    return {
                        type: GUI.TableViewV2.CellType.Special.MENU_SYSTEM_STATE_CELL,
                        action: this._showState.bind(this, ScreenState.AutomaticDesigner.OverviewScreen, showStateFn),
                        uuid: ScreenState.AutomaticDesigner.OverviewScreen,
                        content: {
                            leftIconSrc: Icon.AutomaticDesigner.COMMON,
                            reactLeftIconSrc: Icons.AutomaticDesigner,
                            title: _('autopilot.name'),
                            disclosureIcon: false,
                            leftIconColor: window.Styles.colors.brand,
                            clickable: true,
                            sourceUuids: [ActiveMSComponent.getStructureManager().getAutopilotGenerator().uuidAction]
                        }
                    };
                } else {
                    return {
                        action: this._showState.bind(this, ScreenState.Autopilot.MainScreen, showStateFn),
                        uuid: ScreenState.Autopilot.MainScreen,
                        content: {
                            leftIconSrc: Icon.Menu.AUTOMATIC_DESIGNER,
                            reactLeftIconSrc: Icons.AutomaticDesigner,
                            title: _('autopilot.name'),
                            disclosureIcon: false,
                            leftIconColor: window.Styles.colors.brand,
                            clickable: true
                        }
                    };
                }
            } else {
                return null;
            }
        }

        getScenesItem(showStateFn) {
            if (!SandboxComponent.isRecordingTask() && SandboxComponent.isAutopilotAvailable()) {
                if (Feature.AUTOMATIC_DESIGNER_SCENES) {
                    return {
                        type: GUI.TableViewV2.CellType.Special.MENU_SYSTEM_STATE_CELL,
                        action: this._showState.bind(this, ScreenState.AutomaticDesigner.ScenesOverviewScreen, showStateFn),
                        uuid: ScreenState.AutomaticDesigner.ScenesOverviewScreen,
                        content: {
                            leftIconSrc: Icon.Menu.SCENES,
                            reactLeftIconSrc: Icons.PlayStripeCircled,
                            title: _("scenes.name_plural"),
                            disclosureIcon: false,
                            leftIconColor: window.Styles.colors.brand,
                            clickable: true,
                            // Add all uuids
                            sourceUuids: SandboxComponent.getStructureManager().getAllSceneControls().map(function (sceneControl) {
                                return sceneControl.uuidAction;
                            })
                        }
                    };
                }
            }

            return null;
        }

        getUserManagementItem(showStateFn) {
            if (!SandboxComponent.isRecordingTask() && ActiveMSComponent.getRequiredUserManagementPermissionInfos().hasUserManagementPermissions) {
                return {
                    action: function () {
                        this._showState(ScreenState.UserManagementNavigator, showStateFn, {
                            initialRouteName: ScreenState.UserManagementScreen
                        });
                    }.bind(this),
                    uuid: ScreenState.UserManagementNavigator,
                    content: {
                        title: _('user.management'),
                        leftIconSrc: Icon.Menu.USERS,
                        reactLeftIconSrc: Icons.UsersWithGear,
                        clickable: true,
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand
                    }
                };
            } else {
                return null;
            }
        }

        getBatteryMonitorItem(showStateFn) {
            if (!SandboxComponent.isRecordingTask() && Regex.UUID.test(ActiveMSComponent.getDeviceMonitorUuid())) {
                return {
                    action: this._showState.bind(this, ScreenState.BatteryMonitor, showStateFn),
                    uuid: ScreenState.BatteryMonitor,
                    content: {
                        leftIconSrc: Icon.Menu.BATTERY_STATE,
                        reactLeftIconSrc: Icons.BatteryMonitor,
                        title: _('battery-monitor.overview'),
                        clickable: true,
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand
                    }
                };
            } else {
                return null;
            }
        }

        getGenericDeviceSearchItem(showStateFn) {
            if (Feature.LEARN_DEVICE_OVER_APP && !SandboxComponent.isRecordingTask() && SandboxComponent.checkPermission(MsPermission.DEVICE_MANAGEMENT) && Feature.GENERIC_DEVICE_SEARCH) {
                return {
                    action: function () {
                        if (ActiveMSComponent.getGatewayType() !== GatewayType.CLIENT) {
                            this._showState(ScreenState.DeviceSearchViewController, showStateFn, {
                                searchType: DeviceManagement.TYPE.TECH_SELECTION
                            });
                        } else {
                            NavigationComp.showPopup({
                                message: _('feature-unavailable-on-client.message'),
                                icon: Icon.INFO,
                                buttonOk: _('okay')
                            });
                        }
                    }.bind(this),
                    // Needed for search, uuid needs to be uniq
                    uuid: ScreenState.SelectExtensionScreen + '_generic',
                    content: {
                        leftIconSrc: Icon.DeviceLearning.DEVICE_SEARCH,
                        reactLeftIconSrc: Icons.DeviceSearch,
                        title:  _("generic.device-learning.title"),
                        clickable: true,
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand
                    }
                };
            } else {
                return null;
            }
        }

        getDeviceSearchItem(showStateFn) {
            if (Feature.LEARN_DEVICE_OVER_APP && !SandboxComponent.isRecordingTask() && SandboxComponent.checkPermission(MsPermission.DEVICE_MANAGEMENT)) {
                return {
                    action: function () {
                        if (ActiveMSComponent.getGatewayType() !== GatewayType.CLIENT) {
                            this._showState(ScreenState.DeviceSearchViewController, showStateFn, {
                                searchType: DeviceManagement.TYPE.AIR
                        });
                        } else {
                            NavigationComp.showPopup({
                                message: _('feature-unavailable-on-client.message'),
                                icon: Icon.INFO,
                                buttonOk: _('okay')
                            });
                        }
                    }.bind(this),
                    // Needed for search, uuid needs to be uniq
                    uuid: ScreenState.SelectExtensionScreen + '_air',
                    content: {
                        leftIconSrc: Icon.Menu.AIR,
                        reactLeftIconSrc: Icons.Air,
                        title: ActiveMSComponent.getTitleForDeviceSearchType(DeviceManagement.TYPE.AIR),
                        clickable: true,
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand
                    }
                };
            } else {
                return null;
            }
        }

        getDeviceSearchTreeItem(showStateFn) {
            if (Feature.LEARN_DEVICE_OVER_APP && !SandboxComponent.isRecordingTask() && SandboxComponent.checkPermission(MsPermission.DEVICE_MANAGEMENT)) {
                return {
                    action: function () {
                        if (ActiveMSComponent.getGatewayType() !== GatewayType.CLIENT) {
                            this._showState(ScreenState.DeviceSearchViewController, showStateFn, {
                                searchType: DeviceManagement.TYPE.TREE
                        });
                        } else {
                            NavigationComp.showPopup({
                                message: _('feature-unavailable-on-client.message'),
                                icon: Icon.INFO,
                                buttonOk: _('okay')
                            });
                        }
                    }.bind(this),
                    // Needed for search, uuid needs to be uniq
                    uuid: ScreenState.SelectExtensionScreen + '_tree',
                    content: {
                        leftIconSrc: Icon.Menu.TREE,
                        reactLeftIconSrc: Icons.Tree,
                        title: ActiveMSComponent.getTitleForDeviceSearchType(DeviceManagement.TYPE.TREE),
                        clickable: true,
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand
                    }
                };
            } else {
                return null;
            }
        }

        getQRItem(showStateFn) {
            if (!SandboxComponent.isRecordingTask()) {
                return {
                    action: this._showState.bind(this, ScreenState.QRCode, showStateFn),
                    uuid: ScreenState.QRCode,
                    content: {
                        leftIconSrc: Icon.Menu.QR,
                        reactLeftIconSrc: Icons.QrCode,
                        title: _('qr.code-generator'),
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand,
                        clickable: true
                    }
                };
            } else {
                return null;
            }
        }

        getQuickActionsItem(showStateFn) {
            var hasLXControl = LoxoneControl.hasLoxoneControl(),
                cellTitle;

            if (this.isAndroid) {
                cellTitle = _("media.shortcuts.title");
            } else if (hasLXControl) {
                cellTitle = _("loxone-control.quick-action");
            } else {
                cellTitle = _("quick-action.quick-action-generator");
            }

            if (!SandboxComponent.isRecordingTask() && (QuickActionUtility.hasQuickActions() || this.isDeveloperInterface)) {
                return {
                    action: function () {
                        if (!hasLXControl || LoxoneControl.isVisibleInStatusBar()) {
                            // only show quick actions screen if there is no loxone control available or is visible in the status bar
                            this._showState(ScreenState.QuickActions, showStateFn);
                        } else {
                            var content = {
                                title: _("loxone-control.quick-action"),
                                message: _("loxone-control.activation-message"),
                                buttonOk: _("loxone-control.show").toUpperCase(),
                                buttonCancel: true
                            };
                            NavigationComp.showPopup(content).then(function () {
                                LoxoneControl.setVisibleInStatusBar(true).then(function () {
                                    this._showState(ScreenState.QuickActions, showStateFn);
                                }.bind(this));
                            }.bind(this));
                        }
                    }.bind(this),
                    uuid: ScreenState.QuickActions,
                    content: {
                        leftIconSrc: Icon.Menu.QUICK_ACTION,
                        reactLeftIconSrc: Icons.PlayCircled,
                        title: cellTitle,
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand,
                        clickable: true
                    }
                };
            } else {
                return null;
            }
        }

        getNFCSmartTagItem(showStateFn) {
            if ((this.isAndroid || this.isDeveloperInterface) && nfcService.nfcAvailable()) {
                return {
                    action: function () {
                        nfcService.nfcDisabled().done(function (isDisabled) {
                            if (isDisabled) {
                                var content = {
                                    icon: Icon.INFO,
                                    title: _('nfc.activate'),
                                    message: _('nfc.activate.message'),
                                    buttonOk: _('activate'),
                                    buttonCancel: true
                                };
                                NavigationComp.showPopup(content).done(function () {
                                    nfcService.openNfcSettings();
                                });
                            } else {
                                this._showState(ScreenState.Nfc, showStateFn);
                            }
                        }.bind(this));
                    }.bind(this),
                    uuid: ScreenState.Nfc,
                    content: {
                        leftIconSrc: Icon.Menu.NFC,
                        reactLeftIconSrc: Icons.NfcTag,
                        title: _('nfc.smart-tag'),
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand,
                        clickable: true
                    }
                };
            } else {
                return null;
            }
        }

        getSystemStateItem(showStateFn) {
            if (!SandboxComponent.isRecordingTask()) {
                var entryInfo,
                    severityColor,
                    item = null,
                    activeEntry;

                if (MessageCenterHelper.isAvailable()) {
                    activeEntry = ActiveMSComponent.getActiveMessageCenterEntry();

                    if (activeEntry) {
                        severityColor = MessageCenterHelper.getColorForSeverityEntry(activeEntry);
                        entryInfo = activeEntry.affectedName || activeEntry.title;
                    }

                    item = {
                        action: function () {
                            this._showState(ScreenState.MessageCenterMessagesScreen, showStateFn);
                        }.bind(this),
                        uuid: ScreenState.MessageCenterMessagesScreen,
                        content: {
                            leftIconSrc: Icon.Menu.SYSTEM_STATE,
                            reactLeftIconSrc: Icons.HeartWithBeat,
                            leftIconColor: window.Styles.colors.brand,
                            title: _('message-center.name'),
                            disclosureIcon: false,
                            clickable: true
                        }
                    };
                }

                return item;
            } else {
                return null;
            }
        }

        /// ----------------------------------------------------------------
        /// --------- Individual Items -------------------------------------
        /// ----------------------------------------------------------------
        _getAboutMiniserverItem(showStateFn) {
            return {
                action: this._showState.bind(this, ScreenState.AboutMiniserver, showStateFn),
                uuid: ScreenState.AboutMiniserver,
                content: {
                    leftIconSrc: Icon.Menu.MINISERVER,
                    reactLeftIconSrc: Icons.Miniserver,
                    title: _('menu.miniserver.about'),
                    disclosureIcon: true
                }
            };
        }

        getRebootItem(showStateFn) {
            var miniserverName = ActiveMSComponent.getActiveMiniserver().msName; // trigger a miniserver reboot from the app

            if (SandboxComponent.checkPermission(MsPermission.SYS_WS)) {
                return {
                    action: function () {
                        SandboxComponent.getPermission(MsPermission.SYS_WS).done(this._rebootMiniserver.bind(this));
                    }.bind(this),
                    uuid: "reboot-miniserver",
                    type: GUI.TableViewV2.CellType.CENTER,
                    content: {
                        title: _("miniserver.trigger-reboot", {
                            miniserverName: miniserverName
                        }),
                        active: true,
                        clickable: true,
                        disclosureIcon: false
                    }
                };
            } else {
                return null;
            }
        }

        getPartnerBrandingItem(showStateFn) {
            var result = null;

            if (ActiveMSComponent.getPartnerDetails()) {
                result = {
                    action: function () {
                        this._showState(ScreenState.PartnerBranding, showStateFn);
                    }.bind(this),
                    uuid: ScreenState.PartnerBranding,
                    content: {
                        title: _("update.contact-partner"),
                        disclosureIcon: false,
                        rightIconSrc: Icon.DISCLOSURE,
                        clickable: true
                    }
                };
            }

            return result;
        }

        getAboutLoxone(showStateFn) {
            return {
                action: function () {
                    this._showState(ScreenState.LoxoneBranding, showStateFn);
                }.bind(this),
                uuid: ScreenState.LoxoneBranding,
                content: {
                    leftIconSrc: Icon.Menu.INFO,
                    reactLeftIconSrc: Icons.InfoCircled,
                    leftIconColor: window.Styles.colors.brand,
                    title: _('loxone.about'),
                    rightIconSrc: Icon.DISCLOSURE,
                    clickable: true
                }
            };
        }

        getAboutLoxoneHelpFeedbackMenu(showStateFn) {
            return {
                action: function () {
                    this._showState(ScreenState.LoxoneBranding, showStateFn);
                }.bind(this),
                uuid: ScreenState.LoxoneBranding,
                content: {
                    title: _('loxone.about'),
                    clickable: true
                }
            };
        }

        getAboutApp(showStateFn) {
            this.appInfo = PlatformComponent.getAppInfoObj();
            return {
                action: function () {
                    this._showState(ScreenState.AboutApp, showStateFn);
                }.bind(this),
                uuid: ScreenState.AboutApp,
                content: {
                    leftIconSrc: Icon.Menu.APP_ICON,
                    title: this.isWebinterface ? _('wi.about') : _('app.about'),
                    subtitle: this.appInfo.appVersion,
                    rightIconSrc: Icon.DISCLOSURE,
                    leftIconColor: "initial",
                    // overwrite the base style to keep the background as it is defined in the SVG
                    clickable: true
                }
            };
        }

        getAboutAppHelpFeedbackMenu(showStateFn) {
            this.appInfo = PlatformComponent.getAppInfoObj();
            return {
                action: function () {
                    this._showState(ScreenState.AboutApp, showStateFn);
                }.bind(this),
                uuid: ScreenState.AboutApp,
                content: {
                    title: this.isWebinterface ? _('wi.about') : _('app.about'),
                    subtitle: this.appInfo.appVersion,
                    leftIconColor: "initial",
                    // overwrite the base style to keep the background as it is defined in the SVG
                    clickable: true
                }
            };
        }

        _getTableViewDebugItem(showStateFn) {
            return {
                action: function () {
                    console.log("About to show TableViewDebugScreen!");

                    this._showState(ScreenState.TableViewDebugScreen, showStateFn);
                }.bind(this),
                uuid: "Menu+table.view.debug",
                content: {
                    title: "TableViewDebug",
                    clickable: true
                }
            };
        }

        _getReactDebugItem(showStateFn) {
            return {
                action: function () {
                    this._showState("ReactTestScreen", showStateFn);
                }.bind(this),
                uuid: "Menu+react-test",
                content: {
                    title: "React Test",
                    clickable: true
                }
            };
        }

        _getTableViewShowcaseItem(showStateFn) {
            if (Debug.Test.TABLE_VIEW_CELL_SHOWCASE) {
                return {
                    action: function() {
                        this._showState(ScreenState.TableViewShowCase, showStateFn);
                    }.bind(this),
                    uuid: "Menu+TableViewShowCase",
                    content: {
                        title: "TableViewShowCase",
                        clickable: true
                    }
                };
            }
        }

        _getIconLibShowcaseItem(showStateFn) {
            if (window.UPDATE_LEVEL !== UpdateComp.UpdateLevel.RELEASE) {
                return {
                    action: function() {
                        this._showState(ScreenState.IconLibShowcase, showStateFn);
                    }.bind(this),
                    uuid: "Menu+IconLibShowcase",
                    content: {
                        leftIconSrc: Icon.DEFAULT,
                        reactLeftIconSrc: Icons.Default,
                        leftIconColor: window.Styles.colors.brand,
                        title: "Icons",
                        clickable: true
                    }
                };
            }
        }

        _getNewsHubItem(showStateFn) {
            var item,
                color,
                entries = LxServerComponent.getLatestNewsHubEntries(),
                hasBeenOpened = LxServerComponent.getNewsHubOpened(); // are there news? If so.. change color!

            if (!hasBeenOpened && entries && entries.newIds && entries.newIds.length > 0) {
                color = window.Styles.colors.red;
            }

            item = {
                action: function () {
                    this._showState(ScreenState.NewsHubScreen, showStateFn);
                }.bind(this),
                uuid: "Menu+menu.miniserver.news",
                content: {
                    leftIconSrc: Icon.Menu.NEWS,
                    reactLeftIconSrc: Icons.News, // required as the search isn't a react table yet.
                    leftIconColor: window.Styles.colors.brand,
                    title: _('menu.miniserver.news'),
                    disclosureIcon: false,
                    clickable: true
            }
            };
            return item;
        }

        _getPresenceDetectionItem(showStateFn) {
            if (LoxoneControl.hasLoxoneControl() || this.isDeveloperInterface) {
                var canBeActivated = ActiveMSComponent.getStructureManager().getPresenceRooms().length > 0,
                    content,
                    showPresenceDetectorScreen = CommunicationComponent.getCurrentReachMode() === ReachMode.LOCAL && LoxoneControl.isVisibleInStatusBar();

                if (canBeActivated) {
                    content = {
                        title: _("loxone-control.presence-detector"),
                        message: _("loxone-control.activation-message"),
                        buttonOk: _("loxone-control.show").toUpperCase(),
                        buttonCancel: true
                    };
                } else {
                    content = {
                        title: _("loxone-control.configuration.no-controls.title"),
                        message: _("loxone-control.configuration.no-controls.description", {
                            computer: _('loxone-control.configuration.device')
                        }),
                        buttonOk: _("mobiscroll.set"),
                        buttonCancel: false
                    };
                }

                return {
                    action: function () {
                        if (showPresenceDetectorScreen && canBeActivated) {
                            // only show presence detector if it is visible in the status bar
                            this._openPresenceDetector(showStateFn);
                        } else if (canBeActivated) {
                            NavigationComp.showPopup(content).then(function () {
                                if (this.isDeveloperInterface) {
                                    this._openPresenceDetector(showStateFn);
                                } else {
                                    LoxoneControl.setVisibleInStatusBar(true).then(function () {
                                        this._openPresenceDetector(showStateFn);
                                    }.bind(this));
                                }
                            }.bind(this));
                        } else {
                            return NavigationComp.showPopup(content);
                        }
                    }.bind(this),
                    uuid: ScreenState.PresenceDetector,
                    content: {
                        leftIconSrc: Icon.Menu.PRESENCE_MENU,
                        reactLeftIconSrc: Icons.Presence,
                        title: _("loxone-control.presence-detector"),
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand,
                        clickable: true
                    }
                };
            } else {
                return null;
            }
        }

        _openPresenceDetector(showStateFn) {
            if (CommunicationComponent.getCurrentReachMode() === ReachMode.LOCAL) {
                if (ActiveMSComponent.getStructureManager().getPresenceRooms().length > 0) {
                    this._showState(ScreenState.PresenceDetector, showStateFn);
                }
            } else {
                this._showState(ScreenState.LoxoneControlInfo, showStateFn, {
                    infoType: LoxoneControlInfoType.NO_INTERNAL_STRUCTURE
                });
            }
        }

        _getTaskRecorderItem(showStateFn) {
            if (!Feature.NO_TASK_RECORDER) {
                return {
                    action: this._showState.bind(this, ScreenState.TaskRecorder, showStateFn),
                    uuid: ScreenState.TaskRecorder,
                    content: {
                        leftIconSrc: Icon.Menu.TASK_RECORDER,
                        reactLeftIconSrc: Icons.Record,
                        title: _('taskrecorder'),
                        disclosureIcon: false,
                        leftIconColor: window.Styles.colors.brand,
                        clickable: true
                    }
                };
            }
        }

        _getWeatherItem(hasWeather, showStateFn) {
            var action, title;

            if (hasWeather) {
                title = _('weather.weather');
                action = this._showState.bind(this, ScreenState.Weather, showStateFn);
            } else {
                title = _('menu.tools.weather-server.subscribe');
                action = this._showState.bind(this, ScreenState.WeatherAd, showStateFn);
            }

            return {
                action: action,
                uuid: ScreenState.Weather,
                content: {
                    leftIconSrc: Icon.Menu.WEATHER,
                    reactLeftIconSrc: Icons.Weather,
                    disclosureIcon: false,
                    title: title,
                    leftIconColor: window.Styles.colors.brand,
                    clickable: true
                }
            };
        }

        _getTableDebugItem(showStateFn) {
            var item = null;

            if (Debug.GUI.TableView) {
                item = {
                    action: this._showState.bind(this, ScreenState.TableViewTest, showStateFn),
                    uuid: ScreenState.TableViewTest,
                    content: {
                        title: "TableView test",
                        disclosureIcon: true
                    }
                };
            }

            return item;
        }

        _getDisconnectItem(showStateFn) {
            return {
                action: this._disconnectFromMs.bind(this),
                uuid: "Menu+disconnect.connection",
                content: {
                    leftIconSrc: Icon.Menu.DISCONNECT,
                    reactLeftIconSrc: Icons.BreakChain,
                    title: _('disconnect.connection')
                }
            };
        }

        /// ----------------------------------------------------------------
        /// --------- Infrastructure Methods--------------------------------
        /// ----------------------------------------------------------------
        _showState(state, showStateFn, details) {
            if (showStateFn) {
                showStateFn(state, details);
            } else {
                NavigationComp.showState(state, details);
            }
        }

        _disconnectFromMs() {
            NavigationComp.disconnect();
        }

        _rebootMiniserver() {
            CommunicationComponent.send(Commands.REBOOT_MINISERVER);
        }

        _applyDefaultCellType(items) {
            items.forEach(function (item) {
                item.type = DEFAULT_MENU_CELL_TYPE;
            }.bind(this));
        }

        _applyDefaultCellSearchType(items) {
            items.forEach(function (item) {
                item.type = DEFAULT_MENU_CELL_SEARCH_TYPE;
                item.content.leftIconSrc = null;
                item.content.disclosureIcon = false;
                item.content.rightIconSrc = null;
                item.content.clickable = true;
                item.content.active = false;
            }.bind(this));
        }

        _hasActiveMiniserver() {
            return ActiveMSComponent.getActiveMiniserver();
        }

    };
});
