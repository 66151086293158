'use strict';

define("SequentialControlStateContainer", ["ControlStateContainer"], function (ControlStateContainer) {
    return class SequentialControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            this.states.activeSequence = newVals[this.control.states.activeSequence];
        }

        getStateText() {
            if (this.states.activeSequence) {
                return _("x.active", {
                    x: this.getStateTextShort()
                });
            } else {
                return this.getStateTextShort();
            }
        }

        getStateTextShort() {
            let text = "";

            if (!this.states.activeSequence) {
                text = _("off");
            } else {
                let activeSequence = this.control.details.sequences.find(sequence => sequence.id === this.states.activeSequence);
                text = activeSequence.name;
            }

            return text;
        }

        getStateColor() {
            if (this.states.activeSequence) {
                return window.Styles.colors.stateActive;
            }
        }

    };
});
