'use strict';

define("LightV2ControlStateContainer", ["ControlStateContainer", "LightV2ControlEnums"], function (ControlStateContainer, LightV2ControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var SubCtrlType = {
        DIMMER: 'Dimmer',
        SWITCH: 'Switch',
        COLOR_PICKERV2: 'ColorPickerV2'
    };
    return class LightV2ControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            this.subCtrlStates = {}; // keep track of states of subControls to properly update extra scenes

            var subCtrl, state;

            for (var ctrlId in control.subControls) {
                if (control.subControls.hasOwnProperty(ctrlId)) {
                    subCtrl = control.subControls[ctrlId];
                    state = {
                        isOn: false,
                        isOff: true,
                        type: subCtrl.type,
                        states: {},
                        uuids: subCtrl.states
                    };
                    this.subCtrlStates[ctrlId] = state;
                }
            }
        }

        /**
         * returns array of uuids to registered
         * @returns {*[]}
         */
        getUUIDsToRegister() {
            var uuids = super.getUUIDsToRegister(...arguments);
            var subCtrls = this.control.subControls,
                subCtrl;

            for (var ctrlId in subCtrls) {
                if (subCtrls.hasOwnProperty(ctrlId)) {
                    subCtrl = subCtrls[ctrlId];

                    if (subCtrl.type === SubCtrlType.COLOR_PICKERV2) {
                        uuids.push(subCtrl.states.color);
                    } else {
                        uuids = uuids.concat(Object.values(subCtrl.states));
                    }
                }
            }

            return uuids;
        }

        /**
         * handles incoming values and prepares the states
         * @param newVals
         */
        prepareStates(newVals) {
            this._updateSubControlStates(newVals); // store last active moods, this way overwriting a mood becomes more handy


            this.states.lastActiveMoods = this.states.lastActiveMoods || [];

            if (this.states.activeMoods && this.states.activeMoods.length > 0) {
                this.states.lastActiveMoods = this.states.activeMoods;
            }

            this.states.activeMoods = JSON.parse(newVals[this.control.states.activeMoods].text) || [];
            this.states.moodList = JSON.parse(newVals[this.control.states.moodList].text) || [];
            this.states.favoriteMoods = JSON.parse(newVals[this.control.states.favoriteMoods].text) || [];
            this.states.additionalMoods = JSON.parse(newVals[this.control.states.additionalMoods].text) || []; // Prepare master control states

            this.states.masterValue = this.subCtrlStates[this.control.details.masterValue];
            this.states.masterColor = this.subCtrlStates[this.control.details.masterColor];if (Feature.DAYLIGHT) {
                this.states.daylightconfig = JSON.parse(newVals[this.control.states.daylightConfig].text);
            } else {
                this.states.daylightconfig = {};
            }
            this.states.isOn = false;

            for (var subCtrlUuid in this.subCtrlStates) {
                if (this.subCtrlStates.hasOwnProperty(subCtrlUuid)) {
                    // We don't count masterValue and masterColor
                    if (subCtrlUuid !== this.control.details.masterValue && subCtrlUuid !== this.control.details.masterColor) {
                        var subCtrlState = this.subCtrlStates[subCtrlUuid];

                        if (!subCtrlState.isOff) {
                            this.states.isOn = true;
                            break;
                        }
                    }
                }
            } // Iterate over all moods and "resolve" the used bitmap if available,


            this.states.moodList.forEach(function (mood) {
                // .used doesn't exist if the mood is not used by the presenceDetector or a T5 input
                if (Feature.LCV2_USED_STATE && mood.used) {
                    mood.isMovementMood = hasBit(mood.used, LightV2ControlEnums.USED_BIT.MOVEMENT_MOOD) || hasBit(mood.used, LightV2ControlEnums.USED_BIT.PRESENCE_MOOD);
                    mood.isT5Mood = hasBit(mood.used, LightV2ControlEnums.USED_BIT.IS_T5_MOOD);
                    mood.isAlarmClockMood = hasBit(mood.used, LightV2ControlEnums.USED_BIT.ALARM_CLOCK_MOOD);
                } else {
                    mood.used = LightV2ControlEnums.USED_BIT.NOT_USED;
                    mood.isMovementMood = false;
                    mood.isT5Mood = false;
                    mood.isAlarmClockMood = false;
                } // Check if this mood can be combined with another mood


                var selected = this.states.activeMoods.indexOf(mood.id) !== -1,
                    someMoodSelected = this.states.activeMoods.length > 0,
                    isMulti = this.states.activeMoods.length > 1 && selected,
                    isAllOff = mood.id === LightV2ControlEnums.KNOWN_MOODS.ALL_OFF,
                    isAllOffActive = this.states.activeMoods.indexOf(LightV2ControlEnums.KNOWN_MOODS.ALL_OFF) !== -1; // isAccumulative indicates if this mood and another can be added added
                // Multiple moods are selected or some mood, but this one is selected

                mood.isAccumulative = isMulti || someMoodSelected && !selected; // Don't set accumulative if:
                // All off is active, it doesn't make any sense to add a mood to all off

                if (isAllOff || isAllOffActive) {
                    mood.isAccumulative = false;
                }
            }.bind(this)); // Used to create command Texts

            if (this.control.states.hasOwnProperty("circuitNames")) {
                this.states.circuitNames = JSON.parse(newVals[this.control.states.circuitNames].text) || {};
            } else {
                this.states.circuitNames = {};
                Object.values(this.control.subControls).forEach(function (subControl) {
                    if (!subControl.uuidAction.hasSuffix("/masterValue") && !subControl.uuidAction.hasSuffix("/masterColor")) {
                        this.states.circuitNames[subControl.uuidAction] = subControl.name;
                    }
                }.bind(this));
            }
        }

        getStateIcon() {
            return Icon.Light.ComfortMode.BULB;
        }

        getStateText() {
            if (!this.control.hasSingleSwitch()) {
                if (this.states.activeMoods.length > 1) {
                    return _('controls.lightV2controller.mood.multiple');
                } else if (this.states.activeMoods[0] === LightV2ControlEnums.KNOWN_MOODS.ALL_OFF || this.states.activeMoods[0] === LightV2ControlEnums.KNOWN_MOODS.ALL_ON) {
                    return this.control.getMoodName();
                }

                return _('controls.lightV2controller.mood.x.active', {
                    mood: this.control.getMoodName()
                });
            }
        }

        getStateTextShort() {
            if (this.control.hasSingleSwitch()) {
                return this.states.isOn ? _('on') : _('off');
            } else {
                return this.control.getMoodName();
            }
        }

        getStateColor() {
            if (this.states.isOn) {
                return window.Styles.colors.stateActive;
            }
        }

        /**
         * Returns an array of object with at least a "name" and "id" property
         * @param sourceState
         * @return {*[]}
         */
        getAutomaticDesignerStateObjectsFromState(sourceState) {
            var objectStates;

            switch (sourceState) {
                case "moodList":
                    objectStates = Object.values(this.states[sourceState]);
                    break;

                default:
                    objectStates = super.getAutomaticDesignerStateObjectsFromState(...arguments);
            }

            return objectStates;
        }

        _updateSubControlStates(newStates) {
            var subStates = this.subCtrlStates,
                subState,
                val;

            for (var subId in subStates) {
                if (subStates.hasOwnProperty(subId)) {
                    subState = this.subCtrlStates[subId];

                    if (subState.type === SubCtrlType.DIMMER) {
                        val = newStates[subState.uuids.position];
                        subState.states.pos = val;
                        subState.states.min = newStates[subState.uuids.min];
                        subState.states.max = newStates[subState.uuids.max];
                        subState.isOn = val >= subState.states.max;
                        subState.isOff = val === 0 || val < subState.states.min;
                    } else if (subState.type === SubCtrlType.SWITCH) {
                        val = newStates[subState.uuids.active] === 1;
                        subState.states.isActive = val;
                        subState.isOn = val;
                        subState.isOff = !val;
                    } else if (subState.type === SubCtrlType.COLOR_PICKERV2) {
                        val = newStates[subState.uuids.color].text || "hsv(0,0,0)";
                        subState.states.color = val; // hsv and temp values might still have H&S or TEMP values might still be there if a light is off, but no brightness/value

                        subState.isOff = val.startsWith('hsv(') && val.endsWith(',0)') || val.startsWith('temp(0,');
                        var sequenceState = newStates[subState.uuids.sequence];
                        subState.sequence = ""; // Empty string means no sequence

                        if (sequenceState) {
                            subState.sequence = JSON.parse(sequenceState.text) || {};
                        }
                    }
                }
            }
        }

    };
});
