'use strict';

SandboxComp.factory('DeviceActivityTrackerExt', function () {
    const ACTIVITY_RECHECK_INTERVAL = 5000; // recheck each 5 seconds.

    /**
     * @class DeviceActivityTracker
     * takes care of monitoring activity on the device.
     */
    return class DeviceActivityTracker extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.name = "DeviceActivityTracker"
            this._active = false;
            this._activityTimeout = null;
            this._activityBlocked = false;
            this._activityExtended = false;
            this._lastMouseMove = 0;

            this._registerListeners();

            // kick off
            setTimeout(() => { this.activityTick(); }, 1);

            this.registerExtensionEv(CCEvent.Pause, this.handleAppPaused.bind(this));
            this.registerExtensionEv(CCEvent.Resume, this.handleAppResumed.bind(this));
            this.registerExtensionEv(CCEvent.StartMSSession, this.activityTick.bind(this));
        }

        isActive() {
            return this._active;
        }

        dispatchChange() {
            this.channel.emit(SandboxComp.ECEvent.DeviceActivityChanged, { active: this._active })
        }

        activityTick() {
            this.channel.emit(SandboxComp.ECEvent.ActivityTick, { active: true })
            if (this._activityTimeout) {
                this._activityExtended = true;
            } else {
                this._startActivityRecheckInterval();
                this.updateActive(true);
            }
        }

        setIgnoreActivityTicks(shouldIgnore) {
            this._activityBlocked = shouldIgnore;
            if (this._activityBlocked) {
                this.updateActive(false);
                this._stopActivityRecheck();
            }
        }

        updateActive(newVal) {
            if (this._active !== newVal) {
                this._active = !!newVal;
                this.dispatchChange();
            }
        }

        handleAppPaused() {
            this.setIgnoreActivityTicks(true);
        }

        handleAppResumed() {
            this.setIgnoreActivityTicks(false);
            this.activityTick();
        }

        _startActivityRecheckInterval() {
            this._activityTimeout = setTimeout(this._recheckActivity.bind(this), ACTIVITY_RECHECK_INTERVAL);
        }

        _stopActivityRecheck() {
            clearTimeout(this._activityTimeout);
            this._activityTimeout = null;
        }

        _recheckActivity() {
            if (this._activityExtended) {
                this._activityExtended = false;
                this._startActivityRecheckInterval();
            } else {
                this._activityTimeout = null;
                this.updateActive(false);
            }
        }

        _registerListeners() {
            $(document).on("tap touchend", this._delayTouchInteractionTick.bind(this));

           // also mobile devices may leverage a mouse or a trackpad (iPad/Android)
            $(document).on("click keypress", this._delayTouchInteractionTick.bind(this));
            $(document).on("mousemove mousewheel touchmove", (e) => {
                var curr = new Date().getTime(); // trigger mouse move event every x seconds
                if (curr - this._lastMouseMove > (2000)) {
                    this._lastMouseMove = curr;
                    this._delayTouchInteractionTick()
                }
            });
        }

        _delayTouchInteractionTick() {
            if (!this._delayedTimeout) {
                this._delayedTimeout = setTimeout(() => {
                    this._delayedTimeout = null;
                    this._onInternalActivity()
                }, 500);
            }
        }

        _onInternalActivity(event) {
            this.activityTick();
        }
    }
});
