'use strict';

SandboxComp.factory('StatisticExt', function () {
    var STATISTIC_CACHE_FILE_NAME = "%s_Statistic_Cache.json",
        // mac
        STATISTIC_DATES_FILE_NAME = "%s_Statistic_Dates.json",
        // mac
        STATISTIC_DATA_FILE_NAME = "%s_Statistic_%s_%s.bin",
        // mac, controlUuid, date
        STATISTIC_CACHE_VERSION = 1; // used to invalidate all stats caches

    let weakThis, comp;
    var REQEUST_MONTH_FORMAT = "YYYYMM",
        DAY_FORMAT = "YYYYMMDD";

    function StatisticExt(sandboxComp) {
        weakThis = this
        this.name = "StatisticExt";
        comp = sandboxComp;
        weakThis.statisticControlList = {};
        weakThis.requestsQueue = [];
        weakThis.currentRequest = null;
        weakThis.statisticInfoAvailable = false;
        sandboxComp.on(SandboxComp.ECEvent.ConnReady, function () {
            checkRequests();
        }.bind(weakThis));
        sandboxComp.on(SandboxComp.ECEvent.ConnClosed, weakThis.stopLoadingStatisticData.bind(weakThis, true));
    }

    var getStatisticInfo = function getStatisticInfo(controlUUID) {
        Debug.Statistic.Basic && console.log(weakThis.name, "getStatisticInfo " + controlUUID);

        if (!weakThis.statisticInfoAvailable) {
            weakThis.statisticInfoAvailable = initNeededData.call(weakThis, controlUUID);
            weakThis.statisticInfoAvailable.done(function success() {
                Debug.Statistic.Basic && console.info("all statistic info available, let's go!");
            }, function error(e) {
                console.error(e);
            });
        }

        return weakThis.statisticInfoAvailable;
    };

    var initNeededData = function initNeededData(controlUUID) {
        Debug.Statistic.Basic && console.log(weakThis.name, "initNeededData"); // creates an object with the ouputs, available dates and interval information

        createControlIndexMap();
        var loadedFile = loadStatisticFile(controlUUID);
        loadedFile.then(function (file) {
            if (file instanceof Array) {
                receivedAvailableDates(file[0].File);
            } else if (typeof file === "object") {
                receivedAvailableDates(file.File);
            }
        }, function error(e) {
            console.error(e.stack);
        }); // load index list of cached statistic files

        var hasIndexFile = loadCacheIndexFile();
        hasIndexFile.then(function (indexList) {
            weakThis.cacheIndexList = indexList;
        });
        return Q.all([loadedFile, hasIndexFile]);
    };
    /**
     * creates an indexMap for all controls
     * used for availableDates etc.
     */


    var createControlIndexMap = function createControlIndexMap() {
        let controls = comp.getStructureManager().getAllControls();
        weakThis.statisticControlList = {};

        for (var uuid in controls) {
            if (controls.hasOwnProperty(uuid)) {
                if (controls[uuid].statistic) {
                    weakThis.statisticControlList[uuid] = $.extend({}, controls[uuid].statistic, {
                        availableDates: []
                    });
                }
            }
        }
    };
    /**
     * compares saved statistic file date with actual date and downloads the new file if neccessary
     * @returns {promise}
     */


    var loadStatisticFile = function loadStatisticFile(controlUUID) {
        Debug.Statistic.Basic && console.log(weakThis.name, "loadStatisticFile " + controlUUID);

        if (Feature.STATISTIC_FILE_FILTERED) {
            return downloadStatisticFile(controlUUID);
        }

        return comp.download(Commands.STATISTIC.STATISTIC_DATES_VERSION).then(function (res) {
            return PersistenceComponent.loadFile(statisticDatesFilename(), DataType.OBJECT).then(function (statisticFile) {
                if (statisticFile.Statisticfiles.Date === res.LL.value) {
                    return statisticFile.Statisticfiles;
                } else {
                    throw new Error("file is out of date!");
                }
            }).fail(function error(e) {
                Debug.Statistic.Basic && console.error("Couldn't load statistic file, download it - " + e);
                return downloadStatisticFile();
            });
        }, function error(e) {
            console.error("statistic date request failed");
            throw e;
        });
    };
    /**
     * downloads statistic dates file from Miniserver
     * @returns {promise}
     */


    var downloadStatisticFile = function downloadStatisticFile(controlUUID) {
        // if the controlUUID is given, use the filtered request
        var cmd = Commands.STATISTIC.STATISTIC_DATES_FILE;

        if (controlUUID) {
            cmd = Commands.format(Commands.STATISTIC.STATISTIC_DATES_FILE_FILTERED, controlUUID);
        }

        return comp.download(cmd).then(function (statisticFile) {
            statisticFile = JSON.parse(statisticFile);

            if (!controlUUID) {
                // only save, if we requested the whole file - makes no sense to cache a filtered file because we can't compare dates
                var fileName = statisticDatesFilename();
                PersistenceComponent.saveFile(fileName, statisticFile, DataType.OBJECT).done(function success() {
                    Debug.Statistic.Basic && console.log("saved successfully the statistic dates of " + fileName);
                }, function error(e) {
                    Debug.Statistic.Basic && console.error("couldn't save the statistic of " + fileName);
                });
            }

            return statisticFile.Statisticfiles;
        }, function (e) {
            console.error("statistic file download failed");
            throw e;
        });
    };
    /**
     * loads the cache index file from storage
     * @returns {promise}
     */


    var loadCacheIndexFile = function loadCacheIndexFile() {
        return comp.loadFile(statisticCacheFilename(), DataType.OBJECT).then(function (indexFile) {
            if (!indexFile.hasOwnProperty("version") || indexFile.version !== STATISTIC_CACHE_VERSION) {
                console.log("invalidating statistic cache due to version update..");
                return {
                    version: STATISTIC_CACHE_VERSION
                };
            }

            return indexFile;
        }, function () {
            return {
                version: STATISTIC_CACHE_VERSION
            };
        });
    };
    /**
     * adds all available dates to the statisticsControlList
     * @param dateInfos
     */


    var receivedAvailableDates = function receivedAvailableDates(dateInfos) {
        var control, date;

        if (dateInfos instanceof Array) {
            var statInfo;

            for (var i = 0; i < dateInfos.length; i++) {
                statInfo = dateInfos[i];

                if (control = weakThis.statisticControlList[statInfo.UUID]) {
                    date = statInfo.Year + (statInfo.Month.length === 1 ? "0" : "") + statInfo.Month; // 20149 -> 201409

                    control.availableDates.push(date);
                }
            }
        } else if (typeof dateInfos === "object") {
            if (control = weakThis.statisticControlList[dateInfos.UUID]) {
                date = dateInfos.Year + (dateInfos.Month.length === 1 ? "0" : "") + dateInfos.Month; // 20149 -> 201409

                control.availableDates.push(date);
            }
        } // sort!


        for (var uuid in weakThis.statisticControlList) {
            if (weakThis.statisticControlList.hasOwnProperty(uuid)) {
                weakThis.statisticControlList[uuid].availableDates.sort(function (a, b) {
                    if (parseInt(a) < parseInt(b)) return -1;
                    if (parseInt(a) > parseInt(b)) return 1;
                    return 0;
                });
            }
        }
    };
    /**
     * returns unix timestamp of first month where statistic data is available
     * @param controlUUID
     * @returns {promise}
     */


    StatisticExt.prototype.getMinimumDateOfControl = function getMinimumDateOfControlAndOutput(controlUUID) {
        return Q.when(getStatisticInfo(controlUUID)).then(function () {
            var statsInfo = weakThis.statisticControlList[controlUUID];

            if (statsInfo && statsInfo.availableDates.length) {
                return moment.utc(statsInfo.availableDates[0], REQEUST_MONTH_FORMAT).unix();
            } else {
                throw new Error("Can't get min date!");
            }
        });
    };
    /**
     * loads statistic data for the given params
     * @param controlUUID
     * @param outputUUID
     * @param date
     * @param range
     * @returns {promise}
     */


    StatisticExt.prototype.getStatisticData = function getStatisticData(controlUUID, outputUUID, date, range) {
        Debug.Statistic.Basic && console.log(weakThis.name, "getStatisticData: " + controlUUID, date); // check if there is already a request for exact the same params

        var i;

        for (i = 0; i < weakThis.requestsQueue.length; i++) {
            if (compareRequest(weakThis.requestsQueue[i], controlUUID, outputUUID, date, range)) {
                // return same promise as before
                return weakThis.requestsQueue[i].defer.promise;
            }
        } // check if currentReqeust is equal


        if (weakThis.currentRequest && compareRequest(weakThis.currentRequest, controlUUID, outputUUID, date, range)) {
            return weakThis.currentRequest.defer.promise;
        } // no request, add it to the queue


        var newRequest = createRequest(controlUUID, outputUUID, date, range);
        weakThis.requestsQueue.push(newRequest);
        checkRequests();
        return newRequest.defer.promise;
    };
    /**
     * creates a new request object with given params
     * @param {String} controlUUID
     * @param {String} outputUUID
     * @param {String} date
     * @param {Statistic.DataRange} range
     * @returns {{controlUUID: String, outputUUID: String, date: String, range: Statistic.DataRange, defer: Promise}}
     */


    var createRequest = function createRequest(controlUUID, outputUUID, date, range) {
        return {
            controlUUID: controlUUID,
            outputUUID: outputUUID,
            date: date,
            range: range,
            defer: Q.defer()
        };
    };
    /**
     * creates an array of requests for a whole year
     * looks if statistic data is available!
     * @param {String} year
     * @param {String} controlUUID
     * @param {String} outputUUID
     * @param {String} date
     * @param {Statistic.DataRange} range
     * @returns {{controlUUID: String, outputUUID: String, date: String, range: Statistic.DataRange, defer: Promise}[]}
     */


    var createRequestsForYear = function createRequestsForYear(year, controlUUID, outputUUID, date, range) {
        var res = [],
            month,
            dateStr;

        for (month = 1; month <= 12; month++) {
            dateStr = year + (month < 10 ? "0" + month : "" + month);

            if (isStatisticDataAvailable(controlUUID, dateStr)) {
                res.push(createRequest(controlUUID, outputUUID, dateStr, range));
            }
        }

        return res;
    };
    /**
     * compares the request with the given params
     * @param request
     * @param controlUUID
     * @param outputUUID
     * @param date
     * @param range
     * @returns {bool} if request and params are equal
     */


    var compareRequest = function compareRequest(request, controlUUID, outputUUID, date, range) {
        return request.controlUUID === controlUUID && request.outputUUID === outputUUID && request.date === date && request.range === range;
    };

    StatisticExt.prototype.stopLoadingStatisticData = function stopLoadingStatisticData(socketStop) {
        Debug.Statistic.Basic && console.info("stopLoadingStatisticData");
        weakThis.statisticInfoAvailable = false; // TODO-thallth check if data loads again when connection closes..
        // TODO-thallth add ability to only remove all requests from 1 control
        // reject all requests

        while (weakThis.requestsQueue.length) {
            var req = weakThis.requestsQueue.shift();
            Debug.Statistic.Basic && console.info("reject request for date", req.date);
            req.defer.reject(SupportCode.STATISTIC_MANUAL_CANCEL);
        }

        if (weakThis.currentRequest) {
            Debug.Statistic.Basic && console.info("reject current request for date", weakThis.currentRequest.date);
            weakThis.currentRequest.defer.reject(SupportCode.STATISTIC_MANUAL_CANCEL);
            weakThis.currentRequest = null;
        }
    };
    /**
     * checks requests and stats to processing new one if no current request is pending
     */


    var checkRequests = function checkRequests() {
        if (!weakThis.currentRequest && weakThis.requestsQueue.length) {
            weakThis.currentRequest = weakThis.requestsQueue.shift(); // handling to process next request

            weakThis.currentRequest.defer.promise.then(function success() {
                console.log("request with date", weakThis.currentRequest.date, "finished, go to next!");
                processNextRequest();
            }, function error(code) {
                if (weakThis.currentRequest) {
                    console.log("request with date", weakThis.currentRequest.date, "failed with code", code);
                }

                if (code !== SupportCode.STATISTIC_MANUAL_CANCEL) {
                    console.log("  - go to next request!");
                    processNextRequest();
                }
            });
            var visuType = getVisuTypeOfControlOutput(weakThis.currentRequest.controlUUID, weakThis.currentRequest.outputUUID);

            if (visuType === Statistic.Type.BAR_CHART) {
                processAccumulatedRequest(weakThis.currentRequest);
            } else {
                processNormalRequest(weakThis.currentRequest);
            }
        }
    };

    var processNextRequest = function processNextRequest() {
        weakThis.currentRequest = null;
        checkRequests();
    };
    /**
     * processes a normal request, normal means not accumulated
     * @param request
     */


    var processNormalRequest = function processNormalRequest(request) {
        Q.when(getStatisticInfo(request.controlUUID)).then(function () {
            // keep .then here! errors are handled in .fail!
            if (isStatisticDataAvailable(request.controlUUID, request.date)) {
                loadStatisticData(request.controlUUID, request.date).done(function success(data) {
                    Debug.Statistic.Downloading && console.info("all data loaded for", request.date); // final data, up to date!

                    prepareDataToDispatch(data, request.date, request.controlUUID, request.outputUUID).then(function success(result) {
                        request.defer.resolve(result);
                    }, function error(e) {
                        request.defer.reject(SupportCode.STATISTIC_DISPATCH_PREPARING_FAILED);
                        console.error(e.stack);
                    }); //processNextRequest();
                }, function error(code) {
                    request.defer.reject(code);
                    console.error("Statistic Error: Code", code);
                }, function notify(data) {
                    Debug.Statistic.Downloading && console.info("got intermediate data for", request.date); // intermediate data!

                    prepareDataToDispatch(data, request.date, request.controlUUID, request.outputUUID).done(function (result) {
                        request.defer.notify(result);
                    }, function error(e) {
                        request.defer.reject(SupportCode.STATISTIC_DISPATCH_PREPARING_FAILED);
                        console.error(e.stack);
                    });
                });
            } else {
                Debug.Statistic.Downloading && console.log('No statistic available for ctrl: ' + request.controlUUID + ' and date: ' + request.date);
                request.defer.reject(SupportCode.STATISTIC_NO_DATA_AVAILABLE); //processNextRequest();
            }
        }).fail(function (e) {
            // catches all errors!
            request.defer.reject(SupportCode.STATISTIC_ERROR);
            console.error(e.stack); //processNextRequest();
        });
    };
    /**
     * processes a request for accumulated statistic data
     * @param request
     */


    var processAccumulatedRequest = function processAccumulatedRequest(request) {
        if (request.range === Statistic.DataRange.YEAR) {
            // load the whole year!
            var neededMonthRequests = createRequestsForYear(request.date, request.controlUUID, request.outputUUID, request.date, request.range);
            var neededMonthPromises = []; // now pick each promise from the requests

            neededMonthRequests.forEach(function (req) {
                neededMonthPromises.push(req.defer.promise);
            }); // if no sub requests were created, we have no data for the request!

            if (!neededMonthPromises.length) {
                request.defer.reject(SupportCode.STATISTIC_NO_DATA_AVAILABLE);
                return;
            }

            Q.all(neededMonthPromises).then(function (result) {
                // keep .then here! errors are handled in .fail!
                // concat all result dataPoints!
                var concatenated = [];
                result.forEach(function (res) {
                    concatenated = concatenated.concat(res.dataPoints);
                }); // now start to calculated accumulated stuff!

                var accumulatedData = Statistic.Processor.calculateAccumulatedStats(concatenated, request.date, request.range);
                var res = Statistic.Processor.checkAccumulatedOutliers(accumulatedData);
                result.dataPoints = res.data;
                result.min = res.min;
                result.max = res.max;
                result.outlierLimit = res.outlierLimit;
                request.defer.resolve(result);
            }, function error(code) {
                request.defer.reject(code); // this is already a support code!

                console.error("Statistic Error: Code", code);
            }).fail(function error(e) {
                // catches all errors!
                request.defer.reject(SupportCode.STATISTIC_ERROR);
                console.error(e.stack);
            });
            request.defer.promise.then(null, function error(e) {
                console.error("Year-Request got rejected - also reject all 'Sub' Requests!");
                neededMonthRequests.forEach(function (req) {
                    req.defer.reject(e);
                });
            });
            neededMonthRequests.forEach(function (req) {
                processNormalRequest(req);
            });
        } else {
            // create a new request, and download data from socket with it
            // this has to be done because, to seperate download, preparation etc. from the original request
            var newRequest = createRequest(request.controlUUID, request.outputUUID, request.date, request.range);
            var lastIntermediateDP = {}; // props are set from intermediate packages!

            newRequest.defer.promise.then(function success(result) {
                // keep .then here! errors are handled in .fail!
                // now start to calculated accumulated stuff!
                var accumulatedData = Statistic.Processor.calculateAccumulatedStats(result.dataPoints, request.date, request.range);
                var res = Statistic.Processor.checkAccumulatedOutliers(accumulatedData);
                result.dataPoints = res.data;
                result.min = res.min;
                result.max = res.max;
                result.outlierLimit = res.outlierLimit;
                /*result.dataPoints = accumulatedData;
                 var minAndMax = Statistic.Processor.getMinAndMaxOfPoints(result.dataPoints);
                result.min = minAndMax.min;
                result.max = minAndMax.max;*/

                request.defer.resolve(result); // processNextRequest(); not needed, is handled in processNormalRequest fn!
            }, function error(code) {
                request.defer.reject(code); // this is already a support code!

                console.error("Statistic Error: Code", code);
            }, function notify(result) {
                // now start to calculated accumulated stuff!
                // keep lastIntermediateDP in mind when using Worker maybe some time!!
                var accumulatedData = Statistic.Processor.calculateAccumulatedStats(result.dataPoints, request.date, request.range, lastIntermediateDP);
                var res = Statistic.Processor.checkAccumulatedOutliers(accumulatedData);
                result.dataPoints = res.data;
                result.min = res.min;
                result.max = res.max;
                result.outlierLimit = res.outlierLimit;
                /*result.dataPoints = accumulatedData;
                 var minAndMax = Statistic.Processor.getMinAndMaxOfPoints(result.dataPoints);
                result.min = minAndMax.min;
                result.max = minAndMax.max;*/

                if (result.dataPoints.length) {
                    // we got new data!
                    request.defer.notify(result);
                }
            }).fail(function (e) {
                // catches all errors!
                request.defer.reject(SupportCode.STATISTIC_ERROR);
                console.error(e.stack); // processNextRequest(); not needed, is handled in processNormalRequest fn!
            });
            processNormalRequest(newRequest);
        }
    };
    /**
     * loads statistic data for params
     * @param ctrlUUID
     * @param date
     * @returns {promise}
     */


    var loadStatisticData = function loadStatisticData(ctrlUUID, date) {
        if (hasCachedStatisticData(ctrlUUID, date)) {
            // create own defer to be able to call notify!
            var def = Q.defer();
            loadCachedStatisticData(ctrlUUID, date).then(function (data) {
                if (isCachedStatisticDataUpToDate(ctrlUUID, date)) {
                    // resolve with final data
                    def.resolve(data);
                } else {
                    var range = getRangeFromDate(date);
                    Statistic.Processor.sortStatisticData(data, range.from, range.to); // sort (for finding last ts and to speed up 2nd sorting)
                    // notify with intermediate data

                    def.notify(data); // and download rest

                    var lastDpTimestamp = data[data.length - 1].ts;
                    var dateFromLastCachedDay = moment.utc(lastDpTimestamp * 1000).format(DAY_FORMAT);
                    loadStatisticData(ctrlUUID, dateFromLastCachedDay).then(function (restData) {
                        // sort rest before merging
                        Statistic.Processor.sortStatisticData(restData, range.from, range.to); // then merge with cached data

                        var newData = Statistic.Processor.mergeStatisticData(data, restData);

                        if (newData.length) {
                            // we got new data!
                            // notify with the new data
                            def.notify(newData); // and save merged again to cache

                            Statistic.Parser.asyncSerializeBinaryStats(data).then(function (arrayBuffer) {
                                saveStatisticData(arrayBuffer, ctrlUUID, date);
                            });
                            def.resolve(data);
                        } else {
                            // no new data available!
                            def.resolve(data);
                        }
                    }, function error(code) {
                        def.reject(code);
                        console.error("Statistic Error: Code", code);
                    }).fail(function (e) {
                        def.reject(SupportCode.STATISTIC_ERROR);
                        console.error(e.stack);
                    });
                }
            }, function (code) {
                console.warn("loading cached stats failed, download again!"); // remove from cache

                removeStatisticDataFromCache(ctrlUUID, date); // and download again..

                def.resolve(loadStatisticData(ctrlUUID, date)); // -> goes to download, because cached was removed!
                // resolving a promise with another promise (returned from fn) works!
            }).fail(function fail(e) {
                def.reject(SupportCode.STATISTIC_ERROR);
                console.error(e.stack);
            });
            return def.promise;
        } else {
            return downloadStatisticData(ctrlUUID, date).then(function success(data) {
                var parsedData = data[0],
                    rawData = data[1]; // save

                if (date.length === 6) {
                    // only save whole month data, not day data!
                    saveStatisticData(rawData, ctrlUUID, date);
                }

                return parsedData;
            }, function error(code) {
                throw code;
            }).fail(function (e) {
                console.error(e);

                if (e === SupportCode.STATISTIC_NO_DATA_AVAILABLE) {
                    throw e;
                } else {
                    throw SupportCode.STATISTIC_DOWNLOAD_ERROR;
                }
            });
        }
    };
    /**
     * loads statistic data from the cache
     * @param ctrlUUID
     * @param date
     * @returns {promise}
     */


    var loadCachedStatisticData = function loadCachedStatisticData(ctrlUUID, date) {
        return comp.loadFile(statisticDataFilename(ctrlUUID, date), DataType.STRING).then(function (file) {
            return asyncBase64StringToArrayBuffer(file).then(function (arrayBuffer) {
                return Statistic.Parser.asyncParseBinaryStats(arrayBuffer, nrOfOutputsOfControl(ctrlUUID)).then(function (parsedData) {
                    return parsedData;
                }, function (e) {
                    console.error(e);
                    throw SupportCode.STATISTIC_PARSE_ERROR;
                }); //return Statistic.Parser.parseBinaryStats(arrayBuffer, nrOfOutputsOfControl(ctrlUUID));
            }, function (e) {
                console.error(e);
                throw SupportCode.STATISTIC_BINARY_CONVERSION_ERROR;
            });
        });
    };
    /**
     * downloads the statistic data over websocket
     * @param ctrlUUID
     * @param date
     * @returns {promise}
     */


    var downloadStatisticData = function downloadStatisticData(ctrlUUID, date) {
        return comp.download(Commands.format(Commands.STATISTIC.DATA_MONTH, ctrlUUID, date)).then(function success(arrayBuffer) {
            if (arrayBuffer) {
                return Statistic.Parser.asyncParseBinaryStats(arrayBuffer, nrOfOutputsOfControl(ctrlUUID)).then(function success(parsedData) {
                    return [parsedData, arrayBuffer];
                }, function error(e) {
                    console.error(e);
                    throw SupportCode.STATISTIC_PARSE_ERROR;
                });
            } else {
                // do this for the actual month and test how it reacts, "no data" should appear!
                throw SupportCode.STATISTIC_NO_DATA_AVAILABLE;
            } // return [Statistic.Parser.parseBinaryStats(bytes, nrOfOutputsOfControl(ctrlUUID)), bytes];

        }, function error(code) {
            if (code !== SupportCode.WEBSOCKET_MANUAL_CLOSE) {
                console.error("ERROR while downloading statistic data! code", code);
                NavigationComp.showSupportErrorPopup(code).then(function () {
                    NavigationComp.navigateBack();
                });
                weakThis.stopLoadingStatisticData();
            }

            throw code;
        });
    };
    /**
     * saves statistic data to persistence
     * @param rawData
     * @param ctrlUUID
     * @param date
     */


    var saveStatisticData = function saveStatisticData(rawData, ctrlUUID, date) {
        asyncArrayBufferToBase64String(rawData).done(function success(base64String) {
            return comp.saveFile(statisticDataFilename(ctrlUUID, date), base64String, DataType.STRING).then(function success() {
                return addStatisticDataToCache(ctrlUUID, date).then(function success() {
                    console.log('saved succesfully cache index file');
                }, function error(e) {
                    console.error(e);
                    throw SupportCode.STATISTIC_CACHING_ERROR;
                });
            }, function error(e) {
                console.error(e);
                throw SupportCode.STATISTIC_CACHING_ERROR;
            });
        }, function error(e) {
            console.error(e);
            throw SupportCode.STATISTIC_BINARY_CONVERSION_ERROR;
        });
    };
    /**
     * checks if date is the current month
     * @param date
     * @returns {boolean}
     */


    var isCurrentMonth = function isCurrentMonth(date) {
        var miniserverTime = comp.getMiniserverTimeInfo(null, null, TimeValueFormat.MINISERVER_DATE_TIME);
        return miniserverTime.format(REQEUST_MONTH_FORMAT) === date;
    };
    /**
     * checks if statistic data is available
     * @param ctrlUUID
     * @param date
     * @returns {boolean}
     */


    var isStatisticDataAvailable = function isStatisticDataAvailable(ctrlUUID, date) {
        return weakThis.statisticControlList[ctrlUUID] && weakThis.statisticControlList[ctrlUUID].availableDates.indexOf(date) !== -1;
    };
    /**
     * returns the first and last timestamp for this date (month)
     * @param date string (YYYYMM or YYYYMMDD)
     * @returns {{from: number, to: number}}
     */


    var getRangeFromDate = function getRangeTsFromDate(date) {
        var m = moment.utc(date, [DAY_FORMAT, REQEUST_MONTH_FORMAT]).startOf("month"),
            firstTs = m.unix(),
            lastTs = m.add(1, "month").unix();
        return {
            from: firstTs,
            to: lastTs
        };
    };
    /**
     * checks if statistic data is cached
     * @param ctrlUUID
     * @param [date] optional
     * @returns {boolean}
     */


    var hasCachedStatisticData = function hasCachedStatisticData(ctrlUUID, date) {
        var ctrlCache = weakThis.cacheIndexList[ctrlUUID];

        if (!date) {
            // check if some data is cached
            return !!ctrlCache; // -> bool
        } else if (ctrlCache) {
            // check if data for date is cached
            for (var i = 0; i < ctrlCache.cachedDates.length; i++) {
                if (ctrlCache.cachedDates[i].date === date) {
                    return true;
                }
            }
        }

        return false;
    };
    /**
     * adds an entry to the cacheIndexList to indicate cached statistic data
     * @param ctrlUUID
     * @param date
     * @returns {promise} whether cacheIndexList could be saved or not
     */


    var addStatisticDataToCache = function addStatisticDataToCache(ctrlUUID, date) {
        var completed = !isCurrentMonth(date);

        if (!hasCachedStatisticData(ctrlUUID)) {
            weakThis.cacheIndexList[ctrlUUID] = {
                cachedDates: []
            };
        }

        if (!hasCachedStatisticData(ctrlUUID, date)) {
            weakThis.cacheIndexList[ctrlUUID].cachedDates.push({
                date: date,
                completed: completed
            });
        } else if (completed && !isCachedStatisticDataUpToDate(ctrlUUID, date)) {
            setCachedStatisticDataUpToDate(ctrlUUID, date);
        }

        return comp.saveFile(statisticCacheFilename(), weakThis.cacheIndexList, DataType.OBJECT);
    };
    /**
     * removes entry from the cacheIndexList for controlUUID and date
     * @param ctrlUUID
     * @param date
     * @returns {promise} whether cacheIndexList could be saved or not
     */


    var removeStatisticDataFromCache = function removeStatisticDataFromCache(ctrlUUID, date) {
        var ctrlCache = weakThis.cacheIndexList[ctrlUUID];

        if (ctrlCache) {
            for (var i = 0; i < ctrlCache.cachedDates.length; i++) {
                if (ctrlCache.cachedDates[i].date === date) {
                    ctrlCache.cachedDates.splice(i, 1);
                    break;
                }
            }
        } // TODO-thallth maybe (try to) delete files also? if it makes sense..


        return comp.saveFile(statisticCacheFilename(), weakThis.cacheIndexList, DataType.OBJECT);
    };
    /**
     * sets the completed flag in the cache to true
     * @param ctrlUUID
     * @param date
     */


    var setCachedStatisticDataUpToDate = function setCachedStatisticDataUpToDate(ctrlUUID, date) {
        var ctrlCache = weakThis.cacheIndexList[ctrlUUID];

        if (ctrlCache) {
            for (var i = 0; i < ctrlCache.cachedDates.length; i++) {
                if (ctrlCache.cachedDates[i].date === date) {
                    ctrlCache.cachedDates[i].completed = true;
                    break;
                }
            }
        }
    };
    /**
     * checks if the cached data is up to date
     * @param ctrlUUID
     * @param date
     * @returns {boolean}
     */


    var isCachedStatisticDataUpToDate = function isCachedStatisticDataUpToDate(ctrlUUID, date) {
        if (weakThis.cacheIndexList[ctrlUUID]) {
            var cacheArray = weakThis.cacheIndexList[ctrlUUID].cachedDates,
                cache;

            for (var i = 0; i < cacheArray.length; i++) {
                cache = cacheArray[i];

                if (cache.date === date) {
                    return cache.completed;
                }
            }
        }

        return false;
    };
    /**
     * returns the correct index (for value array) for the given outputUUID
     * @param ctrlUUID
     * @param outputUUID
     * @returns {number} index
     */


    var getWantedOutputIndex = function getWantedOutputIndex(ctrlUUID, outputUUID) {
        var outputs = weakThis.statisticControlList[ctrlUUID].outputs;

        for (var i = 0; i < outputs.length; i++) {
            if (outputs[i].uuid === outputUUID) {
                return i;
            }
        }
    };
    /**
     * prepares the data
     * @param data
     * @param date
     * @param ctrlUUID
     * @param outputUUID removeDuplicateDatapoints must be updated if more outputs should be supported!
     * @returns {{date: string, min: number, max: number, dataPoints: array}}
     */


    var prepareDataToDispatch = function prepareDataToDispatch(data, date, ctrlUUID, outputUUID) {
        var outputIdx = getWantedOutputIndex(ctrlUUID, outputUUID),
            control = comp.getStructureManager().getAllControls()[ctrlUUID],
            definedNoEntry = control.getStatisticNoDataValue(),
            frequency = getFrequencyOfControl(ctrlUUID),
            msNowUnix = SandboxComponent.getMiniserverTimeAsFakeUTC().unix(),
            visuType = getVisuTypeOfControlOutput(ctrlUUID, outputUUID);
        return lxWorker.evalFunction(Statistic.Processor.reduceStatisticDataToOutputs, [data, [outputIdx], definedNoEntry]).then(function (reducedData) {
            Debug.Statistic.Basic && console.log("reduceStatisticDataToOutputs finished!");
            return lxWorker.evalFunction(Statistic.Processor.addMissingDatapoints, [reducedData, frequency, visuType, msNowUnix, { Type: Statistic.Type, Frequency: Statistic.Frequency}]).then(function (completedData) {
                var range = getRangeFromDate(date);
                return lxWorker.evalFunction(Statistic.Processor.sortStatisticData, [completedData, range.from, range.to]).then(function (sortedData) {
                    Debug.Statistic.Basic && console.log("sortStatisticData finished!");
                    var minAndMax = {
                        min: NaN,
                        max: NaN
                    };

                    if (visuType !== Statistic.Type.BAR_CHART) {
                        minAndMax = Statistic.Processor.getMinAndMaxOfPoints(sortedData);

                        if (visuType === Statistic.Type.DIGITAL || frequency === Statistic.Frequency.EVERY_CHANGE) {
                            // remove unnecessary data points
                            // IMPORTANT for future: if more outputs, don't do this, or update algorithm!
                            // don't do it for accumulated!
                            // remove duplicate values or ts
                            return lxWorker.evalFunction(Statistic.Processor.removeDuplicateDatapoints, [sortedData, true, true]).then(function (cleanedData) {
                                Debug.Statistic.Basic && console.log("removeDuplicateDatapoints finished!");
                                return {
                                    date: date,
                                    min: minAndMax.min,
                                    max: minAndMax.max,
                                    dataPoints: cleanedData
                                };
                            });
                        } else {
                            // only remove duplicate ts!
                            return lxWorker.evalFunction(Statistic.Processor.removeDuplicateDatapoints, [sortedData, false, true]).then(function (cleanedData) {
                                Debug.Statistic.Basic && console.log("removeDuplicateDatapoints finished!");
                                return {
                                    date: date,
                                    min: minAndMax.min,
                                    max: minAndMax.max,
                                    dataPoints: cleanedData
                                };
                            });
                        }
                    }

                    return {
                        date: date,
                        min: minAndMax.min,
                        max: minAndMax.max,
                        dataPoints: sortedData
                    };
                });
            });
        });
    };
    /**
     * returns the number of outputs of a control
     * @param ctrlUUID
     * @returns {number}
     */


    var nrOfOutputsOfControl = function nrOfOutputsOfControl(ctrlUUID) {
        return weakThis.statisticControlList[ctrlUUID].outputs.length;
    };
    /**
     * returns the visuType of the output from control
     * @param ctrlUUID
     * @param outputUUID
     * @returns {Statistic.Type}
     */


    var getVisuTypeOfControlOutput = function getVisuTypeOfControlOutput(ctrlUUID, outputUUID) {
        var outputs = weakThis.statisticControlList[ctrlUUID].outputs;

        for (var i = 0; i < outputs.length; i++) {
            if (outputs[i].uuid === outputUUID) {
                return outputs[i].visuType;
            }
        }
    };
    /**
     * returns frequency of the control's statistic
     * @param ctrlUUID
     * @returns {Statistic.Frequency}
     */


    var getFrequencyOfControl = function getFrequencyOfControl(ctrlUUID) {
        return weakThis.statisticControlList[ctrlUUID].frequency;
    };

    var statisticDatesFilename = function statisticDatesFilename() {
        return sprintf(STATISTIC_DATES_FILE_NAME, comp.getMiniserverSerialNo());
    };

    var statisticCacheFilename = function statisticCacheFilename() {
        return sprintf(STATISTIC_CACHE_FILE_NAME, comp.getMiniserverSerialNo());
    };

    var statisticDataFilename = function statisticDataFilename(ctrlUUID, date) {
        return sprintf(STATISTIC_DATA_FILE_NAME, comp.getMiniserverSerialNo(), ctrlUUID, date);
    };
    /**
     * will delete all stored statistic data of the miniserver
     * @param serialNo of the miniserver
     */


    StatisticExt.prototype.deleteAllStatisticData = function deleteAllStatisticData(serialNo) {
        var cacheFileName = sprintf(STATISTIC_CACHE_FILE_NAME, serialNo),
            indexPromise = PersistenceComponent.loadFile(cacheFileName, DataType.OBJECT);
        indexPromise.then(function (indexFile) {
            var uuid, dates, date;

            for (uuid in indexFile) {
                if (indexFile.hasOwnProperty(uuid)) {
                    if (indexFile[uuid].hasOwnProperty("cachedDates")) {
                        dates = indexFile[uuid].cachedDates;

                        for (var i = 0; i < dates.length; i++) {
                            date = dates[i].date;
                            var fileName = sprintf(STATISTIC_DATA_FILE_NAME, serialNo, uuid, date);
                            PersistenceComponent.deleteFile(fileName);
                        }
                    }
                }
            }

            PersistenceComponent.deleteFile(cacheFileName);
        }, function () {// no file, so there is no statistic data to delete
        });
    };
    /**
     * will delete the stored statistic data of the provided control on the given miniserver
     * @param uuid          of the control we want to delete the statistics of
     * @param [serialNo]    of the miniserver (using activeMs if not provided)
     */


    StatisticExt.prototype.deleteStatisticDataOf = function deleteStatisticDataOf(uuid, serialNo) {
        Debug.Statistic.Basic && console.log(weakThis.name, "deleteStatisticDataOf: " + uuid);
        var msSerial = serialNo || ActiveMSComponent.getActiveMiniserver().serialNo,
            cacheFileName = sprintf(STATISTIC_CACHE_FILE_NAME, msSerial),
            indexPromise = PersistenceComponent.loadFile(cacheFileName, DataType.OBJECT); // if statistic data of the active ms is deleted, make sure to update the current cached data.

        if (msSerial === ActiveMSComponent.getActiveMiniserver().serialNo) {
            if (this.cacheIndexList) {
                // there may not be one yet.
                delete this.cacheIndexList[uuid];
            }

            this.statisticInfoAvailable = false;
        }

        indexPromise.then(function (indexFile) {
            var dates, date;

            if (indexFile.hasOwnProperty(uuid)) {
                if (indexFile[uuid].hasOwnProperty("cachedDates")) {
                    dates = indexFile[uuid].cachedDates;

                    for (var i = 0; i < dates.length; i++) {
                        date = dates[i].date;
                        var fileName = sprintf(STATISTIC_DATA_FILE_NAME, serialNo, uuid, date);
                        PersistenceComponent.deleteFile(fileName);
                    }
                }

                delete indexFile[uuid];
                return comp.saveFile(cacheFileName, indexFile, DataType.OBJECT);
            }
        }.bind(this), function () {// no file, so there is no statistic data to delete
        });
    };

    return StatisticExt;
});
