'use strict';

define("ClimateUsControlStateContainer", ["ControlStateContainer", "ClimateUsControlEnums"], function (ControlStateContainer, CtrlEnums) {
    return class ClimateUsControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            // controls & their demands
            this.states.controls = JSON.parse(newVals[this.control.states.controls].text) || [];
            this.states.heatingControls = this._getControlsWithDemand(this.states.controls, CtrlEnums.Demand.HEATING);
            this.states.coolingControls = this._getControlsWithDemand(this.states.controls, CtrlEnums.Demand.COOLING);
            this.states.idleControls = this._getControlsWithDemand(this.states.controls, CtrlEnums.Demand.NONE);
            this.states.hasHeatingDemand = this.states.heatingControls.length > 0;
            this.states.hasCoolingDemand = this.states.coolingControls.length > 0;
            this.states.hasNoDemand = !this.states.hasHeatingDemand && !this.states.hasCoolingDemand; // status of the climate controller

            this.states.currentStatus = newVals[this.control.states.currentStatus];
            this.states.hcStage = this._getHcStage();
            this.states.isHeating = this._getIsHeating();
            this.states.isCooling = this._getIsCooling();
            this.states.isHeatingStage2 = this.states.isHeating && this._hasStatusFlag(CtrlEnums.CurrentStatus.HEATING_STAGE_2);
            this.states.isCoolingStage2 = this.states.isCooling && this._hasStatusFlag(CtrlEnums.CurrentStatus.COOLING_STAGE_2);
            this.states.notHeatingDueTemp = this._hasStatusFlag(CtrlEnums.CurrentStatus.NEED_HEAT_BUT_TOO_HOT);
            this.states.notCoolingDueTemp = this._hasStatusFlag(CtrlEnums.CurrentStatus.NEED_COOL_BUT_TOO_COLD);
            this.states.isSwitching = this._getIsSwitching();
            this.states.isSwitchingToHeating = this._hasStatusFlag(CtrlEnums.CurrentStatus.SWITCHING_TO_HEAT);
            this.states.isSwitchingToCooling = this._hasStatusFlag(CtrlEnums.CurrentStatus.SWITCHING_TO_COOL);
            this.states.switchingText = this._getSwitchingText();
            this.states.isEmergencyHeaterActive = this._hasStatusFlag(CtrlEnums.CurrentStatus.HEATING_EMERGENCY);
            this.states.wantsToHeatButTooLittleDemand = this._hasStatusFlag(CtrlEnums.CurrentStatus.HEAT_DEMAND_BUT_TOO_LITTLE);
            this.states.wantsToCoolButTooLittleDemand = this._hasStatusFlag(CtrlEnums.CurrentStatus.COOL_DEMAND_BUT_TOO_LITTLE); //this._logStatus();
            // Emergency Heat info

            this.states.demandCool = newVals[this.control.states.demandCool];
            this.states.demandHeat = newVals[this.control.states.demandHeat];
            this.states.demandThreshold = newVals[this.control.states.threshold]; // Mode of the climate controller

            this.states.mode = newVals[this.control.states.mode];
            this.states.modeOnlyHeating = this.states.mode === CtrlEnums.Mode.HEATING_ONLY;
            this.states.modeOnlyCooling = this.states.mode === CtrlEnums.Mode.COOLING_ONLY;
            this.states.modeAuto = this.states.mode === CtrlEnums.Mode.HEATING_AND_COOLING;
            this.states.modeOff = this.states.mode === CtrlEnums.Mode.OFF;
            this.states.modeName = this._getModeName(); // Mode Timer

            this.states.modeTimerUntil = newVals[this.control.states.modeTimerUntil];
            this.states.modeTimerActive = this.states.modeTimerUntil !== 0;
            this.states.modeTimerActiveText = this._getModeTimerActiveText(); // Fan

            this.states.fanTimerUntil = newVals[this.control.states.fanTimerUntil];
            this.states.fanActive = newVals[this.control.states.fan];
            this.states.fanTimerActive = this.states.fanTimerUntil !== 0;
            this.states.fanTimerActiveText = this._getFanTimerActiveText(); // Emergency Heat info

            this.states.emergencyHeatActive = newVals[this.control.states.emergencyOverride] > 0;
            this.states.emergencyHeatTimerUntil = newVals[this.control.states.emergencyTimerUntil];
            this.states.emergencyHeatTimerActive = this.states.emergencyHeatTimerUntil !== 0;
            this.states.emergencyHeatTimerActiveText = this._getEmergencyHeatTimerActiveText(); // Outside temperature stuff

            this.states.actualOutdoorTemp = newVals[this.control.states.actualOutdoorTemp];
            this.states.minimumTempCooling = newVals[this.control.states.minimumTempCooling];
            this.states.maximumTempHeating = newVals[this.control.states.maximumTempHeating];
            this.states.outdoorTempCoolAllowed = this.states.actualOutdoorTemp >= this.states.minimumTempCooling;
            this.states.outdoorTempHeatAllowed = this.states.actualOutdoorTemp <= this.states.maximumTempHeating;
            this.states.outdoorTempHeatCoolAllowed = this.states.outdoorTempCoolAllowed && this.states.outdoorTempHeatAllowed;

            if (Feature.HVAC_CLIMATE_CONTROLLER_INTEGRATION) {
                this.states.excessEnergyBitmask = newVals[this.control.states.excessEnergy];
                this.states.excessEnergyHeat = hasBit(this.states.excessEnergyBitmask, CtrlEnums.ExcessEnergy.EMERGENCY_HEAT);
                this.states.excessEnergyCool = hasBit(this.states.excessEnergyBitmask, CtrlEnums.ExcessEnergy.EMERGENCY_COOL);
                this.states.fanActive = this.control.hasFanCapability && !!this.states.fanActive;
                this.states.serviceMode = this.control.hasServiceModeCapability && newVals[this.control.states.serviceMode] || 0;

                this.states.outdoorTempInfo = newVals[this.control.states.outdoorTempInfo];
                if (this.control.states.hasOwnProperty("outdoorTempMode")) {
                    this.states.outdoorTempMode = newVals[this.control.states.outdoorTempMode]
                } else {
                    this.states.outdoorTempMode = CtrlEnums.OutdoorTempMode.CURR_TEMP;
                }
            } else {
                this.states.excessEnergyBitmask = 0;
                this.states.excessEnergyHeat = false;
                this.states.excessEnergyCool = false;
                this.states.serviceMode = 0;

                /* Feedback by LA: connectedInputs doesn't monitor the outside temp input. The value -1000 for the
                outside temp state indicates whether or not it's used. */
                if (this.states.actualOutdoorTemp > -999) {
                    this.states.outdoorTempInfo = CtrlEnums.OutdoorTempInfo.OK;
                } else {
                    this.states.outdoorTempInfo = CtrlEnums.OutdoorTempInfo.NO_DATA;
                }
                // the hvac controller always only used the current temperature.
                this.states.outdoorTempMode = CtrlEnums.OutdoorTempInfo.CURR_TEMP;
            }
        }

        getStateText() {
            return this._getModeName();
        }

        getStateIcon() {
            return Icon.Climate.THERMOMETER;
        }

        getStateColor() {
            if (this._getIsInServiceMode()) {
                return window.Styles.colors.white;
            } else if (this._getIsHeating()) {
                return window.Styles.colors.orange;
            } else if (this._getIsCooling()) {
                return window.Styles.colors.blue;
            } else {
                return Color.TEXT_HINT_A;
            }
        }

        getStateIconSmall() {
            var iconSrc = null,
                iconColor = null,
                iconObj = null;

            if (this._getIsInServiceMode()) {
                iconSrc = Icon.WRENCH;
                iconColor = window.Styles.colors.red;
            } else if (this._getIsHeating()) {
                iconSrc = Icon.ClimateUs.HEATING;
                iconColor = window.Styles.colors.orange;
            } else if (this._getIsCooling()) {
                iconSrc = Icon.ClimateUs.COOLING;
                iconColor = window.Styles.colors.blue;
            }

            if (iconSrc) {
                iconObj = {
                    color: iconColor,
                    iconSrc: iconSrc
                };
            }

            return iconObj;
        }

        getStateTextColor() {
            return this.getStateColor();
        }

        getStateInfo() {
            var info = null,
                endTime;
            const { message } = this._getExcessEnergyInfo() || {};
            if (nullEmptyString(message)) {
                info = this._getExcessEnergyInfo();
            } else if (nullEmptyString(this.states.infoText)) {
                info = {
                    message: _("controls.climate.override.logic.input", {
                        input: this.states.infoText
                    })
                };
            }

            return info;
        }

        /**
         * Returns the name of the current mode
         * @return {*}
         * @private
         */
        _getModeName() {
            var modeName;

            switch (this.states.mode) {
                case CtrlEnums.Mode.HEATING_AND_COOLING:
                    modeName = _("controls.climate.us.mode.auto");
                    break;

                case CtrlEnums.Mode.HEATING_ONLY:
                    modeName = _("controls.climate.us.mode.heating");
                    break;

                case CtrlEnums.Mode.COOLING_ONLY:
                    modeName = _("controls.climate.us.mode.cooling");
                    break;

                case CtrlEnums.Mode.OFF:
                    modeName = _("controls.climate.us.mode.off");
                    break;

                default:
                    modeName = "";
                    break;
            }

            return modeName;
        }

        _hasStatusFlag(flag) {
            return hasBit(this.states.currentStatus, flag);
        }

        _getIsHeating() {
            var isHeating = false;
            isHeating |= this._hasStatusFlag(CtrlEnums.CurrentStatus.HEATING_STAGE_1);
            isHeating |= this._hasStatusFlag(CtrlEnums.CurrentStatus.HEATING_STAGE_2);
            isHeating |= this._hasStatusFlag(CtrlEnums.CurrentStatus.HEATING_EMERGENCY);
            return isHeating;
        }

        _getIsCooling() {
            var isCooling = false;
            isCooling |= this._hasStatusFlag(CtrlEnums.CurrentStatus.COOLING_STAGE_1);
            isCooling |= this._hasStatusFlag(CtrlEnums.CurrentStatus.COOLING_STAGE_2);
            return isCooling;
        }

        _getExternalHeaterInUse() {
            return this._hasStatusFlag(CtrlEnums.CurrentStatus.HEATING_EMERGENCY);
        }

        _getHasNoDemand() {
            return this._getControlsWithDemand(this.states.controls, CtrlEnums.Demand.NONE).length === this.states.controls.length;
        }

        _getHcStage() {
            var stage = 0;

            if (this._hasStatusFlag(CtrlEnums.CurrentStatus.COOLING_STAGE_2) || this._hasStatusFlag(CtrlEnums.CurrentStatus.HEATING_STAGE_2)) {
                stage = 2;
            } else if (this._hasStatusFlag(CtrlEnums.CurrentStatus.COOLING_STAGE_1) || this._hasStatusFlag(CtrlEnums.CurrentStatus.HEATING_STAGE_1)) {
                stage = 1;
            }

            return stage;
        }

        _getIsSwitching() {
            var isSwitching = false;
            isSwitching |= this._hasStatusFlag(CtrlEnums.CurrentStatus.SWITCHING_TO_COOL);
            isSwitching |= this._hasStatusFlag(CtrlEnums.CurrentStatus.SWITCHING_TO_HEAT);
            return isSwitching;
        }

        _getSwitchingText() {
            var result, switchingTo, reason;

            if (this._getIsSwitching()) {
                if (this._hasStatusFlag(CtrlEnums.CurrentStatus.SWITCHING_TO_HEAT)) {
                    switchingTo = _("controls.climate.us.state.switching-to-heat");
                } else if (this._hasStatusFlag(CtrlEnums.CurrentStatus.SWITCHING_TO_COOL)) {
                    switchingTo = _("controls.climate.us.state.switching-to-cool");
                }

                if (this._hasStatusFlag(CtrlEnums.CurrentStatus.SWITCHING_DELAYED_FAN_OVERRUN_TIME)) {
                    reason = _("controls.climate.us.state.switch-delay.fan-overrun-time");
                } else if (this._hasStatusFlag(CtrlEnums.CurrentStatus.SWITCHING_DELAYED_REVERSING_VALVE)) {
                    reason = _("controls.climate.us.state.switch-delay.valve-reversing");
                } else if (this._hasStatusFlag(CtrlEnums.CurrentStatus.SWITCHING_DELAYED_MINIMUM_OFF_TIME)) {
                    reason = _("controls.climate.us.state.switch-delay.min-off-time");
                }

                if (switchingTo && reason) {
                    result = switchingTo + "\n" + reason;
                } else if (switchingTo && !reason) {
                    result = switchingTo;
                } else if (reason) {
                    result = reason;
                }
            }

            return result;
        }

        _getFanTimerActiveText() {
            var endTime,
                texts = [];

            if (this.states.fanTimerUntil > 0) {
                endTime = LxDate.formatFutureDateDynamic(new LxDate(this.states.fanTimerUntil, true), true);
                texts.push(_("controls.climate.us.fan.state.on-until") + "</br>");
                texts.push({
                    text: endTime,
                    color: window.Styles.colors.stateActive
                });
            } else if (this.states.fanTimerUntil < 0) {
                texts.push(_("controls.climate.us.fan.state.always-on"));
            }

            return texts;
        }

        _getEmergencyHeatTimerActiveText() {
            var endTime,
                texts = [];

            if (this.states.emergencyHeatTimerUntil > 0) {
                endTime = LxDate.formatFutureDateDynamic(new LxDate(this.states.emergencyHeatTimerUntil, true), true);
                texts.push(_("controls.climate.us.emergency-heat-active-until") + "</br>");
                texts.push({
                    text: endTime,
                    color: window.Styles.colors.stateActive
                });
            } else if (this.states.emergencyHeatTimerUntil < 0) {
                texts.push(_("controls.climate.us.emergency-heat-always-active"));
            }

            return texts;
        }

        _getModeTimerActiveText() {
            var endTime,
                texts = [];

            if (this.states.modeTimerUntil > 0) {
                endTime = LxDate.formatFutureDateDynamic(new LxDate(this.states.modeTimerUntil, true), true);
                texts.push(_("controls.climate.us.mode.temporary-on", {
                    modeName: this._getModeName()
                }) + "</br>");
                texts.push({
                    text: endTime,
                    color: window.Styles.colors.stateActive
                });
            } else if (this.states.modeTimerUntil < 0) {
                texts.push(_("controls.climate.us.mode.permanent-on", {
                    modeName: this._getModeName()
                }));
            }

            return texts;
        }

        _getControlsWithDemand(controls, demand) {
            return controls.filter(function (ctrl) {
                return ctrl.demand === demand;
            });
        }

        _getIsInServiceMode() {
            return this.states.serviceMode && this.states.serviceMode !== CtrlEnums.ServiceModeSettings.OFF;
        }

        _getExcessEnergyInfo() {
            var info = null,
                message = null; // only show if excess energy is available AND it is used.

            if (this.states.excessEnergyHeat && this._getIsHeating()) {
                message = _("controls.climate.excess-energy.heating");
            } else if (this.states.excessEnergyCool && this._getIsCooling()) {
                message = _("controls.climate.excess-energy.cooling");
            }

            if (message !== null) {
                info = {
                    message: message
                };
            }

            return info;
        }

        _logStatus() {
            var keys = Object.keys(CtrlEnums.CurrentStatus);
            keys.forEach(function (key) {
                console.log("    " + key + ": " + !!this._hasStatusFlag(CtrlEnums.CurrentStatus[key]));
            }.bind(this));
        }

    };
});
